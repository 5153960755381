import classNames from 'classnames';
import TextField from 'features/targetingV2/components/core/TextField';
import React, { ReactNode, Ref } from 'react';

// Input ref is a reference to underlying input element to the ref prop
export const InputComponent = ({ inputRef, ...props }: any) => (
  <div ref={inputRef} {...props} />
);

// sets up Select component to use MU TextField
const Control = (props: {
  children: ReactNode;
  innerProps: object;
  innerRef: Ref<any>;
  selectProps: any;
  testId?: string;
}) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      externalClasses,
      TextFieldProps,
      AttributeProps,
      isDisabled,
      externalLink,
      testId,
    },
  } = props;

  return (
    <TextField
      multiline
      data-testid={AttributeProps.fieldAttribute}
      fullWidth
      disabled={isDisabled}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: classNames(
            `${classes.input}`,
            `${externalClasses.input}`,
            {
              [`${classes.input}--externalLink`]: externalLink,
            }
          ),
          ref: innerRef,
          children,
          'data-testid': testId,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

export default Control;
