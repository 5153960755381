import { IOption } from 'features/targetingV2/types/common';
import { IDayTimePoint } from 'features/targetingV2/types/targeting';
import {
  DayOfWeekAttributes,
  DayTargeting,
  DayTimeTargetingAttributes,
} from 'interfaces/generated.types';

import { weekdayOptions } from './dayTime';

// eslint-disable-next-line import/prefer-default-export
export const getDayTimeTargetingAttributes = (
  formValues: any
): DayTimeTargetingAttributes | null => {
  const dayTimeTargeting = formValues?.targetingDefinitionV2?.dayTimeTargeting;
  if (
    !dayTimeTargeting?.dayTimePoints ||
    !dayTimeTargeting.dayTimePoints.length
  )
    return null;

  const daysOfWeek = weekdayOptions.reduce(
    (result: DayOfWeekAttributes[], weekday: IOption<DayTargeting>) => {
      const allSelectedHours: IDayTimePoint[] = dayTimeTargeting.dayTimePoints
        .filter(
          (dayTimePoint: IDayTimePoint) => dayTimePoint.day === weekday.value
        )
        .map((dayTimePoint: IDayTimePoint) => dayTimePoint.hour);

      if (allSelectedHours.length)
        result.push({
          day: weekday.value,
          hours: allSelectedHours,
        });
      return result;
    },
    []
  );

  return {
    clusivity: dayTimeTargeting.clusivity,
    timeZone: dayTimeTargeting.timeZone,
    daysOfWeek,
  };
};
