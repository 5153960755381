import { IAncestorType, SectionType } from 'features/targetingV2/types/common';
import {
  IAllLocationAccuracies,
  IAudienceParameter,
  ILocationParameterGroup,
  IParameter,
  IParameterGroup,
  IParameterInfo,
} from 'features/targetingV2/types/targeting';
import { LocationParameterType, Territory } from 'interfaces/generated.types';
import { TerritoryLabelShort } from 'utils/territory';

import { getSectionTitle } from './common';
import { defaultLocationParameter } from './defaults';
import { getSortedGradeLabels } from './grading';

export const getLocationAccuracyIndex = (
  selectedAccuracyRadius: number,
  allLocationAccuracyValues: number[]
) =>
  allLocationAccuracyValues.findIndex(
    (locationAccuracy) => locationAccuracy === selectedAccuracyRadius
  ) + 1;

export const getAccuracy = (
  parameterGroup: IParameterGroup,
  parameter: IParameter,
  sectionType: SectionType,
  allLocationAccuracies: IAllLocationAccuracies,
  isGradeLabelMinimal: boolean = false
) => {
  switch (sectionType) {
    case SectionType.Location: {
      const locationParameterGroup = parameterGroup as ILocationParameterGroup;
      if (
        parameter.type !== LocationParameterType.Geo ||
        locationParameterGroup.accuracyRadius === -1
      )
        return '';

      const locationAccuracyIndex = getLocationAccuracyIndex(
        locationParameterGroup.accuracyRadius,
        allLocationAccuracies.allValues || []
      );
      return locationAccuracyIndex;
    }
    case SectionType.Audience: {
      return getSortedGradeLabels(
        (parameter as IAudienceParameter).enabledGrades,
        isGradeLabelMinimal
      );
    }
    default:
      return '';
  }
};

export const getNumberedSectionTitle = (
  parameterGroupsCount: number,
  parameterGroupIndex: number,
  sectionType: SectionType
) => {
  if (parameterGroupsCount <= 1) {
    return getSectionTitle(sectionType);
  }
  return `${getSectionTitle(sectionType)} ${parameterGroupIndex + 1}`;
};

export const getValueBreadcrumbs = (
  ancestorList: IAncestorType[] | undefined
) => {
  if (ancestorList && ancestorList.length) {
    return ` (${ancestorList.map((ancestor) => ancestor.label)})`
      .split(',')
      .join(' > ');
  }
  return '';
};

export const getParameterValueLabels = (parameter: IParameter) =>
  parameter.values.map(
    (value) => `${value.label}${getValueBreadcrumbs(value.ancestorList)}`
  );

export const getParameterValueLabelsBasedOnType = (
  parameter: IParameter,
  parameterGroup: IParameterGroup,
  territory: Territory
) => {
  switch (parameter.type) {
    case LocationParameterType.Geo: {
      const locationParameterGroup = parameterGroup as ILocationParameterGroup;
      if (!parameter.values.length) {
        if (locationParameterGroup.limitLocationParameterValue?.id) {
          const limitLocationCountryLabel = TerritoryLabelShort[territory];
          return [limitLocationCountryLabel];
        }
        return ['World'];
      }
      return getParameterValueLabels(parameter);
    }
    default:
      return getParameterValueLabels(parameter);
  }
};

export const getParameterTypeName = (
  parameterType: string,
  allParametersInfo: (IParameterInfo | undefined)[]
) =>
  allParametersInfo.find(
    (parameterInfo) => parameterInfo?.type === parameterType
  )?.name;

export const getDefaultRows = (
  parameterGroup: IParameterGroup,
  sectionType: SectionType,
  getDefaultRowForParameter: (parameter: IParameter) => any[]
) => {
  switch (sectionType) {
    case SectionType.Location: {
      const geoOccurrences = parameterGroup.parameters.filter(
        (parameter) => parameter.type === LocationParameterType.Geo
      );

      if (!geoOccurrences.length)
        return getDefaultRowForParameter(defaultLocationParameter);

      return [];
    }
    default:
      return [];
  }
};
