import {
  usePostcodeGroupQuery,
  useAllPostcodeGroupsQuery,
  useCreatePostcodeGroupMutation,
  useUpdatePostcodeGroupMutation,
  useDeletePostcodeGroupMutation,
  useCountryGroupQuery,
  useAllCountryGroupsQuery,
  useCreateCountryGroupMutation,
  useUpdateCountryGroupMutation,
  useDeleteCountryGroupMutation,
} from 'interfaces/generated.types';
import { find } from 'lodash';
import memoizeOne from 'memoize-one';

export enum TargetingPackTypeEnum {
  POSTCODE = 'postcode',
  LOCATION = 'location',
  REGION = 'region',
}

export interface ITargetingPackType {
  type: TargetingPackTypeEnum;
  label: string;
  dataType: string;
  path: string;
  apiPath: string;
  details: Function;
  listAll: Function;
  create: Function;
  update: Function;
  delete: Function;
}

export const targetingPackTypes: ITargetingPackType[] = [
  {
    type: TargetingPackTypeEnum.POSTCODE,
    label: 'Postcode',
    dataType: 'postcode',
    path: 'postcodes',
    apiPath: 'postcodes',
    details: usePostcodeGroupQuery,
    listAll: useAllPostcodeGroupsQuery,
    create: useCreatePostcodeGroupMutation,
    update: useUpdatePostcodeGroupMutation,
    delete: useDeletePostcodeGroupMutation,
  },
  {
    type: TargetingPackTypeEnum.REGION,
    label: 'Regions',
    dataType: 'country',
    apiPath: 'countries',
    path: 'countryParams',
    details: useCountryGroupQuery,
    listAll: useAllCountryGroupsQuery,
    create: useCreateCountryGroupMutation,
    update: useUpdateCountryGroupMutation,
    delete: useDeleteCountryGroupMutation,
  },
];

export const getTargetingPackDetails = memoizeOne(
  (type: string) => find(targetingPackTypes as any, { type }) || {}
);
