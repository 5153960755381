import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  graphsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  donut: {
    width: '100%',
    height: '165px',
  },
  label: {
    margin: 0,
  },
  loader: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
