import { APP_ENVIRONMENT } from './environmentVariables';

export const setCookie = (
  key: string,
  value: any,
  maxAge?: number,
  additionalFlags: string = ''
) => {
  const cookieAge = typeof maxAge !== 'undefined' ? `max-age=${maxAge};` : '';
  const secureFlag = APP_ENVIRONMENT !== 'local' ? 'Secure;' : '';

  document.cookie = `${key}=${JSON.stringify(
    value
  )};path=/;${cookieAge}${secureFlag}${additionalFlags}`.trim();
};

export const getCookieValue = (key: string) => {
  const cookies = document.cookie.split(';');
  const startsWithKey = (item: string) => item.trim().startsWith(`${key}=`);

  const cookieHasKey = cookies.some(startsWithKey);

  if (cookieHasKey) {
    const cookieValueAsString = cookies.find(startsWithKey)?.split('=')[1];

    const cookieValue = JSON.parse(cookieValueAsString || '""');
    return cookieValue;
  }
  return null;
};
