import StyledChip from 'features/targetingV2/components/core/StyledChip/StyledChip';
import { ChipState } from 'features/targetingV2/types/common';
import React, { ReactNode } from 'react';

const MultiValue = ({
  children,
  selectProps,
  removeProps,
  data,
  index,
}: {
  children: ReactNode;
  selectProps: any;
  removeProps: any;
  data: any;
  index?: number;
}) => {
  const {
    readOnly = false,
    active = true,
    ancestorList,
    label,
    state = ChipState.DEFAULT,
    tooltip = '',
  } = data;
  const {
    AttributeProps,
    showBreadcrumbsOnMultiValue,
    showHiddenMultiValues,
    noOfMultiValuesToShow,
    inactiveMultiValueTooltip,
  } = selectProps;

  const chipState = !active ? ChipState.INACTIVE : state;
  const chipTooltip = !active ? inactiveMultiValueTooltip : tooltip;
  const showChip =
    (index !== undefined && index < noOfMultiValuesToShow) ||
    showHiddenMultiValues;

  return showChip ? (
    <StyledChip
      label={children}
      removeActions={removeProps}
      data-tc={AttributeProps.chipAttribute}
      state={chipState}
      tooltip={chipTooltip}
      readOnly={readOnly}
      breadcrumbsList={showBreadcrumbsOnMultiValue ? ancestorList : []}
      breadcrumbIdentifier={label}
    />
  ) : null;
};

export default MultiValue;
