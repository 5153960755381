import DualTreeDialog from 'components/DualTreeDialog/DualTreeDialog';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import TargetingParameters from 'features/targeting/components/TargetingParameters/TargetingParameters';
import { ErrorMessage, FieldArray } from 'formik';
import { IFormProps } from 'interfaces';
import { Publisher } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { getTargetingFieldArrayName } from 'utils/targetingDefinitions';
import {
  removeTargetingType,
  toggleTargetingType,
} from 'utils/targetingParameters';

import FormHelperText from '@material-ui/core/FormHelperText';
import AddIcon from '@material-ui/icons/Add';

import {
  targetingParameterClusivityTypesEnum,
  targetingParameterTypesEnum,
} from '../TargetingParameters/TargetingParametersValues';
import useStyles from './Targeting.styles';

export interface ITargetingProps {
  templateIndex?: number;
  groupIndex: number;
  allPublishers?: Publisher[] | [];
}

const Targeting = (props: IFormProps<any> & ITargetingProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    values,
    templateIndex,
    groupIndex,
    allPublishers,
  } = props;
  const classes = useStyles();

  const fieldArrayName = getTargetingFieldArrayName(groupIndex, templateIndex);
  const fieldName =
    templateIndex !== undefined
      ? `targetingMasterTemplates[${templateIndex}].groups.${groupIndex}`
      : `targetingGroups[${groupIndex}]`;
  const targetingValues =
    templateIndex !== undefined
      ? values.targetingMasterTemplates[templateIndex].groups
      : values.targetingGroups;

  const [selectedTypes, setSelectedTypes] = useState<
    targetingParameterTypesEnum[]
  >([]);
  const [isOpen, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const loadingPublishers = !(allPublishers && allPublishers.length);

  useEffect(() => {
    if (
      targetingValues[groupIndex].audienceParams &&
      targetingValues[groupIndex].audienceParams.length
    ) {
      setSelectedTypes([
        ...selectedTypes,
        ...targetingValues[groupIndex].audienceParams.map(
          (targeting: any) => targeting.type
        ),
      ]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.group} data-tc="targeting">
      <FieldArray name={`${fieldArrayName}.audienceParams`}>
        {({ push, remove }) => (
          <>
            <div className={classes.channelsContainer}>
              <>
                <StyledButton
                  variant={ButtonVariantEnum.Outlined}
                  color={ButtonColorEnum.Primary}
                  onClick={() => {
                    setFieldTouched(`${fieldName}.channels`, true);
                    handleClickOpen();
                  }}
                  data-tc="selectChannels"
                  testId="selectChannels"
                  disabled={loadingPublishers}
                  isLoading={loadingPublishers}
                >
                  {`Channels (${targetingValues[groupIndex].channels.length})`}
                </StyledButton>
                {allPublishers && allPublishers.length > 0 && isOpen && (
                  <DualTreeDialog
                    groupIndex={groupIndex}
                    allPublishers={allPublishers}
                    isOpen
                    handleClose={() => setOpen(false)}
                    targetingGroupValues={targetingValues}
                    targetingGroupName={`${
                      templateIndex !== undefined ? 'groups' : 'targetingGroups'
                    }`}
                    templateIndex={templateIndex}
                    setFieldValue={setFieldValue}
                  />
                )}
              </>

              {allPublishers && allPublishers.length > 0 && (
                <div className={classes.channelErrors}>
                  <FormHelperText error data-tc="targetingChannelError">
                    <ErrorMessage name={`${fieldName}.channels`} />
                  </FormHelperText>
                </div>
              )}
            </div>
            {targetingValues[groupIndex].audienceParams &&
              targetingValues[groupIndex].audienceParams.length > 0 && (
                <TargetingParameters
                  {...props}
                  remove={(index: number) => {
                    remove(index);
                    removeTargetingType(
                      setSelectedTypes,
                      selectedTypes,
                      targetingValues[groupIndex].audienceParams[index].type
                    );
                  }}
                  title="Targeting Parameter"
                  targetingGroupName={`${
                    templateIndex !== undefined ? 'groups' : 'targetingGroups'
                  }`}
                  templateIndex={templateIndex}
                  groupIndex={groupIndex}
                  selectedTypes={selectedTypes}
                  toggleSelectedTargetingType={(
                    type: targetingParameterTypesEnum,
                    newType: targetingParameterTypesEnum
                  ) =>
                    toggleTargetingType(
                      setSelectedTypes,
                      selectedTypes,
                      type,
                      newType
                    )
                  }
                />
              )}
            <div className={classes.addTargetingButton}>
              <StyledButton
                data-tc="targetingAddIcon"
                onClick={() => {
                  push({
                    type: '',
                    clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
                    readOnlyType: false,
                  });
                }}
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Outlined}
              >
                <AddIcon />
                Add a Targeting Parameter
              </StyledButton>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Targeting;
