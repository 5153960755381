import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';

import TargetingTemplateTabsForm, {
  ITargetingTemplateValues,
} from 'features/targeting/components/TargetingTemplateTabsForm/TargetingTemplateTabsForm';
import TargetingTemplateFormValidation from 'features/targeting/validations/targetingTemplates';

import useError from 'hooks/Error/useError';

import {
  UpdateTargetingTemplateMutationFn,
  useUpdateTargetingTemplateMutation,
} from 'interfaces/generated.types';

import { differenceInValues, handleFormErrors } from 'utils/forms';
import {
  groupTargetingGroups,
  ITargetingTemplate,
} from 'utils/targetingDefinitions';

interface IUpdateTargetingTemplateContainerProps {
  history: History;
  match: {
    params: {
      targetingTemplateId: string;
    };
  };
  targetingTemplate: ITargetingTemplate;
  isTemplateAssignedToActiveTerritory: boolean;
}

export const handleOnComplete = (history: History) => {
  history.push('/targeting-templates');
};

export const submitUpdateTargetingTemplate = (
  initialValues: ITargetingTemplateValues,
  targetingTemplateId: string,
  updateTargetingTemplate: UpdateTargetingTemplateMutationFn,
  toggleErrorModal: () => void
) => (
  formValues: ITargetingTemplateValues,
  { setStatus, setSubmitting }: FormikActions<ITargetingTemplateValues>
) => {
  const filteredValues: any = {
    ...differenceInValues(formValues, initialValues),
    id: targetingTemplateId,
  };

  updateTargetingTemplate({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateTargetingTemplateContainer = ({
  history,
  match,
  targetingTemplate,
  isTemplateAssignedToActiveTerritory,
}: IUpdateTargetingTemplateContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Targeting Template.",
    'Please try again later.',
  ]);

  const [updateTargetingTemplate] = useUpdateTargetingTemplateMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  const initialValues = {
    name: targetingTemplate.name,
    description: targetingTemplate.description || '',
    isMasterTemplate: targetingTemplate.isMasterTemplate,
    targetingGroups: groupTargetingGroups({
      targetingGroups: targetingTemplate.targetingGroups,
      masterTargetingTemplates: [],
    }).groups,
    territory: targetingTemplate.territory,
  };

  return (
    <>
      <Formik<ITargetingTemplateValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={TargetingTemplateFormValidation}
        onSubmit={submitUpdateTargetingTemplate(
          initialValues,
          match.params.targetingTemplateId,
          updateTargetingTemplate,
          toggleErrorModal
        )}
      >
        {(props) => (
          <TargetingTemplateTabsForm
            {...props}
            update
            targetingTemplate={targetingTemplate}
            history={history}
            isTemplateAssignedToActiveTerritory={
              isTemplateAssignedToActiveTerritory
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateTargetingTemplate"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default UpdateTargetingTemplateContainer;
