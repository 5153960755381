import { Field } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import React, { useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import {
  BitRateTypes,
  ExtensionEnum,
  ExtensionTypes,
  FormatEnum,
  FormatTypes,
  ModeTypes,
  SampleRateTypes,
  VolumeTypes,
} from 'features/inventory/transcodePreset/containers/TranscodePresetContainer/TranscodePresetValues';

import { IFormProps } from 'interfaces';
import {
  CreateTranscodePresetMutation,
  CreateTranscodePresetMutationFn,
  SoundMode,
  TranscodePreset,
  useCreateTranscodePresetMutation,
} from 'interfaces/generated.types';
import Grid from '@material-ui/core/Grid';

import useStyles from './TranscodePresetContainer.styles';

export interface ITranscodePresetValues {
  name: string;
  transcodePresets: TranscodePreset[];
  transcodeFormat: FormatEnum;
  transcodeBitRate: string | number;
  transcodeMode: SoundMode;
  transcodeSampleRate: string | number;
  transcodeVolume: string | number;
  transcodeExtension: ExtensionEnum;
  defaultTranscodePresetId: string;
}

export const handleOnComplete = (
  response: CreateTranscodePresetMutation,
  setFieldValue: (field: string, value: any) => void,
  setFieldError: (field: string, value: any) => void,
  transcodePresets: TranscodePreset[]
) => {
  if (
    !transcodePresets.some(
      (el) => el.id === response?.createTranscodePreset?.id
    )
  ) {
    setFieldValue('transcodePresets', [
      ...transcodePresets,
      response.createTranscodePreset,
    ]);
    setFieldValue('transcodeFormat', FormatEnum.unspecified);
    setFieldValue('transcodeBitRate', '');
    setFieldValue('transcodeMode', SoundMode.ModeUnspecified);
    setFieldValue('transcodeSampleRate', '');
    setFieldValue('transcodeVolume', '');
    setFieldValue('transcodeExtension', '');
  } else {
    setFieldError(
      'transcodePresets',
      'That transcode preset already exists. Please enter one this is not listed below'
    );
  }
};

export const submitTranscodePreset = (
  createTranscodePreset: CreateTranscodePresetMutationFn,
  values: ITranscodePresetValues,
  toggleErrorModal: () => void
) => {
  createTranscodePreset({
    variables: {
      bitRate: values.transcodeBitRate,
      format: values.transcodeFormat,
      mode: values.transcodeMode,
      sampleRate: values.transcodeSampleRate,
      volume: values.transcodeVolume,
      extension: values.transcodeExtension,
    },
  }).catch(() => {
    toggleErrorModal();
  });
};

export const removeTranscodePreset = (
  setFieldValue: (field: string, value: any) => void,
  transcodePresets: TranscodePreset[],
  defaultTranscodePresetId: string,
  selectedPreset: TranscodePreset
) => {
  if (selectedPreset.id === defaultTranscodePresetId)
    setFieldValue('defaultTranscodePresetId', '');

  setFieldValue(
    'transcodePresets',
    transcodePresets.filter((preset) => preset.id !== selectedPreset.id)
  );
};

export const validTranscodePreset = (values: ITranscodePresetValues) => {
  const {
    transcodeFormat,
    transcodeBitRate,
    transcodeMode,
    transcodeSampleRate,
    transcodeVolume,
    transcodeExtension,
  } = values;

  return (
    !!transcodeFormat &&
    !!transcodeBitRate &&
    transcodeMode !== SoundMode.ModeUnspecified &&
    !!transcodeSampleRate &&
    !!transcodeVolume &&
    !!transcodeExtension
  );
};

const TranscodePresetContainer = (props: IFormProps<any>) => {
  const { values, errors, setFieldValue, setFieldError } = props;
  const classes = useStyles();
  const [hasError, setErrorModal] = useState(false);
  const toggleErrorModal = () => setErrorModal((prevState) => !prevState);

  const [createTranscodePreset] = useCreateTranscodePresetMutation({
    onCompleted(data) {
      handleOnComplete(
        data,
        setFieldValue,
        setFieldError,
        values.transcodePresets
      );
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Field
            id="transcodeFormat"
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            inputProps={{
              id: 'transcodeFormat',
            }}
            label="Format"
            name="transcodeFormat"
            data-tc="transcodeFormat"
            fullWidth
          >
            {FormatTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Bit Rate"
            name="transcodeBitRate"
            data-tc="transcodeBitRate"
            data-testid="transcodeBitRate"
            fullWidth
          >
            {BitRateTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Sample Rate"
            name="transcodeSampleRate"
            data-tc="transcodeSampleRate"
            data-testid="transcodeSampleRate"
            fullWidth
          >
            {SampleRateTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Mode"
            name="transcodeMode"
            data-tc="transcodeMode"
            data-testid="transcodeMode"
            fullWidth
          >
            {ModeTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Volume"
            name="transcodeVolume"
            data-tc="transcodeVolume"
            data-testid="transcodeVolume"
            fullWidth
          >
            {VolumeTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Extension"
            name="transcodeExtension"
            data-tc="transcodeExtension"
            fullWidth
          >
            {ExtensionTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <div className={classes.transcodes}>
          {errors.transcodePresets && (
            <div className={classes.error}>
              <FormHelperText error data-tc="transcodePresetErrors">
                {errors.transcodePresets}
              </FormHelperText>
            </div>
          )}
          {values.transcodePresets && (
            <Field
              component={RadioGroup}
              name="defaultTranscodePresetId"
              data-tc="defaultTranscodePresetId"
              row
            >
              {values.transcodePresets.map((preset: TranscodePreset) => (
                <FormControlLabel
                  data-tc="transcodePreset"
                  classes={{ root: classes.radioLabel }}
                  key={preset.id}
                  value={preset.id}
                  control={
                    <Radio
                      classes={{
                        root: classes.radioButton,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={
                    <div className={classes.transcode}>
                      <span className={classes.transcodeAltId}>
                        {preset.altId}
                      </span>
                      <span className={classes.transcodeName}>
                        {preset.name}
                      </span>
                      <StyledButton
                        data-tc="removeTranscodePresetButton"
                        variant={ButtonVariantEnum.Text}
                        onClick={() =>
                          removeTranscodePreset(
                            setFieldValue,
                            values.transcodePresets,
                            values.defaultTranscodePresetId,
                            preset
                          )
                        }
                      >
                        <CloseIcon />
                        <span className="sr-only">
                          Remove {preset.name} from {values.name}
                        </span>
                      </StyledButton>
                    </div>
                  }
                />
              ))}
            </Field>
          )}
        </div>
        <StyledButton
          data-tc="addTranscodePresetButton"
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          disabled={!validTranscodePreset(values)}
          onClick={() =>
            submitTranscodePreset(
              createTranscodePreset,
              values,
              toggleErrorModal
            )
          }
        >
          Add Transcode Preset
        </StyledButton>
      </div>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createChannel"
        errorMessages={[
          "Something went wrong and we couldn't create the transcode preset.",
        ]}
      />
    </>
  );
};

export default TranscodePresetContainer;
