import memoizeOne from 'memoize-one';

import { CurrenciesEnum } from 'utils/currency';
import {
  TechnicalProviderStatus,
  VastVersion,
  DspProvider,
  Territory,
} from 'interfaces/generated.types';

export interface ITechnicalProviderValues {
  altId?: number;
  currency: CurrenciesEnum;
  id: string;
  name: string;
  userSyncSecureUrl: string;
  userSyncUrl: string;
  blockGeoData: boolean;
  truncateGpsCoords: boolean;
  blockIpData: boolean;
  truncateIpAddress: boolean;
  block1stPartyData: boolean;
  kvps1stPartyData: string;
  kvps1stPartyAllData: boolean;
  blockListenerId: boolean;
  gzipCompressed: boolean;
  sendNotSyncedUsers: boolean;
  blockSite: boolean;
  siteName: string;
  siteDomain: string;
  blockApp: boolean;
  appBundle: string;
  appName: string;
  appDomain: string;
  blockPublisher: boolean;
  publisherId: string;
  publisherName: string;
  publisherDomain: string;
  publisherTagId: string;
  consentVendorId: string;
  journey: string;
  status?: TechnicalProviderStatus;
  vastVersions: string[];
  dspProvider: string;
  territories: { value: Territory; label: string }[];
  bidFloorPrecision: string;
}

export const vastVersions = [
  {
    value: VastVersion.Two,
    label: 'VAST 2',
  },
  {
    value: VastVersion.Three,
    label: 'VAST 3',
  },
  {
    value: VastVersion.Four,
    label: 'VAST 4',
  },
];

export const dspProviderValues = [
  {
    label: '',
    value: DspProvider.DspProviderUnspecified,
  },
  {
    label: 'AudioMatic',
    value: DspProvider.Audiomatic,
  },
];

export const technicalProviderStatusValues = [
  {
    value: TechnicalProviderStatus.Draft,
    label: 'Draft',
    isDisabled: (value: TechnicalProviderStatus) =>
      [
        TechnicalProviderStatus.Active,
        TechnicalProviderStatus.Inactive,
      ].includes(value),
  },
  {
    value: TechnicalProviderStatus.Active,
    label: 'Active',
  },
  {
    value: TechnicalProviderStatus.Inactive,
    label: 'Inactive',
  },
];

export const getTechnicalProviderStatusData = memoizeOne(
  (values: ITechnicalProviderValues) => [
    {
      status: TechnicalProviderStatus.Draft,
      required: [...(!values.name ? ["Complete 'Name'"] : [])],
    },
    {
      status:
        values.status === TechnicalProviderStatus.Inactive
          ? TechnicalProviderStatus.Inactive
          : TechnicalProviderStatus.Active,
      required: [...(!values.name ? ['Waiting for Name'] : [])],
    },
  ]
);

export const technicalProviderDetailsFields = [
  'name',
  'consentVendorId',
  'currency',
  'userSyncUrl',
  'userSyncSecureUrl',
];

export const technicalProviderDataControlFields = [
  'kvps1stPartyData',
  'appBundle',
  'publisherId',
  'publisherTagId',
];
