import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreateCampaignContainer from 'features/direct/campaign/containers/CreateCampaignContainer/CreateCampaignContainer';
import { History } from 'history';
import {
  Order,
  PermissionName,
  Tag,
  useAllOrdersMinimalQuery,
  useAllTagsQuery,
  useAllUsersQuery,
  useOrderMinimalQuery,
  User,
} from 'interfaces/generated.types';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { allTerritories } from 'utils/territory';

interface ICreateCampaignPage {
  history: History;
  match: {
    params: {
      orderId: string;
    };
  };
  currentUser: User;
}

const CreateCampaignPage = ({
  history,
  match,
  currentUser,
}: ICreateCampaignPage) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const orderIdExists = !isUndefined(match.params.orderId);
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useOrderMinimalQuery({
    variables: { id: match.params.orderId },
    skip: !orderIdExists,
  });

  const {
    loading: allOrdersLoading,
    error: allOrdersError,
    data: allOrdersData,
  } = useAllOrdersMinimalQuery({
    variables: { territories: [activeTerritory!] },
    skip: orderIdExists,
  });

  const { loading: loadingAllTags, data: allTagsData } = useAllTagsQuery();

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Direct, PermissionName.Primary],
      territories: allTerritories,
    },
  });

  const isLoading =
    orderLoading || allOrdersLoading || loadingAllTags || loadingAllUsers;

  if (
    !isLoading &&
    ((orderIdExists && (orderError || !(orderData && orderData.order))) ||
      (!orderIdExists &&
        (allOrdersError || !(allOrdersData && allOrdersData.allOrders))))
  )
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Campaign</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <CreateCampaignContainer
            history={history}
            match={match}
            orders={
              orderIdExists
                ? (orderData!.order as Order)
                : (allOrdersData!.allOrders as Order[])
            }
            allTags={(allTagsData?.allTags as Tag[]) || []}
            allUsers={(allUsersData?.allUsers as User[]) || []}
            currentUser={currentUser}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateCampaignPage;
