import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@material-ui/core';

import useStyles from './Select.styles';

export type SelectProps = MUISelectProps;

const Select = React.forwardRef(
  (props: SelectProps, ref: React.Ref<HTMLSelectElement>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return (
      <MUISelect
        aria-label="Select"
        classes={classes}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

Select.displayName = 'Select';

export default Select;
