import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 0.5),
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '175px',
    height: '30px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px 0px 0px 4px',
    borderRight: 'none',
  },
  label: {
    padding: theme.spacing(0, 1.25),
  },
  input: {
    borderRadius: '0px 4px 4px 0px',
  },
  selectContainer: {
    flex: 1,
    minWidth: '300px',
  },
}));

export default useStyles;
