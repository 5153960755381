import { Territory } from 'interfaces/generated.types';
import React from 'react';
import { getTerritoryIcon, TerritoryLabelShort } from 'utils/territory';

import { Checkbox } from '@material-ui/core';

import useStyles from './TerritoryDropdown.styles';

export interface ITerritoryDropdownOptionProps {
  territory: Territory;
  isCheckboxChecked?: boolean;
  showCheckbox?: boolean;
}

const TerritoryDropdownOption = ({
  territory,
  isCheckboxChecked = false,
  showCheckbox = false,
}: ITerritoryDropdownOptionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.dropdownContent}>
      {showCheckbox && (
        <Checkbox
          color="primary"
          checked={isCheckboxChecked}
          value={territory}
          inputProps={{
            'aria-label': TerritoryLabelShort[territory],
          }}
          name={TerritoryLabelShort[territory]}
        />
      )}
      <span className={classes.dropdownIcon}>
        {getTerritoryIcon(territory)}
      </span>
      <span className={classes.dropdownLabel}>
        {TerritoryLabelShort[territory]}
      </span>
    </div>
  );
};

export default TerritoryDropdownOption;
