import * as Yup from 'yup';

const DaxLoginFormValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Please enter an email')
    .email('Must be valid email')
    .test(
      'email',
      'Please enter a valid email',
      (email?: string) => !!(email && !email.endsWith('@global.com'))
    ),
});

export default DaxLoginFormValidation;
