import { PrefixTypes } from 'components/NumberInput/NumberInput';

export enum CurrenciesEnum {
  GBP = 'GBP',
  USD = 'USD',
  CAD = 'CAD',
}

export enum CurrencyLabel {
  GBP = 'GBP (£)',
  USD = 'USD ($)',
  CAD = 'CAD ($)',
}

export const defaultCurrencyLabelBasedOnCurrency = {
  [CurrenciesEnum.GBP]: CurrencyLabel.GBP,
  [CurrenciesEnum.USD]: CurrencyLabel.USD,
  [CurrenciesEnum.CAD]: CurrencyLabel.CAD,
};

export const getDefaultCurrencyLabelBasedOnCurrency = (
  currency: CurrenciesEnum
) => defaultCurrencyLabelBasedOnCurrency[currency];

export const currencyValues = [
  {
    value: CurrenciesEnum.GBP,
    label: CurrencyLabel.GBP,
  },
  {
    value: CurrenciesEnum.USD,
    label: CurrencyLabel.USD,
  },
  {
    value: CurrenciesEnum.CAD,
    label: CurrencyLabel.CAD,
  },
];

export const allCurrencies = Object.values(CurrenciesEnum);

export const prefixBySelectedCurrency = {
  [CurrenciesEnum.GBP]: PrefixTypes.pound,
  [CurrenciesEnum.USD]: PrefixTypes.dollar,
  [CurrenciesEnum.CAD]: PrefixTypes.dollar,
};

export const getPrefixBySelectedCurrency = (currency: CurrenciesEnum) =>
  prefixBySelectedCurrency[currency];

export default { getPrefixBySelectedCurrency };
