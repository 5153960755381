import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import GradingSelect from 'features/targetingV2/components/Grading/GradingSelect/GradingSelect';
import ParameterRow from 'features/targetingV2/components/ParameterRow/ParameterRow';
import { SectionType } from 'features/targetingV2/types/common';
import {
  IAudienceParameter,
  IAudienceParameterGroup,
} from 'features/targetingV2/types/targeting';
import {
  getGroupCreationOptionDetails,
  getSectionTitle,
} from 'features/targetingV2/utils/common';
import {
  defaultAudienceParameter,
  getDefaultEnabledGrades,
} from 'features/targetingV2/utils/defaults';
import { enrichAudienceParameterValues } from 'features/targetingV2/utils/parameterValues';
import { getAvailableParameterOptionsBasedOnCardinality } from 'features/targetingV2/utils/targeting';
import { FieldArray } from 'formik';
import {
  AudienceParameterInfo,
  TargetingGrade,
} from 'interfaces/generated.types';
import React from 'react';

export interface IAudienceProps {
  audience: IAudienceParameterGroup;
  audienceFieldName: string;
  audienceIndex?: number;
  allParametersInfo: AudienceParameterInfo[];
  areParameterOptionsLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  addAudience?: (parameterType: string) => void;
  removeAudience?: (audienceIndex: number) => void;
  emptyParameter?: any;
  disableAddParameterButton?: boolean;
  hideCreateGroupButton?: boolean;
  sectionType?: SectionType;
  hasAccuracy?: boolean;
}

const Audience = (props: IAudienceProps) => {
  const {
    audience,
    audienceFieldName,
    audienceIndex = 0,
    allParametersInfo,
    areParameterOptionsLoading,
    setFieldValue,
    addAudience,
    removeAudience,
    emptyParameter = defaultAudienceParameter,
    disableAddParameterButton = false,
    hideCreateGroupButton = false,
    sectionType = SectionType.Audience,
    hasAccuracy = false,
  } = props;
  const handleAudienceParameterDeletion = (
    parameterIndex: number,
    remove: (index: number) => void
  ) => {
    if (audience.parameters.length === 1 && removeAudience) {
      removeAudience(audienceIndex);
    } else remove(parameterIndex);
  };

  const onParameterChange = (parameterIndex: number, parameterType: string) => {
    const newGrades = getDefaultEnabledGrades(parameterType);

    const parameterField = `${audienceFieldName}.parameters[${parameterIndex}]`;

    setFieldValue(`${parameterField}.clusivity`, emptyParameter.clusivity);
    setFieldValue(`${parameterField}.values`, emptyParameter.values);
    setFieldValue(`${parameterField}.enabledGrades`, newGrades);
    setFieldValue(`${parameterField}.isFocused`, true);
  };

  const getAccuracySelectComponent = (
    parameterIndex: number,
    parameterEnabledGrades: TargetingGrade[]
  ) => {
    const enabledGradesFieldName = `${audienceFieldName}.parameters[${parameterIndex}].enabledGrades`;

    const handleParameterGradesChange = (value: TargetingGrade[]) => {
      setFieldValue(enabledGradesFieldName, value);
    };

    return (
      <GradingSelect
        fieldName={enabledGradesFieldName}
        onChange={handleParameterGradesChange}
        enabledGrades={parameterEnabledGrades}
      />
    );
  };

  const getAvailableParametersOptions = (parameterType?: string) =>
    getAvailableParameterOptionsBasedOnCardinality(
      allParametersInfo,
      audience.parameters,
      parameterType
    );

  const availableParameterOptions = getAvailableParametersOptions();

  const groupCreationOptionDetails =
    !hideCreateGroupButton && addAudience
      ? {
          label: 'Create New Group',
          ...getGroupCreationOptionDetails(
            allParametersInfo,
            getSectionTitle(sectionType)
          ),
          onOptionClick: addAudience,
        }
      : undefined;

  return (
    <div data-testid={audienceFieldName}>
      <FieldArray name={`${audienceFieldName}.parameters`}>
        {({ push: pushParameter, remove: removeParameter }) => (
          <>
            {audience.parameters.map(
              (parameter: IAudienceParameter, parameterIndex: number) => (
                <ParameterRow
                  {...props}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`audience${audienceIndex}-parameter${parameterIndex}`}
                  parameterFieldName={`${audienceFieldName}.parameters[${parameterIndex}]`}
                  selectedParameter={
                    hasAccuracy
                      ? enrichAudienceParameterValues(
                          parameter,
                          allParametersInfo
                        )
                      : parameter
                  }
                  parameterTypeOptions={getAvailableParametersOptions(
                    parameter.type
                  )}
                  areParameterTypeOptionsLoading={areParameterOptionsLoading}
                  removeParameter={() =>
                    handleAudienceParameterDeletion(
                      parameterIndex,
                      removeParameter
                    )
                  }
                  sectionType={sectionType}
                  onParameterTypeChange={(parameterType: string) =>
                    onParameterChange(parameterIndex, parameterType)
                  }
                  accuracySelect={
                    hasAccuracy &&
                    getAccuracySelectComponent(
                      parameterIndex,
                      parameter.enabledGrades
                    )
                  }
                  parameterRowId={`${sectionType} ${audienceIndex + 1} ${
                    parameter.type
                  } Parameter Row ${parameterIndex + 1}`}
                />
              )
            )}
            {audience.parameters.length && availableParameterOptions.length ? (
              <AddParameterDropdownButton
                options={availableParameterOptions}
                onOptionClick={(parameterType: string) =>
                  pushParameter({
                    ...emptyParameter,
                    type: parameterType,
                    ...(hasAccuracy
                      ? {
                          enabledGrades: getDefaultEnabledGrades(parameterType),
                        }
                      : []),
                    isFocused: true,
                  })
                }
                groupCreationOptionDetails={groupCreationOptionDetails}
                disabled={disableAddParameterButton}
                loading={areParameterOptionsLoading}
              />
            ) : null}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Audience;
