import { format, isValid } from 'date-fns';
import targetingV2 from 'features/targetingV2/validations/targetingV2';
import { AdStatus, Territory, TrackingUrl } from 'interfaces/generated.types';
import dateUtils, { TimeZones } from 'utils/date';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';
import { parseFormattedValue } from 'utils/numbers';
import commons from 'validations/commons';
import * as Yup from 'yup';

const requiredAdStatusForLandingPageUrl = [
  AdStatus.Ready,
  AdStatus.Live,
  AdStatus.Completed,
];

const AdsFormValidation = (
  campaignStartDate: Date | null,
  campaignEndDate: Date | null,
  timeZone: TimeZones,
  dailyCap: number | null,
  territory: Territory,
  adStatus?: AdStatus
) =>
  Yup.object().shape(
    {
      name: commons.name('Ad Name'),
      creativeRedirect: Yup.object().shape({
        url: commons
          .validUrl({
            message: 'Please enter a valid url',
            requiredProtocol: true,
            fieldKey: 'creativeRedirect',
          })
          .nullable()
          .when('consentVendorId', {
            is: (consentVendorId: string) => !!consentVendorId,
            then: Yup.string().required('Redirect URL is a required field'),
          })
          .when('trackingUrls', {
            is: (trackingUrls?: TrackingUrl[]) =>
              !!trackingUrls &&
              trackingUrls.some((trackingUrl) => !!trackingUrl.url),
            then: Yup.string().required('Redirect URL is a required field'),
          }),
        consentVendorId: Yup.string()
          .nullable()
          .test(
            'consentVendorId',
            'Consent Vendor ID must be lower than 2,147,483,647',
            (value?: string) => {
              if (!value) {
                return true;
              }
              const numberValue = parseFormattedValue(value);
              return numberValue <= 2147483647;
            }
          ),
        trackingUrls: Yup.array().of(
          Yup.object()
            .shape({
              url: commons
                .validUrl({
                  message: 'Please enter a valid url',
                  fieldKey: 'url',
                  protocols: ['https'],
                })
                .nullable()
                .when('consentVendorId', {
                  is: (consentVendorId: string) => !!consentVendorId,
                  then: Yup.string().required(
                    'Tracking URL is a required field'
                  ),
                }),
              consentVendorId: Yup.string()
                .nullable()
                .test(
                  'consentVendorId',
                  'Consent Vendor ID must be lower than 2,147,483,647',
                  (value?: string) => {
                    if (!value) {
                      return true;
                    }
                    const numberValue = parseFormattedValue(value);
                    return numberValue <= 2147483647;
                  }
                ),
            })
            .nullable()
        ),
      }),
      cpm: commons.optionalPrice({
        messages: {
          minMessage: 'Ad Bid Price should be higher than or equal to 0',
          maxMessage:
            'Ad Bid Price should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'cpm',
      }),
      ...commons.getFrequencyCapFields('Ad Frequency Cap'),
      dailyCap: Yup.string()
        .test(
          'dailyCap',
          'Ad Daily Impression Cap should be higher than or equal to 1',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue >= 1;
          }
        )
        .test(
          'dailyCap',
          'Ad Daily Impression Cap should be lower than or equal to 1,000,000,000',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue <= 1000000000;
          }
        )
        .test(
          'dailyCap',
          `Ad Daily Impression Cap should be lower than Campaign Daily Impression Cap (${dailyCap})`,
          (value: string) => {
            if (value && dailyCap) {
              return parseFormattedValue(value) <= dailyCap;
            }
            return true;
          }
        ),
      weighting: Yup.number()
        .nullable()
        .default(null)
        .integer('Ad Weight should be a whole value')
        .test(
          'weighting',
          'Ad Weight should be between 1 and 10',
          (weighting: number | null) => {
            if (weighting === null) {
              return true;
            }
            return weighting >= 1 && weighting <= 10;
          }
        ),
      startDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Ad Start Date should be a valid date')
        .when('endDate', {
          is: (endDate: Date) => !!endDate,
          then: Yup.date().required(
            'Start date is required when an end date is added'
          ),
        })
        .test(
          'startDate',
          `${
            campaignStartDate &&
            `Ad Start Date should be after Campaign Start Date (${format(
              campaignStartDate,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`
          }`,
          function adStartDateAfterCampaignStartDate(startDate: Date) {
            if (!isValid(startDate)) {
              return true;
            }
            if (campaignStartDate) {
              return dateUtils.isDateEqualOrAfterTheOther({
                date: startDate,
                dateToCompare: campaignStartDate,
                timeZone,
              });
            }
            return true;
          }
        )
        .test(
          'startDate',
          `${
            campaignEndDate &&
            `Ad Start Date should not be after the Campaign End Date (${format(
              campaignEndDate,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`
          }`,
          function adStartDateAfterCampaignEndDate(startDate: Date) {
            if (!isValid(startDate)) {
              return true;
            }
            if (campaignEndDate) {
              return !dateUtils.isDateAfterTheOther({
                date: startDate,
                dateToCompare: campaignEndDate,
                timeZone,
              });
            }
            return true;
          }
        ),
      endDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Ad End Date should be a valid date')
        .test(
          'endDate',
          `${
            campaignStartDate &&
            `Ad End Date should not be before the Campaign Start Date (${format(
              campaignStartDate,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`
          }`,
          function adEndDateBeforeCampaignStartDate(endDate: Date) {
            if (!isValid(endDate)) {
              return true;
            }
            if (campaignStartDate) {
              return !dateUtils.isDateBeforeTheOther({
                date: endDate,
                dateToCompare: campaignStartDate,
                timeZone,
              });
            }
            return true;
          }
        )
        .test(
          'endDate',
          `${
            campaignEndDate &&
            `Ad End Date should be before Campaign or Order End Date (${format(
              campaignEndDate,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`
          }`,
          function adEndDateAfterCampaignEndDate(endDate: Date) {
            if (!isValid(endDate)) {
              return true;
            }
            if (campaignEndDate) {
              return dateUtils.isDateEqualOrBeforeTheOther({
                date: endDate,
                dateToCompare: campaignEndDate,
                timeZone,
              });
            }
            return true;
          }
        )
        .when('startDate', (startDate: Date | null, schema: Yup.DateSchema) => {
          if (!isValid(startDate)) {
            return schema;
          }
          return schema.test(
            'startDate',
            `Ad End Date should be after (${format(
              startDate as Date,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`,
            (endDate: Date) => {
              if (!isValid(endDate)) {
                return true;
              }
              return dateUtils.isDateAfterTheOther({
                date: endDate,
                dateToCompare: startDate as Date,
                timeZone,
              });
            }
          );
        })
        .test(
          'endDate',
          'Ad End Date should not be in the past',
          (endDate: Date | null) => {
            if (!endDate || !isValid(endDate)) {
              return true;
            }
            return dateUtils.isDateInTheFuture(endDate, timeZone);
          }
        ),
      targetingMasterTemplates: Yup.array().of(
        Yup.object().shape({
          groups: Yup.array().of(
            Yup.object().shape(
              {
                audienceParams: commons.audienceParams('Listener parameters'),
                channels: Yup.array()
                  .of(Yup.string())
                  .when(['audienceParams'], {
                    is: (audienceParams: any) =>
                      audienceParams && audienceParams.length > 0,
                    then: Yup.array().required(
                      "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
                    ),
                  })
                  .nullable(),
              },
              ['audienceParams', 'channels'] as any
            )
          ),
        })
      ),
      targetingGroups: Yup.array().of(
        Yup.object().shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
            channels: Yup.array()
              .of(Yup.string())
              .when(['audienceParams'], {
                is: (audienceParams: any) =>
                  audienceParams && audienceParams.length > 0,
                then: Yup.array().required(
                  "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
                ),
              })
              .nullable(),
          },
          ['audienceParams', 'channels'] as any
        )
      ),
      targetingGeneralGroup: Yup.object()
        .shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
          },
          ['audienceParams'] as any
        )
        .nullable(),
      imageCreatives: Yup.array()
        .nullable()
        .of(
          Yup.object()
            .shape({
              landingPageUrl: commons
                .validUrl({
                  message: 'Please enter a valid url',
                  fieldKey: 'landingPageUrl',
                })
                .test(
                  'landingPageUrl',
                  'Click-through URL is a required field',
                  (value?: string) => {
                    if (
                      adStatus &&
                      requiredAdStatusForLandingPageUrl.includes(adStatus)
                    ) {
                      return !!value;
                    }

                    return true;
                  }
                ),
              trackingUrls: Yup.array().of(
                Yup.object()
                  .shape({
                    url: commons
                      .validUrl({
                        message: 'Please enter a valid url',
                        fieldKey: 'url',
                        protocols: ['https'],
                      })
                      .nullable()
                      .when('consentVendorId', {
                        is: (consentVendorId: string) => !!consentVendorId,
                        then: Yup.string().required(
                          'Tracking URL is a required field'
                        ),
                      }),
                    consentVendorId: Yup.string()
                      .nullable()
                      .test(
                        'consentVendorId',
                        'Consent Vendor ID must be lower than 2,147,483,647',
                        (value?: string) => {
                          if (!value) {
                            return true;
                          }
                          const numberValue = parseFormattedValue(value);
                          return numberValue <= 2147483647;
                        }
                      ),
                  })
                  .nullable()
              ),
            })
            .nullable()
        ),
      audioCreatives: Yup.array().of(
        Yup.object().shape({
          duration: Yup.number().max(
            300,
            'Audio creative cannot be longer than 300 seconds'
          ),
          trackingUrls: Yup.array().of(
            Yup.object()
              .shape({
                url: commons
                  .validUrl({
                    message: 'Please enter a valid url',
                    fieldKey: 'url',
                    protocols: ['https'],
                  })
                  .nullable()
                  .when('consentVendorId', {
                    is: (consentVendorId: string) => !!consentVendorId,
                    then: Yup.string().required(
                      'Tracking URL is a required field'
                    ),
                  }),
                consentVendorId: Yup.string()
                  .nullable()
                  .test(
                    'consentVendorId',
                    'Consent Vendor ID must be lower than 2,147,483,647',
                    (value?: string) => {
                      if (!value) {
                        return true;
                      }
                      const numberValue = parseFormattedValue(value);
                      return numberValue <= 2147483647;
                    }
                  ),
              })
              .nullable()
          ),
        })
      ),
      duration: commons.optionalPrice({
        messages: {
          minMessage: 'Minimum duration is 0.001 seconds',
          maxMessage: 'Maximum duration is 300 seconds',
        },
        minimum: 0.001,
        maximum: 300,
        fieldKey: 'duration',
      }),
      targetingDefinitionV2: Yup.object().shape({
        customTargeting: targetingV2.customTargeting,
      }),
    },
    [
      ['frequencyCapCount', 'frequencyCapValue'],
      ['frequencyCapCount', 'frequencyCapTimeUnit'],
      ['frequencyCapValue', 'frequencyCapTimeUnit'],
      ['startDate', 'endDate'],
    ]
  );

export default AdsFormValidation;
