import { keyValueValidation } from 'validations/commons';
import * as Yup from 'yup';

const targetingV2 = {
  customTargeting: Yup.object().shape({
    customGroups: Yup.array().of(
      Yup.object().shape({
        parameters: Yup.array().of(
          Yup.object().shape({
            customKvps: Yup.array().of(
              Yup.object().shape({
                keyValuePair: keyValueValidation('key'),
              })
            ),
          })
        ),
      })
    ),
  }),
};

export default targetingV2;
