import React from 'react';
import { ColumnInstance, HeaderGroup } from 'react-table';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { useHeaderStyles } from './Table.styles';

interface ITableHeader {
  headerGroups: HeaderGroup<object>[];
  handleSort?: (column: ColumnInstance) => void;
  tabTable?: boolean;
  entityType?: {};
}

const TableHeader = ({
  headerGroups,
  handleSort,
  entityType,
  tabTable = false,
}: ITableHeader) => {
  const classes = useHeaderStyles();

  return (
    <TableHead className={classes.root}>
      {headerGroups.map((headerGroup: HeaderGroup<object>, index: number) => (
        /* eslint-disable react/no-array-index-key */
        <React.Fragment key={index}>
          <TableRow {...headerGroup.getHeaderGroupProps()} key={1}>
            {headerGroup.headers.map((column: ColumnInstance<object>) => (
              /* eslint-disable react/jsx-key */
              <TableCell
                {...column.getHeaderProps()}
                className={classNames([
                  classes.cell,
                  tabTable && classes.tabTableCell,
                ])}
                onClick={handleSort ? () => handleSort(column) : undefined}
              >
                <div {...column.getSortByToggleProps()}>
                  <TableSortLabel
                    disabled={!column.canSort}
                    active={column.isSorted}
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                    hideSortIcon
                  >
                    {column.render('Header')}
                  </TableSortLabel>
                </div>
              </TableCell>
            ))}
          </TableRow>
          <TableRow {...headerGroup.getHeaderGroupProps()} key={2}>
            {headerGroup.headers.map((column: ColumnInstance<object>) => (
              /* eslint-disable react/jsx-key */
              <TableCell
                {...column.getHeaderProps()}
                className={classes.filter}
              >
                {column.canFilter ? (
                  column.render('Filter', { entityType })
                ) : (
                  <>&nbsp;</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </React.Fragment>
      ))}
    </TableHead>
  );
};

export default TableHeader;
