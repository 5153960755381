import React from 'react';
import { IDealFormValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import { GENERATE_UNIQUE_EXTERNAL_ID } from 'features/programmatic/seat/graphql/queries';
import { Dsp, DspIncludedSeats } from 'interfaces/generated.types';
import { createSelectOptions } from './dataTransformation';

export const findDspInAllDsps = (allDsps: Dsp[], currentDspId: string) =>
  allDsps.find((dsp: Dsp) => dsp.id === currentDspId);

export const generateSeatOptions = (allDsps: Dsp[], dspValue: any) => {
  const dspObject = findDspInAllDsps(allDsps, dspValue.value);
  const dspsSeats = dspObject?.seats || [];
  return createSelectOptions(dspsSeats);
};

export const handlePlaceHolder = (
  allKnownSeats: boolean,
  allSeats: boolean
): string => {
  if (allSeats) {
    return 'All seats selected';
  }
  if (allKnownSeats) {
    return 'All known seats selected';
  }
  return 'Select a Seat...';
};

export const setDspIncludedSeats = (values: IDealFormValues) => {
  if (values.allSeats) {
    return DspIncludedSeats.AllSeats;
  }
  if (values.allKnownSeats) {
    return DspIncludedSeats.AllKnownSeats;
  }
  if (values.dsp && values.seats.length > 0) {
    return DspIncludedSeats.SpecifiedSeats;
  }
  return DspIncludedSeats.DspNotSet;
};

export const handleGenerateId = async (client: any, setFieldValue: any) => {
  const { data } = await client.query({
    query: GENERATE_UNIQUE_EXTERNAL_ID,
    fetchPolicy: 'network-only',
  });
  setFieldValue(
    'externalId',
    data && data.generateUniqueExternalId ? data.generateUniqueExternalId : ''
  );
};

export const handleCheckboxes = (
  userSelectedDsp: Dsp | undefined,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setIsAllSeatsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (
    userSelectedDsp?.technicalProvider?.openSeatDsp?.id === userSelectedDsp?.id
  ) {
    setFieldValue('allSeats', true);
    setFieldValue('seats', []);
    setIsAllSeatsDisabled(false);
  } else if (
    userSelectedDsp?.technicalProvider?.openSeatDsp &&
    userSelectedDsp?.technicalProvider?.openSeatDsp?.id !== userSelectedDsp?.id
  ) {
    setFieldValue('allSeats', false);
    setIsAllSeatsDisabled(true);
  } else {
    setFieldValue('allSeats', false);
    setIsAllSeatsDisabled(false);
  }
};

export const handleDspSelection = (
  allDsps: Dsp[],
  fieldValue: {
    value: string;
    label: string;
  } | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setIsAllSeatsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (fieldValue !== null) {
    const userSelectedDspId = fieldValue.value;
    const userSelectedDsp = findDspInAllDsps(allDsps, userSelectedDspId);
    handleCheckboxes(userSelectedDsp, setFieldValue, setIsAllSeatsDisabled);
  } else {
    setFieldValue('allSeats', false);
    setIsAllSeatsDisabled(true);
    setFieldValue('allKnownSeats', false);
  }
};
