import React from 'react';

import Postcode from 'components/Postcode/Postcode';

import { TargetingPackTypeEnum } from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import TargetingPackSelector from 'features/targeting/components/TargetingPackSelector/TargetingPackSelector';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const postcodeValues = {
  postalCodeParams: {
    postalCodes: [],
    groups: [],
  },
};

const TargetingPostcodes = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const { targetingGroupName, templateIndex, groupIndex, index } = props;

  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <>
      <Postcode
        {...props}
        name={`${targetingPath}.audienceParams.${index}.postalCodeParams.postalCodes`}
        placeholder="Begin typing to find a Post/Zipcode"
        label="Postcode"
        dataTc="targetingPostcode"
      />
      <TargetingPackSelector
        {...props}
        name={`${targetingPath}.audienceParams.${index}.postalCodeParams.postcodeGroups`}
        type={TargetingPackTypeEnum.POSTCODE}
        label="Postcode Packs"
        errorMessage="There was an error fetching postcode packs. Please try again later"
      />
    </>
  );
};

export default TargetingPostcodes;
