import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  Table as MUITable,
  TableProps as MUITableProps,
} from '@material-ui/core';

import useStyles from './Table.styles';

export type TableProps = MUITableProps;

const Table = React.forwardRef((props: TableProps, ref: React.Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUITable ref={ref} classes={classes} {...otherProps} />;
});

Table.displayName = 'Table';

export default Table;
