import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  EntityType,
  useAuditTrailQuery,
  UserStatus,
} from 'interfaces/generated.types';
import React, { useMemo } from 'react';
import { findLabelValue } from 'utils/dataTransformation';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

import { auditChangeValues } from './ActivityTableValues';

interface IHistoryTable {
  entityType: EntityType;
  id: string;
  'data-tc': string;
}

export const getUserEmail = (
  changedBy: { email: string; status: UserStatus },
  changedByAssumed: { email: string }
) => {
  if (changedBy?.email) {
    const userEmail = `${changedBy.email}${
      changedBy.status === UserStatus.Inactive ? '(inactive)' : ''
    }`;
    return changedByAssumed.email !== changedBy?.email
      ? `${userEmail} (assumed user: ${changedByAssumed.email})`
      : userEmail;
  }
  return 'SYSTEM';
};

const ActivityTable = ({
  entityType,
  id,
  'data-tc': dataTc,
}: IHistoryTable) => {
  const { loading, data } = useAuditTrailQuery({
    variables: {
      filter: {
        entity: {
          id,
          type: entityType,
        },
      },
    },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Change',
        id: 'changeType',
        accessor: ({ changeType }: any) =>
          findLabelValue({
            collection: auditChangeValues,
            lookupValue: changeType,
          }),
      },
      {
        Header: 'User',
        accessor: ({ changedBy, changedByAssumed }: any) =>
          getUserEmail(changedBy, changedByAssumed),
      },
      {
        Header: 'Date',
        id: 'changedAt',
        accessor: ({ changedAt }: any) =>
          getFormattedDateBasedOnTerritory(
            new Date(changedAt),
            activeTerritory,
            true
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) return <Loader />;

  return (
    <Table
      columns={columns}
      data={data?.auditTrail || []}
      dataTc={dataTc}
      isPageTable={false}
    />
  );
};

export default ActivityTable;
