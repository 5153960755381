import CookieName from 'components/CookieConsent/CookieName/CookieName';
import React, { ChangeEvent, ReactNode } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import useStyles from './CookieOption.styles';

export interface ICookieOptionProps {
  label: string;
  Icon: ReactNode;
  showCheckboxes: boolean;
  checked: boolean;
  disabled?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  cookieDescription?: string;
}

const CookieOption = ({
  label,
  Icon,
  showCheckboxes,
  checked,
  disabled = false,
  handleChange,
  cookieDescription,
}: ICookieOptionProps) => {
  const classes = useStyles();

  return showCheckboxes ? (
    <div className={classes.choiceContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            classes={{ root: classes.checkbox }}
            onChange={handleChange}
            color="primary"
          />
        }
        label={<Typography color="textPrimary">{label}</Typography>}
      />
      {cookieDescription && (
        <Typography variant="caption" className={classes.descriptionText}>
          {cookieDescription}
        </Typography>
      )}
    </div>
  ) : (
    <CookieName label={label} Icon={Icon} />
  );
};

export default CookieOption;
