import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import OrdersTable from 'features/direct/order/components/OrdersTable/OrdersTable';
import { History } from 'history';
import {
  Order,
  PermissionName,
  useAllOrdersQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface IOrderPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const OrdersPage = ({ history, userPermissions }: IOrderPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllOrdersQuery({
    errorPolicy: 'all',
    variables: {
      territories: [activeTerritory!],
    },
  });

  const classes = pageStyles();

  if (!loading && error && !(data && data.allOrders))
    return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Orders</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <OrdersTable
              history={history}
              isEditable={hasEditPermissions}
              allOrders={data?.allOrders as Order[]}
            />
          )}
        </div>
      </Container>
    </main>
  );
};

export default OrdersPage;
