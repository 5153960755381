import colors from 'assets/styles/colors';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: colors.primaryBlue,
    padding: 0,
  },
}));

export default useStyles;
