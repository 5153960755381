import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';

import CreateDatacenterContainer from 'features/programmatic/datacenter/containers/CreateDatacenterContainer/CreateDatacenterContainer';

import {
  TechnicalProvider,
  useTechnicalProviderMinimalQuery,
} from 'interfaces/generated.types';

interface ICreateDatacenterPage {
  history: History;
  match: {
    params: {
      technicalProviderId: string;
    };
  };
}

const CreateDatacenterPage = ({ history, match }: ICreateDatacenterPage) => {
  const { loading, error, data } = useTechnicalProviderMinimalQuery({
    variables: { id: match.params.technicalProviderId },
  });

  if (!loading && (error || !(data && data.technicalProvider)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Datacenter</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreateDatacenterContainer
            history={history}
            match={match}
            technicalProvider={data?.technicalProvider as TechnicalProvider}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateDatacenterPage;
