import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import CreateDealContainer from 'features/programmatic/deal/containers/CreateDealContainer/CreateDealContainer';
import { History } from 'history';
import {
  PermissionName,
  SalesChannel,
  useAllUsersQuery,
  User,
  useSalesChannelMinimalQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { allTerritories } from 'utils/territory';

interface ICreateDealPage {
  history: History;
  match: {
    params: {
      salesChannelId: string;
    };
  };
}

const CreateDealPage = ({ history, match }: ICreateDealPage) => {
  const { loading, error, data } = useSalesChannelMinimalQuery({
    variables: { id: match.params.salesChannelId },
  });
  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Programmatic, PermissionName.Primary],
      territories: allTerritories,
    },
  });

  const isLoading = loading || loadingAllUsers;

  if (!isLoading && (error || !(data && data.salesChannel)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Deal</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <CreateDealContainer
            history={history}
            match={match}
            salesChannel={data?.salesChannel as SalesChannel}
            allUsers={(allUsersData?.allUsers as User[]) || []}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateDealPage;
