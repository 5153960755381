import { ReactComponent as ClearIcon } from 'assets/icons/TargetingV2/dax-cross.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/TargetingV2/dax-uploadIcon.svg';
import ClusivitySelect from 'features/targetingV2/components/ClusivitySelect/ClusivitySelect';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import ParameterSelect from 'features/targetingV2/components/ParameterSelect/ParameterSelect';
import { IOptionType, SectionType } from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import {
  getParameterLabel,
  handleFileUploadNativeClick,
  hasBulkUpload,
  hasParameterChanged,
  resetFileInputValue,
} from 'features/targetingV2/utils/common';
import { getParameterValuesComponent } from 'features/targetingV2/utils/parameterValuesComponentSelector';
import { Territory } from 'interfaces/generated.types';
import React, { useRef } from 'react';

import useStyles from './ParameterRow.styles';

export interface IParameterRowProps {
  parameterRowId: string;
  sectionType: SectionType;
  parameterFieldName: string;
  selectedParameter: IParameter;
  parameterTypeOptions: IOptionType[];
  areParameterTypeOptionsLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  removeParameter: () => void;
  onParameterTypeChange: (parameterType: string) => void;
  parameterValuesDropdownPlaceholder?: string;
  idUsedToLimitParameterValues?: string;
  territory?: Territory;
  isParameterMandatory?: boolean;
  accuracySelect?: React.ReactNode;
}

const ParameterRow = (props: IParameterRowProps) => {
  const {
    parameterRowId,
    sectionType,
    parameterFieldName,
    selectedParameter,
    parameterTypeOptions,
    areParameterTypeOptionsLoading,
    setFieldValue,
    removeParameter,
    onParameterTypeChange,
    parameterValuesDropdownPlaceholder = '',
    idUsedToLimitParameterValues = '',
    territory,
    isParameterMandatory = false,
    accuracySelect,
  } = props;
  const classes = useStyles();

  const externalUploadInputRef = useRef<HTMLInputElement | null>(null);

  const openFileUpload = handleFileUploadNativeClick(externalUploadInputRef);

  const removeOption = {
    MenuItemIcon: <RemoveIcon />,
    menuItemIconLabel: 'Remove',
    menuItemAction: removeParameter,
  };

  const bulkUploadOption = {
    MenuItemIcon: <UploadIcon />,
    menuItemIconLabel: 'Bulk Upload',
    menuItemAction: openFileUpload,
  };

  const clearOption = {
    MenuItemIcon: <ClearIcon />,
    menuItemIconLabel: 'Clear',
    menuItemAction: () => {
      hasBulkUpload(selectedParameter.type) &&
        resetFileInputValue(externalUploadInputRef);
      onParameterTypeChange(selectedParameter.type);
    },
  };

  const contextMenuOptions = [
    ...(hasBulkUpload(selectedParameter.type) ? [bulkUploadOption] : []),
    ...(hasParameterChanged(selectedParameter) ? [clearOption] : []),
    ...(isParameterMandatory ? [] : [removeOption]),
  ];

  const allValuesComponentProps = {
    sectionType,
    selectedParameter,
    selectedParameterLabel: getParameterLabel(
      parameterTypeOptions,
      selectedParameter
    ),
    fieldName: parameterFieldName,
    setFieldValue,
    placeholder: parameterValuesDropdownPlaceholder,
    classes: accuracySelect ? { input: classes.customAccuracyInput } : {},
    externalUploadInputRef,
    onClick: openFileUpload,
    idUsedToLimitParameterValues,
    territory,
  };

  const parameterValuesComponent = getParameterValuesComponent(
    selectedParameter.type,
    allValuesComponentProps
  );

  return (
    <div className={classes.container} data-testid={parameterFieldName}>
      <ParameterSelect
        sectionType={sectionType}
        options={parameterTypeOptions}
        areOptionsLoading={areParameterTypeOptionsLoading}
        fieldName={parameterFieldName}
        fieldValue={selectedParameter.type}
        setFieldValue={setFieldValue}
        customOnChange={onParameterTypeChange}
      />
      {selectedParameter.clusivity && (
        <ClusivitySelect
          selectedClusivity={selectedParameter.clusivity}
          fieldName={`${parameterFieldName}.clusivity`}
          setFieldValue={setFieldValue}
        />
      )}
      {parameterValuesComponent}
      {accuracySelect}
      <IconButtonMenu
        menuIconButtonLabel={parameterRowId}
        menuOptions={contextMenuOptions}
      />
    </div>
  );
};

export default ParameterRow;
