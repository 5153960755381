import {
  audienceParamLabels,
  targetingParameterTypesEnum,
} from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import { weekdayOptions } from 'features/targetingV2/utils/dayTime';
import groupBy from 'lodash/groupBy';

import {
  groupTargetingGroups,
  IAudienceParams,
  ISelectedPeriod,
  ITargetingDefinition,
} from './targetingDefinitions';

const formatDeviceType = (audienceParam: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_TYPE],
  values:
    audienceParam.deviceTypeParams?.deviceTypes.map((type) => type.label) || [],
  clusivity: audienceParam.clusivity,
});

const formatDeviceOS = ({
  deviceOperatingSystemParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_OS],
  values:
    deviceOperatingSystemParams?.deviceOperatingSystems.map((os) => os.label) ||
    [],
  clusivity,
});

const formatDeviceModel = (audienceParam: IAudienceParams) => {
  const allDeviceMakers = audienceParam.deviceMakerParams?.deviceMakers.map(
    (maker) => maker.label
  );
  return {
    label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_MODEL],
    values: allDeviceMakers || [],
    clusivity: audienceParam.clusivity,
  };
};

const formatCustomType = (audienceParam: IAudienceParams) => {
  const allKeyValuePairs = audienceParam.keyValueParams?.keyValues.map(
    (kvp) => `${kvp.key}:${kvp.value}`
  );

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.CUSTOM],
    values: allKeyValuePairs || [],
    clusivity: audienceParam.clusivity,
  };
};

const formatAge = ({ ageParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.AGE],
  values: [`${ageParams?.ageRange.minAge} to ${ageParams?.ageRange.maxAge}`],
  clusivity,
});

const formatGender = ({ genderParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.GENDER],
  values: genderParams?.genders.map((gender) => gender.label) || [],
  clusivity,
});

export const addHourRange = (
  hourRanges: string[],
  startHour: number,
  endHour: number
) =>
  hourRanges.push(
    startHour === endHour ? `${startHour}` : `${startHour}-${endHour}`
  );

export const sortSelectedDays = (groupedPointsPerDay: {
  [key: string]: ISelectedPeriod[];
}) =>
  weekdayOptions
    .filter((weekdayOption) => !!groupedPointsPerDay[weekdayOption.value])
    .map((filteredWeekdayOption) => filteredWeekdayOption.value);

export const formatDayTime = ({
  dayAndTimeParams,
  clusivity,
}: IAudienceParams) => {
  const groupedPointsPerDay = groupBy(
    dayAndTimeParams?.selectedPeriods,
    (period) => period.day
  );
  const sortedSelectedDays = sortSelectedDays(groupedPointsPerDay);

  const formattedDayTimes = sortedSelectedDays.map((day) => {
    const selectedPointsOfDay = groupedPointsPerDay[day];
    const sortedHours = selectedPointsOfDay
      .map((dayTimePoint) => dayTimePoint.hours.hour)
      .sort((a, b) => a - b);

    const hourRanges: string[] = [];
    let startHour: number = -1;

    sortedHours.forEach((hour, index) => {
      const isLast = index + 1 === sortedHours.length;
      const isNextHourConsecutive =
        !isLast && hour + 1 === sortedHours[index + 1];

      if (startHour < 0) {
        startHour = hour;
      }

      if (!isNextHourConsecutive) {
        addHourRange(hourRanges, startHour, hour);
        startHour = -1;
      }
    });

    return `${day}: ${hourRanges.join(', ')}`;
  });

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.DAYTIME],
    values: formattedDayTimes,
    clusivity,
  };
};

const formatBrowserLanguage = ({
  browserLanguageParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.BROWSER_LANGUAGE],
  values: browserLanguageParams?.browserLanguages.map((bl) => bl.label) || [],
  clusivity,
});

const formatPostcode = ({ postalCodeParams, clusivity }: IAudienceParams) => {
  const postcodePacks =
    postalCodeParams?.postcodeGroups &&
    postalCodeParams?.postcodeGroups?.length > 0
      ? `Postcode Packs: ${postalCodeParams?.postcodeGroups
          .map((group) => group.label)
          .join(',')}`
      : '';

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.POSTCODES],
    values: [
      ...(postalCodeParams?.postalCodes.map((bl) => bl.label) || []),
      postcodePacks,
    ],
    clusivity,
  };
};

const formatLocation = ({ locationParams, clusivity }: IAudienceParams) => {
  const locations = locationParams?.positions.map(
    ({ latitude, longitude, radius }) =>
      `Lat: ${latitude}, Long: ${longitude}, Radius: ${radius}km`
  );

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.LOCATION],
    values: locations || [],
    clusivity,
  };
};

const formatGenre = ({ genreParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.GENRE],
  values: genreParams?.genres.map((genre) => genre.label) || [],
  clusivity,
});

const formatITunesCategory = ({
  itunesCategoryParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.ITUNES_CATEGORY],
  values: itunesCategoryParams?.categories.map((category) => category.id) || [],
  clusivity,
});

const formatPodcast = ({ podcastParams, clusivity }: IAudienceParams) => {
  const setLabel = (val?: string, label?: string) =>
    val && label ? `${label}: ${val}` : null;

  const values =
    podcastParams?.podcastTargets.map((pod) => {
      const collection = setLabel(pod.collectionId, 'Collection');
      const show = setLabel(pod.showId, 'Show');
      const episode = setLabel(pod.episodeId, 'Episode');
      const name = setLabel(pod.name, 'Name');

      return [collection, show, episode, name]
        .filter((target) => !!target)
        .join('/');
    }) || [];

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.PODCAST],
    values,
    clusivity,
  };
};

const formatAdPosition = ({ adPositionParams, clusivity }: IAudienceParams) => {
  const values =
    adPositionParams?.positions.map((position) => {
      if (position.midRollPositions && position.midRollPositions?.length > 0) {
        const midRollPositions = position.midRollPositions?.map(
          (pos) => pos.value
        );
        return `${position.position.value}: ${midRollPositions.join(', ')}`;
      }

      return position.position.value;
    }) || [];
  return {
    label: audienceParamLabels[targetingParameterTypesEnum.AD_POSITION],
    values,
    clusivity,
  };
};

const formatListenerAccuracy = ({ listenerAccuracy }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.LISTENER_ACCURACY],
  values: listenerAccuracy?.value ? [listenerAccuracy.value] : [],
});

const formatISP = ({ ispParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.ISP],
  values: ispParams?.isps.map((isp) => isp.label) || [],
  clusivity,
});

const formatConnectionType = ({
  connectionTypeParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.CONNECTION_TYPE],
  values: connectionTypeParams?.connectionTypes.map((ct) => ct.label) || [],
  clusivity,
});

const getListOfLabels = (list: { label: string }[]) =>
  list.map((c: { label: string }) => c.label).join(', ');

const formatCountry = ({ countryParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.COUNTRY],
  values: countryParams.countries.map((c: { label: string }) => c.label) || [],
  clusivity,
});

const formatCity = ({ countryParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.CITY],
  values:
    countryParams.countries.map(
      (c: { label: string }) =>
        `${c.label}/${getListOfLabels(countryParams.cities)}`
    ) || [],
  clusivity,
});

const formatRegion = ({ countryParams, clusivity }: IAudienceParams) => {
  const values = countryParams.countries.map(
    (country: { label: string; value: string }) =>
      `${country.label}/${countryParams.regions
        .filter(
          (region: { countryCode: string }) =>
            region.countryCode === country.value
        )
        .map((region: { label: string }) => region.label)
        .join(', ')}`
  );

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.REGION],
    values,
    clusivity,
  };
};

const formatSubRegion = ({ countryParams, clusivity }: IAudienceParams) => {
  const regionPacks =
    countryParams.countryGroups?.length > 0
      ? `Region Packs: ${countryParams.countryGroups
          .map((group: { label: string }) => group.label)
          .join(',')}`
      : '';

  const subRegions =
    countryParams.countries.length > 0
      ? `${getListOfLabels(countryParams.countries)}/${getListOfLabels(
          countryParams.regions
        )}/${getListOfLabels(countryParams.subRegions)}`
      : '';

  const divider = regionPacks && subRegions ? ', ' : '';

  const values = [`${regionPacks}${divider}${subRegions}`];

  return {
    label: audienceParamLabels[targetingParameterTypesEnum.SUBREGION],
    values,
    clusivity,
  };
};

const formatDma = ({ dmaParams, clusivity }: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.DMA],
  values: dmaParams?.dmas.map((dma) => dma.label) || [],
  clusivity,
});

const formatDmpSegments = ({
  dmpSegmentParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.DMP_SEGMENT],
  values: dmpSegmentParams?.segments.map((segment) => segment.label) || [],
  clusivity,
});

const formatAudienceSegments = ({
  audienceSegmentParams,
  clusivity,
}: IAudienceParams) => ({
  label: audienceParamLabels[targetingParameterTypesEnum.AUDIENCE],
  values:
    audienceSegmentParams?.segments.map((audience) => audience.label) || [],
  clusivity,
});
export const getFormatAudienceParamFunction = (
  audienceParam: IAudienceParams
) => {
  switch (audienceParam.type) {
    case targetingParameterTypesEnum.DEVICE_TYPE:
      return formatDeviceType;
    case targetingParameterTypesEnum.DEVICE_MODEL:
      return formatDeviceModel;
    case targetingParameterTypesEnum.CUSTOM:
      return formatCustomType;
    case targetingParameterTypesEnum.AGE:
      return formatAge;
    case targetingParameterTypesEnum.GENDER:
      return formatGender;
    case targetingParameterTypesEnum.DAYTIME:
      return formatDayTime;
    case targetingParameterTypesEnum.DEVICE_OS:
      return formatDeviceOS;
    case targetingParameterTypesEnum.BROWSER_LANGUAGE:
      return formatBrowserLanguage;
    case targetingParameterTypesEnum.POSTCODES:
      return formatPostcode;
    case targetingParameterTypesEnum.LOCATION:
      return formatLocation;
    case targetingParameterTypesEnum.GENRE:
      return formatGenre;
    case targetingParameterTypesEnum.ITUNES_CATEGORY:
      return formatITunesCategory;
    case targetingParameterTypesEnum.PODCAST:
      return formatPodcast;
    case targetingParameterTypesEnum.AD_POSITION:
      return formatAdPosition;
    case targetingParameterTypesEnum.LISTENER_ACCURACY:
      return formatListenerAccuracy;
    case targetingParameterTypesEnum.ISP:
      return formatISP;
    case targetingParameterTypesEnum.CONNECTION_TYPE:
      return formatConnectionType;
    case targetingParameterTypesEnum.COUNTRY:
      return formatCountry;
    case targetingParameterTypesEnum.CITY:
      return formatCity;
    case targetingParameterTypesEnum.REGION:
      return formatRegion;
    case targetingParameterTypesEnum.SUBREGION:
      return formatSubRegion;
    case targetingParameterTypesEnum.DMA:
      return formatDma;
    case targetingParameterTypesEnum.DMP_SEGMENT:
      return formatDmpSegments;
    case targetingParameterTypesEnum.AUDIENCE:
      return formatAudienceSegments;
    default:
      return null;
  }
};

const formatAudienceParams = (audienceParams: IAudienceParams[]) => {
  const audienceParamsFormatted = audienceParams.reduce(
    (acc, audienceParam) => {
      const formatAudienceParam = getFormatAudienceParamFunction(audienceParam);
      if (!formatAudienceParam) return acc;

      acc.push(formatAudienceParam(audienceParam));

      return acc;
    },
    [] as IAudienceParamLabel[]
  );

  return audienceParamsFormatted;
};

export const formatTargetingDefinition = (
  targetingDefinition: ITargetingDefinition
): IFormattedTargetingDefinition[] => {
  const { groups, general, templates } =
    groupTargetingGroups(targetingDefinition);

  const formattedPrimaryGroups: IFormattedTargetingDefinition[] = [];

  templates.forEach((template) => {
    const primaryGroups = template.groups.map((group) => {
      const formattedGroup: IFormattedTargetingDefinition = {
        title: `Primary Targeting Group - ${template.masterName}`,
        channels: group.channels?.length || 0,
        audienceParamLabels: formatAudienceParams(group.audienceParams),
      };
      return formattedGroup;
    });

    return primaryGroups.forEach((group) => formattedPrimaryGroups.push(group));
  });

  let formattedGeneralTargetingGroup: IFormattedTargetingDefinition[] = [];

  if (general && general?.audienceParams.length > 0) {
    formattedGeneralTargetingGroup = [
      {
        title: 'General Targeting Group',
        audienceParamLabels: formatAudienceParams(
          general?.audienceParams || []
        ),
      },
    ];
  }

  const formattedTargetingGroups = groups.map((group, index) => {
    const formattedGroup: IFormattedTargetingDefinition = {
      title: `Group ${index + 1}`,
      channels: group.channels?.length || 0,
      audienceParamLabels: formatAudienceParams(group.audienceParams),
    };

    return formattedGroup;
  });

  return [
    ...formattedPrimaryGroups,
    ...formattedGeneralTargetingGroup,
    ...formattedTargetingGroups,
  ];
};

export interface IFormattedTargetingDefinition {
  title: string;
  channels?: number;
  audienceParamLabels: IAudienceParamLabel[];
}

interface IAudienceParamLabel {
  label: string;
  values: string[];
  clusivity?: string;
}

export default { formatTargetingDefinition };
