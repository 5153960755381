import { makeStyles, Theme } from '@material-ui/core/styles';

export const useDateTimePickerCalendarStyles = makeStyles((theme: Theme) => ({
  nav: {
    display: 'flex',
  },
  month: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1.125)}`,
    fontSize: '0.813em',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  body: {
    backgroundColor: theme.palette.common.white,
  },
  cell: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    padding: 0,
    backgroundColor: theme.palette.grey[200],
    '&:last-child': {
      borderRightColor: theme.palette.grey[400],
    },
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  head: {
    composes: '$cell',
    fontWeight: 300,
    fontSize: '0.813em',
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.secondary.main,
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(0.5),
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
    },
    '&:last-child': {
      borderTopRightRadius: theme.spacing(0.5),
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  row: {
    '&:last-child td:first-child': {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
    },
    '&:last-child td:last-child': {
      borderBottomRightRadius: theme.spacing(0.5),
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
    '&:last-child td': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  button: {
    width: theme.spacing(5),
    display: 'block',
    border: 0,
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
    '&--today': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
    },
    '&--selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&--range-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    fontWeight: 500,
  },
}));

export const useDateTimePickerInputStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    textAlign: 'center',
    border: 'none',
    height: '50px',
    width: '50px',
    fontWeight: 500,
    fontSize: '1.125em',
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(0.5),
    margin: `0 ${theme.spacing(1.125)}`,
    '&::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
}));

export const useDateTimePickerDialogStyles = makeStyles((theme: Theme) => ({
  heading: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '1.25em',
    minWidth: '300px',
  },
  grid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: '0.5fr repeat(3, auto)',
    gridColumnGap: '0px',
    gridRowGap: '0px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&--heading': {
      gridArea: '1 / 1 / 2 / 13',
    },
    '&--time': {
      gridArea: '2 / 7 / 5 / 13',
    },
    '&--date': {
      gridArea: '2 / 1 / 3 / 7',
    },
    '&--date-centered': {
      gridArea: '2 / 1 / 3 / 13',
    },
    '&--calendar': {
      gridArea: '3 / 1 / 5 / 7',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      minHeight: '230px',
    },
    '&--calendar-centered': {
      gridArea: '3 / 1 / 5 / 13',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      minHeight: '230px',
    },
    '&--range-calendar': {
      gridArea: '2 / 1 / 5 / 6',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: 'max-content',
    },
    '&--range-content-section': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(3),
    },
    '&--range-selected': {
      marginTop: theme.spacing(2),
      gridArea: '2 / 6 / 3 / 13',
      marginBottom: theme.spacing(2),
    },
    '&--last-x-days-presets': {
      flexDirection: 'column',
      gridArea: '3 / 6 / 4 / 13',
      marginBottom: theme.spacing(1),
    },
    '&--period-presets': {
      flexDirection: 'column',
      gridArea: '4 / 6 / 5 / 13',
      marginBottom: theme.spacing(1),
    },
  },
  content: {
    padding: theme.spacing(0, 2),
    width: '672px',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  presetsHeader: {
    fontSize: '0.813em',
    fontWeight: 'normal',
  },
  presetsCtasWrapper: {
    flexDirection: 'row',
  },
  presetsButton: {
    boxShadow: 'none',
    padding: theme.spacing(0, 1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '1.188em',
    fontWeight: 'normal',
    height: '32px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.primary.main,
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.primary.main,
    },
  },
  actions: {
    padding: theme.spacing(2, 0, 1),
    justifyContent: 'space-between',
  },
  rangeTextField: {
    width: '140px',
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  rangeTextFieldLabel: {
    fontSize: '0.813em',
  },
  rangeTextFieldInput: {
    padding: theme.spacing(0.5, 0.5),
  },
  spacer: {
    width: '30%',
  },
  button: {
    padding: theme.spacing(0.5),
    minWidth: '90px',
    '&--right-margin': {
      marginRight: theme.spacing(1),
    },
  },
  displayedSelection: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.625),
    width: '100%',
  },
}));

export default useDateTimePickerDialogStyles;
