import formStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IPublisherValues } from 'features/inventory/publisher/components/PublisherTabsForm/PublisherFormValues';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import { PricingType } from 'interfaces/generated.types';
import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { currencyValues } from 'utils/currency';
import { parseFormattedValue } from 'utils/numbers';
import { getTerritoryValues } from 'utils/territory';

import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchIcon from '@material-ui/icons/Launch';

export const isPercentageAllowed = (values: NumberFormatValues) => {
  const isIntegerValid =
    values.value.length <= 3 && !values.value.includes('.');
  const isFloatValid =
    values.value.includes('.') &&
    values.value.substr(0, values.value.indexOf('.')).length <= 3;
  return isIntegerValid || isFloatValid;
};

export const pricingTypes = [
  {
    value: PricingType.Cpm,
    label: 'CPM',
  },
  {
    value: PricingType.RevShare,
    label: 'Rev Share',
  },
];

const PublisherDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  update = false,
  status = {},
}: IFormProps<IPublisherValues>) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();
  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Network Name"
              placeholder="Network Name"
              name="networkName"
              data-tc="networkNameField"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/network/${values.networkId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="publisherNetworkLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.networkName}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Publisher Name"
            placeholder="Publisher Name"
            data-tc="publisherNameField"
            name="name"
            autoFocus={!update}
            fullWidth
            inputProps={{ 'data-testid': 'publisherName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Publisher ID"
              placeholder="Publisher ID"
              data-tc="publisherIdField"
              name="altId"
              disabled
              fullWidth
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="publisherCurrencyField"
            label="Publisher Currency"
            name="currency"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.currency && errors.currency) || status.currency
            }
            FormHelperTextProps={{
              error: !!(
                (touched.currency && errors.currency) ||
                status.currency
              ),
            }}
            fullWidth
          >
            {currencyValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Price type"
            data-tc="priceTypeDropdown"
            name="pricingType"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('pricingType', e.target.value);
              setFieldValue('pricingAmount', '');
            }}
            helperText={
              (touched.pricingType && errors.pricingType) || status.pricingType
            }
            FormHelperTextProps={{
              error: !!(
                (touched.pricingType && errors.pricingType) ||
                status.pricingType
              ),
            }}
            fullWidth
          >
            {pricingTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          {values.pricingType === PricingType.Cpm ? (
            <>
              <NumberInput
                name="pricingAmount"
                label="Amount"
                value={values.pricingAmount}
                allowNegative={false}
                dataTc="amountCpmField"
                decimalScale={2}
                thousandSeparator={ThousandSeparatorTypes.comma}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.pricingAmount || !!status.pricingAmount}
                error={errors.pricingAmount || status.pricingAmount}
                fullWidth
              />
              {parseFormattedValue(values.pricingAmount) > 100 && (
                <FormHelperText
                  error
                  data-tc="cpmPricingAmountOverHundredWarning"
                >
                  Amount over 100
                </FormHelperText>
              )}
            </>
          ) : (
            <NumberInput
              name="pricingAmount"
              label="Amount (%)"
              value={values.pricingAmount}
              allowNegative={false}
              dataTc="amountRevShareField"
              decimalScale={2}
              isAllowed={isPercentageAllowed}
              thousandSeparator={ThousandSeparatorTypes.comma}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.pricingAmount || !!status.pricingAmount}
              error={errors.pricingAmount || status.pricingAmount}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            id="territories"
            options={getTerritoryValues(
              values.networkTerritories || []
            ).map((territoryOption) =>
              territories?.includes(territoryOption.value)
                ? territoryOption
                : { ...territoryOption, readOnly: true }
            )}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue(
                'territories',
                fieldValue.filter(
                  (territoryOption: OptionType) => !territoryOption.readOnly
                )
              );
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default PublisherDetails;
