import Loader from 'features/targetingV2/components/core/Loader/Loader';
import Paper from 'features/targetingV2/components/core/Paper';
import React, { ReactNode } from 'react';

// Displays autocomplete options when user starts typing
const Menu = ({
  selectProps,
  innerProps,
  children,
}: {
  children: ReactNode;
  selectProps: any;
  innerProps: any;
}) => (
  <Paper
    square
    className={selectProps.classes.paper}
    {...innerProps}
    data-testid={selectProps.AttributeProps.menuAttribute}
  >
    {selectProps.isLoading ? <Loader /> : children}
  </Paper>
);

export default Menu;
