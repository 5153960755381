import classNames from 'classnames';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Tab from 'components/Tab/Tab';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreativesTable from 'features/direct/creative/components/CreativesTable/CreativesTable';
import {
  ICreativeAudio,
  ICreativeImage,
} from 'features/direct/creative/graphql/queries';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  CreativeStatus,
  CreativeType,
  PermissionName,
  Territory,
  useAllAudioCreativesQuery,
  useAllImageCreativesQuery,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';
import React, { useEffect, useState } from 'react';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import { handleTabHistory } from 'utils/journeys';
import { userHasAtLeastOnePermission } from 'utils/permissions';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

import useStyles from './CreativesPage.styles';

export const formatCreativesData = memoizeOne(
  (data: any[], territory: Territory | undefined) =>
    data.map((d: any) => ({
      id: d.id,
      resourceAltId: d.resourceAltId,
      name: d.name,
      advertiser: d.advertiser ? d.advertiser.name : '',
      status: d.status,
      fileName: d.fileName,
      url: d.url,
      size: d.size || null,
      mimeType: d.mimeType || null,
      uploadedDate: d.uploadedDate
        ? getFormattedDateBasedOnTerritory(new Date(d.uploadedDate), territory)
        : null,
      duration: d.duration ? numbro(d.duration).format({ mantissa: 3 }) : null,
    }))
);

export interface ICreativesPage {
  userPermissions: PermissionName[];
  history: History;
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const CreativesPage = ({ userPermissions, history }: ICreativesPage) => {
  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );
  const classes = useStyles();
  const [selectedTab, setTab] = useState(0);

  const location = usePreviousLocation();
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isAudioTab = selectedTab === 0;
  const isImageTab = selectedTab === 1;
  const { loading: loadingAudioCreatives, data: audioCreatives } =
    useAllAudioCreativesQuery({
      variables: {
        filter: {
          status_in: [CreativeStatus.Ready, CreativeStatus.Disabled],
        },
        territories: [activeTerritory!],
      },
      errorPolicy: 'all',
    });

  const { loading: loadingImageCreatives, data: imageCreatives } =
    useAllImageCreativesQuery({
      variables: {
        filter: {
          status_in: [CreativeStatus.Ready, CreativeStatus.Disabled],
        },
        territories: [activeTerritory!],
      },
      errorPolicy: 'all',
    });

  const uploadCreativesCta = hasEditPermissions && (
    <StyledLink
      location={{
        pathname: '/creatives/upload',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="uploadCreativeButton"
    >
      Upload Creatives
    </StyledLink>
  );

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Creatives</h1>
        <div className={classes.table}>
          {loadingAudioCreatives || loadingImageCreatives ? (
            <Loader />
          ) : (
            <>
              <AppBar position="static" classes={{ root: classes.bar }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, index) => {
                    setTab(index);
                    history && handleTabHistory(history, index);
                  }}
                  data-tc="creativeTabs"
                >
                  <Tab dataTc="creativeAudioTab" label="Audio" />
                  <Tab dataTc="creativeImageTab" label="Display" />
                </Tabs>
              </AppBar>
              <div
                className={classNames([
                  classes.formView,
                  [`${classes.formView}--table`],
                ])}
              >
                {isAudioTab && (
                  <CreativesTable
                    allCreatives={formatCreativesData(
                      (audioCreatives?.allCreatives as ICreativeAudio[]) || [],
                      activeTerritory
                    )}
                    isEditable={hasEditPermissions}
                    dataTc="listAudioCreativesTable"
                    type={CreativeType.Audio}
                    history={history}
                    customToolbarCtas={uploadCreativesCta}
                  />
                )}
                {isImageTab && (
                  <CreativesTable
                    allCreatives={formatCreativesData(
                      (imageCreatives?.allCreatives as ICreativeImage[]) || [],
                      activeTerritory
                    )}
                    isEditable={hasEditPermissions}
                    dataTc="listImageCreativesTable"
                    type={CreativeType.Image}
                    history={history}
                    customToolbarCtas={uploadCreativesCta}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Container>
    </main>
  );
};

export default CreativesPage;
