import { ILocationParameterGroup } from 'features/targetingV2/types/targeting';
import { GetLocationParameterValueByTerritoryQuery } from 'interfaces/generated.types';
import { useEffect, useState } from 'react';

const useLimitLocation = (
  locations: ILocationParameterGroup[],
  locationsFieldName: string,
  setFieldValue: (field: string, value: any) => void,
  limitLocationData: GetLocationParameterValueByTerritoryQuery | undefined
) => {
  const [isLocationLimited, setIsLocationLimited] = useState(
    locations.some((location) => location.limitLocationParameterValue)
  );

  const limitLocationParameterValueIndex = locations.findIndex(
    (location) => location.limitLocationParameterValue
  );

  const limitLocationParameterValueId =
    locations[limitLocationParameterValueIndex]?.limitLocationParameterValue
      ?.id;

  useEffect(() => {
    if (
      isLocationLimited &&
      limitLocationData?.getLocationParameterValueByTerritory &&
      limitLocationParameterValueId !==
        limitLocationData?.getLocationParameterValueByTerritory.id
    ) {
      setFieldValue(
        `${locationsFieldName}[${limitLocationParameterValueIndex}].limitLocationParameterValue`,
        {
          id: limitLocationData?.getLocationParameterValueByTerritory.id,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitLocationData?.getLocationParameterValueByTerritory]);

  return {
    isLocationLimited,
    setIsLocationLimited,
  };
};

export default useLimitLocation;
