import { CloneTypeEnum } from 'components/CloneModal/CloneModalValues';
import Impressions from 'components/Impressions/Impressions';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableCloneCell from 'components/Table/TableCloneCell';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { orderStatusValues } from 'features/direct/order/components/OrderTabsForm/OrderFormValues';
import { GET_ALL_ORDERS } from 'features/direct/order/graphql/queries';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  EntityType,
  Order,
  OrderStatus,
  Territory,
  useCloneOrderMutation,
  useUpdateOrderMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue, removeEmailDomain } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import {
  handleCellClone,
  handleCellUpdate,
  handleCellValidate,
} from 'utils/tables';

interface IOrdersRow {
  id: string;
  altId: number;
  name: string;
  owner: string;
  startDate: string;
  endDate: string;
  objective: number | undefined;
  impressions: number | null;
  status: OrderStatus;
}

interface IOrdersTableProps {
  history: History;
  allOrders: Order[];
  isEditable: boolean;
}

export const formatOrdersData = memoizeOne(
  (data: Order[], territory: Territory | undefined) =>
    data.map((d) => ({
      id: d.id,
      altId: d.altId,
      name: d.name,
      owner: d.owner ? removeEmailDomain(d.owner?.email || '') : '',
      startDate: d.startDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.startDate, d.timeZone),
            territory
          )
        : '',
      endDate: d.endDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.endDate, d.timeZone),
            territory
          )
        : '',
      objective: d.objective,
      impressions: d.impressions,
      externalId: d.externalId || '',
      status: d.status,
    }))
);

const hiddenColumns = ['externalId'];

const OrdersTable = ({ history, isEditable, allOrders }: IOrdersTableProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateOrder] = useUpdateOrderMutation({
    refetchQueries: [
      {
        query: GET_ALL_ORDERS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });

  const [cloneOrder] = useCloneOrderMutation({
    refetchQueries: [
      {
        query: GET_ALL_ORDERS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });

  const validateOrder = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createOrderCta = isEditable && (
    <StyledLink
      location={{
        pathname: '/order',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createOrderButton"
    >
      Create Order
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IOrdersRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/order/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
      },
      {
        Header: 'Objective Impressions',
        accessor: 'objective',
        filter: 'objective',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value, row } }: CellProps<IOrdersRow>) => {
          const { impressions } = row.original;
          return <Impressions objective={value} impressions={impressions} />;
        },
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
        style: {
          wordBreak: 'break-word',
          width: '140px',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IOrdersRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: orderStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={orderStatusValues}
                name="orderStatusSelect"
                dataTc="orderStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, name },
            },
          },
          onCellClone,
          setErrorModal,
        }: CellProps<IOrdersRow>) => (
          <TableCloneCell
            onCellClone={onCellClone}
            cloneChildren
            setErrorModal={setErrorModal}
            entity={{ id, name }}
            isEditable={isEditable}
            type={CloneTypeEnum.ORDER}
            dataTc="cloneOrder"
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <Table
      history={history}
      columns={columns}
      isEditable={isEditable}
      dataTc="listOrdersTable"
      data={formatOrdersData(allOrders, activeTerritory)}
      hiddenColumns={hiddenColumns}
      onCellClone={({
        entity,
        cloneChildren,
        hasChildren,
        isEdit,
        setErrorModal,
      }) =>
        handleCellClone({
          variables: {
            id: entity.id,
            disableCascade: cloneChildren ? !hasChildren : undefined,
          },
          clone: cloneOrder,
          handleSuccess: (data: any) =>
            isEdit && history.push(`/order/${data.cloneOrder.id}`),
          handleContinue: () => history.push(`/order/${entity.id}`),
          setErrorModal,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Order',
          },
        })
      }
      onCellUpdate={(row: IOrdersRow, setErrorModal: any, setUpdating: any) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            status: row.status,
          },
          update: updateOrder,
          handleContinue: () => history.push(`/order/${row.id}`),
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Order',
          },
        })
      }
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateOrder,
          entity: { ...entity, type: EntityType.Order },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      customToolbarCtas={createOrderCta}
    />
  );
};

export default OrdersTable;
