import NavigationContainer from 'components/Navigation/NavigationContainer/NavigationContainer';
import React from 'react';

import useStyle from './Header.styles';

const Header = () => {
  const classes = useStyle();

  return (
    <header
      className={classes.header}
      data-tc="header"
      data-testid="navigation-header"
    >
      <div className={classes.container}>
        <div className={classes.head}>
          <NavigationContainer />
        </div>
      </div>
    </header>
  );
};

export default Header;
