import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  blankSpace: {
    width: '175px',
    alignSelf: 'flex-start',
    visibility: 'hidden',
  },
  container: ({ disabled }: { disabled: boolean }) => ({
    display: 'flex',
    flex: 2,
    flexWrap: 'wrap',
    height: '30px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '0px 0px 4px 4px',
    overflow: 'hidden',
    color: disabled ? theme.palette.grey[400] : 'inherit',
    backgroundColor: theme.palette.common.white,
  }),
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    width: '130px',
    padding: theme.spacing(0, 1.25),
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1),
    height: '15px',
  },
  text: {
    fontSize: '14px',
  },
}));

export default useStyles;
