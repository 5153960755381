import { History } from 'history';
import { PermissionName, User } from 'interfaces/generated.types';
import DirectHomePage from 'pages/DirectHomePage/DirectHomePage';
import React from 'react';
import { Redirect } from 'react-router-dom';

interface IHomePageProps {
  history: History;
  userPermissions: PermissionName[];
  currentUser: User;
}
const HomePage = ({
  history,
  userPermissions,
  currentUser,
}: IHomePageProps) => {
  switch (true) {
    case userPermissions.includes(PermissionName.Primary):
    case userPermissions.includes(PermissionName.Direct):
      return <DirectHomePage history={history} currentUser={currentUser} />;
    case userPermissions.includes(PermissionName.Supply):
      return <Redirect to="/networks" />;
    case userPermissions.includes(PermissionName.Programmatic):
      return <Redirect to="/sales-channels" />;
    case userPermissions.includes(PermissionName.DirectRead):
      return <Redirect to="/campaigns" />;
    case userPermissions.includes(PermissionName.SupplyRead):
      return <Redirect to="/networks" />;
    case userPermissions.includes(PermissionName.ProgrammaticRead):
      return <Redirect to="/sales-channels" />;
    case userPermissions.includes(PermissionName.Forecasting):
      return <Redirect to="/forecasting" />;
    case userPermissions.includes(PermissionName.PublisherInsightsTier1):
    case userPermissions.includes(PermissionName.PublisherInsightsTier2):
      return <Redirect to="/monetize" />;
    default:
      return <Redirect to="/error/403" />;
  }
};

export default HomePage;
