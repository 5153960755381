import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import pageStyles from 'assets/styles/components/Page.styles';

import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';

import { advertiserStatusValues } from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserFormValues';
import { GET_AGENCY } from 'features/direct/agency/graphql/queries';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  Advertiser,
  AdvertiserStatus,
  EntityType,
  useUpdateAdvertiserMutation,
} from 'interfaces/generated.types';

import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';

interface IAdvertisersTableProps {
  isEditable?: boolean;
  advertisers: Advertiser[];
  history: History;
  match: {
    params: {
      agencyId: string;
    };
  };
}

interface IAdvertisersRow {
  id: string;
  altId: number;
  name: string;
  domains: string;
  status: AdvertiserStatus;
}

const formatData = memoizeOne((data: Advertiser[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    domains: d.domains.join(', '),
    status: d.status,
  }))
);

const AdvertisersTable = ({
  isEditable = true,
  history,
  match,
  advertisers,
}: IAdvertisersTableProps) => {
  const classes = pageStyles();

  const location = usePreviousLocation();

  const [updateAdvertiser] = useUpdateAdvertiserMutation({
    refetchQueries: [
      {
        query: GET_AGENCY,
        variables: { id: match.params.agencyId },
      },
    ],
  });

  const validateAdvertiser = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const createAdvertiserCta = isEditable && (
    <StyledLink
      location={{
        pathname: `/agency/${match.params.agencyId}/advertiser`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newAdvertiserButton"
    >
      Create New Advertiser
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IAdvertisersRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/advertiser/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Domains',
        accessor: 'domains',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IAdvertisersRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: advertiserStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={advertiserStatusValues}
                name="advertiserStatusSelect"
                dataTc="advertiserStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Advertisers"
        columns={columns}
        data={formatData(advertisers)}
        isEditable={isEditable}
        dataTc={`${match.params.agencyId || ''}listAdvertisersTable`}
        isPageTable={false}
        onCellUpdate={(
          row: IAdvertisersRow,
          setErrorModal: any,
          setUpdating: any
        ) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateAdvertiser,
            handleContinue: () => history.push(`/advertiser/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Advertiser',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateAdvertiser,
            entity: { ...entity, type: EntityType.Advertiser },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createAdvertiserCta}
      />
    </div>
  );
};

export default AdvertisersTable;
