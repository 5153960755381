import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import useStyles from './Breadcrumb.styles';
import BreadcrumbLink, { IBreadcrumbLinkProps } from './BreadcrumbLink';
import BreadcrumbDropdown, {
  IBreadcrumbDropdownProps,
} from './BreadcrumbDropdown';

export enum BreadcrumbNodeEnum {
  Dropdown = 'dropdown',
  Link = 'link',
}

export interface IBreadcrumbConfigItem {
  type: BreadcrumbNodeEnum;
  item: IBreadcrumbDropdownProps | IBreadcrumbLinkProps;
}

export interface IBreadcrumbNavProps {
  'data-testid'?: string;
  config: IBreadcrumbConfigItem[];
}

const BreadcrumbNav = (props: IBreadcrumbNavProps) => {
  const { config, 'data-testid': dataTestId } = props;

  const classes = useStyles();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<div />}
      data-testid={dataTestId}
      classes={{
        separator: classes.separator,
      }}
    >
      {config.map((node) => {
        switch (node.type) {
          case BreadcrumbNodeEnum.Link:
            return (
              <BreadcrumbLink
                key={`breadcrumbLink-${node.item.label}`}
                {...(node.item as IBreadcrumbLinkProps)}
              />
            );
          case BreadcrumbNodeEnum.Dropdown:
            return (
              <BreadcrumbDropdown
                key={`breadcrumbDropdown-${node.item.label}`}
                {...(node.item as IBreadcrumbDropdownProps)}
              />
            );
          default:
            return <span key="breadcrumb-emptyNode" />;
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbNav;
