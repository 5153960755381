import gql from 'graphql-tag';

export const ALL_LOCATION_PARAMETERS = gql`
  query allLocationParameters($territory: Territory) {
    allLocationParameters(territory: $territory) {
      type
      name
      cardinality
    }
  }
`;

export const GET_LOCATION_PARAMETER_VALUES = gql`
  query locationParameterValues(
    $type: LocationParameterType!
    $search: String
  ) {
    locationParameterValues(type: $type, search: $search) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_ACCURACIES = gql`
  query AllLocationAccuracies {
    allLocationAccuracies {
      defaultValue
      allValues
    }
  }
`;

export const GET_LOCATION_PARAMETER_VALUE_BY_TERRITORY = gql`
  query getLocationParameterValueByTerritory($territory: Territory!) {
    getLocationParameterValueByTerritory(territory: $territory) {
      id
    }
  }
`;

export const GET_ALL_REGION_PACKS = gql`
  query allRegionPacks($territories: [Territory!]!) {
    allRegionPacks(territories: $territories) {
      id
      name
    }
  }
`;

export const VALIDATE_ZIPCODES = gql`
  query validateZipcodes($postcodes: [String!]!) {
    validatePostcodes(postcodes: $postcodes) {
      valid
      invalid
    }
  }
`;
