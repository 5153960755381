import * as Yup from 'yup';

import commons from 'validations/commons';

const AgencyFormValidation = Yup.object().shape({
  name: commons.name('Agency Name'),
  externalId: commons.max255Characters(),
  externalSystemId: commons.max255Characters(),
});

export default AgencyFormValidation;
