import classNames from 'classnames';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/Clear';

import withFeatureToggles from 'containers/withFeatureToggles/withFeatureToggles';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  IAudienceParams,
} from 'utils/targetingDefinitions';

import TargetingAdPosition, {
  adPositionValues,
} from '../TargetingAdPosition/TargetingAdPosition';
import TargetingAge, { ageValues } from '../TargetingAge/TargetingAge';
import TargetingBrowserLanguage, {
  browserLanguageValues,
} from '../TargetingBrowserLanguage/TargetingBrowserLanguage';
import TargetingCity, { cityValues } from '../TargetingCity/TargetingCity';
import TargetingCountry, {
  countryValues,
} from '../TargetingCountry/TargetingCountry';
import TargetingCustomType, {
  customTypeValues,
} from '../TargetingCustomType/TargetingCustomType';
import TargetingDayTime, {
  dayTimeValues,
} from '../TargetingDayTime/TargetingDayTime';
import TargetingDeviceOS, {
  deviceOSValues,
} from '../TargetingDeviceOS/TargetingDeviceOS';
import TargetingDeviceType, {
  deviceTypeValues,
} from '../TargetingDeviceType/TargetingDeviceType';
import TargetingDeviceModel, {
  deviceMakerValues,
} from '../TargetingDeviceModel/TargetingDeviceModel';
import TargetingDMPSegment, {
  dmpSegmentValues,
} from '../TargetingDMPSegment/TargetingDMPSegment';
import TargetingDMA, { dmaValues } from '../TargetingDMA/TargetingDMA';
import TargetingGender, {
  genderValues,
} from '../TargetingGender/TargetingGender';
import TargetingGenre, { genreValues } from '../TargetingGenre/TargetingGenre';
import TargetingItunesCategory, {
  itunesCategoryValues,
} from '../TargetingItunesCategory/TargetingItunesCategory';
import TargetingListenerAccuracy from '../TargetingListenerAccuracy/TargetingListenerAccuracy';
import TargetingLocation, {
  locationValues,
} from '../TargetingLocation/TargetingLocation';
import TargetingPodcast, {
  podcastValues,
} from '../TargetingPodcast/TargetingPodcast';
import TargetingPostCodes, {
  postcodeValues,
} from '../TargetingPostCodes/TargetingPostCodes';
import TargetingRegion, {
  regionValues,
} from '../TargetingRegion/TargetingRegion';
import TargetingSubRegion, {
  subRegionValues,
} from '../TargetingSubRegion/TargetingSubRegion';
import TargetingIsp, { ispValues } from '../TargetingIsp/TargetingIsp';
import TargetingConnectionType, {
  connectionTypeValues,
} from '../TargetingConnectionType/TargetingConnectionType';
import TargetingAudience, {
  audienceSegmentValues,
} from '../TargetingAudience/TargetingAudience';
import useStyles from './TargetingParameters.styles';
import {
  disableClusivity,
  targetingParameterClusivityTypes,
  targetingParameterClusivityTypesEnum,
  targetingParameterTypes,
  targetingParameterTypesEnum,
} from './TargetingParametersValues';

interface ITargetingParametersProps {
  remove: any;
  title: string;
  targetingGroupName: string;
  templateIndex?: number;
  groupIndex?: number;
  selectedTypes: targetingParameterTypesEnum[];
  toggleSelectedTargetingType: any;
  featureToggles: any;
}

export const setTargetingParameterComponentValues = (
  type: string,
  readOnlyType: boolean,
  setFieldValue: any,
  field: string
) => {
  switch (type) {
    case targetingParameterTypesEnum.CUSTOM:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...customTypeValues,
      });
      break;
    case targetingParameterTypesEnum.AGE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...ageValues,
      });
      break;
    case targetingParameterTypesEnum.GENDER:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...genderValues,
      });
      break;
    case targetingParameterTypesEnum.DAYTIME:
      setFieldValue(field, {
        ...{ type },
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...dayTimeValues,
      });
      break;
    case targetingParameterTypesEnum.DEVICE_TYPE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...deviceTypeValues,
      });
      break;
    case targetingParameterTypesEnum.DEVICE_OS:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...deviceOSValues,
      });
      break;

    case targetingParameterTypesEnum.DEVICE_MODEL:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...deviceMakerValues,
      });
      break;
    case targetingParameterTypesEnum.BROWSER_LANGUAGE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...browserLanguageValues,
      });
      break;
    case targetingParameterTypesEnum.POSTCODES:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...postcodeValues,
      });
      break;
    case targetingParameterTypesEnum.LOCATION:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...locationValues,
      });
      break;
    case targetingParameterTypesEnum.COUNTRY:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...countryValues,
      });
      break;
    case targetingParameterTypesEnum.REGION:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...regionValues,
      });
      break;
    case targetingParameterTypesEnum.SUBREGION:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...subRegionValues,
      });
      break;
    case targetingParameterTypesEnum.CITY:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...cityValues,
      });
      break;
    case targetingParameterTypesEnum.DMP_SEGMENT:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...dmpSegmentValues,
      });
      break;
    case targetingParameterTypesEnum.DMA:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...dmaValues,
      });
      break;
    case targetingParameterTypesEnum.AUDIENCE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...audienceSegmentValues,
      });
      break;
    case targetingParameterTypesEnum.GENRE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...genreValues,
      });
      break;
    case targetingParameterTypesEnum.ITUNES_CATEGORY:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...itunesCategoryValues,
      });
      break;
    case targetingParameterTypesEnum.PODCAST:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...podcastValues,
      });
      break;
    case targetingParameterTypesEnum.AD_POSITION:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...adPositionValues,
      });
      break;
    case targetingParameterTypesEnum.LISTENER_ACCURACY:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        listenerAccuracy: {
          value: '',
          readOnly: false,
        },
      });
      break;
    case targetingParameterTypesEnum.ISP:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...ispValues,
      });
      break;

    case targetingParameterTypesEnum.CONNECTION_TYPE:
      setFieldValue(field, {
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType,
        ...{ type },
        ...connectionTypeValues,
      });
      break;
    default:
      setFieldValue(`${field}.type`, type);
      break;
  }
};

export const getTargetingParameterComponent = (
  props: IFormProps<any> & ITargetingParametersProps & { index: number }
) =>
  ({
    [targetingParameterTypesEnum.CUSTOM]: <TargetingCustomType {...props} />,
    [targetingParameterTypesEnum.AGE]: <TargetingAge {...props} />,
    [targetingParameterTypesEnum.GENDER]: <TargetingGender {...props} />,
    [targetingParameterTypesEnum.DAYTIME]: <TargetingDayTime {...props} />,
    [targetingParameterTypesEnum.DEVICE_TYPE]: (
      <TargetingDeviceType {...props} />
    ),
    [targetingParameterTypesEnum.DEVICE_MODEL]: (
      <TargetingDeviceModel {...props} />
    ),
    ...(props.featureToggles && props.featureToggles.enableBrowserLanguages
      ? {
          [targetingParameterTypesEnum.BROWSER_LANGUAGE]: (
            <TargetingBrowserLanguage {...props} />
          ),
        }
      : {}),
    [targetingParameterTypesEnum.DEVICE_OS]: <TargetingDeviceOS {...props} />,
    [targetingParameterTypesEnum.POSTCODES]: <TargetingPostCodes {...props} />,
    [targetingParameterTypesEnum.LOCATION]: <TargetingLocation {...props} />,
    [targetingParameterTypesEnum.COUNTRY]: <TargetingCountry {...props} />,
    [targetingParameterTypesEnum.REGION]: <TargetingRegion {...props} />,
    [targetingParameterTypesEnum.SUBREGION]: <TargetingSubRegion {...props} />,
    [targetingParameterTypesEnum.CITY]: <TargetingCity {...props} />,
    [targetingParameterTypesEnum.DMP_SEGMENT]: (
      <TargetingDMPSegment {...props} />
    ),
    [targetingParameterTypesEnum.DMA]: <TargetingDMA {...props} />,
    [targetingParameterTypesEnum.AUDIENCE]: <TargetingAudience {...props} />,
    [targetingParameterTypesEnum.GENRE]: <TargetingGenre {...props} />,
    [targetingParameterTypesEnum.ITUNES_CATEGORY]: (
      <TargetingItunesCategory {...props} />
    ),
    [targetingParameterTypesEnum.PODCAST]: <TargetingPodcast {...props} />,
    [targetingParameterTypesEnum.AD_POSITION]: (
      <TargetingAdPosition {...props} />
    ),
    [targetingParameterTypesEnum.LISTENER_ACCURACY]: (
      <TargetingListenerAccuracy {...props} />
    ),
    [targetingParameterTypesEnum.ISP]: <TargetingIsp {...props} />,
    [targetingParameterTypesEnum.CONNECTION_TYPE]: (
      <TargetingConnectionType {...props} />
    ),
  } as any);

const TargetingParameters = (
  props: IFormProps<any> & ITargetingParametersProps
) => {
  const {
    values,
    remove,
    title,
    targetingGroupName,
    templateIndex,
    groupIndex,
    setFieldValue,
    selectedTypes,
    toggleSelectedTargetingType,
    featureToggles,
  } = props;

  const classes: any = useStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <List className={classes.listenerList}>
      {targetingValues.audienceParams.map(
        (audienceParam: IAudienceParams, index: number) => (
          /* eslint-disable react/no-array-index-key */
          <li
            data-tc="targetingParameter"
            key={index}
            className={classNames([
              classes.parameters,
              {
                [classes.parametersGroup]: groupIndex !== undefined,
              },
            ])}
          >
            <p className={classes.title} data-tc="targetingParameterTitle">
              {title}
            </p>
            <div className={classes.parametersValues}>
              <div className={classes.parameterOptions}>
                <Field
                  component={TextField}
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    },
                  }}
                  label="Data Type"
                  data-tc="targetingParameterOptions"
                  disabled={targetingValues.audienceParams[index].readOnlyType}
                  name={`${targetingPath}.audienceParams.${index}.type`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTargetingParameterComponentValues(
                      e.target.value,
                      targetingValues.audienceParams[index].readOnlyType,
                      setFieldValue,
                      `${targetingPath}.audienceParams.${index}`
                    );
                    toggleSelectedTargetingType(
                      targetingValues.audienceParams[index].type,
                      e.target.value
                    );
                  }}
                  classes={{ root: classes.parameterSelect }}
                  fullWidth
                >
                  {targetingParameterTypes(featureToggles).map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      disabled={
                        selectedTypes.includes(option.value) &&
                        option.value !== targetingParameterTypesEnum.CUSTOM
                      }
                      data-tc="targetingParameterOption"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    },
                  }}
                  name={`${targetingPath}.audienceParams.${index}.clusivity`}
                  data-tc="targetingParameterClusivity"
                  classes={{ root: classes.parameterSelect }}
                  fullWidth
                  disabled={
                    disableClusivity.includes(
                      targetingValues.audienceParams[index].type
                    ) || targetingValues.audienceParams[index].readOnlyType
                  }
                >
                  {targetingParameterClusivityTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              {targetingValues.audienceParams[index].type && (
                <div className={classes.parameterWrapper}>
                  {
                    getTargetingParameterComponent({ ...props, index })[
                      targetingValues.audienceParams[index].type as string
                    ]
                  }
                </div>
              )}
              <IconButton
                className={classes.iconRemove}
                data-tc="targetingParameterRemoveIcon"
                onClick={() => remove(index)}
                disabled={targetingValues.audienceParams[index].readOnlyType}
              >
                <ClearIcon />
                <span className="sr-only">Remove targeting paremeter</span>
              </IconButton>
            </div>
          </li>
        )
      )}
    </List>
  );
};

export default withFeatureToggles(TargetingParameters);
