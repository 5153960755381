import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    margin: theme.spacing(2.5),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
