import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    display: 'flex',
    height: '20px',
    width: '220px',
    border: 'solid 2px',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.625),
    borderColor: theme.palette.grey[800],
    fontSize: '15px',
    justifyContent: 'center',
    '&:hover, &:focus': {
      borderRadius: theme.spacing(0.625),
      backgroundColor: theme.palette.common.white,
    },
    alignItems: 'center',
  },
  dropdownContent: {
    display: 'flex',
    alignItems: 'center',
    height: '18px',
  },
  dropdownLabel: {
    textAlign: 'center',
    fontSize: '15px',
    marginRight: theme.spacing(1.5),
  },
  dropdownIcon: {
    display: 'flex',
    marginRight: theme.spacing(0.5),
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  dropdownOption: {
    minHeight: 0,
  },
}));

export default useStyles;
