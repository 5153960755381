import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import TargetingVersionSwitch from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSwitch';
import { Field, FieldArray } from 'formik';
import { IGetAllTargetingTemplatesResponse } from 'graphql/targetingTemplates/queries';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import { IFormProps } from 'interfaces';
import {
  PermissionName,
  Publisher,
  TargetingVersion,
  useAllPublishersAndChannelsQuery,
  useAllTargetingTemplatesQuery,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { checkForTargetingMissingErrors } from 'utils/forms';
import {
  addReadOnlyValues,
  formatInitialTargetingGroups,
  ITargetingGroup,
  ITargetingMasterTemplate,
  ITargetingTemplate,
} from 'utils/targetingDefinitions';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import TargetingGeneralGroup from '../TargetingGeneralGroup/TargetingGeneralGroup';
import TargetingGroups from '../TargetingGroups/TargetingGroups';
import TargetingTemplateModal from '../TargetingTemplateModal/TargetingTemplateModal';
import useStyles from './TargetingGroupings.styles';

export interface ITargetingGroupsProps {
  showTemplates?: boolean;
  showGeneralTargeting?: boolean;
  filterByActiveTerritory?: boolean;
  targetingVersion?: TargetingVersion;
  isTargetingVersionToggleDisabled?: boolean;
  hasTargetingVersionToggle?: boolean;
}

export const handleTargetingMasterTemplatesSelection = (
  masterTemplate: ITargetingTemplate,
  push: (obj: any) => void,
  setModalOpen: (arg0: boolean) => void,
  isModalOpen: boolean
) => {
  push({
    masterId: masterTemplate.id,
    masterName: masterTemplate.name,
    edited: false,
    groups: formatInitialTargetingGroups(
      masterTemplate.targetingGroups.map((g: ITargetingGroup) => ({
        ...g,
        id: g.id,
        masterTargetingGroupId: g.id,
        channels:
          g.channels && g.channels.length
            ? g.channels.map((c: any) => ({ ...c, readOnly: true }))
            : [],
        audienceParams: g.audienceParams.flatMap((a: any) =>
          addReadOnlyValues(a, [a], a.__typename)
        ),
        listenerAccuracy: {
          value: g.listenerAccuracy,
          readOnly: true,
          readOnlyType: true,
          edited: true,
        },
      }))
    ),
  });

  setModalOpen(!isModalOpen);
};

export const handleTargetingGroupsSelection = (
  targetingGroup: ITargetingTemplate,
  push: (obj: any) => void,
  setModalOpen: (arg0: boolean) => void,
  isModalOpen: boolean
) => {
  formatInitialTargetingGroups(
    targetingGroup.targetingGroups.map((g: ITargetingGroup) => ({
      ...g,
      id: '',
      masterTargetingGroupId: '',
      targetingGroupId: '',
      channels:
        g.channels && g.channels.length
          ? g.channels.map((c: any) => ({ ...c, readOnly: false }))
          : [],
      audienceParams: g.audienceParams.flatMap((a: any) =>
        addReadOnlyValues(a, [], a.__typename)
      ),
      listenerAccuracy: {
        value: g.listenerAccuracy,
        readOnly: false,
        readOnlyType: false,
        edited: false,
      },
    }))
  ).map((group: any) =>
    push({
      id: '',
      masterTargetingGroupId: '',
      targetingGroupId: '',
      audienceParams: group.audienceParams,
      channels: group.channels,
      templateName: targetingGroup.name,
    })
  );

  setModalOpen(!isModalOpen);
};

const TargetingGroupings = (props: IFormProps<any> & ITargetingGroupsProps) => {
  const {
    values,
    showTemplates = true,
    showGeneralTargeting = true,
    setFieldValue,
    errors,
    filterByActiveTerritory = false,
    targetingVersion,
    isTargetingVersionToggleDisabled,
    hasTargetingVersionToggle = true,
  } = props;
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const userPermissions = useUserPermissions();

  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const variables = filterByActiveTerritory
    ? { territories: [activeTerritory] }
    : { territories: values?.territory ? [values.territory] : [] };

  const { loading: loadingTemplates, data: templates } =
    useAllTargetingTemplatesQuery({
      skip: !isModalOpen,
      variables,
    });

  const { data: publishers } = useAllPublishersAndChannelsQuery({ variables });

  const targetingMissingError = checkForTargetingMissingErrors(errors);

  const isExperimentalUser = userPermissions.includes(
    PermissionName.Experimental
  );

  const switchCheckedValue =
    values.targetingVersion === TargetingVersion.TargetingV2;

  return (
    <FieldArray name="targetingMasterTemplates">
      {({ push: masterPush, remove: masterRemove }) => (
        <FieldArray name="targetingGroups">
          {({ push: groupPush, remove: groupRemove }) => (
            <>
              <div className={classes.buttonGroupContainer}>
                {showTemplates && (
                  <>
                    <div
                      data-tc="targetingTemplateModalButton"
                      className={classes.templateButtonContainer}
                    >
                      <StyledButton
                        color={ButtonColorEnum.Primary}
                        onClick={() => setModalOpen(!isModalOpen)}
                        variant={ButtonVariantEnum.Outlined}
                        isLoading={loadingTemplates}
                        testId="targeting-template-button"
                      >
                        Apply Targeting Template
                      </StyledButton>
                    </div>
                    {isModalOpen && !loadingTemplates && (
                      <TargetingTemplateModal
                        data-tc="targetingTemplatesModal"
                        data-testid="targetingTemplatesModal"
                        isModalOpen={isModalOpen}
                        setModalOpen={() => setModalOpen(false)}
                        allTargetingTemplates={
                          (
                            templates as unknown as IGetAllTargetingTemplatesResponse
                          )?.allTargetingTemplates || []
                        }
                        onSelect={(targetingGroups: any[]) => {
                          targetingGroups.map((targetingGroup: any) =>
                            targetingGroup.isMasterTemplate
                              ? handleTargetingMasterTemplatesSelection(
                                  targetingGroup,
                                  masterPush,
                                  setModalOpen,
                                  isModalOpen
                                )
                              : handleTargetingGroupsSelection(
                                  targetingGroup,
                                  groupPush,
                                  setModalOpen,
                                  isModalOpen
                                )
                          );
                        }}
                      />
                    )}
                  </>
                )}
                {isExperimentalUser &&
                targetingVersion &&
                hasTargetingVersionToggle ? (
                  <FormControl component={'fieldset' as 'div'}>
                    <FormControlLabel
                      control={
                        <Field
                          component={TargetingVersionSwitch}
                          type="checkbox"
                          name="targetingVersion"
                          data-tc="entityTargetingVersion"
                          checked={switchCheckedValue}
                          disabled={isTargetingVersionToggleDisabled}
                        />
                      }
                      label="Use Targeting 2.0"
                    />
                  </FormControl>
                ) : null}
              </div>
              {showGeneralTargeting
                ? [
                    values.targetingGeneralGroup ? (
                      <TargetingGeneralGroup
                        key={0}
                        {...props}
                        errors={errors}
                        remove={() => {
                          setFieldValue('targetingGeneralGroup', null);
                        }}
                      />
                    ) : (
                      <div className={classes.button} key={0}>
                        <StyledButton
                          data-tc="targetingGeneralGroupAddIcon"
                          color={ButtonColorEnum.Primary}
                          onClick={() => {
                            setFieldValue('targetingGeneralGroup', {
                              id: '',
                              listenerAccuracy: null,
                              audienceParams: [],
                              channels: [],
                            });
                          }}
                          variant={ButtonVariantEnum.Outlined}
                        >
                          <AddIcon />
                          <span>Add General Targeting Group</span>
                        </StyledButton>
                      </div>
                    ),
                  ]
                : null}

              {values.targetingMasterTemplates &&
                values.targetingMasterTemplates.length > 0 &&
                values.targetingMasterTemplates.map(
                  (template: ITargetingMasterTemplate, templateIndex: number) =>
                    template.groups &&
                    template.groups.length > 0 && (
                      /* eslint-disable react/no-array-index-key */
                      <div
                        key={`template-${templateIndex}`}
                        className={classNames({
                          [`${classes.template}`]: template.masterId,
                        })}
                      >
                        {template.masterId && (
                          <IconButton
                            data-tc="targetingRemoveMasterTemplateIcon"
                            onClick={() => masterRemove(templateIndex)}
                            className={classes.iconRemove}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        {template.masterId && (
                          <p
                            className={classes.templateHeading}
                            data-tc="targetingMasterTemplateName"
                          >
                            {template.masterName}{' '}
                            {template.edited && <>[Edited]</>}
                          </p>
                        )}
                        <TargetingGroups
                          {...props}
                          errors={errors}
                          data-tc="targetingGroupsMasterTemplates"
                          groups={template.groups}
                          masterId={template.masterId}
                          templateIndex={templateIndex}
                          allPublishers={
                            (publishers?.allPublishers as Publisher[]) || []
                          }
                        />
                      </div>
                    )
                )}

              {values.targetingGroups && values.targetingGroups.length > 0 && (
                <TargetingGroups
                  {...props}
                  errors={errors}
                  data-tc="targetingGroupsBlock"
                  groups={values.targetingGroups}
                  remove={groupRemove}
                  allPublishers={
                    (publishers?.allPublishers as Publisher[]) || []
                  }
                />
              )}
              <div className={classes.button}>
                <StyledButton
                  data-tc="targetingGroupsAddIcon"
                  variant={ButtonVariantEnum.Outlined}
                  color={ButtonColorEnum.Primary}
                  onClick={() =>
                    groupPush({
                      id: '',
                      masterTargetingGroupId: '',
                      targetingGroupId: '',
                      audienceParams: [],
                      channels: [],
                    })
                  }
                >
                  <AddIcon />
                  <span>Add a Targeting Group</span>
                </StyledButton>
              </div>
              {targetingMissingError && (
                <FormHelperText error data-testid="targetingMissingError">
                  <span>{targetingMissingError}</span>
                </FormHelperText>
              )}
            </>
          )}
        </FieldArray>
      )}
    </FieldArray>
  );
};

export default TargetingGroupings;
