import { ApolloQueryResult } from 'apollo-client';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import DynamicTable, {
  IFetchData,
  IPaginationCursor,
} from 'components/DynamicTable/DynamicTable';
import ImagePopover from 'components/ImagePopover/ImagePopover';
import Redirecting from 'components/Redirecting/Redirecting';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useCtaFooterStyles } from 'components/Table/Table.styles';
import TableDateRangeFilter from 'components/Table/TableDateRangeFilter';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  ColumnSelect,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import debounce from 'debounce-promise';
import ProgrammaticCreativeModal from 'features/programmatic/creative/components/ProgrammaticCreativeModal/ProgrammaticCreativeModal';
import {
  IProgrammaticCreativesRow,
  programmaticCreativeStatusValues,
} from 'features/programmatic/creative/components/ProgrammaticCreativesTable/ProgrammaticCreativesValues';
import {
  GET_ALL_PAGINATED_PROGRAMMATIC_CREATIVES,
  GET_ALL_PROGRAMMATIC_CREATIVES_MINIMAL,
  GET_TOTAL_PROGRAMMATIC_CREATIVES,
} from 'features/programmatic/creative/graphql/queries';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  ApprovalStatus,
  EntityType,
  IabCategory,
  OrderingDirection,
  PageInfo,
  PaginatedProgrammaticCreativesQuery,
  PaginatedProgrammaticCreativesQueryVariables,
  ProgrammaticCreative,
  ProgrammaticCreativeAudioSpec,
  ProgrammaticCreativeConnection,
  ProgrammaticCreativeOrderable,
  ProgrammaticCreativeType,
  Territory,
  useAllIabCategoriesQuery,
  useUpdateProgrammaticCreativeMutation,
} from 'interfaces/generated.types';
import find from 'lodash/find';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';
import React, { useMemo, useState } from 'react';
import { CellProps, SortingRule } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

import { Button, Checkbox, MenuItem } from '@material-ui/core';

import ProgrammaticCreativeBulkApprovalModal from '../ProgrammaticCreativeBulkApprovalModal/ProgrammaticCreativeBulkApprovalModal';
import useStyles from './ProgrammaticCreativesTable.styles';

const getAllIabCategoriesArray = memoizeOne((iabCategories: IabCategory[]) =>
  iabCategories.reduce((result: any[], iabCategory: IabCategory) => {
    const { subIabCategories, ...rest } = iabCategory;
    return [...result, rest, ...subIabCategories];
  }, [])
);

export const formatProgrammaticCreativesData = memoizeOne(
  (
    data: ProgrammaticCreative[],
    iabCategories: IabCategory[],
    territory: Territory | undefined
  ) => {
    const allIabCategoriesArray = getAllIabCategoriesArray(iabCategories);

    return data?.map((d) => {
      const hasIabCategories =
        d.iabCategoryCodes && d.iabCategoryCodes.length > 0;

      let relevantCategories: string[] = [];

      if (hasIabCategories) {
        relevantCategories = d.iabCategoryCodes.map((code) => {
          const selectedCategory = allIabCategoriesArray.find(
            (category: { code: string }) => category.code === code
          );

          if (selectedCategory) {
            return `${selectedCategory.code}: ${selectedCategory.name}`;
          }

          return code;
        });
      }

      return {
        id: d.id,
        name: d.name as string,
        deal: d.firstSeenDeal
          ? {
              id: d.firstSeenDeal.id,
              name: d.firstSeenDeal.name,
            }
          : null,
        deals: d.deals,
        advertiserDomains:
          d.advertiserDomains && d.advertiserDomains.length > 0
            ? d.advertiserDomains.join(', ')
            : null,
        firstSeen: d.firstBidDate
          ? getFormattedDateBasedOnTerritory(
              new Date(d.firstBidDate),
              territory
            )
          : null,
        status: d.status,
        type: d.type,
        fileName: d.name || null,
        url: d.url || '',
        firstBidDate: d.firstBidDate || null,
        approvalDate: d.approvalDate || null,
        iabCategoryCodes: hasIabCategories
          ? relevantCategories.join(', ')
          : null,
        duration:
          d.type === ProgrammaticCreativeType.ProgrammaticAudio &&
          d.spec &&
          (d.spec as ProgrammaticCreativeAudioSpec).duration
            ? numbro((d.spec as ProgrammaticCreativeAudioSpec).duration).format(
                { mantissa: 3 }
              )
            : 'N/A',
        spec: d.spec,
        territories: d.territories,
      };
    });
  }
);

export const programmaticCreativeColumnMappings = [
  {
    id: 'name',
    ordering: ProgrammaticCreativeOrderable.Name,
    filter: 'partialNameMatch',
  },
  {
    id: 'duration',
    ordering: ProgrammaticCreativeOrderable.Duration,
    filter: 'partialDurationMatch',
  },
  {
    id: 'firstSeen',
    ordering: ProgrammaticCreativeOrderable.FirstBidDate,
    filter: ['firstBidDateOnOrAfter', 'firstBidDateOnOrBefore'],
  },
  {
    id: 'deal.name',
    ordering: ProgrammaticCreativeOrderable.DealName,
    filter: 'partialDealNameMatch',
  },
  {
    id: 'status',
    ordering: ProgrammaticCreativeOrderable.Status,
    filter: 'status_in',
  },
  {
    id: 'advertiserDomains',
    ordering: ProgrammaticCreativeOrderable.AdvertiserDomain,
    filter: 'partialAdvertiserDomainMatch',
  },
  {
    id: 'iabCategoryCodes',
    ordering: ProgrammaticCreativeOrderable.IabCategory,
    filter: 'partialIabCategoryMatch',
  },
  {
    id: 'deal.ids',
    ordering: ProgrammaticCreativeOrderable.DealName,
    filter: 'dealIds',
  },
];

export const setPagination = (cursor: IPaginationCursor, pageSize: number) => ({
  ...(!cursor.start && !cursor.last && { first: pageSize }),
  ...((cursor.start || (!cursor.start && !cursor.end && cursor.last)) && {
    last: pageSize,
  }),
  ...(cursor.end && { after: cursor.end }),
  ...(cursor.start && { before: cursor.start }),
});

export const setOrdering = (sortBy: SortingRule<string>[]) => ({
  ordering: {
    orderableField: get(
      find(programmaticCreativeColumnMappings, { id: sortBy[0].id }),
      'ordering',
      ''
    ),
    direction: sortBy[0].desc ? OrderingDirection.Desc : OrderingDirection.Asc,
  },
});

export const setFilters = (
  filters: { id: string; value: string | string[] }[]
) =>
  programmaticCreativeColumnMappings
    .map((val) => ({
      ...val,
      ...filters.find((filter) => filter.id === val.id),
    }))
    .map((tm) => {
      // If we are applying multiple filters to a single column
      if (Array.isArray(tm.filter)) {
        // And there are values provided for the filters
        if ((tm as any).value) {
          let formattedFilters = {};
          tm.filter.forEach((filterName, index) => {
            formattedFilters = {
              ...formattedFilters,
              [filterName]: (tm as any).value[index],
            };
          });
          return formattedFilters;
        }
        return undefined;
      }

      return { [tm.filter]: (tm as any).value };
    })
    .reduce((acc, val) => ({ ...val, ...acc }), {});

export const getPaginationPayload = ({
  cursor,
  filters,
  globalFilter,
  sortBy,
  pageSize,
  activeTerritory,
}: IFetchData): PaginatedProgrammaticCreativesQueryVariables =>
  ({
    ...setPagination(cursor, pageSize),
    ...(sortBy.length && {
      ...setOrdering(sortBy),
    }),
    ...((globalFilter || filters.length) && {
      filter: {
        ...(globalFilter && { anyPartialMatch: globalFilter }),
        ...(filters.length && setFilters(filters)),
      },
    }),
    territories: [activeTerritory],
  } as PaginatedProgrammaticCreativesQueryVariables);

const handleRequest = async ({
  getProgrammaticCreatives,
  getTotalProgrammaticCreatives,
  setData,
  setPageInfo,
  setTotalCount,
  setLoading,
  setError,
  setLatestPayload,
  fetchArgs,
}: {
  getProgrammaticCreatives: (variables: Record<string, any>) => Promise<
    ApolloQueryResult<{
      paginateProgrammaticCreatives: ProgrammaticCreativeConnection;
    }>
  >;
  getTotalProgrammaticCreatives: (variables: Record<string, any>) => Promise<
    ApolloQueryResult<{
      totalProgrammaticCreatives: number;
    }>
  >;
  setData: (arg: any) => void;
  setTotalCount: (arg: number) => void;
  setPageInfo: (arg?: PageInfo) => void;
  setLoading: (arg: boolean) => void;
  setError: (arg: string) => void;
  setLatestPayload: (arg: PaginatedProgrammaticCreativesQueryVariables) => void;
  fetchArgs: IFetchData;
}) => {
  try {
    setLoading(true);
    const payload = getPaginationPayload(fetchArgs);
    const { data } = await getProgrammaticCreatives(payload);

    const { data: totalData } = await getTotalProgrammaticCreatives(payload);

    if (data && totalData) {
      setData(data);
      setLatestPayload(payload);
      setTotalCount(totalData.totalProgrammaticCreatives);
      setLoading(false);
      setPageInfo(data.paginateProgrammaticCreatives.pageInfo);
    }
  } catch (error: any) {
    setLoading(false);
    setError(error);
  }
};

const debounceRequest = debounce(handleRequest, 250, {
  leading: true,
});

const getProgrammaticCreativeNodes = (
  data: PaginatedProgrammaticCreativesQuery
): ProgrammaticCreative[] =>
  data?.paginateProgrammaticCreatives?.edges?.map(
    (edge) => edge.node as ProgrammaticCreative
  ) || [];

interface IProgrammaticCreativesTableProps {
  isEditable?: boolean;
  dealView?: boolean;
  constantFilter?: any;
  title?: string;
  allowBulkEdit?: boolean;
  isPageTable?: boolean;
  history: History;
}

interface ISelectedProgrammaticCreative {
  id: string;
  status: ApprovalStatus;
}

const ProgrammaticCreativesTable = ({
  isEditable = true,
  dealView = false,
  isPageTable = true,
  allowBulkEdit = false,
  constantFilter,
  history,
}: IProgrammaticCreativesTableProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [data, setData] = useState<{
    paginateProgrammaticCreatives: ProgrammaticCreativeConnection;
  } | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalProgrammaticCreatives, setTotalProgrammaticCreatives] =
    useState<number>(0);
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>(
    {} as PageInfo
  );
  const [latestPayload, setLatestPayload] =
    useState<PaginatedProgrammaticCreativesQueryVariables>({
      first: 15,
      territories: [activeTerritory!],
    });
  const [selectedRows, setSelectedRows] = useState<
    ISelectedProgrammaticCreative[]
  >([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [confirmEntityType, setConfirmEntityType] = useState<ApprovalStatus>(
    ApprovalStatus.Approved
  );

  const location = usePreviousLocation();

  const classes = useStyles();
  const ctaFooterClasses = useCtaFooterStyles();

  const getProgrammaticCreatives = useLazyQuery<{
    paginateProgrammaticCreatives: ProgrammaticCreativeConnection;
  }>(GET_ALL_PAGINATED_PROGRAMMATIC_CREATIVES);

  const getTotalProgrammaticCreatives = useLazyQuery<{
    totalProgrammaticCreatives: number;
  }>(GET_TOTAL_PROGRAMMATIC_CREATIVES);

  const getAllProgrammaticCreativesMinimal = useLazyQuery<{
    paginateProgrammaticCreatives: ProgrammaticCreativeConnection;
  }>(GET_ALL_PROGRAMMATIC_CREATIVES_MINIMAL);

  const validateProgrammaticCreative =
    useLazyQuery<IGetAffectedEntitiesResponse>(GET_ALL_AFFECTED_ENTITIES);

  const { data: iabCategoriesData } = useAllIabCategoriesQuery({
    skip: !data?.paginateProgrammaticCreatives?.edges?.length,
  });

  const [updateProgrammaticCreative] = useUpdateProgrammaticCreativeMutation({
    onCompleted: async () => {
      const { data: newData } = await getProgrammaticCreatives(latestPayload);
      setData(newData);
    },
  });

  const combineWithConstantFilter = (fetchArgs: IFetchData): IFetchData => {
    if (!constantFilter) {
      return fetchArgs;
    }

    return { ...fetchArgs, filters: [...fetchArgs.filters, constantFilter] };
  };

  const mapToSelectedRows = (mapData: PaginatedProgrammaticCreativesQuery) => {
    const allCreatives = getProgrammaticCreativeNodes(mapData).map(
      (creative) =>
        ({
          id: creative.id,
          status: creative.status,
        } as ISelectedProgrammaticCreative)
    );

    setSelectedRows(allCreatives);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Creative',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<IProgrammaticCreativesRow>) =>
          row.original.type === ProgrammaticCreativeType.ProgrammaticAudio ? (
            <AudioPlayer src={row.original.url} />
          ) : (
            <ImagePopover fileName={row.original.name} url={row.original.url} />
          ),
      },
      {
        Header: 'Creative Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          maxWidth: '300px',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<IProgrammaticCreativesRow>) => (
          <ProgrammaticCreativeModal
            programmaticCreative={row.original}
            updateProgrammaticCreative={updateProgrammaticCreative}
          />
        ),
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Advertiser Domains',
        accessor: 'advertiserDomains',
      },
      {
        Header: 'IAB Categories',
        accessor: 'iabCategoryCodes',
      },
      ...(!dealView
        ? [
            {
              Header: 'Deals',
              accessor: 'deal.name',
              style: {
                wordBreak: 'break-word',
              },
              // eslint-disable-next-line react/display-name
              Cell: ({
                cell: { value, row },
              }: CellProps<IProgrammaticCreativesRow>) => (
                <>
                  {value &&
                    TableLink({
                      name:
                        row.original.deals.length > 1 ? `${value}, ` : value,
                      location: {
                        pathname: `/deal/${row.original.deal?.id}`,
                        state: {
                          parent: location.state,
                          from: location.pathname,
                        },
                      },
                    })}
                  {row.original.deals.length > 1 && (
                    <ProgrammaticCreativeModal
                      label="more deals"
                      programmaticCreative={row.original}
                      updateProgrammaticCreative={updateProgrammaticCreative}
                      testId={`${row.original.id}-moreDeals`}
                    />
                  )}
                </>
              ),
            },
          ]
        : []),
      {
        Header: 'First seen',
        accessor: 'firstSeen',
        Filter: TableDateRangeFilter,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IProgrammaticCreativesRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: programmaticCreativeStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={programmaticCreativeStatusValues}
                name="programmaticCreativeStatusSelect"
                dataTc="programmaticCreativeStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      ...(allowBulkEdit
        ? [
            {
              Header: 'Bulk Editing',
              accessor: 'bulk',
              Filter: () => (
                <ColumnSelect text="Select all">
                  <MenuItem dense>
                    <Button
                      onClick={async () => {
                        if (
                          totalProgrammaticCreatives >
                          getProgrammaticCreativeNodes(
                            data as PaginatedProgrammaticCreativesQuery
                          ).length
                        ) {
                          const { data: progCreatives } =
                            await getAllProgrammaticCreativesMinimal({
                              first: totalProgrammaticCreatives,
                              filter: latestPayload.filter,
                              territories: [activeTerritory!],
                            });

                          mapToSelectedRows(progCreatives);
                        } else {
                          mapToSelectedRows(
                            data as PaginatedProgrammaticCreativesQuery
                          );
                        }
                      }}
                      data-testid="selectAllButton"
                      disabled={!latestPayload.filter}
                    >
                      Select All
                    </Button>
                  </MenuItem>
                  <MenuItem dense>
                    <Button
                      onClick={() => {
                        setSelectedRows([]);
                      }}
                      data-testid="deselectButton"
                    >
                      Deselect All
                    </Button>
                  </MenuItem>
                </ColumnSelect>
              ),
              Cell: ({
                cell: { row },
              }: CellProps<IProgrammaticCreativesRow>) => (
                <Checkbox
                  color="primary"
                  data-testid={`checkbox-${row.values.id}`}
                  className={classes.checkbox}
                  checked={selectedRows.some(
                    (selectedRow) => selectedRow.id === row.values.id
                  )}
                  onChange={() => {
                    if (
                      selectedRows.some(
                        (selectedRow) => selectedRow.id === row.values.id
                      )
                    ) {
                      setSelectedRows(
                        selectedRows.filter(
                          (selectedRow) => selectedRow.id !== row.values.id
                        )
                      );
                    } else {
                      setSelectedRows([
                        ...selectedRows,
                        {
                          id: row.values.id,
                          status: row.values.status,
                        } as IProgrammaticCreativesRow,
                      ]);
                    }
                  }}
                />
              ),
            },
          ]
        : []),
    ],
    [
      dealView,
      updateProgrammaticCreative,
      getAllProgrammaticCreativesMinimal,
      totalProgrammaticCreatives,
      location.state,
      location.pathname,
      isEditable,
      classes,
      selectedRows,
      latestPayload,
      data,
      allowBulkEdit,
      activeTerritory,
    ]
  );

  if (!loading && error && !data) return <Redirecting history={history} />;

  const onClick = (status: ApprovalStatus) => () => {
    selectedRows.forEach((row: ISelectedProgrammaticCreative) => {
      if (row.status !== status) {
        updateProgrammaticCreative({
          variables: {
            id: row.id,
            status,
          },
          context: {
            batch: true,
          },
        });
      }
    });
    setSelectedRows([]);
  };

  return (
    <>
      <DynamicTable
        activeTerritory={activeTerritory!}
        columns={columns}
        data={formatProgrammaticCreativesData(
          getProgrammaticCreativeNodes(
            data as PaginatedProgrammaticCreativesQuery
          ) || [],
          (iabCategoriesData?.allIabCategories as IabCategory[]) || [],
          activeTerritory
        )}
        loading={loading}
        pageInfo={pageInfo}
        totalEntities={totalProgrammaticCreatives}
        dataTc={`${
          latestPayload.filter?.dealIds &&
          latestPayload.filter?.dealIds.length > 0
            ? latestPayload.filter?.dealIds[0]
            : ''
        }listProgrammaticCreativesTable`}
        isPageTable={isPageTable}
        hiddenColumns={['bulk']}
        entityType={ApprovalStatus}
        fetchData={(fetchArgs: IFetchData) =>
          debounceRequest({
            getProgrammaticCreatives,
            getTotalProgrammaticCreatives,
            setData,
            setLoading,
            setError,
            setPageInfo,
            setTotalCount: setTotalProgrammaticCreatives,
            setLatestPayload,
            fetchArgs: combineWithConstantFilter(fetchArgs),
          })
        }
        onCellUpdate={(
          row: IProgrammaticCreativesRow,
          setErrorModal,
          setUpdating
        ) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateProgrammaticCreative,
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: '',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateProgrammaticCreative,
            entity: { ...entity, type: EntityType.ProgrammaticCreative },
            setErrorModal,
            setWarningModal,
            setUpdating,
            handleContinue,
          })
        }
      />
      {allowBulkEdit && (
        <div className={ctaFooterClasses.footer}>
          <StyledButton
            onClick={() => {
              setConfirmEntityType(ApprovalStatus.Blocked);
              setConfirmModalOpen(true);
            }}
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            isLoading={loading}
            disabled={!(selectedRows.length > 0)}
            testId="blockCreativesButton"
            className={ctaFooterClasses.button}
          >
            Block creatives selected
          </StyledButton>
          <StyledButton
            onClick={() => {
              setConfirmEntityType(ApprovalStatus.Approved);
              setConfirmModalOpen(true);
            }}
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            isLoading={loading}
            testId="approveCreativesButton"
            disabled={!(selectedRows.length > 0)}
            className={ctaFooterClasses.button}
          >
            Approve creatives selected
          </StyledButton>
        </div>
      )}
      <ProgrammaticCreativeBulkApprovalModal
        onConfirm={onClick(confirmEntityType)}
        isOpen={confirmModalOpen}
        approvalStatus={confirmEntityType}
        closeModal={() => setConfirmModalOpen(false)}
      />
    </>
  );
};

export default ProgrammaticCreativesTable;
