import { IContextMenuItemType } from 'features/targetingV2/components/core/IconButtonMenuItem/IconButtonMenuItem';
import ParameterRow from 'features/targetingV2/components/ParameterRow/ParameterRow';
import ParameterRowWithDoubleCardinality from 'features/targetingV2/components/ParameterRowWithDoubleCardinality/ParameterRowWithDoubleCardinality';
import { IOptionType, SectionType } from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import { defaultParameter } from 'features/targetingV2/utils/defaults';
import {
  Cardinality,
  LocationParameterInfo,
  Territory,
} from 'interfaces/generated.types';
import React from 'react';

export interface IParameterRowContainerProps {
  parameterIndex: number;
  sectionType: SectionType;
  groupFieldName: string;
  parameterTypeOptions: IOptionType[];
  areParameterTypeOptionsLoading: boolean;
  allParametersInfo: LocationParameterInfo[];
  allSelectedParameters: IParameter[];
  setFieldValue: (field: string, value: any) => void;
  removeParameter: (index: number) => void;
  insertParameter: (index: number, value: any) => void;
  removeSection?: () => void;
  parameterValuesDropdownPlaceholder?: string;
  idUsedToLimitParameterValues?: string;
  isParameterMandatory?: boolean;
  isParameterSelectReadOnly?: boolean;
  territory?: Territory;
  OptionalAccuracyRow?: React.ElementType<any>;
  additionalMenuOptions?: IContextMenuItemType[];
}

const ParameterRowContainer = (props: IParameterRowContainerProps) => {
  const {
    parameterIndex,
    sectionType,
    groupFieldName,
    parameterTypeOptions,
    areParameterTypeOptionsLoading,
    allParametersInfo,
    allSelectedParameters,
    setFieldValue,
    insertParameter,
    removeParameter,
    removeSection,
    parameterValuesDropdownPlaceholder = '',
    idUsedToLimitParameterValues = '',
    isParameterMandatory = false,
    isParameterSelectReadOnly = false,
    territory,
    OptionalAccuracyRow,
    additionalMenuOptions,
  } = props;

  const parameter = allSelectedParameters[parameterIndex];

  const selectedParameterCardinality = allParametersInfo.find(
    (item) => item.type === parameter.type
  )?.cardinality;

  const isCardinalityOnePerClusivity =
    selectedParameterCardinality === Cardinality.OnePerClusivity;

  const handleParameterDeletion = (index: number) => {
    if (allSelectedParameters.length === 1 && removeSection) {
      removeSection();
    } else removeParameter(index);
  };

  const onParameterChange = (index: number) => {
    const parameterFieldName = `${groupFieldName}.parameters[${index}]`;

    setFieldValue(
      `${parameterFieldName}.clusivity`,
      defaultParameter.clusivity
    );
    setFieldValue(`${parameterFieldName}.values`, defaultParameter.values);
    setFieldValue(`${parameterFieldName}.isFocused`, true);
  };

  return (
    <>
      {isCardinalityOnePerClusivity ? (
        <ParameterRowWithDoubleCardinality
          // eslint-disable-next-line react/no-array-index-key
          key={`${groupFieldName}-parameter${parameterIndex}`}
          sectionType={sectionType}
          groupFieldName={groupFieldName}
          parameterIndex={parameterIndex}
          allSelectedParameters={allSelectedParameters}
          parameterTypeOptions={parameterTypeOptions}
          areParameterTypeOptionsLoading={areParameterTypeOptionsLoading}
          setFieldValue={setFieldValue}
          removeParameter={handleParameterDeletion}
          insertParameter={insertParameter}
          onParameterTypeChange={onParameterChange}
          isParameterSelectReadOnly={isParameterSelectReadOnly}
          parameterValuesDropdownPlaceholder={
            parameterValuesDropdownPlaceholder
          }
          idUsedToLimitParameterValues={idUsedToLimitParameterValues}
          territory={territory}
          isParameterMandatory={isParameterMandatory}
          parameterRowId={`${sectionType} ${parameter.type} Parameter Row ${
            parameterIndex + 1
          }`}
          OptionalAccuracyRow={OptionalAccuracyRow}
          additionalMenuOptions={additionalMenuOptions}
        />
      ) : (
        <ParameterRow
          // eslint-disable-next-line react/no-array-index-key
          key={`${groupFieldName}-parameter${parameterIndex}`}
          sectionType={sectionType}
          parameterFieldName={`${groupFieldName}.parameters[${parameterIndex}]`}
          selectedParameter={parameter}
          parameterTypeOptions={parameterTypeOptions}
          areParameterTypeOptionsLoading={areParameterTypeOptionsLoading}
          setFieldValue={setFieldValue}
          removeParameter={() => handleParameterDeletion(parameterIndex)}
          onParameterTypeChange={() => {
            onParameterChange(parameterIndex);
          }}
          parameterValuesDropdownPlaceholder={
            parameterValuesDropdownPlaceholder
          }
          idUsedToLimitParameterValues={idUsedToLimitParameterValues}
          territory={territory}
          isParameterMandatory={isParameterMandatory}
          parameterRowId={`${sectionType} ${parameter.type} Parameter Row ${
            parameterIndex + 1
          }`}
        />
      )}
    </>
  );
};

export default ParameterRowContainer;
