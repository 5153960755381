import { makeStyles, Theme } from '@material-ui/core/styles';

import { baseButtonStyles } from 'assets/styles/components/Button.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseButtonStyles(theme),
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  loader: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
}));

export default useStyles;
