import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@material-ui/core';

import useStyles from './Tooltip.styles';

export type TooltipProps = MUITooltipProps;

const Tooltip = React.forwardRef((props: TooltipProps, ref: React.Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);
  return (
    <MUITooltip
      ref={ref}
      classes={classes}
      data-testid="tooltip"
      PopperProps={{
        modifiers: {
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        },
      }}
      {...otherProps}
    />
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;
