import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    margin: 'auto',
  },
  dialog: {
    width: 'fit-content',
    margin: 'auto',
  },
  dialogOptions: {
    margin: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));

export default useStyles;
