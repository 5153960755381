import { format, isValid } from 'date-fns';
import * as Yup from 'yup';

import dateUtils from 'utils/date';

import commons from 'validations/commons';
import { OrderExternalSystems } from 'utils/defaultExternalSystems';
import { Territory } from 'interfaces/generated.types';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';

const OrderFormValidation = (territory: Territory | undefined) =>
  Yup.object().shape(
    {
      name: commons.name('Order Name'),
      ...commons.getFrequencyCapFields('Order Frequency Cap'),
      owner: Yup.object()
        .shape({
          label: Yup.string().required('Owner label must be present'),
          value: Yup.string().required('Owner value must be present'),
        })
        .required('Owner is a required field')
        .nullable(),
      externalId: commons
        .max255Characters()
        .trim()
        .when(['externalSystemId'], {
          is: (externalSystemId) => !!externalSystemId,
          then: Yup.string().required(
            'External ID is required when external system is provided'
          ),
        })
        .when(['externalSystemId'], {
          is: (externalSystemId) =>
            externalSystemId === OrderExternalSystems.GPLAN,
          then: Yup.string()
            .matches(/^[0-9]*$/, {
              excludeEmptyString: true,
              message: 'External ID should only contain numbers',
            })
            .min(7, 'External ID should be 7 digits')
            .max(7, 'External ID should be 7 digits'),
        }),
      externalSystemId: commons.max255Characters().when(['externalId'], {
        is: (externalId) => !!externalId,
        then: Yup.string().required(
          'External System is required when external id is provided'
        ),
      }),
      advertiser: Yup.object()
        .shape({
          label: Yup.string().required('Advertiser label must be present'),
          value: Yup.string().required('Advertiser value must be present'),
        })
        .required('Advertiser is a required field')
        .nullable(),
      timeZone: Yup.string().when(['startDate', 'endDate'], {
        is: (startDate: Date, endDate: Date) => !!startDate || !!endDate,
        then: Yup.string().required(
          'Timezone is required when a start or end date is added'
        ),
      }),
      objective: commons
        .optionalPrice({
          messages: {
            minMessage: 'Order Objective should be higher than or equal to 1',
            maxMessage:
              'Order Objective should be lower than or equal to 1,000,000,000',
          },
          minimum: 1,
          maximum: 1000000000,
          fieldKey: 'objective',
        })
        .when(['unlimitedObjective'], {
          is: (unlimitedObjective) => !unlimitedObjective,
          then: Yup.string().required('Order Objective is a required field'),
        }),
      startDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Order Start Date should be a valid date')
        .when('endDate', {
          is: (endDate: Date) => !!endDate,
          then: Yup.date().required(
            'Start date is required when an end date is added'
          ),
        }),
      endDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Order End Date should be a valid date')
        .when(
          ['startDate', 'timeZone'],
          (startDate: Date | null, timeZone: any, schema: Yup.DateSchema) => {
            if (!isValid(startDate)) {
              return schema;
            }
            return schema.test(
              'endDate',
              `Order End Date should be after ${format(
                startDate as Date,
                getDateFormatBasedOnTerritory(territory, true),
                {
                  useAdditionalWeekYearTokens: true,
                  useAdditionalDayOfYearTokens: true,
                }
              )}`,
              (endDate: Date) => {
                if (!isValid(endDate)) {
                  return true;
                }
                return dateUtils.isDateAfterTheOther({
                  date: endDate,
                  dateToCompare: startDate as Date,
                  timeZone,
                });
              }
            );
          }
        )
        .test(
          'endDate',
          'Order End Date should not be in the past',
          function endDateInThePast(endDate: Date) {
            if (!endDate || !isValid(endDate)) {
              return true;
            }
            const { timeZone } = this.parent;
            return dateUtils.isDateInTheFuture(endDate, timeZone);
          }
        ),
    },
    [
      ['startDate', 'endDate'],
      ['timeZone', 'startDate'],
      ['timeZone', 'endDate'],
      ['endDate', 'objective'],
      ['externalId', 'externalSystemId'],
      ['objective', 'unlimitedObjective'],
      ['frequencyCapCount', 'frequencyCapValue'],
      ['frequencyCapCount', 'frequencyCapTimeUnit'],
      ['frequencyCapValue', 'frequencyCapTimeUnit'],
    ]
  );

export default OrderFormValidation;
