import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(1),
  },
  accuracyContainer: {
    display: 'flex',
    marginRight: '34px',
    alignItems: 'flex-end',
  },
  buttonContainer: {
    paddingTop: theme.spacing(1),
  },
  closeIcon: {
    padding: theme.spacing(0, 0.4),
  },
  sliderIconWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
