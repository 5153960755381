import { OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { useCallback } from 'react';

import { useApolloClient } from '@apollo/react-hooks';

export default function useLazyQuery<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
        fetchPolicy: 'no-cache',
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, query]
  );
}
