import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UpdateDatacenterContainer from 'features/programmatic/datacenter/containers/UpdateDatacenterContainer/UpdateDatacenterContainer';
import { History } from 'history';
import {
  DataCenter,
  PermissionName,
  useDataCenterQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface IUpdateDatacenterPage {
  history: History;
  match: {
    params: {
      datacenterId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Programmatic];

const UpdateDatacenterPage = ({
  history,
  match,
  userPermissions,
}: IUpdateDatacenterPage) => {
  const { loading, data } = useDataCenterQuery({
    variables: { id: match.params.datacenterId },
    errorPolicy: 'all',
  });

  if (!loading && !data?.dataCenter) return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Datacenter</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateDatacenterContainer
            datacenter={data?.dataCenter as DataCenter}
            history={history}
            match={match}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateDatacenterPage;
