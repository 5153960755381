import {
  IMenuDualListProps,
  IOptionGroupType,
  IOptionType,
} from 'features/targetingV2/types/common';
import { OptionType } from 'interfaces';
import { ActionMeta, GroupTypeBase } from 'react-select';
import { SelectComponents } from 'react-select/src/components';

export enum SelectType {
  Default = 'Default',
  Creatable = 'Creatable',
}

export interface IMultiSelectProps {
  id: string;
  name: string;
  options: IOptionGroupType[] | IOptionType[];
  value: IOptionType | IOptionType[] | null;
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  maxSelectHeight?: number | string;
  placeholder?: string;
  onBlur?(): void;
  onChange?(options: any, actionMeta?: ActionMeta<IOptionType>): void;
  noOptionsText?: string | null;
  maxSelectedOptions?: number;
  maxSelectedOptionsText?: string;
  errorProps?: {
    helperText?: string | boolean;
    FormHelperTextProps: {
      error: boolean;
    };
  };
  attributes?: {
    fieldAttribute: string;
    menuAttribute: string;
    chipAttribute: string;
  };
  components?: Partial<
    SelectComponents<IOptionType, boolean, GroupTypeBase<IOptionType>>
  >;
  externalClasses?: {
    root?: string;
    input?: string;
    optionLabelText?: string;
    optionCheckbox?: string;
  };
  externalLink?: boolean;
  testId?: string;
  disableSearch?: boolean;
  isLoading?: boolean;
  openMenuOnClick?: boolean;
  maxSearchResults?: number;
  noOfMultiValuesToShow?: number;
  showBreadcrumbsOnMultiValue?: boolean;
  menuDualListProps?: IMenuDualListProps;
  inactiveMultiValueTooltip?: string;
  selectType?: SelectType;
  clearSearchTermOnSelection?: boolean;
  autoFocus?: boolean;
  openMenuOnFocus?: boolean;
  selectRef?: any;
  [key: string]: any;
}

export const getSelectOptions = ({
  value,
  options,
  maxSelectedOptions,
}: {
  value: IOptionType | IOptionType[] | null;
  options?: IOptionGroupType[] | IOptionType[];
  maxSelectedOptions: number | undefined;
}) => {
  if (!maxSelectedOptions || !value) return options;
  return Object.keys(value).length === maxSelectedOptions ? [] : options;
};

export const isOptionDisabled = (data: IOptionType, values: IOptionType[]) => {
  const selectedValue =
    values &&
    values.length &&
    values.length > 0 &&
    values.find((value: IOptionType) => value.value === data.value);
  return selectedValue ? selectedValue.readOnly : false;
};

export const allOptionsSelected = (children: any, isOptGroup: any) =>
  Array.isArray(children) &&
  children
    .map((child: any) =>
      isOptGroup(child)
        ? child.props.children.every((ch: any) => ch.props.isSelected)
        : child.props.isSelected
    )
    .every(Boolean);

export const handleSelectAll =
  (
    children: any,
    isOptGroup: any,
    isAllSelected: boolean,
    setValue: any,
    selectProps: any
  ) =>
  () => {
    const childrenData = children.flatMap((child: any) =>
      isOptGroup(child)
        ? children.flatMap((c: any) =>
            c.props.children.flatMap((ch: any) => ch.props.data)
          )
        : child.props.data
    );

    if (isAllSelected)
      setValue(
        selectProps.value.filter(
          (selectValue: OptionType) =>
            !childrenData.some(
              (childValue: OptionType) =>
                childValue.value === selectValue.value && !selectValue.readOnly
            )
        ),
        'set-value'
      );
    else {
      const filteredChildrenData = childrenData.filter(
        (selectValue: OptionType) =>
          !selectProps.value.some(
            (childValue: OptionType) => childValue.value === selectValue.value
          )
      );

      setValue([...selectProps.value, ...filteredChildrenData], 'set-value');
    }
  };
