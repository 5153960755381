import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import HealthDashboard from 'components/HealthDashboard/HealthDashboard';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import ProgrammaticCreativesTable from 'features/programmatic/creative/components/ProgrammaticCreativesTable/ProgrammaticCreativesTable';
import DealConnector from 'features/programmatic/deal/components/DealConnector/DealConnector';
import DealDetails from 'features/programmatic/deal/components/DealDetails/DealDetails';
import DealMonitoring from 'features/programmatic/deal/components/DealMonitoring/DealMonitoring';
import {
  dealDetailsFields,
  dealStatusValues,
  getDealStatusData,
  IDealFormValues,
  rtbConnectionFields,
} from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import TargetingVersionSelection from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSelection';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  Deal,
  DealStatus,
  EntityType,
  ProgrammaticCreative,
  SalesChannelStatus,
  TargetingVersion,
  useDealHealthQuery,
  User,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import { getInitialDealHealthDateRange } from './DealTabsFormUtils';

export interface IDealFormProps {
  goBackTo: ILocation;
  allUsers: User[];
  deal?: Deal;
  hasEditPermissions?: boolean;
  creatives?: ProgrammaticCreative[];
  salesChannelStatus?: SalesChannelStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
  loading: boolean;
}

export const dealButtons = [
  {
    text: 'Save and Review',
    url: '/deal/ID',
    update: true,
    dataTc: 'dealReviewButton',
  },
  {
    text: 'Save and View All Deals',
    url: '/sales-channel/PARENT_ID',
    update: false,
    dataTc: 'dealListButton',
  },
];

export const getDefaultTab = (dealStatus?: string, location?: any) => {
  if (location && location.state) {
    const { tab } = location.state;
    if (tab) return tab;
  }
  return dealStatus && dealStatus === DealStatus.Active ? 1 : 2;
};

const getDealDetailsTabErrors = createNumberOfFormErrorsFn();
const getRtbConnectionTabErrors = createNumberOfFormErrorsFn();

const DealTabsForm = (props: IFormProps<IDealFormValues> & IDealFormProps) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update,
    values,
    deal,
    setFieldValue,
    goBackTo,
    hasEditPermissions = true,
    creatives,
    status,
    salesChannelStatus,
    onFieldUpdate,
    onFieldValidate,
    history,
    loading,
  } = props;

  const classes = tabStyles();

  const location = usePreviousLocation();

  const [selectedTab, setTab] = useState(
    getDefaultTab(values.status, location)
  );
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const isDealMonitoringTab = selectedTab === 0;
  const isDealHealthTab = selectedTab === 1;
  const isDealTab = selectedTab === 2;
  const isRtbConnectorTab = selectedTab === 3;
  const isTargetingTab = selectedTab === 4;
  const isListCreativesTab = selectedTab === 5;
  const isDealActivityTab = selectedTab === 6;

  const dealIdsFilter = {
    id: 'deal.ids',
    value: [values.id],
  };

  const { initialStartDate, initialEndDate } = getInitialDealHealthDateRange(
    deal?.startDate,
    deal?.endDate,
    deal?.timeZone
  );

  return (
    <>
      <Form className={classes.form} role="form">
        {update && salesChannelStatus && (
          <>
            <EntityProgressBar
              statusData={getDealStatusData(values, salesChannelStatus)}
              currentStatus={values.status || ''}
              dataTc="dealStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={dealStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="dealHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="dealTabs"
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab
              disabled={
                values.status !== DealStatus.Inactive &&
                values.status !== DealStatus.Active
              }
              dataTc="dealMonitoringTab"
              label="Performance Monitoring"
            />
            <Tab
              disabled={
                values.status !== DealStatus.Inactive &&
                values.status !== DealStatus.Active
              }
              dataTc="dealHealthTab"
              label="Health"
            />
            <Tab
              dataTc="dealDetailsTab"
              numberOfTabErrors={getDealDetailsTabErrors(
                status,
                dealDetailsFields
              )}
              label="Details"
            />
            <Tab
              dataTc="rtbConnectionTab"
              numberOfTabErrors={getRtbConnectionTabErrors(
                status,
                rtbConnectionFields
              )}
              label="RTB Connection"
            />
            <Tab dataTc="dealTargetingTab" label="Targeting" />
            <Tab
              disabled={!update}
              dataTc="listCreativesTab"
              label="Creatives"
            />
            <Tab disabled={!update} dataTc="dealActivityTab" label="Activity" />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListCreativesTab || isDealActivityTab,
            },
            {
              [`${classes.formView}--noPadding`]:
                isTargetingTab &&
                values.targetingVersion === TargetingVersion.TargetingV2,
            },
          ])}
        >
          {isDealMonitoringTab && (
            <DealMonitoring id={values.id} timeZone={values.timeZone} />
          )}
          {isDealTab && <DealDetails {...props} />}
          {isRtbConnectorTab && <DealConnector {...props} />}
          {isTargetingTab && <TargetingVersionSelection {...props} />}
          {isListCreativesTab && creatives && (
            <ProgrammaticCreativesTable
              isEditable={hasEditPermissions}
              dealView
              constantFilter={dealIdsFilter}
              isPageTable={false}
              history={history}
            />
          )}
          {isDealActivityTab && (
            <ActivityTable
              entityType={EntityType.Deal}
              id={values.id}
              data-tc="dealActivityTable"
            />
          )}
          {isDealHealthTab && deal && (
            <HealthDashboard
              id={deal.id}
              selectedDateRange={{
                startDate: initialStartDate,
                endDate: initialEndDate,
              }}
              useHealthQuery={useDealHealthQuery}
              entityType="deal"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitDealButton"
          disabled={!isValid || !dirty || isSubmitting || loading}
          onClick={handleSubmit}
          isLoading={isSubmitting || loading}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={dealButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting || loading,
            disabled:
              !isValid ||
              !dirty ||
              isSubmitting ||
              !hasEditPermissions ||
              loading,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default DealTabsForm;
