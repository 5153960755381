import React, { lazy, Suspense } from 'react';

import Loader from 'components/Loader/Loader';

import {
  Channel,
  ChannelTimeSeries,
  TimeSeries,
  useChannelTimeSeriesQuery,
} from 'interfaces/generated.types';

import { ChartAggregatorEnum } from 'utils/charts';
import dateUtils, { TimeZones } from 'utils/date';

import pageStyles from 'assets/styles/components/Page.styles';

interface IChannelMonitoring {
  id: string;
  timeZone: TimeZones;
}

const XYDateChart = lazy(() => import('components/XYDateChart/XYDateChart'));

export const transformChannelMonitoringData = (
  channel: Channel | undefined
) => {
  if (!channel) {
    return [];
  }
  const { timeSeries, timeZone } = channel;
  const { impressions } = timeSeries as ChannelTimeSeries;

  return [
    {
      label: 'Impressions',
      value: 'impressions',
      series: (impressions as TimeSeries)?.dataPoints?.map((point) => ({
        date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
        value: point.v,
      })),
      aggregator: ChartAggregatorEnum.SUM,
    },
  ];
};

const ChannelMonitoring = ({ id, timeZone }: IChannelMonitoring) => {
  const { data, loading, error } = useChannelTimeSeriesQuery({
    variables: { id, timeZone },
    errorPolicy: 'all',
  });

  const classes = pageStyles();

  const noData = !(data && data.channel);

  if (!loading && (error || noData))
    return (
      <p className={classes.errorMessage} data-testid="channelMonitoringError">
        Error, issues have occurred loading graph data
      </p>
    );

  return loading ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <XYDateChart
        data={transformChannelMonitoringData(data?.channel as Channel)}
        initialSeries="impressions"
        testId="channelMonitoringChart"
      />
    </Suspense>
  );
};

export default ChannelMonitoring;
