import React from 'react';
import { Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './KeyValuePairs.styles';

const keyValue = {
  key: '',
  value: '',
};

interface IKeyValueProps {
  valuePath: string;
  keyValuePairs: { key: string; value: string }[];
}

const KeyValuePairs = (props: IKeyValueProps) => {
  const { keyValuePairs, valuePath } = props;

  const classes = useStyles();

  return (
    <FieldArray name={valuePath}>
      {({ push, remove }) =>
        keyValuePairs &&
        keyValuePairs.map((_, index: number) => (
          /* eslint-disable react/no-array-index-key */
          <div className={classes.wrapper} key={index}>
            <Field
              component={TextField}
              label="Key"
              placeholder="Key"
              name={`${valuePath}.${index}.key`}
              fullWidth
              classes={{ root: classes.pairValues }}
              id={`keyValueKey${index}`}
            />
            <Field
              component={TextField}
              label="Value"
              placeholder="Value"
              name={`${valuePath}.${index}.value`}
              fullWidth
              classes={{ root: classes.pairValues }}
            />
            <div className={classes.buttons}>
              <IconButton
                data-testid="keyValueRemoveButton"
                className={classes.button}
                onClick={() => {
                  if (keyValuePairs.length === 1) {
                    push(keyValue);
                  }
                  remove(index);
                }}
              >
                <ClearIcon />
                <span className="sr-only">Remove key/value pair</span>
              </IconButton>
              <IconButton
                data-testid="keyValueAddButton"
                onClick={() => {
                  push(keyValue);
                }}
                className={classes.button}
              >
                <AddIcon />
                <span className="sr-only">Add another key/value pair</span>
              </IconButton>
            </div>
          </div>
        ))
      }
    </FieldArray>
  );
};

export default KeyValuePairs;
