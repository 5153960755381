import find from 'lodash/find';
import { Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import get from 'lodash/get';
import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import formStyles from 'assets/styles/components/Form.styles';

import FormButtons from 'components/FormButtons/FormButtons';
import Postcode from 'components/Postcode/Postcode';
import { Territory } from 'interfaces/generated.types';
import { TerritoryLabel } from 'utils/territory';

import { IFormProps } from 'interfaces';

import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useStyles from './TargetingPackForm.styles';
import {
  TargetingPackTypeEnum,
  targetingPackTypes,
  getTargetingPackDetails,
  ITargetingPackType,
} from './TargetingPackFormValues';
import TargetingSubRegionPacks from '../TargetingSubRegionPacks/TargetingSubRegionPacks';

export interface ITargetingPackValues {
  name: string;
  description: string;
  type?: TargetingPackTypeEnum;
  postcodes?: string[];
  countryParams?: any;
  territory: Territory;
}

export const getTargetingPackComponent = (
  props: IFormProps<any>,
  packDetails: ITargetingPackType
) =>
  ({
    [TargetingPackTypeEnum.POSTCODE]: (
      <Postcode
        {...props}
        name={packDetails.path}
        label={packDetails.label}
        placeholder="Begin typing to find a Post/Zipcode"
        dataTc="targetingPostcodePack"
      />
    ),
    [TargetingPackTypeEnum.REGION]: (
      <TargetingSubRegionPacks {...props} name={packDetails.path} />
    ),
  } as any);

export const setTargetingPackComponentValues = ({
  type,
  setFieldValue,
  update,
  setCreateQuery,
  setUpdateQuery,
}: {
  type: string;
  setFieldValue: (arg0: string, arg1: any) => void;
  update?: boolean;
  setCreateQuery?: (arg: any) => void;
  setUpdateQuery?: (arg: any) => void;
}) => {
  const createQuery = get(find(targetingPackTypes as any, { type }), 'create');

  const updateQuery = get(find(targetingPackTypes as any, { type }), 'update');

  switch (type) {
    case TargetingPackTypeEnum.POSTCODE:
      setFieldValue('postcodes', null);
      break;
    case TargetingPackTypeEnum.REGION:
      setFieldValue('countryParams', {
        countries: [],
        regions: [],
        subRegions: [],
      });
      break;
    default:
      break;
  }
  setCreateQuery && setCreateQuery(() => createQuery);
  update && setUpdateQuery && setUpdateQuery(() => updateQuery);
};

interface ITargetingPackFormProps {
  setCreateQuery?: (arg: any) => void;
  setUpdateQuery?: (arg: any) => void;
  isPackAssignedToActiveTerritory?: boolean;
}

const TargetingPackForm = (
  props: IFormProps<ITargetingPackValues> & ITargetingPackFormProps
) => {
  const classes = { ...formStyles(), ...useStyles() };
  const {
    update,
    values,
    handleChange,
    touched,
    status = {},
    errors,
    isSubmitting,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
    setCreateQuery,
    setUpdateQuery,
    isPackAssignedToActiveTerritory,
  } = props;

  const location = usePreviousLocation();

  return (
    <>
      <Form className={classes.form} onSubmit={handleSubmit} role="form">
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend} data-tc="packLegend">
            {update ? 'Update Pack' : 'Create Pack'}
          </legend>
          <div className={classes.formWrapper}>
            <Field
              component={TextField}
              label="Pack Name"
              placeholder="Pack Name"
              name="name"
              autoFocus={!update}
              data-tc="packName"
              inputProps={{ 'data-testid': 'packName' }}
              fullWidth
              helperText={(touched.name && errors.name) || status.name}
              FormHelperTextProps={{
                error: !!((touched.name && errors.name) || status.name),
              }}
            />

            <Field
              component={TextField}
              label="Pack Description"
              placeholder="Pack Description"
              name="description"
              data-tc="packDescription"
              fullWidth
              margin="normal"
              multiline
              helperText={
                (touched.description && errors.description) ||
                status.description
              }
              FormHelperTextProps={{
                error: !!(
                  (touched.description && errors.description) ||
                  status.description
                ),
              }}
            />
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              data-tc="territory"
              fullWidth
              disabled
              margin="normal"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />

            <div className={classes.pack}>
              <div className={classes.packValues}>
                <Field
                  component={TextField}
                  select
                  className={classes.packType}
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    },
                  }}
                  label="Data Type"
                  data-tc="targetingPackOptions"
                  name="type"
                  onChange={(e: any) => {
                    handleChange(e);
                    setTargetingPackComponentValues({
                      type: e.target.value,
                      setFieldValue,
                      update,
                      setCreateQuery,
                      setUpdateQuery,
                    });
                  }}
                  fullWidth
                  margin="normal"
                >
                  {targetingPackTypes.map((option) => (
                    <MenuItem
                      key={option.type}
                      value={option.type}
                      data-tc="targetingPackOption"
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>

                {values.type && (
                  <div className={classes.packComponent}>
                    {
                      getTargetingPackComponent(
                        { ...props },
                        getTargetingPackDetails(values.type)
                      )[values.type]
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </fieldset>
        <FormButtons
          dataTc="submitTargetingPackButton"
          disabled={
            !isValid ||
            !dirty ||
            isSubmitting ||
            (!isPackAssignedToActiveTerritory && update)
          }
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/targeting-packs',
            state: location.state?.parent || {},
          }}
        >
          {update ? 'Save Changes' : 'Create Pack'}
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !isSubmitting} />
    </>
  );
};

export default TargetingPackForm;
