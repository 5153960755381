import { baseFontStyles } from 'assets/styles/components/Font.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  signUpForm: {
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  error: {
    margin: theme.spacing(1, 0),
  },
  textField: {
    width: '400px',
    marginTop: theme.spacing(1),
  },
  cta: {
    width: '96px',
    marginTop: theme.spacing(1),
  },
  footer: {
    justifySelf: 'flex-end',
    margin: theme.spacing(1),
  },
  formControlLabel: {
    height: '27px',
    margin: theme.spacing(0, 0),
  },
  checkboxText: {
    width: '230px',
    height: '15px',
    margin: theme.spacing(0, 0, 0, -1),
    fontSize: '12px',
    fontWeight: 'normal',
  },
}));

export default useStyles;
