import { EntityPermission, PermissionName } from 'interfaces/generated.types';

export const handlePermissionsSelection = (
  selectedPermission: string,
  userPermissions: PermissionName[],
  setFieldValue: (field: string, value: any) => void
) => {
  let newPermissions: PermissionName[] = userPermissions;
  switch (selectedPermission) {
    case PermissionName.PublisherInsightsTier1:
      newPermissions = [
        ...userPermissions.filter(
          (permission) => permission !== PermissionName.PublisherInsightsTier2
        ),
        PermissionName.PublisherInsightsTier1,
      ];
      setFieldValue('entityPermissions', []);
      break;
    case PermissionName.PublisherInsightsTier2:
      newPermissions = [
        ...userPermissions.filter(
          (permission) => permission !== PermissionName.PublisherInsightsTier1
        ),
        PermissionName.PublisherInsightsTier2,
      ];
      break;
    default:
      newPermissions = userPermissions.filter(
        (permission) =>
          permission !== PermissionName.PublisherInsightsTier1 &&
          permission !== PermissionName.PublisherInsightsTier2
      );
      setFieldValue('entityPermissions', []);
      break;
  }
  setFieldValue('permissions', newPermissions);
};

export const getInsightsPermissionsOptions = (isGlobalUser: boolean = true) => [
  ...(isGlobalUser
    ? [
        {
          value: PermissionName.PublisherInsightsTier1,
          label: 'Tier 1 permissions',
        },
      ]
    : []),
  {
    value: PermissionName.PublisherInsightsTier2,
    label: 'Tier 2 permissions',
  },
];

export const getEntityPermissionsIds = (permissions: EntityPermission[]) =>
  permissions.map((permission: EntityPermission) => permission.entityId);
