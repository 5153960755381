import debounce from 'debounce-promise';
import React, { useState } from 'react';

import Loader from 'components/Loader/Loader';

import { IGetAllGenresResponse } from 'features/targeting/graphql/genres/queries';

import { IFormProps } from 'interfaces';
import { useAllGenresQuery } from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

import { TargetingAsyncMultiSelect } from '../TargetingMultiSelect/TargetingMultiSelect';

export const genreValues = {
  genreParams: {
    genres: [],
  },
};

const formatData = (data: string[]) =>
  data.map((genre: string) => ({
    label: genre,
    value: genre,
  }));

export const loadOptions = async (
  value: string,
  genres: IGetAllGenresResponse,
  setFetchedOptions: any
) => {
  if (value.length >= 2) {
    const data = genres.allGenres.filter((genre) =>
      genre.toLowerCase().includes(value.toLowerCase())
    );

    if (data) {
      const formattedData = formatData(data);
      setFetchedOptions(formattedData);
      return formattedData;
    }

    return [];
  }
  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 1000, {
  leading: true,
});

const TargetingGenre = (props: IFormProps<any> & ITargetingComponentProps) => {
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const { loading, data } = useAllGenresQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <TargetingAsyncMultiSelect
      {...props}
      label="Genre"
      placeholder="Begin typing to find a Genre"
      targetingName="genreParams.genres"
      loadOptions={(value: string) =>
        data && debouncedLoadOptions(value, data, setFetchedOptions)
      }
      fetchedOptions={fetchedOptions}
    />
  );
};

export default TargetingGenre;
