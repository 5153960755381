import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  legend: {
    marginRight: theme.spacing(2.5),
    fontSize: theme.spacing(2.626),
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
}));

export default useStyles;
