import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreativesMultiUploadContainer from 'features/direct/creative/containers/CreativesMultiUploadContainer/CreativesMultiUploadContainer';
import { History } from 'history';
import { Advertiser, useAllAdvertisersQuery } from 'interfaces/generated.types';
import React from 'react';

interface ICreativesUploadPage {
  history: History;
}

const CreativesUploadPage = ({ history }: ICreativesUploadPage) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllAdvertisersQuery({
    variables: {
      territories: [activeTerritory!],
    },
  });

  if (!loading && (error || !(data && data.allAdvertisers)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Upload Creatives</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreativesMultiUploadContainer
            history={history}
            allAdvertisers={data?.allAdvertisers as Advertiser[]}
          />
        )}
      </Container>
    </main>
  );
};
export default CreativesUploadPage;
