import merge from 'lodash/merge';

import { ClassNameMap } from '@material-ui/styles';

const mergeClasses = (
  defaultClasses: ClassNameMap<any>,
  userClasses?: ClassNameMap<any>
) => merge(JSON.parse(JSON.stringify(defaultClasses)), userClasses);

export default mergeClasses;
