import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import TargetingTemplatesTable from 'features/targeting/components/TargetingTemplatesTable/TargetingTemplatesTable';
import { IGetAllTargetingTemplatesResponse } from 'graphql/targetingTemplates/queries';
import { History } from 'history';
import { useAllTargetingTemplatesMinimalQuery } from 'interfaces/generated.types';
import React from 'react';

interface ITargetingTemplatePageProps {
  history: History;
}

const TargetingTemplatesPage = ({ history }: ITargetingTemplatePageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data } = useAllTargetingTemplatesMinimalQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });
  const classes = pageStyles();

  if (!loading && !data?.allTargetingTemplates)
    return <Redirecting history={history} />;

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Targeting Templates</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <TargetingTemplatesTable
              history={history}
              allTargetingTemplates={
                (data as IGetAllTargetingTemplatesResponse)
                  .allTargetingTemplates
              }
            />
          )}
        </div>
      </Container>
    </main>
  );
};

export default TargetingTemplatesPage;
