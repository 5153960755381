import pageStyles from 'assets/styles/components/Page.styles';
import Blocklisting, {
  BlocklistingEntityEnum,
} from 'components/Blocklisting/Blocklisting';
import FormButtons from 'components/FormButtons/FormButtons';
import useBlocklistingStyles from 'features/primaryControls/containers/BlocklistingPrimaryControl/BlocklistingPrimaryControl.styles';
import { Form, Formik } from 'formik';
import {
  Territory,
  useUpdateGlobalBlocklistMutation,
} from 'interfaces/generated.types';
import React from 'react';
import { formatValues } from 'utils/forms';

import { Dialog } from '@material-ui/core';

import { GET_GLOBAL_BLOCKLISTS } from '../../graphql/queries';
import BlocklistingFormValidation from '../../validations/blocklisting';

interface IBlocklistingModalProps {
  isOpen: boolean;
  closeModal(): void;
  iabCategories: string[];
  domains: string[];
  activeTerritory: Territory;
}

interface IBlocklistingFormValues {
  blacklistedDomains: string[];
  blacklistedIabCategoryCodes: string[];
}

const BlocklistingModal = ({
  isOpen,
  closeModal,
  domains,
  iabCategories,
  activeTerritory,
}: IBlocklistingModalProps) => {
  const classes = useBlocklistingStyles({});
  const pageClasses = pageStyles({});

  const [updateBlocklist] = useUpdateGlobalBlocklistMutation();

  const onSubmit = async (formValues: IBlocklistingFormValues) => {
    const formattedValues = formatValues(formValues);

    await updateBlocklist({
      variables: {
        domains: formattedValues.blacklistedDomains || [],
        iabCategories: formattedValues.blacklistedIabCategoryCodes || [],
        territory: activeTerritory,
      },
      refetchQueries: [
        {
          query: GET_GLOBAL_BLOCKLISTS,
          variables: { territories: [activeTerritory!] },
        },
      ],
    });

    closeModal();
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isOpen}
      onClose={closeModal}
      data-testid="blocklistDialog"
    >
      <Formik<IBlocklistingFormValues>
        initialValues={{
          blacklistedDomains: domains,
          blacklistedIabCategoryCodes: iabCategories,
        }}
        validationSchema={BlocklistingFormValidation()}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form className={classes.dialogContainer}>
            <header className={pageClasses.flexContainer}>
              <p className={classes.dialogTitle}>
                Block Listing for IAB categories and Advertiser domains
              </p>
            </header>
            <div className={pageClasses.flexContainer}>
              <p className={classes.dialogSubtitle}>
                Add IAB categories and/or Advertiser domains to block list,
                preventing their delivery across DAX Audio
              </p>
            </div>

            <Blocklisting
              {...formikProps}
              entity={BlocklistingEntityEnum.Master}
            />

            <FormButtons
              onClick={formikProps.handleSubmit}
              disabled={formikProps.isSubmitting}
              dataTc="blocklistSubmit"
              onCancel={closeModal}
            >
              Apply to Block List
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default BlocklistingModal;
