import classNames from 'classnames';
import React from 'react';

import useStyle from './Image.styles';

interface IImageProps {
  src: string;
  alt: string;
  className?: string;
}

const Image = ({ src, alt, className }: IImageProps) => {
  const classes = useStyle();
  const imageClass = classNames(classes.image, {
    [`${className}`]: className,
  });

  return <img src={src} alt={alt} className={imageClass} />;
};

export default Image;
