import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  selectableCell: {
    height: '100%',
  },
  styledCheckbox: {
    display: 'block',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    '&--selecting': {
      backgroundColor: theme.palette.grey[200],
    },
    '&--checked': {
      backgroundColor: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&::after': {
        content: '""',
        display: 'block',
        width: theme.spacing(0.626),
        height: theme.spacing(1.626),
        border: `solid ${theme.palette.common.white}`,
        borderWidth: theme.spacing(0, 0.376, 0.376, 0),
        transform: 'rotate(45deg)',
      },
    },
    '&--disabled': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export default useStyles;
