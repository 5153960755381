import CreativeLink from 'components/CreativeLink/CreativeLink';
import Table from 'components/Table/Table';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreativeDetailsModal from 'features/direct/creative/components/CreativeDetailsModal/CreativeDetailsModal';
import { creativeStatusValues } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import {
  GET_ALL_AUDIO_CREATIVES,
  GET_ALL_IMAGE_CREATIVES,
} from 'features/direct/creative/graphql/queries';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import {
  CreativeStatus,
  CreativeType,
  useUpdateCreativeAudioMutation,
  useUpdateCreativeImageMutation,
} from 'interfaces/generated.types';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

import { ICreativesRow } from './Creatives.values';

interface ICreativesTableProps {
  allCreatives: ICreativesRow[];
  dataTc: string;
  type: CreativeType;
  isEditable: boolean;
  history: History;
  customToolbarCtas?: React.ReactNode;
}

const CreativesTable = ({
  allCreatives,
  isEditable,
  dataTc,
  type,
  history,
  customToolbarCtas,
}: ICreativesTableProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const [updateAudioCreatives] = useUpdateCreativeAudioMutation({
    refetchQueries: [
      {
        query: GET_ALL_AUDIO_CREATIVES,
        variables: {
          filter: {
            status_in: [CreativeStatus.Ready, CreativeStatus.Disabled],
          },
          territories: [activeTerritory!],
        },
      },
    ],
  });
  const [updateImageCreatives] = useUpdateCreativeImageMutation({
    refetchQueries: [
      {
        query: GET_ALL_IMAGE_CREATIVES,
        variables: {
          filter: {
            status_in: [CreativeStatus.Ready, CreativeStatus.Disabled],
          },
          territories: [activeTerritory!],
        },
      },
    ],
  });
  const validateCreative = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const audioColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Creative',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativesRow>) => (
          <CreativeLink
            url={row.original.url}
            type={CreativeType.Audio}
            dataTc="creativePageAudioPlayer"
          />
        ),
      },
      {
        Header: 'ID',
        accessor: 'resourceAltId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          maxWidth: '300px',
          wordBreak: 'break-word',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativesRow>) => (
          <CreativeDetailsModal
            creative={row.original}
            type={CreativeType.Audio}
          />
        ),
      },
      {
        Header: 'Advertiser',
        accessor: 'advertiser',
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Uploaded Date',
        accessor: 'uploadedDate',
        sortType: dateSort,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ICreativesRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: creativeStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={creativeStatusValues}
                name="creativeAudioStatusSelect"
                dataTc="creativeAudioStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable]
  );

  const imageColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Creative',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativesRow>) => (
          <CreativeLink
            url={row.original.url}
            fileName={row.original.fileName}
            type={CreativeType.Image}
            dataTc="creativePageImageThumbnail"
          />
        ),
      },
      {
        Header: 'ID',
        accessor: 'resourceAltId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          maxWidth: '300px',
          wordBreak: 'break-word',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativesRow>) => (
          <CreativeDetailsModal
            creative={row.original}
            type={CreativeType.Image}
          />
        ),
      },
      {
        Header: 'Advertiser',
        accessor: 'advertiser',
      },
      {
        Header: 'Uploaded Date',
        accessor: 'uploadedDate',
        sortType: dateSort,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ICreativesRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: creativeStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={creativeStatusValues}
                name="creativeImageStatusSelect"
                dataTc="creativeImageStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable]
  );

  return (
    <Table
      history={history}
      columns={type === CreativeType.Audio ? audioColumns : imageColumns}
      data={allCreatives}
      isEditable={isEditable}
      dataTc={dataTc}
      isPageTable={false}
      onCellUpdate={(row: ICreativesRow, setErrorModal, setUpdating) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            type,
            status: row.status,
          },
          update:
            type === CreativeType.Audio
              ? updateAudioCreatives
              : updateImageCreatives,
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: '',
          },
        })
      }
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateCreative,
          entity: { ...entity, type },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      customToolbarCtas={customToolbarCtas}
    />
  );
};

export default CreativesTable;
