import colors from 'assets/styles/colors';
import { XYDateChartData, XYDateChartProps } from 'interfaces/XYDateChart';
import React, { useEffect, useState } from 'react';
import {
  am4coreSetup,
  ChartAggregatorEnum,
  createAxis,
  createSeries,
} from 'utils/charts';
import { findLabelValue } from 'utils/dataTransformation';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import useStyles from './XYDateChart.styles';

am4coreSetup(am4core);

const createChart = ({
  data,
  axis,
  showScrollbar,
  chartId,
  isPercentageChart,
  toolTipNumberFormat,
  showVerticalGridLines,
  displayYAxisCursorLabel,
}: {
  data: XYDateChartData[];
  axis: string;
  showScrollbar: boolean;
  chartId: string;
  isPercentageChart?: boolean;
  toolTipNumberFormat?: string | undefined;
  showVerticalGridLines: boolean;
  displayYAxisCursorLabel: boolean;
}) => {
  // Create chart instance
  const chart = am4core.create(chartId, am4charts.XYChart);
  chart.paddingRight = 20;

  if (isPercentageChart) {
    chart.numberFormatter.numberFormat = '#.%';
  }

  // Increase contrast by taking every second color
  chart.colors.step = 2;

  if (data.length > 0) {
    // Add data
    const chartData = data.filter((dataGroup) => dataGroup.value === axis);

    // Create X Axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.6;

    if (!showVerticalGridLines) {
      dateAxis.renderer.grid.template.disabled = true;
    }

    // Enable grouping of data on a 48 points (2 days) interval
    dateAxis.groupData = true;
    dateAxis.groupCount = 48;
    dateAxis.groupIntervals.setAll([
      { timeUnit: 'hour', count: 1 },
      { timeUnit: 'day', count: 1 },
      { timeUnit: 'week', count: 1 },
      { timeUnit: 'month', count: 1 },
      { timeUnit: 'year', count: 1 },
    ]);

    // Create Y axis
    const valueAxis = createAxis({
      name: findLabelValue({ collection: data, lookupValue: axis }),
      opposite: false,
      color: colors.daxPrimaryBlue,
      chart,
    });

    const aggregator =
      (findLabelValue({
        collection: chartData,
        lookupValue: axis,
        labelKey: 'aggregator',
      }) as ChartAggregatorEnum) || ChartAggregatorEnum.HIGH;

    // Create Series
    chartData.forEach((dataGroup) =>
      createSeries({
        chart,
        field: 'value',
        valueAxis,
        name: findLabelValue({ collection: data, lookupValue: axis }),
        shape: 'circle',
        toolTipNumberFormat,
        seriesChartData: dataGroup.series,
        color: dataGroup.seriesColor,
        showScrollbar,
        aggregator,
      })
    );

    // Set minimum to zero
    valueAxis.min = 0;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    valueAxis.cursorTooltipEnabled = displayYAxisCursorLabel;

    // Property configurations for a multi series chart
    if (chartData.length > 1) {
      chart.series.each((seriesGroup) => {
        const seriesProps = seriesGroup;
        seriesProps.tooltipText = '';
        seriesProps.fillOpacity = 0.6;
      });
    }
  } else {
    const label = chart.createChild(am4core.Label);
    label.text = 'The chart contains no data at the moment';
    label.fontSize = 20;
    label.align = 'center';
    label.isMeasured = false;
    label.x = am4core.percent(50);
    label.horizontalCenter = 'middle';
    label.y = am4core.percent(35);
  }

  return chart;
};

const XYDateChart = ({
  data,
  initialSeries,
  testId,
  showSelector = false,
  customClasses,
  showScrollbar = true,
  chartId = 'chartdiv',
  isPercentageChart = false,
  toolTipNumberFormat,
  showVerticalGridLines = true,
  displayYAxisCursorLabel = true,
}: XYDateChartProps) => {
  const classes = useStyles({});
  const [axis, setAxis] = useState(initialSeries);
  const axes = data.map((series) => ({
    label: `${series.label}${
      series.currencyLabel ? ` - ${series.currencyLabel}` : ''
    }`,
    value: series.value,
  }));

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAxis(event.target.value);
  };

  useEffect(() => {
    const chart = createChart({
      axis,
      data,
      showScrollbar,
      chartId,
      isPercentageChart,
      toolTipNumberFormat,
      showVerticalGridLines,
      displayYAxisCursorLabel,
    });

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [
    axis,
    data,
    showScrollbar,
    chartId,
    isPercentageChart,
    toolTipNumberFormat,
    showVerticalGridLines,
    displayYAxisCursorLabel,
  ]);

  return (
    <>
      {showSelector && (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={4}>
            <TextField
              select
              fullWidth
              label="Select Chart"
              value={axis}
              onChange={handleSelectChange}
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                },
              }}
            >
              {axes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}
      <div
        id={chartId}
        data-testid={testId}
        className={customClasses ? customClasses.container : classes.container}
        data-tc="dateGraph"
      />
    </>
  );
};

export default XYDateChart;
