import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CheckIcon } from 'assets/icons/dax-checkicon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/dax-crossicon.svg';
import { ReactComponent as CreateIcon } from 'assets/icons/dax-editicon.svg';

import { useEditableCellStyles } from './Table.styles';

interface ITableUpdateCell {
  id: string;
  row: any;
  value: any;
  render: () => string | React.ReactElement<any>;
  editComponent: (cellValue: any, onChange: any) => React.ReactElement<any>;
  onCellUpdate: (
    row: any,
    setErrorModal: any,
    setUpdating: any
  ) => Promise<void>;
  setErrorModal: any;
  setUpdating: any;
  isEditable: boolean;
}

const TableUpdateCell = ({
  id,
  row,
  value: initialValue,
  render,
  editComponent,
  onCellUpdate,
  setErrorModal,
  setUpdating,
  isEditable,
}: ITableUpdateCell) => {
  const classes = useEditableCellStyles();
  const [isEditing, setEditing] = useState(false);
  const [cellValue, setCellValue] = useState(initialValue);
  const handleEdit = () => {
    setEditing(true);
  };

  const onChange = (value: any) => {
    setCellValue(value);
  };

  const handleDismiss = () => {
    setCellValue(initialValue);
    setEditing(false);
  };

  const handleApprove = async () => {
    const updateRow = { ...row.values, [id]: cellValue };
    setEditing(false);
    await onCellUpdate(updateRow, setErrorModal, setUpdating);
  };

  return !isEditing ? (
    <div className={classes.root}>
      {render()}
      {isEditable ? (
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit} data-testid="tableCellEditButton">
            <CreateIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  ) : (
    <div className={classes.root}>
      {editComponent(cellValue, onChange)}
      <Tooltip title="Save">
        <IconButton
          onClick={handleApprove}
          data-testid="tableCellApproveButton"
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancel">
        <IconButton
          onClick={handleDismiss}
          data-testid="tableCellDismissButton"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TableUpdateCell;
