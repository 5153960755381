import 'assets/styles/index.css';

import ApolloProviderWrapper from 'clients/ApolloProviderWrapper/ApolloProviderWrapper';
import { AuthProvider } from 'context/AuthProvider/AuthProvider';
import CustomApolloProvider from 'context/CustomApolloProvider/CustomApolloProvider';
import { GTMLoggerProvider } from 'context/GTMLoggerProvider/GTMLoggerProvider';
import UserProvider from 'context/UserProvider/UserProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from 'routes/browserHistory';
import { APP_ENVIRONMENT } from 'utils/environmentVariables';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import styles from './assets/styles';
import App from './containers/App/App';
import SessionContextProvider from './context/SessionProvider/SessionProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    __ENV__: any;
    gigya: any;
  }
}

const sentryId =
  // @ts-ignore
  window.__ENV__.REACT_APP_SENTRY_ID || process.env.REACT_APP_SENTRY_ID;

const imageTag =
  // @ts-ignore
  window.__ENV__.REACT_APP_IMAGE_TAG || process.env.REACT_APP_IMAGE_TAG;

Sentry.init({
  dsn: sentryId,
  integrations: [
    new TracingIntegrations.BrowserTracing({
      routingInstrumentation: SentryReact.reactRouterV5Instrumentation(history),
      tracingOrigins: [
        'console.ui-dev.audio.dax.global.com',
        'test-console.audio.thisisdax.com',
        'console.ui-load.audio.dax.global.com',
        'console.ui-qa.audio.dax.global.com',
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  release: imageTag,
  environment: APP_ENVIRONMENT,
});

const gtmParams = {
  id: window.__ENV__.REACT_APP_GTM_ID || process.env.REACT_APP_GTM_ID,
  environment: {
    gtm_auth:
      window.__ENV__.REACT_APP_GTM_AUTH || process.env.REACT_APP_GTM_AUTH,
    gtm_preview:
      window.__ENV__.REACT_APP_GTM_PREVIEW || process.env.REACT_APP_GTM_PREVIEW,
  },
};

ReactDOM.render(
  <GTMProvider state={gtmParams}>
    <AuthProvider>
      <CustomApolloProvider>
        <ApolloProviderWrapper>
          <GTMLoggerProvider>
            <UserProvider>
              <SessionContextProvider>
                <Router history={history}>
                  <StylesProvider jss={styles.plugins}>
                    <ThemeProvider theme={styles.themes.theme}>
                      <App />
                    </ThemeProvider>
                  </StylesProvider>
                </Router>
              </SessionContextProvider>
            </UserProvider>
          </GTMLoggerProvider>
        </ApolloProviderWrapper>
      </CustomApolloProvider>
    </AuthProvider>
  </GTMProvider>,
  document.getElementById('root')
);
