import classNames from 'classnames';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import {
  campaignPacings,
  campaignPriorityTypes,
  ICampaignValues,
} from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import TargetingVersionSwitch from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSwitch';
import { Field } from 'formik';
import {
  fieldToSwitch,
  RadioGroup,
  SwitchProps,
  TextField,
} from 'formik-material-ui';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import { FrequencyCapTimeUnits, IFormProps, OptionType } from 'interfaces';
import {
  Advertiser,
  CampaignExternalSystem,
  Order,
  PermissionName,
  Tag,
  TargetingVersion,
  User,
} from 'interfaces/generated.types';
import isNull from 'lodash/isNull';
import numbro from 'numbro';
import React, { Dispatch, useEffect, useState } from 'react';
import { currencyValues } from 'utils/currency';
import {
  calculateTotalCampaignsObjectiveWithoutCurrentCampaign,
  createSelectOptions,
  createUserOption,
  transformIabCategoryOptions,
} from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { parseFormattedValue, sumTwoNumbers } from 'utils/numbers';
import { TerritoryLabel } from 'utils/territory';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import MuiSwitch from '@material-ui/core/Switch';
import MuiTextField from '@material-ui/core/TextField';
import LaunchIcon from '@material-ui/icons/Launch';

import { hasDaxExternalSystem } from 'features/direct/order/utils/order';
import billingType from './billingType.json';
import useStyles from './CampaignDetails.styles';

export interface ICampaignFormProps {
  orderIdExists?: boolean;
  orderObj?: number | null;
  totalCampaignsObj: number | null;
  allOrders?: Order[] | [];
  update?: boolean;
  validateOrderFields?: (
    name: string,
    value: Date | number | null | string
  ) => void;
  allTags: Tag[];
  allUsers: User[];
  currentUser: User;
  isTargetingVersionToggleDisabled: boolean;
  shouldHaveExternalId?: boolean;
}

export const ObjectiveSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('objective', '');
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

export const EndDateSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('endDate', null);
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

export const handleGetOrder = (
  allOrders: Order[],
  order: OptionType | null,
  setFieldValue: (field: string, value: any) => void,
  setSelectedOrder: Dispatch<Order | null>,
  currentUser: User,
  setShowExternalId: (value: boolean) => void
) => {
  setFieldValue('orderName', order);

  if (!order) {
    setSelectedOrder(null);
    setShowExternalId(false);
    setFieldValue('owner', createUserOption(currentUser));
    return;
  }

  const chosenOrder = allOrders.find(
    (orders: Order) => orders.id === order.value
  );

  if (chosenOrder) {
    setSelectedOrder(chosenOrder);
    setShowExternalId(hasDaxExternalSystem(chosenOrder.externalSystemId));
  }
};

export const handleOrderValidation = (
  values: ICampaignValues,
  selectedOrder: Order | null,
  setOrderObjective: Dispatch<number | null>,
  setTotalCampaignsObjective: Dispatch<number | null>,
  setFieldValue: (field: string, value: any) => void,
  setFieldTouched: (field: string, value: boolean) => void,
  validateOrderFields: (
    name: string,
    value: Date | number | null | string
  ) => void,
  currentUser: User
) => {
  setOrderObjective(selectedOrder?.objective ? selectedOrder.objective : null);
  setTotalCampaignsObjective(
    selectedOrder?.campaigns
      ? calculateTotalCampaignsObjectiveWithoutCurrentCampaign({
          allCampaigns: selectedOrder.campaigns,
        })
      : null
  );
  setFieldValue(
    'owner',
    createUserOption(selectedOrder?.owner ? selectedOrder.owner : currentUser)
  );

  if (!values.startDate && selectedOrder?.startDate) {
    setFieldValue(
      'startDate',
      dateUtils.getDateInSpecificTimezone(
        selectedOrder.startDate,
        values.timeZone
      )
    );
  }
  if (!values.endDate && selectedOrder?.endDate) {
    setFieldValue(
      'endDate',
      dateUtils.getDateInSpecificTimezone(
        selectedOrder.endDate,
        values.timeZone
      )
    );
    setFieldValue('noEndDate', false);
  }

  if (selectedOrder) {
    setFieldValue(
      'iabCategoryCodes',
      transformIabCategoryOptions(
        (selectedOrder.advertiser as Advertiser).iabCategories
      )
    );
    setFieldValue(
      'externalSystem',
      hasDaxExternalSystem(selectedOrder.externalSystemId)
        ? CampaignExternalSystem.Dax
        : CampaignExternalSystem.CampaignExternalSystemUnspecified
    );

    setFieldTouched('objective', true);
    setFieldTouched('startDate', true);
    setFieldTouched('endDate', true);
    setFieldTouched('owner', true);
    setFieldTouched('iabCategoryCodes', true);
    setFieldTouched('externalId', true);
  } else {
    setFieldValue(
      'externalSystem',
      CampaignExternalSystem.CampaignExternalSystemUnspecified
    );
    setFieldValue('externalId', '');
  }

  validateOrderFields(
    'orderStartDate',
    selectedOrder?.startDate ? selectedOrder.startDate : null
  );
  validateOrderFields(
    'orderEndDate',
    selectedOrder?.endDate ? selectedOrder.endDate : null
  );
  validateOrderFields(
    'totalCampaignsObjective',
    selectedOrder?.campaigns
      ? calculateTotalCampaignsObjectiveWithoutCurrentCampaign({
          allCampaigns: selectedOrder.campaigns,
        })
      : null
  );
  validateOrderFields(
    'orderObjective',
    selectedOrder?.objective ? selectedOrder.objective : null
  );
  validateOrderFields(
    'orderExternalSystemId',
    selectedOrder?.externalSystemId ? selectedOrder.externalSystemId : null
  );
};

const CampaignDetails = (
  props: IFormProps<ICampaignValues> & ICampaignFormProps
) => {
  const {
    errors,
    handleBlur,
    handleChange,
    orderObj,
    totalCampaignsObj,
    setFieldTouched,
    setFieldValue,
    allOrders = [],
    orderIdExists = true,
    touched,
    update = false,
    values,
    validateOrderFields = () => {},
    allTags,
    allUsers,
    currentUser,
    status = {},
    isTargetingVersionToggleDisabled,
    shouldHaveExternalId = false,
  } = props;

  const [selectedOrder, setSelectedOrder] = useState(null as Order | null);
  const [showExternalId, setShowExternalId] = useState(shouldHaveExternalId);
  const [orderObjective, setOrderObjective] = useState(
    orderObj as number | null
  );
  const [totalCampaignsObjective, setTotalCampaignsObjective] = useState(
    totalCampaignsObj as number | null
  );

  const classes = useStyles();

  useEffect(() => {
    if (!orderIdExists) {
      handleOrderValidation(
        values,
        selectedOrder,
        setOrderObjective,
        setTotalCampaignsObjective,
        setFieldValue,
        setFieldTouched,
        validateOrderFields,
        currentUser
      );
    }
  }, [selectedOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchCheckedValue =
    values.targetingVersion === TargetingVersion.TargetingV2;

  const userPermissions = useUserPermissions();
  const isExperimentalUser = userPermissions.includes(
    PermissionName.Experimental
  );

  return (
    <fieldset className={classes.fieldset}>
      {isExperimentalUser && (
        <div className={classes.targetingToggleContainer}>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={TargetingVersionSwitch}
                  type="checkbox"
                  name="targetingVersion"
                  checked={switchCheckedValue}
                  disabled={isTargetingVersionToggleDisabled}
                />
              }
              label="Use Targeting 2.0"
            />
          </FormControl>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            {orderIdExists ? (
              <MuiTextField
                label="Order Name"
                placeholder="Order Name"
                name="orderName"
                data-tc="orderName"
                fullWidth
                value={values && values.orderName && values.orderName.label}
                disabled
                InputProps={{
                  classes: { root: classes.textfieldLinkWrapper },
                }}
              />
            ) : (
              <MultiSelect
                data-tc="orderNameMultiSelect"
                data-testId="orderNameMultiSelect"
                id="campaignOrderMultiSelect"
                label="Order Name"
                isMulti={false}
                selectAll={false}
                maxSelectHeight={250}
                name="orderName"
                externalLink
                noMargin
                onBlur={() => setFieldTouched('orderName', true)}
                onChange={(fieldValue: OptionType) =>
                  handleGetOrder(
                    allOrders,
                    fieldValue,
                    setFieldValue,
                    setSelectedOrder,
                    currentUser,
                    setShowExternalId
                  )
                }
                options={createSelectOptions(allOrders)}
                placeholder="Select an Order..."
                value={values.orderName}
                attributes={{
                  fieldAttribute: 'campaignOrderField',
                  menuAttribute: 'campaignOrderMenuOptions',
                  chipAttribute: 'campaignOrderChip',
                }}
                errorProps={{
                  helperText:
                    (touched.orderName && errors.orderName) || status.orderName,
                  FormHelperTextProps: {
                    error: !!(
                      (touched.orderName && errors.orderName) ||
                      status.orderName
                    ),
                  },
                }}
              />
            )}
            <IconButton
              href={`/order/${
                values && values.orderName && values.orderName.value
              }`}
              target="_blank"
              classes={{
                root: classNames([
                  classes.textFieldLink,
                  {
                    [classes.textFieldLinkMultiSelect]: !orderIdExists,
                  },
                ]),
              }}
              disabled={!values.orderName}
              data-tc="campaignOrderLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for{' '}
                {values && values.orderName && values.orderName.label}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={4}>
          <MultiSelect
            data-tc="campaignOwnerMultiSelect"
            data-testId="campaignOwnerMultiSelect"
            id="campaignOwnerMultiSelect"
            label="Campaign Owner"
            isMulti={false}
            selectAll={false}
            maxSelectHeight={250}
            name="owner"
            noMargin
            onBlur={() => setFieldTouched('owner', true)}
            onChange={(fieldValue: OptionType) =>
              setFieldValue('owner', fieldValue)
            }
            options={allUsers.map(
              (user) => createUserOption(user) as OptionType
            )}
            placeholder="Select an Owner..."
            value={values.owner}
            attributes={{
              fieldAttribute: 'campaignOwnerField',
              menuAttribute: 'campaignOwnerMenuOptions',
              chipAttribute: 'campaignOwnerChip',
            }}
            errorProps={{
              helperText: (touched.owner && errors.owner) || status.owner,
              FormHelperTextProps: {
                error: !!((touched.owner && errors.owner) || status.owner),
              },
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Campaign Name"
            placeholder="Campaign Name"
            name="name"
            id="campaignName"
            autoFocus={!update}
            data-tc="campaignName"
            fullWidth
            inputProps={{ 'data-testid': 'campaignName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Campaign ID"
              placeholder="Campaign ID"
              name="altId"
              data-tc="campaignId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={values.startDate}
              label="Campaign Start Date"
              name="startDate"
              onChange={(value) => setFieldValue('startDate', value)}
              onClose={() => setFieldTouched('startDate', true)}
              data-tc="campaignStartDate"
            />
            {((((update && touched.timeZone) || touched.startDate) &&
              errors.startDate) ||
              status.startDate) && (
              <FormHelperText error data-tc="campaignStartDateError">
                {errors.startDate || status.startDate}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={values.endDate}
              label="Campaign End Date"
              name="endDate"
              onChange={(value) => setFieldValue('endDate', value)}
              onClose={() => setFieldTouched('endDate', true, false)}
              disabled={values.noEndDate}
              data-tc="campaignEndDate"
            />
            {((touched.endDate && errors.endDate) ||
              (touched.startDate && errors.endDate) ||
              status.endDate) && (
              <FormHelperText error data-tc="campaignEndDateError">
                {errors.endDate || status.endDate}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={EndDateSwitch}
                  type="checkbox"
                  name="noEndDate"
                  data-tc="campaignNoEndDate"
                />
              }
              label="No end date"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="timeZone"
            fullWidth
            label="Time Zone"
            name="timeZone"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.timeZone && errors.timeZone) || status.timeZone
            }
            FormHelperTextProps={{
              error: !!(
                (touched.timeZone && errors.timeZone) ||
                status.timeZone
              ),
            }}
          >
            {dateUtils.timezoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="campaignObjective"
            dataTestId="campaignObjective"
            decimalScale={0}
            label="Objective Impressions"
            name="objective"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.objective}
            fullWidth
            touched={touched.objective || !!status.objective}
            error={errors.objective || status.objective}
            disabled={values.unlimitedObjective}
          />
          <div
            className={classes.objectiveImpressions}
            data-tc="totalOrderObjective"
          >
            {!isNull(totalCampaignsObjective) ? (
              <>
                Total Order Objective:{' '}
                {sumTwoNumbers(values.objective, totalCampaignsObjective)}
                {orderObjective
                  ? `/${numbro(orderObjective).format('0,00')}`
                  : null}
              </>
            ) : (
              <>
                Total Order Objective: Not Available. Please select a parent
                order
              </>
            )}
          </div>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={ObjectiveSwitch}
                  type="checkbox"
                  name="unlimitedObjective"
                  data-tc="campaignUnlimitedObjective"
                />
              }
              label="Unlimited objective"
            />
            {((touched.unlimitedObjective && errors.unlimitedObjective) ||
              status.unlimitedObjective) && (
              <FormHelperText error data-tc="unlimitedObjectiveError">
                {errors.unlimitedObjective || status.unlimitedObjective}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="campaignDailyCap"
            decimalScale={0}
            label="Daily Impression Cap"
            name="dailyCap"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.dailyCap}
            fullWidth
            touched={touched.dailyCap || !!status.dailyCap}
            error={errors.dailyCap || status.dailyCap}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="campaignSponsorshipRevenue"
            decimalScale={2}
            label="Sponsorship Revenue"
            name="sponsorshipRevenue"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.sponsorshipRevenue}
            fullWidth
            touched={touched.sponsorshipRevenue || !!status.sponsorshipRevenue}
            error={errors.sponsorshipRevenue || status.sponsorshipRevenue}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="campaignCpm"
            decimalScale={2}
            error={errors.cpm || status.cpm}
            label="CPM"
            name="cpm"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.cpm || !!status.cpm}
            value={values.cpm}
            fullWidth
          />
          {parseFormattedValue(values.cpm) > 100 && (
            <FormHelperText
              error
              data-tc="cpmOverHundredWarning"
              data-testid="cpmOverHundredWarning"
            >
              Campaign CPM over 100
            </FormHelperText>
          )}
          {(!values.cpm ||
            parseFormattedValue(values.cpm.trim()) === undefined) && (
            <FormHelperText
              error
              data-tc="cpmNotEnteredWarning"
              data-testid="cpmNotEnteredWarning"
            >
              CPM needs to be entered
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="campaignOffsetCpm"
            decimalScale={2}
            error={errors.offsetCpm || status.offsetCpm}
            label="Off-set"
            name="offsetCpm"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.offsetCpm || !!status.offsetCpm}
            value={values.offsetCpm}
            fullWidth
          />
          {parseFormattedValue(values.offsetCpm) > 100 && (
            <FormHelperText
              error
              data-tc="offsetCpmOverHundredWarning"
              data-testid="offsetCpmOverHundredWarning"
            >
              Campaign Off-set over 100
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="currency"
            fullWidth
            label="Campaign currency"
            name="currency"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.currency && errors.currency) || status.currency
            }
            FormHelperTextProps={{
              error: !!(
                (touched.currency && errors.currency) ||
                status.currency
              ),
            }}
          >
            {currencyValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="campaignPriority"
            fullWidth
            label="Priority"
            name="priority"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.priority && errors.priority) || status.priority
            }
            FormHelperTextProps={{
              error: !!(
                (touched.priority && errors.priority) ||
                status.priority
              ),
            }}
          >
            {campaignPriorityTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Campaign Pacing"
            name="pacing"
            data-tc="campaignPacing"
            fullWidth
            inputProps={{ 'data-testid': 'campaignPacing' }}
            helperText={(touched.pacing && errors.pacing) || status.pacing}
            FormHelperTextProps={{
              error: !!((touched.pacing && errors.pacing) || status.pacing),
            }}
          >
            {campaignPacings.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            component={'fieldset' as 'div'}
            className={classes.billingTypeWrapper}
            fullWidth
          >
            <FormLabel
              component={'legend' as 'span'}
              className={classes.radioLabel}
            >
              Billing type
            </FormLabel>
            <Field
              component={RadioGroup}
              name="billingType"
              data-tc="campaignBillingType"
              row
            >
              {billingType.map((type) => (
                <FormControlLabel
                  data-tc="campaignBillingTypeRadio"
                  key={type.value}
                  value={type.value}
                  control={
                    <Radio
                      classes={{
                        root: classes.radioButton,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={type.label}
                />
              ))}
            </Field>
            {((touched.billingType && errors.billingType) ||
              status.billingType) && (
              <FormHelperText error data-tc="billingTypeError">
                {errors.billingType || status.billingType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <InputLabel shrink>Frequency Capping</InputLabel>
            <div className={classes.frequencyCap}>
              <Field
                component={TextField}
                type="number"
                name="frequencyCapCount"
                data-tc="campaignFrequencyCapCount"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <span className={classes.impressions}>impressions per</span>
              <Field
                component={TextField}
                name="frequencyCapValue"
                data-tc="campaignFrequencyCapValue"
                type="number"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <Field
                component={TextField}
                select
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  },
                }}
                name="frequencyCapTimeUnit"
                data-tc="campaignFrequencyCapTimeUnit"
                classes={{ root: classes.selectFrequencyCap }}
              >
                {FrequencyCapTimeUnits.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </div>
            {(errors.frequencyCapCount || status.frequencyCapCount) && (
              <FormHelperText error data-tc="campaignFrequencyCapCountError">
                {errors.frequencyCapCount || status.frequencyCapCount}
              </FormHelperText>
            )}
            {(errors.frequencyCapValue || status.frequencyCapValue) && (
              <FormHelperText error data-tc="campaignFrequencyCapValueError">
                {errors.frequencyCapValue || status.frequencyCapValue}
              </FormHelperText>
            )}
            {(errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit) && (
              <FormHelperText error data-tc="campaignFrequencyCapTimeUnitError">
                {errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            data-tc="campaignTagsMultiSelect"
            data-testId="campaignTagsMultiSelect"
            id="campaignTagsMultiSelect"
            label="Reporting Tags"
            isMulti
            maxSelectHeight={250}
            name="tags"
            noMargin
            onBlur={() => setFieldTouched('tags', true)}
            onChange={(fieldValue: any) => {
              setFieldValue('tags', fieldValue);
            }}
            options={createSelectOptions(allTags)}
            placeholder="Select tags..."
            value={values.tags}
            attributes={{
              fieldAttribute: 'campaignTagsField',
              menuAttribute: 'campaignTagsMenuOptions',
              chipAttribute: 'campaignTagChip',
            }}
            errorProps={{
              FormHelperTextProps: {
                error: false,
              },
            }}
          />
        </Grid>
        <Grid item xs={showExternalId ? 6 : 12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
            />
          </div>
        </Grid>
        {showExternalId && (
          <Grid item xs={6}>
            <Field
              component={TextField}
              fullWidth
              label="External ID"
              name="externalId"
              id="externalId"
              placeholder="External ID"
              helperText={
                (touched.externalId && errors.externalId) || status.externalId
              }
              FormHelperTextProps={{
                error: !!(
                  (touched.externalId && errors.externalId) ||
                  status.externalId
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
    </fieldset>
  );
};

export default CampaignDetails;
