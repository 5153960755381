import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateSeatContainer from 'features/programmatic/seat/containers/UpdateSeatContainer/UpdateSeatContainer';
import { History } from 'history';
import { PermissionName, Seat, useSeatQuery } from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface UpdateSeatPageProps {
  history: History;
  match: {
    params: {
      seatId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Programmatic];

const UpdateSeatPage = ({
  history,
  match,
  userPermissions,
}: UpdateSeatPageProps) => {
  const { loading, data } = useSeatQuery({
    variables: { id: match.params.seatId },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  if (!loading && !data?.seat) return <Redirecting history={history} />;

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, editPermissions) &&
    isActiveTerritoryAssignedToEntity(
      data?.seat?.territories!,
      activeTerritory!
    );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Seat</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateSeatContainer
            history={history}
            match={match}
            seat={data?.seat as Seat}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateSeatPage;
