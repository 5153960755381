import React, { useState } from 'react';

import { ReactComponent as EmailIcon } from 'assets/icons/dax-emailicon.svg';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { IMessageContent } from 'hooks/Message/useMessage';
import { useEditableCellStyles } from './Table.styles';

export interface IResendSignUpCell {
  entity: {
    id: string;
    name: string;
  };
  children?: React.ReactNode;
  setMessageModal: (hasMessage: boolean) => void;
  setMessageContent: ({ title, main }: IMessageContent) => void;
  onCellResend: any;
  dataTc: string;
  setUpdating: (isUpdating: boolean) => void;
  setErrorModal: (value: boolean) => void;
}

const TableResendSignUpCell = ({
  entity,
  children = <EmailIcon />,
  setMessageModal,
  setMessageContent,
  onCellResend,
  dataTc,
  setUpdating,
  setErrorModal,
}: IResendSignUpCell) => {
  const classes = useEditableCellStyles();
  const [isConfirmationModalOpen, setIsConfirmationModal] = useState(false);

  const handleClick = () => {
    setIsConfirmationModal(true);
  };

  const handleResending = async () => {
    setIsConfirmationModal(false);
    setMessageContent({
      title: 'Sign up email resent',
      main: `The sign up email has been resent to ${entity.name}`,
    });
    await onCellResend({ entity, setErrorModal, setMessageModal, setUpdating });
  };

  return (
    <>
      <div className={classes.root}>
        <Tooltip title="Resend sign up email">
          <span>
            <IconButton onClick={handleClick} data-tc={`${dataTc}Button`}>
              {children}
              <span className="sr-only">Resend Sign Up Option</span>
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setModalOpen={setIsConfirmationModal}
        handleConfirm={handleResending}
        dataTc={`${dataTc}ConfirmationModal`}
        content={{
          title: 'Resend sign up email request',
          main: `Are you sure you want to resend the sign up email to ${entity.name}?`,
        }}
      />
    </>
  );
};
export default TableResendSignUpCell;
