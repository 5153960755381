import React, { createContext, useContext, useReducer } from 'react';

import reducer from './reducer';
import initialCustomApolloState, { CustomApolloState } from './state';

export const getState = (assumedUserState: CustomApolloState) => {
  if (!assumedUserState) {
    return initialCustomApolloState;
  }
  return assumedUserState;
};

const CustomApolloContext = createContext<{
  state: CustomApolloState;
  dispatch: React.Dispatch<any>;
}>({ state: initialCustomApolloState, dispatch: () => null });

const CustomApolloProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const sessionState = JSON.parse(
    window.sessionStorage.getItem('assumedUser') || 'null'
  );

  const [state, dispatch] = useReducer(reducer, getState(sessionState));

  return (
    <CustomApolloContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomApolloContext.Provider>
  );
};

export const useCustomApolloContext = () => useContext(CustomApolloContext);

export default CustomApolloProvider;
