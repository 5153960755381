import React from 'react';

import formStyles from 'assets/styles/components/Form.styles';

import Domains from 'components/Domains/Domains';

import IabCategoriesTree from 'features/iabCategories/containers/IabCategoriesTree/IabCategoriesTree';
import KeyValuePairs from 'components/KeyValuePairs/KeyValuePairs';

import { IFormProps } from 'interfaces';

export enum BlocklistingEntityEnum {
  Publisher = 'publisher',
  Channel = 'channel',
  Master = 'master',
}

interface IBlocklistingProps {
  entity: BlocklistingEntityEnum;
}

const Blocklisting = (props: IFormProps<any> & IBlocklistingProps) => {
  const classes = formStyles();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    entity,
    setFieldTouched,
  } = props;

  return (
    <fieldset className={classes.fieldset}>
      {entity !== BlocklistingEntityEnum.Master && (
        <p className={classes.description}>
          Add IAB categories and/or advertiser domains to prevent Ads associated
          with them from using this Channel.
        </p>
      )}
      <IabCategoriesTree
        onChange={setFieldValue}
        iabCategoryCodes={values.blacklistedIabCategoryCodes}
        dataTc={`${entity}IabCategories`}
        attribute="blacklistedIabCategoryCodes"
      />
      <Domains
        dataTc={{
          domainField: `${entity}DomainField`,
          addDomain: `${entity}AddDomainButton`,
          removeDomain: `${entity}RemoveDomainButton`,
        }}
        domainArrayName="blacklistedDomains"
        domainFor={values.name}
        domains={values.blacklistedDomains}
        generatedDomains={values.generatedDomains}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
      />

      {entity === BlocklistingEntityEnum.Channel && (
        <>
          <p className={classes.description}>Key/value pairs</p>

          <KeyValuePairs
            valuePath="blockedKeyValues"
            keyValuePairs={values.blockedKeyValues}
          />
        </>
      )}
    </fieldset>
  );
};

export default Blocklisting;
