import classNames from 'classnames';
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './Sanities.styles';
import { ISanity, sanityIconMap } from './Sanities.values';

export const Sanity = ({ sanities }: { sanities: ISanity[] }) => {
  const isOnlySanity = sanities.length === 1;
  const classes = useStyles();
  return (
    <div
      className={classNames([
        classes.sanitiesWrapper,
        {
          [`${classes.sanitiesWrapper}--oneSanity`]: isOnlySanity,
        },
      ])}
    >
      {sanities.map((sanity: ISanity) => (
        <Tooltip title={sanity.name} key={sanity.id}>
          <img
            src={sanityIconMap[sanity.id]}
            alt={sanity.name}
            className={classes.icon}
          />
        </Tooltip>
      ))}
    </div>
  );
};

const Sanities = ({ sanities }: { sanities: ISanity[] }) => {
  if (!sanities || sanities.length === 0) return null;

  return <Sanity sanities={sanities} />;
};

export default Sanities;
