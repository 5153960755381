import { makeStyles, Theme } from '@material-ui/core/styles';

export const baseCheckboxStyles = (theme: Theme) => ({
  checkbox: {
    fill: theme.palette.primary.main,
  },
});

const checkboxStyles = makeStyles((theme: Theme) => ({
  ...baseCheckboxStyles(theme),
}));

export default checkboxStyles;
