import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNav';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IOrderValues } from 'features/direct/order/components/OrderTabsForm/OrderFormValues';
import OrderTabsForm, {
  orderButtons,
} from 'features/direct/order/components/OrderTabsForm/OrderTabsForm';
import OrderFormValidation from 'features/direct/order/validations/orders';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  Advertiser,
  EntityType,
  FrequencyCapTimeUnit,
  Order,
  UpdateOrderMutationFn,
  User,
  useUpdateOrderMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useState } from 'react';
import { createDirectBreadcrumbConfig } from 'utils/breadcrumbConfigFactory';
import { createSelectOption, createUserOption } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { numbroNumberFormatting } from 'utils/numbers';

interface IUpdateOrderContainerProps {
  history: History;
  match: {
    params: {
      orderId: string;
    };
  };
  order: Order;
  allUsers: User[];
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });

export const submitUpdateOrder = (
  updateOrder: UpdateOrderMutationFn,
  id: string,
  initialValues: IOrderValues,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IOrderValues,
  { setStatus, setSubmitting }: FormikActions<IOrderValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = {
    ...differenceInValues(
      { ...formValues, journey: null, unlimitedObjective: null },
      {
        ...initialValues,
        journey: null,
        unlimitedObjective: null,
      }
    ),
    id,
  };

  updateOrder({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateOrderContainer = ({
  history,
  match,
  order,
  allUsers,
  hasEditPermissions,
}: IUpdateOrderContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Order.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateOrder] = useUpdateOrderMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, order.id);
    },
  });
  const validateOrder = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    startDate: order.startDate
      ? dateUtils.getDateInSpecificTimezone(order.startDate, order.timeZone)
      : null,
    endDate: order.endDate
      ? dateUtils.getDateInSpecificTimezone(order.endDate, order.timeZone)
      : null,
    timeZone: order.timeZone,
    name: order.name as string,
    id: order.id,
    altId: order.altId,
    externalId: order.externalId || '',
    externalSystemId: order.externalSystemId || '',
    allAdvertisers: [],
    advertiser: createSelectOption(order.advertiser as Advertiser),
    objective: order.objective
      ? numbro(order.objective).format(numbroNumberFormatting)
      : '',
    unlimitedObjective: !order.objective,
    journey: orderButtons[0].url,
    owner: createUserOption(order.owner as User),
    status: order.status,
    noEndDate: !order.endDate,
    frequencyCapCount: order.frequencyCapCount || '',
    frequencyCapTimeUnit: order.frequencyCapTimeUnit as FrequencyCapTimeUnit,
    frequencyCapValue: order.frequencyCapValue || '',
    territory: order.territory,
  };

  const breadcrumbConfig = createDirectBreadcrumbConfig(order);

  return (
    <>
      <BreadcrumbNav config={breadcrumbConfig} />
      <Formik<IOrderValues>
        initialValues={initialValues}
        validationSchema={OrderFormValidation(activeTerritory)}
        onSubmit={submitUpdateOrder(
          updateOrder,
          match.params.orderId,
          initialValues,
          toggleErrorModal,
          setSelectedJourney
        )}
        initialTouched={{
          externalId: true,
        }}
      >
        {(formikProps) => (
          <OrderTabsForm
            {...formikProps}
            update
            allUsers={allUsers}
            hasEditPermissions={hasEditPermissions}
            history={history}
            match={match}
            advertiserStatus={(order.advertiser as Advertiser).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateOrder,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Order,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateOrder,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateOrder"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateOrderWarningDialog"
      />
    </>
  );
};

export default UpdateOrderContainer;
