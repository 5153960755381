import { isEmailGlobalDomain } from './user';

export enum CookieOptionEnum {
  ESSENTIAL = 'Essential Cookies',
  GOOGLE_ANALYTICS = 'Google Analytics',
}

export const setConsentCookie = (googleAnalyticsConsent: boolean) => {
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setFullYear(cookieExpiryDate.getFullYear() + 2);

  document.cookie = `dax_consent_choices=${JSON.stringify({
    googleAnalytics: googleAnalyticsConsent,
  })}; expires=${cookieExpiryDate.toUTCString()}`;
};

export const getConsentChoices = () => {
  if (document.cookie.includes('dax_consent_choices')) {
    const consentCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('dax_consent_choices='))
      ?.split('=')[1];
    const consentChoices = JSON.parse(consentCookie as string);
    return consentChoices;
  }
  return {};
};

export const hasUserProvidedConsentChoices = (loggedInUserEmail: string) => {
  if (document.cookie.includes('dax_consent_choices')) return true;

  if (isEmailGlobalDomain(loggedInUserEmail)) {
    setConsentCookie(true);
    return true;
  }

  return false;
};
