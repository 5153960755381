import gql from 'graphql-tag';

export const GET_PRIMARY_CONTROLS = gql`
  query PrimaryControls {
    primaryControls {
      entity
      entries {
        enabled
        territory
      }
    }
  }
`;

export const GET_GLOBAL_BLOCKLISTS = gql`
  query GlobalBlocklists($territories: [Territory!]!) {
    globalBlacklistedDomains(territories: $territories) {
      territory
      domains
    }
    globalBlacklistedIabCategories(territories: $territories) {
      territory
      iabCategories {
        code
        name
      }
    }
  }
`;
