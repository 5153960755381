import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';

import CreateDspContainer from 'features/programmatic/dsp/containers/CreateDspContainer/CreateDspContainer';

import {
  TechnicalProvider,
  useTechnicalProviderMinimalQuery,
} from 'interfaces/generated.types';

interface ICreateDspPage {
  history: History;
  match: {
    params: {
      technicalProviderId: string;
    };
  };
}

const CreateDspPage = ({ history, match }: ICreateDspPage) => {
  const { loading, error, data } = useTechnicalProviderMinimalQuery({
    variables: { id: match.params.technicalProviderId },
  });

  if (!loading && (error || !(data && data.technicalProvider)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Dsp</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreateDspContainer
            history={history}
            match={match}
            technicalProvider={data?.technicalProvider as TechnicalProvider}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateDspPage;
