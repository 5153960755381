import classNames from 'classnames';
import Checkbox from 'features/targetingV2/components/core/Checkbox';
import FormControlLabel from 'features/targetingV2/components/core/FormControlLabel';
import { isOptionDisabled } from 'features/targetingV2/utils/multiSelect';
import { OptionType } from 'interfaces';
import React, { Ref } from 'react';

const Option = ({
  isSelected,
  isFocused,
  innerProps,
  innerRef,
  data,
  selectProps,
}: {
  innerProps: any;
  isSelected: boolean;
  isFocused: boolean;
  innerRef: Ref<any>;
  data: OptionType;
  selectProps: any;
}) => {
  const isDisabled = data.readOnly || isOptionDisabled(data, selectProps.value);

  return (
    <FormControlLabel
      {...innerProps}
      classes={{
        root: classNames([
          selectProps.classes.optionLabel,
          {
            [`${selectProps.classes.optionLabel}--focussed`]: isFocused,
          },
        ]),
        label: classNames([
          selectProps.classes.optionLabelText,
          selectProps.externalClasses.optionLabelText,
        ]),
      }}
      control={
        <Checkbox
          ref={innerRef}
          checked={isSelected}
          value={data.value}
          disabled={isDisabled}
          color="primary"
          classes={{
            root: classNames([
              selectProps.classes.optionCheckbox,
              selectProps.externalClasses.optionCheckbox,
            ]),
            colorPrimary: selectProps.classes.checkboxPrimaryColor,
          }}
        />
      }
      label={data.label}
      onClick={() => {
        if (!isDisabled) innerProps.onClick();
      }}
    />
  );
};

export default Option;
