import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 0, 3),
  },
  textfield: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  error: {
    margin: 0,
    fontSize: theme.spacing(1.625),
    color: theme.palette.error.main,
  },
  wrapper: {
    margin: theme.spacing(0, 1.5),
  },
  content: {
    padding: theme.spacing(0, 3),
  },
  closeButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.grey[800],
    display: 'inline-flex',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
