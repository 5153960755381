import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import CreateTargetingPackContainer from 'features/targeting/containers/CreateTargetingPackContainer/CreateTargetingPackContainer';

interface ICreateTargetingPackPage {
  history: History;
}

const CreateTargetingPackPage = ({ history }: ICreateTargetingPackPage) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Targeting Pack</h1>
      <CreateTargetingPackContainer history={history} />
    </Container>
  </main>
);

export default CreateTargetingPackPage;
