import { baseTargetingStyles } from 'assets/styles/components/Targeting.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseTargetingStyles(theme),
  groupWrapper: {
    textAlign: 'right',
    position: 'relative',
    '&::after': {
      content: "'or'",
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'block',
      padding: theme.spacing(1.25, 0),
      backgroundColor: theme.palette.common.white,
    },
  },
  headingWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    margin: 0,
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  iconAdd: {
    fontSize: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(2.5),
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  iconRemove: {
    position: 'absolute',
    fontSize: theme.spacing(2.5),
    right: theme.spacing(3.126),
    color: theme.palette.grey[800],
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(-1.5),
  },
  modalButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    margin: theme.spacing(5, 0, 3, 0),
  },
}));

export default useStyles;
