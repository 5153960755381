import Loader from 'components/Loader/Loader';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import LoginDaxForm, {
  ILoginDaxFormValues,
} from 'features/login/components/LoginDaxForm/LoginDaxForm';
import loginOptionsStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import DaxLoginFormValidation from 'features/login/validations/login';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { Typography } from '@material-ui/core';

const LoginDaxClient = () => {
  const classes = loginOptionsStyles();

  const history = useHistory();
  const { loaded, state, onDaxClientSignIn } = useAuthContext();

  const [error, setError] = useState('');

  const isLoading = !loaded || state === AuthState.Fetching;

  if (state === AuthState.LoggedIn) {
    return <Redirect to="/home" />;
  }

  const redirectOnUnverifiedEmail = () => {
    history.push('/email-verification');
  };

  const handleFormSubmit = (
    values: { email: string; password: string },
    actions: FormikHelpers<ILoginDaxFormValues>
  ) => {
    const params = {
      loginID: values.email,
      password: values.password,
    };

    actions.setSubmitting(false);
    onDaxClientSignIn(params, setError, redirectOnUnverifiedEmail);
  };

  return (
    <PageContainer>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Sign In
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <Formik<ILoginDaxFormValues>
              initialValues={{ email: '', password: '' }}
              onSubmit={(values, actions) => {
                handleFormSubmit(values, actions);
              }}
              validationSchema={DaxLoginFormValidation}
              component={(formikProps) => (
                <LoginDaxForm
                  error={error}
                  setError={setError}
                  {...formikProps}
                />
              )}
            />
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default LoginDaxClient;
