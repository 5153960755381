import cx from 'classnames';
import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';

import useStyles from './ErrorToast.styles';

interface ErrorToastProps {
  isToastOpen: boolean;
  setToastOpen: (state: boolean) => void;
}

const ErrorToast = ({ isToastOpen, setToastOpen }: ErrorToastProps) => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isToastOpen}
      autoHideDuration={5000}
      onClose={() => {
        setToastOpen(false);
      }}
    >
      <SnackbarContent
        className={classes.error}
        message={
          <span data-testid="errorToastMessage" className={classes.message}>
            <ErrorIcon className={cx(classes.icon, classes.iconVariant)} />
            The creative file could not be downloaded at this time. Please
            refresh the page and try again
          </span>
        }
      />
    </Snackbar>
  );
};

export default ErrorToast;
