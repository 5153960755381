import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import CampaignsTable from 'features/direct/campaign/components/CampaignsTable/CampaignsTable';
import OrderDetails from 'features/direct/order/components/OrderDetails/OrderDetails';
import { GET_ORDER } from 'features/direct/order/graphql/queries';
import { Form } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFieldUpdate, IFieldValidate, IFormProps } from 'interfaces';
import {
  AdvertiserStatus,
  EntityType,
  useCloneCampaignMutation,
  User,
  useUpdateCampaignMutation,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import {
  getOrderStatusData,
  IOrderValues,
  orderDetailsFields,
  orderStatusValues,
} from './OrderFormValues';
import useStyles from './OrderTabsForm.styles';

export interface IOrderProps {
  update?: boolean;
  allUsers: User[];
  hasEditPermissions?: boolean;
  history?: History;
  match?: {
    params: {
      orderId: string;
    };
  };
  advertiserStatus?: AdvertiserStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
}

export const orderButtons = [
  {
    text: 'Save and Review',
    url: '/order/ID',
    update: true,
    dataTc: 'orderReviewButton',
  },
  {
    text: 'Save and Create Campaign',
    url: '/order/ID/campaign',
    update: false,
    dataTc: 'orderCreateCampaignButton',
  },
  {
    text: 'Save and View All Orders',
    url: '/orders',
    update: false,
    dataTc: 'orderListButton',
  },
];

const getOrderDetailsTabErrors = createNumberOfFormErrorsFn();

const OrderTabsForm = (props: IFormProps<IOrderValues> & IOrderProps) => {
  const {
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    update = false,
    hasEditPermissions = true,
    history,
    match,
    status,
    advertiserStatus,
    initialValues,
    onFieldUpdate,
    onFieldValidate,
  } = props;
  const classes = useStyles();

  const [updateCampaign] = useUpdateCampaignMutation({
    refetchQueries: [
      {
        query: GET_ORDER,
        variables: { id: match && match.params ? match.params.orderId : '' },
      },
    ],
  });

  const [cloneCampaign] = useCloneCampaignMutation({
    refetchQueries: [
      {
        query: GET_ORDER,
        variables: { id: match && match.params ? match.params.orderId : '' },
      },
    ],
  });

  const validateCampaign = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { entityList, tab } = location.state || 0;
    if (entityList === 'listCampaigns') setTab(1);
    if (tab) setTab(tab);
  }, [location]);

  const isOrderTab = selectedTab === 0;
  const isListCampaignsTab = selectedTab === 1;
  const isOrderActivityTab = selectedTab === 2;

  return (
    <>
      <Form className={classes.form}>
        {update && advertiserStatus && (
          <>
            <EntityProgressBar
              statusData={getOrderStatusData(values, advertiserStatus)}
              currentStatus={values.status || ''}
              dataTc="orderStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={orderStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="orderHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="orderTabs"
          >
            <Tab
              dataTc="orderDetailsTab"
              label="Details"
              numberOfTabErrors={getOrderDetailsTabErrors(
                status,
                orderDetailsFields
              )}
            />
            <Tab
              disabled={!update}
              dataTc="listCampaignsTab"
              label="Campaigns"
            />
            <Tab
              disabled={!update}
              dataTc="orderActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListCampaignsTab || isOrderActivityTab,
            },
          ])}
        >
          {isOrderTab && <OrderDetails {...props} />}
          {isListCampaignsTab && match && history && (
            <div className={classes.table}>
              <CampaignsTable
                externalId={initialValues.externalId}
                title="Campaigns"
                updateCampaign={updateCampaign}
                cloneCampaign={cloneCampaign}
                validateCampaign={validateCampaign}
                history={history}
                isEditable={hasEditPermissions}
                isPageTable={false}
                orderId={match.params.orderId}
                customToolbarCtas={
                  hasEditPermissions && (
                    <StyledLink
                      location={{
                        pathname: `/order/${match.params.orderId}/campaign`,
                        state: { parent: location.state },
                      }}
                      color={LinkColorEnum.Secondary}
                      data-tc="createCampaignButton"
                    >
                      Create New Campaign
                    </StyledLink>
                  )
                }
              />
            </div>
          )}
          {isOrderActivityTab && (
            <ActivityTable
              entityType={EntityType.Order}
              id={values.id}
              data-tc="orderActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitOrderButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: location.state?.from || '/orders',
            state: location.state?.parent || {},
          }}
          variant={FormButtonVariants.split}
          buttons={orderButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default OrderTabsForm;
