import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IPublisherValues } from 'features/inventory/publisher/components/PublisherTabsForm/PublisherFormValues';
import PublisherTabsForm, {
  publisherButtons,
} from 'features/inventory/publisher/components/PublisherTabsForm/PublisherTabsForm';
import PublisherFormValidation from 'features/inventory/publisher/validations/publishers';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  CreatePublisherMutation,
  CreatePublisherMutationFn,
  Network,
  PricingType,
  Publisher,
  useCreatePublisherMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { getDefaultCurrencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTerritoryValues } from 'utils/territory';

interface ICreatePublisherContainerProps {
  history: History;
  network: Network;
  match: {
    params: {
      networkId: string;
    };
  };
}

export const handleOnComplete = (
  response: CreatePublisherMutation,
  history: History,
  selectedJourney: string,
  networkId: string
) => {
  const { createPublisher } = response;
  const { id } = createPublisher as Publisher;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: networkId,
    listEntity: selectedJourney === '/network/PARENT_ID' ? 'Publisher' : '',
  });
};

export const submitCreatePublisher =
  (
    createPublisher: CreatePublisherMutationFn,
    networkId: string,
    toggleErrorModal: () => void,
    setSelectedJourney: any
  ) =>
  (
    formValues: IPublisherValues,
    { setStatus, setSubmitting }: FormikActions<IPublisherValues>
  ) => {
    setSelectedJourney(formValues.journey);

    const formattedValues = formatValues(formValues);
    const filteredValues: any = pickBy({
      ...formValues,
      territories: formattedValues.territories,
      networkTerritories: null,
      networkId,
      networkName: null,
      journey: null,
      generatedDomains: null,
      blacklistedDomains: formattedValues.blacklistedDomains.length
        ? formattedValues.blacklistedDomains
        : null,
      blacklistedIabCategoryCodes: formattedValues.blacklistedIabCategoryCodes,
    });

    createPublisher({
      variables: {
        ...filteredValues,
        pricingAmount: formattedValues.pricingAmount,
      },
    }).catch((error) => {
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      });
    });
  };

const CreatePublisherContainer = ({
  history,
  match,
  network,
}: ICreatePublisherContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Publisher.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createPublisher] = useCreatePublisherMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney, match.params.networkId);
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IPublisherValues>
        initialValues={{
          pricingAmount: '0',
          generatedDomains: '',
          networkName: network.name as string,
          networkId: network.id,
          networkTerritories: network.territories,
          pricingType: PricingType.Cpm,
          blacklistedIabCategoryCodes: [],
          currency: getDefaultCurrencyBasedOnTerritory(activeTerritory!),
          blacklistedDomains: [''],
          id: '',
          name: '',
          journey: publisherButtons[0].url,
          territories:
            activeTerritory && network.territories.includes(activeTerritory)
              ? getTerritoryValues([activeTerritory])
              : [],
        }}
        validationSchema={PublisherFormValidation}
        onSubmit={submitCreatePublisher(
          createPublisher,
          match.params.networkId,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(props) => (
          <PublisherTabsForm
            {...props}
            goBackTo={{
              pathname: `/network/${match.params.networkId}`,
              state: location.state?.parent || {},
            }}
            history={history}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createPublisher"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreatePublisherContainer;
