import classNames from 'classnames';
import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import TargetingParameters from 'features/targeting/components/TargetingParameters/TargetingParameters';

import { IFormProps } from 'interfaces';

import {
  removeTargetingType,
  toggleTargetingType,
} from 'utils/targetingParameters';

import {
  targetingParameterClusivityTypesEnum,
  targetingParameterTypesEnum,
} from '../TargetingParameters/TargetingParametersValues';
import useStyles from './TargetingGeneralGroup.styles';

interface ITargetingProps {
  remove: () => void;
}

const TargetingGeneralGroup = (props: IFormProps<any> & ITargetingProps) => {
  const { values, remove: removeGroup } = props;
  const classes = useStyles();

  const fieldArrayName = 'targetingGeneralGroup';
  const targetingValues = values.targetingGeneralGroup;

  const [selectedTypes, setSelectedTypes] = useState<
    targetingParameterTypesEnum[]
  >([]);

  useEffect(() => {
    if (
      targetingValues.audienceParams &&
      targetingValues.audienceParams.length
    ) {
      setSelectedTypes([
        ...selectedTypes,
        ...targetingValues.audienceParams.map(
          (targeting: any) => targeting.type
        ),
      ]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.groupWrapper} data-tc="targetingGeneralGroup">
      <div
        className={classNames([
          classes.group,
          {
            [`${classes.group}--general`]: true,
          },
        ])}
      >
        <div className={classes.headingWrapper}>
          <p className={classes.heading} data-tc="targetingGeneralGroupHeading">
            General Targeting Group
          </p>

          <IconButton
            data-tc="targetingGeneralGroupRemoveIcon"
            onClick={() => removeGroup && removeGroup()}
            className={classes.iconRemove}
          >
            <ClearIcon />
            <span className="sr-only">Remove general targeting group</span>
          </IconButton>
        </div>
        <FieldArray name={`${fieldArrayName}.audienceParams`}>
          {({ push, remove }) => (
            <>
              {targetingValues.audienceParams &&
                targetingValues.audienceParams.length > 0 && (
                  <TargetingParameters
                    {...props}
                    remove={(index: number) => {
                      remove(index);
                      removeTargetingType(
                        setSelectedTypes,
                        selectedTypes,
                        targetingValues.audienceParams[index].type
                      );
                    }}
                    title="Targeting Parameter"
                    targetingGroupName="targetingGeneralGroup"
                    selectedTypes={selectedTypes}
                    toggleSelectedTargetingType={(
                      type: targetingParameterTypesEnum,
                      newType: targetingParameterTypesEnum
                    ) =>
                      toggleTargetingType(
                        setSelectedTypes,
                        selectedTypes,
                        type,
                        newType
                      )
                    }
                  />
                )}
              <div className={classes.addTargetingButton}>
                <StyledButton
                  data-tc="targetingAddIcon"
                  onClick={() => {
                    push({
                      type: '',
                      clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
                      readOnlyType: false,
                    });
                  }}
                  color={ButtonColorEnum.Primary}
                  variant={ButtonVariantEnum.Outlined}
                >
                  <AddIcon />
                  Add a Targeting Parameter
                </StyledButton>
              </div>
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default TargetingGeneralGroup;
