import ContractModal from 'components/Contract/ContractModal';
import Contracts from 'components/Contracts/Contracts';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import PublisherContractDialog from 'features/inventory/publisher/components/PublisherContractDialog/PublisherContractDialog';
import { History } from 'history';
import {
  Publisher,
  useAllPublisherContractsQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { formatContracts, getContractTimezone } from 'utils/contracts';

interface IPublisherContractsProps {
  history: History;
  publisher: Publisher;
  hasEditPermissions: boolean;
}

const PublisherContracts = ({
  history,
  publisher,
  hasEditPermissions,
}: IPublisherContractsProps) => {
  const { loading, error, data, refetch } = useAllPublisherContractsQuery({
    variables: { publisherId: publisher.id },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const currentTimezone = getContractTimezone(publisher, activeTerritory!);

  const formattedContracts = formatContracts(
    data?.allPublisherContracts || [],
    currentTimezone
  );

  if (loading) return <Loader />;

  if (!loading && error) return <Redirecting history={history} />;

  const createPublisherContractDialogProps = {
    publisher,
    refetch,
  };

  const updatePublisherContractDialogProps = {
    refetch,
    update: true,
  };

  return (
    <>
      <ContractModal
        testId={`publisherContract-${publisher.id}`}
        CustomDialog={PublisherContractDialog}
        customDialogProps={createPublisherContractDialogProps}
        buttonLabel="Create Publisher Contract"
        disabled={!hasEditPermissions}
        hasEditPermissions={hasEditPermissions}
      />
      <Contracts
        contracts={formattedContracts}
        CustomDialog={PublisherContractDialog}
        customDialogProps={updatePublisherContractDialogProps}
        hasEditPermissions={hasEditPermissions}
      />
    </>
  );
};

export default PublisherContracts;
