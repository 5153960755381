import { makeStyles, Theme } from '@material-ui/core/styles';

const useTableStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    '&--updating': {
      opacity: 0.5,
    },
  },
  table: {
    borderCollapse: 'separate',
  },
  body: {
    backgroundColor: theme.palette.common.white,
  },
  cell: {
    textAlign: 'center',
    borderBottom: 'none',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    '&:last-child': {
      borderRightColor: theme.palette.common.white,
    },
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.common.white}`,
    },
  },
  row: {
    '&:last-child td:first-child': {
      borderBottomLeftRadius: theme.spacing(1.25),
      borderLeft: `1px solid ${theme.palette.common.white}`,
    },
    '&:last-child td:last-child': {
      borderBottomRightRadius: theme.spacing(1.25),
      borderRight: `1px solid ${theme.palette.common.white}`,
    },
    '&:last-child td': {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    '&--subRow': {
      '& td': {
        borderBottom: 0,
      },
    },
  },
  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const useLoaderStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const usePaginationStyles = makeStyles((theme: Theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export const useFooterStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: 'none',
  },
  toolbar: {
    margin: theme.spacing(2.75, 0),
  },
  select: {
    padding: theme.spacing(0, 3, 0, 1),
  },
}));

export const useSelectFilterStyles = makeStyles(() => ({
  select: {
    fontSize: '12px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectMenu: {
    padding: 0,
  },
}));

export const useFilterStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0.625),
  },
  input: {
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '2px 0',
  },
}));

export const useEditableCellStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  cell: {
    fontWeight: 400,
    verticalAlign: 'top',
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: 'none',
    padding: theme.spacing(1),
    paddingBottom: 0,
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1.25),
      borderLeft: `1px solid ${theme.palette.common.white}`,
    },
    '&:last-child': {
      borderTopRightRadius: theme.spacing(1.25),
      borderRight: `1px solid ${theme.palette.common.white}`,
    },
  },
  tabTableCell: {
    borderTopLeftRadius: '0 !important',
  },
  filter: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: `3px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1, 0.5),
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.common.white}`,
    },
  },
}));

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    '&--tabTable': {
      paddingRight: theme.spacing(4),
    },
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    '@media screen and (max-width: 1280px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: theme.spacing(2.626),
    fontWeight: 500,
    minWidth: 'fit-content',
    color: theme.palette.grey[700],
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(4),
    },
  },
  spacer: {
    flex: '1 1 10%',
  },
  textfield: {
    maxWidth: 200,
  },
  searchTextField: {
    border: `1.5px solid ${theme.palette.common.white}`,
    '&:hover, &:focus': {
      border: `1.5px solid ${theme.palette.common.white}`,
    },
    lineHeight: theme.spacing(3),
  },
  columnToggle: {
    paddingRight: theme.spacing(4),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 200,
    overflow: 'auto',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  error: {
    fontSize: theme.spacing(1.625),
    color: theme.palette.error.main,
    padding: `${theme.spacing(4)} 0`,
    margin: 0,
  },
}));

export const useDateRangeInputFilterStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0, 0, 0.625),
  },
  input: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '20px',
    padding: '2px 0',
  },
}));

export const useCtaFooterStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2.5, 0, 2.5),
  },
  button: {
    margin: theme.spacing(0, 0, 0, 1.5),
  },
}));

export default useTableStyles;
