import { format } from 'date-fns';
import { targetingParameterTypesEnum } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import { IDateRange } from 'interfaces';
import { Clusivity, Region, Territory } from 'interfaces/generated.types';
import { CurrenciesEnum } from 'utils/currency';

import { TimeZones } from './date';
import {
  AdvertiserAndAgencyExternalSystems,
  OrderExternalSystems,
} from './defaultExternalSystems';
import { TerritoryLabel, TerritoryToCountryValueMap } from './territory';

export const defaultCurrencyBasedOnTerritory = {
  [Territory.Uk]: CurrenciesEnum.GBP,
  [Territory.Us]: CurrenciesEnum.USD,
  [Territory.Ca]: CurrenciesEnum.CAD,
};

export const getDefaultCurrencyBasedOnTerritory = (territory: Territory) =>
  defaultCurrencyBasedOnTerritory[territory];

export const getCustomCurrencyBasedOnTerritory = (
  territory: Territory | undefined
) => {
  switch (territory) {
    case Territory.Ca:
    case Territory.Us:
      return CurrenciesEnum.USD;
    case Territory.Uk:
    default:
      return CurrenciesEnum.GBP;
  }
};

export const defaultTimezoneBasedOnTerritory = {
  [Territory.Uk]: TimeZones.EuropeLondon,
  [Territory.Us]: TimeZones.AmericaNewYork,
  [Territory.Ca]: TimeZones.AmericaNewYork,
};

export const getDefaultTimezoneBasedOnTerritory = (territory: Territory) =>
  defaultTimezoneBasedOnTerritory[territory];

export const defaultRegionBasedOnTerritory = {
  [Territory.Uk]: Region.Eu,
  [Territory.Us]: Region.UsEast,
  [Territory.Ca]: Region.UsEast,
};

export const getDefaultRegionBasedOnTerritory = (territory: Territory) =>
  defaultRegionBasedOnTerritory[territory];

export const defaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory = {
  [Territory.Uk]: AdvertiserAndAgencyExternalSystems.Salesforce,
  [Territory.Us]: AdvertiserAndAgencyExternalSystems.TOMS,
  [Territory.Ca]: '',
};

export const getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory = (
  territory: Territory
) => defaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory[territory];

export const defaultExternalSystemIdBasedOnTerritory = {
  [Territory.Uk]: OrderExternalSystems.DAX,
  [Territory.Us]: OrderExternalSystems.TOMS,
  [Territory.Ca]: OrderExternalSystems.TOMS,
};

export const getDefaultExternalSystemIdBasedOnTerritory = (
  territory: Territory
) => defaultExternalSystemIdBasedOnTerritory[territory];

export const getDefaultTargetingGroupWithCountryParamsBasedOnTerritory = (
  territory: Territory
) => ({
  audienceParams: [
    {
      type: targetingParameterTypesEnum.COUNTRY,
      clusivity: Clusivity.Include,
      readOnly: false,
      countryParams: {
        countries: [
          {
            label: TerritoryLabel[territory],
            value: TerritoryToCountryValueMap[territory],
            readOnly: false,
          },
        ],
      },
    },
  ],
  id: '',
  channels: [],
});

export const getDateFormatBasedOnTerritory = (
  territory: Territory | undefined,
  withTimePattern: boolean = false
) => {
  let datePattern = '';

  switch (territory) {
    case Territory.Ca:
    case Territory.Us:
      datePattern = 'MM/dd/yyyy';
      break;
    case Territory.Uk:
    default:
      datePattern = 'dd/MM/yyyy';
      break;
  }

  return withTimePattern ? `${datePattern} HH:mm` : datePattern;
};

export const getFormattedDateBasedOnTerritory = (
  date: Date,
  territory: Territory | undefined,
  showTime: boolean = false
) => {
  const datePattern = getDateFormatBasedOnTerritory(territory, showTime);

  return format(date, datePattern);
};

export const getDateRangeDisplayedValueBasedOnTerritory = (
  dateRange: IDateRange,
  territory: Territory
) =>
  `${getFormattedDateBasedOnTerritory(
    dateRange.startDate,
    territory
  )} - ${getFormattedDateBasedOnTerritory(dateRange.endDate, territory)}`;

export default {
  getDefaultCurrencyBasedOnTerritory,
  getDefaultTimezoneBasedOnTerritory,
  getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory,
  getDefaultExternalSystemIdBasedOnTerritory,
  getDefaultTargetingGroupWithCountryParamsBasedOnTerritory,
  getDateFormatBasedOnTerritory,
  getFormattedDateBasedOnTerritory,
  getDateRangeDisplayedValueBasedOnTerritory,
};
