import colors from 'assets/styles/colors';
import {
  ISanity,
  SanityEnum,
  sanityIconMap,
} from 'components/Sanities/Sanities.values';
import { parseDate } from 'components/Table/TableSort';
import { addDays, endOfDay, format, startOfDay, subDays } from 'date-fns';
import { IChartSegmentData } from 'interfaces';
import { Campaign, CampaignStatus } from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import dateUtils from 'utils/date';
import { calculatePercentage } from 'utils/numbers';

const ChartSanities = {
  recentlyEnded: [SanityEnum.FAILED_OBJECTIVE, SanityEnum.ACHIEVED_OBJECTIVE],
  endingSoon: [
    SanityEnum.DELIVERING,
    SanityEnum.NOT_DELIVERING,
    SanityEnum.UNDER_DELIVERING,
    SanityEnum.OVER_DELIVERING,
  ],
  live: [
    SanityEnum.DELIVERING,
    SanityEnum.NOT_DELIVERING,
    SanityEnum.UNDER_DELIVERING,
    SanityEnum.OVER_DELIVERING,
  ],
  overallDelivery: [
    SanityEnum.DELIVERING,
    SanityEnum.NOT_DELIVERING,
    SanityEnum.UNDER_DELIVERING,
    SanityEnum.OVER_DELIVERING,
    SanityEnum.MISSING_CREATIVE,
    SanityEnum.ACHIEVED_OBJECTIVE,
    SanityEnum.FAILED_OBJECTIVE,
    SanityEnum.STARTING_SOON,
    SanityEnum.ENDING_SOON,
    SanityEnum.INCOMPLETE_SEQUENCE,
  ],
};

export type ChartIdType =
  | 'recentlyEnded'
  | 'endingSoon'
  | 'live'
  | 'overallDelivery';

export const updateSanityCount = (
  count: number | undefined,
  campaign: any,
  sanity: SanityEnum
) => {
  if (typeof count === 'undefined') return count;
  const hasSanity = campaign.sanities.some((san: ISanity) => san.id === sanity);

  return hasSanity ? count + 1 : count;
};

export type CampaignGraphData = Pick<
  Campaign,
  'id' | 'sanities' | 'status' | 'startDate' | 'endDate' | 'timeZone'
>;

export const formatCampaignGraphData = memoizeOne(
  (data: CampaignGraphData[] = []) =>
    data.map((campaign) => ({
      sanities: campaign.sanities,
      status: campaign.status,
      startDate: campaign.startDate
        ? format(
            dateUtils.getDateInSpecificTimezone(
              campaign.startDate,
              campaign.timeZone
            ),
            'dd/MM/yyyy'
          )
        : '',
      endDate: campaign.endDate
        ? format(
            dateUtils.getDateInSpecificTimezone(
              campaign.endDate,
              campaign.timeZone
            ),
            'dd/MM/yyyy'
          )
        : '',
    }))
);

export const matchesChartFilter = (campaign: any, chartId: ChartIdType) => {
  const endDate = parseDate(campaign.endDate);

  switch (chartId) {
    case 'recentlyEnded':
      return (
        dateUtils.isDateInRange(
          endDate,
          startOfDay(subDays(new Date(), 3)),
          endOfDay(new Date())
        ) && campaign.status === CampaignStatus.Completed
      );
    case 'endingSoon':
      return dateUtils.isDateInRange(
        endDate,
        startOfDay(new Date()),
        endOfDay(addDays(new Date(), 3))
      );
    case 'live':
      return campaign.status === CampaignStatus.Live;
    case 'overallDelivery':
      return true;
    default:
      return false;
  }
};

export interface ChartMetrics {
  total: number;
  sanities: {
    [key in SanityEnum]?: number;
  };
}

export type CampaignDeliveryMetricsType = Record<ChartIdType, ChartMetrics>;

const initialMetrics: CampaignDeliveryMetricsType = {
  recentlyEnded: {
    total: 0,
    sanities: {
      FAILED_OBJECTIVE: 0,
      ACHIEVED_OBJECTIVE: 0,
    },
  },
  endingSoon: {
    total: 0,
    sanities: {
      DELIVERING: 0,
      OVER_DELIVERING: 0,
      UNDER_DELIVERING: 0,
      NOT_DELIVERING: 0,
    },
  },
  live: {
    total: 0,
    sanities: {
      DELIVERING: 0,
      OVER_DELIVERING: 0,
      UNDER_DELIVERING: 0,
      NOT_DELIVERING: 0,
    },
  },
  overallDelivery: {
    total: 0,
    sanities: {
      DELIVERING: 0,
      OVER_DELIVERING: 0,
      UNDER_DELIVERING: 0,
      NOT_DELIVERING: 0,
      MISSING_CREATIVE: 0,
      ACHIEVED_OBJECTIVE: 0,
      FAILED_OBJECTIVE: 0,
      RECENTLY_UPDATED: 0,
      STARTING_SOON: 0,
      ENDING_SOON: 0,
      INCOMPLETE_SEQUENCE: 0,
    },
  },
};

export const getCampaignDeliveryMetricsPerChart = (
  campaigns: any[],
  chartOrder: ChartIdType[]
) =>
  campaigns.reduce<CampaignDeliveryMetricsType>((acc, currentCampaign) => {
    let updatedStats = acc;

    chartOrder.forEach((chartId) => {
      if (matchesChartFilter(currentCampaign, chartId)) {
        const chartSanities = ChartSanities[chartId];
        const updatedSanities = chartSanities.reduce(
          (previous, currentSanity) => ({
            ...previous,
            [currentSanity]: updateSanityCount(
              updatedStats[chartId].sanities[currentSanity],
              currentCampaign,
              currentSanity
            ),
          }),
          {}
        );

        updatedStats = {
          ...updatedStats,
          [chartId]: {
            total: updatedStats[chartId].total + 1,
            sanities: updatedSanities,
          },
        };
      }
    });

    return updatedStats;
  }, initialMetrics);

export const getSanityInfo = (sanity: SanityEnum) => {
  switch (sanity) {
    case SanityEnum.ACHIEVED_OBJECTIVE:
      return {
        label: 'Achieved Objective',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Achieved objective',
      };
    case SanityEnum.FAILED_OBJECTIVE:
      return {
        label: 'Failed Objective',
        color: colors.daxStatusWarning,
        filterValue: 'Failed objective',
      };
    case SanityEnum.OVER_DELIVERING:
      return {
        label: 'Over Delivering',
        color: colors.daxStatusError,
        filterValue: 'Over delivering',
      };
    case SanityEnum.NOT_DELIVERING:
      return {
        label: 'Not Delivering',
        color: colors.daxStatusWarning,
        filterValue: 'Not delivering',
      };
    case SanityEnum.UNDER_DELIVERING:
      return {
        label: 'Under Delivering',
        color: colors.daxStatusWarning,
        filterValue: 'Under delivering',
      };
    case SanityEnum.DELIVERING:
      return {
        label: 'Delivering',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Delivering',
      };
    case SanityEnum.MISSING_CREATIVE:
      return {
        label: 'Missing Creative',
        color: colors.daxStatusError,
        filterValue: 'Missing Creative',
      };
    case SanityEnum.STARTING_SOON:
      return {
        label: 'Starting Soon',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Starting soon',
      };
    case SanityEnum.ENDING_SOON:
      return {
        label: 'Ending Soon',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Ending soon',
      };
    case SanityEnum.INCOMPLETE_SEQUENCE:
      return {
        label: 'Incomplete Sequence',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Incomplete sequence',
      };
    case SanityEnum.RECENTLY_UPDATED:
      return {
        label: 'Recently Updated',
        color: colors.daxGreyscaleDarkGrey,
        filterValue: 'Recently updated',
      };
    default:
      return undefined;
  }
};

const ChartFilters = {
  recentlyEnded: [
    { id: 'status', value: ['COMPLETED'] },
    {
      id: 'endDate',
      value: [startOfDay(subDays(new Date(), 3)), endOfDay(new Date())],
    },
  ],
  endingSoon: [
    {
      id: 'endDate',
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 3))],
    },
  ],
  live: [{ id: 'status', value: ['LIVE'] }],
  overallDelivery: [],
};

const getChartSegments = (
  chartId: string,
  selectedSegmentId: string,
  chartStats: ChartMetrics
): IChartSegmentData[] => {
  const chartSanities = ChartSanities[chartId as ChartIdType];

  const initialChartData: IChartSegmentData[] = [];

  return chartSanities.reduce((previous, currentSanity) => {
    const sanityInfo = getSanityInfo(currentSanity);
    const segmentId = `${chartId}-${currentSanity}`;

    const numberOfCampaignsWithCurrentSanity =
      chartStats.sanities[currentSanity];

    const percentage = numberOfCampaignsWithCurrentSanity
      ? calculatePercentage(
          numberOfCampaignsWithCurrentSanity,
          chartStats.total
        )
      : 'N/A';

    if (sanityInfo)
      previous.push({
        id: segmentId,
        category: sanityInfo.label,
        value: numberOfCampaignsWithCurrentSanity,
        color: sanityInfo.color,
        icon: sanityIconMap[currentSanity],
        percentage,
        isActive: selectedSegmentId === segmentId,
        filters: [
          ...ChartFilters[chartId as ChartIdType],
          { id: 'Campaign Health', value: [sanityInfo.filterValue] },
        ],
      });

    return previous;
  }, initialChartData);
};

export enum ChartLabelEnum {
  recentlyEnded = 'Recently Ended',
  endingSoon = 'Ending Soon',
  live = 'Live Campaigns',
  overallDelivery = 'Campaigns Overall Delivery',
}

export interface ICampaignDeliveryChartsData {
  id: ChartIdType;
  label: string;
  segments: IChartSegmentData[];
}

export const getConfigForCampaignDeliveryCharts = (
  campaigns: any[],
  selectedSegmentId: string,
  chartOrder: ChartIdType[]
): ICampaignDeliveryChartsData[] => {
  const metrics = getCampaignDeliveryMetricsPerChart(campaigns, chartOrder);

  const data = chartOrder.map((chartId: ChartIdType) => {
    const chartStats = metrics[chartId];

    return {
      id: chartId,
      label: `${ChartLabelEnum[chartId]} - ${chartStats.total}`,
      segments: getChartSegments(chartId, selectedSegmentId, chartStats),
    };
  });

  return data;
};
