import { ReactComponent as CheckIcon } from 'assets/icons/TargetingV2/dax-checkIcon.svg';
import { ReactComponent as NotAvailableIcon } from 'assets/icons/TargetingV2/dax-notAvailableIcon.svg';
import { IOption } from 'features/targetingV2/types/common';
import { Clusivity } from 'interfaces/generated.types';
import React from 'react';

export const clusivityOptionLabels = {
  [Clusivity.Include]: 'is any of',
  [Clusivity.Exclude]: 'is none of',
};

export const clusivityOptions = Object.keys(clusivityOptionLabels).map(
  (clusivity) => ({
    label: `${clusivityOptionLabels[clusivity as Clusivity]}`,
    value: clusivity,
  })
) as IOption<Clusivity>[];

export const getClusivityIcon = (clusivity: Clusivity, classes: any) => {
  const clusivityText = clusivity.toLowerCase();
  const props = {
    'data-testid': `${clusivityText}ClusivityIcon`,
    'aria-label': `${clusivityText}-icon`,
  };

  switch (clusivity) {
    case Clusivity.Include:
      return <CheckIcon {...props} className={classes.checkIcon} />;
    case Clusivity.Exclude:
      return (
        <NotAvailableIcon {...props} className={classes.notAvailableIcon} />
      );
    default:
      return <></>;
  }
};
