import classNames from 'classnames';
import Slider from 'features/targetingV2/components/core/Slider/Slider';
import Typography from 'features/targetingV2/components/core/Typography';
import { ISliderValue } from 'features/targetingV2/types/common';
import React, { ReactNode } from 'react';

import useStyles from './Accuracy.styles';

export interface IAccuracyProps {
  allAccuracyValues: ISliderValue[];
  sliderValue: number | string;
  label: string;
  onSliderChange: (newValue: number | string) => void;
  disabled?: boolean;
  classes?: { container?: string };
  sliderIcons?: {
    LeftIcon: ReactNode;
    RightIcon: ReactNode;
  };
  sliderLabels?: {
    leftLabel: string;
    rightLabel: string;
  };
  AccuracyIcon?: React.ReactNode;
}

const Accuracy = (props: IAccuracyProps) => {
  const {
    allAccuracyValues,
    sliderValue,
    label,
    onSliderChange,
    disabled = false,
    classes: externalClasses = {},
    sliderIcons,
    sliderLabels,
    AccuracyIcon,
  } = props;
  const classes = useStyles({ disabled });

  return (
    <>
      <div className={classes.blankSpace} />
      <div
        data-testid="accuracy"
        className={classNames(classes.container, externalClasses.container)}
      >
        <div className={classes.labelWrapper}>
          {AccuracyIcon && <span className={classes.icon}>{AccuracyIcon}</span>}
          <Typography className={classes.text}>{label}</Typography>
        </div>
        <Slider
          allValues={allAccuracyValues}
          value={sliderValue}
          onChange={onSliderChange}
          disabled={disabled}
          icons={sliderIcons}
          labels={sliderLabels}
        />
      </div>
    </>
  );
};

export default Accuracy;
