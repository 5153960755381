import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '220px',
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '220px',
  },
}));

export default useStyles;
