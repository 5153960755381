import pageStyles from 'assets/styles/components/Page.styles';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { dataCenterStatusValues } from 'features/programmatic/datacenter/components/DatacenterTabsForm/DatacenterFormValues';
import { GET_TECHNICAL_PROVIDER } from 'features/programmatic/technicalProvider/graphql/queries';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  DataCenter,
  DataCenterStatus,
  EntityType,
  useUpdateDataCenterMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

interface IDatacentersTableProps {
  datacenters: DataCenter[];
  match: {
    params: {
      technicalProviderId: string;
    };
  };
  history: History;
  isEditable: boolean;
}

interface IDatacentersRow {
  id: string;
  altId: number;
  name: string;
  status: DataCenterStatus;
}

const formatData = memoizeOne((data: DataCenter[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const DatacentersTable = (props: IDatacentersTableProps) => {
  const { datacenters, match, history, isEditable = true } = props;

  const classes = pageStyles();
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateDataCenter] = useUpdateDataCenterMutation({
    refetchQueries: [
      {
        query: GET_TECHNICAL_PROVIDER,
        variables: {
          id: match.params.technicalProviderId,
          territories: activeTerritory,
        },
      },
    ],
  });

  const validateDataCenter = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createDatacenterCta = isEditable && (
    <StyledLink
      location={{
        pathname: `/programmatic-connector/${match.params.technicalProviderId}/datacenter`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newDatacenterButton"
    >
      Create New Datacenter
    </StyledLink>
  );

  const dataCenterColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IDatacentersRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/datacenter/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IDatacentersRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: dataCenterStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={dataCenterStatusValues}
                name="dataCenterStatusSelect"
                dataTc="dataCenterStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Datacenters"
        columns={dataCenterColumns}
        data={formatData(datacenters)}
        isEditable={isEditable}
        dataTc={`${match.params.technicalProviderId || ''}listDatacenterTable`}
        isPageTable={false}
        onCellUpdate={(row: IDatacentersRow, setErrorModal, setUpdating) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateDataCenter,
            handleContinue: () => history.push(`/datacenter/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Datacenter',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateDataCenter,
            entity: { ...entity, type: EntityType.Datacenter },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createDatacenterCta}
      />
    </div>
  );
};

export default DatacentersTable;
