import CategoryValueItem from 'features/insights/components/CategoryValueItem/CategoryValueItem';
import {
  PublisherInsightsFilterCategory,
  PublisherInsightsFilterValue,
} from 'interfaces/generated.types';
import React from 'react';
import { ISelectedFilter } from 'utils/insights';
import {
  handleValueItemClick,
  isCheckboxDisabled,
  isValueAlreadySelected,
} from 'utils/insightsFilters';

import Typography from '@material-ui/core/Typography';

import useStyles from './CategoryValuesContainer.styles';

interface IFilterValuesListProps {
  filterCategory: PublisherInsightsFilterCategory;
  selectedFilters: ISelectedFilter[];
  setSelectedFilters: (newSelectedFilters: ISelectedFilter[]) => void;
  filterValues: PublisherInsightsFilterValue[];
  title?: string;
}

const FilterValuesList = ({
  filterCategory,
  selectedFilters,
  setSelectedFilters,
  filterValues,
  title,
}: IFilterValuesListProps) => {
  const classes = useStyles();

  return (
    <>
      {title && (
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
      )}
      {filterValues.map((filterValue) => (
        <CategoryValueItem
          key={filterValue.id}
          filterValue={filterValue}
          isDisabled={isCheckboxDisabled(selectedFilters, filterCategory.type)}
          isChecked={isValueAlreadySelected(selectedFilters, filterValue.id)}
          onClick={() =>
            handleValueItemClick(
              filterValue.id,
              filterCategory,
              selectedFilters,
              setSelectedFilters
            )
          }
        />
      ))}
    </>
  );
};

export default FilterValuesList;
