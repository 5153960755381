import { Prefixes, PrefixTypes } from 'components/NumberInput/NumberInput';
import numbro from 'numbro';

export const numbroNumberFormatting = {
  thousandSeparated: true,
  mantissa: 0,
  trimMantissa: true,
};
export const numbroDecimalFormatting = {
  thousandSeparated: true,
  optionalMantissa: true,
  mantissa: 2,
  trimMantissa: true,
};
export const numbroFullDecimalFormatting = {
  thousandSeparated: true,
  mantissa: 2,
};
export const numbroPercentageFormatting = {
  output: 'percent',
  optionalMantissa: true,
  mantissa: 2,
  trimMantissa: true,
} as numbro.Format;

export const numbroFullPercentageFormatting = {
  output: 'percent',
  mantissa: 2,
};

export const numbroWholePercentageFormatting = {
  output: 'percent',
  mantissa: 0,
} as numbro.Format;

export const parseFormattedValue = (value: string) => numbro(value).value();

export const parseStringToNumberOrEmptyString = (value: string) => {
  const numericValue = parseFormattedValue(value);

  return Number.isNaN(numericValue) || numericValue === undefined
    ? ''
    : numericValue;
};

export const parseCurrencyToNumber = (value: string) => {
  const numberValue = Prefixes.includes(value[0] as PrefixTypes)
    ? value.substring(1)
    : value;
  return parseFormattedValue(numberValue);
};

export const sumTwoNumbers = (
  a: number | string | null,
  b: number | string | null
) => {
  const valueOfA = numbro(a || 0);
  const valueOfB = numbro(b || 0).value();
  return valueOfA.add(valueOfB).format(numbroDecimalFormatting);
};

export const subtractTwoNumbers = (
  value: number | string | null,
  minusValue: number | string | null
) => {
  const valueOfA = numbro(value || 0);
  const valueOfB = numbro(minusValue || 0).value();
  return valueOfA.subtract(valueOfB).format(numbroDecimalFormatting);
};

export const calculatePercentage = (
  numerator: number | string | null,
  denominator: number | string | null
) => {
  const valueOfNumerator = numbro(numerator || 0);
  const valueOfDenominator = numbro(denominator || 1).value();
  return valueOfNumerator
    .divide(valueOfDenominator)
    .format(numbroPercentageFormatting);
};

export const convertNumberToPercentage = (
  number: number | null,
  defaultValue: string = ''
) => (number !== null ? calculatePercentage(number, 1) : defaultValue);

export const multiplyTwoNumbers = (
  valueOfA: number | string | null,
  valueOfB: number | string | null
) => {
  const valueA = numbro(valueOfA || 0);
  const valueB = numbro(valueOfB || 0).value();
  return numbro(valueA).multiply(valueB).value();
};

export const divideTwoNumbers = (
  numerator: number | string | null,
  denominator: number | string | null
) => {
  const valueOfNumerator = numbro(numerator || 0);
  const valueOfDenominator = numbro(denominator || 1).value();
  return numbro(valueOfNumerator).divide(valueOfDenominator).value();
};

export const parseDurationValue = (duration: number | undefined) =>
  parseFormattedValue(
    numbro(duration || 0)
      .format({ mantissa: 4 })
      .slice(0, -1)
  );

export default {
  numbroNumberFormatting,
  numbroDecimalFormatting,
  numbroFullDecimalFormatting,
  numbroPercentageFormatting,
  numbroFullPercentageFormatting,
  numbroWholePercentageFormatting,
  parseFormattedValue,
  sumTwoNumbers,
  subtractTwoNumbers,
  calculatePercentage,
  multiplyTwoNumbers,
  parseDurationValue,
  parseStringToNumberOrEmptyString,
};
