import Loader from 'components/Loader/Loader';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { Field } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';

import { IFormProps, IOption } from 'interfaces';
import { Dsp, useAllDspsQuery } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import {
  createSelectOptions,
  transformSelectOption,
} from 'utils/dataTransformation';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import {
  findDspInAllDsps,
  generateSeatOptions,
  handleDspSelection,
  handleGenerateId,
  handlePlaceHolder,
} from 'utils/dealConnector';
import useStyles from './DealConnector.styles';

const DealConnector = (props: IFormProps<any>) => {
  const {
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    status = {},
  } = props;

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data } = useAllDspsQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  const [idFieldDisabled, setIdFieldDisabled] = useState(true);

  const allDsps = (data?.allDsps as Dsp[]) || [];

  const classes = useStyles();

  const [isAllSeatsDisabled, setIsAllSeatsDisabled] = useState<boolean>(false);

  const handleAllKnownSeats = (checked: boolean) => {
    setFieldValue('seats', []);
    setFieldValue('allKnownSeats', checked);
  };

  const handleAllSeats = (checked: boolean) => {
    setFieldValue('allSeats', checked);
    handleAllKnownSeats(checked);
  };

  if (loading) {
    return <Loader />;
  }
  const currentDspId = values.dsp && values.dsp.value;
  const currentDsp = findDspInAllDsps(allDsps, currentDspId);

  const disableAllSeats =
    isAllSeatsDisabled ||
    !values.dsp ||
    (!!currentDsp?.technicalProvider?.openSeatDsp &&
      currentDsp?.technicalProvider?.openSeatDsp?.id !== currentDsp?.id);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MultiSelect
          data-tc="dealDspMultiSelect"
          id="dspMultiSelect"
          label="Dsp"
          isMulti={false}
          selectAll={false}
          maxSelectHeight={250}
          name="dsp"
          noMargin
          onBlur={() => setFieldTouched('dsp', true)}
          onChange={(fieldValue: IOption<string>) => {
            setFieldValue('dsp', fieldValue);
            setFieldValue('seats', []);
            handleDspSelection(
              allDsps,
              fieldValue,
              setFieldValue,
              setIsAllSeatsDisabled
            );
          }}
          options={createSelectOptions(allDsps)}
          placeholder="Select a Dsp..."
          value={values.dsp}
          attributes={{
            fieldAttribute: 'dealDspField',
            menuAttribute: 'dealDspMenuOptions',
            chipAttribute: 'dealDspChip',
          }}
          errorProps={{
            helperText: status.dsp,
            FormHelperTextProps: {
              error: !!status.dsp,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelect
          data-tc="dealSeatMultiSelect"
          id="dealSealMultiSelect"
          label="Seats"
          isMulti
          noMargin
          selectAll={false}
          isDisabled={
            transformSelectOption(values.dsp) === '' ||
            values.allKnownSeats ||
            values.allSeats
          }
          maxSelectHeight={250}
          name="seats"
          onBlur={() => setFieldTouched('seats', true)}
          onChange={(fieldValue: any) => {
            setFieldValue('seats', fieldValue);
          }}
          options={
            values.dsp && allDsps.length > 0
              ? generateSeatOptions(allDsps as Dsp[], values.dsp)
              : []
          }
          placeholder={handlePlaceHolder(values.allKnownSeats, values.allSeats)}
          value={values.seats}
          attributes={{
            fieldAttribute: 'dealSeatField',
            menuAttribute: 'dealSeatMenuOptions',
            chipAttribute: 'dealSeatChip',
          }}
          errorProps={{
            helperText: errors.seats || status.seats,
            FormHelperTextProps: {
              error: !!errors.seats || !!status.seats,
            },
          }}
        />
        <FormControl component={'fieldset' as 'div'}>
          <FormControlLabel
            control={
              <Field
                component={Checkbox}
                type="checkbox"
                name="allSeats"
                data-tc="dealAllSeatsCheckbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleAllSeats(e.target.checked)
                }
                value={values.allSeats}
                checked={values.allSeats}
                className={classes.checkbox}
                disabled={disableAllSeats}
              />
            }
            label="All Seats"
          />
        </FormControl>
        <FormControl component={'fieldset' as 'div'}>
          <FormControlLabel
            control={
              <Field
                component={Checkbox}
                type="checkbox"
                name="allKnownSeats"
                data-tc="dealAllKnownSeatsCheckbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleAllKnownSeats(e.target.checked)
                }
                value={values.allKnownSeats}
                checked={values.allKnownSeats || values.allSeats}
                disabled={values.allSeats || !values.dsp}
                className={classes.checkbox}
              />
            }
            label="All Known Seats"
          />
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <Field
          component={TextField}
          label="Deal ID"
          placeholder="Deal ID"
          data-tc="dealExternalId"
          name="externalId"
          id="externalId"
          fullWidth
          disabled={idFieldDisabled}
          helperText={
            (touched.externalId && errors.externalId) || status.externalId
          }
          FormHelperTextProps={{
            error: !!(
              (touched.externalId && errors.externalId) ||
              status.externalId
            ),
          }}
        />
      </Grid>
      <div className={classes.buttons}>
        <StyledButton
          color={ButtonColorEnum.Secondary}
          variant={ButtonVariantEnum.Outlined}
          type="button"
          disabled={!values.dsp}
          data-tc="dealEditId"
          onClick={() => setIdFieldDisabled(!idFieldDisabled)}
        >
          Edit ID
        </StyledButton>
        <ApolloConsumer>
          {(client) => (
            <StyledButton
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              type="button"
              disabled={!values.dsp}
              data-tc="dealGenerateUniqueId"
              onClick={() => handleGenerateId(client, setFieldValue)}
            >
              Generate New ID
            </StyledButton>
          )}
        </ApolloConsumer>
      </div>
    </Grid>
  );
};

export default DealConnector;
