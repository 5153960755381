import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    display: 'flex',
    alignItems: 'center',
    maxHeight: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  container: {
    margin: theme.spacing(0, 8),
    width: '100%',
  },
}));

export default useStyles;
