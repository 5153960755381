import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { Entity } from 'interfaces/types';
import React from 'react';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import useStyles from './Contract.styles';
import ContractActivityModal from './ContractActivityModal';
import ContractModal from './ContractModal';

export interface IContract {
  id: string;
  startDate: Date;
  endDate?: Date | null;
  customHeader?: string;
  __typename?: Entity | undefined;
  [key: string]: any;
}

export interface IContractProps {
  contract: IContract;
  readOnly?: boolean;
  hasEditPermissions?: boolean;
  customDialogProps?: any;
  CustomDialog: React.ElementType<any>;
}

const Contract = ({
  contract,
  readOnly = false,
  hasEditPermissions = false,
  customDialogProps,
  CustomDialog,
}: IContractProps) => {
  const classes = useStyles({});

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const testId = `contract-${contract.id}`;

  return (
    <div data-testid={testId}>
      {contract.customHeader && (
        <Typography data-testid={`${testId}-header`}>
          {contract.customHeader}
        </Typography>
      )}
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4}>
          <TextField
            label="Contract Start Date"
            id={`${testId}-startDate`}
            name="contractStartDate"
            defaultValue={getFormattedDateBasedOnTerritory(
              contract.startDate,
              activeTerritory
            )}
            fullWidth
            disabled={!hasEditPermissions}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Contract End Date (optional)"
            id={`${testId}-endDate`}
            name="contractEndDate"
            defaultValue={
              contract.endDate
                ? getFormattedDateBasedOnTerritory(
                    contract.endDate,
                    activeTerritory
                  )
                : ''
            }
            fullWidth
            disabled={!hasEditPermissions}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={4} className={classes.ctaContainer}>
          <ContractModal
            customDialogProps={{ ...customDialogProps, contract }}
            CustomDialog={CustomDialog}
            readOnly={readOnly}
            hasEditPermissions={hasEditPermissions}
            testId={testId}
          />
          <ContractActivityModal
            contractId={contract.id}
            contractType={contract.__typename}
            testId={testId}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Contract;
