import { targetingParameterTypesEnum } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';

export const removeTargetingType = (
  setSelectedTypes: any,
  selectedTypes: targetingParameterTypesEnum[],
  type?: targetingParameterTypesEnum
) => {
  if (type) {
    const newSelectedTypes = [...selectedTypes];
    newSelectedTypes.splice(newSelectedTypes.indexOf(type), 1);
    setSelectedTypes(newSelectedTypes);
  }
};

export const toggleTargetingType = (
  setSelectedTypes: any,
  selectedTypes: targetingParameterTypesEnum[],
  type: targetingParameterTypesEnum,
  newType: targetingParameterTypesEnum
) => {
  if (type) {
    const newSelectedTypes = [...selectedTypes];
    newSelectedTypes.splice(newSelectedTypes.indexOf(type), 1);
    setSelectedTypes([...newSelectedTypes, newType]);
  } else {
    setSelectedTypes([...selectedTypes, newType]);
  }
};

export default {
  removeTargetingType,
  toggleTargetingType,
};
