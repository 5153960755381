import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import cache from 'cache';
import crypto from 'crypto';
import history from 'routes/browserHistory';
import TraceParent from 'traceparent';
import { handleGraphqlErrors } from 'utils/errors';

const apiUrl =
  // @ts-ignore
  window.__ENV__.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

interface HttpHeaders {
  [key: string]: string;
}

export const getHttpLinkOptions = (
  validateJwtToken: any,
  customHeaders: HttpHeaders
) => ({
  uri: apiUrl,
  fetch: async (uri: RequestInfo, options: RequestInit = {}) => {
    const { headers = {} } = options;
    const token = await validateJwtToken();

    const version = Buffer.alloc(1).toString('hex');
    const traceId = crypto.randomBytes(16).toString('hex');
    const id = crypto.randomBytes(8).toString('hex');
    const flags = '01';

    const header = `${version}-${traceId}-${id}-${flags}`;
    const parent = TraceParent.fromString(header);

    return window.fetch(uri, {
      ...options,
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        traceparent: parent,
        ...customHeaders,
      },
    });
  },
});

const getApolloLink = (linkOptions: HttpLink.Options) =>
  ApolloLink.split(
    (operation) => operation.getContext().batch === true,
    new BatchHttpLink({ ...linkOptions, batchMax: 20 }),
    new HttpLink(linkOptions)
  );

const setupClient = (linkOptions: HttpLink.Options) =>
  new ApolloClient({
    queryDeduplication: true,
    link: ApolloLink.from([
      onError((error) => handleGraphqlErrors(error, history)),
      getApolloLink(linkOptions),
    ]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

export const initializeApolloClient = (linkOptions: HttpLink.Options) =>
  setupClient(linkOptions);
