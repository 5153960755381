import { ErrorMessage, FieldArray, getIn, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const podcastValues = {
  podcastParams: {
    podcastTargets: [
      {
        collectionId: '',
        showId: '',
        episodeId: '',
        name: '',
        readOnly: false,
      },
    ],
  },
};

const TargetingPodcast = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index: locationIndex,
    touched,
    errors,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <FieldArray
      name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets`}
    >
      {({ push, remove }) => (
        <>
          {targetingValues.audienceParams[
            locationIndex
          ].podcastParams.podcastTargets.map((_: any, index: number) => (
            /* eslint-disable react/no-array-index-key */
            <div className={classes.wrapper} key={index}>
              <Field
                component={TextField}
                data-tc="targetingPodcastCollectionId"
                label="Collection ID"
                placeholder="Collection ID"
                name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.collectionId`}
                fullWidth
                classes={{ root: classes.value }}
                disabled={
                  targetingValues.audienceParams[locationIndex].podcastParams
                    .podcastTargets[index].readOnly
                }
              />
              <Field
                component={TextField}
                data-tc="targetingPodcastShowId"
                label="Show ID"
                placeholder="Show ID"
                name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.showId`}
                fullWidth
                classes={{ root: classes.value }}
                disabled={
                  targetingValues.audienceParams[locationIndex].podcastParams
                    .podcastTargets[index].readOnly
                }
              />
              <Field
                component={TextField}
                data-tc="targetingPodcastEpisodeId"
                label="Episode ID"
                placeholder="Episode ID"
                name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.episodeId`}
                fullWidth
                classes={{ root: classes.value }}
                disabled={
                  targetingValues.audienceParams[locationIndex].podcastParams
                    .podcastTargets[index].readOnly
                }
              />
              <Field
                component={TextField}
                data-tc="targetingPodcastName"
                label="Name"
                placeholder="Name"
                name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.name`}
                fullWidth
                classes={{ root: classes.value }}
                disabled={
                  targetingValues.audienceParams[locationIndex].podcastParams
                    .podcastTargets[index].readOnly
                }
              />
              <div className={classes.buttons}>
                <IconButton
                  data-tc="targetingPodcastRemoveButton"
                  disabled={
                    targetingValues.audienceParams[locationIndex].podcastParams
                      .podcastTargets.length === 1 ||
                    targetingValues.audienceParams[locationIndex].podcastParams
                      .podcastTargets[index].readOnly
                  }
                  className={classes.button}
                  onClick={() => remove(index)}
                >
                  <ClearIcon />
                  <span className="sr-only">Remove podcast</span>
                </IconButton>
                <IconButton
                  data-tc="targetingCustomTypeAddButton"
                  onClick={() => {
                    push(podcastValues.podcastParams.podcastTargets[0]);
                  }}
                  className={classes.button}
                >
                  <AddIcon />
                  <span className="sr-only">Add another podcast</span>
                </IconButton>
              </div>
              {((getIn(
                touched,
                `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.collectionId`
              ) &&
                (getIn(
                  errors,
                  `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.collectionId`
                ) ||
                  getIn(
                    errors,
                    `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}`
                  ))) ||
                (getIn(
                  touched,
                  `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.showId`
                ) &&
                  (getIn(
                    errors,
                    `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.showId`
                  ) ||
                    getIn(
                      errors,
                      `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}`
                    ))) ||
                (getIn(
                  touched,
                  `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.episodeId`
                ) &&
                  (getIn(
                    errors,
                    `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.episodeId`
                  ) ||
                    getIn(
                      errors,
                      `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}`
                    )))) && (
                <div className={classes.errors}>
                  <FormHelperText error data-tc="targetingPodcastError">
                    {typeof getIn(
                      errors,
                      `${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}`
                    ) === 'string' && (
                      <span
                        className={classes.errorMessage}
                        data-tc="targetingPodcastErrorGeneric"
                      >
                        <ErrorMessage
                          name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}`}
                        />
                      </span>
                    )}
                    <span className={classes.errorMessage}>
                      <ErrorMessage
                        name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.collectionId`}
                      />
                    </span>
                    <span className={classes.errorMessage}>
                      <ErrorMessage
                        name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.showId`}
                      />
                    </span>
                    <span className={classes.errorMessage}>
                      <ErrorMessage
                        name={`${targetingPath}.audienceParams.${locationIndex}.podcastParams.podcastTargets.${index}.episodeId`}
                      />
                    </span>
                  </FormHelperText>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </FieldArray>
  );
};

export default TargetingPodcast;
