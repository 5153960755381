import { Form } from 'formik';
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import TerritoryCheckbox from 'components/TerritoryCheckbox/TerritoryCheckbox';
import { allTerritories, TerritoryLabelShort } from 'utils/territory';
import { IFormProps } from 'interfaces';
import { Territory } from 'interfaces/generated.types';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import useStyles from './PrimaryControlForm.styles';

export interface IPrimaryControlFormProps {
  handleClose: () => void;
  content: {
    title: string;
    message: string;
    note: string;
    cancel: string;
    continue: string;
  };
}

export interface IPrimaryControlValues {
  territories: Territory[];
}

const PrimaryControlForm = ({
  setFieldValue,
  values,
  isSubmitting,
  dirty,
  content,
  handleClose,
  handleSubmit,
}: IFormProps<IPrimaryControlValues> & IPrimaryControlFormProps) => {
  const classes = useStyles({});

  return (
    <Form role="form">
      <FormGroup>
        <div className={classes.territoryCheckboxes}>
          {allTerritories.map((territory: Territory) => (
            <div className={classes.checkboxContainer} key={territory}>
              <TerritoryCheckbox
                territory={territory}
                checkboxLabel={TerritoryLabelShort[territory]}
                values={values}
                onChange={({ target: { value, checked } }) =>
                  setFieldValue(
                    'territories',
                    allTerritories.filter(
                      (t) =>
                        (checked && t === value) ||
                        (t !== value && values.territories.includes(t))
                    )
                  )
                }
                classes={classes}
              />
            </div>
          ))}
        </div>
        <p className={classes.note}>{content.note}</p>
        <div className={classes.footer}>
          <StyledButton
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="closeModal"
            onClick={handleClose}
          >
            {content.cancel}
          </StyledButton>
          <StyledButton
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            type="submit"
            testId="primaryControlSubmit"
            data-tc="submitModal"
            isLoading={isSubmitting}
            disabled={!dirty || isSubmitting}
            onClick={handleSubmit}
          >
            {content.continue}
          </StyledButton>
        </div>
      </FormGroup>
    </Form>
  );
};

export default PrimaryControlForm;
