import gql from 'graphql-tag';

export interface IValidatePostcodesResponse {
  validatePostcodes: {
    valid: string[];
    invalid: string[];
  };
}

export const SEARCH_POSTCODES = gql`
  query SearchPostcodes($postcode: String!) {
    searchPostcodes(text: $postcode)
  }
`;

export const VALIDATE_POSTCODES = gql`
  query ValidatePostcodes($postcodes: [String!]!) {
    validatePostcodes(postcodes: $postcodes) {
      valid
      invalid
    }
  }
`;

export default SEARCH_POSTCODES;
