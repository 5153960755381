import React from 'react';
import { getInsightsMetricSelectOptions } from 'utils/insights';

import { MenuItem, TextField } from '@material-ui/core';

import useStyles from './MetricsDropdown.styles';

interface MetricDropdownProps {
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  initialMetric: string;
}

const MetricsDrowpdown = ({
  handleChange,
  initialMetric,
}: MetricDropdownProps) => {
  const classes = useStyles();

  return (
    <TextField
      inputProps={{ 'data-testid': `metrics-dropdown-${initialMetric}` }}
      select
      fullWidth
      value={initialMetric}
      onChange={handleChange}
      SelectProps={{
        className: classes.dropdownField,
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          MenuListProps: {
            className: classes.menuList,
          },
        },
      }}
    >
      {getInsightsMetricSelectOptions().map((metricOption) => (
        <MenuItem
          key={metricOption.value}
          value={metricOption.value}
          className={classes.menuItem}
        >
          {metricOption.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MetricsDrowpdown;
