import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.error.main}`,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(0.5),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
