import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import pickBy from 'lodash/pickBy';
import React from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';

import TagForm, { ITagValues } from 'features/tag/components/TagForm/TagForm';
import TagFormValidation from 'features/tag/validations/tags';

import useError from 'hooks/Error/useError';

import {
  CreateTagMutationFn,
  useCreateTagMutation,
} from 'interfaces/generated.types';

import { handleFormErrors } from 'utils/forms';

interface ICreateTagContainerProps {
  history: History;
}

export const handleOnComplete = (history: History) => {
  history.push('/reporting-tags');
};

export const submitCreateTag = (
  createTag: CreateTagMutationFn,
  toggleErrorModal: () => void
) => (
  formValues: ITagValues,
  { setSubmitting, setStatus }: FormikActions<ITagValues>
) => {
  const filteredValues = pickBy(formValues);

  createTag({
    variables: filteredValues,
  }).catch((error) => {
    if (error.message.includes('Resource already exists')) {
      setSubmitting(false);
      setStatus({
        name: 'This tag name already exists. Please select a different name.',
      });
    } else {
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      });
    }
  });
};

const CreateTagContainer = ({ history }: ICreateTagContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Tag.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const [createTag] = useCreateTagMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <>
      <Formik<ITagValues>
        initialValues={{
          name: '',
          description: '',
        }}
        validationSchema={TagFormValidation}
        onSubmit={submitCreateTag(createTag, toggleErrorModal)}
        component={TagForm}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createTag"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateTagContainer;
