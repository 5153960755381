import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  MenuItem as MUIMenuItem,
  MenuItemProps as MUIMenuItemProps,
} from '@material-ui/core';

import useStyles from './MenuItem.styles';

export type MenuItemProps = MUIMenuItemProps<'li', { button?: true }> & {
  disableRipple?: boolean;
  ListItemClasses?: any;
};

const MenuItem = React.forwardRef(
  (props: MenuItemProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUIMenuItem ref={ref} classes={classes} {...otherProps} />;
  }
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
