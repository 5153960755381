import SplitButton, { ISplitButton } from 'components/SplitButton/SplitButton';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import { ILocation } from 'interfaces';
import React from 'react';

import useStyles from './FormButtons.styles';

export enum FormButtonVariants {
  single = 'single',
  split = 'split',
}

interface FormButtonsProps {
  children?: React.ReactNode;
  onClick(value: any): void;
  disabled?: boolean;
  dataTc: string;
  isLoading?: boolean;
  variant?: FormButtonVariants.single | FormButtonVariants.split;
  buttons?: ISplitButton[];
  goBackTo?: string | ILocation;
  onCancel?(value: any): void;
  handleCloseModal?: () => void;
}

const FormButtons = ({
  children,
  onClick,
  disabled,
  dataTc,
  buttons,
  goBackTo,
  onCancel,
  variant = FormButtonVariants.single,
  isLoading = false,
  handleCloseModal,
}: FormButtonsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      {onCancel || handleCloseModal ? (
        <StyledButton
          color={ButtonColorEnum.Primary}
          type="button"
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}-cancel`}
          disabled={onCancel ? disabled : false}
          onClick={onCancel || handleCloseModal}
          isLoading={isLoading}
          testId="formStyledCancelButton"
        >
          {onCancel ? 'Cancel' : 'Back'}
        </StyledButton>
      ) : (
        <StyledLink
          location={goBackTo as any}
          color={LinkColorEnum.Secondary}
          data-tc="cancelButton"
        >
          Back
        </StyledLink>
      )}
      {buttons && variant === FormButtonVariants.split ? (
        <SplitButton
          toggleDataTc="splitButtonToggleFormButtons"
          buttons={buttons}
          isLoading={isLoading}
        />
      ) : (
        <StyledButton
          color={ButtonColorEnum.Primary}
          type="submit"
          variant={ButtonVariantEnum.Outlined}
          data-tc={dataTc}
          disabled={disabled}
          onClick={onClick}
          isLoading={isLoading}
          testId="formStyledButton"
        >
          {children}
        </StyledButton>
      )}
    </div>
  );
};

export default FormButtons;
