import { baseCheckboxStyles } from 'features/targetingV2/styles/checkbox.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseCheckboxStyles(theme),
  checkbox: {
    padding: theme.spacing(0, 0.2, 0, 0),
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
  container: {
    display: 'flex',
    marginLeft: theme.spacing(1.35),
    alignItems: 'center',
  },
  loaderContainer: {
    justifyContent: 'flex-start',
  },
  loaderProgress: {
    margin: theme.spacing(0, 0.5),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0.6),
  },
}));

export default useStyles;
