import pageStyles from 'assets/styles/components/Page.styles';
import DatePicker from 'components/DateTimePicker/DatePicker';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { isAfter } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { HealthMetric } from 'interfaces/generated.types';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import dateUtils from 'utils/date';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { THealthMetric } from 'utils/healthMetrics';
import { IDateRange } from 'interfaces';
import useStyles from './HealthDashboard.styles';

export interface IEntityHealthProps {
  id: string;
  selectedDateRange: IDateRange;
  useHealthQuery: any;
  entityType: string;
}

const StackedBarChart = lazy(
  () => import('components/StackedBarChart/StackedBarChart')
);

const HealthDashboard = (props: IEntityHealthProps) => {
  const componentClasses = useStyles();
  const pageClasses = pageStyles();

  const { id, selectedDateRange, useHealthQuery, entityType } = props;

  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: selectedDateRange.startDate,
    endDate: selectedDateRange.endDate,
  });

  const [healthData, setHealthData] = useState<HealthMetric[]>([]);
  const [dateError, setDateError] = useState<string | null>(null);

  const { loading, data, error, refetch } = useHealthQuery({
    variables: {
      id,
      from: formatISO(selectedDateRange.startDate, { representation: 'date' }),
      until: formatISO(selectedDateRange.endDate, { representation: 'date' }),
    },
  });

  useEffect(() => {
    if (data?.[entityType]?.health) setHealthData(data[entityType].health);
  }, [data, entityType]);

  useEffect(() => {
    if (isAfter(dateRange.startDate, dateRange.endDate)) {
      setDateError('Start date should be before End date');
    } else {
      setDateError(null);
    }
  }, [dateRange]);

  if (error || !healthData)
    return (
      <p className={pageClasses.errorMessage}>
        Health data is not available at the moment. Please try again later
      </p>
    );

  if (loading) return <Loader />;

  return (
    <>
      <Grid container spacing={2} className={componentClasses.grid}>
        <Grid item xs={5}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={componentClasses.formControl}
          >
            <DatePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={dateRange.startDate}
              label="Start Date"
              name="startDate"
              onChange={(value) =>
                setDateRange({ ...dateRange, startDate: value as Date })
              }
              onClose={() => {}}
              data-tc={`${entityType}HealthStartDate`}
            />
            {!!dateError && (
              <FormHelperText data-tc={`${entityType}HealthStartDate`} error>
                {dateError}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={componentClasses.formControl}
          >
            <DatePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={dateRange.endDate}
              label="End Date"
              name="endDate"
              onChange={(value) =>
                setDateRange({ ...dateRange, endDate: value as Date })
              }
              onClose={() => {}}
              data-tc={`${entityType}HealthEndDate`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} className={componentClasses.buttonWrapper}>
          <StyledButton
            data-tc="dateRangeButton"
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            isLoading={loading}
            disabled={loading || !!dateError}
            onClick={() =>
              refetch({
                id,
                from: formatISO(dateRange.startDate, {
                  representation: 'date',
                }),
                until: formatISO(dateRange.endDate, { representation: 'date' }),
              })
            }
          >
            Apply date range
          </StyledButton>
        </Grid>
      </Grid>
      <Suspense fallback={<Loader />}>
        <StackedBarChart
          data={healthData as THealthMetric[]}
          testId={`${entityType}HealthChart`}
        />
      </Suspense>
      <Typography
        variant="caption"
        align="center"
        className={componentClasses.italic}
      >
        Data is sampled at a rate of 1/100
      </Typography>
    </>
  );
};

export default HealthDashboard;
