import { makeStyles, Theme } from '@material-ui/core/styles';

export const baseButtonStyles = (theme: Theme) => ({
  button: {
    minWidth: theme.spacing(15),
    fontSize: theme.spacing(2),
    borderRadius: theme.spacing(0.625),
    maxHeight: '32px',
    fontweight: '400',
  },
  // primary CTA button
  containedPrimary: {
    composes: '$button',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[400],
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
  },

  // icon part of a split primary CTA button
  containedSecondary: {
    composes: '$containedPrimary',
    minWidth: theme.spacing(5),
    width: 'auto',
  },

  // secondary CTA button
  outlinedPrimary: {
    composes: '$button',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.primary.main}`,
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover, &:focus': {
      borderWidth: '1px',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.light,
      borderWidth: '1px',
    },
  },

  // tertiary CTA button
  outlinedSecondary: {
    composes: '$button',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[300],
    },
  },

  // used for delete/add icons or button based areas
  text: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.common.white,
    padding: theme.spacing(0.625),
    minWidth: 'auto',
    lineHeight: 'unset',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
    },
  },

  splitButtonToggle: {
    minWidth: 'unset',
    composes: '$outlinedPrimary',
  },
});

const buttonStyles = makeStyles(
  (theme: Theme) => ({
    ...baseButtonStyles(theme),
  }),
  { index: 1 }
);

export default buttonStyles;
