import inRange from 'lodash/inRange';
import React, { useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useDateTimePickerInputStyles } from './DateTimePicker.styles';

interface DateTimePickerInputProps {
  min: number;
  max: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (val: number) => void;
  value: any;
  name: string;
}

const DateTimePickerInput = ({
  min,
  max,
  onIncrement,
  onDecrement,
  onChange,
  value,
  name,
}: DateTimePickerInputProps) => {
  const classes = useDateTimePickerInputStyles();
  const inputEl = useRef<any>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = Number(event.target.value);
    if (inRange(input, min, max + 1)) {
      onChange(input);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
      inputEl.current.select();
    }
  };

  return (
    <div className={classes.container}>
      <IconButton
        data-tc={`${name}IncrementButton`}
        data-testid={`${name}IncrementButton`}
        onClick={onIncrement}
        tabIndex={-1}
      >
        <KeyboardArrowUpIcon />
        <span className="sr-only">Next</span>
      </IconButton>
      <input
        className={classes.input}
        type="number"
        name={name}
        min={min}
        max={max}
        ref={inputEl}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      <IconButton
        onClick={onDecrement}
        data-tc={`${name}DecrementButton`}
        tabIndex={-1}
      >
        <KeyboardArrowDownIcon />
        <span className="sr-only">Previous</span>
      </IconButton>
    </div>
  );
};

export default DateTimePickerInput;
