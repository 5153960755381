import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CheckIcon } from 'assets/icons/dax-checkicon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/dax-crossicon.svg';
import { ReactComponent as CreateIcon } from 'assets/icons/dax-editicon.svg';

import { IValidateCellData } from './Table';
import { useEditableCellStyles } from './Table.styles';

interface ITableValidateCell {
  row: any;
  value: any;
  render: () => string | React.ReactElement<any>;
  editComponent: (cellValue: any, onChange: any) => React.ReactElement<any>;
  onCellUpdate: (
    row: any,
    setErrorModal: any,
    setUpdating: any
  ) => Promise<void>;
  onCellValidate: ({
    entity,
    setErrorModal,
    setWarningModal,
    handleContinue,
  }: IValidateCellData) => Promise<void>;
  setErrorModal: any;
  setWarningModal: any;
  setUpdating: any;
  isEditable: boolean;
}

const TableValidateCell = ({
  row,
  value: initialValue,
  render,
  editComponent,
  onCellUpdate,
  onCellValidate,
  setErrorModal,
  setWarningModal,
  setUpdating,
  isEditable,
}: ITableValidateCell) => {
  const classes = useEditableCellStyles();
  const [isEditing, setEditing] = useState(false);
  const [cellValue, setCellValue] = useState(initialValue);

  const handleEdit = () => {
    setEditing(true);
  };

  const onChange = (value: any) => {
    setCellValue(value);
  };

  const handleDismiss = () => {
    setCellValue(initialValue);
    setEditing(false);
  };

  const handleApprove = async () => {
    const updateRow = { ...row.values, status: cellValue };
    setEditing(false);
    await onCellValidate({
      entity: {
        id: row.values.id,
        targetStatus: cellValue,
      },
      setErrorModal,
      setWarningModal,
      setUpdating,
      handleContinue: () => onCellUpdate(updateRow, setErrorModal, setUpdating),
    });
  };

  return !isEditing ? (
    <div className={classes.root}>
      {render()}
      {isEditable ? (
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit} data-testid="tableCellEditButton">
            <CreateIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  ) : (
    <div className={classes.root}>
      {editComponent(cellValue, onChange)}
      <Tooltip title="Save">
        <IconButton
          onClick={handleApprove}
          data-testid="tableCellApproveButton"
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancel">
        <IconButton
          onClick={handleDismiss}
          data-testid="tableCellDismissButton"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TableValidateCell;
