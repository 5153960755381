import Checkbox from 'features/targetingV2/components/core/Checkbox';
import FormControlLabel from 'features/targetingV2/components/core/FormControlLabel';
import Loader from 'features/targetingV2/components/core/Loader';
import Typography from 'features/targetingV2/components/core/Typography';
import { ILimitLocationParameterValue } from 'features/targetingV2/types/targeting';
import React from 'react';

import useStyles from './LimitLocationCheckbox.styles';

export interface ILimitLocationCheckboxProps {
  countryLabel: string;
  fieldName: string;
  limitLocationParameterValueFromTerritory:
    | ILimitLocationParameterValue
    | undefined;
  loading: boolean;
  isLocationLimited: boolean;
  setIsLocationLimited: (value: boolean) => void;
  setFieldValue: (field: string, value: any) => void;
}

const LimitLocationCheckbox = (props: ILimitLocationCheckboxProps) => {
  const {
    countryLabel,
    fieldName,
    limitLocationParameterValueFromTerritory,
    loading,
    isLocationLimited,
    setIsLocationLimited,
    setFieldValue,
  } = props;
  const classes = useStyles();

  return (
    <>
      {loading || !limitLocationParameterValueFromTerritory ? (
        <Loader
          classes={{
            container: classes.loaderContainer,
            progress: classes.loaderProgress,
          }}
          size={20}
        />
      ) : (
        <div className={classes.container}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isLocationLimited}
                color="primary"
                classes={{
                  root: classes.checkbox,
                  colorPrimary: classes.checkboxPrimaryColor,
                }}
                onChange={(_, checked) => {
                  setIsLocationLimited(checked);
                  const newFieldValue = checked
                    ? {
                        id: limitLocationParameterValueFromTerritory!.id,
                      }
                    : null;

                  setFieldValue(fieldName, newFieldValue);
                }}
                inputProps={{
                  'aria-label': `limit-to-${countryLabel}-checkbox`,
                }}
              />
            }
            classes={{
              root: classes.checkboxLabel,
            }}
            label={<Typography variant="body2">{countryLabel} only</Typography>}
          />
        </div>
      )}
    </>
  );
};

export default LimitLocationCheckbox;
