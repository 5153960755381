import useUrlSearchParams from 'hooks/UrlParams/useUrlSearchParams';
import useUrlSearchParamUtils from 'hooks/UrlParams/useUrlSearchParamUtils';
import { useLocation } from 'react-router-dom';
import { APP_ENVIRONMENT } from 'utils/environmentVariables';

const useLoginRedirect = () => {
  const originalLocation = window.location.origin;

  const { search } = useLocation();
  const urlSearchParams = useUrlSearchParams(search);
  const { getUrlParam } = useUrlSearchParamUtils(urlSearchParams);
  const redirectParam = getUrlParam('redirect');

  const redirect = () => {
    const baseUrl =
      APP_ENVIRONMENT === 'local'
        ? process.env.REACT_APP_BASE_URI
        : originalLocation;

    window.location.replace(`${baseUrl}${redirectParam}`);
  };

  if (redirectParam) {
    return redirect;
  }

  return undefined;
};

export default useLoginRedirect;
