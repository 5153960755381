import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import classNames from 'classnames';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import { Field, getIn } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import useStyles from './CustomKvpTextFieldsRow.styles';

export interface ICustomKvpTextFieldsRowProps {
  errors: any;
  touched: any;
  removeRow: () => void;
  rowFieldName: string;
  rowId: string;
}

const CustomKvpTextFieldsRow = (props: ICustomKvpTextFieldsRowProps) => {
  const { errors, touched, removeRow, rowFieldName, rowId } = props;
  const classes = useStyles();

  const keyFieldName = `${rowFieldName}.key`;
  const valueFieldName = `${rowFieldName}.value`;

  const keyError = getIn(errors, keyFieldName);
  const valueError = getIn(errors, valueFieldName);

  const showKeyError = getIn(touched, keyFieldName) && !!keyError;
  const showValueError = getIn(touched, valueFieldName) && !!valueError;

  const contextMenuOptions = [
    {
      MenuItemIcon: <RemoveIcon />,
      menuItemIconLabel: 'Remove',
      menuItemAction: () => removeRow(),
    },
  ];

  return (
    <div className={classes.container} data-testid={rowFieldName}>
      <Field
        component={TextField}
        InputProps={{
          classes: {
            input: classNames(classes.input, {
              [`${classes.input}--error`]: showKeyError,
            }),
            root: classes.inputRoot,
          },
        }}
        classes={{ root: classes.keyInputContainer }}
        name={keyFieldName}
        helperText={showKeyError && keyError}
        FormHelperTextProps={{ classes: { root: classes.error } }}
        error={showKeyError}
        placeholder="Key"
      />
      <Field
        component={TextField}
        InputProps={{
          classes: {
            input: classNames(classes.input, `${classes.input}--valueInput`, {
              [`${classes.input}--error`]: showValueError,
            }),
            root: classes.inputRoot,
          },
        }}
        classes={{ root: classes.valueInputContainer }}
        name={valueFieldName}
        helperText={showValueError && valueError}
        FormHelperTextProps={{ classes: { root: classes.error } }}
        error={showValueError}
        placeholder="Value"
      />
      <IconButtonMenu
        menuIconButtonLabel={rowId}
        menuOptions={contextMenuOptions}
      />
    </div>
  );
};

export default CustomKvpTextFieldsRow;
