import React from 'react';

import PostcodeSearch from 'components/Postcode/PostcodeSearch';
import { IFormProps } from 'interfaces';

import useStyles from './Postcode.styles';
import PostcodeBulkUpload from './PostcodeBulkUpload';

export interface IPostcodeProps {
  name: string;
  label: string;
  placeholder: string;
  dataTc: string;
}

const Postcode = (props: IFormProps<any> & IPostcodeProps) => {
  const { dataTc } = props;
  const classes = useStyles();

  return (
    <div className={classes.postcodeWrapper}>
      <PostcodeBulkUpload {...props} dataTc={dataTc} />
      <PostcodeSearch {...props} dataTc={`${dataTc}Search`} />
    </div>
  );
};

export default Postcode;
