import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import loginStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React from 'react';

import { Typography } from '@material-ui/core';

import useStyles from '../ForgottenPassword/ForgettenPassword.styles';

const ForgottenPasswordResend = () => {
  const classes = useStyles();
  const loginClasses = loginStyles();
  const { onResetPasswordSendEmail } = useAuthContext();

  const {
    state: { params },
  } = usePreviousLocation();

  return (
    <PageContainer>
      <div className={loginClasses.container}>
        <div className={loginClasses.content}>
          <Typography variant="h6" className={classes.title}>
            Forgotten Password
          </Typography>
          <Typography
            variant="body2"
            align="center"
            className={classes.paragraph}
            data-testid="forgotten-password-resend--description"
          >
            Click the link in the email we&apos;ve just sent to reset your
            password. It may take a few minutes to arrive.
            <br />
            <br /> Can&apos;t find it? Check your spam folder.
          </Typography>
          <div className={classes.ctaContainer}>
            <StyledButton
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Contained}
              className={classNames(`${classes.cta}--resend`)}
              onClick={() => onResetPasswordSendEmail(params)}
            >
              Resend the Email
            </StyledButton>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default ForgottenPasswordResend;
