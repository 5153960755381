import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  modalButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    color: theme.palette.primary.main,
    '&:focus': {
      outline: 'none',
    },
  },
  paper: {
    width: '1312px',
  },
}));

export default useStyles;
