import { ReactComponent as CanadaIcon } from 'assets/icons/dax-flag-canada.svg';
import { ReactComponent as UKIcon } from 'assets/icons/dax-flag-uk.svg';
import { ReactComponent as USIcon } from 'assets/icons/dax-flag-us.svg';
import { Territory } from 'interfaces/generated.types';
import * as React from 'react';

export const allTerritories = Object.values(Territory);

export const mockTerritory = Territory.Uk;

export enum TerritoryLabel {
  UK = 'United Kingdom',
  US = 'United States of America',
  CA = 'Canada',
}

export enum TerritoryLabelShort {
  UK = 'UK',
  US = 'USA',
  CA = 'CA',
}

export enum TerritoryToCountryValueMap {
  UK = 'GB',
  US = 'US',
  CA = 'CA',
}

export const getTerritoryIcon = (territory?: Territory, className?: string) => {
  const props = {
    'data-testid': `${territory}Icon`,
    className,
  };

  switch (territory) {
    case Territory.Ca:
      return <CanadaIcon {...props} />;
    case Territory.Uk:
      return <UKIcon {...props} />;
    case Territory.Us:
      return <USIcon {...props} />;
    default:
      return <></>;
  }
};

export const territoryValues = [
  {
    value: Territory.Uk,
    label: TerritoryLabel[Territory.Uk],
  },
  {
    value: Territory.Us,
    label: TerritoryLabel[Territory.Us],
  },
  {
    value: Territory.Ca,
    label: TerritoryLabel[Territory.Ca],
  },
];

export const getTerritoryValues = (territories: Territory[]) =>
  territoryValues.filter((value) => territories.includes(value.value));

export const isActiveTerritoryAssignedToEntity = (
  entityTerritories: Territory[],
  activeTerritory: Territory
) => entityTerritories?.includes(activeTerritory!) || false;

export const getEnabledTerritories = (
  list: Territory[],
  disabled: Territory[]
) => list.filter((item) => !disabled.includes(item));

export default {
  allTerritories,
  TerritoryLabel,
  TerritoryLabelShort,
  getTerritoryIcon,
  getTerritoryValues,
  isActiveTerritoryAssignedToEntity,
  getEnabledTerritories,
};
