import CookieConsentModal from 'components/CookieConsent/CookieConsentModal/CookieConsentModal';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { useUserContext } from 'context/UserProvider/UserProvider';
import React, { useState } from 'react';
import { isEmailGlobalDomain } from 'utils/user';

import { Typography } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';

import useStyles from './Footer.styles';

const Footer = () => {
  const classes = useStyles();

  const {
    state: { assumedId },
  } = useCustomApolloContext();

  const [isCookieConsentModalOpen, setIsCookieConsentModalOpen] =
    useState(false);

  const currentYear = new Date().getFullYear();

  const showPrivacyManagerButton =
    !isEmailGlobalDomain(useUserContext().email) && !assumedId;

  return (
    <>
      <footer className={classes.footer} title="dax-footer">
        <div className={classes.footerLinks}>
          <div className={classes.copyrightContainer}>
            <CopyrightIcon
              classes={{ root: classes.copyrightIcon }}
              data-testid="material-ui-copyright-icon"
            />
            <Typography variant="caption">Global {currentYear}</Typography>
          </div>
          <a
            href="https://global.com/legal/privacy-policy/"
            className={classes.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          {showPrivacyManagerButton && (
            <button
              className={classes.privacyManager}
              onClick={() => setIsCookieConsentModalOpen(true)}
              type="button"
            >
              Privacy Manager
            </button>
          )}
        </div>
        {window.__ENV__.DAX_APP_VERSION && (
          <Typography variant="caption" data-testid="app-version">
            App Version: {window.__ENV__.DAX_APP_VERSION}
          </Typography>
        )}
      </footer>
      <CookieConsentModal
        isModalOpen={isCookieConsentModalOpen}
        setIsModalOpen={setIsCookieConsentModalOpen}
        testId="manageCookieConsent"
        showCheckboxesByDefault
      />
    </>
  );
};

export default Footer;
