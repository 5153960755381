import classNames from 'classnames';
import format from 'date-fns/format';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useDateTimePickerCalendarStyles } from './DateTimePicker.styles';

interface DateTimePickerCalendarProps {
  cursor: Date;
  selected: Date;
  onIncrement: () => void;
  onDecrement: () => void;
  onClick: (value: Date) => void;
  forEachWeek: any;
  forEachDay: any;
  isSameDay: (date1: Date, date2: Date) => boolean;
  isToday: (date: Date) => boolean;
  isRangeCalendar?: boolean;
  isDateInRange?: (date: Date) => boolean;
}
const DateTimePickerCalendar = ({
  cursor,
  selected,
  onIncrement,
  onDecrement,
  onClick,
  forEachWeek,
  forEachDay,
  isSameDay,
  isToday,
  isRangeCalendar = false,
  isDateInRange,
}: DateTimePickerCalendarProps) => {
  const classes = useDateTimePickerCalendarStyles();
  return (
    <>
      <div className={classes.nav}>
        <IconButton data-testid="previousMonthButton" onClick={onDecrement}>
          <KeyboardArrowLeftIcon />
          <span className="sr-only">Previous month</span>
        </IconButton>
        <span className={classes.month}>{format(cursor, 'MMMM yyyy')}</span>
        <IconButton data-testid="nextMonthButton" onClick={onIncrement}>
          <KeyboardArrowRightIcon />
          <span className="sr-only">Next month</span>
        </IconButton>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.head}>Sun</th>
            <th className={classes.head}>Mon</th>
            <th className={classes.head}>Tue</th>
            <th className={classes.head}>Wed</th>
            <th className={classes.head}>Thu</th>
            <th className={classes.head}>Fri</th>
            <th className={classes.head}>Sat</th>
          </tr>
        </thead>
        <tbody>
          {forEachWeek(({ key, days }: { key: string; days: Date[] }) => (
            <tr
              key={key}
              style={{ textAlign: 'right' }}
              className={classes.row}
            >
              {forEachDay(
                ({
                  value,
                  day,
                  key: keyDay,
                }: {
                  value: number;
                  day: Date;
                  key: string;
                }) => (
                  <td key={keyDay} className={classes.cell}>
                    {value ? (
                      <button
                        type="button"
                        data-testid="dateTimePickerDay"
                        className={classNames([
                          classes.button,
                          {
                            [`${classes.button}--selected`]:
                              !isRangeCalendar && isSameDay(day, selected),
                            [`${classes.button}--today`]: isToday(day),
                            [`${classes.button}--range-selected`]:
                              isRangeCalendar &&
                              isDateInRange &&
                              isDateInRange(day),
                          },
                        ])}
                        onClick={() => onClick(day)}
                      >
                        {value}
                      </button>
                    ) : null}
                  </td>
                ),
                days
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DateTimePickerCalendar;
