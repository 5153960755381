import { baseFormStyles } from 'assets/styles/components/Form.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFormStyles(theme),
  objectiveImpressions: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.875em',
  },
  billingTypeWrapper: {
    top: theme.spacing(-0.625),
  },
  targetingToggleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0, 2, 2),
  },
}));

export default useStyles;
