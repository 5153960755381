import {
  ActionTypes,
  CheckboxTreeActionTypes,
  CheckboxTreeState,
  CheckedStateEnum,
  InitSearchAction,
  SearchNodesAction,
  ToggleExpandedAction,
  ToggleNodeAction,
  UpdateExpandedAction,
  UpdateStateAction,
} from './types';
import {
  buildCheckedIndex,
  buildExpandedIndex,
  buildSelectedNodesMap,
  checkAndUpdate,
  getSelectedNodeValues,
} from './utils';

const checkboxTreeReducer = (
  state: CheckboxTreeState,
  action: CheckboxTreeActionTypes
): CheckboxTreeState => {
  const {
    allNodes,
    checked,
    nodesMap,
    selectedNodesMap,
    areSelectedProvidedAsTrees,
  } = state;
  switch (action.type) {
    case ActionTypes.ToggleNode: {
      const { id } = (action as ToggleNodeAction).payload;
      const newValue =
        checked[id] === CheckedStateEnum.Checked
          ? CheckedStateEnum.Unchecked
          : CheckedStateEnum.Checked;
      const { updates, newSelectedNodesMap } = checkAndUpdate(
        nodesMap,
        checked,
        id,
        newValue,
        selectedNodesMap
      );

      const newCheckedState = {};

      for (const [nodeId, nodeChecked] of Object.entries(checked)) {
        (newCheckedState as any)[nodeId] =
          updates[nodeId] !== undefined ? updates[nodeId] : nodeChecked;
      }

      return {
        ...state,
        nodesMap,
        checked: newCheckedState,
        selectedNodesMap: newSelectedNodesMap,
      };
    }
    case ActionTypes.CheckAll: {
      const allChecked: Record<string, number> = {};
      nodesMap.forEach((_, key) => {
        allChecked[key] = CheckedStateEnum.Checked;
      });
      return { ...state, nodesMap, checked: allChecked };
    }
    case ActionTypes.CheckNone: {
      const noneChecked: Record<string, number> = {};
      nodesMap.forEach((_, key) => {
        noneChecked[key] = CheckedStateEnum.Unchecked;
      });
      return { ...state, nodesMap, checked: noneChecked };
    }
    case ActionTypes.ToggleExpanded: {
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [(action as ToggleExpandedAction).payload.id]:
            !state.expanded[(action as ToggleExpandedAction).payload.id],
        },
      };
    }
    case ActionTypes.ExpandAll: {
      const allExpanded: Record<string, boolean> = {};
      nodesMap.forEach((_, key) => {
        allExpanded[key] = true;
      });
      return { ...state, nodesMap, expanded: allExpanded };
    }
    case ActionTypes.CollapseAll: {
      const allCollapsed: Record<string, boolean> = {};
      nodesMap.forEach((_, key) => {
        allCollapsed[key] = false;
      });
      return { ...state, nodesMap, expanded: allCollapsed };
    }
    case ActionTypes.InitSearch: {
      const { term } = (action as InitSearchAction).payload;
      return {
        ...state,
        filter: term,
        searching: true,
      };
    }
    case ActionTypes.SearchNodes: {
      const { expanded } = (action as SearchNodesAction).payload;
      return { ...state, expanded, searching: false };
    }
    case ActionTypes.UpdateState: {
      const { selected } = (action as UpdateStateAction).payload;
      const selectedNodeValues = areSelectedProvidedAsTrees
        ? getSelectedNodeValues(selected)
        : selected.map((selectedNode) => selectedNode.value);

      const newChecked = buildCheckedIndex(allNodes, selectedNodeValues);

      const newSelectedNodesMap = buildSelectedNodesMap(selected);

      return {
        ...state,
        checked: newChecked,
        selectedNodesMap: newSelectedNodesMap,
      };
    }
    case ActionTypes.UpdateExpanded: {
      const { selected } = (action as UpdateExpandedAction).payload;
      const selectedNodeValues = areSelectedProvidedAsTrees
        ? getSelectedNodeValues(selected)
        : selected.map((selectedNode) => selectedNode.value);

      const newExpanded = buildExpandedIndex(allNodes, selectedNodeValues);

      return {
        ...state,
        expanded: newExpanded,
      };
    }
    default: {
      throw new Error(`Unsupported type: ${(action as any).type}`);
    }
  }
};

export default checkboxTreeReducer;
