import useStyles from 'assets/styles/components/Dialog.styles';
import classNames from 'classnames';
import AudioLibrary from 'components/AudioLibrary/AudioLibrary';
import ImageLibrary from 'components/ImageLibrary/ImageLibrary';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { CreativeStatusEnum } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import {
  ICreativeAudio,
  ICreativeImage,
} from 'features/direct/creative/graphql/queries';
import {
  CreativeAudio,
  CreativeImage,
  CreativeStatus as ICreativeStatus,
  CreativeType,
  useAllAudioCreativesQuery,
  useAllImageCreativesQuery,
} from 'interfaces/generated.types';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { useSessionContext } from '../../context/SessionProvider/SessionProvider';

const CreativeModal = ({
  resourceType,
  advertiserId,
  values,
  setFieldValue,
  closeModal,
}: {
  resourceType: CreativeType;
  closeModal: () => void;
  advertiserId: string;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const {
    loading: loadingImages,
    data: imageCreatives,
  } = useAllImageCreativesQuery({
    variables: {
      advertiserId,
      filter: {
        status_in: [ICreativeStatus.Ready],
      },
      territories: [activeTerritory!],
    },
    skip: !(advertiserId && resourceType === CreativeType.Image),
  });
  const {
    loading: loadingAudio,
    data: audioCreatives,
  } = useAllAudioCreativesQuery({
    variables: {
      advertiserId,
      filter: {
        status_in: [ICreativeStatus.Ready],
      },
      territories: [activeTerritory!],
    },
    skip: !(advertiserId && resourceType === CreativeType.Audio),
  });
  const classes = useStyles();
  const [selectedImageIds, setSelectedImagesIds] = React.useState(
    values.imageCreatives
      .filter((image: ICreativeImage) => image.id)
      .map((image: ICreativeImage) => image.resourceAltId.toString())
  );
  const [selectedAudioId, setSelectedAudioId] = React.useState(
    (values.audioCreatives[0] &&
      values.audioCreatives[0].resourceAltId &&
      values.audioCreatives[0].resourceAltId.toString()) ||
      ''
  );

  const saveAndClose = () => {
    if (resourceType === CreativeType.Audio && audioCreatives) {
      const selectedAudio = (audioCreatives.allCreatives as ICreativeAudio[]).find(
        (audio) => audio.resourceAltId === Number(selectedAudioId)
      );
      if (selectedAudio) {
        setFieldValue('audioCreatives', [
          {
            ...selectedAudio,
            trackingUrls: [{ url: '', consentVendorId: '' }],
            status: CreativeStatusEnum.HasCreative,
          },
        ]);
      }
    } else if (resourceType === CreativeType.Image && imageCreatives) {
      const uploadedImageCreatives = values.imageCreatives.filter(
        (image: ICreativeImage) => !image.id
      );

      const newImagesFromLibrary = selectedImageIds.reduce(
        (result: ICreativeImage[], newImageId: string) => {
          const imageInLibrary = (imageCreatives.allCreatives as ICreativeImage[]).find(
            (creativeImage: ICreativeImage) =>
              !!creativeImage.resourceAltId &&
              creativeImage.resourceAltId === Number(newImageId)
          );

          if (imageInLibrary) {
            const alreadySelectedImage = values.imageCreatives.find(
              (image: ICreativeImage) =>
                image.resourceAltId === imageInLibrary.resourceAltId
            );

            if (alreadySelectedImage) {
              result.push(alreadySelectedImage);
            } else {
              result.push({
                ...imageInLibrary,
                size: imageInLibrary.size,
                mimeType: imageInLibrary.mimeType,
                landingPageUrl: '',
                trackingUrls: [{ url: '', consentVendorId: '' }],
                status: CreativeStatusEnum.HasCreative,
              });
            }
          }
          return result;
        },
        []
      );

      const newImageCreatives = [
        ...uploadedImageCreatives,
        ...newImagesFromLibrary,
      ];

      setFieldValue('imageCreatives', newImageCreatives);
    }
    closeModal();
  };

  const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, checked },
    } = event;
    if (checked) {
      setSelectedImagesIds([...selectedImageIds, value]);
    } else {
      setSelectedImagesIds(
        selectedImageIds.filter((id: string) => id !== value)
      );
    }
  };

  const handleAudioSelection = (event: React.ChangeEvent<unknown>) =>
    setSelectedAudioId((event.target as HTMLInputElement).value);

  if (loadingAudio || loadingImages) {
    return <Loader />;
  }

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open
      onClose={closeModal}
      maxWidth="xl"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">
          {resourceType === CreativeType.Audio
            ? 'Audio Library'
            : 'Image Library'}
        </Typography>
        <IconButton
          onClick={closeModal}
          classes={{ root: classes.closeButton }}
          data-tc="creativeLibraryModalX"
          data-testid="creativeLibraryModalX"
        >
          <CloseIcon />
          <span className="sr-only">Close modal</span>
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.content }}
        data-tc="creativeLibraryContent"
      >
        {resourceType === CreativeType.Audio ? (
          <AudioLibrary
            data={(audioCreatives?.allCreatives as CreativeAudio[]) || []}
            handleChange={handleAudioSelection}
            selectedAudioId={selectedAudioId}
          />
        ) : (
          <ImageLibrary
            data={(imageCreatives?.allCreatives as CreativeImage[]) || []}
            selectedImageIds={selectedImageIds}
            handleChange={handleImageSelection}
          />
        )}
      </DialogContent>
      <DialogActions
        classes={{
          root: classNames(
            classes.actions,
            `${classes.actions}--space-between`
          ),
        }}
      >
        <StyledButton
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc="cancelModalButton"
          onClick={closeModal}
        >
          Cancel
        </StyledButton>
        <StyledButton
          color={ButtonColorEnum.Primary}
          type="submit"
          variant={ButtonVariantEnum.Contained}
          data-tc="submitModalCreatives"
          onClick={saveAndClose}
        >
          {resourceType === CreativeType.Image
            ? 'Select Creative(s)'
            : 'Select Creative'}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreativeModal;
