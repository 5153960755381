import classNames from 'classnames';
import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CheckIcon } from 'assets/icons/dax-checkicon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/dax-crossicon.svg';
import { ReactComponent as CreateIcon } from 'assets/icons/dax-editicon.svg';

import TableSelect from 'components/Table/TableSelect';

import { IFieldUpdate, IFieldValidate, OptionType } from 'interfaces';

import { findLabelValue } from 'utils/dataTransformation';

import useStyles from './EntityProgressUpdater.styles';

export interface IStatusValue extends OptionType {
  isDisabled?: (value: any) => Boolean;
}

interface IEntityProgressUpdaterProps {
  currentStatus: string;
  statusValues: IStatusValue[];
  onUpdate: (values: IFieldUpdate) => void;
  onValidate: (values: IFieldValidate) => void;
  onChange: any;
  error: string | null;
  'data-tc': string;
}

const EntityProgressUpdater = ({
  currentStatus,
  statusValues,
  onUpdate,
  onValidate,
  onChange,
  error,
  'data-tc': dataTc,
}: IEntityProgressUpdaterProps) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(currentStatus);
  const classes = useStyles();

  const handleEdit = () => {
    setEditing(true);
  };

  const onSelectChange = (newStatus: any) => {
    setValue(newStatus);
  };

  const handleDismiss = () => {
    setValue(currentStatus);
    setEditing(false);
  };

  const handleApprove = async () => {
    onChange('status', value, false);
    setEditing(false);
    await onValidate({
      entity: { targetStatus: value },
      handleContinue: () => onUpdate({ entity: { status: value } }),
      handleCancel: () => {
        setValue(currentStatus);
        onChange('status', currentStatus, false);
      },
    });
  };

  return editing ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <TableSelect
          value={value}
          onChange={onSelectChange}
          options={statusValues as any}
          name="status"
          dataTc={`${dataTc}Select`}
          classes={{ select: classes.select }}
        />
        <Tooltip title="Save">
          <IconButton onClick={handleApprove} data-testid="saveStatusButton">
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton onClick={handleDismiss} data-testid="cancelStatusButton">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div
        data-tc={`${dataTc}Label`}
        className={classNames([
          classes.container,
          {
            [`${classes.container}--edit`]: true,
          },
        ])}
      >
        {findLabelValue({
          collection: statusValues,
          lookupValue: value,
        })}
        <Tooltip title="Edit">
          <IconButton onClick={handleEdit} data-testid="editStatusButton">
            <CreateIcon />
          </IconButton>
        </Tooltip>
      </div>
      {error ? <span className={classes.error}>{error}</span> : null}
    </div>
  );
};

export default EntityProgressUpdater;
