import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuIconButton: {
    padding: theme.spacing(0, 0.4),
    margin: theme.spacing(0, 0.5),
    backgroundColor: 'transparent',
    borderRadius: '4px',
    height: '30px',
    '&:hover': {
      backgroundColor: colors.primaryBlueLight,
    },
    '&--open': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  paper: {
    boxShadow: `0px 4px 8px ${colors.boxShadowLightGrey}`,
    borderRadius: '8px',
  },
  noOptionsText: {
    justifyContent: 'center',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
