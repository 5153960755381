import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import UserForm from 'features/user/components/UserForm/UserForm';
import { IUserValues } from 'features/user/components/UserForm/userFormUtils';
import UserFormValidation from 'features/user/validations/users';
import { Formik } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useCreateUserMutation } from 'interfaces/generated.types';
import React from 'react';
import { handleOnComplete, submitCreateUser } from './createUserContainerUtils';

interface ICreateUserContainerProps {
  history: History;
}

const CreateUserContainer = ({ history }: ICreateUserContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the User.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const [createUser] = useCreateUserMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <>
      <Formik<IUserValues>
        initialValues={{
          email: '',
          permissions: [],
          primaryTerritory: '',
          territories: [],
          isUserDelete: false,
          entityPermissions: [],
          userType: '',
        }}
        validationSchema={UserFormValidation}
        onSubmit={submitCreateUser(createUser, toggleErrorModal)}
      >
        {(props) => <UserForm {...props} />}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createUser"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateUserContainer;
