import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexWrap: 'wrap',
    minHeight: '24px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '0px 4px 4px 0px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.625),
    paddingTop: '3px',
    paddingBottom: '3px',
    gap: theme.spacing(0.5),
  },
  placeholder: {
    color: colors.daxGreyscaleSilver,
  },
  messagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  toggleValuesBtnText: {
    height: '22px',
  },
}));

export default useStyles;
