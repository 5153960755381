import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import numbro from 'numbro';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import { IDspFormValues } from 'features/programmatic/dsp/components/DspTabsForm/DspFormValues';
import DspTabsForm, {
  dspButtons,
} from 'features/programmatic/dsp/components/DspTabsForm/DspTabsForm';
import DspFormValidation from 'features/programmatic/dsp/validations/dsps';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  Dsp,
  EntityType,
  TechnicalProvider,
  Territory,
  UpdateDspMutationFn,
  useUpdateDspMutation,
} from 'interfaces/generated.types';

import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { numbroDecimalFormatting } from 'utils/numbers';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    dspId: string;
  };
}
interface IUpdateDspContainerProps {
  dsp: Dsp;
  history: History;
  match: IMatch;
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string,
  technicalProviderId: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: technicalProviderId,
    listEntity:
      selectedJourney === '/programmatic-connector/PARENT_ID' ? 'Dsp' : '',
  });

export const submitForm = (
  updateDsp: UpdateDspMutationFn,
  initialValues: IDspFormValues,
  match: IMatch,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IDspFormValues,
  { setStatus, setSubmitting }: FormikActions<IDspFormValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = {
    ...differenceInValues(
      { ...formValues, journey: null },
      { ...initialValues, journey: null }
    ),
    id: match.params.dspId,
  };

  updateDsp({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateDspContainer = ({
  history,
  match,
  dsp,
  hasEditPermissions,
}: IUpdateDspContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Dsp.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateDsp] = useUpdateDspMutation({
    onCompleted() {
      handleOnComplete(
        history,
        selectedJourney,
        dsp.id,
        (dsp.technicalProvider as TechnicalProvider).id
      );
    },
  });
  const validateDsp = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    altId: dsp.altId,
    dailyCap:
      typeof dsp.dailyCap === 'number'
        ? numbro(dsp.dailyCap).format(numbroDecimalFormatting)
        : '',
    maxAds: dsp.maxAds,
    id: dsp.id,
    name: dsp.name as string,
    journey: dspButtons[0].url,
    status: dsp.status,
    technicalProviderName: (dsp.technicalProvider as TechnicalProvider)
      .name as string,
    technicalProviderId: (dsp.technicalProvider as TechnicalProvider).id,
    territories: getTerritoryValues(dsp.territories || []),
    technicalProviderTerritories: getTerritoryValues(
      ((dsp.technicalProvider as TechnicalProvider)
        .territories as Territory[]) || []
    ),
  };

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IDspFormValues>
        initialValues={initialValues}
        validationSchema={DspFormValidation}
        onSubmit={submitForm(
          updateDsp,
          initialValues,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <DspTabsForm
            {...formikProps}
            update
            goBackTo={{
              pathname: `/programmatic-connector/${
                (dsp.technicalProvider as TechnicalProvider).id
              }`,
              state: location.state?.parent || {},
            }}
            hasEditPermissions={hasEditPermissions}
            match={match}
            history={history}
            seats={dsp.seats || []}
            technicalProviderStatus={
              (dsp.technicalProvider as TechnicalProvider).status
            }
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateDsp,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Dsp,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateDsp,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateDsp"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateDspWarningDialog"
      />
    </>
  );
};
export default UpdateDspContainer;
