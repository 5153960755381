import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

export const baseCheckboxStyles = (theme: Theme) => ({
  checkboxPrimaryColor: {
    color: theme.palette.grey[800],
    '&.Mui-checked': {
      color: colors.primaryBlue,
    },
  },
});

const checkboxStyles = makeStyles((theme: Theme) => ({
  ...baseCheckboxStyles(theme),
}));

export default checkboxStyles;
