import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  targetingToggleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(2, 4, 0, 0),
  },
  helpAndTutLink: {
    color: colors.primaryBlue,
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: colors.primaryBlue,
      '& > svg': {
        marginRight: theme.spacing(1),
        overflow: 'visible',
        '& > path': {
          strokeWidth: 1.4,
        },
      },
    },
  },
}));

export default useStyles;
