import Container from 'components/Container/Container';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { Event } from 'context/GTMLoggerProvider/GTMLoggerProvider';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { utcToZonedTime } from 'date-fns-tz';
import { IForecastingValues } from 'features/forecasting/components/ForecastingDetails/ForecastingDetails';
import ForecastingTabsForm from 'features/forecasting/components/ForecastingTabsForm/ForecastingTabsForm';
import {
  GET_FORECAST,
  GET_FORECAST_V1,
  IGetForecastResponse,
} from 'features/forecasting/graphql/queries';
import ForecastingFormValidation from 'features/forecasting/validations/forecasting';
import useAllParametersInfo from 'features/targetingV2/hooks/AllParametersInfo/useAllParametersInfo';
import { defaultTargetingDefinitionV2 } from 'features/targetingV2/utils/defaults';
import { sendTargetingV2DataToGTM } from 'features/targetingV2/utils/gtmData';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import {
  Ad,
  TargetingVersion,
  Territory,
  useAllLocationAccuraciesQuery,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import dateUtils from 'utils/date';
import {
  getDefaultTargetingGroupWithCountryParamsBasedOnTerritory,
  getDefaultTimezoneBasedOnTerritory,
} from 'utils/defaultsByTerritory';
import { commonDefaultValues, getAdValues } from 'utils/forecasting';
import { formatValues, handleFormErrors } from 'utils/forms';
import { getTargetingDefinitionForCreate } from 'utils/targetingDefinitions';

interface IForecastingContainerProps {
  history: History;
  ad: Ad | undefined;
}

export const submitForm = async (
  formValues: IForecastingValues,
  { setStatus, setSubmitting }: FormikActions<IForecastingValues>,
  getForecast: (variables: any) => Promise<any>,
  setData: (value: React.SetStateAction<any>) => void,
  setDateOfForecastGeneration: (value: React.SetStateAction<any>) => void,
  toggleErrorModal: () => void,
  activeTerritory: Territory
) => {
  const formattedValues = formatValues(formValues);

  const targetingDefinition = getTargetingDefinitionForCreate(
    formValues,
    formattedValues
  );

  const filteredValues = pickBy({
    ...formValues,
    objective: formattedValues.objective,
    startDate: formattedValues.startDate,
    endDate: formattedValues.endDate,
    iabCategoryCodes: formattedValues.iabCategoryCodes,
    targetingGroups: null,
    targetingMasterTemplates: null,
    targetingGeneralGroup: null,
    ...targetingDefinition,
    targetingVersion: null,
    territory: activeTerritory,
  });

  try {
    const { data }: { data: IGetForecastResponse } = await getForecast(
      filteredValues
    );

    setStatus(undefined);
    setSubmitting(false);
    setData(data);

    const newDateForForecast = utcToZonedTime(new Date(), formValues.timeZone);
    setDateOfForecastGeneration(newDateForForecast);
  } catch (error) {
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  }
};

const ForecastingContainer = ({ history, ad }: IForecastingContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't retrieve the forecast.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [data, setData] = useState<IGetForecastResponse>();
  const [dateOfForecastGeneration, setDateOfForecastGeneration] =
    useState<Date>();
  const getForecast = useLazyQuery<IGetForecastResponse>(GET_FORECAST);
  const getForecastV1 = useLazyQuery<IGetForecastResponse>(GET_FORECAST_V1);

  const gtmLogger = useGTMLogger();
  const { allParametersInfo, loading: allParametersInfoLoading } =
    useAllParametersInfo();

  const {
    data: allLocationAccuraciesData,
    loading: allLocationAccuraciesLoading,
  } = useAllLocationAccuraciesQuery({
    fetchPolicy: 'cache-first',
  });

  const defaultInitialValues = {
    ...commonDefaultValues,
    timeZone: getDefaultTimezoneBasedOnTerritory(activeTerritory!),
    startDate: dateUtils.getNextMonday(),
    endDate: dateUtils.getFollowingSunday(),
    iabCategoryCodes: [],
    targetingGroups: [],
    targetingMasterTemplates: [],
    targetingGeneralGroup: activeTerritory
      ? getDefaultTargetingGroupWithCountryParamsBasedOnTerritory(
          activeTerritory
        )
      : null,
    targetingVersion: TargetingVersion.TargetingV2,
    targetingDefinitionV2: defaultTargetingDefinitionV2,
    targetingRestrictions: [],
  };

  const initialValues: IForecastingValues = ad
    ? getAdValues(ad)
    : defaultInitialValues;

  return (
    <main>
      <Container>
        <Formik<IForecastingValues>
          initialValues={initialValues}
          validationSchema={ForecastingFormValidation(activeTerritory)}
          validateOnMount
          onSubmit={(formValues, formikHelpers) => {
            gtmLogger({
              event: Event.USER_CLICKS_GENERATE_FORECAST,
            });

            if (formValues.targetingVersion === TargetingVersion.TargetingV2) {
              sendTargetingV2DataToGTM(
                formValues.targetingDefinitionV2,
                allParametersInfo,
                gtmLogger,
                activeTerritory!
              );
            }

            const forecastQuery =
              formValues.targetingVersion === TargetingVersion.TargetingV1
                ? getForecastV1
                : getForecast;

            submitForm(
              formValues,
              formikHelpers,
              forecastQuery,
              setData,
              setDateOfForecastGeneration,
              toggleErrorModal,
              activeTerritory!
            );
          }}
        >
          {(props) => (
            <ForecastingTabsForm
              {...props}
              dirty={!!ad || props.dirty}
              data={data?.forecast}
              dateOfForecastGeneration={dateOfForecastGeneration}
              history={history}
              allParametersInfo={allParametersInfo}
              allLocationAccuracies={
                allLocationAccuraciesData?.allLocationAccuracies
              }
              loading={allLocationAccuraciesLoading || allParametersInfoLoading}
            />
          )}
        </Formik>
      </Container>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="getForecast"
        errorMessages={errorMessages}
      />
    </main>
  );
};

export default ForecastingContainer;
