import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import UserForm from 'features/user/components/UserForm/UserForm';
import { IUserValues } from 'features/user/components/UserForm/userFormUtils';
import UserFormValidation from 'features/user/validations/users';
import { Formik } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  useDeleteUserMutation,
  User,
  useUpdateUserMutation,
} from 'interfaces/generated.types';
import React from 'react';

import {
  formatUserPermissions,
  handleOnComplete,
  IMatch,
  submitDeleteUser,
  submitUpdateUser,
} from './updateUserContainerUtils';

interface IUpdateUserContainerProps {
  history: History;
  user: User;
  match: IMatch;
}

const UpdateUserContainer = ({
  history,
  user,
  match,
}: IUpdateUserContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the User.",
    'Please try again later.',
  ]);

  const initialValues: IUserValues = {
    email: user.email as string,
    permissions: formatUserPermissions(user.permissions),
    primaryTerritory: user.primaryTerritory,
    territories: user.territories || [],
    isUserDelete: false,
    entityPermissions: user.entityPermissions || [],
    userType: user.userType || '',
  };

  const [deleteUser] = useDeleteUserMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  const [updateUser] = useUpdateUserMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setStatus }) =>
          values.isUserDelete
            ? submitDeleteUser(deleteUser, match)
            : submitUpdateUser({
                updateUser,
                initialValues,
                formValues: values,
                match,
                setSubmitting,
                setStatus,
                toggleErrorModal,
              })
        }
        validationSchema={UserFormValidation}
      >
        {(props) => <UserForm {...props} update />}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateUser"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default UpdateUserContainer;
