import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import useStyles from './PasswordResetForm.styles';

export interface IPasswordResetFormValues {
  password: string;
  passwordConfirm: string;
}

interface IPasswordResetFormProps
  extends FormikProps<IPasswordResetFormValues> {
  error?: string;
  setError: (error: string) => void;
}

const PasswordResetForm = (props: IPasswordResetFormProps) => {
  const {
    values: { password, passwordConfirm },
    error,
    setError,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
  } = props;

  const classes = useStyles();
  const pageClasses = pageStyles();

  const history = useHistory();

  const clearErrors = () => {
    setError('');
  };

  return (
    <div className={classes.signInContainer}>
      <Form onSubmit={handleSubmit} aria-label="password-reset-form">
        <div>
          <Field
            id="password"
            component={TextField}
            label="Password"
            fullWidth
            margin="normal"
            InputLabelProps={{
              'aria-label': 'password',
              shrink: true,
            }}
            name="password"
            type="password"
            onChange={handleChange}
            onKeyUp={clearErrors}
            helperText={touched.password && errors.password}
            FormHelperTextProps={{
              error: !!(touched.password && errors.password),
            }}
          />
          <Field
            id="passwordConfirm"
            component={TextField}
            label="Confirm Password"
            fullWidth
            margin="normal"
            InputLabelProps={{
              'aria-label': 'password-confirm',
              shrink: true,
            }}
            name="passwordConfirm"
            type="password"
            onChange={handleChange}
            onKeyUp={clearErrors}
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            FormHelperTextProps={{
              error: !!(touched.passwordConfirm && errors.passwordConfirm),
            }}
          />
          {error && (
            <div className={classes.error}>
              <Typography
                variant="body2"
                align="center"
                className={pageClasses.errorMessage}
              >
                {error}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <StyledButton
            onClick={history.goBack}
            color={ButtonColorEnum.Default}
            variant={ButtonVariantEnum.Outlined}
            className={classes.button}
          >
            Back
          </StyledButton>
          <StyledButton
            onClick={handleSubmit}
            color={ButtonColorEnum.Default}
            variant={ButtonVariantEnum.Contained}
            className={classNames(classes.button, `${classes.button}--submit`)}
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting || !password || !passwordConfirm}
            testId="confirm-reset-button"
          >
            Confirm
          </StyledButton>
        </div>
      </Form>
    </div>
  );
};

export default PasswordResetForm;
