import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, Ref } from 'react';

import {
  Paper as MUIPaper,
  PaperProps as MUIPaperProps,
  styled,
} from '@material-ui/core';
import {
  compose,
  display,
  flexbox,
  spacing,
  typography,
} from '@material-ui/system';

import useStyles from './Paper.styles';

export type PaperProps = MUIPaperProps;

// eslint-disable-next-line react/display-name
const StyledPaper = forwardRef((props: PaperProps, ref: Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUIPaper ref={ref} classes={classes} {...otherProps} />;
});

const Paper = styled(StyledPaper)(
  compose(spacing, flexbox, display, typography)
);

Paper.displayName = 'Paper';

export default Paper;
