import pageStyles from 'assets/styles/components/Page.styles';
import Table from 'components/Table-new/Table';
import TableLink from 'components/Table/TableLink';
import { targetingParameterClusivityLabelEnum } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { Ad, AdStatus } from 'interfaces/generated.types';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { ITargetingDefinition } from 'utils/targetingDefinitions';
import { formatTargetingDefinition } from 'utils/targetingFormatting';

import { Typography } from '@material-ui/core';

import useStyles from './AdTargetingTable.styles';

interface IAdsTargetingRow {
  id: string;
  name: string;
  status: AdStatus;
  targetingDefinition: ITargetingDefinition;
}

interface IAdTargetingTableProps {
  ads: Partial<Ad>[];
}

export const TargetingDescription = (
  targetingDefinition: ITargetingDefinition
) => {
  const targetingGroups = formatTargetingDefinition(targetingDefinition);

  const classes = useStyles();

  return (
    <>
      {targetingGroups.map((group) => (
        <div className={classes.group} key={group.title}>
          <Typography className={classes.title}>
            <span className={classes.bold}>{group.title}</span>
            {group.channels !== undefined && `- Channels(${group.channels})`}
          </Typography>
          {group.audienceParamLabels.map((param) => (
            <Typography
              className={classes.group}
              key={`${param.label}-${param.values[0] || 'empty'}`}
            >
              {param.label}:{' '}
              {param.clusivity !== undefined &&
                `(${
                  targetingParameterClusivityLabelEnum[
                    param.clusivity as keyof typeof targetingParameterClusivityLabelEnum
                  ]
                }) `}
              {param.values.join(', ')}
            </Typography>
          ))}
        </div>
      ))}
    </>
  );
};

const AdTargetingTable = ({ ads }: IAdTargetingTableProps) => {
  const classes = pageStyles();

  const location = usePreviousLocation();

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IAdsTargetingRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/ad/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Ad Targeting',
        accessor: 'targetingDefinition',
        Cell: ({ cell: { row } }: CellProps<IAdsTargetingRow>) =>
          TargetingDescription(row.original.targetingDefinition),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        columns={columns}
        data={ads}
        dataTc="adsTargetingTable"
        hideToolbar
        filtering={{ manualFiltering: false }}
        searching={{ manualSearching: false }}
        sorting={{ manualSorting: false }}
        pagination={{ manualPagination: false }}
      />
    </div>
  );
};

export default AdTargetingTable;
