import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  descriptionText: {
    paddingLeft: theme.spacing(5.7),
  },
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    paddingRight: theme.spacing(1),
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
}));

export default useStyles;
