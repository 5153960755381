import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import CreateTagContainer from 'features/tag/containers/CreateTagContainer/CreateTagContainer';

interface ICreateTagPageProps {
  history: History;
}

const CreateTagPage = ({ history }: ICreateTagPageProps) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Tag Page</h1>
      <CreateTagContainer history={history} />
    </Container>
  </main>
);

export default CreateTagPage;
