import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import ForecastingContainer from 'features/forecasting/containers/ForecastingContainer/ForecastingContainer';
import { History } from 'history';
import useUrlSearchParams from 'hooks/UrlParams/useUrlSearchParams';
import useUrlSearchParamUtils from 'hooks/UrlParams/useUrlSearchParamUtils';
import { Ad, useAdByAltIdQuery } from 'interfaces/generated.types';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface IForecastingPage {
  history: History;
}

const ForecastingPage = ({ history }: IForecastingPage) => {
  const { search }: { search: string } = useLocation();
  const urlSearchParams = useUrlSearchParams(search);
  const { getUrlParam } = useUrlSearchParamUtils(urlSearchParams);

  const adIdQueryParam = getUrlParam('ad_id');
  const adAltId = Number(adIdQueryParam);

  const { loading, data, error } = useAdByAltIdQuery({
    errorPolicy: 'all',
    variables: {
      altId: adAltId,
    },
    fetchPolicy: 'no-cache',
    skip: !adIdQueryParam,
  });

  if (adIdQueryParam) {
    if (loading) {
      return <Loader />;
    }

    if (error || !data?.adByAltId) {
      return <Redirecting history={history} />;
    }
  }

  return (
    <main>
      <Container>
        <h1 className="sr-only">Forecasting</h1>
        <ForecastingContainer history={history} ad={data?.adByAltId as Ad} />
      </Container>
    </main>
  );
};

export default ForecastingPage;
