import {
  groupByModel,
  groupByOs,
  targetingAdMidRollPositionValues,
  targetingAdPositionEnum,
  targetingConnectionTypeValues,
  targetingDayEnum,
  targetingDMPNames,
  targetingGenderValues,
  targetingParameterClusivityTypesEnum,
  targetingParameterTypesEnum,
  targetingTimeZoneEnum,
} from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import { getTargetingDefinitionV2Attributes } from 'features/targetingV2/utils/targeting';
import { IFormValues, OptionType } from 'interfaces';
import {
  Channel,
  TargetingDefinition,
  TargetingTemplateLink,
  TargetingTemplateStatus,
  TargetingVersion,
  Territory,
} from 'interfaces/generated.types';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import mergeWith from 'lodash/mergeWith';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';

import {
  dedupeValues,
  findLabelValue,
  groupBy,
  mergeArrays,
} from './dataTransformation';
import keyValueUtils from './keyValues';
import locationUtils, {
  processLocationParams,
  splitCountryParam,
} from './location';
import { numbroDecimalFormatting, parseFormattedValue } from './numbers';

export interface IAudienceParams {
  listenerAccuracy?: { value: string };
  keyValueParams?: {
    keyValues: {
      key: string;
      value: string;
    }[];
  };
  ageParams?: {
    ageRange: {
      minAge: number;
      maxAge: number;
    };
  };
  genderParams?: {
    genders: any[];
  };
  dayAndTimeParams?: {
    timeZone: targetingTimeZoneEnum;
    selectedPeriods: ISelectedPeriod[];
  };
  deviceTypeParams?: {
    deviceTypes: {
      label: string;
      value: string;
    }[];
  };
  deviceMakerParams?: {
    deviceMakers: {
      label: string;
      value: string;
      model: string;
    }[];
  };
  deviceOperatingSystemParams?: {
    deviceOperatingSystems: {
      label: string;
      value: string;
      os: string;
    }[];
  };
  browserLanguageParams?: {
    browserLanguages: {
      label: string;
      value: string;
    }[];
  };
  postalCodeParams?: {
    postalCodes: {
      label: string;
      value: string;
    }[];
    postcodeGroups: {
      label: string;
      value: string;
    }[];
  };
  locationParams?: {
    positions: {
      latitude: number | string;
      longitude: number | string;
      radius: number | string;
    }[];
  };
  dmpSegmentParams?: {
    segments: {
      label: string;
      value: string;
    }[];
  };
  dmaParams?: {
    dmas: {
      label: string;
      value: string;
    }[];
  };
  audienceSegmentParams?: {
    segments: {
      label: string;
      value: string;
    }[];
  };
  genreParams?: {
    genres: {
      label: string;
      value: string;
    }[];
  };
  ispParams?: {
    isps: {
      label: string;
      value: string;
    }[];
  };
  connectionTypeParams?: {
    connectionTypes: {
      label: string;
      value: string;
    }[];
  };
  itunesCategoryParams?: {
    categories: { id: string; readonly: false }[];
  };
  podcastParams?: {
    podcastTargets: {
      collectionId?: string;
      showId?: string;
      episodeId?: string;
      name?: string;
    }[];
  };
  adPositionParams?: {
    positions: {
      position: {
        label: string;
        value: string;
      };
      midRollPositions?: {
        label: string;
        value: number;
      }[];
    }[];
  };
  countryParams?: any;
  type: string;
  clusivity: string;
  readOnly: boolean;
}

export interface IDayTime {
  day: targetingDayEnum;
  hours: number[];
}

export interface ISelectedPeriod {
  day: targetingDayEnum;
  hours: { hour: number; readonly: boolean };
}

export interface IChannel extends Channel {
  readOnly: boolean;
}
export interface ITargetingGroup {
  id: string;
  targetingGroupId?: string;
  masterTargetingGroupId?: string;
  audienceParams: IAudienceParams[];
  channels: IChannel[] | null;
  listenerAccuracy?: number;
  templateName?: string;
}

export interface IFormattedTargetingGroup {
  id: string | undefined;
  masterTargetingGroupId: string | undefined;
  channelIds: string[];
  audienceParams: any;
  listenerAccuracy?: number | null;
}

export interface ITargetingMasterTemplate {
  masterId: string;
  masterName: string;
  edited: boolean;
  groups: ITargetingGroup[];
}

export interface ITargetingTemplateLink extends TargetingTemplateLink {
  adStatus?: any;
  dealStatus?: any;
}

export interface ITargetingTemplate {
  id: string;
  name: string;
  description: string;
  isMasterTemplate: boolean;
  generalTargetingGroup: ITargetingGroup;
  targetingGroups: ITargetingGroup[];
  status: TargetingTemplateStatus;
  assignedTo: ITargetingTemplateLink[];
  territory: Territory;
}

export interface ITargetingDefinition {
  id: string;
  generalTargetingGroup: ITargetingGroup;
  targetingGroups: ITargetingGroup[];
  masterTargetingTemplates: ITargetingTemplate[];
}

export interface ITargetingComponentProps {
  targetingGroupName: string;
  templateIndex?: number;
  groupIndex?: number;
  index: number;
}

export interface ITargetingGroupChannel {
  value: string;
  label: string;
}

export const getMasterTargetingTemplateIds = (
  targetingMasterTemplates: ITargetingMasterTemplate[]
) =>
  targetingMasterTemplates.map(
    (targetingMasterTemplate: ITargetingMasterTemplate) =>
      targetingMasterTemplate.masterId
  );

export const getTargetingFieldArrayName = memoizeOne(
  (groupIndex: number, templateIndex?: number) =>
    templateIndex !== undefined
      ? `targetingMasterTemplates[${templateIndex}].groups[${groupIndex}]`
      : `targetingGroups[${groupIndex}]`
);

export const getTargetingValues = memoizeOne(
  (
    values: IFormValues,
    targetingGroupName: string,
    templateIndex?: number,
    groupIndex?: number
  ) => {
    if (templateIndex !== undefined) {
      return groupIndex !== undefined
        ? values.targetingMasterTemplates[templateIndex][targetingGroupName][
            groupIndex
          ]
        : values.targetingMasterTemplates[templateIndex][targetingGroupName];
    }

    return groupIndex !== undefined
      ? values[targetingGroupName][groupIndex]
      : values[targetingGroupName];
  }
);

export const getTargetingPath = memoizeOne(
  (targetingGroupName: string, templateIndex?: number, groupIndex?: number) => {
    if (templateIndex !== undefined) {
      return groupIndex !== undefined
        ? `targetingMasterTemplates.${templateIndex}.${targetingGroupName}.${groupIndex}`
        : `targetingMasterTemplates.${templateIndex}.${targetingGroupName}`;
    }

    return groupIndex !== undefined
      ? `${targetingGroupName}.${groupIndex}`
      : `${targetingGroupName}`;
  }
);

export const matchAudienceParams = () =>
  ({
    [targetingParameterTypesEnum.CUSTOM]: 'keyValueGroupParams',
    [targetingParameterTypesEnum.AGE]: 'ageParams',
    [targetingParameterTypesEnum.GENDER]: 'genderParams',
    [targetingParameterTypesEnum.DAYTIME]: 'dayAndTimeParams',
    [targetingParameterTypesEnum.DEVICE_TYPE]: 'deviceTypeParams',
    [targetingParameterTypesEnum.DEVICE_MODEL]: 'deviceMakerParams',
    [targetingParameterTypesEnum.DEVICE_OS]: 'deviceOperatingSystemParams',
    [targetingParameterTypesEnum.BROWSER_LANGUAGE]: 'browserLanguageParams',
    [targetingParameterTypesEnum.POSTCODES]: 'postalCodeParams',
    [targetingParameterTypesEnum.LOCATION]: 'locationParams',
    [targetingParameterTypesEnum.COUNTRY]: 'countryParams',
    [targetingParameterTypesEnum.REGION]: 'countryParams',
    [targetingParameterTypesEnum.SUBREGION]: 'countryParams',
    [targetingParameterTypesEnum.CITY]: 'countryParams',
    [targetingParameterTypesEnum.DMP_SEGMENT]: 'dmpSegmentParams',
    [targetingParameterTypesEnum.DMA]: 'dmaParams',
    [targetingParameterTypesEnum.AUDIENCE]: 'audienceSegmentParams',
    [targetingParameterTypesEnum.GENRE]: 'genreParams',
    [targetingParameterTypesEnum.ITUNES_CATEGORY]: 'itunesCategoryParams',
    [targetingParameterTypesEnum.PODCAST]: 'podcastParams',
    [targetingParameterTypesEnum.AD_POSITION]: 'adPositionParams',
    [targetingParameterTypesEnum.ISP]: 'ispParams',
    [targetingParameterTypesEnum.CONNECTION_TYPE]: 'connectionTypeParams',
  } as any);

export const formatAudienceParams = (
  param: IAudienceParams // Happens on submission
) =>
  ({
    [targetingParameterTypesEnum.CUSTOM]: param &&
      param.keyValueParams &&
      keyValueUtils.filterKeyValues(param.keyValueParams.keyValues).length >
        0 && {
        keyValues: keyValueUtils
          .filterKeyValues(param.keyValueParams.keyValues)
          .map((keyValue: any) => ({
            key: keyValue.key,
            value: keyValue.value,
          })),
        clusivity: param.clusivity,
        matchCriteria: 'ANY_OF',
      },
    [targetingParameterTypesEnum.AGE]: param &&
      param.ageParams &&
      param.ageParams.ageRange &&
      param.ageParams.ageRange.minAge &&
      param.ageParams.ageRange.maxAge && {
        ageRange: {
          minAge: param.ageParams.ageRange.minAge,
          maxAge: param.ageParams.ageRange.maxAge,
        },
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.GENDER]: param &&
      param.genderParams &&
      param.genderParams.genders &&
      param.genderParams.genders.length > 0 && {
        genders: param.genderParams.genders
          .map((g: OptionType) => g.value)
          .sort(),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.DAYTIME]: param &&
      param.dayAndTimeParams &&
      param.dayAndTimeParams.timeZone &&
      param.dayAndTimeParams.selectedPeriods && {
        timeZone: param.dayAndTimeParams.timeZone,
        daysOfWeek: Object.entries(
          groupBy(param.dayAndTimeParams.selectedPeriods, 'day')
        ).map(([key, value]) => ({
          day: key,
          hours: value.map((hour: any) => hour.hours.hour),
        })),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.DEVICE_TYPE]: param &&
      param.deviceTypeParams &&
      param.deviceTypeParams.deviceTypes &&
      param.deviceTypeParams.deviceTypes.length > 0 && {
        deviceTypes: param.deviceTypeParams.deviceTypes
          .map((types: OptionType) => types.value)
          .sort(),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.DEVICE_OS]: param &&
      param.deviceOperatingSystemParams &&
      param.deviceOperatingSystemParams.deviceOperatingSystems &&
      param.deviceOperatingSystemParams.deviceOperatingSystems.length > 0 && {
        deviceOperatingSystems: groupByOs(
          param.deviceOperatingSystemParams.deviceOperatingSystems
        ).map(([name, versions]) => ({ name, versions })),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.DEVICE_MODEL]: param &&
      param.deviceMakerParams &&
      param.deviceMakerParams.deviceMakers &&
      param.deviceMakerParams.deviceMakers.length > 0 && {
        deviceMakers: groupByModel(param.deviceMakerParams.deviceMakers).map(
          ([name, models]) => ({ name, models })
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.BROWSER_LANGUAGE]: param &&
      param.browserLanguageParams &&
      param.browserLanguageParams.browserLanguages &&
      param.browserLanguageParams.browserLanguages.length > 0 && {
        browserLanguages: param.browserLanguageParams.browserLanguages.map(
          (browserLanguage: OptionType) => ({
            code: browserLanguage.value,
            description: browserLanguage.label,
          })
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.POSTCODES]: param &&
      param.postalCodeParams &&
      (param.postalCodeParams.postalCodes ||
        param.postalCodeParams.postcodeGroups) &&
      (param.postalCodeParams.postalCodes.length > 0 ||
        param.postalCodeParams.postcodeGroups.length > 0) && {
        postalCodes:
          param.postalCodeParams.postalCodes?.map(
            (code: OptionType) => code.value
          ) || [],
        postcodeGroupIds:
          param.postalCodeParams.postcodeGroups?.map(
            (group: OptionType) => group.value
          ) || [],
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.LOCATION]: param &&
      param.locationParams &&
      param.locationParams.positions &&
      locationUtils.filterPositions(param.locationParams.positions).length >
        0 && {
        positions: locationUtils.filterPositions(
          param.locationParams.positions
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.COUNTRY]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      locationUtils.createLocationData(
        param.countryParams,
        param.clusivity,
        targetingParameterTypesEnum.COUNTRY
      ),
    [targetingParameterTypesEnum.REGION]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      param.countryParams.regions &&
      locationUtils.createLocationData(
        param.countryParams,
        param.clusivity,
        targetingParameterTypesEnum.REGION
      ),
    [targetingParameterTypesEnum.SUBREGION]:
      param &&
      param.countryParams &&
      ((param.countryParams.countries &&
        param.countryParams.regions &&
        param.countryParams.subRegions) ||
        param.countryParams.countryGroups) &&
      locationUtils.createLocationData(
        param.countryParams,
        param.clusivity,
        targetingParameterTypesEnum.SUBREGION
      ),
    [targetingParameterTypesEnum.CITY]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      param.countryParams.cities &&
      locationUtils.createLocationData(
        param.countryParams,
        param.clusivity,
        targetingParameterTypesEnum.CITY
      ),
    [targetingParameterTypesEnum.DMP_SEGMENT]: param &&
      param.dmpSegmentParams &&
      param.dmpSegmentParams.segments &&
      param.dmpSegmentParams.segments.length > 0 && {
        segmentType: targetingDMPNames.NIELSEN,
        segments: param.dmpSegmentParams.segments.map(
          (segment: OptionType) => ({
            id: parseFormattedValue(segment.value),
            fullName: segment.label,
          })
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.DMA]: param &&
      param.dmaParams &&
      param.dmaParams.dmas &&
      param.dmaParams.dmas.length > 0 && {
        dmas: param.dmaParams.dmas.map((dma: OptionType) => ({
          code: parseFormattedValue(dma.value),
          name: dma.label,
        })),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.AUDIENCE]: param &&
      param.audienceSegmentParams &&
      param.audienceSegmentParams.segments &&
      param.audienceSegmentParams.segments.length > 0 && {
        segments: param.audienceSegmentParams.segments.map(
          (segment: OptionType) => ({
            id: parseFormattedValue(segment.value),
          })
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.GENRE]: param &&
      param.genreParams &&
      param.genreParams.genres &&
      param.genreParams.genres.length > 0 && {
        genres: param.genreParams.genres.map(
          (genre: OptionType) => genre.value
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.ITUNES_CATEGORY]: param &&
      param.itunesCategoryParams &&
      param.itunesCategoryParams.categories &&
      param.itunesCategoryParams.categories.length > 0 && {
        categories: param.itunesCategoryParams.categories.map(
          (category: any) => category.id
        ),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.PODCAST]: param &&
      param.podcastParams &&
      param.podcastParams.podcastTargets &&
      param.podcastParams.podcastTargets.length > 0 && {
        podcastTargets: param.podcastParams.podcastTargets
          .filter(
            (podcastTarget: any) =>
              !!podcastTarget.collectionId ||
              !!podcastTarget.episodeId ||
              !!podcastTarget.showId
          )
          .map((podcastTarget: any) => ({
            collectionId: podcastTarget.collectionId,
            episodeId: podcastTarget.episodeId,
            showId: podcastTarget.showId,
            name: podcastTarget.name,
          })),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.AD_POSITION]: param &&
      param.adPositionParams &&
      param.adPositionParams.positions &&
      param.adPositionParams.positions.length > 0 && {
        positions: param.adPositionParams.positions.map((pos: any) => ({
          position: pos.position.value,
          midRollPositions:
            pos.midRollPositions.length > 0
              ? pos.midRollPositions.map((midRoll: any) => midRoll.value)
              : [],
        })),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.ISP]: param &&
      param.ispParams &&
      param.ispParams.isps &&
      param.ispParams.isps.length > 0 && {
        ids: param.ispParams.isps.map((isp: OptionType) => isp.value),
        clusivity: param.clusivity,
      },
    [targetingParameterTypesEnum.CONNECTION_TYPE]: param &&
      param.connectionTypeParams &&
      param.connectionTypeParams.connectionTypes &&
      param.connectionTypeParams.connectionTypes.length > 0 && {
        connectionTypes: param.connectionTypeParams.connectionTypes.map(
          (connectionType: OptionType) => connectionType.value
        ),
        clusivity: param.clusivity,
      },
  } as any);

export const filterAudienceParams = (audienceParams: IAudienceParams[]) => {
  const combineAudienceParams = groupBy(audienceParams, 'type');

  /* eslint-disable array-callback-return, consistent-return */
  return Object.entries(combineAudienceParams)
    .filter(
      ([param, values]: [string, IAudienceParams[]]) => param && values.length
    )
    .map(([param, values]: [string, IAudienceParams[]]) => {
      const formattedValues = values
        .flatMap(
          (value: IAudienceParams) =>
            formatAudienceParams(value)[value.type as string]
        )
        .filter((value: IAudienceParams) => value);
      const uniqueValues = dedupeValues(formattedValues);
      if (uniqueValues.length)
        return { [matchAudienceParams()[param as string]]: uniqueValues };
    });
};

export const splitAudienceParams = (audienceParams: any) => {
  const filteredAudienceParams = filterAudienceParams(audienceParams);

  const [locationAudienceParams, remainingAudienceParams] = partition(
    filteredAudienceParams,
    (audienceParam: any) =>
      audienceParam && Object.keys(audienceParam).includes('countryParams')
  );
  /* eslint-disable no-param-reassign */
  const combinedLocationAudienceParams = locationAudienceParams.reduce(
    (acc, param) => {
      acc = mergeWith(acc, param, mergeArrays);
      return acc;
    },
    {} as any
  );

  return [remainingAudienceParams, combinedLocationAudienceParams];
};

export const formatTargetingGroup = (targetingGroup: ITargetingGroup) => {
  const { channels, audienceParams } = targetingGroup;
  if ((channels && channels.length) || audienceParams.length) {
    const audienceParamsWithoutListenerAccuracy =
      targetingGroup.audienceParams.filter(
        (audienceParam: any) =>
          audienceParam.type !== targetingParameterTypesEnum.LISTENER_ACCURACY
      );
    const [remainingAudienceParams, combinedLocationAudienceParams] =
      splitAudienceParams(audienceParamsWithoutListenerAccuracy);
    const combinedAudienceParams = Object.assign(
      {},
      ...remainingAudienceParams,
      combinedLocationAudienceParams
    );
    const { masterTargetingGroupId, id, targetingGroupId } = targetingGroup;
    const channelIds = targetingGroup.channels
      ? targetingGroup.channels.map((channel: Channel) => channel.id)
      : [];
    const listenerAccuracy = targetingGroup.audienceParams.find(
      (params: any) =>
        params.type === targetingParameterTypesEnum.LISTENER_ACCURACY
    );

    if (listenerAccuracy && listenerAccuracy.listenerAccuracy) {
      return {
        id: masterTargetingGroupId ? targetingGroupId : id,
        masterTargetingGroupId,
        channelIds,
        audienceParams: combinedAudienceParams,
        listenerAccuracy: parseFormattedValue(
          listenerAccuracy.listenerAccuracy.value
        ),
      };
    }
    return {
      id: masterTargetingGroupId ? targetingGroupId : id,
      masterTargetingGroupId,
      channelIds,
      audienceParams: combinedAudienceParams,
      listenerAccuracy: null,
    };
  }
};

export const formatTargetingGroups = (targetingGroups: ITargetingGroup[]) =>
  targetingGroups
    .map((targetingGroup: ITargetingGroup) =>
      formatTargetingGroup(targetingGroup)
    )
    .filter(
      (targetingGroup: IFormattedTargetingGroup | undefined) => !!targetingGroup
    );

export const findMultiSelectReadOnly = (
  param: any,
  selector: string,
  values: string
) => {
  const filteredValues = param[selector][values].filter(
    (v: any) => !v.readOnly
  );
  return filteredValues.length
    ? { ...param, [selector]: { [values]: filteredValues } }
    : [];
};

export const findDualMultiSelectReadOnly = (
  param: any,
  selector: string,
  first: string,
  second: string
) => {
  const filteredFirstValues = param[selector][first].filter(
    (v: any) => !v.readOnly
  );
  const filteredSecondValues = param[selector][second].filter(
    (v: any) => !v.readOnly
  );
  return filteredFirstValues.length || filteredSecondValues.length
    ? {
        ...param,
        [selector]: {
          [first]: filteredFirstValues,
          [second]: filteredSecondValues,
        },
      }
    : [];
};

export const findDayTimeReadOnly = (param: any) => {
  const filteredValues = param.dayAndTimeParams.selectedPeriods.filter(
    (period: any) => !period.hours.readOnly
  );
  return filteredValues.length
    ? {
        ...param,
        dayAndTimeParams: {
          timeZone: param.dayAndTimeParams.timeZone,
          selectedPeriods: filteredValues,
        },
      }
    : [];
};

export const findRegionCityReadOnly = (param: any, type: string) => {
  const countries = groupBy(param.countryParams.countries, 'value');

  const editedTypeValues = param.countryParams[type].filter(
    (value: any) => !value.readOnly
  );

  const editedCountries = editedTypeValues.flatMap(
    (value: any) => countries[value.countryCode][0]
  );

  const editedCountryValues = param.countryParams.countries.filter(
    (value: any) => !value.readOnly
  );

  const mergedCountries = dedupeValues([
    ...editedCountries,
    ...editedCountryValues,
  ]);

  return mergedCountries.length || editedCountryValues.length
    ? {
        ...param,
        countryParams: {
          countries: mergedCountries,
          [type]: editedTypeValues,
        },
      }
    : [];
};

export const findSubRegionReadOnly = (param: any) => {
  const countries = groupBy(param.countryParams.countries, 'value');
  const regions = groupBy(
    param.countryParams.regions.flatMap((region: any) => ({
      ...region,
      value: region.value.split('-')[0],
    })),
    'value'
  );

  const editedSubRegionValues = param.countryParams.subRegions.filter(
    (value: any) => !value.readOnly
  );
  const editedRegions = editedSubRegionValues
    .flatMap((subRegion: any) => regions[subRegion.countryCode][0])
    .map((region: any) => ({
      ...region,
      value: `${region.value}-${region.countryCode}`,
    }));

  const editedRegionValues = param.countryParams.regions.filter(
    (value: any) => !value.readOnly
  );

  const editedCountries = dedupeValues([
    ...editedRegionValues,
    ...editedRegions,
  ]).flatMap((region: any) => countries[region.countryCode][0]);
  const editedCountryValues = param.countryParams.countries.filter(
    (value: any) => !value.readOnly
  );

  const mergedCountries = dedupeValues([
    ...editedCountryValues,
    ...editedCountries,
  ]);
  const mergedRegions = dedupeValues([...editedRegionValues, ...editedRegions]);

  return mergedCountries.length ||
    mergedRegions.length ||
    editedSubRegionValues.length
    ? {
        ...param,
        countryParams: {
          countries: mergedCountries,
          regions: mergedRegions,
          subRegions: editedSubRegionValues,
        },
      }
    : [];
};

export const findAdPositionReadOnly = (param: any) => {
  const filteredPositions = param.adPositionParams.positions
    .filter(
      (v: any) =>
        !v.position.readOnly ||
        (v.position.value === targetingAdPositionEnum.MID_ROLL &&
          v.midRollPositions.some((midRoll: any) => !midRoll.readOnly))
    )
    .map((p: any) => ({
      ...p,
      midRollPositions: p.midRollPositions.filter(
        (midRoll: any) => !midRoll.readOnly
      ),
    }));

  return filteredPositions.length
    ? {
        ...param,
        adPositionParams: {
          positions: filteredPositions,
        },
      }
    : [];
};

export const filteredAudienceParams = (param: any) =>
  ({
    [targetingParameterTypesEnum.CUSTOM]:
      param &&
      param.keyValueParams &&
      param.keyValueParams.keyValues &&
      findMultiSelectReadOnly(param, 'keyValueParams', 'keyValues'),

    [targetingParameterTypesEnum.AGE]:
      param &&
      param.ageParams &&
      param.ageParams.ageRange &&
      param.ageParams.ageRange.readOnly
        ? []
        : param,

    [targetingParameterTypesEnum.GENDER]:
      param &&
      param.genderParams &&
      param.genderParams.genders &&
      findMultiSelectReadOnly(param, 'genderParams', 'genders'),

    [targetingParameterTypesEnum.DAYTIME]:
      param &&
      param.dayAndTimeParams &&
      param.dayAndTimeParams.selectedPeriods &&
      findDayTimeReadOnly(param),

    [targetingParameterTypesEnum.DEVICE_TYPE]:
      param &&
      param.deviceTypeParams &&
      param.deviceTypeParams.deviceTypes &&
      findMultiSelectReadOnly(param, 'deviceTypeParams', 'deviceTypes'),

    [targetingParameterTypesEnum.DEVICE_OS]:
      param &&
      param.deviceOperatingSystemParams &&
      param.deviceOperatingSystemParams.deviceOperatingSystems &&
      findMultiSelectReadOnly(
        param,
        'deviceOperatingSystemParams',
        'deviceOperatingSystems'
      ),
    [targetingParameterTypesEnum.DEVICE_MODEL]:
      param &&
      param.deviceMakerParams &&
      param.deviceMakerParams.deviceMakers &&
      findMultiSelectReadOnly(param, 'deviceMakerParams', 'deviceMakers'),
    [targetingParameterTypesEnum.BROWSER_LANGUAGE]:
      param &&
      param.browserLanguageParams &&
      param.browserLanguageParams.browserLanguages &&
      findMultiSelectReadOnly(
        param,
        'browserLanguageParams',
        'browserLanguages'
      ),

    [targetingParameterTypesEnum.POSTCODES]:
      param &&
      param.postalCodeParams &&
      (param.postalCodeParams.postalCodes ||
        param.postalCodeParams.postcodeGroups) &&
      findDualMultiSelectReadOnly(
        param,
        'postalCodeParams',
        'postalCodes',
        'postcodeGroups'
      ),

    [targetingParameterTypesEnum.LOCATION]:
      param &&
      param.locationParams &&
      param.locationParams.positions &&
      findMultiSelectReadOnly(param, 'locationParams', 'positions'),

    [targetingParameterTypesEnum.COUNTRY]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      findMultiSelectReadOnly(param, 'countryParams', 'countries'),

    [targetingParameterTypesEnum.REGION]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      param.countryParams.regions &&
      findRegionCityReadOnly(param, 'regions'),

    [targetingParameterTypesEnum.SUBREGION]:
      param &&
      param.countryParams &&
      ((param.countryParams.countries &&
        param.countryParams.regions &&
        param.countryParams.subRegions) ||
        param.countryParams.countryGroups) &&
      findSubRegionReadOnly(param),

    [targetingParameterTypesEnum.CITY]:
      param &&
      param.countryParams &&
      param.countryParams.countries &&
      param.countryParams.cities &&
      findRegionCityReadOnly(param, 'cities'),

    [targetingParameterTypesEnum.DMP_SEGMENT]:
      param &&
      param.dmpSegmentParams &&
      param.dmpSegmentParams.segments &&
      findMultiSelectReadOnly(param, 'dmpSegmentParams', 'segments'),

    [targetingParameterTypesEnum.DMA]:
      param &&
      param.dmaParams &&
      param.dmaParams.dmas &&
      findMultiSelectReadOnly(param, 'dmaParams', 'dmas'),

    [targetingParameterTypesEnum.AUDIENCE]:
      param &&
      param.audienceSegmentParams &&
      param.audienceSegmentParams.segments &&
      findMultiSelectReadOnly(param, 'audienceSegmentParams', 'segments'),

    [targetingParameterTypesEnum.GENRE]:
      param &&
      param.genreParams &&
      param.genreParams.genres &&
      findMultiSelectReadOnly(param, 'genreParams', 'genres'),

    [targetingParameterTypesEnum.ITUNES_CATEGORY]:
      param &&
      param.itunesCategoryParams &&
      param.itunesCategoryParams.categories &&
      findMultiSelectReadOnly(param, 'itunesCategoryParams', 'categories'),

    [targetingParameterTypesEnum.PODCAST]:
      param &&
      param.podcastParams &&
      param.podcastParams.podcastTargets &&
      findMultiSelectReadOnly(param, 'podcastParams', 'podcastTargets'),

    [targetingParameterTypesEnum.AD_POSITION]:
      param &&
      param.adPositionParams &&
      param.adPositionParams.positions &&
      findAdPositionReadOnly(param),

    [targetingParameterTypesEnum.LISTENER_ACCURACY]:
      param &&
      param.listenerAccuracy &&
      param.listenerAccuracy.value &&
      !param.listenerAccuracy.readOnly
        ? param
        : [],

    [targetingParameterTypesEnum.ISP]:
      param &&
      param.ispParams &&
      param.ispParams.isps &&
      findMultiSelectReadOnly(param, 'ispParams', 'isps'),

    [targetingParameterTypesEnum.CONNECTION_TYPE]:
      param &&
      param.connectionTypeParams &&
      param.connectionTypeParams.connectionTypes &&
      findMultiSelectReadOnly(param, 'connectionTypeParams', 'connectionTypes'),
  } as any);

export const filterMasterValues = (values: ITargetingMasterTemplate[]) =>
  values.flatMap((grouping: ITargetingMasterTemplate) =>
    grouping.groups
      .flatMap((rg: ITargetingGroup) => ({
        id: rg.id,
        masterTargetingGroupId: rg.masterTargetingGroupId,
        targetingGroupId: rg.targetingGroupId || '',
        channels:
          rg.channels && rg.channels.length
            ? rg.channels.filter((c: any) => !c.readOnly)
            : [],
        audienceParams: rg.audienceParams.flatMap(
          (ap: any) => filteredAudienceParams(ap)[ap.type as string]
        ),
      }))
      .filter(
        (group: ITargetingGroup) =>
          (group.channels && group.channels.length > 0) ||
          group.audienceParams.length > 0
      )
  );

export const formatUpdateAudienceParams = (
  param: any // happens on get of targting details
) =>
  ({
    [targetingParameterTypesEnum.CUSTOM]: {
      type: targetingParameterTypesEnum.CUSTOM,
      clusivity: param.clusivity,
      keyValueParams: {
        keyValues:
          param.keyValues &&
          param.keyValues.map((keyValue: any) => ({
            key: keyValue.value.key,
            value: keyValue.value.value,
            readOnly: keyValue.readOnly,
          })),
      },
      readOnlyType:
        param.keyValues && param.keyValues.some((p: any) => p.readOnly),
    },
    [targetingParameterTypesEnum.AGE]: {
      type: targetingParameterTypesEnum.AGE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnly,
      ageParams: {
        ageRange: {
          minAge: param && param.ageRange && param.ageRange.minAge,
          maxAge: param && param.ageRange && param.ageRange.maxAge,
          readOnly: param.readOnly,
        },
      },
    },
    [targetingParameterTypesEnum.GENDER]: {
      type: targetingParameterTypesEnum.GENDER,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      genderParams: {
        genders:
          param &&
          param.genders &&
          param.genders.map((g: any) => ({
            value: g.value,
            label: findLabelValue({
              collection: targetingGenderValues,
              lookupValue: g.value,
            }),
            readOnly: g.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.DAYTIME]: {
      type: targetingParameterTypesEnum.DAYTIME,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      dayAndTimeParams: {
        timeZone: param && param.timeZone,
        selectedPeriods:
          param &&
          param.daysOfWeek &&
          flatten(
            param.daysOfWeek.map((p: IDayTime) =>
              p.hours.map((hours: any) => ({
                day: p.day,
                hours: { hour: hours.value, readOnly: hours.readOnly },
              }))
            )
          ),
      },
    },
    [targetingParameterTypesEnum.DEVICE_TYPE]: {
      type: targetingParameterTypesEnum.DEVICE_TYPE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      deviceTypeParams: {
        deviceTypes:
          param &&
          param.deviceTypes &&
          param.deviceTypes.map((type: any) => ({
            label: type.value,
            value: type.value,
            readOnly: type.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.DEVICE_OS]: {
      type: targetingParameterTypesEnum.DEVICE_OS,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      deviceOperatingSystemParams: {
        deviceOperatingSystems:
          param &&
          param.deviceOperatingSystems &&
          param.deviceOperatingSystems.flatMap((osGroup: any) =>
            osGroup.versions.map((version: any) => ({
              label: version.value,
              value: version.value,
              os: osGroup.name,
              readOnly: version.readOnly,
            }))
          ),
      },
    },
    [targetingParameterTypesEnum.DEVICE_MODEL]: {
      type: targetingParameterTypesEnum.DEVICE_MODEL,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      deviceMakerParams: {
        deviceMakers:
          param &&
          param.deviceMakers &&
          param.deviceMakers.flatMap((deviceMaker: any) =>
            deviceMaker.models.map((maker: any) => ({
              label: maker.value,
              value: maker.value,
              maker: deviceMaker.name,
              readOnly: maker.readOnly,
            }))
          ),
      },
    },
    [targetingParameterTypesEnum.BROWSER_LANGUAGE]: {
      type: targetingParameterTypesEnum.BROWSER_LANGUAGE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      browserLanguageParams: {
        browserLanguages:
          param &&
          param.browserLanguages &&
          param.browserLanguages.map((browserLanguage: any) => ({
            label: browserLanguage.description,
            value: browserLanguage.code,
            readOnly: browserLanguage.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.POSTCODES]: {
      type: targetingParameterTypesEnum.POSTCODES,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      postalCodeParams: {
        postalCodes:
          param &&
          param.postalCodes &&
          param.postalCodes.map((postalCode: any) => ({
            label: postalCode.value,
            value: postalCode.value,
            readOnly: postalCode.readOnly,
          })),
        postcodeGroups:
          param &&
          param.postcodeGroups &&
          param.postcodeGroups.map((group: any) => ({
            label: group.name,
            value: group.id,
            readOnly: group.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.LOCATION]: {
      type: targetingParameterTypesEnum.LOCATION,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      locationParams: {
        positions:
          param &&
          param.positions &&
          param.__typename === targetingParameterTypesEnum.LOCATION &&
          param.positions.map((position: any) => ({
            latitude: position.value.latitude,
            longitude: position.value.longitude,
            radius: position.value.radius / 1000,
            readOnly: position.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.COUNTRY]: {
      type: targetingParameterTypesEnum.COUNTRY,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      countryParams: {
        countries:
          param &&
          param.__typename === targetingParameterTypesEnum.COUNTRY &&
          param.countryParams &&
          locationUtils.createCountryGroup(param.countryParams),
      },
    },
    [targetingParameterTypesEnum.REGION]: {
      type: targetingParameterTypesEnum.REGION,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      countryParams: {
        countries:
          param &&
          param.__typename === targetingParameterTypesEnum.REGION &&
          param.countryParams &&
          dedupeValues(locationUtils.createCountryGroup(param.countryParams)),
        regions:
          param &&
          param.__typename === targetingParameterTypesEnum.REGION &&
          param.countryParams &&
          dedupeValues(
            param.countryParams.flatMap((country: any) =>
              locationUtils.createRegionOptions(country)
            )
          ),
      },
    },
    [targetingParameterTypesEnum.SUBREGION]: {
      type: targetingParameterTypesEnum.SUBREGION,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      countryParams: splitCountryParam(param),
    },
    [targetingParameterTypesEnum.CITY]: {
      type: targetingParameterTypesEnum.CITY,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      countryParams: {
        countries:
          param &&
          param.__typename === targetingParameterTypesEnum.CITY &&
          param.countryParams &&
          locationUtils.createCountryGroup(param.countryParams),
        cities:
          param &&
          param.__typename === targetingParameterTypesEnum.CITY &&
          param.countryParams &&
          param.countryParams.flatMap((country: any) =>
            locationUtils.createCityOptions(country)
          ),
      },
    },
    [targetingParameterTypesEnum.DMP_SEGMENT]: {
      type: targetingParameterTypesEnum.DMP_SEGMENT,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      dmpSegmentParams: {
        segments:
          param &&
          param.segments &&
          param.segments.map((segment: any) => ({
            label: segment.fullName,
            value: segment.id.toString(),
            readOnly: segment.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.DMA]: {
      type: targetingParameterTypesEnum.DMA,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      dmaParams: {
        dmas:
          param &&
          param.dmas &&
          param.dmas.map((dma: any) => ({
            label: dma.name,
            value: dma.code.toString(),
            readOnly: dma.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.AUDIENCE]: {
      type: targetingParameterTypesEnum.AUDIENCE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      audienceSegmentParams: {
        segments:
          param &&
          param.segments &&
          param.segments.map((segment: any) => ({
            label: segment.displayName,
            value: segment.id.toString(),
            readOnly: segment.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.GENRE]: {
      type: targetingParameterTypesEnum.GENRE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      genreParams: {
        genres:
          param &&
          param.genres &&
          param.genres.map((genre: any) => ({
            label: genre.value,
            value: genre.value,
            readOnly: genre.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.ITUNES_CATEGORY]: {
      type: targetingParameterTypesEnum.ITUNES_CATEGORY,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      itunesCategoryParams: {
        categories:
          param &&
          param.categories &&
          param.categories.map((category: any) => ({
            id: category.value,
            readOnly: category.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.PODCAST]: {
      type: targetingParameterTypesEnum.PODCAST,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      podcastParams: {
        podcastTargets:
          param &&
          param.podcastTargets &&
          param.podcastTargets.map((podcastTarget: any) => ({
            collectionId: podcastTarget.value.collectionId || '',
            episodeId: podcastTarget.value.episodeId || '',
            showId: podcastTarget.value.showId || '',
            name: podcastTarget.value.name || '',
            readOnly: podcastTarget.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.AD_POSITION]: {
      type: targetingParameterTypesEnum.AD_POSITION,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      adPositionParams: {
        positions:
          param &&
          param.positions &&
          param.__typename === targetingParameterTypesEnum.AD_POSITION &&
          param.positions.map((pos: any) => ({
            position: {
              value: pos.position.value,
              readOnly: pos.position.readOnly,
            },
            midRollPositions: pos.midRollPositions.map((midRoll: any) => ({
              value: midRoll.value,
              label: findLabelValue({
                collection: targetingAdMidRollPositionValues,
                lookupValue: midRoll.value,
              }),
              readOnly: midRoll.readOnly,
            })),
          })),
      },
    },
    [targetingParameterTypesEnum.ISP]: {
      type: targetingParameterTypesEnum.ISP,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      ispParams: {
        isps:
          param &&
          param.isps &&
          param.isps.map((isp: any) => ({
            label: isp.name,
            value: isp.id,
            readOnly: isp.readOnly,
          })),
      },
    },
    [targetingParameterTypesEnum.CONNECTION_TYPE]: {
      type: targetingParameterTypesEnum.CONNECTION_TYPE,
      clusivity: param.clusivity,
      readOnlyType: param.readOnlyType,
      connectionTypeParams: {
        connectionTypes:
          param &&
          param.connectionTypes &&
          param.connectionTypes.map((type: any) => ({
            label: findLabelValue({
              collection: targetingConnectionTypeValues,
              lookupValue: type.value,
            }),
            value: type.value,
            readOnly: type.readOnly,
          })),
      },
    },
  } as any);

export const formatInitialAudienceParams = (
  audienceParams: IAudienceParams[]
) =>
  processLocationParams(audienceParams).map(
    (param: any) => formatUpdateAudienceParams(param)[param.__typename]
  );

export const formatInitialChannels = (channels: IChannel[]) =>
  channels &&
  channels.map((channel) => ({
    ...channel,
    id: channel.id,
    readOnly: channel.readOnly,
  }));

export const formatInitialTargetingGroup = (targetingGroup: any) => {
  let listenerAccuracy: any = [];
  if (
    targetingGroup.listenerAccuracy &&
    targetingGroup.listenerAccuracy.value
  ) {
    listenerAccuracy = [
      {
        type: targetingParameterTypesEnum.LISTENER_ACCURACY,
        clusivity: targetingParameterClusivityTypesEnum.INCLUDE,
        readOnlyType: targetingGroup.listenerAccuracy.readOnlyType,
        listenerAccuracy: {
          value: numbro(targetingGroup.listenerAccuracy.value).format(
            numbroDecimalFormatting
          ),
          readOnly: targetingGroup.listenerAccuracy.readOnly,
        },
      },
    ];
  }
  return {
    audienceParams: [
      ...formatInitialAudienceParams(targetingGroup.audienceParams),
      ...listenerAccuracy,
    ],
    channels: formatInitialChannels(targetingGroup.channels),
    id: targetingGroup.id,
    targetingGroupId: targetingGroup.targetingGroupId || '',
    masterTargetingGroupId: targetingGroup.masterTargetingGroupId,
  };
};

export const formatInitialTargetingGroups = (targetingGroups: any[]) => {
  if (!targetingGroups.length) return [];
  return targetingGroups.map((targetingGroup: any) =>
    formatInitialTargetingGroup(targetingGroup)
  );
};

export const addReadOnlySimpleObject = (
  param: any,
  masterAudienceParams: any
) => {
  const valuesExistInMaster = masterAudienceParams.some((masterParam: any) =>
    isEqual(masterParam, param)
  );
  return {
    ...param,
    edited: !valuesExistInMaster,
    readOnly: valuesExistInMaster,
  };
};

export const addReadOnlyRegion = (param: any, masterAudienceParams: any) => {
  const regionMasterParams = masterAudienceParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.REGION &&
        division.subRegions.length === 0
    )
  );

  const countryValueExistInMaster = regionMasterParams.some(
    (masterParam: any) =>
      masterParam.code === param.code && masterParam.name === param.name
  );

  const regionMasterSubdivisions = regionMasterParams
    .filter(
      (regionMaster: any) =>
        regionMaster.code === param.code && regionMaster.name === param.name
    )
    .flatMap((regionMaster: any) => regionMaster.subdivisions);

  const formattedSubdivisions = param.subdivisions.flatMap((value: any) => ({
    ...value,
    readOnly: regionMasterSubdivisions.some((s: any) => isEqual(s, value)),
  }));

  const editedRegion = formattedSubdivisions.some(
    (division: any) => !division.readOnly
  );

  return {
    ...param,
    subdivisions: formattedSubdivisions,
    edited: !countryValueExistInMaster || editedRegion,
    readOnly: countryValueExistInMaster,
  };
};

export const addReadOnlySubRegion = (param: any, masterAudienceParams: any) => {
  const regionMasterParams = masterAudienceParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.REGION &&
        division.subRegions.length > 0
    )
  );

  const countryValueExistInMaster = regionMasterParams.some(
    (masterParam: any) =>
      masterParam.code === param.code && masterParam.name === param.name
  );

  const regionMasterSubdivisions = regionMasterParams
    .filter(
      (regionMaster: any) =>
        regionMaster.code === param.code && regionMaster.name === param.name
    )
    .flatMap((regionMaster: any) => regionMaster.subdivisions);

  const regionMasterSubRegions = regionMasterSubdivisions.flatMap(
    (subdivisionMaster: any) => subdivisionMaster.subRegions
  );

  const formattedSubdivisions = param.subdivisions.flatMap((value: any) => ({
    ...value,
    readOnly: regionMasterSubdivisions.some(
      (s: any) => s.code === value.code && s.name === value.name
    ),
    subRegions: value.subRegions.map((sr: any) => ({
      ...sr,
      readOnly: regionMasterSubRegions.some((s: any) => isEqual(s, sr)),
    })),
  }));

  const editedRegion = formattedSubdivisions.some(
    (division: any) => !division.readOnly
  );
  const editedSubRegion = formattedSubdivisions
    .flatMap((division: any) => division.subRegions)
    .some((u: any) => !u.readOnly);

  return {
    ...param,
    subdivisions: formattedSubdivisions,
    edited: !countryValueExistInMaster || editedRegion || editedSubRegion,
    readOnly: countryValueExistInMaster,
  };
};

export const addReadOnlyCity = (param: any, masterAudienceParams: any) => {
  const cityMasterParams = masterAudienceParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.CITY
    )
  );

  const countryValueExistInMaster = cityMasterParams.some(
    (masterParam: any) =>
      masterParam.code === param.code && masterParam.name === param.name
  );

  const cityMasterSubdivisions = cityMasterParams
    .filter(
      (cityMasterMaster: any) =>
        cityMasterMaster.code === param.code &&
        cityMasterMaster.name === param.name
    )
    .flatMap((cityMasterMaster: any) => cityMasterMaster.subdivisions);

  const formattedSubdivisions = param.subdivisions.flatMap((value: any) => ({
    ...value,
    readOnly: cityMasterSubdivisions.some((s: any) => isEqual(s, value)),
  }));

  const editedCity = formattedSubdivisions.some(
    (division: any) => !division.readOnly
  );

  return {
    ...param,
    subdivisions: formattedSubdivisions,
    edited: !countryValueExistInMaster || editedCity,
    readOnly: countryValueExistInMaster,
  };
};

export const addReadOnlySiblingValues = (
  param: any,
  masterAudienceParams: any,
  selector: string,
  siblingSelector: string,
  values: any
) => {
  const updatedParams = param[selector].flatMap((value: any) => ({
    [siblingSelector]: value[siblingSelector],
    [values]: value[values],
    readOnly: masterAudienceParams
      .flatMap((p: any) => p[selector])
      .some((i: any) => isEqual(i, value)),
  }));
  const edited = updatedParams.some((u: any) => !u.readOnly);
  const readOnlyType = masterAudienceParams.length > 0;

  return {
    ...param,
    readOnlyType,
    edited,
    [selector]: dedupeValues(updatedParams),
  };
};

export const addReadOnlyCountry = (
  param: any = {},
  masterAudienceParams: any
) => {
  const regionParams = param.subdivisions.some(
    (division: any) =>
      division.__typename === targetingParameterTypesEnum.REGION &&
      division.subRegions.length === 0
  );

  const subRegionParams = param.subdivisions.some(
    (division: any) =>
      division.__typename === targetingParameterTypesEnum.REGION &&
      division.subRegions.length > 0
  );

  const cityParams = param.subdivisions.some(
    (division: any) => division.__typename === targetingParameterTypesEnum.CITY
  );

  if (regionParams) return addReadOnlyRegion(param, masterAudienceParams);
  if (subRegionParams) return addReadOnlySubRegion(param, masterAudienceParams);
  if (cityParams) return addReadOnlyCity(param, masterAudienceParams);

  const countryValueExistInMaster = masterAudienceParams
    .filter((masterParam: any) => masterParam.subdivisions.length === 0)
    .some(
      (masterParam: any) =>
        isEqual(masterParam.code, param.code) &&
        isEqual(masterParam.name, param.name)
    );

  return {
    ...param,
    edited: !countryValueExistInMaster,
    readOnly: countryValueExistInMaster,
  };
};

export const addReadOnlyMultiSelect = (
  param: any,
  masterAudienceParams: any,
  values: any
) => {
  const updatedParams = param[values].flatMap((value: any) => ({
    value,
    readOnly: masterAudienceParams
      .flatMap((p: any) => p[values])
      .some((i: any) => isEqual(i, value)),
  }));

  const edited = updatedParams.some((u: any) => !u.readOnly);
  const readOnlyType = masterAudienceParams.length > 0;

  return {
    ...param,
    readOnlyType,
    edited,
    [values]: dedupeValues(updatedParams),
  };
};

export const addReadOnlyAdPositionValues = (
  param: any,
  masterAudienceParams: any
) => {
  const updatedParams = param.positions.flatMap((value: any) => ({
    position: {
      value: value.position,
      readOnly: masterAudienceParams
        .flatMap((p: any) => p.positions)
        .some((i: any) => i.position === value.position),
    },
    midRollPositions: value.midRollPositions.flatMap((val: any) => ({
      value: val,
      readOnly: masterAudienceParams
        .flatMap((p: any) =>
          p.positions.filter((i: any) => i.position === value.position)
        )
        .flatMap((j: any) => j.midRollPositions)
        .some((i: any) => i === val),
    })),
  }));

  const edited = updatedParams
    .flatMap((i: any) => i.midRollPositions)
    .some((i: any) => !i.readOnly);
  const readOnlyType = masterAudienceParams.length > 0;

  return {
    ...param,
    readOnlyType,
    edited,
    positions: dedupeValues(updatedParams),
  };
};

export const addReadOnlyNestedValues = (
  param: any,
  masterAudienceParams: any,
  selector: string,
  siblingSelector: string,
  values: any,
  typeName: string
) => {
  const filteredParams = masterAudienceParams.filter(
    (masterParam: any) => masterParam.__typename === typeName
  );

  const updatedParams = param[selector].flatMap((value: any) => ({
    [siblingSelector]: value[siblingSelector],
    [values]: value[values].flatMap((val: any) => ({
      value: val,
      readOnly: filteredParams
        .flatMap((p: any) =>
          p[selector].filter(
            (i: any) => i[siblingSelector] === value[siblingSelector]
          )
        )
        .flatMap((j: any) => j[values])
        .some((i: any) => i === val),
    })),
  }));

  const edited = updatedParams
    .flatMap((i: any) => i[values])
    .some((i: any) => !i.readOnly);
  const readOnlyType = masterAudienceParams.length > 0;

  return {
    ...param,
    readOnlyType,
    edited,
    [selector]: dedupeValues(updatedParams),
  };
};

export const addReadOnlyPostcodeValues = (
  param: any,
  masterAudienceParams: any
) => {
  const postcodeParams = addReadOnlyMultiSelect(
    param,
    masterAudienceParams,
    'postalCodes'
  );
  const postcodeGroupParams = addReadOnlySiblingValues(
    param,
    masterAudienceParams,
    'postcodeGroups',
    'name',
    'id'
  );

  const edited = postcodeParams.edited || postcodeGroupParams.edited;
  const readOnlyType =
    postcodeParams.readOnlyType || postcodeGroupParams.readOnlyType;

  return {
    ...postcodeParams,
    ...postcodeGroupParams,
    edited,
    readOnlyType,
    postalCodes: postcodeParams.postalCodes,
    postcodeGroups: postcodeGroupParams.postcodeGroups,
  };
};

export const addReadOnlyValues = (
  param: any = [],
  masterAudienceParams: any = [],
  type: targetingParameterTypesEnum
) => {
  switch (type) {
    case targetingParameterTypesEnum.CUSTOM:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'keyValues');
    case targetingParameterTypesEnum.AGE:
      return addReadOnlySimpleObject(param, masterAudienceParams);
    case targetingParameterTypesEnum.GENDER:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'genders');
    case targetingParameterTypesEnum.DAYTIME:
      return addReadOnlyNestedValues(
        param,
        masterAudienceParams,
        'daysOfWeek',
        'day',
        'hours',
        'DayAndTimeParam'
      );
    case targetingParameterTypesEnum.DEVICE_TYPE:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'deviceTypes');
    case targetingParameterTypesEnum.DEVICE_OS:
      return addReadOnlyNestedValues(
        param,
        masterAudienceParams,
        'deviceOperatingSystems',
        'name',
        'versions',
        'DeviceOperatingSystemParam'
      );
    case targetingParameterTypesEnum.DEVICE_MODEL:
      return addReadOnlyNestedValues(
        param,
        masterAudienceParams,
        'deviceMakers',
        'name',
        'models',
        'DeviceMakerParam'
      );
    case targetingParameterTypesEnum.BROWSER_LANGUAGE:
      return addReadOnlySiblingValues(
        param,
        masterAudienceParams,
        'browserLanguages',
        'description',
        'code'
      );
    case targetingParameterTypesEnum.POSTCODES:
      return addReadOnlyPostcodeValues(param, masterAudienceParams);
    case targetingParameterTypesEnum.LOCATION:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'positions');
    case targetingParameterTypesEnum.COUNTRY:
      return addReadOnlyCountry(param, masterAudienceParams);
    case targetingParameterTypesEnum.DMP_SEGMENT:
      return addReadOnlySiblingValues(
        param,
        masterAudienceParams,
        'segments',
        'fullName',
        'id'
      );
    case targetingParameterTypesEnum.DMA:
      return addReadOnlySiblingValues(
        param,
        masterAudienceParams,
        'dmas',
        'name',
        'code'
      );
    case targetingParameterTypesEnum.AUDIENCE:
      return addReadOnlySiblingValues(
        param,
        masterAudienceParams,
        'segments',
        'displayName',
        'id'
      );
    case targetingParameterTypesEnum.GENRE:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'genres');
    case targetingParameterTypesEnum.ITUNES_CATEGORY:
      return addReadOnlyMultiSelect(param, masterAudienceParams, 'categories');
    case targetingParameterTypesEnum.PODCAST:
      return addReadOnlyMultiSelect(
        param,
        masterAudienceParams,
        'podcastTargets'
      );
    case targetingParameterTypesEnum.AD_POSITION:
      return addReadOnlyAdPositionValues(param, masterAudienceParams);
    case targetingParameterTypesEnum.ISP:
      return addReadOnlySiblingValues(
        param,
        masterAudienceParams,
        'isps',
        'name',
        'id'
      );
    case targetingParameterTypesEnum.CONNECTION_TYPE:
      return addReadOnlyMultiSelect(
        param,
        masterAudienceParams,
        'connectionTypes'
      );
    default:
      break;
  }
};

export const combineMultiSelectParams = (
  masterParams: any = [],
  targetingParams: any = [],
  values: any
) => ({
  ...masterParams.concat(targetingParams)[0],
  [values]: [
    ...masterParams.flatMap((p: any) => p[values]),
    ...targetingParams.flatMap((mt: any) => mt[values]),
  ],
});

export const combineDualMultiSelectParams = (
  masterParams: any = [],
  targetingParams: any = [],
  first: any,
  second: any
) => ({
  ...masterParams.concat(targetingParams)[0],
  [first]: [
    ...masterParams.flatMap((p: any) => p[first]),
    ...targetingParams.flatMap((mt: any) => mt[first]),
  ],
  [second]: [
    ...masterParams.flatMap((p: any) => p[second]),
    ...targetingParams.flatMap((mt: any) => mt[second]),
  ],
});

export const combineNestedValues = (
  masterParams: any = [],
  targetingParams: any = [],
  selector: string,
  siblingSelector: string,
  values: any
) => {
  const groupedSiblings = groupBy(
    [
      ...masterParams.flatMap((p: any) => p[selector]),
      ...targetingParams.flatMap((mt: any) => mt[selector]),
    ],
    siblingSelector
  );

  const groupedValues = Object.entries(groupedSiblings).map(([key, value]) => ({
    [siblingSelector]: key,
    [values]: [...value.flatMap((v: any) => v[values])],
    __typename: value[0].__typename,
  }));

  return {
    ...masterParams.concat(targetingParams)[0],
    [selector]: groupedValues,
  };
};

export const combineValues = (
  masterTypenames: any = [],
  groupTypenames: any = [],
  type: targetingParameterTypesEnum
) => {
  switch (type) {
    case targetingParameterTypesEnum.CUSTOM:
      return [
        ...masterTypenames.flatMap((g: any) => g),
        ...groupTypenames.flatMap((g: any) => g),
      ];
    case targetingParameterTypesEnum.AGE:
      return masterTypenames && masterTypenames.length
        ? masterTypenames
        : groupTypenames;
    case targetingParameterTypesEnum.GENDER:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'genders'
      );
    case targetingParameterTypesEnum.DEVICE_TYPE:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'deviceTypes'
      );
    case targetingParameterTypesEnum.DEVICE_OS:
      return combineNestedValues(
        masterTypenames,
        groupTypenames,
        'deviceOperatingSystems',
        'name',
        'versions'
      );
    case targetingParameterTypesEnum.DEVICE_MODEL:
      return combineNestedValues(
        masterTypenames,
        groupTypenames,
        'deviceMakers',
        'name',
        'models'
      );
    case targetingParameterTypesEnum.BROWSER_LANGUAGE:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'browserLanguages'
      );
    case targetingParameterTypesEnum.POSTCODES:
      return combineDualMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'postalCodes',
        'postcodeGroups'
      );
    case targetingParameterTypesEnum.LOCATION:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'positions'
      );
    case targetingParameterTypesEnum.DMP_SEGMENT:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'segments'
      );
    case targetingParameterTypesEnum.DMA:
      return combineMultiSelectParams(masterTypenames, groupTypenames, 'dmas');
    case targetingParameterTypesEnum.AUDIENCE:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'segments'
      );
    case targetingParameterTypesEnum.DAYTIME:
      return combineNestedValues(
        masterTypenames,
        groupTypenames,
        'daysOfWeek',
        'day',
        'hours'
      );
    case targetingParameterTypesEnum.COUNTRY:
      return [
        ...masterTypenames.flatMap((g: any) => g),
        ...groupTypenames.flatMap((g: any) => g),
      ];
    case targetingParameterTypesEnum.GENRE:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'genres'
      );
    case targetingParameterTypesEnum.ITUNES_CATEGORY:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'categories'
      );
    case targetingParameterTypesEnum.PODCAST:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'podcastTargets'
      );
    case targetingParameterTypesEnum.AD_POSITION:
      return combineNestedValues(
        masterTypenames,
        groupTypenames,
        'positions',
        'position',
        'midRollPositions'
      );
    case targetingParameterTypesEnum.ISP:
      return combineMultiSelectParams(masterTypenames, groupTypenames, 'isps');
    case targetingParameterTypesEnum.CONNECTION_TYPE:
      return combineMultiSelectParams(
        masterTypenames,
        groupTypenames,
        'connectionTypes'
      );
    default:
      break;
  }
};

export const sortTargetingGroups = (
  groups: ITargetingGroup[],
  masterTemplates: ITargetingTemplate[],
  general: ITargetingGroup | null
) => {
  const unLinkedTargetingGroups = groups
    .filter((g: ITargetingGroup) => !g.masterTargetingGroupId)
    .map((g: ITargetingGroup) => ({
      ...g,
      id: g.id,
      channels: g.channels
        ? g.channels.map((c: any) => ({ ...c, readOnly: false }))
        : [],
      audienceParams: g.audienceParams.flatMap((a: any) =>
        addReadOnlyValues(a, [], a.__typename)
      ),
      listenerAccuracy: {
        value: g.listenerAccuracy,
        readOnly: false,
        readOnlyType: false,
        edited: false,
      },
    }));

  const filteredTargetingGroups = groups.filter(
    (g: ITargetingGroup) => g.masterTargetingGroupId
  );

  const generalTargetingGroup = general && {
    ...general,
    audienceParams: general.audienceParams.flatMap((a: any) =>
      addReadOnlyValues(a, [], a.__typename)
    ),
    listenerAccuracy: {
      value: general.listenerAccuracy,
      readOnly: false,
      readOnlyType: false,
      edited: false,
    },
  };

  const masterCombinedTargetingGroups = masterTemplates.flatMap(
    (mt: ITargetingTemplate) =>
      mt.targetingGroups.flatMap((mg: ITargetingGroup) => {
        const tg: ITargetingGroup[] = flatten(
          filteredTargetingGroups.filter(
            (ftg: ITargetingGroup) => ftg.masterTargetingGroupId === mg.id
          )
        );

        const masterTypenames = groupBy(mg.audienceParams, '__typename');
        const groupTypenames = groupBy(
          tg.flatMap((t: any) => t.audienceParams),
          '__typename'
        );

        const typeNames = uniq([
          ...Object.keys(masterTypenames),
          ...Object.keys(groupTypenames),
        ]);

        const combinedParams = typeNames.flatMap((type: any) =>
          combineValues(masterTypenames[type], groupTypenames[type], type)
        );

        const readOnlyParams = combinedParams.flatMap((p: any) =>
          addReadOnlyValues(p, masterTypenames[p.__typename], p.__typename)
        );

        return {
          masterTemplateId: mt.id,
          masterName: mt.name,
          masterTargetingGroupId: mg.id,
          targetingGroupId: tg.length ? tg[0].id : '',
          id: mg.id,
          channels: [
            ...(mg.channels && mg.channels.length
              ? mg.channels.map((c: any) => ({ ...c, readOnly: true }))
              : []),
            ...tg.flatMap(
              (t: ITargetingGroup) =>
                t.channels &&
                t.channels.map((p: any) =>
                  mg.channels && mg.channels.some((c: any) => isEqual(c, p))
                    ? { ...p, readOnly: null }
                    : { ...p, readOnly: false }
                )
            ),
          ].filter((ap) => !isNull(ap.readOnly)),
          audienceParams: readOnlyParams,
          listenerAccuracy: {
            value:
              mg.listenerAccuracy || tg.length
                ? mg.listenerAccuracy || tg[0].listenerAccuracy
                : null,
            readOnly: !isNull(mg.listenerAccuracy),
            readOnlyType: !isNull(mg.listenerAccuracy),
            edited: !!(
              isNull(mg.listenerAccuracy) &&
              tg.length &&
              !isNull(tg[0].listenerAccuracy)
            ),
          },
        };
      })
  );

  return {
    groups: unLinkedTargetingGroups,
    templates: masterCombinedTargetingGroups,
    ...(generalTargetingGroup && { general: generalTargetingGroup }),
  };
};

export const groupTargetingGroups = (targetingDefinition: {
  targetingGroups: ITargetingGroup[];
  masterTargetingTemplates: ITargetingTemplate[];
  generalTargetingGroup?: ITargetingGroup;
}) => {
  const sortedGroups = sortTargetingGroups(
    targetingDefinition && targetingDefinition.targetingGroups.length
      ? targetingDefinition.targetingGroups
      : [],
    targetingDefinition &&
      targetingDefinition.masterTargetingTemplates &&
      targetingDefinition.masterTargetingTemplates.length
      ? targetingDefinition.masterTargetingTemplates
      : [],
    targetingDefinition && targetingDefinition.generalTargetingGroup
      ? targetingDefinition.generalTargetingGroup
      : null
  );

  const masterTemplates = Object.entries(
    groupBy(sortedGroups.templates, 'masterTemplateId')
  ).map(([key, value]: [string, any]) => {
    const edited = value.some(
      (v: any) =>
        v.audienceParams.some((p: any) => p.edited) ||
        v.channels.some((p: any) => !p.readOnly) ||
        v.listenerAccuracy.edited
    );

    return {
      masterId: key,
      masterName: value[0].masterName,
      edited,
      groups: formatInitialTargetingGroups(value),
    };
  });

  return {
    groups: formatInitialTargetingGroups(sortedGroups.groups),
    templates: masterTemplates,
    general: sortedGroups.general
      ? formatInitialTargetingGroup(sortedGroups.general)
      : null,
  };
};

export const getTargetingDefinitionForCreate = (
  formValues: any,
  formattedValues: any
) => {
  if (formValues.targetingVersion === TargetingVersion.TargetingV1)
    return {
      targetingDefinition: {
        masterTargetingTemplateIds: getMasterTargetingTemplateIds(
          formValues.targetingMasterTemplates
        ),
        targetingGroups: [
          ...formattedValues.targetingMasterTemplates,
          ...formattedValues.targetingGroups,
        ],
        generalTargetingGroup: formattedValues.targetingGeneralGroup
          ? formattedValues.targetingGeneralGroup
          : { audienceParams: {}, channelIds: [] },
      },
      targetingDefinitionV2: null,
    };

  return {
    targetingDefinitionV2: getTargetingDefinitionV2Attributes(formValues),
    targetingDefinition: null,
  };
};

export const getTargetingDefinitionForUpdate = (formValues: any) => {
  if (formValues.targetingVersion === TargetingVersion.TargetingV1)
    return {
      targetingDefinition: {
        masterTargetingTemplateIds: getMasterTargetingTemplateIds(
          formValues.targetingMasterTemplates
        ),
        targetingGroups: [
          ...formatTargetingGroups(formValues.targetingGroups),
          ...formatTargetingGroups(
            filterMasterValues(formValues.targetingMasterTemplates)
          ),
        ],
        generalTargetingGroup: formValues.targetingGeneralGroup
          ? formatTargetingGroup(formValues.targetingGeneralGroup)
          : { audienceParams: {}, channelIds: [], listenerAccuracy: null },
      },
      targetingDefinitionV2: null,
    };

  return {
    targetingDefinitionV2: getTargetingDefinitionV2Attributes(formValues),
    targetingDefinition: null,
  };
};

export const formatTargeting = (
  targetingDefinition: TargetingDefinition | undefined
) =>
  targetingDefinition
    ? groupTargetingGroups(targetingDefinition as ITargetingDefinition)
    : { groups: [], templates: [], general: null };

export default {
  getMasterTargetingTemplateIds,
  getTargetingFieldArrayName,
  getTargetingValues,
  getTargetingPath,
  matchAudienceParams,
  formatAudienceParams,
  filterAudienceParams,
  formatTargetingGroups,
  formatUpdateAudienceParams,
  formatInitialAudienceParams,
  formatInitialTargetingGroups,
  sortTargetingGroups,
  groupTargetingGroups,
  filterMasterValues,
  formatTargeting,
};
