import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0, 1.25),
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  tableCell: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2, 1, 0),
  },
  columnHeadings: {
    padding: 0,
    border: 'none',
    '&:first-child': {
      width: '25px',
    },
  },
  columnHeadingButton: {
    border: 'none',
    color: colors.primaryBlue,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    width: '100%',
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  rowHeadings: {
    backgroundColor: theme.palette.grey[100],
    border: 'none',
    fontSize: '10px',
    '&:first-child': {
      padding: 0,
    },
  },
  rowHeadingButton: {
    border: 'none',
    color: theme.palette.common.black,
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400,
    width: '100%',
    textAlign: 'right',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
    },
    '&--selected': {
      color: colors.primaryBlue,
    },
  },
  footer: {
    padding: theme.spacing(1, 0, 2, 0),
  },
}));

export default useStyles;
