import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import PublisherContractForm, { IPublisherContractValues } from 'features/inventory/publisher/components/PublisherContractForm/PublisherContractForm';
import PublisherContractFormValidation from 'features/inventory/publisher/validations/publisherContracts';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import useError from 'hooks/Error/useError';
import { IFormValues } from 'interfaces';
import {
  PublisherContract,
  UpdatePublisherContractMutationFn,
  useUpdatePublisherContractMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React from 'react';
import { getContractTerritory, getContractTimezone } from 'utils/contracts';
import { differenceInValues, handleFormErrors } from 'utils/forms';
import { calculatePercentage, numbroDecimalFormatting } from 'utils/numbers';
import { getPublisherCategoryOptions } from 'utils/publisherCategories';

export interface IFormattedPublisherContract
  extends Omit<PublisherContract, 'startDate' | 'endDate'> {
  startDate: Date;
  endDate: Date | null;
}

export interface IUpdatePublisherContractContainerProps {
  publisherContract: IFormattedPublisherContract;
  handleClose: () => void;
  refetch: () => void;
  readOnly?: boolean;
  hasEditPermissions?: boolean;
}

export const submitUpdatePublisherContract =
  (
    updatePublisherContract: UpdatePublisherContractMutationFn,
    initialValues: IPublisherContractValues,
    toggleErrorModal: () => void,
    publisherContract: PublisherContract
  ) =>
  (
    formValues: IPublisherContractValues,
    { setStatus, setSubmitting }: FormikActions<IPublisherContractValues>
  ) => {
    const filteredValues: any = {
      ...differenceInValues(formValues, initialValues),
      id: publisherContract.id,
    } as IFormValues;

    delete filteredValues.timeZone;

    updatePublisherContract({
      variables: filteredValues,
    }).catch((error) =>
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      })
    );
  };

const UpdatePublisherContractContainer = ({
  publisherContract,
  handleClose,
  refetch,
  readOnly = false,
  hasEditPermissions = false,
}: IUpdatePublisherContractContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updatePublisherContract] = useUpdatePublisherContractMutation({
    onCompleted() {
      refetch();
      handleClose();
    },
  });

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Contract.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const timeZone = getContractTimezone(publisherContract, activeTerritory!);
  const contractTerritory = getContractTerritory(
    publisherContract,
    activeTerritory!
  );

  const initialValues: IPublisherContractValues = {
    id: publisherContract.id,
    startDate: publisherContract.startDate,
    endDate: publisherContract.endDate,
    timeZone,
    publisherCategories: getPublisherCategoryOptions(
      publisherContract.publisherCategories || []
    ),
    currency: publisherContract.currency,
    directSpotCpm:
      typeof publisherContract.directSpotCpm === 'number'
        ? numbro(publisherContract.directSpotCpm).format(
            numbroDecimalFormatting
          )
        : '',
    programmaticSpotCpm:
      typeof publisherContract.programmaticSpotCpm === 'number'
        ? numbro(publisherContract.programmaticSpotCpm).format(
            numbroDecimalFormatting
          )
        : '',
    spotRevenueShare:
      typeof publisherContract.spotRevenueShare === 'number'
        ? calculatePercentage(publisherContract.spotRevenueShare, 100)
        : '',
    hostReadRevenueShare:
      typeof publisherContract.hostReadRevenueShare === 'number'
        ? calculatePercentage(publisherContract.hostReadRevenueShare, 100)
        : '',
    hostReadCpm:
      typeof publisherContract.hostReadCpm === 'number'
        ? numbro(publisherContract.hostReadCpm).format(numbroDecimalFormatting)
        : '',
    sponsorshipRevenueShare:
      typeof publisherContract.sponsorshipRevenueShare === 'number'
        ? calculatePercentage(publisherContract.sponsorshipRevenueShare, 100)
        : '',
    minInventoryGuaranteed:
      typeof publisherContract.minInventoryGuaranteed === 'number'
        ? numbro(publisherContract.minInventoryGuaranteed).format(
            numbroDecimalFormatting
          )
        : '',
    maxInventoryGuaranteed:
      typeof publisherContract.maxInventoryGuaranteed === 'number'
        ? numbro(publisherContract.maxInventoryGuaranteed).format(
            numbroDecimalFormatting
          )
        : '',
    percentFillGuaranteed:
      typeof publisherContract.percentFillGuaranteed === 'number'
        ? calculatePercentage(publisherContract.percentFillGuaranteed, 100)
        : '',
    minRevenueGuaranteed:
      typeof publisherContract.minRevenueGuaranteed === 'number'
        ? numbro(publisherContract.minRevenueGuaranteed).format(
            numbroDecimalFormatting
          )
        : '',
    minRevenueIdeal:
      typeof publisherContract.minRevenueIdeal === 'number'
        ? numbro(publisherContract.minRevenueIdeal).format(
            numbroDecimalFormatting
          )
        : '',
    notes: publisherContract.notes || '',
  };

  return (
    <>
      <Formik<IPublisherContractValues>
        initialValues={initialValues}
        onSubmit={submitUpdatePublisherContract(
          updatePublisherContract,
          initialValues,
          toggleErrorModal,
          publisherContract
        )}
        validationSchema={PublisherContractFormValidation(contractTerritory)}
      >
        {(props) => (
          <PublisherContractForm
            {...props}
            handleClose={handleClose}
            readOnly={readOnly}
            update
            hasEditPermissions={hasEditPermissions}
            testId="updatePublisherContractForm"
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => handleClose()}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updatePublisherContractError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default UpdatePublisherContractContainer;
