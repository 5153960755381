export enum CloneTypeEnum {
  ORDER = 'ORDER',
  CAMPAIGN = 'CAMPAIGN',
  AD = 'AD',
  DEAL = 'DEAL',
}

export const cloneTypeValues = [
  {
    value: CloneTypeEnum.ORDER,
    label: 'Order',
  },
  {
    value: CloneTypeEnum.CAMPAIGN,
    label: 'Campaign',
  },
  {
    value: CloneTypeEnum.AD,
    label: 'Ad',
  },
  {
    value: CloneTypeEnum.DEAL,
    label: 'Deal',
  },
];

export const cloneChildrenText = [
  {
    value: CloneTypeEnum.ORDER,
    label: 'Also clone Campaign(s) & Ad(s)',
  },
  {
    value: CloneTypeEnum.CAMPAIGN,
    label: 'Also clone Ad(s)',
  },
  {
    value: CloneTypeEnum.AD,
    label: '',
  },
  {
    value: CloneTypeEnum.DEAL,
    label: '',
  },
];
