import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import React from 'react';
import {
  Row,
  TableInstance,
  Hooks,
  TableToggleAllRowsSelectedProps,
} from 'react-table';
import { ISetErrorModal } from 'components/ErrorDialog/ErrorDialog';
import {
  handleWarning,
  ISetWarningModal,
} from 'components/WarningDialog/WarningDialog';

import {
  CreativeType,
  EntityStatus,
  EntityType,
} from 'interfaces/generated.types';

import { GraphqlErrorCodes } from './errors';

export const handleTableErrors = ({
  error,
  setModal,
  content,
  handleContinue,
  errorMessages = ['Something went wrong. Please try again later'],
}: {
  error: any;
  setModal: (values: ISetErrorModal) => void;
  content: { title: string; closeButton: string; continueButton: string };
  handleContinue?: () => void;
  errorMessages?: string[];
}) => {
  const extensions = get(error, 'graphQLErrors[0].extensions.code', '');
  if (extensions === GraphqlErrorCodes.BAD_USER_INPUT) {
    const validationErrors = get(
      error,
      'graphQLErrors[0].extensions.exception.validationErrors',
      {}
    );
    if (!isEmpty(validationErrors)) {
      const errors = reduce(
        validationErrors,
        (result: string[], value: string[]) => [...result, value[0]],
        []
      );
      setModal({ isOpened: true, errors, content, handleContinue });
      return;
    }
  }

  setModal({
    isOpened: true,
    errors: errorMessages,
    handleContinue: undefined,
    content,
  });
};

export const handleCellClone = async ({
  clone,
  variables,
  handleContinue,
  handleSuccess,
  setErrorModal,
  errorModalContent,
}: {
  clone: any;
  variables: any;
  handleSuccess: (data: any) => void;
  handleContinue?: () => void;
  setErrorModal: (values: ISetErrorModal) => void;
  errorModalContent: {
    title: string;
    closeButton: string;
    continueButton: string;
  };
}) => {
  try {
    const { data } = await clone({
      variables,
    });
    handleSuccess(data);
  } catch (error) {
    handleTableErrors({
      error,
      setModal: setErrorModal,
      handleContinue,
      content: errorModalContent,
    });
  }
};

export const handleCellDelete = async ({
  deleteCell,
  variables,
  setErrorModal,
  handleContinue,
  errorModalContent,
}: {
  deleteCell: any;
  variables: any;
  setErrorModal: (values: ISetErrorModal) => void;
  handleContinue?: () => void;
  errorModalContent: {
    title: string;
    closeButton: string;
    continueButton: string;
  };
}) => {
  try {
    await deleteCell({
      variables,
    });
  } catch (error) {
    handleTableErrors({
      error,
      setModal: setErrorModal,
      handleContinue,
      content: errorModalContent,
    });
  }
};

export const handleCellUpdate = async ({
  update,
  variables,
  handleContinue,
  handleSuccess,
  errorModalContent,
  setErrorModal,
  setUpdating,
}: {
  update: any;
  variables: any;
  setErrorModal: (values: ISetErrorModal) => void;
  handleContinue?: () => void;
  handleSuccess?: () => void;
  errorModalContent: {
    title: string;
    closeButton: string;
    continueButton: string;
  };
  setUpdating: any;
}) => {
  try {
    setUpdating(true);
    await update({
      variables,
    });
    setUpdating(false);
    if (handleSuccess) handleSuccess();
  } catch (error) {
    handleTableErrors({
      error,
      setModal: setErrorModal,
      handleContinue,
      content: errorModalContent,
    });
    setUpdating(false);
  }
};

export const handleCellValidate = async ({
  validate,
  entity,
  setWarningModal,
  setErrorModal,
  setUpdating,
  handleContinue,
}: {
  validate: any;
  entity: {
    id: string;
    type: EntityType | CreativeType;
    targetStatus: EntityStatus;
  };
  setWarningModal: (values: ISetWarningModal) => void;
  setErrorModal: any;
  setUpdating: (value: boolean) => void;
  handleContinue: () => void;
}) => {
  try {
    setUpdating(true);
    const { data } = await validate({
      targetStatus: entity.targetStatus,
      entityAttributes: {
        id: entity.id,
        entityType: entity.type,
      },
    });
    setUpdating(false);
    if (data.allAffectedEntities.length) {
      handleWarning({
        setWarningModal,
        handleContinue,
      });
    } else {
      handleContinue();
    }
  } catch (error) {
    const content = {
      title: 'Error',
      closeButton: 'Close',
      continueButton: 'Continue',
    };
    handleTableErrors({
      error,
      setModal: setErrorModal,
      content,
    });
    setUpdating(false);
  }
};

export const handleCellResendEmail = async ({
  resend,
  setMessageModal,
  setErrorModal,
  handleContinue,
  variables,
  errorModalContent,
  setUpdating,
}: {
  resend: any;
  setMessageModal: (value: { hasMessage: boolean }) => void;
  setErrorModal: (values: ISetErrorModal) => void;
  handleContinue?: () => void;
  errorModalContent: {
    title: string;
    closeButton: string;
    continueButton: string;
  };
  variables: any;
  setUpdating: (value: boolean) => void;
}) => {
  try {
    setUpdating(true);
    await resend({
      variables,
    });
    setUpdating(false);
    setMessageModal({
      hasMessage: true,
    });
  } catch (error) {
    const errorMessages = [
      `There has been an error in resending the confirmation email, please check whether the user has already signed up`,
      'NOTE: Global server outage may have caused this error, please check with global support if this error persists',
    ];
    handleTableErrors({
      error,
      setModal: setErrorModal,
      handleContinue,
      content: errorModalContent,
      errorMessages,
    });
    setUpdating(false);
  }
};

export const customGetToggleAllPageRowsSelectedProps = (
  hooks: Hooks<Row<Record<string, unknown>>>
) => {
  const getToggleAllPageRowsSelectedProps = (
    props: Partial<TableToggleAllRowsSelectedProps>,
    { instance }: { instance: TableInstance<Record<string, unknown>> }
  ) => [
    props,
    {
      onChange: () => {
        instance.page
          .filter((row) => !row.original.disabled)
          .forEach((row) =>
            row.toggleRowSelected(
              !instance.page
                .filter((selRow) => !selRow.original.disabled)
                .every((selRow) => selRow.isSelected)
            )
          );
      },
      style: { cursor: 'pointer' },
      checked: instance.page
        .filter((row) => !row.original.disabled)
        .every((row) => row.isSelected),
      title: 'Toggle All Rows Selected',
      'data-tc': 'tableRowsToggle',
      indeterminate: Boolean(
        !instance.isAllPageRowsSelected &&
          Object.keys(instance.state.selectedRowIds).length
      ),
    },
  ];
  // eslint-disable-next-line
  hooks.getToggleAllPageRowsSelectedProps = [
    getToggleAllPageRowsSelectedProps as any,
  ];

  return hooks.getToggleAllPageRowsSelectedProps;
};

export const customGetToggleRowSelectedProps = (
  hooks: Hooks<Row<Record<string, unknown>>>
) => {
  const getToggleRowSelectedProps = (
    props: Partial<TableToggleAllRowsSelectedProps>,
    {
      row: { original, isSelected, toggleRowSelected, isSomeSelected },
      instance,
    }: {
      row: Row<Record<string, unknown>>;
      instance: TableInstance<Row<Record<string, unknown>>>;
    }
  ) => {
    const { manualRowSelectedKey = 'isSelected' } = instance;
    let checked = false;

    if (original && original[manualRowSelectedKey]) {
      checked = true;
    } else {
      checked = isSelected;
    }
    return [
      props,
      {
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          toggleRowSelected(e.target.checked);
        },
        style: {
          cursor: 'pointer',
        },
        checked,
        title: 'Toggle Row Selected',
        'data-tc': 'tableRowToggle',
        indeterminate: isSomeSelected,
        disabled: original.disabled,
      },
    ];
  };
  // eslint-disable-next-line
  hooks.getToggleRowSelectedProps = [getToggleRowSelectedProps as any];
  return hooks.getToggleRowSelectedProps;
};
