import { OptionType } from 'interfaces';
import { TargetingRestriction } from 'interfaces/generated.types';
import React from 'react';
import { getTargetingRestrictionsOptions } from 'utils/targetingRestrictions';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';

import useStyles from './TargetingRestrictions.styles';

interface ITargetingRestrictions {
  title: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  selectedRestrictions: TargetingRestriction[] | string[];
  restrictionsList?: OptionType[];
  className?: string;
}

const targetingRestrictionsOptions = getTargetingRestrictionsOptions();

const TargetingRestrictions = ({
  title,
  restrictionsList = targetingRestrictionsOptions,
  handleChange,
  selectedRestrictions,
  className,
}: ITargetingRestrictions) => {
  const classes = useStyles();
  return (
    <FormControl component={'fieldset' as 'div'} className={className}>
      <FormLabel
        classes={{ root: classes.label }}
        component={'legend' as 'span'}
      >
        {title}
      </FormLabel>
      <FormGroup row>
        {restrictionsList.map((restriction) => (
          <FormControlLabel
            key={restriction.value}
            control={
              <Checkbox
                checked={selectedRestrictions.includes(
                  restriction.value as TargetingRestriction
                )}
                color="primary"
                onChange={handleChange}
                value={restriction.value}
              />
            }
            label={restriction.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default TargetingRestrictions;
