import { makeStyles, Theme } from '@material-ui/core/styles';

import { basePageStyles } from 'assets/styles/components/Page.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  displayPopover: {
    pointerEvents: 'none',
  },
  displayThumbnail: {
    maxWidth: '50px',
    display: 'inline-block',
  },
  displayPaper: {
    borderRadius: 0,
    padding: theme.spacing(0.5),
  },
  displayImage: {
    maxWidth: '100%',
    display: 'block',
  },
  displayWrapper: {
    display: 'inline-block',
  },
}));

export default useStyles;
