import {
  AdditionalEditingFunctionArgs,
  MenuListTitleEnum,
  NodeLike,
  ValueIdPrefixEnum,
} from 'features/targetingV2/types/common';

import { convertArrayToObject, getToggledNode, removeNode } from './common';

export const getGeoSelectedNodesPerList = (
  nodes: NodeLike[]
): { firstList: NodeLike[]; secondList: NodeLike[] } => {
  const firstListNodes = nodes.filter((selectedNode) =>
    selectedNode.value.startsWith(ValueIdPrefixEnum.Country)
  );
  const secondListNodes = nodes.filter((selectedNode) =>
    selectedNode.value.startsWith(ValueIdPrefixEnum.RegionPack)
  );

  return { firstList: firstListNodes, secondList: secondListNodes };
};

export const handleAllLocationsNodeToggle = (
  newSelectedNodes: NodeLike[],
  allPreviouslySelectedNodes: NodeLike[]
) => {
  const newSelectedNodesObject = convertArrayToObject(
    newSelectedNodes,
    'value'
  );

  const allPreviouslySelectedNodesObject = convertArrayToObject(
    allPreviouslySelectedNodes,
    'value'
  );

  const previouslySelectedNodesToKeep = allPreviouslySelectedNodes.filter(
    (previouslySelectedNode) => {
      const isNodeInNewSelectedNodes =
        !!newSelectedNodesObject[previouslySelectedNode.value];

      return (
        previouslySelectedNode.value.startsWith(ValueIdPrefixEnum.RegionPack) ||
        isNodeInNewSelectedNodes
      );
    }
  );

  const nodesToAdd = newSelectedNodes.filter(
    (newSelectedNode) =>
      !allPreviouslySelectedNodesObject[newSelectedNode.value]
  );

  return previouslySelectedNodesToKeep.concat(nodesToAdd);
};

export const handleRegionPacksNodeToggle = (
  newSelectedNodes: NodeLike[],
  selectedRegionPackNodes: NodeLike[],
  allPreviouslySelectedNodes: NodeLike[]
) => {
  const deselectedNode = getToggledNode(
    newSelectedNodes,
    selectedRegionPackNodes
  );

  if (deselectedNode)
    return removeNode(allPreviouslySelectedNodes, deselectedNode);

  const selectedNode = getToggledNode(
    selectedRegionPackNodes,
    newSelectedNodes
  );

  if (selectedNode) return allPreviouslySelectedNodes.concat(selectedNode);

  return allPreviouslySelectedNodes;
};

export const handleGeoNodeToggle = ({
  newSelectedNodes,
  menuList,
  allPreviouslySelectedNodes,
}: AdditionalEditingFunctionArgs) => {
  if (menuList.title === MenuListTitleEnum.RegionPacks) {
    return handleRegionPacksNodeToggle(
      newSelectedNodes,
      menuList.selectedNodes,
      allPreviouslySelectedNodes || []
    );
  }

  return handleAllLocationsNodeToggle(
    newSelectedNodes,
    allPreviouslySelectedNodes || []
  );
};
