import classNames from 'classnames';
import React from 'react';

import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';

import useStyles from './Breadcrumbs.styles';
import BreadcrumbsBrackets from './BreadcrumbsBrackets';
import BreadcrumbText from './BreadcrumbText';

export interface IBreadcrumbNode {
  id: string;
  label: string;
}
export interface IBreadcrumbsProps {
  nodes: IBreadcrumbNode[];
  classes?: {
    container?: string;
    text?: string;
  };
  identifier: string;
}

const Breadcrumbs = (props: IBreadcrumbsProps) => {
  const { nodes, classes: externalClasses = {}, identifier } = props;
  const classes = useStyles();

  return (
    <div className={classNames([classes.container, externalClasses.container])}>
      <BreadcrumbsBrackets>
        <MUIBreadcrumbs
          aria-label={`breadcrumbs-${identifier}`}
          separator=">"
          className={classes.breadcrumbs}
          classes={{
            separator: classes.separator,
          }}
        >
          {nodes.map((node) => (
            <BreadcrumbText
              key={node.id}
              text={node.label}
              classes={{ text: externalClasses.text }}
            />
          ))}
        </MUIBreadcrumbs>
      </BreadcrumbsBrackets>
    </div>
  );
};

export default Breadcrumbs;
