import React, { useState } from 'react';

import primaryControlsStyles from 'assets/styles/components/PrimaryControls.styles';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import PrimaryControlDialog from 'features/primaryControls/components/PrimaryControlDialog/PrimaryControlDialog';

import PrintedBlockList from 'features/primaryControls/components/PrintedBlockList/PrintedBlockList';
import { getTerritoryIcon, TerritoryLabelShort } from 'utils/territory';
import { PrimaryControlEntry, Territory } from 'interfaces/generated.types';

interface IPrimaryControl {
  refetch: () => void;
  hasError: boolean;
  content: any;
  entries: PrimaryControlEntry[];
}

const PrimaryControlContainer = ({
  refetch,
  hasError,
  entries,
  content,
}: IPrimaryControl) => {
  const [isOpen, setModalOpened] = useState(false);
  const classes = primaryControlsStyles({});
  const closeModal = () => setModalOpened(false);

  const frozenTerritories = entries
    .filter((entry) => entry.enabled)
    .map((entry) => entry.territory);

  const printedBlockListContent = frozenTerritories.map(
    (territory: Territory) => (
      <span className={classes.territory} key={territory}>
        <span className={classes.territoryIcon}>
          {getTerritoryIcon(territory)}
        </span>
        {TerritoryLabelShort[territory]}
      </span>
    )
  );

  if (hasError) {
    return (
      <div className={classes.container}>
        <h2 className={classes.title}>{content.error.title}</h2>
        <div>
          <p>{content.error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div data-testid={`${content.dialog.entity}PrimaryControlContainer`}>
      <h2 className={classes.title}>{content.container.title}</h2>
      <div className={classes.container}>
        <p>{content.container.message}</p>
        <p className={classes.note}>{content.container.note}</p>
        <div className={classes.actions} data-testid="openPrimaryControlModal">
          <StyledButton
            data-tc="openPrimaryControlModal"
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            onClick={() => setModalOpened(!isOpen)}
          >
            {content.container.action}
          </StyledButton>
        </div>
        {frozenTerritories.length > 0 && (
          <PrintedBlockList
            label={content.printedBlockListLabel}
            content={printedBlockListContent}
            testId={`${content.dialog.entity}currentBlockList`}
          />
        )}
      </div>
      <PrimaryControlDialog
        isOpen={isOpen}
        content={content.dialog}
        frozenTerritories={frozenTerritories}
        handleClose={closeModal}
        refetch={refetch}
      />
    </div>
  );
};

export default PrimaryControlContainer;
