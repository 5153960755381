import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import TargetingRestrictions from 'components/TargetingRestrictions/TargetingRestrictions';
import { ITargetingDefinitionV2 } from 'features/targetingV2/types/targeting';
import { IFormProps } from 'interfaces';
import {
  TargetingRestriction,
  TargetingVersion,
} from 'interfaces/generated.types';
import React from 'react';
import dateUtils, { TimeZones } from 'utils/date';
import {
  ITargetingGroup,
  ITargetingMasterTemplate,
} from 'utils/targetingDefinitions';
import { setTargetingRestrictions } from 'utils/targetingRestrictions';

import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

import useStyles from './ForecastingDetails.styles';

export interface IForecastingValues {
  timeZone: TimeZones;
  startDate: Date | null;
  endDate: Date | null;
  iabCategoryCodes: string[];
  duration: string;
  targetingGroups: ITargetingGroup[];
  targetingMasterTemplates: ITargetingMasterTemplate[];
  targetingGeneralGroup: ITargetingGroup | null;
  objective: string;
  targetingVersion: TargetingVersion;
  targetingDefinitionV2: ITargetingDefinitionV2 | null;
  targetingRestrictions: TargetingRestriction[];
}

const ForecastingDetails = (props: IFormProps<IForecastingValues>) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = props;

  const classes = useStyles();

  const handleCampaignRestrictionChange = ({
    target: { value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setTargetingRestrictions(
      value as TargetingRestriction,
      checked,
      setFieldValue,
      values.targetingRestrictions
    );
  };

  return (
    <fieldset className={classes.fieldset} data-testid="forecastDetails">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <NumberInput
            name="duration"
            label="Creative Duration (Seconds)"
            value={values.duration}
            allowNegative={false}
            dataTc="duration"
            decimalScale={3}
            thousandSeparator={ThousandSeparatorTypes.comma}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.duration}
            error={errors.duration}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="objective"
            dataTestId="objective"
            decimalScale={0}
            label="Objective Impressions"
            name="objective"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.objective}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={values.startDate}
              label="Start Date"
              name="startDate"
              onChange={(value) => setFieldValue('startDate', value)}
              onClose={() => setFieldTouched('startDate', true, false)}
              data-tc="startDate"
              disablePast
            />
            {errors.startDate && (
              <FormHelperText error data-tc="startDateError">
                {errors.startDate}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={values.endDate}
              label="End Date"
              name="endDate"
              onChange={(value) => {
                setFieldValue('endDate', value);
              }}
              onClose={() => {
                setFieldTouched('endDate', true, false);
              }}
              data-tc="endDate"
              disablePast
            />
            {touched.endDate && errors.endDate && (
              <FormHelperText error data-tc="endDateError">
                {errors.endDate}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <TargetingRestrictions
            title="Campaign Restriction (only applicable for Targeting 2.0)"
            handleChange={handleCampaignRestrictionChange}
            className={classes.campaignRestrictionsWrapper}
            selectedRestrictions={values.targetingRestrictions}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default ForecastingDetails;
