import { FormikProps } from 'formik';
import { TargetingGrade } from 'interfaces/generated.types';

export interface IOption<T> {
  label: string;
  value: T;
}

export interface IOptionType extends IOption<string> {
  label: string;
  value: string;
  readOnly?: boolean;
}

export interface IOptionGroupType {
  label: string;
  options: IOptionType[];
}

export interface IAncestorType {
  id: string;
  label: string;
}

export interface ITargetingV2ParameterValue {
  id: string;
  name: string;
  children?: ITargetingV2ParameterValue[];
  parents?: ITargetingV2ParameterValue[];
}

export interface IParameterValueOption extends IOptionType {
  active?: boolean;
  children?: IParameterValueOption[];
  ancestorList?: IAncestorType[];
  activeGrades?: TargetingGrade[];
  state?: ChipState;
  tooltip?: string | string[];
}

export interface IFormProps<P> extends FormikProps<P> {
  update?: boolean;
  classes?: any;
}

export enum SectionType {
  Audience = 'AUDIENCE',
  Location = 'LOCATION',
  Custom = 'CUSTOM',
  Inventory = 'INVENTORY',
  Technology = 'TECHNOLOGY',
  DayTime = 'DAYTIME',
  AdRequestEnrichmentAudience = 'AD_REQUEST_ENRICHMENT_AUDIENCE',
}

export interface ISliderValue {
  index: number;
  value: number | string;
}

export interface ICustomKvp {
  key: string;
  value: string;
}
export interface ICustomKvpOption extends IParameterValueOption {
  keyValuePair: ICustomKvp;
}

export interface ICustomKvpError {
  keyValuePair: ICustomKvp;
}

export enum ChipState {
  DEFAULT = 'default',
  WARNING = 'warning',
  INACTIVE = 'inactive',
}

export enum MenuListTitleEnum {
  AllLocations = 'All Locations',
  RegionPacks = 'Region Packs',
  PopularInterests = 'Popular Interests',
  AllInterests = 'Browse All Interests',
}

export enum ValueIdPrefixEnum {
  Country = 'COUNTRY:',
  RegionPack = 'RPACK:',
}

export interface NodeLike {
  value: string;
  label: string;
  children?: NodeLike[];
  [key: string]: any;
}

export interface IMenuListProps {
  options: IOptionType[];
  selectedNodes: NodeLike[];
  title?: string;
  displayAncestorPath?: boolean;
}

export interface AdditionalEditingFunctionArgs {
  newSelectedNodes: NodeLike[];
  menuList: IMenuListProps;
  otherMenuList?: IMenuListProps;
  allPreviouslySelectedNodes?: NodeLike[];
}

export interface IMenuDualListProps {
  firstList: IMenuListProps;
  secondList: IMenuListProps;
  additionalEditingFunction?: (args: AdditionalEditingFunctionArgs) => void;
  allPreviouslySelectedNodes?: NodeLike[];
}
