import { CustomApolloState } from './state';

export const UPDATE_ASSUMED_USER = 'UPDATE_ASSUMED_USER';
export const COMPLETE_USER_UPDATE_WITH_ASSUMED_USER_DETAILS =
  'COMPLETE_USER_UPDATE_WITH_ASSUMED_USER_DETAILS';

const Reducer = (state: CustomApolloState, action: any) => {
  switch (action.type) {
    case UPDATE_ASSUMED_USER:
      return {
        assumedId: action.payload.id,
        assumedEmail: action.payload.email,
        updatingUserWithAssumedDetails: true,
      };
    case COMPLETE_USER_UPDATE_WITH_ASSUMED_USER_DETAILS:
      return {
        ...state,
        updatingUserWithAssumedDetails: false,
      };
    default:
      return state;
  }
};

const SessionedReducer = (state: any, action: any) => {
  const newState = Reducer(state, action);

  window.sessionStorage.setItem('assumedUser', JSON.stringify(newState));

  return newState;
};

export default SessionedReducer;
