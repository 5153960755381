import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import LinkedAdsTable from 'features/direct/ad/components/LinkedAdsTable/LinkedAdsTable';
import { History } from 'history';
import { Ad, usePublisherAssociatedAdsQuery } from 'interfaces/generated.types';
import React from 'react';

const PublisherAssociatedAdsTable = ({
  history,
  publisherId,
}: {
  history: History;
  publisherId: string;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = usePublisherAssociatedAdsQuery({
    variables: {
      id: publisherId,
      territories: [activeTerritory!],
    },
    errorPolicy: 'all',
  });

  if (loading) return <Loader />;

  if (!loading && error) return <Redirecting history={history} />;

  return (
    <LinkedAdsTable
      ads={(data?.publisher?.associatedAds as Ad[]) || []}
      history={history}
    />
  );
};

export default PublisherAssociatedAdsTable;
