import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import SalesChannelsTable from 'features/programmatic/salesChannel/components/SalesChannelsTable/SalesChannelsTable';
import { History } from 'history';
import {
  PermissionName,
  SalesChannel,
  useAllSalesChannelsQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface ISalesChannelPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const salesChannelEditPermissions = [
  PermissionName.Primary,
  PermissionName.Programmatic,
];

const SalesChannelPage = ({
  history,
  userPermissions,
}: ISalesChannelPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllSalesChannelsQuery({
    errorPolicy: 'all',
    variables: { territories: [activeTerritory!] },
  });

  const classes = pageStyles();

  if (!loading && error && !(data && data.allSalesChannels))
    return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    salesChannelEditPermissions
  );

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Sales Channels</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <SalesChannelsTable
              history={history}
              allSalesChannels={data?.allSalesChannels as SalesChannel[]}
              isEditable={hasEditPermissions}
              activeTerritory={activeTerritory!}
            />
          )}
        </div>
      </Container>
    </main>
  );
};

export default SalesChannelPage;
