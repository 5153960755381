import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import Audience from 'features/targetingV2/components/AudienceSection/Audience/Audience';
import CustomKvpsSection from 'features/targetingV2/components/CustomKvpsSection/CustomKvpsSection';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import { ICustomKvp, SectionType } from 'features/targetingV2/types/common';
import useError from 'hooks/Error/useError';
import { IFormProps } from 'interfaces';
import { useAllAdRequestEnrichmentAudienceParametersQuery } from 'interfaces/generated.types';
import React, { useEffect } from 'react';
import {
  defaultAdRequestEnrichmentParameter,
  hasAdRequestEnrichmentInvalidParameters,
  hasIncompleteAdRequestEnrichmentCustomKvps,
} from 'utils/adRequestEnrichment';
import { getSelectOptions } from 'utils/dataTransformation';

const AdRequestEnrichment = (props: IFormProps<any>) => {
  const { values, setFieldValue } = props;

  const adRequestEnrichmentAudience = values.adRequestEnrichment?.parameters
    ? { parameters: values.adRequestEnrichment.parameters }
    : { parameters: [] };

  const customKvps = values.adRequestEnrichment.customKvps as ICustomKvp[];

  const {
    loading: loadingAudienceParameters,
    data,
    error,
  } = useAllAdRequestEnrichmentAudienceParametersQuery({
    fetchPolicy: 'cache-first',
  });

  const { hasError, toggleErrorModal, errorMessages, setErrorMessages } =
    useError(['An error has occurred.']);

  const handleError = (err: any) => {
    const errorHeader =
      'Error while loading audience parameters. Please refresh and try again.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const allParametersInfo =
    data?.allAdRequestEnrichmentAudienceParameters || [];

  const optionalAddParameterButton = !adRequestEnrichmentAudience.parameters
    .length && (
    <AddParameterDropdownButton
      options={getSelectOptions(allParametersInfo, 'name', 'type')}
      onOptionClick={(parameterType: string) =>
        setFieldValue('adRequestEnrichment.parameters', [
          {
            ...defaultAdRequestEnrichmentParameter,
            type: parameterType,
            isFocused: true,
          },
        ])
      }
      loading={loadingAudienceParameters}
      testId="add-first-parameter-btn"
    />
  );

  return (
    <div data-testid="adRequestEnrichment">
      <TargetingSection
        sectionType={SectionType.AdRequestEnrichmentAudience}
        headerChildren={optionalAddParameterButton}
        hideTooltip
      >
        {!loadingAudienceParameters && (
          <Audience
            audience={adRequestEnrichmentAudience}
            audienceFieldName="adRequestEnrichment"
            allParametersInfo={allParametersInfo}
            areParameterOptionsLoading={loadingAudienceParameters}
            setFieldValue={setFieldValue}
            emptyParameter={defaultAdRequestEnrichmentParameter}
            disableAddParameterButton={hasAdRequestEnrichmentInvalidParameters(
              adRequestEnrichmentAudience.parameters
            )}
            hideCreateGroupButton
            sectionType={SectionType.AdRequestEnrichmentAudience}
          />
        )}
      </TargetingSection>
      <CustomKvpsSection
        {...props}
        customKvps={customKvps}
        disableAddCustomKvpButton={hasIncompleteAdRequestEnrichmentCustomKvps(
          customKvps
        )}
        sectionFieldName="adRequestEnrichment.customKvps"
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="allAdRequestEnrichmentAudienceParametersError"
        errorMessages={errorMessages}
      />
    </div>
  );
};

export default AdRequestEnrichment;
