import classNames from 'classnames';
import Typography from 'features/targetingV2/components/core/Typography';
import React from 'react';

import useStyles from './Breadcrumbs.styles';

const BreadcrumbsBrackets = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles({});
  return (
    <>
      <Typography
        className={classNames(
          classNames(classes.bracket, `${classes.bracket}--left`)
        )}
      >
        (
      </Typography>
      {children}
      <Typography
        className={classNames(classes.bracket, `${classes.bracket}--right`)}
      >
        )
      </Typography>
    </>
  );
};

export default BreadcrumbsBrackets;
