import { compareAsc, fromUnixTime, parse } from 'date-fns';
import memoizeOne from 'memoize-one';
import { Row } from 'react-table';

const getRowValueByColumnID = (row: Row, columnId: string) =>
  row.values[columnId];

export const parseDate = (date: string) => {
  if (!date) return fromUnixTime(0);
  return parse(date, 'dd/MM/yyyy', new Date());
};

export const dateSort = memoizeOne((rowA: Row, rowB: Row, columnId: string) => {
  const valueA = getRowValueByColumnID(rowA, columnId);
  const valueB = getRowValueByColumnID(rowB, columnId);
  const dateA = parseDate(valueA);
  const dateB = parseDate(valueB);

  return compareAsc(dateA, dateB) as 1 | -1 | 0;
});

export default dateSort;
