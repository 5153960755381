import SearchField from 'components/SearchField/SearchField';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import debounce from 'debounce-promise';
import SEARCH from 'graphql/search/queries';
import {
  SearchResult,
  SearchType,
  Territory,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { getEntityPath, getPathPerEntityType } from 'utils/routing';

export const handleChange = async (
  client: any,
  searchType: SearchType,
  value: string,
  setOptions: any,
  activeTerritory: Territory
) => {
  if (value) {
    const { data } = await client.query({
      query: SEARCH,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          searchType,
          value,
        },
        territories: [activeTerritory],
      },
    });

    setOptions(data.search);
  }
};

export const debouncedHandleChange = debounce(handleChange, 250, {
  leading: true,
});

const GlobalSearch = () => {
  const [searchOptions, setSearchOptions] = useState<Array<SearchResult>>([]);
  const history = useHistory();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const handleSelection = (value: SearchResult) => {
    const basePath = getPathPerEntityType(value.type);
    const optionUrl = getEntityPath(value.id, basePath);

    if (optionUrl) history.push(optionUrl);
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <SearchField
          key="nav-search-field"
          options={searchOptions}
          onChange={(type: SearchType, value: string) =>
            debouncedHandleChange(
              client,
              type,
              value,
              setSearchOptions,
              activeTerritory!
            )
          }
          onSelect={(value: SearchResult) => handleSelection(value)}
        />
      )}
    </ApolloConsumer>
  );
};

export default GlobalSearch;
