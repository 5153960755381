import { makeStyles, Theme } from '@material-ui/core/styles';

export enum LoaderMarginStyles {
  Default = 'default',
  SidesOnly = 'sidesOnly',
}

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    [`&--margin-${LoaderMarginStyles.Default}`]: {
      margin: theme.spacing(2),
    },
    [`&--margin-${LoaderMarginStyles.SidesOnly}`]: {
      margin: theme.spacing(0, 2),
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
