import SplitButton from 'components/SplitButton/SplitButton';
import CampaignsHealthOverviewBar from 'features/direct/campaign/components/CampaignsHealthOverview/CampaignsHealthOverviewBar';
import CampaignsTable from 'features/direct/campaign/components/CampaignsTable/CampaignsTable';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import {
  PermissionName,
  useCloneCampaignMutation,
  useUpdateCampaignMutation,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

import useStyles from './CampaignsPage.styles';

interface ICampaignsPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const CampaignsPage = ({ history, userPermissions }: ICampaignsPageProps) => {
  const [updateCampaign] = useUpdateCampaignMutation();

  const [cloneCampaign] = useCloneCampaignMutation();

  const validateCampaign = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const classes = useStyles();

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  const createCta = hasEditPermissions && (
    <SplitButton
      toggleDataTc="splitButtonToggleCreate"
      buttons={[
        {
          text: 'Create Campaign',
          onClick: () =>
            history.push({
              pathname: '/campaign',
              state: {
                parent: history.location.state,
                from: history.location.pathname,
              },
            }),
          isLoading: false,
          disabled: false,
          dataTc: 'createCampaignButton',
        },
        {
          text: 'Create Order',
          onClick: () =>
            history.push({
              pathname: '/order',
              state: {
                parent: history.location.state,
                from: history.location.pathname,
              },
            }),
          isLoading: false,
          disabled: false,
          dataTc: 'createOrderButton',
        },
      ]}
    />
  );

  return (
    <main className={classes.page}>
      <div className={classes.container}>
        <h1 className="sr-only">Campaigns</h1>
        <div className={classes.table}>
          <CampaignsTable
            title="All Campaigns"
            updateCampaign={updateCampaign}
            cloneCampaign={cloneCampaign}
            validateCampaign={validateCampaign}
            history={history}
            isEditable={hasEditPermissions}
            allowBulkArchiving
            customToolbarCtas={createCta}
            CustomHeader={CampaignsHealthOverviewBar}
            showCustomHeaderOnlyForActiveTab
          />
        </div>
      </div>
    </main>
  );
};

export default CampaignsPage;
