import ContractModal from 'components/Contract/ContractModal';
import Contracts from 'components/Contracts/Contracts';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import ShowContractDialog from 'features/inventory/channel/components/ShowContractDialog/ShowContractDialog';
import { History } from 'history';
import { Channel, useAllShowContractsQuery } from 'interfaces/generated.types';
import React from 'react';
import { formatContracts, getContractTimezone } from 'utils/contracts';

interface IShowContractsProps {
  history: History;
  channel: Channel;
  hasEditPermissions: boolean;
}

const ShowContracts = ({
  history,
  channel,
  hasEditPermissions,
}: IShowContractsProps) => {
  const { loading, error, data, refetch } = useAllShowContractsQuery({
    variables: { channelId: channel.id },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const currentTimezone = getContractTimezone(channel, activeTerritory!);

  const formattedContracts = formatContracts(
    data?.allShowContracts || [],
    currentTimezone
  );

  if (loading) return <Loader />;

  if (!loading && error) return <Redirecting history={history} />;

  const createShowContractDialogProps = {
    channel,
    refetch,
  };

  const updateShowContractDialogProps = {
    refetch,
    update: true,
  };

  return (
    <>
      <ContractModal
        testId={`showContract-${channel.id}`}
        CustomDialog={ShowContractDialog}
        customDialogProps={createShowContractDialogProps}
        buttonLabel="Create Show Contract"
        disabled={!hasEditPermissions}
        hasEditPermissions={hasEditPermissions}
      />
      <Contracts
        contracts={formattedContracts}
        customDialogProps={updateShowContractDialogProps}
        CustomDialog={ShowContractDialog}
        hasEditPermissions={hasEditPermissions}
      />
    </>
  );
};

export default ShowContracts;
