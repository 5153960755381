import { SectionType } from 'features/targetingV2/types/common';
import {
  ICustomParameter,
  ICustomParameterGroup,
  IDayTimeTargeting,
  ILocationParameterGroup,
  IParameter,
  IParameterGroup,
  ITargetingDefinitionV2,
  ITargetingV2Info,
} from 'features/targetingV2/types/targeting';
import { LocationParameterType, Territory } from 'interfaces/generated.types';
import { TerritoryLabelShort } from 'utils/territory';

import { clusivityOptionLabels } from './clusivity';
import { getSectionTitle } from './common';
import { getParametersContainingValidCustomKvps } from './customParameter';
import {
  getAccuracy,
  getDefaultRows,
  getNumberedSectionTitle,
  getParameterTypeName,
  getValueBreadcrumbs,
} from './dataFormatting';
import { formatDayTime } from './dayTime';

export const getCsvRowForParameterValue = (
  value: string,
  valueIndex: number,
  parameter: IParameter,
  parameterIndex: number,
  parameterGroup: IParameterGroup,
  parameterGroupsCount: number,
  parameterGroupIndex: number,
  sectionType: SectionType,
  targetingV2Info: ITargetingV2Info
) => ({
  section:
    parameterIndex === 0 && valueIndex === 0
      ? getNumberedSectionTitle(
          parameterGroupsCount,
          parameterGroupIndex,
          sectionType
        )
      : '',
  parameterType:
    valueIndex === 0
      ? getParameterTypeName(parameter.type, targetingV2Info.allParametersInfo)
      : '',
  clusivity:
    valueIndex === 0 && parameter.clusivity
      ? clusivityOptionLabels[parameter.clusivity]
      : '',
  value,
  accuracy: getAccuracy(
    parameterGroup,
    parameter,
    sectionType,
    targetingV2Info.allLocationAccuracies
  ),
});

export const getCsvRowsFromParameterValues = (
  parameter: IParameter,
  parameterIndex: number,
  parameterGroup: IParameterGroup,
  parameterGroupsCount: number,
  parameterGroupIndex: number,
  sectionType: SectionType,
  targetingV2Info: ITargetingV2Info
) =>
  parameter.values.map((value, valueIndex) =>
    getCsvRowForParameterValue(
      `${value.label}${getValueBreadcrumbs(value.ancestorList)}`,
      valueIndex,
      parameter,
      parameterIndex,
      parameterGroup,
      parameterGroupsCount,
      parameterGroupIndex,
      sectionType,
      targetingV2Info
    )
  );

export const getCsvRowsFromParameter = (
  parameter: IParameter,
  parameterIndex: number,
  parameterGroup: IParameterGroup,
  parameterGroupsCount: number,
  parameterGroupIndex: number,
  sectionType: SectionType,
  targetingV2Info: ITargetingV2Info,
  territory?: Territory
) => {
  if (
    parameter.type === LocationParameterType.Geo &&
    !parameter.values.length &&
    territory
  ) {
    const getSingleRow = (value: string) =>
      getCsvRowForParameterValue(
        value,
        0,
        parameter,
        parameterIndex,
        parameterGroup,
        parameterGroupsCount,
        parameterGroupIndex,
        sectionType,
        targetingV2Info
      );

    if (
      (parameterGroup as ILocationParameterGroup).limitLocationParameterValue
        ?.id
    ) {
      const limitLocationCountryLabel = TerritoryLabelShort[territory];
      return [getSingleRow(limitLocationCountryLabel)];
    }
    return [getSingleRow('World')];
  }

  return getCsvRowsFromParameterValues(
    parameter,
    parameterIndex,
    parameterGroup,
    parameterGroupsCount,
    parameterGroupIndex,
    sectionType,
    targetingV2Info
  );
};

export const getCsvRowsFromParameterGroups = (
  parameterGroups: IParameterGroup[] | undefined,
  sectionType: SectionType,
  targetingV2Info: ITargetingV2Info,
  territory?: Territory
) =>
  parameterGroups?.flatMap(
    (parameterGroup: IParameterGroup, parameterGroupIndex) => {
      const getDefaultRowCallback = (parameter: IParameter) =>
        getCsvRowsFromParameter(
          parameter,
          0,
          parameterGroup,
          parameterGroups.length,
          parameterGroupIndex,
          sectionType,
          targetingV2Info,
          territory
        );

      const defaultRows = getDefaultRows(
        parameterGroup,
        sectionType,
        getDefaultRowCallback
      );

      return [
        ...defaultRows,
        ...parameterGroup.parameters.flatMap((parameter, parameterIndex) =>
          getCsvRowsFromParameter(
            parameter,
            parameterIndex,
            parameterGroup,
            parameterGroups.length,
            parameterGroupIndex,
            sectionType,
            targetingV2Info,
            territory
          )
        ),
      ];
    }
  );

export const getCsvRowsFromCustomParameter = (
  parameter: ICustomParameter,
  parameterIndex: number,
  customGroupsCount: number,
  customGroupIndex: number
) =>
  parameter.customKvps.map((customKvp, customKvpIndex) => ({
    section:
      parameterIndex === 0 && customKvpIndex === 0
        ? getNumberedSectionTitle(
            customGroupsCount,
            customGroupIndex,
            SectionType.Custom
          )
        : '',
    parameterType: customKvpIndex === 0 ? 'Custom Parameter' : '',
    clusivity:
      customKvpIndex === 0 ? clusivityOptionLabels[parameter.clusivity] : '',
    value: customKvp.label,
    accuracy: '',
  }));

export const getCsvRowFromDayTimeTargeting = (
  dayTimeTargeting: IDayTimeTargeting | null,
  sectionType: SectionType
) => {
  if (!dayTimeTargeting || !dayTimeTargeting.dayTimePoints.length) {
    return null;
  }
  const { clusivity, dayTimePoints } = dayTimeTargeting;
  const formattedDayTime = formatDayTime(dayTimePoints);
  return {
    section: getSectionTitle(sectionType),
    parameterType: 'Day & Time',
    clusivity: clusivityOptionLabels[clusivity],
    value: formattedDayTime,
    accuracy: '',
  };
};

export const getCsvRowsFromCustomParameterGroups = (
  customGroups: ICustomParameterGroup[] | undefined,
  errors: any
) =>
  customGroups?.flatMap((customGroup, customGroupIndex) => {
    const customGroupFieldName = `targetingDefinitionV2.customTargeting.customGroups[${customGroupIndex}]`;
    const validParameters = getParametersContainingValidCustomKvps(
      customGroup.parameters,
      customGroupFieldName,
      errors
    );

    return validParameters.flatMap((validParameter, validParameterIndex) =>
      getCsvRowsFromCustomParameter(
        validParameter,
        validParameterIndex,
        customGroups.length,
        customGroupIndex
      )
    );
  });

export const getCsvRowsFromTargetingV2 = (
  targetingDefinitionV2: ITargetingDefinitionV2,
  targetingV2Info: ITargetingV2Info,
  errors: any,
  territory?: Territory
) => {
  const {
    locationTargeting,
    audienceTargeting,
    inventoryTargeting,
    technologyTargeting,
    customTargeting,
    dayTimeTargeting,
  } = targetingDefinitionV2;

  const locationTargetingRows = getCsvRowsFromParameterGroups(
    locationTargeting?.locations,
    SectionType.Location,
    targetingV2Info,
    territory
  );

  const audienceTargetingRows = getCsvRowsFromParameterGroups(
    audienceTargeting?.audiences,
    SectionType.Audience,
    targetingV2Info
  );

  const dayTimeTargetingRow = getCsvRowFromDayTimeTargeting(
    dayTimeTargeting,
    SectionType.DayTime
  );

  const inventoryTargetingRows = getCsvRowsFromParameterGroups(
    inventoryTargeting?.inventories,
    SectionType.Inventory,
    targetingV2Info
  );

  const technologyTargetingRows = getCsvRowsFromParameterGroups(
    technologyTargeting?.technologies,
    SectionType.Technology,
    targetingV2Info
  );

  const customTargetingRows = getCsvRowsFromCustomParameterGroups(
    customTargeting?.customGroups,
    errors
  );

  const allTargetingRows = [
    locationTargetingRows,
    audienceTargetingRows,
    dayTimeTargetingRow,
    inventoryTargetingRows,
    technologyTargetingRows,
    customTargetingRows,
  ]
    .flat()
    .filter(Boolean);

  return allTargetingRows;
};
