import { baseFontStyles } from 'assets/styles/components/Font.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  container: {
    width: '480px',
    height: 'auto',
    minHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(11),
    borderRadius: theme.spacing(1.25),
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  ctaContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100px',
  },
  cta: {
    width: '224px',
  },
  footer: {
    justifySelf: 'flex-end',
  },
}));

export default useStyles;
