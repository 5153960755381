import { ReactComponent as AnalyticsIcon } from 'assets/icons/dax-analyticsicon.svg';
import { ReactComponent as GaIcon } from 'assets/icons/dax-gaicon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/dax-lockicon.svg';
import CookieOption from 'components/CookieConsent/CookieOption/CookieOption';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { camelCase } from 'lodash';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import {
  CookieOptionEnum,
  getConsentChoices,
  setConsentCookie,
} from 'utils/cookieConsent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

import useStyles from './CookieConsentModal.styles';

export interface ICookieConsentModalProps {
  testId: string;
  isModalOpen: boolean;
  setIsModalOpen: (arg0: boolean) => void;
  showCheckboxesByDefault?: boolean;
}

interface ICookieOptionDetails {
  label: CookieOptionEnum;
  Icon: ReactNode;
  checkedState: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  description?: string;
}

const CookieConsentModal = (props: ICookieConsentModalProps) => {
  const classes = useStyles();
  const {
    testId,
    isModalOpen,
    setIsModalOpen,
    showCheckboxesByDefault = false,
  } = props;

  const consentChoices = getConsentChoices();

  const [googleAnalyticsChecked, setGoogleAnalyticsChecked] = useState(false);

  const [showCheckboxes, setShowCheckboxes] = useState(showCheckboxesByDefault);

  const handleSave = (googleAnalyticsConsentChoice: boolean) => {
    setConsentCookie(googleAnalyticsConsentChoice);
    setIsModalOpen(false);
  };

  const cookieDetails: ICookieOptionDetails[] = [
    {
      label: CookieOptionEnum.ESSENTIAL,
      Icon: <LockIcon />,
      checkedState: true,
    },
    {
      label: CookieOptionEnum.GOOGLE_ANALYTICS,
      Icon: <GaIcon />,
      handleChange: (event) => setGoogleAnalyticsChecked(event.target.checked),
      checkedState: googleAnalyticsChecked,
      description:
        'Google Analytics collects anonymised usage data from DAX to help us understand how visitors use our tools. This is information about the number of visitors, page visits, etc.',
    },
  ];

  useEffect(() => {
    setGoogleAnalyticsChecked(consentChoices?.googleAnalytics || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentChoices?.googleAnalytics]);

  return (
    <Dialog open={isModalOpen} data-testid={testId}>
      <DialogTitle className={classes.dialogTitleRoot} disableTypography>
        <AnalyticsIcon className={classes.analyticsIcon} />
        <Typography variant="h5">Analytics cookies on DAX</Typography>
      </DialogTitle>
      <DialogContent
        className={classes.content}
        data-testid={`${testId}Content`}
      >
        <Typography>
          We use the following cookies to remember your settings, enable
          functionalities, and measure the performance of our product.
        </Typography>
        <FormGroup className={classes.formGroup}>
          {cookieDetails.map((cookieOption) => {
            const { label, Icon, checkedState, handleChange, description } =
              cookieOption;
            return (
              <CookieOption
                key={camelCase(label)}
                label={label}
                Icon={Icon}
                showCheckboxes={showCheckboxes}
                checked={checkedState}
                disabled={label === CookieOptionEnum.ESSENTIAL}
                handleChange={handleChange}
                cookieDescription={description}
              />
            );
          })}
        </FormGroup>
        <Typography>
          You can consent to the use of cookies for the above purposes or choose
          not to accept Google Analytics cookies. You can change your choice at
          any time from the Privacy Manager link at the bottom of every page.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <a
          href="https://global.com/legal/privacy-policy/"
          className={classes.privacyLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        {showCheckboxes ? (
          <StyledButton
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            onClick={() => handleSave(googleAnalyticsChecked)}
          >
            Save My Changes
          </StyledButton>
        ) : (
          <StyledButton
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            onClick={() => setShowCheckboxes(true)}
          >
            Manage Cookies
          </StyledButton>
        )}
        <StyledButton
          variant={ButtonVariantEnum.Contained}
          color={ButtonColorEnum.Primary}
          onClick={() => handleSave(true)}
        >
          {showCheckboxes ? 'Allow All Cookies' : "I'm Ok with that"}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
export default CookieConsentModal;
