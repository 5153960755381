import { ITreeItemsWithType } from 'interfaces';
import {
  EntityPermission,
  EntityType,
  InsightsTier2PermissionEntitiesQuery,
} from 'interfaces/generated.types';
import { uniqBy } from 'lodash';
import memoizeOne from 'memoize-one';
import {
  getDescendantIdsToRemove,
  getIdsToUpdateOnUnselectingNode,
  getSelectedTreeItem,
} from 'utils/treeDataTransformation';

import { getEntityPermissionsIds } from '../InsightsPermissionsSelector/InsightsPermissionsSelectorUtils';

export const formatTreeItemToPermission = (item: ITreeItemsWithType) => ({
  entityName: item.label,
  entityId: item.value,
  entityType: item.type,
});

export const formatTier2Entities = memoizeOne(
  (data: InsightsTier2PermissionEntitiesQuery | undefined) => {
    if (!data?.allNetworks) return [];

    return data.allNetworks.map((network) => ({
      value: network.id,
      label: network.name || 'Unnamed network',
      showCheckbox: true,
      type: EntityType.Network,
      children: network.publishers.map((publisher) => ({
        value: publisher.id,
        label: publisher.name || 'Unnamed publisher',
        showCheckbox: true,
        type: EntityType.Publisher,
        children: publisher.channels.map((channel) => ({
          value: channel.id,
          label: channel.name || 'Unnamed channel',
          showCheckbox: true,
          type: EntityType.Channel,
          children: channel.shows.map((show) => ({
            value: show.show_id,
            label: show.name || 'Unnamed show',
            showCheckbox: true,
            type: EntityType.Show,
          })),
        })),
      })),
    }));
  }
);

export const getSelectedPermissions = memoizeOne(
  (
    allItems: ITreeItemsWithType[],
    previousPermissions: EntityPermission[],
    selectedNode: any
  ) => {
    let newPermissions: EntityPermission[] = [...previousPermissions];

    const previousPermissionsIds = getEntityPermissionsIds(previousPermissions);

    const selectedNodeDescendantsToRemove = getDescendantIdsToRemove(
      previousPermissionsIds,
      selectedNode
    );

    newPermissions = newPermissions.filter(
      (item) =>
        item.entityId &&
        !selectedNodeDescendantsToRemove.includes(item.entityId)
    );

    if (selectedNode.checked) {
      const selectedTreeItem = getSelectedTreeItem(allItems, selectedNode);

      if (selectedTreeItem)
        newPermissions.push(
          formatTreeItemToPermission(selectedTreeItem as ITreeItemsWithType)
        );
    } else {
      allItems.forEach((node) => {
        const result = getIdsToUpdateOnUnselectingNode(
          getEntityPermissionsIds(newPermissions),
          node,
          selectedNode
        );

        newPermissions = newPermissions.filter(
          (item) =>
            item.entityId && !result?.idsToRemove.includes(item.entityId)
        );
        newPermissions = [
          ...newPermissions,
          ...result.itemsToAdd.map((item) =>
            formatTreeItemToPermission(item as ITreeItemsWithType)
          ),
        ];
      });
    }

    return uniqBy(newPermissions, 'entityId');
  }
);
