import mediaLibraryStyles from 'assets/styles/components/MediaLibrary.styles';
import CreativeLink from 'components/CreativeLink/CreativeLink';
import DownloadCreativeButton from 'components/DownloadCreativeButton/DownloadCreativeButton';
import Table from 'components/Table/Table';
import { dateSort } from 'components/Table/TableSort';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  CreativeAudio,
  CreativeType,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

import Radio from '@material-ui/core/Radio';

interface ICreativeAudioRow {
  id: string;
  name: string;
  fileName: string;
  url: string;
  duration: number;
  uploadedDate: string;
  resourceAltId: number;
}

export const formatAudioData = memoizeOne(
  (data: CreativeAudio[], territory: Territory | undefined) =>
    data.map((d) => ({
      id: d.id,
      name: d.name,
      url: d.url,
      duration: d.duration || null,
      resourceAltId: d.resourceAltId || null,
      uploadedDate: d.uploadedDate
        ? getFormattedDateBasedOnTerritory(new Date(d.uploadedDate), territory)
        : null,
    }))
);

const AudioLibrary = ({
  data,
  selectedAudioId,
  handleChange,
}: {
  data: CreativeAudio[];
  selectedAudioId: string;
  handleChange: (event: React.ChangeEvent<unknown>) => void;
}) => {
  const classes = mediaLibraryStyles();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Select',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeAudioRow>) => (
          <Radio
            data-tc="creativeAudioRadioButton"
            checked={Number(selectedAudioId) === row.original.resourceAltId}
            onChange={handleChange}
            value={row.original.resourceAltId}
            name={row.original.name}
          />
        ),
      },
      {
        Header: 'Audio',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeAudioRow>) => (
          <CreativeLink
            url={row.original.url}
            fileName={row.original.fileName}
            type={CreativeType.Audio}
            dataTc="creativePageAudioPlayer"
          />
        ),
      },
      {
        Header: 'Id',
        accessor: 'resourceAltId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          maxWidth: '300px',
        },
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Uploaded Date',
        accessor: 'uploadedDate',
        sortType: dateSort,
      },
      {
        Header: 'Action',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeAudioRow>) => (
          <DownloadCreativeButton
            url={row.original.url}
            name={row.original.name}
          />
        ),
      },
    ],
    [selectedAudioId, handleChange]
  );

  return (
    <div className={classes.form}>
      <Table
        columns={columns}
        data={formatAudioData(data, activeTerritory)}
        dataTc="audioLibrary"
        showColumnToggle={false}
      />
    </div>
  );
};

export default AudioLibrary;
