import Tree from 'components/Tree/Tree';
import { ITreeItems } from 'interfaces';
import React from 'react';
import {
  generateAllReadonlySelectedItems,
  getExpandedItemsIds,
} from 'utils/treeDataTransformation';

import useStyles from './DualFilteredTreeList.styles';

export interface ITreeChannelData {
  id: string;
  name: string;
  readOnly: boolean;
}

export interface IDualFilteredTreeListProps {
  'data-tc': string;
  allItems: ITreeItems[];
  selectedItemsIds: string[];
  allData?: ITreeChannelData[];
  onChangeWithIds?: (value: string[]) => void;
  onChangeWithTreeItems?: (value: ITreeItems[]) => void;
  displaySelectOptions?: boolean;
  onTreeNodeSelection?: (node: any) => void;
  providedReadOnlySelectedItems?: ITreeItems[];
}

const DualFilteredTreeList = (props: IDualFilteredTreeListProps) => {
  const {
    'data-tc': dataTc,
    allItems,
    onChangeWithIds,
    onChangeWithTreeItems,
    selectedItemsIds,
    allData = [],
    displaySelectOptions = true,
    onTreeNodeSelection,
    providedReadOnlySelectedItems,
  } = props;
  const classes = useStyles();

  const [readOnlySelectedItems, setReadOnlySelectedItems] = React.useState<
    ITreeItems[]
  >(
    providedReadOnlySelectedItems ||
      generateAllReadonlySelectedItems(selectedItemsIds, allItems)
  );

  React.useEffect(() => {
    const selectedItems =
      providedReadOnlySelectedItems ||
      generateAllReadonlySelectedItems(selectedItemsIds, allItems);

    setReadOnlySelectedItems(selectedItems);
    if (onChangeWithTreeItems) {
      onChangeWithTreeItems(selectedItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsIds, allItems]);

  return (
    <div className={classes.dualTree} data-testid={dataTc}>
      <div>
        <div className={classes.tree}>
          <p className={classes.titleText}>Available</p>
          <Tree
            allItems={allItems}
            data-tc={`${dataTc}Available`}
            expandedItemsIds={getExpandedItemsIds(selectedItemsIds, allItems)}
            selectedItemsIds={selectedItemsIds}
            onChange={onChangeWithIds}
            selectionOptions={displaySelectOptions}
            searchDataTc={`${dataTc}SearchAvailable`}
            allData={allData}
            handleTreeNodeSelection={onTreeNodeSelection}
          />
        </div>
      </div>
      <div>
        <div className={classes.tree}>
          <p className={classes.titleText}>Selected</p>
          <Tree
            allItems={readOnlySelectedItems}
            data-tc={`${dataTc}Selected`}
            expandedItemsIds={[]}
            selectedItemsIds={[]}
            searchDataTc={`${dataTc}SearchSelected`}
          />
        </div>
      </div>
    </div>
  );
};

export default DualFilteredTreeList;
