import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { OptionType } from 'interfaces';

interface TableOptionType extends OptionType {
  isDisabled?: (value: any) => Boolean;
}

interface ITableSelect {
  value: string;
  onChange: any;
  options: TableOptionType[];
  name: string;
  dataTc: string;
  classes?: object;
}

const TableSelect = ({
  value,
  onChange,
  options,
  name,
  dataTc,
  classes = {},
}: ITableSelect) => {
  const selectOptions = options.map((option: any) => (
    <MenuItem
      key={option.value}
      disabled={
        typeof option.isDisabled === 'function'
          ? option.isDisabled(value)
          : false
      }
      value={option.value}
    >
      {option.label}
    </MenuItem>
  ));

  return (
    <TextField
      select
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
        classes,
      }}
      label=""
      value={value}
      name={name}
      data-tc={dataTc}
      onChange={(e) => onChange(e.target.value)}
    >
      {selectOptions}
    </TextField>
  );
};

export default TableSelect;
