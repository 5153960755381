import { makeStyles, Theme } from '@material-ui/core/styles';

const primaryControlFormStyles = makeStyles((theme: Theme) => ({
  note: {
    color: theme.palette.error.main,
    padding: theme.spacing(0, 2, 0, 2),
  },
  territoryCheckboxes: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxContainer: {
    display: 'flex',
  },
  checkboxContent: {
    display: 'flex',
    paddingTop: theme.spacing(0.5),
  },
  checkboxIcon: {
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    margin: theme.spacing(1, 0.75, 0, 0),
  },
  checkboxLabel: {
    marginTop: theme.spacing(0.75),
    width: '64px',
  },
  footer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default primaryControlFormStyles;
