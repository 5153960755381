import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: 'none',
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  content: {
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: '1.25em',
    justifyContent: 'center',
    minHeight: '30px',
    '&:last-child': {
      padding: theme.spacing(0.1),
    },
    '& img': {
      maxHeight: '1.5em',
      maxWidth: '1.5em',
    },
  },
  default: {
    composes: '$content',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
  },
  amber: {
    composes: '$content',
    backgroundColor: theme.palette.error.main,
  },
  red: {
    composes: '$content',
    backgroundColor: theme.palette.warning.main,
  },
  green: {
    composes: '$content',
    backgroundColor: theme.palette.primary.dark,
  },
  defaultBlackText: {
    composes: '$content',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  header: {
    padding: 0,
  },
  title: {
    fontSize: '0.9em',
    padding: theme.spacing(1, 0),
  },
}));

export default useStyles;
