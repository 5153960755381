import classNames from 'classnames';
import CircularProgress from 'features/targetingV2/components/core/CircularProgress';
import React from 'react';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import useStyles from './Loader.styles';

interface ILoader {
  classes?: ClassNameMap<string>;
  size?: number;
}
const Loader = ({ classes: externalClasses = {}, size }: ILoader) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.container, externalClasses.container)}
      data-testid="loader"
    >
      <CircularProgress
        className={classNames(classes.progress, externalClasses.progress)}
        size={size}
      />
    </div>
  );
};

export default Loader;
