import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { startOfDay } from 'date-fns';
import ShowContractForm from 'features/inventory/channel/components/ShowContractForm/ShowContractForm';
import { IShowContractValues } from 'features/inventory/channel/components/ShowContractForm/ShowContractValues';
import ShowContractFormValidation from 'features/inventory/channel/validations/showContracts';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import useError from 'hooks/Error/useError';
import {
  Channel,
  CreateShowContractMutationFn,
  useCreateShowContractMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React from 'react';
import { getContractTerritory, getContractTimezone } from 'utils/contracts';
import dateUtils from 'utils/date';
import { getDefaultCurrencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';

export interface ICreateShowContractContainerProps {
  channel: Channel;
  handleClose: () => void;
  refetch: () => void;
  hasEditPermissions?: boolean;
}

export const submitCreateShowContract =
  ({
    createShowContract,
    toggleErrorModal,
  }: {
    createShowContract: CreateShowContractMutationFn;
    toggleErrorModal: () => void;
  }) =>
  (
    formValues: IShowContractValues,
    { setStatus, setSubmitting }: FormikActions<IShowContractValues>
  ) => {
    const formattedValues = formatValues(formValues);

    const filteredValues: any = pickBy(
      {
        ...formattedValues,
        showId: formValues.showName?.value,
        channelId: formValues.channelId,
        currency: formValues.currency,
        territories: formValues.territories,
        targetingGroups: null,
        targetingMasterTemplates: null,
        timeZone: null,
        entityPermissions: null,
        notes: formValues.notes,
      },
      (value) => value === 0 || !!value
    );
    createShowContract({
      variables: filteredValues,
    }).catch((error) =>
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      })
    );
  };

const CreateShowContractContainer = ({
  channel,
  handleClose,
  refetch,
  hasEditPermissions = false,
}: ICreateShowContractContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [createShowContract] = useCreateShowContractMutation({
    onCompleted() {
      refetch();
      handleClose();
    },
  });

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Contract.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const timeZone = getContractTimezone(channel, activeTerritory!);
  const contractTerritory = getContractTerritory(channel, activeTerritory!);

  const initialValues: IShowContractValues = {
    id: '',
    showName: null,
    channelId: channel.id,
    startDate: dateUtils.getDateInSpecificTimezone(
      startOfDay(new Date()).toISOString(),
      timeZone
    ),
    endDate: null as any,
    timeZone,
    currency: getDefaultCurrencyBasedOnTerritory(activeTerritory!),
    directSpotCpm: '',
    programmaticSpotCpm: '',
    spotRevenueShare: '',
    hostReadRevenueShare: '',
    hostReadCpm: '',
    sponsorshipRevenueShare: '',
    territories: channel.territories,
    notes: '',
  };

  return (
    <>
      <Formik<IShowContractValues>
        initialValues={initialValues}
        onSubmit={submitCreateShowContract({
          createShowContract,
          toggleErrorModal,
        })}
        validationSchema={ShowContractFormValidation(contractTerritory)}
      >
        {(props) => (
          <ShowContractForm
            {...props}
            handleClose={handleClose}
            testId="createShowContractForm"
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => handleClose()}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createShowContractError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateShowContractContainer;
