import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundImage: `linear-gradient(to right, ${colors.daxPrimaryBlue}, ${colors.globalStandardPrimary})`,
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    minWidth: '1024px',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0, 1.875),
    paddingTop: '87px',
    display: 'flex',
    justifyContent: 'center',
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '232px',
    height: '128px',
  },
}));

export default useStyles;
