import { TargetingRestriction } from 'interfaces/generated.types';
import { getTargetingRestrictionsOptions } from 'utils/targetingRestrictions';

export const GeneralRestriction = 'GENERAL';

export const mapChannelRestrictionValueToLabel: Record<string, string> = {
  [GeneralRestriction]: 'General',
  [TargetingRestriction.Local]: 'Local',
  [TargetingRestriction.CapSensitive]: 'CAP Sensitive',
  [TargetingRestriction.Hfss]: 'HFSS',
};

export const channelTargetingRestrictionsOptions =
  getTargetingRestrictionsOptions(mapChannelRestrictionValueToLabel);
