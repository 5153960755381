export interface IKeyValue {
  key: string;
  value: string;
  readOnly?: boolean;
}

const filterKeyValues = (keyValues: IKeyValue[]) =>
  keyValues.filter(
    (keyValue: IKeyValue) => keyValue.key !== '' && keyValue.value !== ''
  );

export default {
  filterKeyValues,
};
