import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ParameterRow from 'features/targetingV2/components/ParameterRow/ParameterRow';
import { SectionType } from 'features/targetingV2/types/common';
import {
  IParameter,
  IParameterGroup,
} from 'features/targetingV2/types/targeting';
import { hasInvalidParameters } from 'features/targetingV2/utils/common';
import { defaultParameter } from 'features/targetingV2/utils/defaults';
import { getAvailableParameterOptionsBasedOnCardinality } from 'features/targetingV2/utils/targeting';
import { FieldArray } from 'formik';
import { TechnologyParameterInfo } from 'interfaces/generated.types';
import React from 'react';

export interface ITechnologyProps {
  technology: IParameterGroup;
  technologyFieldName: string;
  technologyIndex?: number;
  allParametersInfo: TechnologyParameterInfo[];
  areParameterOptionsLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  removeTechnology?: (technologyIndex: number) => void;
  sectionType?: SectionType;
}

const Technology = (props: ITechnologyProps) => {
  const {
    technology,
    technologyFieldName,
    technologyIndex = 0,
    allParametersInfo,
    areParameterOptionsLoading,
    setFieldValue,
    removeTechnology,
  } = props;

  const sectionType = SectionType.Technology;

  const handleTechnologyParameterDeletion = (
    parameterIndex: number,
    remove: (index: number) => void
  ) => {
    if (technology.parameters.length === 1 && removeTechnology) {
      removeTechnology(technologyIndex);
    } else remove(parameterIndex);
  };

  const onParameterChange = (parameterIndex: number) => {
    const parameterField = `${technologyFieldName}.parameters[${parameterIndex}]`;

    setFieldValue(`${parameterField}.clusivity`, defaultParameter.clusivity);
    setFieldValue(`${parameterField}.values`, defaultParameter.values);
    setFieldValue(`${parameterField}.isFocused`, true);
  };

  const getAvailableParametersOptions = (parameterType?: string) =>
    getAvailableParameterOptionsBasedOnCardinality(
      allParametersInfo,
      technology.parameters,
      parameterType
    );

  const availableParameterOptions = getAvailableParametersOptions();
  const isAddButtonDisabled = hasInvalidParameters(technology.parameters);

  return (
    <div data-testid={technologyFieldName}>
      <FieldArray name={`${technologyFieldName}.parameters`}>
        {({ push: pushParameter, remove: removeParameter }) => (
          <>
            {technology.parameters.map(
              (parameter: IParameter, parameterIndex: number) => (
                <ParameterRow
                  {...props}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`technology${technologyIndex}-parameter${parameterIndex}`}
                  parameterFieldName={`${technologyFieldName}.parameters[${parameterIndex}]`}
                  selectedParameter={technology.parameters[parameterIndex]}
                  parameterTypeOptions={getAvailableParametersOptions(
                    parameter.type
                  )}
                  areParameterTypeOptionsLoading={areParameterOptionsLoading}
                  removeParameter={() =>
                    handleTechnologyParameterDeletion(
                      parameterIndex,
                      removeParameter
                    )
                  }
                  sectionType={sectionType}
                  onParameterTypeChange={() =>
                    onParameterChange(parameterIndex)
                  }
                  parameterRowId={`${sectionType} ${technologyIndex} ${parameter.type} Parameter Row ${parameterIndex}`}
                />
              )
            )}
            {technology.parameters.length &&
            availableParameterOptions.length ? (
              <AddParameterDropdownButton
                options={availableParameterOptions}
                onOptionClick={(parameterType: string) => {
                  pushParameter({
                    ...defaultParameter,
                    type: parameterType,
                    isFocused: true,
                  });
                }}
                disabled={isAddButtonDisabled}
                loading={areParameterOptionsLoading}
              />
            ) : null}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Technology;
