import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    color: theme.palette.grey[800],
    fontSize: theme.spacing(2.376),
    lineHeight: theme.spacing(2.876),
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    width: 'auto',
    height: theme.spacing(4),
    background: theme.palette.grey[200],
    borderRadius: theme.spacing(0.625),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  dualTree: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  tree: {
    margin: theme.spacing(0, 2),
  },
}));

export default useStyles;
