import classNames from 'classnames';
import DynamicTableFooter from 'components/DynamicTable/DynamicTableFooter';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { ITableErrorState, IValidateCellData } from 'components/Table/Table';
import useTableStyles from 'components/Table/Table.styles';
import TableFilter from 'components/Table/TableFilter';
import TableHeader from 'components/Table/TableHeader';
import TableLoader from 'components/Table/TableLoader';
import TableToolbar from 'components/Table/TableToolbar';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { UPDATE_TABLE_FILTERS } from 'context/SessionProvider/reducer';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import useWarning from 'hooks/Warning/useWarning';
import { PageInfo, Territory } from 'interfaces/generated.types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Column,
  ColumnInstance,
  Row,
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import MTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

const getStyles = (props: any, style = {}) => [
  props,
  {
    style,
  },
];

export interface IFetchData {
  cursor: IPaginationCursor;
  pageSize: number;
  sortBy: SortingRule<string>[];
  globalFilter: string | null;
  filters: any[];
  activeTerritory: Territory;
}

export interface IPaginationCursor {
  start?: string;
  end?: string;
  last?: boolean;
}

interface IDynamicTableProps {
  activeTerritory: Territory;
  columns: Column[];
  data: any[];
  loading: boolean;
  pageInfo?: PageInfo;
  title?: string;
  errorMessage?: string;
  showColumnToggle?: boolean;
  isPageTable?: boolean;
  hiddenColumns?: string[];
  dataTc: string;
  totalEntities: number;
  entityType?: {};
  onCellUpdate?: (
    row: any,
    setErrorModal: any,
    setUpdating: any
  ) => Promise<void>;
  onCellValidate?: ({
    entity,
    setErrorModal,
    setWarningModal,
    handleContinue,
  }: IValidateCellData) => Promise<void>;
  fetchData: ({
    cursor,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    activeTerritory,
  }: IFetchData) => void;
}

const DynamicTable = ({
  activeTerritory,
  columns,
  data,
  fetchData,
  entityType,
  onCellUpdate,
  onCellValidate,
  loading,
  totalEntities,
  pageInfo,
  title,
  errorMessage,
  isPageTable = true,
  dataTc,
  showColumnToggle = true,
  hiddenColumns = [],
}: IDynamicTableProps) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: TableFilter,
    }),
    []
  );
  const [paginationRowsPerPage, setPaginationRowsPerPage] = useState(15);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [entityRange, setEntityRange] = useState({ start: 1, end: 15 });

  const [cursor, setCursor] = useState<IPaginationCursor>({});
  const classes = useTableStyles();
  const cellProps = (props: any, { cell }: any) =>
    getStyles(props, cell.column && cell.column.style);
  const [{ isOpened, errors, handleContinue, content }, setErrorModal] =
    useState<ITableErrorState>({
      isOpened: false,
      errors: [],
      content: { title: 'Error', closeButton: 'Close', continueButton: '' },
    });
  const {
    hasWarning,
    handleWarningContinue,
    setWarningModal,
    toggleWarningModal,
  } = useWarning();
  const [isUpdating, setUpdating] = useState(false);
  const toggleErrorModal = () =>
    setErrorModal((prevState: ITableErrorState) => ({
      ...prevState,
      isOpened: !prevState.isOpened,
    }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    setAllFilters,
    allColumns,
    state: { pageSize, filters, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      onCellUpdate,
      onCellValidate,
      setErrorModal,
      setWarningModal,
      setUpdating,
      initialState: {
        pageSize: paginationRowsPerPage,
        pageIndex: paginationIndex,
        hiddenColumns: ['id', ...hiddenColumns],
      },
      useControlledState: (state) =>
        useMemo(
          () => ({
            ...state,
            pageIndex: paginationIndex,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, paginationRowsPerPage]
        ),
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      manualPagination: true,
      setCursor,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSort = (col: ColumnInstance) => {
    setCursor({});
    setPaginationIndex(0);
    if (!col.isSorted) {
      col.toggleSortBy(false, false);
    } else if (!col.isSortedDesc) {
      col.toggleSortBy(true, false);
    } else {
      col.clearSortBy();
    }
  };

  const { state, dispatch } = useSessionContext();

  useEffect(() => {
    const defaultEnd = (paginationIndex + 1) * paginationRowsPerPage;
    const end =
      (paginationIndex + 1) * paginationRowsPerPage > totalEntities
        ? totalEntities
        : defaultEnd;
    const start = cursor.last
      ? end - paginationRowsPerPage
      : paginationIndex * paginationRowsPerPage;

    setEntityRange({
      start: totalEntities > 0 ? start + 1 : 0,
      end,
    });
  }, [totalEntities, paginationIndex, paginationRowsPerPage, cursor]);

  useEffect(() => {
    if (dataTc) {
      setAllFilters(state.pages[dataTc]?.filters || []);
      setGlobalFilter(state.pages[dataTc]?.globalFilter || '');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({
      type: UPDATE_TABLE_FILTERS,
      payload: { parentPageName: dataTc, filters, globalFilter },
    });
  }, [dispatch, filters, globalFilter, dataTc]);

  // Listen for changes in pagination, filter and search and use the state to fetch our new data
  useEffect(() => {
    if (activeTerritory)
      fetchData({
        pageSize,
        filters,
        globalFilter,
        sortBy,
        cursor,
        activeTerritory,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, pageSize, sortBy, globalFilter, filters, activeTerritory]);

  useEffect(() => {
    setPaginationIndex(0);
  }, [filters]);

  return (
    <>
      <div
        className={classNames(classes.root, {
          [`${classes.root}--updating`]: isUpdating,
        })}
      >
        <TableToolbar
          title={title}
          columns={allColumns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setAllFilters={setAllFilters}
          setCursor={setCursor}
          errorMessage={errorMessage}
          showColumnToggle={showColumnToggle}
          isPageTable={isPageTable}
        />
        <TableContainer>
          <MTable
            {...getTableProps()}
            data-tc={dataTc}
            className={classes.table}
          >
            <TableHeader
              headerGroups={headerGroups}
              handleSort={handleSort}
              entityType={entityType}
            />
            <TableBody {...getTableBodyProps()} className={classes.body}>
              {page.map((row: Row) => {
                prepareRow(row);
                return (
                  /* eslint-disable react/jsx-key */
                  <TableRow
                    {...row.getRowProps()}
                    data-tc={(row.original as any).id}
                    className={classes.row}
                  >
                    {row.cells.map((cell: Cell) => (
                      /* eslint-disable react/jsx-key */
                      <TableCell
                        {...cell.getCellProps(cellProps)}
                        className={classes.cell}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
            <DynamicTableFooter
              totalEntities={totalEntities}
              pageIndex={paginationIndex}
              pageInfo={pageInfo}
              pageSize={paginationRowsPerPage}
              setPaginationIndex={setPaginationIndex}
              setPaginationRowsPerPage={setPaginationRowsPerPage}
              setCursor={setCursor}
              entityRange={entityRange}
            />
          </MTable>
        </TableContainer>
        {loading ? <TableLoader /> : null}

        {isOpened ? (
          <ErrorDialog
            content={{
              title: content.title,
              closeButton: content.closeButton,
              continueButton: content.continueButton,
            }}
            handleContinue={handleContinue}
            isOpen={isOpened}
            handleClose={toggleErrorModal}
            dataTc={`${dataTc}Dialog`}
            errorMessages={errors}
          />
        ) : null}
        {hasWarning ? (
          <WarningDialog
            handleContinue={handleWarningContinue}
            handleClose={toggleWarningModal}
            isOpen={hasWarning}
            dataTc={`${dataTc}WarningDialog`}
          />
        ) : null}
      </div>
    </>
  );
};

export default DynamicTable;
