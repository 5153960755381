import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const baseFormStyles = (theme: Theme) =>
  createStyles({
    legend: {
      marginRight: theme.spacing(2.5),
      fontSize: theme.spacing(2.626),
      fontWeight: 500,
      color: theme.palette.grey[700],
    },
    form: {
      margin: `${theme.spacing(2.5)} auto`,
    },
    formWrapper: {
      composes: '$form',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2.5, 8),
      borderRadius: theme.spacing(1),
    },
    fieldset: {
      width: '100%',
      border: 'none',
      padding: 0,
    },
    description: {
      fontSize: '1em',
      color: theme.palette.grey[400],
      margin: theme.spacing(2, 0, 1),
    },
    targetingGroup: {
      margin: theme.spacing(4, 0, 1),
    },
    textFieldLinkContainer: {
      position: 'relative',
      width: '100%',
    },
    textfieldLinkWrapper: {
      paddingRight: theme.spacing(6.25),
    },
    textFieldLink: {
      position: 'absolute',
      right: theme.spacing(0.4),
      top: theme.spacing(1.4),
    },
    textFieldLinkMultiSelect: {
      composes: '$textFieldLink',
      top: theme.spacing(1.4),
    },
    formToggle: {
      margin: 'unset',
      textAlign: 'right',
      display: 'block',
    },
    formControl: {
      margin: theme.spacing(1.5, 0, 1),
    },

    radioButton: {
      color: theme.palette.primary.main,
      '&$checked': {
        color: theme.palette.primary.main,
      },
      padding: theme.spacing(0.625),
    },
    checked: {},
    radioLabel: {
      paddingBottom: 0,
    },
    numberFrequencyCap: {
      maxWidth: theme.spacing(12.5),
      marginRight: theme.spacing(2.5),
    },
    frequencyCap: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    selectFrequencyCap: {
      flex: 1,
    },
    impressions: {
      marginRight: theme.spacing(2.5),
    },
  });

const formStyles = makeStyles((theme: Theme) => ({
  ...baseFormStyles(theme),
}));

export default formStyles;
