import { transformObjectKeysToLowerCase } from 'features/targetingV2/utils/dataTransformation';
import PapaParse from 'papaparse';

const defaultMaxFileSize = 128000;
const defaultErrorMessage = 'There was a problem in parsing the csv file';
const headerValidationError =
  'Incorrect CSV format. Learn about CSV formatting in our FAQ.';

const checkAllFileHeadersIncluded = (
  firstRowData: any,
  headerOptions: string[]
) =>
  headerOptions.every((_option, index) => headerOptions[index] in firstRowData);

const validateHeaders = (
  results: PapaParse.ParseResult<any>,
  headerOptions: string[]
) => {
  const firstRowData = results.data[0] || [];

  const firstRowDataInLowerCase = transformObjectKeysToLowerCase(firstRowData);
  const headerOptionsInLowerCase = headerOptions.map((headerOption) =>
    headerOption.toLowerCase()
  );

  const areAllFileHeadersIncluded = checkAllFileHeadersIncluded(
    firstRowDataInLowerCase,
    headerOptionsInLowerCase
  );
  return areAllFileHeadersIncluded;
};

const parseFile = ({
  file,
  header,
  delimiter,
  headerOptions,
}: {
  file: File;
  header: boolean;
  delimiter: string;
  headerOptions: string[];
}) =>
  new Promise((resolve, reject) => {
    PapaParse.parse(file, {
      header,
      delimiter,
      skipEmptyLines: 'greedy',
      complete: (results) => resolve(results),
      error: (error) => reject(error.message),
      chunk: (results: PapaParse.ParseResult<any>) => {
        const areAllFileHeadersIncluded = validateHeaders(
          results,
          headerOptions
        );
        if (areAllFileHeadersIncluded) {
          resolve(results.data);
        } else {
          reject(headerValidationError);
        }
      },
    });
  });

const getErrorMessage = (file: File, maxFileSize: number): string => {
  const maxFileSizeInKb = maxFileSize / 1000;
  switch (true) {
    case file.size > maxFileSize:
      return `This file is too large to upload. The maximum supported file size is ${maxFileSizeInKb}Kb.`;
    case file.size === 0:
      return "We couldn't find any information in the file you uploaded.";
    default:
      return '';
  }
};

export default {
  defaultErrorMessage,
  defaultMaxFileSize,
  parseFile,
  getErrorMessage,
  checkAllFileHeadersIncluded,
  validateHeaders,
};
