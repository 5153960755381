import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateTechnicalProviderContainer from 'features/programmatic/technicalProvider/containers/UpdateTechnicalProviderContainer/UpdateTechnicalProviderContainer';
import { History } from 'history';
import {
  PermissionName,
  TechnicalProvider,
  useTechnicalProviderQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface IUpdateTechnicalProviderPage {
  history: History;
  match: {
    params: {
      technicalProviderId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Programmatic];

const UpdateTechnicalProviderPage = ({
  history,
  match,
  userPermissions,
}: IUpdateTechnicalProviderPage) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useTechnicalProviderQuery({
    variables: {
      id: match.params.technicalProviderId,
      territories: activeTerritory,
    },
    errorPolicy: 'all',
  });

  const hasErrorAndNoData = error && !(data && data.technicalProvider);
  const noErrorAndNoData = !error && !(data && data.technicalProvider);

  if (!loading && (hasErrorAndNoData || noErrorAndNoData))
    return <Redirecting history={history} />;

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, editPermissions) &&
    isActiveTerritoryAssignedToEntity(
      data?.technicalProvider?.territories!,
      activeTerritory!
    );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Programmatic Connector</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateTechnicalProviderContainer
            technicalProvider={data?.technicalProvider as TechnicalProvider}
            history={history}
            match={match}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateTechnicalProviderPage;
