import PapaParse from 'papaparse';

const defaultErrorMessage = 'There was a problem in parsing the csv file';

const parseFile = ({
  file,
  header,
  delimiter,
}: {
  file: File;
  header: boolean;
  delimiter: string;
}) =>
  new Promise((resolve, reject) => {
    PapaParse.parse(file, {
      header,
      delimiter,
      skipEmptyLines: 'greedy',
      complete: (results) => resolve(results.data),
      error: (error) => reject(error.message),
    });
  });

const downloadData = (data: any, filename: string) => {
  const a = document.createElement('a');
  a.href = `data:attachment/csv,${encodeURIComponent(data)}`;
  a.target = '_blank';
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const unparseAndDownloadData = (data: any, filename: string) => {
  const csvData = PapaParse.unparse(data);
  downloadData(csvData, filename);
};



export default { parseFile, defaultErrorMessage, unparseAndDownloadData, downloadData };
