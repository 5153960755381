import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  pack: {
    padding: theme.spacing(4),
    margin: theme.spacing(3, 0, 0),
    backgroundColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  },
  packValues: {
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  packType: {
    display: 'block',
    width: 'auto',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  packComponent: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
