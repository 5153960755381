import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: 'auto',
    margin: theme.spacing(2, 0, 1),
    '&--noMargin': {
      margin: 0,
    },
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[500],
    '&--externalLink': {
      paddingRight: theme.spacing(6.25),
    },
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  dropdownIndicator: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  chip: {
    color: theme.palette.common.black,
    background: theme.palette.grey[300],
    fontSize: '0.938em',
    height: '28px',
    margin: '1px 0',
  },
  noOptionsMessage: {
    padding: theme.spacing(2),
  },
  placeholder: {
    position: 'absolute',
    left: theme.spacing(0.25),
    bottom: theme.spacing(0.75),
    fontSize: '1em',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(2),
    left: 0,
    right: 0,
  },
  selectAllLabel: {
    width: '100%',
    margin: 0,
    '&:hover, &:focus': {
      background: theme.palette.grey[200],
    },
  },
  optionCheckboxLabel: {
    width: '100%',
    margin: 0,
    '&--focussed': {
      background: theme.palette.grey[200],
    },
  },
}));

export const customStyles = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
  }),
  // So that if selected value is too long, it won't overflow the indicators. Ideal would be to set
  // max-width to (100% - indicatorsWidth), but haven't found a way to do that yet.
  singleValue: (provided: any) => ({
    ...provided,
    maxWidth: '80%',
  }),
};

export const asyncCustomStyles = {
  clearIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
  }),
};
