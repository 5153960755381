import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';
import React from 'react';
import useStyles from './Breadcrumb.styles';
import { IBreadcrumbLinkInfo } from './BreadcrumbLink';

export interface IBreadcrumbDropdownProps {
  label: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  options: IBreadcrumbLinkInfo[];
}

const BreadcrumbDropdown = (props: IBreadcrumbDropdownProps) => {
  const classes = useStyles();
  const { label, value, disabled = false, options, placeholder } = props;
  const defaultSelectedValue = '';
  const [selectedValue, setSelectedValue] = React.useState(
    value || defaultSelectedValue
  );

  const history = useHistory();

  const customRenderValue =
    selectedValue === defaultSelectedValue
      ? () => <div>{placeholder}</div>
      : undefined;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValue(event.target.value as string);

    const selectedOption = options.find(
      (option) => option.id === event.target.value
    );

    if (selectedOption) history.push(selectedOption.path);
  };

  return (
    <>
      <InputLabel
        id="entitySelect"
        className={classNames([
          {
            [`${classes.dropdownLabelDisabled}`]: disabled,
          },
        ])}
        data-testid={`breadcrumbDropdownLabel-${label}`}
      >
        {label}
      </InputLabel>
      <Select
        labelId="entitySelect"
        id="select"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          classes: {
            list: classes.dropdownList,
          },
        }}
        inputProps={{
          className: classes.inputWrapper,
        }}
        value={selectedValue}
        defaultValue={defaultSelectedValue}
        onChange={handleChange}
        className={classes.dropdownContainer}
        disabled={disabled}
        renderValue={customRenderValue}
        displayEmpty
        data-testid={`breadcrumbDropdownField-${label}`}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            classes={{ root: classes.menuItem }}
          >
            <Typography
              variant="inherit"
              noWrap
              data-testid={`breadcrumbDropdownOption-${option.id}`}
            >
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default BreadcrumbDropdown;
