import { ReactComponent as AlertIcon } from 'assets/icons/TargetingV2/dax-alert.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/TargetingV2/dax-info.svg';
import Tooltip from 'features/targetingV2/components/core/Tooltip/Tooltip';
import Typography from 'features/targetingV2/components/core/Typography';
import { ChipState } from 'features/targetingV2/types/common';
import { camelCase } from 'lodash';
import React from 'react';

import useStyles from './ChipTooltip.styles';

const Icon = ({ state }: { state: ChipState }) => {
  const classes = useStyles({ state });
  if (state === ChipState.DEFAULT) {
    return <InfoIcon className={classes.icon} />;
  }
  return <AlertIcon className={classes.icon} />;
};

const CustomTitle = ({
  state,
  tooltip,
}: {
  state: ChipState;
  tooltip: string | string[];
}) => {
  const classes = useStyles({ state });

  return (
    <>
      <Icon state={state} />
      {!Array.isArray(tooltip) ? (
        <Typography variant="body2">{tooltip}</Typography>
      ) : (
        <div>
          {tooltip.map((message, index) => (
            <Typography
              key={camelCase(message)}
              variant="body2"
              className={index > 0 ? classes.titleText : ''}
            >
              {message}
            </Typography>
          ))}
        </div>
      )}
    </>
  );
};

const ChipTooltip = ({
  state,
  tooltip,
}: {
  state: ChipState;
  tooltip: string | string[];
}) => {
  const classes = useStyles({ state });

  return (
    <Tooltip
      id="chipTooltip"
      title={<CustomTitle state={state} tooltip={tooltip} />}
      placement="bottom-start"
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <div className={classes.tooltipIconWrapper} data-testid="chipTooltipIcon">
        <Icon state={state} />
      </div>
    </Tooltip>
  );
};

export default ChipTooltip;
