import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import React, { useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import dialogStyles from 'assets/styles/components/Dialog.styles';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import Table from 'components/Table/Table';

import { TargetingTemplate } from 'interfaces/generated.types';

import { ITargetingTemplate } from 'utils/targetingDefinitions';

interface ITargetingTemplateModal {
  isModalOpen: boolean;
  setModalOpen: (arg0: boolean) => void;
  allTargetingTemplates: TargetingTemplate[];
  onSelect: (arg0: string[]) => void;
}

interface ITargetingTemplateRow {
  id: string;
  name: string;
  description: string;
  primary: string;
}

const formatData = memoizeOne((data: TargetingTemplate[]) =>
  data.map((d) => ({
    id: d.id,
    name: d.name,
    description: d.description,
    primary: d.isMasterTemplate ? 'Yes' : 'No',
  }))
);

export const handleCheckboxChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  allTargetingTemplates: TargetingTemplate[],
  setSelectedTemplates: (arg0: any[]) => void,
  selectedTemplates: any[]
) => {
  const {
    target: { value, checked },
  } = event;
  if (checked) {
    // eslint-disable-next-line react/prop-types
    const template = allTargetingTemplates.filter(
      (targetingGroup) => targetingGroup.id === value
    );
    setSelectedTemplates([...selectedTemplates, ...template]);
  } else
    setSelectedTemplates(
      selectedTemplates.filter((targetingGroup) => targetingGroup.id !== value)
    );
};

const TargetingTemplateModal = ({
  isModalOpen,
  setModalOpen,
  allTargetingTemplates,
  onSelect,
}: ITargetingTemplateModal) => {
  const classes = dialogStyles();

  const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Select',
        accessor: '',
        disableFilters: true,
        Filter: () => null,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ITargetingTemplateRow>) => (
          <Checkbox
            data-tc="targetingTemplateModalCheckbox"
            checked={selectedTemplates.some(
              (targetingGroup: ITargetingTemplate) =>
                targetingGroup.id === row.original.id
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleCheckboxChange(
                event,
                allTargetingTemplates,
                setSelectedTemplates,
                selectedTemplates
              )
            }
            value={row.original.id}
            name={row.original.name}
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          maxWidth: '500px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      {
        Header: 'Primary',
        accessor: 'primary',
      },
    ],
    [allTargetingTemplates, selectedTemplates]
  );

  return (
    <Dialog
      data-tc="targetingTemplateModal"
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      maxWidth="xl"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">Apply Targeting Template</Typography>
        <IconButton
          onClick={() => setModalOpen(false)}
          classes={{ root: classes.closeButton }}
          data-tc="targetingTemplateModalCloseX"
        >
          <CloseIcon />
          <span className="sr-only">
            Close the add targeting template modal
          </span>
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Table
          columns={columns}
          showColumnToggle={false}
          dataTc="listTargetingTemplatesTable"
          data={formatData(allTargetingTemplates)}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: classNames(
            classes.actions,
            `${classes.actions}--space-between`
          ),
        }}
      >
        <StyledButton
          onClick={() => setModalOpen(false)}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc="targetingTemplateModalClose"
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => onSelect(selectedTemplates)}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Contained}
          data-tc="targetingTemplateModalSelect"
          disabled={!selectedTemplates.length}
        >
          Select Targeting Template
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default TargetingTemplateModal;
