import { Territory } from 'interfaces/generated.types';
import React from 'react';
import { getTerritoryIcon } from 'utils/territory';

import Checkbox from '@material-ui/core/Checkbox';

export interface ITerritoryCheckboxProps {
  territory: Territory;
  checkboxLabel: string;
  values: { territories: Territory[] };
  onChange: (value: any, checked: boolean) => void;
  classes: any;
}

const TerritoryCheckbox = (props: ITerritoryCheckboxProps) => {
  const { territory, checkboxLabel, values, onChange, classes } = props;

  return (
    <>
      <Checkbox
        color="primary"
        checked={values.territories.includes(territory)}
        onChange={onChange}
        value={territory}
        name="territory"
        data-testid={`${territory}-checkboxField`}
        classes={{
          root: classes.checkbox,
        }}
      />
      <div className={classes.checkboxContent}>
        <span className={classes.checkboxIcon}>
          {getTerritoryIcon(territory)}
        </span>
        <span className={classes.checkboxLabel}>{checkboxLabel}</span>
      </div>
    </>
  );
};

export default TerritoryCheckbox;
