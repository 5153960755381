import colors from 'assets/styles/colors';
import { ChipState } from 'features/targetingV2/types/common';

import { makeStyles, Theme } from '@material-ui/core/styles';

const stateToStyles = {
  default: colors.daxBlue800,
  warning: colors.daxOrange500,
  inactive: colors.daxStatusWarning,
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltipIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(0.75),
  },
  icon: ({ state }: { state: ChipState }) => ({
    color: stateToStyles[state],
    width: '14px',
    height: '14px',
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    padding: theme.spacing(0.3, 0),
  }),
  tooltip: {
    background: theme.palette.common.white,
    boxShadow: `0px 2px 12px -1px ${colors.boxShadowLightGrey}`,
    borderRadius: '8px',
    color: colors.daxGrey900,
    padding: theme.spacing(0.75),
    fontWeight: 400,
    fontSize: '14px',
    marginTop: theme.spacing(1.2),
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    maxWidth: '670px',
  },
  titleText: {
    marginTop: theme.spacing(0.8),
  },
}));

export default useStyles;
