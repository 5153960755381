import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import CreateTargetingTemplateContainer from 'features/targeting/containers/CreateTargetingTemplateContainer/CreateTargetingTemplateContainer';

interface ICreateTargetingTemplatePage {
  history: History;
}

const CreateTargetingTemplatePage = ({
  history,
}: ICreateTargetingTemplatePage) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Targeting Template</h1>
      <CreateTargetingTemplateContainer history={history} />
    </Container>
  </main>
);

export default CreateTargetingTemplatePage;
