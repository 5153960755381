import gql from 'graphql-tag';

import { NielsenSegment } from 'interfaces/generated.types';

export interface IListDmpSegmentsResponse {
  allDmpSegments: NielsenSegment[];
}

export const LIST_DMP_SEGMENTS = gql`
  query ListDmpSegments($type: DmpSegmentType!) {
    allDmpSegments(type: $type) {
      ... on NielsenSegment {
        id
        fullName
      }
    }
  }
`;

export const SEARCH_DMP_SEGMENTS = gql`
  query SearchDmpSegments($type: DmpSegmentType!, $text: String!) {
    searchDmpSegments(type: $type, text: $text) {
      ... on NielsenSegment {
        id
        fullName
      }
    }
  }
`;
