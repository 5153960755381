import React from 'react';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import EntityProgressStep, {
  IEntityProgressStep,
} from 'components/EntityProgressStep/EntityProgressStep';

import useStyles from './EntityProgressBar.styles';

interface IEntityProgressBarProps {
  statusData: IEntityProgressStep[];
  currentStatus: string;
  dataTc: string;
}

const EntityProgressBar = (props: IEntityProgressBarProps) => {
  const classes = useStyles();
  const { statusData, currentStatus, dataTc } = props;

  const activeIndex = statusData.findIndex(
    (data) => data.status === currentStatus
  );

  return (
    <div data-tc={dataTc} data-testid={dataTc}>
      <Stepper
        activeStep={activeIndex + 1}
        connector={
          <StepConnector
            classes={{
              root: classes.stepLine,
              active: `${classes.stepLine}--active`,
              completed: `${classes.stepLine}--completed`,
            }}
          />
        }
        classes={{ horizontal: classes.steps }}
      >
        {statusData.map((data, index) => (
          <Step key={data.status} classes={{ horizontal: classes.stepWrapper }}>
            <StepLabel
              classes={{ iconContainer: classes.iconContainer }}
              StepIconComponent={() => (
                <EntityProgressStep
                  data={data}
                  completed={index <= activeIndex}
                />
              )}
            >
              <></>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default EntityProgressBar;
