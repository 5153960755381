import { ReactComponent as PopupIcon } from 'assets/icons/dax-popupicon.svg';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import React, { useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';

import useStyles from './Contract.styles';

export interface IContractModalProps {
  testId: string;
  CustomDialog: React.ElementType<any>;
  customDialogProps?: any;
  buttonLabel?: string;
  readOnly?: boolean;
  disabled?: boolean;
  hasEditPermissions?: boolean;
}

const ContractModal = ({
  testId,
  CustomDialog,
  customDialogProps,
  buttonLabel,
  readOnly = false,
  disabled = false,
  hasEditPermissions = false,
}: IContractModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const classes = useStyles({});

  return (
    <>
      {buttonLabel ? (
        <StyledButton
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          onClick={() => setIsModalOpen(true)}
          disabled={disabled}
        >
          {buttonLabel}
        </StyledButton>
      ) : (
        <IconButton
          onClick={() => setIsModalOpen(true)}
          data-testid={`${testId}-viewButton`}
          className={classes.iconButton}
          disableRipple
          disabled={disabled}
        >
          <div className={classes.svgWrapper}>
            <PopupIcon className={classes.svg} />
          </div>
          <Typography variant="body1">
            {hasEditPermissions ? 'view/edit' : 'view'}
          </Typography>
        </IconButton>
      )}
      {isModalOpen && (
        <CustomDialog
          {...customDialogProps}
          handleClose={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
          readOnly={readOnly}
          hasEditPermissions={hasEditPermissions}
        />
      )}
    </>
  );
};

export default ContractModal;
