import React, { useEffect, useState, lazy, Suspense } from 'react';
import useStyles from 'components/DonutChart/DonutChart.styles';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { History } from 'history';
import Loader from 'components/Loader/Loader';
import { IChartSegmentData } from 'interfaces';
import { Filters, Row } from 'react-table';
import { useLazyQuery } from 'react-apollo';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';

import {
  CampaignGraphData,
  ChartIdType,
  formatCampaignGraphData,
  getConfigForCampaignDeliveryCharts,
} from './CampaignsHealthOverviewValues';
import {
  onSegmentSelection,
  updateHistoryWithSelectedSegment,
} from './CampaignsHealthOverviewUtils';
import { GET_ALL_CAMPAIGNS_FOR_GRAPH_DATA } from '../../graphql/queries';

const SingleStackedBarChart = lazy(
  () => import('components/StackedBarChart/SingleStackedBarChart')
);

export interface ICampaignsHealthOverviewBarProps {
  data?: any[];
  tableFilters: Filters<Row<Record<string, unknown>>>;
  campaignsFilter?: any;
  history?: History;
  setAllFilters: (filters: any[]) => void;
}

const CampaignsHealthOverviewBar = ({
  data,
  tableFilters,
  campaignsFilter,
  history,
  setAllFilters,
}: ICampaignsHealthOverviewBarProps) => {
  const classes = useStyles();
  const location = usePreviousLocation();
  const { table } = location.state || 0;
  const segmentStoredInHistory = table?.customHeader?.selectedSegment;

  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(
    segmentStoredInHistory || ''
  );
  const [graphData, setGraphData] = useState<any[]>(data || []);

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const queryVariables = campaignsFilter
    ? {
        variables: { filter: campaignsFilter, territories: [activeTerritory!] },
      }
    : undefined;

  const [
    fetchGraphData,
    { loading: fetchingData, error: fetchingError },
  ] = useLazyQuery<{
    allCampaigns: CampaignGraphData[];
  }>(GET_ALL_CAMPAIGNS_FOR_GRAPH_DATA, {
    ...queryVariables,
    onCompleted: (result) => {
      if (result?.allCampaigns) {
        setGraphData(formatCampaignGraphData(result?.allCampaigns));
      }
    },
  });

  const campaignsPageChartOrder: ChartIdType[] = ['overallDelivery'];

  const charts = getConfigForCampaignDeliveryCharts(
    graphData,
    selectedSegmentId,
    campaignsPageChartOrder
  );

  const handleSegmentSelection = (segment: IChartSegmentData | undefined) => {
    onSegmentSelection(
      segment,
      location,
      setSelectedSegmentId,
      setAllFilters,
      history
    );
  };

  useEffect(() => {
    if (tableFilters.length === 0) {
      setSelectedSegmentId('');
      updateHistoryWithSelectedSegment('', location, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters]);

  useEffect(() => {
    if (!data && fetchGraphData) {
      fetchGraphData();
    }
  }, [data, campaignsFilter, fetchGraphData]);

  return (
    <>
      {fetchingData ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div
          className={classes.graphsWrapper}
          data-testid="campaignsHealthOverview-bar"
        >
          {fetchingError ? (
            <div>There was an error loading the graph data</div>
          ) : (
            <>
              {charts.map((chart) => (
                <Suspense fallback={<Loader />} key={chart.id}>
                  <SingleStackedBarChart
                    label={chart.label}
                    category="Overall Delivery"
                    segments={chart.segments}
                    onClick={handleSegmentSelection}
                    testId={`${chart.id}CampaignsChart`}
                  />
                </Suspense>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CampaignsHealthOverviewBar;
