import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const contractStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  container: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  iconButton: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  svgWrapper: {
    display: 'flex',
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(0.5),
  },
  svg: {
    width: '100%',
    height: 'auto',
  },
  modalTitle: {
    ...baseDialogStyles(theme).title,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
}));

export default contractStyles;
