import StyledButton, {
  ButtonVariantEnum,
} from 'features/targetingV2/components/core/StyledButton/StyledButton';
import React from 'react';

import useStyles from './AddParameterButton.styles';

const AddParameterButton = ({
  onClick,
  disabled,
  testId,
}: {
  onClick: () => void;
  disabled?: boolean;
  testId?: string;
}) => {
  const classes = useStyles();

  return (
    <StyledButton
      variant={ButtonVariantEnum.Text}
      disabled={disabled}
      onClick={onClick}
      testId={testId}
      className={classes.addButton}
    >
      Add
    </StyledButton>
  );
};

export default AddParameterButton;
