import { makeStyles, Theme } from '@material-ui/core/styles';

import { basePageStyles } from 'assets/styles/components/Page.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  toast: {
    backgroundColor: theme.palette.secondary.main,
  },
  message: {
    textAlign: 'center',
    width: '100%',
    color: theme.palette.common.white,
  },
  customButtonWrapper: {
    textAlign: 'right',
    margin: '1em 0',
  },
}));

export default useStyles;
