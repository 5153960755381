import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import AgenciesTable from 'features/direct/agency/components/AgenciesTable/AgenciesTable';
import { History } from 'history';
import {
  Agency,
  PermissionName,
  useAllAgenciesQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface IAgencyPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const AgenciesPage = ({ history, userPermissions }: IAgencyPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllAgenciesQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  const classes = pageStyles();

  if (!loading && error && !(data && data.allAgencies))
    return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Agencies</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <AgenciesTable
              allAgencies={data?.allAgencies as Agency[]}
              history={history}
              isEditable={hasEditPermissions}
              activeTerritory={activeTerritory!}
            />
          )}
        </div>
      </Container>
    </main>
  );
};

export default AgenciesPage;
