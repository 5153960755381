import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dropdownField: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    textAlign: 'center',
    fontSize: '15px',
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
    },
    width: '127px',
    height: '32px',
  },
}));

export default useStyles;
