import {
  endOfToday,
  endOfYesterday,
  isFuture,
  startOfToday,
  startOfYesterday,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import getDate from 'date-fns/getDate';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import isBefore from 'date-fns/isBefore';
import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isValid from 'date-fns/isValid';
import setDate from 'date-fns/setDate';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setMonth from 'date-fns/setMonth';
import setYear from 'date-fns/setYear';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import { IDateRange } from 'interfaces';
import isNull from 'lodash/isNull';
import { useEffect, useState } from 'react';

function cloneDate(date: Date) {
  return new Date(date.getTime());
}

function withClone(date: Date, handler: any) {
  const clone = cloneDate(date);
  handler(clone);
  return clone;
}

function cloneAndSetDate(date: Date, value: number) {
  return withClone(date, (c: Date) => c.setDate(value));
}

function cloneAndSetMonth(date: Date, value: number) {
  return withClone(date, (c: Date) => c.setMonth(value));
}

function isValidDate(x: Date, disablePast = false, disableFuture = false) {
  if (disablePast) return !isPast(x) && isValid(x);
  if (disableFuture) return !isFuture(x) && !isToday(x) && isValid(x);

  return isValid(x);
}

function isValidDateRange(
  startDate: Date,
  endDate: Date,
  disablePast = false,
  disableFuture = false
) {
  return (
    isValidDate(startDate, disablePast, disableFuture) &&
    isValidDate(endDate, disablePast, disableFuture) &&
    (isBefore(startDate, endDate) || isSameDay(startDate, endDate))
  );
}

function isDateInRange(date: Date, start: Date, end: Date) {
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}

export function isSameMonthAndYear(x1: Date, x2: Date) {
  return (
    isValidDate(x1) &&
    isValidDate(x2) &&
    x1.getMonth() === x2.getMonth() &&
    x1.getFullYear() === x2.getFullYear()
  );
}

function notNull(x: any) {
  return !isNull(x);
}

function createCommonFunctions() {
  return { isSameDay, isSameMonthAndYear, isToday };
}

function createCursorFunctions(cursor: Date, setCursor: any) {
  function startOfMonth(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      1,
      date.getHours(),
      date.getMinutes()
    );
  }

  function moveCursorOneMonthBackward() {
    const c = cloneAndSetMonth(startOfMonth(cursor), cursor.getMonth() - 1);
    setCursor(c);
  }

  function moveCursorOneMonthForward() {
    const c = cloneAndSetMonth(startOfMonth(cursor), cursor.getMonth() + 1);
    setCursor(c);
  }

  return {
    moveCursorOneMonthBackward,
    moveCursorOneMonthForward,
  };
}

function createSelectedFunctions(
  selected: Date,
  setSelected: (x: Date) => void,
  setCursor: (x: Date) => void,
  disablePast = false,
  disableFuture = false
) {
  function setSelectedAndCursor(x: Date) {
    if (isValidDate(x, disablePast, disableFuture)) {
      setSelected(x);
      setCursor(x);
    }
  }

  function moveSelectedDay(day: number) {
    setSelectedAndCursor(setDate(selected, day));
  }

  function moveSelectedOneDayForward() {
    setSelectedAndCursor(addDays(selected, 1));
  }

  function moveSelectedOneDayBackward() {
    setSelectedAndCursor(subDays(selected, 1));
  }

  function moveSelectedMonth(month: number) {
    setSelectedAndCursor(setMonth(selected, month - 1));
  }

  function moveSelectedOneMonthForward() {
    setSelectedAndCursor(addMonths(selected, 1));
  }

  function moveSelectedOneMonthBackward() {
    setSelectedAndCursor(subMonths(selected, 1));
  }

  function moveSelectedYear(year: number) {
    setSelectedAndCursor(setYear(selected, year));
  }

  function moveSelectedOneYearForward() {
    setSelectedAndCursor(addYears(selected, 1));
  }

  function moveSelectedOneYearBackward() {
    setSelectedAndCursor(subYears(selected, 1));
  }

  function moveSelectedHour(hour: number) {
    setSelectedAndCursor(setHours(selected, hour));
  }

  function moveSelectedOneHourForward() {
    setSelectedAndCursor(addHours(selected, 1));
  }

  function moveSelectedOneHourBackward() {
    setSelectedAndCursor(subHours(selected, 1));
  }

  function moveSelectedMinute(minute: number) {
    setSelectedAndCursor(setMinutes(selected, minute));
  }

  function moveSelectedOneMinuteForward() {
    setSelectedAndCursor(addMinutes(selected, 1));
  }

  function moveSelectedOneMinuteBackward() {
    setSelectedAndCursor(subMinutes(selected, 1));
  }

  return {
    setSelectedAndCursor,
    moveSelectedDay,
    moveSelectedOneDayForward,
    moveSelectedOneDayBackward,
    moveSelectedMonth,
    moveSelectedOneMonthForward,
    moveSelectedOneMonthBackward,
    moveSelectedYear,
    moveSelectedOneYearForward,
    moveSelectedOneYearBackward,
    moveSelectedHour,
    moveSelectedOneHourForward,
    moveSelectedOneHourBackward,
    moveSelectedMinute,
    moveSelectedOneMinuteForward,
    moveSelectedOneMinuteBackward,
  };
}

export function createWeeksFunctions(
  weeks: Date[][],
  cursor: Date,
  showNonCurrentDates: boolean
) {
  function forEachDay(loop: any, days: Date[]) {
    return days.map((day) => {
      const key = `day-${day.getTime()}`;
      const nonNull = showNonCurrentDates || isSameMonthAndYear(cursor, day);
      const value = nonNull ? day.getDate() : null;
      return loop({ key, value, day });
    });
  }

  function forEachWeek(loop: any) {
    return weeks.map((days) => {
      const key = `week-${days[0].getTime()}`;
      return loop({ key, days });
    });
  }

  return { forEachWeek, forEachDay };
}

function createWeeks(current: Date) {
  const weeksInAMonth = 6;
  const daysInAWeek = 7;
  const month = current.getMonth();
  const year = current.getFullYear();

  current.setDate(1);
  current.setDate(current.getDate() - current.getDay());

  return Array(weeksInAMonth)
    .fill(null)
    .map(() => {
      {
        const currentMonth = current.getMonth();
        const currentYear = current.getFullYear();

        const isNextMonth = month < currentMonth && year === currentYear;
        const isNextYear = year < currentYear;

        if (isNextMonth || isNextYear) return null;
      }

      const days = Array(daysInAWeek)
        .fill(null)
        .map((x, i) => cloneAndSetDate(current, current.getDate() + i));

      current.setDate(current.getDate() + daysInAWeek);

      return days;
    })
    .filter(notNull);
}

function createDateRangeFunctions(
  initialRange: IDateRange,
  selectedRangeStartDate: Date,
  setSelectedRangeStartDate: (x: Date) => void,
  selectedRangeEndDate: Date,
  setSelectedRangeEndDate: (x: Date) => void,
  rangeSelectionMode: string,
  setRangeSelectionMode: (x: string) => void,
  setCursor: (x: Date) => void,
  disablePast = false,
  disableFuture = false
) {
  function setSelectedDateForDateRange(selectedDate: Date) {
    if (!isValidDate(selectedDate, disablePast, disableFuture)) return;
    if (
      rangeSelectionMode === 'end' &&
      isValidDateRange(
        selectedRangeStartDate,
        selectedDate,
        disablePast,
        disableFuture
      )
    ) {
      setSelectedRangeEndDate(selectedDate);
      setRangeSelectionMode('start');
    } else {
      setSelectedRangeStartDate(selectedDate);
      setSelectedRangeEndDate(selectedDate);
      setRangeSelectionMode('end');
    }
  }

  function setSelectedDateRange(dateRange: IDateRange) {
    if (
      !isValidDate(dateRange.startDate, disablePast, disableFuture) ||
      !isValidDate(dateRange.endDate, disablePast, disableFuture)
    )
      return;

    setSelectedRangeStartDate(dateRange.startDate);
    setSelectedRangeEndDate(dateRange.endDate);
    setRangeSelectionMode('start');
    setCursor(dateRange.endDate);
  }

  function resetSelectedRange() {
    if (
      isValidDateRange(
        initialRange.startDate,
        initialRange.endDate,
        disablePast,
        disableFuture
      )
    ) {
      setSelectedRangeStartDate(initialRange.startDate);
      setSelectedRangeEndDate(initialRange.endDate);
      setRangeSelectionMode('start');
    }
  }

  function setSelectedRangeToCurrentDate() {
    const currentDate = new Date();

    setSelectedRangeStartDate(currentDate);
    setSelectedRangeEndDate(currentDate);
    setRangeSelectionMode('start');

    setCursor(currentDate);
  }

  function setCursorToSelectedRangeStart() {
    setCursor(selectedRangeStartDate);
  }

  function isDateInSelectedRange(date: Date) {
    return isDateInRange(date, selectedRangeStartDate, selectedRangeEndDate);
  }

  return {
    setSelectedDateForDateRange,
    setSelectedDateRange,
    resetSelectedRange,
    setSelectedRangeToCurrentDate,
    setCursorToSelectedRangeStart,
    isDateInSelectedRange,
  };
}

export default function useDateTimePicker({
  initial,
  showNonCurrentDates,
  disablePast = false,
  disableFuture = false,
  initialRange,
}: {
  initial?: Date;
  showNonCurrentDates: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  initialRange?: {
    startDate: Date;
    endDate: Date;
  };
}) {
  const date =
    initial && isValidDate(initial, disablePast, disableFuture)
      ? cloneDate(initial)
      : new Date();

  const range =
    initialRange &&
    isValidDateRange(
      initialRange.startDate,
      initialRange.endDate,
      disablePast,
      disableFuture
    )
      ? {
          startDate: cloneDate(initialRange.startDate),
          endDate: cloneDate(initialRange.endDate),
        }
      : {
          startDate: disableFuture ? startOfYesterday() : startOfToday(),
          endDate: disableFuture ? endOfYesterday() : endOfToday(),
        };

  const [cursor, setCursor] = useState(date);
  const [selected, setSelected] = useState(date);
  const [selectedDay, setSelectedDay] = useState(getDate(selected));
  const [selectedMonth, setSelectedMonth] = useState(getMonth(selected) + 1);
  const [selectedYear, setSelectedYear] = useState(getYear(selected));
  const [selectedHour, setSelectedHour] = useState(getHours(selected));
  const [selectedMinute, setSelectedMinute] = useState(getMinutes(selected));

  const [selectedRangeStartDate, setSelectedRangeStartDate] = useState(
    range.startDate
  );
  const [selectedRangeEndDate, setSelectedRangeEndDate] = useState(
    range.endDate
  );
  const [rangeSelectionMode, setRangeSelectionMode] = useState('start');

  useEffect(() => {
    setSelectedDay(getDate(selected));
    setSelectedMonth(getMonth(selected) + 1);
    setSelectedYear(getYear(selected));
    setSelectedHour(getHours(selected));
    setSelectedMinute(getMinutes(selected));
  }, [selected]);

  return {
    cursor,
    selected,
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedHour,
    selectedMinute,
    selectedRangeStartDate,
    selectedRangeEndDate,
    ...createCommonFunctions(),
    ...createCursorFunctions(cursor, setCursor),
    ...createSelectedFunctions(
      selected,
      setSelected,
      setCursor,
      disablePast,
      disableFuture
    ),
    ...createWeeksFunctions(
      createWeeks(cloneDate(cursor)) as any,
      cursor,
      showNonCurrentDates
    ),
    ...createDateRangeFunctions(
      range,
      selectedRangeStartDate,
      setSelectedRangeStartDate,
      selectedRangeEndDate,
      setSelectedRangeEndDate,
      rangeSelectionMode,
      setRangeSelectionMode,
      setCursor,
      disablePast,
      disableFuture
    ),
  };
}
