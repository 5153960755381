import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: 'visible',
    marginTop: theme.spacing(-2.5),
  },
  popoverContent: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2, 3, 2, 2),
    position: 'relative',
    fontSize: '0.813em',
    '&::after, &::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '50%',
      width: '0',
      height: '0',
      borderStyle: 'solid',
    },
    '&::after': {
      top: '-20px',
      marginLeft: '-10px',
      borderColor: `transparent transparent ${theme.palette.grey[200]} transparent`,
      borderWidth: '10px',
    },
    '&::before': {
      top: '-23px',
      marginLeft: '-11px',
      borderColor: `transparent transparent ${theme.palette.grey[300]} transparent`,
      borderWidth: '11px',
    },
  },
  requiredList: {
    margin: 0,
    paddingInlineStart: theme.spacing(3),
  },
  status: {
    fontWeight: 500,
    margin: theme.spacing(0, 0, 1, 0),
    textAlign: 'center',
    fontSize: theme.spacing(1.4),
    color: theme.palette.grey[300],
    '&--completed': {
      color: theme.palette.common.black,
    },
  },
  stepIcon: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::after': {
      content: '""',
      display: 'block',
      width: '18px',
      height: '18px',
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[300],
    },
    '&--completed::after': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
