import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UpdateUserContainer from 'features/user/containers/UpdateUserContainer/UpdateUserContainer';
import { History } from 'history';
import { User, useUserQuery } from 'interfaces/generated.types';
import React from 'react';

interface IUpdateUserPageProps {
  history: History;
  match: {
    params: {
      userId: string;
    };
  };
}

const UpdateUserPage = ({ history, match }: IUpdateUserPageProps) => {
  const { loading, data } = useUserQuery({
    variables: { id: match.params.userId },
    errorPolicy: 'all',
  });

  if (!loading && !data?.user) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update User Page</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateUserContainer
            history={history}
            user={data?.user as User}
            match={match}
          />
        )}
      </Container>
    </main>
  );
};
export default UpdateUserPage;
