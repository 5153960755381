import { useState } from 'react';

export default function useError(initialErrorMessages = ['']) {
  const [hasError, setErrorModal] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>(
    initialErrorMessages
  );
  const toggleErrorModal = () => {
    setErrorModal((prevState: boolean) => !prevState);
  };
  return {
    hasError,
    setErrorModal,
    toggleErrorModal,
    errorMessages,
    setErrorMessages,
  };
}
