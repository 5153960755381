import mediaLibraryStyles from 'assets/styles/components/MediaLibrary.styles';
import CreativeLink from 'components/CreativeLink/CreativeLink';
import DownloadCreativeButton from 'components/DownloadCreativeButton/DownloadCreativeButton';
import Table from 'components/Table/Table';
import { dateSort } from 'components/Table/TableSort';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  CreativeImage,
  CreativeType,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

import Checkbox from '@material-ui/core/Checkbox';

interface ICreativeImageRow {
  id: string;
  name: string;
  fileName: string;
  url: string;
  uploadedDate: string;
  resourceAltId: number;
}

export const formatImageData = memoizeOne(
  (data: CreativeImage[], territory: Territory | undefined) =>
    data.map((d) => ({
      id: d.id,
      name: d.name,
      url: d.url,
      resourceAltId: d.resourceAltId || null,
      uploadedDate: d.uploadedDate
        ? getFormattedDateBasedOnTerritory(new Date(d.uploadedDate), territory)
        : null,
    }))
);

const ImageLibrary = ({
  data,
  selectedImageIds,
  handleChange,
}: {
  data: CreativeImage[];
  selectedImageIds: string[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const classes = mediaLibraryStyles();
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Select',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeImageRow>) => (
          <Checkbox
            data-tc="creativeImageCheckbox"
            // eslint-disable-next-line react/prop-types
            checked={selectedImageIds.includes(
              row.original.resourceAltId.toString()
            )}
            onChange={handleChange}
            value={row.original.resourceAltId}
            name="imageCreative"
            color="primary"
          />
        ),
      },
      {
        Header: 'Image',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeImageRow>) => (
          <CreativeLink
            url={row.original.url}
            fileName={row.original.fileName}
            type={CreativeType.Image}
            dataTc="creativePageImageThumbnail"
          />
        ),
      },
      {
        Header: 'Id',
        accessor: 'resourceAltId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          maxWidth: '300px',
        },
      },
      {
        Header: 'Uploaded Date',
        accessor: 'uploadedDate',
        sortType: dateSort,
      },
      {
        Header: 'Action',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICreativeImageRow>) => (
          <DownloadCreativeButton
            url={row.original.url}
            name={row.original.name}
          />
        ),
      },
    ],
    [selectedImageIds, handleChange]
  );
  return (
    <div className={classes.form}>
      <Table
        columns={columns}
        data={formatImageData(data, activeTerritory)}
        dataTc="imageLibrary"
        showColumnToggle={false}
      />
    </div>
  );
};

export default ImageLibrary;
