import colors from 'assets/styles/colors';

import { createStyles, Theme } from '@material-ui/core/styles';

const targetingTooltipStyles = (theme: Theme) =>
  createStyles({
    tooltipIcon: {
      color: colors.primaryBlue,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: 0,
      maxWidth: '480px',
      borderRadius: '8px',
      boxShadow: `0px 4px 8px ${colors.boxShadowLightGrey}`,
      position: 'relative',
      bottom: '13px',
    },
  });

export default targetingTooltipStyles;
