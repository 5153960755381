import React from 'react';
import { History } from 'history';
import { IChartSegmentData, ILocation } from 'interfaces';

export const updateHistoryWithSelectedSegment = (
  segmentId: string,
  location: ILocation,
  history?: History
) => {
  const { table } = location.state || 0;

  history &&
    history.push({
      pathname: window.location.pathname,
      state: {
        ...location.state,
        table: {
          ...table,
          customHeader: { selectedSegment: segmentId },
        },
      },
    });
};

export const onSegmentSelection = (
  segment: IChartSegmentData | undefined,
  location: ILocation,
  setSelectedSegment: React.Dispatch<React.SetStateAction<string>>,
  setFilters: (filters: any[]) => void,
  history?: History
) => {
  const segmentId = segment?.id || '';

  setSelectedSegment(segmentId);
  updateHistoryWithSelectedSegment(segmentId, location, history);

  const selectedFilters = segment ? segment.filters : [];
  setFilters(selectedFilters);
};
