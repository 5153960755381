import Loader from 'components/Loader/Loader';
import CategoryItem from 'features/insights/components/CategoryItem/CategoryItem';
import {
  PublisherInsightsFilterCategory,
  useGetPublisherInsightsFilterCategoriesQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { ISelectedFilter } from 'utils/insights';

interface ICategoriesContainerProps {
  activeFilterCategory: PublisherInsightsFilterCategory | null;
  setActiveFilterCategory: (
    arg0: PublisherInsightsFilterCategory | null
  ) => void;
  selectedFilters: ISelectedFilter[];
}

const CategoriesContainer = ({
  activeFilterCategory,
  setActiveFilterCategory,
  selectedFilters,
}: ICategoriesContainerProps) => {
  const { loading, data } = useGetPublisherInsightsFilterCategoriesQuery({});

  const handleClick = (filterCategory: PublisherInsightsFilterCategory) => {
    if (activeFilterCategory?.type === filterCategory.type) {
      setActiveFilterCategory(null);
    } else {
      setActiveFilterCategory(filterCategory);
    }
  };

  const getFilterValueCount = (
    filterCategory: PublisherInsightsFilterCategory,
    filters: ISelectedFilter[]
  ) => {
    const filter = filters.find(
      (selectedFilter) =>
        selectedFilter.filterCategory.type === filterCategory.type
    );

    return filter ? filter.ids.length : 0;
  };

  if (!loading && !data?.publisherInsightsFilterCategories)
    return <p>Error in loading filters.</p>;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div data-testid="filterTreeCategoriesContainer">
          {data?.publisherInsightsFilterCategories?.map(
            (option: PublisherInsightsFilterCategory) => (
              <CategoryItem
                key={option.type}
                filterCategory={option}
                handleClick={handleClick}
                isCategoryClicked={activeFilterCategory?.type === option.type}
                valuesCount={getFilterValueCount(option, selectedFilters)}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default CategoriesContainer;
