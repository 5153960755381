import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(3, 0, 0, 0),
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
