import axios from 'axios';
import classNames from 'classnames';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { CreativeStatusEnum } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import useError from 'hooks/Error/useError';
import { IFormProps } from 'interfaces';
import { CreativeType, TrackingUrl } from 'interfaces/generated.types';
import flatten from 'lodash/flatten';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import Dropzone, { FileRejection } from 'react-dropzone';
import creativeUtils from 'utils/creatives';
import fileUtils, {
  getImageDimensions,
  isFileSizeValid,
  isFileTypeValid,
  isImageSizeValid,
} from 'utils/files';

import Paper from '@material-ui/core/Paper';

import useStyles from './CreativeUploadContainer.styles';

export interface ICreativeValues {
  adId: string;
  adName: string;
  creativeStatus: CreativeStatusEnum;
  creativeFile: File | null;
  creativeUrl: string;
  creativeName: string;
  creativeId: string;
}

interface ICreativeFile {
  file: File;
  status: CreativeStatusEnum;
  name: string;
  fileName: string;
  url?: string;
  trackingUrls?: TrackingUrl[];
  dimensions?: string;
  duration?: any;
  landingPageUrl?: string;
  uploadedDate?: string;
}

export const setErrors = async (file: File, creativeType: CreativeType) => {
  const errors: string[] = [];

  if (!isFileSizeValid(file.size, creativeType)) {
    if (creativeType === CreativeType.Image) {
      errors.push(
        `It looks like ${file.name} is too large a file. Display ads can only be up to 512 KB. Please try another file.`
      );
    } else {
      errors.push(
        `It looks like ${file.name} is too large a file. Audio ads can only be up to 50 MB. Please try another file.`
      );
    }
  }
  if (!isFileTypeValid(file.type, creativeType)) {
    if (creativeType === CreativeType.Image) {
      errors.push(
        `It looks like ${file.name} is the incorrect filetype/format. Display can only upload JPEG, JPG or PNG. Please try another file.`
      );
    } else {
      errors.push(
        `It looks like ${file.name} is the incorrect filetype/format. Audio can only upload MP3 or WAV. Please try another file.`
      );
    }
  }

  const imageSizeValid = await isImageSizeValid(file, creativeType);
  if (!imageSizeValid) {
    errors.push(
      `It looks like ${file.name} has the incorrect dimensions. Display ads can only have dimensions of 300x250, 272x272 or 400x400. Please try another file.`
    );
  }

  return errors;
};

export const handlePreUpload = ({
  file,
  url,
  contentType,
  setErrorMessages,
  setErrorModal,
}: {
  file: File;
  url: string;
  contentType: string;
  setErrorMessages: any;
  setErrorModal: (value: boolean) => void;
}) => {
  const options = {
    headers: {
      'Content-Type': contentType,
      'Content-Disposition': `attachment; filename=${file.name};`,
    },
  };

  const formattedFile = new File([file], file.name, {
    lastModified: file.lastModified,
    type: contentType,
  });

  return axios
    .put(url, formattedFile, options)
    .then(() => CreativeStatusEnum.NewCreativeUploaded)
    .catch(() => {
      const errors = [
        'Something went wrong and one or more creatives could not be uploaded. Please try again later.',
      ];
      setErrorMessages(errors);
      setErrorModal(true);
      return CreativeStatusEnum.NoCreative;
    });
};

export const handleFiles = async ({
  advertiserId,
  files,
  client,
  creativeType,
  attribute,
  creatives,
  setFieldValue,
  setErrorMessages,
  setErrorModal,
}: {
  advertiserId: string;
  files: File[];
  client: any;
  creativeType: CreativeType;
  attribute: string;
  creatives: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setErrorMessages: any;
  setErrorModal: (value: boolean) => void;
}) => {
  const validCreativeFiles: ICreativeFile[] = [];
  const errors: string[][] = [];
  for (const file of files) {
    if (
      file &&
      isFileSizeValid(file.size, creativeType) &&
      isFileTypeValid(file.type, creativeType) &&
      (await isImageSizeValid(file, creativeType))
    ) {
      validCreativeFiles.push({
        file,
        status: CreativeStatusEnum.Uploading,
        name: file.name,
        fileName: file.name,
      });
    } else {
      errors.push(await setErrors(file, creativeType));
    }
  }

  const formattedErrors = flatten(errors);
  if (formattedErrors.length) {
    setErrorMessages(formattedErrors);
    setErrorModal(true);
  }

  if (validCreativeFiles.length) {
    setFieldValue(attribute, [...creatives, ...validCreativeFiles]);
    const uploadedFiles: ICreativeFile[] = [];
    for (const creative of validCreativeFiles) {
      const { url, assetUrl, contentType, uploadedDate } =
        await creativeUtils.generateCreativeUrl({
          advertiserId,
          file: creative.file,
          client,
        });
      const uploadedStatus = await handlePreUpload({
        file: creative.file,
        url,
        contentType,
        setErrorMessages,
        setErrorModal,
      });
      if (uploadedStatus === CreativeStatusEnum.NewCreativeUploaded) {
        let uploadedCreative: ICreativeFile = {
          ...creative,
          status: uploadedStatus,
          url: assetUrl,
          trackingUrls: [{ consentVendorId: '', url: '' }],
          uploadedDate,
        };
        if (creativeType === CreativeType.Image) {
          uploadedCreative = {
            ...uploadedCreative,
            landingPageUrl: '',
            dimensions: await getImageDimensions(creative.file),
          };
        }
        if (creativeType === CreativeType.Audio) {
          uploadedCreative = {
            ...uploadedCreative,
            duration: await fileUtils.getAudioDuration(url, client),
          };
        }
        uploadedFiles.push(uploadedCreative);
      }
    }
    setFieldValue(attribute, [...creatives, ...uploadedFiles]);
  }
};

export const dropRejectedFiles = async (
  files: File[],
  creativeType: CreativeType,
  setErrorMessages: any,
  setErrorModal: (value: boolean) => void
) => {
  const promiseErrors = await files.map(async (file: File) =>
    setErrors(file, creativeType)
  );

  await Promise.all(promiseErrors).then((errors) => {
    const formattedErrors = flatten(errors);
    if (formattedErrors.length) {
      setErrorMessages(formattedErrors);
      setErrorModal(true);
    }
    return formattedErrors;
  });
};

export interface ICreativeUploadContainerProps {
  isMulti: boolean;
  acceptedTypes: string;
  maxFileSize: number;
  creativeType: CreativeType;
  attribute: string;
  uploadLimit?: number;
  advertiserId: string;
  dataTc: string;
}

const CreativeUploadContainer = (
  props: IFormProps<any> & ICreativeUploadContainerProps
) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    isMulti,
    acceptedTypes,
    maxFileSize,
    creativeType,
    attribute,
    uploadLimit,
    advertiserId,
    dataTc,
  } = props;

  const {
    hasError,
    toggleErrorModal,
    setErrorModal,
    errorMessages,
    setErrorMessages,
  } = useError([]);
  const classes = useStyles();

  const isDisabled =
    (!!uploadLimit && values[attribute].length >= uploadLimit) || !advertiserId;

  return (
    <ApolloConsumer>
      {(client: any) => (
        <div
          role="button"
          tabIndex={-1}
          data-tc={`${dataTc}Wrapper`}
          onClick={() => isDisabled && setFieldTouched(attribute, true)}
          onDragEnter={() => isDisabled && setFieldTouched(attribute, true)}
          onKeyPress={() => isDisabled && setFieldTouched(attribute, true)}
        >
          <Dropzone
            data-tc={dataTc}
            onDropAccepted={(files: File[]) => {
              handleFiles({
                advertiserId,
                files,
                client,
                creativeType,
                attribute,
                creatives: values[attribute],
                setFieldValue,
                setErrorMessages,
                setErrorModal,
              });
            }}
            onDropRejected={(fileRejections: FileRejection[]) => {
              const files = fileRejections.map(
                (fileRejection) => fileRejection.file
              );
              dropRejectedFiles(
                files,
                creativeType,
                setErrorMessages,
                setErrorModal
              );
            }}
            onFileDialogCancel={() => setFieldTouched(attribute, true)}
            disabled={isDisabled}
            multiple={isMulti}
            accept={acceptedTypes}
            maxSize={maxFileSize}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <Paper elevation={0} className={classes.card}>
                <div
                  {...(getRootProps() as any)}
                  className={classNames([
                    classes.upload,
                    {
                      [`${classes.upload}--disabled`]: isDisabled,
                    },
                  ])}
                >
                  <input
                    {...(getInputProps() as any)}
                    data-tc={`${dataTc}Input`}
                    data-testid="creativeDropzoneInput"
                    onChange={(e: any) => {
                      handleFiles({
                        advertiserId,
                        files: [...e.target.files],
                        client,
                        creativeType,
                        attribute,
                        creatives: values[attribute],
                        setFieldValue,
                        setErrorMessages,
                        setErrorModal,
                      });
                    }}
                  />
                  {!isDragActive &&
                    'Click here to upload a creative or drag and drop the file here'}
                  {isDragActive && !isDragReject && 'Drop the file here'}
                  {isDragReject && 'The file is not in the correct format'}
                </div>
              </Paper>
            )}
          </Dropzone>
          <ErrorDialog
            content={{
              title: 'Error',
              closeButton: 'Close',
            }}
            isOpen={hasError}
            handleClose={toggleErrorModal}
            dataTc={dataTc}
            errorMessages={errorMessages}
          />
        </div>
      )}
    </ApolloConsumer>
  );
};

export default CreativeUploadContainer;
