import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreateOrderContainer from 'features/direct/order/containers/CreateOrderContainer/CreateOrderContainer';
import { History } from 'history';
import {
  Advertiser,
  PermissionName,
  useAllAdvertisersQuery,
  useAllUsersQuery,
  User,
} from 'interfaces/generated.types';
import React from 'react';
import { allTerritories } from 'utils/territory';

interface ICreateOrderPage {
  history: History;
  currentUser: User;
}

const CreateOrderPage = ({ history, currentUser }: ICreateOrderPage) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllAdvertisersQuery({
    variables: {
      territories: [activeTerritory!],
    },
  });

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Direct, PermissionName.Primary],
      territories: allTerritories,
    },
  });

  if (!loading && (error || !(data && data.allAdvertisers)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Order</h1>
        {loading || loadingAllUsers ? (
          <Loader />
        ) : (
          <CreateOrderContainer
            history={history}
            allAdvertisers={data?.allAdvertisers as Advertiser[]}
            allUsers={(allUsersData?.allUsers as User[]) || []}
            currentUser={currentUser}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateOrderPage;
