import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { dealStatusValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import { History } from 'history';
import { Deal, DealStatus, Territory } from 'interfaces/generated.types';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue, removeEmailDomain } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

interface ILinkedDealsRow {
  id: string;
  altId: number;
  name: string;
  salesChannelName: string;
  salesChannelId: string;
  priority: number;
  owner: string;
  startDate: string;
  endDate: string;
  status: DealStatus;
}

export const formatLinkedDealsData = (
  deals: Deal[],
  territory: Territory | undefined
) =>
  deals.map((deal) => ({
    id: deal.id,
    altId: deal.altId,
    name: deal.name as string,
    salesChannelName: deal.salesChannel?.name as string,
    salesChannelId: deal.salesChannel?.id,
    startDate: deal.startDate
      ? getFormattedDateBasedOnTerritory(
          dateUtils.getDateInSpecificTimezone(deal.startDate, deal.timeZone),
          territory
        )
      : '',
    endDate: deal.endDate
      ? getFormattedDateBasedOnTerritory(
          dateUtils.getDateInSpecificTimezone(deal.endDate, deal.timeZone),
          territory
        )
      : '',
    priority: deal.priority,
    status: deal.status,
    owner: deal.salesChannel?.owner?.email
      ? removeEmailDomain(deal.salesChannel?.owner?.email)
      : '',
  }));

const LinkedDealsTable = ({
  deals,
  history,
}: {
  deals: Deal[];
  history: History;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          minWidth: '140px',
        },
        Cell: ({ cell: { value, row } }: CellProps<ILinkedDealsRow>) =>
          TableLink({
            name: value,
            location: `/deal/${row.original.id}`,
          }),
      },
      {
        Header: 'Parent Sales Channel',
        accessor: 'salesChannelName',
        style: {
          wordBreak: 'break-word',
          minWidth: '140px',
        },
        Cell: ({ cell: { value, row } }: CellProps<ILinkedDealsRow>) =>
          TableLink({
            name: value,
            location: `/sales-channel/${row.original.salesChannelId}`,
          }),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
      },
      {
        Header: 'Priority',
        accessor: 'priority',
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        style: {
          wordBreak: 'break-word',
          width: '140px',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        Cell: ({ cell: { value } }: CellProps<ILinkedDealsRow>) =>
          findLabelValue({
            collection: dealStatusValues,
            lookupValue: value,
          }),
      },
    ],
    []
  );
  return (
    <Table
      history={history}
      columns={columns}
      dataTc="listLinkedDealsTable"
      data={formatLinkedDealsData(deals, activeTerritory)}
      isPageTable={false}
    />
  );
};

export default LinkedDealsTable;
