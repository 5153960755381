import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  packWrapper: {
    margin: theme.spacing(4, 1.5, 0),
  },
  error: {
    fontSize: '0.625em',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
