import classNames from 'classnames';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';

import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import ErrorToast from 'components/ErrorToast/ErrorToast';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { creativeStatusValues } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import { ICreativesRow } from 'features/direct/creative/components/CreativesTable/Creatives.values';
import { GENERATE_CREATIVE_URL } from 'features/direct/creative/graphql/queries';

import { CreativeType } from 'interfaces/generated.types';

import creativeUtils from 'utils/creatives';
import { findLabelValue } from 'utils/dataTransformation';
import { getCreativeFileName } from 'utils/urls';

import useStyles from './CreativeDetailsModal.styles';

interface ICreativeDetailsModalProps {
  creative: ICreativesRow;
  type: CreativeType;
}

const CreativeDetailsModal = (props: ICreativeDetailsModalProps) => {
  const { creative, type } = props;

  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isToastOpen, setToastOpen] = useState(false);
  const [creativeUrl, setCreativeUrl] = useState('');

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <button
            type="button"
            className={classes.modalButton}
            onClick={async () => {
              setModalOpen(!isModalOpen);
              const { data } = await client.query({
                query: GENERATE_CREATIVE_URL,
                variables: {
                  fileName: getCreativeFileName(creative.url),
                  httpMethod: 'GET',
                },
              });

              const preSignedUrl = data?.generatePreSignedUrl?.url || '';

              setCreativeUrl(preSignedUrl);
            }}
            data-tc="creativeModalButton"
          >
            {creative.name}
          </button>
          {isModalOpen && (
            <>
              <Dialog
                data-tc="creativeModal"
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                maxWidth="xl"
              >
                <DialogTitle
                  classes={{ root: classes.title }}
                  disableTypography
                >
                  <Typography display="inline">
                    View {type === CreativeType.Audio ? 'Audio' : 'Display'}{' '}
                    Creative
                  </Typography>
                  <IconButton
                    onClick={() => setModalOpen(false)}
                    classes={{ root: classes.closeButton }}
                    data-tc="creativeModalCloseX"
                  >
                    <CloseIcon />
                    <span className="sr-only">
                      Close the{' '}
                      {type === CreativeType.Audio ? 'Audio' : 'Display'}{' '}
                      creative
                    </span>
                  </IconButton>
                </DialogTitle>
                <DialogContent classes={{ root: classes.content }}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item sm={7}>
                      <dl className={classes.descriptionList}>
                        <dt className={classes.descriptionTitle}>Filename:</dt>
                        <dd className={classes.descriptionContent}>
                          {creative.name}
                        </dd>
                        <dt className={classes.descriptionTitle}>
                          Advertiser:
                        </dt>
                        <dd className={classes.descriptionContent}>
                          {creative.advertiser}
                        </dd>

                        {type === CreativeType.Image && (
                          <>
                            <dt className={classes.descriptionTitle}>
                              Mime Type:
                            </dt>
                            <dd className={classes.descriptionContent}>
                              {creative.mimeType}
                            </dd>
                            <dt className={classes.descriptionTitle}>
                              Image Dimensions:
                            </dt>
                            <dd className={classes.descriptionContent}>
                              {creative.size} pixels
                            </dd>
                          </>
                        )}

                        <dt className={classes.descriptionTitle}>Status:</dt>
                        <dd className={classes.descriptionContent}>
                          {findLabelValue({
                            collection: creativeStatusValues,
                            lookupValue: creative.status,
                          })}
                        </dd>
                      </dl>
                    </Grid>
                    <Grid item sm={5}>
                      <>
                        {type === CreativeType.Audio &&
                          (creativeUrl ? (
                            <div className={classes.audioWrapper}>
                              <AudioPlayer
                                src={creativeUrl}
                                fullLength
                                data-tc="creativeAudioPlayer"
                              />
                            </div>
                          ) : (
                            <AudiotrackIcon
                              data-tc="displayIcon"
                              className={classes.creativeIcon}
                            />
                          ))}
                        {type === CreativeType.Image &&
                          (creativeUrl ? (
                            <img
                              src={creativeUrl}
                              alt={creative.name}
                              className={classes.modalImage}
                              data-tc="creativeImageThumbnail"
                            />
                          ) : (
                            <ImageIcon
                              data-tc="displayIcon"
                              className={classes.creativeIcon}
                            />
                          ))}
                      </>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  classes={{
                    root: classNames(
                      classes.actions,
                      `${classes.actions}--space-between`
                    ),
                  }}
                >
                  <StyledButton
                    onClick={() => setModalOpen(false)}
                    color={ButtonColorEnum.Primary}
                    variant={ButtonVariantEnum.Outlined}
                    data-tc="creativeModalClose"
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    onClick={() => {
                      if (creativeUtils.isValidCreativeUrl(creativeUrl)) {
                        FileSaver.saveAs(creativeUrl, creative.fileName);
                      } else {
                        setToastOpen(true);
                      }
                    }}
                    disabled={!creativeUrl}
                    color={ButtonColorEnum.Primary}
                    variant={ButtonVariantEnum.Contained}
                    data-tc="downloadCreative"
                  >
                    Download Creative
                  </StyledButton>
                </DialogActions>
              </Dialog>
              <ErrorToast
                isToastOpen={isToastOpen}
                setToastOpen={setToastOpen}
              />
            </>
          )}
        </>
      )}
    </ApolloConsumer>
  );
};

export default CreativeDetailsModal;
