import DAXLogo from 'assets/images/DAX_logo_white.png';
import Image from 'components/Image/Image';
import NavigationDropdown from 'components/Navigation/NavigationDropdown/NavigationDropdown';
import NavigationLink from 'components/Navigation/NavigationLink/NavigationLink';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import { INavigationLink, INavigationOption } from 'interfaces/navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import {
  getNavigationOptions,
  getSearchOption,
  getSettingsOption,
  getTerritoryOption,
} from 'utils/navigation';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import useStyles from './NavigationContainer.styles';

const NavigationContainer = () => {
  const classes = useStyles();

  const userPermissions = useUserPermissions();
  const navigationOptions = getNavigationOptions(userPermissions);
  const showOptionsAsDropdowns = navigationOptions.length > 1;

  const searchOption = getSearchOption(userPermissions);
  const territoryOption = getTerritoryOption(userPermissions);
  const settingsOption = getSettingsOption(
    userPermissions,
    !searchOption && !territoryOption
  );

  return (
    <>
      <Link to="/" data-tc="headerLogo" className={classes.logo}>
        <Image src={DAXLogo} alt="DAX Audio logo" />
      </Link>
      <nav className={classes.root} data-tc="navigation">
        <AppBar className={classes.appbar} position="static">
          <Toolbar className={classes.toolbar}>
            {navigationOptions.map(({ title, links }: INavigationOption) => {
              if (showOptionsAsDropdowns)
                return (
                  <NavigationDropdown
                    key={shortid.generate()}
                    title={title}
                    links={links}
                  />
                );

              return links.map((link: INavigationLink) => (
                <NavigationLink key={shortid.generate()} link={link} />
              ));
            })}
            {searchOption}
            {territoryOption}
            {settingsOption}
          </Toolbar>
        </AppBar>
      </nav>
    </>
  );
};

export default NavigationContainer;
