import classNames from 'classnames';
import { OptionGroupType, OptionType } from 'interfaces';
import React, { ReactNode, Ref, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { VariableSizeList as List } from 'react-window';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';

import {
  asyncCustomStyles,
  customStyles,
  useStyles,
} from './MultiSelect.styles';

interface IMultiSelectProps {
  id: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  label: string;
  maxSelectHeight?: number | string;
  name: string;
  options: OptionGroupType[] | OptionType[];
  placeholder?: string;
  onBlur?(): void;
  onChange?(value: any): void;
  value: OptionType | OptionType[] | null;
  maxOptions?: number;
  maxOptionsText?: string;
  noOptionsText?: string;
  errorProps?: {
    helperText?: string | boolean;
    FormHelperTextProps: {
      error: boolean;
    };
  };
  attributes?: {
    fieldAttribute: string;
    menuAttribute: string;
    chipAttribute: string;
  };
  externalLink?: boolean;
  noMargin?: boolean;
  selectAll?: boolean;
  dataTc?: string;
  disableSearch?: boolean;
  isLoading?: boolean;
}

interface IAsyncMultiSelectProps {
  id: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  label: string;
  maxSelectHeight?: number | string;
  name: string;
  loadOptions?: any;
  fetchedOptions?: any;
  placeholder: string;
  onBlur(): void;
  onChange(value: any): void;
  value: OptionType | OptionType[] | null;
  errorProps: {
    helperText?: string | boolean;
    FormHelperTextProps: {
      error: boolean;
    };
  };
  attributes: {
    fieldAttribute: string;
    menuAttribute: string;
    chipAttribute: string;
  };
  externalLink?: boolean;
  noMargin?: boolean;
  selectAll?: boolean;
  dataTc?: string;
}

export const getSelectOptions = ({
  value,
  options,
  maxOptions,
}: {
  value: OptionType | OptionType[] | null;
  options?: OptionGroupType[] | OptionType[];
  maxOptions: number | undefined;
}) => {
  if (!maxOptions || !value) return options;
  return Object.keys(value).length === maxOptions ? [] : options;
};

export const allOptionsSelected = (children: any, isOptGroup: any) =>
  Array.isArray(children) &&
  children
    .map((child: any) =>
      isOptGroup(child)
        ? child.props.children.every((ch: any) => ch.props.isSelected)
        : child.props.isSelected
    )
    .every(Boolean);

export const isOptionDisabled = (data: OptionType, values: OptionType[]) => {
  const selectedValue =
    values &&
    values.length &&
    values.length > 0 &&
    values.find((value: OptionType) => value.value === data.value);
  return selectedValue ? selectedValue.readOnly : false;
};

export const handleAllSelect =
  (
    children: any,
    isOptGroup: any,
    isAllSelected: boolean,
    setValue: any,
    selectProps: any
  ) =>
  () => {
    const childrenData = children.flatMap((child: any) =>
      isOptGroup(child)
        ? children.flatMap((c: any) =>
            c.props.children.flatMap((ch: any) => ch.props.data)
          )
        : child.props.data
    );
    if (isAllSelected)
      setValue(
        selectProps.value.filter(
          (selectValue: OptionType) =>
            !childrenData.some(
              (childValue: OptionType) =>
                childValue.value === selectValue.value && !selectValue.readOnly
            )
        ),
        'set-value'
      );
    else {
      const filteredChildrenData = childrenData.filter(
        (selectValue: OptionType) =>
          !selectProps.value.some(
            (childValue: OptionType) => childValue.value === selectValue.value
          )
      );

      setValue([...selectProps.value, ...filteredChildrenData], 'set-value');
    }
  };

// Input ref is a reference to underlying input element to the ref prop
export const InputComponent = ({ inputRef, ...props }: any) => (
  <div ref={inputRef} {...props} />
);

// sets up Select component to use MU TextField
export const Control = (props: {
  children: ReactNode;
  innerProps: object;
  innerRef: Ref<any>;
  selectProps: any;
  testId?: string;
}) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      TextFieldProps,
      AttributeProps,
      isDisabled,
      externalLink,
      testId,
    },
  } = props;

  return (
    <TextField
      data-tc={AttributeProps.fieldAttribute}
      fullWidth
      disabled={isDisabled}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: classNames(`${classes.input}`, {
            [`${classes.input}--externalLink`]: externalLink,
          }),
          ref: innerRef,
          children,
          'data-testid': testId,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

// Individual options that are displayed in autocomplete menu
export const Option = ({
  isSelected,
  isFocused,
  innerProps,
  innerRef,
  data,
  selectProps,
}: {
  innerProps: any;
  isSelected: boolean;
  isFocused: boolean;
  innerRef: Ref<any>;
  data: OptionType;
  selectProps: any;
}) => {
  const isDisabled = data.readOnly || isOptionDisabled(data, selectProps.value);

  return (
    <FormControlLabel
      {...innerProps}
      data-tc="optionCheckbox"
      classes={{
        root: classNames([
          selectProps.classes.optionCheckboxLabel,
          {
            [`${selectProps.classes.optionCheckboxLabel}--focussed`]: isFocused,
          },
        ]),
      }}
      control={
        <Checkbox
          ref={innerRef}
          checked={isSelected}
          value={data.value}
          disabled={isDisabled}
          color="primary"
        />
      }
      label={data.label}
      onClick={() => {
        if (!isDisabled) innerProps.onClick();
      }}
    />
  );
};

export const MenuList = (props: any) => {
  const { children, selectProps, setValue } = props;

  const listRef = useRef<any>(null);
  const groupTitleHeight = 20;
  const optionHeight = 50;
  const menuListHeight = 250;
  const isOptGroup = (group: any) => Array.isArray(group.props.children);
  const getItemSize = (index: number) =>
    children.map((group: any) =>
      isOptGroup(group)
        ? groupTitleHeight + group.props.children.length * optionHeight
        : optionHeight
    )[index];
  const getListHeight = Array.isArray(children)
    ? children.reduce(
        (acc: number, group: any) =>
          isOptGroup(group)
            ? acc +
              groupTitleHeight +
              group.props.children.length * optionHeight
            : acc + optionHeight,
        0
      )
    : menuListHeight;

  const isAllSelected = allOptionsSelected(children, isOptGroup);

  const isAllDisabled =
    children &&
    children.length &&
    children.length > 0 &&
    children.filter((child: any) =>
      isOptGroup(child)
        ? child.props.children.every((ch: any) =>
            isOptionDisabled(ch.props.data, selectProps.value)
          )
        : isOptionDisabled(child.props.data, selectProps.value)
    ).length === children.length;

  const ListOptions = ({ index, style }: { index: number; style: any }) => (
    <div style={style}>{children[index]}</div>
  );

  useEffect(() => {
    if (listRef.current) listRef.current.resetAfterIndex(0, false);
  }, [listRef, children]);

  return Array.isArray(children) ? (
    <>
      {selectProps.selectAll && (
        <FormControlLabel
          data-tc="selectAllOption"
          classes={{ root: selectProps.classes.selectAllLabel }}
          checked={isAllSelected}
          disabled={isAllDisabled}
          name="selectAllOption"
          value="*"
          control={<Checkbox />}
          label={`${isAllSelected ? 'Deselect All' : 'Select All'}`}
          onClick={handleAllSelect(
            children,
            isOptGroup,
            isAllSelected,
            setValue,
            selectProps
          )}
        />
      )}
      <List
        data-tc="menuList"
        ref={listRef}
        width="100%"
        height={Math.min(getListHeight, menuListHeight)}
        itemSize={getItemSize}
        itemCount={children.length}
      >
        {ListOptions}
      </List>
    </>
  ) : (
    children
  );
};

// Displays autocomplete options when user starts typing
export const Menu = ({
  selectProps,
  innerProps,
  children,
}: {
  children: ReactNode;
  selectProps: any;
  innerProps: any;
}) => (
  <Paper
    square
    className={selectProps.classes.paper}
    {...innerProps}
    data-tc={selectProps.AttributeProps.menuAttribute}
  >
    {children}
  </Paper>
);

export const MultiValue = ({
  children,
  selectProps,
  removeProps,
  data,
}: {
  children: ReactNode;
  selectProps: any;
  removeProps: any;
  data: any;
}) => {
  const { readOnly = false } = data;

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={selectProps.classes.chip}
      onDelete={removeProps.onClick}
      deleteIcon={<ClearIcon {...removeProps} data-tc="deleteChip" />}
      data-tc={selectProps.AttributeProps.chipAttribute}
      disabled={readOnly}
    />
  );
};

// Wraps single value with a div
export const ValueContainer = ({
  selectProps,
  children,
}: {
  children: ReactNode;
  selectProps: any;
}) => <div className={selectProps.classes.valueContainer}>{children}</div>;

export const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  Option,
  ValueContainer,
  Select,
};

export const AsyncMultiSelect = (props: IAsyncMultiSelectProps) => {
  const {
    attributes,
    id,
    isDisabled = false,
    isMulti = true,
    isClearable = true,
    errorProps,
    label,
    maxSelectHeight = 'auto',
    loadOptions,
    fetchedOptions = [],
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    externalLink = false,
    noMargin = false,
    selectAll = true,
    dataTc = '',
  } = props;
  const classes = useStyles();
  const [multiSelectQuery, setMultiSelectQuery] = useState('');

  return (
    <div
      className={classNames([
        classes.root,
        {
          [`${classes.root}--noMargin`]: noMargin,
        },
      ])}
      data-testid={dataTc}
    >
      <NoSsr>
        <AsyncSelect
          data-testid={dataTc}
          classes={classes}
          styles={asyncCustomStyles}
          inputId={id}
          maxMenuHeight={250}
          maxSelectHeight={maxSelectHeight}
          name={name}
          openMenuOnClick={false}
          placeholder={placeholder}
          components={{
            ...components,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          value={value}
          onBlur={onBlur}
          loadOptions={loadOptions}
          selectAll={selectAll}
          defaultOptions={fetchedOptions}
          hideSelectedOptions={false}
          closeMenuOnSelect={!isMulti}
          allowSelectAll
          externalLink={externalLink}
          onChange={onChange}
          isClearable={isClearable}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onInputChange={(query: string, { action }) => {
            if (action === 'input-change') setMultiSelectQuery(query);
            if (action === 'menu-close') setMultiSelectQuery('');
          }}
          inputValue={multiSelectQuery}
          AttributeProps={{ ...attributes }}
          TextFieldProps={{
            label,
            helperText: errorProps.helperText,
            FormHelperTextProps: errorProps.FormHelperTextProps,
            InputLabelProps: {
              htmlFor: id,
              shrink: true,
            },
          }}
          testId={`${id}-input`}
        />
      </NoSsr>
    </div>
  );
};

const MultiSelect = (props: IMultiSelectProps) => {
  const {
    attributes,
    id,
    isDisabled = false,
    isMulti = true,
    isClearable = true,
    errorProps,
    label,
    maxSelectHeight = 'auto',
    name,
    onBlur,
    onChange,
    options,
    noOptionsText = 'No options',
    maxOptions,
    maxOptionsText = 'You have reached the maximum limit',
    placeholder,
    value,
    externalLink = false,
    noMargin = false,
    selectAll = true,
    dataTc = '',
    disableSearch = false,
    isLoading = false,
  } = props;
  const classes = useStyles();
  const selectOptions = getSelectOptions({ value, options, maxOptions });
  const [multiSelectQuery, setMultiSelectQuery] = useState('');

  return (
    <div
      className={classNames([
        classes.root,
        {
          [`${classes.root}--noMargin`]: noMargin,
        },
      ])}
      data-testid={dataTc}
    >
      <NoSsr>
        <Select
          classes={classes}
          styles={customStyles}
          inputId={id}
          maxMenuHeight={250}
          maxSelectHeight={maxSelectHeight}
          name={name}
          openMenuOnClick={false}
          placeholder={placeholder}
          options={selectOptions}
          selectAll={selectAll}
          hideSelectedOptions={false}
          closeMenuOnSelect={!isMulti}
          allowSelectAll
          externalLink={externalLink}
          components={components}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          isClearable={isClearable}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onInputChange={(query: string, { action }) => {
            if (action === 'input-change') setMultiSelectQuery(query);
            if (action === 'menu-close') setMultiSelectQuery('');
          }}
          inputValue={multiSelectQuery}
          noOptionsMessage={() =>
            value && Object.keys(value).length === maxOptions
              ? maxOptionsText
              : noOptionsText
          }
          AttributeProps={{ ...attributes }}
          TextFieldProps={{
            label,
            helperText: errorProps?.helperText,
            FormHelperTextProps: errorProps?.FormHelperTextProps,
            InputLabelProps: {
              htmlFor: id,
              shrink: true,
            },
          }}
          testId={`${id}-input`}
          isSearchable={!disableSearch}
          isLoading={isLoading}
        />
      </NoSsr>
    </div>
  );
};

export default MultiSelect;
