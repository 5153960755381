import memoizeOne from 'memoize-one';
import React from 'react';

import Loader from 'components/Loader/Loader';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';

import { IFormProps } from 'interfaces';
import {
  BrowserLanguage,
  useAllBrowserLanguagesQuery,
} from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

export const browserLanguageValues = {
  browserLanguageParams: {
    browserLanguages: [],
  },
};

const formatData = memoizeOne((data: BrowserLanguage[]) =>
  data.map((browserLanguage) => ({
    label: browserLanguage.description,
    value: browserLanguage.code,
  }))
);

const TargetingBrowserLanguage = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const { loading, data } = useAllBrowserLanguagesQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <TargetingMultiSelect
      {...props}
      label="Browser Language"
      targetingName="browserLanguageParams.browserLanguages"
      options={
        data && data.allBrowserLanguages
          ? formatData(data.allBrowserLanguages)
          : []
      }
    />
  );
};

export default TargetingBrowserLanguage;
