import { camelCase } from 'lodash';
import React, { MouseEvent, useState } from 'react';

import { Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './MenuButton.styles';

const MenuButton = ({
  title,
  options,
  testId,
}: {
  title: string;
  options: { title: string; onClick: () => void }[];
  testId?: string;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label={`${title}`}
        aria-controls="button-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{
          root: classes.button,
        }}
        data-tc={testId}
        data-testid={testId}
      >
        {title}
        {open ? (
          <ExpandLess data-tc="expandLessIcon" className={classes.expandIcon} />
        ) : (
          <ExpandMore data-tc="expandMoreIcon" className={classes.expandIcon} />
        )}
      </IconButton>
      <Menu
        id="button-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {options.map((option) => (
          <MenuItem
            className={classes.item}
            key={camelCase(option.title)}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
