import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import { ISeatFormValues } from 'features/programmatic/seat/components/SeatTabsForm/SeatFormValues';
import SeatTabsForm, {
  seatButtons,
} from 'features/programmatic/seat/components/SeatTabsForm/SeatTabsForm';
import SeatFormValidation from 'features/programmatic/seat/validations/seats';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  Dsp,
  EntityType,
  Seat,
  UpdateSeatMutationFn,
  useUpdateSeatMutation,
  Territory,
} from 'interfaces/generated.types';

import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    seatId: string;
  };
}
interface IUpdateSeatContainerProps {
  seat: Seat;
  history: History;
  match: IMatch;
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string,
  dspId: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: dspId,
    listEntity: selectedJourney === '/dsp/PARENT_ID' ? 'Seat' : '',
  });

export const submitForm = (
  updateSeat: UpdateSeatMutationFn,
  initialValues: ISeatFormValues,
  match: IMatch,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ISeatFormValues,
  { setStatus, setSubmitting }: FormikActions<ISeatFormValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = {
    ...differenceInValues(
      { ...formValues, journey: null },
      { ...initialValues, journey: null }
    ),
    id: match.params.seatId,
  };

  updateSeat({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateSeatContainer = ({
  history,
  match,
  seat,
  hasEditPermissions,
}: IUpdateSeatContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Seat.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateSeat] = useUpdateSeatMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, seat.id, (seat.dsp as Dsp).id);
    },
  });
  const validateSeat = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    id: seat.id,
    externalSeatId: seat.externalSeatId || '',
    altId: seat.altId,
    name: seat.name as string,
    journey: seatButtons[0].url,
    status: seat.status,
    dspId: (seat.dsp as Dsp).id,
    dspName: (seat.dsp as Dsp).name as string,
    dspTerritories: getTerritoryValues(
      ((seat.dsp as Dsp).territories as Territory[]) || []
    ),
    territories: getTerritoryValues(seat.territories || []),
  };

  const location = usePreviousLocation();

  return (
    <>
      <Formik<ISeatFormValues>
        initialValues={initialValues}
        validationSchema={SeatFormValidation}
        onSubmit={submitForm(
          updateSeat,
          initialValues,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <SeatTabsForm
            {...formikProps}
            update
            history={history}
            goBackTo={{
              pathname: `/dsp/${(seat.dsp as Dsp).id}`,
              state: location.state?.parent || {},
            }}
            hasEditPermissions={hasEditPermissions}
            dspStatus={(seat.dsp as Dsp).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateSeat,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Seat,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateSeat,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateNetwork"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateSeatWarningDialog"
      />
    </>
  );
};

export default UpdateSeatContainer;
