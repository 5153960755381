import ProgrammaticCreativesTable from 'features/programmatic/creative/components/ProgrammaticCreativesTable/ProgrammaticCreativesTable';
import useStyles from 'features/programmatic/creative/pages/ProgrammaticCreativesPage/ProgrammaticCreativesPage.styles';
import { History } from 'history';
import { PermissionName } from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface IProgrammaticCreativesPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Programmatic];

const ProgrammaticCreativesPage = ({
  userPermissions,
  history,
}: IProgrammaticCreativesPageProps) => {
  const classes = useStyles();

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main className={classes.page}>
      <div className={classes.container}>
        <h1 className="sr-only">Programmatic Creatives</h1>
        <div className={classes.table}>
          <ProgrammaticCreativesTable
            title="All Programmatic Creatives"
            isEditable={hasEditPermissions}
            history={history}
            allowBulkEdit
          />
        </div>
      </div>
    </main>
  );
};

export default ProgrammaticCreativesPage;
