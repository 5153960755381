import { History } from 'history';
import React from 'react';

export const handleOnSubmitJourney = (
  url: string,
  setFieldValue: (field: string, value: any, update?: boolean) => void,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
) => {
  setFieldValue('journey', url, false);
  handleSubmit();
};

export const handleOnJourneyRouting = ({
  history,
  selectedJourney,
  id,
  parentId,
  listEntity,
}: {
  history: History;
  selectedJourney: string;
  id?: string;
  parentId?: string;
  listEntity?: string;
}) => {
  if (selectedJourney) {
    const pathname = selectedJourney
      .replace('PARENT_ID', parentId || '')
      .replace('ID', id || '');
    return listEntity
      ? history.push({ pathname, state: { entityList: `list${listEntity}s` } })
      : history.push(pathname);
  }
  return window.location.reload();
};

export const handleTabHistory = (
  history: History,
  index: number,
  key: string = 'tab'
) => {
  history.replace({
    ...history.location,
    pathname: window.location.pathname,
    state: {
      ...(history.location.state as any),
      entityList: '',
      [key]: index,
    },
  });
};

export default {
  handleOnSubmitJourney,
  handleOnJourneyRouting,
  handleTabHistory,
};
