import { OptionType } from 'interfaces';
import {
  CampaignExternalSystem,
  CampaignStatus,
  OrderStatus,
  Pacing,
  TargetingVersion,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import { CurrenciesEnum } from 'utils/currency';
import { TimeZones } from 'utils/date';
import { generatePriorityTypes } from 'utils/generators';
import {
  ITargetingGroup,
  ITargetingMasterTemplate,
} from 'utils/targetingDefinitions';

export interface ICampaignValues {
  billingType: string;
  endDate: Date | null;
  frequencyCapCount: number | string;
  frequencyCapTimeUnit: string;
  frequencyCapValue: number | string;
  id: string;
  altId?: number;
  name: string;
  objective: string;
  unlimitedObjective: boolean;
  pacing: string;
  startDate: Date | null;
  cpm: string;
  currency: CurrenciesEnum;
  iabCategoryCodes: string[];
  orderName: OptionType | null;
  timeZone: TimeZones;
  offsetCpm: string;
  priority: number;
  tags: OptionType[];
  journey: string;
  owner: OptionType | null;
  status?: CampaignStatus;
  noEndDate: boolean;
  targetingGroups: ITargetingGroup[];
  targetingMasterTemplates: ITargetingMasterTemplate[];
  targetingGeneralGroup: ITargetingGroup | null;
  dailyCap: string;
  sponsorshipRevenue: string;
  territory?: Territory;
  targetingVersion: TargetingVersion;
  externalId?: string;
  externalSystem?: CampaignExternalSystem;
}

export const campaignDetailsFields = [
  'billingType',
  'endDate',
  'frequencyCapCount',
  'frequencyCapTimeUnit',
  'frequencyCapValue',
  'name',
  'objective',
  'pacing',
  'startDate',
  'cpm',
  'currency',
  'timeZone',
  'offsetCpm',
  'priority',
  'owner',
  'noEndDate',
  'dailyCap',
  'territory',
];

export const campaignPacings = [
  {
    value: Pacing.Evenly,
    label: 'Evenly',
  },
  {
    value: Pacing.FrontWeighted,
    label: 'Front-weighted',
  },
  {
    value: Pacing.Asap,
    label: 'As soon as possible',
  },
];

export const pacingMap = {
  Evenly: Pacing.Evenly,
  'Front-weighted': Pacing.FrontWeighted,
  'As soon as possible': Pacing.Asap,
  Unspecified: Pacing.PacingUnspecified,
};

export type PacingLabel = keyof typeof pacingMap;

export const campaignPriorityTypes = [...generatePriorityTypes()];

export interface ICampaignStatusValues {
  value: CampaignStatus;
  label: string;
  isDisabled?: (value: CampaignStatus) => boolean;
}

export const campaignStatusValues: ICampaignStatusValues[] = [
  {
    value: CampaignStatus.Disabled,
    label: 'Disabled',
  },
  {
    value: CampaignStatus.Draft,
    label: 'Draft',
    isDisabled: (value: CampaignStatus) =>
      [
        CampaignStatus.Ready,
        CampaignStatus.Live,
        CampaignStatus.Paused,
        CampaignStatus.Completed,
      ].includes(value),
  },
  {
    value: CampaignStatus.Ready,
    label: 'Ready',
    isDisabled: (value: CampaignStatus) =>
      [
        CampaignStatus.Draft,
        CampaignStatus.Live,
        CampaignStatus.Paused,
        CampaignStatus.Completed,
        CampaignStatus.Disabled,
      ].includes(value),
  },
  {
    value: CampaignStatus.Live,
    label: 'Live',
    isDisabled: (value: CampaignStatus) =>
      [
        CampaignStatus.Draft,
        CampaignStatus.Ready,
        CampaignStatus.Disabled,
        CampaignStatus.Completed,
      ].includes(value),
  },
  {
    value: CampaignStatus.Paused,
    label: 'Paused',
    isDisabled: (value: CampaignStatus) =>
      [
        CampaignStatus.Draft,
        CampaignStatus.Ready,
        CampaignStatus.Completed,
        CampaignStatus.Disabled,
      ].includes(value),
  },
  {
    value: CampaignStatus.Completed,
    label: 'Completed',
    isDisabled: () => true,
  },
];

export const getCampaignStatusData = memoizeOne(
  (values: ICampaignValues, orderStatus?: OrderStatus) => {
    let statusData = [
      {
        status: CampaignStatus.Draft,
        required: [
          ...(!values.name ? ["Complete 'Name'"] : []),
          ...(!values.orderName ? ['Complete Parent Order'] : []),
          ...(!values.objective ? ["Complete 'Objective'"] : []),
        ],
      },
      {
        status: CampaignStatus.Ready,
        required: [
          ...(!values.name ? ['Waiting for Name'] : []),
          ...(!(
            orderStatus === OrderStatus.Ready ||
            orderStatus === OrderStatus.Live
          )
            ? ['Waiting for Parent Order to be in READY or LIVE state']
            : []),
          ...(!values.startDate ? ['Waiting for Start Date'] : []),
          ...(!values.endDate && values.objective
            ? ['Waiting for End Date (if an Objective is provided)']
            : []),
          ...(!values.cpm ? ['Waiting for CPM'] : []),
        ],
      },
      {
        status:
          values.status === CampaignStatus.Paused
            ? CampaignStatus.Paused
            : CampaignStatus.Live,
        required: ['Waiting for Start Date'],
      },
      {
        status: CampaignStatus.Completed,
        required: ['Waiting for End Date'],
      },
    ];

    if (values.status === CampaignStatus.Disabled) {
      statusData = [
        {
          status: CampaignStatus.Disabled,
          required: [],
        },
        ...statusData,
      ];
    }

    return statusData;
  }
);
