import memoizeOne from 'memoize-one';

import {
  DspStatus,
  TechnicalProviderStatus,
  Territory,
} from 'interfaces/generated.types';
import { generateMaxImpressionsPerBid } from 'utils/generators';

export interface IDspFormValues {
  altId?: number;
  dailyCap: string;
  maxAds: number;
  id: string;
  name: string;
  journey: string;
  status?: DspStatus;
  technicalProviderName: string;
  technicalProviderId: string;
  technicalProviderTerritories: { value: Territory; label: string }[];
  territories: { value: Territory; label: string }[];
}

export const dspStatusValues = [
  {
    value: DspStatus.Draft,
    label: 'Draft',
    isDisabled: (value: DspStatus) =>
      [DspStatus.Active, DspStatus.Inactive].includes(value),
  },
  {
    value: DspStatus.Active,
    label: 'Active',
    isDisabled: (value: DspStatus) => [DspStatus.Draft].includes(value),
  },
  {
    value: DspStatus.Inactive,
    label: 'Inactive',
    isDisabled: (value: DspStatus) => [DspStatus.Draft].includes(value),
  },
];

export const getDspStatusData = memoizeOne(
  (
    values: IDspFormValues,
    technicalProviderStatus: TechnicalProviderStatus
  ) => [
    {
      status: DspStatus.Draft,
      required: [
        ...(!values.name ? ["Complete 'Name'"] : []),
        ...(!values.technicalProviderName
          ? ['Complete Parent Programmatic Connector']
          : []),
      ],
    },
    {
      status:
        values.status === DspStatus.Inactive
          ? DspStatus.Inactive
          : DspStatus.Active,
      required: [
        ...(!values.name ? ['Waiting for Name'] : []),
        ...(technicalProviderStatus !== TechnicalProviderStatus.Active
          ? ['Waiting for Parent Programmatic Connector to be in ACTIVE state']
          : []),
      ],
    },
  ]
);

export const dspDetailsFields = ['name', 'dailyCap'];

export const maxImpressionsPerBid = [...generateMaxImpressionsPerBid()];
