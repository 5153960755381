import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    minWidth: 'unset',
    textDecoration: 'none',
    padding: theme.spacing(0, 1.5),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default useStyles;
