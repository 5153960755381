import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  cookieNameContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default useStyles;
