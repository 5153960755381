import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  separator: {
    backgroundColor: theme.palette.grey[200],
    width: theme.spacing(0.25),
    height: theme.spacing(6.75),
    margin: theme.spacing(0, 2.875),
  },
  defaultLink: {
    fontSize: theme.spacing(2.375),
    lineHeight: theme.spacing(2.55),
    padding: theme.spacing(0.528, 1.68),
    width: '192px',
  },
  linkContainer: {
    display: 'block',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.625),
    color: theme.palette.grey[800],
    width: '192px',
    textDecoration: 'none',
    fontSize: theme.spacing(2.375),
    lineHeight: theme.spacing(2.55),
    padding: theme.spacing(0.528, 1.68),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dropdownContainer: {
    backgroundColor: theme.palette.common.white,
    width: '224px',
    color: theme.palette.grey[800],
  },
  dropdownList: {
    maxWidth: '352px',
    maxHeight: '152px',
    '& li': {
      padding: theme.spacing(1, 0.75),
    },
  },
  dropdownLabelDisabled: {
    color: theme.palette.grey[200],
  },
  inputWrapper: {
    borderRadius: theme.spacing(0.625),
  },
  menuItem: {
    minHeight: 'auto',
  },
}));

export default useStyles;
