import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { ICampaignValues } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import CampaignTabsForm, {
  campaignButtons,
} from 'features/direct/campaign/components/CampaignTabsForm/CampaignTabsForm';
import CampaignFormValidation from 'features/direct/campaign/validations/campaigns';
import { hasDaxExternalSystem } from 'features/direct/order/utils/order';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Advertiser,
  Campaign,
  CampaignExternalSystem,
  CreateCampaignMutation,
  CreateCampaignMutationFn,
  FrequencyCapTimeUnit,
  Order,
  Pacing,
  Tag,
  TargetingVersion,
  useCreateCampaignMutation,
  User,
} from 'interfaces/generated.types';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useState } from 'react';
import {
  calculateTotalCampaignsObjectiveWithoutCurrentCampaign,
  createSelectOption,
  createUserOption,
  transformIabCategoryOptions,
} from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import {
  getDefaultCurrencyBasedOnTerritory,
  getDefaultTargetingGroupWithCountryParamsBasedOnTerritory,
  getDefaultTimezoneBasedOnTerritory,
} from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTargetingDefinitionForCreate } from 'utils/targetingDefinitions';

interface ICreateCampaignContainerProps {
  history: History;
  match: {
    params: {
      orderId?: string;
    };
  };
  orders: Order | Order[];
  allTags: Tag[];
  allUsers: User[];
  currentUser: User;
}

export const handleOnComplete = async ({
  response,
  history,
  selectedJourney,
}: {
  response: CreateCampaignMutation;
  history: History;
  selectedJourney: string;
}) => {
  const { createCampaign } = response;
  const { id } = createCampaign as Campaign;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitCreateCampaign =
  ({
    createCampaign,
    orderId,
    toggleErrorModal,
    setSelectedJourney,
  }: {
    createCampaign: CreateCampaignMutationFn;
    orderId?: String;
    toggleErrorModal: () => void;
    setSelectedJourney: any;
  }) =>
  (
    formValues: ICampaignValues,
    { setStatus, setSubmitting }: FormikActions<ICampaignValues>
  ) => {
    setSelectedJourney(formValues.journey);

    const computedOrderId =
      orderId || (formValues.orderName && formValues.orderName.value);

    const formattedValues = formatValues(formValues);

    const targetingDefinition = getTargetingDefinitionForCreate(
      formValues,
      formattedValues
    );

    const filteredValues: any = pickBy({
      ...formValues,
      orderId: computedOrderId,
      orderName: null,
      journey: null,
      startDate: formattedValues.startDate,
      endDate: formattedValues.endDate,
      objective: formattedValues.objective,
      dailyCap: formattedValues.dailyCap,
      iabCategoryCodes: formattedValues.iabCategoryCodes,
      tagIds: formattedValues.tagIds,
      tags: null,
      ownerId: formattedValues.ownerId,
      owner: null,
      unlimitedObjective: null,
      targetingGroups: null,
      targetingMasterTemplates: null,
      targetingGeneralGroup: null,
      ...targetingDefinition,
      sponsorshipRevenue: formattedValues.sponsorshipRevenue,
    });

    delete filteredValues.targetingDefinitionV2;

    const includeZeroValues = {
      ...filteredValues,
      cpm: formattedValues.cpm,
      offsetCpm: formattedValues.offsetCpm,
      sponsorshipRevenue: formattedValues.sponsorshipRevenue,
    };

    createCampaign({
      variables: includeZeroValues,
    }).catch((error) =>
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      })
    );
  };

const CreateCampaignContainer = ({
  history,
  match,
  orders,
  allTags,
  allUsers,
  currentUser,
}: ICreateCampaignContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const orderIdExists = !isUndefined(match.params.orderId);
  const totalCampaignsObjective = orderIdExists
    ? calculateTotalCampaignsObjectiveWithoutCurrentCampaign({
        allCampaigns: (orders as Order).campaigns,
      })
    : null;
  const isMounted = React.useRef(false);
  const [orderState, setOrderState] = useState<{
    orderStartDate: string | null;
    orderEndDate: string | null;
    totalCampaignsObjective: number | null;
    orderObjective: number | null;
    iabCategoryCodes: string[];
    orderExternalSystemId: string;
  }>({
    orderStartDate: null,
    orderEndDate: null,
    totalCampaignsObjective,
    orderObjective: null,
    iabCategoryCodes: [],
    orderExternalSystemId: '',
  });
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Campaign.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  useEffect(() => {
    isMounted.current = true;

    setOrderState({
      orderStartDate: (orders as Order).startDate || null,
      orderEndDate: (orders as Order).endDate || null,
      totalCampaignsObjective,
      orderObjective: (orders as Order).objective || null,
      iabCategoryCodes: (orders as Order).advertiser
        ? transformIabCategoryOptions(
            ((orders as Order).advertiser as Advertiser).iabCategories
          )
        : [],
      orderExternalSystemId: (orders as Order).externalSystemId || '',
    });

    return () => {
      isMounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldHaveExternalId = hasDaxExternalSystem(
    orderIdExists
      ? (orders as Order).externalSystemId
      : orderState.orderExternalSystemId
  );

  const [createCampaign] = useCreateCampaignMutation({
    onCompleted(data) {
      handleOnComplete({
        response: data,
        history,
        selectedJourney,
      });
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<ICampaignValues>
        initialValues={{
          targetingMasterTemplates: [],
          targetingGroups: [],
          targetingGeneralGroup: activeTerritory
            ? getDefaultTargetingGroupWithCountryParamsBasedOnTerritory(
                activeTerritory
              )
            : null,
          priority: 5,
          offsetCpm: '',
          billingType: 'SOLD',
          endDate:
            orderIdExists && (orders as Order).endDate
              ? dateUtils.getDateInSpecificTimezone(
                  (orders as Order).endDate,
                  (orders as Order).timeZone
                )
              : (null as any),
          noEndDate: orderIdExists && !(orders as Order).endDate,
          frequencyCapCount: '',
          frequencyCapTimeUnit: FrequencyCapTimeUnit.TimeUnitUnspecified,
          frequencyCapValue: '',
          sponsorshipRevenue: '',
          id: '',
          name: '',
          objective: '',
          unlimitedObjective: false,
          dailyCap: '',
          pacing: Pacing.Evenly,
          startDate:
            orderIdExists && (orders as Order).startDate
              ? dateUtils.getDateInSpecificTimezone(
                  (orders as Order).startDate,
                  (orders as Order).timeZone
                )
              : (null as any),
          cpm: '',
          currency: getDefaultCurrencyBasedOnTerritory(activeTerritory!),
          iabCategoryCodes: orderIdExists
            ? transformIabCategoryOptions(
                ((orders as Order).advertiser as Advertiser).iabCategories
              )
            : [],
          orderName: orderIdExists ? createSelectOption(orders as Order) : null,
          timeZone:
            (orders as Order).timeZone ||
            getDefaultTimezoneBasedOnTerritory(activeTerritory!),
          tags: [],
          journey: campaignButtons[0].url,
          owner:
            orderIdExists && (orders as Order).owner
              ? createUserOption((orders as Order).owner as User)
              : createUserOption(currentUser),
          territory: activeTerritory,
          targetingVersion: TargetingVersion.TargetingV2,
          externalSystem: shouldHaveExternalId
            ? CampaignExternalSystem.Dax
            : CampaignExternalSystem.CampaignExternalSystemUnspecified,
          externalId: '',
        }}
        validationSchema={CampaignFormValidation(
          orderState.orderStartDate,
          orderState.orderEndDate,
          orderState.totalCampaignsObjective,
          activeTerritory,
          orderState.orderObjective,
          shouldHaveExternalId
        )}
        onSubmit={submitCreateCampaign({
          createCampaign,
          orderId: match.params.orderId,
          toggleErrorModal,
          setSelectedJourney,
        })}
      >
        {(formikProps) => (
          <CampaignTabsForm
            {...formikProps}
            orderIdExists={orderIdExists}
            allOrders={orderIdExists ? [] : (orders as Order[])}
            totalCampaignsObj={totalCampaignsObjective}
            orderObj={orderIdExists ? (orders as Order).objective : null}
            allTags={allTags}
            allUsers={allUsers}
            currentUser={currentUser}
            validateOrderFields={async (
              name: string,
              value: Date | number | null | string
            ) => {
              if (isMounted.current) {
                await setOrderState((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));
                formikProps.validateForm();
              }
            }}
            goBackTo={{
              pathname: orderIdExists
                ? `/order/${match.params.orderId}`
                : '/campaigns',
              state: location.state?.parent || {},
            }}
            shouldHaveExternalId={shouldHaveExternalId}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createCampaign"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateCampaignContainer;
