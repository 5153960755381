import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0, 0.5),
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    '&--second': {
      position: 'relative',
      bottom: '1px',
    },
  },
  parameterValuesInput: {
    borderRadius: '0px 4px 4px 0px',
    '&--first': {
      borderRadius: '0px 4px 0px 0px',
    },
    '&--last': {
      borderRadius: '0px 0px 4px 0px',
    },
    '&--in-between': {
      borderRadius: 0,
    },
  },
  accuracyContainer: {
    '&--withOneClusivity': { position: 'relative', bottom: '1px' },
    '&--withBothClusivity': { position: 'relative', bottom: '2px' },
  },
}));

export default useStyles;
