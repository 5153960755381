import { EntityType } from 'interfaces/generated.types';

export const getPathPerEntityType = (entity: EntityType) => {
  switch (entity) {
    case EntityType.Agency:
      return '/agency/';
    case EntityType.Advertiser:
      return '/advertiser/';
    case EntityType.Ad:
      return '/ad/';
    case EntityType.Campaign:
      return '/campaign/';
    case EntityType.Order:
      return '/order/';
    case EntityType.Saleschannel:
      return '/sales-channel/';
    case EntityType.Deal:
      return '/deal/';
    case EntityType.Technicalprovider:
      return '/programmatic-connector/';
    case EntityType.Datacenter:
      return '/datacenter/';
    case EntityType.Dsp:
      return '/dsp/';
    case EntityType.Seat:
      return '/seat/';
    case EntityType.Network:
      return '/network/';
    case EntityType.Publisher:
      return '/publisher/';
    case EntityType.Channel:
      return '/channel/';
    case EntityType.TargetingTemplate:
      return '/targeting-template/';
    default:
      return '';
  }
};

export const getEntityPath = (
  id: string | undefined,
  basePath: string | undefined
) => (id && basePath ? `${basePath}${id}` : '');

export default { getEntityPath, getPathPerEntityType };
