import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { IFormProps } from 'interfaces';

import useStyles from './DataControlAccordion.styles';

interface IDataControlAccordionProps {
  title: string;
  children: React.ReactNode;
  attribute: string;
  disabled?: boolean;
}

const DataControl = (props: IFormProps<any> & IDataControlAccordionProps) => {
  const {
    values,
    setFieldValue,
    title,
    children,
    attribute,
    disabled = false,
  } = props;

  const classes: any = useStyles();

  return (
    <>
      <div className={classes.heading}>
        {title}
        <FormControlLabel
          data-tc="dataControlAccordionLabel"
          control={
            <Switch
              data-tc="dataControlAccordionSwitch"
              checked={!values[attribute]}
              onChange={() => setFieldValue(attribute, !values[attribute])}
              value={!values[attribute]}
              disabled={disabled}
            />
          }
          label={!values[attribute] ? 'Pass' : 'Block'}
          classes={{ label: classes.toggle }}
        />
      </div>
      <Collapse in={!values[attribute]} data-tc="dataControlAccordionCollapse">
        <div className={classes.innerContent}>{children}</div>
      </Collapse>
    </>
  );
};

export default DataControl;
