import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './StyledButton.styles';

export enum ButtonColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
}

export enum ButtonVariantEnum {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}

const StyledButton = ({
  children,
  color,
  href,
  type,
  className,
  disabled,
  onClick,
  isLoading,
  variant = ButtonVariantEnum.Contained,
  testId,
  disableRipple = false,
}: {
  children: any;
  color?: ButtonColorEnum;
  href?: string;
  type?: any;
  className?: string;
  disabled?: boolean;
  onClick?(value: any): void;
  isLoading?: boolean;
  variant: ButtonVariantEnum;
  testId?: string;
  disableRipple?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        className={className}
        classes={{
          containedPrimary: classes.containedPrimary,
          outlinedPrimary: classes.outlinedPrimary,
          outlinedSecondary: classes.outlinedSecondary,
          text: classes.text,
        }}
        href={href}
        type={type}
        variant={variant}
        color={color}
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
        disableRipple={disableRipple}
      >
        {children}
      </Button>
      {isLoading ? (
        <CircularProgress
          data-tc="buttonLoader"
          data-testid={`buttonLoader-${testId}`}
          size="24"
          className={classes.loader}
        />
      ) : null}
    </div>
  );
};

export default StyledButton;
