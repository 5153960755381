import {
  handleWarning,
  ISetWarningModal,
} from 'components/WarningDialog/WarningDialog';
import { IFormValues, OptionType } from 'interfaces';
import {
  EntityPermission,
  EntityStatus,
  EntityType,
  PermissionName,
  TranscodePreset,
} from 'interfaces/generated.types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import memoizeOne from 'memoize-one';

import { formatKvps } from './dataControl';
import dateUtils from './date';
import { GraphqlErrorCodes } from './errors';
import {
  multiplyTwoNumbers,
  parseCurrencyToNumber,
  parseFormattedValue,
} from './numbers';
import {
  permissionExistsInList,
  readOnlyPermissionRegex,
  readOnlyPermissions,
} from './permissions';
import {
  filterMasterValues,
  formatTargetingGroup,
  formatTargetingGroups,
} from './targetingDefinitions';

export const handleFormErrors = ({
  error,
  setSubmitting,
  toggleErrorModal,
  setStatus,
}: {
  error: any;
  setSubmitting: (isSubmitting: boolean) => void;
  toggleErrorModal: () => void;
  setStatus: (status: any) => void;
}) => {
  const extensions = get(error, 'graphQLErrors[0].extensions.code', '');
  if (extensions === GraphqlErrorCodes.BAD_USER_INPUT) {
    const validationErrors = get(
      error,
      'graphQLErrors[0].extensions.exception.validationErrors',
      {}
    );
    if (!isEmpty(validationErrors)) {
      const errors = reduce(
        validationErrors,
        (result: { [key: string]: string }, value: string[], key: string) => ({
          ...result,
          [key]: value[0],
        }),
        {}
      );

      setStatus(errors);
      setSubmitting(false);
      return;
    }
  }
  toggleErrorModal();
  setSubmitting(false);
};

export const createNumberOfFormErrorsFn = () =>
  memoizeOne((status: any, fields: string[]) => {
    const statusKeys = keys(status);
    const numberOfErrors = statusKeys.reduce((result: number, key: string) => {
      if (fields.includes(key)) {
        return result + 1;
      }
      return result;
    }, 0);

    return numberOfErrors;
  });

export const handleFormErrorsWithDuplicates = ({
  duplicateFields,
  error,
  setSubmitting,
  toggleErrorModal,
  setStatus,
}: {
  setSubmitting: (isSubmitting: boolean) => void;
  toggleErrorModal: () => void;
  setStatus: (status: any) => void;
  duplicateFields: { message: string; error: { [key: string]: string } }[];
  error: any;
}) => {
  const errorCode = get(error, 'graphQLErrors[0].extensions.code', '');
  if (errorCode === GraphqlErrorCodes.ALREADY_EXISTS) {
    const errorMessage = get(error, 'graphQLErrors[0].message', '');
    const field = duplicateFields.find((f: any) => f.message === errorMessage);

    if (field) {
      setSubmitting(false);
      return setStatus(field.error);
    }
    setSubmitting(false);
    return toggleErrorModal();
  }

  return handleFormErrors({
    error,
    setSubmitting,
    toggleErrorModal,
    setStatus,
  });
};

export const handleFieldValidate = async ({
  validate,
  entity,
  setWarningModal,
  toggleErrorModal,
  setSubmitting,
  setStatus,
  handleContinue,
  handleCancel,
}: {
  validate: any;
  entity: {
    id: string;
    type: EntityType;
    targetStatus: EntityStatus;
  };
  setWarningModal: (values: ISetWarningModal) => void;
  toggleErrorModal: () => void;
  setSubmitting: (value: boolean) => void;
  setStatus: () => void;
  handleContinue: () => void;
  handleCancel?: () => void;
}) => {
  try {
    const { data } = await validate({
      targetStatus: entity.targetStatus,
      entityAttributes: {
        id: entity.id,
        entityType: entity.type,
      },
    });
    if (data.allAffectedEntities.length) {
      handleWarning({
        setWarningModal,
        handleContinue,
        handleCancel,
      });
    } else {
      handleContinue();
    }
  } catch (error) {
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  }
};

export const handleFieldUpdate = async ({
  update,
  entity,
  toggleErrorModal,
  setSubmitting,
  setStatus,
}: {
  update: any;
  entity: any;
  toggleErrorModal: () => void;
  setSubmitting: (state: boolean) => void;
  setStatus: () => void;
}) => {
  try {
    setSubmitting(true);
    await update({
      variables: {
        id: entity.id,
        status: entity.status,
      },
    });
  } catch (error) {
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  }
};

export const filterDomains = (domains: string[]) =>
  domains.filter((domain) => !!domain);

export const formatTranscodePresets = (transcodePresets: TranscodePreset[]) =>
  transcodePresets && transcodePresets.length
    ? transcodePresets.map((preset: TranscodePreset) => preset.id)
    : null;

export const removeUnnecessaryReadPermissions = (
  userPermissions: PermissionName[]
) => {
  let result = [...userPermissions];

  readOnlyPermissions.forEach((readPermission: PermissionName) => {
    const respectiveEditPermission = readPermission.replace(
      readOnlyPermissionRegex,
      ''
    );

    if (
      permissionExistsInList(userPermissions, readPermission) &&
      permissionExistsInList(
        userPermissions,
        respectiveEditPermission as PermissionName
      )
    )
      result = result.filter((permission) => permission !== readPermission);
  });

  return result;
};

const filterBlockedKeyValues = (
  blockedKeyValues: { key: string; value: string }[]
) => {
  if (!blockedKeyValues) return null;

  const filteredBlockedKeyValues = blockedKeyValues.filter(
    ({ key, value }) => !!key && !!value
  );

  if (filteredBlockedKeyValues.length > 0) return filteredBlockedKeyValues;

  return null;
};

const formatDate = (date: Date | null, timeZone: any) =>
  date && timeZone
    ? dateUtils.getIsoStringInSpecificTimezone(date, timeZone)
    : null;

const formatInheritableDate = (
  date: Date | null,
  timeZone: any,
  isInherited: boolean
) => ({
  value: !isInherited ? formatDate(date, timeZone) : null,
  isInherited,
});

export const formatValues = (formValues: IFormValues) =>
  ({
    startDate: formValues.isStartDateInheritable
      ? formatInheritableDate(
          formValues.startDate,
          formValues.timeZone,
          formValues.startDateIsInherited
        )
      : formatDate(formValues.startDate, formValues.timeZone),
    endDate: formValues.isEndDateInheritable
      ? formatInheritableDate(
          formValues.endDate,
          formValues.timeZone,
          formValues.endDateIsInherited
        )
      : formatDate(formValues.endDate, formValues.timeZone),
    objective: formValues.objective
      ? parseFormattedValue(formValues.objective)
      : null,
    dailyCap: formValues.dailyCap
      ? parseFormattedValue(formValues.dailyCap)
      : null,
    maxQps: formValues.maxQps ? parseFormattedValue(formValues.maxQps) : null,
    cpm: formValues.cpm ? parseFormattedValue(formValues.cpm) : null,
    offsetCpm: formValues.offsetCpm
      ? parseFormattedValue(formValues.offsetCpm)
      : null,
    floorCpm: formValues.floorCpm
      ? parseFormattedValue(formValues.floorCpm)
      : null,
    duration: formValues.duration
      ? parseFormattedValue(formValues.duration)
      : null,
    minAdDuration: formValues.minAdDuration
      ? parseFormattedValue(formValues.minAdDuration)
      : null,
    maxAdDuration: formValues.maxAdDuration
      ? parseFormattedValue(formValues.maxAdDuration)
      : null,
    adSeparation:
      formValues.hasAdSeparation && formValues.adSeparation
        ? parseFormattedValue(formValues.adSeparation)
        : null,
    pricingAmount: formValues.pricingAmount
      ? parseFormattedValue(formValues.pricingAmount)
      : null,
    consentVendorId: formValues.consentVendorId
      ? parseFormattedValue(formValues.consentVendorId)
      : null,
    sponsorshipRevenue: formValues.sponsorshipRevenue
      ? parseFormattedValue(formValues.sponsorshipRevenue)
      : null,
    iabCategoryCodes:
      formValues.iabCategoryCodes && formValues.iabCategoryCodes.length
        ? formValues.iabCategoryCodes
        : null,
    blacklistedIabCategoryCodes:
      formValues.blacklistedIabCategoryCodes &&
      formValues.blacklistedIabCategoryCodes.length
        ? formValues.blacklistedIabCategoryCodes
        : null,
    blacklistedDomains: formValues.blacklistedDomains
      ? filterDomains(formValues.blacklistedDomains)
      : null,
    blockedKeyValues: filterBlockedKeyValues(formValues.blockedKeyValues),
    domains: formValues.domains ? filterDomains(formValues.domains) : null,
    truncateGpsCoords: formValues.blockGeoData
      ? false
      : formValues.truncateGpsCoords,
    truncateIpAddress: formValues.blockIpData
      ? false
      : formValues.truncateIpAddress,
    kvps1stPartyData:
      formValues.block1stPartyData || formValues.kvps1stPartyAllData
        ? null
        : (formValues.kvps1stPartyData &&
            formatKvps(formValues.kvps1stPartyData)) ||
          null,
    targetingGroups:
      formValues.targetingGroups &&
      formValues.targetingGroups.length &&
      formValues.targetingGroups[0].channels
        ? formatTargetingGroups(formValues.targetingGroups)
        : [],
    targetingMasterTemplates:
      formValues.targetingMasterTemplates &&
      formValues.targetingMasterTemplates.length
        ? formatTargetingGroups(
            filterMasterValues(formValues.targetingMasterTemplates)
          )
        : [],
    targetingGeneralGroup: formValues.targetingGeneralGroup
      ? formatTargetingGroup(formValues.targetingGeneralGroup)
      : null,
    permissions:
      formValues.permissions && formValues.permissions.length
        ? removeUnnecessaryReadPermissions(formValues.permissions)
        : null,
    transcodePresets: formatTranscodePresets(formValues.transcodePresets),
    tagIds:
      formValues.tags && formValues.tags.length
        ? formValues.tags.map((tag: OptionType) => tag.value)
        : null,
    tagsAreInherited:
      (formValues.tags && formValues.tags.length && formValues.inheritedTags) ||
      formValues.tagsAreInherited
        ? formValues.tagsAreInherited
        : null,
    tags: null,
    inheritedTags: null,
    ownerId: formValues.owner ? formValues.owner.value : null,
    owner: null,
    hasAdSeparation: null,
    trafficAcceptancePercentage: formValues.trafficAcceptancePercentage
      ? multiplyTwoNumbers(
          parseFormattedValue(formValues.trafficAcceptancePercentage),
          100
        )
      : null,
    territories:
      formValues.territories && formValues.territories.length
        ? formValues.territories.map((territory: OptionType) => territory.value)
        : null,
    publisherCategories:
      formValues.publisherCategories && formValues.publisherCategories.length
        ? formValues.publisherCategories.map(
            (category: OptionType) => category.value
          )
        : null,
    directSpotCpm: formValues.directSpotCpm
      ? parseCurrencyToNumber(formValues.directSpotCpm)
      : null,
    programmaticSpotCpm: formValues.programmaticSpotCpm
      ? parseCurrencyToNumber(formValues.programmaticSpotCpm)
      : null,
    hostReadCpm: formValues.hostReadCpm
      ? parseCurrencyToNumber(formValues.hostReadCpm)
      : null,
    minRevenueGuaranteed: formValues.minRevenueGuaranteed
      ? parseCurrencyToNumber(formValues.minRevenueGuaranteed)
      : null,
    minRevenueIdeal: formValues.minRevenueIdeal
      ? parseCurrencyToNumber(formValues.minRevenueIdeal)
      : null,
    spotRevenueShare: formValues.spotRevenueShare
      ? multiplyTwoNumbers(
          parseFormattedValue(formValues.spotRevenueShare),
          100
        )
      : null,
    hostReadRevenueShare: formValues.hostReadRevenueShare
      ? multiplyTwoNumbers(
          parseFormattedValue(formValues.hostReadRevenueShare),
          100
        )
      : null,
    sponsorshipRevenueShare: formValues.sponsorshipRevenueShare
      ? multiplyTwoNumbers(
          parseFormattedValue(formValues.sponsorshipRevenueShare),
          100
        )
      : null,
    percentFillGuaranteed: formValues.percentFillGuaranteed
      ? multiplyTwoNumbers(
          parseFormattedValue(formValues.percentFillGuaranteed),
          100
        )
      : null,
    minInventoryGuaranteed: formValues.minInventoryGuaranteed
      ? parseFormattedValue(formValues.minInventoryGuaranteed)
      : null,
    maxInventoryGuaranteed: formValues.maxInventoryGuaranteed
      ? parseFormattedValue(formValues.maxInventoryGuaranteed)
      : null,
    entityPermissions: formValues.entityPermissions
      ? formValues.entityPermissions.map(
          (entityPermission: EntityPermission) => ({
            entityId: entityPermission.entityId,
            entityType: entityPermission.entityType,
          })
        )
      : [],
    externalId: formValues.externalId
      ? (formValues.externalId as string).trim()
      : '',
  } as any);

export const differenceInValues = (
  formValues: IFormValues,
  initialValues: IFormValues
): IFormValues => {
  const formattedFormValues = {
    ...formValues,
    ...(formatValues(formValues) as IFormValues),
  };

  const formattedInitialValues = {
    ...initialValues,
    ...(formatValues(initialValues) as IFormValues),
  };

  return reduce(
    formattedFormValues,
    (result: IFormValues, value: any, key: string) =>
      !isEqual(value, formattedInitialValues[key])
        ? {
            ...result,
            [key]:
              value === 0
                ? 0
                : value || (typeof value === 'boolean' ? Boolean(value) : null),
          }
        : result,
    {}
  );
};

export const checkForTargetingMissingErrors = (errors: any) => {
  if (
    errors.targetingMasterTemplates &&
    typeof errors.targetingMasterTemplates === 'string'
  )
    return errors.targetingMasterTemplates;

  if (errors.targetingGroups && typeof errors.targetingGroups === 'string')
    return errors.targetingGroups;

  return null;
};
