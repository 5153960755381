import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import dialogStyles from 'assets/styles/components/Dialog.styles';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import useStyles from './ConfirmationModal.styles';

export interface IConfirmationModalProps {
  isModalOpen: boolean;
  setModalOpen: (arg0: boolean) => void;
  handleConfirm: () => void;
  dataTc: string;
  content: {
    title: string;
    main: string;
  };
}

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const classes = useStyles();
  const dialogClasses = dialogStyles();
  const {
    isModalOpen,
    setModalOpen,
    handleConfirm,
    dataTc,
    content: { title, main },
  } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      maxWidth="xl"
      data-testid={dataTc}
    >
      <>
        <DialogTitle classes={{ root: dialogClasses.title }} disableTypography>
          <Typography display="inline">{title}</Typography>
          <IconButton
            onClick={() => setModalOpen(false)}
            classes={{ root: dialogClasses.closeButton }}
            data-tc={`${dataTc}X`}
          >
            <CloseIcon />
            <span className="sr-only">Close modal</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes={{ root: dialogClasses.content }}
          data-tc={`${dataTc}Content`}
        >
          <p>{main}</p>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <StyledButton
            onClick={() => setModalOpen(false)}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc={`${dataTc}Cancel`}
          >
            Cancel
          </StyledButton>
          <StyledButton
            data-testId={`${dataTc}Confirm`}
            variant={ButtonVariantEnum.Contained}
            color={ButtonColorEnum.Primary}
            onClick={() => {
              handleConfirm();
            }}
          >
            Confirm
          </StyledButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ConfirmationModal;
