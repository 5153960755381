import { restrictionsTooltipContent } from 'features/targetingV2/components/TooltipContent/restrictionsTooltipContent';
import { tooltipContent } from 'features/targetingV2/components/TooltipContent/sectionTooltipContent';
import { SectionType } from 'features/targetingV2/types/common';
import { workplaceKnowledgeUrl } from 'features/targetingV2/utils/common';
import { TargetingRestriction } from 'interfaces/generated.types';
import { mapRestrictionValueToLabel } from 'utils/targetingRestrictions';

import { getSectionTitle } from './common';

type LinkType = {
  content: string;
  resourceLink: string;
};

type TooltipContentAndLinks = {
  content: string[];
  links?: LinkType[];
};

export type TooltipContent = {
  title: string;
} & TooltipContentAndLinks;

export type TooltipContentMap = {
  [key in SectionType]?: TooltipContentAndLinks;
};

export const tooltipContentMap: TooltipContentMap = {
  [SectionType.Location]: tooltipContent.location,
  [SectionType.Audience]: tooltipContent.audience,
  [SectionType.DayTime]: tooltipContent.dayTime,
  [SectionType.Inventory]: tooltipContent.inventory,
  [SectionType.Technology]: tooltipContent.technology,
  [SectionType.Custom]: tooltipContent.custom,
};

export const getTooltipContentBasedOnSectionType = (
  sectionType: SectionType
): TooltipContent => {
  const sectionTitle = getSectionTitle(sectionType);

  const tooltipContentBasedOnSection = tooltipContentMap[sectionType];

  if (tooltipContentBasedOnSection) {
    return {
      title: `How to use ${sectionTitle} parameters?`,
      ...tooltipContentBasedOnSection,
    } as TooltipContent;
  }
  return {
    title: '',
    content: [],
  };
};

export const getTooltipContentBasedOnRestriction = (
  restriction: TargetingRestriction
): TooltipContent => {
  const tooltipContentBasedOnRestriction =
    restrictionsTooltipContent[restriction];

  if (tooltipContentBasedOnRestriction) {
    return {
      title: `${mapRestrictionValueToLabel[restriction]} restrictions`,
      ...tooltipContentBasedOnRestriction,
      links: [
        {
          content: 'What are campaign restrictions and how to apply them?',
          resourceLink: `${workplaceKnowledgeUrl}/campaign.restrictions`,
        },
      ],
    } as TooltipContent;
  }
  return {
    title: '',
    content: [],
  };
};
