import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';

import { agencyStatusValues } from 'features/direct/agency/components/AgencyTabsForm/AgencyFormValues';
import { GET_ALL_AGENCIES } from 'features/direct/agency/graphql/queries';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  Agency,
  AgencyStatus,
  EntityType,
  Territory,
  useUpdateAgencyMutation,
} from 'interfaces/generated.types';

import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

interface IAgenciesRow {
  id: string;
  altId: number;
  name: string;
  status: AgencyStatus;
}

export interface IAgenciesTableProps {
  history: History;
  allAgencies: Agency[];
  isEditable: boolean;
  activeTerritory: Territory;
}

export const formatData = memoizeOne((data: Agency[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const AgenciesTable = ({
  allAgencies,
  isEditable,
  history,
  activeTerritory,
}: IAgenciesTableProps) => {
  const [updateAgency] = useUpdateAgencyMutation({
    refetchQueries: [
      {
        query: GET_ALL_AGENCIES,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });

  const validateAgency = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createAgencyCta = isEditable && (
    <StyledLink
      location={{
        pathname: '/agency',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createAgencyButton"
    >
      Create Agency
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IAgenciesRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/agency/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IAgenciesRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: agencyStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={agencyStatusValues}
                name="agencyStatusSelect"
                dataTc="agencyStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <Table
      history={history}
      columns={columns}
      data={formatData(allAgencies)}
      isEditable={isEditable}
      dataTc="listAgenciesTable"
      data-testid="listAgenciesTable"
      onCellUpdate={(row: IAgenciesRow, setErrorModal, setUpdating) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            status: row.status,
          },
          update: updateAgency,
          handleContinue: () => history.push(`/agency/${row.id}`),
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Agency',
          },
        })
      }
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateAgency,
          entity: { ...entity, type: EntityType.Agency },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      customToolbarCtas={createAgencyCta}
    />
  );
};

export default AgenciesTable;
