const withoutKeys = <T extends Record<string, string>>(
  obj: T,
  exclude: Partial<keyof T>[]
) =>
  Object.entries(obj).reduce(
    (acc, [key, value]: [key: keyof T, value: any]) => {
      if (!exclude.includes(key)) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = value;
      }
      return acc;
    },
    {} as T
  );

export default withoutKeys;
