import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IAdValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import AdTabsForm from 'features/direct/ad/components/AdTabsForm/AdTabsForm';
import AdValidation from 'features/direct/ad/validations/ads';
import { ICreativeAudioFormValues } from 'features/direct/creative/graphql/queries';
import useAllParametersInfo from 'features/targetingV2/hooks/AllParametersInfo/useAllParametersInfo';
import { sendTargetingV2DataToGTM } from 'features/targetingV2/utils/gtmData';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Ad,
  Advertiser,
  Campaign,
  CreateAdMutation,
  CreateAdMutationFn,
  CreateCreativeAudioMutationFn,
  CreateCreativeImageMutationFn,
  CreateCreativeRedirectMutationFn,
  CreativeType,
  Order,
  TargetingVersion,
  Territory,
  useCreateAdMutation,
  useCreateCreativeAudioMutation,
  useCreateCreativeImageMutation,
  useCreateCreativeRedirectMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { transformTrackingUrls } from 'utils/dataTransformation';
import dateUtils, { TimeZones } from 'utils/date';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { parseFormattedValue } from 'utils/numbers';
import { getTargetingDefinitionForCreate } from 'utils/targetingDefinitions';

import formatInitialValues from './formatCreateAdInitialValues';

interface ICreateAdContainerProps {
  campaign: Campaign;
  history: History;
  match: {
    params: {
      campaignId: string;
    };
  };
}

export const handleOnAdComplete = async ({
  response,
  history,
  campaignId,
  selectedJourney,
}: {
  response: CreateAdMutation;
  history: History;
  campaignId: string;
  selectedJourney: string;
}) => {
  const { createAd } = response;
  const { id: adId } = createAd as Ad;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id: adId,
    parentId: campaignId,
    listEntity: selectedJourney === '/campaign/PARENT_ID' ? 'Ad' : '',
  });
};

export const submitForm = async ({
  advertiserId,
  campaignId,
  createAd,
  createImageCreative,
  createCreativeRedirect,
  timeZone,
  toggleErrorModal,
  setSelectedJourney,
  createAudioCreative,
  territory,
  formValues,
  formikActions: { setStatus, setSubmitting },
}: {
  advertiserId: string;
  campaignId: string;
  createAd: CreateAdMutationFn;
  createImageCreative: CreateCreativeImageMutationFn;
  createCreativeRedirect: CreateCreativeRedirectMutationFn;
  createAudioCreative: CreateCreativeAudioMutationFn;
  timeZone: TimeZones;
  toggleErrorModal: () => void;
  setSelectedJourney: any;
  territory: Territory;
  formValues: IAdValues;
  formikActions: FormikActions<IAdValues>;
}) => {
  let audioCreativeId: string | null = null;
  let imageCreativeIds: string[] = [];
  let creativeRedirectId: string | null = null;
  const hasRedirectUrl = !!formValues.creativeRedirect.url;

  setSelectedJourney(formValues.journey);

  if (formValues.audioCreatives.length && !hasRedirectUrl) {
    const [audioCreative] =
      formValues.audioCreatives as ICreativeAudioFormValues[];
    const commonAudioDetails = {
      name: audioCreative.name,
      fileName: audioCreative.fileName,
      uploadedDate: audioCreative.uploadedDate,
      url: audioCreative.url,
      duration: audioCreative.duration,
      trackingUrls: transformTrackingUrls(audioCreative.trackingUrls),
    };
    const audioDetails = audioCreative.id
      ? {
          ...commonAudioDetails,
          mimeType: audioCreative.mimeType,
        }
      : {
          ...commonAudioDetails,
          mimeType: audioCreative.file
            ? audioCreative.file.type
            : audioCreative.type,
        };
    const response: any = await createAudioCreative({
      variables: {
        ...audioDetails,
        advertiserId,
        type: CreativeType.Audio,
        territory,
      },
    });
    audioCreativeId = response.data.createCreative.id;
  }

  if (formValues.imageCreatives.length && !hasRedirectUrl) {
    const startUploadingImages = formValues.imageCreatives.map(
      (imageCreative: any) => {
        const commonImageDetails = {
          name: imageCreative.name,
          fileName: imageCreative.fileName,
          uploadedDate: imageCreative.uploadedDate,
          trackingUrls: transformTrackingUrls(imageCreative.trackingUrls),
          url: imageCreative.url,
          landingPageUrl: imageCreative.landingPageUrl
            ? imageCreative.landingPageUrl
            : null,
        };
        const imageDetails = imageCreative.id
          ? {
              ...commonImageDetails,
              mimeType: imageCreative.mimeType,
              size: imageCreative.size,
            }
          : {
              ...commonImageDetails,
              mimeType: imageCreative.file
                ? imageCreative.file.type
                : imageCreative.type,
              size: imageCreative.dimensions,
            };

        return createImageCreative({
          variables: {
            advertiserId,
            type: CreativeType.Image,
            ...imageDetails,
            territory,
          },
        });
      }
    );

    const uploadedImages = await Promise.all(startUploadingImages);
    imageCreativeIds = uploadedImages.map(
      (response: any) => response.data.createCreative.id
    );
  }

  if (hasRedirectUrl) {
    const response: any = await createCreativeRedirect({
      variables: {
        advertiserId,
        type: CreativeType.Redirect,
        territory,
        url: formValues.creativeRedirect.url
          ? formValues.creativeRedirect.url
          : null,
        consentVendorId: formValues.creativeRedirect.consentVendorId
          ? parseFormattedValue(formValues.creativeRedirect.consentVendorId)
          : null,
        trackingUrls: transformTrackingUrls(
          formValues.creativeRedirect.trackingUrls
        ),
      },
    });

    creativeRedirectId = response.data.createCreative.id;
  }

  const formattedValues = formatValues({ ...formValues, timeZone });

  const targetingDefinition = getTargetingDefinitionForCreate(
    formValues,
    formattedValues
  );

  const filteredValues: any = pickBy({
    ...formValues,
    campaignId,
    journey: null,
    channels: null,
    campaignName: null,
    campaignCpm: null,
    audioCreatives: null,
    imageCreatives: null,
    creativeRedirect: null,
    creativeRedirectId,
    sequence: formValues.sequence.length ? formValues.sequence : null,
    duration: formattedValues.duration,
    creativeAudioId: audioCreativeId,
    creativeImageIds: imageCreativeIds.length ? imageCreativeIds : null,
    startDate: formattedValues.startDate,
    endDate: formattedValues.endDate,
    tagIds: formattedValues.tagIds,
    inheritedTags: null,
    tags: null,
    dailyCap: formattedValues.dailyCap,
    targetingGroups: null,
    targetingMasterTemplates: null,
    targetingGeneralGroup: null,
    ...targetingDefinition,
    targetingVersion: null,
  });

  delete filteredValues.isEndDateInheritable;
  delete filteredValues.endDateIsInherited;
  delete filteredValues.startDateIsInherited;
  delete filteredValues.isStartDateInheritable;
  delete filteredValues.campaignStartDate;

  createAd({
    variables: filteredValues,
  }).catch((error) => {
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  });
};

const CreateAdContainer = ({
  history,
  match,
  campaign,
}: ICreateAdContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Ad.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    startDate: campaignStartDate,
    endDate: campaignEndDate,
    timeZone,
    order,
  } = campaign;
  const { endDate: orderEndDate, timeZone: orderTimeZone } = order as Order;
  const { id: advertiserId } = (campaign.order as Order)
    .advertiser as Advertiser;

  const [createImageCreative] = useCreateCreativeImageMutation();
  const [createAudioCreative] = useCreateCreativeAudioMutation();
  const [createCreativeRedirect] = useCreateCreativeRedirectMutation();

  const [createAd] = useCreateAdMutation({
    onCompleted(data) {
      handleOnAdComplete({
        response: data,
        history,
        campaignId: match.params.campaignId,
        selectedJourney,
      });
    },
  });

  const location = usePreviousLocation();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { allParametersInfo, loading: allParametersInfoLoading } =
    useAllParametersInfo();

  const gtmLogger = useGTMLogger();

  const initialValues = formatInitialValues(campaign, activeTerritory);

  return (
    <>
      <Formik<IAdValues>
        data-tc="adForm"
        initialValues={initialValues}
        validationSchema={AdValidation(
          campaignStartDate
            ? dateUtils.getDateInSpecificTimezone(campaignStartDate, timeZone)
            : null,
          campaignEndDate || orderEndDate
            ? dateUtils.getDateInSpecificTimezone(
                campaignEndDate || orderEndDate || '',
                campaignEndDate ? timeZone : orderTimeZone
              )
            : null,
          timeZone,
          campaign.dailyCap ? campaign.dailyCap : null,
          activeTerritory!
        )}
        onSubmit={(formValues, formikActions) => {
          if (formValues.targetingVersion === TargetingVersion.TargetingV2) {
            sendTargetingV2DataToGTM(
              formValues.targetingDefinitionV2,
              allParametersInfo,
              gtmLogger,
              formValues.territory
            );
          }

          submitForm({
            advertiserId,
            campaignId: match.params.campaignId,
            createAd,
            createAudioCreative,
            createCreativeRedirect,
            createImageCreative,
            setSelectedJourney,
            timeZone,
            toggleErrorModal,
            territory: activeTerritory!,
            formValues,
            formikActions,
          });
        }}
      >
        {(props) => (
          <AdTabsForm
            {...props}
            advertiserId={advertiserId}
            goBackTo={{
              pathname: `/campaign/${match.params.campaignId}`,
              state: location.state?.parent || {},
            }}
            campaignTags={campaign.tags}
            history={history}
            loading={allParametersInfoLoading}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createAd"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateAdContainer;
