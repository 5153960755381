import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { IChannelValues } from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';
import ChannelTabsForm, {
  channelButtons,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelTabsForm';
import ChannelFormValidation from 'features/inventory/channel/validations/channels';
import {
  ExtensionEnum,
  FormatEnum,
} from 'features/inventory/transcodePreset/containers/TranscodePresetContainer/TranscodePresetValues';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  BlockedKeyValueAttributes,
  BlockedKeyValues,
  Channel,
  ChannelType,
  EntityType,
  InventoryType,
  Publisher,
  SoundMode,
  UpdateChannelMutationFn,
  useUpdateChannelMutation,
  VastVersion,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import {
  formatAdRequestEnrichmentResponse,
  getAdRequestEnrichmentAttributes,
} from 'utils/adRequestEnrichment';
import {
  getCountry,
  getLocale,
  getStationLanguage,
} from 'utils/dataTransformation';
import { AlreadyExistMessages } from 'utils/errors';
import {
  differenceInValues,
  formatTranscodePresets,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrorsWithDuplicates,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { calculatePercentage } from 'utils/numbers';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    channelId: string;
  };
}
interface IUpdateChannelContainerProps {
  history: History;
  channel: Channel;
  match: IMatch;
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string,
  publisherId: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: publisherId,
    listEntity: selectedJourney === '/publisher/PARENT_ID' ? 'Channel' : '',
  });

export const submitUpdateChannel =
  (
    updateChannel: UpdateChannelMutationFn,
    initialValues: IChannelValues,
    match: IMatch,
    toggleErrorModal: () => void,
    setSelectedJourney: any
  ) =>
  (
    formValues: IChannelValues,
    { setStatus, setSubmitting }: FormikActions<IChannelValues>
  ) => {
    setSelectedJourney(formValues.journey);

    const filteredValues: any = {
      ...differenceInValues(
        {
          ...formValues,
          journey: null,
          transcodePresets: null,
          stationLanguage: null,
          country: null,
          publisherName: null,
          generatedDomains: null,
          hasExternalDealId: null,
          externalDealId: formValues.hasExternalDealId
            ? formValues.externalDealId
            : '',
          transcodePresetIds: formatTranscodePresets(
            formValues.transcodePresets
          ),
          locale: getLocale(formValues.stationLanguage, formValues.country),
          type: formValues.type || ChannelType.ChannelTypeUnspecified,
          adRequestEnrichment: getAdRequestEnrichmentAttributes(
            formValues.adRequestEnrichment
          ),
        },
        {
          ...initialValues,
          journey: null,
          transcodePresets: null,
          stationLanguage: null,
          country: null,
          generatedDomains: null,
          publisherName: null,
          hasExternalDealId: null,
          transcodePresetIds: formatTranscodePresets(
            initialValues.transcodePresets
          ),
          locale: getLocale(
            initialValues.stationLanguage,
            initialValues.country
          ),
          type: initialValues.type || ChannelType.ChannelTypeUnspecified,
          adRequestEnrichment: getAdRequestEnrichmentAttributes(
            initialValues.adRequestEnrichment
          ),
        }
      ),
      id: match.params.channelId,
    };

    updateChannel({
      variables: filteredValues,
    }).catch((error) => {
      const duplicateFields = [
        {
          message: AlreadyExistMessages.EXTERNAL_DEAL_ID_FOR_CHANNEL,
          error: {
            externalDealId:
              'This Deal ID already exists in DAX Audio. Please enter a different one.',
          },
        },
      ];
      handleFormErrorsWithDuplicates({
        duplicateFields,
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      });
    });
  };

const mapBlockedKeyValues = (blockedKeyValues: BlockedKeyValues[]) =>
  blockedKeyValues.reduce(
    (acc: BlockedKeyValueAttributes[], { key, values }) => {
      values.forEach((value) => {
        acc.push({ key, value });
      });
      return acc;
    },
    []
  );

const UpdateChannelContainer = ({
  channel,
  history,
  match,
  hasEditPermissions,
}: IUpdateChannelContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Channel.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateChannel] = useUpdateChannelMutation({
    onCompleted() {
      handleOnComplete(
        history,
        selectedJourney,
        channel.id,
        (channel.publisher as Publisher).id
      );
    },
  });

  const validateChannel = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    territories: getTerritoryValues(channel.territories),
    floorCpm:
      typeof channel.floorCpm === 'number' ? channel.floorCpm.toString() : '',
    name: channel.name as string,
    id: channel.id,
    altId: channel.altId,
    externalDealId: channel.externalDealId ? channel.externalDealId : '',
    hasExternalDealId: !!channel.externalDealId,
    publisherName: (channel.publisher as Publisher).name as string,
    publisherId: (channel.publisher as Publisher).id,
    publisherTerritories: (channel.publisher as Publisher).territories,
    vastVersion: channel.vastVersion as VastVersion,
    type: channel.type as ChannelType,
    inventoryType: channel.inventoryType as InventoryType,
    stationLanguage: getStationLanguage(channel.locale),
    country: getCountry(channel.locale),
    timeZone: channel.timeZone,
    defaultTranscodePresetId: channel.defaultTranscodePreset
      ? channel.defaultTranscodePreset.id
      : '',
    transcodePresets: channel.transcodePresets,
    transcodeFormat: FormatEnum.unspecified,
    transcodeBitRate: '',
    transcodeMode: SoundMode.ModeUnspecified,
    transcodeSampleRate: '',
    transcodeVolume: '',
    transcodeExtension: ExtensionEnum.unspecified,
    blacklistedIabCategoryCodes: channel.blacklistedIabCategories.map(
      (category) => category.code
    ),
    blacklistedDomains: channel.blacklistedDomains.length
      ? channel.blacklistedDomains
      : [''],
    blockedKeyValues: channel.blockedKeyValues?.length
      ? mapBlockedKeyValues(channel.blockedKeyValues)
      : [
          {
            key: '',
            value: '',
          },
        ],
    overrideConsentSignal: channel.overrideConsentSignal as boolean,
    includedInDefaultInventoryTargeting:
      channel.includedInDefaultInventoryTargeting,
    journey: channelButtons[0].url,
    status: channel.status,
    generatedDomains: '',
    trafficAcceptancePercentage:
      typeof channel.trafficAcceptancePercentage === 'number'
        ? calculatePercentage(channel.trafficAcceptancePercentage, 100)
        : '',
    minAdDuration:
      typeof channel.minAdDuration === 'number'
        ? channel.minAdDuration.toString()
        : '',
    maxAdDuration:
      typeof channel.maxAdDuration === 'number'
        ? channel.maxAdDuration.toString()
        : '',
    adRequestEnrichment: formatAdRequestEnrichmentResponse(
      channel.adRequestEnrichment
    ),
    targetingRestrictions: channel.targetingRestrictions,
  };

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IChannelValues>
        initialValues={initialValues}
        validationSchema={ChannelFormValidation(channel.status)}
        onSubmit={submitUpdateChannel(
          updateChannel,
          initialValues,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <ChannelTabsForm
            {...formikProps}
            channel={channel}
            history={history}
            update
            goBackTo={{
              pathname: `/publisher/${(channel.publisher as Publisher).id}`,
              state: location.state?.parent || {},
            }}
            hasEditPermissions={hasEditPermissions}
            publisherStatus={(channel.publisher as Publisher).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateChannel,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Channel,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateChannel,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateChannel"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateChannelWarningDialog"
      />
    </>
  );
};

export default UpdateChannelContainer;
