import colors from 'assets/styles/colors';
import { baseCheckboxStyles } from 'features/targetingV2/styles/checkbox.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseCheckboxStyles(theme),
  root: {},
  title: {
    color: colors.daxGreyscaleSilver,
    margin: theme.spacing(0, 1),
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    '&--outer': {
      margin: theme.spacing(1, 0),
    },
    '&--hasTitle': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  nodeGroupContainer: {
    width: '100%',
  },
  nodeContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    margin: 0,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  label: {
    fontSize: '14px',
    fontweight: '500',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    '&:disabled': {
      cursor: 'default',
      paddingLeft: theme.spacing(1),
      color: theme.palette.common.black,
    },
  },
  toggle: {
    fill: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(0, 0.5),
  },
  checkbox: {
    padding: theme.spacing(0, 0.5),
  },
  indeterminate: {
    color: colors.primaryBlue,
  },
  search: {
    marginBottom: theme.spacing(2.5),
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2.5),
  },
  breadcrumbContainer: {
    marginLeft: theme.spacing(0.5),
  },
  noNodesMessage: {
    color: theme.palette.grey[800],
    padding: theme.spacing(1),
  },
}));

export default useStyles;
