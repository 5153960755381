import MenuItem from 'features/targetingV2/components/core/MenuItem';
import React from 'react';

import useStyles from './IconButtonMenuItem.styles';

export interface IContextMenuItemType {
  MenuItemIcon: React.ReactNode;
  menuItemIconLabel: string;
  menuItemAction: () => void;
}

export interface IIconButtonMenuItemProps {
  menuOption: IContextMenuItemType;
  handleClose: () => void;
}

const IconButtonMenuItem = React.forwardRef(
  (props: IIconButtonMenuItemProps, ref: React.Ref<any>) => {
    const {
      handleClose,
      menuOption: { MenuItemIcon, menuItemAction, menuItemIconLabel },
    } = props;
    const classes = useStyles();

    return (
      <MenuItem
        ref={ref}
        className={classes.menuItem}
        onClick={() => {
          menuItemAction();
          handleClose();
        }}
      >
        <div className={classes.menuItemIcon}>
          {MenuItemIcon}
          <span>{menuItemIconLabel}</span>
        </div>
      </MenuItem>
    );
  }
);

IconButtonMenuItem.displayName = 'IconButtonMenuItem';

export default IconButtonMenuItem;
