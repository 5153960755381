import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    width: '175px',
    alignSelf: 'flex-start',
    '&--visibility': {
      visibility: 'hidden',
    },
  },
  select: {
    cursor: 'pointer',
    '&--readOnly': {
      cursor: 'default',
    },
  },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: '20px',
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px 0px 0px 4px',
    paddingLeft: theme.spacing(1.25),
    borderRight: 'none',
    fontSize: '14px',
    '&:hover, &:focus': {
      borderRadius: '4px 0px 0px 4px',
      backgroundColor: theme.palette.common.white,
    },
  },
  selectMenu: {
    boxShadow: `0px 2px 12px -1px ${colors.boxShadowLightGrey}`,
  },
  menuItem: {
    minHeight: '0px',
    fontSize: '14px',

    '&:hover': {
      backgroundColor: colors.primaryBlueLight,
    },

    '&:active': {
      backgroundColor: colors.primaryBlueMediumLight,
    },

    '&.Mui-selected': {
      backgroundColor: colors.primaryBlueMediumLight,

      '&:hover': {
        backgroundColor: colors.primaryBlueMediumLight,
      },
    },
  },
  unSelectableOption: {
    pointerEvents: 'none',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
