import classNames from 'classnames';
import Breadcrumbs from 'features/targetingV2/components/core/Breadcrumbs/Breadcrumbs';
import Checkbox from 'features/targetingV2/components/core/Checkbox';
import IconButton from 'features/targetingV2/components/core/IconButton';
import { Node } from 'features/targetingV2/hooks/UseCheckboxTree';
import React from 'react';

import useStyles from './CheckboxTree.styles';

interface ICheckboxTreeNodeProps {
  node: Node;
  disableSelection: boolean;
  classes?: {
    root?: string;
    list?: string;
    nodeContainer?: string;
    label?: string;
    toggle?: string;
    checkbox?: string;
    search?: string;
    controls?: string;
  };
  getCheckboxProps: (id: string) => {
    checked: boolean;
    onChange: () => void;
    type: string;
  };
  getExpandButtonProps: (id: string) => {
    onClick: () => void;
  };
  getTreeItemProps: (id: string) => {
    role: string;
    'aria-expanded': boolean;
    'aria-selected': boolean;
  };
  isExpanded: (id: string) => boolean;
  defaultExpandIcon: React.ReactNode;
  defaultCollapseIcon: React.ReactNode;
  displayAncestorPath: boolean;
  nodePadding?: number;
}

const CheckboxTreeNode = ({
  node,
  disableSelection,
  classes: externalClasses = {},
  getCheckboxProps,
  getExpandButtonProps,
  getTreeItemProps,
  isExpanded,
  defaultExpandIcon,
  defaultCollapseIcon,
  displayAncestorPath,
  nodePadding = 0,
}: ICheckboxTreeNodeProps) => {
  const classes = useStyles();

  return (
    <li {...getTreeItemProps(node.id)} className={classes.nodeGroupContainer}>
      <label
        className={classNames([
          classes.nodeContainer,
          externalClasses.nodeContainer,
        ])}
        style={{ paddingLeft: `${nodePadding}em` }}
        htmlFor={node.id}
      >
        {!disableSelection ? (
          <Checkbox
            color="primary"
            classes={{
              root: classes.checkbox,
              colorPrimary: classes.checkboxPrimaryColor,
              indeterminate: classes.indeterminate,
            }}
            className={externalClasses?.checkbox}
            inputProps={{ 'aria-label': `${node.label}-checkbox` }}
            {...getCheckboxProps(node.id)}
            value={node.id}
          />
        ) : null}
        <button
          type="button"
          className={classNames([classes.label, externalClasses.label])}
          disabled={disableSelection}
          onClick={getCheckboxProps(node.id).onChange}
        >
          {node.label}
        </button>
        {displayAncestorPath &&
          node.ancestorList &&
          node.ancestorList.length > 0 && (
            <Breadcrumbs
              nodes={node.ancestorList}
              identifier={node.label}
              classes={{
                container: classes.breadcrumbContainer,
              }}
            />
          )}
        {node.children && node.children.length > 0 && (
          <>
            <IconButton
              {...getExpandButtonProps(node.id)}
              className={classNames([classes.toggle, externalClasses.toggle])}
              aria-label={`Toggle ${node.label}`}
              disableRipple
            >
              {isExpanded(node.id) ? defaultCollapseIcon : defaultExpandIcon}
            </IconButton>
          </>
        )}
      </label>
      {node.children && node.children.length > 0 && isExpanded(node.id) && (
        <ul
          className={classNames([classes.list, externalClasses.list])}
          role="group"
        >
          {node.children.map((localNode: Node) => (
            <CheckboxTreeNode
              key={localNode.id}
              node={localNode}
              disableSelection={disableSelection}
              classes={{ ...classes, ...externalClasses }}
              getCheckboxProps={getCheckboxProps}
              getExpandButtonProps={getExpandButtonProps}
              getTreeItemProps={getTreeItemProps}
              isExpanded={isExpanded}
              defaultExpandIcon={defaultExpandIcon}
              defaultCollapseIcon={defaultCollapseIcon}
              displayAncestorPath={displayAncestorPath}
              nodePadding={nodePadding + 1}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CheckboxTreeNode;
