import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UpdateAdvertiserContainer from 'features/direct/advertiser/containers/UpdateAdvertiserContainer/UpdateAdvertiserContainer';
import { History } from 'history';
import {
  Advertiser,
  PermissionName,
  useAdvertiserQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface UpdateAdvertiserPageProps {
  history: History;
  match: {
    params: {
      advertiserId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const UpdateAdvertiserPage = ({
  history,
  match,
  userPermissions,
}: UpdateAdvertiserPageProps) => {
  const { loading, data } = useAdvertiserQuery({
    errorPolicy: 'all',
    variables: { id: match.params.advertiserId },
  });

  if (!loading && !data?.advertiser) return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Channel</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateAdvertiserContainer
            advertiser={data?.advertiser as Advertiser}
            history={history}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateAdvertiserPage;
