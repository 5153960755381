import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ctaContainer: {
    padding: theme.spacing(1, 2, 2),
    justifyContent: 'space-between',
  },
  dialog: {
    width: 'fit-content',
    padding: theme.spacing(0, 2, 0),
  },
}));

export default useStyles;
