import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuIconButton: {
    padding: theme.spacing(0, 0.7),
    margin: theme.spacing(0, 0.8),
    backgroundColor: 'transparent',
    color: colors.primaryBlue,
    fontSize: '14px',
    borderRadius: '4px',
    '&:disabled': {
      color: colors.daxGreyscaleSilver,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&--open': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  menuItem: {
    minHeight: 'auto',
    whiteSpace: 'normal',
    '&:hover, &:focus-visible': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  paper: {
    boxShadow: `0px 4px 8px ${colors.boxShadowLightGrey}`,
    borderRadius: '8px',
    width: '220px',
  },
  divider: {
    width: '90%',
    margin: 'auto',
  },
  progressContainer: {
    width: '100%',
  },
  progress: {
    margin: 0,
  },
}));

export default useStyles;
