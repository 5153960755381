import classNames from 'classnames';
import MenuItem from 'features/targetingV2/components/core/MenuItem';
import Typography from 'features/targetingV2/components/core/Typography';
import { IOptionType } from 'features/targetingV2/types/common';
import React, { useEffect, useState } from 'react';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './ExpandableMenuItem.styles';

export interface IExpandableMenuItemProps {
  label: string;
  options: IOptionType[];
  onOptionClick: (value: string) => void;
  updateMenuPosition: () => void;
  caption?: {
    text?: string;
    link?: {
      label: string;
      url: string;
    };
  };
  classes?: {
    menuItem?: string;
    parentItem?: string;
  };
  indentation?: number;
}

const ExpandableMenuItem = (props: IExpandableMenuItemProps) => {
  const {
    label,
    options,
    caption,
    onOptionClick,
    classes: externalClasses,
    indentation = 1,
    updateMenuPosition,
  } = props;

  const classes = useStyles({ indentation });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(!!anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    updateMenuPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <>
      <MenuItem
        key={label}
        className={classNames(classes.parentItem, externalClasses?.parentItem, {
          [`${classes.parentItem}--expanded`]: isExpanded,
        })}
        disableRipple
        onClick={() => {
          setIsExpanded((prevState) => !prevState);
        }}
        ListItemClasses={{
          root: classes.listItem,
          focusVisible: classes.focusVisible,
        }}
      >
        <>
          <div className={classes.parentItemLabelWrapper}>
            <Typography
              variant="body2"
              className={classNames(classes.menuItemLabel, {
                [`${classes.menuItemLabel}--expanded`]: isExpanded,
              })}
            >
              {label}
            </Typography>
            {(caption?.text || caption?.link) && (
              <span className={classes.menuItemCaption}>
                {caption.text}
                {caption.link && (
                  <a
                    href={caption.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {caption.link.label}
                  </a>
                )}
              </span>
            )}
          </div>
          {isExpanded ? (
            <ExpandLess data-testid="expandLessIcon" />
          ) : (
            <ExpandMore data-testid="expandMoreIcon" />
          )}
        </>
      </MenuItem>
      {isExpanded &&
        options.map((option: IOptionType) => (
          <MenuItem
            key={option.value}
            className={classNames(
              classes.menuItem,
              externalClasses?.menuItem,
              `${classes.menuItem}--indented`
            )}
            disableRipple
            onClick={() => {
              handleClose();
              onOptionClick(option.value);
            }}
          >
            <Typography variant="body2" className={classes.menuItemLabel}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
    </>
  );
};

export default ExpandableMenuItem;
