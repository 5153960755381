import { makeStyles, Theme } from '@material-ui/core/styles';

const contractsStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  contractsSection: {
    '& hr': {
      '&:last-of-type': {
        borderWidth: '0px',
        height: '0px',
      },
    },
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 'normal',
    margin: theme.spacing(1, 0, 1, 0),
  },
  line: {
    color: '#d8d8d8',
    backgroundColor: '#d8d8d8',
    height: '3px',
    width: '100%',
    borderWidth: 0,
    '&--slim': {
      height: '1px',
    },
  },
}));

export default contractsStyles;
