import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ErrorDialog from 'features/targetingV2/components/core/ErrorDialog/ErrorDialog';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import Technology from 'features/targetingV2/components/TechnologySection/Technology/Technology';
import useError from 'features/targetingV2/hooks/Error/useError';
import { IFormProps, SectionType } from 'features/targetingV2/types/common';
import { IParameterGroup } from 'features/targetingV2/types/targeting';
import { getNewGroupWithFirstParameter } from 'features/targetingV2/utils/common';
import { FieldArray } from 'formik';
import { useAllTechnologyParametersQuery } from 'interfaces/generated.types';
import React, { useEffect } from 'react';
import { getSelectOptions } from 'utils/dataTransformation';

const TechnologyBuilder = (props: IFormProps<any>) => {
  const { values, setFieldValue } = props;

  const technologiesFieldName =
    'targetingDefinitionV2.technologyTargeting.technologies';

  const { loading, data, error } = useAllTechnologyParametersQuery({
    fetchPolicy: 'cache-first',
  });

  const { hasError, toggleErrorModal, errorMessages, setErrorMessages } =
    useError(['An error has occurred.']);

  const handleError = (err: any) => {
    const errorHeader =
      'Error while loading technology targeting parameters. Please refresh and try again.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const technologies = values.targetingDefinitionV2.technologyTargeting
    ?.technologies as IParameterGroup[];

  const allParametersInfo = data?.allTechnologyParameters || [];

  const optionalAddFirstParameterButton = !technologies.length && (
    <AddParameterDropdownButton
      options={getSelectOptions(allParametersInfo, 'name', 'type')}
      onOptionClick={(parameterType: string) => {
        setFieldValue(technologiesFieldName, [
          getNewGroupWithFirstParameter(parameterType),
        ]);
      }}
      loading={loading}
      testId="add-first-parameter-btn"
    />
  );

  return (
    <>
      <TargetingSection
        sectionType={SectionType.Technology}
        headerChildren={optionalAddFirstParameterButton}
        restrictionsList={values.targetingRestrictions}
      >
        {!loading && (
          <FieldArray name={technologiesFieldName}>
            {({ remove: removeTechnology }) => (
              <>
                {technologies.map(
                  (technology: IParameterGroup, index: number) => (
                    <Technology
                      // eslint-disable-next-line react/no-array-index-key
                      key={`technology-${index}`}
                      technology={technology}
                      technologyFieldName={`${technologiesFieldName}[${index}]`}
                      technologyIndex={index}
                      allParametersInfo={allParametersInfo}
                      areParameterOptionsLoading={loading}
                      setFieldValue={setFieldValue}
                      removeTechnology={removeTechnology}
                    />
                  )
                )}
              </>
            )}
          </FieldArray>
        )}
      </TargetingSection>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        name="allTechnologyParameterError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default TechnologyBuilder;
