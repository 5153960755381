import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    margin: theme.spacing(1.5, 0.75, 1.5, 0.75),
    padding: theme.spacing(2, 1.5, 1.5, 1.5),
    backgroundColor: theme.palette.grey[200],
    borderRadius: '10px',
  },
  innerContainer: {
    margin: theme.spacing(1.5, 0, 0, 0),
    padding: theme.spacing(2, 0.5, 1, 0.5),
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
  },
  error: {
    margin: 0,
    fontSize: theme.spacing(1.625),
    color: theme.palette.error.main,
  },
}));

export default useStyles;
