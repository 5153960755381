import pageStyles from 'assets/styles/components/Page.styles';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { GET_DSP } from 'features/programmatic/dsp/graphql/queries';
import { seatStatusValues } from 'features/programmatic/seat/components/SeatTabsForm/SeatFormValues';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  EntityType,
  Seat,
  SeatStatus,
  useUpdateSeatMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

interface ISeatsTableProps {
  history: History;
  match: {
    params: {
      dspId: string;
    };
  };
  seats: Seat[];
  isEditable?: boolean;
}

interface ISeatsRow {
  id: string;
  altId: number;
  name: string;
  status: SeatStatus;
  externalSeatId: string;
}

const formatData = memoizeOne((data: Seat[]) =>
  data.map(({ id, altId, name, status, externalSeatId }) => ({
    id,
    altId,
    name,
    status,
    externalSeatId,
  }))
);

const SeatsTable = ({
  history,
  match,
  seats,
  isEditable = true,
}: ISeatsTableProps) => {
  const classes = pageStyles();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateSeat] = useUpdateSeatMutation({
    refetchQueries: [
      {
        query: GET_DSP,
        variables: { id: match.params.dspId, territories: [activeTerritory] },
      },
    ],
  });
  const validateSeat = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createSeatCta = isEditable && (
    <StyledLink
      location={{
        pathname: `/dsp/${match.params.dspId}/seat`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newSeatButton"
    >
      Create New Seat
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<ISeatsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/seat/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'External Seat Id',
        accessor: 'externalSeatId',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ISeatsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: seatStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={seatStatusValues}
                name="seatStatusSelect"
                dataTc="seatStatusSelect"
              />
            )}
            onCellValidate={onCellValidate}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Seats"
        columns={columns}
        data={formatData(seats)}
        isEditable={isEditable}
        dataTc={`${match.params.dspId || ''}listSeatsTable`}
        isPageTable={false}
        onCellUpdate={(row: ISeatsRow, setErrorModal: any, setUpdating: any) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateSeat,
            handleContinue: () => history.push(`/seat/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Seat',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateSeat,
            entity: { ...entity, type: EntityType.Seat },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createSeatCta}
      />
    </div>
  );
};

export default SeatsTable;
