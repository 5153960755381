import DualFilteredTreeList from 'components/DualFilteredTreeList/DualFilteredTreeList';
import Loader from 'components/Loader/Loader';
import { ITreeItems } from 'interfaces';
import {
  IabCategory,
  useAllIabCategoriesQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { formatIabCategories } from 'utils/treeDataTransformation';

interface IIabCategoriesTreeProps {
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  iabCategoryCodes: string[];
  dataTc: string;
  attribute: string;
  setIabCategoriesNames?: (value: string[]) => void;
}
const IabCategoriesTree = ({
  onChange,
  iabCategoryCodes,
  dataTc,
  attribute,
  setIabCategoriesNames,
}: IIabCategoriesTreeProps) => {
  const { loading, data } = useAllIabCategoriesQuery();

  const allItems = formatIabCategories(
    (data?.allIabCategories as IabCategory[]) || []
  );

  if (loading) {
    return <Loader />;
  }

  const getIabCategoriesNames = (selectedItems: ITreeItems[]) => {
    const selectedCategoryNames = selectedItems.reduce(
      (acc, selectedParentCategory) => {
        const selectedChildrenCategories =
          selectedParentCategory?.children?.map((child) => child.label) || [];

        const childrenCategories =
          allItems
            .find((item) => item.value === selectedParentCategory.value)
            ?.children?.map((child) => child.label) || [];

        if (childrenCategories.length === selectedChildrenCategories.length) {
          return acc.concat([selectedParentCategory.label]);
        }
        return acc.concat(selectedChildrenCategories);
      },
      [] as string[]
    );

    if (setIabCategoriesNames) setIabCategoriesNames(selectedCategoryNames);
  };

  return (
    <DualFilteredTreeList
      data-tc={dataTc}
      allItems={allItems}
      onChangeWithIds={(selectedValues: string[]) =>
        onChange(attribute, selectedValues)
      }
      selectedItemsIds={iabCategoryCodes}
      onChangeWithTreeItems={getIabCategoriesNames}
    />
  );
};

export default IabCategoriesTree;
