import Loader from 'components/Loader/Loader';
import debounce from 'debounce-promise';
import {
  IAudienceSegmentMinimal,
  IListAudienceResponse,
} from 'features/targeting/graphql/audienceSegments/queries';
import { IFormProps } from 'interfaces';
import { useListAudienceQuery } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { ITargetingComponentProps } from 'utils/targetingDefinitions';

import { TargetingAsyncMultiSelect } from '../TargetingMultiSelect/TargetingMultiSelect';

export const audienceSegmentValues = {
  audienceSegmentParams: {
    segments: [],
  },
};

const createOptions = (data: IAudienceSegmentMinimal[]) =>
  data.map((segment) => ({
    label: segment.displayName,
    value: segment.id.toString(),
  }));

export const loadOptions = async (
  searchTerm: string,
  setFetchedOptions: any,
  data?: IListAudienceResponse
) => {
  if (searchTerm.length >= 2) {
    if (data) {
      const listResult = data.allAudienceSegments.filter(
        (audience: IAudienceSegmentMinimal) =>
          audience.displayName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          audience.id.toString().includes(searchTerm)
      );
      const response: IAudienceSegmentMinimal[] = await new Promise((resolve) =>
        resolve(listResult)
      );
      const formattedData = createOptions(response);
      setFetchedOptions(formattedData);
      return formattedData;
    }
    return [];
  }
  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 1000, {
  leading: true,
});

const TargetingAudience = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const { loading, data } = useListAudienceQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <ApolloConsumer>
      {() => (
        <TargetingAsyncMultiSelect
          {...props}
          label="Audience Segment"
          placeholder="Begin typing to find an Audience Segment"
          targetingName="audienceSegmentParams.segments"
          loadOptions={(value: string) =>
            debouncedLoadOptions(value, setFetchedOptions, data)
          }
          fetchedOptions={fetchedOptions}
        />
      )}
    </ApolloConsumer>
  );
};

export default TargetingAudience;
