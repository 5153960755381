import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  checkbox: {
    color: theme.palette.grey[800],
    '&.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '&.MuiIconButton-root': {
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export default useStyles;
