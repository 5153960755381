import { baseFontStyles } from 'assets/styles/components/Font.styles';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...baseFontStyles(),
    grid: {
      margin: theme.spacing(1, 0, 4),
    },
    formControl: {
      width: '100%',
      margin: theme.spacing(2, 0, 1),
    },
    error: {
      fontSize: '0.625em',
      color: theme.palette.error.main,
    },
    buttonWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      alignItems: 'flex-end',
    },
  })
);

export default useStyles;
