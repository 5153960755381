import AddParameterButton from 'features/targetingV2/components/AddParameterButton/AddParameterButton';
import CustomKvpTextFieldsRow from 'features/targetingV2/components/CustomKvpTextFieldsRow/CustomKvpTextFieldsRow';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import {
  ICustomKvp,
  IFormProps,
  SectionType,
} from 'features/targetingV2/types/common';
import { defaultCustomKvp } from 'features/targetingV2/utils/defaults';
import { FieldArray } from 'formik';
import React from 'react';

export interface ICustomKvpsSectionProps {
  customKvps: ICustomKvp[];
  disableAddCustomKvpButton: boolean;
  sectionFieldName: string;
}

const CustomKvpsSection = (
  props: IFormProps<any> & ICustomKvpsSectionProps
) => {
  const {
    errors,
    touched,
    customKvps,
    disableAddCustomKvpButton,
    sectionFieldName,
    setFieldValue,
  } = props;

  const optionalAddRowButton = !customKvps.length && (
    <AddParameterButton
      onClick={() => setFieldValue(sectionFieldName, [defaultCustomKvp])}
      testId="add-first-custom-row-btn"
    />
  );

  return (
    <TargetingSection
      sectionType={SectionType.Custom}
      hideDivider
      headerChildren={optionalAddRowButton}
      hideTooltip
    >
      <FieldArray name={sectionFieldName}>
        {({ push: pushCustomKvpRow, remove: removeCustomKvpRow }) => (
          <div data-testid={sectionFieldName}>
            {customKvps.map((_, index: number) => (
              <CustomKvpTextFieldsRow
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                errors={errors}
                touched={touched}
                rowFieldName={`${sectionFieldName}[${index}]`}
                removeRow={() => removeCustomKvpRow(index)}
                rowId={`Custom Kvp ${index + 1}`}
              />
            ))}
            {customKvps.length > 0 && (
              <AddParameterButton
                onClick={() => pushCustomKvpRow(defaultCustomKvp)}
                disabled={disableAddCustomKvpButton}
                testId="add-custom-row-btn"
              />
            )}
          </div>
        )}
      </FieldArray>
    </TargetingSection>
  );
};

export default CustomKvpsSection;
