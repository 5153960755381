import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateChannelContainer from 'features/inventory/channel/containers/UpdateChannelContainer/UpdateChannelContainer';
import { History } from 'history';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import {
  Channel,
  PermissionName,
  useChannelQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface UpdateChannelPageProps {
  history: History;
  match: {
    params: {
      channelId: string;
    };
  };
}

const channelEditPermissions = [PermissionName.Primary, PermissionName.Supply];

const UpdateChannelPage = ({ history, match }: UpdateChannelPageProps) => {
  const { loading, data } = useChannelQuery({
    variables: { id: match.params.channelId },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const userPermissions = useUserPermissions();

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, channelEditPermissions) &&
    isActiveTerritoryAssignedToEntity(
      data?.channel?.territories!,
      activeTerritory!
    );

  if (!loading && !data?.channel) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Channel</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateChannelContainer
            match={match}
            channel={data?.channel as Channel}
            history={history}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateChannelPage;
