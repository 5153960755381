import debounce from 'debounce-promise';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import Loader from 'components/Loader/Loader';

import {
  IListDmpSegmentsResponse,
  SEARCH_DMP_SEGMENTS,
} from 'features/targeting/graphql/dmpSegments/queries';

import { IFormProps } from 'interfaces';
import {
  DmpSegmentType,
  NielsenSegment,
  useListDmpSegmentsQuery,
} from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

import { TargetingAsyncMultiSelect } from '../TargetingMultiSelect/TargetingMultiSelect';
import { targetingDMPNames } from '../TargetingParameters/TargetingParametersValues';

export const dmpSegmentValues = {
  dmpSegmentParams: {
    segments: [],
  },
};

const createOptions = (data: NielsenSegment[]) =>
  data.map((segment) => ({
    label: segment.fullName,
    value: segment.id.toString(),
  }));

export const loadOptions = async (
  searchTerm: string,
  client: any,
  setFetchedOptions: any,
  data?: IListDmpSegmentsResponse
) => {
  if (searchTerm.length >= 2) {
    if (data) {
      const listResult = data.allDmpSegments.filter(
        (segment: NielsenSegment) =>
          segment.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          segment.id.toString().includes(searchTerm)
      );
      const response: NielsenSegment[] = await new Promise((resolve) =>
        resolve(listResult)
      );

      const formattedData = createOptions(response);
      setFetchedOptions(formattedData);
      return formattedData;
    }

    const { data: searchResult } = await client.query({
      query: SEARCH_DMP_SEGMENTS,
      variables: { text: searchTerm, type: targetingDMPNames.NIELSEN },
    });

    if (searchResult) {
      const formattedData = createOptions(searchResult.searchDmpSegments);
      setFetchedOptions(formattedData);
      return formattedData;
    }

    return [];
  }
  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 1000, {
  leading: true,
});

const TargetingDMPSegment = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const { loading, data } = useListDmpSegmentsQuery({
    variables: { type: DmpSegmentType.Nielsen },
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <ApolloConsumer>
      {(client) => (
        <TargetingAsyncMultiSelect
          {...props}
          label="Nielsen Segments"
          placeholder="Begin typing to find a Nielsen Segment"
          targetingName="dmpSegmentParams.segments"
          loadOptions={(value: string) =>
            debouncedLoadOptions(value, client, setFetchedOptions, data)
          }
          fetchedOptions={fetchedOptions}
        />
      )}
    </ApolloConsumer>
  );
};

export default TargetingDMPSegment;
