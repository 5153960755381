import CreatePublisherContractContainer from 'features/inventory/publisher/containers/CreatePublisherContractContainer/CreatePublisherContractContainer';
import UpdatePublisherContractContainer, { IFormattedPublisherContract } from 'features/inventory/publisher/containers/UpdatePublisherContractContainer/UpdatePublisherContractContainer';
import { Publisher } from 'interfaces/generated.types';
import React from 'react';

import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import useStyles from './PublisherContractDialog.styles';

interface IPublisherContractDialogProps {
  refetch: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
  publisher?: Publisher;
  contract?: IFormattedPublisherContract;
  update?: boolean;
  readOnly?: boolean;
  hasEditPermissions?: boolean;
}

const PublisherContractDialog = (props: IPublisherContractDialogProps) => {
  const {
    refetch,
    handleClose,
    isModalOpen,
    publisher,
    contract,
    update = false,
    readOnly = false,
    hasEditPermissions = false,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isModalOpen}
      onClose={handleClose}
      data-testid="publisherContractDialog"
    >
      <div className={classes.container}>
        <Typography className={classes.title}>Publisher Contract</Typography>
        {update && contract && (
          <UpdatePublisherContractContainer
            publisherContract={contract}
            handleClose={handleClose}
            refetch={refetch}
            readOnly={readOnly}
            hasEditPermissions={hasEditPermissions}
          />
        )}
        {!update && publisher && (
          <CreatePublisherContractContainer
            publisher={publisher}
            handleClose={handleClose}
            refetch={refetch}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </div>
    </Dialog>
  );
};

export default PublisherContractDialog;
