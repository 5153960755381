import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles: any = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: theme.spacing(0.8),
    marginRight: theme.spacing(0.2),
  },
  text: {
    fontSize: '14px',
  },
  breadcrumbs: {
    color: 'inherit',
  },
  separator: {
    margin: theme.spacing(0.2, 0.5, 0),
    fontWeight: 300,
  },
  bracket: {
    fontSize: '10px',
    marginTop: theme.spacing(0.2),
    '&--left': {
      marginRight: theme.spacing(0.4),
    },
    '&--right': {
      marginLeft: theme.spacing(0.4),
    },
  },
}));

export default useStyles;
