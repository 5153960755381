import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import CreatePublisherContainer from 'features/inventory/publisher/containers/CreatePublisherContainer/CreatePublisherContainer';
import { History } from 'history';
import { Network, useNetworkMinimalQuery } from 'interfaces/generated.types';
import React from 'react';

export interface ICreatePublisherPage {
  history: History;
  match: {
    params: {
      networkId: string;
    };
  };
}

const CreatePublisherPage = ({ history, match }: ICreatePublisherPage) => {
  const { loading, error, data } = useNetworkMinimalQuery({
    variables: { id: match.params.networkId },
  });

  if (!loading && (error || !(data && data.network)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Publisher</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreatePublisherContainer
            history={history}
            match={match}
            network={data?.network as Network}
          />
        )}
      </Container>
    </main>
  );
};

export default CreatePublisherPage;
