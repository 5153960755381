import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  TableRow as MUITableRow,
  TableRowProps as MUITableRowProps,
} from '@material-ui/core';

import useStyles from './TableRow.styles';

export type TableRowProps = MUITableRowProps;

const TableRow = React.forwardRef(
  (props: TableRowProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUITableRow ref={ref} classes={classes} {...otherProps} />;
  }
);

TableRow.displayName = 'TableRow';

export default TableRow;
