import { baseButtonStyles } from 'assets/styles/components/Button.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseButtonStyles(theme),
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.625),
    minWidth: theme.spacing(15),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),

    '&:hover': {
      borderWidth: '1px',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.light,
      borderWidth: '1px',
    },
  },
  expandIcon: {
    paddingLeft: theme.spacing(0.25),
  },
  item: {
    padding: theme.spacing(0, 1),
    background: theme.palette.common.white,
    minHeight: theme.spacing(4.8),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export default useStyles;
