import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    width: '151px',
  },
  dropdownIcon: {
    display: 'flex',
    marginRight: theme.spacing(1),
    width: '15px',
  },
  checkIcon: {
    width: '15px',
    height: '12px',
  },
  notAvailableIcon: {
    width: '12px',
    height: '12px',
  },
  dropdownBorder: {
    borderRadius: '0px',
    '&:hover, &:focus': {
      borderRadius: '0px',
    },
  },
}));

export default useStyles;
