import {
  getHttpLinkOptions,
  initializeApolloClient,
} from 'clients/dax.graphql';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import React from 'react';
import { ApolloProvider } from 'react-apollo';

const ApolloProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const { validateJwtToken } = useAuthContext();
  const {
    state: { assumedId },
  } = useCustomApolloContext();

  const customHeaders = {
    'X-DAX-Assumed-Identity': assumedId,
  };

  const httpLinkOptions = getHttpLinkOptions(validateJwtToken, customHeaders);
  const client = initializeApolloClient(httpLinkOptions);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
