import TargetingGroupings from 'features/targeting/components/TargetingGroupings/TargetingGroupings';
import Targeting from 'features/targetingV2/components/Targeting/Targeting';
import { IFormProps } from 'interfaces';
import { TargetingVersion } from 'interfaces/generated.types';
import React from 'react';

export interface ITargetingVersionSelectionProps {
  isTargetingVersionToggleDisabled?: boolean;
  filterByActiveTerritory?: boolean;
  children?: React.ReactNode;
  hasTargetingVersionToggle?: boolean;
  hasTargetingV2HelpAndTutLink?: boolean;
}

const TargetingVersionSelection = (
  props: IFormProps<any> & ITargetingVersionSelectionProps
) => {
  const {
    isTargetingVersionToggleDisabled = false,
    values: { targetingVersion },
    children,
    hasTargetingVersionToggle = true,
    hasTargetingV2HelpAndTutLink = true,
  } = props;

  const isTargetingV2Selected =
    targetingVersion === TargetingVersion.TargetingV2;

  return (
    <div data-testid="targeting-version-selection-container">
      {isTargetingV2Selected ? (
        <Targeting
          {...props}
          isTargetingVersionToggleDisabled={isTargetingVersionToggleDisabled}
          hasTargetingVersionToggle={hasTargetingVersionToggle}
          hasHelpAndTutLink={hasTargetingV2HelpAndTutLink}
        >
          {children}
        </Targeting>
      ) : (
        <TargetingGroupings
          {...props}
          targetingVersion={targetingVersion}
          isTargetingVersionToggleDisabled={isTargetingVersionToggleDisabled}
          hasTargetingVersionToggle={hasTargetingVersionToggle}
        />
      )}
    </div>
  );
};

export default TargetingVersionSelection;
