import DAXLogo from 'assets/images/DAX_logo_white.png';
import Image from 'components/Image/Image';
import React from 'react';

import useStyles from './PageContainer.styles';

const PageContainer = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <main>
      <div className={classes.container}>
        <div className={classes.pageContent}>
          <Image src={DAXLogo} alt="DAX Audio logo" className={classes.logo} />
          {children}
        </div>
      </div>
    </main>
  );
};

export default PageContainer;
