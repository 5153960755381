import * as Yup from 'yup';

import commons from 'validations/commons';

const DspFormValidation = () =>
  Yup.object().shape({
    name: commons.name('Dsp Name'),
    dailyCap: commons.optionalPrice({
      messages: {
        minMessage: 'Daily Cap should be higher than or equal to 1',
        maxMessage: 'Daily Cap should be lower than or equal to 1,000,000,000',
      },
      minimum: 1,
      maximum: 1000000000,
      fieldKey: 'dailyCap',
    }),
    territories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .required('DSP should be assigned at least one territory'),
  });

export default DspFormValidation;
