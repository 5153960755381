import cx from 'classnames';
import React from 'react';
import shortid from 'shortid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import useStyles from './ErrorDialog.styles';

interface IErrorDialog {
  dataTc: string;
  isOpen: boolean;
  handleClose: () => void;
  handleContinue?: () => void;
  errorMessages: string[];
  content: {
    title: string;
    closeButton: string;
    continueButton?: string;
  };
}

export interface ISetErrorModal {
  isOpened: boolean;
  errors: any;
  content: { title: string; closeButton: string; continueButton: string };
  handleContinue: (() => void) | undefined;
}

const ErrorDialog = ({
  dataTc,
  isOpen,
  handleClose,
  errorMessages,
  handleContinue,
  content: { title, closeButton, continueButton },
}: IErrorDialog) => {
  const classes = useStyles({});
  const showContinueButton = !!(handleContinue && continueButton);
  return (
    <Dialog
      data-tc={`${dataTc}Dialog`}
      open={isOpen}
      onClose={handleClose}
      data-testid="dialogError"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">{title}</Typography>
        <IconButton
          onClick={handleClose}
          classes={{ root: classes.closeButton }}
          data-tc={`${dataTc}DialogCloseX`}
        >
          <CloseIcon />
          <span className="sr-only">Close the dialog</span>
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <List>
          {errorMessages.map((error: string) => (
            <li key={shortid.generate()} className={classes.errorMessage}>
              {error}
            </li>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        classes={{
          root: cx(classes.actions, {
            [`${classes.actions}--space-between`]: showContinueButton,
          }),
        }}
      >
        <StyledButton
          testId={`${dataTc}DialogCloseButton`}
          onClick={handleClose}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}DialogCloseButton`}
        >
          {closeButton}
        </StyledButton>
        {showContinueButton && (
          <StyledButton
            testId={`${dataTc}DialogContinueButton`}
            onClick={handleContinue as () => void}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Contained}
            data-tc={`${dataTc}DialogContinueButton`}
          >
            {continueButton}
          </StyledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
