import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import useLoginRedirect from 'features/login/hooks/useLoginRedirect';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { Typography } from '@material-ui/core';

import PageContainer from '../PageContainer/PageContainer';
import useStyles from './LoginOptionsPage.styles';

const LoginOptionsPage = () => {
  const classes = useStyles();
  const pageClasses = pageStyles();
  const [error, setError] = useState('');

  const { loaded, state, onGlobalStaffSignIn } = useAuthContext();
  const location = usePreviousLocation();
  const redirect = useLoginRedirect();

  const handleGlobalStaffSignIn = () => {
    onGlobalStaffSignIn(setError);
  };

  const isLoading = !loaded || state === AuthState.Fetching;

  if (state === AuthState.LoggedIn) {
    if (redirect) {
      redirect();
    } else {
      return <Redirect to="/home" />;
    }
  }

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Sign In
          </Typography>
          <>
            <div className={classes.ctaContainer}>
              <StyledLink
                color={LinkColorEnum.Filled}
                location={{
                  pathname: `/sign-in`,
                  state: {
                    parent: location.state,
                  },
                }}
              >
                DAX Client
              </StyledLink>

              <StyledButton
                onClick={() => {
                  handleGlobalStaffSignIn();
                }}
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Outlined}
                className={classes.cta}
              >
                Global Staff
              </StyledButton>
            </div>
          </>
        </div>
        {error && (
          <Typography
            variant="body2"
            align="center"
            className={pageClasses.errorMessage}
          >
            {error}
          </Typography>
        )}
        <Typography
          variant="caption"
          align="center"
          className={classNames([classes.italic, classes.footer])}
        >
          If you require access, please contact us
        </Typography>
      </div>
    </PageContainer>
  );
};

export default LoginOptionsPage;
