import useStyles from 'assets/styles/components/Form.styles';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import {
  actionTypes,
  IDealFormValues,
  priorityTypes,
} from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import { Field } from 'formik';
import {
  fieldToSwitch,
  Switch,
  SwitchProps,
  TextField,
} from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import { AuctionType, User } from 'interfaces/generated.types';
import React from 'react';
import { currencyValues } from 'utils/currency';
import { createUserOption } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { parseFormattedValue } from 'utils/numbers';
import { TerritoryLabel } from 'utils/territory';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSwitch from '@material-ui/core/Switch';
import LaunchIcon from '@material-ui/icons/Launch';

interface IDealDetailsProps {
  allUsers: User[];
}

export const EndDateSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('endDate', null);
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

export const AdSeparationSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('adSeparation', '');
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

const DealDetails = (
  props: IFormProps<IDealFormValues> & IDealDetailsProps
) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    update = false,
    values,
    setFieldValue,
    setFieldTouched,
    allUsers,
    status = {},
  } = props;

  const classes = useStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Sales Channel Name"
              placeholder="Sales Channel Name"
              name="salesChannelName"
              data-tc="salesChannelName"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/sales-channel/${values.salesChannelId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="dealSalesChannelLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.salesChannelName}
              </span>
            </IconButton>
          </div>
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Internal Deal ID"
              placeholder="Internal Deal ID"
              data-tc="dealId"
              name="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Deal Name"
            placeholder="Deal Name"
            data-tc="dealName"
            name="name"
            autoFocus={!update}
            fullWidth
            inputProps={{ 'data-testid': 'dealName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MultiSelect
            data-tc="dealOwnerMultiSelect"
            data-testId="dealOwnerMultiSelect"
            id="dealOwnerMultiSelect"
            label="Deal Owner"
            isMulti={false}
            selectAll={false}
            maxSelectHeight={250}
            name="owner"
            noMargin
            onBlur={() => setFieldTouched('owner', true)}
            onChange={(fieldValue: OptionType) =>
              setFieldValue('owner', fieldValue)
            }
            options={allUsers.map(
              (user) => createUserOption(user) as OptionType
            )}
            placeholder="Select an Owner..."
            value={values.owner}
            attributes={{
              fieldAttribute: 'dealOwnerField',
              menuAttribute: 'dealOwnerMenuOptions',
              chipAttribute: 'dealOwnerChip',
            }}
            errorProps={{
              helperText: (touched.owner && errors.owner) || status.owner,
              FormHelperTextProps: {
                error: !!((touched.owner && errors.owner) || status.owner),
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="dealPriority"
            fullWidth
            label="Priority"
            name="priority"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.priority && errors.priority) || status.priority
            }
            FormHelperTextProps={{
              error: !!(
                (touched.priority && errors.priority) ||
                status.priority
              ),
            }}
          >
            {priorityTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="dealAuctionType"
            fullWidth
            label="Auction Type"
            name="auctionType"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.auctionType && errors.auctionType) || status.auctionType
            }
            FormHelperTextProps={{
              error: !!(
                (touched.auctionType && errors.auctionType) ||
                status.auctionType
              ),
            }}
          >
            <MenuItem value={AuctionType.AuctionTypeUnspecified}>
              <i>None (Reset)</i>
            </MenuItem>
            {actionTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="privateAuction"
                  color="primary"
                  data-tc="privateAuction"
                />
              }
              label="Private Auction"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="dealCurrency"
            fullWidth
            label="Currency"
            name="currency"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.currency && errors.currency) || status.currency
            }
            FormHelperTextProps={{
              error: !!(
                (touched.currency && errors.currency) ||
                status.currency
              ),
            }}
          >
            {currencyValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="dealFloorCpm"
            decimalScale={2}
            error={errors.floorCpm || status.floorCpm}
            label="Floor CPM"
            name="floorCpm"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.floorCpm || !!status.floorCpm}
            value={values.floorCpm}
            fullWidth
          />
          {parseFormattedValue(values.floorCpm) > 100 && (
            <FormHelperText error data-tc="dealFloorCpmOverHundredWarning">
              Floor CPM over 100
            </FormHelperText>
          )}
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={values.overridePublisherFloor}
                  data-tc="overridePublisherFloor"
                  onChange={() =>
                    setFieldValue(
                      'overridePublisherFloor',
                      !values.overridePublisherFloor
                    )
                  }
                  onFocus={() =>
                    setFieldTouched('overridePublisherFloor', true)
                  }
                  value={values.privateAuction}
                  color="primary"
                />
              }
              label="Override Publisher Floor"
            />
            {(((touched.overridePublisherFloor || touched.auctionType) &&
              errors.overridePublisherFloor) ||
              status.auctionType) && (
              <FormHelperText data-tc="overridePublisherFloorError" error>
                {errors.overridePublisherFloor || status.auctionType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative
            dataTc="dealOffsetCpm"
            decimalScale={2}
            error={errors.offsetCpm || status.offsetCpm}
            label="Off-set"
            name="offsetCpm"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.offsetCpm || !!status.offsetCpm}
            value={values.offsetCpm}
            fullWidth
          />
          {parseFormattedValue(values.offsetCpm) > 100 && (
            <FormHelperText error data-tc="dealOffsetCpmOverHundredWarning">
              Off-set over 100
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={values.startDate}
              label="Deal Start Date"
              name="startDate"
              onChange={(value) => setFieldValue('startDate', value)}
              onClose={() => setFieldTouched('startDate', true)}
              data-tc="dealStartDate"
            />
            {((touched.startDate && errors.startDate) || status.startDate) && (
              <FormHelperText data-tc="dealStartDateError" error>
                {errors.startDate || status.startDate}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={values.endDate}
              label="Deal End Date"
              name="endDate"
              onChange={(value) => setFieldValue('endDate', value)}
              onClose={() => setFieldTouched('endDate', true, false)}
              disabled={values.noEndDate}
              data-tc="dealEndDate"
            />
            {((touched.endDate && errors.endDate) || status.endDate) && (
              <FormHelperText data-tc="dealEndDateError" error>
                {errors.endDate || status.endDate}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={EndDateSwitch}
                  type="checkbox"
                  name="noEndDate"
                  data-tc="dealNoEndDate"
                />
              }
              label="No end date"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="timeZone"
            fullWidth
            label="Time Zone"
            name="timeZone"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.timeZone && errors.timeZone) || status.timeZone
            }
            FormHelperTextProps={{
              error: !!(
                (touched.timeZone && errors.timeZone) ||
                status.timeZone
              ),
            }}
          >
            {dateUtils.timezoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="adSeparation"
            decimalScale={3}
            label="Ad Separation (seconds)"
            name="adSeparation"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.adSeparation as any}
            disabled={!values.hasAdSeparation}
            fullWidth
            touched={touched.adSeparation || !!status.adSeparation}
            error={errors.adSeparation || status.adSeparation}
          />
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormControlLabel
              control={
                <Field
                  component={AdSeparationSwitch}
                  type="checkbox"
                  name="hasAdSeparation"
                  data-tc="hasAdSeparation"
                />
              }
              label="Enable Ad Separation"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="adExclusive"
                  color="primary"
                  data-tc="dealAdExclusive"
                />
              }
              label="Make Ads from this Deal Exclusive"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="trusted"
                  color="primary"
                  data-tc="dealCreativeQuarantine"
                />
              }
              label="Quarantine creatives"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              data-tc="territory"
              id="Territory"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default DealDetails;
