import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0, 1),
    padding: theme.spacing(0, 1, 0),
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      color: theme.palette.grey[400],
    },
  },
}));

export default useStyles;
