import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import DspDetails from 'features/programmatic/dsp/components/DspDetails/DspDetails';
import {
  dspDetailsFields,
  dspStatusValues,
  getDspStatusData,
  IDspFormValues,
} from 'features/programmatic/dsp/components/DspTabsForm/DspFormValues';
import SeatsTable from 'features/programmatic/seat/components/SeatsTable/SeatsTable';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  EntityType,
  Seat,
  TechnicalProviderStatus,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

interface IDspFormProps {
  update?: boolean;
  goBackTo: ILocation;
  hasEditPermissions?: boolean;
  history?: History;
  match?: {
    params: {
      dspId: string;
    };
  };
  seats?: Seat[];
  technicalProviderStatus?: TechnicalProviderStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
}

export const dspButtons = [
  {
    text: 'Save and Review',
    url: '/dsp/ID',
    update: true,
    dataTc: 'dspReviewButton',
  },
  {
    text: 'Save and Create Seat',
    url: '/dsp/ID/seat',
    update: false,
    dataTc: 'dspCreateSeatButton',
  },
  {
    text: 'Save and View All DSPs',
    url: '/programmatic-connector/PARENT_ID',
    update: false,
    dataTc: 'dspListButton',
  },
];

const getDspDetailsTabErrors = createNumberOfFormErrorsFn();

const DspTabsForm = (props: IFormProps<IDspFormValues> & IDspFormProps) => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    hasEditPermissions = true,
    values,
    goBackTo,
    history,
    match,
    seats,
    status,
    technicalProviderStatus,
    onFieldUpdate,
    onFieldValidate,
  } = props;

  const classes = tabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { entityList, tab } = location.state || 0;
    if (entityList === 'listSeats') setTab(1);
    if (tab) setTab(tab);
  }, [location]);

  const isDspTab = selectedTab === 0;
  const isListSeatsTab = selectedTab === 1;
  const isDspActivityTab = selectedTab === 2;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && technicalProviderStatus && (
          <>
            <EntityProgressBar
              statusData={getDspStatusData(values, technicalProviderStatus)}
              currentStatus={values.status || ''}
              dataTc="dspStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={dspStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="dspHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="dspTabs"
          >
            <Tab
              dataTc="dspDetailsTab"
              numberOfTabErrors={getDspDetailsTabErrors(
                status,
                dspDetailsFields
              )}
              label="Details"
            />
            <Tab disabled={!update} dataTc="listSeatsTab" label="Seats" />
            <Tab disabled={!update} dataTc="dspActivityTab" label="Activity" />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListSeatsTab || isDspActivityTab,
            },
          ])}
        >
          {isDspTab && <DspDetails {...props} />}
          {isListSeatsTab && match && history && seats && (
            <SeatsTable
              match={match}
              history={history}
              seats={seats}
              isEditable={hasEditPermissions}
            />
          )}
          {isDspActivityTab && (
            <ActivityTable
              entityType={EntityType.Dsp}
              id={values.id}
              data-tc="dspActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitDspButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={dspButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default DspTabsForm;
