import { SectionType } from 'features/targetingV2/types/common';
import { TargetingRestriction } from 'interfaces/generated.types';

export const mapSectionToRestrictions = (sectionType: SectionType) => {
  switch (sectionType) {
    case SectionType.Inventory:
      return [
        TargetingRestriction.Local,
        TargetingRestriction.CapSensitive,
        TargetingRestriction.Liid,
        TargetingRestriction.Hfss,
      ];
    case SectionType.Technology:
      return [TargetingRestriction.Liid];
    case SectionType.Audience:
      return [TargetingRestriction.CapSensitive, TargetingRestriction.Hfss];
    case SectionType.DayTime:
      return [TargetingRestriction.CapSensitive];
    default:
      return [];
  }
};

export const mapRestrictionValueToLabel = {
  [TargetingRestriction.Local]: 'Local',
  [TargetingRestriction.CapSensitive]: 'CAP Sensitive',
  [TargetingRestriction.Liid]: 'LIID',
  [TargetingRestriction.Hfss]: 'HFSS',
};

export const setTargetingRestrictions = (
  restriction: TargetingRestriction,
  checked: boolean,
  setFieldValue: (field: string, value: any) => void,
  selectedTargetingRestrictionsList: TargetingRestriction[]
) => {
  const updatedRestrictionsList = checked
    ? [...selectedTargetingRestrictionsList, restriction]
    : selectedTargetingRestrictionsList.filter(
        (restrict) => restrict !== restriction
      );

  setFieldValue('targetingRestrictions', updatedRestrictionsList);
};

export const getTargetingRestrictionsOptions = (
  mapRestrictionToLabel: Record<string, string> = mapRestrictionValueToLabel
) =>
  Object.entries(mapRestrictionToLabel).map(([value, label]) => ({
    label,
    value,
  }));
