import { getIn, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import { targetingLocationAccuracyValues } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

const TargetingListenerAccuracy = ({
  values,
  errors,
  touched,
  targetingGroupName,
  templateIndex,
  groupIndex,
  index,
}: IFormProps<any> & ITargetingComponentProps) => {
  const classes = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const name = `${targetingPath}.audienceParams.${index}.listenerAccuracy.value`;
  const error = getIn(errors, name);
  const isTouched = getIn(touched, name);

  return (
    <div className={classes.value}>
      <Field
        component={TextField}
        select
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        }}
        label="Listener Location Accuracy"
        data-tc="targetingListenerAccuracy"
        name={name}
        fullWidth
        disabled={
          targetingValues.audienceParams[index].listenerAccuracy.readOnly
        }
      >
        {targetingLocationAccuracyValues.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
      {isTouched && error && (
        <FormHelperText data-tc="targetingListenerAccuracyError" error>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default TargetingListenerAccuracy;
