import { baseTargetingStyles } from 'assets/styles/components/Targeting.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseTargetingStyles(theme),
  tableCell: {
    padding: '0',
    textAlign: 'center',
    height: theme.spacing(4.376),
    position: 'relative',
    border: `1px solid ${theme.palette.grey[500]}`,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  table: {
    minWidth: theme.spacing(80),
    tableLayout: 'fixed',
  },
  timeZone: {
    marginBottom: '0.75em',
  },
  verticalHeadings: {
    padding: 0,
    backgroundColor: theme.palette.grey[500],
    textAlign: 'center',
    zIndex: 2,
  },
  horizontalHeadings: {
    backgroundColor: theme.palette.grey[500],
    textAlign: 'center',
    padding: theme.spacing(0.626, 1.876),
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  label: {
    display: 'block',
    height: '100%',
  },
  checkbox: {
    border: 'none',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));

export default useStyles;
