import { IOption } from 'features/targetingV2/types/common';
import { IDayTimePoint } from 'features/targetingV2/types/targeting';
import { OptionType } from 'interfaces';
import { DayTargeting, TimeZoneTargeting } from 'interfaces/generated.types';
import { findIndex, groupBy, some } from 'lodash';
import React from 'react';
import { SelectableGroup } from 'react-selectable-fast';

export const weekdayOptions: IOption<DayTargeting>[] = [
  {
    value: DayTargeting.Mon,
    label: 'Monday',
  },
  {
    value: DayTargeting.Tue,
    label: 'Tuesday',
  },
  {
    value: DayTargeting.Wed,
    label: 'Wednesday',
  },
  {
    value: DayTargeting.Thu,
    label: 'Thursday',
  },
  {
    value: DayTargeting.Fri,
    label: 'Friday',
  },
  {
    value: DayTargeting.Sat,
    label: 'Saturday',
  },
  {
    value: DayTargeting.Sun,
    label: 'Sunday',
  },
];

export const hoursInDay = [...Array(24).keys()];

export const targetingTimeZoneOptions = [
  {
    value: TimeZoneTargeting.Demand,
    label: "Agency's Timezone",
    disabled: false,
  },
  {
    value: TimeZoneTargeting.User,
    label: "Listener's Timezone",
    disabled: false,
  },
  {
    value: TimeZoneTargeting.Channel,
    label: 'Publisher or Station Timezone',
    disabled: true,
  },
];

export const handleHourSelection = (
  dayTimePoints: IDayTimePoint[],
  fieldName: string,
  hour: number,
  setFieldValue: (field: string, value: any) => void
) => {
  const currentSelectedDays = weekdayOptions.filter((day: OptionType) =>
    some(dayTimePoints, { day: day.value, hour })
  );

  if (currentSelectedDays.length === weekdayOptions.length) {
    const filteredDayTimePoints = dayTimePoints.filter(
      (selectedPoint: IDayTimePoint) => selectedPoint.hour !== hour
    );
    setFieldValue(fieldName, filteredDayTimePoints);
  } else {
    const newSelectedDays: IDayTimePoint[] = [];

    weekdayOptions.forEach((day: OptionType) => {
      const newDayTimePoint = { day: day.value, hour };
      if (!some(dayTimePoints, newDayTimePoint))
        newSelectedDays.push(newDayTimePoint);
    });
    setFieldValue(fieldName, [...dayTimePoints, ...newSelectedDays]);
  }
};

export const handleDaySelection = (
  dayTimePoints: IDayTimePoint[],
  fieldName: string,
  day: string,
  setFieldValue: (field: string, value: any) => void
) => {
  const currentSelectedHours = hoursInDay.filter((hour: number) =>
    some(dayTimePoints, { day, hour })
  );
  if (currentSelectedHours.length === hoursInDay.length) {
    const filteredDayTimePoints = dayTimePoints.filter(
      (selectedPoint: IDayTimePoint) => selectedPoint.day !== day
    );
    setFieldValue(fieldName, filteredDayTimePoints);
  } else {
    const newSelectedDayTimePoints: IDayTimePoint[] = [];

    hoursInDay.forEach((hour: number) => {
      const newDayTimePoint = { day, hour };
      if (!some(dayTimePoints, newDayTimePoint))
        newSelectedDayTimePoints.push(newDayTimePoint);
    });

    setFieldValue(fieldName, [...dayTimePoints, ...newSelectedDayTimePoints]);
  }
};

export const handleSelectionFinish = (
  previousDayTimePoints: IDayTimePoint[],
  selectedItems: any,
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
  selectableGroupRef: React.RefObject<SelectableGroup>
) => {
  if (selectedItems && selectedItems.length) {
    const newDayTimePoints: IDayTimePoint[] = [...previousDayTimePoints] || [];

    selectedItems.forEach((selectedItem: any) => {
      const selectedDayTimePoint = {
        day: selectedItem.props.day,
        hour: selectedItem.props.hour,
      };

      if (some(newDayTimePoints, selectedDayTimePoint)) {
        const selectedIndex = findIndex(newDayTimePoints, selectedDayTimePoint);
        newDayTimePoints.splice(selectedIndex, 1);
      } else {
        newDayTimePoints.push({
          day: selectedItem.props.day,
          hour: selectedItem.props.hour,
        });
      }
    });

    setFieldValue(fieldName, newDayTimePoints);

    selectableGroupRef &&
      selectableGroupRef.current &&
      selectableGroupRef.current.clearSelection();
  }
};

export const sortSelectedDays = (groupedPointsPerDay: {
  [key: string]: IDayTimePoint[];
}) =>
  weekdayOptions
    .filter((weekdayOption) => !!groupedPointsPerDay[weekdayOption.value])
    .map((filteredWeekdayOption) => filteredWeekdayOption.value);

export const addHourRange = (
  hourRanges: string[],
  startHour: number,
  endHour: number
) =>
  hourRanges.push(
    startHour === endHour ? `${startHour}` : `${startHour}-${endHour}`
  );

export const formatDayTime = (dayTimePoints: IDayTimePoint[]) => {
  const groupedPointsPerDay = groupBy(dayTimePoints, (period) => period.day);
  const sortedSelectedDays = sortSelectedDays(groupedPointsPerDay);

  const formattedDayTimes = sortedSelectedDays.map((day) => {
    const selectedPointsOfDay = groupedPointsPerDay[day];
    const sortedHours = selectedPointsOfDay
      .map((dayTimePoint) => dayTimePoint.hour)
      .sort((a, b) => a - b);

    const hourRanges: string[] = [];
    let startHour: number = -1;

    sortedHours.forEach((hour, index) => {
      const isLast = index + 1 === sortedHours.length;
      const isNextHourConsecutive =
        !isLast && hour + 1 === sortedHours[index + 1];

      if (startHour < 0) {
        startHour = hour;
      }

      if (!isNextHourConsecutive) {
        addHourRange(hourRanges, startHour, hour);
        startHour = -1;
      }
    });

    return `${day}: ${hourRanges.join(', ')}`;
  });

  return formattedDayTimes;
};
