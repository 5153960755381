import * as Yup from 'yup';

import commons from 'validations/commons';

const DatacenterFormValidation = Yup.object().shape({
  name: commons.name('Name'),
  bidUrl: commons.validUrl({
    message: 'Bid url should be a valid url',
    fieldKey: 'bidUrl',
    requiredProtocol: true,
  }),
  maxQps: commons.optionalPrice({
    messages: {
      minMessage: 'Max QPS must be greater than or equal to 1',
      maxMessage: 'Max QPS should be no more than 2,147,483,647',
    },
    minimum: 1,
    maximum: 2147483647,
    fieldKey: 'maxQps',
  }),
});

export default DatacenterFormValidation;
