import { makeStyles, Theme } from '@material-ui/core/styles';

import { baseFormStyles } from 'assets/styles/components/Form.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFormStyles(theme),
  multiSelect: {
    margin: theme.spacing(2, 0, 4.375),
  },
  territory: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
