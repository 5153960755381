import useStyles from 'assets/styles/components/Form.styles';
import Domains from 'components/Domains/Domains';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { IAdvertiserValues } from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserFormValues';
import { Field } from 'formik';
import { fieldToSwitch, SwitchProps, TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import React from 'react';
import { advertiserAndAgencyExternalSystems } from 'utils/defaultExternalSystems';
import { TerritoryLabel } from 'utils/territory';

import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiSwitch from '@material-ui/core/Switch/Switch';
import LaunchIcon from '@material-ui/icons/Launch';

export const AdSeparationSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('adSeparation', '');
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

const AdvertiserDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  update = false,
  status = {},
}: IFormProps<IAdvertiserValues>) => {
  const classes = useStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Agency Name"
              placeholder="Agency Name"
              name="agencyName"
              data-tc="agencyName"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/agency/${values.agencyId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="advertiserAgencyLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.agencyName}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Advertiser Name"
            placeholder="Advertiser Name"
            name="name"
            autoFocus={!update}
            data-tc="advertiserName"
            fullWidth
            inputProps={{ 'data-testid': 'advertiserName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Advertiser ID"
              placeholder="Advertiser ID"
              name="altId"
              data-tc="advertiserId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="adSeparation"
            decimalScale={3}
            label="Ad Separation (seconds)"
            name="adSeparation"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.adSeparation as any}
            disabled={!values.hasAdSeparation}
            fullWidth
            touched={touched.adSeparation || !!status.adSeparation}
            error={errors.adSeparation || status.adSeparation}
          />
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormControlLabel
              control={
                <Field
                  component={AdSeparationSwitch}
                  type="checkbox"
                  name="hasAdSeparation"
                  data-tc="hasAdSeparation"
                />
              }
              label="Enable Ad Separation"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="External System"
            placeholder="External System"
            name="externalSystemId"
            data-tc="advertiserExternalSystemId"
            inputProps={{ 'data-testid': 'advertiserExternalSystemId-input' }}
            fullWidth
            helperText={
              (touched.externalSystemId && errors.externalSystemId) ||
              status.externalSystemId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalSystemId && errors.externalSystemId) ||
                status.externalSystemId
              ),
            }}
          >
            <MenuItem value="" />
            {advertiserAndAgencyExternalSystems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid item xs={4}>
          <Field
            component={TextField}
            label="External Id"
            placeholder="External Id"
            name="externalId"
            data-tc="advertiserExternalId"
            fullWidth
            helperText={
              (touched.externalId && errors.externalId) || status.externalId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalId && errors.externalId) ||
                status.externalId
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Domains
            dataTc={{
              domainField: 'advertiserDomainField',
              addDomain: 'advertiserAddDomainButton',
              removeDomain: 'advertiserRemoveDomainButton',
            }}
            domainArrayName="domains"
            domainFor={values.name}
            domains={values.domains}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched}
            generatedDomains={values.generatedDomains}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default AdvertiserDetails;
