import gql from 'graphql-tag';

export const GET_NETWORK = gql`
  query Network($id: ID!, $territories: [Territory!]!) {
    network(id: $id) {
      id
      altId
      name
      status
      territories
      publishers(territories: $territories) {
        id
        altId
        name
        status
        pricingType
        pricingAmount
        territories
      }
    }
  }
`;

export const GET_NETWORK_MINIMAL = gql`
  query NetworkMinimal($id: ID!) {
    network(id: $id) {
      id
      name
      territories
    }
  }
`;

export const GET_ALL_NETWORKS = gql`
  query AllNetworks($territories: [Territory!]!) {
    allNetworks(territories: $territories) {
      id
      altId
      name
      status
      territories
    }
  }
`;
