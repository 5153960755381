import { Show } from 'interfaces/generated.types';

export const getShowName = (show: Show) =>
  `${show.name} - ${show.show_id} - ${show.hostingPlatform}`;

export const getShowNameOption = (show: Show) => ({
  value: show.show_id,
  label: getShowName(show),
});

export const getShowNameOptions = (allShows: Show[]) =>
  allShows.map((show) => getShowNameOption(show));
