import { makeStyles, Theme } from '@material-ui/core/styles';

import { basePageStyles } from 'assets/styles/components/Page.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  displayIcon: {
    display: 'block',
    fontSize: '2em',
    margin: '0 auto',
  },
}));

export default useStyles;
