import React, { useState, useEffect } from 'react';
import debounce from 'debounce-promise';
import { getIn } from 'formik';
import { get } from 'lodash';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import Loader from 'components/Loader/Loader';

import { IGetAllSubRegionsResponse } from 'features/targeting/graphql/subRegions/queries';

import { IFormProps, OptionType } from 'interfaces';
import {
  GeoRegionResponse,
  useAllCountriesQuery,
  useListRegionsQuery,
  useListSubRegionsQuery,
} from 'interfaces/generated.types';

import locationUtils from 'utils/location';
import { dedupeValues } from 'utils/dataTransformation';

import MultiSelect, {
  AsyncMultiSelect,
} from 'components/MultiSelect/MultiSelect';
import { TLocationOption } from '../TargetingParameters/TargetingParametersValues';
import { filterRegionsByCountries } from '../TargetingRegion/TargetingRegion';

export const subRegionValues = {
  countryParams: {
    countries: [],
    regions: [],
    subRegions: [],
  },
};

export const loadSubOptions = async (
  value: string,
  regions: IGetAllSubRegionsResponse,
  setFetchedOptions: any
) => {
  if (value.length >= 1) {
    const data = regions.allSubRegions.map(
      (allSubRegions: GeoRegionResponse) => ({
        ...allSubRegions,
        regions: allSubRegions.regions.filter((r) =>
          r.name.toLowerCase().includes(value.toLowerCase())
        ),
      })
    );
    const response: GeoRegionResponse[] = await new Promise((resolve) =>
      resolve(data)
    );

    const formattedData = locationUtils.createSubRegionGroup(response);
    setFetchedOptions(formattedData);
    return formattedData;
  }
  return [];
};

const debouncedLoadSubOptions = debounce(loadSubOptions, 1000, {
  leading: true,
});

const filterSubRegionsByRegions = (
  subRegions: TLocationOption[],
  regions: OptionType[]
) => {
  const regionCodes = locationUtils.formatRegionCodes(regions, true);
  return subRegions.filter((subRegion: TLocationOption) =>
    regionCodes.includes(subRegion.countryCode)
  );
};

interface ISubRegionSelect {
  name: string;
}

const SubRegionSelect = (props: IFormProps<any> & ISubRegionSelect) => {
  const {
    name,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;

  const [fetchedOptions, setFetchedOptions] = useState([]);

  const classes: any = targetingStyles({});

  const countryField = 'countries';
  const regionField = 'regions';
  const subRegionField = 'subRegions';

  const countryVal = getIn(values, `${name}.${countryField}`);
  const regionVals = getIn(values, `${name}.${regionField}`);
  const subRegionVals = getIn(values, `${name}.${subRegionField}`);
  const [country, setCountry] = useState(countryVal);
  const [regions, setRegions] = useState(regionVals);
  const [subRegions, setSubRegions] = useState(subRegionVals);

  useEffect(() => {
    setCountry(countryVal || []);
  }, [countryVal]);

  useEffect(() => {
    setRegions(regionVals || []);
  }, [regionVals]);

  useEffect(() => {
    setSubRegions(subRegionVals || []);
  }, [subRegionVals]);

  useEffect(() => {
    setFieldValue(regionField, filterRegionsByCountries(regions, country));
  }, [country, regionField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFieldValue(
      subRegionField,
      filterSubRegionsByRegions(subRegions, regions)
    );
  }, [regions, regionField, subRegionField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, data: countriesData } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });

  const { data: regionsData } = useListRegionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCodes: locationUtils.formatCountryCodes(country),
    },
    skip: country.length === 0,
  });
  const { data: subregionsData } = useListSubRegionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCode: locationUtils.formatCountryCodes(country)[0],
      regionCodes: locationUtils.formatRegionCodes(regions, true),
    },
    skip: country.length === 0 || regions.length === 0,
  });

  const getValue = (valueName: string) =>
    dedupeValues(get(values, `${name}.${valueName}`)).filter(
      (val) => val.label !== null
    );

  const isClearable = (valueName: string) =>
    getValue(valueName).some((v: any) => v.readOnly);

  if (loading) return <Loader />;
  const countryLabel = 'Country';
  const regionLabel = 'Region';
  const subRegionLabel = 'Sub-region';

  return (
    <>
      <div className={classes.pairs}>
        <MultiSelect
          id={countryLabel}
          data-tc={`targetingMultiSelect-${countryLabel}`}
          isClearable={!isClearable(countryField)}
          label={countryLabel}
          placeholder={countryLabel}
          name={`${name}.${countryField}`}
          onBlur={() => setFieldTouched(`${name}.${countryField}`, true)}
          onChange={(fieldValue: any) =>
            setFieldValue(`${name}.${countryField}`, fieldValue)
          }
          selectAll={false}
          options={
            countriesData && countriesData.allCountries
              ? locationUtils.createCountryGroup(countriesData.allCountries)
              : []
          }
          maxOptions={1}
          maxOptionsText="You can only select one country"
          value={getValue(countryField)}
          attributes={{
            fieldAttribute: `${countryField}Field`,
            menuAttribute: `${countryField}MenuOptions`,
            chipAttribute: `${countryField}Chip`,
          }}
          errorProps={{
            helperText:
              getIn(touched, `${name}.${countryField}`) &&
              getIn(errors, `${name}.${countryField}`),
            FormHelperTextProps: {
              error: !!(
                getIn(touched, `${name}.${countryField}`) &&
                getIn(errors, `${name}.${countryField}`)
              ),
            },
          }}
        />

        <MultiSelect
          label="Region"
          isDisabled={!regionsData}
          selectAll={false}
          maxOptions={10}
          maxOptionsText="You can only select 10 regions"
          options={
            regionsData && regionsData.allRegions
              ? locationUtils.createRegionGroup(regionsData.allRegions)
              : []
          }
          id={regionLabel}
          data-tc={`targetingMultiSelect-${regionLabel}`}
          isClearable={!isClearable(regionField)}
          placeholder={regionLabel}
          name={`${name}.${regionField}`}
          onBlur={() => setFieldTouched(`${name}.${regionField}`, true)}
          onChange={(fieldValue: any) =>
            setFieldValue(`${name}.${regionField}`, fieldValue)
          }
          value={getValue(regionField)}
          attributes={{
            fieldAttribute: `${regionField}Field`,
            menuAttribute: `${regionField}MenuOptions`,
            chipAttribute: `${regionField}Chip`,
          }}
          errorProps={{
            helperText:
              getIn(touched, `${name}.${regionField}`) &&
              getIn(errors, `${name}.${regionField}`),
            FormHelperTextProps: {
              error: !!(
                getIn(touched, `${name}.${regionField}`) &&
                getIn(errors, `${name}.${regionField}`)
              ),
            },
          }}
        />

        <AsyncMultiSelect
          {...props}
          label={subRegionLabel}
          placeholder="Begin typing to find a Sub-region"
          isDisabled={!subregionsData}
          loadOptions={(value: string) =>
            subregionsData &&
            debouncedLoadSubOptions(value, subregionsData, setFetchedOptions)
          }
          fetchedOptions={fetchedOptions}
          id={subRegionLabel}
          data-tc={`targetingMultiSelect-${subRegionLabel}`}
          isClearable={!isClearable(subRegionField)}
          name={`${name}.${subRegionField}`}
          onBlur={() => setFieldTouched(`${name}.${subRegionField}`, true)}
          onChange={(fieldValue: any) =>
            setFieldValue(`${name}.${subRegionField}`, fieldValue)
          }
          value={getValue(subRegionField)}
          attributes={{
            fieldAttribute: `${subRegionField}Field`,
            menuAttribute: `${subRegionField}MenuOptions`,
            chipAttribute: `${subRegionField}Chip`,
          }}
          errorProps={{
            helperText:
              getIn(touched, `${name}.${subRegionField}`) &&
              getIn(errors, `${name}.${subRegionField}`),
            FormHelperTextProps: {
              error: !!(
                getIn(touched, `${name}.${subRegionField}`) &&
                getIn(errors, `${name}.${subRegionField}`)
              ),
            },
          }}
        />
      </div>
    </>
  );
};

export default SubRegionSelect;
