import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  error: {
    marginBottom: theme.spacing(1.875),
  },
  errorMessage: {
    '& + &': {
      marginTop: theme.spacing(1.875),
    },
  },
}));

export default useStyles;
