import {
  useAllAudienceParametersQuery,
  useAllInventoryParametersQuery,
  useAllLocationParametersQuery,
  useAllTechnologyParametersQuery,
} from 'interfaces/generated.types';

const useAllParametersInfo = () => {
  const { data: audienceParametersData, loading: audienceParametersLoading } =
    useAllAudienceParametersQuery({
      fetchPolicy: 'cache-first',
    });

  const { data: locationParametersData, loading: locationParametersLoading } =
    useAllLocationParametersQuery({
      fetchPolicy: 'cache-first',
    });

  const { data: inventoryParametersData, loading: inventoryParametersLoading } =
    useAllInventoryParametersQuery({
      fetchPolicy: 'cache-first',
    });

  const {
    data: technologyParametersData,
    loading: technologyParametersLoading,
  } = useAllTechnologyParametersQuery({
    fetchPolicy: 'cache-first',
  });

  const allParametersInfo = [
    ...(audienceParametersData?.allAudienceParameters || []),
    ...(locationParametersData?.allLocationParameters || []),
    ...(inventoryParametersData?.allInventoryParameters || []),
    ...(technologyParametersData?.allTechnologyParameters || []),
  ];

  const areParametersLoading =
    audienceParametersLoading ||
    locationParametersLoading ||
    inventoryParametersLoading ||
    technologyParametersLoading;

  return { allParametersInfo, loading: areParametersLoading };
};

export default useAllParametersInfo;
