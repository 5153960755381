import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  grade: ({ disabled = false }: { disabled?: boolean }) => ({
    width: '2px',
    height: `12px`,
    border: `1px solid ${colors.daxGreyscaleStandardGrey}`,
    borderRadius: '2px',
    margin: theme.spacing(0, 0.12),
    padding: 0,
    backgroundColor: 'inherit',
    '&--filled': {
      backgroundColor: disabled
        ? colors.daxGreyscaleNeutral
        : colors.primaryBlue,
      border: `1px solid ${
        disabled ? colors.daxGreyscaleNeutral : colors.primaryBlue
      }`,
    },
  }),
}));

export default useStyles;
