import { makeStyles, Theme } from '@material-ui/core/styles';

import { baseTabStyles } from 'assets/styles/components/Tab.styles';

const tabStyles = makeStyles((theme: Theme) => ({
  ...baseTabStyles(theme),
  container: {
    position: 'relative',
    display: 'flex',
  },
  icon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    zIndex: 1,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.813em',
    fontWeight: 500,
    position: 'absolute',
    top: '0',
    right: '0',
  },
}));

export default tabStyles;
