import gql from 'graphql-tag';

export interface IGetAffectedEntitiesResponse {
  allAffectedEntities: {
    id: string;
  }[];
}

export const GET_ALL_AFFECTED_ENTITIES = gql`
  query AllAffectedEntities(
    $targetStatus: EntityStatus!
    $entityAttributes: EntityAttributes!
  ) {
    allAffectedEntities(
      targetStatus: $targetStatus
      entityAttributes: $entityAttributes
    ) {
      id
      entityType
    }
  }
`;

export default GET_ALL_AFFECTED_ENTITIES;
