import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 3),
  },
  container: {
    width: '100%',
    margin: 0,
  },
  listContainer: {
    backgroundColor: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '0.313em',
    height: '400px',
    overflowY: 'auto',
  },
  textField: {
    textAlign: 'center',
  },
  dialogActionsContainer: {
    justifyContent: 'space-between',
  },
  cancelButton: {
    color: theme.palette.grey[800],
    border: '1px solid',
  },
  applyButton: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.common.white,
    },
  },
}));

export default useStyles;
