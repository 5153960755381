import { fieldToSwitch, SwitchProps } from 'formik-material-ui';
import React from 'react';

import MuiSwitch from '@material-ui/core/Switch';

export interface IFieldSwitchProps {
  fieldToUpdate: string;
  defaultValueOnToggle: any;
}

const FieldSwitch = (props: SwitchProps & IFieldSwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const { fieldToUpdate, defaultValueOnToggle, ...pushThroughProps } = props;

  const onChange = React.useCallback(
    (event) => {
      setFieldValue(fieldToUpdate, defaultValueOnToggle);
      setFieldValue(name, event.target.checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, name]
  );

  return (
    <MuiSwitch
      {...fieldToSwitch(pushThroughProps)}
      onChange={onChange}
      color="primary"
    />
  );
};

export default FieldSwitch;
