import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    padding: theme.spacing(0, 1, 1),
  },
  errorText: {
    marginTop: 0,
  },
  error: {
    margin: 0,
    fontSize: theme.spacing(1.625),
    color: theme.palette.error.main,
  },
}));

export default useStyles;
