import formStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { INetworkValues } from 'features/inventory/network/components/NetworkTabsForm/NetworkFormValues';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import React from 'react';
import { getTerritoryValues } from 'utils/territory';

import Grid from '@material-ui/core/Grid';

const NetworkDetails = (props: IFormProps<INetworkValues>) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    update = false,
    status = {},
  } = props;

  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="name"
            type="text"
            label="Network Name"
            placeholder="Network Name"
            autoFocus={!update}
            data-tc="networkName"
            fullWidth
            inputProps={{ 'data-testid': 'networkName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={6}>
            <Field
              component={TextField}
              label="Network ID"
              placeholder="Network ID"
              name="altId"
              data-tc="networkId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={6} />
        )}
        <Grid item xs={6}>
          <MultiSelect
            id="territories"
            options={getTerritoryValues(territories || [])}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue('territories', fieldValue);
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default NetworkDetails;
