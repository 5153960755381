import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const baseContainerStyles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '1312px',
      margin: theme.spacing(0, 'auto'),
      padding: theme.spacing(0, 1.875),
      width: '100%',
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  ...baseContainerStyles(theme),
}));

export default useStyles;
