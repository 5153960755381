import {
  PublisherInsightsFilterCategory,
  PublisherInsightsFilterType,
  PublisherInsightsFilterValue,
} from 'interfaces/generated.types';
import { ISelectedFilter } from 'utils/insights';

const filterCategoriesWithPopularFilters = ['COUNTRY'];

export const popularCountryValues = ['GB', 'US', 'CA'];

export const hasPopularFilters = (
  filterCategoryType: PublisherInsightsFilterType
) => filterCategoriesWithPopularFilters.includes(filterCategoryType);

export const getPopularFilters = (
  popularFilterIds: string[],
  filterValues: PublisherInsightsFilterValue[]
) =>
  filterValues.filter((value: PublisherInsightsFilterValue) =>
    popularFilterIds.includes(value.id)
  );

export const getFilterCategoryValuesTitle = (
  filterCategoryType: PublisherInsightsFilterType
) => {
  switch (filterCategoryType) {
    case PublisherInsightsFilterType.Country:
      return 'All Countries';
    default:
      return '';
  }
};

export const isCheckboxDisabled = (
  selectedFilters: ISelectedFilter[],
  filterCategoryType: PublisherInsightsFilterType
) =>
  selectedFilters.length === 4 &&
  !selectedFilters.find(
    (filter) => filter.filterCategory.type === filterCategoryType
  );

export const isValueAlreadySelected = (
  selectedFilters: ISelectedFilter[],
  selectedValueId: string
) =>
  selectedFilters.length
    ? selectedFilters.some((filter) => filter.ids.includes(selectedValueId))
    : false;

export const addFilterValue = (
  filterValueId: string,
  filterCategory: PublisherInsightsFilterCategory,
  selectedFilters: ISelectedFilter[],
  setSelectedFilters: (newSelectedFilters: ISelectedFilter[]) => void
) => {
  let newSelectedFilters: ISelectedFilter[] = [];

  const filterCategoryAlreadyExists = selectedFilters.length
    ? selectedFilters.some(
        (filter) => filter.filterCategory.type === filterCategory.type
      )
    : false;

  if (filterCategoryAlreadyExists) {
    newSelectedFilters = selectedFilters.map((selectedFilter) =>
      selectedFilter.filterCategory.type === filterCategory.type
        ? {
            ...selectedFilter,
            ids: selectedFilter.ids.concat([filterValueId]),
          }
        : selectedFilter
    );
  } else {
    newSelectedFilters = [
      ...selectedFilters,
      {
        filterCategory: {
          type: filterCategory.type,
          displayName: filterCategory.displayName,
        },
        ids: [filterValueId],
      },
    ];
  }

  setSelectedFilters(newSelectedFilters);
};

export const removeFilterValue = (
  filterValueId: string,
  filterCategory: PublisherInsightsFilterCategory,
  selectedFilters: ISelectedFilter[],
  setSelectedFilters: (newSelectedFilters: ISelectedFilter[]) => void
) => {
  let newSelectedFilters: ISelectedFilter[] = selectedFilters;

  const alreadySelectedFilter = selectedFilters.find(
    (filterAttribute) =>
      filterAttribute.filterCategory.type === filterCategory.type
  ) as ISelectedFilter;

  if (alreadySelectedFilter.ids.length === 1)
    newSelectedFilters = selectedFilters.filter(
      (selectedFilter) =>
        selectedFilter.filterCategory.type !== filterCategory.type
    );
  else
    newSelectedFilters = selectedFilters.map((selectedFilter) =>
      selectedFilter === alreadySelectedFilter
        ? {
            ...selectedFilter,
            ids: alreadySelectedFilter.ids.filter((id) => id !== filterValueId),
          }
        : selectedFilter
    );

  setSelectedFilters(newSelectedFilters);
};

export const handleValueItemClick = (
  filterValueId: string,
  filterCategory: PublisherInsightsFilterCategory,
  selectedFilters: ISelectedFilter[],
  setSelectedFilters: (newSelectedFilters: ISelectedFilter[]) => void
) => {
  if (isValueAlreadySelected(selectedFilters, filterValueId)) {
    removeFilterValue(
      filterValueId,
      filterCategory,
      selectedFilters,
      setSelectedFilters
    );
  } else {
    addFilterValue(
      filterValueId,
      filterCategory,
      selectedFilters,
      setSelectedFilters
    );
  }
};
