import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { INetworkValues } from 'features/inventory/network/components/NetworkTabsForm/NetworkFormValues';
import NetworkForm, {
  networkButtons,
} from 'features/inventory/network/components/NetworkTabsForm/NetworkTabsForm';
import NetworkFormValidation from 'features/inventory/network/validations/networks';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  CreateNetworkMutation,
  CreateNetworkMutationFn,
  Network,
  useCreateNetworkMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTerritoryValues } from 'utils/territory';

interface ICreateNetworkContainerProps {
  history: History;
}

export const handleOnComplete = (
  response: CreateNetworkMutation,
  history: History,
  selectedJourney: string
) => {
  const { createNetwork } = response;
  const { id } = createNetwork as Network;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitForm = (
  createNetwork: CreateNetworkMutationFn,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: INetworkValues,
  { setStatus, setSubmitting }: FormikActions<INetworkValues>
) => {
  setSelectedJourney(formValues.journey);

  const formattedValues = formatValues(formValues);

  createNetwork({
    variables: pickBy({
      ...formValues,
      journey: null,
      territories: formattedValues.territories,
    }),
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateNetworkContainer = ({ history }: ICreateNetworkContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Network.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createNetwork] = useCreateNetworkMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney);
    },
  });

  return (
    <>
      <Formik<INetworkValues>
        initialValues={{
          name: '',
          id: '',
          journey: networkButtons[0].url,
          territories: activeTerritory
            ? getTerritoryValues([activeTerritory])
            : [],
        }}
        validationSchema={NetworkFormValidation}
        onSubmit={submitForm(
          createNetwork,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={NetworkForm}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createNetwork"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateNetworkContainer;
