import gql from 'graphql-tag';

export const ALL_INVENTORY_PARAMETERS = gql`
  query allInventoryParameters {
    allInventoryParameters {
      type
      name
      cardinality
    }
  }
`;

export const GET_INVENTORY_PARAMETER_VALUES = gql`
  query inventoryParameterValues(
    $type: InventoryParameterType!
    $territory: Territory!
    $status: InventoryStatus
  ) {
    inventoryParameterValues(
      type: $type
      territory: $territory
      status: $status
    ) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
          }
        }
      }
    }
  }
`;
