import DatePicker from 'components/DateTimePicker/DatePicker';
import FormButtons from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, { SuffixTypes } from 'components/NumberInput/NumberInput';
import { endOfDay, startOfDay } from 'date-fns';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import {
  Show,
  useAllShowContractsQuery,
  useAllShowsQuery,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import {
  formatContracts,
  isContractOverlappingWithExistingContract,
  spotCpmAndSpotRevenueError,
} from 'utils/contracts';
import {
  CurrenciesEnum,
  currencyValues,
  getPrefixBySelectedCurrency,
} from 'utils/currency';
import dateUtils from 'utils/date';
import { getShowNameOptions } from 'utils/shows';

import { FormControl, FormHelperText, Grid, MenuItem } from '@material-ui/core';

import useStyles from './ShowContractForm.styles';
import {
  IFormattedShowContract,
  IShowContractValues,
} from './ShowContractValues';

export interface IShowContractFormProps {
  handleClose: () => void;
  update?: boolean;
  hasEditPermissions?: boolean;
  readOnly?: boolean;
  testId?: string;
}

const ShowContractForm = (
  props: IFormProps<IShowContractValues> & IShowContractFormProps
) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    errors,
    touched,
    status = {},
    update = false,
    isSubmitting,
    isValid,
    dirty,
    hasEditPermissions = false,
    handleClose,
    readOnly = false,
    testId = 'showContractForm',
  } = props;

  const classes = useStyles({});

  const { loading, data, error } = useAllShowsQuery();

  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const {
    loading: loadingShowContracts,
    data: showContractsData,
    error: showContractsError,
  } = useAllShowContractsQuery({
    variables: {
      filter: {
        showIds: [values.showName?.value || ''],
      },
    },
    errorPolicy: 'all',
  });

  const contractsOfSelectedShow: IFormattedShowContract[] =
    !loadingShowContracts && !showContractsError && showContractsData
      ? formatContracts(
          showContractsData.allShowContracts,
          values.timeZone
        ).filter(
          (contract: IFormattedShowContract) => contract.id !== values.id
        )
      : [];

  const contractOverlapsWithExistingContract =
    isContractOverlappingWithExistingContract(contractsOfSelectedShow, values);

  return (
    <>
      <Form role="form" data-testid={testId}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MultiSelect
              data-tc="showNameMultiSelect"
              data-testId="showNameMultiSelect"
              id="showNameMultiSelect"
              label="Show Name"
              isMulti={false}
              selectAll={false}
              maxSelectHeight={250}
              name="showName"
              noMargin
              onBlur={() => setFieldTouched('showName', true)}
              onChange={(fieldValue: any) => {
                setFieldValue('showName', fieldValue);
              }}
              options={getShowNameOptions((data?.allShows as Show[]) || [])}
              placeholder="Select a show..."
              value={values.showName}
              isLoading={loading}
              isDisabled={readOnly}
              attributes={{
                fieldAttribute: 'showContractField',
                menuAttribute: 'showContractMenuOptions',
                chipAttribute: 'showContractChip',
              }}
              errorProps={{
                helperText:
                  (touched.showName && errors.showName) || status.showName,
                FormHelperTextProps: {
                  error: !!(
                    (touched.showName && errors.showName) ||
                    status.showName
                  ),
                },
              }}
            />
            {!loading && error && (
              <FormHelperText error data-tc="showNamesError">
                There was an error retrieving show names
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl component={'fieldset' as 'div'} fullWidth>
              <DatePicker
                initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
                value={values.startDate}
                label="Contract Start Date"
                name="startDate"
                onChange={(value) =>
                  setFieldValue('startDate', startOfDay(value))
                }
                onClose={() => setFieldTouched('startDate', true)}
                data-tc="contractStartDate"
                data-testid="contractStartDate"
                disabled={readOnly}
              />
              {((touched.startDate && errors.startDate) ||
                status.startDate) && (
                <FormHelperText error data-tc="showContractStartDateError">
                  {errors.startDate || status.startDate}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl component={'fieldset' as 'div'} fullWidth>
              <DatePicker
                initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
                value={values.endDate}
                label="Contract End Date (optional)"
                name="endDate"
                onChange={(value) => setFieldValue('endDate', endOfDay(value))}
                onClose={() => setFieldTouched('endDate', true, false)}
                data-tc="contractEndDate"
                data-testid="contractEndDate"
                disabled={!hasEditPermissions}
              />
              {((touched.endDate && errors.endDate) ||
                (touched.startDate && errors.endDate) ||
                status.endDate) && (
                <FormHelperText error data-tc="showContractEndDateError">
                  {errors.endDate || status.endDate}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Field
              component={TextField}
              data-tc="currency"
              data-testid="currency"
              fullWidth
              label="Contract Currency"
              name="currency"
              id="currency"
              select
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                },
              }}
              disabled={readOnly}
            >
              {currencyValues
                .filter((option) => option.value !== CurrenciesEnum.CAD)
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          {!loadingShowContracts && contractOverlapsWithExistingContract && (
            <Grid container classes={{ root: classes.errorContainer }}>
              <FormHelperText
                error
                data-tc="showContractOverlapError"
                className={classes.errorText}
              >
                The selected date range overlaps with an existing contract for
                the selected show. Please select a different date range.
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractDirectSpotCpm"
              dataTestId="showContractDirectSpotCpm"
              decimalScale={2}
              label="Direct Spot CPM"
              name="directSpotCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.directSpotCpm || ''}
              fullWidth
              touched={touched.directSpotCpm || !!status.directSpotCpm}
              error={errors.directSpotCpm || status.directSpotCpm}
              disabled={readOnly || !!values.spotRevenueShare}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractProgrammaticSpotCpm"
              dataTestId="showContractProgrammaticSpotCpm"
              decimalScale={2}
              label="Programmatic Spot CPM"
              name="programmaticSpotCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.programmaticSpotCpm || ''}
              fullWidth
              touched={
                touched.programmaticSpotCpm || !!status.programmaticSpotCpm
              }
              error={errors.programmaticSpotCpm || status.programmaticSpotCpm}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractSpotRevenueShare"
              dataTestId="showContractSpotRevenueShare"
              decimalScale={0}
              label="Spot Revenue Share"
              name="spotRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.spotRevenueShare || ''}
              fullWidth
              touched={touched.spotRevenueShare || !!status.spotRevenueShare}
              error={errors.spotRevenueShare || status.spotRevenueShare}
              disabled={readOnly || !!values.directSpotCpm}
            />
          </Grid>
          {spotCpmAndSpotRevenueError(touched, values) &&
            !status.spotRevenueShare &&
            !status.directSpotCpm && (
              <Grid container classes={{ root: classes.errorContainer }}>
                <FormHelperText error data-tc="showContractDirectSpotCpmError">
                  You must enter either the Direct Spot CPM or Spot Revenue
                  Share
                </FormHelperText>
              </Grid>
            )}
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractHostReadRevenueShare"
              dataTestId="showContractHostReadRevenueShare"
              decimalScale={0}
              label="Host Read Revenue Share"
              name="hostReadRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hostReadRevenueShare || ''}
              fullWidth
              touched={
                touched.hostReadRevenueShare || !!status.hostReadRevenueShare
              }
              error={errors.hostReadRevenueShare || status.hostReadRevenueShare}
              disabled={readOnly || !!values.hostReadCpm}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractHostReadCpm"
              dataTestId="showContractHostReadCpm"
              decimalScale={2}
              label="Host Read CPM"
              name="hostReadCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hostReadCpm || ''}
              fullWidth
              touched={touched.hostReadCpm || !!status.hostReadCpm}
              error={errors.hostReadCpm || status.hostReadCpm}
              disabled={readOnly || !!values.hostReadRevenueShare}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="showContractSponsorshipRevenueShare"
              dataTestId="showContractSponsorshipRevenueShare"
              decimalScale={0}
              label="Sponsorship Revenue Share"
              name="sponsorshipRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.sponsorshipRevenueShare || ''}
              fullWidth
              touched={
                touched.sponsorshipRevenueShare ||
                !!status.sponsorshipRevenueShare
              }
              error={
                errors.sponsorshipRevenueShare || status.sponsorshipRevenueShare
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={TextField}
              label="Notes"
              data-tc="notesField"
              multiline
              rows={4}
              name="notes"
              inputProps={{ maxLength: 1000, 'aria-label': 'Notes' }}
              value={values.notes || ''}
              disabled={readOnly}
            />
            {values.notes.length === 1000 && (
              <p className={classes.error}>
                You have reached the max character length of 1000
              </p>
            )}
          </Grid>
        </Grid>
        <FormButtons
          dataTc="submitShowContractButton"
          disabled={
            !isValid ||
            !dirty ||
            isSubmitting ||
            !hasEditPermissions ||
            spotCpmAndSpotRevenueError(touched, values) ||
            contractOverlapsWithExistingContract
          }
          onClick={() => {
            handleSubmit();
            setFormSubmitting(true);
          }}
          isLoading={isSubmitting}
          handleCloseModal={handleClose}
        >
          {update ? 'Update and Save Contract' : 'Create and Save Contract'}
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default ShowContractForm;
