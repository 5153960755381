import gql from 'graphql-tag';

export const ALL_AUDIENCE_PARAMETERS = gql`
  query allAudienceParameters {
    allAudienceParameters {
      type
      name
      cardinality
    }
  }
`;

export const GET_AUDIENCE_PARAMETER_VALUES = gql`
  query audienceParameterValues($type: AudienceParameterType!) {
    audienceParameterValues(type: $type) {
      id
      name
      active
      activeGrades
      children {
        id
        name
        active
        activeGrades
        children {
          id
          name
          active
          activeGrades
          children {
            id
            name
            active
            activeGrades
          }
        }
      }
    }
  }
`;

export const GET_POPULAR_INTERESTS = gql`
  query PopularInterests {
    popularInterests {
      id
      name
      active
      activeGrades
      parents {
        id
        name
      }
    }
  }
`;
