import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';

import { ISeatFormValues } from 'features/programmatic/seat/components/SeatTabsForm/SeatFormValues';
import SeatTabsForm, {
  seatButtons,
} from 'features/programmatic/seat/components/SeatTabsForm/SeatTabsForm';
import SeatFormValidation from 'features/programmatic/seat/validations/seats';

import useError from 'hooks/Error/useError';

import {
  CreateSeatMutation,
  CreateSeatMutationFn,
  Dsp,
  Seat,
  useCreateSeatMutation,
  Territory,
} from 'interfaces/generated.types';

import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    dspId: string;
  };
}
interface ICreateSeatContainerProps {
  history: History;
  match: IMatch;
  dsp: Dsp;
}

export const handleOnComplete = async (
  response: CreateSeatMutation,
  history: History,
  selectedJourney: string,
  dspId: string
) => {
  const { createSeat } = response;
  const { id } = createSeat as Seat;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: dspId,
    listEntity: selectedJourney === '/dsp/PARENT_ID' ? 'Seat' : '',
  });
};

export const submitForm = (
  createSeat: CreateSeatMutationFn,
  match: IMatch,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ISeatFormValues,
  { setStatus, setSubmitting }: FormikActions<ISeatFormValues>
) => {
  setSelectedJourney(formValues.journey);
  const formattedValues = formatValues(formValues);

  const filteredValues: any = pickBy({
    ...formValues,
    journey: null,
    dspId: match.params.dspId,
    dspName: null,
    territories: formattedValues.territories,
    dspTerritories: null,
  });

  createSeat({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateSeatContainer = ({
  history,
  match,
  dsp,
}: ICreateSeatContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Seat.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createSeat] = useCreateSeatMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney, match.params.dspId);
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<ISeatFormValues>
        initialValues={{
          id: '',
          name: '',
          externalSeatId: '',
          journey: seatButtons[0].url,
          dspId: dsp.id,
          dspName: dsp.name as string,
          dspTerritories: getTerritoryValues(
            (dsp.territories as Territory[]) || []
          ),
          territories: getTerritoryValues(
            (dsp.territories as Territory[]) || []
          ),
        }}
        validationSchema={SeatFormValidation}
        onSubmit={submitForm(
          createSeat,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={(props) => (
          <SeatTabsForm
            {...props}
            goBackTo={{
              pathname: `/dsp/${match.params.dspId}`,
              state: location.state?.parent || {},
            }}
            history={history}
          />
        )}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createSeat"
        errorMessages={errorMessages}
      />
    </>
  );
};
export default CreateSeatContainer;
