import classNames from 'classnames';
import BulkUpload from 'features/targetingV2/components/BulkUpload/BulkUpload';
import AsyncMultiSelect from 'features/targetingV2/components/core/MultiSelect/AsyncMultiSelect/AsyncMultiSelect';
import ParameterMessage, { ParameterMessageColorEnum } from 'features/targetingV2/components/ParameterMessage/ParameterMessage';
import useBulkUploadMessages from 'features/targetingV2/hooks/BulkUploadMessages/useBulkUploadMessages';
import {
  IParameterValueOption,
  SectionType,
} from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import { debouncedLoadOptions } from 'features/targetingV2/utils/asyncParameterValues';
import {
  getHeaderDetails,
  handleParameterValuesUpload,
} from 'features/targetingV2/utils/bulkUpload';
import {
  getNoOfMultiValuesToShow,
  getParameterValuesDropdownPlaceholder,
  getQueryBasedOnType,
  hasBulkUpload,
  parametersWithBreadCrumbsOnMultiValue,
} from 'features/targetingV2/utils/common';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import { Territory } from 'interfaces/generated.types';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import useStyles from './AsyncDropdownSelector.styles';

export interface IAsyncDropdownSelectorProps {
  sectionType: SectionType;
  selectedParameter: IParameter;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  externalUploadInputRef: MutableRefObject<HTMLInputElement | null>;
  setFieldTouched?: (field: string, value: boolean) => void;
  classes?: { input?: string };
  idUsedToLimitParameterValues?: string;
  territory?: Territory;
  minimumCharactersRequired?: number;
}

const AsyncDropdownSelector = ({
  sectionType,
  selectedParameter,
  fieldName,
  setFieldTouched,
  setFieldValue,
  externalUploadInputRef,
  classes: externalClasses = {},
  idUsedToLimitParameterValues = '',
  territory,
  minimumCharactersRequired = 2,
}: IAsyncDropdownSelectorProps) => {
  const classes = useStyles();
  const dropdownRef = useRef<HTMLInputElement | null>(null);
  const valuesFieldName = `${fieldName}.values`;

  const {
    warningMessage,
    errorMessage,
    setWarningMessage,
    setErrorMessage,
    setIsBulkUploaded,
  } = useBulkUploadMessages(selectedParameter, externalUploadInputRef);

  const [fetchedOptions, setFetchedOptions] = useState<IParameterValueOption[]>(
    []
  );

  const inputClasses = classNames([
    classes.input,
    { [`${classes.input}--error`]: errorMessage },
  ]);

  const headerDetails = getHeaderDetails(selectedParameter.type);

  const handleChange = (newValues: IParameterValueOption[]) => {
    setFieldValue(valuesFieldName, newValues);
  };

  const validationQuery = useLazyQuery<any>(
    getQueryBasedOnType(selectedParameter.type)
  );

  useEffect(() => {
    if (dropdownRef.current && selectedParameter.type) {
      dropdownRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter.type]);

  return (
    <ApolloConsumer>
      {(client) => (
        <div className={classes.container}>
          <AsyncMultiSelect
            id={valuesFieldName}
            name={valuesFieldName}
            value={selectedParameter.values}
            isClearable={false}
            placeholder={getParameterValuesDropdownPlaceholder(
              selectedParameter.type
            )}
            onBlur={() => {
              if (setFieldTouched) setFieldTouched(valuesFieldName, true);
            }}
            onChange={handleChange}
            externalClasses={{
              ...classes,
              input: inputClasses,
              ...externalClasses,
            }}
            showBreadcrumbsOnMultiValue={parametersWithBreadCrumbsOnMultiValue.includes(
              selectedParameter.type
            )}
            noOfMultiValuesToShow={getNoOfMultiValuesToShow(
              selectedParameter.type
            )}
            attributes={{
              fieldAttribute: `${valuesFieldName}Field`,
              menuAttribute: `${valuesFieldName}MenuOptions`,
              chipAttribute: `${valuesFieldName}Chip`,
            }}
            loadOptions={(inputValue: string) =>
              debouncedLoadOptions(
                inputValue,
                minimumCharactersRequired,
                selectedParameter.type,
                sectionType,
                idUsedToLimitParameterValues,
                client,
                setFetchedOptions,
                territory
              )
            }
            fetchedOptions={fetchedOptions}
            selectAll
            minimumCharactersRequired={minimumCharactersRequired}
            selectRef={dropdownRef}
            autoFocus={!!selectedParameter.isFocused}
          />
          <div className={classes.messagesWrapper}>
            {warningMessage && (
              <ParameterMessage
                message={warningMessage}
                color={ParameterMessageColorEnum.Warning}
              />
            )}
            {errorMessage && (
              <ParameterMessage
                message={errorMessage}
                color={ParameterMessageColorEnum.Error}
              />
            )}
          </div>
          {hasBulkUpload(selectedParameter.type) && (
            <BulkUpload
              fieldName={valuesFieldName}
              label="Bulk Upload"
              externalUploadInputRef={externalUploadInputRef}
              handleDataUpload={(data, error) =>
                handleParameterValuesUpload(
                  data,
                  error,
                  selectedParameter,
                  valuesFieldName,
                  setErrorMessage,
                  setWarningMessage,
                  setFieldValue,
                  setIsBulkUploaded,
                  validationQuery
                )
              }
              headerOptions={headerDetails.headerOptions}
              header={headerDetails.header}
            />
          )}
        </div>
      )}
    </ApolloConsumer>
  );
};

export default AsyncDropdownSelector;
