import dialogStyles from 'assets/styles/components/Dialog.styles';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import Table from 'components/Table/Table';
import { ITargetingPack } from 'graphql/targetingGroups/queries';
import React, { useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

interface ITargetingPackModal {
  isModalOpen: boolean;
  setModalOpen: (arg0: boolean) => void;
  allTargetingPacks: ITargetingPack[];
  selectedTargetingPacks: ITargetingPack[];
  onSelect: (arg0: ITargetingPack[]) => void;
  dataTc: string;
}

interface ITargetingPackRow {
  id: string;
  name: string;
  description: string;
}

export const handleCheckboxChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  allTargetingPacks: ITargetingPack[],
  setSelectedPacks: (arg0: ITargetingPack[]) => void,
  selectedPacks: ITargetingPack[]
) => {
  const {
    target: { value, checked },
  } = event;
  if (checked) {
    // eslint-disable-next-line react/prop-types
    const packs = allTargetingPacks.filter((pack) => pack.id === value);
    setSelectedPacks([...selectedPacks, ...packs]);
  } else setSelectedPacks(selectedPacks.filter((pack) => pack.id !== value));
};

const TargetingPackModal = ({
  isModalOpen,
  setModalOpen,
  allTargetingPacks,
  selectedTargetingPacks,
  onSelect,
  dataTc,
}: ITargetingPackModal) => {
  const classes = dialogStyles();

  const [selectedPacks, setSelectedPacks] = useState<ITargetingPack[]>(
    selectedTargetingPacks
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Select',
        accessor: '',
        disableFilters: true,
        Filter: () => null,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ITargetingPackRow>) => (
          <Checkbox
            color="primary"
            data-tc={`${dataTc}Checkbox`}
            checked={selectedPacks.some((pack) => pack.id === row.original.id)}
            disabled={selectedTargetingPacks
              .filter((pack) => pack.id === row.original.id)
              .some((pack) => !!pack.readOnly)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleCheckboxChange(
                event,
                allTargetingPacks,
                setSelectedPacks,
                selectedPacks
              )
            }
            value={row.original.id}
            name={row.original.name}
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          maxWidth: '500px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    ],
    [allTargetingPacks, selectedTargetingPacks, selectedPacks, dataTc]
  );

  return (
    <Dialog
      data-tc={dataTc}
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      maxWidth="xl"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">Apply Targeting Packs</Typography>
        <IconButton
          onClick={() => setModalOpen(false)}
          classes={{ root: classes.closeButton }}
          data-tc={`${dataTc}X`}
        >
          <CloseIcon />
          <span className="sr-only">Close the add targeting pack modal</span>
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Table
          columns={columns}
          showColumnToggle={false}
          dataTc="listTargetingPacksTable"
          data={allTargetingPacks}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: classNames(
            classes.actions,
            `${classes.actions}--space-between`
          ),
        }}
      >
        <StyledButton
          onClick={() => setModalOpen(false)}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}Close`}
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            onSelect(selectedPacks);
            setModalOpen(false);
          }}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Contained}
          data-tc={`${dataTc}Apply`}
          disabled={!selectedPacks.length}
        >
          Select Targeting Packs
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default TargetingPackModal;
