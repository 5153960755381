import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  container: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.white,
    '&--edit': {
      padding: '5px 15px',
    },
    borderRadius: theme.spacing(0.625),
  },
  select: {
    backgroundColor: theme.palette.common.white,
    fontSize: '0.813em',
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  error: {
    fontSize: '0.625em',
    color: theme.palette.error.main,
  },
}));

export default useStyles;
