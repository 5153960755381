import {
  Event,
  TGTMLoggerContext,
} from 'context/GTMLoggerProvider/GTMLoggerProvider';
import { SectionType } from 'features/targetingV2/types/common';
import {
  IAudienceParameter,
  IAudienceParameterGroup,
  ICustomParameter,
  ICustomParameterGroup,
  IDayTimeTargeting,
  ILocationParameterGroup,
  IParameter,
  IParameterGroup,
  IParameterInfo,
  ITargetingDefinitionV2,
} from 'features/targetingV2/types/targeting';
import { clusivityOptionLabels } from 'features/targetingV2/utils/clusivity';
import { LocationParameterType, Territory } from 'interfaces/generated.types';

import { getFormattedAccuracyValue } from './accuracy';
import {
  isTargetingParameterInvalid,
  mandatoryParameters,
  sortStringsAlphabetically,
} from './common';
import {
  getCustomKvpsLabels,
  isCustomParameterInvalid,
} from './customParameter';
import {
  getParameterTypeName,
  getParameterValueLabelsBasedOnType,
} from './dataFormatting';
import { formatDayTime } from './dayTime';
import { getSortedGradeLabels } from './grading';

export const getParameterDetails = (
  parameter: IParameter,
  sectionType: SectionType,
  allParametersInfo: IParameterInfo[],
  territory: Territory,
  parameterGroup: IParameterGroup
) => ({
  parameterType: getParameterTypeName(parameter.type, allParametersInfo),
  clusivity: clusivityOptionLabels[parameter.clusivity!],
  grades:
    sectionType === SectionType.Audience
      ? getSortedGradeLabels(
          (parameter as IAudienceParameter).enabledGrades,
          false
        )
      : null,
  accuracy:
    parameter.type === LocationParameterType.Geo
      ? getFormattedAccuracyValue(
          (parameterGroup as ILocationParameterGroup).accuracyRadius
        )
      : null,
  valuesLabels: getParameterValueLabelsBasedOnType(
    parameter,
    parameterGroup,
    territory
  ),
});

export const getDetailsFromValidParameters = (
  parameters: IParameter[],
  sectionType: SectionType,
  allParametersInfo: IParameterInfo[],
  territory: Territory,
  parameterGroup: IParameterGroup
) =>
  parameters
    .filter(
      (parameter) =>
        !isTargetingParameterInvalid(parameter, mandatoryParameters)
    )
    .map((validParameter) =>
      getParameterDetails(
        validParameter,
        sectionType,
        allParametersInfo,
        territory,
        parameterGroup
      )
    );

export const getDetailsFromParameterGroups = (
  parameterGroups: IParameterGroup[],
  sectionType: SectionType,
  allParametersInfo: IParameterInfo[],
  territory: Territory
) =>
  parameterGroups.flatMap((parameterGroup) =>
    getDetailsFromValidParameters(
      parameterGroup.parameters,
      sectionType,
      allParametersInfo,
      territory,
      parameterGroup
    )
  );

export const getCustomDetailsFromValidParameters = (
  customParameters: ICustomParameter[]
) =>
  customParameters
    .filter((parameter) => !isCustomParameterInvalid(parameter))
    .map((validParameter) => ({
      parameterType: 'Custom Parameter',
      clusivity: clusivityOptionLabels[validParameter.clusivity],
      valuesLabels: sortStringsAlphabetically(
        getCustomKvpsLabels(validParameter.customKvps)
      ),
      grades: null,
      accuracy: null,
    }));

export const getDetailsFromCustomParameterGroups = (
  customGroups: ICustomParameterGroup[]
) =>
  customGroups.flatMap((customGroup) =>
    getCustomDetailsFromValidParameters(customGroup.parameters)
  );

export const getDayTimeDetails = (dayTimeTargeting: IDayTimeTargeting | null) =>
  dayTimeTargeting && dayTimeTargeting.dayTimePoints.length
    ? {
        parameterType: 'Day & Time',
        clusivity: clusivityOptionLabels[dayTimeTargeting.clusivity],
        valuesLabels: formatDayTime(dayTimeTargeting.dayTimePoints),
        grades: null,
        accuracy: null,
      }
    : null;

export const getAllSelectedParametersDetails = (
  audiences: IAudienceParameterGroup[],
  locations: ILocationParameterGroup[],
  inventories: IParameterGroup[],
  technologies: IParameterGroup[],
  customGroups: ICustomParameterGroup[],
  dayTimeTargeting: IDayTimeTargeting | null,
  allParametersInfo: IParameterInfo[],
  territory: Territory
) => {
  const audienceParametersDetails = getDetailsFromParameterGroups(
    audiences,
    SectionType.Audience,
    allParametersInfo,
    territory
  );

  const locationParametersDetails = getDetailsFromParameterGroups(
    locations,
    SectionType.Location,
    allParametersInfo,
    territory
  );

  const inventoryParametersDetails = getDetailsFromParameterGroups(
    inventories,
    SectionType.Inventory,
    allParametersInfo,
    territory
  );

  const technologyParametersDetails = getDetailsFromParameterGroups(
    technologies,
    SectionType.Technology,
    allParametersInfo,
    territory
  );

  const customParametersDetails =
    getDetailsFromCustomParameterGroups(customGroups);

  const dayTimeDetails = getDayTimeDetails(dayTimeTargeting);

  const allSelectedParametersDetails = [
    ...audienceParametersDetails,
    ...locationParametersDetails,
    ...inventoryParametersDetails,
    ...technologyParametersDetails,
    ...customParametersDetails,
    ...(dayTimeDetails ? [dayTimeDetails] : []),
  ];

  return allSelectedParametersDetails;
};

export const getTargetingV2DataForGTM = (
  targetingDefinitionV2: ITargetingDefinitionV2 | null,
  allParametersInfo: IParameterInfo[],
  territory: Territory
) => {
  if (!targetingDefinitionV2)
    return { parametersDetails: [], allParametersValuesLabels: [] };

  const {
    audienceTargeting,
    locationTargeting,
    inventoryTargeting,
    technologyTargeting,
    customTargeting,
    dayTimeTargeting,
  } = targetingDefinitionV2;

  const allSelectedParametersDetails = getAllSelectedParametersDetails(
    audienceTargeting?.audiences || [],
    locationTargeting?.locations || [],
    inventoryTargeting?.inventories || [],
    technologyTargeting?.technologies || [],
    customTargeting?.customGroups || [],
    dayTimeTargeting,
    allParametersInfo,
    territory
  );

  return {
    parametersDetails: allSelectedParametersDetails,
    allParametersValuesLabels: allSelectedParametersDetails.flatMap(
      (parameterDetails) => parameterDetails.valuesLabels
    ),
  };
};

export const sendTargetingV2DataToGTM = (
  targetingDefinitionV2: ITargetingDefinitionV2 | null,
  allParametersInfo: IParameterInfo[],
  gtmLogger: TGTMLoggerContext,
  territory: Territory
) => {
  const { parametersDetails, allParametersValuesLabels } =
    getTargetingV2DataForGTM(
      targetingDefinitionV2,
      allParametersInfo,
      territory
    );

  parametersDetails.forEach((parameterDetails) =>
    gtmLogger({
      event: Event.USER_SAVES_PARAMETER,
      parameter: parameterDetails.parameterType,
      clusivity: parameterDetails.clusivity,
      grades: parameterDetails.grades,
      accuracy: parameterDetails.accuracy,
      values: parameterDetails.valuesLabels.join(', '),
    })
  );

  allParametersValuesLabels.forEach((valueLabel) =>
    gtmLogger({
      event: Event.USER_SAVES_PARAMETER_VALUE,
      parameterValue: valueLabel,
    })
  );
};
