import { baseFontStyles } from 'assets/styles/components/Font.styles';
import { basePageStyles } from 'assets/styles/components/Page.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  ...basePageStyles(theme),
  title: {
    margin: theme.spacing(1, 0, 1, 0),
  },

  paragraph: {
    margin: theme.spacing(0, 4, 0, 4),
  },

  textFieldContainer: {
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: '416px',
  },

  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 4, 0),
  },
  cta: {
    whiteSpace: 'nowrap',
    height: '32px',
    fontSize: '15px',
    borderColor: theme.palette.grey[800],
    color: theme.palette.grey[800],
    display: 'flex',
    '&--submit': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginLeft: theme.spacing(6),
    },
    '&--back': {
      marginRight: theme.spacing(4),
      width: '100px',
    },
    '&--resend': {
      width: '250px',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginTop: theme.spacing(3.5),
    },
  },
  errorWrapper: {
    composes: '$errorMessage',
    padding: theme.spacing(1, 4, 0, 4),
  },
}));

export default useStyles;
