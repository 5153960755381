import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import { ITechnicalProviderValues } from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderFormValues';
import TechnicalProviderTabsForm, {
  technicalProviderButtons,
} from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderTabsForm';
import TechnicalProviderFormValidation from 'features/programmatic/technicalProvider/validations/technicalProviders';
import { getTerritoryValues } from 'utils/territory';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  EntityType,
  TechnicalProvider,
  UpdateTechnicalProviderMutationFn,
  useUpdateTechnicalProviderMutation,
} from 'interfaces/generated.types';

import { spaceKvps } from 'utils/dataControl';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface IUpdateTechnicalProviderContainerProps {
  technicalProvider: TechnicalProvider;
  history: History;
  match: {
    params: {
      technicalProviderId: string;
    };
  };
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string
) => handleOnJourneyRouting({ history, selectedJourney, id });

export const submitUpdateTechnicalProvider = (
  updateTechnicalProvider: UpdateTechnicalProviderMutationFn,
  initialValues: ITechnicalProviderValues,
  id: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ITechnicalProviderValues,
  { setStatus, setSubmitting }: FormikActions<ITechnicalProviderValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = {
    ...differenceInValues(
      { ...formValues, journey: null },
      { ...initialValues, journey: null }
    ),
    id,
  };

  updateTechnicalProvider({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateTechnicalProviderContainer = ({
  technicalProvider,
  history,
  match,
  hasEditPermissions,
}: IUpdateTechnicalProviderContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Programmatic Connector.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();
  const [updateTechnicalProvider] = useUpdateTechnicalProviderMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, technicalProvider.id);
    },
  });
  const validateTechnicalProvider = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    currency: technicalProvider.currency,
    name: technicalProvider.name as string,
    id: technicalProvider.id,
    consentVendorId: technicalProvider.consentVendorId
      ? technicalProvider.consentVendorId.toString()
      : '',
    userSyncUrl: technicalProvider.userSyncUrl || '',
    userSyncSecureUrl: technicalProvider.userSyncSecureUrl || '',
    altId: technicalProvider.altId,
    blockGeoData: technicalProvider.blockGeoData as boolean,
    truncateGpsCoords: technicalProvider.truncateGpsCoords as boolean,
    blockIpData: technicalProvider.blockIpData as boolean,
    truncateIpAddress: technicalProvider.truncateIpAddress as boolean,
    block1stPartyData: technicalProvider.block1stPartyData as boolean,
    kvps1stPartyAllData:
      !technicalProvider.kvps1stPartyData ||
      !technicalProvider.kvps1stPartyData.length,
    kvps1stPartyData:
      technicalProvider.kvps1stPartyData &&
      technicalProvider.kvps1stPartyData.length
        ? spaceKvps(technicalProvider.kvps1stPartyData)
        : '',
    blockListenerId: technicalProvider.blockListenerId as boolean,
    journey: technicalProviderButtons[0].url,
    gzipCompressed: technicalProvider.gzipCompressed as boolean,
    sendNotSyncedUsers: technicalProvider.sendNotSyncedUsers as boolean,
    blockSite: technicalProvider.blockSite as boolean,
    siteName: technicalProvider.siteName as string,
    siteDomain: technicalProvider.siteDomain as string,
    blockApp: technicalProvider.blockApp as boolean,
    appBundle: (technicalProvider.appBundle as string) || '',
    appName: technicalProvider.appName as string,
    appDomain: technicalProvider.appDomain as string,
    blockPublisher: technicalProvider.blockPublisher as boolean,
    publisherId: (technicalProvider.publisherId as string) || '',
    publisherName: technicalProvider.publisherName as string,
    publisherDomain: technicalProvider.publisherDomain as string,
    publisherTagId: (technicalProvider.publisherTagId as string) || '',
    status: technicalProvider.status,
    vastVersions: technicalProvider.vastVersions || [],
    dspProvider: technicalProvider.dspProvider || '',
    territories: getTerritoryValues(technicalProvider.territories),
    bidFloorPrecision: technicalProvider.bidFloorPrecision as string,
  };

  return (
    <>
      <Formik<ITechnicalProviderValues>
        initialValues={initialValues}
        validationSchema={TechnicalProviderFormValidation}
        onSubmit={submitUpdateTechnicalProvider(
          updateTechnicalProvider,
          initialValues,
          match.params.technicalProviderId,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <TechnicalProviderTabsForm
            {...formikProps}
            update
            hasEditPermissions={hasEditPermissions}
            history={history}
            match={match}
            datacenters={technicalProvider?.dataCenters || []}
            dsps={
              technicalProvider && technicalProvider.dsps
                ? technicalProvider.dsps
                : []
            }
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateTechnicalProvider,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Technicalprovider,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateTechnicalProvider,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateTechnicalProvider"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateTechnicalProviderWarningDialog"
      />
    </>
  );
};

export default UpdateTechnicalProviderContainer;
