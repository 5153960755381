import {
  ChipState,
  ICustomKvpError,
  ICustomKvpOption,
  IOptionType,
} from 'features/targetingV2/types/common';
import {
  ICustomParameter,
  ICustomParameterGroup,
} from 'features/targetingV2/types/targeting';
import { getIn } from 'formik';
import shortid from 'shortid';

export const isCustomParameterInvalid = (parameter: ICustomParameter) =>
  parameter.customKvps.length === 0;

export const hasInvalidCustomParameters = (
  customGroup: ICustomParameterGroup
) =>
  customGroup.parameters.some((parameter) =>
    isCustomParameterInvalid(parameter)
  );

export const enrichCustomKvpOptions = (
  customKvpOptions: ICustomKvpOption[],
  customKvpErrors: (ICustomKvpError | undefined)[] | undefined
) => {
  if (!customKvpErrors) return customKvpOptions;

  return customKvpOptions.map((customKvpOption, index) => {
    const customKvpError = customKvpErrors[index];
    if (customKvpError) {
      const { key: keyError, value: valueError } = customKvpError.keyValuePair;

      const tooltipMessage =
        keyError === valueError
          ? keyError
          : [keyError, valueError].filter(Boolean);

      return {
        ...customKvpOption,
        state: ChipState.INACTIVE,
        tooltip: tooltipMessage,
      };
    }
    return customKvpOption;
  });
};

export const formatNewCustomKvpValues = (
  newValues: IOptionType[]
): ICustomKvpOption[] =>
  newValues.map((newValue) => {
    if (!newValue.label.includes('=')) {
      return {
        ...newValue,
        value: shortid.generate(),
        keyValuePair: { key: newValue.label, value: '' },
      };
    }

    const splitKvp = newValue.label.split('=');
    const key = splitKvp[0].trim();
    const value = splitKvp.slice(1).join('=').trim();

    return {
      label: `${key} = ${value}`,
      value: shortid.generate(),
      keyValuePair: { key, value },
    };
  });

export const getValidCustomKvps = (
  customKvps: ICustomKvpOption[],
  customKvpsErrors: (ICustomKvpError | undefined)[] | undefined
) => {
  const enrichedCustomKvps = enrichCustomKvpOptions(
    customKvps,
    customKvpsErrors
  );

  const validCustomKvps = enrichedCustomKvps.filter(
    (customKvp) => customKvp.state !== ChipState.INACTIVE
  );

  return validCustomKvps;
};

export const getParametersContainingValidCustomKvps = (
  parameters: ICustomParameter[],
  customGroupFieldName: string,
  errors: any
) =>
  parameters.reduce(
    (
      validParameters: ICustomParameter[],
      currentParameter: ICustomParameter,
      currentParameterIndex: number
    ) => {
      const customKvpsFieldName = `${customGroupFieldName}.parameters[${currentParameterIndex}].customKvps`;
      const customKvpsErrors = getIn(errors, customKvpsFieldName);

      const validCustomKvps = getValidCustomKvps(
        currentParameter.customKvps,
        customKvpsErrors
      );

      const cleansedParameter = {
        ...currentParameter,
        customKvps: validCustomKvps,
      };

      if (!isCustomParameterInvalid(cleansedParameter)) {
        validParameters.push(cleansedParameter);
      }

      return validParameters;
    },
    []
  );

export const getCustomKvpsLabels = (customKvps: ICustomKvpOption[]) =>
  customKvps.map((customKvp) => customKvp.label);
