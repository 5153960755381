import { ReactComponent as InfoIcon } from 'assets/icons/TargetingV2/dax-info.svg';
import Divider from 'features/targetingV2/components/core/Divider';
import Tooltip from 'features/targetingV2/components/core/Tooltip/Tooltip';
import Typography from 'features/targetingV2/components/core/Typography';
import TargetingRestrictionsInfo from 'features/targetingV2/components/TargetingRestrictionsInfo/TargetingRestrictionsInfo';
import TooltipContent from 'features/targetingV2/components/TooltipContent/TooltipContent';
import { SectionType } from 'features/targetingV2/types/common';
import { getSectionTitle } from 'features/targetingV2/utils/common';
import { getTooltipContentBasedOnSectionType } from 'features/targetingV2/utils/tooltip';
import { TargetingRestriction } from 'interfaces/generated.types';
import React from 'react';
import { mapSectionToRestrictions } from 'utils/targetingRestrictions';

import useStyles from './TargetingSection.styles';

const TargetingSection = ({
  children,
  headerChildren,
  sectionType,
  hideDivider = false,
  restrictionsList,
  hideTooltip = false,
}: {
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  sectionType: SectionType;
  hideDivider?: boolean;
  restrictionsList?: TargetingRestriction[];
  hideTooltip?: boolean;
}) => {
  const classes = useStyles();
  const sectionTitle = getSectionTitle(sectionType);
  const sectionTooltipContent =
    getTooltipContentBasedOnSectionType(sectionType);

  const sectionRestrictions = mapSectionToRestrictions(sectionType).filter(
    (restriction) => restrictionsList?.includes(restriction)
  );

  return (
    <div data-testid={`targetingSection-${sectionTitle}`}>
      <div className={classes.section}>
        <div className={classes.headerSection}>
          <Typography
            variant="subtitle1"
            classes={{ subtitle1: classes.text }}
            data-testid={`${sectionTitle}-section-title`}
          >
            {sectionTitle}
          </Typography>
          {!hideTooltip && (
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
              }}
              title={
                <TooltipContent
                  tooltipContent={sectionTooltipContent}
                  data-testid={`tooltip-content-${sectionType}`}
                />
              }
              placement="right-start"
              interactive
            >
              <InfoIcon className={classes.tooltipIcon} />
            </Tooltip>
          )}
          {headerChildren}
          {!!sectionRestrictions?.length && (
            <TargetingRestrictionsInfo restrictionsList={sectionRestrictions} />
          )}
        </div>
        <div className={classes.innerSection}>{children}</div>
      </div>
      {!hideDivider && <Divider />}
    </div>
  );
};

export default TargetingSection;
