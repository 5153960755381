import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  message: ({ color }: { color: string }) => ({
    marginTop: theme.spacing(0.5),
    color,
  }),
}));

export default useStyles;
