import { ReactComponent as LeftArrowIcon } from 'assets/icons/dax-leftarrowicon.svg';
import { ReactComponent as FirstPageIcon } from 'assets/icons/dax-leftskipicon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/dax-rightarrowicon.svg';
import { ReactComponent as LastPageIcon } from 'assets/icons/dax-rightskipicon.svg';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MTableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { useFooterStyles, usePaginationStyles } from './Table.styles';

interface ITableFooter {
  totalItems?: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  pageIndex: number;
  setPaginationIndex?: (arg0: number) => void;
  setPaginationRowsPerPage?: (arg0: number) => void;
  history?: History;
}

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: ITablePaginationActionsProps) => {
  const classes = usePaginationStyles();
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <LeftArrowIcon />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <RightArrowIcon />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

const TableFooter = ({
  totalItems,
  gotoPage,
  setPageSize,
  pageSize,
  pageIndex,
  setPaginationIndex,
  setPaginationRowsPerPage,
  history,
}: ITableFooter) => {
  const classes = useFooterStyles();

  const location = usePreviousLocation();
  const { table } = location.state || 0;

  return (
    <MTableFooter aria-label="table-footer">
      <TableRow>
        <TablePagination
          classes={classes}
          count={totalItems || 0}
          onPageChange={(e, page) => {
            gotoPage(page);
            setPaginationIndex && setPaginationIndex(page);
            history &&
              history.push({
                pathname: window.location.pathname,
                state: {
                  ...location.state,
                  table: { ...table, pagination: page },
                },
              });
          }}
          onRowsPerPageChange={(e) => {
            const { value } = e.target;
            setPageSize(Number(value));
            setPaginationRowsPerPage &&
              setPaginationRowsPerPage(parseFloat(value));
            history &&
              history.push({
                pathname: window.location.pathname,
                state: {
                  ...location.state,
                  table: { ...table, rowsPerPage: parseFloat(value) },
                },
              });
          }}
          rowsPerPageOptions={[15, 25, 50]}
          page={pageIndex}
          rowsPerPage={pageSize}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </MTableFooter>
  );
};

export default TableFooter;
