import { basePageStyles } from 'assets/styles/components/Page.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  audioPlayer: {
    width: '200px',
    height: '30px',
    display: 'block',
    '&::-webkit-media-controls-panel': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&::-webkit-media-controls-enclosure': {
      borderRadius: '10px',
    },
    '&::-webkit-media-controls-mute-button': {
      display: 'none',
    },
    '&::-webkit-media-controls-volume-slider': {
      display: 'none',
    },
    '&::-webkit-media-controls-volume-slider-container': {
      display: 'none',
    },
    '&--fullLength': {
      width: '250px',
      '&::-webkit-media-controls-mute-button': {
        display: 'block',
      },
      '&::-webkit-media-controls-volume-slider': {
        display: 'block',
      },
      '&::-webkit-media-controls-volume-slider-container': {
        display: 'block',
      },
    },
    '&--fullWidth': {
      width: '100%',
      opacity: '80%',
      '&::-webkit-media-controls-mute-button': {
        display: 'block',
      },
      '&::-webkit-media-controls-volume-slider': {
        display: 'block',
      },
      '&::-webkit-media-controls-volume-slider-container': {
        display: 'block',
      },
    },
  },
}));

export default useStyles;
