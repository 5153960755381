import { IabCategory, Publisher } from 'interfaces/generated.types';
import { ITreeItems } from 'interfaces/index';
import { uniq } from 'lodash';
import memoizeOne from 'memoize-one';

export const getSubtreeExpandedItemsIds = (
  selectedIds: string[],
  rootItem: ITreeItems
): string[] => {
  let expandedItemsIds: string[] = [];

  if (rootItem.children && rootItem.children.length > 0) {
    rootItem.children.forEach((child) => {
      if (selectedIds.includes(child.value))
        expandedItemsIds.push(rootItem.value);
      else
        expandedItemsIds = expandedItemsIds.concat(
          getSubtreeExpandedItemsIds(selectedIds, child)
        );

      if (
        expandedItemsIds.includes(child.value) &&
        !expandedItemsIds.includes(rootItem.value)
      )
        expandedItemsIds.push(rootItem.value);
    });
  }

  return expandedItemsIds;
};

export const getExpandedItemsIds = memoizeOne(
  (selectedIds: string[], allItems: ITreeItems[]): string[] => {
    if (selectedIds.length === 0) return [];

    let expandedItemsIds: string[] = [];

    allItems.forEach((item) => {
      expandedItemsIds = expandedItemsIds.concat(
        getSubtreeExpandedItemsIds(selectedIds, item)
      );
    });

    return uniq(expandedItemsIds);
  }
);

export const formatIabCategories = memoizeOne(
  (categories: IabCategory[]): ITreeItems[] =>
    categories.map((category) => ({
      value: category.code,
      label: `${category.code}: ${category.name}`,
      children: category.subIabCategories.map((subcategory) => ({
        value: subcategory.code,
        label: `${subcategory.code}: ${subcategory.name}`,
      })),
    }))
);

export const formatPublisherData = memoizeOne(
  (data: Publisher[]): ITreeItems[] =>
    data
      .filter((parent) => parent.channels.length > 0)
      .map((parent) => ({
        value: parent.id,
        label: parent.name as string,
        children: parent.channels.map((child) => ({
          value: child.id,
          label: child.name as string,
        })),
      }))
);

export const getChannelName = (
  channelId: string,
  publisherData: Publisher[]
) => {
  let channelName = '';

  publisherData.forEach((publisher) => {
    const foundChannel = publisher.channels.find(
      (channel) => channel.id === channelId
    );
    if (foundChannel?.name) channelName = foundChannel.name;
  });

  return channelName;
};

export const generateAllReadonlySelectedItems = (
  selectedIds: string[],
  allItems: ITreeItems[],
  stopAtFirstSelectedAncestor: boolean = false
) => {
  if (selectedIds.length === 0) return [];
  return allItems.reduce((result: ITreeItems[], currentItem: ITreeItems) => {
    if (selectedIds.includes(currentItem.value)) {
      if (
        !currentItem.children ||
        currentItem.children.length === 0 ||
        stopAtFirstSelectedAncestor
      ) {
        result.push({
          ...currentItem,
          children: [],
          showCheckbox: false,
        });

        return result;
      }
    }

    if (currentItem.children) {
      const readonlySelectedChildren = generateAllReadonlySelectedItems(
        selectedIds,
        currentItem.children,
        stopAtFirstSelectedAncestor
      );

      if (readonlySelectedChildren.length > 0)
        result.push({
          ...currentItem,
          children: readonlySelectedChildren,
          showCheckbox: false,
        });
    }
    return result;
  }, []);
};

export const getAllExpandableItemsIds = (allItems: ITreeItems[]): string[] =>
  allItems.reduce((result: string[], currentItem) => {
    if (currentItem.children && currentItem.children.length > 0) {
      result.push(currentItem.value);
      return result.concat(getAllExpandableItemsIds(currentItem.children));
    }
    return result;
  }, []);

export const filterNodes = memoizeOne((text: string, nodes: ITreeItems[]) => {
  if (!text) {
    return nodes;
  }

  return nodes.reduce((acc: ITreeItems[], curr: ITreeItems) => {
    if (curr.children && curr.children.length > 0) {
      const filteredChildren = filterNodes(text, curr.children);

      if (
        curr.label.toLowerCase().includes(text.toLowerCase()) ||
        filteredChildren.length > 0
      ) {
        const children =
          filteredChildren.length > 0 ? filteredChildren : curr.children;
        acc.push({
          ...curr,
          children,
        });
      }
    } else if (curr.label.toLowerCase().includes(text.toLowerCase())) {
      acc.push(curr);
    }

    return acc;
  }, []);
});

export const getFilteredItemsIds = memoizeOne((nodes: ITreeItems[]) =>
  nodes.flatMap((item: ITreeItems) => [
    ...(!item.children || item.children.length === 0
      ? [item.value]
      : item.children?.flatMap((child: { value: string }) => child.value)),
  ])
);

export const getDescendantIdsToRemove = (selectedIds: string[], node: any) => {
  let idsToRemove: string[] = [];

  if (selectedIds.includes(node.value)) idsToRemove.push(node.value);

  if (node.children && node.children.length > 0)
    node.children.forEach((child: any) => {
      idsToRemove = [
        ...idsToRemove,
        ...getDescendantIdsToRemove(selectedIds, child),
      ];
    });

  return idsToRemove;
};

export const checkIfAncestor = (
  potentialAncestor: ITreeItems,
  node: any
): boolean => {
  if (!potentialAncestor.children || potentialAncestor.children.length === 0)
    return false;

  return potentialAncestor.children.some((child) => {
    if (child.value === node.value) return true;
    return checkIfAncestor(child, node);
  });
};

export const getSiblingsNodes = (
  treeNodeToCheck: ITreeItems,
  unselectedNode: any
): ITreeItems[] => {
  if (treeNodeToCheck.children) {
    if (treeNodeToCheck.value === unselectedNode.parent?.value) {
      return treeNodeToCheck.children.filter(
        (item: ITreeItems) => item.value !== unselectedNode.value
      );
    }

    const relativeNodes = treeNodeToCheck.children.reduce(
      (resultList: ITreeItems[], currentChildNode: any) => {
        let additionalNodes: ITreeItems[] = [];

        if (checkIfAncestor(currentChildNode, unselectedNode))
          additionalNodes = additionalNodes.concat(
            ...getSiblingsNodes(currentChildNode, unselectedNode)
          );
        else if (
          currentChildNode.children &&
          !resultList.find((item) => item.value === treeNodeToCheck.value)
        )
          additionalNodes.push(currentChildNode);

        return [...resultList, ...additionalNodes];
      },
      []
    );
    return relativeNodes;
  }
  return [];
};

export const getIdsToUpdateOnUnselectingNode = (
  selectedIds: string[],
  nodeToCheck: ITreeItems,
  unselectedTreeNode: any
) => {
  let idsToRemove: string[] = [];
  let itemsToAdd: ITreeItems[] = [];

  if (nodeToCheck.value === unselectedTreeNode.value)
    idsToRemove.push(nodeToCheck.value);

  const isAncestor = checkIfAncestor(nodeToCheck, unselectedTreeNode);

  if (!isAncestor) return { idsToRemove, itemsToAdd };

  if (selectedIds.includes(nodeToCheck.value)) {
    idsToRemove.push(nodeToCheck.value);

    itemsToAdd = [
      ...itemsToAdd,
      ...getSiblingsNodes(nodeToCheck, unselectedTreeNode),
    ];
  }

  if (nodeToCheck.children && nodeToCheck.children.length > 0) {
    nodeToCheck.children.forEach((child) => {
      const result = getIdsToUpdateOnUnselectingNode(
        selectedIds,
        child,
        unselectedTreeNode
      );
      idsToRemove = [...idsToRemove, ...result?.idsToRemove];
      itemsToAdd = [...itemsToAdd, ...result?.itemsToAdd];
    });
  }

  return { idsToRemove, itemsToAdd };
};

export const getSelectedTreeItem = (
  allItems: ITreeItems[],
  selectedTreeNode: any
): ITreeItems | null => {
  let selectedTreeItem: any = null;

  allItems.some((item) => {
    if (item.value === selectedTreeNode.value) {
      selectedTreeItem = item;
      return true;
    }

    if (
      item.value !== selectedTreeNode.value &&
      item.children &&
      item.children.length > 0
    )
      selectedTreeItem = getSelectedTreeItem(item.children, selectedTreeNode);

    return !!selectedTreeItem;
  });

  return selectedTreeItem;
};

export const getAllLeafIds = (rootItem: ITreeItems) => {
  let leafItemsIds: string[] = [];

  if (rootItem.children && rootItem.children.length > 0)
    rootItem.children.forEach((child) => {
      if (!child.children || child.children.length === 0)
        leafItemsIds.push(child.value);
      else {
        const childLeafs = getAllLeafIds(child);
        leafItemsIds = leafItemsIds.concat(childLeafs);
      }
    });
  else leafItemsIds.push(rootItem.value);

  return leafItemsIds;
};

export const getSelectedItemsLeafIds = (
  selectedIds: string[],
  allItems: ITreeItems[]
) => {
  let results: string[] = [];

  allItems.forEach((item) => {
    if (selectedIds.includes(item.value)) {
      results = results.concat(getAllLeafIds(item));
    }

    if (item.children && item.children.length > 0) {
      const childLeafs = getSelectedItemsLeafIds(selectedIds, item.children);
      results = results.concat(childLeafs);
    }
  });

  return results;
};

export default {
  getExpandedItemsIds,
  formatIabCategories,
  formatPublisherData,
  filterNodes,
};
