import { getIn } from 'formik';
import React, { useEffect, useState } from 'react';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import Loader from 'components/Loader/Loader';

import { IFormProps, OptionType } from 'interfaces';
import {
  useAllCountriesQuery,
  useListRegionsQuery,
} from 'interfaces/generated.types';

import locationUtils from 'utils/location';
import {
  getTargetingPath,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

import TargetingMultiSelect from '../TargetingMultiSelect/TargetingMultiSelect';
import { TLocationOption } from '../TargetingParameters/TargetingParametersValues';

export const regionValues = {
  countryParams: {
    countries: [],
    regions: [],
  },
};

export const filterRegionsByCountries = (
  regions: TLocationOption[],
  countries: OptionType[]
) => {
  const countryCodes = locationUtils.formatCountryCodes(countries);
  return regions.filter((region: TLocationOption) =>
    countryCodes.includes(region.countryCode)
  );
};

const TargetingRegion = (props: IFormProps<any> & ITargetingComponentProps) => {
  const {
    values,
    setFieldValue,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
  } = props;

  const classes: any = targetingStyles({});
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const regionField = `${targetingPath}.audienceParams.${index}.countryParams.regions`;
  const countryField = `${targetingPath}.audienceParams.${index}.countryParams.countries`;
  const countryVals = getIn(values, countryField);
  const regionVals = getIn(values, regionField);
  const [countries, setCountries] = useState(countryVals);
  const [regions, setRegions] = useState(regionVals);

  useEffect(() => {
    setCountries(countryVals || []);
  }, [countryVals]);

  useEffect(() => {
    setRegions(regionVals || []);
  }, [regionVals]);

  useEffect(() => {
    setFieldValue(regionField, filterRegionsByCountries(regions, countries));
  }, [countries, regionField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps
  const { loading, data } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });
  const { data: allRegions } = useListRegionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCodes: locationUtils.formatCountryCodes(countries),
    },
    skip: countries.length === 0,
  });
  if (loading) return <Loader />;

  return (
    <>
      <div className={classes.valueWrapper}>
        <TargetingMultiSelect
          {...props}
          label="Country"
          selectAll={false}
          maxOptions={10}
          maxOptionsText="You can only select 10 countries"
          targetingName="countryParams.countries"
          options={
            data && data.allCountries
              ? locationUtils.createCountryGroup(data.allCountries)
              : []
          }
        />
      </div>
      <div className={classes.valueWrapper}>
        <TargetingMultiSelect
          {...props}
          label="Region"
          targetingName="countryParams.regions"
          isDisabled={!allRegions}
          options={
            allRegions && allRegions.allRegions
              ? locationUtils.createRegionGroup(allRegions.allRegions)
              : []
          }
        />
      </div>
    </>
  );
};

export default TargetingRegion;
