import { IFormattedShowContract } from 'features/inventory/channel/components/ShowContractForm/ShowContractValues';
import CreateShowContractContainer from 'features/inventory/channel/containers/CreateShowContractContainer/CreateShowContractContainer';
import UpdateShowContractContainer from 'features/inventory/channel/containers/UpdateShowContractContainer/UpdateShowContractContainer';
import { Channel } from 'interfaces/generated.types';
import React from 'react';

import { Dialog, Typography } from '@material-ui/core';

import useStyles from './ShowContractDialog.styles';

export interface IShowContractModalProps {
  refetch: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
  channel?: Channel;
  contract?: IFormattedShowContract;
  update?: boolean;
  readOnly?: boolean;
  hasEditPermissions?: boolean;
}

const ShowContractDialog = ({
  refetch,
  handleClose,
  isModalOpen,
  channel,
  contract,
  update = false,
  readOnly = false,
  hasEditPermissions = false,
}: IShowContractModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isModalOpen}
      onClose={handleClose}
      data-testid="showContractDialog"
    >
      <div className={classes.container}>
        <Typography className={classes.title}>Show Contract</Typography>
        {update && contract && (
          <UpdateShowContractContainer
            showContract={contract}
            handleClose={handleClose}
            refetch={refetch}
            readOnly={readOnly}
            hasEditPermissions={hasEditPermissions}
          />
        )}
        {!update && channel && (
          <CreateShowContractContainer
            channel={channel}
            handleClose={handleClose}
            refetch={refetch}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </div>
    </Dialog>
  );
};

export default ShowContractDialog;
