import {
  ApprovalStatus,
  DspIncludedSeats,
  ProgrammaticCreativeType,
  Territory,
} from 'interfaces/generated.types';

export interface IProgrammaticCreativeStatusValues {
  value: ApprovalStatus;
  label: string;
  isDisabled?: (value: ApprovalStatus) => boolean;
}

export const programmaticCreativeStatusValues: IProgrammaticCreativeStatusValues[] =
  [
    {
      value: ApprovalStatus.LiveAwaitingQa,
      label: 'Live - Awaiting QA',
      isDisabled: (value: ApprovalStatus) =>
        [
          ApprovalStatus.Approved,
          ApprovalStatus.Blocked,
          ApprovalStatus.BlockedAwaitingQa,
        ].includes(value),
    },
    {
      value: ApprovalStatus.BlockedAwaitingQa,
      label: 'Blocked - Awaiting QA',
      isDisabled: (value: ApprovalStatus) =>
        [
          ApprovalStatus.Approved,
          ApprovalStatus.Blocked,
          ApprovalStatus.LiveAwaitingQa,
        ].includes(value),
    },
    {
      value: ApprovalStatus.Approved,
      label: 'Approved',
    },
    {
      value: ApprovalStatus.Blocked,
      label: 'Blocked',
    },
  ];

export interface IDealBasicProps {
  id: string;
  name: string;
  dspIncludedSeats: DspIncludedSeats;
}

export interface IProgrammaticCreativesRow {
  id: string;
  name: string;
  deal: IDealBasicProps | null;
  deals: IDealBasicProps[];
  advertiserDomains: string | null;
  firstSeen: string | null;
  status: ApprovalStatus;
  type: ProgrammaticCreativeType;
  fileName: string | null;
  url: string;
  firstBidDate: string | null;
  approvalDate: string | null;
  iabCategoryCodes: string | null;
  duration: string;
  spec: {
    duration?: number | undefined;
    width?: number | undefined;
    height?: number | undefined;
  };
  territories?: Territory[];
}
