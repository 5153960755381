import { makeStyles, Theme } from '@material-ui/core/styles';

import { basePageStyles } from 'assets/styles/components/Page.styles';
import { baseContainerStyles } from 'components/Container/Container.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  ...baseContainerStyles(theme),
}));

export default useStyles;
