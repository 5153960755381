import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.grey[800],
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(1, 1.25),
    minWidth: theme.spacing(15),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.common.white,
    },
    height: 34,
  },
  list: {
    width: 25,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    padding: theme.spacing(1),
    width: 450,
  },
  header: {
    fontSize: '0.75rem',
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
  },
  listItem: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 0,
    width: 40,
    marginRight: theme.spacing(1),
    '&.MuiListItem-button': {
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  checkbox: {
    color: theme.palette.grey[800],
    '&.MuiCheckbox-root': {
      padding: 0,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&.MuiIconButton-root': {
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  label: {
    width: 200,
    fontSize: '0.9rem',
    lineHeight: 1.2,
  },
  menuButton: {
    textAlign: 'center',
  },
  loader: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

export default useStyles;
