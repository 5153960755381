import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import FieldSwitch from 'components/FieldSwitch/FieldSwitch';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import TargetingRestrictions from 'components/TargetingRestrictions/TargetingRestrictions';
import { IAdValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { Field } from 'formik';
import {
  fieldToSwitch,
  Switch,
  SwitchProps,
  TextField,
} from 'formik-material-ui';
import { FrequencyCapTimeUnits, IFormProps } from 'interfaces';
import { Tag, TargetingRestriction } from 'interfaces/generated.types';
import React from 'react';
import { createSelectOptions } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { setTargetingRestrictions } from 'utils/targetingRestrictions';
import { TerritoryLabel } from 'utils/territory';

import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSwitch from '@material-ui/core/Switch';
import LaunchIcon from '@material-ui/icons/Launch';

import useStyles from './AdDetails.styles';

interface IAdFormProps {
  update?: boolean;
  campaignTags: Tag[];
  allTags: Tag[];
}

interface ITagInheritProps {
  campaignTags: Tag[];
}

export const TagInheritSwitch = (props: SwitchProps & ITagInheritProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const { campaignTags, ...pushThroughProps } = props;
  const onChange = React.useCallback(
    (event) => {
      if (event.target.checked) {
        setFieldValue(
          'inheritedTags',
          createSelectOptions(campaignTags).map((tag) => ({
            ...tag,
            readOnly: true,
          }))
        );
        setFieldValue('tags', []);
      } else {
        setFieldValue('inheritedTags', []);
        setFieldValue('tags', []);
      }
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name, campaignTags]
  );
  return (
    <MuiSwitch
      {...fieldToSwitch(pushThroughProps)}
      onChange={onChange}
      color="primary"
    />
  );
};

const AdDetails = (props: IFormProps<IAdValues> & IAdFormProps) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    campaignTags,
    allTags,
    update = false,
    values,
    status = {},
  } = props;
  const classes = useStyles();

  const handleCampaignRestrictionChange = ({
    target: { value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setTargetingRestrictions(
      value as TargetingRestriction,
      checked,
      setFieldValue,
      values.targetingRestrictions
    );
  };

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Campaign Name"
              placeholder="Campaign Name"
              name="campaignName"
              data-tc="campaignName"
              fullWidth
              disabled
              id="campaignName"
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/campaign/${values.campaignId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="adCampaignLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.campaignName}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Ad Name"
            placeholder="Ad Name"
            name="name"
            autoFocus={!update}
            data-tc="adName"
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            inputProps={{ 'data-testid': 'adName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>

        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Ad ID"
              placeholder="Ad ID"
              name="altId"
              data-tc="adId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={values.startDate}
              label="Ad Start Date"
              name="startDate"
              onChange={(value) => setFieldValue('startDate', value)}
              onClose={() => setFieldTouched('startDate', true)}
              data-tc="adStartDate"
              disabled={values.startDateIsInherited}
            />
            {((touched.startDate && errors.startDate) || status.startDate) && (
              <FormHelperText error data-tc="adStartDateError">
                {errors.startDate || status.startDate}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={FieldSwitch}
                  type="checkbox"
                  name="startDateIsInherited"
                  data-tc="adInheritStartDateSwitch"
                  data-testid="adInheritStartDateSwitch"
                  fieldToUpdate="startDate"
                  defaultValueOnToggle={values.campaignStartDate}
                />
              }
              label="Inherit from Campaign"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={values.endDate}
              label="Ad End Date"
              name="endDate"
              onChange={(value) => setFieldValue('endDate', value)}
              onClose={() => setFieldTouched('endDate', true, false)}
              disabled={values.endDateIsInherited}
              data-tc="adEndDate"
            />
            {((touched.endDate && errors.endDate) ||
              (touched.startDate && errors.endDate) ||
              status.endDate) && (
              <FormHelperText error data-tc="adEndDateError">
                {errors.endDate || status.endDate}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={FieldSwitch}
                  type="checkbox"
                  name="endDateIsInherited"
                  data-tc="adNoEndDate"
                  fieldToUpdate="endDate"
                  defaultValueOnToggle={null}
                />
              }
              label="Inherit from Campaign"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="adDailyCap"
            decimalScale={0}
            label="Daily Impression Cap"
            name="dailyCap"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            value={values.dailyCap || status.dailyCap}
            fullWidth
            error={errors.dailyCap || status.dailyCap}
            touched={touched.dailyCap || !!status.dailyCap}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <TargetingRestrictions
            title="Campaign Restriction (only applicable for Targeting 2.0)"
            handleChange={handleCampaignRestrictionChange}
            className={classes.campaignRestrictionsWrapper}
            selectedRestrictions={values.targetingRestrictions}
          />
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            name="duration"
            label="Ad Duration (Seconds)"
            value={values.duration}
            allowNegative={false}
            dataTc="adDurationField"
            decimalScale={3}
            disabled={values.audioCreatives && values.audioCreatives.length > 0}
            thousandSeparator={ThousandSeparatorTypes.comma}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.duration || !!status.duration}
            error={errors.duration || status.duration}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.campaignCpm}
            allowNegative={false}
            dataTc="adCampaignCpm"
            decimalScale={2}
            name="campaignCpm"
            touched={touched.campaignCpm}
            error={errors.campaignCpm}
            thousandSeparator={ThousandSeparatorTypes.comma}
            label="Campaign CPM"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="Ad Weight"
            placeholder="Ad Weight"
            name="weighting"
            data-tc="adWeighting"
            type="number"
            fullWidth
            helperText={
              (touched.weighting && errors.weighting) || status.weighting
            }
            FormHelperTextProps={{
              error: !!(
                (touched.weighting && errors.weighting) ||
                status.weighting
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <InputLabel shrink>Frequency Capping</InputLabel>
            <div className={classes.frequencyCap}>
              <Field
                component={TextField}
                type="number"
                name="frequencyCapCount"
                data-tc="adFrequencyCapCount"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <span className={classes.impressions}>impressions per</span>
              <Field
                component={TextField}
                name="frequencyCapValue"
                data-tc="adFrequencyCapValue"
                type="number"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <Field
                component={TextField}
                select
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  },
                }}
                name="frequencyCapTimeUnit"
                data-tc="adFrequencyCapTimeUnit"
                classes={{ root: classes.selectFrequencyCap }}
              >
                {FrequencyCapTimeUnits.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </div>
            {(errors.frequencyCapCount || status.frequencyCapCount) && (
              <FormHelperText error data-tc="adFrequencyCapCountError">
                {errors.frequencyCapCount || status.frequencyCapCount}
              </FormHelperText>
            )}
            {(errors.frequencyCapValue || status.frequencyCapValue) && (
              <FormHelperText error data-tc="adFrequencyCapValueError">
                {errors.frequencyCapValue || status.frequencyCapValue}
              </FormHelperText>
            )}
            {(errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit) && (
              <FormHelperText error data-tc="adFrequencyCapTimeUnitError">
                {errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="adExclusive"
                  color="primary"
                  data-tc="adExclusive"
                />
              }
              label="Make Ad Exclusive"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            data-tc="adTagsMultiSelect"
            data-testId="adTagsMultiSelect"
            id="adTagsMultiSelect"
            label="Reporting Tags"
            isMulti
            maxSelectHeight={250}
            name="tags"
            noMargin
            onBlur={() => setFieldTouched('tags', true)}
            onChange={(fieldValue: any) => {
              setFieldValue(
                'tags',
                fieldValue.filter(
                  (val: { value: string; label: string; readOnly: boolean }) =>
                    !val.readOnly
                )
              );
            }}
            options={
              values.tagsAreInherited
                ? createSelectOptions(allTags)
                : createSelectOptions(campaignTags)
            }
            placeholder="Select tags..."
            value={[
              ...values.inheritedTags.map((tag) => ({
                ...tag,
                readOnly: true,
              })),
              ...values.tags,
            ]}
            attributes={{
              fieldAttribute: 'adTagsField',
              menuAttribute: 'adTagsMenuOptions',
              chipAttribute: 'adTagChip',
            }}
            errorProps={{
              FormHelperTextProps: {
                error: false,
              },
            }}
          />
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={TagInheritSwitch}
                  type="checkbox"
                  name="tagsAreInherited"
                  data-tc="tagsAreInherited"
                  campaignTags={campaignTags}
                />
              }
              label="Inherit reporting tags"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default AdDetails;
