import { ReactComponent as ClearIcon } from 'assets/icons/TargetingV2/dax-cross.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import classNames from 'classnames';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import { IContextMenuItemType } from 'features/targetingV2/components/core/IconButtonMenuItem/IconButtonMenuItem';
import ParameterSelect from 'features/targetingV2/components/ParameterSelect/ParameterSelect';
import { IOptionType, SectionType } from 'features/targetingV2/types/common';
import {
  IParameter,
  IParameterPosition,
} from 'features/targetingV2/types/targeting';
import { getClusivityIcon } from 'features/targetingV2/utils/clusivity';
import {
  getParameterLabel,
  hasPairedParameterChanged,
} from 'features/targetingV2/utils/common';
import {
  defaultParameter,
  getDefaultPairedParameter,
} from 'features/targetingV2/utils/defaults';
import {
  getOppositeClusivity,
  getPairedParameters,
} from 'features/targetingV2/utils/pairedParameters';
import { Territory } from 'interfaces/generated.types';
import { capitalize } from 'lodash';
import React from 'react';

import ClusivityAndValuesSelect from './ClusivityAndValuesSelect/ClusivityAndValuesSelect';
import useStyles from './ParameterRowWithDoubleCardinality.styles';

export interface IParameterRowWithDoubleCardinalityProps {
  sectionType: SectionType;
  groupFieldName: string;
  parameterIndex: number;
  allSelectedParameters: IParameter[];
  parameterTypeOptions: IOptionType[];
  areParameterTypeOptionsLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  removeParameter: (index: number) => void;
  insertParameter: (index: number, value: any) => void;
  isParameterMandatory: boolean;
  parameterRowId: string;
  onParameterTypeChange: (index: number) => void;
  parameterValuesDropdownPlaceholder?: string;
  idUsedToLimitParameterValues?: string;
  isParameterSelectReadOnly?: boolean;
  territory?: Territory;
  OptionalAccuracyRow?: React.ElementType<any>;
  additionalMenuOptions?: IContextMenuItemType[];
}

const ParameterRowWithDoubleCardinality = (
  props: IParameterRowWithDoubleCardinalityProps
) => {
  const {
    sectionType,
    groupFieldName,
    parameterIndex,
    allSelectedParameters,
    parameterTypeOptions,
    areParameterTypeOptionsLoading,
    setFieldValue,
    removeParameter,
    insertParameter,
    onParameterTypeChange,
    parameterValuesDropdownPlaceholder = '',
    idUsedToLimitParameterValues = '',
    isParameterSelectReadOnly = false,
    territory,
    isParameterMandatory,
    parameterRowId,
    OptionalAccuracyRow,
    additionalMenuOptions = [],
  } = props;
  const classes = useStyles();

  const parameterType = allSelectedParameters[parameterIndex].type;

  const pairedParameters = getPairedParameters(
    allSelectedParameters,
    parameterType
  );

  const handleParameterTypeChange = () => {
    onParameterTypeChange(pairedParameters[0].index);

    if (pairedParameters.length > 1) {
      removeParameter(pairedParameters[1].index);
    }
  };

  const shouldRenderRowForPairedParameter =
    parameterIndex === pairedParameters[0].index;

  const oppositeClusivity = getOppositeClusivity(
    allSelectedParameters[parameterIndex].clusivity
  );

  const handlePairedParameterAddition = () => {
    insertParameter(
      parameterIndex + 1,
      getDefaultPairedParameter(
        allSelectedParameters[parameterIndex].type,
        oppositeClusivity
      )
    );
  };

  const clearParameterRow = (index: number) => {
    if (pairedParameters.length === 2) {
      setFieldValue(
        `${groupFieldName}.parameters[${index}].values`,
        defaultParameter.values
      );
    } else onParameterTypeChange(index);
  };

  const getContextMenuOptions = (index: number) => {
    const clusivityOptionLabel = `${capitalize(oppositeClusivity)} ${capitalize(
      parameterType
    )}`;

    const clusivityOption = {
      MenuItemIcon: getClusivityIcon(oppositeClusivity, classes),
      menuItemIconLabel: clusivityOptionLabel,
      menuItemAction: handlePairedParameterAddition,
    };

    const pairedParameter = pairedParameters[index];

    const removeOption = {
      MenuItemIcon: <RemoveIcon />,
      menuItemIconLabel: 'Remove',
      menuItemAction: () => removeParameter(pairedParameter.index),
    };

    const clearOption = {
      MenuItemIcon: <ClearIcon />,
      menuItemIconLabel: 'Clear',
      menuItemAction: () => clearParameterRow(pairedParameter.index),
    };

    const contextMenuOptions = [
      ...(pairedParameters.length === 1 ? [clusivityOption] : []),
      ...additionalMenuOptions,
      ...(hasPairedParameterChanged(
        pairedParameter.parameter,
        pairedParameters.length
      )
        ? [clearOption]
        : []),
      ...(isParameterMandatory ? [] : [removeOption]),
    ];

    return contextMenuOptions;
  };

  return (
    <>
      {shouldRenderRowForPairedParameter ? (
        <div className={classes.container}>
          {pairedParameters.map(
            (pairedParameter: IParameterPosition, index: number) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${groupFieldName}-parameter[${pairedParameters[index].index}]`}
                className={classNames([
                  classes.row,
                  {
                    [`${classes.row}--second`]: index === 1,
                  },
                ])}
                data-testid={`${groupFieldName}.parameters[${pairedParameters[index].index}]`}
              >
                <ParameterSelect
                  sectionType={sectionType}
                  options={parameterTypeOptions}
                  areOptionsLoading={areParameterTypeOptionsLoading}
                  fieldName={`${groupFieldName}.parameters[${pairedParameters[index].index}]`}
                  fieldValue={pairedParameter.parameter.type}
                  setFieldValue={setFieldValue}
                  customOnChange={handleParameterTypeChange}
                  isVisible={index !== 1}
                  readOnly={isParameterSelectReadOnly}
                />
                <ClusivityAndValuesSelect
                  sectionType={sectionType}
                  groupFieldName={groupFieldName}
                  pairedParameterIndex={index}
                  pairedParameters={pairedParameters}
                  pairedParameterLabel={getParameterLabel(
                    parameterTypeOptions,
                    pairedParameters[index].parameter
                  )}
                  setFieldValue={setFieldValue}
                  parameterValuesDropdownPlaceholder={
                    parameterValuesDropdownPlaceholder
                  }
                  showAccuracy={!!OptionalAccuracyRow}
                  idUsedToLimitParameterValues={idUsedToLimitParameterValues}
                  territory={territory}
                />
                <IconButtonMenu
                  menuIconButtonLabel={parameterRowId}
                  menuOptions={getContextMenuOptions(index)}
                />
              </div>
            )
          )}
          {OptionalAccuracyRow && (
            <OptionalAccuracyRow
              externalClasses={
                pairedParameters.length > 1
                  ? [`${classes.accuracyContainer}--withBothClusivity`]
                  : [`${classes.accuracyContainer}--withOneClusivity`]
              }
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default ParameterRowWithDoubleCardinality;
