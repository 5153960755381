import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import CreateAdContainer from 'features/direct/ad/containers/CreateAdContainer/CreateAdContainer';
import { History } from 'history';
import { Campaign, useCampaignForAdQuery } from 'interfaces/generated.types';
import React from 'react';

interface ICreateAdPage {
  history: History;
  match: {
    params: {
      orderId: string;
      campaignId: string;
    };
  };
}

const CreateAdPage = ({ history, match }: ICreateAdPage) => {
  const {
    loading: loadingCampaign,
    data: campaignData,
    error,
  } = useCampaignForAdQuery({
    variables: { id: match.params.campaignId },
  });

  if (!loadingCampaign && (error || !(campaignData && campaignData.campaign)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Ad</h1>
        {loadingCampaign ? (
          <Loader />
        ) : (
          <CreateAdContainer
            match={match}
            history={history}
            campaign={campaignData?.campaign as Campaign}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateAdPage;
