import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { adStatusValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { campaignPacings } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import { History } from 'history';
import { Ad, AdStatus, Pacing, Territory } from 'interfaces/generated.types';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue, removeEmailDomain } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

interface ILinkedAdsRow {
  id: string;
  altId: number;
  name: string;
  campaignName: string;
  campaignId: string;
  pacing: string;
  owner: string;
  startDate: string;
  endDate: string;
  status: AdStatus;
}

export const formatLinkedAdsData = (ads: Ad[], territory: Territory) =>
  ads.map((ad) => ({
    id: ad.id,
    altId: ad.altId,
    name: ad.name as string,
    campaignName: ad.campaign?.name as string,
    campaignId: ad.campaign?.id,
    startDate: ad.startDate.value
      ? getFormattedDateBasedOnTerritory(
          dateUtils.getDateInSpecificTimezone(
            ad.startDate.value,
            ad.campaign?.timeZone
          ),
          territory
        )
      : '',
    endDate: ad.endDate.value
      ? getFormattedDateBasedOnTerritory(
          dateUtils.getDateInSpecificTimezone(
            ad.endDate.value,
            ad.campaign?.timeZone
          ),
          territory
        )
      : '',
    pacing: findLabelValue({
      collection: campaignPacings,
      lookupValue: ad.campaign?.pacing as Pacing,
    }),
    status: ad.status,
    owner: ad.campaign?.owner?.email
      ? removeEmailDomain(ad.campaign?.owner?.email)
      : '',
  }));

const LinkedAdsTable = ({ ads, history }: { ads: Ad[]; history: History }) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          minWidth: '140px',
        },
        Cell: ({ cell: { value, row } }: CellProps<ILinkedAdsRow>) =>
          TableLink({
            name: value,
            location: `/ad/${row.original.id}`,
          }),
      },
      {
        Header: 'Parent Campaign',
        accessor: 'campaignName',
        style: {
          wordBreak: 'break-word',
          minWidth: '140px',
        },
        Cell: ({ cell: { value, row } }: CellProps<ILinkedAdsRow>) =>
          TableLink({
            name: value,
            location: `/campaign/${row.original.campaignId}`,
          }),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
      },
      {
        Header: 'Pacing',
        accessor: 'pacing',
        Filter: TableSelectFilter,
        filter: includesSome,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        style: {
          wordBreak: 'break-word',
          width: '140px',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        Cell: ({ cell: { value } }: CellProps<ILinkedAdsRow>) =>
          findLabelValue({
            collection: adStatusValues,
            lookupValue: value,
          }),
      },
    ],
    []
  );
  return (
    <Table
      history={history}
      columns={columns}
      dataTc="listLinkedAdsTable"
      data={formatLinkedAdsData(ads, activeTerritory!)}
      isPageTable={false}
    />
  );
};

export default LinkedAdsTable;
