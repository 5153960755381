import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IAdvertiserValues } from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserFormValues';
import AdvertiserTabsForm, {
  advertiserButtons,
} from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserTabsForm';
import AdvertiserFormValidation from 'features/direct/advertiser/validations/advertisers';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Advertiser,
  Agency,
  CreateAdvertiserMutation,
  CreateAdvertiserMutationFn,
  useCreateAdvertiserMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface ICreateAdvertiserContainerProps {
  agency: Agency;
  history: History;
  match: {
    params: {
      agencyId: string;
    };
  };
}

export const handleOnComplete = (
  response: CreateAdvertiserMutation,
  history: History,
  agencyId: string,
  selectedJourney: string
) => {
  const { createAdvertiser } = response;
  const { id } = createAdvertiser as Advertiser;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: agencyId,
    listEntity: selectedJourney === '/agency/PARENT_ID' ? 'Advertiser' : '',
  });
};

export const submitCreateAdvertiser = (
  createAdvertiser: CreateAdvertiserMutationFn,
  agencyId: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IAdvertiserValues,
  { setStatus, setSubmitting }: FormikActions<IAdvertiserValues>
) => {
  setSelectedJourney(formValues.journey);
  const formattedValues = formatValues(formValues);
  const filteredValues: any = pickBy(
    {
      ...formValues,
      agencyName: '',
      agencyId,
      journey: null,
      domains: formattedValues.domains.length ? formattedValues.domains : null,
      generatedDomains: null,
      hasAdSeparation: null,
      adSeparation: formattedValues.adSeparation,
    },
    (value) => value === 0 || !!value
  );

  createAdvertiser({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateAdvertiserContainer = ({
  agency,
  match,
  history,
}: ICreateAdvertiserContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Advertiser.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createAdvertiser] = useCreateAdvertiserMutation({
    onCompleted(data) {
      handleOnComplete(data, history, match.params.agencyId, selectedJourney);
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IAdvertiserValues>
        initialValues={{
          name: '',
          id: '',
          agencyName: agency.name as string,
          agencyId: agency.id,
          externalId: '',
          externalSystemId: getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory(
            activeTerritory!
          ),
          domains: [''],
          journey: advertiserButtons[0].url,
          adSeparation: '90',
          hasAdSeparation: true,
          iabCategoryCodes: [],
          generatedDomains: '',
          territory: agency.territory,
        }}
        validationSchema={AdvertiserFormValidation}
        onSubmit={submitCreateAdvertiser(
          createAdvertiser,
          match.params.agencyId,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={(props) => (
          <AdvertiserTabsForm
            {...props}
            goBackTo={{
              pathname: `/agency/${match.params.agencyId}`,
              state: location.state?.parent || {},
            }}
            history={history}
          />
        )}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createAdvertiser"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateAdvertiserContainer;
