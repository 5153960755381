import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';

import GetAppIcon from '@material-ui/icons/GetApp';

import StyledButton, {
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import {
  HttpMethod,
  useGeneratePresignedUrlQuery,
} from 'interfaces/generated.types';

import { getCreativeFileName } from 'utils/urls';

interface IState {
  url: string;
  name: string;
}

const DownloadCreativeButton = ({ url, name }: IState) => {
  const [count, setCount] = useState(0);
  const { loading, data, refetch } = useGeneratePresignedUrlQuery({
    variables: {
      fileName: getCreativeFileName(url),
      httpMethod: HttpMethod.Get,
    },
    skip: count === 0,
  });
  const preSignedUrl = data?.generatePreSignedUrl?.url || '';

  useEffect(() => {
    if (count > 0) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (!loading && preSignedUrl && name) {
      FileSaver.saveAs(preSignedUrl, name);
    }
  }, [loading, preSignedUrl, name]);

  return (
    <StyledButton
      type="button"
      data-tc="creativeDownload"
      variant={ButtonVariantEnum.Text}
      onClick={() => setCount((prevState) => prevState + 1)}
    >
      <GetAppIcon />
      <span className="sr-only">Download Creative</span>
    </StyledButton>
  );
};

export default DownloadCreativeButton;
