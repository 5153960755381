import GlobalSearch from 'components/Navigation/GlobalSearch/GlobalSearch';
import NavigationDropdown from 'components/Navigation/NavigationDropdown/NavigationDropdown';
import TerritoryModal from 'components/Territory/TerritoryModal/TerritoryModal';
import { PermissionName } from 'interfaces/generated.types';
import {
  ILinkConfig,
  INavigationLink,
  INavigationLinks,
  INavigationOption,
} from 'interfaces/navigation';
import React from 'react';
import {
  getPairCombinationsFromPermissionsList,
  permissionExistsInList,
  userHasAllPermissions,
  userHasAtLeastOneCombinationOfPermissions,
  userHasAtLeastOnePermission,
} from 'utils/permissions';

const directPermissions = [
  PermissionName.Primary,
  PermissionName.Direct,
  PermissionName.DirectRead,
];

const forecastingPermissions = [
  PermissionName.Primary,
  PermissionName.Forecasting,
];

const directOptionLinksConfig: ILinkConfig[] = [
  {
    link: { title: 'Agencies', url: '/agencies' },
    allowedPermissions: directPermissions,
  },
  {
    link: { title: 'Orders', url: '/orders' },
    allowedPermissions: directPermissions,
  },
  {
    link: { title: 'Campaigns', url: '/campaigns' },
    allowedPermissions: directPermissions,
  },
  {
    link: { title: 'Creatives', url: '/creatives' },
    allowedPermissions: directPermissions,
  },
  {
    link: { title: 'Forecasting', url: '/forecasting' },
    allowedPermissions: forecastingPermissions,
  },
];

export const getOptionLinksBasedOnPermissions = (
  optionTitle: string,
  userPermissions: PermissionName[]
) => {
  let optionLinksConfig: ILinkConfig[] = [];

  switch (optionTitle) {
    case 'Direct':
      optionLinksConfig = directOptionLinksConfig;
      break;
    default:
      optionLinksConfig = [];
  }

  return optionLinksConfig.reduce(
    (result: INavigationLink[], currentLinkConfig) =>
      userHasAtLeastOnePermission(
        userPermissions,
        currentLinkConfig.allowedPermissions
      )
        ? result.concat(currentLinkConfig.link)
        : result,
    []
  );
};

export const getAllNavigationOptionsConfig = (
  userPermissions: PermissionName[]
): INavigationLinks => ({
  Direct: {
    links: getOptionLinksBasedOnPermissions('Direct', userPermissions),
    allowedPermissions: [...directPermissions, ...forecastingPermissions],
  },
  Programmatic: {
    links: [
      { title: 'Sales Channels', url: '/sales-channels' },
      { title: 'Active Deals', url: '/active-deals' },
      { title: 'Programmatic Connectors', url: '/programmatic-connectors' },
      { title: 'Creatives', url: '/creative-qa' },
    ],
    allowedPermissions: [
      PermissionName.Primary,
      PermissionName.Programmatic,
      PermissionName.ProgrammaticRead,
    ],
  },
  Supply: {
    links: [
      { title: 'Networks', url: '/networks' },
      { title: 'Channels', url: '/channels' },
    ],
    allowedPermissions: [
      PermissionName.Primary,
      PermissionName.Supply,
      PermissionName.SupplyRead,
    ],
  },
  Insights: {
    links: [{ title: 'Monetize', url: '/monetize' }],
    allowedPermissions: [
      PermissionName.PublisherInsightsTier1,
      PermissionName.PublisherInsightsTier2,
    ],
  },
  Settings: {
    links: [
      { title: 'Users', url: '/users' },
      { title: 'Targeting Templates', url: '/targeting-templates' },
      { title: 'Targeting Packs', url: '/targeting-packs' },
      { title: 'Primary Controls', url: '/primary-controls' },
      { title: 'Reporting Tags', url: '/reporting-tags' },
    ],
    allowedPermissions: [PermissionName.Primary],
  },
});

export type NavigationOptionTitle =
  | 'Direct'
  | 'Programmatic'
  | 'Supply'
  | 'Insights'
  | 'Settings';

export const getNavigationOptionConfig = (
  optionTitle: NavigationOptionTitle,
  userPermissions: PermissionName[]
): INavigationOption | null => {
  const optionConfig =
    getAllNavigationOptionsConfig(userPermissions)[optionTitle];

  if (
    userHasAtLeastOnePermission(
      userPermissions,
      optionConfig.allowedPermissions
    )
  ) {
    return {
      title: optionTitle,
      links: optionConfig.links,
    };
  }

  return null;
};

const navigationOptionsOrder: NavigationOptionTitle[] = [
  'Direct',
  'Programmatic',
  'Supply',
  'Insights',
];

export const getNavigationOptions = (
  userPermissions: PermissionName[]
): INavigationOption[] =>
  navigationOptionsOrder.reduce(
    (acc: INavigationOption[], curr: NavigationOptionTitle) => {
      const optionConfig = getNavigationOptionConfig(curr, userPermissions);
      return optionConfig ? acc.concat(optionConfig) : acc;
    },
    []
  );

export const getSearchOption = (userPermissions: PermissionName[]) => {
  const permissionsWithoutSearchOption = [
    PermissionName.Forecasting,
    PermissionName.PublisherInsightsTier1,
    PermissionName.PublisherInsightsTier2,
    PermissionName.Experimental,
  ];

  const permissionCombinationsWithoutSearchOption =
    getPairCombinationsFromPermissionsList(permissionsWithoutSearchOption);

  if (
    !userPermissions.length ||
    (userPermissions.length === 1 &&
      userHasAtLeastOnePermission(
        userPermissions,
        permissionsWithoutSearchOption
      )) ||
    (userPermissions.length === 2 &&
      userHasAtLeastOneCombinationOfPermissions(
        userPermissions,
        permissionCombinationsWithoutSearchOption
      )) ||
    (userPermissions.length === 3 &&
      userHasAllPermissions(userPermissions, [
        PermissionName.Forecasting,
        PermissionName.PublisherInsightsTier1,
        PermissionName.Experimental,
      ]))
  )
    return null;

  return <GlobalSearch />;
};

export const getTerritoryOption = (userPermissions: PermissionName[]) => {
  const permissionsWithoutTerritoryOption = [
    PermissionName.PublisherInsightsTier1,
    PermissionName.PublisherInsightsTier2,
  ];

  if (
    !userPermissions.length ||
    (userPermissions.length === 1 &&
      userHasAtLeastOnePermission(userPermissions, [
        ...permissionsWithoutTerritoryOption,
        PermissionName.Experimental,
      ])) ||
    (userPermissions.length === 2 &&
      permissionExistsInList(userPermissions, PermissionName.Experimental) &&
      userHasAtLeastOnePermission(
        userPermissions,
        permissionsWithoutTerritoryOption
      ))
  )
    return null;

  return <TerritoryModal />;
};

export const getSettingsOption = (
  userPermissions: PermissionName[],
  hasAutoLeftMargin: boolean = false
) => {
  const settingsOptionConfig = getNavigationOptionConfig(
    'Settings',
    userPermissions
  );
  return (
    <NavigationDropdown
      title="Settings"
      links={[
        ...(settingsOptionConfig ? settingsOptionConfig.links : []),
        { title: 'Log Out', url: '/log-out' },
      ]}
      position={hasAutoLeftMargin ? 'autoLeftMargin' : 'positionRight'}
    />
  );
};
