import React from 'react';

import useStyles from './Container.styles';

const Container = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

export default Container;
