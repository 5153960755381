import classNames from 'classnames';
import FormControl from 'features/targetingV2/components/core/FormControl';
import MenuItem from 'features/targetingV2/components/core/MenuItem';
import Select from 'features/targetingV2/components/core/Select';
import parameterSelectStyles from 'features/targetingV2/components/ParameterSelect/ParameterSelect.styles';
import { IOption } from 'features/targetingV2/types/common';
import {
  clusivityOptions,
  getClusivityIcon,
} from 'features/targetingV2/utils/clusivity';
import { Clusivity } from 'interfaces/generated.types';
import React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './ClusivitySelect.styles';

interface IClusivitySelectProps {
  selectedClusivity: Clusivity;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
}
const ClusivitySelect = (props: IClusivitySelectProps) => {
  const classes = { ...parameterSelectStyles(), ...useStyles() };

  const { selectedClusivity, setFieldValue, fieldName } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFieldValue(fieldName, event.target.value);
  };

  return (
    <FormControl component={'fieldset' as 'div'} className={classes.section}>
      <Select
        data-testid={`clusivitySelect-${fieldName}`}
        name={fieldName}
        value={selectedClusivity}
        onChange={handleChange}
        IconComponent={ExpandMore}
        inputProps={{
          classes: {
            root: classNames(classes.dropdown, classes.dropdownBorder),
            select: classes.select,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {clusivityOptions.map((option: IOption<Clusivity>) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
          >
            <span className={classes.dropdownIcon}>
              {getClusivityIcon(option.value, classes)}
            </span>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClusivitySelect;
