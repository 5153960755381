import { createStyles, makeStyles } from '@material-ui/core/styles';

export const baseFontStyles = () =>
  createStyles({
    italic: {
      fontStyle: 'italic',
    },
  });

const fontStyles = makeStyles(() => ({
  ...baseFontStyles(),
}));

export default fontStyles;
