import targetingTooltipStyles from 'features/targetingV2/styles/targetingTooltip.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...targetingTooltipStyles(theme),
  restrictionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    marginLeft: 'auto',
  },
  restriction: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1.25),
    },
  },
  restrictionLabel: {
    fontWeight: 500,
  },
}));

export default useStyles;
