import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IAgencyValues } from 'features/direct/agency/components/AgencyTabsForm/AgencyFormValues';
import AgencyForm, {
  agencyButtons,
} from 'features/direct/agency/components/AgencyTabsForm/AgencyTabsForm';
import AgencyFormValidation from 'features/direct/agency/validations/agencies';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  Agency,
  CreateAgencyMutation,
  CreateAgencyMutationFn,
  useCreateAgencyMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface ICreateAgencyContainerProps {
  history: History;
}

export const handleOnComplete = (
  response: CreateAgencyMutation,
  history: History,
  selectedJourney: string
) => {
  const { createAgency } = response;
  const { id } = createAgency as Agency;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitCreateAgency = (
  createAgency: CreateAgencyMutationFn,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IAgencyValues,
  { setStatus, setSubmitting }: FormikActions<IAgencyValues>
) => {
  setSelectedJourney(formValues.journey);

  createAgency({
    variables: pickBy({
      ...formValues,
      journey: null,
    }),
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateAgencyContainer = ({ history }: ICreateAgencyContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Agency.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createAgency] = useCreateAgencyMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney);
    },
  });

  return (
    <>
      <Formik<IAgencyValues>
        initialValues={{
          name: '',
          id: '',
          externalId: '',
          externalSystemId: getDefaultExternalSystemIdAdvertiserAndAgencyBasedOnTerritory(
            activeTerritory!
          ),
          journey: agencyButtons[0].url,
          territory: activeTerritory,
        }}
        validationSchema={AgencyFormValidation}
        onSubmit={submitCreateAgency(
          createAgency,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={AgencyForm}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createAgency"
        errorMessages={errorMessages}
      />
    </>
  );
};
export default CreateAgencyContainer;
