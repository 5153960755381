import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  TargetingTemplate,
  TargetingTemplateStatus,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';

import { targetingTemplateStatusValues } from '../TargetingTemplateTabsForm/TargetingTemplateFormValues';

interface ITargetingTemplatesRow {
  id: string;
  name: string;
  description: string;
  status: TargetingTemplateStatus;
  isMasterTemplate: string;
}

export interface ITargetingTemplatesTableProps {
  allTargetingTemplates: TargetingTemplate[];
  history: History;
}

export const formatData = memoizeOne((data: TargetingTemplate[]) =>
  data.map((d) => ({
    id: d.id,
    name: d.name,
    description: d.description,
    status: d.status,
    isMasterTemplate: d.isMasterTemplate ? 'Yes' : 'No',
  }))
);

const TargetingTemplatesTable = ({
  allTargetingTemplates,
  history,
}: ITargetingTemplatesTableProps) => {
  const location = usePreviousLocation();

  const createTargetingTemplateCta = (
    <StyledLink
      location={{
        pathname: '/targeting-template',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createTargetingTemplateButton"
    >
      Create Targeting Template
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<ITargetingTemplatesRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/targeting-template/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          maxWidth: '500px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      {
        Header: 'Primary Template',
        accessor: 'isMasterTemplate',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        Cell: ({ cell: { value } }: CellProps<ITargetingTemplatesRow>) =>
          findLabelValue({
            collection: targetingTemplateStatusValues,
            lookupValue: value,
          }),
      },
    ],
    [location.state]
  );

  return (
    <Table
      history={history}
      title="All Targeting Templates"
      columns={columns}
      dataTc="listTargetingTemplatesTable"
      data={formatData(allTargetingTemplates)}
      customToolbarCtas={createTargetingTemplateCta}
    />
  );
};

export default TargetingTemplatesTable;
