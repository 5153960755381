import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ParameterRowContainer from 'features/targetingV2/components/ParameterRowContainer/ParameterRowContainer';
import useStyles from 'features/targetingV2/styles/targetingGroup.styles';
import { SectionType } from 'features/targetingV2/types/common';
import {
  IParameter,
  IParameterGroup,
} from 'features/targetingV2/types/targeting';
import { hasInvalidParameters } from 'features/targetingV2/utils/common';
import { defaultParameter } from 'features/targetingV2/utils/defaults';
import { getAvailableParameterOptionsBasedOnCardinality } from 'features/targetingV2/utils/targeting';
import { FieldArray } from 'formik';
import { Territory } from 'interfaces/generated.types';
import React from 'react';

export interface IInventoryProps {
  inventory: IParameterGroup;
  inventoryIndex: number;
  inventoryFieldName: string;
  allParametersInfo: any[];
  areParameterTypeOptionsLoading: boolean;
  removeInventory: (inventoryIndex: number) => void;
  setFieldValue: (field: string, value: any) => void;
  territory?: Territory;
}

const Inventory = (props: IInventoryProps) => {
  const targetingClasses = useStyles();

  const {
    inventory,
    inventoryIndex,
    inventoryFieldName,
    allParametersInfo,
    areParameterTypeOptionsLoading,
    removeInventory,
    setFieldValue,
    territory,
  } = props;

  const getAvailableParametersOptions = (parameterType?: string) =>
    getAvailableParameterOptionsBasedOnCardinality(
      allParametersInfo,
      inventory.parameters,
      parameterType
    );

  const availableParameterOptions = getAvailableParametersOptions();
  const isAddButtonDisabled = hasInvalidParameters(inventory.parameters);

  return (
    <div
      className={targetingClasses.container}
      data-testid={inventoryFieldName}
    >
      <FieldArray name={`${inventoryFieldName}.parameters`}>
        {({
          push: pushParameter,
          remove: removeParameter,
          insert: insertParameter,
        }) => (
          <>
            {inventory.parameters.map(
              (parameter: IParameter, parameterIndex: number) => (
                <ParameterRowContainer
                  // eslint-disable-next-line react/no-array-index-key
                  key={`inventory${inventoryIndex}-parameter${parameterIndex}`}
                  sectionType={SectionType.Inventory}
                  groupFieldName={inventoryFieldName}
                  parameterIndex={parameterIndex}
                  removeParameter={removeParameter}
                  insertParameter={insertParameter}
                  removeSection={() => removeInventory(inventoryIndex)}
                  parameterTypeOptions={getAvailableParametersOptions(
                    parameter.type
                  )}
                  areParameterTypeOptionsLoading={
                    areParameterTypeOptionsLoading
                  }
                  allSelectedParameters={inventory.parameters}
                  allParametersInfo={allParametersInfo}
                  setFieldValue={setFieldValue}
                  territory={territory}
                />
              )
            )}
            {inventory.parameters.length && availableParameterOptions.length ? (
              <AddParameterDropdownButton
                options={availableParameterOptions}
                onOptionClick={(parameterType: string) => {
                  pushParameter({
                    ...defaultParameter,
                    type: parameterType,
                    isFocused: true,
                  });
                }}
                disabled={isAddButtonDisabled}
                loading={areParameterTypeOptionsLoading}
              />
            ) : null}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Inventory;
