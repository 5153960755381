import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreateSalesChannelContainer from 'features/programmatic/salesChannel/containers/CreateSalesChannelContainer/CreateSalesChannelContainer';
import { History } from 'history';
import {
  Agency,
  PermissionName,
  useAllAgenciesMinimalQuery,
  useAllUsersQuery,
  User,
} from 'interfaces/generated.types';
import React from 'react';
import { allTerritories } from 'utils/territory';

interface CreateSalesChannelPageProps {
  history: History;
  currentUser: User;
}

const CreateSalesChannelPage = ({
  history,
  currentUser,
}: CreateSalesChannelPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { loading, data, error } = useAllAgenciesMinimalQuery({
    variables: { territories: [activeTerritory!] },
  });

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Programmatic, PermissionName.Primary],
      territories: allTerritories,
    },
  });

  if (!loading && (error || !(data && data.allAgencies)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Sales Channel</h1>

        {loading || loadingAllUsers ? (
          <Loader />
        ) : (
          <CreateSalesChannelContainer
            history={history}
            allAgencies={data?.allAgencies as Agency[]}
            allUsers={(allUsersData?.allUsers as User[]) || []}
            currentUser={currentUser}
          />
        )}
      </Container>
    </main>
  );
};
export default CreateSalesChannelPage;
