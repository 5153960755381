import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  appbar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    padding: '0',
    width: '100%',
    alignItems: 'center',
  },
  logo: {
    display: 'inline-block',
    maxWidth: theme.spacing(10.75),
    margin: theme.spacing(0, 4, 0, 0),
    lineHeight: theme.spacing(2),
    paddingTop: theme.spacing(0.375),
  },
}));

export default useStyles;
