import { ReactComponent as FilterIcon } from 'assets/icons/dax-filtericon.svg';
import { endOfDay, startOfDay } from 'date-fns';
import memoizeOne from 'memoize-one';
import React, { useState } from 'react';
import { ColumnInstance, Row } from 'react-table';
import dateUtils from 'utils/date';

import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import DateRangePickerDialog from '../DateTimePicker/DateRangePickerDialog';
import { useDateRangeInputFilterStyles } from './Table.styles';
import { parseDate } from './TableSort';

export interface TableDateRangeFilterProps {
  column: ColumnInstance;
  disablePast?: boolean;
  'data-tc'?: string;
}

export const dateWithinRange = memoizeOne(
  (rows: Row[], ids: string[], filterValues: Date[] | undefined) => {
    if (filterValues && filterValues.length === 2) {
      return rows.filter((row) => {
        const rowValue = row.values[ids[0]];

        if (!rowValue) return false;

        const rowDate = parseDate(rowValue);

        return dateUtils.isDateInRange(
          rowDate,
          filterValues[0],
          filterValues[1]
        );
      });
    }
    return rows;
  }
);

const TableDateRangeFilter = ({
  column,
  disablePast = false,
  'data-tc': dataTc,
}: TableDateRangeFilterProps) => {
  const classes = useDateRangeInputFilterStyles();

  const [open, setOpen] = useState(false);

  const renderedValue = column.filterValue ? '1 filter' : '0 filters';

  const initialFilterRange = column.filterValue
    ? {
        startDate: column.filterValue[0],
        endDate: column.filterValue[1],
      }
    : undefined;

  const handleChange = (selectedStartDate: Date, selectedEndDate: Date) => {
    column.setFilter([
      startOfDay(selectedStartDate),
      endOfDay(selectedEndDate),
    ]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    column.setFilter(undefined);
  };

  return (
    <>
      <TextField
        data-tc={dataTc}
        value={renderedValue}
        onClick={() => setOpen(true)}
        InputProps={{
          classes,
          startAdornment: (
            <InputAdornment position="start">
              <FilterIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <DateRangePickerDialog
        isClearable
        title="Date range filter"
        initialRange={initialFilterRange}
        open={open}
        onClose={handleClose}
        onRangeChange={handleChange}
        onRangeClear={handleClear}
        disablePast={disablePast}
        data-tc={`${dataTc}-dialog`}
      />
    </>
  );
};

export default TableDateRangeFilter;
