import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  targetingMessageWrapper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default useStyles;
