import { TargetingRestriction } from 'interfaces/generated.types';

// eslint-disable-next-line import/prefer-default-export
export const restrictionsTooltipContent = {
  [TargetingRestriction.Local]: {
    content: [
      'Global is not licensed to sell Communicorp UK supplies locally. This restriction excludes this inventory from local campaigns.',
    ],
  },
  [TargetingRestriction.CapSensitive]: {
    content: [
      'These restrictions are protecting children from adverts that are not suitable for all age groups. This could be about gambling, alcohol or any other topic that is deemed age-sensitive.',
    ],
  },
  [TargetingRestriction.Liid]: {
    content: [
      'Listener Insights ID (LIID) is used to remove inventory where we cannot track individual users.',
    ],
  },
  [TargetingRestriction.Hfss]: {
    content: [
      'High in Fat, Salt and Sugar (HFSS) rules aim to restrict the promotion of less healthy food and drink products for children to reduce obesity and improve health.',
    ],
  },
};
