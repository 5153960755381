import { endOfDay, format } from 'date-fns';
import { IForecastingValues } from 'features/forecasting/components/ForecastingDetails/ForecastingDetails';
import { targetingParameterClusivityLabelEnum } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import {
  ITargetingDefinitionV2,
  ITargetingV2Info,
} from 'features/targetingV2/types/targeting';
import { getCsvRowsFromTargetingV2 } from 'features/targetingV2/utils/forecastingExport';
import { formatTargetingDefinitionV2 } from 'features/targetingV2/utils/formValuesFormatting';
import {
  Ad,
  TargetingDefinition,
  TargetingVersion,
  Territory,
} from 'interfaces/generated.types';
import dateUtils, { TimeZones } from 'utils/date';

import {
  getTargetingRestrictionsAsString,
  transformIabCategoryOptions,
} from './dataTransformation';
import { getFormattedDateBasedOnTerritory } from './defaultsByTerritory';
import {
  formatTargeting,
  groupTargetingGroups,
  IAudienceParams,
  ITargetingDefinition,
  ITargetingGroup,
  ITargetingMasterTemplate,
} from './targetingDefinitions';
import { getFormatAudienceParamFunction } from './targetingFormatting';

export const getCsvRowsFromTargetingGroup = (
  targetingGroup: ITargetingGroup,
  isGeneral: boolean = true,
  templateName: string = '',
  groupIndex: number = 0
) =>
  targetingGroup.audienceParams?.map((param: IAudienceParams) => {
    const formatAudienceParamFunction = getFormatAudienceParamFunction(param);
    const formattedAudienceParam = formatAudienceParamFunction
      ? formatAudienceParamFunction(param)
      : undefined;

    const groupName = templateName
      ? `${templateName} - Group ${groupIndex}`
      : `Group ${groupIndex}`;

    return {
      groupName: isGeneral ? 'General Targeting Group' : groupName,
      targetingParameterType: formattedAudienceParam?.label || '',
      logic: param.clusivity
        ? targetingParameterClusivityLabelEnum[
            param.clusivity as keyof typeof targetingParameterClusivityLabelEnum
          ]
        : '',
      values: formattedAudienceParam?.values
        ? `${formattedAudienceParam?.values}`
        : '',
      channels:
        targetingGroup?.channels?.map((channel) => channel.name).join(', ') ||
        '',
      totalChannelNumber: targetingGroup?.channels?.length || 0,
    };
  });

export const formatCsvRowsFromTargeting = (
  generalTargetingGroup: ITargetingGroup | null,
  targetingMasterTemplates: ITargetingMasterTemplate[],
  targetingGroups: ITargetingGroup[],
  mergedTargetingDefinition: TargetingDefinition | undefined
) => {
  const generalTargetingGroupRows = generalTargetingGroup
    ? getCsvRowsFromTargetingGroup(generalTargetingGroup)
    : [];

  const targetingTemplateGroupRows = targetingMasterTemplates.flatMap(
    (template: ITargetingMasterTemplate) =>
      template.groups.flatMap((group, index) =>
        group
          ? getCsvRowsFromTargetingGroup(
              group,
              false,
              template.masterName,
              index + 1
            )
          : []
      )
  );

  // We need to first re-structure the merged targeting definition received from the backend to the format
  // used by the UI components, so we can then use the formatTargetingGroupForCsv function.
  const formattedTargetingDefinition = mergedTargetingDefinition
    ? groupTargetingGroups(mergedTargetingDefinition as ITargetingDefinition)
    : { groups: [] };

  const effectiveTargetingRows = formattedTargetingDefinition.groups.flatMap(
    (group: ITargetingGroup, index: number) =>
      group
        ? getCsvRowsFromTargetingGroup(
            group as ITargetingGroup,
            false,
            '',
            index + 1
          )
        : []
  );

  const [groupsBasedOnTemplates, additionalGroups] = targetingGroups.reduce(
    ([basedOnTemplates, additional], group) =>
      group.templateName
        ? [[...basedOnTemplates, group], additional]
        : [basedOnTemplates, [...additional, group]],
    [[] as ITargetingGroup[], [] as ITargetingGroup[]]
  );

  const groupsBasedOnTemplatesRows = groupsBasedOnTemplates.flatMap(
    (group, index) =>
      group
        ? getCsvRowsFromTargetingGroup(
            group,
            false,
            group.templateName,
            index + 1
          )
        : []
  );

  const additionalGroupsRows = additionalGroups.flatMap((group, index) =>
    group ? getCsvRowsFromTargetingGroup(group, false, '', index + 1) : []
  );

  return {
    generalTargetingGroupRows,
    targetingTemplateGroupRows,
    targetingGroupsRows: [
      ...groupsBasedOnTemplatesRows,
      ...additionalGroupsRows,
    ],
    effectiveTargetingRows,
  };
};

export const formatForecastDataForCsvExport = (
  values: IForecastingValues,
  iabCategories: string[],
  mergedTargetingDefinition: TargetingDefinition | undefined,
  bookedImpressions: string,
  availableImpressions: string,
  deliveryStatus: string,
  dateOfForecastGeneration: Date | undefined,
  territory: Territory | undefined,
  targetingV2Info: ITargetingV2Info,
  errors: any
) => {
  const targetingMasterTemplateNames = values.targetingMasterTemplates.map(
    (template: ITargetingMasterTemplate) => template.masterName
  );

  const targetingNonMasterTemplateNames = values.targetingGroups.map(
    (group) => group.templateName
  );

  const targetingTemplateNames = [
    ...targetingMasterTemplateNames,
    ...targetingNonMasterTemplateNames,
  ];

  const {
    generalTargetingGroupRows,
    targetingTemplateGroupRows,
    targetingGroupsRows,
    effectiveTargetingRows,
  } = formatCsvRowsFromTargeting(
    values.targetingGeneralGroup,
    values.targetingMasterTemplates,
    values.targetingGroups,
    mergedTargetingDefinition
  );

  const groups = [
    ...generalTargetingGroupRows,
    ...targetingTemplateGroupRows,
    ...targetingGroupsRows,
  ];

  const targetingV2Rows = getCsvRowsFromTargetingV2(
    values.targetingDefinitionV2 as ITargetingDefinitionV2,
    targetingV2Info,
    errors,
    territory
  );

  let maxNoOfRows = 0;

  if (values.targetingVersion === TargetingVersion.TargetingV1) {
    maxNoOfRows = Math.max(
      iabCategories.length,
      targetingTemplateNames.length,
      groups.length,
      effectiveTargetingRows?.length
    );
  } else {
    maxNoOfRows = Math.max(1, iabCategories.length, targetingV2Rows.length);
  }

  const dataToBeExported = [];

  for (let i = 0; i < maxNoOfRows; i += 1) {
    const isFirstRow = i === 0;

    const targetingV1RowData = {
      'Targeting Templates': targetingTemplateNames[i],
      Groups: groups[i]?.groupName,
      'Targeting Parameter Type': groups[i]?.targetingParameterType,
      Logic: groups[i]?.logic,
      Values: groups[i]?.values,
      'Total number of Channels': groups[i]?.totalChannelNumber,
      Channels: groups[i]?.channels,
      'Effective Targeting Groups': effectiveTargetingRows[i]?.groupName,
      'Effective Targeting Parameter':
        effectiveTargetingRows[i]?.targetingParameterType,
      'Effective Targeting Logic': effectiveTargetingRows[i]?.logic,
      'Effective Targeting Values': effectiveTargetingRows[i]?.values,
      'Effective Targeting total number of channels':
        effectiveTargetingRows[i]?.totalChannelNumber,
      'Effective Targeting Channels': effectiveTargetingRows[i]?.channels,
    };

    const targetingV2RowData = {
      Section: targetingV2Rows[i]?.section,
      Param: targetingV2Rows[i]?.parameterType,
      Clusivity: targetingV2Rows[i]?.clusivity,
      'Value(s)': targetingV2Rows[i]?.value,
      Accuracy: targetingV2Rows[i]?.accuracy,
    };

    const targetingRestrictionsRow =
      values.targetingVersion === TargetingVersion.TargetingV1
        ? []
        : {
            'Campaign Restriction': isFirstRow
              ? getTargetingRestrictionsAsString(values.targetingRestrictions)
              : '',
          };

    const targetingRowData =
      values.targetingVersion === TargetingVersion.TargetingV1
        ? targetingV1RowData
        : targetingV2RowData;

    dataToBeExported.push({
      Duration: isFirstRow ? values.duration : '',
      'Objective Impressions': isFirstRow ? values.objective : '',
      'Start Date':
        isFirstRow && values.startDate
          ? getFormattedDateBasedOnTerritory(values.startDate, territory)
          : '',
      'Start Date Time':
        isFirstRow && values.startDate ? format(values.startDate, 'HH:mm') : '',
      'End Date':
        isFirstRow && values.endDate
          ? getFormattedDateBasedOnTerritory(values.endDate, territory)
          : '',
      'End Date Time':
        isFirstRow && values.endDate ? format(values.endDate, 'HH:mm') : '',
      ...targetingRestrictionsRow,
      'IAB Categories': iabCategories[i],
      ...targetingRowData,
      'Booked Impressions': isFirstRow ? bookedImpressions : '',
      'Available Impressions': isFirstRow ? availableImpressions : '',
      'Can Deliver': isFirstRow ? deliveryStatus : '',
      'Forecast Date':
        isFirstRow && dateOfForecastGeneration
          ? getFormattedDateBasedOnTerritory(
              dateOfForecastGeneration,
              territory
            )
          : '',
      'Forecast Time':
        isFirstRow && dateOfForecastGeneration
          ? format(dateOfForecastGeneration, 'HH:mm')
          : '',
    });
  }

  return dataToBeExported;
};

export const commonDefaultValues = {
  objective: '',
  duration: '30',
};

export const getAdValues = (ad: Ad): IForecastingValues => {
  const targeting = formatTargeting(ad.targetingDefinition);

  const getInputDateIfValid = (isoDate: string, timeZone: TimeZones) => {
    const dateInTimeZone = dateUtils.getDateInSpecificTimezone(
      isoDate,
      timeZone
    );
    const isDateInFuture = dateUtils.isDateInTheFuture(
      dateInTimeZone,
      timeZone
    );
    return isDateInFuture ? dateInTimeZone : null;
  };

  const currentDate = new Date();
  const endDateAfter30Days = endOfDay(
    dateUtils.getUtcDateAfterXDays(currentDate, ad.campaign!.timeZone, 30)
  );

  const adValues = {
    ...commonDefaultValues,
    timeZone: ad.campaign!.timeZone,
    startDate:
      getInputDateIfValid(ad.startDate.value, ad.campaign!.timeZone) ||
      currentDate,
    endDate:
      getInputDateIfValid(ad.endDate.value, ad.campaign!.timeZone) ||
      endDateAfter30Days,
    iabCategoryCodes: transformIabCategoryOptions(ad.campaign!.iabCategories),
    duration: ad.duration?.toString() || commonDefaultValues.duration,
    targetingMasterTemplates: targeting.templates,
    targetingGroups: targeting.groups,
    targetingGeneralGroup: targeting.general,
    targetingVersion: ad.campaign!.targetingVersion,
    targetingDefinitionV2: formatTargetingDefinitionV2(
      ad.targetingDefinitionV2
    ),
    targetingRestrictions: ad.targetingRestrictions,
  };
  return adValues;
};
