import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles, { LoaderMarginStyles } from './Loader.styles';

const Loader = ({
  size,
  marginStyle = LoaderMarginStyles.Default,
}: {
  size?: number;
  marginStyle?: string;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid="loader">
      <CircularProgress
        className={`${classes.progress}--margin-${marginStyle}`}
        size={size}
      />
    </div>
  );
};

export default Loader;
