import classNames from 'classnames';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import SplitButton from 'components/SplitButton/SplitButton';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  IProgrammaticCreativesRow,
  programmaticCreativeStatusValues,
} from 'features/programmatic/creative/components/ProgrammaticCreativesTable/ProgrammaticCreativesValues';
import FileSaver from 'file-saver';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  ApprovalStatus,
  ProgrammaticCreativeType,
  UpdateProgrammaticCreativeMutationFn,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useState } from 'react';
import { findLabelValue } from 'utils/dataTransformation';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import {
  isActiveTerritoryAssignedToEntity,
  TerritoryLabel,
} from 'utils/territory';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './ProgrammaticCreativeModal.styles';

export const handleUpdate = async (
  programmaticCreativeId: string,
  newStatus: ApprovalStatus,
  updateProgrammaticCreative: UpdateProgrammaticCreativeMutationFn
) => {
  try {
    await updateProgrammaticCreative({
      variables: {
        id: programmaticCreativeId,
        status: newStatus,
      },
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export interface IProgrammaticCreativeModalProps {
  programmaticCreative: IProgrammaticCreativesRow;
  updateProgrammaticCreative: UpdateProgrammaticCreativeMutationFn;
  label?: string;
  testId?: string;
}

const ProgrammaricCreativeModal = (props: IProgrammaticCreativeModalProps) => {
  const { programmaticCreative, updateProgrammaticCreative, label, testId } =
    props;

  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const location = usePreviousLocation();

  const testIdPrefix = testId ? `${testId}-` : '';

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const isAssignedToActiveTerritory = isActiveTerritoryAssignedToEntity(
    programmaticCreative.territories!,
    activeTerritory!
  );

  return (
    <>
      <button
        type="button"
        className={classes.modalButton}
        onClick={() => setModalOpen(!isModalOpen)}
        data-tc="programmaticCreativeModalButton"
        data-testid={`${testIdPrefix}programmaticCreativeModalButton`}
      >
        {label || programmaticCreative.name}
      </button>
      {isModalOpen && (
        <Dialog
          data-tc="programmaticCreativeModal"
          data-testid="programmaticCreativeModal"
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="xl"
        >
          <DialogTitle classes={{ root: classes.title }} disableTypography>
            <Typography display="inline">
              View{' '}
              {programmaticCreative.type ===
              ProgrammaticCreativeType.ProgrammaticAudio
                ? 'Audio'
                : 'Display'}{' '}
              Creative
            </Typography>
            <IconButton
              onClick={() => setModalOpen(false)}
              classes={{ root: classes.closeButton }}
              data-tc="programmaticCreativeModalCloseX"
            >
              <CloseIcon />
              <span className="sr-only">
                Close the{' '}
                {programmaticCreative.type ===
                ProgrammaticCreativeType.ProgrammaticAudio
                  ? 'Audio'
                  : 'Display'}{' '}
                creative
              </span>
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{ root: classes.content }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item sm={7}>
                <dl className={classes.descriptionList}>
                  <dt className={classes.descriptionTitle}>Filename:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-filename"
                  >
                    {programmaticCreative.name}
                  </dd>
                  <dt className={classes.descriptionTitle}>
                    Advertiser Domains:
                  </dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-advertiserDomains"
                  >
                    {programmaticCreative.advertiserDomains}
                  </dd>
                  <dt className={classes.descriptionTitle}>IAB Categories:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-iabCategories"
                  >
                    {programmaticCreative.iabCategoryCodes}
                  </dd>
                  {programmaticCreative.type ===
                  ProgrammaticCreativeType.ProgrammaticAudio ? (
                    <>
                      <dt className={classes.descriptionTitle}>
                        Audio Duration:
                      </dt>
                      {typeof programmaticCreative.spec.duration ===
                      'number' ? (
                        <dd
                          className={classes.descriptionContent}
                          data-testid="progCreativeModalContent-duration"
                        >
                          {numbro(programmaticCreative.spec.duration).format({
                            mantissa: 3,
                          })}{' '}
                          seconds
                        </dd>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <dt className={classes.descriptionTitle}>
                        Image Dimensions:
                      </dt>
                      <dd
                        className={classes.descriptionContent}
                        data-testid="progCreativeModalContent-dimensions"
                      >
                        {programmaticCreative.spec.width}x
                        {programmaticCreative.spec.height} pixels
                      </dd>
                    </>
                  )}
                  <dt className={classes.descriptionTitle}>Status:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-status"
                  >
                    {findLabelValue({
                      collection: programmaticCreativeStatusValues,
                      lookupValue: programmaticCreative.status,
                    })}
                  </dd>
                  <dt className={classes.descriptionTitle}>First Bid Date:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-firstBidDate"
                    data-testid="progCreativeModalContent-firstBidDate"
                  >
                    {programmaticCreative.firstBidDate
                      ? getFormattedDateBasedOnTerritory(
                          new Date(programmaticCreative.firstBidDate),
                          activeTerritory
                        )
                      : null}
                  </dd>
                  <dt className={classes.descriptionTitle}>Approved Date:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-approvedDate"
                    data-testid="progCreativeModalContent-approvedDate"
                  >
                    {programmaticCreative.approvalDate
                      ? getFormattedDateBasedOnTerritory(
                          new Date(programmaticCreative.approvalDate),
                          activeTerritory
                        )
                      : null}
                  </dd>
                  <dt className={classes.descriptionTitle}>Deals:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-deals"
                  >
                    {programmaticCreative.deals.map((deal, index, list) => (
                      <StyledLink
                        compact
                        color={LinkColorEnum.Plain}
                        location={{
                          pathname: `/deal/${deal.id}`,
                          state: {
                            parent: location.state,
                            from: location.pathname,
                          },
                        }}
                        key={`${programmaticCreative.id}-${deal.id}`}
                      >
                        {deal.name + (index < list.length - 1 ? ', ' : '')}
                      </StyledLink>
                    ))}
                  </dd>
                  <dt className={classes.descriptionTitle}>Territories:</dt>
                  <dd
                    className={classes.descriptionContent}
                    data-tc="progCreativeModalContent-territories"
                  >
                    {programmaticCreative.territories?.reduce(
                      (acc, value, index, list) => {
                        acc.push(TerritoryLabel[value]);
                        if (index < list.length - 1) {
                          acc.push(', ');
                        }
                        return acc;
                      },
                      [] as string[]
                    )}
                  </dd>
                </dl>
              </Grid>
              <Grid item sm={5}>
                {programmaticCreative.type ===
                ProgrammaticCreativeType.ProgrammaticAudio ? (
                  <div className={classes.audioWrapper}>
                    <AudioPlayer
                      src={programmaticCreative.url}
                      fullLength
                      data-tc="programmaticCreativeAudioPlayer"
                    />
                  </div>
                ) : (
                  <img
                    src={programmaticCreative.url}
                    alt={programmaticCreative.name}
                    className={classes.modalImage}
                    data-tc="programmaticCreativeImageThumbnail"
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            classes={{
              root: classNames(
                classes.actions,
                `${classes.actions}--space-between`
              ),
            }}
          >
            <StyledButton
              onClick={() => setModalOpen(false)}
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              data-tc="programmaticCreativeModalClose"
            >
              Cancel
            </StyledButton>
            <SplitButton
              toggleDataTc="splitButtonToggleProgrammaticCreatives"
              buttons={[
                {
                  text: 'Approve Creative',
                  onClick: () => {
                    handleUpdate(
                      programmaticCreative.id,
                      ApprovalStatus.Approved,
                      updateProgrammaticCreative
                    );
                    setModalOpen(false);
                  },
                  isLoading: false,
                  disabled:
                    programmaticCreative.status === ApprovalStatus.Approved ||
                    !isAssignedToActiveTerritory,
                  dataTc: 'approveProgrammaticCreative',
                },
                {
                  text: 'Block Creative',
                  onClick: () => {
                    handleUpdate(
                      programmaticCreative.id,
                      ApprovalStatus.Blocked,
                      updateProgrammaticCreative
                    );
                    setModalOpen(false);
                  },
                  isLoading: false,
                  disabled:
                    programmaticCreative.status === ApprovalStatus.Blocked ||
                    !isAssignedToActiveTerritory,
                  dataTc: 'blockProgrammaticCreative',
                },
                {
                  text: 'Download Creative',
                  isLoading: false,
                  disabled: false,
                  dataTc: 'downloadProgrammaticCreative',
                  onClick: () =>
                    FileSaver.saveAs(
                      programmaticCreative.url,
                      programmaticCreative.name
                    ),
                },
              ]}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProgrammaricCreativeModal;
