import { ReactComponent as ViewColumnIcon } from 'assets/icons/dax-collumnicon.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/dax-crossicon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/dax-searchicon.svg';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import React, { useState } from 'react';
import { Column } from 'react-table';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { useToolbarStyles } from './Table.styles';

interface ITableToolbar {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
  setAllFilters: (filters: any[]) => void;
  columns: Column[];
  setCursor?: (arg: any) => void;
  setPaginationIndex?: (arg: number) => void;
  title?: string;
  errorMessage?: string;
  showColumnToggle: boolean;
  isPageTable: boolean;
  customToolbarCtas?: React.ReactNode;
}

const TableToolbar = ({
  globalFilter,
  setGlobalFilter,
  setCursor,
  setPaginationIndex,
  columns,
  title,
  errorMessage,
  showColumnToggle,
  isPageTable,
  setAllFilters,
  customToolbarCtas,
}: ITableToolbar) => {
  const classes = useToolbarStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const revertFilters = () => {
    setAllFilters([]);
    setGlobalFilter('');
  };

  return (
    <div
      className={classNames([
        classes.root,
        {
          [`${classes.root}--tabTable`]: !isPageTable,
        },
      ])}
      data-testid="tableToolbar"
    >
      <Toolbar disableGutters className={classes.wrapper}>
        {isPageTable && title && (
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        )}
        <div className={classes.ctaContainer}>
          <StyledButton
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            testId="resetFiltersButton"
            onClick={revertFilters}
          >
            Reset All Filters
          </StyledButton>
          {customToolbarCtas}
          <TextField
            value={globalFilter || ''}
            onChange={(e) => {
              setCursor && setCursor({ next: null, previous: null });
              setPaginationIndex && setPaginationIndex(0);
              setGlobalFilter(e.target.value || undefined);
            }}
            data-testid="tableSearchField"
            className={classes.textfield}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Search">
                    <SearchIcon />
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: globalFilter ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setGlobalFilter(undefined)}
                    data-testid="tableSearchClearButton"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
              className: classes.searchTextField,
            }}
          />
          {showColumnToggle && (
            <div>
              <Tooltip title="Show/Hide Columns">
                <IconButton
                  onClick={handleClick}
                  data-testid="tableColumnsMenuButton"
                >
                  {open ? <ClearIcon /> : <ViewColumnIcon />}
                </IconButton>
              </Tooltip>
              <Popper open={open} anchorEl={anchorEl}>
                <Paper>
                  <List className={classes.list}>
                    {columns
                      .filter((column: Column) => column.id !== 'id')
                      .map((column: any) => (
                        <ListItem key={column.id} role={undefined} dense>
                          <ListItemIcon>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  edge="start"
                                  size="small"
                                  {...column.getToggleHiddenProps()}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': column.id,
                                    'data-testid': `columnList-${column.id}`,
                                  }}
                                />
                              }
                              id={column.id}
                              label={column.Header}
                            />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </div>
          )}
        </div>
      </Toolbar>
      {errorMessage ? (
        <p className={classes.error} data-testid="tableError">
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
};

export default TableToolbar;
