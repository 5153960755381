import { useHistory } from 'react-router-dom';

const useUrlSearchParamUtils = (params: URLSearchParams) => {
  const history = useHistory();

  const getUrlParam = (key: string): string | null => params.get(key);

  const setUrlParam = (key: string, value: any) => {
    params.set(key, value);
    history.push({ search: params.toString() });
  };

  const deleteUrlParam = (key: string): void => {
    params.delete(key);
    history.push({ search: params.toString() });
  };

  return {
    setUrlParam,
    getUrlParam,
    deleteUrlParam,
  } as const;
};

export default useUrlSearchParamUtils;
