import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';

import TagsTable from 'features/tag/components/TagsTable/TagsTable';

import { Tag, useAllTagsQuery } from 'interfaces/generated.types';

import useStyles from './TagsPage.styles';

interface ITagsPageProps {
  history: History;
}

const TagsPage = ({ history }: ITagsPageProps) => {
  const { loading, error, data } = useAllTagsQuery({
    errorPolicy: 'all',
  });
  const classes = useStyles();

  if (!loading && error && !(data && data.allTags))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className={classes.title}>Reporting Tags</h1>
        <p>
          Add or remove tags that can be used throughout the DAX Audio service,
          allowing for cross-referencing. Removing tags that have already been
          applied on Campaigns and Ads will remove them there as well.
        </p>
        {loading ? (
          <Loader />
        ) : (
          <TagsTable history={history} tags={(data?.allTags as Tag[]) || []} />
        )}
      </Container>
    </main>
  );
};

export default TagsPage;
