import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

import useStyles from './SignUpForm.styles';

export interface ISignUpFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  ageChecked: boolean;
  privacyChecked: boolean;
}

interface ISignUpFormProps extends FormikProps<ISignUpFormValues> {
  error?: string;
  setError: (error: string) => void;
}

const SignUpForm = (props: ISignUpFormProps) => {
  const {
    values: { email, password, passwordConfirm, ageChecked, privacyChecked },
    error,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
  } = props;

  const classes = useStyles();
  const pageClasses = pageStyles();

  return (
    <Grid container spacing={0}>
      <Form
        className={classes.signUpForm}
        onSubmit={handleSubmit}
        aria-label="sign-up-form"
      >
        <Grid item xs={12}>
          <Field
            label="Email Address"
            name="email"
            id="email"
            className={classes.textField}
            component={TextField}
            value={email}
            helperText={errors.email}
            FormHelperTextProps={{
              error: !!errors.email,
            }}
            margin="normal"
            disabled
          />
          <Field
            label="Password"
            type="password"
            name="password"
            id="password"
            component={TextField}
            className={classes.textField}
            onChange={handleChange}
            value={password}
            margin="normal"
          />
          <Field
            label="Re-enter Password"
            type="password"
            name="passwordConfirm"
            id="passwordConfirm"
            component={TextField}
            className={classes.textField}
            onChange={handleChange}
            value={passwordConfirm}
            margin="normal"
          />
          <Grid item xs={12}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  color="primary"
                  onChange={handleChange}
                  checked={ageChecked}
                  name="ageChecked"
                />
              }
              label={
                <Typography
                  align="justify"
                  className={classNames([classes.italic, classes.checkboxText])}
                >
                  I confirm that I am 18 years old or over
                </Typography>
              }
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  color="primary"
                  onChange={handleChange}
                  checked={privacyChecked}
                  name="privacyChecked"
                />
              }
              label={
                <Typography
                  align="justify"
                  className={classNames([classes.italic, classes.checkboxText])}
                >
                  I agree to the{' '}
                  <a href="https://global.com/legal/privacy-policy/">
                    Privacy Notice
                  </a>
                </Typography>
              }
            />
          </Grid>
        </Grid>
        {error && (
          <div className={classes.error}>
            <Typography
              variant="body2"
              align="center"
              className={pageClasses.errorMessage}
            >
              {error}
            </Typography>
          </div>
        )}
        <Grid item xs={12}>
          <StyledButton
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Contained}
            className={classes.cta}
            isLoading={isSubmitting}
            type="submit"
            testId="sign-up-button"
            disabled={
              isSubmitting ||
              !isValid ||
              !ageChecked ||
              !privacyChecked ||
              !isValid ||
              !email ||
              !password ||
              !passwordConfirm
            }
          >
            Sign Up
          </StyledButton>
        </Grid>
      </Form>
    </Grid>
  );
};

export default SignUpForm;
