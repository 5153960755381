import { FieldArray, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';
import {
  targetingAdMidRollPositionValues,
  targetingAdPositionEnum,
  targetingAdPositionValues,
} from 'features/targeting/components/TargetingParameters/TargetingParametersValues';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const adPositionValues = {
  adPositionParams: {
    positions: [
      {
        position: {
          value: '',
          readOnly: false,
        },
        midRollPositions: [],
        readOnly: false,
      },
    ],
  },
};

const TargetingAdPosition = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index: locationIndex,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <FieldArray
      name={`${targetingPath}.audienceParams.${locationIndex}.adPositionParams.positions`}
    >
      {({ push, remove }) => (
        <>
          {targetingValues.audienceParams[
            locationIndex
          ].adPositionParams.positions.map((_: any, index: number) => (
            /* eslint-disable react/no-array-index-key */
            <div className={classes.wrapper} key={index}>
              <Field
                component={TextField}
                select
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  },
                }}
                label="Ad Position"
                name={`${targetingPath}.audienceParams.${locationIndex}.adPositionParams.positions.${index}.position.value`}
                data-tc="targetingAdPosition"
                classes={{ root: classes.pairValues }}
                disabled={
                  targetingValues.audienceParams[locationIndex].adPositionParams
                    .positions[index].position.readOnly
                }
                fullWidth
              >
                {targetingAdPositionValues.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              {targetingValues.audienceParams[locationIndex].adPositionParams
                .positions[index].position.value ===
                targetingAdPositionEnum.MID_ROLL && (
                <TargetingMultiSelect
                  {...props}
                  label="Mid-roll position"
                  targetingName={`adPositionParams.positions.${index}.midRollPositions`}
                  options={targetingAdMidRollPositionValues}
                  className={classes.pairValues}
                  noMargin
                  data-tc="targetingAdPositionMidRoll"
                />
              )}
              <div className={classes.buttons}>
                <IconButton
                  data-tc="targetingAdPositionRemoveButton"
                  disabled={
                    targetingValues.audienceParams[locationIndex]
                      .adPositionParams.positions.length === 1 ||
                    targetingValues.audienceParams[locationIndex]
                      .adPositionParams.positions[index].position.readOnly
                  }
                  className={classes.button}
                  onClick={() => remove(index)}
                >
                  <ClearIcon />
                  <span className="sr-only">Remove ad position</span>
                </IconButton>
                <IconButton
                  data-tc="targetingAdPositionAddButton"
                  onClick={() => {
                    push(adPositionValues.adPositionParams.positions[0]);
                  }}
                  className={classes.button}
                >
                  <AddIcon />
                  <span className="sr-only">Add another ad position type</span>
                </IconButton>
              </div>
            </div>
          ))}
        </>
      )}
    </FieldArray>
  );
};

export default TargetingAdPosition;
