import DateRangePickerDialog from 'components/DateTimePicker/DateRangePickerDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { endOfDay, startOfDay } from 'date-fns';
import { IDateRange } from 'interfaces';
import React, { useState } from 'react';
import { getDateRangeDisplayedValueBasedOnTerritory } from 'utils/defaultsByTerritory';

import TextField from '@material-ui/core/TextField';

import useStyles from './InsightsDateRangeModal.styles';

interface IInsightsDateRangeModal {
  dateRange: IDateRange;
  setDateRange: any;
}

const InsightsDateRangeModal = ({
  dateRange,
  setDateRange,
}: IInsightsDateRangeModal) => {
  const classes = useStyles();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const handleChange = (selectedStartDate: Date, selectedEndDate: Date) => {
    setDateRange({
      startDate: startOfDay(selectedStartDate),
      endDate: endOfDay(selectedEndDate),
    });
  };

  const displayDateRange = getDateRangeDisplayedValueBasedOnTerritory(
    dateRange,
    activeTerritory!
  );

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TextField
        label="Date Range"
        name="dateRangeDialog"
        id="dateRangeDialog"
        data-testid="displayedDateRange"
        value={displayDateRange}
        onClick={() => setOpen(true)}
        inputProps={{
          readOnly: true,
          className: classes.input,
        }}
      />
      <DateRangePickerDialog
        initialRange={dateRange}
        showTwoFieldsForSelectedRange
        cancelButtonLabel="Back"
        title="Date Range Select"
        onClose={handleClose}
        open={open}
        onRangeChange={handleChange}
        data-tc="insightsDateRangeDialog"
        disableFuture
        displayPresets
      />
    </>
  );
};

export default InsightsDateRangeModal;
