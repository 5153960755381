import { PermissionName } from 'interfaces/generated.types';
import * as Yup from 'yup';

const UserFormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('User email is a required field'),
  primaryTerritory: Yup.string().required(
    'User primary territory is a required field'
  ),
  entityPermissions: Yup.array().when(['email', 'permissions'], {
    is: (email: string, permissions: PermissionName[]) =>
      permissions.includes(PermissionName.PublisherInsightsTier2) &&
      email &&
      !email.endsWith('@global.com'),
    then: Yup.array().required(
      'Please select the entities you want the user to have access to'
    ),
  }),
});

export default UserFormValidation;
