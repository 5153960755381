import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${colors.daxGrey100}`,
    margin: theme.spacing(4, 7.4, 0),
    padding: theme.spacing(2, 0),
    fontSize: '12px',
    color: colors.daxGrey600,
    justifyContent: 'space-between',
  },
  privacyPolicy: {
    textDecoration: 'none',
    color: colors.daxGrey600,
    marginRight: theme.spacing(4),
  },
  privacyManager: {
    color: colors.daxGrey600,
    border: 'none',
    cursor: 'pointer',
    padding: theme.spacing(0),
    backgroundColor: 'transparent',
  },
  copyrightContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
  },
  copyrightIcon: {
    fontSize: '12px',
    marginRight: theme.spacing(0.5),
  },
  footerLinks: {
    display: 'flex',
  },
}));

export default useStyles;
