import { ReactComponent as AddIcon } from 'assets/icons/dax-addicon.svg';
import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import { useStyles as selectStyles } from 'components/MultiSelect/MultiSelect.styles';
import { OptionType } from 'interfaces';
import React, { useState } from 'react';

import { Chip, Grid, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './MultiselectModal.styles';

export interface IMultiselectModalProps {
  items: OptionType[];
  label?: string;
  CustomDialog: React.ElementType<any>;
  customDialogProps?: any;
  id: string;
  removeItem: (item: string) => void;
  disabled?: boolean;
  disabledMessage?: string;
  useWhiteBackground?: boolean;
}

const MultiselectModal = ({
  items,
  label,
  CustomDialog,
  customDialogProps,
  id,
  removeItem,
  disabled = false,
  disabledMessage,
  useWhiteBackground = false,
}: IMultiselectModalProps) => {
  const classes = {
    ...selectStyles(),
    ...useStyles({ useWhiteBackground }),
    ...pageStyles(),
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {label && (
        <Typography
          data-testid={`multiselect-modal-label-${id}`}
          className={classes.label}
        >
          {label}
        </Typography>
      )}
      <Grid
        item
        xs={12}
        className={classNames([
          classes.itemsContainer,
          {
            [`${classes.itemsContainer}--disabled`]: disabled,
          },
        ])}
        data-testid={`multiselect-field-${id}`}
      >
        <IconButton
          onClick={() => setIsModalOpen(!isModalOpen)}
          className={classes.iconButton}
          data-testid={`multiselect-modal-add-button-${id}`}
          disableRipple
          disabled={disabled}
        >
          <AddIcon />
          <span className="sr-only">Add items</span>
        </IconButton>
        {items.map((item: OptionType) => (
          <Chip
            label={item.label}
            key={item.value}
            className={classNames(classes.chip, classes.chipWrapper)}
            clickable={false}
            onDelete={() => removeItem(item.value)}
            deleteIcon={
              <ClearIcon
                data-testid={`multiselect-chip-delete-button-${item.value}`}
              />
            }
            disabled={item.readOnly || disabled}
          />
        ))}
      </Grid>
      {disabled && (
        <p
          className={classNames(classes.errorMessage, classes.errorWrapper)}
          data-testid="modalDisabledError"
        >
          {disabledMessage || 'Currently unable to select any options.'}
        </p>
      )}
      {isModalOpen && (
        <CustomDialog
          {...customDialogProps}
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          setSelectedItems={items}
        />
      )}
    </>
  );
};

export default MultiselectModal;
