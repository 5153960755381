import React, { createContext } from 'react';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export enum Event {
  USER_LOADS = 'USER_LOADS',
  USER_CLICKS_GENERATE_FORECAST = 'USER_CLICKS_GENERATE_FORECAST',
  USER_SAVES_PARAMETER_VALUE = 'USER_SAVES_PARAMETER_VALUE',
  USER_SAVES_PARAMETER = 'USER_SAVES_PARAMETER',
}

export type TGTMLoggerContext = (arg: Record<string, any>) => void;

const GTMLoggerContext = createContext<TGTMLoggerContext | undefined>(
  undefined
);

const GTMLoggerProvider = ({ children }: { children: React.ReactNode }) => {
  const sendDataToGTM = useGTMDispatch();

  const gtmLogger = (data: Record<string, any>) => {
    sendDataToGTM(data);
  };

  return (
    <GTMLoggerContext.Provider value={gtmLogger}>
      {children}
    </GTMLoggerContext.Provider>
  );
};

export { GTMLoggerProvider, GTMLoggerContext };
