import gql from 'graphql-tag';

export const GET_ALL_PAGINATED_PROGRAMMATIC_CREATIVES = gql`
  query PaginatedProgrammaticCreatives(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $ordering: ProgrammaticCreativeOrdering
    $filter: ProgrammaticCreativeFilter
    $territories: [Territory!]!
  ) {
    paginateProgrammaticCreatives(
      first: $first
      last: $last
      after: $after
      before: $before
      ordering: $ordering
      filter: $filter
      territories: $territories
    ) {
      edges {
        cursor
        node {
          id
          name
          type
          status
          url
          iabCategoryCodes
          advertiserDomains
          firstBidDate
          approvalDate
          spec {
            ... on ProgrammaticCreativeAudioSpec {
              duration
            }
            ... on ProgrammaticCreativeImageSpec {
              width
              height
            }
          }
          firstSeenDeal {
            id
            name
          }
          deals {
            id
            name
          }
          territories
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_TOTAL_PROGRAMMATIC_CREATIVES = gql`
  query TotalProgrammaticCreatives(
    $filter: ProgrammaticCreativeFilter
    $territories: [Territory!]!
  ) {
    totalProgrammaticCreatives(filter: $filter, territories: $territories)
  }
`;

export const GET_ALL_PROGRAMMATIC_CREATIVES_MINIMAL = gql`
  query AllProgrammaticCreativesMinimal(
    $filter: ProgrammaticCreativeFilter
    $first: Int
    $territories: [Territory!]!
  ) {
    paginateProgrammaticCreatives(
      first: $first
      filter: $filter
      territories: $territories
    ) {
      edges {
        node {
          id
          status
        }
      }
    }
  }
`;

export default {
  GET_ALL_PAGINATED_PROGRAMMATIC_CREATIVES,
  GET_ALL_PROGRAMMATIC_CREATIVES_MINIMAL,
};
