import useStyles from 'assets/styles/components/Dialog.styles';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import format from 'date-fns/format';
import useDateTimePicker from 'hooks/DateTimePicker/useDateTimePicker';
import React, { useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useDateTimePickerDialogStyles } from './DateTimePicker.styles';
import DateTimePickerCalendar from './DateTimePickerCalendar';
import DateTimePickerInput from './DateTimePickerInput';

interface DatePickerDialogProps {
  open: boolean;
  value: Date | null;
  initialFocusedDate: Date;
  onClose: () => void;
  onChange: (value: Date) => void;
  disablePast?: boolean;
  disableFuture?: boolean;
}

const DatePickerDialog = ({
  onClose,
  onChange,
  value,
  initialFocusedDate,
  open,
  disablePast = false,
  disableFuture = false,
}: DatePickerDialogProps) => {
  const options = {
    showNonCurrentDates: false,
    initial: value || initialFocusedDate,
    disablePast,
    disableFuture,
  };
  const classes = useStyles();
  const dialog = useDateTimePickerDialogStyles();
  const {
    cursor,
    selected,
    selectedDay,
    selectedMonth,
    selectedYear,
    setSelectedAndCursor,
    forEachWeek,
    forEachDay,
    isSameDay,
    isToday,
    moveCursorOneMonthForward,
    moveCursorOneMonthBackward,
    moveSelectedOneDayForward,
    moveSelectedOneDayBackward,
    moveSelectedOneMonthForward,
    moveSelectedOneMonthBackward,
    moveSelectedOneYearForward,
    moveSelectedOneYearBackward,
    moveSelectedDay,
    moveSelectedMonth,
    moveSelectedYear,
  } = useDateTimePicker(options);

  useEffect(() => {
    setSelectedAndCursor(value || initialFocusedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialFocusedDate]);

  const handleNow = () => {
    const date = new Date();
    onChange(date);
    onClose();
  };

  const handleConfirm = () => {
    onChange(selected);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogContent classes={{ root: classes.content }}>
        <div className={dialog.grid}>
          <div
            className={classNames([
              dialog.container,
              {
                [`${dialog.container}--heading`]: true,
              },
            ])}
          >
            <h2 className={dialog.heading}>
              {format(selected, 'dd MMMM yyyy')}
            </h2>
          </div>

          <div
            className={classNames([
              dialog.container,
              {
                [`${dialog.container}--date-centered`]: true,
              },
            ])}
          >
            <DateTimePickerInput
              name="day"
              min={1}
              max={31}
              value={selectedDay}
              onChange={moveSelectedDay}
              onIncrement={moveSelectedOneDayForward}
              onDecrement={moveSelectedOneDayBackward}
            />
            /
            <DateTimePickerInput
              name="month"
              min={1}
              max={12}
              value={selectedMonth}
              onChange={moveSelectedMonth}
              onIncrement={moveSelectedOneMonthForward}
              onDecrement={moveSelectedOneMonthBackward}
            />
            /
            <DateTimePickerInput
              name="year"
              min={0}
              max={9999}
              value={selectedYear}
              onChange={moveSelectedYear}
              onIncrement={moveSelectedOneYearForward}
              onDecrement={moveSelectedOneYearBackward}
            />
          </div>
          <div
            className={classNames([
              dialog.container,
              {
                [`${dialog.container}--calendar-centered`]: true,
              },
            ])}
          >
            <DateTimePickerCalendar
              cursor={cursor}
              selected={selected}
              onIncrement={moveCursorOneMonthForward}
              onDecrement={moveCursorOneMonthBackward}
              onClick={setSelectedAndCursor}
              forEachWeek={forEachWeek}
              forEachDay={forEachDay}
              isSameDay={isSameDay}
              isToday={isToday}
            />
          </div>
        </div>
        <DialogActions
          classes={{
            root: classNames(
              classes.actions,
              `${classes.actions}--space-evenly`
            ),
          }}
        >
          <StyledButton
            className={dialog.button}
            onClick={handleNow}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="nowDateButton"
          >
            Today
          </StyledButton>
          <span className={dialog.spacer}>&nbsp;</span>
          <StyledButton
            className={dialog.button}
            onClick={handleClose}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="cancelDateButton"
          >
            Cancel
          </StyledButton>
          <StyledButton
            className={dialog.button}
            onClick={handleConfirm}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="confirmDateButton"
          >
            Ok
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DatePickerDialog;
