import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  filterItem: {
    width: '100%',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    '& > path': {
      stroke: theme.palette.grey[800],
    },
    '&--down': {
      transform: 'rotate(90deg)',
    },
  },
}));

export default useStyles;
