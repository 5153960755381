import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import ConnectedCreateAgencyContainer from 'features/direct/agency/containers/CreateAgencyContainer/CreateAgencyContainer';

interface ICreateAgencyPage {
  history: History;
}

const CreateAgencyPage = ({ history }: ICreateAgencyPage) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Agency</h1>
      <ConnectedCreateAgencyContainer history={history} />
    </Container>
  </main>
);

export default CreateAgencyPage;
