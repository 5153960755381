import gql from 'graphql-tag';

export const GET_ALL_SALES_CHANNELS = gql`
  query AllSalesChannels($agencyId: ID, $territories: [Territory!]!) {
    allSalesChannels(agencyId: $agencyId, territories: $territories) {
      id
      altId
      name
      status
      territory
      owner {
        id
        email
        status
      }
    }
  }
`;

export const GET_SALES_CHANNEL_MINIMAL = gql`
  query SalesChannelMinimal($id: ID!) {
    salesChannel(id: $id) {
      id
      name
      owner {
        id
        email
        status
      }
      territory
    }
  }
`;

export const GET_SALES_CHANNEL = gql`
  query SalesChannel($id: ID!) {
    salesChannel(id: $id) {
      id
      altId
      name
      status
      territory
      health {
        id
        name
        parentId
        childrenIds
        value
        percentOfParent
        category
      }
      deals {
        id
        altId
        name
        floorCpm
        startDate
        endDate
        timeZone
        status
        priority
        externalId
        trusted
        owner {
          id
          email
          status
        }
      }
      agency {
        id
        name
        status
      }
      owner {
        id
        email
        status
      }
    }
  }
`;

export const GET_SALES_CHANNEL_HEALTH = gql`
  query SalesChannelHealth($id: ID!, $from: LocalDate, $until: LocalDate) {
    salesChannel(id: $id) {
      id
      health(from: $from, until: $until) {
        id
        name
        parentId
        childrenIds
        value
        percentOfParent
        category
      }
    }
  }
`;
