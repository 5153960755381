import CategoriesContainer from 'features/insights/containers/CategoriesContainer/CategoriesContainer';
import CategoryValuesContainer from 'features/insights/containers/CategoryValuesContainer/CategoryValuesContainer';
import { PublisherInsightsFilterCategory } from 'interfaces/generated.types';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { ISelectedFilter } from 'utils/insights';

import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  TextField,
} from '@material-ui/core';

import useStyles from './InsightsFilterDialog.styles';

interface IInsightsFilterDialogProps {
  handleClose: () => void;
  isModalOpen: boolean;
  previouslySelectedFilters: ISelectedFilter[];
  setPreviouslySelectedFilters: (newFilters: ISelectedFilter[]) => void;
}

const InsightsFilterDialog = ({
  handleClose,
  isModalOpen,
  previouslySelectedFilters,
  setPreviouslySelectedFilters,
}: IInsightsFilterDialogProps) => {
  const classes = useStyles();

  const [
    activeFilterCategory,
    setActiveFilterCategory,
  ] = useState<PublisherInsightsFilterCategory | null>(null);

  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilter[]>(
    previouslySelectedFilters
  );

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <TextField
            value="Available"
            fullWidth
            inputProps={{
              readOnly: true,
              className: classes.textField,
            }}
          />
          <div className={classes.listContainer}>
            <CategoriesContainer
              data-testid="insightsFilterCategoriesContainer"
              setActiveFilterCategory={setActiveFilterCategory}
              activeFilterCategory={activeFilterCategory}
              selectedFilters={selectedFilters}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value="Selected"
            fullWidth
            inputProps={{
              readOnly: true,
              className: classes.textField,
            }}
          />
          <div className={classes.listContainer}>
            {activeFilterCategory && (
              <CategoryValuesContainer
                data-testid="insightsFilterValuesContainer"
                activeFilterCategory={activeFilterCategory}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <DialogActions className={classes.dialogActionsContainer}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.applyButton}
          disabled={isEqual(previouslySelectedFilters, selectedFilters)}
          onClick={() => {
            handleClose();
            setPreviouslySelectedFilters(selectedFilters);
          }}
        >
          Apply dimension filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsightsFilterDialog;
