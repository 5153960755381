import { SoundMode } from 'interfaces/generated.types';

import { generateVolumeTypes } from 'utils/generators';

export const ModeTypes = [
  {
    value: SoundMode.ModeUnspecified,
    label: undefined,
  },
  {
    value: SoundMode.Mono,
    label: 'Mono',
  },
  {
    value: SoundMode.Stereo,
    label: 'Stereo',
  },
  {
    value: SoundMode.JointStereo,
    label: 'Joint stereo',
  },
];

export const BitRateTypes = [
  {
    value: '',
    label: undefined,
  },
  {
    value: 48,
    label: '48 kbit/s',
  },
  {
    value: 128,
    label: '128 kbit/s',
  },
  {
    value: 192,
    label: '192 kbit/s',
  },
  {
    value: 256,
    label: '256 kbit/s',
  },
];

export const SampleRateTypes = [
  {
    value: '',
    label: undefined,
  },
  {
    value: 44100,
    label: '44100 Hz',
  },
  {
    value: 48000,
    label: '48000 Hz',
  },
];

export const VolumeTypes = [
  {
    value: '',
    label: undefined,
  },
  ...generateVolumeTypes(),
];

export enum FormatEnum {
  unspecified = '',
  wav = 'audio/wav',
  mp3 = 'audio/mpeg',
  mp4 = 'audio/mp4',
  aac = 'audio/HE-AAC',
  hd = 'audio/AAC-LC',
}

export const FormatTypes = [
  {
    value: FormatEnum.unspecified,
    label: undefined,
  },
  {
    value: FormatEnum.aac,
    label: 'HE-AAC',
  },
  {
    value: FormatEnum.hd,
    label: 'AAC-LC',
  },
  {
    value: FormatEnum.wav,
    label: 'WAV',
  },
  {
    value: FormatEnum.mp3,
    label: 'MP3',
  },
  {
    value: FormatEnum.mp4,
    label: 'MP4',
  },
];

export enum ExtensionEnum {
  unspecified = '',
  mp3 = 'mp3',
  mp4 = 'mp4',
  aac = 'aac',
  wav = 'wav',
}

export const ExtensionTypes = [
  {
    value: ExtensionEnum.unspecified,
    label: undefined,
  },
  {
    value: ExtensionEnum.aac,
    label: 'aac',
  },
  {
    value: ExtensionEnum.mp3,
    label: 'mp3',
  },
  {
    value: ExtensionEnum.mp4,
    label: 'mp4',
  },
  {
    value: ExtensionEnum.wav,
    label: 'wav',
  },
];
