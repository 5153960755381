import { NetworkStatus, Territory } from 'interfaces/generated.types';

export interface INetworkValues {
  name: string;
  id: string;
  altId?: number;
  journey: string;
  territories: { value: Territory; label: string }[];
  status?: NetworkStatus;
}

export const networkStatusValues = [
  {
    value: NetworkStatus.Draft,
    label: 'Draft',
    isDisabled: (value: NetworkStatus) =>
      [NetworkStatus.Enabled, NetworkStatus.Disabled].includes(value),
  },
  {
    value: NetworkStatus.Enabled,
    label: 'Enabled',
  },
  {
    value: NetworkStatus.Disabled,
    label: 'Disabled',
  },
];

export const getNetworkStatusData = (values: INetworkValues) => [
  {
    status: NetworkStatus.Draft,
    required: [...(!values.name ? ["Complete 'Name'"] : [])],
  },
  {
    status:
      values.status === NetworkStatus.Disabled
        ? NetworkStatus.Disabled
        : NetworkStatus.Enabled,
    required: [...(!values.name ? ["Waiting 'Name'"] : [])],
  },
];

export const networkDetailsFields = ['name'];
