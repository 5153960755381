import { makeStyles, Theme } from '@material-ui/core/styles';

const baseStyle = {
  display: 'flex',
  alignItems: 'baseline',
};

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    extend: baseStyle,
    justifyContent: 'space-between',
  },
  detailsCreative: {
    display: 'flex',
    alignItems: 'center',
  },
  detailsName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    width: '1em',
    paddingRight: '1em',
  },
  landingPageUrl: {
    flexGrow: 1,
    extend: baseStyle,
    minWidth: '1.25em',
    marginBottom: theme.spacing(2.5),
  },
  trackingUrls: {
    flexGrow: 1,
    extend: baseStyle,
    minWidth: '1.875em',
    flexDirection: 'column',
  },
  trackingUrlsItems: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  trackingUrlItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    width: '100%',
    marginRight: theme.spacing(1),
  },
  centerGrid: {
    textAlign: 'center',
  },
  creativeId: {
    margin: theme.spacing(2.8, 0),
    fontSize: '0.813em',
  },
  durationError: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
}));

export default useStyles;
