import useStyles from 'assets/styles/components/Dialog.styles';
import PageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableAssumeUserCell from 'components/Table/TableAssumeUserCell';
import TableDeleteCell from 'components/Table/TableDeleteCell';
import TableLink from 'components/Table/TableLink';
import TableResendSignUpCell from 'components/Table/TableResendSignUpCell';
import { useUserContext } from 'context/UserProvider/UserProvider';
import { GET_ALL_USERS } from 'features/user/graphql/queries';
import {
  MappedPermissionName,
  permissionNameMap,
} from 'graphql/permissions/queries';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import {
  Permission,
  PermissionName,
  useDeleteUserMutation,
  useInviteUserMutation,
  User,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { handleCellDelete, handleCellResendEmail } from 'utils/tables';
import { allTerritories } from 'utils/territory';
import { isEmailGlobalDomain } from 'utils/user';

interface IUsersRow {
  id: string;
  email: string;
  permissions: string;
}

interface IUsersTableProps {
  history: History;
  allUsers: User[];
}

const formatData = memoizeOne((data: User[]) =>
  data.map((d) => ({
    id: d.id,
    email: d.email,
    permissions: d.permissions
      .map(
        (permission: Permission) =>
          permissionNameMap[permission.name as MappedPermissionName] ||
          'Unknown Permission'
      )
      .join(', '),
  }))
);

const UsersTable = ({ allUsers, history }: IUsersTableProps) => {
  const [deleteUser] = useDeleteUserMutation({
    refetchQueries: [
      {
        query: GET_ALL_USERS,
        variables: {
          territories: allTerritories,
        },
      },
    ],
  });

  const [inviteUser] = useInviteUserMutation({});

  const location = usePreviousLocation();
  const classes = useStyles();
  const pageClasses = PageStyles();

  const { id: userId } = useUserContext();
  const userPermissions = useUserPermissions();
  const primaryUser = userPermissions.includes(PermissionName.Primary);

  const createUserCta = (
    <StyledLink
      location={{
        pathname: '/user',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createUserButton"
    >
      Create New User
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ cell: { value, row } }: CellProps<IUsersRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/user/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Permissions',
        accessor: 'permissions',
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, email },
            },
          },
          onCellDelete,
          setErrorModal,
          isEditable,
          onCellResend,
          setMessageModal,
          setMessageContent,
          setUpdating,
        }: CellProps<IUsersRow>) => (
          <div
            className={classNames([
              pageClasses.flexContainer,
              `${classes.actions}--space-evenly`,
            ])}
          >
            {!isEmailGlobalDomain(email) && (
              <TableResendSignUpCell
                onCellResend={onCellResend}
                entity={{ id, name: email }}
                setMessageModal={setMessageModal}
                dataTc="resendSignUp"
                setMessageContent={setMessageContent}
                setUpdating={setUpdating}
                setErrorModal={setErrorModal}
              />
            )}
            {primaryUser && userId !== id && (
              <TableAssumeUserCell entity={{ id, email }} dataTc="assumeUser" />
            )}
            <TableDeleteCell
              onCellDelete={onCellDelete}
              setErrorModal={setErrorModal}
              entity={{ id, name: email }}
              isEditable={isEditable}
              dataTc="userDelete"
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state]
  );

  return (
    <Table
      history={history}
      title="All Users"
      dataTc="usersTable"
      columns={columns}
      onCellDelete={({ entity, setErrorModal }) =>
        handleCellDelete({
          variables: {
            id: entity.id,
          },
          deleteCell: deleteUser,
          handleContinue: () => history.push(`/user/${entity.id}`),
          setErrorModal,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit User',
          },
        })
      }
      onCellResend={({ entity, setErrorModal, setMessageModal, setUpdating }) =>
        handleCellResendEmail({
          variables: {
            id: entity.id,
          },
          resend: inviteUser,
          setMessageModal,
          setErrorModal,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: '',
          },
          setUpdating,
        })
      }
      data={formatData(allUsers)}
      customToolbarCtas={createUserCta}
    />
  );
};

export default UsersTable;
