import {
  ExtensionEnum,
  FormatEnum,
} from 'features/inventory/transcodePreset/containers/TranscodePresetContainer/TranscodePresetValues';
import {
  BlockedKeyValueAttributes,
  ChannelStatus,
  ChannelType,
  InventoryType,
  PublisherStatus,
  SoundMode,
  TargetingRestriction,
  Territory,
  TranscodePreset,
  VastVersion,
} from 'interfaces/generated.types';
import { TimeZones } from 'utils/date';

export interface IChannelValues {
  publisherTerritories: Territory[];
  id: string;
  altId?: number;
  name: string;
  country: string;
  floorCpm: string;
  externalDealId: string;
  hasExternalDealId: boolean;
  publisherName: string;
  publisherId: string;
  type: string;
  inventoryType: InventoryType | string;
  stationLanguage: string;
  timeZone: TimeZones;
  transcodeBitRate: string | number;
  transcodeFormat: FormatEnum;
  transcodeMode: SoundMode;
  transcodePresets: TranscodePreset[];
  transcodeSampleRate: string | number;
  transcodeVolume: string | number;
  transcodeExtension: ExtensionEnum;
  defaultTranscodePresetId: string;
  vastVersion: VastVersion;
  blacklistedIabCategoryCodes: string[];
  blacklistedDomains: string[];
  blockedKeyValues: BlockedKeyValueAttributes[];
  overrideConsentSignal: boolean;
  journey: string;
  status?: ChannelStatus;
  trafficAcceptancePercentage: string;
  includedInDefaultInventoryTargeting: boolean;
  generatedDomains: string;
  minAdDuration: string;
  maxAdDuration: string;
  territories: { value: Territory; label: string }[];
  adRequestEnrichment: any;
  targetingRestrictions: TargetingRestriction[];
}

export const channelTypes = [
  {
    value: ChannelType.Music,
    label: 'Music Service',
  },
  {
    value: ChannelType.FmAm,
    label: 'FM/AM Broadcast',
  },
  {
    value: ChannelType.Podcast,
    label: 'Podcast',
  },
];

export enum StationLanguageEnum {
  English = 'en',
  French = 'fr',
  Spanish = 'es',
  Unspecified = '',
}

export const stationLanguageTypes = [
  {
    value: StationLanguageEnum.Unspecified,
    label: undefined,
  },
  {
    value: StationLanguageEnum.English,
    label: 'English',
  },
  {
    value: StationLanguageEnum.French,
    label: 'French',
  },
  {
    value: StationLanguageEnum.Spanish,
    label: 'Spanish',
  },
];

export enum OriginatingCountryEnum {
  UnitedKingdom = 'GB',
  UnitedStates = 'US',
  Canada = 'CA',
  Unspecified = '',
}

export const originatingCountryTypes = [
  {
    value: OriginatingCountryEnum.Unspecified,
    label: undefined,
  },
  {
    value: OriginatingCountryEnum.Canada,
    label: 'Canada',
  },
  {
    value: OriginatingCountryEnum.UnitedKingdom,
    label: 'United Kingdom',
  },
  {
    value: OriginatingCountryEnum.UnitedStates,
    label: 'United States',
  },
];

export const inventoryTypes = [
  {
    value: InventoryType.AudioArticle,
    label: 'Audio Article',
  },
  {
    value: InventoryType.Catchup,
    label: 'Catch up',
  },
  {
    value: InventoryType.FlashBriefing,
    label: 'Flash Briefing',
  },
  {
    value: InventoryType.Gaming,
    label: 'Gaming',
  },
  {
    value: InventoryType.Playlist,
    label: 'Playlist',
  },
  {
    value: InventoryType.PodCast,
    label: 'Podcast',
  },
  {
    value: InventoryType.Radio,
    label: 'Radio',
  },
];

export const vastVersions = [
  {
    value: VastVersion.Two,
    label: 'VAST 2.0',
  },
  {
    value: VastVersion.Three,
    label: 'VAST 3.0',
  },
  {
    value: VastVersion.Four,
    label: 'VAST 4.0',
  },
  {
    value: VastVersion.FourOne,
    label: 'VAST 4.1',
  },
];

export const channelStatusValues = [
  {
    value: ChannelStatus.Draft,
    label: 'Draft',
    isDisabled: (value: ChannelStatus) =>
      [ChannelStatus.Enabled, ChannelStatus.Disabled].includes(value),
  },
  {
    value: ChannelStatus.Enabled,
    label: 'Enabled',
    isDisabled: (value: ChannelStatus) => [ChannelStatus.Draft].includes(value),
  },
  {
    value: ChannelStatus.Disabled,
    label: 'Disabled',
    isDisabled: (value: ChannelStatus) => [ChannelStatus.Draft].includes(value),
  },
];

export const getChannelStatusData = (
  values: IChannelValues,
  publisherStatus: PublisherStatus
) => [
  {
    status: ChannelStatus.Draft,
    required: [
      ...(!values.name ? ["Complete 'Name'"] : []),
      ...(!values.publisherName ? ['Complete Parent Publisher'] : []),
    ],
  },
  {
    status:
      values.status === ChannelStatus.Disabled
        ? ChannelStatus.Disabled
        : ChannelStatus.Enabled,
    required: [
      ...(!values.name ? ['Waiting for Name'] : []),
      ...(publisherStatus !== PublisherStatus.Enabled
        ? ['Waiting for Parent Publisher to be in ENABLED state']
        : []),
      ...(values.type === ChannelType.ChannelTypeUnspecified
        ? ['Waiting for Default Publisher Type']
        : []),
      ...(!values.floorCpm ? ['Waiting for Floor CPM'] : []),
      ...(values.transcodePresets && !values.transcodePresets.length
        ? ['Waiting for Transcode Preset']
        : []),
    ],
  },
];

export const channelDetailsFields = [
  'name',
  'type',
  'stationLanguage',
  'country',
  'timeZone',
  'floorCpm',
  'trafficAcceptancePercentage',
  'vastVersion',
  'externalDealId',
];
