import gql from 'graphql-tag';

export const GET_SEAT = gql`
  query Seat($id: ID!) {
    seat(id: $id) {
      altId
      externalSeatId
      id
      name
      status
      dsp {
        id
        name
        status
        territories
      }
      territories
    }
  }
`;

export const GENERATE_UNIQUE_EXTERNAL_ID = gql`
  query GenerateUniqueExternalId {
    generateUniqueExternalId
  }
`;

export default {
  GET_SEAT,
};
