import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: theme.spacing(1, 2),
    backgroundColor: colors.backgroundLightBlue,
    fontWeight: 500,
    borderRadius: '8px 8px 0px 0px',
  },
  bodyWrapper: {
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2, 3),
  },
  body: {
    marginBottom: theme.spacing(1.5),
  },
  helpTitle: {
    fontWeight: 500,
    margin: theme.spacing(2.5, 0, 1, 0),
  },
  link: {
    marginBottom: theme.spacing(1),
    '& > a': {
      color: colors.primaryBlue,
    },
  },
}));

export default useStyles;
