import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  styledCheckbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '14px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    '&--selecting': {
      backgroundColor: theme.palette.common.white,
      borderColor: colors.daxBlue800Light,
      '& > svg': {
        width: '10px',
        height: '8px',
      },
      '& > svg > g > g': {
        fill: colors.daxBlue800,
      },
    },
    '&--deselecting': {
      backgroundColor: colors.errorHighlight,
      borderColor: 'transparent',
      '& > svg': {
        width: '9px',
        height: '9px',
      },
    },
    '&--checked': {
      backgroundColor: colors.daxBlue50,
      border: `1px solid ${colors.daxBlue800Light}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        width: '10px',
        height: '8px',
      },
      '& > svg > g > g': {
        fill: colors.daxBlue800,
      },
    },
  },
}));

export default useStyles;
