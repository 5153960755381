import {
  ICreativeAudio,
  ICreativeImage,
  ICreativeRedirect,
} from 'features/direct/creative/graphql/queries';
import { ITargetingDefinitionV2 } from 'features/targetingV2/types/targeting';
import { OptionType } from 'interfaces';
import {
  AdStatus,
  CampaignStatus,
  TargetingRestriction,
  TargetingVersion,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import dateUtils, { TimeZones } from 'utils/date';
import {
  ITargetingGroup,
  ITargetingMasterTemplate,
} from 'utils/targetingDefinitions';

export interface IAdValues {
  campaignCpm: string;
  duration: string;
  endDate: Date | null;
  endDateIsInherited?: boolean;
  isEndDateInheritable?: boolean;
  frequencyCapCount: number | string;
  targetingRestrictions: TargetingRestriction[];
  frequencyCapTimeUnit: string;
  frequencyCapValue: number | string;
  id: string;
  altId?: number;
  name: string;
  sequence: { id: string }[];
  startDate: Date | null;
  startDateIsInherited?: boolean;
  isStartDateInheritable?: boolean;
  weighting: number | string;
  audioCreatives: ICreativeAudio[];
  creativeRedirect: ICreativeRedirect;
  campaignName: string;
  campaignId: string;
  campaignStartDate: Date | null;
  imageCreatives: ICreativeImage[];
  targetingGroups: ITargetingGroup[];
  targetingMasterTemplates: ITargetingMasterTemplate[];
  targetingGeneralGroup: ITargetingGroup | null;
  tags: OptionType[];
  tagsAreInherited: boolean;
  inheritedTags: OptionType[];
  journey: string;
  status?: AdStatus;
  dailyCap: string;
  adExclusive: boolean;
  territory: Territory;
  targetingVersion: TargetingVersion;
  targetingDefinitionV2: ITargetingDefinitionV2 | null;
}

export interface IAdStatusValues {
  value: AdStatus;
  label: string;
  isDisabled?: (value: AdStatus) => boolean;
}

export const adStatusValues: IAdStatusValues[] = [
  {
    value: AdStatus.Disabled,
    label: 'Disabled',
  },
  {
    value: AdStatus.Draft,
    label: 'Draft',
    isDisabled: (value: AdStatus) =>
      [
        AdStatus.Ready,
        AdStatus.Live,
        AdStatus.Paused,
        AdStatus.Completed,
      ].includes(value),
  },
  {
    value: AdStatus.Ready,
    label: 'Ready',
    isDisabled: (value: AdStatus) =>
      [
        AdStatus.Draft,
        AdStatus.Live,
        AdStatus.Paused,
        AdStatus.Disabled,
        AdStatus.Completed,
      ].includes(value),
  },
  {
    value: AdStatus.Live,
    label: 'Live',
    isDisabled: (value: AdStatus) =>
      [
        AdStatus.Draft,
        AdStatus.Ready,
        AdStatus.Disabled,
        AdStatus.Completed,
      ].includes(value),
  },
  {
    value: AdStatus.Paused,
    label: 'Paused',
    isDisabled: (value: AdStatus) =>
      [
        AdStatus.Draft,
        AdStatus.Ready,
        AdStatus.Disabled,
        AdStatus.Completed,
      ].includes(value),
  },
  {
    value: AdStatus.Completed,
    label: 'Completed',
    isDisabled: () => true,
  },
];

export const getAdStatusData = memoizeOne(
  (
    values: IAdValues,
    campaignStatus: CampaignStatus,
    campaignTimeZone: TimeZones
  ) => {
    let statusData = [
      {
        status: AdStatus.Draft,
        required: [
          ...(!values.name ? ["Complete 'Name'"] : []),
          ...(!values.campaignName ? ['Complete Parent Campaign'] : []),
        ],
      },
      {
        status: AdStatus.Ready,
        required: [
          ...(!values.name ? ['Waiting for Name'] : []),
          ...(!(
            campaignStatus === CampaignStatus.Ready ||
            campaignStatus === CampaignStatus.Live
          )
            ? ['Waiting for Parent Campaign to be in READY or LIVE state']
            : []),
          ...(!values.startDate ? ['Waiting for Start Date'] : []),
          ...(!values.duration ? ['Waiting for Ad Duration'] : []),
          ...(values.targetingVersion === TargetingVersion.TargetingV1 &&
          !(
            values.targetingGroups.length ||
            values.targetingMasterTemplates.length
          )
            ? ['Waiting for at least one Targeting Group']
            : []),
        ],
      },
      {
        status:
          values.status === AdStatus.Paused ? AdStatus.Paused : AdStatus.Live,
        required: [
          ...(campaignStatus !== CampaignStatus.Live
            ? ['Waiting for Parent Campaign to be in LIVE state']
            : []),
          ...((values.startDate &&
            dateUtils.isDateInTheFuture(values.startDate, campaignTimeZone)) ||
          !values.startDate
            ? ['Waiting for Start Date']
            : []),
          ...(!(values.creativeRedirect.url || values.audioCreatives.length)
            ? ['Waiting for either a Creative Audio or Creative Redirect']
            : []),
        ],
      },
      {
        status: AdStatus.Completed,
        required: ['Waiting for End Date'],
      },
    ];

    if (values.status === AdStatus.Disabled) {
      statusData = [
        {
          status: AdStatus.Disabled,
          required: [],
        },
        ...statusData,
      ];
    }
    return statusData;
  }
);

export const adDetailsFields = [
  'name',
  'weighting',
  'dailyCap',
  'startDate',
  'endDate',
  'frequencyCapCount',
  'frequencyCapTimeUnit',
  'frequencyCapValue',
  'duration',
  'territory',
];
