import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UsersTable from 'features/user/components/UsersTable/UsersTable';
import { History } from 'history';
import { useAllUsersQuery, User } from 'interfaces/generated.types';
import React from 'react';
import { allTerritories } from 'utils/territory';

interface IUsersPageProps {
  history: History;
}

const UsersPage = ({ history }: IUsersPageProps) => {
  const { loading, data } = useAllUsersQuery({
    errorPolicy: 'all',
    variables: {
      territories: allTerritories,
    },
  });
  const classes = pageStyles();

  if (!loading && !data?.allUsers) return <Redirecting history={history} />;

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Users Page</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <UsersTable history={history} allUsers={data?.allUsers as User[]} />
          )}
        </div>
      </Container>
    </main>
  );
};

export default UsersPage;
