import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, Ref } from 'react';

import {
  Divider as MUIDivider,
  DividerProps as MUIDividerProps,
} from '@material-ui/core';

import useStyles from './Divider.styles';

export type DividerProps = MUIDividerProps;

const Divider = forwardRef((props: DividerProps, ref: Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUIDivider ref={ref} classes={classes} {...otherProps} />;
});

Divider.displayName = 'Divider';

export default Divider;
