import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import React from 'react';
import { currencyValues } from 'utils/currency';
import {
  currenciesBasedOnTerritories,
  getAvailableCurrencyOptions,
} from 'utils/insights';

import { MenuItem, TextField } from '@material-ui/core';

import useStyles from './CurrencyDropdown.styles';

interface ICurrencyDropdownProps {
  setSelectedCurrency: (newSelectedCurrency: any) => void;
  selectedCurrency: any;
}

const CurrencyDropdown = ({
  setSelectedCurrency,
  selectedCurrency,
}: ICurrencyDropdownProps) => {
  const classes = useStyles();

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCurrency(event.target.value);
  };

  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();

  return (
    <TextField
      name="currencyDropdown"
      id="currencyDropdown"
      select
      value={selectedCurrency}
      onChange={handleChange}
      SelectProps={{
        className: classes.dropdownField,
      }}
    >
      {getAvailableCurrencyOptions(
        currencyValues,
        currenciesBasedOnTerritories(territories!)
      ).map((currency) => (
        <MenuItem key={currency.value} value={currency.value}>
          {currency.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CurrencyDropdown;
