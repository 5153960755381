import FileSaver from 'file-saver';
import { FieldArray, getIn, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';

import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import CreativeLink from 'components/CreativeLink/CreativeLink';
import ErrorToast from 'components/ErrorToast/ErrorToast';
import NumberInput from 'components/NumberInput/NumberInput';
import StyledButton, {
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { IFormProps } from 'interfaces';
import { CreativeType } from 'interfaces/generated.types';

import creativeUtils from 'utils/creatives';

import useStyles from './CreativeContainer.styles';

interface ICreativeContainerProps {
  creativeType: CreativeType;
  attribute: string;
  index: number;
  remove: (index: number) => void;
  hideUrls?: boolean;
  setFieldValue: (field: string, value: any) => void;
}

const CreativeContainer = ({
  attribute,
  creativeType,
  errors,
  setFieldValue,
  handleBlur,
  handleChange,
  index,
  remove,
  touched,
  values,
  hideUrls,
}: IFormProps<any> & ICreativeContainerProps) => {
  const [presignedUrl, setPreSignedUrl] = useState('');
  const [isToastOpen, setToastOpen] = useState(false);
  const isImageType = creativeType === CreativeType.Image;
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={2} classes={{ root: classes.centerGrid }}>
            <StyledButton
              variant={ButtonVariantEnum.Text}
              onClick={() => {
                if (!isImageType) {
                  setFieldValue('duration', '');
                }
                remove(index);
              }}
              data-tc={
                isImageType
                  ? 'removeCreativeImageButton'
                  : 'removeCreativeAudioButton'
              }
            >
              <CloseIcon />
              <span className="sr-only">
                Remove {values[attribute][index].name} from {values.adName}
              </span>
            </StyledButton>
          </Grid>
          <Grid item xs={10}>
            <div>
              <CreativeLink
                fullWidthAudio
                setFieldValue={setFieldValue}
                retrieveUrl={(url: string) => setPreSignedUrl(url)}
                fileName={values[attribute][index].fileName}
                url={values[attribute][index].url}
                type={isImageType ? CreativeType.Image : CreativeType.Audio}
                dataTc={`${
                  isImageType
                    ? 'creativeUploadImageThumbnail'
                    : 'creativeUploadAudioPlayer'
                }`}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={2} classes={{ root: classes.centerGrid }}>
            {values[attribute][index].altId && (
              <p
                className={classes.creativeId}
                data-tc={
                  isImageType ? 'creativeImageAltId' : 'creativeAudioAltId'
                }
              >
                ID: {values[attribute][index].altId}
              </p>
            )}
          </Grid>
          <Grid item xs={10}>
            <div className={classes.detailsCreative}>
              <p
                data-tc={
                  isImageType ? 'creativeImageName' : 'creativeAudioName'
                }
                className={classes.detailsName}
              >
                {values[attribute][index].name}
              </p>
              <StyledButton
                type="button"
                data-tc="creativeDownload"
                variant={ButtonVariantEnum.Text}
                onClick={() => {
                  if (creativeUtils.isValidCreativeUrl(presignedUrl)) {
                    FileSaver.saveAs(
                      presignedUrl,
                      values[attribute][index].fileName
                    );
                  } else {
                    setToastOpen(true);
                  }
                }}
                disabled={!presignedUrl}
              >
                <GetAppIcon />
                <span className="sr-only">Download Creative</span>
              </StyledButton>
              <ErrorToast
                isToastOpen={isToastOpen}
                setToastOpen={setToastOpen}
              />
            </div>
            {!isImageType &&
              values.audioCreatives &&
              errors.audioCreatives &&
              (errors as any).audioCreatives[index] && (
                <FormHelperText error className={classes.durationError}>
                  {(errors as any).audioCreatives[index].duration}
                </FormHelperText>
              )}
            {isImageType && !hideUrls ? (
              <div className={classes.landingPageUrl}>
                <Field
                  component={TextField}
                  label="Click-through URL"
                  name={`${attribute}.${index}.landingPageUrl`}
                  data-tc={`${attribute}LandingPageUrl`}
                  data-testid="creativelandingPageUrl"
                  fullWidth
                  helperText={
                    getIn(touched, `${attribute}.${index}.landingPageUrl`) &&
                    getIn(errors, `${attribute}.${index}.landingPageUrl`)
                  }
                  FormHelperTextProps={{
                    error: !!(
                      getIn(touched, `${attribute}.${index}.landingPageUrl`) &&
                      getIn(errors, `${attribute}.${index}.landingPageUrl`)
                    ),
                  }}
                />
              </div>
            ) : null}
            {!hideUrls ? (
              <div>
                <FieldArray
                  name={`${attribute}.${index}.trackingUrls`}
                  render={({
                    remove: removeTrackingUrl,
                    insert: addTrackingUrl,
                  }) =>
                    !!values[attribute][index].trackingUrls &&
                    values[attribute][index].trackingUrls.map(
                      (_: any, trackingUrlIndex: number) => (
                        /* Formik FieldArray uses index to track array elements */
                        /* eslint-disable react/no-array-index-key */
                        <div
                          key={`trackingUrl-${trackingUrlIndex}`}
                          className={classes.trackingUrlsItems}
                        >
                          <div className={classes.trackingUrlItem}>
                            <Field
                              component={TextField}
                              label="Tracking URL"
                              placeholder="Tracking URL"
                              data-tc={`${attribute}TrackingUrl`}
                              data-testid="trackingUrl"
                              name={`${attribute}.${index}.trackingUrls.${trackingUrlIndex}.url`}
                              fullWidth
                              helperText={
                                getIn(
                                  touched,
                                  `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.url`
                                ) &&
                                getIn(
                                  errors,
                                  `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.url`
                                )
                              }
                              FormHelperTextProps={{
                                error: !!(
                                  getIn(
                                    touched,
                                    `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.url`
                                  ) &&
                                  getIn(
                                    errors,
                                    `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.url`
                                  )
                                ),
                              }}
                            />
                            <NumberInput
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values[attribute][index].trackingUrls[
                                  trackingUrlIndex
                                ].consentVendorId || ''
                              }
                              allowNegative={false}
                              dataTc={`${attribute}TrackingUrlConsentVendorId`}
                              decimalScale={0}
                              name={`${attribute}.${index}.trackingUrls.${trackingUrlIndex}.consentVendorId`}
                              touched={getIn(
                                touched,
                                `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.consentVendorId`
                              )}
                              error={getIn(
                                errors,
                                `${attribute}.${index}.trackingUrls.${trackingUrlIndex}.consentVendorId`
                              )}
                              label="Consent Vendor Id"
                              fullWidth
                            />
                          </div>

                          {values[attribute][index].trackingUrls.length > 1 && (
                            <StyledButton
                              type="button"
                              data-tc={`${attribute}TrackingUrlRemoveButton`}
                              data-testid="trackingUrlRemoveButton"
                              variant={ButtonVariantEnum.Text}
                              onClick={() =>
                                removeTrackingUrl(trackingUrlIndex)
                              }
                            >
                              <CloseIcon />
                              <span className="sr-only">
                                Remove the tracking url grouping from the
                                creative
                              </span>
                            </StyledButton>
                          )}
                          <StyledButton
                            type="button"
                            data-tc={`${attribute}TrackingUrlAddButton`}
                            data-testid="trackingUrlAddButton"
                            variant={ButtonVariantEnum.Text}
                            onClick={() =>
                              addTrackingUrl(trackingUrlIndex + 1, {
                                url: '',
                                consentVendorId: '',
                              })
                            }
                            disabled={
                              values[attribute][index].trackingUrls.length >= 5
                            }
                          >
                            <AddIcon />
                            <span className="sr-only">
                              Add a new tracking url to the creative
                            </span>
                          </StyledButton>
                        </div>
                      )
                    )
                  }
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreativeContainer;
