import { ICustomKvp } from 'features/targetingV2/types/common';
import {
  IAudienceTargeting,
  ICustomParameter,
  ICustomParameterGroup,
  ICustomTargeting,
  IDayTimeTargeting,
  IInventoryTargeting,
  ILimitLocationParameterValue,
  ILocationTargeting,
  IParameter,
  ITargetingDefinitionV2,
  ITechnologyTargeting,
} from 'features/targetingV2/types/targeting';
import {
  AudienceParameterType,
  Clusivity,
  LocationParameterType,
  TargetingGrade,
  TimeZoneTargeting,
} from 'interfaces/generated.types';

export const defaultEnabledGrades = [TargetingGrade.Grade_4];

export const getDefaultEnabledGrades = (parameterType?: string) => [
  ...(parameterType === AudienceParameterType.Interest
    ? [TargetingGrade.Grade_2, TargetingGrade.Grade_3]
    : defaultEnabledGrades),
];

export const defaultParameter: IParameter = {
  type: '',
  clusivity: Clusivity.Include,
  values: [],
};

export const defaultParameterGroup = {
  parameters: [defaultParameter],
};

export const defaultAudienceParameter = {
  type: '',
  clusivity: Clusivity.Include,
  values: [],
  enabledGrades: defaultEnabledGrades,
};

export const defaultAudienceParameterGroup = {
  parameters: [defaultAudienceParameter],
};

export const defaultAudienceTargeting: IAudienceTargeting = {
  audiences: [],
};

export const defaultTechnologyTargeting: ITechnologyTargeting = {
  technologies: [],
};

export const defaultLimitLocationParameterValue: ILimitLocationParameterValue =
  {
    id: '',
  };

export const defaultLocationParameter = {
  type: LocationParameterType.Geo,
  clusivity: Clusivity.Include,
  values: [],
};

export const defaultLocationParameterGroup = {
  limitLocationParameterValue: defaultLimitLocationParameterValue,
  accuracyRadius: -1,
  parameters: [],
};

export const defaultLocationTargeting: ILocationTargeting = {
  locations: [defaultLocationParameterGroup],
};

export const defaultInventoryTargeting: IInventoryTargeting = {
  inventories: [],
};

export const defaultDayTimeTargeting: IDayTimeTargeting = {
  clusivity: Clusivity.Include,
  timeZone: TimeZoneTargeting.Demand,
  dayTimePoints: [],
};

export const defaultCustomKvp: ICustomKvp = { key: '', value: '' };

export const defaultCustomParameter: ICustomParameter = {
  clusivity: Clusivity.Include,
  customKvps: [],
};

export const defaultCustomParameterGroup: ICustomParameterGroup = {
  parameters: [defaultCustomParameter],
};

export const defaultCustomTargeting: ICustomTargeting = {
  customGroups: [],
};

export const defaultTargetingDefinitionV2: ITargetingDefinitionV2 = {
  audienceTargeting: defaultAudienceTargeting,
  locationTargeting: defaultLocationTargeting,
  inventoryTargeting: defaultInventoryTargeting,
  technologyTargeting: defaultTechnologyTargeting,
  dayTimeTargeting: null,
  customTargeting: defaultCustomTargeting,
};

export const getDefaultPairedParameter = (
  selectedParameterType: string,
  oppositeClusivity: Clusivity
) => ({
  type: selectedParameterType,
  clusivity: oppositeClusivity,
  values: [],
});
