import { TargetingTemplateStatus } from 'interfaces/generated.types';

export const targetingTemplateStatusValues = [
  {
    value: TargetingTemplateStatus.Created,
    label: 'Created',
  },
  {
    value: TargetingTemplateStatus.Deleted,
    label: 'Deleted',
  },
];

export const targetingTemplateDetailsFields = [
  'name',
  'description',
  'targetingGroups',
];
