import debounce from 'debounce-promise';
import { getIn } from 'formik';
import get from 'lodash/get';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import { AsyncMultiSelect } from 'components/MultiSelect/MultiSelect';

import { SEARCH_POSTCODES } from 'graphql/postcodes/queries';

import { OptionType, IFormProps } from 'interfaces';

import { dedupeValues } from 'utils/dataTransformation';

interface IPostcodeSearch {
  name: string;
  label: string;
  placeholder: string;
  dataTc: string;
  isDisabled?: boolean;
  selectAll?: boolean;
}

const formatData = (data: string[]) =>
  data.map((postcode: string) => ({
    label: postcode,
    value: postcode,
  }));

export const loadOptions = async (
  value: string,
  client: any,
  setFetchedOptions: any
) => {
  if (value.length >= 2) {
    const { data } = await client.query({
      query: SEARCH_POSTCODES,
      variables: { postcode: value },
    });

    if (data) {
      const formattedData = formatData(data.searchPostcodes);
      setFetchedOptions(formattedData);
      return formattedData;
    }

    return [];
  }

  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 10, {
  leading: true,
});

const PostcodeSearch = (props: IFormProps<any> & IPostcodeSearch) => {
  const {
    name,
    label,
    placeholder,
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    dataTc,
    isDisabled = false,
    selectAll = true,
  } = props;
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const value = dedupeValues(get(values, name)).filter(
    (val: OptionType) => val.label !== null
  );

  const isClearable = value.some((v: any) => v.readOnly);

  return (
    <ApolloConsumer>
      {(client) => (
        <AsyncMultiSelect
          id={label}
          dataTc={`${dataTc}Select`}
          isMulti
          isDisabled={isDisabled}
          isClearable={!isClearable}
          label={label}
          placeholder={placeholder || label}
          name={name}
          onBlur={() => setFieldTouched(name, true)}
          onChange={(fieldValue: any) => {
            setFieldValue(name, fieldValue);
          }}
          selectAll={selectAll}
          loadOptions={(option: string) =>
            debouncedLoadOptions(option, client, setFetchedOptions)
          }
          fetchedOptions={fetchedOptions}
          value={value}
          attributes={{
            fieldAttribute: `${name}Field`,
            menuAttribute: `${name}MenuOptions`,
            chipAttribute: `${name}Chip`,
          }}
          errorProps={{
            helperText: getIn(touched, name) && getIn(errors, name),
            FormHelperTextProps: {
              error: !!(getIn(touched, name) && getIn(errors, name)),
            },
          }}
        />
      )}
    </ApolloConsumer>
  );
};

export default PostcodeSearch;
