import { baseTabStyles } from 'assets/styles/components/Tab.styles';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...baseTabStyles(theme),
    content: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0.625, 2.5),
      position: 'relative',
      borderTopRightRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    subtitle: {
      fontSize: '0.813em',
      color: theme.palette.grey[400],
    },
    modalButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
    },
    creative: {
      marginBottom: theme.spacing(4),
      '& + &': {
        marginTop: theme.spacing(8),
      },
      '&--multi + &--multi': {
        marginTop: 0,
      },
    },
    heading: {
      fontSize: '1em',
      fontWeight: 'normal',
    },
    tabsWrapper: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
    },
  })
);

export default useStyles;
