import { baseFormStyles } from 'assets/styles/components/Form.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFormStyles(theme),
  campaignRestrictionsWrapper: {
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
