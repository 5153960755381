import { ReactComponent as ClearIcon } from 'assets/icons/TargetingV2/dax-cross.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import ClusivitySelect from 'features/targetingV2/components/ClusivitySelect/ClusivitySelect';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import MultiSelect from 'features/targetingV2/components/core/MultiSelect/MultiSelect/MultiSelect';
import Typography from 'features/targetingV2/components/core/Typography';
import {
  ICustomKvpError,
  ICustomKvpOption,
  IOptionType,
} from 'features/targetingV2/types/common';
import { ICustomParameter } from 'features/targetingV2/types/targeting';
import {
  enrichCustomKvpOptions,
  formatNewCustomKvpValues,
} from 'features/targetingV2/utils/customParameter';
import { defaultCustomParameter } from 'features/targetingV2/utils/defaults';
import { SelectType } from 'features/targetingV2/utils/multiSelect';
import React from 'react';

import useStyles from './CustomParameterRow.styles';

export interface ICustomParameterRowProps {
  fieldName: string;
  parameter: ICustomParameter;
  customKvpsErrors: (ICustomKvpError | undefined)[] | undefined;
  setFieldValue: (field: string, value: any) => void;
  removeParameter: () => void;
  rowId: string;
}

const CustomParameterRow = (props: ICustomParameterRowProps) => {
  const {
    setFieldValue,
    fieldName,
    parameter,
    customKvpsErrors,
    removeParameter,
    rowId,
  } = props;

  const classes = useStyles();

  const handleChange = (newValues: ICustomKvpOption[]) => {
    const previouslyCreatedValues: ICustomKvpOption[] = [];
    const newlyCreatedValues: IOptionType[] = [];

    newValues.forEach((newValue) => {
      if (newValue.keyValuePair) previouslyCreatedValues.push(newValue);
      else newlyCreatedValues.push(newValue);
    });

    const formattedNewValues = formatNewCustomKvpValues(newlyCreatedValues);

    setFieldValue(
      `${fieldName}.customKvps`,
      previouslyCreatedValues.concat(formattedNewValues)
    );
  };

  const removeOption = {
    MenuItemIcon: <RemoveIcon />,
    menuItemIconLabel: 'Remove',
    menuItemAction: removeParameter,
  };

  const shouldShowClearOption =
    parameter.clusivity !== defaultCustomParameter.clusivity ||
    parameter.customKvps.length > 0;

  const clearOption = {
    MenuItemIcon: <ClearIcon />,
    menuItemIconLabel: 'Clear',
    menuItemAction: () => setFieldValue(fieldName, defaultCustomParameter),
  };

  const contextMenuOptions = [
    ...(shouldShowClearOption ? [clearOption] : []),
    removeOption,
  ];

  return (
    <div className={classes.rowWrapper} data-testid={fieldName}>
      <div className={classes.labelContainer}>
        <Typography variant="body2" className={classes.label}>
          Custom Parameter
        </Typography>
      </div>
      <ClusivitySelect
        selectedClusivity={parameter.clusivity}
        fieldName={`${fieldName}.clusivity`}
        setFieldValue={setFieldValue}
      />
      <div className={classes.selectContainer}>
        <MultiSelect
          options={[]}
          id={`${fieldName}-dropdown`}
          name="customKvpsSelect"
          value={enrichCustomKvpOptions(parameter.customKvps, customKvpsErrors)}
          selectType={SelectType.Creatable}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Menu: () => null,
          }}
          onChange={handleChange}
          isClearable={false}
          externalClasses={{ input: classes.input }}
          placeholder="Type key = value"
          clearSearchTermOnSelection
        />
      </div>
      <IconButtonMenu
        menuIconButtonLabel={rowId}
        menuOptions={contextMenuOptions}
      />
    </div>
  );
};

export default CustomParameterRow;
