import classNames from 'classnames';
import ErrorDialog, {
  ISetErrorModal,
} from 'components/ErrorDialog/ErrorDialog';
import MessageDialog from 'components/MessageDialog/MessageDialog';
import Tab, { ITabProps } from 'components/Tab/Tab';
import WarningDialog, {
  ISetWarningModal,
} from 'components/WarningDialog/WarningDialog';
import { UPDATE_TABLE_FILTERS } from 'context/SessionProvider/reducer';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { History } from 'history';
import useMessage from 'hooks/Message/useMessage';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { ILocationState } from 'interfaces';
import { EntityStatus } from 'interfaces/generated.types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import AppBar from '@material-ui/core/AppBar/AppBar';
import MTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs/Tabs';

import useTabStyles from '../Tab/Tab.styles';
import useTableStyles from './Table.styles';
import TableFilter from './TableFilter';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableLoader from './TableLoader';
import TableToolbar from './TableToolbar';

export interface ICloneCellData {
  entity: {
    id: string;
    name: string;
  };
  cloneChildren: boolean;
  hasChildren: boolean;
  isEdit: boolean;
  setErrorModal: (values: ISetErrorModal) => void;
}

export interface IDeleteCellData {
  entity: {
    id: string;
    name: string;
  };
  setErrorModal: (values: ISetErrorModal) => void;
}

export interface IValidateCellData {
  entity: {
    id: string;
    targetStatus: EntityStatus;
  };
  setErrorModal: (values: ISetErrorModal) => void;
  setWarningModal: (values: ISetWarningModal) => void;
  setUpdating: (value: boolean) => void;
  handleContinue: () => void;
}

export interface IResendCellData {
  entity: {
    id: string;
    name: string;
  };
  setErrorModal: (values: ISetErrorModal) => void;
  setMessageModal: (value: { hasMessage: boolean }) => void;
  setUpdating: (value: boolean) => void;
}

interface ITableProps {
  columns: Column[];
  data: any[];
  onCellUpdate?: (
    row: any,
    setErrorModal: any,
    setUpdating: any
  ) => Promise<void>;
  onCellValidate?: ({
    entity,
    setErrorModal,
    setWarningModal,
    handleContinue,
  }: IValidateCellData) => Promise<void>;
  onCellDelete?: ({ entity, setErrorModal }: IDeleteCellData) => Promise<void>;
  onCellClone?: ({
    entity,
    cloneChildren,
    hasChildren,
    isEdit,
    setErrorModal,
  }: ICloneCellData) => Promise<void>;
  onCellResend?: ({
    entity,
    setErrorModal,
    setMessageModal,
    setUpdating,
  }: IResendCellData) => Promise<void>;
  isEditable?: boolean;
  title?: string;
  errorMessage?: string;
  dataTc: string;
  showColumnToggle?: boolean;
  isPageTable?: boolean;
  hiddenColumns?: string[];
  history?: History;
  tabs?: ITabProps[];
  selectedTab?: number;
  onTabChange?(e: React.ChangeEvent<{}>, index: number): void;
  onHiddenColumnsChange?: (columns: string[]) => void;
  customToolbarCtas?: React.ReactNode;
  hideToolbar?: boolean;
  CustomHeader?: React.ElementType<any>;
}

export interface ITableErrorState {
  isOpened: boolean;
  errors: string[];
  handleContinue?: () => void;
  content: { title: string; closeButton: string; continueButton: string };
}

const getStyles = (props: any, style = {}) => [
  props,
  {
    style,
  },
];

export const updatePaginationInHistory = (
  history: History,
  locationState: ILocationState,
  index: number
) => {
  const { table } = locationState || 0;

  history &&
    history.push({
      pathname: window.location.pathname,
      state: {
        ...locationState,
        table: { ...table, pagination: index },
      },
    });
};

const Table = ({
  columns,
  data,
  title,
  onCellUpdate,
  onCellValidate,
  onCellClone,
  onCellDelete,
  onCellResend,
  isEditable = true,
  showColumnToggle = true,
  errorMessage,
  dataTc,
  isPageTable = true,
  hiddenColumns = [],
  history,
  tabs,
  selectedTab,
  onTabChange,
  onHiddenColumnsChange,
  customToolbarCtas,
  hideToolbar = false,
  CustomHeader,
}: ITableProps) => {
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [paginationRowsPerPage, setPaginationRowsPerPage] = useState(15);

  const location = usePreviousLocation();

  const [{ isOpened, errors, handleContinue, content }, setErrorModal] =
    useState<ITableErrorState>({
      isOpened: false,
      errors: [],
      content: { title: 'Error', closeButton: 'Close', continueButton: '' },
    });
  const {
    hasWarning,
    handleWarningContinue,
    setWarningModal,
    toggleWarningModal,
  } = useWarning();
  const {
    hasMessage,
    setMessageModal,
    toggleMessageModal,
    messageContent,
    setMessageContent,
  } = useMessage();
  const [isUpdating, setUpdating] = useState(false);
  const toggleErrorModal = () =>
    setErrorModal((prevState: ITableErrorState) => ({
      ...prevState,
      isOpened: !prevState.isOpened,
    }));

  const defaultColumn = useMemo(
    () => ({
      Filter: TableFilter,
    }),
    []
  );

  const { state: appState, dispatch } = useSessionContext();

  const storedFilters =
    dataTc && appState.pages[dataTc]?.filters
      ? appState.pages[dataTc]?.filters
      : [];
  const storedGlobalFilter =
    dataTc && appState.pages[dataTc]?.globalFilter
      ? appState.pages[dataTc]?.globalFilter
      : '';

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: {
      globalFilter,
      pageIndex,
      pageSize,
      filters,
      hiddenColumns: currentHiddenColumns,
    },
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    allColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      onCellUpdate,
      onCellValidate,
      onCellDelete,
      onCellClone,
      onCellResend,
      setErrorModal,
      setWarningModal,
      setMessageModal,
      setMessageContent,
      setUpdating,
      isEditable,
      initialState: {
        pageSize: paginationRowsPerPage,
        hiddenColumns: ['id', ...hiddenColumns],
        filters: storedFilters,
        globalFilter: storedGlobalFilter,
      },
      useControlledState: (state) =>
        useMemo(
          () => ({
            ...state,
            pageIndex: paginationIndex,
            pageSize: paginationRowsPerPage,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, paginationIndex, paginationRowsPerPage]
        ),
      autoResetFilters: false,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const cellProps = (props: any, { cell }: any) =>
    getStyles(props, cell.column && cell.column.style);
  const classes = useTableStyles();
  const tabClasses = useTabStyles();

  useEffect(() => {
    dispatch({
      type: UPDATE_TABLE_FILTERS,
      payload: { parentPageName: dataTc, filters, globalFilter },
    });

    setPaginationIndex(0);
  }, [dispatch, filters, globalFilter, dataTc]);

  useEffect(() => {
    if (onHiddenColumnsChange)
      onHiddenColumnsChange(currentHiddenColumns || []);
  }, [currentHiddenColumns, onHiddenColumnsChange]);

  useEffect(() => {
    const { table } = location.state || 0;
    const { pagination, rowsPerPage } = table || 0;

    if (pagination) setPaginationIndex(pagination);
    if (rowsPerPage) setPaginationRowsPerPage(rowsPerPage);
  }, [location]);

  const handleTabChange = () => {
    setPaginationIndex(0);

    if (history) updatePaginationInHistory(history, location.state, 0);
  };

  return (
    <div
      className={classNames(classes.root, {
        [`${classes.root}--updating`]: isUpdating,
      })}
    >
      {CustomHeader && (
        <CustomHeader
          setAllFilters={setAllFilters}
          filters={filters}
          data={data}
          history={history}
        />
      )}
      {!hideToolbar && (
        <TableToolbar
          title={title}
          columns={allColumns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setAllFilters={setAllFilters}
          errorMessage={errorMessage}
          showColumnToggle={showColumnToggle}
          isPageTable={isPageTable}
          customToolbarCtas={customToolbarCtas}
        />
      )}
      <TableContainer>
        {tabs && (
          <AppBar position="static" classes={{ root: tabClasses.bar }}>
            <Tabs
              value={selectedTab}
              data-tc={`${dataTc}-tabs`}
              onChange={(e, index) => {
                handleTabChange();
                if (onTabChange) onTabChange(e, index);
              }}
            >
              {tabs.map((tab) => (
                <Tab label={tab.label} dataTc={tab.dataTc} key={tab.dataTc} />
              ))}
            </Tabs>
          </AppBar>
        )}
        <MTable
          {...getTableProps()}
          data-tc={dataTc}
          data-testid={dataTc}
          className={classes.table}
        >
          <caption className="sr-only">{title}</caption>
          <TableHeader headerGroups={headerGroups} tabTable={!!tabs} />
          <TableBody {...getTableBodyProps()} className={classes.body}>
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                /* eslint-disable react/jsx-key */
                <TableRow
                  {...row.getRowProps()}
                  data-tc={(row.original as any).id}
                  className={classes.row}
                >
                  {row.cells.map((cell: Cell) => (
                    /* eslint-disable react/jsx-key */
                    <TableCell
                      {...cell.getCellProps(cellProps)}
                      className={classes.cell}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter
            totalItems={rows.length}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            setPaginationIndex={setPaginationIndex}
            setPaginationRowsPerPage={setPaginationRowsPerPage}
            history={history}
          />
        </MTable>
      </TableContainer>
      {isUpdating ? <TableLoader /> : null}
      {isOpened ? (
        <ErrorDialog
          content={{
            title: content.title,
            closeButton: content.closeButton,
            continueButton: content.continueButton,
          }}
          handleContinue={handleContinue}
          isOpen={isOpened}
          handleClose={toggleErrorModal}
          dataTc={`${dataTc}Dialog`}
          errorMessages={errors}
        />
      ) : null}
      {hasWarning ? (
        <WarningDialog
          handleContinue={handleWarningContinue}
          handleClose={toggleWarningModal}
          isOpen={hasWarning}
          dataTc={`${dataTc}WarningDialog`}
        />
      ) : null}
      {hasMessage && (
        <MessageDialog
          isModalOpen={hasMessage}
          handleClose={toggleMessageModal}
          dataTc={`${dataTc}MessageDialog`}
          content={messageContent}
        />
      )}
    </div>
  );
};

export default Table;
