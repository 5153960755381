import * as Yup from 'yup';

import { filterKvps } from 'utils/dataControl';

import commons from 'validations/commons';

const TechnicalProviderFormValidation = Yup.object().shape({
  name: commons.name('Name'),
  territories: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    )
    .required(
      'Programmatic Connector should be assigned at least one territory'
    ),
  userSyncUrl: commons.validUrl({
    message: 'User Sync Url must be a valid url',
    fieldKey: 'userSyncUrl',
  }),
  userSyncSecureUrl: commons.validUrl({
    message: 'User Sync Secure Url must be a valid secure url',
    fieldKey: 'userSyncSecureUrl',
    protocols: ['https'],
    requiredProtocol: true,
  }),
  currency: commons.currency('currency'),
  kvps1stPartyData: Yup.string()
    .ensure()
    .test(
      'length',
      'Each entry can only be 20 characters long. Please reduce one of your keys.',
      (kvps: string) => !filterKvps(kvps).find((kvp) => kvp.length > 20)
    ),
  appBundle: commons.max255Characters(),
  publisherId: commons
    .max255Characters()
    .required('Publisher Id is a required field'),
  publisherTagId: commons
    .max255Characters()
    .required('Publisher Tag Id is a required field'),
  bidFloorPrecision: Yup.string().required(
    'Currency Precision is a required field'
  ),
});

export default TechnicalProviderFormValidation;
