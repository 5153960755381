import { FormControl, Grid, InputLabel, TextField } from '@material-ui/core';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { FrequencyCapTimeUnits } from 'interfaces';
import { Campaign } from 'interfaces/generated.types';
import * as React from 'react';

import useStyles from './CampaignModalDetails.styles';

interface ICampaignModalDetailsProps {
  campaign: Campaign;
}

const CampaignModalDetails = ({ campaign }: ICampaignModalDetailsProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={8}>
        <div className={classes.textFieldLinkContainer}>
          <TextField
            label="Order Name"
            name="orderName"
            data-testid="orderName"
            fullWidth
            value={campaign.order?.name}
            disabled
            InputProps={{
              classes: { root: classes.textfieldLinkWrapper },
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <MultiSelect
          data-tc="campaignOwnerMultiSelect"
          data-testId="campaignOwnerMultiSelect"
          id="campaignOwnerMultiSelect"
          label="Campaign Owner"
          isMulti={false}
          selectAll={false}
          maxSelectHeight={250}
          name="owner"
          noMargin
          options={[
            {
              label: campaign.owner?.email || '',
              value: campaign.owner?.id || '',
              readOnly: true,
            },
          ]}
          value={{
            label: campaign.owner?.email || '',
            value: campaign.owner?.id || '',
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          label="Campaign Name"
          placeholder="Campaign Name"
          value={campaign.name}
          name="name"
          id="campaignName"
          data-tc="campaignName"
          fullWidth
          inputProps={{ 'data-testid': 'campaignName' }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Campaign ID"
          placeholder="Campaign ID"
          name="altId"
          data-testid="campaignId"
          value={campaign.id}
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <NumberInput
          allowNegative={false}
          dataTc="campaignDailyCap"
          decimalScale={0}
          label="Daily Impression Cap"
          name="dailyCap"
          thousandSeparator={ThousandSeparatorTypes.comma}
          value={campaign.dailyCap}
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <NumberInput
          allowNegative={false}
          dataTc="campaignCpm"
          decimalScale={2}
          label="CPM"
          name="cpm"
          thousandSeparator={ThousandSeparatorTypes.comma}
          value={campaign.cpm}
          fullWidth
        />
      </Grid>
      <Grid item xs={4} />

      <Grid item xs={12}>
        <FormControl component={'fieldset' as 'div'} fullWidth>
          <InputLabel shrink>Frequency Capping</InputLabel>
          <div className={classes.frequencyCap}>
            <TextField
              type="number"
              name="frequencyCapCount"
              data-tc="campaignFrequencyCapCount"
              value={campaign.frequencyCapCount}
              classes={{ root: classes.numberFrequencyCap }}
            />
            <span className={classes.impressions}>impressions per</span>
            <TextField
              name="frequencyCapValue"
              data-tc="campaignFrequencyCapValue"
              type="number"
              value={campaign.frequencyCapValue}
              classes={{ root: classes.numberFrequencyCap }}
            />
            <TextField
              value={
                FrequencyCapTimeUnits.find(
                  (unit) => unit.value === campaign.frequencyCapTimeUnit
                )?.label
              }
              name="frequencyCapTimeUnit"
              data-tc="campaignFrequencyCapTimeUnit"
              classes={{ root: classes.selectFrequencyCap }}
            />
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CampaignModalDetails;
