import React, { useMemo } from 'react';
import { History } from 'history';
import { CellProps } from 'react-table';

import TextField from '@material-ui/core/TextField';

import Table from 'components/Table/Table';
import TableDeleteCell from 'components/Table/TableDeleteCell';
import TableUpdateCell from 'components/Table/TableUpdateCell';

import { GET_ALL_TAGS } from 'features/tag/graphql/queries';

import {
  Tag,
  useDeleteTagMutation,
  useUpdateTagMutation,
} from 'interfaces/generated.types';

import { handleCellDelete, handleCellUpdate } from 'utils/tables';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';

interface ITagsTableProps {
  tags: Tag[];
  history: History;
}

interface ITagsRow {
  id: string;
  name: string;
  description: string;
}

const TagsTable = ({ tags, history }: ITagsTableProps) => {
  const [updateTag] = useUpdateTagMutation({
    refetchQueries: [
      {
        query: GET_ALL_TAGS,
      },
    ],
  });
  const [deleteTag] = useDeleteTagMutation({
    refetchQueries: [
      {
        query: GET_ALL_TAGS,
      },
    ],
  });

  const location = usePreviousLocation();

  const createTagCta = (
    <StyledLink
      color={LinkColorEnum.Primary}
      location={{
        pathname: '/reporting-tag',
        state: { parent: location.state },
      }}
      data-tc="createNewTagButton"
    >
      Create New Tag
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            column: { id },
            row,
            value,
          },
          onCellUpdate,
          setErrorModal,
          setUpdating,
        }: CellProps<ITagsRow>) => (
          <TableUpdateCell
            id={id}
            row={row}
            value={value}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setUpdating={setUpdating}
            isEditable
            render={() => value}
            editComponent={(cellValue: string, onChange: any) => (
              <TextField
                name="editTagNameField"
                data-tc="editTagNameField"
                value={cellValue}
                onChange={(event) => onChange(event.target.value)}
              />
            )}
          />
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          wordBreak: 'break-word',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            column: { id },
            row,
            value,
          },
          onCellUpdate,
          setErrorModal,
          setUpdating,
        }: CellProps<ITagsRow>) => (
          <TableUpdateCell
            id={id}
            row={row}
            value={value}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setUpdating={setUpdating}
            isEditable
            render={() => value}
            editComponent={(cellValue: string, onChange: any) => (
              <TextField
                name="editTagDescriptionField"
                data-tc="editTagDescriptionField"
                value={cellValue}
                onChange={(event) => onChange(event.target.value)}
              />
            )}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, name },
            },
          },
          onCellDelete,
          setErrorModal,
          isEditable,
        }: CellProps<ITagsRow>) => (
          <TableDeleteCell
            onCellDelete={onCellDelete}
            setErrorModal={setErrorModal}
            entity={{ id, name }}
            isEditable={isEditable}
            dataTc="tagDelete"
          />
        ),
      },
    ],
    []
  );

  return (
    <Table
      history={history}
      columns={columns}
      data={tags}
      dataTc="listTagsTable"
      onCellUpdate={(row: ITagsRow, setErrorModal: any, setUpdating: any) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            name: row.name,
            description: row.description,
          },
          update: updateTag,
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: '',
          },
        })
      }
      onCellDelete={({ entity, setErrorModal }) =>
        handleCellDelete({
          variables: {
            id: entity.id,
          },
          deleteCell: deleteTag,
          setErrorModal,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: '',
          },
        })
      }
      customToolbarCtas={createTagCta}
    />
  );
};

export default TagsTable;
