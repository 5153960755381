import checkboxStyles from 'assets/styles/components/Checkbox.styles';
import { CloneTypeEnum } from 'components/CloneModal/CloneModalValues';
import Impressions from 'components/Impressions/Impressions';
import Redirecting from 'components/Redirecting/Redirecting';
import Sanities from 'components/Sanities/Sanities';
import { ISanity, sanityCodesMap } from 'components/Sanities/Sanities.values';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import Table, {
  ICloneCellData,
  ITableFetch,
  IValidateCellData,
  updatePaginationInHistory,
} from 'components/Table-new/Table';
import TableSelectFilter from 'components/Table-new/TableSelectFilter';
import { useCtaFooterStyles } from 'components/Table/Table.styles';
import TableCloneCell from 'components/Table/TableCloneCell';
import TableDateRangeFilter, {
  dateWithinRange,
} from 'components/Table/TableDateRangeFilter';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import { dateSort } from 'components/Table/TableSort';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CampaignModal from 'features/direct/campaign/components/CampaignModal/CampaignModal';
import {
  campaignStatusValues,
  pacingMap,
} from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Campaign,
  CampaignFilter,
  CampaignStatus,
  CloneCampaignMutationFn,
  EntityType,
  PageMetaData,
  UpdateCampaignMutationFn,
  useUpdateCampaignMutation,
} from 'interfaces/generated.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import addCommaToImpressions from 'utils/formatImpressionsValue';
import { handleTabHistory } from 'utils/journeys';
import { includesSome } from 'utils/tableFilters';
import {
  handleCellClone,
  handleCellUpdate,
  handleCellValidate,
} from 'utils/tables';

import { Checkbox } from '@material-ui/core';

import { GET_PAGINATED_CAMPAIGNS } from '../../graphql/queries';
import {
  defaultHiddenColumns,
  formatCampaignsData,
  formatFilter,
  formatSort,
  getDefaultTab,
} from './CampaignsTableUtils';

export interface ICampaignsTableProps {
  updateCampaign: UpdateCampaignMutationFn;
  cloneCampaign: CloneCampaignMutationFn;
  validateCampaign: any;
  history: History;
  isEditable: boolean;
  title?: string;
  isPageTable?: boolean;
  externalId?: string;
  orderId?: string;
  allowBulkArchiving?: boolean;
  customToolbarCtas?: React.ReactNode;
  ownerId?: string;
  showTabs?: boolean;
  CustomHeader?: React.ElementType<any>;
  showCustomHeaderOnlyForActiveTab?: boolean;
}

export interface ICampaignsRow {
  id: string;
  altId: number;
  name: string;
  owner: string;
  advertiser: string;
  advertiserId: string;
  startDate: string;
  endDate: string;
  objective: number | null;
  impressions: number | null;
  companionImpressions: number | null;
  sanities: ISanity[];
  deliveryPace: string | null;
  priorityPacing: string;
  status: CampaignStatus;
  externalId: string;
  clickThroughRate: number | null;
}

interface ISelectedCampaignsForArchiving {
  id: string;
  archived: boolean;
}

const CampaignsTable = ({
  updateCampaign,
  cloneCampaign,
  validateCampaign,
  history,
  isEditable,
  isPageTable = true,
  externalId,
  orderId,
  allowBulkArchiving = false,
  customToolbarCtas,
  ownerId,
  showTabs = true,
  CustomHeader,
  showCustomHeaderOnlyForActiveTab = false,
}: ICampaignsTableProps) => {
  const showOnlyOwnedCampaigns = !!ownerId;
  const location = usePreviousLocation();
  const [selectedTab, setSelectedTab] = useState(
    showTabs ? getDefaultTab(location) : 0
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[][]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isDisplayingArchived, setIsDisplayingArchived] = useState<boolean>(
    selectedTab === 1
  );
  const [showBulkArchivingButton, setShowBulkArchivingButton] =
    useState<boolean>(false);

  const [selectedRowsForArchiving, setSelectedRowsForArchiving] = useState<
    ISelectedCampaignsForArchiving[]
  >([]);

  const [updateCampaignMutation] = useUpdateCampaignMutation({
    onCompleted: async () => {
      setShouldRefetchData(true);
      setSelectedRowsForArchiving([]);
      updatePaginationInHistory(history, location.state, 0);
    },
  });

  const checkboxClasses = checkboxStyles();
  const ctaFooterClasses = useCtaFooterStyles();

  const ownerFilter = showOnlyOwnedCampaigns
    ? { owner_id: ownerId }
    : undefined;

  const defaultFilters = useMemo(
    () => ({
      archived: isDisplayingArchived,
      ...ownerFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDisplayingArchived]
  );

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [fetchCampaigns, { loading: fetchingData, data, error }] =
    useLazyQuery<{
      offsetPaginateCampaigns: {
        campaigns: Campaign[];
        pageMetaData: PageMetaData;
      };
    }>(GET_PAGINATED_CAMPAIGNS, {
      variables: {
        filter: defaultFilters,
        orderId: orderId || undefined,
        pageNumber: 0,
        itemsPerPage: 15,
        territories: [activeTerritory],
      },
      onCompleted: () => {
        setLoading(false);
      },
    });

  useEffect(() => {
    if (data && data.offsetPaginateCampaigns?.campaigns) {
      const updatedCampaigns = [...campaigns];
      updatedCampaigns[selectedTab] =
        (data.offsetPaginateCampaigns?.campaigns as Campaign[]) || [];

      setCampaigns(updatedCampaigns);
      setTotalItems(
        data.offsetPaginateCampaigns?.pageMetaData?.totalItems || 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedTab]);

  useEffect(() => {
    setShouldRefetchData(true);
  }, [activeTerritory]);

  const fetchData = useCallback(
    ({
      pageIndex: newPageIndex,
      pageSize: newPageSize,
      search: newSearch,
      filters: newPageFilters,
      sortBy: newSortBy,
    }: ITableFetch) => {
      const newTableVars = {
        pageNumber: newPageIndex,
        itemsPerPage: newPageSize,
        filter: {
          ...defaultFilters,
          ...(newPageFilters &&
            (formatFilter(newPageFilters) as CampaignFilter)),
        },
        ...(newSortBy &&
          newSortBy.length > 0 && { orderable: formatSort(newSortBy) }),
        ...(newSearch && { search: newSearch }),
        territories: [activeTerritory],
        orderId: orderId || undefined,
      };
      fetchCampaigns({ variables: newTableVars });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchCampaigns, defaultFilters, activeTerritory]
  );

  const onTabChange = async (e: React.ChangeEvent<{}>, index: number) => {
    setLoading(true);
    history && handleTabHistory(history, index, 'campaignsTableTab');
    setSelectedTab(index);
    setIsDisplayingArchived(index === 1);
    setSelectedRowsForArchiving([]);
  };

  const handleHiddenColumnsChange = (latestHiddenColumns: string[]) => {
    setShowBulkArchivingButton(!latestHiddenColumns.includes('bulkArchiving'));
  };

  const onBulkArchivingClick = () => {
    selectedRowsForArchiving.forEach((row: ISelectedCampaignsForArchiving) => {
      updateCampaignMutation({
        variables: {
          id: row.id,
          archived: row.archived,
        },
        context: {
          batch: true,
        },
      });
    });
  };

  const bulkArchivingColumnHeader = isDisplayingArchived
    ? 'Unarchive'
    : 'Archive';

  const ownerColumn = !showOnlyOwnedCampaigns
    ? [
        {
          Header: 'Owner',
          accessor: 'owner',
          style: {
            wordBreak: 'break-word',
            width: '140px',
          },
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Campaign Health',
        disableSortBy: true,
        accessor: ({ sanities }: any) =>
          sanities.map((sanity: ISanity) => sanity.name).join(','),
        filter: includesSome,
        Filter: TableSelectFilter,
        selectOptions: Object.keys(sanityCodesMap),
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICampaignsRow>) =>
          Sanities({ sanities: row.original.sanities }),
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          minWidth: '140px',
        },
        Cell: ({ cell: { value, row } }: CellProps<ICampaignsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/campaign/${row.original.id}`,
              state: {
                parent: history.location.state,
                from: location.pathname,
              },
            },
          }),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
        Filter: TableDateRangeFilter,
        filter: dateWithinRange,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
        Filter: TableDateRangeFilter,
        filter: dateWithinRange,
      },
      {
        Header: 'Objective Impressions',
        accessor: 'objective',
        filter: 'objective',
        style: {
          minWidth: '140px',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value, row } }: CellProps<ICampaignsRow>) => {
          const { impressions } = row.original;
          return <Impressions objective={value} impressions={impressions} />;
        },
      },
      {
        Header: 'Actual Impressions',
        accessor: 'impressions',
        Cell: ({ cell: { value } }: CellProps<ICampaignsRow>) =>
          addCommaToImpressions(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        selectOptions: Object.keys(CampaignStatus),
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ICampaignsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: campaignStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={campaignStatusValues}
                name="campaignStatusSelect"
                dataTc="campaignStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Priority (Pacing)',
        accessor: 'pacing',
        Filter: TableSelectFilter,
        filter: includesSome,
        selectOptions: Object.keys(pacingMap),
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<ICampaignsRow>) =>
          row.original.priorityPacing,
      },
      {
        Header: 'Delivery',
        accessor: 'estimatedCompletion',
      },
      {
        Header: 'Pacing Value',
        accessor: 'deliveryPace',
      },
      ...ownerColumn,
      {
        Header: 'Advertiser',
        accessor: 'advertiser',
        Cell: ({ cell: { value, row } }: CellProps<ICampaignsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/advertiser/${row.original.advertiserId}`,
              state: {
                parent: history.location.state,
                from: location.pathname,
              },
            },
          }),
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        Filter: () => null,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: { original },
          },
          onCellClone,
          setErrorModal,
        }: CellProps<ICampaignsRow>) => (
          <>
            <TableCloneCell
              onCellClone={onCellClone}
              cloneChildren
              setErrorModal={setErrorModal}
              entity={{ id: original.id, name: original.name }}
              isEditable={isEditable}
              type={CloneTypeEnum.CAMPAIGN}
              dataTc="cloneCampaign"
            />
            <CampaignModal id={original.id} history={history} />
          </>
        ),
      },
      {
        Header: 'Companion Impressions',
        accessor: 'companionImpressions',
      },
      {
        Header: 'Click Through Rate',
        accessor: 'clickThroughRate',
      },
      {
        Header: 'Listen Through Rate',
        accessor: 'ltr',
      },
      ...(allowBulkArchiving
        ? [
            {
              Header: bulkArchivingColumnHeader,
              accessor: 'bulkArchiving',
              disableFilters: true,
              Filter: () => null,
              disableSortBy: true,
              // eslint-disable-next-line react/display-name
              Cell: ({ cell: { row } }: CellProps<ICampaignsRow>) => (
                <Checkbox
                  color="primary"
                  data-testid={`checkbox-${row.values.id}`}
                  className={checkboxClasses.checkbox}
                  checked={selectedRowsForArchiving.some(
                    (selectedRow) => selectedRow.id === row.values.id
                  )}
                  disabled={
                    !isDisplayingArchived &&
                    row.values.status !== CampaignStatus.Completed &&
                    row.values.status !== CampaignStatus.Disabled
                  }
                  onChange={() => {
                    if (
                      selectedRowsForArchiving.some(
                        (selectedRow) => selectedRow.id === row.values.id
                      )
                    ) {
                      setSelectedRowsForArchiving(
                        selectedRowsForArchiving.filter(
                          (selectedRow) => selectedRow.id !== row.values.id
                        )
                      );
                    } else {
                      setSelectedRowsForArchiving([
                        ...selectedRowsForArchiving,
                        {
                          id: row.values.id,
                          archived: !isDisplayingArchived,
                        },
                      ]);
                    }
                  }}
                />
              ),
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEditable,
      bulkArchivingColumnHeader,
      selectedRowsForArchiving,
      isDisplayingArchived,
    ]
  );

  const tableData = useMemo(
    () =>
      formatCampaignsData(campaigns[selectedTab], activeTerritory, externalId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaigns[selectedTab], externalId]
  );

  if (!loading && error) return <Redirecting history={history} />;

  if (showOnlyOwnedCampaigns && campaigns[0] && campaigns[0].length === 0)
    return <Redirect to="/campaigns" />;

  const DisplayedHeader =
    !showCustomHeaderOnlyForActiveTab || !isDisplayingArchived
      ? CustomHeader
      : undefined;

  const customHeaderProps = {
    campaignsFilter: defaultFilters,
  };

  const tabs = showTabs
    ? [
        {
          dataTc: 'activeCampaignsTab',
          label: 'Active Campaigns',
        },
        {
          dataTc: 'archivedCampaignsTab',
          label: 'Archived Campaigns',
        },
      ]
    : undefined;

  const tableTestId = `${ownerId || ''}${
    orderId || ''
  }${selectedTab}listCampaignsTable`;

  const updateRefetchData = (value: boolean) => {
    setShouldRefetchData(value);
  };

  return (
    <>
      <Table
        history={history}
        columns={columns}
        isEditable={isEditable}
        dataTc={tableTestId}
        data={tableData}
        hiddenColumns={defaultHiddenColumns}
        isPageTable={isPageTable}
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        onHiddenColumnsChange={handleHiddenColumnsChange}
        CustomHeader={DisplayedHeader}
        customHeaderProps={customHeaderProps}
        loading={loading}
        updateRefetchData={updateRefetchData}
        filtering={{ manualFiltering: true }}
        searching={{ manualSearching: true }}
        sorting={{ manualSorting: true }}
        pagination={{ manualPagination: true, pageCount: totalItems }}
        fetchData={fetchData}
        fetchingData={fetchingData}
        shouldRefetchData={shouldRefetchData}
        onCellClone={({
          entity,
          cloneChildren,
          hasChildren,
          isEdit,
          setErrorModal,
        }: ICloneCellData) =>
          handleCellClone({
            variables: {
              id: entity.id,
              disableCascade: cloneChildren ? !hasChildren : undefined,
            },
            clone: cloneCampaign,
            handleSuccess: (cloneData: any) => {
              setShouldRefetchData(true);
              isEdit && history.push(`/campaign/${cloneData.cloneCampaign.id}`);
            },
            handleContinue: () => history.push(`/campaign/${entity.id}`),
            setErrorModal,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Campaign',
            },
          })
        }
        onCellUpdate={(
          row: ICampaignsRow,
          setErrorModal: any,
          setUpdating: any
        ) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateCampaign,
            handleSuccess: () => setShouldRefetchData(true),
            handleContinue: () => history.push(`/campaign/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Campaign',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }: IValidateCellData) =>
          handleCellValidate({
            validate: validateCampaign,
            entity: { ...entity, type: EntityType.Campaign },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={customToolbarCtas}
      />
      {!loading && showBulkArchivingButton && (
        <div className={ctaFooterClasses.footer}>
          <StyledButton
            onClick={onBulkArchivingClick}
            variant={ButtonVariantEnum.Outlined}
            color={ButtonColorEnum.Primary}
            isLoading={loading}
            disabled={!(selectedRowsForArchiving.length > 0)}
            testId="campaignsBulkArchivingButton"
            className={ctaFooterClasses.button}
          >
            {`${isDisplayingArchived ? 'Unarchive' : 'Archive'} Selected`}
          </StyledButton>
        </div>
      )}
    </>
  );
};

export default CampaignsTable;
