import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

interface INumberInput {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  allowNegative: boolean;
  dataTc: string;
  dataTestId?: string;
  decimalScale: number;
  name: string;
  touched?: boolean;
  error?: string;
  thousandSeparator?: string;
  label?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  suffix?: string;
  prefix?: string;
  disabled?: boolean;
  margin?: 'none' | 'normal';
  fullWidth?: boolean;
}

export enum ThousandSeparatorTypes {
  comma = ',',
}

export enum SuffixTypes {
  percentage = '%',
}

export enum PrefixTypes {
  dollar = '$',
  pound = '£',
}

export const Prefixes = Object.values(PrefixTypes);

const NumberInput = ({
  onChange,
  onBlur,
  value,
  allowNegative,
  dataTc,
  dataTestId,
  decimalScale,
  name,
  touched = false,
  error,
  thousandSeparator,
  label,
  isAllowed,
  suffix,
  prefix,
  disabled = false,
  margin = 'none',
  fullWidth = false,
}: INumberInput) => {
  const numberFormat = (
    <NumberFormat
      disabled={disabled}
      customInput={Input}
      data-tc={dataTc}
      decimalScale={decimalScale}
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      thousandSeparator={thousandSeparator}
      value={value}
      allowNegative={allowNegative}
      isAllowed={isAllowed}
      suffix={suffix}
      prefix={prefix}
      inputProps={{ 'data-testid': dataTestId }}
    />
  );

  return label ? (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <InputLabel htmlFor={name} disabled={disabled}>
        {label}
      </InputLabel>
      {numberFormat}
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  ) : (
    numberFormat
  );
};

export default NumberInput;
