import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Paper from '@material-ui/core/Paper';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';

import useCsvParser from 'hooks/CsvParser/useCsvParser';

import useStyles from './Dropzone.styles';

interface IDropzoneProps {
  fileType: string;
  maxFileSize: number;
  dropzoneMessage: string;
  errorMessages: string[];
  multiple?: boolean;
  dataTc: string;
  handleDropzoneData: any;
}
const Dropzone = ({
  fileType,
  maxFileSize,
  errorMessages,
  dropzoneMessage,
  multiple = false,
  dataTc,
  handleDropzoneData,
}: IDropzoneProps) => {
  const classes = useStyles({});
  const { data, error, setFile, file } = useCsvParser({
    header: true,
    delimiter: ',',
  });
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((acceptedFile: File) => setFile(acceptedFile));
    },
    [setFile]
  );
  const onDropRejected = useCallback(() => {
    setErrorMessage(errorMessages);
    setErrorModalOpen(true);
  }, [errorMessages]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: fileType,
    maxSize: maxFileSize,
    onDropAccepted,
    onDropRejected,
    multiple,
  });

  useEffect(() => {
    if (data) {
      handleDropzoneData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorMessage([error]);
      setErrorModalOpen(true);
    }
  }, [error]);

  return (
    <>
      <Paper elevation={0} className={classes.card}>
        <div
          {...(getRootProps() as any)}
          className={classes.upload}
          data-testid="csvDropzone"
        >
          <input
            {...(getInputProps() as any)}
            data-tc={dataTc}
            data-testid="csvDropzoneInput"
          />
          <div className={classes.messageWrapper}>
            {file ? file.name : !isDragActive && dropzoneMessage}
            {isDragActive && !isDragReject && 'Drop the file here'}
            {isDragReject && 'The file is not in the correct format'}
          </div>
        </div>
      </Paper>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={isErrorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        dataTc={dataTc}
        errorMessages={errorMessage}
      />
    </>
  );
};

export default Dropzone;
