import { ICustomParameterGroup } from 'features/targetingV2/types/targeting';
import { CustomGroupAttributes } from 'interfaces/generated.types';

import { isCustomParameterInvalid } from './customParameter';

export const getValidCustomGroupsParametersAttributes = (
  customGroup: ICustomParameterGroup
) =>
  customGroup.parameters
    .filter((parameter) => !isCustomParameterInvalid(parameter))
    .map((validParameter) => ({
      clusivity: validParameter.clusivity,
      customKvps: validParameter.customKvps.map(
        (customKvpOption) => customKvpOption.keyValuePair
      ),
    }));

export const getCustomGroupAttributes = (
  customGroups: ICustomParameterGroup[]
) =>
  customGroups.reduce(
    (
      customGroupAttributesResult: CustomGroupAttributes[],
      currentCustomGroup: ICustomParameterGroup
    ) => {
      const customGroupParametersAttributes: CustomGroupAttributes = {
        parameters:
          getValidCustomGroupsParametersAttributes(currentCustomGroup),
      };

      if (customGroupParametersAttributes.parameters.length > 0)
        customGroupAttributesResult.push(customGroupParametersAttributes);

      return customGroupAttributesResult;
    },
    []
  );

export const getCustomTargetingAttributes = (formValues: any) => {
  const customTargeting = formValues?.targetingDefinitionV2?.customTargeting;
  if (!customTargeting?.customGroups?.length) return null;

  const customGroupAttributes = getCustomGroupAttributes(
    customTargeting.customGroups
  );

  return customGroupAttributes.length
    ? { customGroups: customGroupAttributes }
    : null;
};
