import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  FormControlLabel as MUIFormControlLabel,
  FormControlLabelProps as MUIFormControlLabelProps,
} from '@material-ui/core';

import useStyles from './FormControlLabel.styles';

export type FormControlLabelProps = MUIFormControlLabelProps;

const FormControlLabel = (props: FormControlLabelProps) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUIFormControlLabel classes={classes} {...otherProps} />;
};

FormControlLabel.displayName = 'FormControlLabel';

export default FormControlLabel;
