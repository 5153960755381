import {
  IParameterValueOption,
  ITargetingV2ParameterValue,
  SectionType,
} from 'features/targetingV2/types/common';
import {
  AudienceParameterType,
  LocationParameterType,
  Territory,
  useAllRegionPacksQuery,
  usePopularInterestsQuery,
} from 'interfaces/generated.types';

import { getSelectOption } from 'utils/dataTransformation';
import { formatParameterValuesResponse } from './formValuesFormatting';
import { IParameterValuesQueryDetails } from './parameterValues';

export const getSecondaryParameterValuesQueryDetails = (
  parameterType: string,
  territory: Territory
): IParameterValuesQueryDetails => {
  const defaultFetchPolicy = 'cache-first';
  switch (parameterType) {
    case LocationParameterType.Geo:
      return {
        query: useAllRegionPacksQuery,
        fetchPolicy: defaultFetchPolicy,
        path: 'allRegionPacks',
        variables: { type: parameterType, territories: [territory] },
      };
    case AudienceParameterType.Interest:
      return {
        query: usePopularInterestsQuery,
        fetchPolicy: 'no-cache',
        path: 'popularInterests',
      };
    default:
      return {
        query: usePopularInterestsQuery,
        fetchPolicy: defaultFetchPolicy,
        path: '',
        skipQuery: true,
      };
  }
};

export const formatRegionPacksResponse = (
  parameterValues: ITargetingV2ParameterValue[]
): IParameterValueOption[] =>
  parameterValues.reduce(
    (
      parameterValuesResult: IParameterValueOption[],
      currentParameterValue: ITargetingV2ParameterValue
    ) => {
      const option = getSelectOption(currentParameterValue, 'name', 'id');

      const formattedParameterValue = {
        ...option,
        ancestorList: [{ id: option.value, label: 'Region Pack' }],
      };

      parameterValuesResult.push(formattedParameterValue);
      return parameterValuesResult;
    },
    []
  );

export const formatSecondaryValuesResponse = (
  parameterType: string,
  parameterValues: ITargetingV2ParameterValue[],
  sectionType: SectionType
) => {
  switch (parameterType) {
    case LocationParameterType.Geo:
      return formatRegionPacksResponse(parameterValues);
    case AudienceParameterType.Interest:
      return formatParameterValuesResponse(parameterValues, sectionType);
    default:
      return [];
  }
};
