import { Permission, PermissionName } from 'interfaces/generated.types';

export const allPermissions = Object.values(PermissionName);

export const permissionExistsInList = (
  permissionList: PermissionName[],
  permission: PermissionName
) => permissionList.some((r) => r === permission);

export const readOnlyPermissionRegex = new RegExp('_READ$');

export const readOnlyPermissions: PermissionName[] = Object.values(
  PermissionName
).filter((permission) => readOnlyPermissionRegex.test(permission));

export const getPermissionsWithReadOnlyVersions = () => {
  const editPermissions: PermissionName[] = [];

  readOnlyPermissions.forEach((permission) => {
    const respectiveEditPermission = permission.replace(
      readOnlyPermissionRegex,
      ''
    ) as PermissionName;

    if (permissionExistsInList(allPermissions, respectiveEditPermission))
      editPermissions.push(respectiveEditPermission);
  });

  return editPermissions;
};

export const getReadVersionOfPermission = (permission: PermissionName) => {
  const readPermission = `${permission}_READ` as PermissionName;

  return permissionExistsInList(readOnlyPermissions, readPermission)
    ? readPermission
    : null;
};

export const userHasAtLeastOnePermission = (
  userPermissions: PermissionName[],
  permissions: PermissionName[]
) =>
  userPermissions.some((userPermission) =>
    permissions.includes(userPermission)
  );

export const userHasAllPermissions = (
  userPermissions: PermissionName[],
  permissions: PermissionName[]
) => permissions.every((permission) => userPermissions.includes(permission));

export const userHasAtLeastOneCombinationOfPermissions = (
  userPermissions: PermissionName[],
  permittedPermissionCombinations: PermissionName[][]
) =>
  permittedPermissionCombinations.some((combination) =>
    combination.every((permission) => userPermissions.includes(permission))
  );

export const getPairCombinationsFromPermissionsList = (
  permissions: PermissionName[]
) =>
  permissions.flatMap((currentPermission, index) =>
    permissions
      .slice(index + 1)
      .map((permissionToPair) => [currentPermission, permissionToPair])
  );

export const getPermissionNames = (permissions: Permission[]) =>
  permissions.map(
    (permission) =>
      permission.name || ('Missing permission name' as PermissionName)
  );
