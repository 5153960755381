import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  FormControl as MUIFormControl,
  FormControlProps as MUIFormControlProps,
} from '@material-ui/core';

import useStyles from './FormControl.styles';

export interface FormControlProps extends MUIFormControlProps {
  component?: string;
}

const FormControl = React.forwardRef(
  (props: FormControlProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUIFormControl ref={ref} classes={classes} {...otherProps} />;
  }
);

FormControl.displayName = 'FormControl';

export default FormControl;
