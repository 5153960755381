import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, ReactElement, Ref } from 'react';

import { List as MUIList, ListProps as MUIListProps } from '@material-ui/core';

import useStyles from './List.styles';

export type ListProps = MUIListProps;

const List = forwardRef((props: ListProps, ref: Ref<any>): ReactElement => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);
  return <MUIList ref={ref} classes={classes} {...otherProps} />;
});

List.displayName = 'List';

export default List;
