import {
  IAncestorType,
  ICustomKvp,
  IParameterValueOption,
  ITargetingV2ParameterValue,
  NodeLike,
  SectionType,
  ValueIdPrefixEnum,
} from 'features/targetingV2/types/common';
import {
  IAudienceParameterGroup,
  IAudienceTargeting,
  ICustomParameterGroup,
  ICustomTargeting,
  IDayTimePoint,
  IDayTimeTargeting,
  IInventoryTargeting,
  ILocationParameterGroup,
  ILocationTargeting,
  IParameterGroup,
  ITechnologyTargeting,
} from 'features/targetingV2/types/targeting';
import {
  AudienceGroup,
  AudienceParameter,
  AudienceParameterValue,
  CustomGroup,
  CustomParameter,
  CustomTargeting,
  DayOfWeek,
  DayTargeting,
  DayTimeTargeting,
  InventoryGroup,
  InventoryParameter,
  InventoryParameterValue,
  LocationGroup,
  LocationParameter,
  LocationParameterType,
  TechnologyGroup,
  TechnologyParameter,
} from 'interfaces/generated.types';
import shortid from 'shortid';

import { getSelectOption } from 'utils/dataTransformation';
import { convertArrayToObject } from './common';
import {
  defaultAudienceTargeting,
  defaultCustomTargeting,
  defaultInventoryTargeting,
  defaultLocationParameter as defaultGeoLocationParameter,
  defaultLocationParameterGroup,
  defaultLocationTargeting,
  defaultTargetingDefinitionV2,
  defaultTechnologyTargeting,
} from './defaults';

export const getAdditionalProperties = (
  sectionType: SectionType,
  parameterValue: ITargetingV2ParameterValue
) => {
  switch (sectionType) {
    case SectionType.Inventory:
      return {
        active: (parameterValue as InventoryParameterValue).active,
      };
    case SectionType.Audience:
      return {
        active: (parameterValue as AudienceParameterValue).active,
        activeGrades: (parameterValue as AudienceParameterValue).activeGrades,
      };
    default:
      return {};
  }
};

export const getFormattedAncestorList = (
  parameterValue: ITargetingV2ParameterValue,
  limitParameterValueId?: string | undefined
) => {
  if (parameterValue.id.startsWith(ValueIdPrefixEnum.RegionPack))
    return [{ id: parameterValue.name, label: 'Region Pack' }];

  return parameterValue.parents?.reduce(
    (
      ancestorList: IAncestorType[],
      currentAncestor: ITargetingV2ParameterValue
    ) => {
      if (currentAncestor.id !== limitParameterValueId) {
        ancestorList.push({
          id: currentAncestor.id,
          label: currentAncestor.name,
        });
      }
      return ancestorList;
    },
    []
  );
};

export const formatParameterValuesResponse = (
  parameterValues: ITargetingV2ParameterValue[],
  sectionType: SectionType,
  limitParameterValueId?: string | undefined,
  omitInactiveValues: boolean = false
): IParameterValueOption[] =>
  parameterValues.reduce(
    (
      parameterValuesResult: IParameterValueOption[],
      currentParameterValue: ITargetingV2ParameterValue
    ) => {
      const option = getSelectOption(currentParameterValue, 'name', 'id');
      const additionalProperties = getAdditionalProperties(
        sectionType,
        currentParameterValue
      );

      const formattedParameterValue = {
        ...option,
        ...additionalProperties,
        children: currentParameterValue.children
          ? formatParameterValuesResponse(
              currentParameterValue.children,
              sectionType,
              limitParameterValueId,
              omitInactiveValues
            )
          : undefined,
        ancestorList: getFormattedAncestorList(
          currentParameterValue,
          limitParameterValueId
        ),
      };

      if (omitInactiveValues && additionalProperties.active === false) {
        return parameterValuesResult;
      }

      parameterValuesResult.push(formattedParameterValue);
      return parameterValuesResult;
    },
    []
  );

export const formatAudienceTargetingResponse = (
  audienceTargeting: any
): IAudienceTargeting => {
  if (!audienceTargeting) return defaultAudienceTargeting;

  let formattedAudiences: IAudienceParameterGroup[] = [];

  if (audienceTargeting.audiences && audienceTargeting.audiences.length > 0)
    formattedAudiences = audienceTargeting.audiences.map(
      (audience: AudienceGroup) => ({
        parameters: audience.parameters.map((parameter: AudienceParameter) => ({
          type: parameter.type,
          clusivity: parameter.clusivity,
          values: formatParameterValuesResponse(
            parameter.values,
            SectionType.Audience
          ),
          enabledGrades: parameter.enabledGrades,
        })),
      })
    );

  return {
    audiences: formattedAudiences,
  };
};

export const formatTechnologyTargetingResponse = (
  technologyTargeting: any
): ITechnologyTargeting => {
  if (!technologyTargeting) return defaultTechnologyTargeting;

  let formattedTechnologies: IParameterGroup[] = [];

  if (
    technologyTargeting.technologies &&
    technologyTargeting.technologies.length > 0
  )
    formattedTechnologies = technologyTargeting.technologies.map(
      (technology: TechnologyGroup) => ({
        parameters: technology.parameters.map(
          (parameter: TechnologyParameter) => ({
            type: parameter.type,
            clusivity: parameter.clusivity,
            values: formatParameterValuesResponse(
              parameter.values,
              SectionType.Technology
            ),
          })
        ),
      })
    );

  return {
    technologies: formattedTechnologies,
  };
};

export const formatInventoryTargetingResponse = (
  inventoryTargeting: any
): IInventoryTargeting => {
  if (!inventoryTargeting) return defaultInventoryTargeting;

  let formattedInventories: IParameterGroup[] = [];

  if (
    inventoryTargeting.inventories &&
    inventoryTargeting.inventories?.length > 0
  )
    formattedInventories = inventoryTargeting.inventories.map(
      (inventory: InventoryGroup) => ({
        parameters: inventory.parameters.map(
          (parameter: InventoryParameter) => ({
            type: parameter.type,
            clusivity: parameter.clusivity,
            values: formatParameterValuesResponse(
              parameter.values,
              SectionType.Inventory
            ),
          })
        ),
      })
    );

  return {
    inventories: formattedInventories,
  };
};

export const formatLocationTargetingResponse = (
  locationTargeting: any
): ILocationTargeting => {
  if (!locationTargeting) return defaultLocationTargeting;

  let formattedLocations: ILocationParameterGroup[] = [
    defaultLocationParameterGroup,
  ];

  if (locationTargeting.locations && locationTargeting.locations.length > 0) {
    formattedLocations = locationTargeting.locations.map(
      (location: LocationGroup) => {
        let parameters = location.parameters.map(
          (parameter: LocationParameter) => ({
            type: parameter.type,
            clusivity: parameter.clusivity,
            values: formatParameterValuesResponse(
              parameter.values,
              SectionType.Location,
              location.limitLocationParameterValue?.id
            ),
          })
        );

        const hasGeoParameter = parameters.some(
          (parameter) => parameter.type === LocationParameterType.Geo
        );

        if (location.accuracyRadius && !hasGeoParameter) {
          parameters = [defaultGeoLocationParameter, ...parameters];
        }

        return {
          limitLocationParameterValue: location.limitLocationParameterValue
            ? { id: location.limitLocationParameterValue.id }
            : null,
          accuracyRadius: location.accuracyRadius ?? -1,
          parameters,
        };
      }
    );
  }

  return {
    locations: formattedLocations,
  };
};

export const formatDayTimeTargetingResponse = (
  dayTimeTargeting: DayTimeTargeting | undefined
): IDayTimeTargeting | null => {
  if (!dayTimeTargeting) return null;

  const dayTimePoints = dayTimeTargeting.daysOfWeek.reduce(
    (allDayTimePoints: IDayTimePoint[], currentDay: DayOfWeek) => {
      const pointsOfDay = currentDay.hours.map((hour) => ({
        day:
          currentDay.day === DayTargeting.Other
            ? DayTargeting.Mon
            : currentDay.day,
        hour,
      }));

      return allDayTimePoints.concat(pointsOfDay);
    },
    []
  );

  return {
    clusivity: dayTimeTargeting.clusivity,
    timeZone: dayTimeTargeting.timeZone,
    dayTimePoints,
  };
};

export const formatCustomKvpsResponse = (customKvps: ICustomKvp[]) =>
  customKvps.map((customKvp) => {
    const { key, value } = customKvp;
    return {
      label: `${key} = ${value}`,
      value: shortid.generate(),
      keyValuePair: { key, value },
    };
  });

export const formatCustomTargetingResponse = (
  customTargeting: CustomTargeting | undefined
): ICustomTargeting => {
  if (!customTargeting) return defaultCustomTargeting;

  let formattedCustomGroups: ICustomParameterGroup[] = [];

  if (customTargeting.customGroups && customTargeting.customGroups.length) {
    formattedCustomGroups = customTargeting.customGroups.map(
      (customGroup: CustomGroup) => ({
        parameters: customGroup.parameters.map(
          (parameter: CustomParameter) => ({
            clusivity: parameter.clusivity,
            customKvps: formatCustomKvpsResponse(parameter.customKvps),
          })
        ),
      })
    );
  }

  return { customGroups: formattedCustomGroups };
};

export const formatTargetingDefinitionV2 = (targetingDefinitionV2: any) => {
  if (!targetingDefinitionV2) return defaultTargetingDefinitionV2;

  const {
    audienceTargeting,
    locationTargeting,
    technologyTargeting,
    inventoryTargeting,
    dayTimeTargeting,
    customTargeting,
  } = targetingDefinitionV2;

  return {
    audienceTargeting: formatAudienceTargetingResponse(audienceTargeting),
    locationTargeting: formatLocationTargetingResponse(locationTargeting),
    technologyTargeting: formatTechnologyTargetingResponse(technologyTargeting),
    inventoryTargeting: formatInventoryTargetingResponse(inventoryTargeting),
    dayTimeTargeting: formatDayTimeTargetingResponse(dayTimeTargeting),
    customTargeting: formatCustomTargetingResponse(customTargeting),
  };
};

export const formatMenuTreeListSelection = (
  newSelectedNodes: NodeLike[],
  previouslySelectedNodes: NodeLike[],
  menuListOptions?: IParameterValueOption[]
): NodeLike[] => {
  const menuListOptionsObject = convertArrayToObject(
    menuListOptions || [],
    'value'
  );

  const newSelectedNodesObject = convertArrayToObject(
    newSelectedNodes,
    'value'
  );

  const selectedNodesToKeep = previouslySelectedNodes.filter(
    (previouslySelectedNode) => {
      const isNodeInNewSelectedNodes =
        !!newSelectedNodesObject[previouslySelectedNode.value];

      const isNodeInOptions =
        !!menuListOptionsObject[previouslySelectedNode.value];

      return (
        isNodeInNewSelectedNodes ||
        // We need to specifically check for active being defined and set to false and for the node not being available in the list to deselect
        (previouslySelectedNode.active === false && !isNodeInOptions)
      );
    }
  );

  const selectedNodesToKeepObject = convertArrayToObject(
    selectedNodesToKeep,
    'value'
  );

  const nodesToAdd = newSelectedNodes.filter(
    (newSelectedNode) => !selectedNodesToKeepObject[newSelectedNode.value]
  );

  const newNodes = selectedNodesToKeep.concat(nodesToAdd);

  const formattedNodes = newNodes.map((node) => ({
    ...node,
    id: undefined,
    children: undefined,
    parent: undefined,
    allChildrenSelected: undefined,
  }));

  return formattedNodes;
};
