import { baseButtonStyles } from 'assets/styles/components/Button.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseButtonStyles(theme),
  plain: ({ compact }: { compact: boolean }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'inherit',
    border: 'none',
    borderRadius: '0',
    padding: compact ? 0 : theme.spacing(1.25),
    textDecoration: 'none',
    display: 'inline-block',
    transition: 'background-color 0.2s linear, color 0.2s linear',
  }),
  linkButton: {
    textDecoration: 'none',
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    padding: theme.spacing(0.4, 2.5),
  },
  primary: {
    composes: '$linkButton $outlinedPrimary',
  },
  secondary: {
    composes: '$linkButton $outlinedPrimary',
  },
  filled: {
    composes: '$linkButton $containedPrimary',
  },
}));

export default useStyles;
