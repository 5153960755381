import { Theme } from '@material-ui/core/styles';
import createTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createTheme';

import colors from '../colors';

interface CustomThemeOptions extends ThemeOptions {
  spacing: any;
}

const themeOptions: CustomThemeOptions = {
  palette: {
    primary: {
      light: colors.globalStandardPrimary,
      main: colors.daxPrimaryBlue,
      dark: colors.daxPrimaryGreen,
    },
    secondary: {
      light: colors.globalStandardSecondary,
      main: colors.daxSecondaryBlue,
      dark: colors.daxSecondaryGreen,
    },
    grey: {
      200: colors.daxTertiaryBlue,
      300: colors.daxGreyscaleLightGrey,
      400: colors.daxGreyscaleStandardGrey,
      500: colors.daxGreyscaleOffWhite,
      700: colors.mainText,
      800: colors.daxGreyscaleDarkGrey,
    },
    common: {
      black: colors.daxGreyscaleBlack,
      white: colors.daxGreyscaleWhite,
    },
    error: {
      main: colors.daxStatusError,
    },
    warning: {
      main: colors.daxStatusWarning,
    },

    success: {
      main: colors.daxStatusConfirmation,
    },

    // used for hover and click states
    info: {
      light: colors.daxSecondaryBlueHover, // hover
      main: colors.daxSecondaryBlueClick, // click
    },
  },
  spacing: (factor: number) => `${factor * 0.5}em`,
  typography: {
    fontFamily: ['Poppins', '-apple-system', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        '&$expanded': {
          margin: '0em, 1em, 0em, 0em',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 400,
      },
    },

    MuiMenuItem: {
      root: {
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    MuiTableBody: {
      root: {
        '& tr:nth-of-type(even)': {
          backgroundColor: colors.daxTertiaryBlue,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '15px',
      },
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        '&:not(:last-child)': {
          borderRight: `1px solid ${colors.daxPrimaryBlue}`,
          '&:hover,&:focus': {
            borderColor: `${colors.daxPrimaryBlue}`,
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: colors.daxTertiaryBlue,
        borderRadius: '0.313em',
        fontSize: '1.188em',
        lineHeight: '24px',
        '&:hover': {
          border: 'none',
        },
        '&$disabled': {
          color: colors.daxTertiaryBlue,
        },
      },
      input: {
        height: '24px',
        borderRadius: '0.313em',
        '&$disabled': {
          color: colors.daxGreyscaleStandardGrey,
          backgroundColor: 'inherit',
        },
      },
      multiline: {
        padding: 0,
      },
      adornedStart: {
        paddingLeft: '16px',
      },
      adornedEnd: {
        paddingRight: '16px',
      },
    },
    MuiInput: {
      root: {
        backgroundColor: colors.daxGreyscaleOffWhite,
      },
      underline: {
        '&::before': {
          content: 'none',
        },
        '&::after': {
          content: 'none',
        },
      },
      input: {
        padding: '5px 16px',
        '&$disabled': {
          color: colors.daxGreyscaleStandardGrey,
          backgroundColor: 'inherit',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.938em',
        lineHeight: '1.188em',
        paddingBottom: '0.375em',
        color: colors.daxGreyscaleDarkGrey,
        '&$focused': {
          color: colors.daxGreyscaleDarkGrey,
        },
        '&$disabled': {
          color: colors.daxGreyscaleStandardGrey,
        },
      },
    },
    MuiInputLabel: {
      animated: {
        transition: 'none',
      },
      shrink: {
        transform: 'none',
      },
      formControl: {
        transform: 'none',
        top: '-5px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '5px',
        color: colors.daxGreyscaleDarkGrey,
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: colors.daxPrimaryBlue,
        },
      },
      colorSecondary: {
        color: colors.daxPrimaryBlue,
      },
    },
    MuiSwitch: {
      root: {
        width: '30px',
        height: '16px',
        padding: '0px',
        margin: '0.5em',
      },
      switchBase: {
        padding: '4px',
        '&$checked': {
          color: colors.daxGreyscaleWhite,
          transform: 'translateX(14px)',
          padding: '2px',
          '& + $track': {
            backgroundColor: colors.primaryBlue,
            opacity: 1,
            border: 'none',
          },
          '& $thumb': {
            backgroundColor: colors.daxGreyscaleWhite,
            width: '12px',
            height: '12px',
          },
          '&$disabled': {
            '& + $track': {
              backgroundColor: colors.daxGreyscaleLightGrey,
              opacity: 1,
            },
            '& $thumb': {
              backgroundColor: colors.daxGreyscaleWhite,
            },
          },
        },
      },
      track: {
        borderRadius: '40px',
        opacity: 1,
        boxSizing: 'border-box',
        backgroundColor: colors.daxGreyscaleWhite,
        border: `solid 1px ${colors.daxGreyscaleSilver}`,
        '$switchBase$disabled + &': {
          opacity: 1,
          backgroundColor: colors.daxGreyscaleWhite,
          border: `solid 1px ${colors.daxGreyscaleLightGrey}`,
        },
      },
      thumb: {
        boxShadow: 'none',
        backgroundColor: colors.daxGreyscaleSilver,
        width: '8px',
        height: '8px',
        '$switchBase$disabled &': {
          backgroundColor: colors.daxGreyscaleLightGrey,
        },
      },
      checked: {},
      disabled: {},
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiTabs: {
      indicator: {
        position: 'unset',
      },
    },
  },
};

const theme: Theme = createTheme(themeOptions);

export default { theme };
