import MultiSelect from 'components/MultiSelect/MultiSelect';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  getTargetingPackDetails,
  ITargetingPackType,
} from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import TargetingPackModal from 'features/targeting/components/TargetingPackModal/TargetingPackModal';
import { getIn } from 'formik';
import { ITargetingPack } from 'graphql/targetingGroups/queries';
import { IFormProps, OptionType } from 'interfaces';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect, useState } from 'react';

import useStyles from './TargetingPackSelector.styles';

export interface ITargetingPackSelectorProps {
  name: string;
  type: string;
  label: string;
  errorMessage: string;
}

export const formatTargetingPacks = (newPacks: ITargetingPack[]) =>
  newPacks.map((pack) => ({
    label: pack.name,
    value: pack.id,
    readOnly: pack.readOnly || false,
  }));

const TargetingPackSelector = (
  props: IFormProps<any> & ITargetingPackSelectorProps
) => {
  const classes = useStyles();
  const {
    name,
    values,
    label,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    type,
    errorMessage,
  } = props;
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const targetingPackDetails: ITargetingPackType = getTargetingPackDetails(
    type
  );

  const [targetingPacks, setTargetingPacks] = useState<ITargetingPack[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const useListAllQuery = targetingPackDetails.listAll;
  const { loading, error, data } = useListAllQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });
  const queryKey = `all${upperFirst(targetingPackDetails.dataType)}Groups`;

  const selectedTargetingPacks = get(values, name) || [];

  const isClearable = selectedTargetingPacks.some(
    (v: ITargetingPack) => v.readOnly
  );

  useEffect(() => {
    if (name && targetingPacks.length) {
      setFieldValue(name, formatTargetingPacks(targetingPacks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetingPacks]);

  return (
    <div className={classes.packWrapper}>
      <StyledButton
        color={ButtonColorEnum.Primary}
        onClick={() => setModalOpen(true)}
        variant={ButtonVariantEnum.Outlined}
        disabled={loading || !!error}
        data-tc="targetingPackAddButton"
        testId="targetingPackAddButton"
      >
        Add {label}
      </StyledButton>
      {isModalOpen && data && data[queryKey] && (
        <TargetingPackModal
          allTargetingPacks={data[queryKey]}
          selectedTargetingPacks={selectedTargetingPacks.map(
            (pack: OptionType) => ({
              name: pack.label,
              id: pack.value,
              readOnly: pack.readOnly,
            })
          )}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          onSelect={setTargetingPacks}
          dataTc="targetingPackModal"
        />
      )}
      {error ? (
        <div data-tc="targetingPackError" className={classes.error}>
          {errorMessage}
        </div>
      ) : null}
      {selectedTargetingPacks.length > 0 ? (
        <MultiSelect
          id={label}
          noMargin={false}
          data-tc="targetingPackSelect"
          isMulti
          isDisabled={false}
          isClearable={!isClearable}
          label={label}
          placeholder={label}
          name={name}
          onBlur={() => setFieldTouched(name, true)}
          onChange={(fieldValue: any) => setFieldValue(name, fieldValue)}
          selectAll={false}
          options={[]}
          maxOptions={0}
          maxOptionsText=""
          value={selectedTargetingPacks}
          attributes={{
            fieldAttribute: `${name}Field`,
            menuAttribute: `${name}MenuOptions`,
            chipAttribute: `${name}Chip`,
          }}
          errorProps={{
            helperText: getIn(touched, name) && getIn(errors, name),
            FormHelperTextProps: {
              error: !!(getIn(touched, name) && getIn(errors, name)),
            },
          }}
          disableSearch
        />
      ) : null}
    </div>
  );
};

export default TargetingPackSelector;
