import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateTargetingTemplateContainer from 'features/targeting/containers/UpdateTargetingTemplateContainer/UpdateTargetingTemplateContainer';
import { History } from 'history';
import { useTargetingTemplateQuery } from 'interfaces/generated.types';
import React from 'react';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface UpdateTargetingTemplatePageProps {
  history: History;
  match: {
    params: {
      targetingTemplateId: string;
    };
  };
}

const UpdateTargetingTemplatePage = ({
  history,
  match,
}: UpdateTargetingTemplatePageProps) => {
  const { loading, data } = useTargetingTemplateQuery({
    variables: { id: match.params.targetingTemplateId },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const isTemplateAssignedToActiveTerritory = isActiveTerritoryAssignedToEntity(
    [data?.targetingTemplate?.territory!],
    activeTerritory!
  );

  if (!loading && !data?.targetingTemplate)
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Targeting Template</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateTargetingTemplateContainer
            history={history}
            match={match}
            targetingTemplate={data && (data.targetingTemplate as any)}
            isTemplateAssignedToActiveTerritory={
              isTemplateAssignedToActiveTerritory
            }
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateTargetingTemplatePage;
