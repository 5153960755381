import { isNull } from 'util';
import * as Yup from 'yup';

import commons from 'validations/commons';

const TargetingTemplateFormValidation = Yup.object().shape({
  name: commons.name('Template Name'),
  description: Yup.string().trim().max(1024, 'Character limit reached'),
  targetingGroups: Yup.array()
    .of(
      Yup.object().shape(
        {
          audienceParams: commons.audienceParams('Listener parameters'),
          channels: Yup.array()
            .of(Yup.string())
            .when(['audienceParams'], {
              is: (audienceParams: any) =>
                audienceParams && audienceParams.length > 0,
              then: Yup.array().required(
                "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
              ),
            })
            .transform((channels) => (isNull(channels) ? [] : channels))
            .min(
              1,
              "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
            ),
        },
        ['audienceParams', 'channels'] as any
      )
    )
    .min(
      1,
      'You cannot save a Targeting Template without a targeting group. Please add a targeting group.'
    ),
});

export default TargetingTemplateFormValidation;
