import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import LinkedDealsTable from 'features/programmatic/deal/components/LinkedDealsTable/LinkedDealsTable';
import { History } from 'history';
import {
  Deal,
  usePublisherAssociatedDealsQuery,
} from 'interfaces/generated.types';
import React from 'react';

const PublisherAssociatedDealsTable = ({
  history,
  publisherId,
}: {
  history: History;
  publisherId: string;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = usePublisherAssociatedDealsQuery({
    variables: { id: publisherId, territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  if (loading) return <Loader />;

  if (!loading && error) return <Redirecting history={history} />;

  return (
    <LinkedDealsTable
      deals={(data?.publisher?.associatedDeals as Deal[]) || []}
      history={history}
    />
  );
};

export default PublisherAssociatedDealsTable;
