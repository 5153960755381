import * as Yup from 'yup';

import { OptionType } from 'interfaces';

const CreativeFormValidation = Yup.object().shape(
  {
    advertiser: Yup.object()
      .shape({
        label: Yup.string().required('Advertiser label must be present'),
        value: Yup.string().required('Advertiser value must be present'),
      })
      .required('Advertiser is a required field')
      .nullable(),
    imageCreatives: Yup.array()
      .when('advertiser', {
        is: (advertiser: OptionType) => !advertiser,
        then: Yup.array().required(
          'Please select an advertiser before uploading a creative'
        ),
      })
      .when(
        'audioCreatives',
        (audioCreatives: any, schema: Yup.ArraySchema<[]>) =>
          schema.test(
            'creativesPresent',
            'Please upload some audio and/or display creatives',
            (imageCreatives: any) => {
              if (imageCreatives && imageCreatives.length) return true;
              return audioCreatives && audioCreatives.length;
            }
          )
      ),
    audioCreatives: Yup.array()
      .of(
        Yup.object().shape({
          duration: Yup.number().max(
            300,
            'Audio creative cannot be longer than 300 seconds'
          ),
        })
      )
      .when('advertiser', {
        is: (advertiser: OptionType) => !advertiser,
        then: Yup.array().required(
          'Please select an advertiser before uploading a creative'
        ),
      })
      .when(
        'imageCreatives',
        (imageCreatives: any, schema: Yup.ArraySchema<[]>) =>
          schema.test(
            'creativesPresent',
            'Please upload some audio and/or display creatives',
            (audioCreatives: any) => {
              if (audioCreatives && audioCreatives.length) return true;
              return imageCreatives && imageCreatives.length;
            }
          )
      ),
  },
  [
    ['audioCreatives', 'imageCreatives'] as any,
    ['imageCreatives', 'advertiser'] as any,
    ['audioCreatives', 'advertiser'] as any,
  ]
);

export default CreativeFormValidation;
