import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';

import useStyles from './ImagePopover.styles';

interface IImagePopoverProps {
  fileName: string;
  url: string;
}

const ImagePopover = (props: IImagePopoverProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverToggle = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const { fileName, url } = props;

  return (
    <>
      <div
        aria-owns={open ? `${fileName}-popover` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverToggle}
        onMouseLeave={handlePopoverToggle}
        data-testid="displayThumbnail"
        data-tc="displayThumbnail"
        className={classes.displayWrapper}
      >
        <img src={url} alt={fileName} className={classes.displayThumbnail} />
        <span className="sr-only">
          Hover to view a bigger version of {fileName}
        </span>
      </div>
      <Popover
        data-testid="displayPopover"
        className={classes.displayPopover}
        classes={{
          paper: classes.displayPaper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverToggle}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        disableRestoreFocus
      >
        <img
          src={url}
          alt={fileName}
          className={classes.displayImage}
          data-tc="displayImage"
        />
      </Popover>
    </>
  );
};

export default ImagePopover;
