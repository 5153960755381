import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { TargetingPackTypeEnum } from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import TargetingPacksTable from 'features/targeting/components/TargetingPacksTable/TargetingPacksTable';
import { History } from 'history';
import {
  CountryGroup,
  useAllCountryGroupsQuery,
} from 'interfaces/generated.types';
import React from 'react';

interface ITargetingRegionPacksTabProps {
  history: History;
}

const TargetingRegionPacksTab = ({
  history,
}: ITargetingRegionPacksTabProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllCountryGroupsQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  if (!loading && error) return <Redirecting history={history} />;

  return loading ? (
    <Loader />
  ) : (
    <TargetingPacksTable
      allTargetingPacks={(data?.allCountryGroups as CountryGroup[]) || []}
      dataTc="listRegionPacksTable"
      data-testid="listRegionPacksTable"
      type={TargetingPackTypeEnum.REGION}
      history={history}
    />
  );
};

export default TargetingRegionPacksTab;
