import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import Loader from 'components/Loader/Loader';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import utils from 'hooks/CsvParser/utils';
import useError from 'hooks/Error/useError';
import useAxios, { IFilter } from 'hooks/useAxios/useAxios';
import { IDateRange } from 'interfaces';
import Dimensions from 'interfaces/dimensions';
import React, { useEffect, useState } from 'react';
import { CurrenciesEnum } from 'utils/currency';
import dateUtils from 'utils/date';
import { getDefaultTimezoneBasedOnTerritory } from 'utils/defaultsByTerritory';
import { ISelectedFilter } from 'utils/insights';

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useStyles from './ExportDimensionsMenu.styles';

interface IExportDimenionsMenuProps {
  selectedDateRange: IDateRange;
  selectedFilters?: ISelectedFilter[];
  selectedCurrency: CurrenciesEnum;
}

interface IDimension {
  dimension: Dimensions;
  displayName?: string;
}

const ExportDimensionsMenu = (props: IExportDimenionsMenuProps) => {
  const { selectedDateRange, selectedFilters, selectedCurrency } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [optionSelected, setOptionSelected] = useState<string | null>(null);
  const [dimensionOptions, setDimensionOptions] = useState<IDimension[]>([]);

  const {
    response: data,
    loading,
    error,
    request: requestDimensions,
  } = useAxios('insights/dimensions', 'GET');

  const {
    response: csvData,
    loading: loadingCsvData,
    error: csvDataError,
    request: requestCsvData,
  } = useAxios('insights', 'POST');

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const timeZone = getDefaultTimezoneBasedOnTerritory(activeTerritory!);

  const {
    hasError,
    toggleErrorModal,
    errorMessages,
    setErrorMessages,
    setErrorModal,
  } = useError(['The download for this export has failed.']);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    requestDimensions(null);
  };

  const toggleCheckbox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (optionSelected === (e.target as HTMLInputElement).value) {
      setOptionSelected(null);
    } else {
      setOptionSelected((e.target as HTMLInputElement).value);
    }
  };

  const filters: IFilter[] = selectedFilters
    ? selectedFilters.map((filter: ISelectedFilter) => ({
        type: filter.filterCategory.type,
        ids: filter.ids,
      }))
    : [];

  const handleExportCsv = async () => {
    const reqBody = {
      startDate: dateUtils.getIsoStringInSpecificTimezone(
        selectedDateRange.startDate,
        timeZone
      ),
      endDate: dateUtils.getIsoStringInSpecificTimezone(
        selectedDateRange.endDate,
        timeZone
      ),
      dimension: optionSelected,
      currency: selectedCurrency,
      filters,
      timeZone,
    };
    requestCsvData(reqBody);
  };

  const handleError = (err: any) => {
    const errorHeader = 'The download for this export has failed.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (!loading && data !== null && data.dimensions !== null)
      setDimensionOptions(data.dimensions);

    if (data === null && error !== null) handleError(error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  useEffect(() => {
    if (!loadingCsvData && csvData !== null) {
      handleClose();
      utils.downloadData(csvData, 'insightsData.csv');
    }
    if (csvData === null && csvDataError !== null) handleError(csvDataError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvData, loadingCsvData, csvDataError]);

  useEffect(() => {
    if (hasError) {
      setErrorModal(hasError);
    }
  }, [hasError, setErrorModal]);

  return (
    <>
      <Button
        aria-controls={open ? 'export-dimensions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        classes={{
          root: classes.button,
        }}
        onClick={handleExport}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </Button>
      <Menu
        MenuListProps={{
          'aria-label': 'export-dimensions-menu',
          className: classes.menu,
        }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography variant="h6" className={classes.header}>
          Include break down by:
        </Typography>
        {loading && <Loader />}
        <Grid container>
          {!loading && dimensionOptions && (
            <>
              {dimensionOptions.map((option: IDimension) => (
                <Grid
                  item
                  xs={4}
                  key={option.dimension}
                  className={classes.list}
                >
                  <MenuItem
                    key={option.dimension}
                    value={option.dimension}
                    className={classes.listItem}
                    disableRipple
                  >
                    <Checkbox
                      value={option.dimension}
                      checked={optionSelected === option.dimension}
                      onClick={(e) => toggleCheckbox(e)}
                      className={classes.checkbox}
                    />
                  </MenuItem>
                  <Typography className={classes.label}>
                    {option.displayName}
                  </Typography>
                </Grid>
              ))}
            </>
          )}
          <Grid item xs={12} className={classes.menuButton}>
            <Button
              classes={{
                root: classes.button,
              }}
              onClick={() => handleExportCsv()}
              disabled={!optionSelected || loadingCsvData || loading}
            >
              Export CSV
              {loadingCsvData && (
                <CircularProgress
                  data-testid="loader"
                  className={classes.loader}
                  size="24"
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Menu>
      <ErrorDialog
        isOpen={hasError}
        handleClose={toggleErrorModal}
        errorMessages={errorMessages}
        content={{ title: 'Error', closeButton: 'Close' }}
        dataTc="exportErrorDialog"
      />
    </>
  );
};

export default ExportDimensionsMenu;
