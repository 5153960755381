import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import StyledButton, {
    ButtonColorEnum, ButtonVariantEnum
} from 'components/StyledButton/StyledButton';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { useUserContext } from 'context/UserProvider/UserProvider';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React from 'react';
import { AuthState } from 'utils/authProvider';

import AssumedUserIdHeader from '../../components/AssumedUserIdHeader/AssumedUserIdHeader';
import useStyles from './ErrorPage.styles';

interface ErrorDetails {
  title: string;
  subTitle: string;
  message: string;
}

export const errorDetails: { [key: string]: ErrorDetails } = {
  '400': {
    title: '400',
    subTitle: 'Bad Request',
    message:
      "Something went wrong and we couldn't process your request. Please try again or if the issue persists, contact a DAX Audio administrator.",
  },
  '401': {
    title: '401',
    subTitle: 'Unauthorised',
    message:
      'It looks like access to this page is not available for this account. You may not have permission for the functionality. Either contact a DAX Audio administrator or please login with another account.',
  },
  '403': {
    title: '403',
    subTitle: 'Forbidden',
    message:
      'It looks like access to this page is not available. Please contact a DAX Audio administrator.',
  },
  '404': {
    title: '404',
    subTitle: 'Not Found',
    message:
      "We can't find the page you were looking for. You could either try going back to your previous page or go to the homepage?",
  },
  '500': {
    title: '500',
    subTitle: 'Maintenance',
    message:
      'Dax Audio app is temporarily unavailable. Please try again later or if the issue persists, contact a DAX Audio administrator.',
  },
  UNKNOWN: {
    title: '?',
    subTitle: 'Unknown Error',
    message:
      'An unknown error has occurred. Please try again or if the issue persists, contact a DAX Audio administrator.',
  },
};

interface IErrorPage {
  history: History;
  match: {
    params: {
      errorCode: string;
    };
  };
}

const ErrorPage = ({ match, history }: IErrorPage) => {
  const error = errorDetails[match.params.errorCode] || errorDetails['404'];
  const classes = useStyles();

  const location = usePreviousLocation();
  const { traceParent } = location.state || {};

  const { state: authState } = useAuthContext();
  const { fetchingUserDetails } = useUserContext();

  const {
    state: { assumedId },
  } = useCustomApolloContext();

  return (
    <>
      {authState === AuthState.LoggedIn &&
        !fetchingUserDetails &&
        assumedId && <AssumedUserIdHeader />}

      {authState === AuthState.LoggedIn && !fetchingUserDetails && <Header />}
      <main>
        <Container>
          <div className={classes.page}>
            <h1 className={classes.title}>{error.title}</h1>
            <h2 className={classes.subTitle}>{error.subTitle}</h2>
            <p className={classes.message}>{error.message}</p>
            {traceParent && (
              <p className={classes.message}>{`errorID: ${traceParent}`}</p>
            )}
            <div className={classes.actions}>
              <StyledButton
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Outlined}
                onClick={history.goBack}
              >
                Go to Previous Page
              </StyledButton>
              <StyledButton
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Contained}
                onClick={() => history.push('/')}
              >
                Go to Home
              </StyledButton>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};

export default ErrorPage;
