import useStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput from 'components/NumberInput/NumberInput';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  dspProviderValues,
  ITechnicalProviderValues,
  vastVersions,
} from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderFormValues';
import { Field } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import React from 'react';
import { currencyValues } from 'utils/currency';
import { getTerritoryValues } from 'utils/territory';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

export interface ITechnicalProviderDetailsProps {
  update?: boolean;
}

const TechnicalProviderDetails = (
  props: IFormProps<ITechnicalProviderValues> & ITechnicalProviderDetailsProps
) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();
  const {
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    update = false,
    status = {},
  } = props;

  const classes = useStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Name"
            placeholder="Name"
            name="name"
            autoFocus={!update}
            data-tc="technicalProviderName"
            fullWidth
            inputProps={{ 'data-testid': 'technicalProviderName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Programmatic Connector ID"
              data-tc="technicalProviderAltId"
              name="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <NumberInput
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.consentVendorId}
            allowNegative={false}
            dataTc="technicalProviderConsentVendorId"
            decimalScale={0}
            name="consentVendorId"
            touched={touched.consentVendorId || !!status.consentVendorId}
            error={errors.consentVendorId || status.consentVendorId}
            label="Consent Vendor Id"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="technicalProviderCurrencyField"
            fullWidth
            label="Currency"
            name="currency"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.currency && errors.currency) || status.currency
            }
            FormHelperTextProps={{
              error: !!(
                (touched.currency && errors.currency) ||
                status.currency
              ),
            }}
          >
            {currencyValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            data-tc="technicalProviderDspProvider"
            fullWidth
            label="Dsp Provider"
            name="dspProvider"
            placeholder="Dsp Provider"
            helperText={
              (touched.dspProvider && errors.dspProvider) || status.dspProvider
            }
            FormHelperTextProps={{
              error: !!(
                (touched.dspProvider && errors.dspProvider) ||
                status.dspProvider
              ),
            }}
          >
            {dspProviderValues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="User Sync Url"
            placeholder="User Sync Url"
            data-tc="technicalProviderUserSyncUrl"
            name="userSyncUrl"
            fullWidth
            helperText={
              (touched.userSyncUrl && errors.userSyncUrl) || status.userSyncUrl
            }
            FormHelperTextProps={{
              error: !!(
                (touched.userSyncUrl && errors.userSyncUrl) ||
                status.userSyncUrl
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="User Sync Secure Url"
            placeholder="User Sync Secure Url"
            data-tc="technicalProviderUserSyncSecureUrl"
            name="userSyncSecureUrl"
            fullWidth
            helperText={
              (touched.userSyncSecureUrl && errors.userSyncSecureUrl) ||
              status.userSyncSecureUrl
            }
            FormHelperTextProps={{
              error: !!(
                (touched.userSyncSecureUrl && errors.userSyncSecureUrl) ||
                status.userSyncSecureUrl
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="technicalProviderCurrencyPrecision"
            decimalScale={0}
            label="Currency Precision"
            name="bidFloorPrecision"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.bidFloorPrecision}
            fullWidth
            error={errors.bidFloorPrecision || status.bidFloorPrecision}
            touched={touched.bidFloorPrecision || !!status.bidFloorPrecision}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="gzipCompressed"
                  color="primary"
                  data-tc="gzipCompressed"
                />
              }
              label="Apply gzip compression"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            component={'fieldset' as 'div'}
            fullWidth
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="sendNotSyncedUsers"
                  color="primary"
                  data-tc="sendNotSyncedUsers"
                />
              }
              label="Send not synced users"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <FormControl component={'fieldset' as 'div'}>
            <FormLabel component={'legend' as 'span'}>
              Select vast versions
            </FormLabel>
            <FormGroup row>
              {vastVersions.map((version) => (
                <div key={version.value}>
                  <FormControlLabel
                    name="vastVersions"
                    control={
                      <Checkbox
                        checked={values.vastVersions.includes(version.value)}
                        color="primary"
                        onChange={({ target: { value, checked } }) =>
                          setFieldValue(
                            'vastVersions',
                            vastVersions
                              .filter(
                                (v) =>
                                  (checked && v.value === value) ||
                                  (v.value !== value &&
                                    values.vastVersions.includes(v.value))
                              )
                              .map((v) => v.value)
                          )
                        }
                        value={version.value}
                        name="vastVersion"
                        data-tc="technicalProviderVastVersion"
                      />
                    }
                    label={version.label}
                  />
                </div>
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            id="territories"
            options={getTerritoryValues(territories || [])}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue('territories', fieldValue);
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default TechnicalProviderDetails;
