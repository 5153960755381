import {
  DeleteUserMutationFn,
  Permission,
  UpdateUserMutationFn,
} from 'interfaces/generated.types';
import {
  getReadVersionOfPermission,
  getPermissionsWithReadOnlyVersions,
  permissionExistsInList,
} from 'utils/permissions';
import { History } from 'history';
import { IUserValues } from 'features/user/components/UserForm/userFormUtils';
import { FormikErrors } from 'formik';
import { differenceInValues, handleFormErrors } from 'utils/forms';

export interface IMatch {
  params: {
    userId: string;
  };
}

export const formatUserPermissions = (permissions: Permission[]) => {
  const editPermissions = getPermissionsWithReadOnlyVersions();

  return permissions.reduce((result: any[], userPermission: Permission) => {
    const { name } = userPermission;

    if (!name) return result;

    const readPermission = getReadVersionOfPermission(name);

    return permissionExistsInList(editPermissions, name) && readPermission
      ? [...result, name, readPermission]
      : [...result, name];
  }, []);
};

export const handleOnComplete = (history: History) => {
  history.push('/users');
  window.location.reload();
};

export const submitUpdateUser = ({
  updateUser,
  formValues,
  match,
  toggleErrorModal,
  setStatus,
  setSubmitting,
  initialValues,
}: {
  updateUser: UpdateUserMutationFn;
  formValues: IUserValues;
  match: IMatch;
  toggleErrorModal: () => void;
  setStatus: (errors: FormikErrors<IUserValues>) => void;
  setSubmitting: (bool: boolean) => void;
  initialValues: IUserValues;
}) => {
  const filteredValues: any = {
    ...differenceInValues(formValues, initialValues),
    id: match.params.userId,
    territories: formValues.territories,
  };

  updateUser({
    variables: filteredValues,
  }).catch((error) => {
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  });
};

export const submitDeleteUser = (
  deleteUser: DeleteUserMutationFn,
  match: IMatch
) => {
  deleteUser({
    variables: {
      id: match.params.userId,
    },
  });
};
