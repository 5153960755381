import StyledButton, {
  ButtonVariantEnum,
} from 'features/targetingV2/components/core/StyledButton/StyledButton';
import TextField from 'features/targetingV2/components/core/TextField';
import React, { Ref, useRef } from 'react';

import useStyles from './FileUpload.styles';

interface IFileUploadProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileType: string;
  label: string;
  name: string;
  externalUploadInputRef?: Ref<any>;
}

const FileUpload = React.forwardRef(
  (props: IFileUploadProps, ref: React.Ref<any>): React.ReactElement => {
    const { onChange, fileType, label, name, externalUploadInputRef } = props;

    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    const classes = useStyles();

    const handleClick = () => {
      if (uploadInputRef.current) {
        uploadInputRef.current.value = '';
        uploadInputRef.current.click();
      }
    };

    return (
      <div className={classes.wrapper} ref={ref}>
        <TextField
          inputRef={externalUploadInputRef || uploadInputRef}
          type="file"
          className={classes.input}
          inputProps={{
            accept: fileType,
            'data-testid': `fileInput-${name}`,
          }}
          onChange={onChange}
        />
        {!externalUploadInputRef && (
          <StyledButton
            variant={ButtonVariantEnum.Text}
            data-testid={`fileUploadButton-${name}`}
            onClick={handleClick}
          >
            {label}
          </StyledButton>
        )}
      </div>
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;
