import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, ReactElement, Ref } from 'react';

import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@material-ui/core';

import useStyles from './TextField.styles';

export type TextFieldProps = MUITextFieldProps;

const TextField = forwardRef(
  (props: TextFieldProps, ref: Ref<any>): ReactElement => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);
    return (
      <>
        <MUITextField ref={ref} classes={classes} {...otherProps} />
      </>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
