import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import React, { useState } from 'react';
import { getTerritoryIcon } from 'utils/territory';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import TerritorySelect from '../TerritorySelect/TerritorySelect';
import useStyles from './TerritoryModal.styles';

const TerritoryModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    state: {
      user: { activeTerritory, territories },
    },
  } = useSessionContext();

  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <IconButton
          onClick={() => setIsModalOpen(!isModalOpen)}
          data-testid="territoryButton"
          disabled={territories && territories.length === 1}
        >
          {getTerritoryIcon(activeTerritory)}
          <span className="sr-only">Active territory: {activeTerritory}</span>
        </IconButton>
      </div>
      {isModalOpen && (
        <Dialog
          data-testid="territoryModal"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle classes={{ root: classes.title }} disableTypography>
            <Typography display="inline">Select Territory</Typography>
          </DialogTitle>
          <DialogContent classes={{ root: classes.content }}>
            <Grid container xs={12} className={classes.selectWrapper}>
              <TerritorySelect setIsModalOpen={setIsModalOpen} />
            </Grid>
            <Typography className={classes.text} align="center">
              If you can not access your territory please contact your Global
              representative{' '}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default TerritoryModal;
