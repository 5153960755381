import gql from 'graphql-tag';

export const ALL_TECHNOLOGY_PARAMETERS = gql`
  query allTechnologyParameters {
    allTechnologyParameters {
      type
      name
      cardinality
    }
  }
`;

export const GET_TECHNOLOGY_PARAMETER_VALUES = gql`
  query technologyParameterValues(
    $type: TechnologyParameterType!
    $search: String
  ) {
    technologyParameterValues(type: $type, search: $search) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;
