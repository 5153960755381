import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IOrderValues } from 'features/direct/order/components/OrderTabsForm/OrderFormValues';
import OrderTabsForm, {
  orderButtons,
} from 'features/direct/order/components/OrderTabsForm/OrderTabsForm';
import OrderFormValidation from 'features/direct/order/validations/orders';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  Advertiser,
  CreateOrderMutation,
  CreateOrderMutationFn,
  FrequencyCapTimeUnit,
  Order,
  useCreateOrderMutation,
  User,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import {
  createSelectOptions,
  createUserOption,
  transformSelectOption,
} from 'utils/dataTransformation';
import {
  getDefaultExternalSystemIdBasedOnTerritory,
  getDefaultTimezoneBasedOnTerritory,
} from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface ICreateOrderContainerProps {
  history: History;
  allAdvertisers: Advertiser[];
  allUsers: User[];
  currentUser: User;
}

export const handleOnComplete = (
  response: CreateOrderMutation,
  history: History,
  selectedJourney: string
) => {
  const { createOrder } = response;
  const { id } = createOrder as Order;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitCreateOrder = (
  createOrder: CreateOrderMutationFn,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IOrderValues,
  { setStatus, setSubmitting }: FormikActions<IOrderValues>
) => {
  setSelectedJourney(formValues.journey);

  const formattedValues = formatValues(formValues);

  const filteredValues: any = pickBy({
    ...formValues,
    allAdvertisers: null,
    advertiser: null,
    journey: null,
    advertiserId: transformSelectOption(formValues.advertiser),
    startDate: formattedValues.startDate,
    endDate: formattedValues.endDate,
    objective: formattedValues.objective,
    ownerId: formattedValues.ownerId,
    owner: null,
    unlimitedObjective: null,
  });

  createOrder({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateOrderContainer = ({
  history,
  allAdvertisers,
  allUsers,
  currentUser,
}: ICreateOrderContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Order.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createOrder] = useCreateOrderMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney);
    },
  });

  return (
    <>
      <Formik<IOrderValues>
        initialValues={{
          startDate: null,
          endDate: null,
          timeZone: getDefaultTimezoneBasedOnTerritory(activeTerritory!),
          name: '',
          id: '',
          externalId: '',
          externalSystemId: getDefaultExternalSystemIdBasedOnTerritory(
            activeTerritory!
          ),
          allAdvertisers: createSelectOptions(allAdvertisers),
          advertiser: null,
          objective: '',
          unlimitedObjective: false,
          journey: orderButtons[0].url,
          noEndDate: false,
          owner: createUserOption(currentUser),
          frequencyCapCount: '',
          frequencyCapTimeUnit: FrequencyCapTimeUnit.TimeUnitUnspecified,
          frequencyCapValue: '',
          territory: activeTerritory,
        }}
        validationSchema={OrderFormValidation(activeTerritory)}
        onSubmit={submitCreateOrder(
          createOrder,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(props) => (
          <OrderTabsForm {...props} allUsers={allUsers} history={history} />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createOrder"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateOrderContainer;
