import classNames from 'classnames';
import Control from 'features/targetingV2/components/core/MultiSelect/components/Control/Control';
import Menu from 'features/targetingV2/components/core/MultiSelect/components/Menu/Menu';
import MenuList from 'features/targetingV2/components/core/MultiSelect/components/MenuList/MenuList';
import MultiValue from 'features/targetingV2/components/core/MultiSelect/components/MultiValue/MultiValue';
import Option from 'features/targetingV2/components/core/MultiSelect/components/Option/Option';
import ValueContainer from 'features/targetingV2/components/core/MultiSelect/components/ValueContainer/ValueContainer';
import {
  getSelectOptions,
  IMultiSelectProps,
  SelectType,
} from 'features/targetingV2/utils/multiSelect';
import React, { useEffect, useState } from 'react';
import Select, { InputActionTypes } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import NoSsr from '@material-ui/core/NoSsr';

import { customStyles, useStyles } from './MultiSelect.styles';

const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  Option,
  ValueContainer,
};

const MultiSelect = (props: IMultiSelectProps) => {
  const {
    id,
    name,
    options,
    value,
    isClearable = true,
    isDisabled = false,
    label = '',
    maxSelectHeight = 'auto',
    placeholder,
    onBlur,
    onChange,
    noOptionsText = 'No options',
    maxSelectedOptions,
    maxSelectedOptionsText = 'You have reached the maximum selection limit',
    errorProps,
    attributes,
    components: externalComponents,
    externalClasses = {},
    externalLink = false,
    testId = '',
    disableSearch = false,
    isLoading = false,
    openMenuOnClick = true,
    maxSearchResults,
    noOfMultiValuesToShow,
    showBreadcrumbsOnMultiValue = false,
    menuDualListProps,
    inactiveMultiValueTooltip = 'This value is currently not available',
    selectType = SelectType.Default,
    clearSearchTermOnSelection = false,
    autoFocus = false,
    openMenuOnFocus = false,
    selectRef,
    ...otherProps
  } = props;

  const classes = useStyles();
  const selectOptions = getSelectOptions({
    value,
    options,
    maxSelectedOptions,
  });
  const [multiSelectQuery, setMultiSelectQuery] = useState('');

  const [showHiddenMultiValues, setShowHiddenMultiValues] = useState(
    noOfMultiValuesToShow === undefined
  );

  useEffect(() => {
    if (clearSearchTermOnSelection) setMultiSelectQuery('');
  }, [value, clearSearchTermOnSelection]);

  const selectProps = {
    ...otherProps,
    classes,
    externalClasses,
    styles: customStyles,
    inputId: id,
    maxMenuHeight: 250,
    maxSelectHeight,
    name,
    openMenuOnClick,
    placeholder,
    options: selectOptions,
    showHiddenMultiValues,
    setShowHiddenMultiValues,
    noOfMultiValuesToShow,
    hideSelectedOptions: false,
    closeMenuOnSelect: false,
    externalLink,
    components: { ...components, ...externalComponents },
    value,
    onBlur,
    onChange,
    isClearable,
    isMulti: true,
    isDisabled,
    onInputChange: (
      query: string,
      { action }: { action: InputActionTypes }
    ) => {
      if (action === 'input-change') setMultiSelectQuery(query);
      if (action === 'menu-close') setMultiSelectQuery('');
    },
    inputValue: multiSelectQuery,
    noOptionsMessage: () =>
      value && Object.keys(value).length === maxSelectedOptions
        ? maxSelectedOptionsText
        : noOptionsText,
    AttributeProps: { ...attributes },
    TextFieldProps: {
      label,
      helperText: errorProps?.helperText,
      FormHelperTextProps: errorProps?.FormHelperTextProps,
      InputLabelProps: {
        htmlFor: id,
        shrink: true,
      },
    },
    isSearchable: !disableSearch,
    isLoading,
    showBreadcrumbsOnMultiValue,
    maxSearchResults,
    menuDualListProps,
    inactiveMultiValueTooltip,
    selectType,
    autoFocus,
    openMenuOnFocus,
    ref: selectRef,
  };

  const SelectComponents = {
    [SelectType.Default]: (
      <Select {...otherProps} {...selectProps} testId={`${id}-input`} />
    ),
    [SelectType.Creatable]: (
      <CreatableSelect
        {...otherProps}
        {...selectProps}
        testId={`${id}-creatable-input`}
      />
    ),
  };

  return (
    <div
      className={classNames([classes.root, externalClasses.root])}
      data-testid={testId}
    >
      <NoSsr>{SelectComponents[selectType]}</NoSsr>
    </div>
  );
};

export default MultiSelect;
