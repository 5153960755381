import { ReactComponent as UserIcon } from 'assets/icons/dax-usericon.svg';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { UPDATE_ASSUMED_USER } from 'context/CustomApolloProvider/reducer';
import React from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { useEditableCellStyles } from './Table.styles';

interface ITableAssumeUserCell {
  entity: {
    id: string;
    email: string;
  };
  children?: React.ReactNode;
  dataTc: string;
}

const TableAssumeUserCell = ({
  entity,
  children = <UserIcon />,
  dataTc,
}: ITableAssumeUserCell) => {
  const classes = useEditableCellStyles();

  const { dispatch } = useCustomApolloContext();
  const history = useHistory();

  const onAssumeUserIdClick = (assumedId: string, assumedEmail: string) => {
    dispatch({
      type: UPDATE_ASSUMED_USER,
      payload: {
        id: assumedId,
        email: assumedEmail,
      },
    });
    history.push('/home');
  };

  return (
    <>
      <div className={classes.root}>
        <Tooltip title="Temporarily assume user identity">
          <span>
            <IconButton
              onClick={() => onAssumeUserIdClick(entity.id, entity.email)}
              data-tc={`${dataTc}Button`}
            >
              {children}
              <span className="sr-only">Assume user id</span>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </>
  );
};

export default TableAssumeUserCell;
