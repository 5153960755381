import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import TargetingPackForm, {
  ITargetingPackValues,
} from 'features/targeting/components/TargetingPackForm/TargetingPackForm';
import { getTargetingPackDetails } from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import { packVariables } from 'features/targeting/containers/UpdateTargetingPackContainer/UpdateTargetingPackContainer';
import TargetingPackFormValidation from 'features/targeting/validations/targetingPacks';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useCreatePostcodeGroupMutation } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { handleFormErrors } from 'utils/forms';

interface ICreateTargetingPackContainerProps {
  history: History;
}

export const handleOnComplete = (history: History) =>
  history.push('/targeting-packs');

export const submitCreateTargetingPack = (
  createTargetingPack: any,
  toggleErrorModal: () => void
) => (formValues: any, { setStatus, setSubmitting }: FormikActions<any>) => {
  const targetingPackDetails = getTargetingPackDetails(formValues.type);
  const variables = {
    name: formValues.name,
    description: formValues.description,
    territory: formValues.territory,
    ...packVariables(targetingPackDetails, formValues),
  };

  createTargetingPack({
    variables,
  }).catch((error: any) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateTargetingPackContainer = ({
  history,
}: ICreateTargetingPackContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Targeting Pack.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const [createQuery, setCreateQuery] = useState<any>(
    () => useCreatePostcodeGroupMutation
  );

  const [createTargetingPack] = createQuery({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <>
      <Formik<ITargetingPackValues>
        initialValues={{
          name: '',
          description: '',
          postcodes: [],
          countryParams: {
            countries: [],
            regions: [],
            subRegions: [],
          },
          territory: activeTerritory!,
        }}
        validationSchema={TargetingPackFormValidation}
        onSubmit={submitCreateTargetingPack(
          createTargetingPack,
          toggleErrorModal
        )}
      >
        {(props) => (
          <TargetingPackForm {...props} setCreateQuery={setCreateQuery} />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createTargetingPack"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateTargetingPackContainer;
