import { useEffect, useState } from 'react';

import utils from './csvParserUtils';

interface IUseCsvParser {
  data: any[] | null;
  error: string | null;
  setFile: (file: File | null) => void;
  file: File | null;
  setError: (error: string | null) => void;
}

export default function useCsvParser({
  selectedFile = null,
  header = false,
  delimiter = ',',
  headerOptions = [],
  maxFileSize = utils.defaultMaxFileSize,
}: {
  selectedFile?: File | null;
  header?: boolean;
  delimiter: string;
  headerOptions?: string[];
  maxFileSize?: number;
}): IUseCsvParser {
  const [file, setFile] = useState<null | File>(selectedFile);
  const [error, setError] = useState<null | string>(null);
  const [data, setData] = useState<null | any>(null);

  useEffect(() => {
    if (selectedFile) {
      const errorMessage = utils.getErrorMessage(selectedFile, maxFileSize);
      if (errorMessage) {
        setFile(null);
        setError(errorMessage);
      } else {
        setFile(selectedFile);
        setError(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    if (!file) {
      setData(null);
      return;
    }
    const parseCsv = async () => {
      try {
        const parsedCsv = await utils.parseFile({
          file,
          header,
          delimiter,
          headerOptions,
        });
        setData(parsedCsv);
      } catch (err: any) {
        setError(err || utils.defaultErrorMessage);
      }
    };

    parseCsv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, header, delimiter]);

  return { data, setError, error, setFile, file };
}
