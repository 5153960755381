import classNames from 'classnames';
import BulkUpload from 'features/targetingV2/components/BulkUpload/BulkUpload';
import MultiSelect from 'features/targetingV2/components/core/MultiSelect/MultiSelect/MultiSelect';
import ParameterMessage, { ParameterMessageColorEnum } from 'features/targetingV2/components/ParameterMessage/ParameterMessage';
import useBulkUploadMessages from 'features/targetingV2/hooks/BulkUploadMessages/useBulkUploadMessages';
import useParameterValues from 'features/targetingV2/hooks/ParameterValues/useParameterValues';
import {
  IParameterValueOption,
  SectionType,
} from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import {
  getHeaderDetails,
  handleParameterValuesUpload,
} from 'features/targetingV2/utils/bulkUpload';
import {
  getNoOfMultiValuesToShow,
  handleNoOptionsText,
  hasBulkUpload,
  parametersWithBreadCrumbsOnMultiValue,
} from 'features/targetingV2/utils/common';
import {
  getMaxSearchResults,
  getMenuListComponent,
  getMenuProps,
} from 'features/targetingV2/utils/parameterValues';
import { Territory } from 'interfaces/generated.types';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import useStyles from './DropdownSelector.styles';

export interface IDropdownSelectorProps {
  sectionType: SectionType;
  selectedParameter: IParameter;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  selectedParameterLabel?: string;
  placeholder?: string;
  classes?: { input?: string };
  externalUploadInputRef?: MutableRefObject<HTMLInputElement | null>;
  idUsedToLimitParameterValues?: string;
  territory?: Territory;
}

const DropdownSelector = ({
  sectionType,
  selectedParameter,
  fieldName,
  setFieldValue,
  selectedParameterLabel = 'value',
  placeholder = '',
  classes: externalClasses = {},
  externalUploadInputRef,
  idUsedToLimitParameterValues,
  territory,
}: IDropdownSelectorProps) => {
  const classes = useStyles();
  const dropdownRef = useRef<HTMLInputElement | null>(null);
  const valuesFieldName = `${fieldName}.values`;

  const {
    warningMessage,
    errorMessage,
    setWarningMessage,
    setErrorMessage,
    setIsBulkUploaded,
  } = useBulkUploadMessages(selectedParameter, externalUploadInputRef);

  const inputClasses = classNames([
    classes.input,
    { [`${classes.input}--error`]: errorMessage },
  ]);

  const [
    isUpdatingParameterValuesOptions,
    setIsUpdatingParameterValuesOptions,
  ] = useState<boolean>(false);

  const {
    isLoadingPrimaryValues,
    primaryValuesOptions,
    isLoadingSecondaryValues,
    secondaryValuesOptions,
  } = useParameterValues(
    sectionType,
    selectedParameter.type,
    setIsUpdatingParameterValuesOptions,
    territory,
    idUsedToLimitParameterValues
  );

  const handleChange = (newValues: IParameterValueOption[]) => {
    setFieldValue(valuesFieldName, newValues);
  };

  const handleBlur = () => {
    setFieldValue(`${fieldName}.isFocused`, false);
  };

  const maxSearchResults = getMaxSearchResults(selectedParameter.type);
  const menuProps = getMenuProps(
    selectedParameter,
    primaryValuesOptions || [],
    secondaryValuesOptions
  );
  const headerDetails = getHeaderDetails(selectedParameter.type);

  useEffect(() => {
    if (
      !isLoadingPrimaryValues &&
      primaryValuesOptions &&
      !isLoadingSecondaryValues &&
      secondaryValuesOptions
    ) {
      setIsUpdatingParameterValuesOptions(false);
    }
  }, [
    isLoadingPrimaryValues,
    isLoadingSecondaryValues,
    primaryValuesOptions,
    secondaryValuesOptions,
  ]);

  useEffect(() => {
    if (dropdownRef?.current && selectedParameter.type) {
      dropdownRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter.type]);

  return (
    <div className={classes.container}>
      <MultiSelect
        options={primaryValuesOptions || []}
        id={`${valuesFieldName}-dropdown`}
        showBreadcrumbsOnMultiValue={parametersWithBreadCrumbsOnMultiValue.includes(
          selectedParameter.type
        )}
        name={`valuesSelect-${selectedParameter.type}`}
        value={selectedParameter.values}
        onChange={handleChange}
        onBlur={handleBlur}
        isClearable={false}
        placeholder={placeholder}
        isLoading={isUpdatingParameterValuesOptions}
        maxSearchResults={maxSearchResults}
        components={{
          MenuList: getMenuListComponent(selectedParameter.type),
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        externalClasses={{
          ...classes,
          input: inputClasses,
          ...externalClasses,
        }}
        noOptionsText={handleNoOptionsText(selectedParameter.type)}
        noOfMultiValuesToShow={getNoOfMultiValuesToShow(selectedParameter.type)}
        menuDualListProps={menuProps}
        inactiveMultiValueTooltip={`This ${selectedParameterLabel} is currently not available`}
        selectRef={dropdownRef}
        autoFocus={!!selectedParameter.isFocused}
        openMenuOnFocus
      />
      <div className={classes.messagesWrapper}>
        {warningMessage && (
          <ParameterMessage
            message={warningMessage}
            color={ParameterMessageColorEnum.Warning}
          />
        )}
        {errorMessage && (
          <ParameterMessage
            message={errorMessage}
            color={ParameterMessageColorEnum.Error}
          />
        )}
      </div>
      {hasBulkUpload(selectedParameter.type) && (
        <BulkUpload
          fieldName={valuesFieldName}
          label="Bulk Upload"
          externalUploadInputRef={externalUploadInputRef}
          handleDataUpload={(data, error) =>
            handleParameterValuesUpload(
              data,
              error,
              selectedParameter,
              valuesFieldName,
              setErrorMessage,
              setWarningMessage,
              setFieldValue,
              setIsBulkUploaded
            )
          }
          headerOptions={headerDetails.headerOptions}
          header={headerDetails.header}
        />
      )}
    </div>
  );
};

export default DropdownSelector;
