import formStyles from 'assets/styles/components/Form.styles';
import TargetingGroupings from 'features/targeting/components/TargetingGroupings/TargetingGroupings';
import { Field } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import { Territory } from 'interfaces/generated.types';
import React from 'react';
import { ITargetingGroup } from 'utils/targetingDefinitions';
import { TerritoryLabel } from 'utils/territory';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

interface ITargetingTemplateValues {
  name: string;
  description: string;
  isMasterTemplate: boolean;
  targetingGroups: ITargetingGroup[];
  territory: Territory;
}

const TargetingTemplateDetails = (
  props: IFormProps<ITargetingTemplateValues>
) => {
  const { errors, touched, update = false, status = {}, values } = props;

  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Field
        component={TextField}
        label="Template Name"
        placeholder="Template Name"
        name="name"
        role="textbox"
        autoFocus={!update}
        data-tc="templateName"
        fullWidth
        inputProps={{ 'data-testid': 'templateName' }}
        helperText={(touched.name && errors.name) || status.name}
        FormHelperTextProps={{
          error: !!((touched.name && errors.name) || status.name),
        }}
      />

      <Field
        component={TextField}
        label="Template Description"
        placeholder="Template Description"
        name="description"
        data-tc="templateDescription"
        fullWidth
        margin="normal"
        multiline
        helperText={
          (touched.description && errors.description) || status.description
        }
        FormHelperTextProps={{
          error: !!(
            (touched.description && errors.description) ||
            status.description
          ),
        }}
      />

      <Field
        component={TextField}
        label="Territory"
        placeholder="Territory"
        name="territory"
        value={TerritoryLabel[values.territory!]}
        data-tc="territory"
        fullWidth
        disabled
        margin="normal"
        id="Territory"
        helperText={status.territory}
        FormHelperTextProps={{
          error: !!status.territory,
        }}
      />

      <FormControl component={'fieldset' as 'div'} margin="normal">
        <FormControlLabel
          control={
            <Field
              component={Checkbox}
              type="checkbox"
              name="isMasterTemplate"
              disabled={update}
              data-tc="templateIsMasterTemplate"
              color="primary"
            />
          }
          label="Make a primary template"
        />
      </FormControl>

      <div className={classes.targetingGroup} data-tc="targetingGroup">
        <TargetingGroupings
          showTemplates={false}
          showGeneralTargeting={false}
          {...props}
        />
      </div>
      {(touched.targetingGroups &&
        errors.targetingGroups &&
        typeof errors.targetingGroups === 'string') ||
        (status.targetingGroups && (
          <FormHelperText error>{errors.targetingGroups}</FormHelperText>
        ))}
    </fieldset>
  );
};

export default TargetingTemplateDetails;
