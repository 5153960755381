import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const baseTargetingStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      '& + &': {
        marginTop: theme.spacing(3),
      },
    },
    value: {
      margin: theme.spacing(0, 1.25),
      flex: 100,
    },
    pairValues: {
      composes: '$value',
      flex: 50,
    },
    tripleValues: {
      composes: '$value',
      flex: 33.3,
    },
    valueWrapper: {
      marginBottom: theme.spacing(1.5),
    },
    errors: {
      margin: theme.spacing(1.25),
      width: '100%',
    },
    errorMessage: {
      display: 'block',
      '& + &': {
        marginTop: theme.spacing(0.626),
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    button: {
      margin: theme.spacing(0, 0.5),
    },
    group: {
      position: 'relative',
      padding: theme.spacing(2.5),
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.spacing(1),
      '&--general': {
        backgroundColor: theme.palette.grey[200],
      },
      marginBottom: theme.spacing(1),
    },
    bulkUploadWrapper: {
      margin: theme.spacing(0, 1.25, 3),
    },
  });

const targetingStyles = makeStyles((theme: Theme) => ({
  ...baseTargetingStyles(theme),
}));

export default targetingStyles;
