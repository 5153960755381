import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';

import CreateAdvertiserContainer from 'features/direct/advertiser/containers/CreateAdvertiserContainer/CreateAdvertiserContainer';

import { Agency, useAgencyMinimalQuery } from 'interfaces/generated.types';

interface ICreateAdvertiserPage {
  history: History;
  match: {
    params: {
      agencyId: string;
    };
  };
}

const CreateAdvertiserPage = ({ history, match }: ICreateAdvertiserPage) => {
  const { loading, data, error } = useAgencyMinimalQuery({
    variables: { id: match.params.agencyId },
  });

  if (!loading && (error || !(data && data.agency)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Advertiser</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreateAdvertiserContainer
            history={history}
            match={match}
            agency={data?.agency as Agency}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateAdvertiserPage;
