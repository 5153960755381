import MenuDualTreeList from 'features/targetingV2/components/core/MultiSelect/components/MenuDualTreeList/MenuDualTreeList';
import MenuTreeList from 'features/targetingV2/components/core/MultiSelect/components/MenuTreeList/MenuTreeList';
import {
  ChipState,
  IParameterValueOption,
  MenuListTitleEnum,
  SectionType,
} from 'features/targetingV2/types/common';
import {
  IAudienceParameter,
  IParameter,
} from 'features/targetingV2/types/targeting';
import {
  AudienceParameterInfo,
  AudienceParameterType,
  InventoryStatus,
  LocationParameterType,
  TargetingGrade,
  Territory,
  useAllAdRequestEnrichmentAudienceParameterValuesLazyQuery,
  useAudienceParameterValuesLazyQuery,
  useInventoryParameterValuesLazyQuery,
  useLocationParameterValuesLazyQuery,
  useTechnologyParameterValuesLazyQuery,
} from 'interfaces/generated.types';
import { lowerCase } from 'lodash';
import { formatListToString } from 'utils/formatStrings';

import { getGeoSelectedNodesPerList, handleGeoNodeToggle } from './geoValues';
import { getGradeLabelMinimal } from './grading';
import {
  getSelectedPopularInterests,
  handleInterestNodeToggle,
} from './popularInterestValues';

export interface IParameterValuesQueryDetails {
  query: Function;
  path: string;
  fetchPolicy: string;
  variables?: any;
  skipQuery?: boolean;
}

export const getParameterValuesQueryDetails = (
  parameterSection: SectionType,
  territory: Territory
): IParameterValuesQueryDetails => {
  const defaultFetchPolicy = 'cache-first';
  switch (parameterSection) {
    case SectionType.AdRequestEnrichmentAudience:
      return {
        query: useAllAdRequestEnrichmentAudienceParameterValuesLazyQuery,
        fetchPolicy: defaultFetchPolicy,
        path: 'allAdRequestEnrichmentAudienceParameterValues',
      };
    case SectionType.Audience:
      return {
        query: useAudienceParameterValuesLazyQuery,
        fetchPolicy: defaultFetchPolicy,
        path: 'audienceParameterValues',
      };
    case SectionType.Location:
      return {
        query: useLocationParameterValuesLazyQuery,
        fetchPolicy: 'no-cache',
        path: 'locationParameterValues',
      };
    case SectionType.Technology:
      return {
        query: useTechnologyParameterValuesLazyQuery,
        fetchPolicy: defaultFetchPolicy,
        path: 'technologyParameterValues',
      };
    case SectionType.Inventory:
      return {
        query: useInventoryParameterValuesLazyQuery,
        fetchPolicy: 'no-cache',
        path: 'inventoryParameterValues',
        variables: {
          territory,
          status: InventoryStatus.Enabled,
        },
      };

    default:
      return {
        query: () => [() => {}, { data: [], loading: false }],
        fetchPolicy: defaultFetchPolicy,
        path: '',
      };
  }
};

export const getMaxSearchResults = (parameterType: string) => {
  switch (parameterType) {
    case LocationParameterType.Geo:
      return 6;
    default:
      return 8;
  }
};

export const getMenuProps = (
  parameter: IParameter,
  primaryValuesOptions: IParameterValueOption[],
  secondaryValuesOptions: IParameterValueOption[]
) => {
  switch (parameter.type) {
    case LocationParameterType.Geo: {
      const selectedNodesPerList = getGeoSelectedNodesPerList(parameter.values);

      return {
        firstList: {
          title: MenuListTitleEnum.AllLocations,
          options: primaryValuesOptions,
          selectedNodes: selectedNodesPerList.firstList,
        },
        secondList: {
          title: MenuListTitleEnum.RegionPacks,
          options: secondaryValuesOptions,
          selectedNodes: selectedNodesPerList.secondList,
          displayAncestorPath: false,
        },
        additionalEditingFunction: handleGeoNodeToggle,
        allPreviouslySelectedNodes: parameter.values,
      };
    }
    case AudienceParameterType.Interest: {
      const selectedPopularInterests = getSelectedPopularInterests(
        parameter.values,
        secondaryValuesOptions
      );

      return {
        firstList: {
          title: MenuListTitleEnum.PopularInterests,
          options: secondaryValuesOptions,
          selectedNodes: selectedPopularInterests,
          displayAncestorPath: true,
        },
        secondList: {
          title: MenuListTitleEnum.AllInterests,
          options: primaryValuesOptions,
          selectedNodes: parameter.values,
        },
        additionalEditingFunction: handleInterestNodeToggle,
      };
    }
    default:
      return {
        firstList: {
          title: '',
          options: [],
          selectedNodes: [],
        },
        secondList: {
          title: '',
          options: [],
          selectedNodes: [],
        },
        additionalEditingFunction: undefined,
      };
  }
};

export const getMenuListComponent = (parameterType: string) => {
  switch (parameterType) {
    case LocationParameterType.Geo:
    case AudienceParameterType.Interest:
      return MenuDualTreeList;
    default:
      return MenuTreeList;
  }
};

export const enrichAudienceParameterValues = (
  parameter: IAudienceParameter,
  parametersInfo: AudienceParameterInfo[]
): IAudienceParameter => {
  const parameterEnabledGrades = parameter.enabledGrades;
  const parameterTypeLabel = lowerCase(
    parametersInfo.find(
      (parameterInfo) => parameter.type === parameterInfo.type
    )?.name || 'value'
  );

  const enrichedValues = parameter.values.map(
    (parameterValue: IParameterValueOption) => {
      const hasMatchingGrade =
        !!parameterValue.activeGrades?.length &&
        parameterValue.activeGrades?.some((grade: TargetingGrade) =>
          parameterEnabledGrades.includes(grade)
        );

      const activeGradesLabels = parameterValue.activeGrades?.map(
        (grade: TargetingGrade) => getGradeLabelMinimal(grade)
      );

      const tooltip = !parameterValue.activeGrades?.length
        ? `There are no active grades for this ${parameterTypeLabel}`
        : `Please select grade ${formatListToString({
            list: activeGradesLabels,
            conjunctionSeparator: ' or ',
          })} to target this ${parameterTypeLabel}`;

      return {
        ...parameterValue,
        state: hasMatchingGrade ? ChipState.DEFAULT : ChipState.WARNING,
        tooltip: hasMatchingGrade ? '' : tooltip,
      };
    }
  );

  return {
    ...parameter,
    values: [...enrichedValues],
  };
};
