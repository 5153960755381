import debounce from 'debounce-promise';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import Loader from 'components/Loader/Loader';

import { IFormProps } from 'interfaces';
import { Dma, useListDmasQuery } from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';
import { IListDmasResponse } from '../../graphql/dmas/queries';

import { TargetingAsyncMultiSelect } from '../TargetingMultiSelect/TargetingMultiSelect';

export const dmaValues = {
  dmaParams: {
    dmas: [],
  },
};

const createOptions = (data: Dma[]) =>
  data.map((dma) => ({
    label: dma.name,
    value: dma.code.toString(),
  }));

export const loadOptions = async (
  searchTerm: string,
  setFetchedOptions: any,
  data?: IListDmasResponse
) => {
  if (searchTerm.length >= 2) {
    if (data) {
      const listResult = data.allDmas.filter(
        (dma: Dma) =>
          dma.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dma.code.toString().includes(searchTerm)
      );
      const response: Dma[] = await new Promise((resolve) =>
        resolve(listResult)
      );
      const formattedData = createOptions(response);
      setFetchedOptions(formattedData);
      return formattedData;
    }
    return [];
  }
  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 1000, {
  leading: true,
});

const TargetingDMA = (props: IFormProps<any> & ITargetingComponentProps) => {
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const { loading, data } = useListDmasQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <ApolloConsumer>
      {() => (
        <TargetingAsyncMultiSelect
          {...props}
          label="Designated Market Area"
          placeholder="Begin typing to find a Designated Market Area"
          targetingName="dmaParams.dmas"
          loadOptions={(value: string) =>
            debouncedLoadOptions(value, setFetchedOptions, data)
          }
          fetchedOptions={fetchedOptions}
        />
      )}
    </ApolloConsumer>
  );
};

export default TargetingDMA;
