import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as DeleteIcon } from 'assets/icons/dax-deleteicon.svg';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ISetErrorModal } from 'components/ErrorDialog/ErrorDialog';

import { IDeleteCellData } from './Table';
import { useEditableCellStyles } from './Table.styles';

interface ITableDeleteCell {
  entity: {
    id: string;
    name: string;
  };
  onCellDelete: ({ entity, setErrorModal }: IDeleteCellData) => Promise<void>;
  children?: React.ReactNode;
  setErrorModal: (values: ISetErrorModal) => void;
  isEditable: boolean;
  dataTc: string;
}

const TableDeleteCell = ({
  entity,
  onCellDelete,
  children = <DeleteIcon />,
  setErrorModal,
  isEditable,
  dataTc,
}: ITableDeleteCell) => {
  const classes = useEditableCellStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleDelete = async () => {
    setModalOpen(false);
    await onCellDelete({ entity, setErrorModal });
  };

  return (
    <>
      <div className={classes.root}>
        <Tooltip title="Delete">
          <span>
            <IconButton
              onClick={handleClick}
              disabled={!isEditable}
              data-tc={`${dataTc}Button`}
            >
              {children}
              <span className="sr-only">Delete</span>
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {isModalOpen ? (
        <ConfirmationModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          handleConfirm={handleDelete}
          dataTc={`${dataTc}ConfirmationModal`}
          content={{
            title: 'Are You Sure?',
            main: `This will delete ${entity.name}`,
          }}
        />
      ) : null}
    </>
  );
};

export default TableDeleteCell;
