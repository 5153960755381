import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import { Menu as MUIMenu, MenuProps as MUIMenuProps } from '@material-ui/core';

import useStyles from './Menu.styles';

export type MenuProps = MUIMenuProps;

const Menu = React.forwardRef((props: MenuProps, ref: React.Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUIMenu ref={ref} classes={classes} {...otherProps} />;
});

Menu.displayName = 'Menu';

export default Menu;
