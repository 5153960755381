import { baseFontStyles } from 'assets/styles/components/Font.styles';
import { basePageStyles } from 'assets/styles/components/Page.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  ...basePageStyles(theme),
  heading: {
    fontWeight: 500,
  },
  infoWrapper: {
    textAlign: 'center',
    margin: theme.spacing(1.5, 10, 0),
    color: theme.palette.grey[800],
  },
  textFieldContainer: {
    alignItems: 'center',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  textFieldLabel: {
    fontSize: '13px',
  },
  textField: {
    width: '416px',
  },
  button: {
    width: '96px',
    height: '32px',
    fontSize: '15px',
    borderColor: theme.palette.grey[800],
    color: theme.palette.grey[800],
    '&--submit': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(5, 4, 0),
    '&--space-evenly': {
      justifyContent: 'space-evenly',
    },
  },
  resendLink: {
    fontSize: '12px',
    color: theme.palette.grey[800],
  },
  resendLinkContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  errorWrapper: {
    composes: '$errorMessage',
    padding: theme.spacing(1, 4, 0, 4),
  },
}));

export default useStyles;
