import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  searchTextField: {
    width: theme.spacing(12),
    height: theme.spacing(4),
    paddingLeft: theme.spacing(0.5),
    transition: 'width 0.6s ease',
  },
  searchTextFieldExpanded: {
    paddingLeft: theme.spacing(0.5),
    height: theme.spacing(4),
    width: '100%',
    transition: 'width 0.6s ease',
    marginRight: theme.spacing(4),
  },
  listboxWrapper: {
    padding: 0,
  },
  optionContainer: {
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
    '&:hover, &[data-focus="true"]': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:last-child div': {
      borderBottomWidth: 0,
    },
  },
  optionWrapper: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    paddingBottom: theme.spacing(0.5),
    textDecoration: 'none',
    color: 'inherit',
  },
  optionField: {
    display: 'flex',
    flex: 2,
    overflow: 'hidden',
    marginRight: theme.spacing(0.4),
    fontSize: theme.spacing(1.625),
    '&:last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(1.5),
    },
  },
  optionFieldSecondary: {
    flex: 1,
    color: theme.palette.grey[800],
  },
  optionTextWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  selectWrapper: {
    margin: theme.spacing(0.5, 0),
    width: '130px',
    '& #mui-component-select-SearchType': {
      padding: theme.spacing(0, 2.5, 0, 1),
      fontSize: 'initial',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '2px',
      textAlign: 'center',
    },
  },
  searchTextFieldInput: {
    padding: theme.spacing(1, 0, 1),
    '&::placeholder': {
      color: theme.palette.grey[800],
    },
  },
  dropdownList: {
    padding: theme.spacing(0),
  },
  dropdownItem: {
    padding: theme.spacing(0, 1, 0),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  wrapper: {
    marginRight: theme.spacing(4),
  },
}));

export default useStyles;
