import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';

import { parseDurationValue } from 'utils/numbers';

import useStyles from './AudioPlayer.styles';

interface IAudioPlayerProps {
  src: string;
  fullLength?: boolean;
  fullWidthAudio?: boolean;
  setFieldValue?: (field: string, value: any) => void;
}

const AudioPlayer = (props: IAudioPlayerProps) => {
  const classes = useStyles();
  const {
    src,
    fullLength = false,
    fullWidthAudio = false,
    setFieldValue,
  } = props;
  const [audioSrc, setAudioSrc] = useState(src);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    setAudioSrc(src);
    // eslint-disable-next-line no-unused-expressions
    audioRef?.current?.load();
  }, [src]);

  return (
    /* eslint-disable jsx-a11y/media-has-caption */
    <audio
      ref={audioRef}
      onLoadedMetadata={(event) => {
        setFieldValue &&
          setFieldValue(
            'duration',
            parseDurationValue(event.currentTarget.duration).toString()
          );
      }}
      controls
      controlsList="nodownload"
      className={classNames([
        classes.audioPlayer,
        {
          [`${classes.audioPlayer}--fullLength`]: fullLength,
          [`${classes.audioPlayer}--fullWidth`]: fullWidthAudio,
        },
      ])}
      data-tc="audioPlayer"
    >
      <source src={audioSrc} data-tc="audioPlayerSource" />
      Your browser does not support the audio format.
    </audio>
  );
};

export default AudioPlayer;
