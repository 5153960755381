import ChipTooltip from 'features/targetingV2/components/ChipTooltip/ChipTooltip';
import Breadcrumbs from 'features/targetingV2/components/core/Breadcrumbs/Breadcrumbs';
import Chip from 'features/targetingV2/components/core/Chip/Chip';
import { ChipState } from 'features/targetingV2/types/common';
import React from 'react';

import ClearIcon from '@material-ui/icons/Clear';

import useStyles from './StyledChip.styles';
import { IChipProps } from './StyledChip.types';

const StyledChip = ({
  breadcrumbsList = [],
  state = ChipState.DEFAULT,
  removeActions,
  tooltip,
  breadcrumbIdentifier = 'chipBreadcrumbs',
  label,
  readOnly = false,
  ...otherProps
}: IChipProps) => {
  const classes = useStyles({ state });

  return (
    <Chip
      size="small"
      tabIndex={-1}
      classes={{
        label: classes.label,
        root: classes.root,
      }}
      label={label}
      onDelete={readOnly ? undefined : removeActions?.onClick}
      deleteIcon={
        <ClearIcon
          className={classes.clearIndicator}
          {...removeActions}
          data-testid="deleteChipIcon"
        />
      }
      disabled={readOnly}
      icon={
        <>
          {!!tooltip && <ChipTooltip state={state} tooltip={tooltip} />}
          {!!breadcrumbsList.length && (
            <Breadcrumbs
              nodes={breadcrumbsList}
              identifier={breadcrumbIdentifier}
              classes={{
                container: classes.breadcrumbsContainer,
              }}
            />
          )}
        </>
      }
      data-testid={`${label}-chip`}
      {...otherProps}
    />
  );
};

export default StyledChip;
