import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import { INetworkValues } from 'features/inventory/network/components/NetworkTabsForm/NetworkFormValues';
import { getTerritoryValues } from 'utils/territory';
import NetworkTabsForm, {
  networkButtons,
} from 'features/inventory/network/components/NetworkTabsForm/NetworkTabsForm';
import NetworkFormValidation from 'features/inventory/network/validations/networks';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  EntityType,
  Network,
  Publisher,
  UpdateNetworkMutationFn,
  useUpdateNetworkMutation,
} from 'interfaces/generated.types';

import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface IUpdateNetworkContainerProps {
  history: History;
  match: {
    params: {
      networkId: string;
    };
  };
  network: Network;
  hasEditPermissions: boolean;
  publishers?: Publisher[];
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });

export const submitUpdateNetwork = (
  updateNetwork: UpdateNetworkMutationFn,
  initialValues: INetworkValues,
  id: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: INetworkValues,
  { setStatus, setSubmitting }: FormikActions<INetworkValues>
) => {
  setSelectedJourney(formValues.journey);

  updateNetwork({
    variables: {
      id,
      ...differenceInValues(
        { ...formValues, journey: null },
        { ...initialValues, journey: null }
      ),
    },
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateNetworkContainer = ({
  history,
  match,
  network,
  hasEditPermissions,
  publishers,
}: IUpdateNetworkContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Network.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateNetwork] = useUpdateNetworkMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, network.id);
    },
  });
  const validateNetwork = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    name: network.name as string,
    id: network.id,
    altId: network.altId,
    journey: networkButtons[0].url,
    status: network.status,
    territories: getTerritoryValues(network.territories),
  };

  return (
    <>
      <Formik<INetworkValues>
        initialValues={initialValues}
        validationSchema={NetworkFormValidation}
        onSubmit={submitUpdateNetwork(
          updateNetwork,
          initialValues,
          match.params.networkId,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <NetworkTabsForm
            {...formikProps}
            update
            hasEditPermissions={hasEditPermissions}
            history={history}
            match={match}
            publishers={publishers}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateNetwork,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Network,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateNetwork,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateNetwork"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateNetworkWarningDialog"
      />
    </>
  );
};

export default UpdateNetworkContainer;
