import { ReactComponent as PopupIcon } from 'assets/icons/dax-popupicon.svg';
import { History } from 'history';
import React, { useState } from 'react';

import { Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import CampaignModalTabs from '../CampaignModalTabs/CampaignModalTabs';
import useStyles from './CampaignModal.styles';

interface ICampaignModalProps {
  id: string;
  history: History;
}

const CampaignModal = ({ id, history }: ICampaignModalProps) => {
  const classes = useStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={classes.modalButton}>
        <Tooltip title="More details">
          <span>
            <IconButton
              onClick={() => setModalOpen(!isModalOpen)}
              data-testid={`campaignModalButton-${id}`}
            >
              <PopupIcon />
              <span className="sr-only">More details</span>
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {isModalOpen && (
        <Dialog
          data-testid="campaignModal"
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="xl"
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle classes={{ root: classes.title }} disableTypography>
            <Typography display="inline">View Campaign</Typography>
            <IconButton
              onClick={() => setModalOpen(false)}
              classes={{ root: classes.closeButton }}
              data-testid="campaignModalClose"
            >
              <CloseIcon />
              <span className="sr-only">Close the campaign</span>
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{ root: classes.content }}>
            <CampaignModalTabs id={id} history={history} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CampaignModal;
