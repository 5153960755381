import * as Yup from 'yup';

import commons from 'validations/commons';
import { TargetingPackTypeEnum } from '../components/TargetingPackForm/TargetingPackFormValues';

const TargetingPackFormValidation = Yup.object().shape({
  name: commons.name('Pack Name'),
  description: Yup.string().trim().max(1024, 'Character limit reached'),
  type: Yup.string(),
  postcodes: Yup.array()
    .of(Yup.string())
    .when('type', {
      is: (type: string) => type === TargetingPackTypeEnum.POSTCODE,
      then: Yup.array()
        .of(Yup.string())
        .min(1, 'Please add some postcodes before saving the targeting pack')
        .nullable(),
    }),
  countryParams: Yup.object().when('type', {
    is: (type: string) => type === TargetingPackTypeEnum.REGION,
    then: Yup.object().shape({
      countries: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            readonly: Yup.boolean().notRequired(),
          })
        )
        .min(1, 'Please select at least one country'),
      regions: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            countryCode: Yup.string(),
            readonly: Yup.boolean().notRequired(),
          })
        )
        .min(1, 'Please select at least one region'),
      subRegions: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
            countryCode: Yup.string(),
            readonly: Yup.boolean().notRequired(),
          })
        )
        .min(1, 'Please select at least one sub region'),
    }),
  }),
});

export default TargetingPackFormValidation;
