import { CreativeStatus } from 'interfaces/generated.types';

export const CreativeStatusEnum = {
  ...CreativeStatus,
  Uploading: 'Uploading',
  HasCreative: 'HasCreative',
  NoCreative: 'NoCreative',
  NewCreativeUploaded: 'NewCreativeUploaded',
} as const;

// eslint-disable-next-line no-redeclare
export type CreativeStatusEnum = typeof CreativeStatusEnum[keyof typeof CreativeStatusEnum];

export const allCreativeStatuses = [
  CreativeStatusEnum.Ready,
  CreativeStatusEnum.Disabled,
] as CreativeStatusEnum[];

export const creativeStatusValues = [
  {
    value: CreativeStatusEnum.Ready,
    label: 'Ready',
  },
  {
    value: CreativeStatusEnum.Disabled,
    label: 'Disabled',
  },
];
