import numbro from 'numbro';
import { numbroNumberFormatting } from './numbers';

const formatImpressionsToAddCommaSeparator = (actualImpressions: any) => {
  if (
    actualImpressions === null ||
    actualImpressions === undefined ||
    actualImpressions === ''
  ) {
    return 'N/A';
  }
  return numbro(actualImpressions).format(numbroNumberFormatting);
};

export default formatImpressionsToAddCommaSeparator;
