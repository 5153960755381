import StyledButton, {
  ButtonVariantEnum,
} from 'features/targetingV2/components/core/StyledButton/StyledButton';
import React, { ReactNode, useEffect } from 'react';

const ValueContainer = ({
  selectProps,
  children,
}: {
  children: ReactNode;
  selectProps: any;
}) => {
  const {
    setShowHiddenMultiValues,
    classes,
    showHiddenMultiValues,
    value,
    noOfMultiValuesToShow,
  } = selectProps;

  const handleClick = () => {
    setShowHiddenMultiValues((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    if (value.length <= noOfMultiValuesToShow) {
      setShowHiddenMultiValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const showToggleMultiValuesButton =
    noOfMultiValuesToShow !== undefined && value.length > noOfMultiValuesToShow;

  return (
    <div className={classes.valueContainer}>
      {children}
      {showToggleMultiValuesButton && (
        <StyledButton
          variant={ButtonVariantEnum.Text}
          onClick={() => handleClick()}
          externalWrapper={
            showHiddenMultiValues ? classes.toggleMultiValuesBtnWrapper : ''
          }
          className={classes.toggleMultiValuesBtnText}
        >
          {`Show ${showHiddenMultiValues ? 'Less' : `All (${value.length})`}`}
        </StyledButton>
      )}
    </div>
  );
};

export default ValueContainer;
