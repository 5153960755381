import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  CircularProgress as MUICircularProgress,
  CircularProgressProps as MUICircularProgressProps,
} from '@material-ui/core';

import useStyles from './CircularProgress.styles';

export type CircularProgressProps = MUICircularProgressProps;

const CircularProgress = React.forwardRef(
  (props: CircularProgressProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUICircularProgress ref={ref} classes={classes} {...otherProps} />;
  }
);

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
