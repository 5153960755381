import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UpdateOrderContainer from 'features/direct/order/containers/UpdateOrderContainer/UpdateOrderContainer';
import { History } from 'history';
import {
  Order,
  PermissionName,
  useAllUsersQuery,
  useOrderQuery,
  User,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { allTerritories } from 'utils/territory';

interface UpdateOrderPageProps {
  history: History;
  match: {
    params: {
      orderId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const UpdateOrderPage = ({
  history,
  match,
  userPermissions,
}: UpdateOrderPageProps) => {
  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );
  const { loading, data } = useOrderQuery({
    errorPolicy: 'all',
    variables: { id: match.params.orderId },
  });
  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Direct, PermissionName.Primary],
      territories: allTerritories,
    },
    skip: !hasEditPermissions,
  });

  if (!loading && !data?.order) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Order</h1>
        {loading || loadingAllUsers ? (
          <Loader />
        ) : (
          <UpdateOrderContainer
            order={data?.order as Order}
            history={history}
            match={match}
            hasEditPermissions={hasEditPermissions}
            allUsers={(allUsersData?.allUsers as User[]) || []}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateOrderPage;
