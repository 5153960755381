import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    minWidth: '300px',
  },
  input: {
    borderRadius: '0px 4px 4px 0px',
    '&--error': {
      borderColor: colors.errorText,
    },
  },
  messagesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  optionLabelText: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export default useStyles;
