import { create } from 'jss';
import jssCompose from 'jss-plugin-compose';
import jssExtend from 'jss-plugin-extend';
import jssGlobal from 'jss-plugin-global';

import { jssPreset } from '@material-ui/core/styles';

const plugins = create({
  plugins: [jssExtend(), jssCompose(), jssGlobal(), ...jssPreset().plugins],
});

export default plugins;
