import { format, isValid, setMilliseconds, setSeconds } from 'date-fns';
import targetingV2 from 'features/targetingV2/validations/targetingV2';
import { TargetingVersion, Territory } from 'interfaces/generated.types';
import dateUtils from 'utils/date';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';
import { parseFormattedValue } from 'utils/numbers';
import commons from 'validations/commons';
import * as Yup from 'yup';

const ForecastingValidation = (territory: Territory | undefined) =>
  Yup.object().shape(
    {
      startDate: Yup.date()
        .required('Start Date is a required field')
        .nullable()
        .default(null)
        .typeError('Start Date should be a valid date')
        .test(
          'startDate',
          'Start Date should not be in the past',
          function startDateValidation(startDate: Date | null) {
            if (!startDate || !isValid(startDate)) {
              return true;
            }
            const { timeZone } = this.parent;
            const currentDate = setSeconds(setMilliseconds(new Date(), 0), 0);

            return dateUtils.isDateEqualOrAfterTheOther({
              date: startDate,
              dateToCompare: currentDate,
              timeZone,
            });
          }
        ),
      endDate: Yup.date()
        .required('End Date is a required field')
        .nullable()
        .default(null)
        .typeError('End Date should be a valid date')
        .when('startDate', (startDate: Date | null, schema: Yup.DateSchema) => {
          if (!isValid(startDate)) {
            return schema;
          }
          return schema.test(
            'startDate',
            `End Date should be after ${format(
              startDate as Date,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )}`,
            function endDateValidation(endDate: Date) {
              if (!isValid(endDate)) {
                return true;
              }
              const { timeZone } = this.parent;
              return dateUtils.isDateAfterTheOther({
                date: endDate,
                dateToCompare: startDate as Date,
                timeZone,
              });
            }
          );
        })
        .test(
          'endDate',
          'End Date should not be in the past',
          function endDateInThePastValidation(endDate: Date | null) {
            if (!endDate || !isValid(endDate)) {
              return true;
            }
            const { timeZone } = this.parent;
            return dateUtils.isDateInTheFuture(endDate, timeZone);
          }
        ),
      objective: Yup.string()
        .test(
          'objective',
          'Objective should be higher than or equal to 1',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue >= 1;
          }
        )
        .test(
          'objective',
          'Objective should be lower than or equal to 1,000,000,000',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue <= 1000000000;
          }
        ),
      duration: commons.requiredPrice({
        messages: {
          minMessage: 'Minimum duration is 1 second',
          maxMessage: 'Maximum duration is 300 seconds',
          required: 'Duration is a required field',
        },
        minimum: 1,
        maximum: 300,
        fieldKey: 'duration',
      }),
      targetingMasterTemplates: Yup.array()
        .of(
          Yup.object().shape({
            groups: Yup.array().of(
              Yup.object().shape(
                {
                  audienceParams: commons.audienceParams('Listener parameters'),
                  channels: Yup.array()
                    .of(Yup.string())
                    .when(['audienceParams', 'targetingVersion'], {
                      is: (
                        audienceParams: any,
                        targetingVersion: TargetingVersion
                      ) =>
                        targetingVersion === TargetingVersion.TargetingV1 &&
                        audienceParams?.length > 0,
                      then: Yup.array().required(
                        "It looks like your targeting group does not have channels assigned. Use the 'Channels' button to assign a channel."
                      ),
                    })
                    .nullable(),
                },
                ['audienceParams', 'channels'] as any
              )
            ),
          })
        )
        .when(['targetingGroups', 'targetingVersion'], {
          is: (targetingGroups: any, targetingVersion: TargetingVersion) =>
            targetingVersion === TargetingVersion.TargetingV1 &&
            (!targetingGroups ||
              targetingGroups?.length === 0 ||
              (targetingGroups?.length > 0 &&
                targetingGroups.every((tg: any) => tg.channels?.length === 0))),
          then: Yup.array().required(
            'A targeting template or a targeting group with channels assigned to it need to be added in order for the forecast to be generated.'
          ),
        }),
      targetingGroups: Yup.array()
        .of(
          Yup.object().shape(
            {
              audienceParams: commons.audienceParams('Listener parameters'),
              channels: Yup.array()
                .of(Yup.string())
                .when(['audienceParams', 'targetingVersion'], {
                  is: (
                    audienceParams: any,
                    targetingVersion: TargetingVersion
                  ) =>
                    targetingVersion === TargetingVersion.TargetingV1 &&
                    audienceParams?.length > 0,
                  then: Yup.array().required(
                    "It looks like your targeting group does not have channels assigned. Use the 'Channels' button to assign a channel."
                  ),
                })
                .nullable(),
            },
            ['audienceParams', 'channels'] as any
          )
        )
        .when(['targetingMasterTemplates', 'targetingVersion'], {
          is: (
            targetingMasterTemplates: any,
            targetingVersion: TargetingVersion
          ) =>
            targetingVersion === TargetingVersion.TargetingV1 &&
            (!targetingMasterTemplates ||
              targetingMasterTemplates?.length === 0),
          then: Yup.array().required(
            'A targeting template or a targeting group with channels assigned to it need to be added in order for the forecast to be generated.'
          ),
        }),
      targetingDefinitionV2: Yup.object().shape({
        customTargeting: targetingV2.customTargeting,
      }),
    },
    [
      ['startDate', 'endDate'],
      ['timeZone', 'startDate'],
      ['timeZone', 'endDate'],
      ['targetingMasterTemplates', 'targetingGroups'],
    ]
  );

export default ForecastingValidation;
