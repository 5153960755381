import React from 'react';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';

import { IFormProps } from 'interfaces';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';
import { targetingConnectionTypeValues } from '../TargetingParameters/TargetingParametersValues';

export const connectionTypeValues = {
  connectionTypeParams: {
    connectionTypes: [],
  },
};

const TargetingConnectionType = (
  props: IFormProps<any> & ITargetingComponentProps
) => (
  <TargetingMultiSelect
    {...props}
    label="Connection Type"
    targetingName="connectionTypeParams.connectionTypes"
    options={targetingConnectionTypeValues}
  />
);

export default TargetingConnectionType;
