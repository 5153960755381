import pageStyles from 'assets/styles/components/Page.styles';
import { CloneTypeEnum } from 'components/CloneModal/CloneModalValues';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableCloneCell from 'components/Table/TableCloneCell';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { dealStatusValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Deal,
  DealStatus,
  EntityType,
  Territory,
  useCloneDealMutation,
  useUpdateDealMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue, removeEmailDomain } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import { numbroFullDecimalFormatting } from 'utils/numbers';
import {
  handleCellClone,
  handleCellUpdate,
  handleCellValidate,
} from 'utils/tables';

interface IDealsTableProps {
  match?: {
    params: {
      salesChannelId: string;
    };
  };
  history: History;
  deals: Deal[];
  isEditable?: boolean;
  mutationOptions?: any;
}

interface IDealsRow {
  id: string;
  altId: number;
  name: string;
  owner: string;
  startDate: string | null;
  endDate: string | null;
  floorCpm: string;
  externalId: string;
  priority: number;
  status: DealStatus;
  trusted: string;
}

export const formatDealsData = memoizeOne(
  (data: Deal[], territory: Territory | undefined) =>
    data.map((d) => ({
      id: d.id,
      altId: d.altId,
      name: d.name,
      owner: d.owner ? removeEmailDomain(d.owner?.email || '') : '',
      startDate: d.startDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.startDate, d.timeZone),
            territory
          )
        : null,
      endDate: d.endDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.endDate, d.timeZone),
            territory
          )
        : null,
      floorCpm: d.floorCpm
        ? numbro(d.floorCpm).format(numbroFullDecimalFormatting)
        : '',
      externalId: d.externalId || '',
      priority: d.priority,
      status: d.status,
      trusted: d.trusted ? 'Trusted' : 'Quarantined',
    }))
);

const DealsTable = (props: IDealsTableProps) => {
  const { match, history, deals, isEditable = true, mutationOptions } = props;
  const classes = pageStyles();

  const [updateDeal] = useUpdateDealMutation(mutationOptions);

  const [cloneDeal] = useCloneDealMutation(mutationOptions);

  const validateDeal = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const createDealCta = isEditable && match?.params?.salesChannelId && (
    <StyledLink
      location={{
        pathname: `/sales-channel/${match.params.salesChannelId}/deal`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="createDealButton"
    >
      Create New Deal
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
          minWidth: '100px',
        },
        Cell: ({ cell: { value, row } }: CellProps<IDealsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/deal/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Creative Status',
        accessor: 'trusted',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
      },
      {
        Header: 'Floor Price',
        accessor: 'floorCpm',
      },
      {
        Header: 'External Deal Id',
        accessor: 'externalId',
        style: {
          wordBreak: 'break-word',
          minWidth: '100px',
        },
      },
      {
        Header: 'Priority',
        accessor: 'priority',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IDealsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: dealStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(celValue: any, onChange: any) => (
              <TableSelect
                value={celValue}
                onChange={onChange}
                options={dealStatusValues}
                name="dealStatusSelect"
                dataTc="dealStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, name },
            },
          },
          onCellClone,
          setErrorModal,
        }: CellProps<IDealsRow>) => (
          <TableCloneCell
            onCellClone={onCellClone}
            setErrorModal={setErrorModal}
            entity={{ id, name }}
            isEditable={isEditable}
            type={CloneTypeEnum.DEAL}
            dataTc="cloneDeal"
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Deals"
        columns={columns}
        data={formatDealsData(deals, activeTerritory)}
        isEditable={isEditable}
        dataTc={`${match?.params?.salesChannelId || ''}listDealsTable`}
        isPageTable={false}
        onCellClone={({
          entity,
          cloneChildren,
          hasChildren,
          isEdit,
          setErrorModal,
        }) =>
          handleCellClone({
            variables: {
              id: entity.id,
              disableCascade: cloneChildren ? !hasChildren : undefined,
            },
            clone: cloneDeal,
            handleSuccess: (data: any) =>
              isEdit && history.push(`/deal/${data.cloneDeal.id}`),
            handleContinue: () => history.push(`/deal/${entity.id}`),
            setErrorModal,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Deal',
            },
          })
        }
        onCellUpdate={(row: IDealsRow, setErrorModal, setUpdating) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateDeal,
            handleContinue: () => history.push(`/deal/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Deal',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateDeal,
            entity: { ...entity, type: EntityType.Deal },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createDealCta}
      />
    </div>
  );
};

export default DealsTable;
