import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import DatacenterDetails from 'features/programmatic/datacenter/components/DatacenterDetails/DatacenterDetails';
import {
  datacenterDetailsFields,
  dataCenterStatusValues,
  getDatacenterStatusData,
  IDatacenterValues,
} from 'features/programmatic/datacenter/components/DatacenterTabsForm/DatacenterFormValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  EntityType,
  TechnicalProviderStatus,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

interface IDatacenterProps {
  goBackTo: ILocation;
  hasEditPermissions?: boolean;
  update?: boolean;
  technicalProviderStatus?: TechnicalProviderStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
}

export const datacenterButtons = [
  {
    text: 'Save and Review',
    url: '/datacenter/ID',
    update: true,
    dataTc: 'datacenterReviewButton',
  },
  {
    text: 'Save and View All Datacenters',
    url: '/programmatic-connector/PARENT_ID',
    update: false,
    dataTc: 'datacenterListButton',
  },
];

const getDatacenterDetailsTabErrors = createNumberOfFormErrorsFn();

const DatacenterTabsForm = (
  props: IFormProps<IDatacenterValues> & IDatacenterProps
) => {
  const {
    setFieldValue,
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    goBackTo,
    update = false,
    hasEditPermissions = true,
    status,
    technicalProviderStatus,
    onFieldUpdate,
    onFieldValidate,
    history,
  } = props;

  const classes = tabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isDatacenterTab = selectedTab === 0;
  const isDatacenterActivityTab = selectedTab === 1;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && technicalProviderStatus && (
          <>
            <EntityProgressBar
              statusData={getDatacenterStatusData(
                values,
                technicalProviderStatus
              )}
              currentStatus={values.status || ''}
              dataTc="datacenterStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={dataCenterStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="datacenterHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="datacenterTabs"
          >
            <Tab
              dataTc="datacenterDetailsTab"
              numberOfTabErrors={getDatacenterDetailsTabErrors(
                status,
                datacenterDetailsFields
              )}
              label="Details"
            />
            <Tab
              disabled={!update}
              dataTc="datacenterActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]: isDatacenterActivityTab,
            },
          ])}
        >
          {isDatacenterTab && <DatacenterDetails {...props} />}
          {isDatacenterActivityTab && (
            <ActivityTable
              entityType={EntityType.Datacenter}
              id={values.id}
              data-tc="datacenterActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitDatacenterButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={datacenterButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default DatacenterTabsForm;
