import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import SalesChannelTabsForm, {
  salesChannelButtons,
} from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelTabsForm';
import { ISalesChannelValues } from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelValues';
import SalesChannelFormValidation from 'features/programmatic/salesChannel/validations/salesChannels';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  Agency,
  CreateSalesChannelMutation,
  CreateSalesChannelMutationFn,
  SalesChannel,
  useCreateSalesChannelMutation,
  User,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import {
  createSelectOptions,
  createUserOption,
  transformSelectOption,
} from 'utils/dataTransformation';
import { handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface ICreateSalesChannelContainerProps {
  history: History;
  allAgencies: Agency[];
  allUsers: User[];
  currentUser: User;
}

export const handleOnComplete = (
  response: CreateSalesChannelMutation,
  history: History,
  selectedJourney: string
) => {
  const { createSalesChannel } = response;
  const { id } = createSalesChannel as SalesChannel;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitCreateSalesChannel = (
  createSalesChannel: CreateSalesChannelMutationFn,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ISalesChannelValues,
  { setStatus, setSubmitting }: FormikActions<ISalesChannelValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = pickBy({
    ...formValues,
    journey: null,
    agency: null,
    allAgencies: null,
    agencyId: transformSelectOption(formValues.agency),
    ownerId: formValues.owner ? formValues.owner.value : null,
    owner: null,
  });
  createSalesChannel({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateSalesChannelContainer = ({
  history,
  allAgencies,
  allUsers,
  currentUser,
}: ICreateSalesChannelContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Saleschannel.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createSalesChannel] = useCreateSalesChannelMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney);
    },
  });

  return (
    <>
      <Formik<ISalesChannelValues>
        initialValues={{
          name: '',
          id: '',
          agency: null,
          allAgencies: createSelectOptions(allAgencies),
          journey: salesChannelButtons[0].url,
          owner: createUserOption(currentUser),
          territory: activeTerritory,
        }}
        validationSchema={SalesChannelFormValidation}
        onSubmit={submitCreateSalesChannel(
          createSalesChannel,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(props) => (
          <SalesChannelTabsForm
            {...props}
            allUsers={allUsers}
            history={history}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createSalesChannel"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateSalesChannelContainer;
