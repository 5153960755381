import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import HealthDashboard from 'components/HealthDashboard/HealthDashboard';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import subDays from 'date-fns/subDays';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import ProgrammaticCreativesTable from 'features/programmatic/creative/components/ProgrammaticCreativesTable/ProgrammaticCreativesTable';
import DealsTable from 'features/programmatic/deal/components/DealsTable/DealsTable';
import SalesChannelDetails from 'features/programmatic/salesChannel/components/SalesChannelDetails/SalesChannelDetails';
import {
  getSalesChannelStatusData,
  ISalesChannelValues,
  salesChannelDetailsFields,
  salesChannelStatusValues,
} from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFieldUpdate, IFieldValidate, IFormProps } from 'interfaces';
import {
  AgencyStatus,
  Deal,
  EntityType,
  HealthMetric,
  SalesChannelStatus,
  User,
  useSalesChannelHealthQuery,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import { GET_SALES_CHANNEL } from '../../graphql/queries';

export interface ISalesChannelProps {
  update?: boolean;
  allUsers: User[];
  hasEditPermissions?: boolean;
  match?: {
    params: {
      salesChannelId: string;
    };
  };
  history: History;
  deals?: Deal[];
  health?: HealthMetric[];
  agencyStatus?: AgencyStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
}

export const salesChannelButtons = [
  {
    text: 'Save and Review',
    url: '/sales-channel/ID',
    update: true,
    dataTc: 'salesChannelReviewButton',
  },
  {
    text: 'Save and Create Deal',
    url: '/sales-channel/ID/deal',
    update: false,
    dataTc: 'salesChannelCreateDealButton',
  },
  {
    text: 'Save and View All Sales Channels',
    url: '/sales-channels',
    update: false,
    dataTc: 'salesChannelListButton',
  },
];

const getSalesChannelDetailsTabErrors = createNumberOfFormErrorsFn();

export const getDefaultTab = (salesChannelStatus?: string, location?: any) => {
  if (location && location.state) {
    const { entityList, tab } = location.state;
    if (entityList === 'listDeals') return 2;
    if (tab) return tab;
  }
  return salesChannelStatus && salesChannelStatus === SalesChannelStatus.Active
    ? 0
    : 1;
};

const SalesChannelTabsForm = (
  props: IFormProps<ISalesChannelValues> & ISalesChannelProps
) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    update = false,
    hasEditPermissions = true,
    values,
    history,
    match,
    deals,
    health,
    status,
    agencyStatus,
    onFieldUpdate,
    onFieldValidate,
  } = props;

  const classes = tabStyles();

  const location = usePreviousLocation();

  const dealMutationOptions = match?.params.salesChannelId
    ? {
        refetchQueries: [
          {
            query: GET_SALES_CHANNEL,
            variables: { id: match.params.salesChannelId },
          },
        ],
      }
    : undefined;

  const [selectedTab, setTab] = useState(
    getDefaultTab(values.status, location)
  );
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);
  const initialStartDate = subDays(new Date(), 30);
  const initialEndDate = new Date();

  const isSalesChannelHealthTab = selectedTab === 0;
  const isSalesChannelTab = selectedTab === 1;
  const isListDealsTab = selectedTab === 2;
  const isListCreativesTab = selectedTab === 3;
  const isSalesChannelActivityTab = selectedTab === 4;

  // if a sales channel has no deals, we need to filter all creatives, so we use an empty id
  const dealIds = deals && deals.length > 0 ? deals.map((d) => d.id) : [''];
  const dealIdsFilter = {
    id: 'deal.ids',
    value: dealIds,
  };

  return (
    <>
      <Form className={classes.form} role="form">
        {update && agencyStatus && (
          <>
            <EntityProgressBar
              statusData={getSalesChannelStatusData(values, agencyStatus)}
              currentStatus={values.status || ''}
              dataTc="salesChannelStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={salesChannelStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="salesChannelHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="salesChannelTabs"
          >
            <Tab
              disabled={values.status !== SalesChannelStatus.Active}
              dataTc="salesChannelHealthTab"
              label="Health"
            />
            <Tab
              dataTc="salesChannelDetailsTab"
              numberOfTabErrors={getSalesChannelDetailsTabErrors(
                status,
                salesChannelDetailsFields
              )}
              label="Details"
            />
            <Tab disabled={!update} dataTc="listDealsTab" label="Deals" />
            <Tab
              disabled={!update}
              dataTc="listCreativesTab"
              label="Creatives"
            />
            <Tab
              disabled={!update}
              dataTc="salesChannelActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListCreativesTab ||
                isListDealsTab ||
                isSalesChannelActivityTab,
            },
          ])}
        >
          {isSalesChannelHealthTab && health && (
            <HealthDashboard
              id={values.id}
              useHealthQuery={useSalesChannelHealthQuery}
              selectedDateRange={{
                startDate: initialStartDate,
                endDate: initialEndDate,
              }}
              entityType="salesChannel"
            />
          )}
          {isSalesChannelTab && <SalesChannelDetails {...props} />}
          {isListDealsTab && match && history && deals && (
            <DealsTable
              match={match}
              history={history}
              deals={deals}
              isEditable={hasEditPermissions}
              mutationOptions={dealMutationOptions}
            />
          )}
          {isListCreativesTab && (
            <ProgrammaticCreativesTable
              isEditable={hasEditPermissions}
              isPageTable={false}
              history={history}
              constantFilter={dealIdsFilter}
            />
          )}
          {isSalesChannelActivityTab && (
            <ActivityTable
              entityType={EntityType.Saleschannel}
              id={values.id}
              data-tc="salesChannelActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitSalesChannelButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/sales-channels',
            state: location.state?.parent || {},
          }}
          variant={FormButtonVariants.split}
          buttons={salesChannelButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default SalesChannelTabsForm;
