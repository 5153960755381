import React from 'react';

import {
  FeatureToggle,
  Permission,
  useGetCurrentUserQuery,
} from 'interfaces/generated.types';

export interface FeatureToggles {
  featureToggles?: { [key: string]: boolean };
}

const getFeatures = (permission: Permission) =>
  permission.featureToggles.reduce(
    (allToggles: { [key: string]: boolean }, feature: FeatureToggle) => ({
      ...allToggles,
      [feature.name as string]: feature.toggled as boolean,
    }),
    {}
  );

const withFeatureToggles = (Component: React.ElementType<any>) =>
  function FeatureToggledComponent(props: any) {
    const { data } = useGetCurrentUserQuery({
      fetchPolicy: 'cache-first',
    });

    const allPermissions = data?.currentUser?.permissions || [];

    if (!allPermissions.length) return <Component {...props} />;

    const featureToggles = (allPermissions as Permission[]).reduce(
      (result: { [key: string]: boolean }, permission: Permission) => {
        const features = getFeatures(permission);

        const existingToggles = Object.keys(result).filter(
          (key) => result[key]
        );

        existingToggles.forEach((toggle) => {
          delete features[toggle];
        });

        return {
          ...result,
          ...features,
        };
      },
      {}
    );

    return <Component {...props} featureToggles={featureToggles} />;
  };

export default withFeatureToggles;
