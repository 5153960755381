import { ReactComponent as InfoIcon } from 'assets/icons/TargetingV2/dax-info.svg';
import Tooltip from 'features/targetingV2/components/core/Tooltip/Tooltip';
import Typography from 'features/targetingV2/components/core/Typography';
import TooltipContent from 'features/targetingV2/components/TooltipContent/TooltipContent';
import { getTooltipContentBasedOnRestriction } from 'features/targetingV2/utils/tooltip';
import { TargetingRestriction } from 'interfaces/generated.types';
import React from 'react';
import { mapRestrictionValueToLabel } from 'utils/targetingRestrictions';

import useStyles from './TargetingRestrictionsInfo.styles';

interface ITargetingRestrictionsInfoProps {
  restrictionsList: TargetingRestriction[];
}

const TargetingRestrictionsInfo = ({
  restrictionsList,
}: ITargetingRestrictionsInfoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.restrictionsContainer}>
      <Typography variant="body2">Restriction:</Typography>

      {restrictionsList.map((restriction, index, collection) => {
        const isLastRestriction = index === collection.length - 1;

        return (
          <div key={restriction} className={classes.restriction}>
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
              }}
              title={
                <TooltipContent
                  tooltipContent={getTooltipContentBasedOnRestriction(
                    restriction
                  )}
                  data-testid={`tooltip-content-${restriction}`}
                />
              }
              placement="bottom"
              interactive
            >
              <InfoIcon className={classes.tooltipIcon} />
            </Tooltip>
            <Typography variant="body2" className={classes.restrictionLabel}>
              {`${mapRestrictionValueToLabel[restriction]}${
                isLastRestriction ? '' : ','
              }`}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TargetingRestrictionsInfo;
