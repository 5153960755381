import AsyncDropdownSelector from 'features/targetingV2/components/ParameterValuesComponents/AsyncDropdownSelector/AsyncDropdownSelector';
import BulkUploadSelector from 'features/targetingV2/components/ParameterValuesComponents/BulkUploadSelector/BulkUploadSelector';
import DropdownSelector from 'features/targetingV2/components/ParameterValuesComponents/DropdownSelector/DropdownSelector';
import { SectionType } from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import {
  LocationParameterType,
  TechnologyParameterType,
  Territory,
} from 'interfaces/generated.types';
import React, { MutableRefObject } from 'react';

export interface IValuesComponentProps {
  sectionType: SectionType;
  selectedParameter: IParameter;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  externalUploadInputRef: MutableRefObject<HTMLInputElement | null>;
  setFieldTouched?: (field: string, value: boolean) => void;
  selectedParameterLabel?: string;
  placeholder?: string;
  classes?: { input?: string };
  onClick?: () => void;
  idUsedToLimitParameterValues?: string;
  territory?: Territory;
}

export const getParameterValuesComponent = (
  parameterType: string,
  providedProps: IValuesComponentProps
) => {
  const commonProps = {
    selectedParameter: providedProps.selectedParameter,
    fieldName: providedProps.fieldName,
    setFieldValue: providedProps.setFieldValue,
    externalUploadInputRef: providedProps.externalUploadInputRef,
  };

  switch (parameterType) {
    case LocationParameterType.LatLong:
      return (
        <BulkUploadSelector {...commonProps} onClick={providedProps.onClick} />
      );

    case LocationParameterType.Zipcode:
    case TechnologyParameterType.Isp:
      return (
        <AsyncDropdownSelector
          {...commonProps}
          sectionType={providedProps.sectionType}
          setFieldTouched={providedProps.setFieldTouched}
          classes={providedProps.classes}
          idUsedToLimitParameterValues={
            providedProps.idUsedToLimitParameterValues
          }
        />
      );

    default:
      return (
        <DropdownSelector
          {...commonProps}
          sectionType={providedProps.sectionType}
          selectedParameterLabel={providedProps.selectedParameterLabel}
          placeholder={providedProps.placeholder}
          classes={providedProps.classes}
          idUsedToLimitParameterValues={
            providedProps.idUsedToLimitParameterValues
          }
          territory={providedProps.territory}
        />
      );
  }
};
