import classNames from 'classnames';
import Typography from 'features/targetingV2/components/core/Typography';
import React from 'react';

import useStyles from './Breadcrumbs.styles';

const BreadcrumbText = ({
  text,
  classes: externalClasses = {},
}: {
  text: string;
  classes?: {
    text?: string;
  };
}) => {
  const classes = useStyles({});

  return (
    <Typography
      variant="body1"
      className={classNames([classes.text, externalClasses.text])}
    >
      {text}
    </Typography>
  );
};

export default BreadcrumbText;
