import classNames from 'classnames';
import SplitButton from 'components/SplitButton/SplitButton';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import React, { useState } from 'react';
import { findLabelValue } from 'utils/dataTransformation';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './CloneModal.styles';
import {
  cloneChildrenText,
  CloneTypeEnum,
  cloneTypeValues,
} from './CloneModalValues';

interface ICloneModalProps {
  entity: {
    id: string;
    name: string;
  };
  type: CloneTypeEnum;
  isModalOpen: boolean;
  setModalOpen: (arg0: boolean) => void;
  cloneChildren?: boolean;
  handleClone: ({
    hasChildren,
    isEdit,
  }: {
    hasChildren: boolean;
    isEdit: boolean;
  }) => void;
  dataTc: string;
}

const CloneModal = (props: ICloneModalProps) => {
  const [hasChildren, setChildren] = useState(false);
  const classes = useStyles();

  const {
    isModalOpen,
    setModalOpen,
    type,
    entity,
    cloneChildren = false,
    handleClone,
    dataTc,
  } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      maxWidth="xl"
      data-tc={dataTc}
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">Are you sure?</Typography>
        <IconButton
          onClick={() => setModalOpen(false)}
          classes={{ root: classes.closeButton }}
          data-tc={`${dataTc}X`}
        >
          <CloseIcon />
          <span className="sr-only">Close modal</span>
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.content }}
        data-tc={`${dataTc}Content`}
      >
        <p>
          This will create a new{' '}
          {findLabelValue({
            collection: cloneTypeValues,
            lookupValue: type,
          })}{' '}
          called &quot;[CLONE] {entity.name}&quot;
        </p>
        {cloneChildren ? (
          <FormControlLabel
            control={
              <Switch
                checked={hasChildren}
                data-tc="cloneChildren"
                name="cloneChildren"
                onChange={() => setChildren((prevState) => !prevState)}
                value={hasChildren}
                color="primary"
              />
            }
            label={findLabelValue({
              collection: cloneChildrenText,
              lookupValue: type,
            })}
          />
        ) : null}
      </DialogContent>
      <DialogActions
        classes={{
          root: classNames(
            classes.actions,
            `${classes.actions}--space-between`
          ),
        }}
      >
        <StyledButton
          onClick={() => setModalOpen(false)}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}Cancel`}
        >
          Cancel
        </StyledButton>
        <SplitButton
          toggleDataTc="splitButtonToggleClone"
          buttons={[
            {
              text: 'Clone',
              onClick: () => handleClone({ hasChildren, isEdit: false }),
              isLoading: false,
              disabled: false,
              dataTc: `clone${findLabelValue({
                collection: cloneTypeValues,
                lookupValue: type,
              })}Button`,
            },
            {
              text: 'Clone and Edit',
              onClick: () => handleClone({ hasChildren, isEdit: true }),
              isLoading: false,
              disabled: false,
              dataTc: `cloneEdit${findLabelValue({
                collection: cloneTypeValues,
                lookupValue: type,
              })}Button`,
            },
          ]}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CloneModal;
