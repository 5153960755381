import colors from 'assets/styles/colors';
import Typography from 'features/targetingV2/components/core/Typography';
import React from 'react';

import useStyles from './ParameterMessage.styles';

export enum ParameterMessageColorEnum {
  Warning = 'warning',
  Error = 'error',
}

const ParameterMessage = ({
  message,
  color,
}: {
  message: string;
  color: ParameterMessageColorEnum;
}) => {
  const messageColor = {
    warning: colors.daxStatusError,
    error: colors.errorText,
  };

  const classes = useStyles({ color: messageColor[color] });

  return (
    <Typography variant="body2" className={classes.message}>
      {message}
    </Typography>
  );
};

export default ParameterMessage;
