import { SectionType } from 'features/targetingV2/types/common';
import {
  ICustomParameter,
  ICustomParameterGroup,
  IDayTimeTargeting,
  IParameter,
  IParameterGroup,
  ITargetingDefinitionV2,
  ITargetingV2Info,
} from 'features/targetingV2/types/targeting';
import { TargetingRestriction, Territory } from 'interfaces/generated.types';
import { getTargetingRestrictionsAsString } from 'utils/dataTransformation';

import { clusivityOptionLabels } from './clusivity';
import { isTargetingParameterInvalid, mandatoryParameters } from './common';
import {
  getCustomKvpsLabels,
  getParametersContainingValidCustomKvps,
} from './customParameter';
import {
  getAccuracy,
  getDefaultRows,
  getNumberedSectionTitle,
  getParameterTypeName,
  getParameterValueLabelsBasedOnType,
} from './dataFormatting';
import { formatDayTime } from './dayTime';

export const formatAccuracy = (
  sectionType: SectionType,
  accuracy: string | number
) => {
  switch (sectionType) {
    case SectionType.Location:
      return `Accuracy ${accuracy}`;
    default:
      return accuracy;
  }
};

export const getParameterLineData = (
  parameter: IParameter,
  targetingV2Info: ITargetingV2Info,
  parameterGroup: IParameterGroup,
  sectionType: SectionType,
  sectionTitle: string,
  activeTerritory: Territory
) => {
  const parameterName = getParameterTypeName(
    parameter.type,
    targetingV2Info.allParametersInfo
  );

  const parameterValueLabels = getParameterValueLabelsBasedOnType(
    parameter,
    parameterGroup,
    activeTerritory
  );

  const accuracy = getAccuracy(
    parameterGroup,
    parameter,
    sectionType,
    targetingV2Info.allLocationAccuracies,
    true
  );

  const parameterLine = [
    sectionTitle,
    ...(parameterName ? [parameterName] : []),
    ...(parameter.clusivity
      ? [clusivityOptionLabels[parameter.clusivity]]
      : []),
    parameterValueLabels.join(', '),
    ...(accuracy ? [formatAccuracy(sectionType, accuracy)] : []),
  ];

  return parameterLine;
};

export const getSectionClipboardLinesFromParameterGroups = (
  parameterGroups: IParameterGroup[] | undefined,
  sectionType: SectionType,
  targetingV2Info: ITargetingV2Info,
  activeTerritory: Territory
) =>
  parameterGroups?.flatMap((parameterGroup, parameterGroupIndex) => {
    const numberedSectionTitle = getNumberedSectionTitle(
      parameterGroups.length,
      parameterGroupIndex,
      sectionType
    );

    const getDefaultRowCallback = (parameter: IParameter) => [
      getParameterLineData(
        parameter,
        targetingV2Info,
        parameterGroup,
        sectionType,
        numberedSectionTitle,
        activeTerritory
      ).join(' - '),
    ];

    const defaultRows = getDefaultRows(
      parameterGroup,
      sectionType,
      getDefaultRowCallback
    );

    return [
      ...defaultRows,
      ...parameterGroup.parameters.reduce((accumulator, parameter) => {
        if (!isTargetingParameterInvalid(parameter, mandatoryParameters)) {
          const parameterLineData = getParameterLineData(
            parameter,
            targetingV2Info,
            parameterGroup,
            sectionType,
            numberedSectionTitle,
            activeTerritory
          ).join(' - ');
          if (parameterLineData !== '') {
            accumulator.push(parameterLineData);
          }
        }
        return accumulator;
      }, [] as string[]),
    ];
  }) || [];

export const getCustomParameterLineData = (
  customParameter: ICustomParameter
) => {
  const customKvpsLabels = getCustomKvpsLabels(customParameter.customKvps).join(
    ', '
  );

  const parameterLine = [
    'Custom Parameter',
    clusivityOptionLabels[customParameter.clusivity],
    customKvpsLabels,
  ];

  return parameterLine;
};

export const getCustomSectionClipboardLinesFromParameterGroups = (
  customGroups: ICustomParameterGroup[] | undefined,
  errors: any
) =>
  customGroups?.flatMap((customGroup, customGroupIndex) => {
    const customGroupFieldName = `targetingDefinitionV2.customTargeting.customGroups[${customGroupIndex}]`;
    const validParameters = getParametersContainingValidCustomKvps(
      customGroup.parameters,
      customGroupFieldName,
      errors
    );

    return validParameters.map((validParameter) =>
      getCustomParameterLineData(validParameter).join(' - ')
    );
  }) || [];

export const getDayTimeSectionClipboardLine = (
  dayTimeTargeting: IDayTimeTargeting | null
) => {
  if (!dayTimeTargeting || !dayTimeTargeting.dayTimePoints.length) {
    return null;
  }

  const { clusivity, dayTimePoints } = dayTimeTargeting;
  const formattedDayTime = formatDayTime(dayTimePoints);
  const parameterLine = [
    'Day & Time',
    clusivityOptionLabels[clusivity],
    formattedDayTime,
  ].join(' - ');
  return parameterLine;
};

export const formatForecastDataForClipboard = (
  targetingDefinitionV2: ITargetingDefinitionV2,
  targetingV2Info: ITargetingV2Info,
  activeTerritory: Territory,
  errors: any,
  restrictions: TargetingRestriction[]
) => {
  const {
    locationTargeting,
    audienceTargeting,
    dayTimeTargeting,
    inventoryTargeting,
    technologyTargeting,
    customTargeting,
  } = targetingDefinitionV2;

  const targetingRestrictionsString =
    getTargetingRestrictionsAsString(restrictions);
  const targetingRestrictionsLine = targetingRestrictionsString.length
    ? [`Campaign Restriction - ${targetingRestrictionsString}`]
    : [];

  const locationSectionLines = getSectionClipboardLinesFromParameterGroups(
    locationTargeting?.locations,
    SectionType.Location,
    targetingV2Info,
    activeTerritory
  );

  const audienceSectionLines = getSectionClipboardLinesFromParameterGroups(
    audienceTargeting?.audiences,
    SectionType.Audience,
    targetingV2Info,
    activeTerritory
  );

  const dayTimeSectionLine = getDayTimeSectionClipboardLine(dayTimeTargeting);
  const dayTimeSectionLines = dayTimeSectionLine ? [dayTimeSectionLine] : [];

  const inventorySectionLines = getSectionClipboardLinesFromParameterGroups(
    inventoryTargeting?.inventories,
    SectionType.Inventory,
    targetingV2Info,
    activeTerritory
  );

  const technologySectionLines = getSectionClipboardLinesFromParameterGroups(
    technologyTargeting?.technologies,
    SectionType.Technology,
    targetingV2Info,
    activeTerritory
  );

  const customSectionLines = getCustomSectionClipboardLinesFromParameterGroups(
    customTargeting?.customGroups,
    errors
  );

  const allClipboardLines = [
    ...targetingRestrictionsLine,
    ...locationSectionLines,
    ...audienceSectionLines,
    ...dayTimeSectionLines,
    ...inventorySectionLines,
    ...technologySectionLines,
    ...customSectionLines,
  ];

  const formattedClipboardLines = allClipboardLines.join('\n');

  return formattedClipboardLines;
};
