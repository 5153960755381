import pageStyles from 'assets/styles/components/Page.styles';
import Loader from 'components/Loader/Loader';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { COMPLETE_USER_UPDATE_WITH_ASSUMED_USER_DETAILS } from 'context/CustomApolloProvider/reducer';
import { Event } from 'context/GTMLoggerProvider/GTMLoggerProvider';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import {
  EntityPermission,
  Permission,
  Territory,
  useGetCurrentUserQuery,
} from 'interfaces/generated.types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthState } from 'utils/authProvider';

export interface IUserContext {
  id: string;
  email: string;
  permissions: Permission[];
  entityPermissions: EntityPermission[];
  primaryTerritory: Territory | undefined;
  territories: Territory[];
  fetchingUserDetails: boolean;
  userType: string;
}

export const defaultUserContext = {
  id: '',
  email: '',
  permissions: [],
  entityPermissions: [],
  primaryTerritory: undefined,
  territories: [],
  fetchingUserDetails: true,
  userType: '',
};

const UserContext = createContext<IUserContext>(defaultUserContext);

const UserProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const classes = pageStyles();
  const { loaded, state: authState } = useAuthContext();
  const [currentUserContext, setCurrentUserContext] =
    useState<IUserContext>(defaultUserContext);

  const {
    state: { assumedId, updatingUserWithAssumedDetails },
    dispatch,
  } = useCustomApolloContext();

  const [skipQuery, setSkipQuery] = useState(authState !== AuthState.LoggedIn);

  const gtmLogger = useGTMLogger();

  const { loading } = useGetCurrentUserQuery({
    skip: skipQuery,
    onCompleted: (result) => {
      if (result?.currentUser) {
        setCurrentUserContext({
          id: result.currentUser.id,
          email: result.currentUser.email || defaultUserContext.email,
          permissions:
            result.currentUser.permissions || defaultUserContext.permissions,
          entityPermissions:
            result.currentUser.entityPermissions ||
            defaultUserContext.entityPermissions,
          primaryTerritory: result.currentUser.primaryTerritory,
          territories:
            result.currentUser.territories || defaultUserContext.territories,
          fetchingUserDetails: false,
          userType: result.currentUser.userType || defaultUserContext.userType,
        });

        const userEmailDomain = result.currentUser.email?.split('@')[1];

        gtmLogger({
          event: Event.USER_LOADS,
          userId: result.currentUser.id,
          userEmailDomain,
        });

        dispatch({
          type: COMPLETE_USER_UPDATE_WITH_ASSUMED_USER_DETAILS,
        });
      }

      setSkipQuery(true);
    },
    onError: () => {
      setCurrentUserContext(() => ({
        ...defaultUserContext,
        fetchingUserDetails: false,
      }));
    },
  });

  useEffect(() => {
    if (authState === AuthState.LoggedIn) {
      setCurrentUserContext((prevState) => ({
        ...prevState,
        fetchingUserDetails: true,
      }));
      setSkipQuery(false);
    } else if (authState === AuthState.LoggedOut) {
      setSkipQuery(true);
      setCurrentUserContext(() => ({
        ...defaultUserContext,
        fetchingUserDetails: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, assumedId]);

  const message =
    authState === AuthState.FailedLoadingSdk
      ? 'If loading takes longer than expected, please consider refreshing the page.'
      : '';

  if (
    currentUserContext.fetchingUserDetails ||
    updatingUserWithAssumedDetails ||
    loading ||
    !loaded
  )
    return (
      <div>
        <Loader />
        {message && <p className={classes.page}>{message}</p>}
      </div>
    );

  return (
    <UserContext.Provider value={currentUserContext}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserProvider;
