import {
  ICustomKvp,
  IParameterValueOption,
} from 'features/targetingV2/types/common';
import {
  AdRequestEnrichment,
  AdRequestEnrichmentAudienceParameterAttribute,
  AdRequestEnrichmentCustomKvpAttribute,
  AudienceParameter,
  AudienceParameterType,
  AudienceParameterValue,
} from 'interfaces/generated.types';
import { getSelectOption } from './dataTransformation';

export interface IAdRequestEnrichmentParameter {
  type: AudienceParameterType;
  values: IParameterValueOption[];
}

export interface IAdRequestEnrichment {
  parameters?: IAdRequestEnrichmentParameter[];
  customKvps?: ICustomKvp[];
}

export const defaultAdRequestEnrichmentParameter = {
  type: '' as AudienceParameterType,
  values: [],
};

export const defaultAdRequestEnrichment = {
  parameters: [],
  customKvps: [],
};

export const formatAdRequestEnrichmentParameterValuesResponse = (
  parameterValues: AudienceParameterValue[]
): IParameterValueOption[] =>
  parameterValues.map((currentParameterValue: AudienceParameterValue) => {
    const option = getSelectOption(currentParameterValue, 'name', 'id');

    return {
      ...option,
      children: currentParameterValue.children
        ? formatAdRequestEnrichmentParameterValuesResponse(
            currentParameterValue.children
          )
        : undefined,
    };
  });

export const formatAdRequestEnrichmentResponse = (
  adRequestEnrichment: AdRequestEnrichment | undefined
): IAdRequestEnrichment => {
  const formattedCustomKvps = adRequestEnrichment?.customKvps
    ? adRequestEnrichment.customKvps.map((customKvp) => ({
        key: customKvp.key,
        value: customKvp.value,
      }))
    : [];

  if (!adRequestEnrichment || !adRequestEnrichment.parameters.length)
    return { ...defaultAdRequestEnrichment, customKvps: formattedCustomKvps };

  const formattedAdRequestEnrichment = {
    parameters: adRequestEnrichment.parameters.map(
      (parameter: AudienceParameter) => ({
        type: parameter.type,
        values: formatAdRequestEnrichmentParameterValuesResponse(
          parameter.values
        ),
      })
    ),
    customKvps: formattedCustomKvps,
  };

  return formattedAdRequestEnrichment;
};

export const isAdRequestEnrichmentParameterInvalid = (parameter: {
  type: AudienceParameterType;
  values: any[];
}) => !parameter.type || parameter.values.length === 0;

export const hasAdRequestEnrichmentInvalidParameters = (
  parameters: IAdRequestEnrichmentParameter[]
) =>
  parameters.some((parameter: IAdRequestEnrichmentParameter) =>
    isAdRequestEnrichmentParameterInvalid(parameter)
  );

export const getAdRequestEnrichmentAudienceParametersAttributes = (
  parameters: IAdRequestEnrichmentParameter[] | undefined
) => {
  if (!parameters) return null;

  return parameters.reduce(
    (
      parametersAttributesResult: AdRequestEnrichmentAudienceParameterAttribute[],
      currentParameter: IAdRequestEnrichmentParameter
    ) => {
      const currentParameterWithFormattedValues = {
        type: currentParameter.type as AudienceParameterType,
        values: currentParameter.values.map(
          (parameterValue) => parameterValue.value
        ),
      };

      if (
        !isAdRequestEnrichmentParameterInvalid(
          currentParameterWithFormattedValues
        )
      )
        parametersAttributesResult.push(currentParameterWithFormattedValues);

      return parametersAttributesResult;
    },
    []
  );
};

export const isAdRequestEnrichmentCustomKvpIncomplete = (
  customKvp: ICustomKvp
) => !customKvp.key || !customKvp.value;

export const hasIncompleteAdRequestEnrichmentCustomKvps = (
  customKvps: ICustomKvp[]
) =>
  customKvps.some((customKvp) =>
    isAdRequestEnrichmentCustomKvpIncomplete(customKvp)
  );

export const getAdRequestEnrichmentCustomKvpsAttributes = (
  customKvps: ICustomKvp[] | undefined
) => {
  if (!customKvps) return [];

  return customKvps.reduce(
    (
      customKvpsResult: AdRequestEnrichmentCustomKvpAttribute[],
      currentCustomKvp: ICustomKvp
    ) => {
      if (!isAdRequestEnrichmentCustomKvpIncomplete(currentCustomKvp)) {
        customKvpsResult.push({
          key: currentCustomKvp.key,
          value: currentCustomKvp.value,
          override: false,
        });
      }
      return customKvpsResult;
    },
    []
  );
};

export const getAdRequestEnrichmentAttributes = (
  adRequestEnrichment: IAdRequestEnrichment | null
) => {
  if (!adRequestEnrichment) return null;

  return {
    parameters: getAdRequestEnrichmentAudienceParametersAttributes(
      adRequestEnrichment.parameters
    ),
    customKvps: getAdRequestEnrichmentCustomKvpsAttributes(
      adRequestEnrichment.customKvps
    ),
  };
};
