import { ErrorMessage, Field, getIn } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const ageValues = {
  ageParams: {
    ageRange: {
      minAge: '',
      maxAge: '',
      readOnly: false,
    },
  },
};

export interface ITargetingAgeValues {
  minAge: number | string;
  maxAge: number | string;
}

const TargetingAge = (
  props: IFormProps<ITargetingAgeValues> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
    touched,
    errors,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <div className={classes.wrapper}>
      <Field
        component={TextField}
        data-tc="targetingMinAge"
        label="Minimum Age"
        placeholder="Minimum Age"
        type="number"
        name={`${targetingPath}.audienceParams.${index}.ageParams.ageRange.minAge`}
        fullWidth
        classes={{ root: classes.pairValues }}
        disabled={
          targetingValues.audienceParams[index].ageParams.ageRange.readOnly
        }
      />
      <Field
        component={TextField}
        data-tc="targetingMaxAge"
        label="Maximum Age"
        placeholder="Maximum Age"
        type="number"
        name={`${targetingPath}.audienceParams.${index}.ageParams.ageRange.maxAge`}
        fullWidth
        classes={{ root: classes.pairValues }}
        disabled={
          targetingValues.audienceParams[index].ageParams.ageRange.readOnly
        }
      />
      {((getIn(
        touched,
        `${targetingPath}.audienceParams.${index}.ageParams.ageRange.minAge`
      ) &&
        getIn(
          errors,
          `${targetingPath}.audienceParams.${index}.ageParams.ageRange.minAge`
        )) ||
        (getIn(
          touched,
          `${targetingPath}.audienceParams.${index}.ageParams.ageRange.maxAge`
        ) &&
          getIn(
            errors,
            `${targetingPath}.audienceParams.${index}.ageParams.ageRange.maxAge`
          ))) && (
        <div className={classes.errors}>
          <FormHelperText error data-tc="targetingAgeError">
            <span className={classes.errorMessage}>
              <ErrorMessage
                name={`${targetingPath}.audienceParams.${index}.ageParams.ageRange.minAge`}
              />
            </span>
            <span className={classes.errorMessage}>
              <ErrorMessage
                name={`${targetingPath}.audienceParams.${index}.ageParams.ageRange.maxAge`}
              />
            </span>
          </FormHelperText>
        </div>
      )}
    </div>
  );
};

export default TargetingAge;
