import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1, 0.5, 2),
    backgroundColor: `${theme.palette.grey[500]}`,
    color: theme.palette.primary.main,
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
  },
  toggle: {
    fontSize: '0.813em',
  },
  innerContent: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 2.5),
    fontSize: '0.813em',
  },
}));

export default useStyles;
