import { ReactComponent as SearchIcon } from 'assets/icons/dax-searchicon.svg';
import { SearchResult, SearchType } from 'interfaces/generated.types';
import React, { useState } from 'react';

import { InputAdornment, MenuItem, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import useStyles from './SearchField.styles';
import SearchOption from './SearchOption';

export interface SearchFieldProps {
  options: SearchResult[];
  onChange: (type: SearchType, value: string) => void;
  onSelect: (value: SearchResult) => void;
}

type SearchTypeItem = {
  key: SearchType;
  label: string;
};

const searchTypeOptions: SearchTypeItem[] = [
  { key: SearchType.Name, label: 'Entity Name' },
  { key: SearchType.Id, label: 'Id' },
];

const SearchField = ({ options, onChange, onSelect }: SearchFieldProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [searchType, setSearchType] = React.useState<SearchTypeItem>(
    searchTypeOptions[0]
  );
  // Following 2 state properties are used to manage animations and focus on the various
  // components of the search field
  const [isSearchFieldFocused, setIsSearchFieldFocused] = useState(false);
  const [wasDropdownItemSelected, setWasDropdownItemSelected] = useState(false);

  const classes = useStyles();

  const handleInputChange = (value: any) => {
    if (value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    setInputValue(value);
    // Get suggestions for search type and value
    onChange(searchType.key, value);
  };

  const handleSelection = (_event: any, value: any) => {
    if (value) onSelect(value);
  };

  const handleDropdownChange = (event: any) => {
    const selectedType = searchTypeOptions.find(
      (option) => option.key === event.target.value
    );

    if (selectedType) {
      setSearchType(selectedType);
      onChange(selectedType.key, inputValue);
    }
  };

  const handleOpen = () => {
    if (inputValue.length > 0) {
      // Wait for width animation to finish
      setTimeout(() => {
        setOpen(true);
      }, 600);
    }
  };

  const dropdownOptions = searchTypeOptions.map((option: any) => (
    <MenuItem
      key={option.key}
      value={option.key}
      onBlur={() => {
        setWasDropdownItemSelected(true);
      }}
      className={classes.dropdownItem}
    >
      {option.label}
    </MenuItem>
  ));

  const isSearchFieldExpanded = isSearchFieldFocused || wasDropdownItemSelected;

  return (
    <Autocomplete
      fullWidth
      options={options}
      filterOptions={(suggestions) => suggestions}
      noOptionsText="No options found"
      data-tc="globalSearchFieldAutocomplete"
      inputValue={inputValue}
      blurOnSelect
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          handleInputChange(value);
          setWasDropdownItemSelected(false);
        }
      }}
      onChange={handleSelection}
      open={open}
      onOpen={() => {
        handleOpen();
      }}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          key="global-search-input-field"
          data-tc="globalSearchInputField"
          onFocus={() => {
            setIsSearchFieldFocused(true);
          }}
          onBlur={() => {
            setIsSearchFieldFocused(false);
          }}
          placeholder="Search"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                data-tc="globalSearchFieldStartAdornment"
              >
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: isSearchFieldExpanded ? (
              <InputAdornment
                position="end"
                data-tc="globalSearchFieldEndAdornment"
              >
                <TextField
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      classes: {
                        list: classes.dropdownList,
                      },
                    },
                  }}
                  label=""
                  onFocus={() => {
                    setIsSearchFieldFocused(true);
                  }}
                  onBlur={() => {
                    setIsSearchFieldFocused(false);
                    setWasDropdownItemSelected(false);
                  }}
                  value={searchType.key}
                  name="SearchType"
                  data-tc="globalSearchDropdown"
                  onChange={handleDropdownChange}
                  className={classes.selectWrapper}
                >
                  {dropdownOptions}
                </TextField>
              </InputAdornment>
            ) : null,
            className: isSearchFieldExpanded
              ? classes.searchTextFieldExpanded
              : classes.searchTextField,
          }}
        />
      )}
      renderOption={(option) => <SearchOption option={option} />}
      className={classes.wrapper}
      classes={{
        option: classes.optionContainer,
        listbox: classes.listboxWrapper,
        input: classes.searchTextFieldInput,
      }}
    />
  );
};

export default SearchField;
