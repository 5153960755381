import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.8),
    padding: theme.spacing(0.9, 2.38, 0.9, 2.38),
    margin: theme.spacing(0.5, 0, 2, 0),
  },
}));

export default useStyles;
