import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import useDateTimePicker from 'hooks/DateTimePicker/useDateTimePicker';
import { IDateRange } from 'interfaces';
import React, { useEffect } from 'react';
import {
  lastXDaysPresets,
  periodPresets,
  Preset,
} from 'utils/dateRangePresets';
import {
  getDateRangeDisplayedValueBasedOnTerritory,
  getFormattedDateBasedOnTerritory,
} from 'utils/defaultsByTerritory';

import { TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { useDateTimePickerDialogStyles } from './DateTimePicker.styles';
import DateTimePickerCalendar from './DateTimePickerCalendar';

export interface DateRangePickerDialogProps {
  open: boolean;
  initialRange?: IDateRange;
  title: string;
  isClearable?: boolean;
  cancelButtonLabel?: string;
  showTwoFieldsForSelectedRange?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  displayPresets?: boolean;
  'data-tc'?: string;
  onClose: () => void;
  onRangeChange: (startDate: Date, endDate: Date) => void;
  onRangeClear?: () => void;
}

const DateRangePickerDialog = ({
  open,
  initialRange,
  title,
  isClearable = false,
  cancelButtonLabel = 'Cancel',
  showTwoFieldsForSelectedRange = false,
  disablePast = false,
  disableFuture = false,
  displayPresets = false,
  'data-tc': dataTc,
  onClose,
  onRangeChange,
  onRangeClear,
}: DateRangePickerDialogProps) => {
  const options = {
    showNonCurrentDates: false,
    disablePast,
    disableFuture,
    initialRange,
  };
  const datePickerDialogClasses = useDateTimePickerDialogStyles();
  const {
    cursor,
    selected,
    selectedRangeStartDate,
    selectedRangeEndDate,
    setSelectedDateForDateRange,
    setSelectedDateRange,
    resetSelectedRange,
    setSelectedRangeToCurrentDate,
    setCursorToSelectedRangeStart,
    isDateInSelectedRange,
    forEachWeek,
    forEachDay,
    isSameDay,
    isToday,
    moveCursorOneMonthForward,
    moveCursorOneMonthBackward,
  } = useDateTimePicker(options);

  const handleConfirm = () => {
    onRangeChange(selectedRangeStartDate, selectedRangeEndDate);
    onClose();
  };

  const handleClose = () => {
    onClose();
    resetSelectedRange();
  };

  const handleClear = () => {
    onRangeClear && onRangeClear();
    onClose();
    setSelectedRangeToCurrentDate();
  };

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const displayedRange = getDateRangeDisplayedValueBasedOnTerritory(
    { startDate: selectedRangeStartDate, endDate: selectedRangeEndDate },
    activeTerritory!
  );

  const rangeStartDate = `${getFormattedDateBasedOnTerritory(
    selectedRangeStartDate,
    activeTerritory
  )}`;

  const rangeEndDate = `${getFormattedDateBasedOnTerritory(
    selectedRangeEndDate,
    activeTerritory
  )}`;

  const handlePresetSelection = (dateRange: IDateRange) => {
    setSelectedDateRange(dateRange);
  };

  useEffect(() => {
    resetSelectedRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRange]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      onEnter={() => setCursorToSelectedRangeStart()}
      data-tc={dataTc}
      data-testid={dataTc}
    >
      <DialogContent classes={{ root: datePickerDialogClasses.content }}>
        <div className={datePickerDialogClasses.grid}>
          <div
            className={classNames(
              datePickerDialogClasses.container,
              `${datePickerDialogClasses.container}--heading`
            )}
          >
            <h2 className={datePickerDialogClasses.heading}>{title}</h2>
          </div>
          <div
            className={classNames(
              datePickerDialogClasses.container,
              `${datePickerDialogClasses.container}--range-calendar`
            )}
          >
            <DateTimePickerCalendar
              cursor={cursor}
              selected={selected}
              onIncrement={moveCursorOneMonthForward}
              onDecrement={moveCursorOneMonthBackward}
              onClick={setSelectedDateForDateRange}
              forEachWeek={forEachWeek}
              forEachDay={forEachDay}
              isSameDay={isSameDay}
              isToday={isToday}
              isDateInRange={isDateInSelectedRange}
              isRangeCalendar
            />
          </div>
          <div
            className={classNames(
              datePickerDialogClasses.container,
              `${datePickerDialogClasses.container}--range-content-section`,
              `${datePickerDialogClasses.container}--range-selected`
            )}
          >
            {showTwoFieldsForSelectedRange ? (
              <>
                <TextField
                  label="Start Date"
                  name="StartDate"
                  id="startDate"
                  value={rangeStartDate}
                  inputProps={{
                    readOnly: true,
                    className: datePickerDialogClasses.rangeTextFieldInput,
                  }}
                  InputLabelProps={{
                    className: datePickerDialogClasses.rangeTextFieldLabel,
                  }}
                  className={`${datePickerDialogClasses.rangeTextField}`}
                />

                <TextField
                  label="End Date"
                  name="EndDate"
                  id="endDate"
                  value={rangeEndDate}
                  inputProps={{
                    readOnly: true,
                    className: datePickerDialogClasses.rangeTextFieldInput,
                  }}
                  InputLabelProps={{
                    className: datePickerDialogClasses.rangeTextFieldLabel,
                  }}
                  className={datePickerDialogClasses.rangeTextField}
                />
              </>
            ) : (
              <span
                className={datePickerDialogClasses.displayedSelection}
                data-testid="dateRangeDialog--selected-range"
              >
                {displayedRange}
              </span>
            )}
          </div>
          {displayPresets && (
            <>
              <div
                className={classNames(
                  datePickerDialogClasses.container,
                  `${datePickerDialogClasses.container}--range-content-section`,
                  `${datePickerDialogClasses.container}--last-x-days-presets`
                )}
              >
                <Typography
                  className={datePickerDialogClasses.presetsHeader}
                  noWrap
                >
                  Last
                </Typography>
                <div className={datePickerDialogClasses.presetsCtasWrapper}>
                  {lastXDaysPresets.map((preset: Preset) => (
                    <StyledButton
                      className={datePickerDialogClasses.presetsButton}
                      onClick={() => {
                        const newRange = preset.onSelection();
                        handlePresetSelection(newRange);
                      }}
                      color={ButtonColorEnum.Secondary}
                      variant={ButtonVariantEnum.Contained}
                      data-tc={`${preset.id}`}
                      key={preset.id}
                      disableRipple
                    >
                      {preset.label}
                    </StyledButton>
                  ))}
                </div>
              </div>
              <div
                className={classNames(
                  datePickerDialogClasses.container,
                  `${datePickerDialogClasses.container}--range-content-section`,
                  `${datePickerDialogClasses.container}--period-presets`
                )}
              >
                <Typography
                  className={datePickerDialogClasses.presetsHeader}
                  noWrap
                >
                  Presets
                </Typography>
                <div className={datePickerDialogClasses.presetsCtasWrapper}>
                  {periodPresets.map((preset: Preset) => (
                    <StyledButton
                      className={datePickerDialogClasses.presetsButton}
                      onClick={() => {
                        const newRange = preset.onSelection(
                          disablePast,
                          disableFuture
                        );
                        handlePresetSelection(newRange);
                      }}
                      color={ButtonColorEnum.Secondary}
                      variant={ButtonVariantEnum.Contained}
                      data-tc={`${preset.id}`}
                      key={preset.id}
                    >
                      {preset.label}
                    </StyledButton>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <DialogActions
          classes={{
            root: datePickerDialogClasses.actions,
          }}
        >
          <StyledButton
            className={datePickerDialogClasses.button}
            onClick={handleClose}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="cancelDateRangeButton"
            testId="cancelDateRangeButton"
          >
            {cancelButtonLabel}
          </StyledButton>
          <div>
            {isClearable && (
              <StyledButton
                className={classNames(
                  datePickerDialogClasses.button,
                  `${datePickerDialogClasses.button}--right-margin`
                )}
                onClick={handleClear}
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Outlined}
                data-tc="clearDateRangeButton"
              >
                Clear
              </StyledButton>
            )}
            <StyledButton
              className={datePickerDialogClasses.button}
              onClick={handleConfirm}
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              data-tc="confirmDateRangeButton"
            >
              Apply
            </StyledButton>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DateRangePickerDialog;
