import { baseCheckboxStyles } from 'features/targetingV2/styles/checkbox.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseCheckboxStyles(theme),
  checkbox: {
    padding: '0 8px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
  labelContainer: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    padding: '8px',
  },
  gradeDescription: {
    opacity: 0.5,
  },
  gradeAccuracyIcon: {
    flex: 1,
    justifySelf: 'flex-end',
    width: '16px',
    height: '16px',
    '& > path': {
      stroke: theme.palette.grey[400],
    },
  },
}));

export default useStyles;
