import classNames from 'classnames';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import dialogStyles from 'assets/styles/components/Dialog.styles';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

export interface IMessageDialogProps {
  isModalOpen: boolean;
  handleClose: () => void;
  content: {
    title: string;
    main: string;
  };
  dataTc: string;
}

const MessageDialog = (props: IMessageDialogProps) => {
  const classes = dialogStyles();

  const {
    isModalOpen,
    handleClose,
    content: { title, main },
    dataTc,
  } = props;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="xl"
      data-testid={dataTc}
    >
      <>
        <DialogTitle classes={{ root: classes.title }} disableTypography>
          <Typography display="inline">{title}</Typography>
          <IconButton
            onClick={handleClose}
            classes={{ root: classes.closeButton }}
            data-tc={`${dataTc}X`}
          >
            <CloseIcon />
            <span className="sr-only">Close modal</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          classes={{ root: classes.content }}
          data-tc={`${dataTc}Content`}
        >
          <p>{main}</p>
        </DialogContent>
        <DialogActions
          classes={{
            root: classNames(
              classes.actions,
              `${classes.actions}--space-evenly`
            ),
          }}
        >
          <StyledButton
            onClick={handleClose}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc={`${dataTc}Close`}
          >
            Close
          </StyledButton>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default MessageDialog;
