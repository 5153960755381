import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(2, 0, 2.5, 0),
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
    minWidth: theme.spacing(52),
  },
  upload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(19),
  },
  messageWrapper: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
}));

export default useStyles;
