import formStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  IDspFormValues,
  maxImpressionsPerBid,
} from 'features/programmatic/dsp/components/DspTabsForm/DspFormValues';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import React from 'react';

import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchIcon from '@material-ui/icons/Launch';

interface IDspDetailsProps {
  update?: boolean;
}

const DspDetails = ({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  touched,
  update = false,
  values,
  status = {},
}: IFormProps<IDspFormValues> & IDspDetailsProps) => {
  const classes = formStyles();

  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Programmatic Connector Name"
              placeholder="Programmatic Connector Name"
              name="technicalProviderName"
              data-tc="technicalProviderName"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/programmatic-connector/${values.technicalProviderId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="dspTechnicalProviderLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.technicalProviderName}
              </span>
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={8}>
          <Field
            component={TextField}
            data-tc="dspName"
            fullWidth
            label="Name"
            name="name"
            autoFocus={!update}
            placeholder="Name"
            inputProps={{ 'data-testid': 'dspName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="DSP ID"
              placeholder="DSP ID"
              data-tc="dspId"
              name="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="dspDailyCap"
            decimalScale={0}
            error={errors.dailyCap || status.dailyCap}
            label="Daily Cap"
            name="dailyCap"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.dailyCap || !!status.dailyCap}
            value={values.dailyCap}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="dspMaxAds"
            fullWidth
            label="Max impressions per bid request"
            name="maxAds"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
          >
            {maxImpressionsPerBid.map(
              (option: { value: number; label: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            )}
          </Field>
        </Grid>
        <Grid item xs={8}>
          <MultiSelect
            id="territories"
            options={(
              values.technicalProviderTerritories || []
            ).map((territoryOption) =>
              territories?.includes(territoryOption.value)
                ? territoryOption
                : { ...territoryOption, readOnly: true }
            )}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue(
                'territories',
                fieldValue.filter(
                  (territoryOption: OptionType) => !territoryOption.readOnly
                )
              );
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default DspDetails;
