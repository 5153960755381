import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      content: {},
      loading: {},
      loader: {
        marginLeft: theme.spacing(1),
        display: 'flex',
      },
      hasStartIcon: {
        justifyContent: 'start',
      },
    }),
  { name: 'Button' }
);
