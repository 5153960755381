import { makeStyles, Theme } from '@material-ui/core/styles';

export const baseDialogStyles = (theme: Theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '1em',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    padding: theme.spacing(0.5),
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.common.white,
    display: 'inline-flex',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
  },
  content: {
    padding: theme.spacing(3, 3, 0),
  },
  actions: {
    padding: theme.spacing(3),
    '&--left': {
      justifyContent: 'left',
    },
    '&--right': {
      justifyContent: 'flex-end',
    },
    '&--space-between': {
      justifyContent: 'space-between',
    },
    '&--space-evenly': {
      justifyContent: 'space-evenly',
    },
  },
});

const dialogStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
}));

export default dialogStyles;
