import pageStyles from 'assets/styles/components/Page.styles';
import Loader from 'components/Loader/Loader';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  Publisher,
  TimeSeries,
  usePublisherTimeSeriesQuery,
} from 'interfaces/generated.types';
import React, { lazy, Suspense } from 'react';
import { ChartAggregatorEnum } from 'utils/charts';
import dateUtils, { TimeZones } from 'utils/date';
import { getDefaultTimezoneBasedOnTerritory } from 'utils/defaultsByTerritory';

interface IPublisherMonitoring {
  id: string;
}

const XYDateChart = lazy(() => import('components/XYDateChart/XYDateChart'));

export const transformPublisherMonitoringData = (
  publisher: Publisher | undefined,
  timezone: TimeZones
) => {
  if (!publisher) {
    return [];
  }
  const { impressionsTimeSeries } = publisher;

  return [
    {
      label: 'Impressions',
      value: 'impressions',
      series: (impressionsTimeSeries as TimeSeries)?.dataPoints?.map(
        (point) => ({
          date: dateUtils.getDateInSpecificTimezone(point.t, timezone),
          value: point.v,
        })
      ),
      aggregator: ChartAggregatorEnum.SUM,
    },
  ];
};

const PublisherMonitoring = ({ id }: IPublisherMonitoring) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const activeTerritoryTimezone = getDefaultTimezoneBasedOnTerritory(
    activeTerritory!
  );

  const { data, loading, error } = usePublisherTimeSeriesQuery({
    variables: {
      id,
      timeZone: activeTerritoryTimezone,
    },
    errorPolicy: 'all',
  });

  const classes = pageStyles();

  const noData = !(data && data.publisher);

  if (!loading && (error || noData))
    return (
      <p
        className={classes.errorMessage}
        data-testid="publisherMonitoringError"
      >
        Error, issues have occurred loading graph data
      </p>
    );

  return loading ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <XYDateChart
        data={transformPublisherMonitoringData(
          data?.publisher as Publisher,
          activeTerritoryTimezone
        )}
        initialSeries="impressions"
        testId="publisherMonitoringChart"
      />
    </Suspense>
  );
};

export default PublisherMonitoring;
