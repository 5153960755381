import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import loginStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import ForgottenPasswordValidation from 'features/login/validations/forgottenPassword';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { Typography } from '@material-ui/core';

import useStyles from './ForgettenPassword.styles';

const ForgottenPassword = () => {
  const classes = useStyles();
  const loginClasses = loginStyles();
  const history = useHistory();
  const [error, setError] = useState('');

  const { loaded, state, onResetPasswordSendEmail } = useAuthContext();

  const isLoading = !loaded || state === AuthState.Fetching;

  if (state === AuthState.LoggedIn) {
    return <Redirect to="/home" />;
  }

  const handleFormSubmit = (
    values: { loginID: string },
    actions: FormikHelpers<{
      loginID: string;
    }>
  ) => {
    const params = {
      loginID: values.loginID,
    };
    actions.setSubmitting(false);
    onResetPasswordSendEmail(params)
      .then(() =>
        history.push({
          pathname: '/forgotten-password-resend',
          state: { params },
        })
      )
      .catch((err) => setError(err));
  };
  const clearErrors = () => {
    setError('');
  };

  return (
    <PageContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={loginClasses.container}>
          <div className={loginClasses.content}>
            <Typography variant="h6" className={classes.title}>
              Forgotten Password
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={classes.paragraph}
              data-testid="forgotten-password--description"
            >
              Enter the email registered with DAX account and we&apos;ll send
              you a link to reset your password
            </Typography>
            <Formik
              initialValues={{ loginID: '' }}
              validateOnMount
              onSubmit={(values, actions) => {
                handleFormSubmit(values, actions);
              }}
              validationSchema={ForgottenPasswordValidation}
            >
              {(props) => {
                const {
                  values: { loginID },
                  errors,
                  handleSubmit,
                  handleChange,
                  touched,
                  isSubmitting,
                  isValid,
                } = props;

                return (
                  <Form onSubmit={handleSubmit}>
                    <div className={classes.textFieldContainer}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Email"
                        name="loginID"
                        id="loginID"
                        type="email"
                        margin="normal"
                        onChange={handleChange}
                        onKeyUp={clearErrors}
                        helperText={touched.loginID && errors.loginID}
                        FormHelperTextProps={{
                          error: !!(touched.loginID && errors.loginID),
                        }}
                      />
                    </div>
                    {error && (
                      <Typography
                        variant="body2"
                        align="center"
                        className={classes.errorWrapper}
                      >
                        {error}
                      </Typography>
                    )}

                    <div className={classes.ctaContainer}>
                      <StyledButton
                        onClick={history.goBack}
                        color={ButtonColorEnum.Default}
                        variant={ButtonVariantEnum.Outlined}
                        className={classNames(
                          classes.cta,
                          `${classes.cta}--back`
                        )}
                      >
                        Back
                      </StyledButton>

                      <StyledButton
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        className={classNames(
                          classes.cta,
                          `${classes.cta}--submit`
                        )}
                        isLoading={isSubmitting}
                        type="submit"
                        disabled={
                          !loginID || isSubmitting || !isValid || !!error
                        }
                      >
                        Send Email Password Reset
                      </StyledButton>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default ForgottenPassword;
