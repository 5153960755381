import { makeStyles, Theme } from '@material-ui/core/styles';
import { baseFontStyles } from 'assets/styles/components/Font.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  signInContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  signInForm: {
    marginBottom: theme.spacing(1.5),
  },
  error: {
    margin: theme.spacing(1, 0),
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    margin: theme.spacing(1, 0),
    color: theme.palette.grey[800],
  },
  footer: {
    justifySelf: 'flex-end',
  },
}));

export default useStyles;
