import {
  PrimaryControlEntity,
  PrimaryControlState,
} from 'interfaces/generated.types';

export const content = {
  campaign: {
    container: {
      title: 'Freeze All Campaigns',
      message:
        'Use this control to freeze all Campaigns across DAX Audio. Freezing allows campaigns to be modified but all ads will stop delivering.',
      note:
        'NOTE: This will stop delivery across all Campaigns. Misuse could lead to business-related ramifications for Global.',
      action: 'Freeze/Unfreeze',
    },
    dialog: {
      title: 'Are you sure?',
      message:
        'Continuing will stop delivering campaigns across the following territories:',
      note:
        'Note: Misuse could lead to business-related ramifications for Global.',
      cancel: 'Cancel',
      continue: 'Confirm Changes',
      entity: PrimaryControlEntity.Campaign,
      state: PrimaryControlState.Frozen,
    },
    error: {
      title: 'Freeze All Campaigns',
      message: 'This service is currently unavailable. Please try again later.',
    },
    printedBlockListLabel: 'Territories with campaigns currently frozen',
  },
  deal: {
    container: {
      title: 'Freeze All Deals',
      message: 'Use this control to freeze all Deals across DAX Audio.',
      note:
        'NOTE: This will stop delivery across all Deals. Misuse could lead to business-related ramifications for Global.',
      action: 'Freeze/Unfreeze',
    },
    dialog: {
      title: 'Are you sure?',
      message:
        'Continuing will stop delivering deals across the following territories:',
      note:
        'Note: Misuse could lead to business-related ramifications for Global.',
      cancel: 'Cancel',
      continue: 'Confirm Changes',
      entity: PrimaryControlEntity.Deal,
      state: PrimaryControlState.Frozen,
    },
    error: {
      title: 'Freeze All Deals',
      message: 'This service is currently unavailable. Please try again later.',
    },
    printedBlockListLabel: 'Territories with deals currently frozen',
  },
  salesChannel: {
    container: {
      title: 'Quarantine All Sales Channels & Deals',
      message:
        'Use this control to switch quarantining on for all programmatic sales channels and deals. This will mean all creatives for each deal and sales channel will need to be manually approved before broadcast.',
      note:
        'NOTE:  Misuse could lead to business-related ramifications for Global.',
      action: 'Quarantine Programmatics',
    },
    dialog: {
      title: 'Are you sure?',
      message:
        'Continuing will switch on quarantining for all sales channels and deals across the following territories:',
      note:
        'NOTE: This will mean all creatives for each deal and sales channel will need to be manually approved before broadcast. Misuse could lead to business-related ramifications for Global.',
      cancel: 'Cancel',
      continue: 'Quarantine',
      entity: PrimaryControlEntity.SalesChannel,
      state: PrimaryControlState.Quarantined,
    },
    error: {
      title: 'Quarantine All Sales Channels & Deals',
      message: 'This service is currently unavailable. Please try again later.',
    },
    printedBlockListLabel:
      'Territories with sales channels and deals currently quarantined',
  },
};

export default content;
