import {
  OriginatingCountryEnum,
  StationLanguageEnum,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';
import { IEntity, OptionType } from 'interfaces';
import {
  Campaign,
  CampaignStatus,
  IabCategory,
  TargetingRestriction,
  TrackingUrl,
  User,
  UserStatus,
} from 'interfaces/generated.types';
import find from 'lodash/find';
import result from 'lodash/result';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';

import {
  convertNumberToPercentage,
  divideTwoNumbers,
  numbroWholePercentageFormatting,
  parseFormattedValue,
} from './numbers';
import { getTargetingRestrictionsOptions } from './targetingRestrictions';

export const createSelectOption = (
  selectOption: IEntity,
  useAltId = false
) => ({
  value:
    useAltId && selectOption.altId
      ? selectOption.altId.toString()
      : selectOption.id,
  label: selectOption.name || '',
});

export const createSelectOptions = (
  selectOptions: IEntity[],
  useAltId = false
) =>
  selectOptions.map((selectOption: IEntity) =>
    createSelectOption(selectOption, useAltId)
  );

export const getSelectOption = (
  item: any,
  labelFieldName: string,
  valueFieldName: string
): OptionType => ({
  label: item[labelFieldName] || '',
  value: item[valueFieldName],
});

export const getSelectOptions = (
  list: any[],
  labelFieldName: string,
  valueFieldName: string
): OptionType[] =>
  list.map((item: any) =>
    getSelectOption(item, labelFieldName, valueFieldName)
  );

export const transformIabCategoryOptions = (
  iabCategories: (IabCategory | { code: string })[]
) => iabCategories.map((category) => category.code);

export const transformSelectOption = (selectOption: OptionType | null) => {
  if (!selectOption) {
    return '';
  }
  return selectOption.value;
};

export const transformSelectOptions = (selectOptions: OptionType[] | null) => {
  if (!selectOptions) {
    return [];
  }
  return selectOptions.map((option: OptionType) =>
    transformSelectOption(option)
  );
};

export const findLabelValue = ({
  collection,
  lookupKey = 'value',
  lookupValue,
  labelKey = 'label',
}: {
  collection: any;
  lookupKey?: string;
  lookupValue: any;
  labelKey?: string;
}) => result(find(collection, [lookupKey, lookupValue]), labelKey, '');

export const createUserOption = (userData: User | undefined) =>
  userData
    ? {
        value: userData.id,
        label: `${userData.email}${
          userData.status === UserStatus.Inactive ? ' (inactive)' : ''
        }`,
      }
    : null;

export const removeEmailDomain = (email: string) =>
  email.replace(/@[^@]+$/, '');

export function groupBy<TItem>(
  entries: TItem[],
  key: string
): { [key: string]: TItem[] } {
  return entries.reduce(
    (acc: { [key: string]: TItem[] }, x: any) => ({
      ...acc,
      [x[key]]: [...(acc[x[key]] || []), x],
    }),
    {}
  );
}

export function mapValues<TItem>(values: { [key: string]: TItem[] }[]) {
  return Object.entries(values).reduce(
    (acc: { [key: string]: TItem[] }, [key, arr]: [string, any]) => ({
      ...acc,
      [key]: arr.map((item: any) => item.value),
    }),
    {}
  );
}

export const dedupeValues = (values: { [key: string]: any }[]) =>
  values
    ? [
        ...new Set(
          values.map((value: { [key: string]: any }) => JSON.stringify(value))
        ),
      ].map((value: any) => JSON.parse(value))
    : [];

/* eslint-disable consistent-return */
export const mergeArrays = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return [...objValue, ...srcValue];
  }
};

export const calculateTotalCampaignsObjectiveWithoutCurrentCampaign = ({
  currentCampaignId,
  allCampaigns,
}: {
  currentCampaignId?: string;
  allCampaigns: Campaign[];
}) =>
  allCampaigns.reduce((total: number, { id, objective }) => {
    if (!!currentCampaignId && currentCampaignId === id) {
      return total;
    }
    const newTotal = total + (objective || 0);
    return newTotal;
  }, 0);

export const getCampaignDelivery = (
  status: CampaignStatus | undefined,
  estimatedCompletion: number | null,
  impressions: number,
  objective: number | null
) => {
  if (status === CampaignStatus.Completed) {
    return objective
      ? `${numbro(divideTwoNumbers(impressions, objective)).format(
          numbroWholePercentageFormatting
        )} Delivered`
      : '';
  }
  return estimatedCompletion === null
    ? ''
    : `${convertNumberToPercentage(estimatedCompletion)} Estimated`;
};

export const getLocale = (stationLanguage: string, country: string) => {
  if (stationLanguage !== '' && country !== '') {
    return `${stationLanguage}-${country}`;
  }
  if (stationLanguage === '' && country !== '') {
    return `und-${country}`;
  }
  if (stationLanguage !== '' && country === '') {
    return stationLanguage;
  }
  return null;
};

export const getStationLanguage = (locale: string | null) => {
  if (locale && locale.includes('-')) {
    const language = locale.substring(0, locale.indexOf('-'));
    if (language === 'und') {
      return StationLanguageEnum.Unspecified;
    }
    return language;
  }
  return locale || StationLanguageEnum.Unspecified;
};

export const getCountry = (locale: string | null) => {
  if (locale && locale.includes('-')) {
    return locale.substring(locale.indexOf('-') + 1, locale.length);
  }
  return OriginatingCountryEnum.Unspecified;
};

export const transformTrackingUrls = (trackingUrls: TrackingUrl[]) =>
  trackingUrls
    .filter((value: TrackingUrl) => !!value.url || !!value.consentVendorId)
    .map((value) => ({
      url: value.url || null,
      consentVendorId: value.consentVendorId
        ? parseFormattedValue(value.consentVendorId)
        : null,
    }));

export const memoizeData = memoizeOne((data: any) => data);

export const getTargetingRestrictionsAsString = (
  restrictions: TargetingRestriction[]
) =>
  getTargetingRestrictionsOptions()
    .filter((option) =>
      restrictions.includes(option.value as TargetingRestriction)
    )
    .map((option) => option.label)
    .join(', ');
