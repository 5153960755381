import useStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  SuffixTypes,
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import TargetingRestrictions from 'components/TargetingRestrictions/TargetingRestrictions';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  channelTypes,
  IChannelValues,
  inventoryTypes,
  originatingCountryTypes,
  stationLanguageTypes,
  vastVersions,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';
import { Field } from 'formik';
import {
  fieldToSwitch,
  RadioGroup,
  Switch,
  SwitchProps,
  TextField,
} from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import { TargetingRestriction } from 'interfaces/generated.types';
import React from 'react';
import dateUtils from 'utils/date';
import { parseFormattedValue } from 'utils/numbers';
import { setTargetingRestrictions } from 'utils/targetingRestrictions';
import { getTerritoryValues } from 'utils/territory';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import MuiSwitch from '@material-ui/core/Switch';
import LaunchIcon from '@material-ui/icons/Launch';

import {
  channelTargetingRestrictionsOptions,
  GeneralRestriction,
} from './channelTargetingRestrictionsOptions';

interface IChannelDetailsProps {
  update: boolean;
}

export const handleCheckboxChange = ({
  event,
  vastVersionArray,
  setFieldValue,
}: {
  event: React.ChangeEvent<HTMLInputElement>;
  vastVersionArray: string[];
  setFieldValue: (field: string, value: any) => void;
}) => {
  const {
    target: { value, checked },
  } = event;

  let newArray: string[] = [];
  if (checked) {
    newArray = [...vastVersionArray, value];
  } else {
    newArray = vastVersionArray.filter((arrayValue) => arrayValue !== value);
  }
  setFieldValue('vastVersion', newArray);
};

export const ExternalIdSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('externalDealId', '');
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

const ChannelDetails = (
  props: IFormProps<IChannelValues> & IChannelDetailsProps
) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    update = false,
    status = {},
    setFieldValue,
    setFieldTouched,
  } = props;

  const classes = useStyles();

  const handleChannelRestrictionChange = ({
    target: { value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value === GeneralRestriction) {
      setFieldValue('includedInDefaultInventoryTargeting', checked);
    } else {
      setTargetingRestrictions(
        value as TargetingRestriction,
        checked,
        setFieldValue,
        values.targetingRestrictions
      );
    }
  };

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Publisher Name"
              placeholder="Publisher Name"
              name="publisherName"
              id="publisherName"
              data-tc="publisherNameField"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/publisher/${values.publisherId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="channelPublisherLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.publisherName}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Channel Name"
            placeholder="Channel Name"
            data-tc="channelNameField"
            name="name"
            id="name"
            autoFocus={!update}
            fullWidth
            inputProps={{ 'data-testid': 'channelName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Channel ID"
              placeholder="Channel ID"
              data-tc="channelIdField"
              name="altId"
              id="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}

        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Originating Country"
            name="country"
            id="country"
            data-tc="countryDropdown"
            fullWidth
            helperText={status.country}
            FormHelperTextProps={{
              error: !!status.country,
            }}
          >
            {originatingCountryTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Inventory Type"
            name="inventoryType"
            id="inventoryType"
            fullWidth
            helperText={
              (touched.inventoryType && errors.inventoryType) ||
              status.inventoryType
            }
            FormHelperTextProps={{
              error: !!(
                (touched.inventoryType && errors.inventoryType) ||
                status.inventoryType
              ),
            }}
          >
            {inventoryTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Station Language"
            name="stationLanguage"
            id="stationLanguage"
            data-tc="stationLanguageDropdown"
            fullWidth
            helperText={status.stationLanguage}
            FormHelperTextProps={{
              error: !!status.stationLanguage,
            }}
          >
            {stationLanguageTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="Deal Id"
            placeholder="Deal Id"
            data-tc="externalDealId"
            name="externalDealId"
            id="externalDealId"
            fullWidth
            disabled={!values.hasExternalDealId}
            inputProps={{ 'data-testid': 'externalDealId' }}
            helperText={
              (touched.externalDealId && errors.externalDealId) ||
              status.externalDealId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalDealId && errors.externalDealId) ||
                status.externalDealId
              ),
            }}
          />
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              data-tc="formControlDealId"
              control={
                <Field
                  component={ExternalIdSwitch}
                  type="checkbox"
                  name="hasExternalDealId"
                  data-tc="hasExternalDealId"
                />
              }
              label="Add Deal Id"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="channelFloorCpm"
            decimalScale={2}
            error={errors.floorCpm || status.floorCpm}
            label="Floor CPM"
            name="floorCpm"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.floorCpm || !!status.floorCpm}
            value={values.floorCpm}
            fullWidth
          />
          {parseFormattedValue(values.floorCpm) > 100 && (
            <FormHelperText error data-tc="floorCpmOverHundredWarning">
              Floor CPM over 100
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="timeZoneField"
            fullWidth
            label="Time Zone"
            name="timeZone"
            id="timeZone"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={status.timeZone}
            FormHelperTextProps={{
              error: !!status.timeZone,
            }}
          >
            {dateUtils.timezoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="channelTrafficAcceptancePercentage"
            decimalScale={2}
            suffix={SuffixTypes.percentage}
            error={
              errors.trafficAcceptancePercentage ||
              status.trafficAcceptancePercentage
            }
            label="Traffic Acceptance Percentage"
            name="trafficAcceptancePercentage"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={
              touched.trafficAcceptancePercentage ||
              !!status.trafficAcceptancePercentage
            }
            value={values.trafficAcceptancePercentage}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Default RTB Publisher Type"
            data-tc="publisherTypeDropdown"
            name="type"
            id="type"
            fullWidth
            helperText={status.type}
            FormHelperTextProps={{
              error: !!status.type,
            }}
          >
            {channelTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="channelMinAdDuration"
            decimalScale={3}
            error={errors.minAdDuration || status.minAdDuration}
            label="Min Ad Duration (Seconds)"
            name="minAdDuration"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.minAdDuration || !!status.minAdDuration}
            value={values.minAdDuration}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="channelMaxAdDuration"
            decimalScale={3}
            error={errors.maxAdDuration || status.maxAdDuration}
            label="Max Ad Duration (Seconds)"
            name="maxAdDuration"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.maxAdDuration || !!status.maxAdDuration}
            value={values.maxAdDuration}
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <FormLabel
              classes={{ root: classes.radioLabel }}
              component={'legend' as 'span'}
              className={classes.radioLabel}
            >
              Default VAST version
            </FormLabel>
            <Field
              component={RadioGroup}
              name="vastVersion"
              id="vastVersion"
              data-tc="vastVersion"
              row
            >
              {vastVersions.map((type) => (
                <FormControlLabel
                  data-tc="vastVersionRadio"
                  key={type.value}
                  value={type.value}
                  control={
                    <Radio
                      classes={{
                        root: classes.radioButton,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label={type.label}
                />
              ))}
            </Field>
            {((touched.vastVersion && errors.vastVersion) ||
              status.vastVersion) && (
              <FormHelperText data-tc="vastVersionError" error>
                {errors.vastVersion || status.vastVersion}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            component={'fieldset' as 'div'}
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Field
                  component={Switch}
                  type="checkbox"
                  name="overrideConsentSignal"
                  color="primary"
                  id="overrideConsentSignal"
                  data-tc="overrideConsentSignal"
                />
              }
              label="Override Consent Signal"
            />
          </FormControl>
          <MultiSelect
            id="territories"
            options={getTerritoryValues(values.publisherTerritories || []).map(
              (territoryOption) =>
                territories?.includes(territoryOption.value)
                  ? territoryOption
                  : { ...territoryOption, readOnly: true }
            )}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue(
                'territories',
                fieldValue.filter(
                  (territoryOption: OptionType) => !territoryOption.readOnly
                )
              );
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
        <Grid item xs={12}>
          <TargetingRestrictions
            title="Include channel in the following targeting"
            restrictionsList={channelTargetingRestrictionsOptions}
            handleChange={handleChannelRestrictionChange}
            selectedRestrictions={[
              ...values.targetingRestrictions,
              values.includedInDefaultInventoryTargeting
                ? GeneralRestriction
                : '',
            ]}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default ChannelDetails;
