import React from 'react';

import { IFormProps } from 'interfaces';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

import TargetingMultiSelect from '../TargetingMultiSelect/TargetingMultiSelect';
import { targetingGenderValues } from '../TargetingParameters/TargetingParametersValues';

export interface ITargetingGenderProps {
  defaultParameter?: boolean;
}

export const genderValues = {
  genderParams: {
    genders: [],
  },
};

export const defaultGenderValues = {
  genderParams: {
    gender: { value: '', label: '', readOnly: false },
  },
};

const TargetingGender = (
  props: IFormProps<any> & ITargetingComponentProps & ITargetingGenderProps
) => {
  const { defaultParameter = false } = props;

  return (
    <TargetingMultiSelect
      {...props}
      label="Gender"
      targetingName={`${
        defaultParameter ? 'genderParams.gender' : 'genderParams.genders'
      }`}
      options={targetingGenderValues}
      isMulti={!defaultParameter}
      selectAll={!defaultParameter}
    />
  );
};

export default TargetingGender;
