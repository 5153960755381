import { UPDATE_ACTIVE_TERRITORY } from 'context/SessionProvider/reducer';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { Territory } from 'interfaces/generated.types';
import * as React from 'react';
import { getTerritoryIcon, TerritoryLabel } from 'utils/territory';

import { Grid, IconButton, Typography } from '@material-ui/core';

import useStyles from './TerritorySelect.styles';

interface ITerritorySelectProps {
  setIsModalOpen: (open: boolean) => void;
}

const TerritorySelect = ({ setIsModalOpen }: ITerritorySelectProps) => {
  const {
    state: {
      user: { territories },
    },
    dispatch,
  } = useSessionContext();

  const updateActiveTerritory = (territory: Territory) => () => {
    dispatch({
      type: UPDATE_ACTIVE_TERRITORY,
      payload: territory,
    });

    setIsModalOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      {territories?.map((territory) => (
        <React.Fragment key={territory}>
          <Grid xs={4} container direction="column">
            <IconButton
              data-testid={`${territory}-button`}
              key={territory}
              onClick={updateActiveTerritory(territory)}
              className={classes.iconButton}
            >
              <div className={classes.svgWrapper}>
                {getTerritoryIcon(territory, classes.svg)}
              </div>
              <span className="sr-only">{territory}</span>
            </IconButton>

            <Typography align="center">{TerritoryLabel[territory]}</Typography>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default TerritorySelect;
