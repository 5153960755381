import cx from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './WarningDialog.styles';

export interface ISetWarningModal {
  hasWarning: boolean;
  handleWarningContinue: () => void;
  handleWarningCancel?: () => void;
}

interface IWarningDialog {
  dataTc: string;
  isOpen: boolean;
  handleClose: () => void;
  handleContinue: () => void;
  warningMessage?: string;
}

export const handleWarning = ({
  setWarningModal,
  handleContinue,
  handleCancel,
}: {
  setWarningModal: (values: ISetWarningModal) => void;
  handleContinue: () => void;
  handleCancel?: () => void;
}) => {
  setWarningModal({
    hasWarning: true,
    handleWarningContinue: handleContinue,
    handleWarningCancel: handleCancel,
  });
};

const WarningDialog = ({
  dataTc,
  isOpen,
  handleClose,
  handleContinue,
  warningMessage,
}: IWarningDialog) => {
  const classes = useStyles({});
  return (
    <Dialog
      data-tc={`${dataTc}`}
      data-testid="warningDialog"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline">Warning</Typography>
        <IconButton
          onClick={handleClose}
          classes={{ root: classes.closeButton }}
          data-tc={`${dataTc}CloseX`}
        >
          <CloseIcon />
          <span className="sr-only">Close the dialog</span>
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.content }}
        data-tc={`${dataTc}Content`}
      >
        <p>
          {warningMessage ||
            'This action will affect other entities associated with this one.'}
        </p>
        <p>Are you sure you would like to make this change?</p>
      </DialogContent>
      <DialogActions
        classes={{
          root: cx(classes.actions, {
            [`${classes.actions}--space-between`]: true,
          }),
        }}
      >
        <StyledButton
          onClick={handleClose}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}CloseButton`}
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            handleContinue();
            handleClose();
          }}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Contained}
          data-tc={`${dataTc}ContinueButton`}
        >
          Continue
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
