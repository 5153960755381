import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateSalesChannelContainer from 'features/programmatic/salesChannel/containers/UpdateSalesChannelContainer/UpdateSalesChannelContainer';
import { History } from 'history';
import {
  PermissionName,
  SalesChannel,
  useAllUsersQuery,
  User,
  useSalesChannelQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import {
  allTerritories,
  isActiveTerritoryAssignedToEntity,
} from 'utils/territory';

interface UpdateSalesChannelPageProps {
  history: History;
  match: {
    params: {
      salesChannelId: string;
    };
  };
  userPermissions: PermissionName[];
}

const salesChannelEditPermissions = [
  PermissionName.Primary,
  PermissionName.Programmatic,
];

const UpdateSalesChannelPage = ({
  history,
  match,
  userPermissions,
}: UpdateSalesChannelPageProps) => {
  const { loading, data } = useSalesChannelQuery({
    variables: { id: match.params.salesChannelId },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, salesChannelEditPermissions) &&
    isActiveTerritoryAssignedToEntity(
      [data?.salesChannel?.territory!],
      activeTerritory!
    );

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Programmatic, PermissionName.Primary],
      territories: allTerritories,
    },
    skip: !hasEditPermissions,
  });

  if (!loading && !data?.salesChannel) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Sales Channel</h1>
        {loading || loadingAllUsers ? (
          <Loader />
        ) : (
          <UpdateSalesChannelContainer
            history={history}
            match={match}
            salesChannel={data?.salesChannel as SalesChannel}
            hasEditPermissions={hasEditPermissions}
            allUsers={(allUsersData?.allUsers as User[]) || []}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateSalesChannelPage;
