import { useEffect, useState } from 'react';

import utils from './utils';

interface IUseCsvParser {
  data: any[] | null;
  error: string | null;
  setFile: (file: File) => void;
  file: File | null;
}

export default function useCsvParser({
  header = false,
  delimiter = ',',
}: {
  header: boolean;
  delimiter: string;
}): IUseCsvParser {
  const [file, setFile] = useState<null | File>(null);
  const [error, setError] = useState<null | string>(null);
  const [data, setData] = useState<null | any>(null);

  useEffect(() => {
    if (!file) return;

    const parseCsv = async () => {
      try {
        const parsedCsv = await utils.parseFile({ file, header, delimiter });
        setData(parsedCsv);
      } catch (err: any) {
        setError(err || utils.defaultErrorMessage);
      }
    };

    parseCsv();
  }, [file, header, delimiter]);

  return { data, error, setFile, file };
}
