import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, ReactElement, Ref } from 'react';

import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@material-ui/core';

import useStyles from './Checkbox.styles';

export type CheckboxProps = MUICheckboxProps;

const Checkbox = forwardRef(
  (props: CheckboxProps, ref: Ref<any>): ReactElement => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUICheckbox classes={classes} ref={ref} {...otherProps} />;
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
