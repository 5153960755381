import React, { ReactNode } from 'react';

import Typography from '@material-ui/core/Typography';

import useStyles from './CookieName.styles';

const CookieName = ({ Icon, label }: { Icon: ReactNode; label: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.cookieNameContainer}>
      {Icon}
      <Typography variant="body1" color="textPrimary">
        {label}
      </Typography>
    </div>
  );
};

export default CookieName;
