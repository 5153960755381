import AssumedUserIdHeader from 'components/AssumedUserIdHeader/AssumedUserIdHeader';
import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { useUserContext } from 'context/UserProvider/UserProvider';
import PrimaryControlsMessages from 'features/primaryControls/containers/PrimaryControlsMessages/PrimaryControlsMessages';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import { PermissionName } from 'interfaces/generated.types';
import React from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { AuthState } from 'utils/authProvider';
import {
  userHasAllPermissions,
  userHasAtLeastOnePermission,
} from 'utils/permissions';

const handleAuthorization = (
  Component: React.ElementType<any>,
  permittedPermissions: PermissionName[],
  permittedPermissionCombinations?: PermissionName[][]
) =>
  function ProtectedComponent(props: RouteChildrenProps) {
    const { state: authState } = useAuthContext();
    const { fetchingUserDetails, ...userDetails } = useUserContext();
    const userPermissions = useUserPermissions();

    const {
      state: { assumedId },
    } = useCustomApolloContext();

    if (fetchingUserDetails || authState === AuthState.Fetching)
      return <Loader />;

    if (authState !== AuthState.LoggedIn) return <Redirect to="/" />;

    const hasAuthorizedCombinations = permittedPermissionCombinations
      ? permittedPermissionCombinations.some((combination) =>
          userHasAllPermissions(userPermissions, combination)
        )
      : false;
    const isAuthorized =
      userHasAtLeastOnePermission(userPermissions, permittedPermissions) ||
      hasAuthorizedCombinations;

    if (isAuthorized) {
      return (
        <>
          {assumedId && <AssumedUserIdHeader />}
          <Header />
          <PrimaryControlsMessages />
          <Component
            {...props}
            userPermissions={userPermissions}
            currentUser={userDetails}
          />
        </>
      );
    }

    return <Redirect to="/error/403" />;
  };

export default handleAuthorization;
