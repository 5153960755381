import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';

import { IDspFormValues } from 'features/programmatic/dsp/components/DspTabsForm/DspFormValues';
import DspTabsForm, {
  dspButtons,
} from 'features/programmatic/dsp/components/DspTabsForm/DspTabsForm';
import DspFormValidation from 'features/programmatic/dsp/validations/dsps';

import useError from 'hooks/Error/useError';

import {
  CreateDspMutation,
  CreateDspMutationFn,
  Dsp,
  TechnicalProvider,
  Territory,
  useCreateDspMutation,
} from 'interfaces/generated.types';

import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    technicalProviderId: string;
  };
}
interface ICreateDspContainerProps {
  history: History;
  match: IMatch;
  technicalProvider: TechnicalProvider;
}

export const handleOnComplete = (
  response: CreateDspMutation,
  history: History,
  selectedJourney: string,
  technicalProviderId: string
) => {
  const { createDsp } = response;
  const { id } = createDsp as Dsp;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: technicalProviderId,
    listEntity:
      selectedJourney === '/programmatic-connector/PARENT_ID' ? 'Dsp' : '',
  });
};

export const submitForm = (
  createDsp: CreateDspMutationFn,
  match: IMatch,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IDspFormValues,
  { setStatus, setSubmitting }: FormikActions<IDspFormValues>
) => {
  setSelectedJourney(formValues.journey);

  const formattedValues = formatValues(formValues);

  const filteredValues: any = pickBy({
    ...formValues,
    journey: null,
    technicalProviderId: match.params.technicalProviderId,
    technicalProviderName: null,
    technicalProviderTerritories: null,
    dailyCap: formattedValues.dailyCap,
    territories: formattedValues.territories,
  });

  createDsp({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateDspContainer = ({
  history,
  match,
  technicalProvider,
}: ICreateDspContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the DSP.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createDsp] = useCreateDspMutation({
    onCompleted(data) {
      handleOnComplete(
        data,
        history,
        selectedJourney,
        match.params.technicalProviderId
      );
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IDspFormValues>
        initialValues={{
          name: '',
          id: '',
          dailyCap: '',
          maxAds: 1,
          journey: dspButtons[0].url,
          technicalProviderName: technicalProvider.name as string,
          technicalProviderId: technicalProvider.id,
          territories: getTerritoryValues(technicalProvider.territories) || [],
          technicalProviderTerritories: getTerritoryValues(
            (technicalProvider.territories as Territory[]) || []
          ),
        }}
        validationSchema={DspFormValidation}
        onSubmit={submitForm(
          createDsp,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={(props) => (
          <DspTabsForm
            {...props}
            goBackTo={{
              pathname: `/programmatic-connector/${match.params.technicalProviderId}`,
              state: location.state?.parent || {},
            }}
          />
        )}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createDsp"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateDspContainer;
