import { ReactComponent as ArrowIcon } from 'assets/icons/dax-rightarrowicon.svg';
import classNames from 'classnames';
import { PublisherInsightsFilterCategory } from 'interfaces/generated.types';
import React from 'react';

import { IconButton, Typography } from '@material-ui/core';

import useStyles from './CategoryItem.styles';

interface ICategoryItemProps {
  filterCategory: PublisherInsightsFilterCategory;
  handleClick: (value: PublisherInsightsFilterCategory) => void;
  isCategoryClicked: boolean;
  valuesCount: any;
}

const CategoryItem = ({
  filterCategory,
  handleClick,
  isCategoryClicked,
  valuesCount,
}: ICategoryItemProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.filterItem}
      onClick={() => handleClick(filterCategory)}
      disableRipple
    >
      <ArrowIcon
        data-testid="arrowIcon"
        className={classNames([
          classes.icon,
          {
            [`${classes.icon}--down`]: isCategoryClicked,
          },
        ])}
      />
      <Typography>
        {filterCategory.displayName}
        {valuesCount > 0 && ` (${valuesCount})`}
      </Typography>
    </IconButton>
  );
};

export default CategoryItem;
