import debounce from 'debounce-promise';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';

import { SEARCH_ISPS } from 'features/targeting/graphql/isps/queries';

import { IFormProps } from 'interfaces';
import { Isp } from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

import { TargetingAsyncMultiSelect } from '../TargetingMultiSelect/TargetingMultiSelect';

export const ispValues = {
  ispParams: {
    isps: [],
  },
};

const formatData = (data: Isp[]) =>
  data.map((isp: Isp) => ({
    label: isp.name,
    value: isp.id,
  }));

export const loadOptions = async (
  value: string,
  client: any,
  setFetchedOptions: any
) => {
  if (value.length >= 2) {
    const { data } = await client.query({
      query: SEARCH_ISPS,
      variables: { text: value },
    });

    if (data) {
      const formattedData = formatData(data.searchIsps);
      setFetchedOptions(formattedData);
      return formattedData;
    }

    return [];
  }

  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 10, {
  leading: true,
});

const TargetingIsps = (props: IFormProps<any> & ITargetingComponentProps) => {
  const [fetchedOptions, setFetchedOptions] = useState([]);

  return (
    <ApolloConsumer>
      {(client) => (
        <TargetingAsyncMultiSelect
          {...props}
          label="ISP"
          placeholder="Begin typing to find a ISP"
          targetingName="ispParams.isps"
          loadOptions={(value: string) =>
            debouncedLoadOptions(value, client, setFetchedOptions)
          }
          fetchedOptions={fetchedOptions}
        />
      )}
    </ApolloConsumer>
  );
};

export default TargetingIsps;
