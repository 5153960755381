import { useState } from 'react';

interface IWarningState {
  hasWarning: boolean;
  handleWarningContinue: () => void;
  handleWarningCancel?: () => void;
}

export default function useWarning() {
  const [
    { hasWarning, handleWarningContinue, handleWarningCancel },
    setWarningModal,
  ] = useState<IWarningState>({
    hasWarning: false,
    handleWarningContinue: () => {},
  });
  const toggleWarningModal = () => {
    handleWarningCancel && handleWarningCancel();
    setWarningModal((prevState: IWarningState) => ({
      ...prevState,
      hasWarning: !prevState.hasWarning,
    }));
  };
  return {
    hasWarning,
    handleWarningContinue,
    handleWarningCancel,
    toggleWarningModal,
    setWarningModal,
  };
}
