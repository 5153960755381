export interface CustomApolloState {
  assumedId: string;
  assumedEmail: string;
  updatingUserWithAssumedDetails: boolean;
}

const initialCustomApolloState: CustomApolloState = {
  assumedId: '',
  assumedEmail: '',
  updatingUserWithAssumedDetails: false,
};

export default initialCustomApolloState;
