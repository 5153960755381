import React, { useState } from 'react';
import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { EntityType } from 'interfaces/generated.types';
import { Entity } from 'interfaces/types';
import { ReactComponent as InfoIcon } from 'assets/icons/dax-infoicon.svg';

import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import useStyles from './Contract.styles';

export interface IContractActivityModalProps {
  contractId: string;
  contractType: Entity | undefined;
  testId: string;
}

const ContractActivityModal = ({
  contractId,
  contractType,
  testId,
}: IContractActivityModalProps) => {
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);

  const classes = useStyles({});

  return (
    <>
      <IconButton
        onClick={() => setIsActivityModalOpen(true)}
        data-testid={`${testId}-infoButton`}
        className={classes.iconButton}
        disableRipple
      >
        <div className={classes.svgWrapper}>
          <InfoIcon className={classes.svg} />
        </div>
        <span className="sr-only">View contact activity</span>
      </IconButton>
      {isActivityModalOpen && (
        <Dialog
          data-testid={`${testId}-ActivityModal`}
          open={isActivityModalOpen}
          onClose={() => setIsActivityModalOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle classes={{ root: classes.modalTitle }} disableTypography>
            <Typography variant="h6">Contract Activity</Typography>
            <IconButton
              onClick={() => setIsActivityModalOpen(false)}
              data-testid={`${testId}-ActivityModalCloseButton`}
            >
              <CloseIcon />
              <span className="sr-only">Close the contract activity modal</span>
            </IconButton>
          </DialogTitle>
          {contractType ? (
            <ActivityTable
              entityType={EntityType[contractType]}
              id={contractId}
              data-tc={`${testId}-ActivityTable`}
            />
          ) : (
            <Typography variant="body1">
              No activity could be retrieved for this contract type
            </Typography>
          )}
        </Dialog>
      )}
    </>
  );
};

export default ContractActivityModal;
