import { Territory } from 'interfaces/generated.types';
import React, { ReactElement } from 'react';
import { getEnabledTerritories } from 'utils/territory';

import { InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './TerritoryDropdown.styles';
import TerritoryDropdownOption from './TerritoryDropdownOption';

export interface ITerritoryDropdownProps {
  label: string;
  isMultiSelect?: boolean;
  value: Territory | Territory[] | undefined;
  options: Territory[];
  disabledOptions?: Territory[];
  customOnChange?: (fieldValue: any) => void;
}

export const renderSelectedTerritories = (
  options: Territory[],
  placeholder?: ReactElement
) => {
  if (options.length === 0) return placeholder || null;

  return options.map((item) => (
    <TerritoryDropdownOption key={item} territory={item} />
  ));
};

const TerritoryDropdown = ({
  label,
  isMultiSelect = false,
  value,
  options,
  disabledOptions,
  customOnChange,
}: ITerritoryDropdownProps) => {
  const classes = useStyles();

  const placeholder = <div>Select</div>;

  const getSelectedRenderFunction = () => {
    const selectedOptions =
      isMultiSelect && value ? (value as Territory[]) : [];
    const enabledOptions = disabledOptions
      ? getEnabledTerritories(selectedOptions, disabledOptions)
      : selectedOptions;

    return isMultiSelect
      ? () => renderSelectedTerritories(enabledOptions, placeholder)
      : () => <TerritoryDropdownOption territory={value as Territory} />;
  };

  const customRenderValue =
    value && value.length > 0 ? getSelectedRenderFunction() : () => placeholder;

  const isCheckboxChecked = (item: Territory) =>
    isMultiSelect ? (value as Territory[]).indexOf(item) > -1 : item === value;

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (customOnChange) customOnChange(event.target.value);
  };

  return (
    <>
      <InputLabel id={`territory-dropdown-${label}`}>{label}</InputLabel>
      <Select
        value={value}
        classes={{
          root: classes.dropdown,
        }}
        labelId={`territory-dropdown-${label}`}
        multiple={isMultiSelect}
        onChange={handleChange}
        IconComponent={ExpandMore}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        renderValue={customRenderValue}
        displayEmpty
      >
        {options.map((territory) => (
          <MenuItem
            key={territory}
            value={territory}
            data-testid={`dropdownOption-${territory}`}
            className={classes.dropdownOption}
            disabled={disabledOptions && disabledOptions.includes(territory)}
          >
            <TerritoryDropdownOption
              territory={territory}
              showCheckbox
              isCheckboxChecked={isCheckboxChecked(territory)}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default TerritoryDropdown;
