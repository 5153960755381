import { baseFontStyles } from 'assets/styles/components/Font.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  title: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  footer: {
    justifySelf: 'flex-end',
    margin: theme.spacing(1),
  },
}));

export default useStyles;
