import classNames from 'classnames';
import React from 'react';
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';

import useStyles from './TargetingDayTimeCell.styles';

export interface ITargetingDayTimeCellProps {
  day: string;
  hour: number;
  selected: boolean;
  readOnly: boolean;
}

const TargetingDayTimeCell = (
  props: TSelectableItemProps & ITargetingDayTimeCellProps
) => {
  const classes: any = useStyles();

  const { selectableRef, isSelecting, day, hour, selected, readOnly } = props;

  return (
    <div
      ref={selectableRef}
      className={classes.selectableCell}
      key={`${day}-${hour}`}
      data-tc="targetingDayTimeCell"
    >
      <span
        className={classNames(classes.styledCheckbox, {
          [`${classes.styledCheckbox}--checked`]: selected,
          [`${classes.styledCheckbox}--selecting`]: isSelecting,
          [`${classes.styledCheckbox}--disabled`]: readOnly,
        })}
      />
    </div>
  );
};

export default createSelectable(TargetingDayTimeCell);
