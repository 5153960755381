import axios from 'axios';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import FormButtons from 'components/FormButtons/FormButtons';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import CreativeTabs from 'features/direct/creative/components/CreativeTabs/CreativeTabs';
import {
  ICreativeAudio,
  ICreativeImage,
} from 'features/direct/creative/graphql/queries';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFormProps, OptionType } from 'interfaces';
import { Territory } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import creativeUtils from 'utils/creatives';
import { TerritoryLabel } from 'utils/territory';

import FormHelperText from '@material-ui/core/FormHelperText';

import useStyles from './CreativeForm.styles';
import { CreativeStatusEnum } from './CreativeForm.values';

export interface ICreativeFormValues {
  advertiser: OptionType | null;
  allAdvertisers: OptionType[];
  audioCreatives: ICreativeAudio[];
  imageCreatives: ICreativeImage[];
  territory: Territory;
}

interface ICreativeProps {
  history: History;
}

const reflect = (p: Promise<any>) =>
  p.then(
    (payload) => ({ payload, isSuccessful: true }),
    (error) => ({ error, isSuccessful: false })
  );

const uploadCreativesToS3 = ({
  creatives,
  client,
  advertiserId,
}: {
  advertiserId: string;
  creatives: any;
  client: any;
}) =>
  creatives.map(async (creative: any) => {
    const {
      url,
      assetUrl,
      contentType,
    } = await creativeUtils.generateCreativeUrl({
      advertiserId,
      file: creative.file,
      client,
    });

    const options = {
      headers: {
        'Content-Type': contentType,
        'Content-Disposition': `attachment; filename=${creative.file.name};`,
      },
    };

    const uploadToS3 = await axios.put(url, creative.file, options);
    if (uploadToS3.status === 200) {
      return {
        ...creative,
        status: CreativeStatusEnum.NewCreativeUploaded,
        url: assetUrl,
      };
    }
    return Promise.reject(new Error('Failed to Upload'));
  });

export const handleReupload = async ({
  audioCreatives,
  imageCreatives,
  setFieldValue,
  client,
  advertiserId,
  handleModalErrors,
}: {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  audioCreatives: any[];
  imageCreatives: any[];
  client: any;
  advertiserId: string;
  handleModalErrors: (errors: string[]) => void;
}) => {
  if (!audioCreatives.length && !imageCreatives.length) return;

  const startUploadingAudio = audioCreatives.map((audio) => ({
    ...audio,
    status: CreativeStatusEnum.Uploading,
  }));
  const startUploadingImages = imageCreatives.map((creative) => ({
    ...creative,
    status: CreativeStatusEnum.Uploading,
  }));

  if (startUploadingAudio.length) {
    setFieldValue('audioCreatives', startUploadingAudio);
  }
  if (startUploadingImages.length) {
    setFieldValue('imageCreatives', startUploadingImages);
  }

  const uploadAudioToS3 = uploadCreativesToS3({
    creatives: startUploadingAudio,
    client,
    advertiserId,
  }).map(reflect);
  const uploadImagesToS3 = uploadCreativesToS3({
    creatives: startUploadingImages,
    client,
    advertiserId,
  }).map(reflect);

  const uploadedAudioToS3 = await Promise.all(uploadAudioToS3);
  const uploadedImagesToS3 = await Promise.all(uploadImagesToS3);

  const uploadedAudioSuccessfully = uploadedAudioToS3
    .filter((resolved: any) => resolved.isSuccessful)
    .map((resolved: any) => resolved.payload);
  const uploadedImagesSuccessfully = uploadedImagesToS3
    .filter((resolved: any) => resolved.isSuccessful)
    .map((resolved: any) => resolved.payload);
  const uploadedAudioErrors = uploadedAudioToS3.find(
    (resolved: any) => !resolved.isSuccessful
  );
  const uploadedImagesErrors = uploadedImagesToS3.find(
    (resolved: any) => !resolved.isSuccessful
  );

  if (uploadedAudioErrors || uploadedImagesErrors) {
    handleModalErrors([
      'Something went wrong and one or more creatives could not be uploaded. Please try again later.',
    ]);
  }

  setFieldValue('audioCreatives', uploadedAudioSuccessfully);
  setFieldValue('imageCreatives', uploadedImagesSuccessfully);
};

const CreativeForm = (
  props: IFormProps<ICreativeFormValues> & ICreativeProps
) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldTouched,
    setFieldValue,
    history,
    status = {},
  } = props;

  const classes = useStyles();
  const [oldAdvertiser, setOldAdvertiser] = useState(null as OptionType | null);
  const {
    hasError,
    setErrorModal,
    toggleErrorModal,
    errorMessages,
    setErrorMessages,
  } = useError([]);

  const handleModalErrors = (modalErrors: string[]) => {
    setErrorMessages(modalErrors);
    setErrorModal(true);
  };

  const isUploadingCreatives =
    values.imageCreatives.some(
      (creative) => creative.status === CreativeStatusEnum.Uploading
    ) ||
    values.audioCreatives.some(
      (creative) => creative.status === CreativeStatusEnum.Uploading
    );

  const location = usePreviousLocation();

  return (
    <ApolloConsumer>
      {(client: any) => (
        <>
          <Form onSubmit={handleSubmit}>
            <fieldset className={classes.fieldset}>
              <legend className={classes.legend} data-tc="creativeLegend">
                Upload creatives
              </legend>
              <div className={classes.formWrapper}>
                <div className={classes.multiSelect}>
                  <MultiSelect
                    id="creativesAdvertiser"
                    isDisabled={isUploadingCreatives}
                    isMulti={false}
                    selectAll={false}
                    label="Advertiser"
                    name="advertiser"
                    onBlur={() => setFieldTouched('advertiser', true)}
                    onChange={(fieldValue: OptionType | null) => {
                      if (fieldValue) {
                        if (
                          oldAdvertiser &&
                          oldAdvertiser.value !== fieldValue.value
                        ) {
                          handleReupload({
                            advertiserId: fieldValue.value,
                            audioCreatives: values.audioCreatives,
                            client,
                            handleModalErrors,
                            imageCreatives: values.imageCreatives,
                            setFieldValue,
                          });
                        }
                        setOldAdvertiser(fieldValue);
                      }
                      setFieldValue('advertiser', fieldValue);
                    }}
                    options={values.allAdvertisers}
                    placeholder="Select an advertiser..."
                    value={values.advertiser}
                    attributes={{
                      fieldAttribute: 'advertiserField',
                      menuAttribute: 'advertiserMenuOptions',
                      chipAttribute: 'advertiserChip',
                    }}
                    errorProps={{
                      helperText: touched.advertiser && errors.advertiser,
                      FormHelperTextProps: {
                        error: !!(touched.advertiser && errors.advertiser),
                      },
                    }}
                  />
                </div>
                <div>
                  <CreativeTabs
                    {...props}
                    history={history}
                    advertiserId={
                      values.advertiser && values.advertiser.value
                        ? values.advertiser.value
                        : ''
                    }
                    showLibrary={false}
                    multiAudioUpload
                  />
                  {((touched.audioCreatives &&
                    errors.audioCreatives &&
                    typeof errors.audioCreatives === 'string') ||
                    (touched.imageCreatives &&
                      errors.imageCreatives &&
                      typeof errors.imageCreatives === 'string')) && (
                    <FormHelperText error data-tc="creativeCreativesError">
                      {errors.audioCreatives || errors.imageCreatives}
                    </FormHelperText>
                  )}
                </div>
                <div className={classes.territory}>
                  <Field
                    component={TextField}
                    label="Territory"
                    placeholder="Territory"
                    name="territory"
                    value={TerritoryLabel[values.territory]}
                    fullWidth
                    disabled
                    id="Territory"
                    helperText={status.territory}
                    FormHelperTextProps={{
                      error: !!status.territory,
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <FormButtons
              dataTc="uploadCreativeButton"
              disabled={
                !isValid || !dirty || isSubmitting || isUploadingCreatives
              }
              onClick={handleSubmit}
              isLoading={isSubmitting}
              goBackTo={{
                pathname: '/creatives',
                state: location.state?.parent || {},
              }}
            >
              Upload
            </FormButtons>
          </Form>
          <ErrorDialog
            content={{ title: 'Error', closeButton: 'Close' }}
            isOpen={hasError}
            handleClose={toggleErrorModal}
            dataTc="creative"
            errorMessages={errorMessages}
          />
        </>
      )}
    </ApolloConsumer>
  );
};

export default CreativeForm;
