import Switch from 'features/targetingV2/components/core/Switch';
import { fieldToSwitch, SwitchProps } from 'formik-material-ui';
import { TargetingVersion } from 'interfaces/generated.types';
import React from 'react';

export interface ITargetingVersionSwitchProps {
  checked: boolean;
  disabled?: boolean;
}

const TargetingVersionSwitch = (
  props: SwitchProps & ITargetingVersionSwitchProps
) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const { checked, disabled = false, ...pushThroughProps } = props;
  const onChange = React.useCallback(
    (event) => {
      const selectedVersion = event.target.checked
        ? TargetingVersion.TargetingV2
        : TargetingVersion.TargetingV1;

      setFieldValue(name, selectedVersion);
    },
    [setFieldValue, name]
  );

  return (
    <Switch
      {...fieldToSwitch(pushThroughProps)}
      checked={checked}
      onChange={onChange}
      color="primary"
      disabled={disabled}
    />
  );
};

export default TargetingVersionSwitch;
