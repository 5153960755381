import gql from 'graphql-tag';
import { Forecast } from 'interfaces/generated.types';

export interface IGetForecastResponse {
  forecast: Forecast;
}

const queryBody = `(
    $duration: NonNegativeFloat
    $timeZone: ZoneId!
    $startDate: LocalDateTime!
    $endDate: LocalDateTime
    $targetingDefinition: TargetingDefinitionAttributes
    $targetingDefinitionV2: TargetingDefinitionV2Attributes
    $iabCategoryCodes: [ID!]
    $territory: Territory!
    $targetingRestrictions: [TargetingRestriction!]
  ) {
    forecast(
      attributes: {
        duration: $duration
        timeZone: $timeZone
        startDate: $startDate
        endDate: $endDate
        targetingDefinition: $targetingDefinition
        targetingDefinitionV2: $targetingDefinitionV2
        iabCategoryCodes: $iabCategoryCodes
        territory: $territory
        targetingRestrictions: $targetingRestrictions
      }
    ) {
      availableImpressions {
        dataPoints {
          t
          v
        }
      }
      availableImpressionsTotal
      booked {
        impressions
      }
      bookedImpressions {
        dataPoints {
          t
          v
        }
      }
      bookedImpressionsTotal
      mergedTargetingDefinition {
        targetingGroups {
          channels {
            name
          }
          audienceParams {
            ... on KeyValueGroupParam {
              keyValues {
                key
                value
              }
              clusivity
            }
            ... on AgeParam {
              ageRange {
                minAge
                maxAge
              }
              clusivity
            }
            ... on GenderParam {
              genders
              clusivity
            }
            ... on DayAndTimeParam {
              timeZone
              daysOfWeek {
                day
                hours
              }
              clusivity
            }
            ... on DeviceTypeParam {
              deviceTypes
              clusivity
            }
            ... on DeviceOperatingSystemParam {
              deviceOperatingSystems {
                versions
                name
              }
              clusivity
            }
            ... on ConnectionTypeParam {
              clusivity
              connectionTypes
            }
            ... on DeviceMakerParam {
              deviceMakers {
                name
                models
              }
              clusivity
            }
            ... on BrowserLanguageParam {
              browserLanguages {
                description
                code
              }
              clusivity
            }
            ... on PostalCodeParam {
              postalCodes
              postcodeGroups {
                id
                name
              }
              clusivity
            }
            ... on LocationParam {
              positions {
                latitude
                longitude
                radius
              }
              clusivity
            }
            ... on DmpSegmentParam {
              segmentType
              segments {
                ... on NielsenSegment {
                  id
                  fullName
                }
              }
              clusivity
            }
            ... on DmaParam {
              dmas {
                code
                name
              }
              clusivity
            }
            ... on AudienceSegmentParam {
              segments {
                id
                displayName
              }
              clusivity
            }
            ... on GenreParam {
              genres
              clusivity
            }
            ... on ItunesCategoryParam {
              categories
              clusivity
            }
            ... on PodcastParam {
              podcastTargets {
                collectionId
                episodeId
                showId
                name
              }
              clusivity
            }
            ... on AdPositionParam {
              positions {
                position
                midRollPositions
              }
              clusivity
            }
            ... on IspParam {
              isps {
                id
                name
              }
              clusivity
            }
            ... on CountryParam {
              code
              name
              clusivity
              countryGroups {
                name
                id
              }
              subdivisions {
                ... on CityParam {
                  name
                  regionCode
                  subRegionCode
                }
                ... on RegionParam {
                  name
                  code
                  subRegions {
                    name
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FORECAST = gql`query Forecast${queryBody}`;

export const GET_FORECAST_V1 = gql`query ForecastV1${queryBody}`;
