import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import useStyles from './LoginDaxForm.styles';

export interface ILoginDaxFormValues {
  email: string;
  password: string;
}

interface ILoginDaxFormProps extends FormikProps<ILoginDaxFormValues> {
  error?: string;
  setError: (error: string) => void;
}

const LoginDaxForm = (props: ILoginDaxFormProps) => {
  const {
    values: { email, password },
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    setError,
    error,
  } = props;

  const classes = useStyles();
  const pageClasses = pageStyles();

  const clearErrors = () => {
    setError('');
  };

  return (
    <div className={classes.signInContainer}>
      <Form onSubmit={handleSubmit} aria-label="login-form">
        <div className={classes.signInForm}>
          <Field
            id="email"
            component={TextField}
            label="Email"
            fullWidth
            margin="normal"
            InputLabelProps={{
              'aria-label': 'email',
              shrink: true,
            }}
            name="email"
            onChange={handleChange}
            onKeyUp={clearErrors}
            helperText={touched.email && errors.email}
            FormHelperTextProps={{
              error: !!(touched.email && errors.email),
            }}
          />
          <Field
            id="password"
            component={TextField}
            label="Password"
            fullWidth
            margin="normal"
            InputLabelProps={{
              'aria-label': 'password',
              shrink: true,
            }}
            name="password"
            type="password"
            onChange={handleChange}
            onKeyUp={clearErrors}
          />
          {error && (
            <div className={classes.error}>
              <Typography
                variant="body2"
                align="center"
                className={pageClasses.errorMessage}
              >
                {error}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.ctaContainer}>
          <StyledButton
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Contained}
            type="submit"
            disabled={!isValid || isSubmitting || !email || !password}
            isLoading={isSubmitting}
            testId="sign-in-button"
          >
            Sign In
          </StyledButton>
        </div>
      </Form>

      <Link
        to="/forgotten-password"
        data-tc="forgottenPassword"
        className={classes.link}
      >
        <Typography
          variant="caption"
          align="center"
          className={classNames([classes.italic, classes.footer])}
        >
          Forgotten your password?
        </Typography>
      </Link>
    </div>
  );
};

export default LoginDaxForm;
