import classNames from 'classnames';
import Checkbox from 'features/targetingV2/components/core/Checkbox';
import Divider from 'features/targetingV2/components/core/Divider';
import FormControlLabel from 'features/targetingV2/components/core/FormControlLabel';
import {
  allOptionsSelected,
  handleSelectAll,
  isOptionDisabled,
} from 'features/targetingV2/utils/multiSelect';
import React, { useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

const MenuList = (props: any) => {
  const { children, selectProps, setValue } = props;

  const listRef = useRef<any>(null);
  const groupTitleHeight = 20;
  const optionHeight = 25;
  const menuListHeight = 250;
  const isOptGroup = (group: any) => Array.isArray(group.props.children);
  const getItemSize = (index: number) =>
    children.map((group: any) =>
      isOptGroup(group)
        ? groupTitleHeight + group.props.children.length * optionHeight
        : optionHeight
    )[index];
  const getListHeight = Array.isArray(children)
    ? children.reduce(
        (acc: number, group: any) =>
          isOptGroup(group)
            ? acc +
              groupTitleHeight +
              group.props.children.length * optionHeight
            : acc + optionHeight,
        0
      )
    : menuListHeight;

  const isAllSelected = allOptionsSelected(children, isOptGroup);

  const isAllDisabled =
    children &&
    children.length &&
    children.length > 0 &&
    children.filter((child: any) =>
      isOptGroup(child)
        ? child.props.children.every((ch: any) =>
            isOptionDisabled(ch.props.data, selectProps.value)
          )
        : isOptionDisabled(child.props.data, selectProps.value)
    ).length === children.length;

  const ListOptions = ({ index, style }: { index: number; style: any }) => (
    <div style={style}>{children[index]}</div>
  );

  useEffect(() => {
    if (listRef.current) listRef.current.resetAfterIndex(0, false);
  }, [listRef, children]);

  return Array.isArray(children) ? (
    <div className={selectProps.classes.optionsListWrapper}>
      {selectProps.selectAll && (
        <>
          <FormControlLabel
            data-tc="selectAllOption"
            classes={{
              root: selectProps.classes.optionLabel,
              label: classNames([
                selectProps.classes.optionLabelText,
                selectProps.externalClasses.optionLabelText,
              ]),
            }}
            checked={isAllSelected}
            disabled={isAllDisabled}
            name="selectAllOption"
            value="*"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classNames([
                    selectProps.classes.optionCheckbox,
                    selectProps.externalClasses.optionCheckbox,
                  ]),
                  colorPrimary: selectProps.classes.checkboxPrimaryColor,
                }}
              />
            }
            label="All results"
            onClick={handleSelectAll(
              children,
              isOptGroup,
              isAllSelected,
              setValue,
              selectProps
            )}
          />
          <Divider className={selectProps.classes.dividerWrapper} />
        </>
      )}
      <List
        data-tc="menuList"
        ref={listRef}
        width="100%"
        height={Math.min(getListHeight, menuListHeight)}
        itemSize={getItemSize}
        itemCount={children.length}
      >
        {ListOptions}
      </List>
    </div>
  ) : (
    children
  );
};

export default MenuList;
