import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, Ref } from 'react';

import {
  InputAdornment as MUIInputAdornment,
  InputAdornmentProps as MUIInputAdornmentProps,
} from '@material-ui/core';

import useStyles from './InputAdornment.styles';

export type InputAdornmentProps = MUIInputAdornmentProps;

const InputAdornment = forwardRef(
  (props: InputAdornmentProps, ref: Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUIInputAdornment ref={ref} classes={classes} {...otherProps} />;
  }
);

InputAdornment.displayName = 'InputAdornment';

export default InputAdornment;
