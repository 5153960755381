import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(1, 3, 1, 3),
  },
  dialogTitleRoot: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  actions: {
    padding: theme.spacing(2, 3, 3),
  },
  privacyLink: {
    fontSize: '14px',
    marginRight: 'auto',
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  analyticsIcon: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
