import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(1, 0, 0.5),
  },
  input: {
    padding: '3px 5px 1px',
    fontSize: '14px',
    outline: 'none',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px 0 0 4px',
    border: `solid 1px ${theme.palette.grey[400]}`,
    '&--valueInput': {
      borderLeft: 'none',
      borderRadius: '0 4px 4px 0',
    },
    '&--error': {
      border: `solid 1px ${colors.errorText}`,
    },
    '&::placeholder': {
      color: colors.daxGreyscaleSilver,
      opacity: 1,
    },
    '&:focus': {
      '&::placeholder': {
        visibility: 'hidden',
      },
    },
  },
  inputRoot: {
    height: '32px',
  },
  keyInputContainer: {
    flex: 1,
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: colors.errorText,
      padding: theme.spacing(0, 1),
    },
  },
  valueInputContainer: {
    flex: 3,
  },
}));

export default useStyles;
