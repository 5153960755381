import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  parentItem: ({ indentation }: { indentation: number }) => ({
    paddingLeft: theme.spacing(indentation),
    minHeight: 'auto',
    whiteSpace: 'normal',
    '&:hover,  &:focus-visible ': {
      backgroundColor: colors.primaryBlueLight,
    },

    '&--expanded , :focus-visible': {
      backgroundColor: colors.primaryBlueMediumLight,
    },
  }),
  listItem: {
    '&$focusVisible': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  focusVisible: {},
  parentItemLabelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '164px',
  },
  menuItem: ({ indentation }: { indentation: number }) => ({
    paddingLeft: theme.spacing(indentation),
    minHeight: 'auto',
    whiteSpace: 'normal',
    '&:hover,  &:focus-visible': {
      backgroundColor: colors.primaryBlueLight,
    },
    '&--indented': {
      paddingLeft: theme.spacing(indentation + 1.5),
    },
  }),
  menuItemLabel: {
    '&--expanded': {
      fontWeight: 500,
    },
  },
  menuItemCaption: {
    marginTop: theme.spacing(0.5),
    fontSize: '12px',
    lineHeight: 'inherit',
    color: colors.daxGreyscaleSilver,
  },
  link: {
    alignItems: 'center',
    textDecoration: 'none',
    color: colors.primaryBlue,
  },
}));

export default useStyles;
