import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ClearIcon from '@material-ui/icons/Clear';

import { IFormProps } from 'interfaces';
import { Publisher } from 'interfaces/generated.types';

import { ITargetingGroup } from 'utils/targetingDefinitions';

import Targeting from '../Targeting/Targeting';
import useStyles from './TargetingGroups.styles';

interface ITargetingGroupsProps {
  templateIndex?: number;
  groups: ITargetingGroup[];
  masterId?: string;
  allPublishers?: Publisher[] | [];
  remove?: (index: number) => void;
}

const TargetingGroups = (props: IFormProps<any> & ITargetingGroupsProps) => {
  const {
    templateIndex,
    groups,
    masterId = null,
    allPublishers,
    remove,
  } = props;
  const classes = useStyles();

  return (
    <List disablePadding>
      {groups.map((group: ITargetingGroup, groupIndex: number) => (
        /* eslint-disable react/no-array-index-key */
        <li
          key={`group-${groupIndex}`}
          className={classes.groupWrapper}
          data-testid="targetingGroupsWithPublisher"
          data-tc={`${masterId ? 'targetingGroupMaster' : 'targetingGroup'}`}
        >
          <div className={classes.group}>
            <div className={classes.headingWrapper}>
              <p className={classes.heading} data-tc="targetingHeading">
                Group {groupIndex + 1}
              </p>

              {!masterId && (
                <IconButton
                  data-tc="targetingGroupsRemoveIcon"
                  onClick={() => remove && remove(groupIndex)}
                  className={classes.iconRemove}
                >
                  <ClearIcon />
                  <span className="sr-only">Remove targeting group</span>
                </IconButton>
              )}
            </div>
            <Targeting
              {...props}
              templateIndex={templateIndex}
              groupIndex={groupIndex}
              allPublishers={allPublishers}
            />
          </div>
        </li>
      ))}
    </List>
  );
};

export default TargetingGroups;
