import axios, { Method } from 'axios';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { PublisherInsightsFilterType } from 'interfaces/generated.types';
import { useState } from 'react';

export interface IFilter {
  type: PublisherInsightsFilterType;
  ids: string[];
}

export interface IRequestBody {
  startDate: string;
  endDate: string;
  dimension: string | null;
  currency: string;
  filters?: IFilter[];
}

const restUrl =
  // @ts-ignore
  window.__ENV__.REACT_APP_REST_URL || process.env.REACT_APP_REST_URL;

const useAxios = (url: string, method: Method | undefined) => {
  const [response, setResponse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const { validateJwtToken } = useAuthContext();

  const {
    state: { assumedId },
  } = useCustomApolloContext();

  const request = async (reqBody: IRequestBody | null) => {
    const baseUrl = `${restUrl}/export`;

    const token = await validateJwtToken();
    const headers = {
      'X-DAX-Assumed-Identity': assumedId,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: '*/*',
    };

    try {
      setLoading(true);
      const result = await axios.request({
        url: `${baseUrl}/${url}`,
        data: reqBody,
        headers,
        method,
      });
      setResponse(result.data);
      setError(null);
      setLoading(false);
    } catch (err) {
      setResponse(null);
      setError(err);
      setLoading(false);
    }
  };

  return { response, error, loading, request };
};

export default useAxios;
