import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateCampaignContainer from 'features/direct/campaign/containers/UpdateCampaignContainer/UpdateCampaignContainer';
import { History } from 'history';
import {
  Campaign,
  PermissionName,
  Tag,
  useAllTagsQuery,
  useAllUsersQuery,
  useCampaignQuery,
  User,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { allTerritories } from 'utils/territory';

interface IUpdateCampaignMatch {
  path: string;
  params: {
    campaignId: string;
  };
}
interface UpdateCampaignPageProps {
  history: History;
  match: IUpdateCampaignMatch;
  currentUser: User;
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const UpdateCampaignPage = ({
  history,
  match,
  currentUser,
  userPermissions,
}: UpdateCampaignPageProps) => {
  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, refetch } = useCampaignQuery({
    errorPolicy: 'all',
    variables: {
      id: match.params.campaignId,
      territories: [activeTerritory!],
    },
    fetchPolicy: 'no-cache',
  });

  const { loading: loadingAllTags, data: allTagsData } = useAllTagsQuery();

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Direct, PermissionName.Primary],
      territories: allTerritories,
    },
    skip: !hasEditPermissions,
  });

  if (!loading && !data?.campaign) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Campaign</h1>
        {loading || loadingAllTags || loadingAllUsers ? (
          <Loader />
        ) : (
          <UpdateCampaignContainer
            history={history}
            campaign={data?.campaign as Campaign}
            hasEditPermissions={hasEditPermissions}
            allTags={(allTagsData?.allTags as Tag[]) || []}
            allUsers={(allUsersData?.allUsers as User[]) || []}
            currentUser={currentUser}
            match={match}
            refetch={refetch}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateCampaignPage;
