import classNames from 'classnames';
import React from 'react';

import useStyles from './GradingIcon.styles';

interface IGradeColumnProps {
  id: string;
  selected: boolean;
  disabled?: boolean;
}

const GradeColumn = ({ id, selected, disabled = false }: IGradeColumnProps) => {
  const classes = useStyles({ disabled });

  return (
    <div
      aria-label={id}
      id={id}
      className={classNames([
        classes.grade,
        {
          [`${classes.grade}--filled`]: selected,
        },
      ])}
    />
  );
};

export default GradeColumn;
