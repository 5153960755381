import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CloneIcon } from 'assets/icons/dax-copyicon.svg';

import CloneModal from 'components/CloneModal/CloneModal';
import { CloneTypeEnum } from 'components/CloneModal/CloneModalValues';
import { ISetErrorModal } from 'components/ErrorDialog/ErrorDialog';

import { ICloneCellData } from './Table';
import { useEditableCellStyles } from './Table.styles';

interface ITableCloneCell {
  entity: {
    id: string;
    name: string;
  };
  onCellClone: ({
    entity,
    cloneChildren,
    hasChildren,
    isEdit,
    setErrorModal,
  }: ICloneCellData) => Promise<void>;
  children?: React.ReactNode;
  cloneChildren?: boolean;
  setErrorModal: (values: ISetErrorModal) => void;
  isEditable: boolean;
  type: CloneTypeEnum;
  dataTc: string;
}

const TableCloneCell = ({
  entity,
  onCellClone,
  children = <CloneIcon />,
  cloneChildren = false,
  setErrorModal,
  isEditable,
  type,
  dataTc,
}: ITableCloneCell) => {
  const classes = useEditableCellStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleApprove = async ({ hasChildren, isEdit }: any) => {
    setModalOpen(false);
    await onCellClone({
      entity,
      cloneChildren,
      hasChildren,
      isEdit,
      setErrorModal,
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Tooltip title="Clone">
          <span>
            <IconButton
              onClick={handleClick}
              disabled={!isEditable}
              data-tc={`${dataTc}Button`}
            >
              {children}
              <span className="sr-only">Clone</span>
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {isModalOpen ? (
        <CloneModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          entity={entity}
          type={type}
          cloneChildren={cloneChildren}
          dataTc={`${dataTc}Modal`}
          handleClone={({ hasChildren, isEdit }) => {
            handleApprove({
              cloneChildren,
              hasChildren,
              isEdit,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default TableCloneCell;
