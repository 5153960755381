import { IUserValues } from 'features/user/components/UserForm/userFormUtils';
import { CreateUserMutationFn } from 'interfaces/generated.types';
import { formatValues, handleFormErrors } from 'utils/forms';
import { History } from 'history';
import { FormikHelpers } from 'formik';
import pickBy from 'lodash/pickBy';

export const handleOnComplete = (history: History) => {
  history.push('/users');
};

export const submitCreateUser =
  (createUser: CreateUserMutationFn, toggleErrorModal: () => void) =>
  (
    formValues: IUserValues,
    { setSubmitting, setStatus }: FormikHelpers<IUserValues>
  ) => {
    const formattedValues = formatValues(formValues);

    const filteredValues = {
      ...pickBy({
        ...formValues,
        permissions: formattedValues.permissions,
      }),
      territories: formValues.territories,
      entityPermissions: formattedValues.entityPermissions,
    };

    createUser({
      variables: filteredValues,
    }).catch((error) => {
      if (error.message.includes('Resource already exists')) {
        setSubmitting(false);
        setStatus({
          email:
            'This user already exists in DAX Audio. Please add someone else.',
        });
      } else {
        handleFormErrors({
          error,
          toggleErrorModal,
          setStatus,
          setSubmitting,
        });
      }
    });
  };
