import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import AdRequestEnrichment from 'components/AdRequestEnrichment/AdRequestEnrichment';
import Blocklisting, {
  BlocklistingEntityEnum,
} from 'components/Blocklisting/Blocklisting';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import ChannelAssociatedAdsTable from 'features/inventory/channel/components/ChannelAssociatedAdsTable/ChannelAssociatedAdsTable';
import ChannelAssociatedDealsTable from 'features/inventory/channel/components/ChannelAssociatedDealsTable/ChannelAssociatedDealsTable';
import ChannelDetails from 'features/inventory/channel/components/ChannelDetails/ChannelDetails';
import ChannelMonitoring from 'features/inventory/channel/components/ChannelMonitoring/ChannelMonitoring';
import {
  channelDetailsFields,
  channelStatusValues,
  getChannelStatusData,
  IChannelValues,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';
import TranscodePresetContainer from 'features/inventory/transcodePreset/containers/TranscodePresetContainer/TranscodePresetContainer';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  Channel,
  ChannelStatus,
  EntityType,
  PublisherStatus,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import {
  hasPublisherContractEditPermissions,
  hasPublisherContractReadPermissions,
} from 'utils/contracts';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import ShowContracts from '../ShowContracts/ShowContracts';

interface IChannelFormProps {
  channel?: Channel;
  update?: boolean;
  goBackTo: ILocation;
  hasEditPermissions?: boolean;
  publisherStatus?: PublisherStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
}

export const channelButtons = [
  {
    text: 'Save and Review',
    url: '/channel/ID',
    update: true,
    dataTc: 'channelReviewButton',
  },
  {
    text: 'Save and View All Channels',
    url: '/publisher/PARENT_ID',
    update: false,
    dataTc: 'channelListButton',
  },
];

export const getDefaultTab = (channelStatus?: string, location?: any) => {
  if (location && location.state) {
    const { tab } = location.state;
    if (tab) return tab;
  }
  return channelStatus && channelStatus === ChannelStatus.Enabled ? 0 : 1;
};

const getChannelDetailsTabErrors = createNumberOfFormErrorsFn();

const ChannelTabsForm = (
  props: IFormProps<IChannelValues> & IChannelFormProps
) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    values,
    channel,
    goBackTo,
    update = false,
    hasEditPermissions = true,
    status,
    publisherStatus,
    onFieldUpdate,
    onFieldValidate,
    history,
  } = props;

  const classes = tabStyles();

  const location = usePreviousLocation();

  const [selectedTab, setTab] = useState(
    getDefaultTab(values.status, location)
  );
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const isChannelMonitoringTab = selectedTab === 0;
  const isChannelTab = selectedTab === 1;
  const isTranscodePresetTab = selectedTab === 2;
  const isBlocklistingTab = selectedTab === 3;
  const isTargetingTab = selectedTab === 4;
  const isChannelActivityTab = selectedTab === 5;
  const isShowContractsTab = selectedTab === 6;
  const isLinkedAdsTab = selectedTab === 7;
  const isLinkedDealsTab = selectedTab === 8;

  const userPermissions = useUserPermissions();

  return (
    <>
      <Form role="form" className={classes.form}>
        {update && publisherStatus && (
          <>
            <EntityProgressBar
              statusData={getChannelStatusData(values, publisherStatus)}
              currentStatus={values.status || ''}
              dataTc="channelStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={channelStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="channelHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="publisherTabs"
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab
              disabled={
                values.status !== ChannelStatus.Enabled &&
                values.status !== ChannelStatus.Disabled
              }
              dataTc="channelMonitoringTab"
              label="Performance Monitoring"
            />
            <Tab
              dataTc="channelDetailsTab"
              label="Details"
              numberOfTabErrors={getChannelDetailsTabErrors(
                status,
                channelDetailsFields
              )}
            />
            <Tab dataTc="transcodePresetTab" label="Transcode Presets" />
            <Tab dataTc="channelBlocklistingTab" label="Block Listing" />
            <Tab
              dataTc="channelTargetingTab"
              label="Default Audience Parameters"
            />
            <Tab
              disabled={!update}
              dataTc="channelActivityTab"
              label="Activity"
            />
            {(hasPublisherContractEditPermissions(userPermissions) ||
              hasPublisherContractReadPermissions(userPermissions)) && (
              <Tab
                disabled={!update}
                dataTc="channelShowContractsTab"
                label="Show Contracts"
              />
            )}
            <Tab
              disabled={!update}
              dataTc="channelLinkedAdsTab"
              label="Active Ads"
            />
            <Tab
              disabled={!update}
              dataTc="channelLinkedDealsTab"
              label="Active Deals"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isChannelActivityTab || isLinkedAdsTab || isLinkedDealsTab,
            },
            {
              [`${classes.formView}--noPadding`]: isTargetingTab,
            },
          ])}
        >
          {isChannelMonitoringTab && (
            <ChannelMonitoring id={values.id} timeZone={values.timeZone} />
          )}
          {isChannelTab && <ChannelDetails {...props} update={update} />}
          {isTranscodePresetTab && <TranscodePresetContainer {...props} />}
          {isShowContractsTab &&
            (hasPublisherContractEditPermissions(userPermissions) ||
              hasPublisherContractReadPermissions(userPermissions)) && (
              <ShowContracts
                channel={channel as Channel}
                history={history}
                hasEditPermissions={hasPublisherContractEditPermissions(
                  userPermissions
                )}
              />
            )}
          {isBlocklistingTab && (
            <Blocklisting {...props} entity={BlocklistingEntityEnum.Channel} />
          )}
          {isTargetingTab && <AdRequestEnrichment {...props} />}
          {isChannelActivityTab && (
            <ActivityTable
              entityType={EntityType.Channel}
              id={values.id}
              data-tc="channelActivityTable"
            />
          )}
          {isLinkedAdsTab && (
            <ChannelAssociatedAdsTable
              channelId={channel?.id || ''}
              history={history}
            />
          )}
          {isLinkedDealsTab && (
            <ChannelAssociatedDealsTable
              channelId={channel?.id || ''}
              history={history}
            />
          )}
        </div>
        <FormButtons
          dataTc="submitChannelButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={channelButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default ChannelTabsForm;
