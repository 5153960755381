import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreativeForm, {
  ICreativeFormValues,
} from 'features/direct/creative/components/CreativeForm/CreativeForm';
import CreativeFormValidation from 'features/direct/creative/validations/creatives';
import { Formik } from 'formik';
import { History } from 'history';
import {
  Advertiser,
  CreateCreativeAudioMutationFn,
  CreateCreativeImageMutationFn,
  CreativeType,
  useCreateCreativeAudioMutation,
  useCreateCreativeImageMutation,
} from 'interfaces/generated.types';
import React from 'react';
import { createSelectOptions } from 'utils/dataTransformation';

interface ICreativesMultiUploadContainerProps {
  history: History;
  allAdvertisers: Advertiser[];
}

export const handleOnComplete = (history: History) => {
  history.push('/creatives');
};

export const submitForm = ({
  createImageCreative,
  createAudioCreative,
}: {
  createImageCreative: CreateCreativeImageMutationFn;
  createAudioCreative: CreateCreativeAudioMutationFn;
}) => async (formValues: ICreativeFormValues) => {
  const { audioCreatives, imageCreatives, advertiser } = formValues;

  if (audioCreatives.length) {
    await audioCreatives.map((audioCreative: any) => {
      const audio = {
        name: audioCreative.name,
        mimeType: audioCreative.file
          ? audioCreative.file.type
          : audioCreative.type,
        fileName: audioCreative.fileName,
        url: audioCreative.url,
        duration: audioCreative.duration,
        uploadedDate: audioCreative.uploadedDate,
        territory: formValues.territory,
      };

      return createAudioCreative({
        variables: {
          advertiserId: advertiser?.value as string,
          type: CreativeType.Audio,
          ...audio,
        },
      });
    });
  }

  if (imageCreatives.length) {
    await imageCreatives.map((imageCreative: any) => {
      const image = {
        mimeType: imageCreative.file
          ? imageCreative.file.type
          : imageCreative.type,
        name: imageCreative.name,
        fileName: imageCreative.fileName,
        size: imageCreative.dimensions,
        url: imageCreative.url,
        uploadedDate: imageCreative.uploadedDate,
        territory: formValues.territory,
      };

      return createImageCreative({
        variables: {
          advertiserId: advertiser?.value as string,
          type: CreativeType.Image,
          ...image,
        },
      });
    });
  }
};

const CreativesMultiUploadContainer = ({
  history,
  allAdvertisers,
}: ICreativesMultiUploadContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [createImageCreative] = useCreateCreativeImageMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });
  const [createAudioCreative] = useCreateCreativeAudioMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <Formik<ICreativeFormValues>
      initialValues={{
        allAdvertisers: createSelectOptions(allAdvertisers),
        advertiser: null,
        imageCreatives: [],
        audioCreatives: [],
        territory: activeTerritory!,
      }}
      validationSchema={CreativeFormValidation}
      onSubmit={submitForm({ createImageCreative, createAudioCreative })}
      component={CreativeForm}
    />
  );
};

export default CreativesMultiUploadContainer;
