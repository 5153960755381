import primaryControlsStyles from 'assets/styles/components/PrimaryControls.styles';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import PrintedBlockList from 'features/primaryControls/components/PrintedBlockList/PrintedBlockList';
import useStyles from 'features/primaryControls/containers/BlocklistingPrimaryControl/BlocklistingPrimaryControl.styles';
import { useGlobalBlocklistsQuery } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { TerritoryLabel } from 'utils/territory';

import BlocklistingModal from '../../components/BlocklistingModal/BlocklistingModal';

const BlocklistingPrimaryControl = () => {
  const [isOpen, setModalOpened] = useState(false);
  const primaryControlClasses = primaryControlsStyles({});
  const blocklistingClasses = useStyles({});

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const currentActiveTerritory = activeTerritory!;

  const closeModal = () => setModalOpened(false);

  const { data, error } = useGlobalBlocklistsQuery({
    variables: { territories: [currentActiveTerritory] },
  });

  const iabCategories = data?.globalBlacklistedIabCategories.find(
    (item) => item.territory === activeTerritory
  )?.iabCategories;
  const iabCategoriesToDisplay = iabCategories
    ?.map((category: any) => `${category.code}: ${category.name}`)
    .join(', ');

  const domains = data?.globalBlacklistedDomains.find(
    (item) => item.territory === activeTerritory
  )?.domains;
  const domainsToDisplay = domains?.join(', ');

  const showCurrentBlockLists =
    !!iabCategoriesToDisplay?.trim() || !!domainsToDisplay?.trim();
  return (
    <div data-tc="blocklistingPrimaryControl">
      <h2 className={primaryControlClasses.title}>
        Block Listing for IAB Categories and Advertiser domains
      </h2>
      <div className={primaryControlClasses.container}>
        <p>
          Use this control to block IAB categories and Advertiser in domains
          across DAX Audio in {TerritoryLabel[currentActiveTerritory]} only.
        </p>
        <p className={primaryControlClasses.note}>
          NOTE: This will stop delivery across all entities using these IAB
          categories and Advertiser domains in{' '}
          {TerritoryLabel[currentActiveTerritory]}. Misuse could lead to
          business-related ramifications for Global.
        </p>
        <div className={primaryControlClasses.actions}>
          <StyledButton
            data-tc="openCampaignModal"
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            onClick={() => setModalOpened(true)}
          >
            Select Block Listing
          </StyledButton>
        </div>
        {error ? (
          <p
            className={primaryControlClasses.note}
            data-testid="currentBlockListsError"
          >
            * There was an error loading the currently blocked IAB Categories
            and Advertiser Domains.
          </p>
        ) : (
          showCurrentBlockLists && (
            <div
              className={blocklistingClasses.currentBlockListsWrapper}
              data-testid="currentBlockListsWrapper"
            >
              {iabCategoriesToDisplay && (
                <PrintedBlockList
                  label="Current IAB Categories on Block List"
                  content={iabCategoriesToDisplay}
                  testId="currentIabCategoriesBlockList"
                />
              )}
              {domainsToDisplay && (
                <PrintedBlockList
                  label="Current Advertiser Domains on Block List"
                  content={domainsToDisplay}
                  testId="currentDomainsBlockList"
                />
              )}
            </div>
          )
        )}
      </div>
      <BlocklistingModal
        {...{ isOpen, closeModal }}
        iabCategories={
          iabCategories?.map((category: any) => category.code) || []
        }
        domains={domains || []}
        activeTerritory={currentActiveTerritory}
      />
    </div>
  );
};

export default BlocklistingPrimaryControl;
