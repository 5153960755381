import * as Yup from 'yup';

import { PricingType } from 'interfaces/generated.types';

import commons from 'validations/commons';

const PublisherFormValidation = Yup.object().shape(
  {
    name: commons.name('Publisher Name'),
    territories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .required('Publisher should be assigned at least one territory'),
    pricingType: Yup.string().when('pricingAmount', {
      is: (pricingAmount: string) => !!pricingAmount,
      then: Yup.string().required('Price type is required if Amount is added'),
    }),
    pricingAmount: Yup.string()
      .when('pricingType', {
        is: PricingType.Cpm,
        then: commons.optionalPrice({
          messages: {
            minMessage: 'Amount should be higher than or equal to 0',
            maxMessage: 'Amount should be lower than or equal to 999,999.99',
          },
          minimum: 0,
          maximum: 999999.99,
          fieldKey: 'pricingAmount',
        }),
      })
      .when('pricingType', {
        is: PricingType.RevShare,
        then: commons.optionalPrice({
          messages: {
            minMessage: 'Amount should be a positive number',
            maxMessage: 'Amount should be 100 or lower',
          },
          minimum: 0,
          maximum: 100,
          fieldKey: 'pricingAmount',
        }),
      }),
    blacklistedDomains: commons.domains({
      message: 'Only a valid domain is allowed',
      fieldKey: 'publisherDomains',
    }),
    currency: commons.currency('currency').when('pricingAmount', {
      is: (pricingAmount: string) => !!pricingAmount,
      then: Yup.string().required('Currency is required if Amount is added'),
    }),
  },
  [
    ['pricingAmount', 'currency'],
    ['pricingAmount', 'pricingType'],
  ]
);

export default PublisherFormValidation;
