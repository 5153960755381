import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
    flex: '100%',
    margin: theme.spacing(0, 0, 1, 1.25),
    color: theme.palette.grey[700],
  },
  parameters: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& + &': {
      margin: theme.spacing(3.75, 0, 0),
    },
  },
  parametersGroup: {
    '& + &': {
      margin: theme.spacing(2, 0, 0),
    },
    '& + &::before': {
      content: "'and'",
      textTransform: 'uppercase',
      textAlign: 'center',
      width: '100%',
    },
  },
  parametersValues: {
    padding: theme.spacing(3.75, 2.5, 3.126),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    flex: 1,
  },
  iconAdd: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2.5),
    borderRadius: 0,
    textDecoration: 'underline',
    paddingBottom: 0,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
  iconRemove: {
    position: 'absolute',
    top: theme.spacing(0.6),
    right: theme.spacing(2.5),
  },
  listenerList: {
    marginTop: theme.spacing(3.9),
  },
  parameterOptions: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1.5),
  },
  parameterSelect: {
    margin: theme.spacing(0, 1.25),
    flex: 50,
  },
  parameterWrapper: {
    marginTop: theme.spacing(5),
  },
}));

export default useStyles;
