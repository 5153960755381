/* eslint-disable no-param-reassign */
import { sortItemsAlphabetically } from 'features/targetingV2/utils/common';

import {
  CheckboxTreeProps,
  CheckboxTreeState,
  CheckedStateEnum,
  Node,
  NodeLike,
  NodeMinimal,
  SearchMatchingLogic,
} from './types';

export const getSelectedNodeValues = (nodes: NodeLike[], acc: string[] = []) =>
  nodes.reduce((accumulator: string[], node: NodeLike): string[] => {
    if (!node.children) {
      return [...accumulator, node.value];
    }
    return getSelectedNodeValues(node.children, accumulator);
  }, acc);

export const copyNodes = (nodes: NodeLike[]): NodeLike[] =>
  nodes.map((node) => ({
    ...node,
    children: node.children ? copyNodes(node.children) : undefined,
  }));

export const enrichNodes = (
  nodes: any,
  ancestorList: NodeMinimal[],
  parent?: any
) => {
  for (const node of nodes) {
    const nodeAncestorList = [...ancestorList];
    if (parent) {
      node.parent = parent;
      node.id = `${parent.id}/${node.value}`;
      nodeAncestorList.push({
        id: node.parent.value,
        label: node.parent.label,
      });
      node.ancestorList = nodeAncestorList;
    } else {
      node.id = node.value;
    }
    if (node.children) {
      node.children = enrichNodes(node.children, nodeAncestorList, node);
    }
  }
  return nodes;
};

export const buildNodeMap = (
  nodes: Node[],
  map: Map<string, Node> = new Map<string, Node>()
) => {
  nodes.forEach((node) => {
    map.set(node.id, node);
    if (node.children) {
      map = buildNodeMap(node.children, map);
    }
  });
  return map;
};

export const setParentIndeterminateState = (
  option: Node,
  accumulator: Record<string, number>
) => {
  if (option.parent) {
    if (accumulator[option.parent.id] !== CheckedStateEnum.Checked) {
      const hasAllChildrenSelected =
        option.parent.children &&
        option.parent.children.length > 0 &&
        option.parent.children.every(
          (child) => accumulator[child.id] === CheckedStateEnum.Checked
        );

      if (hasAllChildrenSelected) {
        accumulator[option.parent.id] = CheckedStateEnum.Checked;
      } else {
        accumulator[option.parent.id] = CheckedStateEnum.Indeterminate;
      }
    }
    setParentIndeterminateState(option.parent, accumulator);
  }
};

export const buildCheckedIndex = (
  options: Node[],
  selectedNodesIds: string[],
  isParentChecked: number = CheckedStateEnum.Unchecked,
  acc: Record<string, number> = {}
) =>
  options.reduce((accumulator, option) => {
    if (selectedNodesIds.includes(option.value)) {
      accumulator[option.id] = CheckedStateEnum.Checked;

      setParentIndeterminateState(option, accumulator);

      if (option.children)
        option.children.forEach((child) => {
          accumulator[child.id] = CheckedStateEnum.Checked;
        });
    } else {
      const hasAllChildrenSelected =
        option.children &&
        option.children.length > 0 &&
        option.children.every((child) =>
          selectedNodesIds.includes(child.value)
        );

      accumulator[option.id] =
        isParentChecked || !!hasAllChildrenSelected
          ? CheckedStateEnum.Checked
          : CheckedStateEnum.Unchecked;
    }
    buildCheckedIndex(
      option.children || [],
      selectedNodesIds,
      accumulator[option.id],
      accumulator
    );
    return accumulator;
  }, acc);

export const expandParents = (
  node: Node,
  acc: Record<string, boolean> = {}
) => {
  acc[node.id] = true;
  if (node.parent) expandParents(node.parent, acc);
};

export const addExpandedNodeToMap = (
  node: Node,
  value: string[],
  parent: Node | null,
  acc: Record<string, boolean> = {}
) => {
  acc[node.id] = false;

  if (value.includes(node.value)) {
    if (parent && !value.includes(parent.value)) expandParents(parent, acc);
  } else if (node.children) {
    node.children.forEach((child) =>
      addExpandedNodeToMap(child, value, node, acc)
    );
  }

  return acc;
};

export const buildExpandedIndex = (
  options: Node[],
  value: string[],
  acc: Record<string, boolean> = {}
) =>
  options.reduce((accumulator, option) => {
    addExpandedNodeToMap(option, value, null, accumulator);

    return accumulator;
  }, acc);

export const buildSelectedNodesMap = (selectedNodes: NodeLike[]) =>
  selectedNodes.reduce((selectedNodesMap, currentNode) => {
    selectedNodesMap.set(currentNode.value, currentNode);
    return selectedNodesMap;
  }, new Map<string, NodeLike>());

export const buildInitialState = ({
  rawNodes,
  initialState = {},
}: CheckboxTreeProps): CheckboxTreeState => {
  const { selected = [], areSelectedProvidedAsTrees = false } = initialState;

  const copiedNodes = copyNodes(rawNodes);

  const enrichedNodes: Node[] = enrichNodes(copiedNodes, []);
  const selectedNodeValues = areSelectedProvidedAsTrees
    ? getSelectedNodeValues(selected)
    : selected.map((selectedNode) => selectedNode.value);

  const nodesMap = buildNodeMap(enrichedNodes);
  const checked = buildCheckedIndex(enrichedNodes, selectedNodeValues);
  const expanded = buildExpandedIndex(enrichedNodes, selectedNodeValues);

  const selectedNodesMap = buildSelectedNodesMap(selected);

  return {
    allNodes: enrichedNodes,
    checked,
    nodesMap,
    expanded,
    filter: '',
    searching: false,
    selectedNodesMap,
    areSelectedProvidedAsTrees,
  };
};

const checkSiblings = (
  node: Node,
  updates: Record<string, number>,
  selectedNodesMap: Map<string, NodeLike>,
  checked: Record<string, number>
) => {
  const parentNode = node.parent;
  if (
    checked[parentNode!.id] !== CheckedStateEnum.Unchecked &&
    parentNode?.children &&
    parentNode?.children.length > 1
  ) {
    let isASiblingChecked = false;
    parentNode?.children.forEach((child: Node) => {
      if (
        child.id !== node.id &&
        checked[parentNode!.id] === CheckedStateEnum.Checked
      ) {
        selectedNodesMap.set(child.value, child);
        isASiblingChecked = true;
      }
      if (
        child.id !== node.id &&
        checked[parentNode!.id] === CheckedStateEnum.Indeterminate &&
        checked[child.id] === CheckedStateEnum.Checked
      ) {
        isASiblingChecked = true;
      }
    });
    if (isASiblingChecked)
      updates[parentNode.id] = CheckedStateEnum.Indeterminate;
  }

  if (parentNode?.parent) {
    checkSiblings(parentNode, updates, selectedNodesMap, checked);
  }
};

const uncheckParents = (
  node: Node,
  updates: Record<string, number>,
  selectedNodesMap: Map<string, NodeLike>
) => {
  const anyChildrenChecked = node.children?.some(
    (child: any) =>
      updates[child.id] === CheckedStateEnum.Checked ||
      updates[child.id] === CheckedStateEnum.Indeterminate
  );

  updates[node.id] = anyChildrenChecked
    ? CheckedStateEnum.Indeterminate
    : CheckedStateEnum.Unchecked;

  selectedNodesMap.delete(node.value);
  if (node.parent) {
    uncheckParents(node.parent, updates, selectedNodesMap);
  }
};

const updateParents = (
  node: Node,
  checkedState: Record<string, number>,
  updates: Record<string, number>,
  selectedNodesMap: Map<string, NodeLike>
) => {
  if (node.parent) {
    const siblings = (node.parent.children as Node[]).filter(
      (child: any) => child.id !== node.id
    );
    const allSiblingsPreviouslyChecked = siblings.every(
      (sibling: any) => checkedState[sibling.id] === CheckedStateEnum.Checked
    );

    updates[node.parent.id] = allSiblingsPreviouslyChecked
      ? CheckedStateEnum.Checked
      : CheckedStateEnum.Indeterminate;

    if (allSiblingsPreviouslyChecked) {
      updateParents(node.parent, checkedState, updates, selectedNodesMap);
    } else {
      uncheckParents(node.parent, updates, selectedNodesMap);
    }
  }
};

const updateChildren = (
  node: Node,
  updates: Record<string, number>,
  value: number,
  selectedNodesMap: Map<string, NodeLike>
) => {
  if (node.children) {
    for (const child of node.children) {
      updates[child.id] = value;
      selectedNodesMap.delete(child.value);

      updateChildren(child, updates, value, selectedNodesMap);
    }
  }
};

export const checkAndUpdate = (
  nodeMap: Map<string, Node>,
  checkedState: Record<string, number>,
  id: string,
  newValue: number,
  selectedNodesMap: Map<string, NodeLike>
) => {
  const node = nodeMap.get(id);
  const updates: Record<string, number> = {};
  const newSelectedNodesMap = new Map<string, NodeLike>();

  if (node) {
    for (const [selectedNodeValue, selectedNode] of selectedNodesMap) {
      newSelectedNodesMap.set(selectedNodeValue, selectedNode);
    }
    if (newValue === CheckedStateEnum.Checked) {
      newSelectedNodesMap.set(node.value, node);
    } else {
      newSelectedNodesMap.delete(node.value);
    }

    updates[id] = newValue;

    updateChildren(node, updates, newValue, newSelectedNodesMap);

    if (newValue === CheckedStateEnum.Unchecked) {
      const parentNode = node.parent;
      if (parentNode) {
        uncheckParents(parentNode, updates, newSelectedNodesMap);
        checkSiblings(node, updates, newSelectedNodesMap, checkedState);
      }
    } else {
      updateParents(node, checkedState, updates, newSelectedNodesMap);
    }
  }

  return { updates, newSelectedNodesMap };
};

export const filterTree = (
  nodes: Node[],
  filterText: string,
  matchingLogic: SearchMatchingLogic
) => {
  function filterNodes(filtered: Node[], node: Node) {
    const children = (node.children || []).reduce(filterNodes, []);
    if (
      node.label.toLowerCase()[matchingLogic](filterText.toLowerCase()) ||
      children.length
    ) {
      filtered.push({ ...node, children });
    }

    return filtered;
  }
  return nodes.reduce(filterNodes, []);
};

export const filterNodesRemovingLevels = (
  text: string,
  nodes: Node[],
  matchingLogic: SearchMatchingLogic
) => {
  if (!text) {
    return nodes;
  }

  const filteredNodes = nodes.reduce((acc: Node[], curr: Node) => {
    if (curr.label.toLowerCase()[matchingLogic](text.toLowerCase())) {
      acc.push({ ...curr });
      return acc;
    }

    if (curr.children && curr.children.length > 0) {
      const filteredChildren = filterNodesRemovingLevels(
        text,
        curr.children,
        matchingLogic
      );

      filteredChildren.forEach((child: Node) => {
        acc.push(child);
      });
    }

    return acc;
  }, []);

  return sortItemsAlphabetically(filteredNodes, 'label');
};

export const flatCollectChildren = (nodes: Node[], acc: Node[]): Node[] =>
  nodes.reduce((accumulator, node) => {
    if (!node.children) {
      return [...accumulator, node];
    }
    const newAcc = [...accumulator, node];
    return flatCollectChildren(node.children, newAcc);
  }, acc);

export const getSelectedNodes = (selectedNodesMap: Map<string, NodeLike>) => [
  ...selectedNodesMap.values(),
];

export const search = (
  term: string,
  nodeMap: Map<string, Node>,
  matchingLogic: SearchMatchingLogic
) =>
  Array.from(nodeMap.values())
    .filter((node) =>
      node.label.toLowerCase()[matchingLogic](term.toLowerCase())
    )
    .reduce((acc: Record<string, boolean>, node) => {
      const expand = (
        checkedState: Record<string, boolean>,
        nodeIdx: Map<string, Node>,
        id: string,
        newValue: boolean
      ) => {
        if (nodeIdx.get(id)?.parent) {
          checkedState[(nodeIdx.get(id)?.parent as Node).id] = newValue;
          expand(
            checkedState,
            nodeIdx,
            (nodeIdx.get(id)?.parent as Node).id,
            newValue
          );
        }
      };
      acc[node.id] = !!term;
      expand(acc, nodeMap, node.id, !!term);
      return acc;
    }, {});
