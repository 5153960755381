import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    padding: theme.spacing(3),
    justifyContent: 'space-between',
  },
}));

export default useStyles;
