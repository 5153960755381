import { hasJwtTokenExpired } from 'utils/jwt';
import { getCookieValue } from './cookie';

export enum AuthState {
  'Fetching' = 'FETCHING',
  'LoggedIn' = 'LOGGED_IN',
  'LoggedOut' = 'LOGGED_OUT',
  'NotVerified' = 'NOT_VERIFIED',
  'PasswordResetSuccess' = 'PASSWORD_RESET_SUCCESS',
  'FailedLoadingSdk' = 'FAILED_LOADING_SDK',
}

export interface IAuthContext {
  state: AuthState;
  registrationToken: string;
  verificationToken: string;
  unverifiedEmail: string;
  loaded: boolean;
  validateJwtToken: () => Promise<any>;
  getJwtToken: () => Promise<any>;
  logout: () => Promise<any>;
  onGlobalStaffSignIn: (setError: (message: string) => void) => void;
  onDaxClientSignIn: (
    params: {
      loginID: string;
      password: string;
    },
    setError: (message: string) => void,
    redirectOnUnverifiedEmail: (email: string) => void
  ) => void;
  onDaxClientSignUp: (
    params: {
      email: string;
      password: string;
    },
    setError: (message: string) => void
  ) => void;
  onVerifyEmail: (
    code: string,
    regToken: string,
    vToken: string,
    setError: (message: string) => void
  ) => void;
  onSendVerificationCode: (email: string) => void;
  onResetPasswordSendEmail: (params: { loginID: string }) => Promise<any>;

  onResetPassword: (
    params: {
      passwordResetToken: string;
      newPassword: string;
    },
    setError: (message: string) => void
  ) => void;
}

export const defaultAuthContext = {
  registrationToken: '',
  verificationToken: '',
  unverifiedEmail: '',
  state: AuthState.Fetching,
  loaded: false,
  validateJwtToken: () =>
    new Promise((_resolve, reject) =>
      reject('validateJwtToken not overridden')
    ),
  getJwtToken: () =>
    new Promise((_resolve, reject) => reject('getJwtToken not overridden')),
  logout: () =>
    new Promise((_resolve, reject) => reject('logout not overridden')),
  onGlobalStaffSignIn: (setError: (message: string) => void) =>
    setError(
      'Unable to sign in as global staff - connection to authentication provider failed'
    ),
  onDaxClientSignIn: (
    _params: {
      loginID: string;
      password: string;
    },
    setError: (message: string) => void
  ) =>
    setError(
      'Unable to sign in as DAX client - connection to authentication provider failed'
    ),
  onDaxClientSignUp: (
    _params: {
      email: string;
      password: string;
    },
    setError: (message: string) => void
  ) =>
    setError(
      'Unable to sign up as DAX client - connection to authentication provider failed'
    ),
  onVerifyEmail: (
    _code: string,
    _regToken: string,
    _vToken: string,
    setError: (message: string) => void
  ) =>
    setError(
      'Unable to verify email - connection to authentication provider failed'
    ),
  onSendVerificationCode: () => {},
  onResetPasswordSendEmail: () =>
    new Promise((resolve, reject) =>
      reject(
        'Unable to verify email - connection to authentication provider failed'
      )
    ),

  onResetPassword: (
    _params: {
      passwordResetToken: string;
      newPassword: string;
    },
    setError: (message: string) => void
  ) =>
    setError('Error, reset password failed, please request new password link'),
};

export const gigyaApiKey =
  window.__ENV__.REACT_APP_GIGYA_API_KEY || process.env.REACT_APP_GIGYA_API_KEY;

export const getJwtTokenCallback = (getJwtToken: () => void) => () => {
  getJwtToken();
};

export const getValidateJwtTokenFunc =
  (getJwtToken: () => Promise<any>) => async () => {
    const jwtToken = getCookieValue('dax_jwt_token');

    if (hasJwtTokenExpired(jwtToken)) {
      try {
        const newJwtToken = await getJwtToken();
        return newJwtToken;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating jwt token: ', error);
        return '';
      }
    }

    return jwtToken;
  };

export const finalizeRegistration =
  (getJwtToken: () => void) => (response: any) => {
    window.gigya.accounts.finalizeRegistration({
      regToken: response.requestParams.regToken,
      callback: getJwtTokenCallback(getJwtToken),
    });
  };

export const updateAccountInfo =
  (regToken: string, getJwtToken: () => void) => (response: any) => {
    window.gigya.accounts.setAccountInfo({
      regToken,
      profile: { ...response.profile, gender: 'u' },
      callback: finalizeRegistration(getJwtToken),
    });
  };

export const socialLoginCallback =
  (getJwtToken: () => Promise<any>, setError: (message: string) => void) =>
  (response: any) => {
    if (response.errorCode === 0) {
      getJwtToken().catch((error) =>
        // eslint-disable-next-line no-console
        console.error('Error updating jwt token: ', error)
      );
    } else if (response.errorCode === '206001') {
      window.gigya.accounts.getAccountInfo({
        regToken: response.regToken,
        callback: updateAccountInfo(response.regToken, getJwtToken),
      });
    } else
      setError(
        `An error has occurred!\n Error details: ${response.errorMessage}. ${response.errorDetails}`
      );
  };

export const onSocialLogin = (
  getJwtToken: () => Promise<any>,
  setError: (message: string) => void
) => {
  window.gigya.socialize.login({
    provider: 'saml-azure',
    include: 'id_token,profile,data',
    callback: socialLoginCallback(getJwtToken, setError),
  });
};

export const sendVerificationCode = (
  email: string,
  setVerificationToken: (token: string) => void
) => {
  window.gigya.accounts.otp.sendCode({
    apiKey: gigyaApiKey,
    lang: 'en',
    email,
    callback: (response: any) => setVerificationToken(response.vToken),
  });
};

export const loginCallback =
  (
    getJwtToken: () => Promise<any>,
    setError: (message: string) => void,
    handleUnverifiedEmail: (email: string, regToken: string) => void,
    setVerificationToken: (token: string) => void
  ) =>
  (response: any) => {
    if (response.errorCode === 0) {
      getJwtToken().catch((error) =>
        // eslint-disable-next-line no-console
        console.error('Error updating jwt token: ', error)
      );
    } else if (
      (response.errorCode === 206006 || response.errorCode === 206001) &&
      !response.isVerified
    ) {
      handleUnverifiedEmail(response.profile.email, response.regToken);
      sendVerificationCode(response.profile.email, setVerificationToken);
    } else if (response.errorCode === 206001) {
      window.gigya.accounts.finalizeRegistration({
        regToken: response.regToken,
        callback: getJwtTokenCallback(getJwtToken),
      });
    } else
      setError(
        `This username or password may be incorrect. Make sure that you typed it correctly. Otherwise, contact your admin.`
      );
  };

export const onLogin = (
  params: {
    loginID: string;
    password: string;
  },
  getJwtToken: () => Promise<any>,
  setError: (message: string) => void,
  handleUnverifiedEmail: (email: string, regToken: string) => void,
  setVerificationToken: (token: string) => void
) => {
  window.gigya.accounts.login(params, {
    callback: loginCallback(
      getJwtToken,
      setError,
      handleUnverifiedEmail,
      setVerificationToken
    ),
  });
};

export const verifyEmailCallback =
  (getJwtToken: () => void, setError: (message: string) => void) =>
  (response: any) => {
    if (response.errorCode === 0) {
      window.gigya.accounts.finalizeRegistration({
        regToken: response.requestParams.regToken,
        callback: getJwtTokenCallback(getJwtToken),
      });
    } else
      setError(
        `This code does not match the one given, please check and try again.`
      );
  };

export const verifyEmail = (
  code: string,
  registrationToken: string,
  verificationToken: string,
  getJwtToken: () => void,
  setError: (message: string) => void
) => {
  window.gigya.accounts.otp.update({
    apiKey: gigyaApiKey,
    vToken: verificationToken,
    code,
    regToken: registrationToken,
    callback: verifyEmailCallback(getJwtToken, setError),
  });
};

export const createAccount =
  (
    handleUnverifiedEmail: (email: string, token: string) => void,
    setVerificationToken: (token: string) => void,
    setError: (message: string) => void
  ) =>
  (response: any) => {
    if (response.errorCode === 206001) {
      handleUnverifiedEmail(response.profile.email, response.regToken);
      sendVerificationCode(response.profile.email, setVerificationToken);
    } else if (
      response.errorCode === 400009 &&
      response.validationErrors[0].errorCode === 400003
    ) {
      setError(
        'An account with this email already exists. Please use another email.'
      );
    } else {
      setError('The email or password used may be invalid.');
    }
  };

export const registerCallback =
  (
    params: {
      email: string;
      password: string;
    },
    handleUnverifiedEmail: (email: string, token: string) => void,
    setVerificationToken: (token: string) => void,
    setError: (message: string) => void
  ) =>
  (response: any) => {
    if (response.errorCode === 0) {
      window.gigya.accounts.register({
        email: params.email,
        password: params.password,
        regToken: response.regToken,
        profile: {
          gender: 'u',
        },
        callback: createAccount(
          handleUnverifiedEmail,
          setVerificationToken,
          setError
        ),
      });
    } else {
      setError('The email or password used may be invalid.');
    }
  };

export const onRegister = (
  params: {
    email: string;
    password: string;
  },
  handleUnverifiedEmail: (email: string, token: string) => void,
  setVerificationToken: (token: string) => void,
  setError: (message: string) => void
) => {
  window.gigya.accounts.initRegistration({
    callback: registerCallback(
      params,
      handleUnverifiedEmail,
      setVerificationToken,
      setError
    ),
  });
};

export const onResetPasswordSendEmailCallback =
  (resolve: any, reject: any) => (response: any) => {
    if (response.errorCode === 0) {
      resolve(true);
    } else if (response.errorCode === 403047) reject('Invalid email address');
    else reject('An error occured. Please try again.');
  };

export const onResetPasswordSendEmail = (params: { loginID: string }) =>
  new Promise((resolve, reject) => {
    if (window && window.gigya) {
      window.gigya.accounts.resetPassword({
        loginID: params.loginID,
        callback: onResetPasswordSendEmailCallback(resolve, reject),
      });
    }
  });

export const resetPasswordCallback =
  (setState: (state: AuthState) => void, setError: (message: string) => void) =>
  (response: any) => {
    if (response.errorCode === 0) {
      setState(AuthState.PasswordResetSuccess);
    } else
      setError(
        'Error, reset password failed, please request new password link'
      );
  };

export const resetPassword = (
  params: {
    passwordResetToken: string;
    newPassword: string;
  },
  setState: (state: AuthState) => void,
  setError: (message: string) => void
) => {
  window.gigya.accounts.resetPassword(params, {
    callback: resetPasswordCallback(setState, setError),
  });
};
