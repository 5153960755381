import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import { Chip as MUIChip, ChipProps as MUIChipProps } from '@material-ui/core';

import useStyles from './Chip.styles';

export type ChipProps = MUIChipProps;

const Chip = React.forwardRef((props: ChipProps, ref: React.Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return (
    <MUIChip ref={ref} classes={classes} data-testid="chip" {...otherProps} />
  );
});

Chip.displayName = 'Chip';

export default Chip;
