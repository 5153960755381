import { ReactComponent as EllipsisVertical } from 'assets/icons/TargetingV2/dax-ellipsisVertical.svg';
import classNames from 'classnames';
import IconButton from 'features/targetingV2/components/core/IconButton';
import IconButtonMenuItem, {
  IContextMenuItemType,
} from 'features/targetingV2/components/core/IconButtonMenuItem/IconButtonMenuItem';
import Menu from 'features/targetingV2/components/core/Menu/Menu';
import MenuItem from 'features/targetingV2/components/core/MenuItem';
import React from 'react';

import useStyles from './IconButtonMenu.styles';

export interface IIconButtonMenuProps {
  MenuIconButton?: React.ReactNode;
  menuIconButtonLabel: string;
  menuOptions?: IContextMenuItemType[];
}

const IconButtonMenu = (props: IIconButtonMenuProps) => {
  const {
    MenuIconButton = <EllipsisVertical />,
    menuIconButtonLabel,
    menuOptions = [],
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'icon-button-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        disableRipple
        classes={{
          root: classNames(classes.menuIconButton, {
            [`${classes.menuIconButton}--open`]: open,
          }),
        }}
      >
        {MenuIconButton}
        <span className="sr-only">{menuIconButtonLabel} Context Menu Icon</span>
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-label': 'icon-button-menu',
        }}
        PaperProps={{
          className: classes.paper,
        }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!menuOptions.length ? (
          <MenuItem className={classes.noOptionsText}>No options</MenuItem>
        ) : (
          menuOptions.map((menuOption: IContextMenuItemType) => (
            <IconButtonMenuItem
              key={`${menuOption.menuItemIconLabel}-option`}
              menuOption={menuOption}
              handleClose={handleClose}
            />
          ))
        )}
      </Menu>
    </>
  );
};

export default IconButtonMenu;
