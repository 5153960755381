import achievedobjective from 'assets/images/sanities/achievedobjective.svg';
import delivering from 'assets/images/sanities/delivering.svg';
import endingsoon from 'assets/images/sanities/endingsoon.svg';
import failedobjective from 'assets/images/sanities/failedobjective.svg';
import incompletesequence from 'assets/images/sanities/incompletesequence.svg';
import missingcreative from 'assets/images/sanities/missingcreative.svg';
import notdelivering from 'assets/images/sanities/notdelivering.svg';
import overdelivering from 'assets/images/sanities/overdelivering.svg';
import recentlyupdated from 'assets/images/sanities/recentlyupdated.svg';
import startingsoon from 'assets/images/sanities/startingsoon.svg';
import underdelivering from 'assets/images/sanities/underdelivering.svg';

import { Sanity, SanityType } from 'interfaces/generated.types';

export enum SanityEnum {
  ACHIEVED_OBJECTIVE = 'ACHIEVED_OBJECTIVE',
  FAILED_OBJECTIVE = 'FAILED_OBJECTIVE',
  RECENTLY_UPDATED = 'RECENTLY_UPDATED',
  STARTING_SOON = 'STARTING_SOON',
  ENDING_SOON = 'ENDING_SOON',
  OVER_DELIVERING = 'OVER_DELIVERING',
  NOT_DELIVERING = 'NOT_DELIVERING',
  UNDER_DELIVERING = 'UNDER_DELIVERING',
  MISSING_CREATIVE = 'MISSING_CREATIVE',
  DELIVERING = 'DELIVERING',
  INCOMPLETE_SEQUENCE = 'INCOMPLETE_SEQUENCE',
}

export interface ISanity extends Omit<Sanity, 'id'> {
  id: SanityEnum;
}

export const sanityIconMap = {
  [SanityEnum.ACHIEVED_OBJECTIVE]: achievedobjective,
  [SanityEnum.FAILED_OBJECTIVE]: failedobjective,
  [SanityEnum.RECENTLY_UPDATED]: recentlyupdated,
  [SanityEnum.STARTING_SOON]: startingsoon,
  [SanityEnum.ENDING_SOON]: endingsoon,
  [SanityEnum.OVER_DELIVERING]: overdelivering,
  [SanityEnum.NOT_DELIVERING]: notdelivering,
  [SanityEnum.UNDER_DELIVERING]: underdelivering,
  [SanityEnum.MISSING_CREATIVE]: missingcreative,
  [SanityEnum.DELIVERING]: delivering,
  [SanityEnum.INCOMPLETE_SEQUENCE]: incompletesequence,
};

export const sanityCodesMap = {
  'Achieved objective': SanityEnum.ACHIEVED_OBJECTIVE,
  'Failed objective': SanityEnum.FAILED_OBJECTIVE,
  'Starting soon': SanityEnum.STARTING_SOON,
  'Ending soon': SanityEnum.ENDING_SOON,
  'Over delivering': SanityEnum.OVER_DELIVERING,
  'Not delivering': SanityEnum.NOT_DELIVERING,
  'Under delivering': SanityEnum.UNDER_DELIVERING,
  'Missing Creative': SanityEnum.MISSING_CREATIVE,
  Delivering: SanityEnum.DELIVERING,
  'Incomplete sequence': SanityEnum.INCOMPLETE_SEQUENCE,
};

export type SanityLabel = keyof typeof sanityCodesMap;

export const missingCreativeSanity = {
  id: SanityEnum.MISSING_CREATIVE,
  type: SanityType.Unhealthy,
  name: 'Missing Creative',
};
