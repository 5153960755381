import targetingTooltipStyles from 'features/targetingV2/styles/targetingTooltip.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...targetingTooltipStyles(theme),
  section: {
    padding: theme.spacing(2),
  },
  innerSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconRemove: {
    alignSelf: 'flex-end',
  },
  text: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginRight: theme.spacing(1),
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
