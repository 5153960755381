import classNames from 'classnames';
import Control from 'features/targetingV2/components/core/MultiSelect/components/Control/Control';
import Menu from 'features/targetingV2/components/core/MultiSelect/components/Menu/Menu';
import MenuList from 'features/targetingV2/components/core/MultiSelect/components/MenuList/MenuList';
import MultiValue from 'features/targetingV2/components/core/MultiSelect/components/MultiValue/MultiValue';
import Option from 'features/targetingV2/components/core/MultiSelect/components/Option/Option';
import ValueContainer from 'features/targetingV2/components/core/MultiSelect/components/ValueContainer/ValueContainer';
import {
  asyncCustomStyles,
  useStyles,
} from 'features/targetingV2/components/core/MultiSelect/MultiSelect/MultiSelect.styles';
import { IOptionType } from 'features/targetingV2/types/common';
import { OptionType } from 'interfaces';
import React, { useState } from 'react';
import { GroupTypeBase, InputActionTypes } from 'react-select';
import AsyncSelect from 'react-select/async';
import { SelectComponents } from 'react-select/src/components';

import NoSsr from '@material-ui/core/NoSsr';

const components = {
  Control,
  Menu,
  MenuList,
  MultiValue,
  Option,
  ValueContainer,
};

interface IAsyncMultiSelectProps {
  id: string;
  name: string;
  value: OptionType | OptionType[] | null;
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  maxSelectHeight?: number | string;
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  noOptionsText?: string | null;
  errorProps?: {
    helperText?: string | boolean;
    FormHelperTextProps: {
      error: boolean;
    };
  };
  attributes?: {
    fieldAttribute: string;
    menuAttribute: string;
    chipAttribute: string;
  };
  components?: Partial<
    SelectComponents<IOptionType, boolean, GroupTypeBase<IOptionType>>
  >;
  externalClasses?: {
    root?: string;
    input?: string;
    optionLabelText?: string;
    optionCheckbox?: string;
  };
  externalLink?: boolean;
  noOfMultiValuesToShow?: number;
  showBreadcrumbsOnMultiValue?: boolean;
  loadOptions: any;
  fetchedOptions?: IOptionType[];
  selectAll?: boolean;
  minimumCharactersRequired?: number;
  autoFocus?: boolean;
  selectRef?: any;
}

const AsyncMultiSelect = (props: IAsyncMultiSelectProps) => {
  const {
    id,
    name,
    value,
    isClearable = true,
    isDisabled = false,
    label = '',
    maxSelectHeight = 'auto',
    placeholder,
    onBlur,
    onChange,
    noOptionsText = 'No options',
    errorProps,
    attributes,
    components: externalComponents,
    externalClasses = {},
    externalLink = false,
    noOfMultiValuesToShow,
    showBreadcrumbsOnMultiValue = false,
    loadOptions,
    fetchedOptions,
    selectAll,
    minimumCharactersRequired = 2,
    autoFocus = false,
    selectRef,
  } = props;

  const classes = useStyles();
  const [multiSelectQuery, setMultiSelectQuery] = useState('');

  const [showHiddenMultiValues, setShowHiddenMultiValues] = useState(
    noOfMultiValuesToShow === undefined
  );

  return (
    <div
      className={classNames([classes.root, externalClasses.root])}
      data-testid={`${id}-async-select-container`}
    >
      <NoSsr>
        <AsyncSelect
          testId={`${id}-input`}
          inputId={id}
          name={name}
          value={value}
          isClearable={isClearable}
          isDisabled={isDisabled}
          maxMenuHeight={250}
          maxSelectHeight={maxSelectHeight}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          noOptionsMessage={() => noOptionsText}
          components={{
            ...components,
            ...externalComponents,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          classes={classes}
          externalClasses={externalClasses}
          styles={asyncCustomStyles}
          externalLink={externalLink}
          noOfMultiValuesToShow={noOfMultiValuesToShow}
          showBreadcrumbsOnMultiValue={showBreadcrumbsOnMultiValue}
          openMenuOnClick={false}
          menuIsOpen={multiSelectQuery.length >= minimumCharactersRequired}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          onInputChange={(
            query: string,
            { action }: { action: InputActionTypes }
          ) => {
            if (action === 'input-change') setMultiSelectQuery(query);
            if (action === 'menu-close') setMultiSelectQuery('');
          }}
          inputValue={multiSelectQuery}
          AttributeProps={{ ...attributes }}
          TextFieldProps={{
            label,
            helperText: errorProps?.helperText,
            FormHelperTextProps: errorProps?.FormHelperTextProps,
            InputLabelProps: {
              htmlFor: id,
              shrink: true,
            },
          }}
          loadOptions={loadOptions}
          defaultOptions={fetchedOptions}
          selectAll={selectAll}
          allowSelectAll
          showHiddenMultiValues={showHiddenMultiValues}
          setShowHiddenMultiValues={setShowHiddenMultiValues}
          autoFocus={autoFocus}
          ref={selectRef}
        />
      </NoSsr>
    </div>
  );
};

export default AsyncMultiSelect;
