import { Territory } from 'interfaces/generated.types';

export interface SessionStatePageFilter {
  id: string;
  value: string;
}

export interface SessionStatePage {
  filters: SessionStatePageFilter[];
  globalFilter: string;
}

export interface SessionStateUser {
  territories?: Territory[];
  activeTerritory?: Territory;
}

export interface SessionState {
  pages: {
    [name: string]: SessionStatePage;
  };
  user: SessionStateUser;
}

const initialSessionState: SessionState = {
  pages: {},
  user: {},
};

export default initialSessionState;
