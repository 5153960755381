import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import * as Sentry from '@sentry/browser';

import ErrorPage from 'pages/ErrorPage/ErrorPage';

interface IErrorBoundaryProps extends RouteComponentProps<any> {
  children: any;
}

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<IErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, history } = this.props;
    if (hasError) {
      return (
        <ErrorPage
          match={{ params: { errorCode: 'UNKNOWN' } }}
          history={history}
        />
      );
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);
