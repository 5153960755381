import pageStyles from 'assets/styles/components/Page.styles';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import XYDateChart from 'components/XYDateChart/XYDateChart';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { IDateRange } from 'interfaces';
import {
  PublisherInsightsFilterAttributes,
  PublisherInsightsMetric,
  PublisherInsightsMetricType,
  PublisherInsightsTimeSeries,
  PublisherInsightsTimeSeriesUnit,
  usePublisherInsightsTimeSeriesQuery,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import dateUtils from 'utils/date';
import { getDefaultTimezoneBasedOnTerritory } from 'utils/defaultsByTerritory';
import {
  datePresetOptions,
  formatChartData,
  getDataSet,
  getInsightsMetricTotalValue,
  getLineChartErrorMessage,
  IFormattedInsightsLineGraphData,
  ISelectedFilter,
  isPercentageChart,
  toolTipNumberFormat,
} from 'utils/insights';

import { Grid, Typography } from '@material-ui/core';

import MetricsDropdown from '../MetricsDropdown/MetricsDropdown';
import DataTrend from './DataTrend';
import useStyles from './InsightsLineChart.styles';

interface IInsightsLineChartProps {
  metric: PublisherInsightsMetric;
  testId: string;
  chartId: string;
  onMetricChange: (chartId: string, value: PublisherInsightsMetric) => void;
  selectedDateRange: IDateRange;
  selectedFilters: ISelectedFilter[];
  selectedCurrency: string;
}

const InsightsLineChart = ({
  metric,
  testId,
  chartId,
  onMetricChange,
  selectedDateRange,
  selectedFilters,
  selectedCurrency,
}: IInsightsLineChartProps) => {
  const classes = { ...pageStyles(), ...useStyles() };

  const [chartData, setChartData] = useState<IFormattedInsightsLineGraphData[]>(
    []
  );

  const [totalValue, setTotalValue] = useState<number>(0);
  const [datePreset, setDatePreset] = useState<number>(7);
  const [metricType, setMetricType] = useState<PublisherInsightsMetricType>();
  const [dataTrendError, setDataTrendError] = useState<string>('');

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const timeZone = getDefaultTimezoneBasedOnTerritory(activeTerritory!);

  const formattedSelectedFilters: PublisherInsightsFilterAttributes[] =
    selectedFilters.map((selectedFilter) => ({
      type: selectedFilter.filterCategory.type,
      ids: selectedFilter.ids,
    }));

  const newDatePreset = differenceInCalendarDays(
    addDays(selectedDateRange.endDate, 1),
    selectedDateRange.startDate
  );

  const isDataTrendVisible = datePresetOptions.includes(datePreset);

  useEffect(() => {
    setDatePreset(newDatePreset);
  }, [newDatePreset]);

  const { loading, error } = usePublisherInsightsTimeSeriesQuery({
    variables: {
      metrics: metric,
      startDate: dateUtils.getIsoStringInSpecificTimezone(
        selectedDateRange.startDate,
        timeZone
      ),
      endDate: dateUtils.getIsoStringInSpecificTimezone(
        selectedDateRange.endDate,
        timeZone
      ),
      timeZone,
      timeUnit: PublisherInsightsTimeSeriesUnit.Day,
      currency: selectedCurrency,
      filters: formattedSelectedFilters,
      includeWeightingMetrics: true,
    },
    onCompleted: (result) => {
      const newData = getDataSet(
        metric,
        result.publisherInsightsTimeSeries || []
      );
      setMetricType(newData?.metricType);

      const weightingData = newData?.weightingMetric
        ? getDataSet(
            newData.weightingMetric,
            result.publisherInsightsTimeSeries || []
          )
        : undefined;

      const formattedChartData = newData
        ? formatChartData(
            newData as PublisherInsightsTimeSeries,
            timeZone,
            weightingData
          )
        : [];

      setChartData(formattedChartData);
      if (newData) {
        setTotalValue(newData.totalValue);
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onMetricChange(chartId, event.target.value as PublisherInsightsMetric);
  };

  const variables = {
    metric,
    formattedSelectedFilters,
    timeZone,
    selectedCurrency,
  };

  const isGraphLoadedWithoutError = !loading && !error;

  return (
    <Grid container spacing={2} className={classes.insightsContainer}>
      <Grid item xs={4}>
        <MetricsDropdown handleChange={handleChange} initialMetric={metric} />
      </Grid>
      {!loading && error && (
        <div className={classes.errorContainer}>
          <Typography
            className={classNames(
              classes.errorMessage,
              classes.errorMessageWrapper
            )}
            data-testid="MonetizeInsightsTimeseriesError"
          >
            {getLineChartErrorMessage(metric, error) ||
              'Error, issues have occurred loading graph data'}
          </Typography>
        </div>
      )}

      {isGraphLoadedWithoutError && (
        <Typography
          className={classes.metricContainer}
          data-testid={`total-value-${chartId}`}
        >
          {getInsightsMetricTotalValue(
            metricType!,
            totalValue,
            selectedCurrency
          )}
        </Typography>
      )}

      {isGraphLoadedWithoutError && isDataTrendVisible && (
        <DataTrend
          variables={variables}
          selectedDateRange={selectedDateRange}
          totalValue={totalValue}
          datePreset={datePreset}
          setDataTrendError={setDataTrendError}
        />
      )}

      <div className={classes.chartContainer}>
        {loading && <Loader />}
        {isGraphLoadedWithoutError && (
          <XYDateChart
            showScrollbar={false}
            customClasses={classes}
            data={chartData}
            initialSeries={metric}
            testId={testId}
            chartId={chartId}
            isPercentageChart={isPercentageChart(metric)}
            toolTipNumberFormat={toolTipNumberFormat(metric)}
            showVerticalGridLines={false}
          />
        )}
      </div>

      {isGraphLoadedWithoutError && isDataTrendVisible && dataTrendError && (
        <div className={classes.errorContainer}>
          <Typography
            className={classNames(classes.errorMessage)}
            data-testid="MonetizeInsightsTimeseriesMinimalError"
          >
            {dataTrendError}
          </Typography>
        </div>
      )}
    </Grid>
  );
};

export default InsightsLineChart;
