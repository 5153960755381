import { baseCheckboxStyles } from 'assets/styles/components/Checkbox.styles';
import { baseFormStyles } from 'assets/styles/components/Form.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFormStyles(theme),
  ...baseCheckboxStyles(theme),
  checkboxesContainer: {
    marginLeft: theme.spacing(2.5),
    '&:first-child': {
      marginLeft: '0',
    },
    '&--readOnly': {
      marginLeft: theme.spacing(5),
    },
  },
  territoryDropdown: {
    marginRight: theme.spacing(20),
  },
  territories: {
    margin: theme.spacing(2, 0, 1),
  },
}));

export default useStyles;
