import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, Ref } from 'react';

import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
} from '@material-ui/core';

import useStyles from './IconButton.styles';

export type IconButtonProps = MUIIconButtonProps;

const IconButton = forwardRef((props: IconButtonProps, ref: Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUIIconButton ref={ref} classes={classes} {...otherProps} />;
});

IconButton.displayName = 'IconButton';

export default IconButton;
