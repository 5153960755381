import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import ShowContractForm from 'features/inventory/channel/components/ShowContractForm/ShowContractForm';
import {
  IFormattedShowContract,
  IShowContractValues,
} from 'features/inventory/channel/components/ShowContractForm/ShowContractValues';
import ShowContractFormValidation from 'features/inventory/channel/validations/showContracts';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import useError from 'hooks/Error/useError';
import { IFormValues } from 'interfaces';
import {
  ShowContract,
  UpdateShowContractMutationFn,
  useUpdateShowContractMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React from 'react';
import { getContractTerritory, getContractTimezone } from 'utils/contracts';
import { differenceInValues, handleFormErrors } from 'utils/forms';
import { calculatePercentage, numbroDecimalFormatting } from 'utils/numbers';
import { getShowNameOption } from 'utils/shows';

export interface IUpdateShowContractContainerProps {
  showContract: IFormattedShowContract;
  handleClose: () => void;
  refetch: () => void;
  readOnly?: boolean;
  hasEditPermissions?: boolean;
}

export const submitUpdateShowContract =
  (
    updateShowContract: UpdateShowContractMutationFn,
    initialValues: IShowContractValues,
    toggleErrorModal: () => void,
    showContract: ShowContract
  ) =>
  (
    formValues: IShowContractValues,
    { setStatus, setSubmitting }: FormikActions<IShowContractValues>
  ) => {
    const filteredValues: any = {
      ...differenceInValues(
        { ...formValues, showId: formValues.showName?.value },
        { ...initialValues, showId: initialValues.showName?.value }
      ),
      id: showContract.id,
    } as IFormValues;

    delete filteredValues.timeZone;
    delete filteredValues.showName;

    updateShowContract({
      variables: filteredValues,
    }).catch((error) =>
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      })
    );
  };

const UpdateShowContractContainer = ({
  showContract,
  handleClose,
  refetch,
  readOnly = false,
  hasEditPermissions = false,
}: IUpdateShowContractContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateShowContract] = useUpdateShowContractMutation({
    onCompleted() {
      refetch();
      handleClose();
    },
  });

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Contract.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const timeZone = getContractTimezone(showContract, activeTerritory!);
  const contractTerritory = getContractTerritory(
    showContract,
    activeTerritory!
  );

  const initialValues: IShowContractValues = {
    id: showContract.id,
    showName: getShowNameOption(showContract.show),
    startDate: showContract.startDate,
    endDate: showContract.endDate,
    timeZone,
    currency: showContract.currency,
    directSpotCpm:
      typeof showContract.directSpotCpm === 'number'
        ? numbro(showContract.directSpotCpm).format(numbroDecimalFormatting)
        : '',
    programmaticSpotCpm:
      typeof showContract.programmaticSpotCpm === 'number'
        ? numbro(showContract.programmaticSpotCpm).format(
            numbroDecimalFormatting
          )
        : '',
    spotRevenueShare:
      typeof showContract.spotRevenueShare === 'number'
        ? calculatePercentage(showContract.spotRevenueShare, 100)
        : '',
    hostReadRevenueShare:
      typeof showContract.hostReadRevenueShare === 'number'
        ? calculatePercentage(showContract.hostReadRevenueShare, 100)
        : '',
    hostReadCpm:
      typeof showContract.hostReadCpm === 'number'
        ? numbro(showContract.hostReadCpm).format(numbroDecimalFormatting)
        : '',
    sponsorshipRevenueShare:
      typeof showContract.sponsorshipRevenueShare === 'number'
        ? calculatePercentage(showContract.sponsorshipRevenueShare, 100)
        : '',
    notes: showContract.notes || '',
  };

  return (
    <>
      <Formik<IShowContractValues>
        initialValues={initialValues}
        onSubmit={submitUpdateShowContract(
          updateShowContract,
          initialValues,
          toggleErrorModal,
          showContract
        )}
        validationSchema={ShowContractFormValidation(contractTerritory)}
      >
        {(props) => (
          <ShowContractForm
            {...props}
            handleClose={handleClose}
            update
            hasEditPermissions={hasEditPermissions}
            readOnly={readOnly}
            testId="updateShowContractForm"
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => handleClose()}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateShowContractError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default UpdateShowContractContainer;
