import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  radioButton: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  radioLabel: {
    border: `1px dashed ${theme.palette.common.black}`,
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(0.625, 1.25),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
  },
  footer: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(1.875),
  },
  transcodes: {
    flex: '1',
    marginRight: theme.spacing(3.125),
  },
  transcode: {
    display: 'flex',
    alignItems: 'center',
  },
  transcodeName: {
    flex: '1',
    padding: theme.spacing(0, 1.875, 0, 0.625),
    fontSize: '0.813em',
  },
  transcodeAltId: {
    padding: theme.spacing(0, 1.875, 0, 0.625),
    fontSize: '0.813em',
  },
}));

export default useStyles;
