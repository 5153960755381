import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateNetworkContainer from 'features/inventory/network/containers/UpdateNetworkContainer/UpdateNetworkContainer';
import { History } from 'history';
import {
  Network,
  PermissionName,
  Publisher,
  useNetworkQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface UpdateNetworkPageProps {
  history: History;
  match: {
    params: {
      networkId: string;
    };
  };
  userPermissions: PermissionName[];
}

const networkEditPermissions = [PermissionName.Primary, PermissionName.Supply];

const UpdateNetworkPage = ({
  history,
  match,
  userPermissions,
}: UpdateNetworkPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data } = useNetworkQuery({
    variables: {
      id: match.params.networkId,
      territories: [activeTerritory!],
    },
    errorPolicy: 'all',
  });

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, networkEditPermissions) &&
    isActiveTerritoryAssignedToEntity(
      data?.network?.territories!,
      activeTerritory!
    );

  if (!loading && !data?.network) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Network</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateNetworkContainer
            network={data?.network as Network}
            history={history}
            match={match}
            hasEditPermissions={hasEditPermissions}
            publishers={(data?.network?.publishers as Publisher[]) || []}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateNetworkPage;
