import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import SeatDetails from 'features/programmatic/seat/components/SeatDetails/SeatDetails';
import {
  getSeatStatusData,
  ISeatFormValues,
  seatDetailsFields,
  seatStatusValues,
} from 'features/programmatic/seat/components/SeatTabsForm/SeatFormValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import { DspStatus, EntityType } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

export const seatButtons = [
  {
    text: 'Save and Review',
    url: '/seat/ID',
    update: true,
    dataTc: 'seatReviewButton',
  },
  {
    text: 'Save and View All Seats',
    url: '/dsp/PARENT_ID',
    update: false,
    dataTc: 'seatListButton',
  },
];

interface ISeatFormProps {
  update?: boolean;
  goBackTo: ILocation;
  hasEditPermissions?: boolean;
  dspStatus?: DspStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
}

const getSeatDetailsTabErrors = createNumberOfFormErrorsFn();

const SeatTabsForm = (props: IFormProps<ISeatFormValues> & ISeatFormProps) => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    values,
    goBackTo,
    hasEditPermissions = true,
    status,
    dspStatus,
    onFieldUpdate,
    onFieldValidate,
    history,
  } = props;
  const classes = tabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isSeatTab = selectedTab === 0;
  const isSeatActivityTab = selectedTab === 1;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && dspStatus && (
          <>
            <EntityProgressBar
              statusData={getSeatStatusData(values, dspStatus)}
              currentStatus={values.status || ''}
              dataTc="seatStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={seatStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="seatHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="seatTabs"
          >
            <Tab dataTc="seatDetailsTab" label="Details" />
            <Tab
              disabled={!update}
              dataTc="seatActivityTab"
              numberOfTabErrors={getSeatDetailsTabErrors(
                status,
                seatDetailsFields
              )}
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]: isSeatActivityTab,
            },
          ])}
        >
          {isSeatTab && <SeatDetails {...props} />}
          {isSeatActivityTab && (
            <ActivityTable
              entityType={EntityType.Seat}
              id={values.id}
              data-tc="seatActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitSeatButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={seatButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default SeatTabsForm;
