import useStyles from 'assets/styles/components/Form.styles';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { IOrderValues } from 'features/direct/order/components/OrderTabsForm/OrderFormValues';
import { Field } from 'formik';
import { fieldToSwitch, SwitchProps, TextField } from 'formik-material-ui';
import { FrequencyCapTimeUnits, IFormProps, OptionType } from 'interfaces';
import { User } from 'interfaces/generated.types';
import React from 'react';
import { createUserOption } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { orderExternalSystems } from 'utils/defaultExternalSystems';
import { TerritoryLabel } from 'utils/territory';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSwitch from '@material-ui/core/Switch';
import LaunchIcon from '@material-ui/icons/Launch';

interface IOrderDetails {
  allUsers: User[];
}

export const ObjectiveSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('objective', '');
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

export const EndDateSwitch = (props: SwitchProps) => {
  const {
    form: { setFieldValue },
    field: { name },
  } = props;
  const onChange = React.useCallback(
    (event) => {
      setFieldValue('endDate', null);
      setFieldValue(name, event.target.checked);
    },
    [setFieldValue, name]
  );
  return (
    <MuiSwitch {...fieldToSwitch(props)} onChange={onChange} color="primary" />
  );
};

const OrderDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  update = false,
  allUsers,
  status = {},
}: IFormProps<IOrderValues> & IOrderDetails) => {
  const classes = useStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <MultiSelect
              data-tc="orderAdvertiserMultiSelect"
              id="orderAdvertisers"
              isDisabled={update}
              isMulti={false}
              selectAll={false}
              label="Advertiser"
              name="advertiser"
              externalLink
              noMargin
              onBlur={() => setFieldTouched('advertiser', true)}
              onChange={(fieldValue: any) =>
                setFieldValue('advertiser', fieldValue)
              }
              options={values.allAdvertisers}
              placeholder="Select an advertiser..."
              value={values.advertiser}
              attributes={{
                fieldAttribute: 'advertiserField',
                menuAttribute: 'advertiserMenuOptions',
                chipAttribute: 'advertiserChip',
              }}
              errorProps={{
                helperText:
                  (touched.advertiser && errors.advertiser) ||
                  status.advertiser,
                FormHelperTextProps: {
                  error: !!(
                    (touched.advertiser && errors.advertiser) ||
                    status.advertiser
                  ),
                },
              }}
            />
            <IconButton
              href={`/advertiser/${
                values && values.advertiser && values.advertiser.value
              }`}
              target="_blank"
              classes={{
                root: classes.textFieldLinkMultiSelect,
              }}
              disabled={!values.advertiser}
              data-tc="orderAdvertiserLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for{' '}
                {values && values.advertiser && values.advertiser.label}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={4}>
          <MultiSelect
            data-tc="orderOwnerMultiSelect"
            data-testId="orderOwnerMultiSelect"
            id="orderOwnerMultiSelect"
            label="Order Owner"
            isMulti={false}
            selectAll={false}
            maxSelectHeight={250}
            name="owner"
            noMargin
            onBlur={() => setFieldTouched('owner', true)}
            onChange={(fieldValue: OptionType) =>
              setFieldValue('owner', fieldValue)
            }
            options={allUsers.map(
              (user) => createUserOption(user) as OptionType
            )}
            placeholder="Select an Owner..."
            value={values.owner}
            attributes={{
              fieldAttribute: 'orderOwnerField',
              menuAttribute: 'orderOwnerMenuOptions',
              chipAttribute: 'orderOwnerChip',
            }}
            errorProps={{
              helperText: (touched.owner && errors.owner) || status.owner,
              FormHelperTextProps: {
                error: !!((touched.owner && errors.owner) || status.owner),
              },
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Order Name"
            placeholder="Order Name"
            name="name"
            autoFocus={!update}
            data-tc="orderName"
            fullWidth
            inputProps={{ 'data-testid': 'orderName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Order ID"
              placeholder="Order ID"
              name="altId"
              data-tc="orderId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
              value={values.startDate}
              label="Order Start Date"
              name="startDate"
              onChange={(value) => setFieldValue('startDate', value)}
              onClose={() => setFieldTouched('startDate', true)}
              data-tc="orderStartDate"
            />
            {((((update && touched.timeZone) || touched.startDate) &&
              errors.startDate) ||
              status.startDate) && (
              <FormHelperText error data-tc="orderStartDateError">
                {errors.startDate || status.startDate}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <DateTimePicker
              initialFocusedDate={dateUtils.getInitialFocusedEndDate()}
              value={values.endDate}
              label="Order End Date"
              name="endDate"
              onChange={(value) => setFieldValue('endDate', value)}
              onClose={() => setFieldTouched('endDate', true, false)}
              disabled={values.noEndDate}
              data-tc="orderEndDate"
            />
            {((touched.endDate && errors.endDate) || status.endDate) && (
              <FormHelperText error data-tc="orderEndDateError">
                {errors.endDate || status.endDate}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={EndDateSwitch}
                  type="checkbox"
                  name="noEndDate"
                  data-tc="orderNoEndDate"
                  color="primary"
                />
              }
              label="No end date"
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="timeZone"
            fullWidth
            label="Time Zone"
            name="timeZone"
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            helperText={
              (touched.timeZone && errors.timeZone) || status.timeZone
            }
            FormHelperTextProps={{
              error: !!(
                (touched.timeZone && errors.timeZone) ||
                status.timeZone
              ),
            }}
          >
            {dateUtils.timezoneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="orderObjective"
            decimalScale={0}
            error={errors.objective || status.objective}
            label="Objective Impressions"
            name="objective"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.objective || !!status.objective}
            value={values.objective}
            fullWidth
            disabled={values.unlimitedObjective}
          />
          <FormControl component={'fieldset' as 'div'}>
            <FormControlLabel
              control={
                <Field
                  component={ObjectiveSwitch}
                  type="checkbox"
                  name="unlimitedObjective"
                  data-tc="orderUnlimitedObjective"
                />
              }
              label="Unlimited objective"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component={'fieldset' as 'div'} fullWidth>
            <InputLabel shrink>Frequency Capping</InputLabel>
            <div className={classes.frequencyCap}>
              <Field
                component={TextField}
                type="number"
                name="frequencyCapCount"
                data-tc="orderFrequencyCapCount"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <span className={classes.impressions}>impressions per</span>
              <Field
                component={TextField}
                name="frequencyCapValue"
                data-tc="orderFrequencyCapValue"
                type="number"
                classes={{ root: classes.numberFrequencyCap }}
              />
              <Field
                component={TextField}
                select
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  },
                }}
                name="frequencyCapTimeUnit"
                data-tc="orderFrequencyCapTimeUnit"
                classes={{ root: classes.selectFrequencyCap }}
              >
                {FrequencyCapTimeUnits.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </div>
            {(errors.frequencyCapCount || status.frequencyCapCount) && (
              <FormHelperText error data-tc="orderFrequencyCapCountError">
                {errors.frequencyCapCount || status.frequencyCapCount}
              </FormHelperText>
            )}
            {(errors.frequencyCapValue || status.frequencyCapValue) && (
              <FormHelperText error data-tc="orderFrequencyCapValueError">
                {errors.frequencyCapValue || status.frequencyCapValue}
              </FormHelperText>
            )}
            {(errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit) && (
              <FormHelperText error data-tc="orderFrequencyCapTimeUnitError">
                {errors.frequencyCapTimeUnit || status.frequencyCapTimeUnit}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            data-tc="orderExternalSystemId"
            fullWidth
            label="External System"
            name="externalSystemId"
            placeholder="External System"
            helperText={
              ((touched.externalSystemId || touched.externalId) &&
                errors.externalSystemId) ||
              status.externalSystemId
            }
            FormHelperTextProps={{
              error: !!(
                ((touched.externalId || touched.externalSystemId) &&
                  errors.externalSystemId) ||
                status.externalSystemId
              ),
            }}
          >
            <MenuItem value="" />
            {orderExternalSystems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="orderExternalId"
            fullWidth
            label="External ID"
            name="externalId"
            placeholder="External ID"
            helperText={
              ((touched.externalId || touched.externalSystemId) &&
                errors.externalId) ||
              status.externalId
            }
            FormHelperTextProps={{
              error: !!(
                ((touched.externalId || touched.externalSystemId) &&
                  errors.externalId) ||
                status.externalSystemId
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default OrderDetails;
