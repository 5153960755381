import { ReactComponent as NarrowerIcon } from 'assets/icons/TargetingV2/dax-account.svg';
import { ReactComponent as BroaderIcon } from 'assets/icons/TargetingV2/dax-crowd.svg';
import classNames from 'classnames';
import AccuracyValueLabel from 'features/targetingV2/components/AccuracyValueLabel/AccuracyValueLabel';
import FormControl from 'features/targetingV2/components/core/FormControl';
import Typography from 'features/targetingV2/components/core/Typography';
import { ISliderValue } from 'features/targetingV2/types/common';
import {
  getSelectedIndex,
  getSliderMarks,
  getValueByIndex,
} from 'features/targetingV2/utils/slider';
import isNumber from 'lodash/isNumber';
import React, { ReactNode, useState } from 'react';

import {
  Slider as MUISlider,
  ValueLabelProps as MUIValueLabelProps,
} from '@material-ui/core';

import useStyles from './Slider.styles';

export interface ISliderProps {
  allValues: ISliderValue[];
  value: number | string;
  onChange: (newValue: number | string) => void;
  disabled?: boolean;
  icons?: {
    LeftIcon: ReactNode;
    RightIcon: ReactNode;
  };
  labels?: {
    leftLabel: string;
    rightLabel: string;
  };
}

export type ValueLabelProps = MUIValueLabelProps;

const Slider = (props: ISliderProps) => {
  const {
    allValues,
    value,
    onChange,
    disabled = false,
    icons,
    labels = { leftLabel: 'Less precise', rightLabel: 'More precise' },
  } = props;
  const { leftLabel, rightLabel } = labels;
  const [sliderValue, setSliderValue] = useState(
    getSelectedIndex(value, allValues)
  );

  const classes = useStyles({ disabled });

  const handleSliderChange = (_: any, newIndex: number | number[]) => {
    if (isNumber(newIndex)) {
      setSliderValue(newIndex);
    }
  };

  const handleSliderCommit = (_: any, newIndex: number | number[]) => {
    if (isNumber(newIndex)) {
      const newAccuracyValue = getValueByIndex(newIndex, allValues);
      if (newAccuracyValue) {
        onChange(newAccuracyValue);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.labelSection}>
        <div className={classes.iconContainer}>
          {icons?.LeftIcon || <BroaderIcon className={classes.defaultIcon} />}
        </div>
        <Typography className={classes.labelText}>{leftLabel}</Typography>
      </div>
      <FormControl
        component={'fieldset' as 'div'}
        className={classes.sliderSection}
      >
        <MUISlider
          value={sliderValue}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderCommit}
          step={null}
          marks={getSliderMarks(allValues)}
          min={1}
          max={allValues.length}
          className={classes.slider}
          disabled={disabled}
          ValueLabelComponent={(valueLabelProps: ValueLabelProps) => (
            <AccuracyValueLabel allValues={allValues} {...valueLabelProps} />
          )}
        />
      </FormControl>
      <div className={classes.labelSection}>
        <Typography className={classes.labelText}>{rightLabel}</Typography>
        <div
          className={classNames(
            classes.iconContainer,
            `${classes.iconContainer}--positionedRight`
          )}
        >
          {icons?.RightIcon || <NarrowerIcon className={classes.defaultIcon} />}
        </div>
      </div>
    </div>
  );
};

export default Slider;
