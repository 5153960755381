import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  insightsContainer: {
    padding: theme.spacing(2, 3, 2, 0),
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)',
    margin: 0,
  },
  title: {
    fontSize: '1.25rem',
    marginLeft: theme.spacing(3),
  },
  metricContainer: {
    fontSize: '1.25rem',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  dataTrend: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2.5),
  },

  dataTrendContainer: {
    display: 'flex',
    marginLeft: theme.spacing(1.5),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5, 0.2, 0),
    '&--green': {
      marginBottom: theme.spacing(0.4),
    },
  },
  trendValue: {
    display: 'flex',
    alignItems: 'center',
  },
  dataTrendPositive: {
    fontSize: '0.75rem',
    color: theme.palette.success.main,
  },
  dataTrendNegative: {
    fontSize: '0.75rem',
    color: theme.palette.warning.main,
  },
  chartContainer: {
    width: '100%',
    height: '200px',
    padding: theme.spacing(2, 0, 0, 0),
  },
  // to overwrite the styles on the xyDateChart
  container: {
    width: '100%',
    height: '100%',
    fontSize: '12px',
  },
  errorMessageWrapper: {
    paddingTop: theme.spacing(2),
  },
  errorContainer: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
}));

export default useStyles;
