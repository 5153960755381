import memoizeOne from 'memoize-one';

import { OptionType } from 'interfaces';
import {
  AgencyStatus,
  SalesChannelStatus,
  Territory,
} from 'interfaces/generated.types';

export interface ISalesChannelValues {
  agency: OptionType | null;
  allAgencies: OptionType[];
  id: string;
  altId?: number;
  name: string;
  journey: string;
  owner: OptionType | null;
  status?: SalesChannelStatus;
  territory?: Territory;
}

export const salesChannelStatusValues = [
  {
    value: SalesChannelStatus.Draft,
    label: 'Draft',
    isDisabled: (value: SalesChannelStatus) =>
      [SalesChannelStatus.Active, SalesChannelStatus.Inactive].includes(value),
  },
  {
    value: SalesChannelStatus.Active,
    label: 'Active',
    isDisabled: (value: SalesChannelStatus) =>
      [SalesChannelStatus.Draft].includes(value),
  },
  {
    value: SalesChannelStatus.Inactive,
    label: 'Inactive',
    isDisabled: (value: SalesChannelStatus) =>
      [SalesChannelStatus.Draft].includes(value),
  },
];

export const getSalesChannelStatusData = memoizeOne(
  (values: ISalesChannelValues, agencyStatus: AgencyStatus) => [
    {
      status: SalesChannelStatus.Draft,
      required: [...(!values.name ? ["Complete 'Name'"] : [])],
    },
    {
      status:
        values.status === SalesChannelStatus.Inactive
          ? SalesChannelStatus.Inactive
          : SalesChannelStatus.Active,
      required: [
        ...(!values.name ? ["Waiting for 'Name'"] : []),
        ...(agencyStatus !== AgencyStatus.Enabled
          ? ['Waiting for Agency to be in ENABLED state']
          : []),
      ],
    },
  ]
);

export const salesChannelDetailsFields = [
  'agency',
  'name',
  'owner',
  'territory',
];
