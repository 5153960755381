import React from 'react';
import { Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';

import PrimaryControlForm, {
  IPrimaryControlValues,
} from 'features/primaryControls/components/PrimaryControlForm/PrimaryControlForm';
import { allTerritories } from 'utils/territory';
import {
  PrimaryControlEntity,
  PrimaryControlEntryInput,
  PrimaryControlMutationFn,
  PrimaryControlState,
  Territory,
  usePrimaryControlMutation,
} from 'interfaces/generated.types';
import useStyles from './PrimaryControlDialog.styles';

interface IPrimaryControlDialog {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  content: {
    title: string;
    message: string;
    note: string;
    cancel: string;
    continue: string;
    entity: PrimaryControlEntity;
    state: PrimaryControlState;
  };
  frozenTerritories: Territory[];
}

const PrimaryControlDialog = ({
  content,
  handleClose,
  isOpen,
  frozenTerritories,
  refetch,
}: IPrimaryControlDialog) => {
  const classes = useStyles({});

  const updatePrimaryControl = ({
    changePrimaryControl,
    formValues,
  }: {
    changePrimaryControl: PrimaryControlMutationFn;
    formValues: IPrimaryControlValues;
  }) => {
    const entries: PrimaryControlEntryInput[] = allTerritories.map(
      (territory) => ({
        territory,
        enabled: formValues.territories.includes(territory),
      })
    );

    changePrimaryControl({
      variables: {
        entity: content.entity,
        state: content.state,
        entries,
      },
    });
  };

  const [changePrimaryControl] = usePrimaryControlMutation({
    onCompleted() {
      refetch();
      handleClose();
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      data-testid="primaryControlDialog"
    >
      <header className={classes.header}>
        <h3 className={classes.title}>{content.title}</h3>
      </header>
      <div className={classes.body}>
        <p className={classes.message}>{content.message}</p>
        <Formik<IPrimaryControlValues>
          initialValues={{ territories: frozenTerritories || [] }}
          onSubmit={(values) =>
            updatePrimaryControl({
              changePrimaryControl,
              formValues: values,
            })
          }
        >
          {(props) => (
            <PrimaryControlForm
              {...props}
              handleClose={handleClose}
              content={content}
            />
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default PrimaryControlDialog;
