import gql from 'graphql-tag';

export const GET_ALL_TECHNICAL_PROVIDERS = gql`
  query AllTechnicalProviders($territories: [Territory!]!) {
    allTechnicalProviders(territories: $territories) {
      id
      altId
      name
      status
      userSyncUrl
      userSyncSecureUrl
    }
  }
`;

export const GET_TECHNICAL_PROVIDER_MINIMAL = gql`
  query TechnicalProviderMinimal($id: ID!) {
    technicalProvider(id: $id) {
      id
      name
      territories
    }
  }
`;

export const GET_TECHNICAL_PROVIDER = gql`
  query TechnicalProvider($id: ID!, $territories: [Territory!]) {
    technicalProvider(id: $id) {
      id
      altId
      name
      userSyncUrl
      userSyncSecureUrl
      currency
      blockGeoData
      truncateGpsCoords
      blockIpData
      truncateIpAddress
      block1stPartyData
      blockListenerId
      kvps1stPartyData
      gzipCompressed
      sendNotSyncedUsers
      blockSite
      siteName
      siteDomain
      blockApp
      appBundle
      appName
      appDomain
      blockPublisher
      publisherId
      publisherName
      publisherDomain
      publisherTagId
      status
      consentVendorId
      dspProvider
      dataCenters {
        id
        altId
        name
        status
      }
      dsps(territories: $territories) {
        id
        name
        altId
        status
      }
      vastVersions
      territories
      bidFloorPrecision
    }
  }
`;
