import pageStyles from 'assets/styles/components/Page.styles';
import Card from 'components/Card/Card';
import Impressions from 'components/Impressions/Impressions';
import Loader from 'components/Loader/Loader';
import Sanities from 'components/Sanities/Sanities';
import { ISanity } from 'components/Sanities/Sanities.values';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { campaignPacings } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import {
  Ad,
  Campaign,
  TimeSeries,
  useCampaignTimeSeriesQuery,
} from 'interfaces/generated.types';
import find from 'lodash/find';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import numbro from 'numbro';
import React, { lazy, Suspense } from 'react';
import { ChartAggregatorEnum } from 'utils/charts';
import { findLabelValue } from 'utils/dataTransformation';
import dateUtils, { TimeZones } from 'utils/date';
import addCommaToImpressions from 'utils/formatImpressionsValue';
import {
  convertNumberToPercentage,
  numbroDecimalFormatting,
} from 'utils/numbers';

import Grid from '@material-ui/core/Grid';

const XYDateChart = lazy(() => import('components/XYDateChart/XYDateChart'));

interface ICampaignMonitoring {
  id: string;
  timeZone: TimeZones;
}

export const transformCampaignMonitoringData = memoizeOne(
  (campaign?: Campaign) => {
    if (!campaign) {
      return [];
    }
    const { timeSeries, timeZone } = campaign;

    return [
      {
        label: 'Impressions',
        value: 'impressions',
        series: (timeSeries?.impressions as TimeSeries)?.dataPoints?.map(
          (point) => ({
            date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
            value: point.v,
          })
        ),
        aggregator: ChartAggregatorEnum.SUM,
      },
      {
        label: 'Listen through rate',
        value: 'ltr',
        series: (timeSeries?.ltr as TimeSeries)?.dataPoints?.map((point) => ({
          date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
          value:
            typeof point.v === 'number'
              ? Number((point.v * 100).toFixed(2))
              : null,
          weighting: get(
            find(timeSeries?.impressions?.dataPoints, { t: point.t }),
            'v',
            null
          ),
        })),
        aggregator: ChartAggregatorEnum.WEIGHTED_AVERAGE,
      },
    ];
  }
);

export const campaignHasAdsWithCompanionCreatives = (campaign: Campaign) =>
  campaign?.ads.some(
    (ad: Ad) =>
      ad.creativeImages.length > 0 ||
      (ad.creativeRedirect !== null && ad.creativeRedirect !== undefined)
  );

export const getDisplayedClickThroughRate = (campaign: Campaign) => {
  let clickThroughRate = '-';

  const hasCompanionMetrics = campaignHasAdsWithCompanionCreatives(campaign);

  if (hasCompanionMetrics) {
    clickThroughRate = convertNumberToPercentage(
      campaign.clickThroughRate,
      'N/A'
    );
  }

  return clickThroughRate;
};

const CampaignMonitoring = ({ id, timeZone }: ICampaignMonitoring) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { data, loading, error } = useCampaignTimeSeriesQuery({
    variables: { id, timeZone, territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  const classes = pageStyles();

  const noData = !(data && data.campaign);

  if (!loading && (error || noData))
    return (
      <p className={classes.errorMessage} data-testid="campaignMonitoringError">
        Error, issues have occurred loading graph data
      </p>
    );

  const hasCompanionMetrics = campaignHasAdsWithCompanionCreatives(
    data?.campaign as Campaign
  );
  const clickThroughRateDisplayed = getDisplayedClickThroughRate(
    data?.campaign as Campaign
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <Suspense fallback={<Loader />}>
        <XYDateChart
          data={transformCampaignMonitoringData(data?.campaign as Campaign)}
          initialSeries="impressions"
          showSelector
          testId="campaignMonitoringChart"
        />
      </Suspense>
      {data?.campaign && (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Card title="Health" data-testid="campaignHealth">
              <Sanities sanities={data.campaign.sanities as ISanity[]} />
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Objective Impressions" data-testid="campaignObjective">
              {data.campaign.objective
                ? numbro(data.campaign.objective).format(
                    numbroDecimalFormatting
                  )
                : 'N/A'}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Objective Completion" data-testid="campaignCompletion">
              <Impressions
                showObjective={false}
                objective={data.campaign.objective}
                impressions={data.campaign.impressions}
              />
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Pacing" data-testid="campaignPacing">
              {findLabelValue({
                collection: campaignPacings,
                lookupValue: data.campaign.pacing,
              })}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Actual Impressions" data-testid="campaignImpressions">
              {addCommaToImpressions(data.campaign.impressions)}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card
              title="Companion Impressions"
              data-testid="companionImpressions"
            >
              {hasCompanionMetrics ? data.campaign.companionImpressions : '-'}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Click Through Rate" data-testid="clickThroughRate">
              {clickThroughRateDisplayed}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card title="Listen Through Rate" data-testid="ltr">
              {convertNumberToPercentage(data.campaign.ltr, 'N/A')}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CampaignMonitoring;
