import Divider from 'features/targetingV2/components/core/Divider';
import Typography from 'features/targetingV2/components/core/Typography';
import { TooltipContent as ITooltipContent } from 'features/targetingV2/utils/tooltip';
import { camelCase } from 'lodash';
import React from 'react';

import useStyles from './TooltipContent.styles';

interface ITooltipContentProps {
  tooltipContent: ITooltipContent;
  'data-testid'?: string;
}

const TooltipContent = ({
  tooltipContent,
  'data-testid': testId,
}: ITooltipContentProps) => {
  const { title, content, links } = tooltipContent;
  const classes = useStyles();

  return (
    <div data-testid={testId}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.bodyWrapper}>
        {content.map((line) => (
          <Typography
            key={camelCase(line)}
            variant="body2"
            className={classes.body}
          >
            {line}
          </Typography>
        ))}
        {links?.length ? (
          <>
            <Divider />
            <div className={classes.helpTitle}>
              <Typography variant="caption">HELP & TUTORIALS</Typography>
            </div>
            {links.map((link) => (
              <Typography
                variant="body2"
                key={link.resourceLink}
                className={classes.link}
              >
                <a
                  href={link.resourceLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.content}
                </a>
              </Typography>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TooltipContent;
