import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    margin: theme.spacing(0, 16, 0),
  },
  dialogTitle: {
    fontSize: theme.spacing(2.625),
    margin: theme.spacing(3, 'auto', 0, 'auto'),
  },
  dialogSubtitle: {
    fontSize: theme.spacing(2),
    margin: theme.spacing(1.5, 'auto', 0, 'auto'),
  },
  currentBlockListsWrapper: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  blockListContainer: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.8),
    padding: theme.spacing(0.9, 2.38, 0.9, 2.38),
    marginTop: theme.spacing(0.5),
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 'auto'),
  },
}));

export default useStyles;
