import formStyles from 'assets/styles/components/Form.styles';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { ISeatFormValues } from 'features/programmatic/seat/components/SeatTabsForm/SeatFormValues';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import React from 'react';

import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LaunchIcon from '@material-ui/icons/Launch';

interface ISeatDetailsProps {
  update?: boolean;
}

const SeatDetails = ({
  errors,
  touched,
  update = false,
  values,
  status = {},
  setFieldTouched,
  setFieldValue,
}: IFormProps<ISeatFormValues> & ISeatDetailsProps) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();
  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Dsp Name"
              placeholder="Dsp Name"
              name="dspName"
              data-tc="dspName"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/dsp/${values.dspId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="seatDspLink"
            >
              <LaunchIcon />
              <span className="sr-only">View details for {values.dspId}</span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            data-tc="seatName"
            fullWidth
            label="Name"
            name="name"
            autoFocus={!update}
            placeholder="Name"
            inputProps={{ 'data-testid': 'seatName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Seat ID"
              placeholder="Seat ID"
              name="altId"
              data-tc="seatId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <Field
            component={TextField}
            data-tc="externalSeatId"
            fullWidth
            label="External Seat ID"
            name="externalSeatId"
            placeholder="External Seat ID"
            helperText={
              (touched.externalSeatId && errors.externalSeatId) ||
              status.externalSeatId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalSeatId && errors.externalSeatId) ||
                status.externalSeatId
              ),
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <MultiSelect
            id="territories"
            options={(values.dspTerritories || []).map((territoryOption) =>
              territories?.includes(territoryOption.value)
                ? territoryOption
                : { ...territoryOption, readOnly: true }
            )}
            value={values.territories}
            name="territories"
            onChange={(fieldValue: any) => {
              setFieldValue(
                'territories',
                fieldValue.filter(
                  (territoryOption: OptionType) => !territoryOption.readOnly
                )
              );
            }}
            onBlur={() => setFieldTouched('territories', true)}
            label="Territory"
            isDisabled={territories && territories.length <= 1}
            errorProps={{
              helperText:
                (touched.territories && errors.territories) ||
                status.territories,
              FormHelperTextProps: {
                error: !!(
                  (touched.territories && errors.territories) ||
                  status.territories
                ),
              },
            }}
            noMargin
            dataTc="territoriesMultiSelect"
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default SeatDetails;
