import classNames from 'classnames';
import { ILocation } from 'interfaces';
import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './StyledLink.styles';

export enum LinkColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Plain = 'plain',
  Filled = 'filled',
}

const StyledLink = ({
  children,
  location,
  color = LinkColorEnum.Plain,
  compact = false,
  onClick = () => {},
}: {
  children: any;
  location: string | ILocation;
  color?: LinkColorEnum;
  compact?: boolean;
  onClick?: () => void;
}) => {
  const classes: any = useStyles({ compact });
  const styledLinkClass = classNames({
    [classes[color]]: color,
  });

  return (
    <Link className={styledLinkClass} to={location} onClick={onClick}>
      {children}
    </Link>
  );
};

export default StyledLink;
