import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React, { forwardRef, Ref } from 'react';

import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@material-ui/core';

import useStyles from './Typography.styles';

export type TypographyProps = MUITypographyProps;

const Typography = forwardRef((props: TypographyProps, ref: Ref<any>) => {
  const { classes: userClasses = {}, ...otherProps } = props;
  const classes = mergeClasses(useStyles(), userClasses);

  return <MUITypography ref={ref} classes={classes} {...otherProps} />;
});

Typography.displayName = 'Typography';

export default Typography;
