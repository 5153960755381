import DualFilteredTreeList, {
  ITreeChannelData,
} from 'components/DualFilteredTreeList/DualFilteredTreeList';
import Loader from 'components/Loader/Loader';
import { IFormProps, ITreeItems } from 'interfaces';
import {
  ItunesCategory,
  useAllItunesCategoriesQuery,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useEffect, useState } from 'react';
import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const itunesCategoryValues = {
  itunesCategoryParams: {
    categories: [],
  },
};

export const formatItunesCategories = memoizeOne(
  (categories: ItunesCategory[]): ITreeItems[] =>
    categories.map((category) => ({
      value: category.name as string,
      label: category.name as string,
      children: category.subItunesCategories.map((subcategory) => ({
        value: subcategory.name as string,
        label: subcategory.name as string,
      })),
    }))
);

export const filterItunesCategories = memoizeOne(
  (selectedCategories: string[], allItunesCategories: ITreeItems[]) => {
    const filteredCategories = selectedCategories.flatMap((current: string) => {
      const acc = [];
      const branchCategory = allItunesCategories.find(({ children }) =>
        children?.find(({ value }) => value === current)
      );
      const leafCategory =
        allItunesCategories.find(({ value }) => value === current)?.children
          ?.length === 0;

      if (branchCategory) {
        acc.push(branchCategory.value);
      }
      if (branchCategory || leafCategory) {
        acc.push(current);
      }
      return acc;
    });
    return [...new Set(filteredCategories)];
  }
);

const TargetingItunesCategory = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    setFieldValue,
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
  } = props;
  const { loading, data } = useAllItunesCategoriesQuery({
    fetchPolicy: 'cache-first',
  });
  const allItunesCategories =
    data && data.allItunesCategories
      ? formatItunesCategories(data.allItunesCategories as ItunesCategory[])
      : [];

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    targetingValues.audienceParams[
      index
    ].itunesCategoryParams.categories.flatMap(
      (category: ITreeChannelData) => category.id
    )
  );

  const [readOnlyIds] = useState(
    targetingValues.audienceParams[index].itunesCategoryParams.categories
      .filter((category: ITreeChannelData) => category.readOnly)
      .map((category: ITreeChannelData) => category.id)
  );

  useEffect(() => {
    if (
      `${targetingPath}.audienceParams.${index}.itunesCategoryParams.categories` &&
      selectedCategories &&
      allItunesCategories.length
    ) {
      const filteredItunesCategories = filterItunesCategories(
        selectedCategories,
        allItunesCategories
      );
      setFieldValue(
        `${targetingPath}.audienceParams.${index}.itunesCategoryParams.categories`,
        filteredItunesCategories.map((category: any) => ({
          id: category,
          readOnly: readOnlyIds.some((id: string) => id === category),
        }))
      );
    }
  }, [selectedCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader />;

  return (
    <DualFilteredTreeList
      data-tc="targetingItunesCategory"
      allItems={allItunesCategories}
      onChangeWithIds={(selectedValues: string[]) =>
        setSelectedCategories(selectedValues)
      }
      selectedItemsIds={selectedCategories}
      allData={
        targetingValues.audienceParams[index].itunesCategoryParams.categories
      }
    />
  );
};

export default TargetingItunesCategory;
