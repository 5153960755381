import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const baseKeyValuePairStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      '& + &': {
        marginTop: theme.spacing(3),
      },
    },
    pairValues: {
      composes: '$value',
      flex: 50,
      margin: theme.spacing(0, 0.626),

      '&:first-of-type': {
        margin: theme.spacing(0, 0.626, 0, 0),
      },
      '&:last-of-type': {
        margin: theme.spacing(0, 0, 0, 0.626),
      },
    },
    errors: {
      margin: theme.spacing(1.25),
      width: '100%',
    },
    errorMessage: {
      display: 'block',
      '& + &': {
        marginTop: theme.spacing(0.626),
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    button: {
      margin: theme.spacing(0, 0.5),
    },
  });

const targetingStyles = makeStyles((theme: Theme) => ({
  ...baseKeyValuePairStyles(theme),
}));

export default targetingStyles;
