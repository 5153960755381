import DataControlAccordion from 'components/DataControlAccordion/DataControlAccordion';
import { Field } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { IFormProps } from 'interfaces';
import React from 'react';
import { countKvps } from 'utils/dataControl';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import useStyles from './DataControl.styles';

const DataControl = (props: IFormProps<any>) => {
  const { values, touched, errors, status = {} } = props;

  const classes: any = useStyles();

  return (
    <div className={classes.container}>
      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="Geo Data"
        attribute="blockGeoData"
        {...props}
      >
        <p>Includes GPS, Country, Region, City, DMA and CMA.</p>
        <FormControlLabel
          control={
            <Field
              component={Switch}
              type="checkbox"
              name="truncateGpsCoords"
              color="primary"
              data-tc="dataControlSwitchGpsCoords"
            />
          }
          label="Truncate GPS Coordinates"
          classes={{ label: classes.toggle, root: classes.label }}
        />
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="IP"
        attribute="blockIpData"
        {...props}
      >
        <p>IP Address of the listener.</p>
        <FormControlLabel
          control={
            <Field
              component={Switch}
              type="checkbox"
              name="truncateIpAddress"
              color="primary"
              data-tc="dataControlSwitchIpAddress"
            />
          }
          label="Truncate IP Address"
          classes={{ label: classes.toggle, root: classes.label }}
        />
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="1st Party/Custom Data"
        attribute="block1stPartyData"
        {...props}
      >
        <p>
          Data that is passed by the publisher in the URL via Key Value Pairs.
        </p>
        <FormControlLabel
          data-tc="dataControlLabel1stParty"
          control={
            <Field
              component={Switch}
              type="checkbox"
              name="kvps1stPartyAllData"
              color="primary"
              data-tc="dataControlSwitch1stParty"
            />
          }
          label={
            values.kvps1stPartyAllData ? 'Pass All Data' : 'Pass Specific Keys'
          }
          classes={{ label: classes.toggle, root: classes.label }}
        />
        {!values.kvps1stPartyAllData && (
          <>
            <Field
              component={TextField}
              rows={5}
              rowsMax={5}
              multiline
              className={classes.textarea}
              placeholder="Separate keys with a comma or a new line"
              name="kvps1stPartyData"
              data-tc="technicalProviderKvps1stPartyData"
              fullWidth
              margin="none"
              variant="outlined"
              InputProps={{
                classes: { root: classes.textareaInput },
              }}
            />
            <span className={classes.entries}>
              Entries: {countKvps(values.kvps1stPartyData)}
            </span>
            {((touched.kvps1stPartyData && errors.kvps1stPartyData) ||
              status.kvps1stPartyData) && (
              <FormHelperText
                error
                className={classes.error}
                data-tc="kvps1stPartyDataError"
              >
                {errors.kvps1stPartyData || status.kvps1stPartyData}
              </FormHelperText>
            )}
          </>
        )}
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="Listener ID"
        attribute="blockListenerId"
        {...props}
      >
        <p>
          Depending on the device, this can be mobile device ID, cookie ID or a
          generated ID.
        </p>
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="Site"
        attribute="blockSite"
        {...props}
      >
        <div className={classes.textfieldContainer}>
          <Field
            component={TextField}
            label="Site Name"
            data-tc="technicalProviderSiteName"
            name="siteName"
            fullWidth
            margin="normal"
            disabled
          />
          <Field
            component={TextField}
            label="Site Domain"
            data-tc="technicalProviderSiteDomain"
            name="siteDomain"
            fullWidth
            margin="normal"
            disabled
          />
        </div>
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="App"
        attribute="blockApp"
        {...props}
      >
        <div className={classes.textfieldContainer}>
          <Field
            component={TextField}
            label="App Bundle"
            data-tc="technicalProviderAppBundle"
            name="appBundle"
            fullWidth
            margin="normal"
            helperText={
              (touched.appBundle && errors.appBundle) || status.appBundle
            }
            FormHelperTextProps={{
              error: !!(
                (touched.appBundle && errors.appBundle) ||
                status.appBundle
              ),
            }}
          />
          <Field
            component={TextField}
            label="App Name"
            data-tc="technicalProviderAppName"
            name="appName"
            fullWidth
            margin="normal"
            disabled
          />
          <Field
            component={TextField}
            label="App Domain"
            data-tc="technicalProviderAppDomain"
            name="appDomain"
            fullWidth
            margin="normal"
            disabled
          />
        </div>
      </DataControlAccordion>

      <DataControlAccordion
        data-tc="dataControlAccordion"
        title="Publisher"
        attribute="blockPublisher"
        {...props}
      >
        <div className={classes.textfieldContainer}>
          <Field
            component={TextField}
            label="Publisher Id"
            data-tc="technicalProviderPublisherId"
            name="publisherId"
            fullWidth
            margin="normal"
            helperText={
              (touched.publisherId && errors.publisherId) || status.publisherId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.publisherId && errors.publisherId) ||
                status.publisherId
              ),
            }}
          />
          <Field
            component={TextField}
            label="Publisher Name"
            data-tc="technicalProviderPublisherName"
            name="publisherName"
            fullWidth
            margin="normal"
            disabled
          />
          <Field
            component={TextField}
            label="Publisher Domain"
            data-tc="technicalProviderPublisherDomain"
            name="publisherDomain"
            fullWidth
            margin="normal"
            disabled
          />
          <Field
            component={TextField}
            label="Publisher Tag Id"
            data-tc="technicalProviderPublisherTagId"
            name="publisherTagId"
            fullWidth
            margin="normal"
            helperText={
              (touched.publisherTagId && errors.publisherTagId) ||
              status.publisherId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.publisherTagId && errors.publisherTagId) ||
                status.publisherTagId
              ),
            }}
          />
        </div>
      </DataControlAccordion>
    </div>
  );
};

export default DataControl;
