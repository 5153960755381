import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import memoizeOne from 'memoize-one';

import {
  targetingParameterClusivityTypesEnum,
  targetingParameterTypesEnum,
  TLocationOption,
} from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import { ICities, ICity } from 'features/targeting/graphql/cities/queries';
import {
  ICountry,
  ICountryWithDivisions,
} from 'features/targeting/graphql/countries/queries';
import { IRegion } from 'features/targeting/graphql/regions/queries';

import { OptionType } from 'interfaces';
import { GeoRegionResponse } from 'interfaces/generated.types';

interface IPosition {
  latitude: string | number;
  longitude: string | number;
  radius: string | number;
}

const filterPositions = (positions: IPosition[]) =>
  positions
    .filter(
      (position) =>
        position.latitude !== '' ||
        position.longitude !== '' ||
        position.radius !== ''
    )
    .map((position) => ({
      latitude: position.latitude,
      longitude: position.longitude,
      radius: Number(position.radius) * 1000,
    }));

const createCountryGroup = memoizeOne((data: ICountry[]) =>
  data
    .filter((country: ICountry) => country.code)
    .map((country: ICountry) => ({
      label: country.name,
      value: country.code,
      readOnly: country.readOnly,
    }))
);

const createRegionOptions = (allRegions: GeoRegionResponse) =>
  allRegions.regions.map((region: IRegion) => ({
    label: region.name,
    value: `${region.code}-${allRegions.countryCode}`,
    countryCode: allRegions.countryCode,
    readOnly: region.readOnly,
  }));

const createRegionGroup = memoizeOne((data: GeoRegionResponse[]) =>
  data.map((allRegions) => ({
    label: allRegions.countryCode,
    options: createRegionOptions(allRegions),
  }))
);

const createSubRegionOptions = (allSubRegions: GeoRegionResponse) =>
  allSubRegions.regions.map((region) => ({
    label: region.name,
    value: region.code,
    countryCode: allSubRegions.countryCode,
  }));

const createSubRegionGroup = (data: GeoRegionResponse[]) =>
  data.map((allRegions) => ({
    label: allRegions.countryCode,
    options: createSubRegionOptions(allRegions),
  }));

const createCityLabel = (city: ICity, country: string) => {
  if (city.subRegionCode) {
    return `${city.regionCode}-${city.subRegionCode}`;
  }
  if (city.regionCode) {
    return city.regionCode;
  }
  return country;
};

const createCityOptions = (allCities: ICities) =>
  allCities.cities.map((city: ICity) => ({
    label: city.name,
    value: `${city.name}-${city.regionCode}-${city.subRegionCode}`,
    countryCode: allCities.countryCode,
    regionCode: city.regionCode,
    subRegionCode: city.subRegionCode,
    readOnly: city.readOnly,
  }));

const createCityGroup = (data: ICities[]) =>
  sortBy(
    data[0].cities.map((city) => ({
      label: createCityLabel(city, data[0].countryCode),
      options: [
        {
          label: city.name,
          value: `${city.name}-${city.regionCode}-${city.subRegionCode}`,
          countryCode: data[0].countryCode,
          regionCode: city.regionCode,
          subRegionCode: city.subRegionCode,
        },
      ],
    })),
    (city) => city.label
  );

const formatRegionCodes = (regions: OptionType[], regionOnly = false) =>
  regions.map((region: OptionType) =>
    regionOnly ? region.value.split('-')[0] : region.value
  );

const formatCountryCodes = (countries: OptionType[]) =>
  countries.map((country: OptionType) => country.value);

const createLocationData = (
  data: any,
  clusivity: string,
  key: targetingParameterTypesEnum
) => {
  const formatCountry = (country: OptionType) => ({
    code: country.value,
    name: country.label,
    clusivity: clusivity || undefined,
    ...(key === targetingParameterTypesEnum.REGION
      ? {
          regions: data.regions
            .filter(
              (region: TLocationOption) => region.countryCode === country.value
            )
            .map((region: TLocationOption) => ({
              name: region.label,
              code: region.value.split('-')[0],
            })),
        }
      : {}),
    ...(key === targetingParameterTypesEnum.SUBREGION
      ? {
          regions: data.regions.map((region: TLocationOption) => ({
            name: region.label,
            code: region.value.split('-')[0],
            subRegions: data.subRegions
              .filter(
                (subRegion: TLocationOption) =>
                  subRegion.countryCode === region.value.split('-')[0]
              )
              .map((subRegion: TLocationOption) => ({
                name: subRegion.label,
                code: subRegion.value,
              })),
          })),
          ...(data.countryGroups &&
            data.countryGroups.length > 0 && {
              countryGroupIds: data.countryGroups?.map(
                (group: OptionType) => group.value
              ),
            }),
        }
      : {}),
    ...(key === targetingParameterTypesEnum.CITY
      ? {
          cities: data.cities
            .filter(
              (city: TLocationOption) => city.countryCode === country.value
            )
            .map((city: TLocationOption) => ({
              name: city.label,
              regionCode: city.regionCode,
              subRegionCode: city.subRegionCode,
            })),
        }
      : {}),
  });

  if (data.countries.length > 0) return data.countries.map(formatCountry);

  if (data.countryGroups?.length > 0) return [formatCountry({} as OptionType)];

  return [];
};

const formatCountries = (countries: ICountry[]) =>
  countries.map(({ code, name, readOnly }) => ({
    code,
    name,
    readOnly,
  }));

const formatCities = (countries: ICountryWithDivisions[]) =>
  countries.map(({ code, name, subdivisions, readOnly }) => ({
    code,
    name,
    countryCode: code,
    cities: subdivisions.map((subdivision: ICity) => ({
      name: subdivision.name,
      regionCode: subdivision.regionCode,
      subRegionCode: subdivision.subRegionCode,
      readOnly: subdivision.readOnly,
    })),
    readOnly,
  }));

const formatRegions = (countries: ICountryWithDivisions[]) =>
  countries.map(({ code, name, subdivisions, readOnly }) => ({
    code,
    name,
    countryCode: code,
    regions: subdivisions.map(
      ({ name: regionName, code: regionCode, readOnly: regionReadOnly }) => ({
        name: regionName,
        code: regionCode,
        readOnly: regionReadOnly,
      })
    ),
    readOnly,
  }));

const formatSubRegions = (countries: ICountryWithDivisions[]) =>
  countries.map(({ code, name, subdivisions, readOnly, countryGroups }) => ({
    code,
    name,
    countryCode: code,
    readOnly,
    regions: subdivisions.map(
      ({
        name: regionName,
        code: regionCode,
        subRegions,
        readOnly: regionReadOnly,
      }) => ({
        name: regionName,
        code: regionCode,
        countryCode: regionCode,
        readOnly: regionReadOnly,
        subRegions: subRegions.map(
          ({
            name: subRegionName,
            code: subRegionCode,
            readOnly: subRegionReadOnly,
          }: {
            name: string;
            code: string;
            readOnly: boolean;
          }) => ({
            name: subRegionName,
            code: subRegionCode,
            readOnly: subRegionReadOnly,
          })
        ),
      })
    ),
    countryGroups:
      countryGroups &&
      countryGroups.map((group: { name: string; id: string }) => ({
        name: group.name,
        id: group.id,
        readOnly,
      })),
  }));

const generateTemplate = (
  __typename: string,
  clusivity: string,
  countryParams: any[]
) =>
  countryParams.length
    ? [
        {
          __typename,
          clusivity,
          readOnlyType: countryParams.some((c: any) => c.readOnly),
          countryParams,
        },
      ]
    : [];

const generateCountryParams = (locationParams: any[]) => {
  const countryParams = locationParams.filter(
    (c: any) =>
      c.subdivisions.length === 0 &&
      (!c.countryGroups || c.countryGroups.length === 0)
  );
  const [includedCountries, excludedCountries] = partition(
    countryParams,
    (c) => c.clusivity === targetingParameterClusivityTypesEnum.INCLUDE
  );

  return [
    generateTemplate(
      targetingParameterTypesEnum.COUNTRY,
      targetingParameterClusivityTypesEnum.INCLUDE,
      formatCountries(includedCountries)
    ),
    generateTemplate(
      targetingParameterTypesEnum.COUNTRY,
      targetingParameterClusivityTypesEnum.EXCLUDE,
      formatCountries(excludedCountries)
    ),
  ];
};

const generateRegionParams = (locationParams: any) => {
  const regionParams = locationParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.REGION &&
        division.subRegions.length === 0
    )
  );
  const [includedRegions, excludedRegions] = partition(
    regionParams,
    (c) => c.clusivity === targetingParameterClusivityTypesEnum.INCLUDE
  );

  return [
    generateTemplate(
      targetingParameterTypesEnum.REGION,
      targetingParameterClusivityTypesEnum.INCLUDE,
      formatRegions(includedRegions)
    ),
    generateTemplate(
      targetingParameterTypesEnum.REGION,
      targetingParameterClusivityTypesEnum.EXCLUDE,
      formatRegions(excludedRegions)
    ),
  ];
};

export const generateSubRegionParams = (locationParams: any) => {
  const subRegionParams = locationParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.REGION &&
        division.subRegions.length > 0 &&
        (!c.countryGroups || c.countryGroups.length === 0)
    )
  );

  const [includedSubRegions, excludedSubRegions] = partition(
    subRegionParams,
    (c) => c.clusivity === targetingParameterClusivityTypesEnum.INCLUDE
  );
  return [
    generateTemplate(
      targetingParameterTypesEnum.SUBREGION,
      targetingParameterClusivityTypesEnum.INCLUDE,
      formatSubRegions(includedSubRegions)
    ),
    generateTemplate(
      targetingParameterTypesEnum.SUBREGION,
      targetingParameterClusivityTypesEnum.EXCLUDE,
      formatSubRegions(excludedSubRegions)
    ),
  ];
};

const generateCityParams = (locationParams: any) => {
  const cityParams = locationParams.filter((c: any) =>
    c.subdivisions.some(
      (division: any) =>
        division.__typename === targetingParameterTypesEnum.CITY
    )
  );
  const [includedCities, excludedCities] = partition(
    cityParams,
    (c) => c.clusivity === targetingParameterClusivityTypesEnum.INCLUDE
  );

  return [
    generateTemplate(
      targetingParameterTypesEnum.CITY,
      targetingParameterClusivityTypesEnum.INCLUDE,
      formatCities(includedCities)
    ),
    generateTemplate(
      targetingParameterTypesEnum.CITY,
      targetingParameterClusivityTypesEnum.EXCLUDE,
      formatCities(excludedCities)
    ),
  ];
};

export const generateCountryGroupParams = (locationParams: any) => {
  const countryGroupParams = locationParams.filter(
    (c: any) => c.countryGroups && c.countryGroups.length > 0
  );

  const [includedSubRegions, excludedSubRegions] = partition(
    countryGroupParams,
    (c) => c.clusivity === targetingParameterClusivityTypesEnum.INCLUDE
  );

  return [
    generateTemplate(
      targetingParameterTypesEnum.SUBREGION,
      targetingParameterClusivityTypesEnum.INCLUDE,
      formatSubRegions(includedSubRegions)
    ),
    generateTemplate(
      targetingParameterTypesEnum.SUBREGION,
      targetingParameterClusivityTypesEnum.EXCLUDE,
      formatSubRegions(excludedSubRegions)
    ),
  ];
};

export const processLocationParams = (audienceParams: any) => {
  const [locationParams, rest] = partition(
    audienceParams,
    (o) =>
      o.__typename === targetingParameterTypesEnum.COUNTRY ||
      o.__typename === 'CountryGroupElement'
  );
  const [includedCountries, excludedCountries] = generateCountryParams(
    locationParams
  );
  const [includedRegions, excludedRegions] = generateRegionParams(
    locationParams
  );
  const [includedSubRegions, excludedSubRegions] = generateSubRegionParams(
    locationParams
  );
  const [
    includedCountryGroups,
    excludedCountryGroups,
  ] = generateCountryGroupParams(locationParams);
  const [includedCities, excludedCities] = generateCityParams(locationParams);
  return [
    ...rest,
    ...includedCountries,
    ...excludedCountries,
    ...includedRegions,
    ...excludedRegions,
    ...includedSubRegions,
    ...excludedSubRegions,
    ...includedCities,
    ...excludedCities,
    ...includedCountryGroups,
    ...excludedCountryGroups,
  ];
};

// takes a country param and splits it into countries, regions, and sub regions
export const splitCountryParam = (param: {
  __typename: string;
  countryParams: any;
}) => {
  const val = {
    countries:
      param &&
      param.__typename === targetingParameterTypesEnum.SUBREGION &&
      param.countryParams &&
      createCountryGroup(param.countryParams),
    regions:
      param &&
      param.__typename === targetingParameterTypesEnum.SUBREGION &&
      param.countryParams &&
      param.countryParams.flatMap((country: any) =>
        createRegionOptions(country)
      ),
    subRegions:
      param &&
      param.__typename === targetingParameterTypesEnum.SUBREGION &&
      param.countryParams &&
      param.countryParams.flatMap((country: any) =>
        country.regions.flatMap((region: any) =>
          region.subRegions.map((subRegion: any) => ({
            label: subRegion.name,
            value: subRegion.code,
            countryCode: region.code,
            readOnly: subRegion.readOnly,
          }))
        )
      ),
    countryGroups:
      param &&
      param.__typename === targetingParameterTypesEnum.SUBREGION &&
      param.countryParams &&
      param.countryParams.flatMap((country: any) => {
        if (country.countryGroups) {
          return country.countryGroups.map((group: any) => ({
            label: group.name,
            value: group.id,
            readOnly: group.readOnly,
          }));
        }
        return [];
      }),
  };
  return val;
};

export default {
  createCountryGroup,
  createRegionOptions,
  createRegionGroup,
  createSubRegionOptions,
  createSubRegionGroup,
  createCityLabel,
  createCityOptions,
  createCityGroup,
  formatRegionCodes,
  formatCountryCodes,
  createLocationData,
  formatCountries,
  formatCities,
  formatRegions,
  formatSubRegions,
  filterPositions,
  splitCountryParam,
};
