import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  adCreativeRedirect: {
    marginLeft: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
  },
  iconAdd: {
    fontSize: '1em',
    width: '100%',
    padding: theme.spacing(2.5),
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    border: `1px dashed ${theme.palette.common.black}`,
    color: theme.palette.common.black,
  },
  trackingUrlsItems: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    marginTop: theme.spacing(2.5),
  },
  trackingUrlItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    width: '100%',
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
