import React from 'react';
import { Redirect } from 'react-router-dom';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';

import BlocklistingPrimaryControl from 'features/primaryControls/containers/BlocklistingPrimaryControl/BlocklistingPrimaryControl';
import { content } from 'features/primaryControls/containers/PrimaryControlContainer/PrimaryControlContent';

import {
  PrimaryControlEntity,
  PermissionName,
  usePrimaryControlsQuery,
} from 'interfaces/generated.types';
import PrimaryControlContainer from 'features/primaryControls/containers/PrimaryControlContainer/PrimaryControlContainer';

interface IPrimaryControlPageProps {
  userPermissions: PermissionName[];
}

const PrimaryControlPage = ({ userPermissions }: IPrimaryControlPageProps) => {
  const { loading, data, error, refetch } = usePrimaryControlsQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <main>
        <Container>
          <Loader />
        </Container>
      </main>
    );
  }

  if (!(data && data.primaryControls) || error)
    return <Redirect to="/error/400" />;

  const campaignControl = data.primaryControls.find(
    (primaryControl) => primaryControl.entity === PrimaryControlEntity.Campaign
  );
  const dealControl = data.primaryControls.find(
    (primaryControl) => primaryControl.entity === PrimaryControlEntity.Deal
  );
  const quarantineControl = data.primaryControls.find(
    (primaryControl) =>
      primaryControl.entity === PrimaryControlEntity.SalesChannel
  );

  return (
    <main>
      <Container data-testid="primaryControlsPage">
        <h1 className="sr-only">Primary Controls</h1>
        <PrimaryControlContainer
          refetch={refetch}
          hasError={!campaignControl}
          entries={campaignControl?.entries || []}
          content={content.campaign}
        />
        <PrimaryControlContainer
          refetch={refetch}
          hasError={!dealControl}
          entries={dealControl?.entries || []}
          content={content.deal}
        />
        <PrimaryControlContainer
          refetch={refetch}
          hasError={!quarantineControl}
          entries={quarantineControl?.entries || []}
          content={content.salesChannel}
        />
        {userPermissions.includes(PermissionName.Primary) && (
          <BlocklistingPrimaryControl />
        )}
      </Container>
    </main>
  );
};

export default PrimaryControlPage;
