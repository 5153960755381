import classNames from 'classnames';
import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Dropzone from 'components/Dropzone/Dropzone';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { CSV_FILE_SIZE, CSV_FILE_TYPE } from 'utils/files';
import { dedupeValues } from 'utils/dataTransformation';

import useStyles from './BulkUploadModal.styles';

export interface IBulkUploadModalProps {
  header?: string;
  label: string;
  name: string;
  placeholder: string;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  onUpload: (values: any[]) => Promise<void> | void;
  parseTextValues: (text: string) => any;
  parseCsvValues: (values: { [key: string]: string }[]) => any;
  dataTc: string;
  error?: string;
  isLoading?: boolean;
}

const BulkUploadModal = (props: IBulkUploadModalProps) => {
  const {
    header = 'Bulk Upload',
    label,
    name,
    placeholder,
    isModalOpen,
    setModalOpen,
    onUpload,
    parseTextValues,
    parseCsvValues,
    dataTc,
    error,
    isLoading = false,
  } = props;

  const classes = useStyles();
  const [textValues, setTextValues] = useState<string>('');
  const [csvValues, setCsvValues] = useState<any[]>([]);

  const handleDropzoneData = (dropzoneData: { [key: string]: string }[]) => {
    const parsedCsvValues = parseCsvValues(dropzoneData);

    setCsvValues(parsedCsvValues);
  };

  const handleUpload = async () => {
    const parsedTextValues = parseTextValues(textValues);

    const allUploadedValues = dedupeValues([...csvValues, ...parsedTextValues]);

    await onUpload(allUploadedValues);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      maxWidth="md"
      fullWidth
      data-tc={dataTc}
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        <Typography display="inline" data-testid={`${dataTc}Header`}>
          {header}
        </Typography>
        <IconButton
          onClick={() => setModalOpen(false)}
          classes={{ root: classes.closeButton }}
          data-tc={`${dataTc}X`}
          data-testid={`${dataTc}X`}
        >
          <CloseIcon />
          <span className="sr-only">Close modal</span>
        </IconButton>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.content }}
        data-tc={`${dataTc}Content`}
      >
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Dropzone
                fileType={CSV_FILE_TYPE}
                maxFileSize={CSV_FILE_SIZE}
                dropzoneMessage="Click here and select a csv file or drag and drop the file here"
                errorMessages={[
                  'It looks like the file is either too large or has an incorrect filetype/format. CSV files can only be up to 128 KB. Please try another file.',
                ]}
                dataTc={`${dataTc}Dropzone`}
                handleDropzoneData={handleDropzoneData}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={label}
                data-tc={`${dataTc}TextField`}
                data-testid={`${dataTc}TextField`}
                focused
                multiline
                rows={6}
                rowsMax={6}
                className={classes.textfield}
                name={name}
                value={textValues}
                margin="normal"
                onChange={(event) => {
                  setTextValues(event.target.value);
                }}
                placeholder={placeholder}
              />
            </Grid>
            {error && (
              <p
                className={classes.error}
                data-testid={`${dataTc}TextFieldError`}
              >
                {error}
              </p>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: classNames(
            classes.actions,
            `${classes.actions}--space-between`
          ),
        }}
      >
        <StyledButton
          onClick={() => setModalOpen(false)}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
          data-tc={`${dataTc}Cancel`}
        >
          Cancel
        </StyledButton>
        <StyledButton
          data-tc={`${dataTc}Continue`}
          testId={`${dataTc}Upload`}
          variant={ButtonVariantEnum.Contained}
          color={ButtonColorEnum.Primary}
          disabled={!(csvValues.length || textValues)}
          isLoading={isLoading}
          onClick={async () => {
            await handleUpload();
          }}
        >
          Upload
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadModal;
