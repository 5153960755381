const scriptPromises: any[] = [];

export const verifier = () => !!(window && window.gigya);

export const getInitialScriptPromises = () => scriptPromises;

export const injectScriptFunc = ({ src, isAsync }: any) => {
  const script = document.createElement('script');

  script.src = src;
  script.async = isAsync;

  document.head.appendChild(script);
};

export const waitUntilFunc = (
  conditionChecker: () => any,
  resolveCallback: { (value: unknown): void; (arg0: any): void },
  rejectCallback: { (reason?: any): void; (arg0: any): void },
  delay: number,
  interval = 100
) => {
  let remainingDelay = delay;

  const intervalId = setInterval(() => {
    const value = conditionChecker();

    if (value) {
      clearInterval(intervalId);
      resolveCallback(value);
    }
    if (remainingDelay <= 0) {
      clearInterval(intervalId);
      rejectCallback(value);
    }

    remainingDelay -= interval;
  }, interval);
};

export const loadGigyaScript = ({
  src,
  isAsync,
  detectionDelay = 3000,
  injectScript = injectScriptFunc,
  waitUntil = waitUntilFunc,
  getScriptPromises = getInitialScriptPromises,
}: any) => {
  const savedScriptPromises = getScriptPromises();

  savedScriptPromises[src] =
    savedScriptPromises[src] ||
    new Promise((resolve, reject) => {
      const isScriptAlreadyLoaded = verifier();

      if (isScriptAlreadyLoaded) {
        resolve(isScriptAlreadyLoaded);
        return;
      }

      injectScript({ src, isAsync });
      waitUntil(verifier, resolve, reject, detectionDelay);
    });

  return savedScriptPromises[src];
};
