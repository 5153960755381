export enum AdvertiserAndAgencyExternalSystems {
  Salesforce = 'Salesforce',
  TOMS = 'TOMS',
}

export const advertiserAndAgencyExternalSystems = [
  { value: AdvertiserAndAgencyExternalSystems.Salesforce, label: 'Salesforce' },
  { value: AdvertiserAndAgencyExternalSystems.TOMS, label: 'TOMS' },
];

export enum OrderExternalSystems {
  DAX = 'DAX',
  GPLAN = 'gPlan',
  TOMS = 'TOMS',
}

export const orderExternalSystems = [
  { value: OrderExternalSystems.DAX, label: 'DAX' },
  { value: OrderExternalSystems.GPLAN, label: 'gPlan' },
  { value: OrderExternalSystems.TOMS, label: 'TOMS' },
];

export default {
  advertiserAndAgencyExternalSystems,
  orderExternalSystems,
};
