import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import React, { useEffect, useState } from 'react';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';

import TextField from '@material-ui/core/TextField';

import DatePickerDialog from './DatePickerDialog';

interface DatePickerProps {
  value: Date | null;
  name: string;
  label: string;
  initialFocusedDate: Date;
  onChange: (value: Date) => void;
  onClose: () => void;
  disabled?: boolean;
  disablePast?: boolean;
  'data-tc': string;
  'data-testid'?: string;
}

const DatePicker = ({
  value,
  name,
  label,
  initialFocusedDate,
  onChange,
  onClose,
  disabled = false,
  disablePast = false,
  'data-tc': dataTc,
  'data-testid': dataTestId,
}: DatePickerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    value ? getFormattedDateBasedOnTerritory(value, activeTerritory!) : ''
  );

  useEffect(() => {
    setSelectedValue(
      value ? getFormattedDateBasedOnTerritory(value, activeTerritory) : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (updatedValue: Date) => {
    onChange(updatedValue);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <TextField
        label={label}
        name={name}
        id={name}
        data-tc={dataTc}
        data-testid={dataTestId}
        value={selectedValue}
        onClick={() => setOpen(!disabled)}
        inputProps={{
          readOnly: true,
        }}
        disabled={disabled}
      />
      <DatePickerDialog
        value={value}
        initialFocusedDate={initialFocusedDate}
        open={open}
        onClose={handleClose}
        onChange={handleChange}
        disablePast={disablePast}
      />
    </>
  );
};

export default DatePicker;
