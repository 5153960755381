import { ReactComponent as CheckIcon } from 'assets/icons/TargetingV2/dax-checkIcon.svg';
import { ReactComponent as NotAvailableIcon } from 'assets/icons/TargetingV2/dax-notAvailableIcon.svg';
import classNames from 'classnames';
import React from 'react';
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';

import useStyles from './DayTimeCell.styles';

export interface IDayTimeCellProps {
  day: string;
  hour: number;
  selected: boolean;
}

export const DayTimeCell = (
  props: TSelectableItemProps & IDayTimeCellProps
) => {
  const classes: any = useStyles();

  const { selectableRef, isSelecting, day, hour, selected } = props;

  const checked = selected && !isSelecting;
  const toBeChecked = !selected && isSelecting;
  const toBeUnchecked = selected && isSelecting;

  return (
    <div
      ref={selectableRef}
      key={`${day}-${hour}`}
      data-testid={`${day}-${hour}-dayTimeCell`}
    >
      <span
        className={classNames(classes.styledCheckbox, {
          [`${classes.styledCheckbox}--checked`]: checked,
          [`${classes.styledCheckbox}--selecting`]: toBeChecked,
          [`${classes.styledCheckbox}--deselecting`]: toBeUnchecked,
        })}
        data-testid="icon-container"
      >
        {(checked || toBeChecked) && <CheckIcon />}
        {toBeUnchecked && <NotAvailableIcon />}
      </span>
    </div>
  );
};

export default createSelectable(DayTimeCell);
