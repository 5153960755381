import { ReactComponent as InfoIcon } from 'assets/icons/TargetingV2/dax-info.svg';
import AudienceBuilder from 'features/targetingV2/components/AudienceSection/AudienceBuilder/AudienceBuilder';
import FormControl from 'features/targetingV2/components/core/FormControl';
import FormControlLabel from 'features/targetingV2/components/core/FormControlLabel';
import Typography from 'features/targetingV2/components/core/Typography';
import CustomBuilder from 'features/targetingV2/components/CustomSection/CustomBuilder/CustomBuilder';
import DayTimeBuilder from 'features/targetingV2/components/DayTimeSection/DayTimeBuilder/DayTimeBuilder';
import InventoryBuilder from 'features/targetingV2/components/InventorySection/InventoryBuilder/InventoryBuilder';
import LocationBuilder from 'features/targetingV2/components/LocationSection/LocationBuilder/LocationBuilder';
import TargetingVersionSwitch from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSwitch';
import TechnologyBuilder from 'features/targetingV2/components/TechnologySection/TechnologyBuilder/TechnologyBuilder';
import { workplaceKnowledgeUrl } from 'features/targetingV2/utils/common';
import { Field } from 'formik';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import { IFormProps } from 'interfaces';
import { PermissionName, TargetingVersion } from 'interfaces/generated.types';
import React from 'react';

import useStyles from './Targeting.styles';

export interface ITargetingProps {
  isTargetingVersionToggleDisabled: boolean;
  children?: React.ReactNode;
  hasTargetingVersionToggle?: boolean;
  hasHelpAndTutLink?: boolean;
}

const Targeting = (props: IFormProps<any> & ITargetingProps) => {
  const {
    values: { targetingVersion },
    isTargetingVersionToggleDisabled,
    children,
    hasTargetingVersionToggle = true,
    hasHelpAndTutLink = true,
  } = props;
  const classes = useStyles();
  const userPermissions = useUserPermissions();

  const isExperimentalUser = userPermissions.includes(
    PermissionName.Experimental
  );

  const switchCheckedValue = targetingVersion === TargetingVersion.TargetingV2;

  return (
    <div data-testid="targeting-v2">
      {(hasTargetingVersionToggle || hasHelpAndTutLink) && (
        <div className={classes.targetingToggleContainer}>
          {isExperimentalUser && hasTargetingVersionToggle && (
            <FormControl component={'fieldset' as 'div'}>
              <FormControlLabel
                control={
                  <Field
                    component={TargetingVersionSwitch}
                    type="checkbox"
                    name="targetingVersion"
                    checked={switchCheckedValue}
                    disabled={isTargetingVersionToggleDisabled}
                  />
                }
                label="Use Targeting 2.0"
              />
            </FormControl>
          )}
          {hasHelpAndTutLink && (
            <Typography variant="body1" className={classes.helpAndTutLink}>
              <a
                href={`${workplaceKnowledgeUrl}/3084690195185782`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoIcon />
                Help & Tutorials
              </a>
            </Typography>
          )}
        </div>
      )}
      {children || (
        <>
          <LocationBuilder {...props} />
          <AudienceBuilder {...props} />
          <DayTimeBuilder {...props} />
          <InventoryBuilder {...props} />
          <TechnologyBuilder {...props} />
          <CustomBuilder {...props} />
        </>
      )}
    </div>
  );
};

export default Targeting;
