import { OptionType } from 'interfaces';
import { ConnectionType } from 'interfaces/generated.types';
import { groupBy, mapValues } from 'utils/dataTransformation';
import { generateAdMidRollPositions } from 'utils/generators';

export type TDeviceOSOption = OptionType & { os: string };
export type TDeviceModelOption = OptionType & { model: string };
export type TLocationOption = OptionType & {
  countryCode: string;
  regionCode?: string;
  subRegionCode?: string;
  readOnly?: boolean;
};

export enum targetingParameterTypesEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  CUSTOM = 'KeyValueGroupParam',
  AGE = 'AgeParam',
  GENDER = 'GenderParam',
  DAYTIME = 'DayAndTimeParam',
  DEVICE_MODEL = 'DeviceMakerParam',
  DEVICE_OS = 'DeviceOperatingSystemParam',
  DEVICE_TYPE = 'DeviceTypeParam',
  POSTCODES = 'PostalCodeParam',
  LOCATION = 'LocationParam',
  COUNTRY = 'CountryParam',
  CITY = 'CityParam',
  REGION = 'RegionParam',
  SUBREGION = 'SubRegionParam',
  DMP_SEGMENT = 'DmpSegmentParam',
  DMA = 'DmaParam',
  AUDIENCE = 'AudienceSegmentParam',
  GENRE = 'GenreParam',
  ITUNES_CATEGORY = 'ItunesCategoryParam',
  PODCAST = 'PodcastParam',
  AD_POSITION = 'AdPositionParam',
  LISTENER_ACCURACY = 'ListenerAccuracy',
  BROWSER_LANGUAGE = 'BrowserLanguageParam',
  ISP = 'IspParam',
  CONNECTION_TYPE = 'ConnectionTypeParam',
}

export const audienceParamLabels = {
  [targetingParameterTypesEnum.CUSTOM]: 'Custom Type',
  [targetingParameterTypesEnum.AGE]: 'Age',
  [targetingParameterTypesEnum.GENDER]: 'Gender',
  [targetingParameterTypesEnum.DAYTIME]: 'Day/Time',
  [targetingParameterTypesEnum.DEVICE_TYPE]: 'Device Type',
  [targetingParameterTypesEnum.DEVICE_MODEL]: 'Device Model',
  [targetingParameterTypesEnum.DEVICE_OS]: 'Device Operating System',
  [targetingParameterTypesEnum.BROWSER_LANGUAGE]: 'Browser Language',
  [targetingParameterTypesEnum.POSTCODES]: 'Postcode/Zipcode',
  [targetingParameterTypesEnum.LOCATION]: 'Location',
  [targetingParameterTypesEnum.COUNTRY]: 'Country',
  [targetingParameterTypesEnum.REGION]: 'Region',
  [targetingParameterTypesEnum.SUBREGION]: 'Sub-Region',
  [targetingParameterTypesEnum.CITY]: 'City',
  [targetingParameterTypesEnum.DMP_SEGMENT]: 'Nielsen DMP',
  [targetingParameterTypesEnum.DMA]: 'Designated Market Area',
  [targetingParameterTypesEnum.AUDIENCE]: 'Audience Segment',
  [targetingParameterTypesEnum.GENRE]: 'Genre',
  [targetingParameterTypesEnum.ITUNES_CATEGORY]: 'iTunes Category',
  [targetingParameterTypesEnum.PODCAST]: 'Podcast',
  [targetingParameterTypesEnum.AD_POSITION]: 'Ad Position',
  [targetingParameterTypesEnum.ISP]: 'ISP',
  [targetingParameterTypesEnum.CONNECTION_TYPE]: 'Connection Type',
  [targetingParameterTypesEnum.LISTENER_ACCURACY]: 'Listener Location Accuracy',
};

export const targetingParameterTypes = (featureToggles: any) => [
  {
    value: targetingParameterTypesEnum.CUSTOM,
    label: audienceParamLabels[targetingParameterTypesEnum.CUSTOM],
  },
  {
    value: targetingParameterTypesEnum.AGE,
    label: audienceParamLabels[targetingParameterTypesEnum.AGE],
  },
  {
    value: targetingParameterTypesEnum.GENDER,
    label: audienceParamLabels[targetingParameterTypesEnum.GENDER],
  },
  {
    value: targetingParameterTypesEnum.DAYTIME,
    label: audienceParamLabels[targetingParameterTypesEnum.DAYTIME],
  },
  {
    value: targetingParameterTypesEnum.DEVICE_OS,
    label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_OS],
  },
  {
    value: targetingParameterTypesEnum.DEVICE_TYPE,
    label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_TYPE],
  },
  {
    value: targetingParameterTypesEnum.DEVICE_MODEL,
    label: audienceParamLabels[targetingParameterTypesEnum.DEVICE_MODEL],
  },
  ...(featureToggles && featureToggles.enableBrowserLanguages
    ? [
        {
          value: targetingParameterTypesEnum.BROWSER_LANGUAGE,
          label:
            audienceParamLabels[targetingParameterTypesEnum.BROWSER_LANGUAGE],
        },
      ]
    : []),
  {
    value: targetingParameterTypesEnum.POSTCODES,
    label: audienceParamLabels[targetingParameterTypesEnum.POSTCODES],
  },
  {
    value: targetingParameterTypesEnum.LOCATION,
    label: audienceParamLabels[targetingParameterTypesEnum.LOCATION],
  },
  {
    value: targetingParameterTypesEnum.COUNTRY,
    label: audienceParamLabels[targetingParameterTypesEnum.COUNTRY],
  },
  {
    value: targetingParameterTypesEnum.REGION,
    label: audienceParamLabels[targetingParameterTypesEnum.REGION],
  },
  {
    value: targetingParameterTypesEnum.SUBREGION,
    label: audienceParamLabels[targetingParameterTypesEnum.SUBREGION],
  },
  {
    value: targetingParameterTypesEnum.CITY,
    label: audienceParamLabels[targetingParameterTypesEnum.CITY],
  },
  {
    value: targetingParameterTypesEnum.DMP_SEGMENT,
    label: audienceParamLabels[targetingParameterTypesEnum.DMP_SEGMENT],
  },
  {
    value: targetingParameterTypesEnum.DMA,
    label: audienceParamLabels[targetingParameterTypesEnum.DMA],
  },
  {
    value: targetingParameterTypesEnum.AUDIENCE,
    label: audienceParamLabels[targetingParameterTypesEnum.AUDIENCE],
  },
  {
    value: targetingParameterTypesEnum.GENRE,
    label: audienceParamLabels[targetingParameterTypesEnum.GENRE],
  },
  {
    value: targetingParameterTypesEnum.ITUNES_CATEGORY,
    label: audienceParamLabels[targetingParameterTypesEnum.ITUNES_CATEGORY],
  },
  {
    value: targetingParameterTypesEnum.PODCAST,
    label: audienceParamLabels[targetingParameterTypesEnum.PODCAST],
  },
  {
    value: targetingParameterTypesEnum.AD_POSITION,
    label: audienceParamLabels[targetingParameterTypesEnum.AD_POSITION],
  },
  {
    value: targetingParameterTypesEnum.LISTENER_ACCURACY,
    label: audienceParamLabels[targetingParameterTypesEnum.LISTENER_ACCURACY],
  },
  {
    value: targetingParameterTypesEnum.ISP,
    label: audienceParamLabels[targetingParameterTypesEnum.ISP],
  },
  {
    value: targetingParameterTypesEnum.CONNECTION_TYPE,
    label: audienceParamLabels[targetingParameterTypesEnum.CONNECTION_TYPE],
  },
];

export enum targetingParameterClusivityTypesEnum {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export enum targetingParameterClusivityLabelEnum {
  INCLUDE = 'is any of',
  EXCLUDE = 'is not any of',
}

export const targetingParameterClusivityTypes = [
  {
    value: targetingParameterClusivityTypesEnum.INCLUDE,
    label: `${targetingParameterClusivityLabelEnum.INCLUDE}...`,
  },
  {
    value: targetingParameterClusivityTypesEnum.EXCLUDE,
    label: `${targetingParameterClusivityLabelEnum.EXCLUDE}...`,
  },
];

export enum targetingGenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export const targetingGenderValues = [
  {
    value: targetingGenderEnum.FEMALE,
    label: 'Female',
  },
  {
    value: targetingGenderEnum.MALE,
    label: 'Male',
  },
  {
    value: targetingGenderEnum.UNSPECIFIED,
    label: 'Unspecified',
  },
];

export const targetingConnectionTypeValues = [
  {
    value: ConnectionType.CableOrDsl,
    label: 'Cable or DSL',
  },
  {
    value: ConnectionType.Cellular,
    label: 'Cellular',
  },
  {
    value: ConnectionType.Corporate,
    label: 'Corporate',
  },
  {
    value: ConnectionType.Dialup,
    label: 'Dial up',
  },
];

export enum targetingPodcastTypeEnum {
  COLLECTION = 'COLLECTION',
  SHOW = 'SHOW',
  EPISODE = 'EPISODE',
}

export const targetingPodcastTypeValues = [
  {
    value: targetingPodcastTypeEnum.COLLECTION,
    label: 'Collection',
  },
  {
    value: targetingPodcastTypeEnum.SHOW,
    label: 'Show',
  },
  {
    value: targetingPodcastTypeEnum.EPISODE,
    label: 'Episode',
  },
];

export enum targetingAdPositionEnum {
  PRE_ROLL = 'PRE_ROLL',
  MID_ROLL = 'MID_ROLL',
  POST_ROLL = 'POST_ROLL',
}

export const targetingAdPositionValues = [
  {
    value: targetingAdPositionEnum.PRE_ROLL,
    label: 'Pre-roll',
  },
  {
    value: targetingAdPositionEnum.MID_ROLL,
    label: 'Mid-roll',
  },
  {
    value: targetingAdPositionEnum.POST_ROLL,
    label: 'Post-roll',
  },
];

export const targetingAdMidRollPositionValues = [
  ...generateAdMidRollPositions(),
];

export enum targetingTimeZoneEnum {
  USER = 'USER',
  CHANNEL = 'CHANNEL',
  DEMAND = 'DEMAND',
}

export const targetingTimeZoneValues = [
  {
    value: targetingTimeZoneEnum.USER,
    label: 'Listener time',
    disabled: true,
  },
  {
    value: targetingTimeZoneEnum.CHANNEL,
    label: 'Publisher/station time',
    disabled: true,
  },
  {
    value: targetingTimeZoneEnum.DEMAND,
    label: 'Agency time',
    disabled: false,
  },
];

export const targetingLocationAccuracyValues = [
  {
    value: 500,
    label: '500km / 310 miles',
  },
  {
    value: 200,
    label: '200km / 124 miles',
  },
  {
    value: 100,
    label: '100km / 62 miles',
  },
  {
    value: 50,
    label: '50km / 31 miles',
  },
  {
    value: 20,
    label: '20km / 12 miles',
  },
  {
    value: 10,
    label: '10km / 6 miles',
  },
  {
    value: 5,
    label: '5km / 3 miles',
  },
  {
    value: 1,
    label: '1km / 0.6 mile',
  },
];

export enum targetingDayEnum {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export const groupByOs = (deviceOSValues: TDeviceOSOption[]) =>
  Object.entries(mapValues(groupBy(deviceOSValues, 'os') as any));

export const groupByModel = (deviceModelValues: TDeviceModelOption[]) =>
  Object.entries(mapValues(groupBy(deviceModelValues, 'maker') as any));

export const targetingDayValues = [
  {
    value: targetingDayEnum.MON,
    label: 'Monday',
  },
  {
    value: targetingDayEnum.TUE,
    label: 'Tuesday',
  },
  {
    value: targetingDayEnum.WED,
    label: 'Wednesday',
  },
  {
    value: targetingDayEnum.THU,
    label: 'Thursday',
  },
  {
    value: targetingDayEnum.FRI,
    label: 'Friday',
  },
  {
    value: targetingDayEnum.SAT,
    label: 'Saturday',
  },
  {
    value: targetingDayEnum.SUN,
    label: 'Sunday',
  },
];

export const targetingHourValues = [...Array(24).keys()];

export const disableClusivity = [
  targetingParameterTypesEnum.AGE,
  targetingParameterTypesEnum.LISTENER_ACCURACY,
];

export enum targetingDMPNames {
  NIELSEN = 'NIELSEN',
}
