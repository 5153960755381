import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  template: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(1.25, 0, 2.5),
    padding: theme.spacing(5, 2.5, 0),
    borderRadius: theme.spacing(0.5),
    position: 'relative',
  },
  templateHeading: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1.25),
    display: 'inline-block',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
  },
  group: {
    textAlign: 'right',
    position: 'relative',
    '&::after': {
      content: "'or'",
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'block',
      margin: theme.spacing(1.25, 0),
    },
    '&--single::after': {
      content: 'none',
    },
  },
  iconAdd: {
    fontSize: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(2.5),
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  iconRemove: {
    fontSize: theme.spacing(2.5),
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  templateButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
