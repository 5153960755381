import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React from 'react';

import useStyles from './Breadcrumb.styles';

export interface IBreadcrumbLinkInfo {
  id: string;
  name: string;
  path: string;
}

export interface IBreadcrumbLinkProps {
  label: string;
  info?: IBreadcrumbLinkInfo;
}

const BreadcrumbLink = (props: IBreadcrumbLinkProps) => {
  const classes = useStyles();
  const { label, info } = props;

  const location = usePreviousLocation();

  return (
    <>
      <InputLabel data-testid={`breadcrumbLinkLabel-${label}`}>
        {label}
      </InputLabel>
      {info ? (
        <Link
          className={classes.linkContainer}
          to={{
            pathname: info.path,
            state: { parent: location.state },
          }}
          data-testid={`breadcrumbLinkField-${label}`}
        >
          {info.name}
        </Link>
      ) : (
        <div className={classes.defaultLink}>Undefined entity</div>
      )}
    </>
  );
};

export default BreadcrumbLink;
