import { ISliderValue } from 'features/targetingV2/types/common';

export const getValueByIndex = (
  selectedIndex: number,
  allValues: ISliderValue[]
) => allValues.find((value) => value.index === selectedIndex)?.value;

export const getSelectedIndex = (
  sliderValue: number | string,
  allValues: ISliderValue[]
) => allValues.find((value) => value.value === sliderValue)?.index;

export const getSliderMarks = (allValues: ISliderValue[]) =>
  allValues.map((value) => ({ value: value.index }));
