import Container from 'components/Container/Container';
import SplitButton from 'components/SplitButton/SplitButton';
import CampaignsHealthOverviewDonuts from 'features/direct/campaign/components/CampaignsHealthOverview/CampaignsHealthOverviewDonuts';
import CampaignsTable from 'features/direct/campaign/components/CampaignsTable/CampaignsTable';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import {
  useCloneCampaignMutation,
  User,
  useUpdateCampaignMutation,
} from 'interfaces/generated.types';
import React from 'react';

import useStyles from './DirectHomePage.styles';

interface IDirectHomePage {
  history: History;
  currentUser: User;
}

const DirectHomePage = ({ history, currentUser }: IDirectHomePage) => {
  const classes = useStyles();

  const [updateCampaign] = useUpdateCampaignMutation();

  const [cloneCampaign] = useCloneCampaignMutation();

  const validateCampaign = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const createCta = (
    <SplitButton
      toggleDataTc="splitButtonToggleCreate"
      buttons={[
        {
          text: 'Create Order',
          onClick: () =>
            history.push({
              pathname: '/order',
              state: {
                parent: history.location.state,
                from: history.location.pathname,
              },
            }),
          isLoading: false,
          disabled: false,
          dataTc: 'createOrderButton',
        },
        {
          text: 'Create Campaign',
          onClick: () =>
            history.push({
              pathname: '/campaign',
              state: {
                parent: history.location.state,
                from: history.location.pathname,
              },
            }),
          isLoading: false,
          disabled: false,
          dataTc: 'createCampaignButton',
        },
      ]}
    />
  );

  return (
    <main data-testid="directHomePage" className={classes.page}>
      <Container>
        <h1 className="sr-only">Home Page</h1>
        <div className={classes.titleWrapper}>
          <h2 className={classes.title}>Welcome back!</h2>
          {createCta}
        </div>
        <CampaignsTable
          title="User's Campaigns"
          updateCampaign={updateCampaign}
          cloneCampaign={cloneCampaign}
          validateCampaign={validateCampaign}
          history={history}
          isEditable
          allowBulkArchiving
          ownerId={currentUser.id}
          showTabs={false}
          CustomHeader={CampaignsHealthOverviewDonuts}
        />
      </Container>
    </main>
  );
};

export default DirectHomePage;
