import classNames from 'classnames';
import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';

import useStyles from './EntityProgressStep.styles';

export interface IEntityProgressStep {
  status: string;
  required: string[];
}

interface IEntityProgressStepProps {
  data: IEntityProgressStep;
  completed: boolean;
}

const EntityProgressStep = (props: IEntityProgressStepProps) => {
  const classes = useStyles();

  const { data, completed } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        data-testid="entityProgressStep"
      >
        <p
          className={classNames(classes.status, {
            [`${classes.status}--completed`]: completed,
          })}
          data-tc="entityProgressStepStatus"
        >
          {data.status}
        </p>
        <span
          className={classNames(classes.stepIcon, {
            [`${classes.stepIcon}--completed`]: completed,
          })}
        />
      </div>
      {data.required.length > 0 && !completed && (
        <Popover
          data-testid="entityProgressStepPopover"
          elevation={0}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          className={classes.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          disableRestoreFocus
          PaperProps={{
            classes: { root: classes.paper },
          }}
        >
          <div className={classes.popoverContent}>
            <ul className={classes.requiredList}>
              {data.required.map((field, index) => (
                /* eslint-disable react/no-array-index-key */
                <li key={index}>{field}</li>
              ))}
            </ul>
          </div>
        </Popover>
      )}
    </>
  );
};

export default EntityProgressStep;
