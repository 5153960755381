import Container from 'components/Container/Container';
import useStyles from 'features/primaryControls/containers/PrimaryControlsMessages/PrimaryControlsMessages.styles';
import {
  PrimaryControlEntity,
  usePrimaryControlsQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { formatListToString } from 'utils/formatStrings';
import { TerritoryLabel } from 'utils/territory';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const content: any = {
  [PrimaryControlEntity.Campaign]: {
    title: 'CAMPAIGNS FROZEN',
    message: (territories: string) =>
      `Direct Campaigns in ${territories} have been frozen. Users can still modify Campaigns throughout DAX Audio but all respective Ads have stopped delivering. This can be undone via "Primary Controls" (admin users only).`,
  },
  [PrimaryControlEntity.Deal]: {
    title: 'DEALS FROZEN',
    message: (territories: string) =>
      `Deals in ${territories} have been frozen. Users can still modify Deals throughout DAX Audio but respective ads have stopped delivering. This can be undone via "Primary Controls" (admin users only).`,
  },
  [PrimaryControlEntity.SalesChannel]: {
    title: 'PROGRAMMATICS QUARANTINED',
    message: (territories: string) =>
      `Quarantining in ${territories} has been enabled on all Programmatic functionality. This means all creatives for each Deal and Sales Channel will need to be manually approved before broadcast. This can be undone via "Primary Controls" (admin users only).`,
  },
};

const PrimaryControlsMessages = () => {
  const { loading, error, data } = usePrimaryControlsQuery({
    fetchPolicy: 'network-only',
  });
  const classes = useStyles({});

  if (loading) return null;

  if (!(data && data.primaryControls) || error) return null;

  const errorMessages: any = data.primaryControls
    .filter((control) => control.entries.find((entry) => entry.enabled))
    .map((control) => {
      const territories = control.entries
        .filter((entry) => entry.enabled)
        .map((entry) => TerritoryLabel[entry.territory]);

      const info = content[control.entity] || {};

      return (
        <div
          className={classes.container}
          key={control.entity}
          data-tc={`primaryMessage${control.entity}`}
          data-testid={`primaryMessage${control.entity}`}
        >
          <div className={classes.icon}>
            <ErrorOutlineIcon fontSize="large" color="error" />
          </div>
          <div>
            <span className={classes.title}>{info.title}:</span>
            {info.message(formatListToString({ list: territories }))}
          </div>
        </div>
      );
    });

  if (errorMessages.length) {
    return <Container>{errorMessages}</Container>;
  }

  return null;
};
export default PrimaryControlsMessages;
