import {
  addDays,
  addSeconds,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYesterday,
  isAfter,
  isBefore,
  isEqual,
  setMilliseconds,
  setSeconds,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { IDateRange } from 'interfaces';

export enum TimeZones {
  AmericaNewYork = 'America/New_York',
  AmericaTijuana = 'America/Tijuana',
  AmericaToronto = 'America/Toronto',
  EuropeBerlin = 'Europe/Berlin',
  EuropeLondon = 'Europe/London',
  EuropeParis = 'Europe/Paris',
}

const getIsoStringInSpecificTimezone = (date: Date, timeZone: TimeZones) => {
  const zerooutMilliseconds = setMilliseconds(date, 0);
  const zerooutSeconds = setSeconds(zerooutMilliseconds, 0);
  return zonedTimeToUtc(zerooutSeconds, timeZone).toISOString();
};

const getDateInSpecificTimezone = (isoDate: string, timeZone: TimeZones) =>
  utcToZonedTime(isoDate, timeZone);

const isDateInTheFuture = (date: Date, timeZone: TimeZones) => {
  const utcTime = zonedTimeToUtc(setSeconds(date, 0), timeZone);
  const isoStringCurrentDate = getIsoStringInSpecificTimezone(
    setSeconds(new Date(), 0),
    timeZone
  );
  const currentDateInTimezone = getDateInSpecificTimezone(
    isoStringCurrentDate,
    timeZone
  );
  const currentDateInTimezonePlusOneMinute = addSeconds(
    currentDateInTimezone,
    1
  );
  return isBefore(currentDateInTimezonePlusOneMinute, utcTime);
};

const isDateAfterTheOther = ({
  date,
  dateToCompare,
  timeZone,
}: {
  date: Date;
  dateToCompare: Date;
  timeZone: TimeZones;
}) => {
  const dateInSpecificTimeZone = zonedTimeToUtc(setSeconds(date, 0), timeZone);
  const dateToCompareInSpecificTimeZone = zonedTimeToUtc(
    setSeconds(dateToCompare, 0),
    timeZone
  );
  return isAfter(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone);
};

const isDateEqualOrAfterTheOther = ({
  date,
  dateToCompare,
  timeZone,
}: {
  date: Date;
  dateToCompare: Date;
  timeZone: TimeZones;
}) => {
  const dateInSpecificTimeZone = zonedTimeToUtc(setSeconds(date, 0), timeZone);
  const dateToCompareInSpecificTimeZone = zonedTimeToUtc(
    setSeconds(dateToCompare, 0),
    timeZone
  );
  return (
    isAfter(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone) ||
    isEqual(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone)
  );
};

const isDateBeforeTheOther = ({
  date,
  dateToCompare,
  timeZone,
}: {
  date: Date;
  dateToCompare: Date;
  timeZone: TimeZones;
  shouldRemoveOneMinuteDifference?: boolean;
}) => {
  const dateInSpecificTimeZone = zonedTimeToUtc(setSeconds(date, 0), timeZone);
  const dateToCompareInSpecificTimeZone = zonedTimeToUtc(
    setSeconds(dateToCompare, 0),
    timeZone
  );
  return isBefore(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone);
};

const isDateEqualOrBeforeTheOther = ({
  date,
  dateToCompare,
  timeZone,
}: {
  date: Date;
  dateToCompare: Date;
  timeZone: TimeZones;
  shouldRemoveOneMinuteDifference?: boolean;
}) => {
  const dateInSpecificTimeZone = zonedTimeToUtc(setSeconds(date, 0), timeZone);
  const dateToCompareInSpecificTimeZone = zonedTimeToUtc(
    setSeconds(dateToCompare, 0),
    timeZone
  );
  return (
    isBefore(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone) ||
    isEqual(dateInSpecificTimeZone, dateToCompareInSpecificTimeZone)
  );
};

const isDateInRange = (date: Date, start: Date, end: Date) =>
  (isAfter(date, start) || isEqual(date, start)) &&
  (isBefore(date, end) || isEqual(date, end));

const timezoneOptions = [
  { value: TimeZones.EuropeBerlin, label: 'Europe/Berlin' },
  { value: TimeZones.EuropeLondon, label: 'Europe/London' },
  { value: TimeZones.EuropeParis, label: 'Europe/Paris' },
  { value: TimeZones.AmericaToronto, label: 'America/Toronto' },
  { value: TimeZones.AmericaNewYork, label: 'America/New York' },
  { value: TimeZones.AmericaTijuana, label: 'America/Tijuana' },
];

const getInitialFocusedStartDate = (date = new Date()) => startOfDay(date);

const getInitialFocusedEndDate = (date = new Date()) => endOfDay(date);

const getNextMonday = () =>
  addWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1);

const getFollowingSunday = () => endOfDay(addDays(getNextMonday(), 6));

const getLastXDaysRange: (numberOfDays: number) => IDateRange = (
  numberOfDays
) => {
  const endDate = endOfYesterday();

  return { startDate: startOfDay(subDays(endDate, numberOfDays)), endDate };
};

const getCurrentMonthRange: (
  disablePast?: boolean,
  disableFuture?: boolean
) => IDateRange = (disablePast = false, disableFuture = false) => {
  const currentDate = new Date();
  let startDate: Date = startOfMonth(currentDate);
  let endDate: Date = endOfMonth(currentDate);

  if (disablePast) {
    startDate = currentDate;
  }

  if (disableFuture) {
    endDate = endOfYesterday();
  }

  return { startDate, endDate };
};

const getPreviousMonthRange: (disablePast?: boolean) => IDateRange = (
  disablePast = false
) => {
  const currentDate = new Date();
  let startDate: Date = startOfMonth(subMonths(currentDate, 1));
  let endDate: Date = endOfMonth(subMonths(currentDate, 1));

  if (disablePast) {
    startDate = currentDate;
    endDate = currentDate;
  }

  return { startDate, endDate };
};

const getCurrentQuarterRange: (
  disablePast?: boolean,
  disableFuture?: boolean
) => IDateRange = (disablePast = false, disableFuture = false) => {
  const currentDate = new Date();
  let startDate: Date = startOfQuarter(currentDate);
  let endDate: Date = endOfQuarter(currentDate);

  if (disablePast) {
    startDate = currentDate;
  }

  if (disableFuture) {
    endDate = endOfYesterday();
  }

  return { startDate, endDate };
};

const getUtcDateAfterXDays = (
  date: Date,
  timezone: string,
  noOfDays: number
): Date => {
  const dateAfterXDays = addDays(date, noOfDays);
  const utcDateAfterXDays = zonedTimeToUtc(dateAfterXDays, timezone);
  return utcDateAfterXDays;
};

export default {
  getDateInSpecificTimezone,
  getInitialFocusedEndDate,
  getInitialFocusedStartDate,
  getIsoStringInSpecificTimezone,
  getNextMonday,
  getFollowingSunday,
  isDateAfterTheOther,
  isDateEqualOrAfterTheOther,
  isDateBeforeTheOther,
  isDateEqualOrBeforeTheOther,
  isDateInTheFuture,
  isDateInRange,
  timezoneOptions,
  getLastXDaysRange,
  getCurrentMonthRange,
  getCurrentQuarterRange,
  getPreviousMonthRange,
  getUtcDateAfterXDays,
};
