import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '0.813em',
    color: theme.palette.grey[400],
  },
  pixelTab: {
    marginBottom: theme.spacing(1),

    '&--disabled': {
      position: 'relative',

      '&::after': {
        content:
          "'You cannot generate a tracking pixel until you have created the Ad. Please create the Ad first.'",
        padding: theme.spacing(2),
        position: 'absolute',
        left: theme.spacing(-2.5),
        right: theme.spacing(-2.5),
        top: theme.spacing(-2.5),
        bottom: theme.spacing(-1.625),
        backgroundColor: theme.palette.grey[500],
        opacity: 0.9,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        zIndex: 1,
      },
    },
  },
  multiSelect: {
    margin: theme.spacing(2, 0, 2, 4.672),
  },
  cardButton: {
    margin: theme.spacing(2.5, 0),
    border: `1px dashed ${theme.palette.common.black}`,
    boxShadow: 'none',
    fontSize: '1em',
    width: '100%',
    padding: theme.spacing(2.5),
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.black,

    '&--disabled': {
      borderColor: theme.palette.grey[400],
    },
  },
  trackingPixelWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  heading: {
    fontSize: '1em',
    fontWeight: 'normal',
  },
  copyIcon: {
    color: theme.palette.grey[400],
  },
}));

export default useStyles;
