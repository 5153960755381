import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { useUserContext } from 'context/UserProvider/UserProvider';
import React, { useState } from 'react';
import { AuthState } from 'utils/authProvider';
import { hasUserProvidedConsentChoices } from 'utils/cookieConsent';

import CookieConsentModal from './CookieConsentModal/CookieConsentModal';

const CookieConsent = () => {
  const { state } = useAuthContext();
  const loggedInUser = useUserContext();

  const [isCookieConsentModalOpen, setIsCookieConsentModalOpen] = useState(
    state === AuthState.LoggedIn &&
      !hasUserProvidedConsentChoices(loggedInUser.email)
  );

  return (
    <CookieConsentModal
      testId="consent"
      isModalOpen={isCookieConsentModalOpen}
      setIsModalOpen={setIsCookieConsentModalOpen}
    />
  );
};

export default CookieConsent;
