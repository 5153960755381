import MenuItem from 'features/targetingV2/components/core/MenuItem';
import Select from 'features/targetingV2/components/core/Select';
import Typography from 'features/targetingV2/components/core/Typography';
import GradingIcon from 'features/targetingV2/components/Grading/GradingIcon/GradingIcon';
import GradingSelectOption from 'features/targetingV2/components/Grading/GradingSelectOption/GradingSelectOption';
import { getDefaultEnabledGrades } from 'features/targetingV2/utils/defaults';
import { getAllGradesOrdered } from 'features/targetingV2/utils/grading';
import { TargetingGrade } from 'interfaces/generated.types';
import React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './GradingSelect.styles';

export interface IGradingSelectProps {
  fieldName: string;
  onChange: (value: TargetingGrade[]) => void;
  enabledGrades?: TargetingGrade[];
}

const GradingSelect = ({
  fieldName,
  onChange,
  enabledGrades = getDefaultEnabledGrades(),
}: IGradingSelectProps) => {
  const classes = useStyles();

  const customRenderValue = () => (
    <div className={classes.multiValueContainer}>
      <Typography variant="body2">with grade</Typography>
      <div className={classes.multiValueGradingContainer}>
        <GradingIcon
          enabledGrades={enabledGrades}
          id={`${fieldName}-enabled-grades`}
        />
      </div>
    </div>
  );

  const allGrades = getAllGradesOrdered();

  return (
    <Select
      data-testid={`${fieldName}-grades-select`}
      name={fieldName}
      value={enabledGrades}
      onChange={(e: React.ChangeEvent<{ value: any }>) => {
        const newGrades = e.target.value as TargetingGrade[];
        if (newGrades.length > 0) onChange(newGrades);
      }}
      IconComponent={ExpandMore}
      inputProps={{
        classes: {
          root: classes.dropdown,
          icon: classes.dropdownIcon,
          select: classes.dropdownSelect,
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        getContentAnchorEl: null,
        classes: {
          list: classes.list,
        },
      }}
      renderValue={customRenderValue}
      displayEmpty
      multiple
    >
      {!allGrades.length ? (
        <MenuItem>No options available</MenuItem>
      ) : (
        allGrades.map((grade: TargetingGrade) => (
          <MenuItem
            key={grade}
            value={grade}
            classes={{
              root: classes.optionContainer,
            }}
            disableRipple
          >
            <GradingSelectOption
              fieldName={fieldName}
              grade={grade}
              enabled={enabledGrades.includes(grade)}
            />
          </MenuItem>
        ))
      )}
    </Select>
  );
};

export default GradingSelect;
