import CheckboxTree from 'features/targetingV2/components/core/CheckboxTree/CheckboxTree';
import { IMenuListProps, NodeLike } from 'features/targetingV2/types/common';
import { formatMenuTreeListSelection } from 'features/targetingV2/utils/formValuesFormatting';
import React, { useState } from 'react';

import useStyles from './MenuDualTreeList.styles';

const MenuDualTreeList = (props: any) => {
  const classes = useStyles();

  const { selectProps, children } = props;
  const {
    firstList,
    secondList,
    additionalEditingFunction,
    allPreviouslySelectedNodes,
  } = selectProps.menuDualListProps;

  const handleControlledNodeToggle = (
    newSelectedNodes: NodeLike[],
    menuList: IMenuListProps,
    otherMenuList: IMenuListProps
  ) => {
    const previouslySelectedNodes = menuList.selectedNodes;

    const formattedNodes = formatMenuTreeListSelection(
      newSelectedNodes,
      previouslySelectedNodes || [],
      menuList.options
    );

    let newNodes = formattedNodes;
    if (additionalEditingFunction)
      newNodes = additionalEditingFunction({
        newSelectedNodes: formattedNodes,
        menuList,
        otherMenuList,
        allPreviouslySelectedNodes,
      });

    selectProps.onChange(newNodes);
  };
  const [firstListHasNoSearchResults, setFirstListHasNoSearchResults] =
    useState(false);
  const [secondListHasNoSearchResults, setSecondListHasNoSearchResults] =
    useState(false);

  return firstList.options.length > 0 || secondList.options.length > 0 ? (
    <div className={classes.listsContainer}>
      <CheckboxTree
        data={firstList.options as NodeLike[]}
        title={firstList.title}
        selected={firstList.selectedNodes || []}
        classes={{ label: classes.breadcrumbLabel }}
        isStateControlled
        filterText={selectProps.inputValue}
        onControlledNodeToggle={(newSelectedNodes) =>
          handleControlledNodeToggle(newSelectedNodes, firstList, secondList)
        }
        disableExpansionControls
        disableSelectionControls
        disableFilteringField
        displayAncestorPath={
          firstList.displayAncestorPath !== undefined
            ? firstList.displayAncestorPath
            : !!selectProps.inputValue
        }
        noNodesMessage={
          firstListHasNoSearchResults && secondListHasNoSearchResults
            ? selectProps.noOptionsMessage()
            : ''
        }
        searchResultsLimit={selectProps.maxSearchResults}
        handleNoSearchResults={(hasNoSearchResults) =>
          setFirstListHasNoSearchResults(hasNoSearchResults)
        }
        testId={`${selectProps.testId}-firstList`}
      />
      <CheckboxTree
        data={secondList.options as NodeLike[]}
        title={secondList.title}
        selected={secondList.selectedNodes || []}
        classes={{ label: classes.breadcrumbLabel }}
        isStateControlled
        filterText={selectProps.inputValue}
        onControlledNodeToggle={(newSelectedNodes) => {
          handleControlledNodeToggle(newSelectedNodes, secondList, firstList);
        }}
        disableExpansionControls
        disableSelectionControls
        disableFilteringField
        displayAncestorPath={
          secondList.displayAncestorPath !== undefined
            ? secondList.displayAncestorPath
            : !!selectProps.inputValue
        }
        searchResultsLimit={selectProps.maxSearchResults}
        handleNoSearchResults={(hasNoSearchResults) =>
          setSecondListHasNoSearchResults(hasNoSearchResults)
        }
        testId={`${selectProps.testId}-secondList`}
      />
    </div>
  ) : (
    children
  );
};

export default MenuDualTreeList;
