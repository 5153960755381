import dropdownStyles from 'components/Territory/TerritoryDropdown/TerritoryDropdown.styles';
import React from 'react';

import { Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { OptionType } from 'interfaces';
import Loader from 'components/Loader/Loader';
import useStyles from './SingleSelect.styles';

export interface ISingleSelectProps {
  fieldName: string;
  fieldLabel: string;
  value: string;
  options: OptionType[];
  handleChange: (value: any) => void;
  isLoading?: boolean;
  defaultOptionLabel?: string;
}

const SingleSelect = ({
  fieldName,
  fieldLabel,
  value,
  options,
  handleChange,
  isLoading = false,
  defaultOptionLabel = 'Select',
}: ISingleSelectProps) => {
  const classes = { ...useStyles(), ...dropdownStyles() };

  const renderedValue = () => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : defaultOptionLabel;
  };

  const customRenderValue = isLoading
    ? () => <Loader size={20} />
    : renderedValue;

  const CustomInput = (
    <Input
      data-testid={`${fieldName}-select-input`}
      classes={{ root: classes.root, input: classes.input }}
    />
  );

  const selectedValue = options.length > 0 ? value : '';

  return (
    <>
      <InputLabel id={`select-${fieldName}`}>{fieldLabel}</InputLabel>
      <Select
        labelId={`select-${fieldName}`}
        value={selectedValue}
        classes={{
          root: classes.dropdown,
        }}
        onChange={(e: React.ChangeEvent<{ value: any }>) => {
          handleChange(e.target.value);
        }}
        IconComponent={ExpandMore}
        input={CustomInput}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        renderValue={customRenderValue}
        displayEmpty
      >
        <MenuItem key={`default-option-${fieldLabel}`} value="">
          {defaultOptionLabel}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SingleSelect;
