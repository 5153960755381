import { PublisherCategory } from 'interfaces/generated.types';

export const getPublisherCategoryOptions = (
  allPublisherCategories: PublisherCategory[]
) => {
  const publisherCategoryOptions = allPublisherCategories.map(
    (publisherCategory) => ({
      value: publisherCategory.id,
      label: publisherCategory.name,
    })
  );

  return publisherCategoryOptions;
};

export default {
  getPublisherCategoryOptions,
};
