import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: '20px',
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    fontSize: '14px',
    '&:hover, &:focus': {
      borderRadius: '2px',
      backgroundColor: theme.palette.common.white,
    },
    borderRadius: '0px 4px 4px 0px',
    borderLeft: 'none',
  },
  dropdownSelect: {
    paddingLeft: '32px',
    '&.MuiSelect-select': {
      paddingRight: '8px',
    },
  },
  multiValueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  multiValueGradingContainer: {
    marginLeft: '8px',
    marginBottom: '2px',
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0, 1),
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: colors.primaryBlueLight,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: colors.primaryBlueLight,
      },
    },
    '&:last-child': {
      borderWidth: 0,
    },
  },
  dropdownIcon: {
    left: 0,
    marginLeft: '4px',
    marginRight: '4px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '456px',
    height: '229px',
    padding: 0,
  },
}));

export default useStyles;
