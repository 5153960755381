import * as Yup from 'yup';

const PasswordResetFormValidation = Yup.object().shape({
  password: Yup.string()
    .required('Please enter your password')
    .matches(/^(?=.{8,})/, 'Must Contain 8 Characters'),

  passwordConfirm: Yup.string()
    .required('Please enter your password again')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export default PasswordResetFormValidation;
