import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  TableHead as MUITableHead,
  TableHeadProps as MUITableHeadProps,
} from '@material-ui/core';

import useStyles from './TableHead.styles';

export type TableHeadProps = MUITableHeadProps;

const TableHead = React.forwardRef(
  (props: TableHeadProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUITableHead ref={ref} classes={classes} {...otherProps} />;
  }
);

TableHead.displayName = 'TableHead';

export default TableHead;
