import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNav';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { IAdvertiserValues } from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserFormValues';
import AdvertiserTabForm, {
  advertiserButtons,
} from 'features/direct/advertiser/components/AdvertiserTabsForm/AdvertiserTabsForm';
import AdvertiserFormValidation from 'features/direct/advertiser/validations/advertisers';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate, IFormValues } from 'interfaces';
import {
  Advertiser,
  Agency,
  EntityType,
  UpdateAdvertiserMutationFn,
  useUpdateAdvertiserMutation,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createDirectBreadcrumbConfig } from 'utils/breadcrumbConfigFactory';
import { transformIabCategoryOptions } from 'utils/dataTransformation';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface IUpdateAdvertiserContainerProps {
  history: History;
  advertiser: Advertiser;
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  id: string,
  agencyId: string,
  selectedJourney: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: agencyId,
    listEntity: selectedJourney === '/agency/PARENT_ID' ? 'Advertiser' : '',
  });

export const submitUpdateAdvertiser = (
  updateAdvertiser: UpdateAdvertiserMutationFn,
  initialValues: IAdvertiserValues,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IAdvertiserValues,
  { setStatus, setSubmitting }: FormikActions<IAdvertiserValues>
) => {
  setSelectedJourney(formValues.journey);
  const filteredValues: any = {
    ...differenceInValues(
      {
        ...formValues,
        journey: null,
        generatedDomains: null,
      },
      { ...initialValues, journey: null, generatedDomains: null }
    ),
    id: formValues.id,
  } as IFormValues;

  updateAdvertiser({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateAdvertiserContainer = ({
  history,
  advertiser,
  hasEditPermissions,
}: IUpdateAdvertiserContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Advertiser.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateAdvertiser] = useUpdateAdvertiserMutation({
    onCompleted() {
      handleOnComplete(
        history,
        advertiser.id,
        (advertiser.agency as Agency).id,
        selectedJourney
      );
    },
  });
  const validateAdvertiser = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const hasAdSeparation = typeof advertiser.adSeparation === 'number';

  const initialValues = {
    agencyId: (advertiser.agency as Agency).id,
    agencyName: (advertiser.agency as Agency).name as string,
    name: advertiser.name as string,
    id: advertiser.id,
    altId: advertiser.altId,
    generatedDomains: '',
    externalId: advertiser.externalId || '',
    externalSystemId: advertiser.externalSystemId
      ? advertiser.externalSystemId
      : '',
    domains: advertiser.domains.length ? advertiser.domains : [''],
    journey: advertiserButtons[0].url,
    status: advertiser.status,
    adSeparation: hasAdSeparation ? advertiser.adSeparation.toString() : '',
    hasAdSeparation,
    iabCategoryCodes: transformIabCategoryOptions(advertiser.iabCategories),
    territory: advertiser.territory,
  };

  const location = usePreviousLocation();

  const breadcrumbConfig = createDirectBreadcrumbConfig(advertiser);

  return (
    <>
      <BreadcrumbNav config={breadcrumbConfig} />
      <Formik<IAdvertiserValues>
        initialValues={initialValues}
        validationSchema={AdvertiserFormValidation}
        onSubmit={submitUpdateAdvertiser(
          updateAdvertiser,
          initialValues,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <AdvertiserTabForm
            {...formikProps}
            update
            goBackTo={{
              pathname: `/agency/${(advertiser.agency as Agency).id}`,
              state: location.state?.parent || {},
            }}
            history={history}
            hasEditPermissions={hasEditPermissions}
            agencyStatus={(advertiser.agency as Agency).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateAdvertiser,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Advertiser,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateAdvertiser,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateAdvertiser"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateAdvertiserWarningDialog"
      />
    </>
  );
};

export default UpdateAdvertiserContainer;
