import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    padding: theme.spacing(0, 2, 0, 1),
    color: colors.primaryBlue,
    '&:hover': {
      backgroundColor: colors.primaryBlueLight,
    },
  },
  menuItemIcon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '16px',
      height: '16px',
      marginRight: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
  },
}));

export default useStyles;
