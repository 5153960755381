import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import NetworkDetails from 'features/inventory/network/components/NetworkDetails/NetworkDetails';
import {
  getNetworkStatusData,
  INetworkValues,
  networkDetailsFields,
  networkStatusValues,
} from 'features/inventory/network/components/NetworkTabsForm/NetworkFormValues';
import PublishersTable from 'features/inventory/publisher/components/PublishersTable/PublishersTable';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFieldUpdate, IFieldValidate, IFormProps } from 'interfaces';
import { EntityType, Publisher } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

export const networkButtons = [
  {
    text: 'Save and Review',
    url: '/network/ID',
    update: true,
    dataTc: 'networkReviewButton',
  },
  {
    text: 'Save and Create Publisher',
    url: '/network/ID/publisher',
    update: false,
    dataTc: 'networkCreatePublisherButton',
  },
  {
    text: 'Save and View All Networks',
    url: '/networks',
    update: false,
    dataTc: 'networkListButton',
  },
];

interface INetworkFormProps {
  update?: boolean;
  hasEditPermissions?: boolean;
  history?: History;
  match?: {
    params: {
      networkId: string;
    };
  };
  publishers?: Publisher[];
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
}

const getNetworkDetailsTabErrors = createNumberOfFormErrorsFn();

const NetworkTabsForm = (
  props: IFormProps<INetworkValues> & INetworkFormProps
) => {
  const {
    setFieldValue,
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    hasEditPermissions = true,
    history,
    match,
    publishers,
    status,
    onFieldUpdate,
    onFieldValidate,
  } = props;
  const classes = TabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { entityList, tab } = location.state || 0;
    if (entityList === 'listPublishers') setTab(1);
    if (tab) setTab(tab);
  }, [location]);

  const isNetworkTab = selectedTab === 0;
  const isListPublishersTab = selectedTab === 1;
  const isNetworkActivityTab = selectedTab === 2;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && (
          <>
            <EntityProgressBar
              statusData={getNetworkStatusData(values)}
              currentStatus={values.status || ''}
              dataTc="networkStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={networkStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="networkHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="networkTabs"
          >
            <Tab
              dataTc="networkDetailsTab"
              label="Details"
              numberOfTabErrors={getNetworkDetailsTabErrors(
                status,
                networkDetailsFields
              )}
            />
            <Tab
              disabled={!update}
              dataTc="listPuplishersTab"
              label="Publishers"
            />
            <Tab
              disabled={!update}
              dataTc="networkActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListPublishersTab || isNetworkActivityTab,
            },
          ])}
        >
          {isNetworkTab && <NetworkDetails {...props} />}
          {isListPublishersTab && match && history && publishers && (
            <PublishersTable
              isEditable={hasEditPermissions}
              history={history}
              match={match}
              publishers={publishers}
            />
          )}
          {isNetworkActivityTab && (
            <ActivityTable
              entityType={EntityType.Network}
              id={values.id}
              data-tc="networkActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitNetworkButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/networks',
            state: location.state?.parent || {},
          }}
          variant={FormButtonVariants.split}
          buttons={networkButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default NetworkTabsForm;
