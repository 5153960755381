import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import Audience from 'features/targetingV2/components/AudienceSection/Audience/Audience';
import ErrorDialog from 'features/targetingV2/components/core/ErrorDialog/ErrorDialog';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import useError from 'features/targetingV2/hooks/Error/useError';
import { IFormProps, SectionType } from 'features/targetingV2/types/common';
import { IAudienceParameterGroup } from 'features/targetingV2/types/targeting';
import { hasInvalidParameters } from 'features/targetingV2/utils/common';
import {
  defaultAudienceParameter,
  getDefaultEnabledGrades,
} from 'features/targetingV2/utils/defaults';
import { FieldArray } from 'formik';
import { useAllAudienceParametersQuery } from 'interfaces/generated.types';
import React, { useEffect } from 'react';

import { getSelectOptions } from 'utils/dataTransformation';
import useStyles from './AudienceBuilder.styles';

const AudienceBuilder = (props: IFormProps<any>) => {
  const classes = useStyles();
  const { values, setFieldValue } = props;

  const audiencesFieldName =
    'targetingDefinitionV2.audienceTargeting.audiences';

  const { loading, data, error } = useAllAudienceParametersQuery({
    fetchPolicy: 'cache-first',
  });

  const { hasError, toggleErrorModal, errorMessages, setErrorMessages } =
    useError(['An error has occurred.']);

  const handleError = (err: any) => {
    const errorHeader =
      'Error while loading audience targeting parameters. Please refresh and try again.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const audiences = values.targetingDefinitionV2.audienceTargeting
    ?.audiences as IAudienceParameterGroup[];

  const builderHasInvalidAudience = audiences.some((audience) =>
    hasInvalidParameters(audience.parameters)
  );

  const allParametersInfo = data?.allAudienceParameters || [];

  const getNewAudience = (parameterType: string) => ({
    parameters: [
      {
        ...defaultAudienceParameter,
        type: parameterType,
        enabledGrades: getDefaultEnabledGrades(parameterType),
        isFocused: true,
      },
    ],
  });

  const optionalAddFirstAudienceButton = !audiences.length && (
    <AddParameterDropdownButton
      options={getSelectOptions(allParametersInfo, 'name', 'type')}
      onOptionClick={(parameterType: string) => {
        setFieldValue(audiencesFieldName, [getNewAudience(parameterType)]);
      }}
      loading={loading}
      testId="add-first-audience-btn"
    />
  );

  return (
    <>
      <TargetingSection
        sectionType={SectionType.Audience}
        headerChildren={optionalAddFirstAudienceButton}
        restrictionsList={values.targetingRestrictions}
      >
        {!loading && (
          <FieldArray name={audiencesFieldName}>
            {({ push: pushAudience, remove: removeAudience }) => (
              <>
                {audiences.map(
                  (audience: IAudienceParameterGroup, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`audience-${index}`}>
                      {index >= 1 && (
                        <span className={classes.divider}>OR</span>
                      )}
                      <Audience
                        audience={audience}
                        audienceFieldName={`${audiencesFieldName}[${index}]`}
                        audienceIndex={index}
                        allParametersInfo={allParametersInfo}
                        areParameterOptionsLoading={loading}
                        setFieldValue={setFieldValue}
                        addAudience={(parameterType: string) =>
                          pushAudience(getNewAudience(parameterType))
                        }
                        removeAudience={removeAudience}
                        disableAddParameterButton={hasInvalidParameters(
                          audience.parameters
                        )}
                        hideCreateGroupButton={builderHasInvalidAudience}
                        hasAccuracy
                      />
                    </React.Fragment>
                  )
                )}
              </>
            )}
          </FieldArray>
        )}
      </TargetingSection>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        name="allAudienceParameterError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default AudienceBuilder;
