import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';

import { salesChannelStatusValues } from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelValues';
import { GET_ALL_SALES_CHANNELS } from 'features/programmatic/salesChannel/graphql/queries';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  EntityType,
  SalesChannel,
  SalesChannelStatus,
  Territory,
  User,
  useUpdateSalesChannelMutation,
} from 'interfaces/generated.types';

import { findLabelValue, removeEmailDomain } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

interface ISalesChannelsRow {
  id: string;
  altId: number;
  name: string;
  owner: string;
  status: SalesChannelStatus;
}

interface ISalesChannelsTableProps {
  history: History;
  allSalesChannels: SalesChannel[];
  isEditable: boolean;
  activeTerritory: Territory;
}

const formatData = memoizeOne((data: SalesChannel[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    owner: d.owner ? removeEmailDomain((d.owner as User).email || '') : '',
    status: d.status,
  }))
);

const SalesChannelsTable = ({
  history,
  allSalesChannels,
  isEditable,
  activeTerritory,
}: ISalesChannelsTableProps) => {
  const [updateSalesChannel] = useUpdateSalesChannelMutation({
    refetchQueries: [
      {
        query: GET_ALL_SALES_CHANNELS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });
  const validateSalesChannel = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createSalesChannelCta = isEditable && (
    <StyledLink
      location={{
        pathname: '/sales-channel',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createSalesChannelButton"
    >
      Create Sales Channel
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<ISalesChannelsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/sales-channel/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ISalesChannelsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: salesChannelStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={salesChannelStatusValues}
                name="salesChannelStatusSelect"
                dataTc="salesChannelStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <Table
      history={history}
      columns={columns}
      data={formatData(allSalesChannels)}
      isEditable={isEditable}
      dataTc="listSalesChannelsTable"
      data-testid="listSalesChannelsTable"
      onCellUpdate={(
        row: ISalesChannelsRow,
        setErrorModal: any,
        setUpdating: any
      ) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            status: row.status,
          },
          update: updateSalesChannel,
          handleContinue: () => history.push(`/sales-channel/${row.id}`),
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Sales Channel',
          },
        })
      }
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateSalesChannel,
          entity: { ...entity, type: EntityType.Saleschannel },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      customToolbarCtas={createSalesChannelCta}
    />
  );
};

export default SalesChannelsTable;
