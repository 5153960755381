import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import ConnectedCreateUserContainer from 'features/user/containers/CreateUserContainer/CreateUserContainer';

interface CreateUserPageProps {
  history: History;
}

const CreateUserPage = ({ history }: CreateUserPageProps) => (
  <main>
    <Container>
      <h1 className="sr-only">Create User Page</h1>
      <ConnectedCreateUserContainer history={history} />
    </Container>
  </main>
);

export default CreateUserPage;
