import colors from 'assets/styles/colors';
import { ChipState } from 'features/targetingV2/types/common';

import { makeStyles, Theme } from '@material-ui/core/styles';

const stateToStyle = {
  default: {
    color: colors.daxBlue800,
    background: colors.daxBlue50,
    border: `1px solid ${colors.daxBlue800Light}`,
  },
  warning: {
    background: colors.daxOrange50,
    border: `1px solid ${colors.daxOrange100}`,
    color: colors.neutral,
  },
  inactive: {
    background: colors.daxGrey50,
    border: `1px solid ${colors.daxGrey100}`,
    color: colors.daxGreyscaleNeutral,
  },
};
const clearIndicatorColor = {
  default: colors.daxBlue800,
  warning: colors.neutral,
  inactive: colors.neutral,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ state }: { state: ChipState }) => ({
    ...stateToStyle[state],
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',

    '&.Mui-disabled': {
      ...stateToStyle[ChipState.INACTIVE],
      opacity: 1,
    },
  }),
  label: {
    padding: theme.spacing(0, 0.75),
  },
  breadcrumbsContainer: {
    color: 'inherit',
    fontWeight: 400,
    paddingRight: theme.spacing(0.75),
    order: 1,
    opacity: 0.8,
  },
  clearIndicator: ({ state }: { state: ChipState }) => ({
    order: 3,
    color: clearIndicatorColor[state],
    '&:hover': {
      color: clearIndicatorColor[state],
    },
  }),
}));

export default useStyles;
