import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  steps: {
    alignItems: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[500],
    maxWidth: '75%',
    margin: theme.spacing(0, 'auto'),
    padding: 0,
  },
  stepLine: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    position: 'relative',
    marginTop: theme.spacing(2.6),

    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '6px',
      backgroundColor: theme.palette.grey[300],
    },
    '&--completed::before': {
      backgroundColor: theme.palette.primary.light,
    },
    '&--active::after': {
      content: '""',
      position: 'absolute',
      height: '6px',
      left: '0',
      right: '45%',
      backgroundColor: theme.palette.primary.light,
      clipPath: 'polygon(0 0, 100% 0%, 90% 100%, 0% 100%)',
      WebkitClipPath: 'polygon(0 0, 100% 0%, 90% 100%, 0% 100%)',
    },
  },
  iconContainer: {
    padding: '0',
    justifyContent: 'center',
  },
  stepWrapper: {
    padding: theme.spacing(0, 4),
  },
}));

export default useStyles;
