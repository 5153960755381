import DualFilteredTreeList, {
  ITreeChannelData,
} from 'components/DualFilteredTreeList/DualFilteredTreeList';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { Publisher } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { getTargetingPath, IAudienceParams } from 'utils/targetingDefinitions';
import {
  formatPublisherData,
  getChannelName,
} from 'utils/treeDataTransformation';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import useStyles from './DualTreeDialog.styles';

interface IDualTreeDialogProps {
  setFieldValue(field: string, value: any): void;
  targetingGroupValues: {
    audienceParams: IAudienceParams[];
    channels: ITreeChannelData[];
  }[];
  targetingGroupName: string;
  templateIndex?: number;
  allPublishers: Publisher[] | [];
  groupIndex: number;
  isOpen: boolean;
  handleClose: () => void;
}

const DualTreeDialog = (props: IDualTreeDialogProps) => {
  const {
    setFieldValue,
    targetingGroupValues,
    targetingGroupName,
    templateIndex,
    groupIndex,
    allPublishers,
    isOpen,
    handleClose,
  } = props;
  const classes = useStyles();
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    targetingGroupValues[groupIndex].channels.flatMap(
      (channel: ITreeChannelData) => channel.id
    )
  );
  const [readOnlyIds] = useState(
    targetingGroupValues[groupIndex].channels
      .filter((data: ITreeChannelData) => data.readOnly)
      .map((data: ITreeChannelData) => data.id)
  );

  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <DualFilteredTreeList
          data-tc="targetingChannels"
          allItems={formatPublisherData(allPublishers)}
          onChangeWithIds={(selectedValues: string[]) =>
            setSelectedChannelIds(selectedValues)
          }
          selectedItemsIds={selectedChannelIds}
          allData={targetingGroupValues[groupIndex].channels}
        />
      </DialogContent>
      <DialogActions className={classes.dialogOptions}>
        <StyledButton
          data-tc="cancelChannelsButton"
          variant={ButtonVariantEnum.Outlined}
          color={ButtonColorEnum.Primary}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton
          data-tc="applyChannelsButton"
          variant={ButtonVariantEnum.Contained}
          color={ButtonColorEnum.Primary}
          onClick={() => {
            setFieldValue(
              `${targetingPath}.channels`,
              selectedChannelIds.map((channelId: any) => ({
                id: channelId,
                name: getChannelName(channelId, allPublishers),
                readOnly: readOnlyIds.some((id: string) => id === channelId),
              }))
            );
            handleClose();
          }}
        >
          Apply
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DualTreeDialog;
