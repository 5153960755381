import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { ITechnicalProviderValues } from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderFormValues';
import TechnicalProviderTabsForm, {
  technicalProviderButtons,
} from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderTabsForm';
import TechnicalProviderFormValidation from 'features/programmatic/technicalProvider/validations/technicalProviders';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  CreateTechnicalProviderMutation,
  CreateTechnicalProviderMutationFn,
  DspProvider,
  TechnicalProvider,
  useCreateTechnicalProviderMutation,
  VastVersion,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { getDefaultCurrencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTerritoryValues } from 'utils/territory';

interface ICreateTechnicalProviderContainerProps {
  history: History;
}

export const handleOnComplete = async (
  response: CreateTechnicalProviderMutation,
  history: History,
  selectedJourney: string
) => {
  const { createTechnicalProvider } = response;
  const { id: techProviderId } = createTechnicalProvider as TechnicalProvider;

  handleOnJourneyRouting({ history, selectedJourney, id: techProviderId });
};

export const submitCreateTechnicalProvider = (
  createTechnicalProvider: CreateTechnicalProviderMutationFn,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ITechnicalProviderValues,
  { setStatus, setSubmitting }: FormikActions<ITechnicalProviderValues>
) => {
  setSelectedJourney(formValues.journey);

  const formattedValues = formatValues(formValues);
  const filteredValues = {
    ...pickBy({
      ...formValues,
      kvps1stPartyAllData: null,
      journey: null,
      truncateGpsCoords: formattedValues.truncateGpsCoords,
      truncateIpAddress: formattedValues.truncateIpAddress,
      kvps1stPartyData: formattedValues.kvps1stPartyData,
      consentVendorId: formattedValues.consentVendorId,
      appName: null,
      appDomain: null,
      publisherName: null,
      publisherDomain: null,
      siteName: null,
      siteDomain: null,
    }),
    territories: formattedValues.territories,
  };

  createTechnicalProvider({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateTechnicalProviderContainer = ({
  history,
}: ICreateTechnicalProviderContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Programmatic Connector.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createTechnicalProvider] = useCreateTechnicalProviderMutation({
    onCompleted(data) {
      handleOnComplete(data, history, selectedJourney);
    },
  });

  return (
    <>
      <Formik<ITechnicalProviderValues>
        initialValues={{
          territories: activeTerritory
            ? getTerritoryValues([activeTerritory])
            : [],
          name: '',
          id: '',
          userSyncUrl: '',
          userSyncSecureUrl: '',
          currency: getDefaultCurrencyBasedOnTerritory(activeTerritory!),
          blockGeoData: false,
          truncateGpsCoords: false,
          blockIpData: false,
          truncateIpAddress: false,
          block1stPartyData: false,
          kvps1stPartyAllData: true,
          kvps1stPartyData: '',
          blockListenerId: false,
          gzipCompressed: false,
          blockSite: false,
          siteName: 'dax',
          siteDomain: 'thisisdax.com',
          blockApp: false,
          appBundle: '',
          appName: 'dax',
          appDomain: 'thisisdax.com',
          blockPublisher: false,
          publisherId: 'dax',
          publisherName: 'dax',
          publisherDomain: 'thisisdax.com',
          publisherTagId: 'dax',
          sendNotSyncedUsers: false,
          consentVendorId: '',
          journey: technicalProviderButtons[0].url,
          vastVersions: [VastVersion.Two, VastVersion.Three, VastVersion.Four],
          dspProvider: DspProvider.DspProviderUnspecified,
          bidFloorPrecision: '16',
        }}
        validationSchema={TechnicalProviderFormValidation}
        onSubmit={submitCreateTechnicalProvider(
          createTechnicalProvider,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={TechnicalProviderTabsForm}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createTechnicalProvider"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateTechnicalProviderContainer;
