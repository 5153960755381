import { ISliderValue } from 'features/targetingV2/types/common';

export const getAllAccuracyValues = (
  allValues: number[] | string[] | undefined
): ISliderValue[] =>
  allValues
    ? allValues.map((value, index) => ({
        index: index + 1,
        value,
      }))
    : [];

export const getSliderValue = (
  accuracyRadius: number,
  defaultValue: number
): number => (accuracyRadius === -1 ? defaultValue : accuracyRadius);

export const getFormattedAccuracyValue = (accuracyValue: number) =>
  accuracyValue !== -1 ? `${accuracyValue} km` : null;
