import classNames from 'classnames';
import ClusivitySelect from 'features/targetingV2/components/ClusivitySelect/ClusivitySelect';
import useStyles from 'features/targetingV2/components/ParameterRowWithDoubleCardinality/ParameterRowWithDoubleCardinality.styles';
import DropdownSelector from 'features/targetingV2/components/ParameterValuesComponents/DropdownSelector/DropdownSelector';
import { SectionType } from 'features/targetingV2/types/common';
import { IParameterPosition } from 'features/targetingV2/types/targeting';
import { getOppositeClusivity } from 'features/targetingV2/utils/pairedParameters';
import { Territory } from 'interfaces/generated.types';
import React from 'react';

export interface IClusivityAndValuesSelectProps {
  sectionType: SectionType;
  groupFieldName: string;
  pairedParameterIndex: number;
  pairedParameters: IParameterPosition[];
  setFieldValue: (field: string, value: any) => void;
  pairedParameterLabel?: string;
  parameterValuesDropdownPlaceholder?: string;
  showAccuracy?: boolean;
  idUsedToLimitParameterValues?: string;
  territory?: Territory;
}

const ClusivityAndValuesSelect = (props: IClusivityAndValuesSelectProps) => {
  const {
    sectionType,
    groupFieldName,
    pairedParameterIndex,
    pairedParameters,
    pairedParameterLabel,
    setFieldValue,
    parameterValuesDropdownPlaceholder = '',
    showAccuracy = false,
    idUsedToLimitParameterValues,
    territory,
  } = props;

  const classes = useStyles();

  const setFieldValueBasedOnCardinality = (fieldName: string, value: any) => {
    setFieldValue(fieldName, value);

    if (pairedParameters.length > 1) {
      const paramWithSelectedClusivity = pairedParameters.find(
        (param) => param.parameter.clusivity === value
      );

      const oppositeClusivity = getOppositeClusivity(value);
      setFieldValue(
        `${groupFieldName}.parameters[${paramWithSelectedClusivity?.index}].clusivity`,
        oppositeClusivity
      );
    }
  };

  const pairedParameterFieldName = `${groupFieldName}.parameters[${pairedParameters[pairedParameterIndex].index}]`;
  const pairedParameterClusivity =
    pairedParameters[pairedParameterIndex].parameter.clusivity;

  const optionalParameterValuesInputClasses = classNames([
    classes.parameterValuesInput,
    {
      [`${classes.parameterValuesInput}--first`]:
        (pairedParameters.length === 2 && pairedParameterIndex === 0) ||
        (pairedParameters.length === 1 && showAccuracy),

      [`${classes.parameterValuesInput}--last`]:
        pairedParameters.length === 2 &&
        pairedParameterIndex === 1 &&
        !showAccuracy,

      [`${classes.parameterValuesInput}--in-between`]:
        pairedParameters.length === 2 &&
        pairedParameterIndex === 1 &&
        showAccuracy,
    },
  ]);

  return (
    <>
      {pairedParameterClusivity && (
        <ClusivitySelect
          selectedClusivity={pairedParameterClusivity}
          fieldName={`${pairedParameterFieldName}.clusivity`}
          setFieldValue={setFieldValueBasedOnCardinality}
        />
      )}
      <DropdownSelector
        sectionType={sectionType}
        selectedParameter={pairedParameters[pairedParameterIndex].parameter}
        selectedParameterLabel={pairedParameterLabel}
        fieldName={pairedParameterFieldName}
        setFieldValue={setFieldValue}
        placeholder={parameterValuesDropdownPlaceholder}
        classes={{
          input: optionalParameterValuesInputClasses,
        }}
        idUsedToLimitParameterValues={idUsedToLimitParameterValues}
        territory={territory}
      />
    </>
  );
};

export default ClusivityAndValuesSelect;
