import debounce from 'debounce-promise';
import { getIn } from 'formik';
import React, { useEffect, useState } from 'react';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import Loader from 'components/Loader/Loader';

import TargetingMultiSelect, {
  TargetingAsyncMultiSelect,
} from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';
import { TLocationOption } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import {
  ICities,
  IGetAllCitiesResponse,
} from 'features/targeting/graphql/cities/queries';

import { IFormProps, OptionType } from 'interfaces';
import {
  useAllCountriesQuery,
  useListCitiesQuery,
} from 'interfaces/generated.types';

import locationUtils from 'utils/location';
import {
  getTargetingPath,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const cityValues = {
  countryParams: {
    countries: [],
    cities: [],
  },
};

export const loadOptions = async (
  value: string,
  cities: IGetAllCitiesResponse,
  setFetchedOptions: any
) => {
  if (value.length >= 2) {
    const data = cities.allCities.map((allCities: ICities) => ({
      ...allCities,
      cities: allCities.cities.filter((city: any) =>
        city.name.toLowerCase().includes(value.toLowerCase())
      ),
    }));
    const response: ICities[] = await new Promise((resolve) => resolve(data));

    const formattedData = locationUtils.createCityGroup(response);
    setFetchedOptions(formattedData);
    return formattedData;
  }
  return [];
};

const debouncedLoadOptions = debounce(loadOptions, 1000, {
  leading: true,
});

const filterCitiesByCountries = (
  cities: TLocationOption[],
  countries: OptionType[]
) => {
  const countryCodes = locationUtils.formatCountryCodes(countries);
  return cities.filter((city: TLocationOption) =>
    countryCodes.includes(city.countryCode)
  );
};

const TargetingCity = (props: IFormProps<any> & ITargetingComponentProps) => {
  const {
    values,
    setFieldValue,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
  } = props;

  const [fetchedOptions, setFetchedOptions] = useState([]);

  const classes: any = targetingStyles({});
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const cityField = `${targetingPath}.audienceParams.${index}.countryParams.cities`;
  const countryField = `${targetingPath}.audienceParams.${index}.countryParams.countries`;
  const countryVals = getIn(values, countryField);
  const cityVals = getIn(values, cityField);
  const [countries, setCountries] = useState(countryVals);
  const [cities, setCities] = useState(cityVals);
  const { loading, data } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });
  const { data: dataCities, loading: loadingCities } = useListCitiesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCodes: locationUtils.formatCountryCodes(countries),
    },
    skip: countries.length === 0,
  });
  useEffect(() => {
    setCountries(countryVals || []);
  }, [countryVals]);

  useEffect(() => {
    setCities(cityVals || []);
  }, [cityVals]);

  useEffect(() => {
    setFieldValue(cityField, filterCitiesByCountries(cities, countries));
  }, [countries, cityField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loader />;

  return (
    <>
      <div className={classes.valueWrapper}>
        <TargetingMultiSelect
          {...props}
          label="Country"
          selectAll={false}
          maxOptions={1}
          maxOptionsText="You can only select 1 country"
          targetingName="countryParams.countries"
          options={
            data && data.allCountries
              ? locationUtils.createCountryGroup(data.allCountries)
              : []
          }
        />
      </div>
      <div className={classes.valueWrapper}>
        {loadingCities ? (
          <Loader />
        ) : (
          <TargetingAsyncMultiSelect
            {...props}
            label="City"
            placeholder="Begin typing to find a City"
            isDisabled={!dataCities}
            targetingName="countryParams.cities"
            loadOptions={(value: string) =>
              dataCities &&
              debouncedLoadOptions(value, dataCities, setFetchedOptions)
            }
            fetchedOptions={fetchedOptions}
          />
        )}
      </div>
    </>
  );
};

export default TargetingCity;
