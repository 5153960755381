import React, { forwardRef, useEffect, useRef } from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';

import checkboxStyles from 'assets/styles/components/Checkbox.styles';

interface ICheckboxProps extends CheckboxProps {
  indeterminate?: boolean;
}

const TableCheckbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  function TableCheckbox({ indeterminate, ...rest }: any, ref: any) {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;
    const classes = checkboxStyles();

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} className={classes.checkbox} />
      </>
    );
  }
);

export default TableCheckbox;
