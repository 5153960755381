import pageStyles from 'assets/styles/components/Page.styles';
import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import NetworksTable from 'features/inventory/network/components/NetworksTable/NetworksTable';
import { History } from 'history';
import {
  Network,
  PermissionName,
  useAllNetworksQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface INetworkPageProps {
  history: History;
  userPermissions: PermissionName[];
}

const networkEditPermissions = [PermissionName.Primary, PermissionName.Supply];

const NetworksPage = ({ history, userPermissions }: INetworkPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllNetworksQuery({
    errorPolicy: 'all',
    variables: { territories: [activeTerritory!] },
  });

  const classes = pageStyles();

  if (!loading && error && !(data && data.allNetworks))
    return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    networkEditPermissions
  );

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Networks</h1>
        <div className={classes.table}>
          {loading ? (
            <Loader />
          ) : (
            <NetworksTable
              history={history}
              allNetworks={(data?.allNetworks as Network[]) || []}
              isEditable={hasEditPermissions}
              activeTerritory={activeTerritory!}
            />
          )}
        </div>
      </Container>
    </main>
  );
};

export default NetworksPage;
