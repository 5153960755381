import { workplaceKnowledgeUrl } from 'features/targetingV2/utils/common';

// eslint-disable-next-line import/prefer-default-export
export const tooltipContent = {
  location: {
    content: [
      'Location targeting allows you to target listeners in specific geographic locations, which can increase relevance and engagement by playing the right ads to the right people in the right place. You can set up parameters such as countries, regions, cities or postcodes/zip codes to reach the desired audience.',
    ],
    links: [
      {
        content: 'Where can I find the Region and Postcode packs?',
        resourceLink: `${workplaceKnowledgeUrl}/region.and.postcode.packs.in.dax.targeting2.0`,
      },
      {
        content: 'How to increase avails using location targeting?',
        resourceLink: `${workplaceKnowledgeUrl}/how.to.increase.avails.using.location.targeting`,
      },
      {
        content: 'How to target specific locations or smaller areas?',
        resourceLink: `${workplaceKnowledgeUrl}/targeting.smaller.areas.in.dax.targeting2.0`,
      },
      {
        content: 'How to upload a CSV or use Bulk Upload?',
        resourceLink: `${workplaceKnowledgeUrl}/bulk.upload.in.dax.targeting2.0`,
      },
      {
        content: 'How does Location Accuracy work?',
        resourceLink: `${workplaceKnowledgeUrl}/location.accuracy.in.dax.targeting2.0`,
      },
    ],
  },
  audience: {
    content: [
      'Audience targeting enables us to tailor our advertising to specific groups of people based on their demographics, interests or other characteristics, allowing us to deliver more personalized and relevant ads to our listeners.',
    ],
    links: [
      {
        content: 'Where are the DAX Audiences?',
        resourceLink: `${workplaceKnowledgeUrl}/dax.audiences.in.targeting2.0`,
      },
      {
        content: 'How to find Nielsen segments?',
        resourceLink: `${workplaceKnowledgeUrl}/finding.nielsen.segments`,
      },
      {
        content: 'How to find the right interests to target?',
        resourceLink: `${workplaceKnowledgeUrl}/finding.the.right.interests`,
      },
      {
        content: 'What do grades mean and how do they work?',
        resourceLink: `${workplaceKnowledgeUrl}/how.do.grades.work`,
      },
      {
        content: 'When and how to create multiple audiences?',
        resourceLink: `${workplaceKnowledgeUrl}/multiple.audiences.in.dax.targeting2.0`,
      },
    ],
  },
  inventory: {
    content: [
      'Using inventory parameters allows us to target specific shows, publishers or inventory types such as podcasts, radio, catchup, etc. where we believe our target audience is likely to spend time, which can help increase the relevance of our ads.',
    ],
    links: [
      {
        content: 'How to target specific types of inventory ?',
        resourceLink: `${workplaceKnowledgeUrl}/3470177583303706`,
      },
      {
        content: 'How to exclude podcasts?',
        resourceLink: `${workplaceKnowledgeUrl}/excluding.podcasts.in.dax.targeting2.0`,
      },
      {
        content: 'How to target podcasts only?',
        resourceLink: `${workplaceKnowledgeUrl}/targeting.podcasts.only`,
      },
      {
        content: 'Include/exclude networks, publishers or channels?',
        resourceLink: `${workplaceKnowledgeUrl}/include.or.exclude.networks.publishers.or.channels`,
      },
      {
        content: 'How to define ad position?',
        resourceLink: `${workplaceKnowledgeUrl}/defining.ad.position.in.dax.targeting2.0`,
      },
    ],
  },
  dayTime: {
    content: [
      'Day & Time targeting enables us to play our ads to our target audience at specific times and days of the week when they are most likely to be active, which can increase the effectiveness of our campaigns.',
    ],
  },
  technology: {
    content: [
      'Using Technology parameters allows us to target our ads to specific devices, operating systems, browsers, or internet service providers (ISPs) to ensure that our ads are played to the most relevant audience.',
    ],
  },
  custom: {
    content: [
      'Custom parameters help us to create unique targeting parameters based on our first-party data or custom criteria which can help improve the accuracy and effectiveness of our ad targeting.',
      "We can also leverage our advertisers' data management platforms(DMPs), customer relationship management(CRM) systems, or third- party providers to segment their audience based on custom attributes to create tailored campaigns.",
    ],
  },
};
