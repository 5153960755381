import gql from 'graphql-tag';

export const SEARCH_ISPS = gql`
  query SearchIsps($text: String!) {
    searchIsps(text: $text) {
      id
      name
    }
  }
`;

export default SEARCH_ISPS;
