import {
  AdvertiserStatus,
  AgencyStatus,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';

export interface IAdvertiserValues {
  agencyName: string;
  agencyId: string;
  name: string;
  id: string;
  altId?: number;
  externalId: string;
  externalSystemId: string;
  domains: string[];
  journey: string;
  status?: AdvertiserStatus;
  adSeparation: string;
  hasAdSeparation: boolean;
  iabCategoryCodes: string[];
  generatedDomains: string;
  territory?: Territory;
}

export const advertiserStatusValues = [
  {
    value: AdvertiserStatus.Draft,
    label: 'Draft',
    isDisabled: (value: AdvertiserStatus) =>
      [AdvertiserStatus.Enabled, AdvertiserStatus.Disabled].includes(value),
  },
  {
    value: AdvertiserStatus.Enabled,
    label: 'Enabled',
    isDisabled: (value: AdvertiserStatus) =>
      [AdvertiserStatus.Draft].includes(value),
  },
  {
    value: AdvertiserStatus.Disabled,
    label: 'Disabled',
    isDisabled: (value: AdvertiserStatus) =>
      [AdvertiserStatus.Draft].includes(value),
  },
];

export const getAdvertiserStatusData = memoizeOne(
  (values: IAdvertiserValues, agencyStatus: AgencyStatus) => [
    {
      status: AdvertiserStatus.Draft,
      required: [
        ...(!values.name ? ["Complete 'Name'"] : []),
        ...(!values.agencyName ? ["Complete 'Parent Agency'"] : []),
      ],
    },
    {
      status:
        values.status === AdvertiserStatus.Disabled
          ? AdvertiserStatus.Disabled
          : AdvertiserStatus.Enabled,
      required: [
        ...(!values.name ? ['Waiting for Name'] : []),
        ...(agencyStatus !== AgencyStatus.Enabled
          ? ['Waiting for Parent Agency to be in ENABLED state']
          : []),
      ],
    },
  ]
);

export const advertiserDetailsFields = [
  'name',
  'adSeparation',
  'externalId',
  'externalSystemId',
  'territory',
];
