import GradingIcon from 'features/targetingV2/components/Grading/GradingIcon/GradingIcon';
import { TargetingGrade } from 'interfaces/generated.types';
import { capitalize, lowerCase } from 'lodash';
import React from 'react';

import { sortArrayBasedOnOrder } from './common';

export const Grade1Icon = () => (
  <GradingIcon
    enabledGrades={[TargetingGrade.Grade_1]}
    id="gradingIcon--grade1-selected"
  />
);
export const Grade2Icon = () => (
  <GradingIcon
    enabledGrades={[TargetingGrade.Grade_2]}
    id="gradingIcon--grade2-selected"
  />
);
export const Grade3Icon = () => (
  <GradingIcon
    enabledGrades={[TargetingGrade.Grade_3]}
    id="gradingIcon--grade3-selected"
  />
);
export const Grade4Icon = () => (
  <GradingIcon
    enabledGrades={[TargetingGrade.Grade_4]}
    id="gradingIcon--grade4-selected"
  />
);

export const getAllGradesOrdered = () => Object.values(TargetingGrade);

export const getGradeLabel = (grade: TargetingGrade) =>
  capitalize(lowerCase(grade));

export const getGradeLabelMinimal = (grade: TargetingGrade) =>
  lowerCase(grade).replace(/Grade/i, '').trim();

export const getGradeOptionInfo = (grade?: TargetingGrade) => {
  switch (grade) {
    case TargetingGrade.Grade_1:
      return {
        label: getGradeLabel(TargetingGrade.Grade_1),
        gradeIcon: <Grade1Icon />,
        description: '1st Party data; based on logged in users',
      };
    case TargetingGrade.Grade_2:
      return {
        label: getGradeLabel(TargetingGrade.Grade_2),
        gradeIcon: <Grade2Icon />,
        description: 'Contextual data; podcasts, gaming & audio articles',
      };
    case TargetingGrade.Grade_3:
      return {
        label: getGradeLabel(TargetingGrade.Grade_3),
        gradeIcon: <Grade3Icon />,
        description: 'DAX Audiences; Nielsen etc.',
      };
    case TargetingGrade.Grade_4:
      return {
        label: getGradeLabel(TargetingGrade.Grade_4),
        gradeIcon: <Grade4Icon />,
        description: 'Inferred data; based on channels & podcasts',
      };
    default:
      return {
        label: 'Unknown grade',
        gradeIcon: <></>,
        description: 'Unknown grade',
      };
  }
};

export const getSortedGradeLabels = (
  enabledGrades: TargetingGrade[],
  isGradeLabelMinimal: boolean
) => {
  const copyOfEnabledGrades = [...enabledGrades];
  sortArrayBasedOnOrder(copyOfEnabledGrades, getAllGradesOrdered());

  const getGradeLabelFormat = isGradeLabelMinimal
    ? getGradeLabelMinimal
    : getGradeLabel;

  const grades = copyOfEnabledGrades
    .map((enabledGrade) => getGradeLabelFormat(enabledGrade))
    .join(', ');

  return isGradeLabelMinimal ? `Grade ${grades}` : grades;
};
