import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  bullet: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    marginRight: theme.spacing(0.75),
    '&--booked': {
      backgroundColor: theme.palette.grey[400],
    },
    '&--available': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
