import { getIn } from 'formik';
import get from 'lodash/get';
import React from 'react';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import MultiSelect, {
  AsyncMultiSelect,
} from 'components/MultiSelect/MultiSelect';

import { IFormProps, OptionGroupType, OptionType } from 'interfaces';

import { dedupeValues } from 'utils/dataTransformation';
import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export interface ITargetingMultiSelectProps {
  label: string;
  placeholder?: string;
  targetingName: string;
  options: OptionGroupType[] | OptionType[];
  isMulti?: boolean;
  isDisabled?: boolean;
  maxOptions?: number;
  maxOptionsText?: string;
  className?: string;
  noMargin?: boolean;
  selectAll?: boolean;
}

export interface ITargetingAsyncMultiSelectProps {
  label: string;
  placeholder?: string;
  targetingName: string;
  loadOptions: any;
  fetchedOptions?: any;
  isDisabled?: boolean;
  selectAll?: boolean;
}

export const TargetingAsyncMultiSelect = (
  props: IFormProps<any> &
    ITargetingComponentProps &
    ITargetingAsyncMultiSelectProps
) => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
    label,
    placeholder,
    loadOptions,
    fetchedOptions = [],
    targetingName,
    touched,
    errors,
    isDisabled = false,
    selectAll = true,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const value = dedupeValues(
    get(targetingValues, `audienceParams.${index}.${targetingName}`)
  ).filter((val) => val.label !== null);

  const isClearable = value.some((v: any) => v.readOnly);

  return (
    <div className={classes.value}>
      <AsyncMultiSelect
        id={label}
        dataTc={`targetingMultiSelect-${label}`}
        data-tc={`targetingMultiSelect-${label}`}
        isMulti
        isDisabled={isDisabled}
        isClearable={!isClearable}
        label={label}
        placeholder={placeholder || label}
        name={`${targetingPath}.audienceParams.${index}.${targetingName}`}
        onBlur={() =>
          setFieldTouched(
            `${targetingPath}.audienceParams.${index}.${targetingName}`,
            true
          )
        }
        onChange={(fieldValue: any) => {
          setFieldValue(
            `${targetingPath}.audienceParams.${index}.${targetingName}`,
            fieldValue
          );
        }}
        selectAll={selectAll}
        loadOptions={loadOptions}
        fetchedOptions={fetchedOptions}
        value={value}
        attributes={{
          fieldAttribute: `${targetingName}Field`,
          menuAttribute: `${targetingName}MenuOptions`,
          chipAttribute: `${targetingName}Chip`,
        }}
        errorProps={{
          helperText:
            getIn(
              touched,
              `${targetingPath}.audienceParams.${index}.${targetingName}`
            ) &&
            getIn(
              errors,
              `${targetingPath}.audienceParams.${index}.${targetingName}`
            ),
          FormHelperTextProps: {
            error: !!(
              getIn(
                touched,
                `${targetingPath}.audienceParams.${index}.${targetingName}`
              ) &&
              getIn(
                errors,
                `${targetingPath}.audienceParams.${index}.${targetingName}`
              )
            ),
          },
        }}
      />
    </div>
  );
};

const TargetingMultiSelect = (
  props: IFormProps<any> & ITargetingComponentProps & ITargetingMultiSelectProps
) => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
    label,
    placeholder,
    options,
    maxOptions,
    maxOptionsText,
    targetingName,
    touched,
    errors,
    isMulti = true,
    isDisabled = false,
    className,
    noMargin = false,
    selectAll = true,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const value = isMulti
    ? dedupeValues(
        get(targetingValues, `audienceParams.${index}.${targetingName}`)
      )
    : get(targetingValues, `audienceParams.${index}.${targetingName}`);

  const isClearable =
    isMulti && value ? value.some((v: any) => v.readOnly) : false;

  return (
    <div className={`${classes.value} ${className || ''}`}>
      <MultiSelect
        id={label}
        noMargin={noMargin}
        data-tc={`targetingMultiSelect-${label}`}
        dataTc={`targetingMultiSelect-${label}`}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isClearable={!isClearable}
        label={label}
        placeholder={placeholder || label}
        name={`${targetingPath}.audienceParams.${index}.${targetingName}`}
        onBlur={() =>
          setFieldTouched(
            `${targetingPath}.audienceParams.${index}.${targetingName}`,
            true
          )
        }
        onChange={(fieldValue: any) =>
          setFieldValue(
            `${targetingPath}.audienceParams.${index}.${targetingName}`,
            fieldValue
          )
        }
        selectAll={selectAll}
        options={options}
        maxOptions={maxOptions}
        maxOptionsText={maxOptionsText}
        value={value}
        attributes={{
          fieldAttribute: `${targetingName}Field`,
          menuAttribute: `${targetingName}MenuOptions`,
          chipAttribute: `${targetingName}Chip`,
        }}
        errorProps={{
          helperText:
            getIn(
              touched,
              `${targetingPath}.audienceParams.${index}.${targetingName}`
            ) &&
            getIn(
              errors,
              `${targetingPath}.audienceParams.${index}.${targetingName}`
            ),
          FormHelperTextProps: {
            error: !!(
              getIn(
                touched,
                `${targetingPath}.audienceParams.${index}.${targetingName}`
              ) &&
              getIn(
                errors,
                `${targetingPath}.audienceParams.${index}.${targetingName}`
              )
            ),
          },
        }}
      />
    </div>
  );
};

export default TargetingMultiSelect;
