import FileUpload from 'features/targetingV2/components/core/FileUpload/FileUpload';
import useCsvParser from 'features/targetingV2/hooks/CsvParser/useCsvParser';
import React, { Ref, useEffect, useState } from 'react';

interface IBulkUploadProps {
  fieldName: string;
  header?: boolean;
  headerOptions?: string[];
  maxFileSize?: number;
  label: string;
  externalUploadInputRef?: Ref<any>;
  handleDataUpload: (data: any[] | null, error: string | null) => void;
}

const BulkUpload = (props: IBulkUploadProps) => {
  const {
    fieldName,
    header,
    headerOptions,
    maxFileSize,
    label,
    externalUploadInputRef,
    handleDataUpload,
  } = props;

  const [selectedFile, setSelectedFile] = useState<File | null>();

  const { data, error } = useCsvParser({
    selectedFile,
    header,
    delimiter: ',',
    headerOptions,
    maxFileSize,
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    handleDataUpload(data, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <div data-testid={`bulkUpload-${fieldName}`}>
      <FileUpload
        name={fieldName}
        label={label}
        onChange={handleFileUpload}
        fileType="text/csv"
        externalUploadInputRef={externalUploadInputRef}
      />
    </div>
  );
};

export default BulkUpload;
