import base64url from 'base64url';

export const decodeJWT = (jwt: string) =>
  JSON.parse(base64url.decode(jwt.split('.')[1]));

export const hasJwtTokenExpired = (jwtToken: string | null) => {
  if (jwtToken) {
    const decoded = decodeJWT(jwtToken);

    if (decoded && decoded.exp) {
      return Date.now() >= decoded.exp * 1000;
    }
    return true;
  }

  return true;
};
