import * as Yup from 'yup';

import commons from 'validations/commons';

const TagFormValidation = Yup.object().shape({
  name: commons.name('Tag Name'),
  description: Yup.string()
    .trim()
    .max(1024, 'Only a maximum of 1024 characters are allowed'),
});

export default TagFormValidation;
