import { ReactComponent as TargetIcon } from 'assets/icons/TargetingV2/dax-target.svg';
import AccuracyRow from 'features/targetingV2/components/AccuracyRow/AccuracyRow';
import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ParameterRowContainer from 'features/targetingV2/components/ParameterRowContainer/ParameterRowContainer';
import { ISliderValue, SectionType } from 'features/targetingV2/types/common';
import {
  ILimitLocationParameterValue,
  ILocationParameterGroup,
  IParameter,
} from 'features/targetingV2/types/targeting';
import { getParameterValuesDropdownPlaceholder } from 'features/targetingV2/utils/common';
import { defaultParameter } from 'features/targetingV2/utils/defaults';
import { getLocationAvailableParameterOptions } from 'features/targetingV2/utils/targeting';
import { FieldArray } from 'formik';
import {
  LocationParameterInfo,
  LocationParameterType,
} from 'interfaces/generated.types';
import React from 'react';

export interface ILocationProps {
  location: ILocationParameterGroup;
  locationIndex: number;
  locationFieldName: string;
  allParametersInfo: LocationParameterInfo[];
  areParameterTypeOptionsLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  limitLocationCountryLabel: string;
  limitLocationParameterValueFromTerritory?: ILimitLocationParameterValue;
  isLocationLimited: boolean;
  accuracyRadiusDefaultValue: number | undefined;
  allAccuracyValues: ISliderValue[];
  areLocationAccuraciesLoading: boolean;
}

export interface IOptionalAccuracyRowProps {
  externalClasses?: any;
}

const Location = (props: ILocationProps) => {
  const {
    location,
    locationIndex,
    locationFieldName,
    allParametersInfo,
    areParameterTypeOptionsLoading,
    setFieldValue,
    limitLocationCountryLabel,
    limitLocationParameterValueFromTerritory,
    isLocationLimited,
    areLocationAccuraciesLoading,
    accuracyRadiusDefaultValue,
    allAccuracyValues,
  } = props;
  const accuracyRadiusFieldName = `${locationFieldName}.accuracyRadius`;

  const showAccuracyRow =
    !areLocationAccuraciesLoading &&
    accuracyRadiusDefaultValue &&
    allAccuracyValues.length > 0 &&
    location.accuracyRadius > 0;

  const handleAccuracyDeletion = () => {
    setFieldValue(accuracyRadiusFieldName, -1);
  };

  const renderOptionalAccuracyRow = (type: string) =>
    showAccuracyRow && type === LocationParameterType.Geo
      ? (optionalAccuracyRowProps: IOptionalAccuracyRowProps) => (
          <AccuracyRow
            setFieldValue={setFieldValue}
            allAccuracyValues={allAccuracyValues}
            areAccuraciesLoading={areLocationAccuraciesLoading}
            defaultAccuracy={accuracyRadiusDefaultValue}
            selectedAccuracy={location.accuracyRadius}
            fieldName={accuracyRadiusFieldName}
            menuIconButtonLabel={`${SectionType.Location} Accuracy Slider`}
            handleAccuracyDeletion={handleAccuracyDeletion}
            {...optionalAccuracyRowProps}
          />
        )
      : undefined;

  const renderSetAccuracyOption = (type: string) =>
    type === LocationParameterType.Geo && !showAccuracyRow
      ? [
          {
            MenuItemIcon: <TargetIcon />,
            menuItemIconLabel: 'Set Accuracy',
            menuItemAction: () => {
              setFieldValue(
                accuracyRadiusFieldName,
                accuracyRadiusDefaultValue
              );
            },
          },
        ]
      : [];

  const getAvailableParametersOptions = (parameterType?: string) =>
    getLocationAvailableParameterOptions(
      allParametersInfo,
      location.parameters,
      parameterType
    );

  const availableParameterOptions = getAvailableParametersOptions();

  const showAddButton =
    !!location.parameters.length &&
    (location.parameters.length < 2 ||
      !location.parameters.some(
        (parameter) => parameter.type !== LocationParameterType.Geo
      ));

  const shouldResetAccuracy = (type: string) =>
    type === LocationParameterType.Geo &&
    location.parameters.filter(
      (param) => param.type === LocationParameterType.Geo
    ).length === 1;

  return (
    <div data-testid={locationFieldName}>
      <FieldArray name={`${locationFieldName}.parameters`}>
        {({
          push: pushParameter,
          remove: removeParameter,
          insert: insertParameter,
        }) => (
          <>
            {location.parameters.map(
              (parameter: IParameter, parameterIndex: number) => (
                <ParameterRowContainer
                  // eslint-disable-next-line react/no-array-index-key
                  key={`location${locationIndex}-parameter${parameterIndex}`}
                  sectionType={SectionType.Location}
                  groupFieldName={locationFieldName}
                  parameterIndex={parameterIndex}
                  removeParameter={(index) => {
                    if (shouldResetAccuracy(parameter.type)) {
                      handleAccuracyDeletion();
                    }
                    removeParameter(index);
                  }}
                  insertParameter={insertParameter}
                  parameterTypeOptions={getAvailableParametersOptions(
                    parameter.type
                  )}
                  areParameterTypeOptionsLoading={
                    areParameterTypeOptionsLoading
                  }
                  allSelectedParameters={location.parameters}
                  allParametersInfo={allParametersInfo}
                  setFieldValue={setFieldValue}
                  parameterValuesDropdownPlaceholder={getParameterValuesDropdownPlaceholder(
                    parameter.type,
                    isLocationLimited,
                    limitLocationCountryLabel
                  )}
                  idUsedToLimitParameterValues={
                    isLocationLimited &&
                    limitLocationParameterValueFromTerritory
                      ? limitLocationParameterValueFromTerritory.id
                      : ''
                  }
                  OptionalAccuracyRow={renderOptionalAccuracyRow(
                    parameter.type
                  )}
                  additionalMenuOptions={renderSetAccuracyOption(
                    parameter.type
                  )}
                />
              )
            )}
            {showAddButton && (
              <AddParameterDropdownButton
                options={availableParameterOptions}
                onOptionClick={(parameterType: string) => {
                  pushParameter({
                    ...defaultParameter,
                    type: parameterType,
                    isFocused: true,
                  });
                }}
                loading={areParameterTypeOptionsLoading}
                testId="add-location-parameter-btn"
              />
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default Location;
