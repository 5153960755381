import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { subDays } from 'date-fns';

import { Typography } from '@material-ui/core';

import { ReactComponent as GreenArrowIcon } from 'assets/icons/dax-arrow-green.svg';
import { ReactComponent as RedArrowIcon } from 'assets/icons/dax-arrow-red.svg';
import pageStyles from 'assets/styles/components/Page.styles';

import Loader from 'components/Loader/Loader';

import { IDateRange } from 'interfaces';
import {
  PublisherInsightsFilterAttributes,
  PublisherInsightsMetric,
  PublisherInsightsTimeSeries,
  PublisherInsightsTimeSeriesUnit,
  usePublisherInsightsTimeSeriesMinimalQuery,
} from 'interfaces/generated.types';

import dateUtils, { TimeZones } from 'utils/date';
import { calculateDataTrend, getDataSet } from 'utils/insights';

import useStyles from './InsightsLineChart.styles';

interface IDataTrendProps {
  variables: {
    metric: PublisherInsightsMetric;
    formattedSelectedFilters: PublisherInsightsFilterAttributes[];
    timeZone: TimeZones;
    selectedCurrency: string;
  };
  selectedDateRange: IDateRange;
  totalValue: number;
  datePreset: number;
  setDataTrendError: (error: string) => void;
}

const DataTrend = ({
  variables: { metric, timeZone, formattedSelectedFilters, selectedCurrency },
  selectedDateRange,
  totalValue,
  datePreset,
  setDataTrendError,
}: IDataTrendProps) => {
  const classes = { ...pageStyles(), ...useStyles() };

  const [dataTrend, setDataTrend] = useState<number>(0);

  const { loading, error } = usePublisherInsightsTimeSeriesMinimalQuery({
    variables: {
      metrics: [metric],
      startDate: dateUtils.getIsoStringInSpecificTimezone(
        subDays(selectedDateRange.startDate, datePreset),
        timeZone
      ),
      endDate: dateUtils.getIsoStringInSpecificTimezone(
        subDays(selectedDateRange.endDate, datePreset),
        timeZone
      ),
      timeZone,
      timeUnit: PublisherInsightsTimeSeriesUnit.Day,
      currency: selectedCurrency,
      filters: formattedSelectedFilters,
    },
    onCompleted: (result) => {
      const newData = getDataSet(
        metric,
        (result?.publisherInsightsTimeSeries as PublisherInsightsTimeSeries[]) ||
          []
      );

      if (newData) {
        const previousTotalValue = newData.totalValue;
        const newDataTrend = calculateDataTrend(previousTotalValue, totalValue);
        setDataTrend(newDataTrend);
      }
    },
  });

  useEffect(
    () =>
      error
        ? setDataTrendError('Error in loading data trend values')
        : setDataTrendError(''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  const isPositiveTrend = dataTrend >= 0;
  const trendIcon = isPositiveTrend ? (
    <GreenArrowIcon data-testid="positiveTrendArrow" />
  ) : (
    <RedArrowIcon data-testid="negativeTrendArrow" />
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.dataTrendContainer}>
          {!loading && !error && (
            <div
              className={classes.dataTrend}
              data-testid={`insightsDataTrend-${metric}`}
            >
              <span
                className={classNames([
                  classes.icon,
                  {
                    [`${classes.icon}--green`]: isPositiveTrend,
                  },
                ])}
              >
                {trendIcon}
              </span>
              <span className={classes.trendValue}>
                <Typography
                  className={
                    isPositiveTrend
                      ? classes.dataTrendPositive
                      : classes.dataTrendNegative
                  }
                >
                  {`${Math.abs(dataTrend)}% vs last ${datePreset} days`}
                </Typography>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DataTrend;
