import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IDealFormValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import DealTabsForm, {
  dealButtons,
} from 'features/programmatic/deal/components/DealTabsForm/DealTabsForm';
import DealFormValidation from 'features/programmatic/deal/validations/deals';
import useAllParametersInfo from 'features/targetingV2/hooks/AllParametersInfo/useAllParametersInfo';
import { formatTargetingDefinitionV2 } from 'features/targetingV2/utils/formValuesFormatting';
import { sendTargetingV2DataToGTM } from 'features/targetingV2/utils/gtmData';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate, OptionType } from 'interfaces';
import {
  AuctionType,
  Deal,
  Dsp,
  DspIncludedSeats,
  EntityType,
  SalesChannel,
  TargetingVersion,
  UpdateDealMutation,
  UpdateDealMutationFn,
  User,
  useUpdateDealMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useState } from 'react';
import {
  createSelectOption,
  createSelectOptions,
  createUserOption,
} from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { setDspIncludedSeats } from 'utils/dealConnector';
import { getDefaultTargetingGroupWithCountryParamsBasedOnTerritory } from 'utils/defaultsByTerritory';
import { AlreadyExistMessages } from 'utils/errors';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrorsWithDuplicates,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { numbroDecimalFormatting } from 'utils/numbers';
import {
  getTargetingDefinitionForUpdate,
  groupTargetingGroups,
  ITargetingDefinition,
} from 'utils/targetingDefinitions';

interface IUpdateDealContainerProps {
  deal: Deal;
  history: History;
  allUsers: User[];
  hasEditPermissions: boolean;
}

export const handleOnDealComplete = async (
  response: UpdateDealMutation,
  history: History,
  selectedJourney: string
) => {
  const { updateDeal } = response;
  const { id: dealId, salesChannel } = updateDeal as Deal;
  const { id: salesChannelId } = salesChannel as SalesChannel;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id: dealId,
    parentId: salesChannelId,
    listEntity: selectedJourney === '/sales-channel/PARENT_ID' ? 'Deal' : '',
  });
};

export const submitUpdateDeal = (
  updateDeal: UpdateDealMutationFn,
  initialValues: IDealFormValues,
  toggleErrorModal: () => void,
  setSelectedJourney: any,
  formValues: IDealFormValues,
  { setSubmitting, setStatus }: FormikActions<IDealFormValues>
) => {
  setSelectedJourney(formValues.journey);

  const filteredValues: any = {
    ...differenceInValues(
      {
        ...formValues,
        dsp: null,
        seats: [],
        journey: null,
        trusted: !formValues.trusted,
        targetingGroups: null,
        targetingMasterTemplates: null,
        targetingGeneralGroup: null,
        ...getTargetingDefinitionForUpdate(formValues),
        seatAllocation: {
          dspId: formValues.dsp ? formValues.dsp.value : null,
          seatIds:
            formValues.seats.length > 0
              ? (formValues.seats as OptionType[]).map(
                  (seat: OptionType) => seat.value
                )
              : [],
        },
        allSeats: initialValues.allSeats,
        allKnownSeats: initialValues.allKnownSeats,
        dspIncludedSeats: setDspIncludedSeats(formValues),
      },
      {
        ...initialValues,
        dsp: null,
        seats: [],
        journey: null,
        trusted: !initialValues.trusted,
        targetingGroups: null,
        targetingMasterTemplates: null,
        targetingGeneralGroup: null,
        ...getTargetingDefinitionForUpdate(initialValues),
        seatAllocation: {
          dspId: initialValues.dsp ? initialValues.dsp.value : null,
          seatIds:
            initialValues.seats.length > 0
              ? (initialValues.seats as OptionType[]).map(
                  (seat: OptionType) => seat.value
                )
              : [],
        },
        allSeats: initialValues.allSeats,
        allKnownSeats: initialValues.allKnownSeats,
        dspIncludedSeats: initialValues.dspIncludedSeats,
      }
    ),
    id: formValues.id,
  };

  if (formValues.targetingVersion === TargetingVersion.TargetingV1)
    delete filteredValues.targetingDefinitionV2;
  else delete filteredValues.targetingDefinition;

  updateDeal({
    variables: filteredValues,
  }).catch((error) => {
    const duplicateFields = [
      {
        message: AlreadyExistMessages.EXTERNAL_DEAL_ID,
        error: {
          externalId:
            'This Deal ID already exists in DAX Audio. Please enter a different one.',
        },
      },
    ];
    handleFormErrorsWithDuplicates({
      duplicateFields,
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  });
};

const UpdateDealContainer = ({
  deal,
  history,
  allUsers,
  hasEditPermissions,
}: IUpdateDealContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Deal.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updateDeal] = useUpdateDealMutation({
    onCompleted(data) {
      handleOnDealComplete(data, history, selectedJourney);
    },
  });
  const validateDeal = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const defaultGeneralGroup = activeTerritory
    ? getDefaultTargetingGroupWithCountryParamsBasedOnTerritory(activeTerritory)
    : null;

  const targeting =
    deal.targetingDefinition && deal.targetingDefinition
      ? groupTargetingGroups(deal.targetingDefinition as ITargetingDefinition)
      : { groups: [], templates: [], general: defaultGeneralGroup };
  const hasAdSeparation = typeof deal.adSeparation === 'number';

  const initialValues: IDealFormValues = {
    overridePublisherFloor: deal.overridePublisherFloor as boolean,
    privateAuction: deal.privateAuction as boolean,
    priority: deal.priority,
    auctionType: deal.auctionType as AuctionType,
    offsetCpm:
      typeof deal.offsetCpm === 'number'
        ? numbro(deal.offsetCpm).format(numbroDecimalFormatting)
        : '',
    currency: deal.currency,
    externalId: deal.seats && deal.externalId ? deal.externalId : '',
    name: deal.name as string,
    id: deal.id,
    altId: deal.altId,
    dsp: deal.dsp ? createSelectOption(deal.dsp as Dsp) : null,
    seats: createSelectOptions(deal.seats),
    salesChannelName: (deal.salesChannel as SalesChannel).name as string,
    salesChannelId: (deal.salesChannel as SalesChannel).id,
    startDate:
      deal.startDate && deal.timeZone
        ? dateUtils.getDateInSpecificTimezone(deal.startDate, deal.timeZone)
        : null,
    endDate:
      deal.endDate && deal.timeZone
        ? dateUtils.getDateInSpecificTimezone(deal.endDate, deal.timeZone)
        : null,
    floorCpm:
      typeof deal.floorCpm === 'number'
        ? numbro(deal.floorCpm).format(numbroDecimalFormatting)
        : '',
    timeZone: deal.timeZone,
    trusted: !deal.trusted,
    targetingMasterTemplates: targeting.templates,
    targetingGroups: targeting.groups,
    targetingGeneralGroup: targeting.general,
    journey: dealButtons[0].url,
    owner: createUserOption(deal.owner as User),
    status: deal.status,
    adSeparation: hasAdSeparation ? deal.adSeparation.toString() : '',
    hasAdSeparation,
    noEndDate: !deal.endDate,
    adExclusive: deal.adExclusive || false,
    territory: deal.territory,
    allSeats: deal.dspIncludedSeats === DspIncludedSeats.AllSeats,
    allKnownSeats: deal.dspIncludedSeats === DspIncludedSeats.AllKnownSeats,
    dspIncludedSeats: deal.dspIncludedSeats || DspIncludedSeats.DspNotSet,
    targetingVersion: deal.targetingVersion,
    targetingDefinitionV2: formatTargetingDefinitionV2(
      deal.targetingDefinitionV2
    ),
  };

  const location = usePreviousLocation();
  const { allParametersInfo, loading: allParametersInfoLoading } =
    useAllParametersInfo();

  const gtmLogger = useGTMLogger();

  return (
    <>
      <Formik<IDealFormValues>
        initialValues={initialValues}
        validationSchema={DealFormValidation(activeTerritory)}
        onSubmit={(formValues, formikActions) => {
          if (formValues.targetingVersion === TargetingVersion.TargetingV2) {
            sendTargetingV2DataToGTM(
              formValues.targetingDefinitionV2,
              allParametersInfo,
              gtmLogger,
              formValues.territory
            );
          }

          submitUpdateDeal(
            updateDeal,
            initialValues,
            toggleErrorModal,
            setSelectedJourney,
            formValues,
            formikActions
          );
        }}
      >
        {(formikProps) => (
          <DealTabsForm
            {...formikProps}
            update
            history={history}
            deal={deal}
            goBackTo={{
              pathname:
                location.state?.from ||
                `/sales-channel/${(deal.salesChannel as SalesChannel).id}`,
              state: location.state?.parent || {},
            }}
            allUsers={allUsers}
            hasEditPermissions={hasEditPermissions}
            creatives={deal?.programmaticCreatives || []}
            salesChannelStatus={(deal.salesChannel as SalesChannel).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateDeal,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Deal,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateDeal,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
            loading={allParametersInfoLoading}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateDeal"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateDealWarningDialog"
      />
    </>
  );
};

export default UpdateDealContainer;
