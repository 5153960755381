import { useState } from 'react';

interface IMessageState {
  hasMessage: boolean;
}

export interface IMessageContent {
  title: string;
  main: string;
}

export default function useMessage() {
  const [{ hasMessage }, setMessageModal] = useState<IMessageState>({
    hasMessage: false,
  });
  const [messageContent, setMessageContent] = useState<IMessageContent>({
    title: '',
    main: '',
  });
  const toggleMessageModal = () => {
    setMessageModal((prevState: IMessageState) => ({
      ...prevState,
      hasMessage: !prevState.hasMessage,
    }));
  };
  return {
    hasMessage,
    toggleMessageModal,
    setMessageModal,
    messageContent,
    setMessageContent,
  };
}
