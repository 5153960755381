import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateDealContainer from 'features/programmatic/deal/containers/UpdateDealContainer/UpdateDealContainer';
import { History } from 'history';
import {
  Deal,
  PermissionName,
  useAllUsersQuery,
  useDealQuery,
  User,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import {
  allTerritories,
  isActiveTerritoryAssignedToEntity,
} from 'utils/territory';

interface UpdateDealPageProps {
  history: History;
  match: {
    params: {
      dealId: string;
    };
  };
  userPermissions: PermissionName[];
}

const dealChannelEditPermissions = [
  PermissionName.Primary,
  PermissionName.Programmatic,
];

const UpdateDealPage = ({
  history,
  match,
  userPermissions,
}: UpdateDealPageProps) => {
  const { loading, data } = useDealQuery({
    variables: { id: match.params.dealId },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, dealChannelEditPermissions) &&
    isActiveTerritoryAssignedToEntity(
      [data?.deal?.territory!],
      activeTerritory!
    );

  const { loading: loadingAllUsers, data: allUsersData } = useAllUsersQuery({
    variables: {
      permissions: [PermissionName.Programmatic, PermissionName.Primary],
      territories: allTerritories,
    },
    skip: !hasEditPermissions,
  });

  const isLoading = loading || loadingAllUsers;

  if (!isLoading && !data?.deal) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Deal</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <UpdateDealContainer
            deal={data?.deal as Deal}
            history={history}
            hasEditPermissions={hasEditPermissions}
            allUsers={(allUsersData?.allUsers as User[]) || []}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateDealPage;
