import React from 'react';
import { ColumnInstance } from 'react-table';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { ReactComponent as FilterIcon } from 'assets/icons/dax-filtericon.svg';

import { useFilterStyles } from './Table.styles';

interface ITableFilter {
  column: ColumnInstance;
  setCursor?: (arg: any) => void;
  setPaginationIndex?: (arg: number) => void;
}

const TableFilter = ({
  column,
  setCursor,
  setPaginationIndex,
}: ITableFilter) => {
  const classes = useFilterStyles();
  return (
    <TextField
      size="small"
      fullWidth
      onChange={(e) => {
        setCursor && setCursor({ next: null, previous: null });
        setPaginationIndex && setPaginationIndex(0);
        column.setFilter(e.target.value || undefined);
      }}
      value={column.filterValue || ''}
      InputProps={{
        classes,
        startAdornment: (
          <InputAdornment position="start">
            <FilterIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TableFilter;
