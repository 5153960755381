import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import TargetingTemplateTabsForm, {
  ITargetingTemplateValues,
} from 'features/targeting/components/TargetingTemplateTabsForm/TargetingTemplateTabsForm';
import TargetingTemplateFormValidation from 'features/targeting/validations/targetingTemplates';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import {
  CreateTargetingTemplateMutationFn,
  useCreateTargetingTemplateMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React from 'react';
import { formatValues, handleFormErrors } from 'utils/forms';

interface ICreateTargetingTemplateContainerProps {
  history: History;
}

export const handleOnComplete = (history: History) =>
  history.push('/targeting-templates');

export const submitCreateTargetingTemplate = (
  createTargetingTemplate: CreateTargetingTemplateMutationFn,
  toggleErrorModal: () => void
) => (
  formValues: ITargetingTemplateValues,
  { setStatus, setSubmitting }: FormikActions<ITargetingTemplateValues>
) => {
  const formattedValues = formatValues(formValues);

  const filteredValues = pickBy({
    ...formValues,
    targetingGroups: formattedValues.targetingGroups.length
      ? formattedValues.targetingGroups
      : null,
  });

  createTargetingTemplate({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateTargetingTemplateContainer = ({
  history,
}: ICreateTargetingTemplateContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Targeting Template.",
    'Would you like to go to Home or close the alert and try again?',
  ]);

  const [createTargetingTemplate] = useCreateTargetingTemplateMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  return (
    <>
      <Formik<ITargetingTemplateValues>
        initialValues={{
          name: '',
          description: '',
          isMasterTemplate: false,
          targetingGroups: [],
          territory: activeTerritory!,
        }}
        validationSchema={TargetingTemplateFormValidation}
        onSubmit={submitCreateTargetingTemplate(
          createTargetingTemplate,
          toggleErrorModal
        )}
      >
        {(props) => <TargetingTemplateTabsForm {...props} history={history} />}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createTargetingTemplate"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateTargetingTemplateContainer;
