import { GTMLoggerContext } from 'context/GTMLoggerProvider/GTMLoggerProvider';
import { useContext } from 'react';

export const useGTMLogger = () => {
  const gtmLogger = useContext(GTMLoggerContext);
  if (!gtmLogger) {
    throw new Error('useGTMLogger must be used within GTMLoggerProvider');
  }
  return gtmLogger;
};

export default useGTMLogger;
