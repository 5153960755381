import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import numbro from 'numbro';
import React from 'react';
import {
  calculatePercentage,
  numbroDecimalFormatting,
  parseFormattedValue,
} from 'utils/numbers';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './Impressions.styles';

const Meter = ({
  objectiveString,
  impressionsValue,
  impressionsPercent,
  showObjective,
}: {
  objectiveString: string;
  impressionsValue: number;
  impressionsPercent?: string;
  showObjective: boolean;
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {showObjective && (
        <Typography className={classes.legend}>
          Obj: {objectiveString}
        </Typography>
      )}
      {impressionsPercent ? (
        <>
          <Typography className={classes.label}>
            {impressionsPercent}
          </Typography>
          <LinearProgress
            classes={{ root: classes.bar, barColorPrimary: classes.barColor }}
            variant="determinate"
            value={impressionsValue}
          />
        </>
      ) : (
        <Typography className={classes.text}>
          Imp: {numbro(impressionsValue).format(numbroDecimalFormatting)}
        </Typography>
      )}
    </div>
  );
};

const Impressions = ({
  objective,
  impressions,
  showObjective = true,
}: {
  objective: number | null;
  impressions: number | null;
  showObjective?: boolean;
}) => {
  if (isNull(objective) && isNull(impressions)) return null;

  if (isNull(objective) && isNumber(impressions))
    return (
      <Meter
        showObjective={showObjective}
        objectiveString="-"
        impressionsValue={impressions}
      />
    );

  const objectiveString = numbro(objective).format(numbroDecimalFormatting);
  const impressionsPercent = calculatePercentage(impressions, objective);
  const impressionsValue = Math.min(
    parseFormattedValue(impressionsPercent) * 100,
    100
  );

  return (
    <Meter
      showObjective={showObjective}
      objectiveString={objectiveString}
      impressionsValue={impressionsValue}
      impressionsPercent={impressionsPercent}
    />
  );
};

export default Impressions;
