export const filterKvps = (kvps: string) =>
  kvps
    .replace(/\n/g, ',')
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean);

export const countKvps = (kvps: string) => filterKvps(kvps).length;

export const formatKvps = (kvps: string) =>
  [...new Set(filterKvps(kvps))].toString();

export const spaceKvps = (kvps: string) => kvps.replace(/,/g, ', ');

export default { filterKvps, countKvps, formatKvps, spaceKvps };
