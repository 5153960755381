import { IOption } from 'interfaces';
import {
  EntityPermission,
  PermissionName,
  Territory,
} from 'interfaces/generated.types';
import uniq from 'lodash/uniq';
import React from 'react';
import {
  permissionExistsInList,
  userHasAtLeastOnePermission,
} from 'utils/permissions';

export interface IUserValues {
  email: string;
  permissions: PermissionName[];
  primaryTerritory: string | undefined;
  entityPermissions: EntityPermission[];
  territories: Territory[];
  isUserDelete: boolean;
  userType: string;
}

export const userPermissionsOptions: IOption<PermissionName>[] = [
  {
    value: PermissionName.Primary,
    label: 'Primary',
  },
  {
    value: PermissionName.Direct,
    label: 'Direct: Edit',
  },
  {
    value: PermissionName.DirectRead,
    label: 'Direct: Read-only',
  },
  {
    value: PermissionName.Booking,
    label: 'Booking: Edit',
  },
  {
    value: PermissionName.BookingRead,
    label: 'Booking: Read-only',
  },

  {
    value: PermissionName.Supply,
    label: 'Supply: Edit',
  },
  {
    value: PermissionName.SupplyRead,
    label: 'Supply: Read-only',
  },
  {
    value: PermissionName.PublisherContract,
    label: 'Publisher Contracts: Edit',
  },
  {
    value: PermissionName.PublisherContractRead,
    label: 'Publisher Contracts: Read-only',
  },
  {
    value: PermissionName.Programmatic,
    label: 'Programmatic: Edit',
  },
  {
    value: PermissionName.ProgrammaticRead,
    label: 'Programmatic: Read-only',
  },
  {
    value: PermissionName.Forecasting,
    label: 'Forecasting',
  },
];

export const handleUserUpdate = (
  setFieldValue: (field: string, value: any, update?: boolean) => void,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
) => {
  setFieldValue('isUserDelete', false, false);
  handleSubmit();
};

export const handleUserDelete = (
  setFieldValue: (field: string, value: any, update?: boolean) => void,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
) => {
  setFieldValue('isUserDelete', true, false);
  handleSubmit();
};

export const handlePermissionCheckboxChange = ({
  event,
  valuesArray,
  setFieldValue,
  isUpdateForm,
  isGlobalUser,
}: {
  event: React.ChangeEvent<HTMLInputElement>;
  valuesArray: PermissionName[];
  setFieldValue: (field: string, value: any) => void;
  isUpdateForm: boolean;
  isGlobalUser: boolean;
}) => {
  const {
    target: { value, checked },
  } = event;

  let newPermissionsArray: string[] = [];

  if (checked) {
    switch (value) {
      case PermissionName.Primary: {
        const additionalPermissions = [];
        if (!isUpdateForm && isGlobalUser)
          additionalPermissions.push(PermissionName.PublisherInsightsTier1);
        if (valuesArray.includes(PermissionName.Experimental))
          additionalPermissions.push(PermissionName.Experimental);
        newPermissionsArray = uniq([value, ...additionalPermissions]);
        break;
      }
      case PermissionName.Direct: {
        newPermissionsArray = uniq([
          ...valuesArray,
          value,
          PermissionName.DirectRead,
        ]);
        break;
      }
      case PermissionName.Booking:
        newPermissionsArray = uniq([
          ...valuesArray,
          value,
          PermissionName.BookingRead,
        ]);
        break;
      case PermissionName.Supply: {
        const additionalPermissions =
          !isUpdateForm && isGlobalUser
            ? [PermissionName.PublisherInsightsTier1]
            : [];
        newPermissionsArray = uniq([
          ...valuesArray,
          value,
          PermissionName.SupplyRead,
          ...additionalPermissions,
        ]);
        break;
      }
      case PermissionName.Programmatic:
        newPermissionsArray = uniq([
          ...valuesArray,
          value,
          PermissionName.ProgrammaticRead,
        ]);
        break;
      case PermissionName.PublisherContract:
        newPermissionsArray = uniq([
          ...valuesArray,
          value,
          PermissionName.PublisherContractRead,
        ]);
        break;
      default:
        newPermissionsArray = [...valuesArray, value];
        break;
    }
  } else {
    switch (value) {
      case PermissionName.Direct:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value && arrayValue !== PermissionName.DirectRead
        );
        break;
      case PermissionName.Booking:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value && arrayValue !== PermissionName.BookingRead
        );
        break;
      case PermissionName.Supply:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value &&
            arrayValue !== PermissionName.SupplyRead &&
            arrayValue !== PermissionName.PublisherContract &&
            arrayValue !== PermissionName.PublisherContractRead
        );
        break;
      case PermissionName.SupplyRead:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value &&
            arrayValue !== PermissionName.PublisherContractRead
        );
        break;
      case PermissionName.Programmatic:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value &&
            arrayValue !== PermissionName.ProgrammaticRead
        );
        break;
      case PermissionName.PublisherContract:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) =>
            arrayValue !== value &&
            arrayValue !== PermissionName.PublisherContractRead
        );
        break;
      default:
        newPermissionsArray = valuesArray.filter(
          (arrayValue) => arrayValue !== value
        );
        break;
    }
  }
  setFieldValue('permissions', newPermissionsArray);
};

export const isPermissionCheckboxDisabled = (
  permission: PermissionName,
  selectedPermissions: PermissionName[]
) => {
  switch (permission) {
    case PermissionName.DirectRead:
      return selectedPermissions.includes(PermissionName.Direct);
    case PermissionName.BookingRead:
      return selectedPermissions.includes(PermissionName.Booking);
    case PermissionName.ProgrammaticRead:
      return selectedPermissions.includes(PermissionName.Programmatic);
    case PermissionName.SupplyRead:
      return selectedPermissions.includes(PermissionName.Supply);
    case PermissionName.PublisherContract:
      return !userHasAtLeastOnePermission(selectedPermissions, [
        PermissionName.Supply,
        PermissionName.Primary,
      ]);
    case PermissionName.PublisherContractRead:
      return (
        !userHasAtLeastOnePermission(selectedPermissions, [
          PermissionName.SupplyRead,
          PermissionName.Supply,
          PermissionName.Primary,
        ]) || selectedPermissions.includes(PermissionName.PublisherContract)
      );
    default:
      return false;
  }
};

export const checkIfGlobalEmail = (email: string) =>
  email.endsWith('@global.com');

export const getUserInsightsPermission = (permissions: PermissionName[]) => {
  if (
    permissionExistsInList(permissions, PermissionName.PublisherInsightsTier1)
  )
    return PermissionName.PublisherInsightsTier1;
  if (
    permissionExistsInList(permissions, PermissionName.PublisherInsightsTier2)
  )
    return PermissionName.PublisherInsightsTier2;
  return '';
};

export const updatePrimaryTerritory = (
  newFieldValue: Territory,
  userValues: IUserValues,
  setFieldValue: (field: string, value: any) => void
) => {
  const filteredTerritories = userValues.territories.filter(
    (territory) => territory !== userValues.primaryTerritory
  );

  const newTerritories = !userValues.territories.includes(newFieldValue)
    ? [...filteredTerritories, newFieldValue]
    : [...filteredTerritories];

  setFieldValue('territories', [...newTerritories]);
  setFieldValue('primaryTerritory', newFieldValue);
  if (userValues.entityPermissions.length > 0)
    setFieldValue('entityPermissions', []);
};
