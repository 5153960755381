import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { ITreeItems, OptionType } from 'interfaces';
import every from 'lodash/every';
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import {
  filterNodes,
  getAllExpandableItemsIds,
  getExpandedItemsIds,
  getFilteredItemsIds,
} from 'utils/treeDataTransformation';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Clear, Search } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBox from '@material-ui/icons/CheckBoxRounded';
import CheckboxTwoTone from '@material-ui/icons/CheckBoxTwoTone';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowDown from '@material-ui/icons/ExpandMore';
import Remove from '@material-ui/icons/Remove';

import useStyles from './Tree.styles';

interface ITreeData {
  id: string;
  readOnly: boolean;
}

interface ITreeProps {
  'data-tc': string;
  allItems: ITreeItems[];
  expandedItemsIds: string[];
  selectedItemsIds: string[];
  onChange?: (value: string[]) => void;
  selectionOptions?: boolean;
  searchDataTc: string;
  allData?: ITreeData[];
  handleTreeNodeSelection?: (node: any) => void;
}

const treeIcons = ({ classes, dataTc }: { classes: any; dataTc: string }) => ({
  check: (
    <CheckBox
      data-tc={`${dataTc}CheckboxChecked`}
      className={classes.checkbox}
    />
  ),
  expandOpen: (
    <ArrowDown data-tc={`${dataTc}CollapseGroup`} className={classes.chevron} />
  ),
  expandClose: (
    <ArrowRight data-tc={`${dataTc}ExpandGroup`} className={classes.chevron} />
  ),
  halfCheck: <CheckboxTwoTone className={classes.checkbox} />,
  uncheck: (
    <CheckBoxOutlineBlank
      data-tc={`${dataTc}Checkbox`}
      className={classes.outline}
    />
  ),
  expandAll: (
    <span className={classes.selectingButton}>
      <Add />
      Expand All
    </span>
  ),
  collapseAll: (
    <span className={classes.selectingButton}>
      <Remove />
      Collapse All
    </span>
  ),
});

const Tree = (props: ITreeProps) => {
  const {
    'data-tc': dataTc,
    allItems,
    expandedItemsIds,
    selectedItemsIds,
    onChange,
    selectionOptions = false,
    searchDataTc,
    allData = [],
    handleTreeNodeSelection,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState(selectedItemsIds);
  const [expanded, setExpanded] = React.useState(expandedItemsIds);
  const [filterText, setFilterText] = React.useState('');
  const [nodesFiltered, setNodesFiltered] = React.useState(allItems);
  const [readOnlyIds] = React.useState(
    allData
      .filter((data: ITreeData) => data.readOnly)
      .map((data: ITreeData) => data.id)
  );

  React.useEffect(() => {
    if (onChange) onChange(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  React.useEffect(() => {
    const updatedItems = allItems.flatMap((allItem: ITreeItems) => {
      const children = allItem?.children?.flatMap((i: OptionType) => ({
        ...i,
        disabled: readOnlyIds.some((ro: string) => ro === i.value),
      }));
      return {
        ...allItem,
        disabled:
          children && children.length > 0 && every(children, 'disabled'),
        children,
      };
    });
    const filteredItems = filterNodes(filterText, updatedItems);
    setNodesFiltered(filteredItems);
  }, [allItems, readOnlyIds, filterText]);

  const handleChecked = (value: string[], node: any) => {
    if (handleTreeNodeSelection) handleTreeNodeSelection(node);
    setChecked(value);
  };

  const handleExpand = (value: string[]) => {
    setExpanded(value);
  };

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = filterNodes(e.target.value, allItems);
    setFilterText(e.target.value);
    if (e.target.value) {
      setExpanded(getAllExpandableItemsIds(result));
    } else {
      setExpanded(getExpandedItemsIds(checked, allItems));
    }
    setNodesFiltered(result);
  };

  const selectAll = () => {
    const filteredIds = getFilteredItemsIds(nodesFiltered);
    setChecked([...new Set([...checked, ...filteredIds])]);
  };

  const deselectAll = () => {
    const filteredIds = getFilteredItemsIds(nodesFiltered);
    const remainingIds = checked.filter(
      (element) => !filteredIds.includes(element)
    );
    setChecked([...new Set([...readOnlyIds, ...remainingIds])]);
  };

  const clearSearch = () => {
    setFilterText('');
    setNodesFiltered(allItems);
  };

  return (
    <div>
      <TextField
        name="search"
        type="text"
        value={filterText}
        onChange={onFilterChange}
        data-tc={searchDataTc}
        placeholder="Search..."
        fullWidth
        className={classNames([
          classes.searchWrapper,
          {
            [`${classes.searchWrapper}--large`]: !selectionOptions,
          },
        ])}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
              <span className="sr-only">Search</span>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="clear search" onClick={clearSearch}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.tree}>
        {selectionOptions && (
          <div className={classes.selectionOptions} data-tc="selectionOptions">
            <StyledButton
              className={classes.selectingButton}
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              onClick={selectAll}
            >
              Select All
            </StyledButton>

            <StyledButton
              className={classes.selectingButton}
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              onClick={deselectAll}
            >
              Deselect All
            </StyledButton>
          </div>
        )}
        <CheckboxTree
          data-tc={dataTc}
          checked={checked}
          expanded={expanded}
          icons={treeIcons({ classes, dataTc })}
          onCheck={handleChecked}
          onExpand={handleExpand}
          nodes={nodesFiltered as any}
          showExpandAll
        />
      </div>
    </div>
  );
};

export default Tree;
