import AddParameterButton from 'features/targetingV2/components/AddParameterButton/AddParameterButton';
import CustomParameterRow from 'features/targetingV2/components/CustomSection/CustomParameterRow/CustomParameterRow';
import {
  ICustomParameter,
  ICustomParameterGroup,
} from 'features/targetingV2/types/targeting';
import { hasInvalidCustomParameters } from 'features/targetingV2/utils/customParameter';
import { defaultCustomParameter } from 'features/targetingV2/utils/defaults';
import { FieldArray, getIn } from 'formik';
import React from 'react';

export interface ICustomGroupProps {
  customGroup: ICustomParameterGroup;
  customGroupFieldName: string;
  customGroupIndex: number;
  setFieldValue: (field: string, value: any) => void;
  removeCustomGroup: () => void;
  errors: any;
}

const CustomGroup = (props: ICustomGroupProps) => {
  const {
    customGroup,
    customGroupFieldName,
    customGroupIndex,
    setFieldValue,
    removeCustomGroup,
    errors,
  } = props;

  return (
    <div data-testid={customGroupFieldName}>
      <FieldArray name={`${customGroupFieldName}.parameters`}>
        {({ push: pushParameter, remove: removeParameter }) => (
          <>
            {customGroup.parameters.map(
              (parameter: ICustomParameter, parameterIndex: number) => {
                const customParameterFieldName = `${customGroupFieldName}.parameters[${parameterIndex}]`;
                const parameterCustomKvpsErrors = getIn(
                  errors,
                  `${customParameterFieldName}.customKvps`
                );

                const handleCustomParameterDeletion = () => {
                  if (customGroup.parameters.length === 1) removeCustomGroup();
                  else removeParameter(parameterIndex);
                };

                return (
                  <CustomParameterRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`customGroup${customGroupIndex}-parameter${parameterIndex}`}
                    parameter={parameter}
                    setFieldValue={setFieldValue}
                    customKvpsErrors={parameterCustomKvpsErrors}
                    fieldName={customParameterFieldName}
                    removeParameter={handleCustomParameterDeletion}
                    rowId={`Custom Group ${
                      customGroupIndex + 1
                    } CustomParameter ${parameterIndex + 1}`}
                  />
                );
              }
            )}
            {customGroup.parameters.length > 0 && (
              <AddParameterButton
                onClick={() => pushParameter(defaultCustomParameter)}
                disabled={hasInvalidCustomParameters(customGroup)}
              />
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default CustomGroup;
