import Loader from 'components/Loader/Loader';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  PublisherInsightsFilterCategory,
  PublisherInsightsFilterValue,
  useGetPublisherInsightsFiltersQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { ISelectedFilter } from 'utils/insights';
import {
  getFilterCategoryValuesTitle,
  getPopularFilters,
  hasPopularFilters,
  popularCountryValues,
} from 'utils/insightsFilters';

import ValuesList from './ValuesList';

interface ICategoryValuesContainerProps {
  activeFilterCategory: PublisherInsightsFilterCategory;
  selectedFilters: ISelectedFilter[];
  setSelectedFilters: (newSelectedFilters: ISelectedFilter[]) => void;
}

const CategoryValuesContainer = ({
  activeFilterCategory,
  selectedFilters,
  setSelectedFilters,
}: ICategoryValuesContainerProps) => {
  const {
    state: {
      user: { territories },
    },
  } = useSessionContext();

  const { loading, data, error } = useGetPublisherInsightsFiltersQuery({
    variables: {
      types: [activeFilterCategory.type],
      territories,
    },
  });
  const showPopularFilters = hasPopularFilters(activeFilterCategory.type);

  if (
    !loading &&
    (error ||
      !data?.publisherInsightsFilters ||
      !data?.publisherInsightsFilters.length)
  )
    return <p>Error in loading filters.</p>;

  const activeCategoryValues: PublisherInsightsFilterValue[] = data?.publisherInsightsFilters
    ? data?.publisherInsightsFilters[0].values
    : [];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {showPopularFilters && (
            <>
              <ValuesList
                filterCategory={activeFilterCategory}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterValues={getPopularFilters(
                  popularCountryValues,
                  activeCategoryValues
                )}
                title="Popular filters"
              />
              <hr />
            </>
          )}
          <ValuesList
            filterCategory={activeFilterCategory}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filterValues={activeCategoryValues}
            title={getFilterCategoryValuesTitle(activeFilterCategory.type)}
          />
        </>
      )}
    </>
  );
};

export default CategoryValuesContainer;
