import classNames from 'classnames';
import Contract, { IContract } from 'components/Contract/Contract';
import React, { useEffect, useState } from 'react';
import { classifyContracts } from 'utils/contracts';

import { Typography } from '@material-ui/core';

import useStyles from './Contracts.styles';

export interface IContractsProps {
  contracts: IContract[];
  CustomDialog: React.ElementType<any>;
  customDialogProps?: any;
  hasEditPermissions?: boolean;
}

const Contracts = ({
  contracts,
  CustomDialog,
  customDialogProps,
  hasEditPermissions = false,
}: IContractsProps) => {
  const classes = useStyles({});

  const [sortedContracts, setSortedContracts] = useState({
    currentContracts: [],
    futureContracts: [],
    expiredContracts: [],
  });

  useEffect(() => {
    const { currentContracts, futureContracts, expiredContracts } =
      classifyContracts(contracts);

    setSortedContracts({
      currentContracts,
      futureContracts,
      expiredContracts,
    });
  }, [contracts]);

  const { currentContracts, futureContracts, expiredContracts } =
    sortedContracts;

  return (
    <div className={classes.container} data-testid="contractsContainer">
      <div data-testid="currentContractsSection">
        <Typography className={classes.title}>Current Contracts</Typography>
        <div className={classes.contractsSection}>
          {currentContracts.length > 0 &&
            currentContracts.map((contract: any) => (
              <React.Fragment key={contract.id}>
                <Contract
                  contract={contract}
                  customDialogProps={customDialogProps}
                  CustomDialog={CustomDialog}
                  readOnly={!hasEditPermissions || customDialogProps.update}
                  hasEditPermissions={hasEditPermissions}
                />
                <hr
                  className={classNames(classes.line, `${classes.line}--slim`)}
                />
              </React.Fragment>
            ))}
        </div>
        <hr className={classes.line} />
      </div>
      <div>
        <Typography className={classes.title}>Future Contracts</Typography>
        <div
          className={classes.contractsSection}
          data-testid="futureContractsSection"
        >
          {futureContracts.length > 0 &&
            futureContracts.map((contract: any) => (
              <React.Fragment key={contract.id}>
                <Contract
                  contract={contract}
                  customDialogProps={customDialogProps}
                  CustomDialog={CustomDialog}
                  readOnly={!hasEditPermissions}
                  hasEditPermissions={hasEditPermissions}
                />
                <hr
                  className={classNames(classes.line, `${classes.line}--slim`)}
                />
              </React.Fragment>
            ))}
        </div>
        <hr className={classes.line} />
      </div>
      <div data-testid="expiredContractsSection">
        <Typography className={classes.title}>Expired Contracts</Typography>
        <div className={classes.contractsSection}>
          {expiredContracts.length > 0 &&
            expiredContracts.map((contract: any) => (
              <React.Fragment key={contract.id}>
                <Contract
                  contract={contract}
                  customDialogProps={customDialogProps}
                  CustomDialog={CustomDialog}
                  readOnly
                />
                <hr
                  className={classNames(classes.line, `${classes.line}--slim`)}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Contracts;
