import classNames from 'classnames';
import React from 'react';

import MaterialCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import useStyles from './Card.styles';

export enum CardColorEnum {
  AMBER = 'amber',
  GREEN = 'green',
  RED = 'red',
  DEFAULT = 'default',
  DEFAULT_BLACK_TEXT = 'defaultBlackText',
}

interface ICardProps {
  title: string;
  children: React.ReactNode;
  'data-testid': string;
  color?: CardColorEnum;
}

const Card = (props: ICardProps) => {
  const classes = useStyles();
  const {
    title,
    children,
    'data-testid': dataTestId,
    color = CardColorEnum.DEFAULT,
  } = props;

  return (
    <MaterialCard className={classes.card}>
      <CardHeader
        classes={{ root: classes.header, title: classes.title }}
        title={title}
      />
      <CardContent
        data-testid={dataTestId}
        className={classNames({
          [classes[color]]: color,
        })}
      >
        {children}
      </CardContent>
    </MaterialCard>
  );
};

export default Card;
