import React from 'react';

import { ReactComponent as ImageIcon } from 'assets/icons/dax-imageicon.svg';
import { ReactComponent as AudiotrackIcon } from 'assets/icons/dax-musicfileicon.svg';

import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import ImagePopover from 'components/ImagePopover/ImagePopover';
import Loader from 'components/Loader/Loader';

import {
  CreativeType,
  HttpMethod,
  useGeneratePresignedUrlQuery,
} from 'interfaces/generated.types';

import { getCreativeFileName } from 'utils/urls';

import useStyles from './CreativeLink.styles';

interface ICreativeLinkProps {
  fileName?: string;
  url: string;
  type: CreativeType;
  retrieveUrl?: (url: string) => void;
  dataTc: string;
  fullWidthAudio?: boolean;
  setFieldValue?: (field: string, value: any) => void;
}

const CreativeLink = (props: ICreativeLinkProps) => {
  const {
    fileName = '',
    url,
    type,
    retrieveUrl,
    dataTc,
    setFieldValue,
    fullWidthAudio = false,
  } = props;

  const { loading, data, error } = useGeneratePresignedUrlQuery({
    variables: {
      fileName: getCreativeFileName(url),
      httpMethod: HttpMethod.Get,
    },
    fetchPolicy: 'cache-first',
  });

  const classes = useStyles();
  if (loading) return <Loader />;
  if (error || !(data && data.generatePreSignedUrl))
    return type === CreativeType.Audio ? (
      <AudiotrackIcon
        data-tc="displayAudioIcon"
        className={classes.displayIcon}
      />
    ) : (
      <ImageIcon data-tc="displayImageIcon" className={classes.displayIcon} />
    );

  retrieveUrl && retrieveUrl(data.generatePreSignedUrl.url);
  return type === CreativeType.Audio ? (
    <AudioPlayer
      setFieldValue={setFieldValue}
      src={data.generatePreSignedUrl.url}
      data-tc={dataTc}
      fullWidthAudio={fullWidthAudio}
    />
  ) : (
    <ImagePopover
      fileName={fileName}
      url={data.generatePreSignedUrl.url}
      data-tc={dataTc}
    />
  );
};

export default CreativeLink;
