import pageStyles from 'assets/styles/components/Page.styles';
import Loader from 'components/Loader/Loader';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  Deal,
  DealTimeSeries,
  TimeSeries,
  useDealTimeSeriesQuery,
} from 'interfaces/generated.types';
import find from 'lodash/find';
import get from 'lodash/get';
import React, { lazy, Suspense } from 'react';
import { ChartAggregatorEnum } from 'utils/charts';
import { CurrencyLabel } from 'utils/currency';
import dateUtils, { TimeZones } from 'utils/date';
import { getCustomCurrencyBasedOnTerritory } from 'utils/defaultsByTerritory';

interface IDealMonitoring {
  id: string;
  timeZone: TimeZones;
}

const XYDateChart = lazy(() => import('components/XYDateChart/XYDateChart'));

export const transformDealMonitoringData = (
  deal: Deal | undefined,
  currencyLabel: string
) => {
  if (!deal) {
    return [];
  }
  const { timeSeries, timeZone } = deal;
  const { impressions, eCpm, advPayout, ltr } = timeSeries as DealTimeSeries;

  return [
    {
      label: 'Impressions',
      value: 'impressions',
      series: (impressions as TimeSeries)?.dataPoints?.map((point) => ({
        date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
        value: point.v,
      })),
      aggregator: ChartAggregatorEnum.SUM,
    },
    {
      label: 'eCPM',
      value: 'eCpm',
      series: (eCpm as TimeSeries)?.dataPoints?.map((point) => ({
        date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
        value: point.v,
        weighting: get(
          find((impressions as TimeSeries)?.dataPoints, { t: point.t }),
          'v',
          null
        ),
      })),
      aggregator: ChartAggregatorEnum.WEIGHTED_AVERAGE,
      currencyLabel,
    },
    {
      label: 'Revenue',
      value: 'revenue',
      series: (advPayout as TimeSeries)?.dataPoints?.map((point) => ({
        date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
        value: point.v,
      })),
      aggregator: ChartAggregatorEnum.SUM,
      currencyLabel,
    },
    {
      label: 'Listen through rate',
      value: 'ltr',
      series: (ltr as TimeSeries)?.dataPoints?.map((point) => ({
        date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
        value:
          typeof point.v === 'number'
            ? Number((point.v * 100).toFixed(2))
            : null,
        weighting: get(
          find((impressions as TimeSeries)?.dataPoints, { t: point.t }),
          'v',
          null
        ),
      })),
      aggregator: ChartAggregatorEnum.WEIGHTED_AVERAGE,
    },
  ];
};

const DealMonitoring = ({ id, timeZone }: IDealMonitoring) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const currencyBasedOnTerritory =
    getCustomCurrencyBasedOnTerritory(activeTerritory);

  const { data, loading, error } = useDealTimeSeriesQuery({
    variables: {
      id,
      timeZone,
      currency: currencyBasedOnTerritory,
    },
    errorPolicy: 'all',
  });

  const classes = pageStyles();

  const noData = !(data && data.deal);

  if (!loading && (error || noData))
    return (
      <p className={classes.errorMessage} data-testid="dealMonitoringError">
        Error, issues have occurred loading graph data
      </p>
    );

  return loading ? (
    <Loader />
  ) : (
    <Suspense fallback={<Loader />}>
      <XYDateChart
        data={transformDealMonitoringData(
          data?.deal as Deal,
          CurrencyLabel[currencyBasedOnTerritory]
        )}
        initialSeries="impressions"
        showSelector
        testId="dealMonitoringChart"
      />
    </Suspense>
  );
};

export default DealMonitoring;
