import gql from 'graphql-tag';

export const GET_PUBLISHER_MINIMAL = gql`
  query PublisherMinimal($id: ID!) {
    publisher(id: $id) {
      id
      name
      blacklistedIabCategories {
        name
        code
      }
      blacklistedDomains
      territories
    }
  }
`;

export const GET_PUBLISHER = gql`
  query Publisher($id: ID!, $territories: [Territory!]!) {
    publisher(id: $id) {
      territories
      channels(territories: $territories) {
        id
        altId
        name
        vastVersion
        type
        locale
        publisher {
          id
          name
        }
        timeZone
        blacklistedIabCategories {
          name
          code
        }
        blacklistedDomains
        status
        territories
      }
      id
      altId
      currency
      name
      network {
        id
        name
        status
        territories
      }
      pricingType
      pricingAmount
      blacklistedIabCategories {
        name
        code
      }
      blacklistedDomains
      status
    }
  }
`;

export const GET_PUBLISHER_ASSOCIATED_ADS = gql`
  query PublisherAssociatedAds($id: ID!, $territories: [Territory!]!) {
    publisher(id: $id) {
      id
      associatedAds(
        filter: { status_in: [LIVE, READY, PAUSED] }
        territories: $territories
      ) {
        id
        altId
        name
        startDate {
          isInherited
          value
        }
        endDate {
          isInherited
          value
        }
        status
        campaign {
          id
          name
          timeZone
          pacing
          owner {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_PUBLISHER_ASSOCIATED_DEALS = gql`
  query PublisherAssociatedDeals($id: ID!, $territories: [Territory!]!) {
    publisher(id: $id) {
      id
      associatedDeals(
        filter: { status_in: [ACTIVE, READY] }
        territories: $territories
      ) {
        id
        altId
        name
        startDate
        endDate
        timeZone
        status
        priority
        salesChannel {
          id
          name
          owner {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_PUBLISHER_TIME_SERIES = gql`
  query PublisherTimeSeries($id: ID!, $timeZone: ZoneId!) {
    publisher(id: $id) {
      id
      impressionsTimeSeries(params: { timeUnit: HOUR, timeZone: $timeZone }) {
        dataPoints {
          t
          v
        }
      }
    }
  }
`;

export const GET_ALL_PUBLISHERS_AND_CHANNELS = gql`
  query AllPublishersAndChannels($networkId: ID, $territories: [Territory!]!) {
    allPublishers(
      networkId: $networkId
      filter: { status_in: [ENABLED] }
      territories: $territories
    ) {
      id
      name
      channels(filter: { status_in: ENABLED }, territories: $territories) {
        id
        name
      }
    }
  }
`;

export const GET_ALL_PUBLISHER_CONTRACTS = gql`
  query AllPublisherContracts($publisherId: ID!) {
    allPublisherContracts(publisherId: $publisherId) {
      id
      altId
      startDate
      endDate
      publisher {
        id
        name
      }
      publisherCategories {
        id
        name
      }
      directSpotCpm
      programmaticSpotCpm
      hostReadCpm
      spotRevenueShare
      hostReadRevenueShare
      sponsorshipRevenueShare
      minRevenueGuaranteed
      minRevenueIdeal
      minInventoryGuaranteed
      maxInventoryGuaranteed
      percentFillGuaranteed
      currency
      territories
      notes
    }
  }
`;

export const GET_ALL_PUBLISHER_CATEGORIES = gql`
  query allPublisherCategories {
    allPublisherCategories {
      id
      name
    }
  }
`;
