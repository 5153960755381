import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateDspContainer from 'features/programmatic/dsp/containers/UpdateDspContainer/UpdateDspContainer';
import { History } from 'history';
import { Dsp, PermissionName, useDspQuery } from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface UpdateDspPageProps {
  history: History;
  match: {
    params: {
      dspId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Programmatic];

const UpdateDspPage = ({
  history,
  match,
  userPermissions,
}: UpdateDspPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useDspQuery({
    variables: { id: match.params.dspId, territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  const hasErrorAndNoData = error && !(data && data.dsp);
  const noErrorAndNoData = !error && !(data && data.dsp);

  if (!loading && (hasErrorAndNoData || noErrorAndNoData))
    return <Redirecting history={history} />;

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, editPermissions) &&
    isActiveTerritoryAssignedToEntity(
      data?.dsp?.territories!,
      activeTerritory!
    );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Dsp</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateDspContainer
            history={history}
            match={match}
            dsp={data?.dsp as Dsp}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateDspPage;
