import * as Yup from 'yup';

const SignUpValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Please enter an email')
    .email('Must be a valid email. Please contact support.'),

  password: Yup.string()
    .required('Please enter your password')
    .matches(/^(?=.{8,})/, 'Must Contain 8 Characters'),

  passwordConfirm: Yup.string()
    .required('Please enter your password again')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export default SignUpValidation;
