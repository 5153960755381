import { INavigationLink } from 'interfaces/navigation';
import React from 'react';
import { NavLink } from 'react-router-dom';

import useNavigationDropdownStyles from '../NavigationDropdown/NavigationDropdown.styles';

const NavigationLink = ({
  link,
  onClick,
}: {
  link: INavigationLink;
  onClick?: any;
}) => {
  const classes = useNavigationDropdownStyles();

  return (
    <NavLink
      onClick={onClick}
      exact
      to={link.url}
      className={classes.menu}
      data-tc="navigationLink"
    >
      {link.title}
    </NavLink>
  );
};

export default NavigationLink;
