import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    paddingTop: theme.spacing(2.5),
    color: theme.palette.common.black,
    textAlign: 'left',
  },
  channelErrors: {
    margin: theme.spacing(1.25, 0),
    height: 0,
  },
  addTargetingButton: {
    marginTop: theme.spacing(3),
  },
  loader: {
    margin: theme.spacing(0, 1),
    paddingTop: theme.spacing(0.5),
  },
  channelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
