import React, { useState } from 'react';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { IFormProps } from 'interfaces';

import BulkUploadModal from 'components/BulkUploadModal/BulkUploadModal';
import { parseStringToNumberOrEmptyString } from 'utils/numbers';

interface ITargetingLocationPosition {
  latitude: number | string;
  longitude: number | string;
  radius: number | string;
}

export interface ILocationBulkUploadProps {
  dataTc: string;
  onUpload: (values: any[]) => void;
}

export const parseTextPositions = (text = '') => {
  const positions = text.split(/\s+/g).filter((line) => !!line);

  const formattedPositions = positions.reduce<ITargetingLocationPosition[]>(
    (acc, value) => {
      const items = value.split(',').filter((item) => !!item);

      if (items.length !== 3) return acc;

      return [
        ...acc,
        {
          latitude: parseStringToNumberOrEmptyString(items[0]),
          longitude: parseStringToNumberOrEmptyString(items[1]),
          radius: parseStringToNumberOrEmptyString(items[2]),
        },
      ];
    },
    []
  );

  return formattedPositions;
};

type CsvHeaders = 'Latitude' | 'Longitude' | 'Radius';

export type PositionFromCsv = Partial<Record<CsvHeaders, string>>;

export const parseCsvPositions = (values: PositionFromCsv[]) =>
  values.map((value) => ({
    latitude: value.Latitude
      ? parseStringToNumberOrEmptyString(value.Latitude)
      : '',
    longitude: value.Longitude
      ? parseStringToNumberOrEmptyString(value.Longitude)
      : '',
    radius: value.Radius ? parseStringToNumberOrEmptyString(value.Radius) : '',
  }));

const LocationBulkUpload = (
  props: IFormProps<any> & ILocationBulkUploadProps
) => {
  const { dataTc, onUpload } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  const handleUpload = (values: any[]) => {
    onUpload(values);
    setModalOpen(false);
  };

  return (
    <>
      <StyledButton
        color={ButtonColorEnum.Primary}
        onClick={() => setModalOpen(!isModalOpen)}
        variant={ButtonVariantEnum.Outlined}
        data-tc={`${dataTc}-button`}
        testId={`${dataTc}-button`}
      >
        Bulk Upload
      </StyledButton>
      {isModalOpen && (
        <BulkUploadModal
          {...props}
          label="Location parameters"
          name="textLocations"
          placeholder={
            'Separate location parameter entries with a space or a new line. \nEg. \n10,20,30 \n15,25,35'
          }
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          onUpload={handleUpload}
          parseTextValues={parseTextPositions}
          parseCsvValues={parseCsvPositions}
          dataTc={`${dataTc}-modal`}
        />
      )}
    </>
  );
};

export default LocationBulkUpload;
