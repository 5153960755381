import memoizeOne from 'memoize-one';

import {
  DataCenterStatus,
  Region,
  TechnicalProviderStatus,
} from 'interfaces/generated.types';

export interface IDatacenterValues {
  id: string;
  name: string;
  region: Region | string;
  bidUrl: string;
  maxQps: string;
  altId?: number;
  journey: string;
  status?: DataCenterStatus;
  technicalProviderName: string;
  technicalProviderId: string;
}

export const Regions = [
  {
    value: Region.Eu,
    label: 'Europe',
  },
  {
    value: Region.UsEast,
    label: 'US East',
  },
];

export const dataCenterStatusValues = [
  {
    value: DataCenterStatus.Draft,
    label: 'Draft',
    isDisabled: (value: DataCenterStatus) =>
      [DataCenterStatus.Active, DataCenterStatus.Inactive].includes(value),
  },
  {
    value: DataCenterStatus.Active,
    label: 'Active',
    isDisabled: (value: DataCenterStatus) =>
      [DataCenterStatus.Draft].includes(value),
  },
  {
    value: DataCenterStatus.Inactive,
    label: 'Inactive',
    isDisabled: (value: DataCenterStatus) =>
      [DataCenterStatus.Draft].includes(value),
  },
];

export const getDatacenterStatusData = memoizeOne(
  (
    values: IDatacenterValues,
    technicalProviderStatus: TechnicalProviderStatus
  ) => [
    {
      status: DataCenterStatus.Draft,
      required: [
        ...(!values.name ? ["Complete 'Name'"] : []),
        ...(!values.technicalProviderName
          ? ['Complete Parent Programmatic Connector']
          : []),
      ],
    },
    {
      status:
        values.status === DataCenterStatus.Inactive
          ? DataCenterStatus.Inactive
          : DataCenterStatus.Active,
      required: [
        ...(!values.name ? ['Waiting for Name'] : []),
        ...(technicalProviderStatus !== TechnicalProviderStatus.Active
          ? ['Waiting for Parent Programmatic Connector to be in ACTIVE state']
          : []),
        ...(!values.bidUrl ? ['Waiting for Bid URL'] : []),
      ],
    },
  ]
);

export const datacenterDetailsFields = ['name', 'region', 'bidUrl', 'maxQps'];
