import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  IParameterValueOption,
  SectionType,
} from 'features/targetingV2/types/common';
import { formatParameterValuesResponse } from 'features/targetingV2/utils/formValuesFormatting';
import { getParameterValuesQueryDetails } from 'features/targetingV2/utils/parameterValues';
import {
  formatSecondaryValuesResponse,
  getSecondaryParameterValuesQueryDetails,
} from 'features/targetingV2/utils/secondaryParameterValues';
import { Territory } from 'interfaces/generated.types';
import { useEffect, useState } from 'react';

const useParameterValues = (
  sectionType: SectionType,
  selectedParameterType: string,
  setUpdatingValuesOptions: (value: boolean) => void,
  territory?: Territory,
  idUsedToLimitParameterValues: string = ''
) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [primaryValuesOptions, setPrimaryValuesOptions] = useState<
    IParameterValueOption[] | null
  >(null);

  const [secondaryValuesOptions, setSecondaryValuesOptions] = useState<
    IParameterValueOption[]
  >([]);

  const { query, path, fetchPolicy, variables } =
    getParameterValuesQueryDetails(sectionType, territory || activeTerritory!);

  const [getParameterValues, { data, loading }] = query({
    fetchPolicy,
  });

  const [skipSecondaryQuery, setSkipSecondaryQuery] = useState(
    !selectedParameterType
  );

  const {
    query: secondaryValuesQuery,
    path: secondaryValuesPath,
    fetchPolicy: secondaryValuesFetchPolicy,
    variables: secondaryValuesQueryVariables,
    skipQuery: skipSecondaryValuesQuery,
  } = getSecondaryParameterValuesQueryDetails(
    selectedParameterType,
    territory || activeTerritory!
  );

  const { data: secondaryValuesData, loading: secondaryValuesLoading } =
    secondaryValuesQuery({
      variables: secondaryValuesQueryVariables,
      fetchPolicy: secondaryValuesFetchPolicy,
      skip: skipSecondaryQuery || !!skipSecondaryValuesQuery,
    });

  useEffect(() => {
    if (selectedParameterType) {
      setUpdatingValuesOptions(true);
      setPrimaryValuesOptions(null);
      setSecondaryValuesOptions([]);

      getParameterValues({
        variables: { type: selectedParameterType, ...variables },
      });

      setSkipSecondaryQuery(!!skipSecondaryValuesQuery);
    } else setSkipSecondaryQuery(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameterType]);

  useEffect(() => {
    if (!loading && data && data[path]) {
      const formattedParameterValues = formatParameterValuesResponse(
        data[path],
        sectionType,
        undefined,
        true
      );

      if (idUsedToLimitParameterValues) {
        const parameterValue = formattedParameterValues.find(
          (option) => option.value === idUsedToLimitParameterValues
        );

        setPrimaryValuesOptions(
          parameterValue?.children || formattedParameterValues
        );
      } else {
        setPrimaryValuesOptions(formattedParameterValues);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, idUsedToLimitParameterValues]);

  useEffect(() => {
    if (
      !secondaryValuesLoading &&
      secondaryValuesData &&
      secondaryValuesData[secondaryValuesPath]
    ) {
      const formattedParameterValues = formatSecondaryValuesResponse(
        selectedParameterType,
        secondaryValuesData[secondaryValuesPath],
        sectionType
      );

      setSecondaryValuesOptions(formattedParameterValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryValuesData]);

  return {
    primaryValuesOptions,
    isLoadingPrimaryValues: loading,
    secondaryValuesOptions,
    isLoadingSecondaryValues: secondaryValuesLoading,
  };
};

export default useParameterValues;
