import CreateAdPage from 'features/direct/ad/pages/CreateAdPage/CreateAdPage';
import UpdateAdPage from 'features/direct/ad/pages/UpdateAdPage/UpdateAdPage';
import CreateAdvertiserPage from 'features/direct/advertiser/pages/CreateAdvertiserPage/CreateAdvertiserPage';
import UpdateAdvertiserPage from 'features/direct/advertiser/pages/UpdateAdvertiserPage/UpdateAdvertiserPage';
import AgenciesPage from 'features/direct/agency/pages/AgenciesPage/AgenciesPage';
import CreateAgencyPage from 'features/direct/agency/pages/CreateAgencyPage/CreateAgencyPage';
import UpdateAgencyPage from 'features/direct/agency/pages/UpdateAgencyPage/UpdateAgencyPage';
import CampaignsPage from 'features/direct/campaign/pages/CampaignsPage/CampaignsPage';
import CreateCampaignPage from 'features/direct/campaign/pages/CreateCampaignPage/CreateCampaignPage';
import UpdateCampaignPage from 'features/direct/campaign/pages/UpdateCampaignPage/UpdateCampaignPage';
import CreativesPage from 'features/direct/creative/pages/CreativesPage/CreativesPage';
import CreativesUploadPage from 'features/direct/creative/pages/CreativesUploadPage/CreativesUploadPage';
import CreateOrderPage from 'features/direct/order/pages/CreateOrderPage/CreateOrderPage';
import OrdersPage from 'features/direct/order/pages/OrdersPage/OrdersPage';
import UpdateOrderPage from 'features/direct/order/pages/UpdateOrderPage/UpdateOrderPage';
import ForecastingPage from 'features/forecasting/pages/ForecastingPage/ForecastingPage';
import MonetizeInsightsPage from 'features/insights/pages/MonetizeInsightsPage';
import ChannelsPage from 'features/inventory/channel/pages/ChannelsPage/ChannelsPage';
import CreateChannelPage from 'features/inventory/channel/pages/CreateChannelPage/CreateChannelPage';
import UpdateChannelPage from 'features/inventory/channel/pages/UpdateChannelPage/UpdateChannelPage';
import CreateNetworkPage from 'features/inventory/network/pages/CreateNetworkPage/CreateNetworkPage';
import NetworksPage from 'features/inventory/network/pages/NetworksPage/NetworksPage';
import UpdateNetworkPage from 'features/inventory/network/pages/UpdateNetworkPage/UpdateNetworkPage';
import CreatePublisherPage from 'features/inventory/publisher/pages/CreatePublisherPage/CreatePublisherPage';
import UpdatePublisherPage from 'features/inventory/publisher/pages/UpdatePublisherPage/UpdatePublisherPage';
import EmailVerificationPage from 'features/login/pages/EmailVerification/EmailVerification';
import ForgottenPassword from 'features/login/pages/ForgottenPassword/ForgottenPassword';
import ForgottenPasswordResend from 'features/login/pages/ForgottenPasswordResend/ForgottenPasswordResend';
import LoginDaxClient from 'features/login/pages/LoginDaxClient/LoginDaxClient';
import LoginOptionsPage from 'features/login/pages/LoginOptions/LoginOptionsPage';
import PasswordReset from 'features/login/pages/PasswordReset/PasswordReset';
import PrimaryControlPage from 'features/primaryControls/pages/PrimaryControlsPage/PrimaryControlsPage';
import ProgrammaticCreativesPage from 'features/programmatic/creative/pages/ProgrammaticCreativesPage/ProgrammaticCreativesPage';
import CreateDatacenterPage from 'features/programmatic/datacenter/pages/CreateDatacenterPage/CreateDatacenterPage';
import UpdateDatacenterPage from 'features/programmatic/datacenter/pages/UpdateDatacenterPage/UpdateDatacenterPage';
import ActiveDealsPage from 'features/programmatic/deal/pages/ActiveDealsPage/ActiveDealsPage';
import CreateDealPage from 'features/programmatic/deal/pages/CreateDealPage/CreateDealPage';
import UpdateDealPage from 'features/programmatic/deal/pages/UpdateDealPage/UpdateDealPage';
import CreateDspPage from 'features/programmatic/dsp/pages/CreateDspPage/CreateDspPage';
import UpdateDspPage from 'features/programmatic/dsp/pages/UpdateDspPage/UpdateDspPage';
import CreateSalesChannelPage from 'features/programmatic/salesChannel/pages/CreateSalesChannelPage/CreateSalesChannelPage';
import SalesChannelPage from 'features/programmatic/salesChannel/pages/SalesChannelPage/SalesChannelPage';
import UpdateSalesChannelPage from 'features/programmatic/salesChannel/pages/UpdateSalesChannelPage/UpdateSalesChannelPage';
import CreateSeatPage from 'features/programmatic/seat/pages/CreateSeatPage/CreateSeatPage';
import UpdateSeatPage from 'features/programmatic/seat/pages/UpdateSeatPage/UpdateSeatPage';
import CreateTechnicalProviderPage from 'features/programmatic/technicalProvider/pages/CreateTechnicalProviderPage/CreateTechnicalProviderPage';
import TechnicalProvidersPage from 'features/programmatic/technicalProvider/pages/TechnicalProvidersPage/TechnicalProvidersPage';
import UpdateTechnicalProviderPage from 'features/programmatic/technicalProvider/pages/UpdateTechnicalProviderPage/UpdateTechnicalProviderPage';
import SignUpPage from 'features/signup/pages/SignUpPage/SignUpPage';
import CreateTagPage from 'features/tag/pages/CreateTagPage/CreateTagPage';
import TagsPage from 'features/tag/pages/TagsPage/TagsPage';
import CreateTargetingPackPage from 'features/targeting/pages/CreateTargetingPackPage/CreateTargetingPackPage';
import CreateTargetingTemplatePage from 'features/targeting/pages/CreateTargetingTemplatePage/CreateTargetingTemplatePage';
import TargetingPacksPage from 'features/targeting/pages/TargetingPacksPage/TargetingPacksPage';
import TargetingTemplatesPage from 'features/targeting/pages/TargetingTemplatesPage/TargetingTemplatesPage';
import UpdateTargetingPackPage from 'features/targeting/pages/UpdateTargetingPackPage/UpdateTargetingPackPage';
import UpdateTargetingTemplatePage from 'features/targeting/pages/UpdateTargetingTemplatePage/UpdateTargetingTemplatePage';
import CreateUserPage from 'features/user/pages/CreateUserPage/CreateUserPage';
import UpdateUserPage from 'features/user/pages/UpdateUserPage/UpdateUserPage';
import UsersPage from 'features/user/pages/UsersPage/UsersPage';
import { PermissionName } from 'interfaces/generated.types';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import HomePage from 'pages/HomePage/HomePage';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import handleAuthorization from './handleAuthorization';

const allUsers = Object.values(PermissionName);

const masterUsers = [PermissionName.Primary];
const directEditUsers = [...masterUsers, PermissionName.Direct];
const directReadUsers = [...directEditUsers, PermissionName.DirectRead];
const supplyEditUsers = [...masterUsers, PermissionName.Supply];
const supplyReadUsers = [...supplyEditUsers, PermissionName.SupplyRead];
const programmaticEditUsers = [...masterUsers, PermissionName.Programmatic];
const programmaticReadUsers = [
  ...programmaticEditUsers,
  PermissionName.ProgrammaticRead,
];

const Routes = () => (
  <Switch>
    <Route exact path="/" component={LoginOptionsPage} />
    <Route
      exact
      path="/home"
      component={handleAuthorization(HomePage, allUsers)}
    />
    <Route exact path="/sign-up" component={SignUpPage} />
    <Route exact path="/sign-in" component={LoginDaxClient} />
    <Route exact path="/forgotten-password" component={ForgottenPassword} />
    <Route
      exact
      path="/forgotten-password-resend"
      component={ForgottenPasswordResend}
    />
    <Route exact path="/email-verification" component={EmailVerificationPage} />
    <Route exact path="/reset-password" component={PasswordReset} />
    <Route
      exact
      path="/campaigns"
      component={handleAuthorization(CampaignsPage, directReadUsers)}
    />
    <Route
      exact
      path="/order/:orderId/campaign"
      component={handleAuthorization(CreateCampaignPage, directEditUsers)}
    />
    <Route
      exact
      path="/campaign"
      component={handleAuthorization(CreateCampaignPage, directEditUsers)}
    />
    <Route
      exact
      path="/campaign/:campaignId"
      component={handleAuthorization(UpdateCampaignPage, directReadUsers)}
    />
    <Route
      exact
      path="/campaign/:campaignId/ad"
      component={handleAuthorization(CreateAdPage, directEditUsers)}
    />
    <Route
      exact
      path="/ad/:adId"
      component={handleAuthorization(UpdateAdPage, directReadUsers)}
    />
    <Route
      exact
      path="/creatives"
      component={handleAuthorization(CreativesPage, directReadUsers)}
    />
    <Route
      exact
      path="/creatives/upload"
      component={handleAuthorization(CreativesUploadPage, directEditUsers)}
    />
    <Route
      exact
      path="/networks"
      component={handleAuthorization(NetworksPage, supplyReadUsers)}
    />
    <Route
      exact
      path="/network"
      component={handleAuthorization(CreateNetworkPage, supplyEditUsers)}
    />
    <Route
      exact
      path="/network/:networkId"
      component={handleAuthorization(UpdateNetworkPage, supplyReadUsers)}
    />
    <Route
      exact
      path="/network/:networkId/publisher"
      component={handleAuthorization(CreatePublisherPage, supplyEditUsers)}
    />
    <Route
      exact
      path="/publisher/:publisherId"
      component={handleAuthorization(UpdatePublisherPage, supplyReadUsers)}
    />
    <Route
      exact
      path="/publisher/:publisherId/channel"
      component={handleAuthorization(CreateChannelPage, supplyEditUsers)}
    />
    <Route
      exact
      path="/channel/:channelId"
      component={handleAuthorization(UpdateChannelPage, supplyReadUsers)}
    />
    <Route
      exact
      path="/channels"
      component={handleAuthorization(ChannelsPage, supplyReadUsers)}
    />
    <Route
      exact
      path="/sales-channels"
      component={handleAuthorization(SalesChannelPage, programmaticReadUsers)}
    />
    <Route
      exact
      path="/sales-channel"
      component={handleAuthorization(
        CreateSalesChannelPage,
        programmaticEditUsers
      )}
    />

    <Route
      exact
      path="/sales-channel/:salesChannelId"
      component={handleAuthorization(
        UpdateSalesChannelPage,
        programmaticReadUsers
      )}
    />
    <Route
      exact
      path="/sales-channel/:salesChannelId/deal"
      component={handleAuthorization(CreateDealPage, programmaticEditUsers)}
    />
    <Route
      exact
      path="/active-deals"
      component={handleAuthorization(ActiveDealsPage, programmaticReadUsers)}
    />
    <Route
      exact
      path="/deal/:dealId"
      component={handleAuthorization(UpdateDealPage, programmaticReadUsers)}
    />
    <Route
      exact
      path="/agencies"
      component={handleAuthorization(AgenciesPage, directReadUsers)}
    />
    <Route
      exact
      path="/agency"
      component={handleAuthorization(CreateAgencyPage, directEditUsers)}
    />
    <Route
      exact
      path="/agency/:agencyId"
      component={handleAuthorization(UpdateAgencyPage, directReadUsers)}
    />
    <Route
      exact
      path="/agency/:agencyId/advertiser"
      component={handleAuthorization(CreateAdvertiserPage, directEditUsers)}
    />
    <Route
      exact
      path="/advertiser/:advertiserId"
      component={handleAuthorization(UpdateAdvertiserPage, directReadUsers)}
    />
    <Route
      exact
      path="/orders"
      component={handleAuthorization(OrdersPage, directReadUsers)}
    />
    <Route
      exact
      path="/order"
      component={handleAuthorization(CreateOrderPage, directEditUsers)}
    />
    <Route
      exact
      path="/order/:orderId"
      component={handleAuthorization(UpdateOrderPage, directReadUsers)}
    />
    <Route
      exact
      path="/users"
      component={handleAuthorization(UsersPage, masterUsers)}
    />
    <Route
      exact
      path="/user"
      component={handleAuthorization(CreateUserPage, masterUsers)}
    />
    <Route
      exact
      path="/user/:userId"
      component={handleAuthorization(UpdateUserPage, masterUsers)}
    />
    <Route
      exact
      path="/programmatic-connectors"
      component={handleAuthorization(
        TechnicalProvidersPage,
        programmaticReadUsers
      )}
    />
    <Route
      exact
      path="/programmatic-connector"
      component={handleAuthorization(
        CreateTechnicalProviderPage,
        programmaticEditUsers
      )}
    />
    <Route
      exact
      path="/programmatic-connector/:technicalProviderId"
      component={handleAuthorization(
        UpdateTechnicalProviderPage,
        programmaticReadUsers
      )}
    />
    <Route
      exact
      path="/programmatic-connector/:technicalProviderId/datacenter"
      component={handleAuthorization(
        CreateDatacenterPage,
        programmaticEditUsers
      )}
    />
    <Route
      exact
      path="/datacenter/:datacenterId"
      component={handleAuthorization(
        UpdateDatacenterPage,
        programmaticReadUsers
      )}
    />
    <Route
      exact
      path="/programmatic-connector/:technicalProviderId/dsp"
      component={handleAuthorization(CreateDspPage, programmaticEditUsers)}
    />
    <Route
      exact
      path="/dsp/:dspId"
      component={handleAuthorization(UpdateDspPage, programmaticReadUsers)}
    />
    <Route
      exact
      path="/dsp/:dspId/seat"
      component={handleAuthorization(CreateSeatPage, programmaticEditUsers)}
    />
    <Route
      exact
      path="/seat/:seatId"
      component={handleAuthorization(UpdateSeatPage, programmaticReadUsers)}
    />
    <Route
      exact
      path="/targeting-templates"
      component={handleAuthorization(TargetingTemplatesPage, masterUsers)}
    />
    <Route
      exact
      path="/targeting-template"
      component={handleAuthorization(CreateTargetingTemplatePage, masterUsers)}
    />
    <Route
      exact
      path="/targeting-template/:targetingTemplateId"
      component={handleAuthorization(UpdateTargetingTemplatePage, masterUsers)}
    />
    <Route
      exact
      path="/targeting-packs"
      component={handleAuthorization(TargetingPacksPage, masterUsers)}
    />
    <Route
      exact
      path="/targeting-pack"
      component={handleAuthorization(CreateTargetingPackPage, masterUsers)}
    />
    <Route
      exact
      path="/targeting-pack/:targetingPackType/:targetingPackId"
      component={handleAuthorization(UpdateTargetingPackPage, masterUsers)}
    />
    <Route
      exact
      path="/creative-qa"
      component={handleAuthorization(
        ProgrammaticCreativesPage,
        programmaticReadUsers
      )}
    />
    <Route
      exact
      path="/primary-controls"
      component={handleAuthorization(PrimaryControlPage, masterUsers)}
    />
    <Route
      exact
      path="/reporting-tags"
      component={handleAuthorization(TagsPage, masterUsers)}
    />
    <Route
      exact
      path="/reporting-tag"
      component={handleAuthorization(CreateTagPage, masterUsers)}
    />
    <Route
      exact
      path="/forecasting"
      component={handleAuthorization(ForecastingPage, [
        PermissionName.Forecasting,
        PermissionName.Primary,
      ])}
    />
    <Route
      exact
      path="/monetize"
      component={handleAuthorization(MonetizeInsightsPage, [
        PermissionName.PublisherInsightsTier1,
        PermissionName.PublisherInsightsTier2,
      ])}
    />
    <Route exact path="/error/:errorCode" component={ErrorPage} />
    <Route
      render={() => (
        <Redirect
          to={{
            pathname: '/error/404',
          }}
        />
      )}
    />
  </Switch>
);

export default Routes;
