import React, { useState } from 'react';
import useError from 'hooks/Error/useError';
import { History } from 'history';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import TargetingPackForm, {
  ITargetingPackValues,
} from 'features/targeting/components/TargetingPackForm/TargetingPackForm';
import TargetingPackFormValidation from 'features/targeting/validations/targetingPacks';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { handleFormErrors } from 'utils/forms';
import locationUtils, {
  splitCountryParam,
  generateSubRegionParams,
} from 'utils/location';
import {
  TargetingPackTypeEnum,
  getTargetingPackDetails,
  ITargetingPackType,
} from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import { targetingParameterTypesEnum } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';

interface IUpdateTargetingPackContainerProps {
  history: History;
  match: {
    params: {
      targetingPackId: string;
    };
  };
  type: TargetingPackTypeEnum;
  targetingPack: any;
  isPackAssignedToActiveTerritory: boolean;
}

export const packVariables = (
  { type, path, apiPath }: ITargetingPackType,
  values: any
) => {
  switch (type) {
    case TargetingPackTypeEnum.POSTCODE:
      return {
        [apiPath]: values[path].map((code: any) => code.value),
      };
    case TargetingPackTypeEnum.REGION:
      return {
        [apiPath]: locationUtils.createLocationData(
          values[path],
          '',
          targetingParameterTypesEnum.SUBREGION
        ),
      };
    default:
      return {};
  }
};

export const submitUpdateTargetingPack = (
  targetingPackId: string,
  updateTargetingPack: any,
  toggleErrorModal: () => void,
  targetingPackDetails: ITargetingPackType
) => (formValues: any, { setStatus, setSubmitting }: FormikActions<any>) => {
  const variables = {
    id: targetingPackId,
    name: formValues.name,
    description: formValues.description,
    ...packVariables(targetingPackDetails, formValues),
  };

  updateTargetingPack({
    variables,
  }).catch((error: any) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

export const handleOnComplete = (history: History) => {
  history.push('/targeting-packs');
};

const getSubRegionParams = (params: any) => {
  const [includedSubRegions, excludedSubRegions] = generateSubRegionParams(
    params
  );

  return [...includedSubRegions, ...excludedSubRegions];
};

const UpdateTargetingPackContainer = ({
  history,
  match,
  type,
  targetingPack,
  isPackAssignedToActiveTerritory,
}: IUpdateTargetingPackContainerProps) => {
  const targetingPackDetails = getTargetingPackDetails(type);

  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Targeting Pack.",
    'Please try again later.',
  ]);
  const [updateQuery, setUpdateQuery] = useState<any>(
    () => targetingPackDetails.update
  );

  const [updateTargetingPack] = updateQuery({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  const initialValues = {
    name: targetingPack.name,
    description: targetingPack.description || '',
    type,
    postcodes:
      targetingPack[
        getTargetingPackDetails(TargetingPackTypeEnum.POSTCODE).apiPath
      ] &&
      targetingPack[targetingPackDetails.apiPath].map((code: any) => ({
        label: code,
        value: code,
      })),
    countryParams:
      targetingPack[
        getTargetingPackDetails(TargetingPackTypeEnum.REGION).apiPath
      ] &&
      splitCountryParam(
        getSubRegionParams(targetingPack[targetingPackDetails.apiPath])[0]
      ),
    territory: targetingPack.territory,
  };

  return (
    <>
      <Formik<ITargetingPackValues>
        initialValues={initialValues}
        validationSchema={TargetingPackFormValidation}
        onSubmit={submitUpdateTargetingPack(
          match.params.targetingPackId,
          updateTargetingPack,
          toggleErrorModal,
          targetingPackDetails
        )}
      >
        {(props) => (
          <TargetingPackForm
            {...props}
            update
            setUpdateQuery={setUpdateQuery}
            isPackAssignedToActiveTerritory={isPackAssignedToActiveTerritory}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateTargetingPack"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default UpdateTargetingPackContainer;
