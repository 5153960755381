import debounce from 'debounce-promise';
import { getIn } from 'formik';
import React, { useEffect, useState } from 'react';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import Loader from 'components/Loader/Loader';

import { IGetAllSubRegionsResponse } from 'features/targeting/graphql/subRegions/queries';

import { IFormProps, OptionType } from 'interfaces';
import {
  GeoRegionResponse,
  useAllCountriesQuery,
  useListRegionsQuery,
  useListSubRegionsQuery,
} from 'interfaces/generated.types';

import locationUtils from 'utils/location';
import {
  getTargetingPath,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

import TargetingMultiSelect, {
  TargetingAsyncMultiSelect,
} from '../TargetingMultiSelect/TargetingMultiSelect';
import { TLocationOption } from '../TargetingParameters/TargetingParametersValues';
import { filterRegionsByCountries } from '../TargetingRegion/TargetingRegion';
import { TargetingPackTypeEnum } from '../TargetingPackForm/TargetingPackFormValues';
import TargetingPackSelector from '../TargetingPackSelector/TargetingPackSelector';

export const subRegionValues = {
  countryParams: {
    countries: [],
    regions: [],
    subRegions: [],
    countryGroups: [],
  },
};

export const loadSubOptions = async (
  value: string,
  regions: IGetAllSubRegionsResponse,
  setFetchedOptions: any
) => {
  if (value.length >= 1) {
    const data = regions.allSubRegions.map(
      (allSubRegions: GeoRegionResponse) => ({
        ...allSubRegions,
        regions: allSubRegions.regions.filter((r) =>
          r.name.toLowerCase().includes(value.toLowerCase())
        ),
      })
    );
    const response: GeoRegionResponse[] = await new Promise((resolve) =>
      resolve(data)
    );

    const formattedData = locationUtils.createSubRegionGroup(response);
    setFetchedOptions(formattedData);
    return formattedData;
  }
  return [];
};

const debouncedLoadSubOptions = debounce(loadSubOptions, 1000, {
  leading: true,
});

const filterSubRegionsByRegions = (
  subRegions: TLocationOption[],
  regions: OptionType[]
) => {
  const regionCodes = locationUtils.formatRegionCodes(regions, true);
  return subRegions.filter((subRegion: TLocationOption) =>
    regionCodes.includes(subRegion.countryCode)
  );
};

const TargetingSubRegion = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    setFieldValue,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
  } = props;

  const [fetchedOptions, setFetchedOptions] = useState([]);

  const classes: any = targetingStyles({});
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const countryField = `${targetingPath}.audienceParams.${index}.countryParams.countries`;
  const regionField = `${targetingPath}.audienceParams.${index}.countryParams.regions`;
  const subRegionField = `${targetingPath}.audienceParams.${index}.countryParams.subRegions`;
  const countryVal = getIn(values, countryField);
  const regionVals = getIn(values, regionField);
  const subRegionVals = getIn(values, subRegionField);
  const [country, setCountry] = useState(countryVal);
  const [regions, setRegions] = useState(regionVals);
  const [subRegions, setSubRegions] = useState(subRegionVals);

  useEffect(() => {
    setCountry(countryVal || []);
  }, [countryVal]);

  useEffect(() => {
    setRegions(regionVals || []);
  }, [regionVals]);

  useEffect(() => {
    setSubRegions(subRegionVals || []);
  }, [subRegionVals]);

  useEffect(() => {
    setFieldValue(regionField, filterRegionsByCountries(regions, country));
  }, [country, regionField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFieldValue(
      subRegionField,
      filterSubRegionsByRegions(subRegions, regions)
    );
  }, [regions, regionField, subRegionField, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps
  const { loading, data: countriesData } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });
  const { data: regionsData } = useListRegionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCodes: locationUtils.formatCountryCodes(country),
    },
    skip: country.length === 0,
  });
  const { data: subregionsData } = useListSubRegionsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      countryCode: locationUtils.formatCountryCodes(country)[0],
      regionCodes: locationUtils.formatRegionCodes(regions, true),
    },
    skip: country.length === 0 || regions.length === 0,
  });

  if (loading) return <Loader />;

  return (
    <>
      <div className={classes.pairs}>
        <TargetingMultiSelect
          {...props}
          label="Country"
          selectAll={false}
          maxOptions={1}
          maxOptionsText="You can only select one country"
          targetingName="countryParams.countries"
          options={
            countriesData && countriesData.allCountries
              ? locationUtils.createCountryGroup(countriesData.allCountries)
              : []
          }
        />
      </div>
      <div className={classes.valueWrapper}>
        <TargetingMultiSelect
          {...props}
          label="Region"
          isDisabled={!regionsData}
          selectAll={false}
          maxOptions={10}
          maxOptionsText="You can only select 10 regions"
          targetingName="countryParams.regions"
          options={
            regionsData && regionsData.allRegions
              ? locationUtils.createRegionGroup(regionsData.allRegions)
              : []
          }
        />
      </div>
      <div className={classes.valueWrapper}>
        <TargetingAsyncMultiSelect
          {...props}
          label="Sub-region"
          placeholder="Begin typing to find a Sub-region"
          isDisabled={!subregionsData}
          targetingName="countryParams.subRegions"
          loadOptions={(value: string) =>
            subregionsData &&
            debouncedLoadSubOptions(value, subregionsData, setFetchedOptions)
          }
          fetchedOptions={fetchedOptions}
        />
      </div>

      <TargetingPackSelector
        {...props}
        name={`${targetingPath}.audienceParams.${index}.countryParams.countryGroups`}
        type={TargetingPackTypeEnum.REGION}
        label="Region Packs"
        errorMessage="There was an error fetching region packs. Please try again later"
      />
    </>
  );
};

export default TargetingSubRegion;
