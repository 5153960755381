import buttonStyles from 'assets/styles/components/Button.styles';
import styledButtonStyles from 'components/StyledButton/StyledButton.styles';
import React from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export interface ISplitButton {
  text: string;
  onClick: () => void;
  isLoading: boolean;
  disabled: boolean;
  dataTc: string;
}

export default function SplitButton({
  buttons,
  toggleDataTc,
  isLoading = false,
}: {
  buttons: ISplitButton[];
  toggleDataTc: string;
  isLoading?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const classes = buttonStyles();
  const styledButtonClasses = styledButtonStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLDivElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={styledButtonClasses.wrapper}>
        <ButtonGroup
          variant="outlined"
          color="primary"
          ref={anchorRef}
          aria-label="Split button"
        >
          <Button
            classes={{
              containedPrimary: classes.containedPrimary,
              containedSecondary: classes.containedSecondary,
              outlinedPrimary: classes.outlinedPrimary,
              outlinedSecondary: classes.outlinedSecondary,
              text: classes.text,
            }}
            data-testid="splitButton"
            data-tc={buttons[0].dataTc}
            disabled={buttons[0].disabled}
            onClick={buttons[0].onClick}
          >
            {buttons[0].text}
          </Button>
          <Button
            classes={{
              containedPrimary: classes.containedPrimary,
              containedSecondary: classes.containedSecondary,
              outlinedPrimary: classes.outlinedPrimary,
              outlinedSecondary: classes.outlinedSecondary,
              text: classes.text,
              root: classes.splitButtonToggle,
            }}
            data-testid="splitButtonMenuToggle"
            color="primary"
            variant="outlined"
            size="small"
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            data-tc={toggleDataTc}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        {isLoading && (
          <CircularProgress
            data-testid="splitButtonLoader"
            size="24"
            className={styledButtonClasses.loader}
          />
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow" data-testid="splitButtonMenu">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {buttons.map((button: ISplitButton) => (
                    <MenuItem
                      data-testid="splitButtonMenuItem"
                      key={button.text}
                      disabled={button.disabled}
                      onClick={() => button.onClick()}
                      data-tc={button.dataTc}
                    >
                      {button.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
