export const transformObjectKeysToLowerCase = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
  );

export const dedupeValues = (values: { [key: string]: any }[]) =>
  values
    ? [
        ...new Set(
          values.map((value: { [key: string]: any }) => JSON.stringify(value))
        ),
      ].map((value: any) => JSON.parse(value))
    : [];
