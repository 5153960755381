import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import AdvertiserDetails from 'features/direct/advertiser/components/AdvertiserDetails/AdvertiserDetails';
import IabCategoriesTree from 'features/iabCategories/containers/IabCategoriesTree/IabCategoriesTree';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import { AgencyStatus, EntityType } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import {
  advertiserDetailsFields,
  advertiserStatusValues,
  getAdvertiserStatusData,
  IAdvertiserValues,
} from './AdvertiserFormValues';

interface IAdvertiserProps {
  goBackTo: ILocation;
  hasEditPermissions?: boolean;
  agencyStatus?: AgencyStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
}

export const advertiserButtons = [
  {
    text: 'Save and Review',
    url: '/advertiser/ID',
    update: true,
    dataTc: 'advertiserReviewButton',
  },
  {
    text: 'Save and View All Advertisers',
    url: '/agency/PARENT_ID',
    update: false,
    dataTc: 'advertiserListButton',
  },
];

const getAdvertiserDetailsTabErrors = createNumberOfFormErrorsFn();

const AdvertiserTabsForm = (
  props: IFormProps<IAdvertiserValues> & IAdvertiserProps
) => {
  const {
    setFieldValue,
    values,
    handleSubmit,
    goBackTo,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    hasEditPermissions = true,
    status,
    agencyStatus,
    onFieldUpdate,
    onFieldValidate,
    history,
  } = props;
  const classes = TabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isAdvertiserTab = selectedTab === 0;
  const isIabCategoriesTab = selectedTab === 1;
  const isAdvertiserActivityTab = selectedTab === 2;

  return (
    <>
      <Form className={classes.form}>
        {update && agencyStatus && (
          <>
            <EntityProgressBar
              statusData={getAdvertiserStatusData(values, agencyStatus)}
              currentStatus={values.status || ''}
              dataTc="advertiserStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={advertiserStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="advertiserHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="advertiserTabs"
          >
            <Tab
              dataTc="advertiserDetailsTab"
              label="Details"
              numberOfTabErrors={getAdvertiserDetailsTabErrors(
                status,
                advertiserDetailsFields
              )}
            />
            <Tab dataTc="advertiserIabCategoriesTab" label="IAB Categories" />
            <Tab
              disabled={!update}
              dataTc="advertiserActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]: isAdvertiserActivityTab,
            },
          ])}
        >
          {isAdvertiserTab && <AdvertiserDetails {...props} />}
          {isIabCategoriesTab && (
            <IabCategoriesTree
              dataTc="advertiserIabCategories"
              iabCategoryCodes={values.iabCategoryCodes}
              onChange={setFieldValue}
              attribute="iabCategoryCodes"
            />
          )}
          {isAdvertiserActivityTab && (
            <ActivityTable
              entityType={EntityType.Advertiser}
              id={values.id}
              data-tc="advertiserActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitAdvertiserButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={advertiserButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default AdvertiserTabsForm;
