import classNames from 'classnames';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import StyledButton, {
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { OptionType } from 'interfaces';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import useStyles from './CreativeTrackingPixel.styles';

interface ICreativeTrackingPixelProps {
  allChannels: OptionType[];
  adId?: string;
  update: boolean;
  trackingPixel: string;
  setTrackingPixel: (trackingPixel: string) => void;
  channel: OptionType;
  setChannel: (channel: OptionType) => void;
}

const CreativeTrackingPixel = (props: ICreativeTrackingPixelProps) => {
  const {
    allChannels,
    adId,
    update,
    trackingPixel,
    setTrackingPixel,
    channel,
    setChannel,
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div
      className={classNames([
        classes.pixelTab,
        {
          [`${classes.pixelTab}--disabled`]: !update,
        },
      ])}
    >
      <h2 className={classes.heading}>Generate Tracking Pixel</h2>
      {trackingPixel ? (
        <p className={classes.subtitle} data-tc="additionalMessage">
          Use this control to manage a tracking pixel.
        </p>
      ) : (
        <p className={classes.subtitle} data-tc="introMessage">
          Use this control to generate a tracking pixel. Select the channel that
          will host the third-party ad servers, then clicking the button below
          will generate a custom URL that can then be used in other ad tracking
          platforms.
        </p>
      )}
      <div className={classes.multiSelect}>
        <MultiSelect
          id="channel"
          isDisabled={trackingPixel !== ''}
          isMulti={false}
          selectAll={false}
          label="Channel"
          name="channel"
          data-tc="channelMultiselect"
          onBlur={() => null}
          onChange={(fieldValue: OptionType | null) => {
            if (fieldValue) {
              setChannel(fieldValue);
            } else {
              setChannel({ value: '', label: '' });
            }
          }}
          options={allChannels || []}
          placeholder="Select a Channel..."
          value={channel}
          attributes={{
            fieldAttribute: 'channelField',
            menuAttribute: 'channelMenuOptions',
            chipAttribute: 'channelChip',
          }}
          errorProps={{
            FormHelperTextProps: {
              error: false,
            },
          }}
        />
      </div>
      <div>
        {trackingPixel ? (
          <div className={classes.trackingPixelWrapper}>
            <StyledButton
              variant={ButtonVariantEnum.Text}
              onClick={() => {
                setTrackingPixel('');
                setChannel({ value: '', label: '' });
              }}
              data-tc="removeTrackingPixel"
            >
              <CloseIcon />
              <span className="sr-only">Remove tracking pixel</span>
            </StyledButton>
            <TextField
              label="Tracking Pixel"
              name="trackingPixel"
              data-tc="trackingPixelField"
              fullWidth
              disabled
              multiline
              value={trackingPixel}
            />
            <CopyToClipboard text={trackingPixel}>
              <Tooltip
                onClose={handleTooltipClose}
                open={open}
                title="Copied to clipboard"
                data-tc="tooltip"
              >
                <div>
                  <StyledButton
                    variant={ButtonVariantEnum.Text}
                    data-tc="copyTrackingButton"
                    onClick={handleTooltipOpen}
                  >
                    <FileCopyOutlinedIcon className={classes.copyIcon} />
                  </StyledButton>
                </div>
              </Tooltip>
            </CopyToClipboard>
          </div>
        ) : (
          <IconButton
            data-tc="generateTrackingPixelButton"
            className={classNames([
              classes.cardButton,
              {
                [`${classes.cardButton}--disabled`]: !channel.value,
              },
            ])}
            onClick={() => {
              // @ts-ignore
              const trackingPixelUrl =
                (window.__ENV__ &&
                  window.__ENV__.REACT_APP_TRACKING_PIXEL_URI) ||
                process.env.REACT_APP_TRACKING_PIXEL_URI;
              setTrackingPixel(
                `${trackingPixelUrl}?cid=${channel.value}&ad=${adId}`
              );
            }}
            disabled={!channel.value}
          >
            <AddIcon />
            <span>Generate Tracking Pixel</span>
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default CreativeTrackingPixel;
