import { ApolloQueryResult } from 'apollo-client';
import { IParameterValueOption } from 'features/targetingV2/types/common';
import { IParameter } from 'features/targetingV2/types/targeting';
import {
  dedupeValues,
  transformObjectKeysToLowerCase,
} from 'features/targetingV2/utils/dataTransformation';
import {
  LocationParameterType,
  ValidateZipcodesQuery,
  ValidateZipcodesQueryVariables,
} from 'interfaces/generated.types';

import { getSelectOptions } from 'utils/dataTransformation';
import { isBetweenInclusive, isNumber } from './mathOperations';

type TLatLongDataType = { latitude: number; longitude: number; radius: number };
type TPostcodeDataType = string[];

type TValidatePostcodesQueryType = (
  variables: ValidateZipcodesQueryVariables
) => Promise<ApolloQueryResult<ValidateZipcodesQuery>>;
type TValidateQueryType<TData = any, TVariables = any> = (
  variables: TVariables
) => Promise<ApolloQueryResult<TData>>;

export const validateLatLongData = (data: TLatLongDataType[]) => {
  const validLatLongData = data.filter((dataRow: TLatLongDataType) => {
    const { latitude, longitude, radius } = transformObjectKeysToLowerCase(
      dataRow
    ) as TLatLongDataType;

    const isLatitudeValid = !!(
      isNumber(latitude) &&
      isBetweenInclusive(latitude, -90, 90) &&
      (longitude || longitude === 0) &&
      radius
    );

    const isLongitudeValid = !!(
      isNumber(longitude) &&
      isBetweenInclusive(longitude, -180, 180) &&
      (latitude || latitude === 0) &&
      radius
    );

    const isRadiusValid = !!(
      isNumber(radius) &&
      radius > 0 &&
      radius <= 20000000 &&
      (longitude || longitude === 0) &&
      (latitude || latitude === 0)
    );

    return isLatitudeValid && isLongitudeValid && isRadiusValid;
  });

  return validLatLongData;
};

export const validatePostcodesData = async (
  data: TPostcodeDataType[],
  validatePostcodesQuery: TValidatePostcodesQueryType
) => {
  const formattedPostcodesData = data.flatMap(([value]) => value.trim());
  try {
    const { data: allPostcodes } = await validatePostcodesQuery({
      postcodes: formattedPostcodesData,
    });

    return allPostcodes?.validatePostcodes?.valid;
  } catch (err) {
    return null;
  }
};

export const getValidDataBasedOnType = async (
  parameterType: string,
  data: any[],
  validationQuery?: TValidateQueryType
) => {
  switch (parameterType) {
    case LocationParameterType.LatLong:
      return validateLatLongData(data as TLatLongDataType[]);
    case LocationParameterType.Zipcode:
      if (validationQuery) {
        const validPostcodesData = await validatePostcodesData(
          data as TPostcodeDataType[],
          validationQuery
        );
        return validPostcodesData;
      }
      return null;
    default:
      return data;
  }
};

export const getBulkUploadOptionsBasedOnType = (
  parameterType: string,
  data: any[]
): IParameterValueOption[] => {
  if (parameterType === LocationParameterType.LatLong) {
    return (data as TLatLongDataType[]).map((obj: TLatLongDataType) => {
      const { latitude, longitude, radius } =
        transformObjectKeysToLowerCase(obj);
      return {
        label: `${latitude}, ${longitude}, ${radius}Km`,
        value: `${latitude}, ${longitude}, ${radius}`,
      };
    });
  }

  return data.map((parsedCsvDataValue) => ({
    label: parsedCsvDataValue,
    value: parsedCsvDataValue,
  }));
};

export const getWarningMessageBasedOnType = (parameterType: string): string => {
  switch (parameterType) {
    case LocationParameterType.Zipcode:
      return 'We have removed one or more postcodes that were duplicates or invalid.';
    case LocationParameterType.LatLong:
      return 'We have removed one or more coordinates that were duplicates or invalid.';
    default:
      return '';
  }
};

export const getHeaderDetails = (parameterType: string) => {
  switch (parameterType) {
    case LocationParameterType.LatLong:
      return {
        header: true,
        headerOptions: ['Latitude', 'Longitude', 'Radius'],
      };
    default:
      return {
        header: false,
        headerOptions: [],
      };
  }
};

export const getBulkUploadOptions = async (
  parameterType: string,
  parameterValues: IParameterValueOption[],
  data: any[],
  setWarningMessage: (value: string) => void,
  setErrorMessage: (value: string) => void,
  validationQuery?: TValidateQueryType
): Promise<IParameterValueOption[]> => {
  const validData = await getValidDataBasedOnType(
    parameterType,
    data,
    validationQuery
  );

  const previouslySelectedOptions = getSelectOptions(
    parameterValues,
    'label',
    'value'
  );

  if (!validData) {
    setErrorMessage('Sorry, our server had a hiccup. Try again later.');
    return previouslySelectedOptions;
  }

  const bulkUploadOptions = getBulkUploadOptionsBasedOnType(
    parameterType,
    validData
  );

  const allOptions = [...previouslySelectedOptions, ...bulkUploadOptions];

  const allUniqueOptions = dedupeValues(allOptions);

  const hasDuplicateOptions = allOptions.length !== allUniqueOptions.length;
  const hasInvalidDataValues = data.length !== validData.length;

  if (hasDuplicateOptions || hasInvalidDataValues || !validData.length) {
    setWarningMessage(getWarningMessageBasedOnType(parameterType));
  } else {
    setWarningMessage('');
  }

  return allUniqueOptions;
};

export const handleParameterValuesUpload = async (
  data: any[] | null,
  error: string | null,
  selectedParameter: IParameter,
  fieldName: string,
  setErrorMessage: (message: string) => void,
  setWarningMessage: (message: string) => void,
  setFieldValue: (field: string, value: any) => void,
  setIsBulkUploaded: (value: boolean) => void,
  validationQuery?: TValidateQueryType
) => {
  if (error) {
    setErrorMessage(error);
    setWarningMessage('');
  } else {
    setErrorMessage('');
    if (data && data.length) {
      const bulkUploadOptions = await getBulkUploadOptions(
        selectedParameter.type,
        selectedParameter.values,
        data,
        setWarningMessage,
        setErrorMessage,
        validationQuery
      );

      setIsBulkUploaded(true);
      setFieldValue(fieldName, bulkUploadOptions);
    }
  }
};
