import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(5, 0, 2.5, 0),
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
  },
  upload: {
    padding: theme.spacing(2.5, 0),
    textAlign: 'center',

    '&--disabled': {
      backgroundColor: theme.palette.grey[500],
      opacity: 0.3,
    },
  },
}));

export default useStyles;
