import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AuthState,
  defaultAuthContext,
  getValidateJwtTokenFunc,
  gigyaApiKey,
  IAuthContext,
  onLogin,
  onRegister,
  onResetPasswordSendEmail,
  onSocialLogin,
  resetPassword,
  sendVerificationCode,
  verifyEmail,
} from 'utils/authProvider';
import { setCookie } from 'utils/cookie';
import { loadGigyaScript } from 'utils/gigyaScript';

const AuthContext = createContext<IAuthContext>(defaultAuthContext);

const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, setState] = useState<AuthState>(AuthState.Fetching);
  const [registrationToken, setRegistrationToken] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [unverifiedEmail, setUnverifiedEmail] = useState('');
  const [loaded, setLoaded] = useState(false);

  const src = `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaApiKey}`;

  const initLoad = useCallback(() => {
    loadGigyaScript({ src, isAsync: true })
      .then(() => setLoaded(true))
      // eslint-disable-next-line no-console
      .catch(() => setState(AuthState.FailedLoadingSdk));
  }, [src]);

  useEffect(() => {
    initLoad();
  }, [initLoad]);

  const getJwtToken = () =>
    new Promise((resolve, reject) => {
      if (window && window.gigya) {
        window.gigya.accounts.getJWT({
          fields: 'email,isVerified,emails,loginProvider',
          callback: (data: any) => {
            if (data.errorCode === 0) {
              setCookie('dax_jwt_token', data.id_token || '', 300);

              if (state !== AuthState.LoggedIn) setState(AuthState.LoggedIn);
            } else {
              setState(AuthState.LoggedOut);
            }
            resolve(data.id_token || '');
          },
        });
      } else {
        reject(new Error('Unable to access authentication service'));
      }
    });

  const logout = () =>
    new Promise((resolve, reject) => {
      if (window && window.gigya) {
        window.gigya.accounts.logout({
          callback: (data: any) => {
            if (data.errorCode === 0) {
              setState(AuthState.LoggedOut);
              resolve(true);
            } else reject('Unable to log out. Please try again later.');
          },
        });
      }
    });

  const getHandleUnverifiedEmailFunc =
    (onUnverifiedEmail?: (email: string) => void) =>
    (email: string, token: string) => {
      setState(AuthState.NotVerified);
      setUnverifiedEmail(email);
      setRegistrationToken(token);
      if (onUnverifiedEmail) onUnverifiedEmail(email);
    };

  const [authContext, setAuthContext] = useState({
    ...defaultAuthContext,
    verificationToken,
    registrationToken,
    unverifiedEmail,
    loaded,
    validateJwtToken: getValidateJwtTokenFunc(getJwtToken),
    getJwtToken,
    logout,
    onGlobalStaffSignIn: (setError: (message: string) => void) =>
      onSocialLogin(getJwtToken, setError),
    onDaxClientSignIn: (
      params: {
        loginID: string;
        password: string;
      },
      setError: (message: string) => void,
      redirectOnUnverifiedEmail: (email: string) => void
    ) =>
      onLogin(
        params,
        getJwtToken,
        setError,
        getHandleUnverifiedEmailFunc(redirectOnUnverifiedEmail),
        setVerificationToken
      ),
    onDaxClientSignUp: (
      params: {
        email: string;
        password: string;
      },
      setError: (message: string) => void
    ) =>
      onRegister(
        params,
        getHandleUnverifiedEmailFunc(),
        setVerificationToken,
        setError
      ),
    onVerifyEmail: (
      code: string,
      regToken: string,
      vToken: string,
      setError: (message: string) => void
    ) => verifyEmail(code, regToken, vToken, getJwtToken, setError),
    onSendVerificationCode: (email: string) =>
      sendVerificationCode(email, setVerificationToken),
    onResetPasswordSendEmail,
    onResetPassword: (
      params: {
        passwordResetToken: string;
        newPassword: string;
      },
      setError: (message: string) => void
    ) => resetPassword(params, setState, setError),
  });

  useEffect(() => {
    if (loaded) {
      getJwtToken()
        // eslint-disable-next-line no-console
        .catch((error) => console.error('Error updating jwt token: ', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    setAuthContext((prevState) => ({
      ...prevState,
      state,
      loaded,
      verificationToken,
      registrationToken,
      unverifiedEmail,
    }));
  }, [loaded, state, verificationToken, registrationToken, unverifiedEmail]);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, AuthContext };
