import { Field, FieldArray, FormikErrors, FormikTouched, getIn } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Dropzone from 'components/Dropzone/Dropzone';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { CSV_FILE_SIZE, CSV_FILE_TYPE } from 'utils/files';

import useStyles from './Domains.styles';

interface IDomains {
  domainArrayName: string;
  dataTc: {
    domainField: string;
    addDomain: string;
    removeDomain: string;
  };
  domainFor: string;
  handleBlur<T = string | any>(
    fieldOrEvent: T
  ): T extends string ? (e: any) => void : void;
  handleChange(e: React.ChangeEvent<any>): void;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  domains: any[];
  generatedDomains: string;
  setFieldValue: any;
  setFieldTouched: any;
}

export const generateNewDomains = ({
  generatedDomains,
  setFieldValue,
  setFieldTouched,
  domains,
  domainArrayName,
}: {
  setFieldValue: any;
  setFieldTouched: any;
  generatedDomains: any;
  domains: string[];
  domainArrayName: string;
}) => {
  const splittedDomains = generatedDomains!.split(/\s+/g);
  const newValues = [...domains, ...splittedDomains].filter((v) => !!v);
  setFieldValue(domainArrayName, newValues);
  setFieldValue('generatedDomains', '');
  newValues.forEach((v, index) => {
    setFieldTouched(`${domainArrayName}.${index}`, true, true);
  });
};

export const generateParsedDomains = ({
  parsedDomains,
  setFieldValue,
  setFieldTouched,
  domains,
  domainArrayName,
}: {
  setFieldValue: any;
  setFieldTouched: any;
  parsedDomains: object[];
  domains: string[];
  domainArrayName: string;
}) => {
  const formattedDomains = parsedDomains
    .map((domain) => Object.values(domain))
    .flat();
  const newValues = [...domains, ...formattedDomains].filter(
    (v) => !!v && v.trim() !== ''
  );
  setFieldValue(domainArrayName, newValues);
  newValues.forEach((v, index) => {
    setFieldTouched(`${domainArrayName}.${index}`, true, true);
  });
};

const Domains = ({
  domains,
  errors,
  touched,
  domainArrayName,
  domainFor,
  dataTc,
  generatedDomains,
  setFieldValue,
  setFieldTouched,
}: IDomains) => {
  const classes = useStyles({});
  const handleDropzoneData = (data: any) => {
    generateParsedDomains({
      parsedDomains: data,
      setFieldValue,
      setFieldTouched,
      domains,
      domainArrayName,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="Add New Domains"
            data-tc="generateNewDomainsField"
            multiline
            rows={6}
            rowsMax={6}
            className={classes.createDomains}
            name="generatedDomains"
            placeholder="Separate domains with a space or a new line"
          />
        </Grid>
        <Grid item xs={4}>
          <Dropzone
            fileType={CSV_FILE_TYPE}
            maxFileSize={CSV_FILE_SIZE}
            dropzoneMessage="Click here and select a csv file or drag and drop the file here"
            errorMessages={[
              'It looks like the file is either too large or has an incorrect filetype/format. CSV files can only be up to 128 KB. Please try another file.',
            ]}
            dataTc="domainsDropzone"
            handleDropzoneData={handleDropzoneData}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.buttonWrapper}>
            <StyledButton
              color={ButtonColorEnum.Primary}
              variant={ButtonVariantEnum.Outlined}
              type="button"
              disabled={!generatedDomains}
              data-tc="generateNewDomains"
              onClick={() =>
                generateNewDomains({
                  generatedDomains,
                  setFieldValue,
                  setFieldTouched,
                  domains,
                  domainArrayName,
                })
              }
            >
              Add New Domains
            </StyledButton>
          </div>
        </Grid>
      </Grid>

      <FieldArray name={domainArrayName}>
        {({ remove, insert }) => (
          <div>
            {!!domains &&
              domains.map((_: any, index: number) => (
                /* eslint-disable react/no-array-index-key */
                <div key={index} className={classes.domains}>
                  <Field
                    component={TextField}
                    label="Domain"
                    placeholder="example.com"
                    data-tc={dataTc.domainField}
                    name={`${domainArrayName}.${index}`}
                    className={classes.textField}
                    helperText={
                      getIn(touched, `${domainArrayName}.${index}`) &&
                      getIn(errors, `${domainArrayName}.${index}`)
                    }
                    FormHelperTextProps={{
                      error: !!(
                        getIn(touched, `${domainArrayName}.${index}`) &&
                        getIn(errors, `${domainArrayName}.${index}`)
                      ),
                    }}
                  />
                  {domains.length > 1 && (
                    <StyledButton
                      type="button"
                      data-tc={dataTc.removeDomain}
                      testId={dataTc.removeDomain}
                      variant={ButtonVariantEnum.Text}
                      className={classes.button}
                      onClick={() => remove(index)}
                    >
                      <CloseIcon />
                      <span className="sr-only">
                        Remove {domains[index]} from {domainFor}
                      </span>
                    </StyledButton>
                  )}
                  <StyledButton
                    type="button"
                    data-tc={dataTc.addDomain}
                    testId={dataTc.addDomain}
                    variant={ButtonVariantEnum.Text}
                    className={classes.button}
                    onClick={() => insert(index + 1, '')}
                  >
                    <AddIcon />
                    <span className="sr-only">
                      Add a new domain to {domainFor}
                    </span>
                  </StyledButton>
                </div>
              ))}
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default Domains;
