import classNames from 'classnames';
import CreativeModal from 'components/CreativeModal/CreativeModal';
import CreativeRedirect from 'components/CreativeRedirect/CreativeRedirect';
import CreativeTrackingPixel from 'components/CreativeTrackingPixel/CreativeTrackingPixel';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import Tab from 'components/Tab/Tab';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  allCreativeStatuses,
  CreativeStatusEnum,
} from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import CreativeContainer from 'features/direct/creative/containers/CreativeContainer/CreativeContainer';
import CreativeUploadContainer from 'features/direct/creative/containers/CreativeUploadContainer/CreativeUploadContainer';
import { FieldArray } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFormProps } from 'interfaces';
import {
  CreativeType,
  useAllChannelsMinimalQuery,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import { createSelectOptions } from 'utils/dataTransformation';
import {
  AUDIO_FILE_SIZE,
  AUDIO_FILE_TYPES,
  IMAGE_FILE_SIZE,
  IMAGE_FILE_TYPES,
} from 'utils/files';
import { handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';

import useStyles from './CreativeTabs.styles';

interface ICreativeTabsProps {
  advertiserId: string;
  showLibrary?: boolean;
  multiAudioUpload?: boolean;
  history: History;
}

export const countCreatives = (creatives: any) =>
  creatives.reduce(
    (r: number, a: any) =>
      r +
      +(
        a.status === CreativeStatusEnum.NewCreativeUploaded ||
        a.status === CreativeStatusEnum.HasCreative ||
        allCreativeStatuses.includes(a.status)
      ),
    0
  );

const CreativeTabs = (props: IFormProps<any> & ICreativeTabsProps) => {
  const {
    values,
    setFieldValue,
    advertiserId,
    showLibrary = true,
    multiAudioUpload = false,
    update = false,
    history,
  } = props;
  const classes = useStyles();
  const hasCreativeRedirect = !!(
    showLibrary &&
    values.creativeRedirect &&
    (values.creativeRedirect.url !== null ||
      values.creativeRedirect.consentVendorId !== null)
  );

  const [selectedTab, setTab] = useState(hasCreativeRedirect ? 2 : 0);
  const [isModalOpened, setModalOpen] = useState(false);
  const [trackingPixel, setTrackingPixel] = useState('');
  const [channel, setChannel] = useState({ label: '', value: '' });

  const location = usePreviousLocation();
  useEffect(() => {
    const { innerTab } = location.state || 0;
    if (innerTab) setTab(innerTab);
  }, [location]);

  const isAudioTab = selectedTab === 0;
  const isImageTab = selectedTab === 1;
  const isCreativeRedirectTab = selectedTab === 2;
  const isTrackingTab = selectedTab === 3;
  const hasCreatives =
    values.audioCreatives.length > 0 || values.imageCreatives.length > 0;
  const selectedCreative = isAudioTab ? 'audioCreatives' : 'imageCreatives';
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { loading, error, data } = useAllChannelsMinimalQuery({
    variables: { territories: [activeTerritory!] },
    skip: !isTrackingTab,
  });

  return (
    <div className={classes.tabsWrapper}>
      <AppBar position="static" classes={{ root: classes.bar }}>
        <Tabs
          value={selectedTab}
          onChange={(e, index) => {
            setTab(index);
            history && handleTabHistory(history, index, 'innerTab');
          }}
          data-tc="creativeTabs"
        >
          <Tab
            disabled={hasCreativeRedirect}
            dataTc="creativeAudioTab"
            label={`Audio ${
              values.audioCreatives.length
                ? `(${countCreatives(values.audioCreatives)})`
                : ''
            }`}
          />
          <Tab
            disabled={hasCreativeRedirect}
            dataTc="creativeImageTab"
            label={`Display ${
              values.imageCreatives.length
                ? `(${countCreatives(values.imageCreatives)})`
                : ''
            }`}
          />
          {showLibrary && (
            <Tab
              disabled={hasCreatives}
              dataTc="creativeRedirectTab"
              label="Redirect"
            />
          )}
          {showLibrary && (
            <Tab
              disabled={hasCreativeRedirect}
              dataTc="trackingPixelTab"
              label={`Tracking ${trackingPixel ? `(1)` : ''}`}
            />
          )}
        </Tabs>
      </AppBar>
      <div className={classes.content}>
        {isCreativeRedirectTab ? (
          <>
            <h2 className={classes.heading}>Set Redirect Creatives</h2>
            <p className={classes.subtitle}>
              You can add redirect URLs here. However, adding one will disable
              all other tabs.
            </p>
            <CreativeRedirect {...props} />
          </>
        ) : null}
        {isTrackingTab &&
          // eslint-disable-next-line no-nested-ternary
          (loading ? (
            <Loader />
          ) : error || !(data && data.allChannels) ? (
            <p>
              There seem to be no channels to host third-party ads. Please
              refresh the page and contact your administrator if the issue
              persists.
            </p>
          ) : (
            <CreativeTrackingPixel
              allChannels={createSelectOptions(data.allChannels, true)}
              update={update}
              adId={values.altId}
              trackingPixel={trackingPixel}
              setTrackingPixel={setTrackingPixel}
              channel={channel}
              setChannel={setChannel}
            />
          ))}
        {isAudioTab || isImageTab ? (
          <>
            <h2 className={classes.heading}>
              Upload {isAudioTab ? 'audio' : 'image'} creatives
            </h2>
            <div>
              {values[selectedCreative].length < 1 &&
                (advertiserId ? (
                  <p className={classes.subtitle}>
                    You have not uploaded {isAudioTab ? 'audio' : 'images'}.
                    {showLibrary && (
                      <span>
                        You can select some from your advertiser&apos;s library
                        or upload one.
                      </span>
                    )}
                  </p>
                ) : (
                  <p className={classes.subtitle}>
                    To upload a creative, please first select an advertiser
                  </p>
                ))}
              {values[selectedCreative].some(
                (creative: any) =>
                  creative.status === CreativeStatusEnum.Uploading
              ) && (
                <List data-tc="creativeUploadingList">
                  {values[selectedCreative]
                    .filter(
                      (creative: any) =>
                        creative.status === CreativeStatusEnum.Uploading
                    )
                    .map((creative: any) => (
                      <li key={`${creative.name}-${shortid.generate()}`}>
                        Uploading {creative.name}
                      </li>
                    ))}
                </List>
              )}
              {values[selectedCreative].some(
                (creative: any) =>
                  creative.status === CreativeStatusEnum.NewCreativeUploaded ||
                  creative.status === CreativeStatusEnum.HasCreative ||
                  !!creative.id
              ) && (
                <FieldArray
                  name={selectedCreative}
                  render={({ remove }) => (
                    <List data-tc="creativeUploadedList">
                      {values[selectedCreative]
                        .filter(
                          (creative: any) =>
                            creative.status ===
                              CreativeStatusEnum.NewCreativeUploaded ||
                            creative.status ===
                              CreativeStatusEnum.HasCreative ||
                            !!creative.id
                        )
                        .map((_: any, index: number) => (
                          /* Formik FieldArray uses index to track array elements */
                          /* eslint-disable react/no-array-index-key */
                          <li
                            key={index}
                            className={classNames([
                              classes.creative,
                              {
                                [`${classes.creative}--multi`]: !showLibrary,
                              },
                            ])}
                          >
                            <CreativeContainer
                              {...props}
                              index={index}
                              attribute={selectedCreative}
                              creativeType={
                                isAudioTab
                                  ? CreativeType.Audio
                                  : CreativeType.Image
                              }
                              remove={remove}
                              values={values}
                              hideUrls={!showLibrary}
                            />
                          </li>
                        ))}
                    </List>
                  )}
                />
              )}
              {isAudioTab &&
                (multiAudioUpload ||
                  (!multiAudioUpload && !values.audioCreatives.length)) && (
                  <>
                    <CreativeUploadContainer
                      acceptedTypes={AUDIO_FILE_TYPES.toString()}
                      isMulti={multiAudioUpload}
                      maxFileSize={AUDIO_FILE_SIZE}
                      creativeType={CreativeType.Audio}
                      attribute={selectedCreative}
                      uploadLimit={multiAudioUpload ? undefined : 1}
                      dataTc="creativeAudioDropzone"
                      {...props}
                    />
                    {showLibrary && (
                      <>
                        <div className={classes.modalButtonWrapper}>
                          <StyledButton
                            color={ButtonColorEnum.Primary}
                            onClick={() => setModalOpen(!isModalOpened)}
                            variant={ButtonVariantEnum.Outlined}
                            data-tc="creativeAudioLibrary"
                            data-testid="creativeAudioLibrary"
                          >
                            Go to Audio Library
                          </StyledButton>
                        </div>
                        {isModalOpened ? (
                          <CreativeModal
                            closeModal={() => setModalOpen(false)}
                            resourceType={CreativeType.Audio}
                            setFieldValue={setFieldValue}
                            values={values}
                            advertiserId={advertiserId}
                          />
                        ) : null}
                      </>
                    )}
                  </>
                )}
              {isImageTab && (
                <>
                  <CreativeUploadContainer
                    acceptedTypes={IMAGE_FILE_TYPES.toString()}
                    isMulti
                    maxFileSize={IMAGE_FILE_SIZE}
                    creativeType={CreativeType.Image}
                    attribute="imageCreatives"
                    {...props}
                    dataTc="creativeImageDropzone"
                  />
                  {showLibrary && (
                    <>
                      <div className={classes.modalButtonWrapper}>
                        <StyledButton
                          color={ButtonColorEnum.Primary}
                          onClick={() => setModalOpen(!isModalOpened)}
                          variant={ButtonVariantEnum.Outlined}
                          data-tc="creativeImageLibrary"
                          data-testid="creativeImageLibrary"
                        >
                          Go to Image Library
                        </StyledButton>
                      </div>
                      {isModalOpened ? (
                        <CreativeModal
                          closeModal={() => setModalOpen(false)}
                          resourceType={CreativeType.Image}
                          values={values}
                          advertiserId={advertiserId}
                          setFieldValue={setFieldValue}
                        />
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CreativeTabs;
