import InsightsLineChart from 'features/insights/components/InsightsLineChart/InsightsLineChart';
import useUrlSearchParams from 'hooks/UrlParams/useUrlSearchParams';
import useUrlSearchParamUtils from 'hooks/UrlParams/useUrlSearchParamUtils';
import { IDateRange } from 'interfaces';
import { PublisherInsightsMetric } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  defaultInitialMetrics,
  IInsightsLineGraphsSelectedMetrics,
  ISelectedFilter,
} from 'utils/insights';

import { Grid } from '@material-ui/core';

import useStyles from './InsightsLineChartsContainer.styles';

interface IInsightsLineChartProps {
  testId: string;
  selectedDateRange: IDateRange;
  selectedFilters: ISelectedFilter[];
  selectedCurrency: string;
}

const InsightsLineChartsContainer = ({
  testId,
  selectedDateRange,
  selectedFilters,
  selectedCurrency,
}: IInsightsLineChartProps) => {
  const classes = useStyles();
  const { search } = useLocation();
  const urlSearchParams = useUrlSearchParams(search);
  const { setUrlParam, getUrlParam } = useUrlSearchParamUtils(urlSearchParams);

  const metricsFromUrl = getUrlParam('metrics');

  const parsedMetricsFromUrl = metricsFromUrl ? JSON.parse(metricsFromUrl) : [];

  let initiallySelectedMetrics = defaultInitialMetrics;

  if (parsedMetricsFromUrl?.length === 4) {
    initiallySelectedMetrics = parsedMetricsFromUrl.reduce(
      (acc: any, current: string, index: number) => {
        if (index < 4)
          return {
            ...acc,
            [`insightsLineChart-${index}`]: current,
          };
        return acc;
      },
      {}
    );
  }

  const [selectedMetrics, setSelectedMetrics] =
    useState<IInsightsLineGraphsSelectedMetrics>(initiallySelectedMetrics);

  useEffect(() => {
    const stringifiedMetrics = JSON.stringify(Object.values(selectedMetrics));

    setUrlParam('metrics', stringifiedMetrics);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetrics]);

  const handleMetricChange = (
    chartId: string,
    value: PublisherInsightsMetric
  ) => {
    setSelectedMetrics({
      ...selectedMetrics,
      [chartId]: value,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      data-testid={testId}
      className={classes.container}
    >
      {Object.keys(selectedMetrics).map((chartId: string) => (
        <Grid item xs={6} key={chartId}>
          <InsightsLineChart
            metric={
              selectedMetrics[
                chartId as keyof IInsightsLineGraphsSelectedMetrics
              ]
            }
            testId={chartId}
            chartId={chartId}
            onMetricChange={handleMetricChange}
            selectedDateRange={selectedDateRange}
            selectedFilters={selectedFilters}
            selectedCurrency={selectedCurrency}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InsightsLineChartsContainer;
