import * as Yup from 'yup';

import commons from 'validations/commons';

const SalesChannelFormValidation = Yup.object().shape({
  name: commons.name('Sales Channel Name'),
  agency: Yup.object()
    .shape({
      label: Yup.string().required('Agency label must be present'),
      value: Yup.string().required('Agency value must be present'),
    })
    .required('Agency is a required field')
    .nullable(),
  owner: Yup.object()
    .shape({
      label: Yup.string().required('Owner label must be present'),
      value: Yup.string().required('Owner value must be present'),
    })
    .required('Owner is a required field')
    .nullable(),
});

export default SalesChannelFormValidation;
