import gql from 'graphql-tag';

export const GET_ORDER_MINIMAL = gql`
  query OrderMinimal($id: ID!) {
    order(id: $id) {
      id
      name
      startDate
      endDate
      objective
      timeZone
      territory
      campaigns {
        id
        objective
      }
      advertiser {
        id
        iabCategories {
          code
        }
      }
      territory
      externalSystemId
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: ID!) {
    order(id: $id) {
      id
      altId
      name
      startDate
      endDate
      status
      externalId
      externalSystemId
      timeZone
      objective
      impressions
      frequencyCapCount
      frequencyCapValue
      frequencyCapTimeUnit
      advertiser {
        id
        name
        status
        agency {
          id
          name
        }
        orders {
          id
          name
        }
      }
      owner {
        id
        email
        status
      }
      campaigns {
        id
        altId
        name
        pacing
        priority
        startDate
        endDate
        status
        timeZone
        objective
        impressions
        companionImpressions
        deliveryPace
        sanities {
          id
          type
          name
        }
        owner {
          id
          email
          status
        }
        order {
          id
          advertiser {
            id
            name
          }
        }
        clickThroughRate
      }
      territory
    }
  }
`;

export const GET_ALL_ORDERS = gql`
  query AllOrders($advertiserId: ID, $territories: [Territory!]!) {
    allOrders(advertiserId: $advertiserId, territories: $territories) {
      id
      altId
      name
      startDate
      endDate
      timeZone
      status
      externalId
      externalSystemId
      objective
      impressions
      owner {
        id
        email
        status
      }
      territory
    }
  }
`;

export const GET_ALL_ORDERS_MINIMAL = gql`
  query AllOrdersMinimal($advertiserId: ID, $territories: [Territory!]!) {
    allOrders(advertiserId: $advertiserId, territories: $territories) {
      id
      name
      startDate
      endDate
      objective
      campaigns {
        id
        objective
      }
      advertiser {
        id
        iabCategories {
          code
        }
      }
      territory
      externalSystemId
    }
  }
`;
