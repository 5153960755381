import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import AdvertisersTable from 'features/direct/advertiser/components/AdvertisersTable/AdvertisersTable';
import AgencyDetails from 'features/direct/agency/components/AgencyDetails/AgencyDetails';
import {
  agencyDetailsFields,
  agencyStatusValues,
  getAgencyStatusData,
  IAgencyValues,
} from 'features/direct/agency/components/AgencyTabsForm/AgencyFormValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFieldUpdate, IFieldValidate, IFormProps } from 'interfaces';
import { Advertiser, EntityType } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

interface IAgencyProps {
  update: boolean;
  hasEditPermissions?: boolean;
  advertisers: Advertiser[];
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history?: History;
  match?: {
    params: {
      agencyId: string;
    };
  };
}

export const agencyButtons = [
  {
    text: 'Save and Review',
    url: '/agency/ID',
    update: true,
    dataTc: 'agencyReviewButton',
  },
  {
    text: 'Save and Create Advertiser',
    url: '/agency/ID/advertiser',
    update: false,
    dataTc: 'agencyCreateAdvertiserButton',
  },
  {
    text: 'Save and View All Agencies',
    url: '/agencies',
    update: false,
    dataTc: 'agencyListButton',
  },
];

const getAgencyDetailsTabErrors = createNumberOfFormErrorsFn();

const AgencyTabsForm = (props: IFormProps<IAgencyValues> & IAgencyProps) => {
  const {
    setFieldValue,
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    hasEditPermissions = true,
    history,
    match,
    advertisers,
    status,
    onFieldUpdate,
    onFieldValidate,
  } = props;

  const classes = TabStyles();

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const location = usePreviousLocation();
  useEffect(() => {
    const { entityList, tab } = location.state || 0;
    if (entityList === 'listAdvertisers') setTab(1);
    if (tab) setTab(tab);
  }, [location]);

  const isAgencyTab = selectedTab === 0;
  const isListAdvertisersTab = selectedTab === 1;
  const isAgencyActivityTab = selectedTab === 2;

  return (
    <>
      <Form className={classes.form}>
        {update && (
          <>
            <EntityProgressBar
              statusData={getAgencyStatusData(values)}
              currentStatus={values.status || ''}
              dataTc="agencyStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={agencyStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="agencyHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="agencyTabs"
          >
            <Tab
              label="Details"
              dataTc="agencyDetailsTab"
              numberOfTabErrors={getAgencyDetailsTabErrors(
                status,
                agencyDetailsFields
              )}
            />
            <Tab
              disabled={!update}
              dataTc="listAdvertisersTab"
              label="Advertisers"
            />
            <Tab
              disabled={!update}
              dataTc="agencyActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListAdvertisersTab || isAgencyActivityTab,
            },
          ])}
        >
          {isAgencyTab && <AgencyDetails {...props} />}
          {isListAdvertisersTab && history && match && advertisers && (
            <AdvertisersTable
              isEditable={hasEditPermissions}
              history={history}
              match={match}
              advertisers={advertisers}
            />
          )}
          {isAgencyActivityTab && (
            <ActivityTable
              entityType={EntityType.Agency}
              id={values.id}
              data-tc="agencyActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitAgencyButton"
          goBackTo={{
            pathname: '/agencies',
            state: location.state?.parent || {},
          }}
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          variant={FormButtonVariants.split}
          buttons={agencyButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default AgencyTabsForm;
