import dateUtils, { TimeZones } from 'utils/date';
import commons from 'validations/commons';
import { format, isValid } from 'date-fns';
import * as Yup from 'yup';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';
import { Territory } from 'interfaces/generated.types';

const ShowContractFormValidation = (territory: Territory) =>
  Yup.object().shape({
    showName: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable()
      .required('Show Name is a required field'),
    startDate: Yup.date()
      .nullable()
      .default(null)
      .required('Contract Start Date is a required field')
      .typeError('Contract Start Date should be a valid date'),
    endDate: Yup.date()
      .nullable()
      .default(null)
      .typeError('Contract End Date should be a valid date')
      .when(
        ['startDate', 'timeZone'],
        (
          startDate: Date | null,
          timeZone: TimeZones,
          schema: Yup.DateSchema
        ) => {
          if (!isValid(startDate)) {
            return schema;
          }
          return schema.test(
            'endDate',
            `Contract End Date should be after ${format(
              startDate as Date,
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )}`,
            (endDate: Date) => {
              if (!isValid(endDate)) {
                return true;
              }
              return dateUtils.isDateAfterTheOther({
                date: endDate,
                dateToCompare: startDate as Date,
                timeZone,
              });
            }
          );
        }
      ),
    directSpotCpm: commons.optionalPrice({
      messages: {
        minMessage: 'Direct Spot CPM should be higher than or equal to 0',
        maxMessage:
          'Direct Spot CPM should be lower than or equal to 999,999.99',
      },
      minimum: 0,
      maximum: 999999.99,
      fieldKey: 'directSpotCpm',
    }),
    programmaticSpotCpm: commons.optionalPrice({
      messages: {
        minMessage: 'Programmatic Spot CPM should be higher than or equal to 0',
        maxMessage:
          'Programmatic Spot CPM should be lower than or equal to 999,999.99',
      },
      minimum: 0,
      maximum: 999999.99,
      fieldKey: 'programmaticSpotCpm',
    }),
    spotRevenueShare: commons.optionalPrice({
      messages: {
        minMessage: 'Percentage should be a positive number',
        maxMessage: 'Percentage should be 100 or lower',
      },
      minimum: 0,
      maximum: 1,
      fieldKey: 'spotRevenueShare',
    }),
    hostReadRevenueShare: commons.optionalPrice({
      messages: {
        minMessage: 'Percentage should be a positive number',
        maxMessage: 'Percentage should be 100 or lower',
      },
      minimum: 0,
      maximum: 1,
      fieldKey: 'hostReadRevenueShare',
    }),
    hostReadCpm: commons.optionalPrice({
      messages: {
        minMessage: 'Host Read CPM should be higher than or equal to 0',
        maxMessage: 'Host Read CPM should be lower than or equal to 999,999.99',
      },
      minimum: 0,
      maximum: 999999.99,
      fieldKey: 'hostReadCpm',
    }),
    sponsorshipRevenueShare: commons.optionalPrice({
      messages: {
        minMessage: 'Percentage should be a positive number',
        maxMessage: 'Percentage should be 100 or lower',
      },
      minimum: 0,
      maximum: 1,
      fieldKey: 'sponsorshipRevenueShare',
    }),
  });

export default ShowContractFormValidation;
