import { IContract } from 'components/Contract/Contract';
import { isAfter, isBefore, isToday } from 'date-fns';
import { PermissionName, Territory } from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';

import dateUtils, { TimeZones } from './date';
import { getDefaultTimezoneBasedOnTerritory } from './defaultsByTerritory';
import {
  permissionExistsInList,
  userHasAtLeastOnePermission,
} from './permissions';
import { getShowName } from './shows';

export const isExpiredContract = (contract: IContract, date = new Date()) =>
  contract.endDate ? isBefore(contract.endDate, date) : false;

export const isFutureContract = (contract: IContract, date = new Date()) =>
  isAfter(contract.startDate, date);

export const isCurrentContract = (contract: IContract, date = new Date()) =>
  (isBefore(contract.startDate, date) || isToday(contract.startDate)) &&
  ((contract.endDate && isAfter(contract.endDate, date)) || !contract.endDate);

export const sortContractsByDate = (contracts: IContract[]) => {
  contracts.sort((a: IContract, b: IContract) => {
    if (a.endDate && b.endDate)
      return b.endDate.getTime() - a.endDate.getTime();
    if (a.endDate) return 1;
    if (b.endDate) return -1;
    return b.startDate.getTime() - a.startDate.getTime();
  });
};

export const classifyContracts = (
  contracts: IContract[],
  date = new Date()
) => {
  const currentContracts: any = [];
  const futureContracts: any = [];
  const expiredContracts: any = [];

  contracts.forEach((contract: IContract) => {
    if (isExpiredContract(contract, date)) {
      expiredContracts.push(contract);
    } else if (isFutureContract(contract, date)) {
      futureContracts.push(contract);
    } else if (isCurrentContract(contract, date)) {
      currentContracts.push(contract);
    }
  });

  sortContractsByDate(currentContracts);
  sortContractsByDate(futureContracts);
  sortContractsByDate(expiredContracts);

  return { currentContracts, futureContracts, expiredContracts };
};

export const spotCpmAndSpotRevenueError = (touched: any, values: any) =>
  (touched.directSpotCpm || touched.spotRevenueShare) &&
  !values.directSpotCpm &&
  !values.spotRevenueShare;

export const isContractOverlappingWithExistingContract = (
  allContracts: IContract[],
  values: any
) => {
  const overlappingContract = allContracts.find((existingContract) => {
    if (!values.startDate) return false;

    if (!existingContract.endDate) {
      if (!values.endDate) return true;

      if (isBefore(values.endDate, existingContract.startDate)) return false;

      return true;
    }

    if (existingContract.endDate) {
      if (isAfter(values.startDate, existingContract.endDate)) return false;

      if (
        values.endDate &&
        isBefore(values.endDate, existingContract.startDate)
      )
        return false;

      return true;
    }

    return false;
  });

  return !!overlappingContract;
};

export const getContractTerritory = (entity: any, activeTerritory: Territory) =>
  entity.territories.length === 1 ? entity.territories[0] : activeTerritory;

export const getContractTimezone = (entity: any, activeTerritory: Territory) =>
  getDefaultTimezoneBasedOnTerritory(
    getContractTerritory(entity, activeTerritory)
  );

export const formatContracts = memoizeOne((data: any, timeZone: TimeZones) =>
  data.map((contract: any) => ({
    ...contract,
    startDate: dateUtils.getDateInSpecificTimezone(
      contract.startDate,
      timeZone
    ),
    endDate: contract.endDate
      ? dateUtils.getDateInSpecificTimezone(contract.endDate, timeZone)
      : null,
    customHeader: contract.show ? getShowName(contract.show) : null,
  }))
);

export const hasPublisherContractEditPermissions = (userPermissions: any) =>
  userHasAtLeastOnePermission(userPermissions, [
    PermissionName.Primary,
    PermissionName.PublisherContract,
  ]);

export const hasPublisherContractReadPermissions = (userPermissions: any) =>
  permissionExistsInList(userPermissions, PermissionName.PublisherContractRead);
