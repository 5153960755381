import { IParameterValueOption } from 'features/targetingV2/types/common';
import {
  ILocationParameterGroup,
  IParameter,
} from 'features/targetingV2/types/targeting';
import {
  LocationGroupAttributes,
  LocationParameterAttributes,
  LocationParameterType,
  LocationParameterValueAttribute,
} from 'interfaces/generated.types';

import { isTargetingParameterInvalid } from './common';

export const getLocationParameterValuesAttributes = (
  currentParameter: IParameter
) =>
  currentParameter.values.map(
    (currentParameterValue: IParameterValueOption) => ({
      id: currentParameterValue.value,
    })
  );

export const getLocationParametersAttributes = (parameters: IParameter[]) =>
  parameters.reduce(
    (
      parametersAttributesResult: LocationParameterAttributes[],
      currentParameter: IParameter
    ) => {
      const currentParameterWithValidValues = {
        type: currentParameter.type as LocationParameterType,
        clusivity: currentParameter.clusivity,
        values: getLocationParameterValuesAttributes(currentParameter),
      };
      const parameterAttributes = isTargetingParameterInvalid(
        currentParameterWithValidValues
      )
        ? null
        : currentParameterWithValidValues;

      if (parameterAttributes)
        parametersAttributesResult.push(
          parameterAttributes as LocationParameterAttributes
        );

      return parametersAttributesResult;
    },
    []
  );

export const getLocationsAttributes = (locations: ILocationParameterGroup[]) =>
  locations.reduce(
    (
      locationsAttributesResult: LocationGroupAttributes[],
      currentLocation: ILocationParameterGroup
    ) => {
      const locationAttributes: LocationGroupAttributes = {
        limitLocationParameterValue:
          currentLocation.limitLocationParameterValue as LocationParameterValueAttribute,
        ...(currentLocation.accuracyRadius !== -1 && {
          accuracyRadius: currentLocation.accuracyRadius,
        }),
        parameters: getLocationParametersAttributes(currentLocation.parameters),
      };

      locationsAttributesResult.push(locationAttributes);
      return locationsAttributesResult;
    },
    []
  );

export const getLocationTargetingAttributes = (formValues: any) => {
  if (
    !formValues?.targetingDefinitionV2?.locationTargeting?.locations ||
    !formValues.targetingDefinitionV2.locationTargeting.locations.length
  )
    return null;

  const locationsAttributes = getLocationsAttributes(
    formValues.targetingDefinitionV2.locationTargeting.locations
  );

  return !locationsAttributes.length
    ? null
    : {
        locations: locationsAttributes,
      };
};
