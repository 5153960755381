import classNames from 'classnames';
import Container from 'components/Container/Container';
import Tab from 'components/Tab/Tab';
import TargetingPostcodePacksTab from 'features/targeting/containers/TargetingPostcodePacksTab/TargetingPostcodePacksTab';
import TargetingRegionPacksTab from 'features/targeting/containers/TargetingRegionPacksTab/TargetingRegionPacksTab';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import React, { useEffect, useState } from 'react';
import { handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

import useStyles from './TargetingPacksPage.styles';

interface ITargetingPacksPageProps {
  history: History;
}

const TargetingPacksPage = ({ history }: ITargetingPacksPageProps) => {
  const classes = useStyles();

  const [selectedTab, setTab] = useState(0);

  const location = usePreviousLocation();

  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isPostcodeTab = selectedTab === 0;
  const isSubRegionTab = selectedTab === 1;

  return (
    <main className={classes.page}>
      <Container>
        <h1 className="sr-only">Targeting Packs</h1>
        <div className={classes.table}>
          <h2 className={classes.title}>Targeting Packs</h2>

          <>
            <AppBar position="static" classes={{ root: classes.bar }}>
              <Tabs
                value={selectedTab}
                onChange={(e, index) => {
                  setTab(index);
                  history && handleTabHistory(history, index);
                }}
                data-tc="targetingPacksTabs"
              >
                <Tab dataTc="postcodePacksTab" label="Postcodes" />
                <Tab dataTc="subRegionPacksTab" label="Regions" />
              </Tabs>
            </AppBar>
            <div
              className={classNames([
                classes.formView,
                [`${classes.formView}--table`],
              ])}
            >
              {isPostcodeTab && <TargetingPostcodePacksTab history={history} />}
              {isSubRegionTab && <TargetingRegionPacksTab history={history} />}
            </div>
          </>
        </div>
      </Container>
    </main>
  );
};

export default TargetingPacksPage;
