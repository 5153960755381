import * as Yup from 'yup';

import commons from 'validations/commons';

const AdvertiserFormValidation = Yup.object().shape({
  name: commons.name('Advertiser Name'),
  externalId: commons.max255Characters(),
  externalSystemId: commons.max255Characters(),
  domains: commons.domains({
    fieldKey: 'domains',
    message: 'Only a valid domain is allowed',
  }),
  adSeparation: commons.optionalPrice({
    messages: {
      minMessage: 'Minimum ad separation is 0 seconds',
      maxMessage: 'Maximum ad separation is 300 seconds',
    },
    minimum: 0,
    maximum: 300,
    fieldKey: 'adSeparation',
  }),
});

export default AdvertiserFormValidation;
