import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  content: {
    padding: theme.spacing(3, 3, 0),
    overflowX: 'hidden',
  },
  modalButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    color: theme.palette.primary.main,
    '&:focus': {
      outline: 'none',
    },
  },
  modalImage: {
    maxWidth: '100%',
  },
  descriptionList: {
    margin: 0,
    minWidth: '200px',
    fontSize: '0.813em',
    paddingRight: theme.spacing(3),
  },
  descriptionTitle: {
    fontWeight: 700,
  },
  descriptionContent: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  audioWrapper: {
    width: '400px',
  },
}));

export default useStyles;
