import React from 'react';
import { ColumnInstance } from 'react-table';

import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { ReactComponent as FilterIcon } from 'assets/icons/dax-filtericon.svg';

import { useSelectFilterStyles } from 'components/Table/Table.styles';

export interface ISelectFilterColumnType extends ColumnInstance {
  selectOptions: string[];
}

export interface ITableSelectFilter {
  column: ISelectFilterColumnType;
}

interface IColumnSelectProps {
  children: React.ReactNode;
  filterValue?: any;
  setFilter?(updater: any): void;
  text?: string;
}

export const ColumnSelect = ({
  children,
  filterValue,
  setFilter,
  text,
}: IColumnSelectProps) => {
  const classes = useSelectFilterStyles();
  return (
    <Select
      classes={classes}
      startAdornment={
        <InputAdornment position="start">
          <FilterIcon />
        </InputAdornment>
      }
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      multiple
      fullWidth
      value={filterValue || ['']}
      onChange={(e: any) => {
        setFilter &&
          setFilter(e.target.value.filter((val: string) => !!val) || undefined);
      }}
      input={<Input />}
      renderValue={(selected: any) =>
        text ||
        `${
          (selected as string[]).filter((item: string) => item !== '').length
        } filters`
      }
    >
      {children}
    </Select>
  );
};

const TableSelectFilter = ({
  column: { filterValue, setFilter, selectOptions = [] },
}: ITableSelectFilter) => (
  <ColumnSelect {...{ filterValue, setFilter }}>
    {selectOptions.map((option: any, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <MenuItem dense key={i} value={option}>
        <Checkbox checked={filterValue?.indexOf(option) > -1} />
        <ListItemText primary={option} />
      </MenuItem>
    ))}
  </ColumnSelect>
);

export default TableSelectFilter;
