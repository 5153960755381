interface IVolume {
  value: number;
  label: string;
}

/**
 * Generates an iterable of objects to display a valid list of volumes.
 * @param start the volume (in dB) to start from.
 * @param end the volume (in dB) to end at.
 */
export function* generateVolumeTypes(
  start: number = -5,
  end: number = -70
): IterableIterator<IVolume> {
  for (let volume: number = start; volume >= end; volume -= 1) {
    yield {
      value: volume,
      label: `${volume} LUFS`,
    };
  }
}

export function* generatePriorityTypes(
  start: number = 1,
  end: number = 9
): IterableIterator<IVolume> {
  for (let volume: number = start; volume <= end; volume += 1) {
    let label;
    switch (volume) {
      case start: {
        label = `${volume} - highest priority`;
        break;
      }
      case end: {
        label = `${end} - lowest priority`;
        break;
      }
      default: {
        label = `${volume}`;
        break;
      }
    }
    yield {
      value: volume,
      label,
    };
  }
}

export function* generateAdMidRollPositions(
  start: number = 1,
  end: number = 10
): IterableIterator<any> {
  for (let position: number = start; position <= end; position += 1) {
    yield {
      value: position,
      label: `Mid-roll position ${position}`,
    };
  }
}

export function* generateMaxImpressionsPerBid(
  start: number = 1,
  end: number = 8
): IterableIterator<IVolume> {
  for (let impression: number = start; impression <= end; impression += 1) {
    yield {
      value: impression,
      label: `${impression}`,
    };
  }
}
