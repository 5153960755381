import React from 'react';

import Loader from 'components/Loader/Loader';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';

import { IFormProps } from 'interfaces';
import { useAllCountriesQuery } from 'interfaces/generated.types';

import locationUtils from 'utils/location';
import { ITargetingComponentProps } from 'utils/targetingDefinitions';

export const countryValues = {
  countryParams: {
    countries: [],
  },
};

const TargetingCountry = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const { loading, data } = useAllCountriesQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <TargetingMultiSelect
      {...props}
      label="Country"
      targetingName="countryParams.countries"
      options={
        data && data.allCountries
          ? locationUtils.createCountryGroup(data.allCountries)
          : []
      }
    />
  );
};

export default TargetingCountry;
