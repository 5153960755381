import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';

import { networkStatusValues } from 'features/inventory/network/components/NetworkTabsForm/NetworkFormValues';
import { GET_ALL_NETWORKS } from 'features/inventory/network/graphql/queries';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  EntityType,
  Network,
  NetworkStatus,
  useUpdateNetworkMutation,
  Territory,
} from 'interfaces/generated.types';

import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

interface INetworksRow {
  id: string;
  altId: number;
  name: string;
  status: NetworkStatus;
}

export interface INetworksTableProps {
  history: History;
  allNetworks: Network[];
  isEditable: boolean;
  activeTerritory: Territory;
}

export const formatData = memoizeOne((data: Network[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const NetworksTable = ({
  allNetworks,
  isEditable,
  history,
  activeTerritory,
}: INetworksTableProps) => {
  const [updateNetwork] = useUpdateNetworkMutation({
    refetchQueries: [
      {
        query: GET_ALL_NETWORKS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });
  const validateNetwork = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createNetworkCta = isEditable && (
    <StyledLink
      location={{
        pathname: '/network',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createNetworkButton"
    >
      Create Network
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<INetworksRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/network/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<INetworksRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: networkStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={networkStatusValues}
                name="networkStatusSelect"
                dataTc="networkStatusSelect"
              />
            )}
            onCellValidate={onCellValidate}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <Table
      history={history}
      columns={columns}
      data={formatData(allNetworks)}
      isEditable={isEditable}
      dataTc="listNetworksTable"
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateNetwork,
          entity: { ...entity, type: EntityType.Network },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      onCellUpdate={(row: INetworksRow, setErrorModal, setUpdating) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            status: row.status,
          },
          update: updateNetwork,
          handleContinue: () => history.push(`/network/${row.id}`),
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Network',
          },
        })
      }
      customToolbarCtas={createNetworkCta}
    />
  );
};

export default NetworksTable;
