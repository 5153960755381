import { addSeconds, isAfter, parseISO } from 'date-fns';
import shortid from 'shortid';

import { GENERATE_CREATIVE_URL } from 'features/direct/creative/graphql/queries';

import { getCreativeFileName } from './urls';

const isValidCreativeUrl = (url: string) => {
  const urlParams = new URLSearchParams(url);
  const amzDateString = urlParams.get('X-Amz-Date') || '';
  const amxExpiryString = urlParams.get('X-Amz-Expires') || '0';
  const amzDate = parseISO(amzDateString);
  const expiryDate = addSeconds(amzDate, parseInt(amxExpiryString, 10));

  return isAfter(expiryDate, new Date());
};

const generateCreativeUrl = async ({
  advertiserId,
  file,
  client,
}: {
  advertiserId: string;
  file: File;
  client: any;
}) => {
  const { name, type, size } = file;
  const { data } = await client.query({
    query: GENERATE_CREATIVE_URL,
    variables: {
      contentType: type,
      fileName: `${advertiserId}/${shortid.generate()}-${name}`,
      fileSize: size,
      httpMethod: 'PUT',
    },
  });

  const { url, assetUrl, contentType, uploadedDate } = data
    ? data.generatePreSignedUrl
    : {
        url: '',
        assetUrl: '',
        contentType: '',
        uploadedDate: '',
      };

  return { url, assetUrl, contentType, uploadedDate };
};

const fetchCreativeUrl = async ({
  targetUrl,
  client,
}: {
  targetUrl: string;
  client: any;
}) => {
  const { data } = await client.query({
    query: GENERATE_CREATIVE_URL,
    variables: {
      fileName: getCreativeFileName(targetUrl),
      httpMethod: 'GET',
    },
  });

  const { url, assetUrl, contentType } = data
    ? data.generatePreSignedUrl
    : {
        url: '',
        assetUrl: '',
        contentType: '',
      };

  return { url, assetUrl, contentType };
};

export default {
  generateCreativeUrl,
  fetchCreativeUrl,
  isValidCreativeUrl,
};
