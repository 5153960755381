import { makeStyles, Theme } from '@material-ui/core/styles';
import { baseFontStyles } from 'assets/styles/components/Font.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseFontStyles(),
  signInContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  signInForm: {
    marginBottom: theme.spacing(1.5),
  },
  error: {
    margin: theme.spacing(1, 0),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0, 4),
  },
  button: {
    width: '96px',
    height: '32px',
    borderColor: theme.palette.grey[800],
    '&--submit': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
  },
}));

export default useStyles;
