import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  sanitiesWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
    width: '100%',
    '&--oneSanity': {
      justifyContent: 'center',
    },
  },
  icon: {
    maxWidth: theme.spacing(5),
    maxHeight: theme.spacing(5),
    verticalAlign: 'middle',
  },
}));

export default useStyles;
