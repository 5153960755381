import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';
import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNav';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import { IAgencyValues } from 'features/direct/agency/components/AgencyTabsForm/AgencyFormValues';
import AgencyTabsForm, {
  agencyButtons,
} from 'features/direct/agency/components/AgencyTabsForm/AgencyTabsForm';
import AgencyFormValidation from 'features/direct/agency/validations/agencies';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  Agency,
  EntityType,
  UpdateAgencyMutationFn,
  useUpdateAgencyMutation,
} from 'interfaces/generated.types';

import { createDirectBreadcrumbConfig } from 'utils/breadcrumbConfigFactory';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface IUpdateAgencyContainerProps {
  history: History;
  agency: Agency;
  hasEditPermissions: boolean;
  match: {
    params: {
      agencyId: string;
    };
  };
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });

export const submitUpdateAgency = (
  updateAgency: UpdateAgencyMutationFn,
  initialValues: IAgencyValues,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IAgencyValues,
  { setStatus, setSubmitting }: FormikActions<IAgencyValues>
) => {
  setSelectedJourney(formValues.journey);

  updateAgency({
    variables: {
      id: formValues.id,
      ...differenceInValues(
        { ...formValues, journey: null },
        { ...initialValues, journey: null }
      ),
    },
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateAgencyContainer = ({
  agency,
  history,
  hasEditPermissions,
  match,
}: IUpdateAgencyContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Agency.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateAgency] = useUpdateAgencyMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, agency.id);
    },
  });
  const validateAgency = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    name: agency.name as string,
    altId: agency.altId,
    id: agency.id,
    externalId: agency.externalId ? agency.externalId : '',
    externalSystemId: agency.externalSystemId ? agency.externalSystemId : '',
    journey: agencyButtons[0].url,
    status: agency.status,
    territory: agency.territory,
  };

  const breadcrumbConfig = createDirectBreadcrumbConfig(agency);

  return (
    <>
      <BreadcrumbNav config={breadcrumbConfig} />
      <Formik<IAgencyValues>
        initialValues={initialValues}
        validationSchema={AgencyFormValidation}
        onSubmit={submitUpdateAgency(
          updateAgency,
          initialValues,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <AgencyTabsForm
            {...formikProps}
            update
            hasEditPermissions={hasEditPermissions}
            match={match}
            history={history}
            advertisers={agency?.advertisers || []}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateAgency,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Agency,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateAgency,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateAgency"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateAgencyWarningDialog"
      />
    </>
  );
};

export default UpdateAgencyContainer;
