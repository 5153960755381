import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IDatacenterValues } from 'features/programmatic/datacenter/components/DatacenterTabsForm/DatacenterFormValues';
import DatacenterTabsForm, {
  datacenterButtons,
} from 'features/programmatic/datacenter/components/DatacenterTabsForm/DatacenterTabsForm';
import DataCenterFormValidation from 'features/programmatic/datacenter/validations/datacenters';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  CreateDataCenterMutation,
  CreateDataCenterMutationFn,
  DataCenter,
  TechnicalProvider,
  useCreateDataCenterMutation,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { getDefaultRegionBasedOnTerritory } from 'utils/defaultsByTerritory';
import { formatValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface ICreateDatacenterContainerProps {
  history: History;
  technicalProvider: TechnicalProvider;
  match: { params: { technicalProviderId: string } };
}

export const handleOnComplete = async (
  response: CreateDataCenterMutation,
  history: History,
  selectedJourney: string,
  technicalProviderId: string
) => {
  const { createDataCenter } = response;
  const { id } = createDataCenter as DataCenter;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: technicalProviderId,
    listEntity:
      selectedJourney === '/programmatic-connector/PARENT_ID'
        ? 'Datacenter'
        : '',
  });
};

export const submitCreateDatacenter = (
  createDataCenter: CreateDataCenterMutationFn,
  technicalProviderId: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: IDatacenterValues,
  { setStatus, setSubmitting }: FormikActions<IDatacenterValues>
) => {
  setSelectedJourney(formValues.journey);

  const formattedValues = formatValues(formValues);

  const filteredValues: any = pickBy({
    ...formValues,
    maxQps: formattedValues.maxQps,
    journey: null,
    technicalProviderId,
    technicalProviderName: null,
  });
  createDataCenter({
    variables: filteredValues,
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const CreateDatacenterContainer = ({
  technicalProvider,
  history,
  match,
}: ICreateDatacenterContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Datacenter.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createDataCenter] = useCreateDataCenterMutation({
    onCompleted(data) {
      handleOnComplete(
        data,
        history,
        selectedJourney,
        match.params.technicalProviderId
      );
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IDatacenterValues>
        initialValues={{
          id: '',
          name: '',
          region: getDefaultRegionBasedOnTerritory(activeTerritory!),
          bidUrl: '',
          maxQps: '',
          journey: datacenterButtons[0].url,
          technicalProviderId: technicalProvider.id,
          technicalProviderName: technicalProvider.name as string,
        }}
        validationSchema={DataCenterFormValidation}
        onSubmit={submitCreateDatacenter(
          createDataCenter,
          match.params.technicalProviderId,
          toggleErrorModal,
          setSelectedJourney
        )}
        component={(props) => (
          <DatacenterTabsForm
            {...props}
            goBackTo={{
              pathname: `/programmatic-connector/${match.params.technicalProviderId}`,
              state: location.state?.parent || {},
            }}
            history={history}
          />
        )}
      />
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createDatacenter"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateDatacenterContainer;
