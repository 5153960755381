import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import CreateNetworkContainer from 'features/inventory/network/containers/CreateNetworkContainer/CreateNetworkContainer';

interface ICreateNetworkPage {
  history: History;
}
const CreateNetworkPage = ({ history }: ICreateNetworkPage) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Network</h1>
      <CreateNetworkContainer history={history} />
    </Container>
  </main>
);

export default CreateNetworkPage;
