import DatePicker from 'components/DateTimePicker/DatePicker';
import FormButtons from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import NumberInput, {
  SuffixTypes,
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';
import { endOfDay, startOfDay } from 'date-fns';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import {
  PublisherCategory,
  Territory,
  useAllPublisherCategoriesQuery,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { spotCpmAndSpotRevenueError } from 'utils/contracts';
import {
  CurrenciesEnum,
  currencyValues,
  getPrefixBySelectedCurrency,
} from 'utils/currency';
import dateUtils, { TimeZones } from 'utils/date';
import { getPublisherCategoryOptions } from 'utils/publisherCategories';

import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';

import useStyles from './PublisherContractForm.styles';

export interface IPublisherContractFormProps {
  update?: boolean;
  hasEditPermissions?: boolean;
  handleClose: () => void;
  readOnly?: boolean;
  testId?: string;
}

export interface IPublisherContractValues {
  id: string;
  altId?: number;
  publisherId?: string;
  startDate: Date;
  endDate: Date | null;
  timeZone: TimeZones;
  publisherCategories: OptionType[];
  currency: CurrenciesEnum;
  directSpotCpm: string | null;
  programmaticSpotCpm: string | null;
  spotRevenueShare: string | null;
  hostReadRevenueShare: string | null;
  hostReadCpm: string | null;
  sponsorshipRevenueShare: string | null;
  minInventoryGuaranteed: string | null;
  maxInventoryGuaranteed: string | null;
  percentFillGuaranteed: string | null;
  minRevenueGuaranteed: string | null;
  minRevenueIdeal: string | null;
  territories?: Territory[];
  notes: string;
}

const PublisherContractForm = (
  props: IFormProps<IPublisherContractValues> & IPublisherContractFormProps
) => {
  const {
    handleSubmit,
    values,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    errors,
    touched,
    status = {},
    update = false,
    isSubmitting,
    isValid,
    dirty,
    hasEditPermissions = false,
    handleClose,
    readOnly = false,
    testId = 'publisherContractForm',
  } = props;

  const classes = useStyles();

  const { loading, data, error } = useAllPublisherCategoriesQuery();

  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  return (
    <>
      <Form role="form" data-testid={testId}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl component={'fieldset' as 'div'} fullWidth>
              <DatePicker
                initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
                value={values.startDate}
                label="Contract Start Date"
                name="startDate"
                onChange={(value) =>
                  setFieldValue('startDate', startOfDay(value))
                }
                onClose={() => setFieldTouched('startDate', true)}
                data-tc="contractStartDate"
                data-testid="contractStartDate"
                disabled={readOnly}
              />
              {((touched.startDate && errors.startDate) ||
                status.startDate) && (
                <FormHelperText error data-tc="publisherContractStartDateError">
                  {errors.startDate || status.startDate}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl component={'fieldset' as 'div'} fullWidth>
              <DatePicker
                initialFocusedDate={dateUtils.getInitialFocusedStartDate()}
                value={values.endDate}
                label="Contract End Date (optional)"
                name="endDate"
                onChange={(value) => setFieldValue('endDate', endOfDay(value))}
                onClose={() => setFieldTouched('endDate', true, false)}
                data-tc="contractEndDate"
                data-testid="contractEndDate"
                disabled={!hasEditPermissions}
              />
              {((touched.endDate && errors.endDate) ||
                (touched.startDate && errors.endDate) ||
                status.endDate) && (
                <FormHelperText error data-tc="publisherContractEndDateError">
                  {errors.endDate || status.endDate}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <MultiSelect
              dataTc="publisherCategoriesMultiSelect"
              id="publisherCategoriesMultiSelect"
              label="Publisher Categories"
              isMulti
              maxSelectHeight={250}
              name="publisherCategories"
              noMargin
              onBlur={() => setFieldTouched('publisherCategories', true)}
              onChange={(fieldValue: any) => {
                setFieldValue('publisherCategories', fieldValue);
              }}
              options={getPublisherCategoryOptions(
                (data?.allPublisherCategories as PublisherCategory[]) || []
              )}
              placeholder="Select categories..."
              value={values.publisherCategories}
              attributes={{
                fieldAttribute: 'publisherCategoriesField',
                menuAttribute: 'publisherCategoriesMenuOptions',
                chipAttribute: 'publisherCategoryChip',
              }}
              errorProps={{
                FormHelperTextProps: {
                  error: false,
                },
              }}
              isDisabled={readOnly}
              isLoading={loading}
            />
            {!loading && error && (
              <FormHelperText error data-tc="publisherCategoriesError">
                There was an error retrieving publisher categories
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <Field
              component={TextField}
              data-tc="currency"
              data-testid="currency"
              fullWidth
              label="Contract Currency"
              name="currency"
              id="currency"
              select
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                },
              }}
              disabled={readOnly}
            >
              {currencyValues
                .filter((option) => option.value !== CurrenciesEnum.CAD)
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractDirectSpotCpm"
              dataTestId="publisherContractDirectSpotCpm"
              decimalScale={2}
              label="Direct Spot CPM"
              name="directSpotCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.directSpotCpm || ''}
              fullWidth
              touched={touched.directSpotCpm || !!status.directSpotCpm}
              error={
                errors.directSpotCpm ||
                status.directSpotCpm ||
                spotCpmAndSpotRevenueError(touched, values)
              }
              disabled={readOnly || !!values.spotRevenueShare}
            />
            {spotCpmAndSpotRevenueError(touched, values) && (
              <FormHelperText
                error
                data-tc="publisherContractDirectSpotCpmError"
              >
                You must enter either the Direct Spot CPM or Spot Revenue Share
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractProgrammaticSpotCpm"
              dataTestId="publisherContractProgrammaticSpotCpm"
              decimalScale={2}
              label="Programmatic Spot CPM"
              name="programmaticSpotCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.programmaticSpotCpm || ''}
              fullWidth
              touched={
                touched.programmaticSpotCpm || !!status.programmaticSpotCpm
              }
              error={errors.programmaticSpotCpm || status.programmaticSpotCpm}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractSpotRevenueShare"
              dataTestId="publisherContractSpotRevenueShare"
              decimalScale={2}
              label="Spot Revenue Share"
              name="spotRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.spotRevenueShare || ''}
              fullWidth
              touched={touched.spotRevenueShare || !!status.spotRevenueShare}
              error={
                errors.spotRevenueShare ||
                status.spotRevenueShare ||
                spotCpmAndSpotRevenueError(touched, values)
              }
              disabled={readOnly || !!values.directSpotCpm}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractHostReadRevenueShare"
              dataTestId="publisherContractHostReadRevenueShare"
              decimalScale={2}
              label="Host Read Revenue Share"
              name="hostReadRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hostReadRevenueShare || ''}
              fullWidth
              touched={
                touched.hostReadRevenueShare || !!status.hostReadRevenueShare
              }
              error={errors.hostReadRevenueShare || status.hostReadRevenueShare}
              disabled={readOnly || !!values.hostReadCpm}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractHostReadCpm"
              dataTestId="publisherContractHostReadCpm"
              decimalScale={2}
              label="Host Read CPM"
              name="hostReadCpm"
              prefix={getPrefixBySelectedCurrency(values.currency)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hostReadCpm || ''}
              fullWidth
              touched={touched.hostReadCpm || !!status.hostReadCpm}
              error={errors.hostReadCpm || status.hostReadCpm}
              disabled={readOnly || !!values.hostReadRevenueShare}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              allowNegative={false}
              dataTc="publisherContractSponsorshipRevenueShare"
              dataTestId="publisherContractSponsorshipRevenueShare"
              decimalScale={2}
              label="Sponsorship Revenue Share"
              name="sponsorshipRevenueShare"
              suffix={SuffixTypes.percentage}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.sponsorshipRevenueShare || ''}
              fullWidth
              touched={
                touched.sponsorshipRevenueShare ||
                !!status.sponsorshipRevenueShare
              }
              error={
                errors.sponsorshipRevenueShare || status.sponsorshipRevenueShare
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid container className={classes.outerContainer}>
            <Grid item xs={12}>
              <Typography>Monthly Commitments</Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.innerContainer}
              data-testid="monthlyCommitmentsContainer"
            >
              <Grid item xs={4}>
                <NumberInput
                  allowNegative={false}
                  dataTc="publisherContractMinInventoryGuaranteed"
                  dataTestId="publisherContractMinInventoryGuaranteed"
                  decimalScale={0}
                  label="Minimum Inventory Guarantee"
                  name="minInventoryGuaranteed"
                  thousandSeparator={ThousandSeparatorTypes.comma}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minInventoryGuaranteed || ''}
                  fullWidth
                  touched={
                    touched.minInventoryGuaranteed ||
                    !!status.minInventoryGuaranteed
                  }
                  error={
                    errors.minInventoryGuaranteed ||
                    status.minInventoryGuaranteed
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  allowNegative={false}
                  dataTc="publisherContractMaxInventoryGuaranteed"
                  dataTestId="publisherContractMaxInventoryGuaranteed"
                  decimalScale={0}
                  label="Maximum Inventory Guarantee"
                  name="maxInventoryGuaranteed"
                  thousandSeparator={ThousandSeparatorTypes.comma}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxInventoryGuaranteed || ''}
                  fullWidth
                  touched={
                    touched.maxInventoryGuaranteed ||
                    !!status.maxInventoryGuaranteed
                  }
                  error={
                    errors.maxInventoryGuaranteed ||
                    status.maxInventoryGuaranteed
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  allowNegative={false}
                  dataTc="publisherContractPercentFillGuaranteed"
                  dataTestId="publisherContractPercentFillGuaranteed"
                  decimalScale={2}
                  label="% Fill Guarantee"
                  name="percentFillGuaranteed"
                  suffix={SuffixTypes.percentage}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.percentFillGuaranteed || ''}
                  fullWidth
                  touched={
                    touched.percentFillGuaranteed ||
                    !!status.percentFillGuaranteed
                  }
                  error={
                    errors.percentFillGuaranteed || status.percentFillGuaranteed
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  allowNegative={false}
                  dataTc="publisherContractMinRevenueGuaranteed"
                  dataTestId="publisherContractMinRevenueGuaranteed"
                  decimalScale={0}
                  label="Minimum Revenue Guarantee"
                  name="minRevenueGuaranteed"
                  thousandSeparator={ThousandSeparatorTypes.comma}
                  prefix={getPrefixBySelectedCurrency(values.currency)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minRevenueGuaranteed || ''}
                  fullWidth
                  touched={
                    touched.minRevenueGuaranteed ||
                    !!status.minRevenueGuaranteed
                  }
                  error={
                    errors.minRevenueGuaranteed || status.minRevenueGuaranteed
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  allowNegative={false}
                  dataTc="publisherContractMinRevenueIdeal"
                  dataTestId="publisherContractMinRevenueIdeal"
                  decimalScale={0}
                  label="Ideal Revenue"
                  name="minRevenueIdeal"
                  thousandSeparator={ThousandSeparatorTypes.comma}
                  prefix={getPrefixBySelectedCurrency(values.currency)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minRevenueIdeal || ''}
                  fullWidth
                  touched={touched.minRevenueIdeal || !!status.minRevenueIdeal}
                  error={errors.minRevenueIdeal || status.minRevenueIdeal}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={TextField}
              label="Notes"
              data-tc="notesField"
              multiline
              rows={4}
              name="notes"
              inputProps={{
                maxLength: 1000,
                'aria-label': 'Notes',
              }}
              value={values.notes || ''}
              disabled={readOnly}
            />
            {values.notes.length === 1000 && (
              <p className={classes.error}>
                You have reached the max character length of 1000
              </p>
            )}
          </Grid>
        </Grid>
        <FormButtons
          dataTc="submitPublisherContractButton"
          disabled={
            !isValid ||
            !dirty ||
            isSubmitting ||
            !hasEditPermissions ||
            spotCpmAndSpotRevenueError(touched, values)
          }
          onClick={() => {
            handleSubmit();
            setFormSubmitting(true);
          }}
          isLoading={isSubmitting}
          handleCloseModal={handleClose}
        >
          {update ? 'Update and Save Contract' : 'Create and Save Contract'}
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default PublisherContractForm;
