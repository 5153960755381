import { targetingLocationAccuracyValues } from 'features/targeting/components/TargetingParameters/TargetingParametersValues';
import { FrequencyCapTimeUnit } from 'interfaces/generated.types';
import { capitalize } from 'lodash';
import { CurrenciesEnum } from 'utils/currency';
import { parseCurrencyToNumber, parseFormattedValue } from 'utils/numbers';
import isFQDN from 'validator/lib/isFQDN';
import isURL from 'validator/lib/isURL';
import * as Yup from 'yup';

export const keyValueValidation = (keyLabel: string) => {
  const capitalizedKeyLabel = capitalize(keyLabel);
  return Yup.object().shape(
    {
      key: Yup.string()
        .required(`A '${keyLabel}' and 'value' are both required`)
        .matches(
          /^(?!(?:or|and|not)$).*$/i,
          'The following words cannot be used as a key: "or", "and", "not"'
        )
        .max(20, `The '${keyLabel}' must not exceed 20 characters`)
        .matches(/^[A-Za-z]([a-zA-Z0-9-._])*$/, {
          excludeEmptyString: true,
          message: `${capitalizedKeyLabel}s must start with a letter and may only contain numbers, letters, "-", "." and "_"`,
        }),
      value: Yup.string().when('key', {
        is: (key: string) => !!key,
        then: Yup.string()
          .required(`A '${keyLabel}' and 'value' are both required`)
          .max(36, "The 'value' must not exceed 36 characters")
          .matches(/^[a-zA-Z0-9-._& ()/]*$/, {
            excludeEmptyString: true,
            message: `Values must start with a letter and may only contain numbers, letters, "-", ".", "_", "&", " ", "(", ")" and "/"`,
          }),
      }),
    },
    ['key', 'value'] as any
  );
};

const commons = {
  name: (startOfMessage: string) =>
    Yup.string()
      .trim()
      .min(1, `${startOfMessage} needs to be more than 1 character`)
      .max(255, 'Only a maximum of 255 characters are allowed')
      .required(`${startOfMessage} is a required field`),
  requiredPrice: ({
    messages: { required, minMessage, maxMessage },
    minimum,
    maximum,
    fieldKey,
  }: {
    messages: {
      required: string;
      minMessage: string;
      maxMessage: string;
    };
    minimum: number;
    maximum: number;
    fieldKey: string;
  }) =>
    Yup.string()
      .trim()
      .required(required)
      .test(fieldKey, minMessage, (value: string) => {
        const numberValue = parseFormattedValue(value);
        return numberValue >= minimum;
      })
      .test(fieldKey, maxMessage, (value: string) => {
        const numberValue = parseFormattedValue(value);
        return numberValue <= maximum;
      }),
  optionalPrice: ({
    messages: { minMessage, maxMessage },
    minimum,
    maximum,
    fieldKey,
  }: {
    messages: {
      minMessage: string;
      maxMessage: string;
    };
    minimum: number;
    maximum: number;
    fieldKey: string;
  }) =>
    Yup.string()
      .trim()
      .test(fieldKey, minMessage, (value: string) => {
        if (!value) {
          return true;
        }
        const numberValue = parseCurrencyToNumber(value);

        return numberValue >= minimum;
      })
      .test(fieldKey, maxMessage, (value: string) => {
        if (!value) {
          return true;
        }

        const numberValue = parseCurrencyToNumber(value);

        return numberValue <= maximum;
      }),
  minimumPrice: ({
    message,
    minimum,
    fieldKey,
  }: {
    message: string;
    minimum: number;
    fieldKey: string;
  }) =>
    Yup.string()
      .trim()
      .test(fieldKey, message, (value: string) => {
        if (!value) {
          return true;
        }
        const numberValue = parseCurrencyToNumber(value);

        return numberValue >= minimum;
      }),
  max255Characters: () =>
    Yup.string()
      .max(255, 'Only a maximum of 255 characters are allowed')
      .nullable(),
  domains: ({ fieldKey, message }: { fieldKey: string; message: string }) =>
    Yup.array().of(
      Yup.string()
        .trim()
        .test(fieldKey, message, (value: string) => {
          if (!value) {
            return true;
          }
          return isFQDN(value);
        })
    ),
  validUrl: ({
    message,
    fieldKey,
    protocols = ['https', 'http'],
    requiredProtocol = true,
  }: {
    message: string;
    fieldKey: string;
    protocols?: string[];
    requiredProtocol?: boolean;
  }) =>
    Yup.string()
      .trim()
      .test(fieldKey, message, (value: string) => {
        if (!value) {
          return true;
        }
        return isURL(value, {
          protocols,
          require_protocol: requiredProtocol,
        });
      })
      .nullable(),
  audienceParams: (startOfMessage: string) =>
    Yup.array().of(
      Yup.object().shape({
        keyValueParams: Yup.object().shape({
          keyValues: Yup.array().of(keyValueValidation('name')),
        }),
        listenerAccuracy: Yup.object().shape({
          value: Yup.string().test(
            'listenerAccuracy',
            'Accuracy must be a value from dropdown',
            (value?: string) => {
              if (!value) {
                return true;
              }
              return targetingLocationAccuracyValues.some(
                (location: { value: number }) =>
                  location.value === parseInt(value, 10)
              );
            }
          ),
        }),
        ageParams: Yup.object().shape({
          ageRange: Yup.object().shape(
            {
              minAge: Yup.number()
                .integer(
                  `${startOfMessage} require the minimum age to be an integer`
                )
                .min(1, `${startOfMessage} require the minimum age to be 1+`)
                .max(
                  120,
                  `${startOfMessage} require the minimum age to be no more than 120`
                )
                .when('maxAge', {
                  is: (maxAge: number) => !!maxAge,
                  then: Yup.number().required(
                    `${startOfMessage} require a minimum and maximum age entry to be valid. Please enter valid age range.`
                  ),
                })
                .when('maxAge', (maxAge: number, schema: Yup.NumberSchema) =>
                  schema.test(
                    'minAge',
                    `${startOfMessage} require the minimum age to be lower than or equal to the maximum age entry to be valid. Please enter valid age range.`,
                    (minAge: number) => {
                      if (minAge && maxAge) return minAge <= maxAge;
                      return true;
                    }
                  )
                )
                .transform((minAge) => (Number.isNaN(minAge) ? null : minAge))
                .nullable(),
              maxAge: Yup.number()
                .integer(
                  `${startOfMessage} require the maximum age to be an integer`
                )
                .min(1, `${startOfMessage} require the maximum age to be 1+`)
                .max(
                  120,
                  `${startOfMessage} require the maximum age to be no more than 120`
                )
                .when('minAge', {
                  is: (minAge: number) => !!minAge,
                  then: Yup.number().required(
                    `${startOfMessage} require a minimum and maximum age entry to be valid. Please enter valid age range.`
                  ),
                })
                .transform((maxAge) => (Number.isNaN(maxAge) ? null : maxAge))
                .nullable(),
            },
            ['minAge', 'maxAge'] as any
          ),
        }),
        podcastParams: Yup.object().shape({
          podcastTargets: Yup.array().of(
            Yup.object()
              .shape({
                collectionId: Yup.string().matches(/^[a-zA-Z0-9-._]*$/, {
                  excludeEmptyString: true,
                  message: `${startOfMessage} (Collection ID) may not contain illegal characters (only numbers, letters, “-”, “.” and “_” are allowed)`,
                }),
                showId: Yup.string().matches(/^[a-zA-Z0-9-._]*$/, {
                  excludeEmptyString: true,
                  message: `${startOfMessage} (Show ID) may not contain illegal characters (only numbers, letters, “-”, “.” and “_” are allowed)`,
                }),
                episodeId: Yup.string().matches(/^[a-zA-Z0-9-._]*$/, {
                  excludeEmptyString: true,
                  message: `${startOfMessage} (Episode ID) may not contain illegal characters (only numbers, letters, “-”, “.” and “_” are allowed)`,
                }),
              })
              .test(
                'atLeastOneOf',
                `${startOfMessage} require a valid podcast ID`,
                (value) =>
                  !!value.collectionId || !!value.episodeId || !!value.showId
              )
          ),
        }),
        locationParams: Yup.object().shape({
          positions: Yup.array().of(
            Yup.object().shape(
              {
                latitude: Yup.number()
                  .min(
                    -90,
                    `${startOfMessage} require a latitude to be greater than -90`
                  )
                  .max(
                    90,
                    `${startOfMessage} require a latitude to be smaller than 90`
                  )
                  .when(
                    ['longitude', 'radius'],
                    (
                      longitude: number,
                      radius: number,
                      schema: Yup.NumberSchema
                    ) =>
                      schema.test(
                        'latitude',
                        `${startOfMessage} require a latitude value`,
                        (latitude: number) => {
                          if (latitude) return true;
                          return !(!!longitude || !!radius);
                        }
                      )
                  )
                  .transform((latitude) =>
                    Number.isNaN(latitude) ? null : latitude
                  )
                  .nullable(),
                longitude: Yup.number()
                  .min(
                    -180,
                    `${startOfMessage} require a longitude to be greater than -180`
                  )
                  .max(
                    180,
                    `${startOfMessage} require a longitude to be smaller than 180`
                  )
                  .when(
                    ['latitude', 'radius'],
                    (
                      latitude: number,
                      radius: number,
                      schema: Yup.NumberSchema
                    ) =>
                      schema.test(
                        'longitude',
                        `${startOfMessage} require a longitude value`,
                        (longitude: number) => {
                          if (longitude) return true;
                          return !(!!latitude || !!radius);
                        }
                      )
                  )
                  .transform((longitude) =>
                    Number.isNaN(longitude) ? null : longitude
                  )
                  .nullable(),
                radius: Yup.number()
                  .positive(
                    `${startOfMessage} require a radius to be greater than 0`
                  )
                  .when(
                    ['latitude', 'longitude'],
                    (
                      latitude: number,
                      longitude: number,
                      schema: Yup.NumberSchema
                    ) =>
                      schema.test(
                        'radius',
                        `${startOfMessage} require a radius value`,
                        (radius: number) => {
                          if (radius) return true;
                          return !(!!latitude || !!longitude);
                        }
                      )
                  )
                  .max(
                    20000,
                    `${startOfMessage} require a radius to be smaller than 20000`
                  )
                  .transform((radius) => (Number.isNaN(radius) ? null : radius))
                  .nullable(),
              },
              [
                ['longitude', 'radius'] as any,
                ['latitude', 'radius'] as any,
                ['latitude', 'longitude'] as any,
              ]
            )
          ),
        }),
      })
    ),
  customKvps: Yup.array().of(keyValueValidation('key')),
  currency: (fieldKey: string) =>
    Yup.string().test(
      fieldKey,
      'Please select a valid currency',
      (value: string) => {
        const currencies = [
          CurrenciesEnum.CAD,
          CurrenciesEnum.GBP,
          CurrenciesEnum.USD,
        ];
        return currencies.includes(value as CurrenciesEnum);
      }
    ),
  requiredFieldWithValidation: ({
    messages: { required, error },
    fieldKey,
    valuesToCompareAgainst,
  }: {
    messages: { required: string; error: string };
    fieldKey: string;
    valuesToCompareAgainst: string[];
  }) =>
    Yup.string()
      .required(required)
      .test(fieldKey, error, (value: string) => {
        if (!value) {
          return true;
        }
        return valuesToCompareAgainst.includes(value);
      }),
  optionalFieldWithValidation: ({
    message,
    fieldKey,
    valuesToCompareAgainst,
  }: {
    message: string;
    fieldKey: string;
    valuesToCompareAgainst: string[];
  }) =>
    Yup.string().test(fieldKey, message, (value: string) => {
      if (!value) {
        return true;
      }
      return valuesToCompareAgainst.includes(value);
    }),
  getFrequencyCapFields: (messageStart: string) => ({
    frequencyCapCount: Yup.number()
      .integer(`${messageStart} Count should be a whole number`)
      .min(1, `${messageStart} Count should be higher than 0`)
      .max(
        1000000000,
        `${messageStart} Count should be lower than 1,000,000,000`
      )
      .when(['frequencyCapValue', 'frequencyCapTimeUnit'], {
        is: (frequencyCapValue: number, frequencyCapTimeUnit: string) =>
          !!frequencyCapValue ||
          frequencyCapTimeUnit !== FrequencyCapTimeUnit.TimeUnitUnspecified,
        then: Yup.number().required(
          `${messageStart} Count is a required field`
        ),
      }),
    frequencyCapValue: Yup.number()
      .integer(`${messageStart} Value should be a whole number`)
      .min(1, `${messageStart} Value should be higher than 0`)
      .max(1000, `${messageStart} Value should be lower than 1,000`)
      .when(['frequencyCapCount', 'frequencyCapTimeUnit'], {
        is: (frequencyCapCount: number, frequencyCapTimeUnit: string) =>
          !!frequencyCapCount ||
          frequencyCapTimeUnit !== FrequencyCapTimeUnit.TimeUnitUnspecified,
        then: Yup.number().required(
          `${messageStart} Value is a required field`
        ),
      }),
    frequencyCapTimeUnit: Yup.string().when(
      ['frequencyCapCount', 'frequencyCapValue'],
      (frequencyCapCount: number, frequencyCapValue: number, schema: any) =>
        schema.test(
          'frequencyCapTimeUnit',
          `${messageStart} Time Unit is a required field`,
          (frequencyCapTimeUnit: string) => {
            if (
              frequencyCapTimeUnit !== FrequencyCapTimeUnit.TimeUnitUnspecified
            )
              return true;
            return (
              (!frequencyCapCount || !frequencyCapValue) &&
              frequencyCapTimeUnit === FrequencyCapTimeUnit.TimeUnitUnspecified
            );
          }
        )
    ),
  }),
};

export default commons;
