import { CurrenciesEnum } from 'utils/currency';
import {
  NetworkStatus,
  PricingType,
  PublisherStatus,
  Territory,
} from 'interfaces/generated.types';

export interface IPublisherValues {
  blacklistedDomains: string[];
  blacklistedIabCategoryCodes: string[];
  generatedDomains: string;
  currency: CurrenciesEnum;
  id: string;
  altId?: number;
  name: string;
  networkName: string;
  networkId: string;
  networkTerritories: Territory[];
  pricingAmount: string;
  pricingType: PricingType;
  journey: string;
  status?: PublisherStatus;
  territories: { value: Territory; label: string }[];
}

export const publisherStatusValues = [
  {
    value: PublisherStatus.Draft,
    label: 'Draft',
    isDisabled: (value: PublisherStatus) =>
      [PublisherStatus.Enabled, PublisherStatus.Disabled].includes(value),
  },
  {
    value: PublisherStatus.Enabled,
    label: 'Enabled',
    isDisabled: (value: PublisherStatus) =>
      [PublisherStatus.Draft].includes(value),
  },
  {
    value: PublisherStatus.Disabled,
    label: 'Disabled',
    isDisabled: (value: PublisherStatus) =>
      [PublisherStatus.Draft].includes(value),
  },
];

export const getPublisherStatusData = (
  values: IPublisherValues,
  networkStatus: NetworkStatus
) => [
  {
    status: PublisherStatus.Draft,
    required: [
      ...(!values.name ? ["Complete 'Name'"] : []),
      ...(!values.networkName ? ['Complete Parent Network'] : []),
    ],
  },
  {
    status:
      values.status === PublisherStatus.Disabled
        ? PublisherStatus.Disabled
        : PublisherStatus.Enabled,
    required: [
      ...(!values.name ? ['Waiting for Name'] : []),
      ...(networkStatus !== NetworkStatus.Enabled
        ? ['Waiting for Parent Network to be in ENABLED state']
        : []),
      ...(!values.pricingType ? ['Waiting for Price Type'] : []),
      ...(!values.pricingAmount ? ['Waiting for Amount'] : []),
    ],
  },
];

export const publisherDetailsFields = [
  'name',
  'currency',
  'pricingType',
  'pricingAmount',
];
