import memoizeOne from 'memoize-one';

import { OptionType } from 'interfaces';
import {
  AdvertiserStatus,
  OrderStatus,
  Territory,
} from 'interfaces/generated.types';

import { TimeZones } from 'utils/date';

export interface IOrderValues {
  advertiser: OptionType | null;
  allAdvertisers: OptionType[];
  endDate: Date | null;
  externalId: string;
  externalSystemId: string;
  id: string;
  altId?: number;
  name: string;
  objective: string;
  unlimitedObjective: boolean;
  startDate: Date | null;
  timeZone: TimeZones;
  journey: string;
  owner: OptionType | null;
  status?: OrderStatus;
  noEndDate: boolean;
  frequencyCapCount: number | string;
  frequencyCapTimeUnit: string;
  frequencyCapValue: number | string;
  territory?: Territory;
}

export const orderStatusValues = [
  {
    value: OrderStatus.Disabled,
    label: 'Disabled',
  },
  {
    value: OrderStatus.Draft,
    label: 'Draft',
    isDisabled: (value: OrderStatus) =>
      [
        OrderStatus.Ready,
        OrderStatus.Live,
        OrderStatus.Paused,
        OrderStatus.Completed,
      ].includes(value),
  },
  {
    value: OrderStatus.Ready,
    label: 'Ready',
    isDisabled: (value: OrderStatus) =>
      [
        OrderStatus.Draft,
        OrderStatus.Live,
        OrderStatus.Paused,
        OrderStatus.Disabled,
        OrderStatus.Completed,
      ].includes(value),
  },
  {
    value: OrderStatus.Live,
    label: 'Live',
    isDisabled: (value: OrderStatus) =>
      [
        OrderStatus.Draft,
        OrderStatus.Ready,
        OrderStatus.Disabled,
        OrderStatus.Completed,
      ].includes(value),
  },
  {
    value: OrderStatus.Paused,
    label: 'Paused',
    isDisabled: (value: OrderStatus) =>
      [
        OrderStatus.Draft,
        OrderStatus.Ready,
        OrderStatus.Disabled,
        OrderStatus.Completed,
      ].includes(value),
  },
  {
    value: OrderStatus.Completed,
    label: 'Completed',
    isDisabled: () => true,
  },
];

export const getOrderStatusData = memoizeOne(
  (values: IOrderValues, advertiserStatus: AdvertiserStatus) => {
    let statusData = [
      {
        status: OrderStatus.Draft,
        required: [
          ...(!values.name ? ["Complete 'Name'"] : []),
          ...(!values.advertiser ? ['Complete Parent Advertiser'] : []),
          ...(!values.objective ? ["Complete 'Objective'"] : []),
        ],
      },
      {
        status: OrderStatus.Ready,
        required: [
          ...(advertiserStatus !== AdvertiserStatus.Enabled
            ? ['Waiting for Parent Advertiser to be in ENABLED state']
            : []),
          ...(!values.name ? ['Waiting for Name'] : []),
          ...(values.objective && !values.endDate
            ? ['Waiting for End Date (if an Objective is provided)']
            : []),
          ...(!values.timeZone ? ['Waiting for Timezone'] : []),
          ...(!values.startDate ? ['Waiting for Start Date'] : []),
        ],
      },
      {
        status:
          values.status === OrderStatus.Paused
            ? OrderStatus.Paused
            : OrderStatus.Live,
        required: ['Waiting for Start Date'],
      },
      {
        status: OrderStatus.Completed,
        required: ['Waiting for End Date'],
      },
    ];

    if (values.status === OrderStatus.Disabled) {
      statusData = [
        {
          status: OrderStatus.Disabled,
          required: [],
        },
        ...statusData,
      ];
    }

    return statusData;
  }
);

export const orderDetailsFields = [
  'advertiser',
  'name',
  'owner',
  'objective',
  'timeZone',
  'startDate',
  'endDate',
  'externalSystemId',
  'externalId',
  'territory',
];
