import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  statusMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    color: colors.errorText,
  },
  statusIcon: {
    width: '14px',
    height: '14px',
    margin: theme.spacing(0, 0.8, 0),
  },
}));

export default useStyles;
