import gql from 'graphql-tag';

import { CreativeStatusEnum } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';

import {
  CreativeAudio,
  CreativeImage,
  CreativeRedirect,
} from 'interfaces/generated.types';

export interface ICreativeImage extends Omit<CreativeImage, 'status'> {
  status: CreativeStatusEnum;
}

export interface ICreativeAudio extends Omit<CreativeAudio, 'status'> {
  status: CreativeStatusEnum;
}

export interface ICreativeAudioFormValues extends ICreativeAudio {
  file?: {
    type: string;
  };
  type?: string;
}

export interface ICreativeRedirect
  extends Omit<CreativeRedirect, 'id' | 'altId' | 'url'> {
  id: string | null;
  altId: number | null;
  url: string | null;
}

export const GET_ALL_AUDIO_CREATIVES = gql`
  query AllAudioCreatives(
    $advertiserId: ID
    $filter: CreativeFilter
    $territories: [Territory!]!
  ) {
    allCreatives(
      advertiserId: $advertiserId
      type: AUDIO
      filter: $filter
      territories: $territories
    ) {
      ... on CreativeAudio {
        id
        name
        fileName
        url
        status
        duration
        mimeType
        uploadedDate
        resourceAltId
        altId
        advertiser {
          id
          name
        }
        territory
      }
    }
  }
`;

export const GET_ALL_IMAGE_CREATIVES = gql`
  query AllImageCreatives(
    $advertiserId: ID
    $filter: CreativeFilter
    $territories: [Territory!]!
  ) {
    allCreatives(
      advertiserId: $advertiserId
      type: IMAGE
      filter: $filter
      territories: $territories
    ) {
      ... on CreativeImage {
        id
        name
        fileName
        url
        size
        mimeType
        status
        uploadedDate
        resourceAltId
        altId
        advertiser {
          id
          name
        }
        territory
      }
    }
  }
`;

export const GENERATE_CREATIVE_URL = gql`
  query GeneratePresignedUrl(
    $contentType: String
    $fileName: String!
    $fileSize: Long
    $httpMethod: HttpMethod!
  ) {
    generatePreSignedUrl(
      attributes: {
        contentType: $contentType
        fileName: $fileName
        fileSize: $fileSize
        httpMethod: $httpMethod
      }
    ) {
      url
      assetUrl
      contentType
      uploadedDate
    }
  }
`;
