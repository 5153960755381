import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';

import { technicalProviderStatusValues } from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderFormValues';
import { GET_ALL_TECHNICAL_PROVIDERS } from 'features/programmatic/technicalProvider/graphql/queries';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  EntityType,
  TechnicalProvider,
  TechnicalProviderStatus,
  Territory,
  useUpdateTechnicalProviderMutation,
} from 'interfaces/generated.types';

import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

interface ITechnicalProvidersRow {
  id: string;
  altId: number;
  name: string;
  status: TechnicalProviderStatus;
}

export interface ITechnicalProvidersTableProps {
  history: History;
  allTechnicalProviders: TechnicalProvider[];
  isEditable: boolean;
  activeTerritory: Territory;
}

export const formatData = memoizeOne((data: TechnicalProvider[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const TechnicalProvidersTable = ({
  history,
  allTechnicalProviders,
  isEditable,
  activeTerritory,
}: ITechnicalProvidersTableProps) => {
  const [updateTechnicalProvider] = useUpdateTechnicalProviderMutation({
    refetchQueries: [
      {
        query: GET_ALL_TECHNICAL_PROVIDERS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });
  const validateTechnicalProvider = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createTechnicalProviderCta = isEditable && (
    <StyledLink
      location={{
        pathname: '/programmatic-connector',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createTechnicalProviderButton"
    >
      Create Programmatic Connector
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<ITechnicalProvidersRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/programmatic-connector/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<ITechnicalProvidersRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: technicalProviderStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={technicalProviderStatusValues}
                name="technicalProviderStatusSelect"
                dataTc="technicalProviderStatusSelect"
              />
            )}
            onCellValidate={onCellValidate}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <Table
      history={history}
      columns={columns}
      data={formatData(allTechnicalProviders)}
      isEditable={isEditable}
      dataTc="listTechnicalProvidersTable"
      data-testid="listTechnicalProvidersTable"
      onCellValidate={({
        entity,
        setErrorModal,
        setWarningModal,
        setUpdating,
        handleContinue,
      }) =>
        handleCellValidate({
          validate: validateTechnicalProvider,
          entity: { ...entity, type: EntityType.Technicalprovider },
          setWarningModal,
          setErrorModal,
          setUpdating,
          handleContinue,
        })
      }
      onCellUpdate={(
        row: ITechnicalProvidersRow,
        setErrorModal: any,
        setUpdating: any
      ) =>
        handleCellUpdate({
          variables: {
            id: row.id,
            status: row.status,
          },
          update: updateTechnicalProvider,
          handleContinue: () =>
            history.push(`/programmatic-connector/${row.id}`),
          setErrorModal,
          setUpdating,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: 'Edit Programmatic Connector',
          },
        })
      }
      customToolbarCtas={createTechnicalProviderCta}
    />
  );
};

export default TechnicalProvidersTable;
