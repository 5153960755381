import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  createDomainContainer: {
    marginBottom: theme.spacing(4),
  },
  createDomains: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  domains: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
  },
  textField: {
    flex: 1,
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(0.625),
    marginRight: theme.spacing(0.625),
  },
  card: {
    margin: theme.spacing(4, 0, 2.5, 0),
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[200],
  },
  upload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(19),
  },
}));

export default useStyles;
