import { makeStyles, Theme } from '@material-ui/core/styles';
import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  svg: {
    width: '100%',
    height: 'auto',
  },
  svgWrapper: {
    width: '142px',
    height: '142px',
  },
  iconButton: {
    margin: theme.spacing(1.25),
  },
}));

export default useStyles;
