import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  IChannelValues,
  OriginatingCountryEnum,
  StationLanguageEnum,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';
import ChannelTabsForm, {
  channelButtons,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelTabsForm';
import ChannelFormValidation from 'features/inventory/channel/validations/channels';
import {
  ExtensionEnum,
  FormatEnum,
} from 'features/inventory/transcodePreset/containers/TranscodePresetContainer/TranscodePresetValues';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Channel,
  ChannelType,
  CreateChannelMutation,
  CreateChannelMutationFn,
  Publisher,
  SoundMode,
  TargetingRestriction,
  useCreateChannelMutation,
  VastVersion,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import numbro from 'numbro';
import React, { useState } from 'react';
import {
  defaultAdRequestEnrichment,
  getAdRequestEnrichmentAttributes,
} from 'utils/adRequestEnrichment';
import { getLocale } from 'utils/dataTransformation';
import { getDefaultTimezoneBasedOnTerritory } from 'utils/defaultsByTerritory';
import { AlreadyExistMessages } from 'utils/errors';
import { formatValues, handleFormErrorsWithDuplicates } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { numbroPercentageFormatting } from 'utils/numbers';
import { getTerritoryValues } from 'utils/territory';

interface IMatch {
  params: {
    publisherId: string;
  };
}
interface ICreateChannelContainerProps {
  history: History;
  match: IMatch;
  publisher: Publisher;
}

export const handleOnComplete = (
  response: CreateChannelMutation,
  history: History,
  selectedJourney: string,
  publisherId: string
) => {
  const { createChannel } = response;
  const { id } = createChannel as Channel;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: publisherId,
    listEntity: selectedJourney === '/publisher/PARENT_ID' ? 'Channel' : '',
  });
};

export const submitCreateChannel =
  (
    createChannel: CreateChannelMutationFn,
    match: IMatch,
    toggleErrorModal: () => void,
    setSelectedJourney: any
  ) =>
  (
    formValues: IChannelValues,
    { setStatus, setSubmitting }: FormikActions<IChannelValues>
  ) => {
    setSelectedJourney(formValues.journey);
    const formattedValues = formatValues(formValues);

    const filteredValues: any = pickBy({
      ...formValues,
      territories: formattedValues.territories,
      journey: null,
      transcodePresets: null,
      transcodeMode: null,
      stationLanguage: null,
      country: null,
      publisherName: null,
      publisherTerritories: null,
      hasExternalDealId: null,
      generatedDomains: null,
      publisherId: match.params.publisherId,
      transcodePresetIds: formattedValues.transcodePresets,
      type: formValues.type || ChannelType.ChannelTypeUnspecified,
      blacklistedDomains: formattedValues.blacklistedDomains.length
        ? formattedValues.blacklistedDomains
        : null,
      blacklistedIabCategoryCodes: formattedValues.blacklistedIabCategoryCodes,
      blockedKeyValues: formattedValues.blockedKeyValues,
      adRequestEnrichment: getAdRequestEnrichmentAttributes(
        formValues.adRequestEnrichment
      ),
    });

    const includeZeroValues = {
      floorCpm: formattedValues.floorCpm,
      trafficAcceptancePercentage: formattedValues.trafficAcceptancePercentage,
      locale: getLocale(formValues.stationLanguage, formValues.country),
      minAdDuration: formattedValues.minAdDuration,
      maxAdDuration: formattedValues.maxAdDuration,
    };

    createChannel({
      variables: { ...filteredValues, ...includeZeroValues },
    }).catch((error) => {
      const duplicateFields = [
        {
          message: AlreadyExistMessages.EXTERNAL_DEAL_ID_FOR_CHANNEL,
          error: {
            externalDealId:
              'This Deal ID already exists in DAX Audio. Please enter a different one.',
          },
        },
      ];
      handleFormErrorsWithDuplicates({
        duplicateFields,
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      });
    });
  };

const CreateChannelContainer = ({
  history,
  publisher,
  match,
}: ICreateChannelContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Channel.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createChannel] = useCreateChannelMutation({
    onCompleted(data) {
      handleOnComplete(
        data,
        history,
        selectedJourney,
        match.params.publisherId
      );
    },
  });

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IChannelValues>
        initialValues={{
          territories:
            activeTerritory && publisher.territories.includes(activeTerritory)
              ? getTerritoryValues([activeTerritory])
              : [],
          floorCpm: '0',
          generatedDomains: '',
          id: '',
          name: '',
          externalDealId: '',
          hasExternalDealId: false,
          country: OriginatingCountryEnum.UnitedKingdom,
          publisherName: publisher.name as string,
          publisherId: publisher.id,
          publisherTerritories: publisher.territories,
          type: '',
          inventoryType: '',
          stationLanguage: StationLanguageEnum.English,
          timeZone: getDefaultTimezoneBasedOnTerritory(activeTerritory!),
          defaultTranscodePresetId: '',
          transcodeBitRate: '',
          transcodeFormat: FormatEnum.unspecified,
          transcodeMode: SoundMode.ModeUnspecified,
          transcodePresets: [],
          transcodeSampleRate: '',
          transcodeVolume: '',
          transcodeExtension: ExtensionEnum.unspecified,
          vastVersion: VastVersion.Two,
          blacklistedIabCategoryCodes: publisher.blacklistedIabCategories.map(
            (categories) => categories.code
          ),
          blacklistedDomains: publisher.blacklistedDomains.length
            ? publisher.blacklistedDomains
            : [''],
          blockedKeyValues: [
            {
              key: '',
              value: '',
            },
          ],
          overrideConsentSignal: false,
          journey: channelButtons[0].url,
          trafficAcceptancePercentage: numbro(1).format(
            numbroPercentageFormatting
          ),
          includedInDefaultInventoryTargeting: false,
          minAdDuration: '',
          maxAdDuration: '',
          adRequestEnrichment: defaultAdRequestEnrichment,
          targetingRestrictions: [TargetingRestriction.Local],
        }}
        validationSchema={ChannelFormValidation()}
        onSubmit={submitCreateChannel(
          createChannel,
          match,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(props) => (
          <ChannelTabsForm
            {...props}
            goBackTo={{
              pathname: `/publisher/${match.params.publisherId}`,
              state: location.state?.parent || {},
            }}
            history={history}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createChannel"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateChannelContainer;
