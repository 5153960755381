import { makeStyles, Theme } from '@material-ui/core/styles';

const primaryControlsStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 10),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(3, 0),
  },
  title: {
    fontSize: theme.spacing(2.626),
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  note: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1.75),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  territory: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    fontSize: '16px',
    padding: theme.spacing(0),
  },
  territoryIcon: {
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    margin: theme.spacing(0.5, 1, 0, 0.5),
  },
}));

export default primaryControlsStyles;
