import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { baseFormStyles } from './Form.styles';

export const baseTabStyles = (theme: Theme) =>
  createStyles({
    ...baseFormStyles(theme),
    bar: {
      backgroundColor: theme.palette.grey[500],
      boxShadow: 'none',
      transition: 'none',
      marginTop: theme.spacing(-1),
    },
    indicator: {
      position: 'absolute',
    },
    tab: {
      backgroundColor: theme.palette.secondary.main,
      opacity: 'unset',
      fontFamily: `${theme.typography.fontFamily}`,
      fontSize: theme.spacing(1.875),
      lineHeight: theme.spacing(2.35),
      color: theme.palette.grey[800],
      overflow: 'initial',
      padding: theme.spacing(1, 2.25),
      margin: theme.spacing(2.134, 2.134, 0),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      textTransform: 'none',
      width: theme.spacing(20),
      '&:disabled': {
        color: theme.palette.grey[800],
      },
      '&:first-child': {
        marginLeft: theme.spacing(0),
      },
      '&--selected': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[800],
      },
    },
    formView: {
      display: 'flex',
      border: 'none',
      padding: theme.spacing(2),
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      '&--table': {
        padding: theme.spacing(2, 0),
      },
      '&--noPadding': {
        padding: theme.spacing(0),
      },
    },
  });

const tabStyles = makeStyles((theme: Theme) => ({
  ...baseTabStyles(theme),
}));

export default tabStyles;
