import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import { LoaderMarginStyles } from 'components/Loader/Loader.styles';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import { INavigationLink } from 'interfaces/navigation';
import React, { MouseEvent, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import shortid from 'shortid';

import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './NavigationDropdown.styles';

const NavigationDropdown = ({
  title,
  links,
  position,
}: {
  title: string;
  links: INavigationLink[];
  position?: string;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [loggingOut, setLoggingOut] = useState(false);
  const { logout } = useAuthContext();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLoggingOut(true);

    logout()
      .then(() => {
        window.sessionStorage.clear();
        return history.push('/');
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Error when logging out: ', error));
  };

  return (
    <>
      <IconButton
        aria-label={`${title}`}
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        className={classNames(classes.menu, {
          [`${classes.menu}--${position}`]: position,
        })}
        classes={{
          root: classes.button,
        }}
        data-tc="navigationDropdown"
      >
        {title}
        {open ? (
          <ExpandLess
            data-testid="expandLessIcon"
            className={classes.expandIcon}
          />
        ) : (
          <ExpandMore
            data-testid="expandMoreIcon"
            className={classes.expandIcon}
          />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
        keepMounted
        open={open || loggingOut}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        {links.map(({ title: linkTitle, url }: INavigationLink) => (
          <MenuItem className={classes.item} key={shortid.generate()}>
            {url === '/log-out' ? (
              <Button
                className={classes.logout}
                data-tc="logoutButton"
                onClick={() => handleLogout()}
              >
                {loggingOut && (
                  <Loader
                    size={20}
                    marginStyle={LoaderMarginStyles.SidesOnly}
                  />
                )}
                {linkTitle}
              </Button>
            ) : (
              <NavLink
                className={classes.linkSlim}
                exact
                to={url}
                data-tc="navigationDropdownLink"
                onClick={() => {
                  handleClose();
                }}
              >
                {linkTitle}
              </NavLink>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NavigationDropdown;
