import pageStyles from 'assets/styles/components/Page.styles';
import { CloneTypeEnum } from 'components/CloneModal/CloneModalValues';
import Impressions from 'components/Impressions/Impressions';
import { Sanity } from 'components/Sanities/Sanities';
import { missingCreativeSanity } from 'components/Sanities/Sanities.values';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableCloneCell from 'components/Table/TableCloneCell';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { dateSort } from 'components/Table/TableSort';
import TableUpdateCell from 'components/Table/TableUpdateCell';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { adStatusValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { ICampaignValues } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import { RefetchCampaignQuery } from 'features/direct/campaign/types/campaign';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Ad,
  AdStatus,
  EntityType,
  useCloneAdMutation,
  useUpdateAdMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import dateUtils, { TimeZones } from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import {
  handleCellClone,
  handleCellUpdate,
  handleCellValidate,
} from 'utils/tables';

import { Checkbox } from '@material-ui/core';

interface IUpdateCampaignMatch {
  params: {
    campaignId: string;
  };
}

export interface IAdsRow {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  weighting: number | null;
  impressions: number | null;
  status: AdStatus;
  creativeAudio?: {
    id: string;
  };
  creativeImages: {
    id: string;
  }[];
  creativeRedirect: {
    id: string;
  };
  sequenceStart: boolean;
  sequence: {
    id: string;
    name: string;
  }[];
}

const calculateTotalWeight = (allAds: Ad[]) =>
  allAds
    .filter((ad) => ad.weighting)
    .reduce((total, ad) => {
      const newTotal = total + (ad.weighting as number);
      return newTotal;
    }, 0);

const formatWeighting = (data: Ad[], weighting: number | null) => {
  if (!weighting) return '';
  const totalWeight = calculateTotalWeight(data);
  return `${weighting} (${Math.floor((weighting * 100) / totalWeight)}%)`;
};

export const formatObjectiveImpressions = (
  allAds: Ad[],
  objective: string | null,
  weighting: number | null
) => {
  if (!objective || !weighting) return null;
  const totalWeight = calculateTotalWeight(allAds);
  return Math.floor((numbro.unformat(objective) * weighting) / totalWeight);
};

export const isSequenceValid = (ads: Ad[]) => {
  const adsWithSequence = ads.filter((ad) => !!ad.sequence.length);

  if (adsWithSequence.length === 0) {
    return true;
  }
  const atLeastOneSequenceStart = ads.some((ad) => ad.sequenceStart === true);
  const allHaveSequence = adsWithSequence.length === ads.length;
  return atLeastOneSequenceStart && allHaveSequence;
};

export const getSequenceStart = (rowData: IAdsRow, ads: Ad[]) => {
  const adsHaveSequence = ads.some((ad) => ad.sequence.length);
  const rowHasSequence = !!rowData.sequence.length && !!rowData.sequence[0].id;

  if (!adsHaveSequence && rowHasSequence) {
    return true;
  }
  if (!rowHasSequence) {
    return false;
  }
  return rowData.sequenceStart;
};

const hiddenColumns = ['startDate', 'endDate'];

const AdsTable = ({
  values,
  allAds,
  hasEditPermissions,
  match,
  history,
  timeZone,
  refetch,
}: {
  values: ICampaignValues;
  allAds: Ad[];
  hasEditPermissions: boolean;
  match: IUpdateCampaignMatch;
  history: History;
  timeZone: TimeZones;
  refetch: RefetchCampaignQuery;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const handleCompleted = () => {
    refetch();
  };

  const [updateAd] = useUpdateAdMutation({
    onCompleted: handleCompleted,
  });

  const [cloneAd] = useCloneAdMutation({
    onCompleted: handleCompleted,
  });

  const validateAd = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const classes = pageStyles();

  const location = usePreviousLocation();

  const createAdCta = hasEditPermissions && (
    <StyledLink
      location={{
        pathname: `/campaign/${match.params.campaignId}/ad`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newAdButton"
    >
      Create New Ad
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Ad Health',
        disableSortBy: true,
        accessor: ({ creativeAudio, creativeRedirect, status }: any) =>
          !(creativeAudio || creativeRedirect) &&
          (status === AdStatus.Ready || status === AdStatus.Live)
            ? 'Missing Creative'
            : '',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<IAdsRow>) =>
          !(row.original.creativeAudio || row.original.creativeRedirect) &&
          (row.original.status === AdStatus.Ready ||
            row.original.status === AdStatus.Live) ? (
            <Sanity sanities={[missingCreativeSanity]} />
          ) : null,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IAdsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/ad/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        sortType: dateSort,
        Cell: ({ cell: { value } }: CellProps<IAdsRow>) => {
          const formattedDate = value.value
            ? getFormattedDateBasedOnTerritory(
                dateUtils.getDateInSpecificTimezone(value.value, timeZone),
                activeTerritory
              )
            : '';
          return formattedDate;
        },
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        sortType: dateSort,
        Cell: ({ cell: { value } }: CellProps<IAdsRow>) => {
          const formattedDate = value.value
            ? getFormattedDateBasedOnTerritory(
                dateUtils.getDateInSpecificTimezone(value.value, timeZone),
                activeTerritory
              )
            : '';
          return formattedDate;
        },
      },
      {
        Header: 'Weighting',
        accessor: 'weighting',
        Cell: ({ cell: { value } }: CellProps<IAdsRow>) =>
          formatWeighting(allAds, value),
      },
      {
        Header: 'Objective Impressions',
        accessor: 'objective',
        filter: 'objective',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { row } }: CellProps<IAdsRow>) => {
          const { impressions } = row.original;
          return (
            <Impressions
              objective={formatObjectiveImpressions(
                allAds,
                values.objective,
                row.original.weighting
              )}
              impressions={impressions}
            />
          );
        },
      },
      {
        Header: 'First Ad in sequence',
        accessor: 'sequenceStart',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            column: { id },
            row,
            value,
          },
          onCellUpdate,
          setErrorModal,
          setUpdating,
          isEditable,
        }: CellProps<IAdsRow>) => (
          <TableUpdateCell
            render={() => (
              <Checkbox
                disabled
                checked={value}
                value={value}
                color="secondary"
                data-tc="adSequenceSwitchDisabled"
              />
            )}
            editComponent={(cellValue: boolean, onChange: any) => (
              <Checkbox
                checked={cellValue}
                value={cellValue}
                color="secondary"
                onChange={() => onChange(!cellValue)}
                data-tc="adSequenceSwitch"
              />
            )}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setUpdating={setUpdating}
            row={row}
            id={id}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Next ad in sequence',
        accessor: 'sequence',
        filter: 'sequence',
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            column: { id },
            row,
            value,
          },
          onCellUpdate,
          setErrorModal,
          setUpdating,
          isEditable,
        }: CellProps<IAdsRow>) => (
          <TableUpdateCell
            render={() => (value.length ? value[0].name : <div />)}
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue.length ? cellValue[0].id : ''}
                onChange={(newCellValue: any) =>
                  onChange([{ id: newCellValue }])
                }
                options={[
                  { label: '', value: '' },
                  ...allAds
                    .filter((ad) => ad.id !== row.original.id)
                    .map((ad) => ({ label: ad.name as string, value: ad.id })),
                ]}
                name="adSequenceSelect"
                dataTc="adSequenceSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            setErrorModal={setErrorModal}
            setUpdating={setUpdating}
            row={row}
            id={id}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
          isEditable,
        }: CellProps<IAdsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: adStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(celValue: any, onChange: any) => (
              <TableSelect
                value={celValue}
                onChange={onChange}
                options={adStatusValues}
                name="adStatusSelect"
                dataTc="adStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, name },
            },
          },
          onCellClone,
          setErrorModal,
          isEditable,
        }: CellProps<IAdsRow>) => (
          <TableCloneCell
            onCellClone={onCellClone}
            setErrorModal={setErrorModal}
            entity={{ id, name }}
            isEditable={isEditable}
            type={CloneTypeEnum.AD}
            dataTc="cloneAd"
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.state, timeZone, allAds, values.objective]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Campaign Ads"
        columns={columns}
        data={allAds}
        isEditable={hasEditPermissions}
        hiddenColumns={hiddenColumns}
        dataTc={`${match.params.campaignId || ''}adsTable`}
        errorMessage={
          isSequenceValid(allAds)
            ? ''
            : 'The sequence is not valid. To ensure campaign will be delivered, there must be a 1st and consecutive ad in the sequence.'
        }
        isPageTable={false}
        onCellClone={({
          entity,
          cloneChildren,
          hasChildren,
          isEdit,
          setErrorModal,
        }) =>
          handleCellClone({
            variables: {
              id: entity.id,
              disableCascade: cloneChildren ? !hasChildren : undefined,
            },
            clone: cloneAd,
            handleSuccess: (data: any) =>
              isEdit && history.push(`/ad/${data.cloneAd.id}`),
            handleContinue: () => history.push(`/ad/${entity.id}`),
            setErrorModal,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Ad',
            },
          })
        }
        onCellUpdate={(row: IAdsRow, setErrorModal: any, setUpdating: any) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
              sequenceStart: getSequenceStart(row, allAds),
              sequence: row.sequence
                .map((seq: { id: string | number }) => seq.id)
                .filter((seq: string | number) => seq !== ''),
            },
            update: updateAd,
            handleContinue: () => history.push(`/ad/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Ad',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateAd,
            entity: { ...entity, type: EntityType.Ad },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createAdCta}
      />
    </div>
  );
};

export default AdsTable;
