import Checkbox from 'features/targetingV2/components/core/Checkbox';
import Typography from 'features/targetingV2/components/core/Typography';
import { getGradeOptionInfo } from 'features/targetingV2/utils/grading';
import { TargetingGrade } from 'interfaces/generated.types';
import React from 'react';

import useStyles from './GradingSelectOption.styles';

const GradeSelectOption = ({
  grade,
  fieldName,
  enabled,
}: {
  grade: TargetingGrade;
  fieldName: string;
  enabled: boolean;
}) => {
  const classes = useStyles();

  const gradeInfo = getGradeOptionInfo(grade);

  return (
    <>
      <Checkbox
        checked={enabled}
        value={grade}
        color="primary"
        classes={{
          root: classes.checkbox,
          colorPrimary: classes.checkboxPrimaryColor,
        }}
        inputProps={{ 'aria-label': `${fieldName}-${grade}-checkbox` }}
      />
      <div className={classes.iconContainer}>{gradeInfo.gradeIcon}</div>
      <div className={classes.labelContainer}>
        <Typography variant="body2">{gradeInfo.label}</Typography>
        <Typography variant="caption" className={classes.gradeDescription}>
          {gradeInfo.description}
        </Typography>
      </div>
    </>
  );
};

export default GradeSelectOption;
