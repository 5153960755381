import CookieConsent from 'components/CookieConsent/CookieConsent';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Footer from 'components/Footer/Footer';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import React from 'react';
import Routes from 'routes/Routes';
import { AuthState } from 'utils/authProvider';

import useStyles from './App.styles';

const App = () => {
  const { state } = useAuthContext();

  const classes = useStyles();

  return (
    <ErrorBoundary>
      <CookieConsent />
      <div className={classes.appContainer}>
        <Routes />
        {state === AuthState.LoggedIn && <Footer />}
      </div>
    </ErrorBoundary>
  );
};
export default App;
