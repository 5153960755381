import classNames from 'classnames';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import TerritoryDropdown from 'components/Territory/TerritoryDropdown/TerritoryDropdown';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import InsightsPermissionsSelector from 'features/insights/components/InsightsPermissionsSelector/InsightsPermissionsSelector';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFormProps, IOption } from 'interfaces';
import {
  PermissionName,
  Territory,
  useAllUserTypesQuery,
} from 'interfaces/generated.types';
import { uniq } from 'lodash';
import React, { useEffect } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import SingleSelect from 'components/SingleSelect/SingleSelect';
import { getSelectOptions } from 'utils/dataTransformation';
import { readOnlyPermissionRegex } from 'utils/permissions';
import useStyles from './UserForm.styles';
import {
  checkIfGlobalEmail,
  getUserInsightsPermission,
  handlePermissionCheckboxChange,
  handleUserDelete,
  handleUserUpdate,
  isPermissionCheckboxDisabled,
  IUserValues,
  updatePrimaryTerritory,
  userPermissionsOptions,
} from './userFormUtils';

const UserForm = ({
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  isValid,
  dirty,
  touched,
  values,
  update = false,
  status = {},
}: IFormProps<IUserValues>) => {
  const classes = useStyles();
  const location = usePreviousLocation();
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const {
    state: {
      user: { territories: currentUserTerritories },
    },
  } = useSessionContext();

  const isPrimaryPermissionSelected = values.permissions.some(
    (permission: any) => permission === PermissionName.Primary
  );

  const isGlobalUser = checkIfGlobalEmail(values.email);
  const userInsightsPermission = getUserInsightsPermission(values.permissions);

  const {
    loading: loadingUserTypes,
    data,
    error: userTypesError,
  } = useAllUserTypesQuery();

  const userTypeOptions = userTypesError
    ? []
    : getSelectOptions(data?.allUserTypes || [], 'displayName', 'userType');

  useEffect(() => {
    if (values.email && !update) {
      if (isGlobalUser) {
        const filteredPermissions = values.permissions.filter(
          (permission) => permission !== PermissionName.PublisherInsightsTier2
        );

        setFieldValue(
          'permissions',
          uniq([...filteredPermissions, PermissionName.PublisherInsightsTier1])
        );
        if (values.entityPermissions.length > 0)
          setFieldValue('entityPermissions', []);
      } else {
        const newPermissions = [
          ...(values.permissions.includes(PermissionName.Experimental)
            ? [PermissionName.Experimental]
            : []),
          PermissionName.PublisherInsightsTier2,
        ];
        setFieldValue('permissions', newPermissions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email]);

  return (
    <>
      <Form
        className={classes.form}
        role="form"
        data-tc="userForm"
        data-testid="userForm"
      >
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend} data-tc="userLegend">
            {update ? 'Update User Details' : 'Create User'}
          </legend>
          <div className={classes.formWrapper}>
            <Field
              component={TextField}
              label="Email"
              name="email"
              id="email"
              autoFocus={!update}
              placeholder="Email"
              type="email"
              data-tc="userEmailField"
              required
              disabled={update}
              fullWidth
              margin="normal"
              inputProps={{ 'data-testid': 'userEmail' }}
              helperText={(touched.email && errors.email) || status.email}
              FormHelperTextProps={{
                error: !!((touched.email && errors.email) || status.email),
              }}
            />
            <div className={classes.territories}>
              <FormLabel component={'legend' as 'span'}>Territories:</FormLabel>
              {currentUserTerritories ? (
                <FormGroup row>
                  <FormControl
                    component={'fieldset' as 'div'}
                    margin="normal"
                    className={classes.territoryDropdown}
                  >
                    <TerritoryDropdown
                      label="Primary"
                      options={currentUserTerritories}
                      value={values.primaryTerritory as Territory}
                      customOnChange={(fieldValue: any) => {
                        if (
                          userInsightsPermission ===
                            PermissionName.PublisherInsightsTier2 &&
                          values.entityPermissions.length > 0
                        )
                          setWarningModal({
                            hasWarning: true,
                            handleWarningContinue: () => {
                              updatePrimaryTerritory(
                                fieldValue,
                                values,
                                setFieldValue
                              );
                            },
                          });
                        else
                          updatePrimaryTerritory(
                            fieldValue,
                            values,
                            setFieldValue
                          );
                      }}
                    />
                  </FormControl>
                  <FormControl
                    component={'fieldset' as 'div'}
                    margin="normal"
                    className={classes.territoryDropdown}
                  >
                    <TerritoryDropdown
                      label="Secondary"
                      isMultiSelect
                      options={currentUserTerritories}
                      value={values.territories}
                      disabledOptions={[values.primaryTerritory as Territory]}
                      customOnChange={(fieldValue: any) => {
                        if (
                          userInsightsPermission ===
                            PermissionName.PublisherInsightsTier2 &&
                          values.entityPermissions.length > 0
                        )
                          setWarningModal({
                            hasWarning: true,
                            handleWarningContinue: () => {
                              setFieldValue('territories', fieldValue);
                              setFieldValue('entityPermissions', []);
                            },
                          });
                        else setFieldValue('territories', fieldValue);
                      }}
                    />
                  </FormControl>
                </FormGroup>
              ) : (
                <FormHelperText error data-testid="noTerritoriesError">
                  Could not load the list of territories
                </FormHelperText>
              )}
              {((touched && errors.primaryTerritory) ||
                status.primaryTerritory) && (
                <FormHelperText error data-testid="userPrimaryTerritoryError">
                  {errors.primaryTerritory || status.primaryTerritory}
                </FormHelperText>
              )}
            </div>
            <FormControl
              component={'fieldset' as 'div'}
              margin="normal"
              className={classes.fieldset}
            >
              <FormLabel component={'legend' as 'span'}>
                Select permissions for the user
              </FormLabel>
              <FormGroup>
                {userPermissionsOptions.map(
                  (permission: IOption<PermissionName>) => (
                    <div
                      key={permission.value}
                      className={classNames([
                        classes.checkboxesContainer,
                        {
                          [`${classes.checkboxesContainer}--readOnly`]:
                            readOnlyPermissionRegex.test(permission.value),
                        },
                      ])}
                    >
                      <FormControlLabel
                        name="permissions"
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              isGlobalUser &&
                              (isPrimaryPermissionSelected ||
                                values.permissions.includes(permission.value))
                            }
                            onChange={(event) => {
                              handlePermissionCheckboxChange({
                                event,
                                valuesArray: values.permissions,
                                setFieldValue,
                                isUpdateForm: update,
                                isGlobalUser,
                              });
                            }}
                            value={permission.value}
                            name="permission"
                            data-tc="checkboxField"
                            disabled={
                              !isGlobalUser ||
                              isPermissionCheckboxDisabled(
                                permission.value,
                                values.permissions
                              ) ||
                              (isPrimaryPermissionSelected &&
                                permission.value !== PermissionName.Primary)
                            }
                          />
                        }
                        label={permission.label}
                      />
                    </div>
                  )
                )}
              </FormGroup>
            </FormControl>
            <FormControl
              component={'fieldset' as 'div'}
              margin="normal"
              className={classes.fieldset}
            >
              <SingleSelect
                fieldName="userType"
                fieldLabel="User Type"
                value={values.userType}
                options={userTypeOptions}
                handleChange={(newValue: string) =>
                  setFieldValue('userType', newValue)
                }
                isLoading={loadingUserTypes}
                defaultOptionLabel="Not set"
              />
            </FormControl>
            <FormControl
              component={'fieldset' as 'div'}
              margin="normal"
              className={classes.fieldset}
            >
              <InsightsPermissionsSelector
                currentInsightsPermission={userInsightsPermission}
                values={values}
                errors={errors}
                status={status}
                setFieldValue={setFieldValue}
              />
            </FormControl>
            <FormControl
              component={'fieldset' as 'div'}
              margin="normal"
              className={classes.fieldset}
            >
              <FormControlLabel
                name="permissions"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.permissions.includes(
                      PermissionName.Experimental
                    )}
                    onChange={(event) => {
                      handlePermissionCheckboxChange({
                        event,
                        valuesArray: values.permissions,
                        setFieldValue,
                        isUpdateForm: update,
                        isGlobalUser,
                      });
                    }}
                    value={PermissionName.Experimental}
                    name="permission"
                    data-tc="checkboxField"
                  />
                }
                label="Experimental: used for beta features"
              />
              <Field type="hidden" name="deleteUser" />
            </FormControl>
          </div>
        </fieldset>
        <FormButtons
          dataTc="createUserButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/users',
            state: location.state?.parent || {},
          }}
          variant={
            update ? FormButtonVariants.split : FormButtonVariants.single
          }
          buttons={[
            {
              text: 'Update User',
              onClick: () => handleUserUpdate(setFieldValue, handleSubmit),
              isLoading: isSubmitting,
              disabled: !isValid || !dirty || isSubmitting,
              dataTc: 'updateUserButton',
            },
            {
              text: 'Delete User',
              onClick: () => handleUserDelete(setFieldValue, handleSubmit),
              isLoading: isSubmitting,
              disabled: isSubmitting,
              dataTc: 'deleteUserButton',
            },
          ]}
        >
          Create User
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !isSubmitting} />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateTerritoriesWarningDialog"
        warningMessage="Changing the assigned territories will reset the selected Tier 2 insights permissions."
      />
    </>
  );
};

export default UserForm;
