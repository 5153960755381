import { IDateRange } from 'interfaces';
import dateUtils from 'utils/date';

export interface Preset {
  id: string;
  label: string;
  onSelection: (disablePast?: boolean, disableFuture?: boolean) => IDateRange;
}

export const lastXDaysPresets: Preset[] = [
  {
    id: '7daysPreset',
    label: '7 days',
    onSelection: () => dateUtils.getLastXDaysRange(6),
  },
  {
    id: '14daysPreset',
    label: '14 days',
    onSelection: () => dateUtils.getLastXDaysRange(13),
  },
  {
    id: '28daysPreset',
    label: '28 days',
    onSelection: () => dateUtils.getLastXDaysRange(27),
  },
];

export const periodPresets: Preset[] = [
  {
    id: 'currentMonthPreset',
    label: 'Current Month',
    onSelection: (disablePast, disableFuture) =>
      dateUtils.getCurrentMonthRange(disablePast, disableFuture),
  },
  {
    id: 'currentQuarterPreset',
    label: 'Current Quarter',
    onSelection: (disablePast, disableFuture) =>
      dateUtils.getCurrentQuarterRange(disablePast, disableFuture),
  },
  {
    id: 'pastMonthPreset',
    label: 'Previous Month',
    onSelection: (disablePast) => dateUtils.getPreviousMonthRange(disablePast),
  },
];
