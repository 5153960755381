import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';

import CreateTechnicalProviderContainer from 'features/programmatic/technicalProvider/containers/CreateTechnicalProviderContainer/CreateTechnicalProviderContainer';

interface ICreateTechnicalProviderPage {
  history: History;
}

const CreateTechnicalProviderPage = ({
  history,
}: ICreateTechnicalProviderPage) => (
  <main>
    <Container>
      <h1 className="sr-only">Create Programmatic Connector Page</h1>
      <CreateTechnicalProviderContainer history={history} />
    </Container>
  </main>
);

export default CreateTechnicalProviderPage;
