import base64url from 'base64url';
import Loader from 'components/Loader/Loader';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import loginStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import SignUpForm, {
  ISignUpFormValues,
} from 'features/signup/components/SignUpForm/SignUpForm';
import SignUpValidation from 'features/signup/validations/signup';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { Typography } from '@material-ui/core';

import useStyles from './SignUpPage.styles';

const SignUpPage = () => {
  const classes = useStyles();
  const loginClasses = loginStyles();

  const [error, setError] = useState('');
  const { search }: { search: string } = useLocation();

  const userEncodedEmail = new URLSearchParams(search).get('data');

  const { loaded, state, onDaxClientSignUp } = useAuthContext();

  const isLoading = !loaded || state === AuthState.Fetching;

  if (state === AuthState.LoggedIn) {
    return <Redirect to="/home" />;
  }

  if (state === AuthState.NotVerified) {
    return (
      <Redirect
        to={{ pathname: '/email-verification', state: { prevPage: 'sign-up' } }}
      />
    );
  }

  if (!userEncodedEmail) {
    return <Redirect to="/error/404" />;
  }

  const handleFormSubmit = (
    values: { email: string; password: string },
    actions: FormikHelpers<ISignUpFormValues>
  ) => {
    const params = {
      email: values.email,
      password: values.password,
    };

    actions.setSubmitting(false);
    onDaxClientSignUp(params, setError);
  };

  return (
    <PageContainer>
      <div className={loginClasses.container}>
        <div className={loginClasses.content}>
          <Typography variant="h6" className={classes.title}>
            Sign Up
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <Formik<ISignUpFormValues>
              initialValues={{
                email: base64url.decode(userEncodedEmail),
                password: '',
                passwordConfirm: '',
                ageChecked: false,
                privacyChecked: false,
              }}
              validateOnMount
              onSubmit={(values, actions) => {
                handleFormSubmit(values, actions);
              }}
              validationSchema={SignUpValidation}
              component={(formikProps) => (
                <SignUpForm
                  error={error}
                  setError={setError}
                  {...formikProps}
                />
              )}
            />
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default SignUpPage;
