import {
  IParameter,
  IParameterPosition,
} from 'features/targetingV2/types/targeting';
import { clusivityOptions } from 'features/targetingV2/utils/clusivity';
import { Clusivity } from 'interfaces/generated.types';

export const getPairedParameters = (
  allSelectedParameters: IParameter[],
  parameterType: string
) => {
  const pairedParameters = allSelectedParameters.reduce(
    (result: IParameterPosition[], currentParameter, currentParameterIndex) => {
      if (currentParameter.type === parameterType)
        result.push({
          parameter: currentParameter,
          index: currentParameterIndex,
        });
      return result;
    },
    []
  );
  return pairedParameters;
};

export const getOppositeClusivity = (value?: Clusivity) => {
  const oppositeClusivity = clusivityOptions.find(
    (option) => option.value !== value
  )?.value;
  return oppositeClusivity as Clusivity;
};
