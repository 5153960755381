import {
  sanityCodesMap,
  SanityLabel,
} from 'components/Sanities/Sanities.values';
import {
  campaignPacings,
  PacingLabel,
  pacingMap,
} from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import {
  Campaign,
  CampaignOrderableField,
  CampaignStatus,
  OrderingDirection,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import { Filters, Row, SortingRule } from 'react-table';
import { findLabelValue, getCampaignDelivery } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { getFormattedDateBasedOnTerritory } from 'utils/defaultsByTerritory';
import { convertNumberToPercentage } from 'utils/numbers';

export const defaultHiddenColumns = [
  'externalId',
  'companionImpressions',
  'clickThroughRate',
  'ltr',
  'bulkArchiving',
  'deliveryPace',
];

export const formatCampaignsData = memoizeOne(
  (
    data: Campaign[] = [],
    territory: Territory | undefined,
    externalId: string = ''
  ) =>
    data.map((d) => ({
      id: d.id,
      altId: d.altId,
      name: d.name,
      owner: d.ownerName || '',
      advertiser: d.order?.advertiser?.name,
      advertiserId: d.order?.advertiser?.id,
      startDate: d.startDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.startDate, d.timeZone),
            territory
          )
        : '',
      endDate: d.endDate
        ? getFormattedDateBasedOnTerritory(
            dateUtils.getDateInSpecificTimezone(d.endDate, d.timeZone),
            territory
          )
        : '',
      objective: d.objective,
      impressions: d.impressions,
      companionImpressions: d.companionImpressions ?? 'N/A',
      sanities: d.sanities,
      deliveryPace: d.deliveryPace
        ? `${Math.round(d.deliveryPace)}% Pacing`
        : null,
      priorityPacing: `${d.priority} (${findLabelValue({
        collection: campaignPacings,
        lookupValue: d.pacing,
      })})`,
      pacing: findLabelValue({
        collection: campaignPacings,
        lookupValue: d.pacing,
      }),
      status: d.status,
      externalId: (d.order && d.order.externalId) || externalId,
      ltr: convertNumberToPercentage(d.ltr, 'N/A'),
      clickThroughRate: convertNumberToPercentage(d.clickThroughRate, 'N/A'),
      estimatedCompletion: getCampaignDelivery(
        d.status,
        d.estimatedCompletion,
        d.impressions,
        d.objective
      ),
    }))
);

export const campaignColumnMappings = (columnAccessor: string) => {
  switch (columnAccessor) {
    case 'altId':
      return {
        filter: 'partialAltId',
        orderableField: CampaignOrderableField.AltId,
      };
    case 'name':
      return {
        filter: 'partialName',
        orderableField: CampaignOrderableField.Name,
      };
    case 'Campaign Health':
      return {
        filter: 'campaignHealth',
        orderableField: '',
      };
    case 'startDate':
      return {
        filter: 'startDateRange',
        orderableField: CampaignOrderableField.StartDate,
      };
    case 'endDate':
      return {
        filter: 'endDateRange',
        orderableField: CampaignOrderableField.EndDate,
      };
    case 'objective':
      return {
        filter: 'partialObjective',
        orderableField: CampaignOrderableField.Objective,
      };
    case 'impressions':
      return {
        filter: 'partialImpressions',
        orderableField: CampaignOrderableField.Impressions,
      };
    case 'estimatedCompletion':
      return {
        filter: 'partialEstimatedCompletion',
        orderableField: CampaignOrderableField.EstimatedCompletion,
      };
    case 'deliveryPace':
      return {
        filter: 'partialDeliveryPace',
        orderableField: CampaignOrderableField.DeliveryPace,
      };
    case 'owner':
      return {
        filter: 'partialOwnerName',
        orderableField: CampaignOrderableField.OwnerName,
      };
    case 'advertiser':
      return {
        filter: 'partialAdvertiserName',
        orderableField: CampaignOrderableField.AdvertiserName,
      };
    case 'externalId':
      return {
        filter: 'partialExternalId',
        orderableField: CampaignOrderableField.ExternalId,
      };
    case 'companionImpressions':
      return {
        filter: 'partialCompanionImpressions',
        orderableField: CampaignOrderableField.CompanionImpressions,
      };
    case 'clickThroughRate':
      return {
        filter: 'partialClickThroughRate',
        orderableField: CampaignOrderableField.ClickThroughRate,
      };
    case 'ltr':
      return {
        filter: 'partialListenThroughRate',
        orderableField: CampaignOrderableField.ListenThroughRate,
      };
    case 'pacing':
      return {
        filter: 'pacing',
        orderableField: CampaignOrderableField.Pacing,
      };
    case 'status':
      return {
        filter: 'status_in',
        orderableField: CampaignOrderableField.Status,
      };
    default:
      return null;
  }
};

// We currently support sorting by 1 field in the api. If api is updated to include multi-sort in
// the future, we can format this and provide the list of fields here.
export const formatSort = (
  newSortBy: SortingRule<Row<Record<string, unknown>>>[]
) => ({
  orderableField: campaignColumnMappings(newSortBy[0].id)?.orderableField,
  direction: newSortBy[0].desc ? OrderingDirection.Desc : OrderingDirection.Asc,
});

export const formatFilter = (filters: Filters<Row<Record<string, unknown>>>) =>
  filters?.reduce((acc, filter) => {
    const filterName = campaignColumnMappings(filter.id)?.filter;

    let filterValue;
    switch (filterName) {
      case 'campaignHealth':
        filterValue = (filter.value as Array<string>).map(
          (sanity) => sanityCodesMap[sanity as SanityLabel]
        );
        break;
      case 'startDateRange':
        filterValue = { from: filter.value[0], to: filter.value[1] };
        break;
      case 'endDateRange':
        filterValue = { from: filter.value[0], to: filter.value[1] };
        break;
      // The status filter value will either be an array of the labels used in the table's select for
      // campaign health or the actual enum values from the graph filtering (so no need to use the mapping)
      case 'status_in':
        filterValue = filter.value.map(
          (value: string) =>
            CampaignStatus[value as keyof typeof CampaignStatus] || value
        );
        break;
      case 'pacing':
        filterValue = filter.value.map(
          (value: string) => pacingMap[value as PacingLabel]
        );
        break;
      default:
        filterValue = filter.value;
    }

    if (filterName && filterValue)
      return {
        ...acc,
        [filterName]: filterValue,
      };

    return acc;
  }, {});

export const getDefaultTab = (location?: any) => {
  if (location && location.state) {
    const { campaignsTableTab } = location.state;
    if (campaignsTableTab) return campaignsTableTab;
  }
  return 0;
};
