import gql from 'graphql-tag';

export const GET_DSP_MINIMAL = gql`
  query DspMinimal($id: ID!) {
    dsp(id: $id) {
      id
      name
      territories
    }
  }
`;

export const GET_DSP = gql`
  query Dsp($id: ID!, $territories: [Territory!]) {
    dsp(id: $id) {
      id
      dailyCap
      maxAds
      name
      altId
      status
      seats(territories: $territories) {
        id
        altId
        name
        status
        externalSeatId
      }
      technicalProvider {
        id
        name
        status
        territories
      }
      territories
    }
  }
`;

export const GET_ALL_DSPS = gql`
  query AllDsps($technicalProviderId: ID, $territories: [Territory!]!) {
    allDsps(
      technicalProviderId: $technicalProviderId
      territories: $territories
    ) {
      id
      name
      dailyCap
      seats(filter: { status_in: [ACTIVE] }, territories: $territories) {
        id
        name
      }
      technicalProvider {
        id
        openSeatDsp {
          id
        }
      }
    }
  }
`;
