import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 'normal',
    margin: theme.spacing(1, 0, 3, 0),
    textAlign: 'center',
  },
}));

export default useStyles;
