import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { UPDATE_ASSUMED_USER } from 'context/CustomApolloProvider/reducer';
import React from 'react';
import { useHistory } from 'react-router-dom';

import useStyles from './AssumedUserIdHeader.styles';

const AssumedUserIdHeader = () => {
  const classes = useStyles();

  const {
    dispatch,
    state: { assumedEmail },
  } = useCustomApolloContext();

  const history = useHistory();

  const onRevertAssumedIdButtonClick = () => {
    dispatch({
      type: UPDATE_ASSUMED_USER,
      payload: {
        id: '',
        email: '',
      },
    });
    history.push('/users');
  };

  return (
    <header
      className={classes.header}
      data-tc="header"
      data-testid="assumeUserId-header"
    >
      <div>
        currently assuming identity of {assumedEmail}
        <StyledButton
          color={ButtonColorEnum.Secondary}
          variant={ButtonVariantEnum.Outlined}
          onClick={onRevertAssumedIdButtonClick}
          data-tc="revertAssumedUserButton"
          className={classes.button}
        >
          Revert
        </StyledButton>
      </div>
    </header>
  );
};

export default AssumedUserIdHeader;
