import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  getTargetingPackDetails,
  TargetingPackTypeEnum,
} from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import UpdateTargetingPackContainer from 'features/targeting/containers/UpdateTargetingPackContainer/UpdateTargetingPackContainer';
import { History } from 'history';
import get from 'lodash/get';
import React from 'react';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

interface IUpdateTargetingPackPageProps {
  history: History;
  match: {
    params: {
      targetingPackId: string;
      targetingPackType: string;
    };
  };
}

const getQuery = (type: string) =>
  get(getTargetingPackDetails(type), 'details', () => ({
    loading: false,
    error: true,
  }));

const UpdateTargetingPackPage = ({
  history,
  match,
}: IUpdateTargetingPackPageProps) => {
  const useQuery = getQuery(match.params.targetingPackType);
  const { loading, error, data } = useQuery({
    variables: { id: match.params.targetingPackId },
    errorPolicy: 'all',
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const isPackAssignedToActiveTerritory =
    match.params.targetingPackType === 'postcode'
      ? isActiveTerritoryAssignedToEntity(
          [data?.postcodeGroup?.territory!],
          activeTerritory!
        )
      : isActiveTerritoryAssignedToEntity(
          [data?.countryGroup?.territory!],
          activeTerritory!
        );

  if (!loading && error) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Targeting Pack</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateTargetingPackContainer
            history={history}
            match={match}
            type={match.params.targetingPackType as TargetingPackTypeEnum}
            targetingPack={
              data &&
              data[
                `${
                  getTargetingPackDetails(match.params.targetingPackType)
                    .dataType
                }Group`
              ]
            }
            isPackAssignedToActiveTerritory={isPackAssignedToActiveTerritory}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateTargetingPackPage;
