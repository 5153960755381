import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: `3px solid ${theme.palette.error.main}`,
    display: 'flex',
    '&:last-child': {
      marginBottom: theme.spacing(5),
    },
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
