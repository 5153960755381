/* eslint-disable no-param-reassign */
import { isEqual } from 'lodash';
import React, { useMemo, useReducer } from 'react';

import { useWorker, WORKER_STATUS } from '@koale/useworker';

import checkboxTreeReducer from './reducer';
import {
  ActionTypes,
  CheckboxTreeInitialState,
  CheckboxTreeProps,
  CheckboxTreeReducer,
  CheckboxTreeState,
  CheckedStateEnum,
  Node,
  NodeLike,
  SearchMatchingLogic,
} from './types';
import {
  buildInitialState,
  checkAndUpdate,
  filterNodesRemovingLevels,
  filterTree,
  getSelectedNodes,
  search,
} from './utils';

export default function useCheckboxTree({
  data: rawNodes,
  initialState = {},
  isStateControlled = false,
  onControlledNodeToggle,
  filterOptions = {
    matchingLogic: SearchMatchingLogic.Includes,
    removeParentLevels: false,
  },
  reducer = checkboxTreeReducer,
  searchResultsLimit,
}: {
  data: NodeLike[];
  initialState?: CheckboxTreeInitialState;
  isStateControlled?: boolean;
  onControlledNodeToggle?: (newSelectedNodes: NodeLike[]) => void;
  filterOptions?: {
    matchingLogic: SearchMatchingLogic;
    removeParentLevels: boolean;
  };
  reducer?: CheckboxTreeReducer;
  searchResultsLimit?: number;
}): {
  toggleChecked: (id: string) => void;
  toggleExpanded: (id: string) => void;
  refreshState: (selected: NodeLike[]) => void;
  state: CheckboxTreeState;
  selectAll: () => void;
  selectNone: () => void;
  expandAll: () => void;
  collapseAll: () => void;
  onExternalSearch: (term: string, selected?: NodeLike[]) => void;
  clearFilter: () => void;
  nodes: Node[];
  isExpanded: (id: string) => boolean;
  getTreeItemProps: (id: string) => {
    role: string;
    'aria-expanded': boolean;
    'aria-selected': boolean;
  };
  getExpandButtonProps: (id: string) => {
    onClick: () => void;
  };
  getCheckboxProps: (id: string) => {
    checked: boolean;
    onChange: () => void;
    type: string;
  };
  getSearchInputProps: () => {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type: string;
  };
} {
  const [state, dispatch] = useReducer<CheckboxTreeReducer, CheckboxTreeProps>(
    reducer,
    { rawNodes, initialState },
    buildInitialState
  );
  const [searchWorker, { status: searchWorkerStatus }] = useWorker(search);
  const nodes = useMemo((): Node[] => {
    const formattedNodes: Node[] = [];
    state.nodesMap.forEach((value) => {
      if (value.parent === undefined) formattedNodes.push(value);
    });
    if (state.filter) {
      const filteredNodes = filterOptions.removeParentLevels
        ? filterNodesRemovingLevels(
            state.filter,
            formattedNodes,
            filterOptions.matchingLogic
          )
        : filterTree(formattedNodes, state.filter, filterOptions.matchingLogic);

      const filteredResults = searchResultsLimit
        ? filteredNodes.slice(0, searchResultsLimit)
        : filteredNodes;
      return filteredResults;
    }
    return formattedNodes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.nodesMap, state.filter]);

  const toggleChecked = (id: string): void => {
    dispatch({ type: ActionTypes.ToggleNode, payload: { id } });
  };
  const selectAll = (): void => {
    dispatch({ type: ActionTypes.CheckAll });
  };
  const selectNone = (): void => {
    dispatch({ type: ActionTypes.CheckNone });
  };
  const expandAll = (): void => {
    dispatch({ type: ActionTypes.ExpandAll });
  };
  const collapseAll = (): void => {
    dispatch({ type: ActionTypes.CollapseAll });
  };
  const toggleExpanded = (id: string): void => {
    dispatch({ type: ActionTypes.ToggleExpanded, payload: { id } });
  };

  const refreshState = (selected: NodeLike[]): void => {
    const selectedNodeValues = selected.map((node) => node.value);
    const previouslySelectedNodeValues: string[] = [
      ...state.selectedNodesMap.keys(),
    ];

    if (!isEqual(selectedNodeValues, previouslySelectedNodeValues)) {
      dispatch({
        type: ActionTypes.UpdateState,
        payload: { selected },
      });
    }
  };

  const controlledToggleChecked = (id: string): void => {
    if (onControlledNodeToggle) {
      const newValue =
        state.checked[id] === CheckedStateEnum.Checked
          ? CheckedStateEnum.Unchecked
          : CheckedStateEnum.Checked;

      const { newSelectedNodesMap } = checkAndUpdate(
        state.nodesMap,
        state.checked,
        id,
        newValue,
        state.selectedNodesMap
      );

      const newSelectedNodes: NodeLike[] =
        getSelectedNodes(newSelectedNodesMap);

      onControlledNodeToggle(newSelectedNodes);
    }
  };

  const initializeSearchAndUpdateExpanded = async (
    term: string,
    selected: NodeLike[]
  ): Promise<void> => {
    if (searchWorkerStatus !== WORKER_STATUS.RUNNING) {
      dispatch({ type: ActionTypes.InitSearch, payload: { term } });

      if (filterOptions.removeParentLevels) {
        if (term)
          dispatch({
            type: ActionTypes.CollapseAll,
          });
        else
          dispatch({
            type: ActionTypes.UpdateExpanded,
            payload: { selected },
          });
      } else {
        const result = await searchWorker(
          term,
          state.nodesMap,
          filterOptions.matchingLogic
        );

        dispatch({
          type: ActionTypes.SearchNodes,
          payload: { expanded: result },
        });
      }
    }
  };

  const clearFilter = async (): Promise<void> => {
    if (searchWorkerStatus !== WORKER_STATUS.RUNNING) {
      dispatch({ type: ActionTypes.InitSearch, payload: { term: '' } });
      const result = await searchWorker(
        '',
        state.nodesMap,
        filterOptions.matchingLogic
      );
      dispatch({
        type: ActionTypes.SearchNodes,
        payload: { expanded: result },
      });
    }
  };

  const onExternalSearch = (
    term: string,
    selected: NodeLike[] | undefined = []
  ): void => {
    initializeSearchAndUpdateExpanded(term, selected);
  };

  const getTreeItemProps = (
    id: string
  ): {
    role: string;
    'aria-expanded': boolean;
    'aria-selected': boolean;
  } => ({
    role: 'treeitem',
    'aria-expanded': state.expanded[id],
    'aria-selected': state.checked[id] === CheckedStateEnum.Checked,
  });

  const getCheckboxProps = (
    id: string
  ): {
    checked: boolean;
    indeterminate: boolean;
    ref?: any;
    onChange: () => void;
    type: string;
  } => ({
    checked: state.checked[id] === CheckedStateEnum.Checked,
    // eslint-disable-next-line no-param-reassign, no-return-assign
    ref: (el: HTMLInputElement) =>
      el &&
      (el.indeterminate = state.checked[id] === CheckedStateEnum.Indeterminate),
    indeterminate: state.checked[id] === CheckedStateEnum.Indeterminate,
    onChange: (): void =>
      isStateControlled ? controlledToggleChecked(id) : toggleChecked(id),
    type: 'checkbox',
  });
  const getExpandButtonProps = (
    id: string
  ): {
    onClick: () => void;
  } => ({
    onClick: (): void => {
      toggleExpanded(id);
    },
  });

  const isExpanded = (id: string): boolean => state.expanded[id];

  const getSearchInputProps = () => ({
    onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
      initializeSearchAndUpdateExpanded(
        e.target.value,
        initialState.selected || []
      );
    },
    type: 'text',
  });

  return {
    nodes,
    state,
    toggleChecked,
    toggleExpanded,
    refreshState,
    selectAll,
    selectNone,
    expandAll,
    collapseAll,
    onExternalSearch,
    clearFilter,
    getCheckboxProps,
    getExpandButtonProps,
    getSearchInputProps,
    getTreeItemProps,
    isExpanded,
  };
}
