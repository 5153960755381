import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IDealFormValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import DealTabsForm, {
  dealButtons,
} from 'features/programmatic/deal/components/DealTabsForm/DealTabsForm';
import DealFormValidation from 'features/programmatic/deal/validations/deals';
import useAllParametersInfo from 'features/targetingV2/hooks/AllParametersInfo/useAllParametersInfo';
import { defaultTargetingDefinitionV2 } from 'features/targetingV2/utils/defaults';
import { sendTargetingV2DataToGTM } from 'features/targetingV2/utils/gtmData';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFormValues, OptionType } from 'interfaces';
import {
  AuctionType,
  CreateDealMutation,
  CreateDealMutationFn,
  Deal,
  DspIncludedSeats,
  SalesChannel,
  TargetingVersion,
  useCreateDealMutation,
  User,
} from 'interfaces/generated.types';
import pickBy from 'lodash/pickBy';
import React, { useState } from 'react';
import { createUserOption } from 'utils/dataTransformation';
import { setDspIncludedSeats } from 'utils/dealConnector';
import {
  getDefaultCurrencyBasedOnTerritory,
  getDefaultTargetingGroupWithCountryParamsBasedOnTerritory,
  getDefaultTimezoneBasedOnTerritory,
} from 'utils/defaultsByTerritory';
import { AlreadyExistMessages } from 'utils/errors';
import { formatValues, handleFormErrorsWithDuplicates } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTargetingDefinitionForCreate } from 'utils/targetingDefinitions';

interface ICreateDealContainerProps {
  history: History;
  match: { params: { salesChannelId: string } };
  salesChannel: SalesChannel;
  allUsers: User[];
}

export const handleOnDealComplete = async (
  response: CreateDealMutation,
  history: History,
  salesChannelId: string,
  selectedJourney: string
) => {
  const { createDeal } = response;
  const { id: dealId } = createDeal as Deal;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id: dealId,
    parentId: salesChannelId,
    listEntity: selectedJourney === '/sales-channel/PARENT_ID' ? 'Deal' : '',
  });
};

export const submitForm = async (
  createDeal: CreateDealMutationFn,
  salesChannelId: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any,
  formValues: IDealFormValues,
  { setSubmitting, setStatus }: FormikActions<IDealFormValues>
) => {
  setSelectedJourney(formValues.journey);
  const formattedValues = formatValues(formValues);

  const targetingDefinition = getTargetingDefinitionForCreate(
    formValues,
    formattedValues
  );

  const filteredValues: any = pickBy(
    {
      ...formValues,
      salesChannelId,
      salesChannelName: null,
      dsp: null,
      seats: null,
      journey: null,
      trusted: !formValues.trusted,
      startDate: formattedValues.startDate,
      endDate: formattedValues.endDate,
      owner: null,
      ownerId: formattedValues.ownerId,
      hasAdSeparation: null,
      adSeparation: formattedValues.adSeparation,
      floorCpm: formattedValues.floorCpm,
      offsetCpm: formattedValues.offsetCpm,
      targetingGroups: null,
      targetingMasterTemplates: null,
      targetingGeneralGroup: null,
      ...targetingDefinition,
      allSeats: false,
      allKnownSeats: false,
      seatAllocation: {
        dspId: formValues.dsp?.value,
        seatIds: (formValues.seats as OptionType[]).map(
          (seat: OptionType) => seat.value
        ),
      },
      dspIncludedSeats: setDspIncludedSeats(formValues),
    },
    (value) => value === 0 || !!value
  ) as IFormValues;

  createDeal({
    variables: filteredValues,
  }).catch((error) => {
    const duplicateFields = [
      {
        message: AlreadyExistMessages.EXTERNAL_DEAL_ID,
        error: {
          externalId:
            'This Deal ID already exists in DAX Audio. Please enter a different one.',
        },
      },
    ];
    handleFormErrorsWithDuplicates({
      duplicateFields,
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    });
  });
};

const CreateDealContainer = ({
  salesChannel,
  match,
  history,
  allUsers,
}: ICreateDealContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't create the Deal.",
    'Would you like to go to Home or close the alert and try again?',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const [createDeal] = useCreateDealMutation({
    onCompleted(data) {
      handleOnDealComplete(
        data,
        history,
        match.params.salesChannelId,
        selectedJourney
      );
    },
  });

  const location = usePreviousLocation();

  const gtmLogger = useGTMLogger();
  const { allParametersInfo, loading: allParametersInfoLoading } =
    useAllParametersInfo();

  return (
    <>
      <Formik<IDealFormValues>
        initialValues={{
          auctionType: AuctionType.AuctionTypeUnspecified,
          offsetCpm: '',
          currency: getDefaultCurrencyBasedOnTerritory(activeTerritory!),
          dsp: null,
          seats: [],
          endDate: null as any,
          externalId: '',
          floorCpm: '',
          id: '',
          name: '',
          priority: 5,
          startDate: null as any,
          overridePublisherFloor: false,
          privateAuction: true,
          trusted: true,
          salesChannelName: salesChannel.name as string,
          salesChannelId: salesChannel.id,
          timeZone: getDefaultTimezoneBasedOnTerritory(activeTerritory!),
          targetingGroups: [],
          targetingMasterTemplates: [],
          targetingGeneralGroup: activeTerritory
            ? getDefaultTargetingGroupWithCountryParamsBasedOnTerritory(
                activeTerritory
              )
            : null,
          journey: dealButtons[0].url,
          owner: createUserOption(salesChannel.owner as User),
          adSeparation: '90',
          hasAdSeparation: true,
          noEndDate: false,
          adExclusive: false,
          territory: salesChannel.territory,
          allSeats: false,
          allKnownSeats: false,
          dspIncludedSeats: DspIncludedSeats.DspNotSet,
          targetingVersion: TargetingVersion.TargetingV2,
          targetingDefinitionV2: defaultTargetingDefinitionV2,
        }}
        validationSchema={DealFormValidation(activeTerritory)}
        onSubmit={(formValues, formikActions) => {
          if (formValues.targetingVersion === TargetingVersion.TargetingV2) {
            sendTargetingV2DataToGTM(
              formValues.targetingDefinitionV2,
              allParametersInfo,
              gtmLogger,
              formValues.territory
            );
          }

          submitForm(
            createDeal,
            match.params.salesChannelId,
            toggleErrorModal,
            setSelectedJourney,
            formValues,
            formikActions
          );
        }}
      >
        {(props) => (
          <DealTabsForm
            {...props}
            goBackTo={{
              pathname: `/sales-channel/${match.params.salesChannelId}`,
              state: location.state?.parent || {},
            }}
            allUsers={allUsers}
            history={history}
            loading={allParametersInfoLoading}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
          continueButton: 'Go to Home',
        }}
        handleContinue={() => history.push('/')}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="createDeal"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default CreateDealContainer;
