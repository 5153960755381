import colors from 'assets/styles/colors';
import { IChartSegmentData } from 'interfaces';
import { XYDateChartSeriesData } from 'interfaces/XYDateChart';
import formatNumberWithCommas from 'utils/formatImpressionsValue';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';

export const am4coreSetup = (core: any) => {
  const amChartsId =
    // @ts-ignore
    window.__ENV__.REACT_APP_AMCHARTS_ID || process.env.REACT_APP_AMCHARTS_ID;

  core.addLicense(amChartsId);
  core.useTheme(am4ThemesAnimated);
};

export interface IGroupDataAdapter {
  dataItem: am4charts.XYSeriesDataItem;
  value: number;
}

export enum ChartAggregatorEnum {
  SUM = 'sum',
  AVERAGE = 'average',
  WEIGHTED_AVERAGE = 'weighted',
  HIGH = 'high',
  LOW = 'low',
}

export const calculateWeightedAverage = (value: IGroupDataAdapter) =>
  value.dataItem.groupDataItems
    .filter(
      ({ valueY, dataContext }) =>
        typeof valueY === 'number' &&
        typeof (dataContext as any).weighting === 'number'
    )
    .reduce(
      (sum, { valueY, dataContext }) => ({
        ...sum,
        count: (dataContext as any).weighting + sum.count,
        total: valueY * (dataContext as any).weighting + sum.total,
      }),
      { total: 0, count: 0 }
    );

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["valueAxis", "chart", "text", "slice", "chartSeries", "selectedSeries"] }] */
export const createSeries = ({
  chart,
  field,
  valueAxis,
  name,
  shape,
  toolTipNumberFormat,
  seriesChartData,
  color = colors.daxPrimaryBlue,
  showScrollbar,
  aggregator,
}: {
  chart: any;
  field: string;
  valueAxis: am4charts.ValueAxis;
  name: string;
  shape: string;
  toolTipNumberFormat: string | undefined;
  seriesChartData: XYDateChartSeriesData[];
  color: string | undefined;
  showScrollbar: boolean;
  aggregator: ChartAggregatorEnum;
}) => {
  const series = chart.series.push(new am4charts.LineSeries());
  series.data = seriesChartData;
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.strokeWidth = 2;
  series.yAxis = valueAxis;
  series.name = name;
  series.stroke = am4core.color(color);
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = colors.daxPrimaryBlue;
  series.fill = am4core.color(color);
  series.tensionX = 0.8;
  series.showOnInit = true;
  valueAxis.renderer.line.stroke = series.stroke;

  // Use aggregator to group values
  if (aggregator === ChartAggregatorEnum.WEIGHTED_AVERAGE) {
    series.adapter.add('groupDataItem', (val: IGroupDataAdapter) => {
      const { total, count } = calculateWeightedAverage(val);
      return {
        ...val,
        ...(count > 0 && { value: total / count }),
      };
    });
  } else {
    series.groupFields.valueY = aggregator;
  }

  // Set series properties
  series.connect = false;

  if (showScrollbar) {
    // Create Scrollbar
    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    // Hide Scrollbar graph
    const scrollSeries = scrollbarX.scrollbarChart.series.getIndex(0);
    if (scrollSeries) {
      scrollSeries.filters.clear();
      scrollSeries.hidden = true;
    }
  }

  // Set series tooltip
  const seriesHasCustomTooltipInfo = seriesChartData.some(
    (dataPoint) => dataPoint.toolTipInfo
  );

  if (seriesHasCustomTooltipInfo) {
    series.data = seriesChartData.map((dataPoint) => {
      const dataPointWithCustomTooltipText: any = {
        date: dataPoint.date,
        value: dataPoint.value,
      };

      if (dataPoint?.toolTipInfo) {
        let customTooltipText = '';
        dataPoint.toolTipInfo.forEach((tooltipInfoProperty) => {
          const formattedPropertyValue = `[bold]${formatNumberWithCommas(
            tooltipInfoProperty.value
          )}[/]\n`;

          const customTextForProperty = tooltipInfoProperty.label
            ? `${tooltipInfoProperty.label}: ${formattedPropertyValue}`
            : formattedPropertyValue;
          customTooltipText += customTextForProperty;
        });

        dataPointWithCustomTooltipText.customTooltipText = customTooltipText;
      }
      return dataPointWithCustomTooltipText;
    });

    series.adapter.add('tooltipText', () => '{customTooltipText}');
  } else {
    const numberFormat = toolTipNumberFormat
      ? `[bold]{valueY.formatNumber('${toolTipNumberFormat}')}[/]`
      : '[bold]{valueY}[/]';

    series.tooltipText = name ? `{name}: ${numberFormat}` : numberFormat;
  }

  switch (shape) {
    case 'triangle': {
      const bullet = series.bullets.push(new am4charts.Bullet());
      bullet.width = 12;
      bullet.height = 12;
      bullet.horizontalCenter = 'middle';
      bullet.verticalCenter = 'middle';

      const triangle = bullet.createChild(am4core.Triangle);
      triangle.fill = am4core.color(colors.daxPrimaryBlue);
      triangle.strokeWidth = 2;
      triangle.direction = 'top';
      triangle.width = 12;
      triangle.height = 12;
      break;
    }
    case 'rectangle': {
      const bullet = series.bullets.push(new am4charts.Bullet());
      bullet.width = 10;
      bullet.height = 10;
      bullet.horizontalCenter = 'middle';
      bullet.verticalCenter = 'middle';

      const rectangle = bullet.createChild(am4core.Rectangle);
      rectangle.fill = am4core.color(colors.daxPrimaryBlue);
      rectangle.strokeWidth = 2;
      rectangle.width = 10;
      rectangle.height = 10;
      break;
    }
    default: {
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.fill = am4core.color(colors.daxPrimaryBlue);
      bullet.circle.radius = 2;
      bullet.circle.strokeWidth = 2;
      break;
    }
  }

  return series;
};

export const createAxis = ({
  chart,
  opposite,
  name,
  color,
}: {
  chart: any;
  opposite: boolean;
  name: string;
  color: string;
}) => {
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  if (chart.yAxes.indexOf(valueAxis) !== 0) {
    valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
  }

  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 2;
  valueAxis.renderer.labels.template.fill = color;
  valueAxis.renderer.opposite = opposite;
  valueAxis.title.text = name;

  return valueAxis;
};

export const createColumnSeries = ({
  chart,
  name,
  valueY,
  color,
  tooltip,
}: any) => {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.columns.template.width = am4core.percent(80);
  series.columns.template.fill = am4core.color(color);
  series.columns.template.stroke = am4core.color(color);
  series.columns.template.tooltipText =
    "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
  if (tooltip) {
    series.columns.template.tooltipHTML = tooltip;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color(colors.daxGreyscaleWhite);
    series.tooltip.label.fill = am4core.color(colors.daxGreyscaleBlack);
    series.tooltip.defaultState.transitionDuration = 0;
    series.tooltip.hiddenState.transitionDuration = 0;
  }
  series.name = name;
  series.dataFields.categoryX = 'category';
  series.dataFields.valueY = valueY;
  series.stacked = true;
  series.tooltip.pointerOrientation = 'left';
  series.columns.template.tooltipX = am4core.percent(5);

  return { series };
};

export const updateSliceState = (selectedSlice: any, slice: any) => {
  // If selected slice was activated
  if (selectedSlice.isActive) {
    // De-activate slice, if it's not the selected slice
    if (slice !== selectedSlice) {
      slice.isActive = false;
      slice.defaultState.properties.opacity = 0.3;
      slice.setState('blurred', 500);
    }
    // Update slice opacity, if it's the selected slice
    else slice.defaultState.properties.opacity = 1;
  }
  // If selected slice was de-activated, reset slice to default state
  else {
    slice.defaultState.properties.opacity = 1;
    slice.setState('default', 500);
  }
};

export const handleSliceClick = (ev: any, onClick: any) => {
  const selectedSlice = ev.target as am4core.Slice;
  const currentSeries = selectedSlice.dataItem?.component;

  (currentSeries as am4charts.PieSeries).slices.each((slice) => {
    updateSliceState(selectedSlice, slice);
  });

  if (onClick) {
    const selectedSliceData = selectedSlice.isActive
      ? selectedSlice.dataItem?.dataContext
      : undefined;

    onClick(selectedSliceData);
  }
};

export interface ICreatePieSeriesProps {
  chart: am4charts.PieChart;
  hasBlurredDefaultState: boolean;
  onClick: ((slice: IChartSegmentData | undefined) => void) | undefined;
}

export const createPieSeries = (props: ICreatePieSeriesProps) => {
  const { chart, hasBlurredDefaultState, onClick } = props;
  const series = chart.series.push(
    new am4charts.PieSeries()
  ) as am4charts.PieSeries;

  // Map chart data to data fields
  series.dataFields.value = 'value';
  series.dataFields.category = 'category';

  // Customize pieSeries style
  series.showOnInit = false;
  series.slices.template.stroke = am4core.color(colors.daxTertiaryBlue);
  series.slices.template.strokeWidth = 3;
  series.slices.template.strokeOpacity = 1;
  series.slices.template.propertyFields.fill = 'color';
  series.labels.template.disabled = true;

  // Set default state styles
  series.slices.template.opacity = hasBlurredDefaultState ? 0.3 : 1;
  series.slices.template.defaultState.properties.opacity =
    hasBlurredDefaultState ? 0.3 : 1;

  // Customize other states' styles
  const activeState = series.slices.template.states.getKey('active');
  activeState!.properties.shiftRadius = 0;
  activeState!.properties.opacity = 1;

  const hoverState = series.slices.template.states.getKey('hover');
  hoverState!.properties.opacity = 1;

  const blurredState = series.slices.template.states.create('blurred');
  blurredState!.properties.opacity = 0.3;

  // Set tooltip style and text format
  series.slices.template.tooltipText =
    "[font-size: 13px]{category} {value} ({value.percent.formatNumber('#.##')}%)[/]";
  series.tooltip!.getFillFromObject = false;
  series.tooltip!.background.fill = am4core.color('#ffffff');
  series.tooltip!.autoTextColor = false;
  series.tooltip!.label.fill = am4core.color('#000000');
  series.tooltip!.label.maxWidth = 100;
  series.tooltip!.label.wrap = true;
  series.tooltip!.label.textAlign = 'middle';

  series.slices.template.propertyFields.isActive = 'isActive';

  series.slices.template.events.on('hit', (ev) => {
    handleSliceClick(ev, onClick);
  });

  return { series };
};

export const updateChartSeriesState = (
  selectedSeries: any,
  chartSeries: any
) => {
  if (chartSeries !== selectedSeries) {
    chartSeries.isActive = false;

    if (selectedSeries.isActive) {
      chartSeries.defaultState.properties.opacity = 0.3;
      chartSeries.setState('blurred');
    } else {
      chartSeries.defaultState.properties.opacity = 1;
      chartSeries.setState('default', 500);
    }
  }
};

export const updateSelectedSeriesState = (
  selectedSeries: am4charts.ColumnSeries
) => {
  if (selectedSeries.isActive) {
    selectedSeries.isActive = false;
    selectedSeries.setState('default');
    selectedSeries.defaultState.properties.opacity = 1;
  } else {
    selectedSeries.isActive = true;
    selectedSeries.setState('active');
  }
};

export const handleSeriesClick = (
  selectedSeries: am4charts.ColumnSeries,
  segment: IChartSegmentData,
  onClick: any
) => {
  updateSelectedSeriesState(selectedSeries);

  selectedSeries.dataItem.component?.chart.series.each((chartSeries) => {
    updateChartSeriesState(selectedSeries, chartSeries);
  });

  if (onClick) {
    const selectedSegmentData = selectedSeries.isActive ? segment : undefined;
    onClick(selectedSegmentData);
  }
};

export interface ICreateSingleStackedBarColumnSeriesProps {
  chart: am4charts.XYChart;
  segment: IChartSegmentData;
  chartHasActiveSegment: boolean;
  onClick: ((slice: IChartSegmentData | undefined) => void) | undefined;
}

export const createSingleStackedBarColumnSeries = (
  props: ICreateSingleStackedBarColumnSeriesProps
) => {
  const { chart, segment, chartHasActiveSegment, onClick } = props;

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.showOnInit = false;
  series.stacked = true;

  // Data binding
  series.name = segment.category;
  series.dataFields.valueX = segment.id;
  series.dataFields.valueXShow = 'totalPercent';
  series.dataFields.categoryY = 'category';

  // Customise columns styles
  series.columns.template.strokeWidth = 3;
  series.columns.template.strokeOpacity = 1;
  series.columns.template.stroke = am4core.color(colors.daxTertiaryBlue);
  series.columns.template.fill = am4core.color(segment.color);

  // Customise tooltip
  series.columns.template.tooltipHTML = `
  <span style='display:flex; align-items: center; max-width: 150px; padding: 0; margin: 0; '>
    <img src=${segment.icon} style='vertical-align:bottom; margin-right: 5px; width:32px; height:32px;'>
    <span style='font-size:13px; color:${colors.daxGreyscaleLightGrey};'>{name} {valueX} (${segment.percentage})
    </span>
  </span>`;
  series.tooltip!.getFillFromObject = false;
  series.tooltip!.background.fill = am4core.color(colors.daxGreyscaleWhite);
  series.tooltip!.autoTextColor = false;
  series.tooltip!.label.wrap = true;

  // Customize state styles
  const defaultState = series.states.create('default');
  defaultState!.properties.opacity = 1;

  const activeState = series.states.create('active');
  activeState!.properties.opacity = 1;

  const hoverState = series.states.create('hover');
  hoverState!.properties.opacity = 1;

  const blurredState = series.states.create('blurred');
  blurredState!.properties.opacity = 0.3;

  // Update series styles if chart has active segment
  series.isActive = segment.isActive || false;
  if (chartHasActiveSegment) {
    if (segment.isActive) series.setState('active');
    else {
      series.setState('blurred');
      series.defaultState.properties.opacity = 0.3;
    }
  }

  series.events.on('hit', (ev) => {
    handleSeriesClick(ev.target, segment, onClick);
  });

  return series;
};
