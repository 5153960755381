import { useUserContext } from 'context/UserProvider/UserProvider';
import { PermissionName } from 'interfaces/generated.types';
import { getPermissionNames } from 'utils/permissions';

export default function useUserPermissions(): PermissionName[] {
  const { permissions } = useUserContext();
  const userPermissions = getPermissionNames(permissions);

  return userPermissions;
}
