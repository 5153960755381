import MultiselectModal from 'components/MultiselectModal/MultiselectModal';
import {
  checkIfGlobalEmail,
  IUserValues,
} from 'features/user/components/UserForm/userFormUtils';
import { FormikErrors } from 'formik';
import { PermissionName } from 'interfaces/generated.types';
import React from 'react';

import { FormHelperText } from '@material-ui/core';

import SingleSelect from 'components/SingleSelect/SingleSelect';
import InsightsPermissionsDialog from 'features/insights/components/InsightsPermissionsDialog/InsightsPermissionsDialog';
import {
  getInsightsPermissionsOptions,
  handlePermissionsSelection,
} from './InsightsPermissionsSelectorUtils';

export interface IInsightsPermissionsSelectorProps {
  currentInsightsPermission: string;
  values: IUserValues;
  errors: FormikErrors<IUserValues>;
  status: any;
  setFieldValue: (field: string, value: any) => void;
}

const InsightsPermissionsSelector = ({
  currentInsightsPermission,
  values,
  errors,
  status,
  setFieldValue,
}: IInsightsPermissionsSelectorProps) => {
  const { permissions, entityPermissions, territories } = values;
  const selectedPermission = currentInsightsPermission;
  const isTier2Selected =
    currentInsightsPermission === PermissionName.PublisherInsightsTier2;

  const insightsPermissionsOptions = getInsightsPermissionsOptions(
    checkIfGlobalEmail(values.email)
  );

  const selectedEntityPermissions = entityPermissions.map(
    (entityPermission) => ({
      label: entityPermission.entityName || 'Unnamed entity',
      value: entityPermission.entityId,
    })
  );

  const isMultiselectModalDisabled = values.territories.length === 0;

  return (
    <>
      <SingleSelect
        fieldName="permissions"
        fieldLabel="Insights"
        value={selectedPermission}
        options={insightsPermissionsOptions}
        handleChange={(newValue: string) =>
          handlePermissionsSelection(newValue, permissions, setFieldValue)
        }
      />
      {isTier2Selected && (
        <>
          <MultiselectModal
            id="tier2permissions"
            items={selectedEntityPermissions}
            label="Network/Publisher/Channel/Show"
            disabled={isMultiselectModalDisabled}
            disabledMessage="At least one territory should be assigned to the user before selecting Tier 2 insights permissions."
            CustomDialog={InsightsPermissionsDialog}
            customDialogProps={{
              setFieldValue,
              territories,
              entityPermissions,
            }}
            removeItem={(item: string) => {
              const newPermissions = entityPermissions.filter(
                (entityPermission) => entityPermission.entityId !== item
              );
              setFieldValue('entityPermissions', newPermissions);
            }}
          />
          {!isMultiselectModalDisabled &&
            (errors.entityPermissions || status.entityPermissions) && (
              <FormHelperText error data-testid="permissionsError">
                {errors.entityPermissions || status.entityPermissions}
              </FormHelperText>
            )}
        </>
      )}
    </>
  );
};

export default InsightsPermissionsSelector;
