import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    fontSize: '1em',
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  textarea: {
    marginBottom: theme.spacing(1),
  },
  textfieldContainer: {
    width: '50%',
    marginBottom: theme.spacing(1),
  },
  textareaInput: {
    fontSize: '1em',
  },
  entries: {
    display: 'inline-block',
    margin: theme.spacing(0, 0, 1, 0),
    textAlign: 'right',
    width: '100%',
  },
  error: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  container: {
    width: '100%',
    margin: theme.spacing(3, 0),
    border: 'none',
    padding: 0,
  },
}));

export default useStyles;
