import EntityProgressUpdater, {
  IStatusValue,
} from 'components/EntityProgressUpdater/EntityProgressUpdater';
import React from 'react';
import useStyles from './EntityHeader.styles';

export interface IEntityHeaderProps {
  entityName: string;
  statusValues: IStatusValue[];
  currentStatus: string;
  dataTc: string;
  onUpdate: () => void;
  onValidate: () => void;
  onChange: any;
  isStatusEditable: boolean;
  error: string | null;
}

const EntityHeader = (props: IEntityHeaderProps) => {
  const classes = useStyles();
  const {
    entityName,
    statusValues,
    currentStatus,
    dataTc,
    onUpdate,
    onValidate,
    onChange,
    isStatusEditable,
    error,
  } = props;

  return (
    <div className={classes.legendWrapper} data-testid={dataTc}>
      <h2 className={classes.legend}>{entityName}</h2>
      {isStatusEditable ? (
        <EntityProgressUpdater
          statusValues={statusValues}
          currentStatus={currentStatus}
          onChange={onChange}
          onUpdate={onUpdate as () => void}
          onValidate={onValidate as () => void}
          data-tc={dataTc}
          error={error}
        />
      ) : null}
    </div>
  );
};

export default EntityHeader;
