import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    minWidth: theme.spacing(15),
    fontSize: '14px',
    color: theme.palette.common.black,
    borderRadius: '2px',
    border: `1px solid ${theme.palette.grey[400]}`,
    maxHeight: '32px',
    fontweight: '400',
  },
  // primary CTA button
  containedPrimary: {
    composes: '$button',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[400],
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[600],
      color: theme.palette.common.white,
    },
  },

  // icon part of a split primary CTA button
  containedSecondary: {
    composes: '$containedPrimary',
    minWidth: theme.spacing(5),
    width: 'auto',
  },

  // secondary CTA button
  outlinedPrimary: {
    composes: '$button',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.primary.main}`,
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover, &:focus': {
      borderWidth: '1px',
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.light,
      borderWidth: '1px',
    },
  },

  // tertiary CTA button
  outlinedSecondary: {
    composes: '$button',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[300],
    },
  },

  // used for delete/add icons or button based areas
  text: {
    color: colors.primaryBlue,
    textDecoration: 'underline',
    fontSize: '14px',
    border: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    '&:disabled': {
      color: colors.daxGreyscaleSilver,
    },
    padding: theme.spacing(0, 1),
  },
}));

export default useStyles;
