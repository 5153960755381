import * as Yup from 'yup';

import commons from 'validations/commons';

const BlocklistingFormValidation = () =>
  Yup.object().shape({
    blacklistedDomains: commons.domains({
      fieldKey: 'blacklistedDomains',
      message: 'Only a valid domain is allowed',
    }),
  });

export default BlocklistingFormValidation;
