import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const basePageStyles = (theme: Theme) =>
  createStyles({
    page: {
      textAlign: 'center',
      marginLeft: 'calc(100vw - 100%)',
      marginRight: 0,
    },
    updatePage: {
      composes: '$page',
      margin: theme.spacing(5, 0),
    },
    header: {
      textAlign: 'right',
    },
    table: {
      margin: theme.spacing(2, 0, 0),
    },
    tabTable: {
      padding: theme.spacing(1.25, 0),
    },
    list: {
      margin: theme.spacing(5, 0),
    },
    title: {
      textAlign: 'left',
      fontSize: theme.spacing(2.626),
      fontWeight: 500,
      color: theme.palette.grey[700],
    },
    button: {
      float: 'right',
    },
    buttonWrapper: {
      textAlign: 'right',
    },
    buttonWrapperTab: {
      textAlign: 'right',
      paddingRight: theme.spacing(4),
    },
    errorMessage: {
      fontSize: theme.spacing(1.625),
      color: theme.palette.error.main,
    },
    flexContainer: {
      display: 'flex',
    },
  });

const pageStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
}));

export default pageStyles;
