import { Formik, FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import React, { useState } from 'react';

import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';

import SalesChannelTabsForm, {
  salesChannelButtons,
} from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelTabsForm';
import { ISalesChannelValues } from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelValues';
import SalesChannelFormValidation from 'features/programmatic/salesChannel/validations/salesChannels';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import useWarning from 'hooks/Warning/useWarning';

import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  Agency,
  EntityType,
  SalesChannel,
  UpdateSalesChannelMutationFn,
  User,
  useUpdateSalesChannelMutation,
} from 'interfaces/generated.types';

import { createSelectOption, createUserOption } from 'utils/dataTransformation';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';

interface IUpdateSalesChannelContainerProps {
  history: History;
  salesChannel: SalesChannel;
  match: {
    params: {
      salesChannelId: string;
    };
  };
  allUsers: User[];
  hasEditPermissions: boolean;
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });

export const submitUpdateSalesChannel = (
  updateSalesChannel: UpdateSalesChannelMutationFn,
  initialValues: ISalesChannelValues,
  id: string,
  toggleErrorModal: () => void,
  setSelectedJourney: any
) => (
  formValues: ISalesChannelValues,
  { setStatus, setSubmitting }: FormikActions<ISalesChannelValues>
) => {
  setSelectedJourney(formValues.journey);

  updateSalesChannel({
    variables: {
      id,
      ...differenceInValues(
        { ...formValues, journey: null },
        { ...initialValues, journey: null }
      ),
    },
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateSalesChannelContainer = ({
  history,
  match,
  salesChannel,
  allUsers,
  hasEditPermissions,
}: IUpdateSalesChannelContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Sales Channel.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateSalesChannel] = useUpdateSalesChannelMutation({
    onCompleted() {
      handleOnComplete(history, selectedJourney, salesChannel.id);
    },
  });
  const validateSalesChannel = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    name: salesChannel.name as string,
    id: salesChannel.id,
    altId: salesChannel.altId,
    agency: createSelectOption(salesChannel.agency as Agency),
    allAgencies: [],
    journey: salesChannelButtons[0].url,
    owner: createUserOption(salesChannel.owner as User),
    status: salesChannel.status,
    territory: salesChannel.territory,
  };

  return (
    <>
      <Formik<ISalesChannelValues>
        initialValues={initialValues}
        validationSchema={SalesChannelFormValidation}
        onSubmit={submitUpdateSalesChannel(
          updateSalesChannel,
          initialValues,
          match.params.salesChannelId,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <SalesChannelTabsForm
            {...formikProps}
            update
            allUsers={allUsers}
            hasEditPermissions={hasEditPermissions}
            match={match}
            history={history}
            deals={salesChannel?.deals || []}
            health={salesChannel?.health || []}
            agencyStatus={(salesChannel.agency as Agency).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateSalesChannel,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Saleschannel,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateSalesChannel,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateSalesChannel"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateSalesChannelWarningDialog"
      />
    </>
  );
};

export default UpdateSalesChannelContainer;
