import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { UpdatePublisherContainer } from 'features/inventory/publisher/containers/UpdatePublisherContainer/UpdatePublisherContainer';
import { History } from 'history';
import { Publisher, usePublisherQuery } from 'interfaces/generated.types';
import React from 'react';

export interface IUpdatePublisherPageProps {
  history: History;
  match: {
    params: {
      publisherId: string;
    };
  };
}

const UpdatePublisherPage = ({ history, match }: IUpdatePublisherPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data } = usePublisherQuery({
    variables: {
      id: match.params.publisherId,
      territories: [activeTerritory!],
    },
    errorPolicy: 'all',
  });

  if (!loading && !data?.publisher) return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Publisher</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdatePublisherContainer
            history={history}
            match={match}
            publisher={data?.publisher as Publisher}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdatePublisherPage;
