import { ReactComponent as InfoIcon } from 'assets/icons/dax-infoicon.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/TargetingV2/dax-cross.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import AddParameterButton from 'features/targetingV2/components/AddParameterButton/AddParameterButton';
import ClusivitySelect from 'features/targetingV2/components/ClusivitySelect/ClusivitySelect';
import DayTimeTable from 'features/targetingV2/components/core/DayTime/DayTimeTable/DayTimeTable';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import Typography from 'features/targetingV2/components/core/Typography';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import { SectionType } from 'features/targetingV2/types/common';
import { defaultDayTimeTargeting } from 'features/targetingV2/utils/defaults';
import { IFormProps } from 'interfaces';
import React from 'react';

import useStyles from './DayTimeBuilder.styles';

const DayTimeBuilder = (props: IFormProps<any>) => {
  const { values, setFieldValue } = props;

  const classes = useStyles();

  const dayTimeTargetingFieldName = 'targetingDefinitionV2.dayTimeTargeting';

  const { dayTimeTargeting } = values.targetingDefinitionV2;

  const optionalAddDayTimeParameterButton = !dayTimeTargeting && (
    <AddParameterButton
      onClick={() =>
        setFieldValue(dayTimeTargetingFieldName, defaultDayTimeTargeting)
      }
      testId="add-default-dayTimeTargeting"
    />
  );

  const infoMessage = "Times are based on the agency's time zone";
  const Footer = () => (
    <div className={classes.footerWrapper}>
      <InfoIcon className={classes.infoIcon} />
      <Typography variant="body2">{infoMessage}</Typography>
    </div>
  );

  const removeOption = {
    MenuItemIcon: <RemoveIcon />,
    menuItemIconLabel: 'Remove',
    menuItemAction: () => setFieldValue(dayTimeTargetingFieldName, null),
  };

  const clearOption = {
    MenuItemIcon: <ClearIcon />,
    menuItemIconLabel: 'Clear',
    menuItemAction: () =>
      setFieldValue(dayTimeTargetingFieldName, defaultDayTimeTargeting),
  };

  const shouldShowClearOption =
    dayTimeTargeting?.clusivity !== defaultDayTimeTargeting.clusivity ||
    dayTimeTargeting?.dayTimePoints.length > 0;

  const contextMenuOptions = [
    ...(shouldShowClearOption ? [clearOption] : []),
    removeOption,
  ];

  return (
    <TargetingSection
      sectionType={SectionType.DayTime}
      headerChildren={optionalAddDayTimeParameterButton}
      restrictionsList={values.targetingRestrictions}
    >
      {dayTimeTargeting && (
        <div className={classes.contentWrapper}>
          <div className={classes.labelContainer}>
            <Typography
              variant="body2"
              className={classes.label}
              data-testid="daytime-section-parameter"
            >
              Day & Time
            </Typography>
          </div>
          <ClusivitySelect
            selectedClusivity={dayTimeTargeting.clusivity}
            fieldName={`${dayTimeTargetingFieldName}.clusivity`}
            setFieldValue={setFieldValue}
          />
          <DayTimeTable
            dayTimePoints={dayTimeTargeting.dayTimePoints || []}
            fieldName={`${dayTimeTargetingFieldName}.dayTimePoints`}
            setFieldValue={setFieldValue}
            classes={{
              container: classes.dayTimeTableContainer,
              table: classes.dayTimeTable,
            }}
            CustomFooter={Footer}
          />
          <IconButtonMenu
            menuIconButtonLabel="dayTimeSelection"
            menuOptions={contextMenuOptions}
          />
        </div>
      )}
    </TargetingSection>
  );
};

export default DayTimeBuilder;
