import { ReactComponent as LeftArrowIcon } from 'assets/icons/dax-leftarrowicon.svg';
import { ReactComponent as FirstPageIcon } from 'assets/icons/dax-leftskipicon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/dax-rightarrowicon.svg';
import { ReactComponent as LastPageIcon } from 'assets/icons/dax-rightskipicon.svg';
import { PageInfo } from 'interfaces/generated.types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import MTableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { useFooterStyles, usePaginationStyles } from '../Table/Table.styles';

interface IDynamicTableFooter {
  totalEntities: number;
  pageSize: number;
  pageIndex: number;
  pageInfo?: PageInfo;
  entityRange: { start: number; end: number };
  setPaginationIndex: (arg0: number) => void;
  setPaginationRowsPerPage: (arg0: number) => void;
  setCursor: (arg0: object) => void;
}

interface IDynamicTablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const DynamicTablePaginationActions =
  (
    setCursor: (arg0: object) => void,
    totalEntities: number,
    classes: Record<'root', string>,
    pageIndex: number,
    pageInfo?: PageInfo
    /* eslint-disable react/display-name */
  ) =>
  ({
    page,
    rowsPerPage,
    onPageChange,
  }: IDynamicTablePaginationActionsProps) => {
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setCursor({});
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setCursor(
        pageIndex - 1 === 0
          ? {}
          : {
              start: pageInfo?.startCursor,
            }
      );

      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setCursor({
        end: pageInfo?.endCursor,
      });
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setCursor({
        last: true,
      });
      onPageChange(
        event,
        Math.max(0, Math.ceil(totalEntities / rowsPerPage) - 1)
      );
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={!pageInfo?.hasPreviousPage}
          aria-label="first page"
          data-testid="firstButton"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={!pageInfo?.hasPreviousPage}
          aria-label="previous page"
          data-testid="previousButton"
        >
          <LeftArrowIcon />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!pageInfo?.hasNextPage}
          aria-label="next page"
          data-testid="nextButton"
        >
          <RightArrowIcon />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={!pageInfo?.hasNextPage}
          aria-label="last page"
          data-testid="lastButton"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

const DynamicTableFooter = ({
  totalEntities,
  pageSize,
  pageIndex,
  pageInfo,
  entityRange,
  setCursor,
  setPaginationIndex,
  setPaginationRowsPerPage,
}: IDynamicTableFooter) => {
  const classes = useFooterStyles();

  const paginationStyles = usePaginationStyles();

  return (
    <MTableFooter>
      <TableRow>
        <TablePagination
          classes={classes}
          count={totalEntities}
          labelDisplayedRows={() =>
            `${entityRange.start}-${entityRange.end} of ${totalEntities}`
          }
          onPageChange={(e, page) => {
            setPaginationIndex(page);
          }}
          onRowsPerPageChange={(e) => {
            setCursor({});
            setPaginationRowsPerPage(Number(e.target.value));
            setPaginationIndex(0);
          }}
          rowsPerPageOptions={[15, 25, 50]}
          page={pageIndex}
          rowsPerPage={pageSize}
          ActionsComponent={DynamicTablePaginationActions(
            setCursor,
            totalEntities,
            paginationStyles,
            pageIndex,
            pageInfo
          )}
        />
      </TableRow>
    </MTableFooter>
  );
};

export default DynamicTableFooter;
