import React from 'react';

import MaterialTab from '@material-ui/core/Tab';

import styles from './Tab.styles';

export interface ITabProps {
  disabled?: boolean;
  dataTc: string;
  label: string;
  numberOfTabErrors?: number;
  [key: string]: any;
}
const Tab = ({
  disabled,
  numberOfTabErrors,
  dataTc,
  label,
  ...rest
}: ITabProps) => {
  const classes = styles({});

  return (
    <div className={classes.container}>
      <MaterialTab
        {...rest}
        disabled={disabled}
        data-tc={dataTc}
        classes={{
          root: classes.tab,
          selected: `${classes.tab}--selected`,
        }}
        label={label}
        data-testid={dataTc}
      />
      {numberOfTabErrors ? (
        <span data-tc="numberOfErrors" className={classes.icon}>
          {numberOfTabErrors}
        </span>
      ) : null}
    </div>
  );
};

export default Tab;
