import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import AdDetails from 'features/direct/ad/components/AdDetails/AdDetails';
import { CreativeStatusEnum } from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import CreativeTabs from 'features/direct/creative/components/CreativeTabs/CreativeTabs';
import TargetingVersionSelection from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSelection';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  CampaignStatus,
  EntityType,
  Tag,
  TargetingVersion,
  useAllTagsQuery,
} from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { TimeZones } from 'utils/date';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import {
  adDetailsFields,
  adStatusValues,
  getAdStatusData,
  IAdValues,
} from './AdFormValues';

export interface IAdTabFormProps {
  advertiserId: string;
  update?: boolean;
  goBackTo: ILocation;
  campaignTags: Tag[];
  hasEditPermissions?: boolean;
  campaignStatus?: CampaignStatus;
  campaignTimeZone?: TimeZones;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  history: History;
  loading: boolean;
}

export const adButtons = [
  {
    text: 'Save and Review',
    url: '/ad/ID',
    update: true,
    dataTc: 'adReviewButton',
  },
  {
    text: 'Save and View All Ads',
    url: '/campaign/PARENT_ID',
    update: false,
    dataTc: 'adListButton',
  },
];

const getAdDetailsTabErrors = createNumberOfFormErrorsFn();

const AdTabsForm = (props: IFormProps<IAdValues> & IAdTabFormProps) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    goBackTo,
    setFieldValue,
    update = false,
    values,
    hasEditPermissions = true,
    status,
    campaignStatus,
    campaignTimeZone,
    onFieldUpdate,
    onFieldValidate,
    history,
    loading,
  } = props;

  const classes = TabStyles();

  const isSubmitButtonDisabled =
    !isValid ||
    !dirty ||
    isSubmitting ||
    values.imageCreatives.some(
      (creative) => creative.status === CreativeStatusEnum.Uploading
    ) ||
    values.audioCreatives.some(
      (creative) => creative.status === CreativeStatusEnum.Uploading
    ) ||
    (values.audioCreatives.some(
      (creative) => creative.status === CreativeStatusEnum.HasCreative
    ) &&
      !values.duration);

  const [selectedTab, setTab] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const { data: allTagsData } = useAllTagsQuery();

  const location = usePreviousLocation();
  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isAdTab = selectedTab === 0;
  const isCreativesTab = selectedTab === 1;
  const isTargetingTab = selectedTab === 2;
  const isAdActivityTab = selectedTab === 3;

  return (
    <>
      <Form className={classes.form}>
        {update && campaignStatus && campaignTimeZone && (
          <>
            <EntityProgressBar
              statusData={getAdStatusData(
                values,
                campaignStatus,
                campaignTimeZone
              )}
              currentStatus={values.status || ''}
              dataTc="adStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={adStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="adHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="adTabs"
          >
            <Tab
              label="Details"
              dataTc="adDetailsTab"
              numberOfTabErrors={getAdDetailsTabErrors(status, adDetailsFields)}
            />
            <Tab label="Creatives" dataTc="adCreativesTab" />
            <Tab label="Targeting" dataTc="campaignTargetingTab" />
            <Tab label="Activity" dataTc="adActivityTab" />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]: isAdActivityTab,
            },
            {
              [`${classes.formView}--noPadding`]:
                isTargetingTab &&
                values.targetingVersion === TargetingVersion.TargetingV2,
            },
          ])}
        >
          {isAdTab && (
            <AdDetails
              allTags={allTagsData?.allTags || ([] as Tag[])}
              {...props}
            />
          )}
          {isCreativesTab && <CreativeTabs {...props} update={update} />}
          {isTargetingTab && (
            <TargetingVersionSelection
              {...props}
              hasTargetingVersionToggle={false}
            />
          )}
          {isAdActivityTab && (
            <ActivityTable
              entityType={EntityType.Ad}
              id={values.id}
              data-tc="adActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitAdButton"
          onClick={handleSubmit}
          isLoading={isSubmitting || loading}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={adButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled:
              !isValid ||
              isSubmitting ||
              isSubmitButtonDisabled ||
              !hasEditPermissions ||
              loading,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default AdTabsForm;
