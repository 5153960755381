import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  itemsContainer: ({
    useWhiteBackground,
  }: {
    useWhiteBackground: boolean;
  }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: useWhiteBackground
      ? theme.palette.common.white
      : theme.palette.grey[500],
    borderRadius: '0.313em',
    padding: theme.spacing(0),
    '&--disabled': {
      opacity: '60%',
    },
  }),
  label: {
    height: '24px',
    fontSize: '0.938em',
  },
  chipWrapper: {
    margin: theme.spacing(0.25, 0.5),
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorWrapper: {
    marginTop: theme.spacing(0.45),
  },
}));

export default useStyles;
