import commons from 'validations/commons';
import * as Yup from 'yup';

const SeatFormValidation = () =>
  Yup.object().shape({
    name: commons.name('Seat Name'),
    externalSeatId: commons
      .max255Characters()
      .matches(
        /^\S*$/,
        'External Seat ID cannot contain whitespace characters'
      ),
    territories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .required('Seat should be assigned at least one territory'),
  });

export default SeatFormValidation;
