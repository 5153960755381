import { baseButtonStyles } from 'assets/styles/components/Button.styles';
import { baseCheckboxStyles } from 'assets/styles/components/Checkbox.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseButtonStyles(theme),
  ...baseCheckboxStyles(theme),
  tree: {
    '@global': {
      '.react-checkbox-tree': {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
      '.react-checkbox-tree > ol': {
        backgroundColor: theme.palette.grey[500],
        padding: theme.spacing(2),
        margin: '0',
        height: '350px',
        width: '400px',
        overflow: 'scroll',
        borderRadius: theme.spacing(0.625),
      },
      '.rct-options': {
        margin: theme.spacing(2, 0),
        display: 'flex',
        justifyContent: 'space-between',
      },
      '.rct-option': {
        border: 'none',
        padding: 0,
      },
      '.rct-text': {
        display: 'flex',
        alignItems: 'baseline',
      },
      '.rct-node-parent > ol': {
        paddingLeft: theme.spacing(4.5),
      },
      '.rct-text input': {
        display: 'none',
      },
      '.rct-text label, .rct-bare-label': {
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
      },
      '.rct-title': {
        paddingLeft: theme.spacing(2),
      },
      '.rct-collapse': {
        border: 'none',
        width: theme.spacing(4.5),
        padding: 0,
        background: 'transparent',
        outline: 'none',
      },
      ol: {
        listStyleType: 'none',
      },
      button: {
        cursor: 'pointer',
        background: 'none',
        outline: 'none',
      },
      'input[type="checkbox"]:disabled + .rct-checkbox svg': {
        fill: theme.palette.grey[200],
      },
    },
  },
  chevron: {
    fill: theme.palette.grey[800],
  },
  outline: {
    fill: theme.palette.grey[400],
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.875em',
    color: theme.palette.primary.main,
  },
  searchWrapper: {
    marginBottom: theme.spacing(2),
    '&--large': {
      marginBottom: theme.spacing(10),
    },
  },
  selectionOptions: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectingButton: {
    composes: '$outlinedPrimary',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.25, 0.6),
  },
}));

export default useStyles;
