import browserImageSize from 'browser-image-size';
import { CreativeType } from 'interfaces/generated.types';

import creativeUtils from './creatives';
import { parseDurationValue } from './numbers';

export const AUDIO_FILE_SIZE = 52428800;
export const IMAGE_FILE_SIZE = 524288;
export const CSV_FILE_SIZE = 128000;

export const AUDIO_FILE_TYPES = [
  'audio/mpeg',
  'audio/aac',
  'audio/mp4',
  'audio/wav',
  'audio/x-wav',
];
export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export const CSV_FILE_TYPE = 'text/csv';

const allowedImageDimensions = [
  { width: 300, height: 250 },
  { width: 272, height: 272 },
  { width: 400, height: 400 },
];
export const isImageSizeValid = async (
  file: any,
  creativeType: CreativeType
) => {
  if (creativeType === CreativeType.Audio) return true;

  const size = await browserImageSize(file);
  return allowedImageDimensions.some(
    (dimensionsCombination) =>
      size.width === dimensionsCombination.width &&
      size.height === dimensionsCombination.height
  );
};

const getAudioDuration = async (targetUrl: string, client: any) => {
  const { url } = await creativeUtils.fetchCreativeUrl({
    targetUrl,
    client,
  });
  return new Promise((resolve) => {
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata', (event: any) => {
      resolve(parseDurationValue(event.currentTarget.duration));
    });
  });
};

export const getImageDimensions = async (file: any) => {
  const size = await browserImageSize(file);
  return `${size.width}x${size.height}`;
};

export const isFileSizeValid = (
  fileSize: number,
  creativeType: CreativeType
) => {
  if (creativeType === CreativeType.Audio)
    return fileSize <= AUDIO_FILE_SIZE && fileSize > 0;
  return fileSize <= IMAGE_FILE_SIZE && fileSize > 0;
};

export const isFileTypeValid = (
  fileType: string,
  creativeType: CreativeType
) => {
  if (creativeType === CreativeType.Audio)
    return AUDIO_FILE_TYPES.includes(fileType);
  return IMAGE_FILE_TYPES.includes(fileType);
};

export default {
  isImageSizeValid,
  isFileSizeValid,
  isFileTypeValid,
  getAudioDuration,
};
