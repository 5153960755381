import * as Yup from 'yup';

import commons from 'validations/commons';

const NetworkFormValidation = Yup.object().shape({
  name: commons.name('Network Name'),
  territories: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    )
    .required('Network should be assigned at least one territory'),
});

export default NetworkFormValidation;
