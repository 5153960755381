import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import LinkedDealsTable from 'features/programmatic/deal/components/LinkedDealsTable/LinkedDealsTable';
import { History } from 'history';
import {
  Deal,
  useChannelAssociatedDealsQuery,
} from 'interfaces/generated.types';
import React from 'react';

const ChannelAssociatedDealsTable = ({
  history,
  channelId,
}: {
  history: History;
  channelId: string;
}) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useChannelAssociatedDealsQuery({
    variables: { id: channelId, territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  if (loading) return <Loader />;

  if (!loading && error) return <Redirecting history={history} />;

  return (
    <LinkedDealsTable
      deals={(data?.channel?.associatedDeals as Deal[]) || []}
      history={history}
    />
  );
};

export default ChannelAssociatedDealsTable;
