import {
  IOptionType,
  IParameterValueOption,
} from 'features/targetingV2/types/common';
import {
  IParameter,
  IParameterGroup,
  IParameterInfo,
} from 'features/targetingV2/types/targeting';
import { Cardinality, LocationParameterType } from 'interfaces/generated.types';

import { getSelectOption } from 'utils/dataTransformation';
import { getAudienceTargetingAttributes } from './audienceTargeting';
import { isTargetingParameterInvalid } from './common';
import { getCustomTargetingAttributes } from './customTargeting';
import { getDayTimeTargetingAttributes } from './dayTimeTargeting';
import { getLocationTargetingAttributes } from './locationTargeting';

type ExcludeParameterCallback = (
  currentParameterInfo: IParameterInfo
) => boolean;

const getAvailableParameterOptions = (
  allParametersInfo: IParameterInfo[],
  excludeParameter: ExcludeParameterCallback
) =>
  allParametersInfo.reduce(
    (result: IOptionType[], currentParameterInfo: IParameterInfo) => {
      if (excludeParameter(currentParameterInfo)) return result;

      result.push(getSelectOption(currentParameterInfo, 'name', 'type'));
      return result;
    },
    []
  );

export const getAvailableParameterOptionsBasedOnCardinality = (
  allParametersInfo: IParameterInfo[],
  alreadySelectedParameters: IParameter[],
  currentlySelectedParameterType?: string
) => {
  const excludeParameterOptionBasedOnCardinality = (
    currentParameterInfo: IParameterInfo
  ) => {
    const parameterOccurrences = alreadySelectedParameters.filter(
      (parameter) => parameter.type === currentParameterInfo.type
    );

    const alreadyAddedInOtherDropdown =
      parameterOccurrences.length > 0 &&
      currentParameterInfo.type !== currentlySelectedParameterType;

    return (
      (currentParameterInfo.cardinality === Cardinality.One ||
        currentParameterInfo.cardinality === Cardinality.OnePerClusivity) &&
      alreadyAddedInOtherDropdown
    );
  };

  return getAvailableParameterOptions(
    allParametersInfo,
    excludeParameterOptionBasedOnCardinality
  );
};

export const getLocationAvailableParameterOptions = (
  allParametersInfo: IParameterInfo[],
  alreadySelectedParameters: IParameter[],
  currentlySelectedParameterType?: string
) => {
  const excludeLocationParameterOption = (
    currentParameterInfo: IParameterInfo
  ) => {
    const geoParameterOccurrences = alreadySelectedParameters.filter(
      (parameter) => parameter.type === LocationParameterType.Geo
    );
    const maxParametersSelected =
      geoParameterOccurrences.length &&
      alreadySelectedParameters.length > geoParameterOccurrences.length;

    const excludeGeoOption =
      currentParameterInfo.type === LocationParameterType.Geo &&
      !!geoParameterOccurrences.length &&
      currentlySelectedParameterType !== LocationParameterType.Geo;

    const excludeOtherParametersFromGeoDropdown =
      maxParametersSelected &&
      currentParameterInfo.type !== LocationParameterType.Geo &&
      currentlySelectedParameterType === LocationParameterType.Geo;

    const excludeOtherParametersFromAddButton =
      currentParameterInfo.type !== LocationParameterType.Geo &&
      !geoParameterOccurrences.length &&
      !currentlySelectedParameterType;

    return (
      excludeGeoOption ||
      excludeOtherParametersFromGeoDropdown ||
      excludeOtherParametersFromAddButton
    );
  };

  return getAvailableParameterOptions(
    allParametersInfo,
    excludeLocationParameterOption
  );
};

export const getParameterValuesAttributes = (currentParameter: IParameter) =>
  currentParameter.values.map((parameterValue: IParameterValueOption) => ({
    id: parameterValue.value,
  }));

export const getParametersAttributes = (parameters: IParameter[]) =>
  parameters.reduce(
    (parametersAttributesResult: any[], currentParameter: IParameter) => {
      const currentParameterWithValidValues = {
        type: currentParameter.type,
        clusivity: currentParameter.clusivity,
        values: getParameterValuesAttributes(currentParameter),
      };
      const parameterAttributes = isTargetingParameterInvalid(
        currentParameterWithValidValues
      )
        ? null
        : currentParameterWithValidValues;

      if (parameterAttributes)
        parametersAttributesResult.push(parameterAttributes);

      return parametersAttributesResult;
    },
    []
  );

export const getParameterGroupsAttributes = (groups: IParameterGroup[]) =>
  groups.reduce(
    (groupsAttributesResult: any[], currentGroup: IParameterGroup) => {
      const groupAttributes: any = {
        parameters: getParametersAttributes(currentGroup.parameters),
      };

      if (groupAttributes.parameters.length > 0)
        groupsAttributesResult.push(groupAttributes);

      return groupsAttributesResult;
    },
    []
  );

export const getTechnologyTargetingAttributes = (formValues: any) => {
  if (
    !formValues?.targetingDefinitionV2?.technologyTargeting?.technologies ||
    !formValues.targetingDefinitionV2.technologyTargeting.technologies.length
  )
    return null;

  const technologiesAttributes = getParameterGroupsAttributes(
    formValues.targetingDefinitionV2.technologyTargeting.technologies
  );

  return !technologiesAttributes.length
    ? null
    : {
        technologies: technologiesAttributes,
      };
};

export const getInventoryTargetingAttributes = (formValues: any) => {
  if (
    !formValues?.targetingDefinitionV2?.inventoryTargeting?.inventories ||
    !formValues.targetingDefinitionV2.inventoryTargeting.inventories.length
  )
    return null;

  const inventoriesAttributes = getParameterGroupsAttributes(
    formValues.targetingDefinitionV2.inventoryTargeting.inventories
  );

  return !inventoriesAttributes.length
    ? null
    : {
        inventories: inventoriesAttributes,
      };
};

export const getTargetingDefinitionV2Attributes = (formValues: any) => ({
  audienceTargeting: getAudienceTargetingAttributes(formValues),
  locationTargeting: getLocationTargetingAttributes(formValues),
  technologyTargeting: getTechnologyTargetingAttributes(formValues),
  inventoryTargeting: getInventoryTargetingAttributes(formValues),
  dayTimeTargeting: getDayTimeTargetingAttributes(formValues),
  customTargeting: getCustomTargetingAttributes(formValues),
});

export const hasAvailableParameterOptions = (
  allParametersInfo: IParameterInfo[],
  alreadySelectedParameters: IParameter[]
) => {
  const selectedParameterTypes = alreadySelectedParameters.map(
    (parameter) => parameter.type
  );

  return allParametersInfo.some((parameterInfo) => {
    if (parameterInfo.cardinality === Cardinality.Many) return true;

    if (!selectedParameterTypes.includes(parameterInfo.type)) return true;
    return false;
  });
};
