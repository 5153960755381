import { makeStyles, Theme } from '@material-ui/core/styles';

const primaryControlStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 2, 0, 2),
  },
  title: {
    margin: 0,
    fontSize: '1.250em',
  },
  body: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  message: {
    textAlign: 'center',
  },
}));

export default primaryControlStyles;
