import {
  AdditionalEditingFunctionArgs,
  IAncestorType,
  IParameterValueOption,
  MenuListTitleEnum,
  NodeLike,
} from 'features/targetingV2/types/common';

import { convertArrayToObject, getToggledNode, removeNode } from './common';

export const findChildren = (
  node: NodeLike | undefined,
  options: IParameterValueOption[]
): NodeLike[] | undefined => {
  let matchingOptionChildren;

  for (const option of options) {
    if (node?.value === option.value) return option.children;
    if (option.children) {
      matchingOptionChildren = findChildren(node, option.children);
    }

    if (matchingOptionChildren) return matchingOptionChildren;
  }
  return undefined;
};

export const getDescendantsInFlatList = (
  node: NodeLike,
  options: IParameterValueOption[]
) => {
  const nodeChildren = node.children || findChildren(node, options);

  const descendantNodes = nodeChildren?.reduce(
    (nodeDescendants: NodeLike[], currentNode: NodeLike) => {
      nodeDescendants.push(currentNode);

      if (currentNode.children) {
        nodeDescendants.push(
          ...(getDescendantsInFlatList(currentNode, options) || [])
        );
      }

      return nodeDescendants;
    },
    []
  );

  return descendantNodes;
};

export const getNodesToBeSelected = (
  nodes: NodeLike[],
  nodeValuesToOmit: string[]
) =>
  nodes.reduce((newSelectedNodes: NodeLike[], currentNode: NodeLike) => {
    if (!nodeValuesToOmit.includes(currentNode.value))
      newSelectedNodes.push({
        ...currentNode,
        children: undefined,
      });
    else if (currentNode.children) {
      newSelectedNodes.push(
        ...getNodesToBeSelected(currentNode.children, nodeValuesToOmit)
      );
    }
    return newSelectedNodes;
  }, []);

export const getSelectedAncestor = (
  node: NodeLike | undefined,
  selectedNodes: NodeLike[]
) => {
  if (node?.ancestorList) {
    const ancestorIds = node.ancestorList.map(
      (ancestor: IAncestorType) => ancestor.id
    );

    const selectedNodesObject = convertArrayToObject(selectedNodes, 'value');

    const selectedAncestorId = ancestorIds.find(
      (ancestorId: string) => selectedNodesObject[ancestorId]
    );

    return selectedAncestorId ? selectedNodesObject[selectedAncestorId] : null;
  }

  return null;
};

export const removeNodes = (
  nodesToBeRemoved: NodeLike[] | undefined,
  selectedNodes: NodeLike[]
) => {
  if (!nodesToBeRemoved) return selectedNodes;

  const nodesToBeRemovedObject = convertArrayToObject(
    nodesToBeRemoved,
    'value'
  );
  return selectedNodes.filter((selectedNode) => {
    if (nodesToBeRemovedObject[selectedNode.value]) return false;
    return true;
  });
};

export const handlePopularInterestDeselection = (
  deselectedNode: NodeLike,
  allInterestsListSelectedNodes: NodeLike[],
  allInterestsListOptions: IParameterValueOption[]
) => {
  if (deselectedNode.active === false) {
    const filteredOtherListNodes = removeNode(
      allInterestsListSelectedNodes,
      deselectedNode
    );
    return filteredOtherListNodes;
  }

  let previouslySelectedAncestor = getSelectedAncestor(
    deselectedNode,
    allInterestsListSelectedNodes
  );

  if (previouslySelectedAncestor) {
    previouslySelectedAncestor = {
      ...previouslySelectedAncestor,
      children: findChildren(
        previouslySelectedAncestor,
        allInterestsListOptions
      ),
    };

    const deselectedNodeAncestorIds = deselectedNode.ancestorList.map(
      (ancestor: IAncestorType) => ancestor.id
    );

    const nodeValuesToOmit = [
      deselectedNode.value,
      ...deselectedNodeAncestorIds,
    ];

    // Omit any immediate ancestors of the deselected node and add any siblings, parents' siblings, etc
    const newSelectedDescendantNodes = getNodesToBeSelected(
      [previouslySelectedAncestor],
      nodeValuesToOmit
    );

    const filteredOtherListNodes = removeNode(
      allInterestsListSelectedNodes,
      previouslySelectedAncestor
    );

    return [...filteredOtherListNodes, ...newSelectedDescendantNodes];
  }

  return removeNode(allInterestsListSelectedNodes, deselectedNode);
};

export const handleInterestNodeToggle = ({
  newSelectedNodes,
  menuList,
  otherMenuList,
}: AdditionalEditingFunctionArgs) => {
  if (menuList.title === MenuListTitleEnum.PopularInterests && otherMenuList) {
    const deselectedNode = getToggledNode(
      newSelectedNodes,
      menuList.selectedNodes
    );

    if (deselectedNode) {
      const newNodes = handlePopularInterestDeselection(
        deselectedNode,
        otherMenuList.selectedNodes,
        otherMenuList.options
      );

      return newNodes;
    }

    const selectedNode = getToggledNode(
      menuList.selectedNodes,
      newSelectedNodes
    );

    if (selectedNode) {
      const nodesToBeRemoved = getDescendantsInFlatList(
        selectedNode,
        otherMenuList.options
      );

      return removeNodes(nodesToBeRemoved, otherMenuList.selectedNodes).concat(
        selectedNode
      );
    }
  }

  return newSelectedNodes;
};

export const getSelectedPopularInterests = (
  selectedNodes: NodeLike[],
  popularInterestOptions: IParameterValueOption[]
) => {
  const selectedNodesObject = convertArrayToObject(selectedNodes, 'value');

  const selectedPopularInterests = popularInterestOptions.filter(
    (popularInterestOption) => {
      if (selectedNodesObject[popularInterestOption.value]) return true;

      if (
        popularInterestOption.ancestorList &&
        popularInterestOption.active !== false
      ) {
        return popularInterestOption.ancestorList.some(
          (ancestor) => selectedNodesObject[ancestor.id]
        );
      }

      return false;
    }
  );

  return selectedPopularInterests;
};
