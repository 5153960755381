import { FieldArray, Field, getIn, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

import LocationBulkUpload from './LocationBulkUpload';

export const locationValues = {
  locationParams: {
    positions: [
      {
        latitude: '',
        longitude: '',
        radius: '',
      },
    ],
  },
};

const TargetingLocation = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index: locationIndex,
    touched,
    errors,
  } = props;

  const classes: any = targetingStyles({});

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <FieldArray
      name={`${targetingPath}.audienceParams.${locationIndex}.locationParams.positions`}
    >
      {({ push, remove }) => (
        <>
          <div className={classes.bulkUploadWrapper}>
            <LocationBulkUpload
              {...props}
              dataTc="locationBulkUpload"
              onUpload={(data) => {
                data.forEach((entry) => push(entry));
              }}
            />
          </div>
          {targetingValues.audienceParams[
            locationIndex
          ].locationParams.positions.map(
            (locationParam: any, index: number) => {
              const latitudeField = `${targetingPath}.audienceParams.${locationIndex}.locationParams.positions.${index}.latitude`;
              const longitudeField = `${targetingPath}.audienceParams.${locationIndex}.locationParams.positions.${index}.longitude`;
              const radiusField = `${targetingPath}.audienceParams.${locationIndex}.locationParams.positions.${index}.radius`;

              return (
                /* eslint-disable react/no-array-index-key */
                <div className={classes.wrapper} key={index}>
                  <Field
                    component={TextField}
                    data-tc="targetingLatitude"
                    label="Latitude"
                    placeholder="Latitude"
                    type="number"
                    name={latitudeField}
                    fullWidth
                    classes={{ root: classes.pairValues }}
                    disabled={
                      targetingValues.audienceParams[locationIndex]
                        .locationParams.positions[index].readOnly
                    }
                  />
                  <Field
                    component={TextField}
                    data-tc="targetingLongitude"
                    label="Longitude"
                    placeholder="Longitude"
                    type="number"
                    name={longitudeField}
                    fullWidth
                    classes={{ root: classes.pairValues }}
                    disabled={
                      targetingValues.audienceParams[locationIndex]
                        .locationParams.positions[index].readOnly
                    }
                  />
                  <Field
                    component={TextField}
                    label="Radius (km)"
                    placeholder="Radius"
                    type="number"
                    data-tc="targetingRadius"
                    name={radiusField}
                    fullWidth
                    classes={{ root: classes.pairValues }}
                    disabled={
                      targetingValues.audienceParams[locationIndex]
                        .locationParams.positions[index].readOnly
                    }
                  />
                  <div className={classes.buttons}>
                    <IconButton
                      data-tc="targetingLocationRemoveButton"
                      disabled={
                        targetingValues.audienceParams[locationIndex]
                          .locationParams.positions.length === 1 ||
                        targetingValues.audienceParams[locationIndex]
                          .locationParams.positions[index].readOnly
                      }
                      className={classes.button}
                      onClick={() => remove(index)}
                    >
                      <ClearIcon />
                      <span className="sr-only">Remove location</span>
                    </IconButton>
                    <IconButton
                      data-tc="targetingLocationAddButton"
                      test-id="targetingLocationAddButton"
                      onClick={() => {
                        push(locationValues.locationParams.positions[0]);
                      }}
                      className={classes.button}
                    >
                      <AddIcon />
                      <span className="sr-only">Add another location</span>
                    </IconButton>
                  </div>
                  {((getIn(touched, latitudeField) &&
                    getIn(errors, latitudeField)) ||
                    (getIn(touched, longitudeField) &&
                      getIn(errors, longitudeField)) ||
                    (getIn(touched, radiusField) &&
                      getIn(errors, radiusField))) && (
                    <div className={classes.errors}>
                      <FormHelperText error data-tc="targetingLocationError">
                        <span className={classes.errorMessage}>
                          <ErrorMessage name={latitudeField} />
                        </span>
                        <span className={classes.errorMessage}>
                          <ErrorMessage name={longitudeField} />
                        </span>
                        <span className={classes.errorMessage}>
                          <ErrorMessage name={radiusField} />
                        </span>
                      </FormHelperText>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </>
      )}
    </FieldArray>
  );
};

export default TargetingLocation;
