import gql from 'graphql-tag';

const SEARCH = gql`
  query Search($filter: SearchFilter!, $territories: [Territory!]!) {
    search(filter: $filter, territories: $territories) {
      id
      altId
      name
      type
      status
    }
  }
`;

export default SEARCH;
