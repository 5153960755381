import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import find from 'lodash/find';
import get from 'lodash/get';
import { History } from 'history';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';

import {
  TargetingPackTypeEnum,
  targetingPackTypes,
} from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';

import TableDeleteCell from 'components/Table/TableDeleteCell';
import { handleCellDelete } from 'utils/tables';
import { ITargetingPack } from 'graphql/targetingGroups/queries';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

interface ITargetingPacksRow {
  id: string;
  name: string;
  description: string;
  type: string;
}

interface ITargetingPacksTableProps {
  allTargetingPacks: ITargetingPack[];
  dataTc: string;
  type: TargetingPackTypeEnum;
  history: History;
}

const getMutation = (type: TargetingPackTypeEnum) =>
  get(find(targetingPackTypes as any, { type }), 'delete');

export const handleOnComplete = (history: History) => {
  history.go(0);
};

const TargetingPacksTable = ({
  allTargetingPacks,
  type,
  history,
}: ITargetingPacksTableProps) => {
  const useDeletePackMutation = getMutation(type);
  const [deletePack] = useDeletePackMutation({
    onCompleted() {
      handleOnComplete(history);
    },
  });

  const location = usePreviousLocation();

  const createTargetingPackCta = (
    <StyledLink
      location={{
        pathname: '/targeting-pack',
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Primary}
      data-tc="createTargetingPackButton"
    >
      Create Targeting Pack
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<ITargetingPacksRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/targeting-pack/${type}/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Description',
        accessor: 'description',
        style: {
          maxWidth: '500px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      {
        Header: 'Actions',
        accessor: '',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: {
            row: {
              original: { id, name },
            },
          },
          onCellDelete,
          setErrorModal,
          isEditable,
        }: CellProps<ITargetingPacksRow>) => (
          <TableDeleteCell
            onCellDelete={onCellDelete}
            setErrorModal={setErrorModal}
            entity={{ id, name }}
            isEditable={isEditable}
            dataTc="packDelete"
          />
        ),
      },
    ],
    [location.state, type]
  );

  return (
    <Table
      history={history}
      columns={columns}
      isPageTable={false}
      dataTc="listTargetingPacksTable"
      data={allTargetingPacks}
      onCellDelete={({ entity, setErrorModal }) =>
        handleCellDelete({
          variables: {
            id: entity.id,
          },
          deleteCell: deletePack,
          setErrorModal,
          errorModalContent: {
            title: 'Error',
            closeButton: 'Close',
            continueButton: '',
          },
        })
      }
      customToolbarCtas={createTargetingPackCta}
    />
  );
};

export default TargetingPacksTable;
