import gql from 'graphql-tag';

export const GET_ALL_USER_TYPES = gql`
  query AllUserTypes {
    allUserTypes {
      userType
      displayName
    }
  }
`;

export const GET_ALL_USERS = gql`
  query AllUsers($permissions: [PermissionName!], $territories: [Territory!]!) {
    allUsers(permissions: $permissions, territories: $territories) {
      id
      email
      status
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      status
      permissions {
        id
        name
      }
      entityPermissions {
        entityName
        entityId
        entityType
      }
      territories
      primaryTerritory
      userType
    }
  }
`;
