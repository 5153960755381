import { ITargetingDefinitionV2 } from 'features/targetingV2/types/targeting';
import { OptionType } from 'interfaces';
import {
  AuctionType,
  DealStatus,
  DspIncludedSeats,
  SalesChannelStatus,
  TargetingVersion,
  Territory,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import { CurrenciesEnum } from 'utils/currency';
import { TimeZones } from 'utils/date';
import { generatePriorityTypes } from 'utils/generators';
import {
  ITargetingGroup,
  ITargetingMasterTemplate,
} from 'utils/targetingDefinitions';

export const priorityTypes = [...generatePriorityTypes()];

export interface IDealFormValues {
  auctionType: AuctionType;
  offsetCpm: string;
  currency: CurrenciesEnum;
  endDate: Date | null;
  externalId: string;
  floorCpm: string;
  id: string;
  altId?: number;
  name: string;
  priority: number;
  dsp: OptionType | null;
  seats: OptionType[] | [];
  startDate: Date | null;
  overridePublisherFloor: boolean;
  privateAuction: boolean;
  salesChannelName: string;
  salesChannelId: string;
  timeZone: TimeZones;
  trusted: boolean;
  journey: string;
  owner: OptionType | null;
  status?: DealStatus;
  targetingGroups: ITargetingGroup[];
  targetingMasterTemplates: ITargetingMasterTemplate[];
  targetingGeneralGroup: ITargetingGroup | null;
  adSeparation: string;
  hasAdSeparation: boolean;
  noEndDate: boolean;
  adExclusive: boolean;
  territory: Territory;
  allSeats?: boolean;
  allKnownSeats?: boolean;
  dspIncludedSeats: DspIncludedSeats;
  targetingVersion: TargetingVersion;
  targetingDefinitionV2: ITargetingDefinitionV2 | null;
}

export const actionTypes = [
  {
    value: AuctionType.FirstPrice,
    label: '1st price',
  },
  {
    value: AuctionType.SecondPrice,
    label: '2nd price',
  },
  {
    value: AuctionType.FixedPrice,
    label: 'Fixed price',
  },
];

export const dealStatusValues = [
  {
    value: DealStatus.Draft,
    label: 'Draft',
    isDisabled: (value: DealStatus) =>
      [DealStatus.Ready, DealStatus.Active, DealStatus.Inactive].includes(
        value
      ),
  },
  {
    value: DealStatus.Ready,
    label: 'Ready',
    isDisabled: (value: DealStatus) =>
      [DealStatus.Draft, DealStatus.Active, DealStatus.Inactive].includes(
        value
      ),
  },
  {
    value: DealStatus.Active,
    label: 'Active',
    isDisabled: (value: DealStatus) =>
      [DealStatus.Draft, DealStatus.Ready].includes(value),
  },
  {
    value: DealStatus.Inactive,
    label: 'Inactive',
    isDisabled: (value: DealStatus) =>
      [DealStatus.Draft, DealStatus.Ready].includes(value),
  },
];

export const getDealStatusData = memoizeOne(
  (values: IDealFormValues, salesChannelStatus: SalesChannelStatus) => [
    {
      status: DealStatus.Draft,
      required: [
        ...(!values.name ? ["Complete 'Name'"] : []),
        ...(!values.salesChannelName ? ['Complete Parent Sales Channel'] : []),
      ],
    },
    {
      status: DealStatus.Ready,
      required: [
        ...(!values.name ? ['Waiting for Name'] : []),
        ...(salesChannelStatus !== SalesChannelStatus.Active
          ? ['Waiting for Parent Sales Channel to be in ACTIVE state']
          : []),
        ...(!values.externalId ? ['Waiting for External ID'] : []),
        ...(!values.startDate ? ['Waiting for Start Date'] : []),
        ...(values.auctionType === AuctionType.AuctionTypeUnspecified
          ? ['Waiting for Auction Type']
          : []),
        ...(!values.floorCpm ? ['Waiting for Floor CPM'] : []),
        ...(!values.priority ? ['Waiting for Priority'] : []),
      ],
    },
    {
      status:
        values.status === DealStatus.Inactive
          ? DealStatus.Inactive
          : DealStatus.Active,
      required: ['Waiting for Start Date'],
    },
  ]
);

export const dealDetailsFields = [
  'name',
  'owner',
  'priority',
  'auctionType',
  'currency',
  'floorCpm',
  'overridePublisherFloor',
  'offsetCpm',
  'timeZone',
  'startDate',
  'endDate',
  'adSeparation',
  'territory',
];

export const rtbConnectionFields = ['dsp', 'seats', 'externalId'];
