import { Reducer } from 'react';

export enum ActionTypes {
  ToggleNode = 'TOGGLE_NODE',
  CheckAll = 'CHECK_ALL',
  CheckNone = 'CHECK_NONE',
  ToggleExpanded = 'TOGGLE_EXPANDED',
  SearchNodes = 'SEARCH_NODES',
  InitSearch = 'INIT_SEARCH',
  ExpandAll = 'EXPAND_ALL',
  CollapseAll = 'COLLAPSE_ALL',
  UpdateState = 'UPDATE_STATE',
  UpdateExpanded = 'UPDATE_EXPANDED',
}

export enum SearchMatchingLogic {
  StartsWith = 'startsWith',
  Includes = 'includes',
}

export interface ToggleNodeAction {
  type: ActionTypes.ToggleNode;
  payload: ToggleNodePayload;
}

export interface CheckAllAction {
  type: typeof ActionTypes.CheckAll;
}

export interface CheckNoneAction {
  type: typeof ActionTypes.CheckNone;
}

export interface ExpandAllAction {
  type: typeof ActionTypes.ExpandAll;
}

export interface CollapseAllAction {
  type: typeof ActionTypes.CollapseAll;
}

export interface ToggleExpandedAction {
  type: typeof ActionTypes.ToggleExpanded;
  payload: ToggleNodePayload;
}

export interface SearchNodesAction {
  type: typeof ActionTypes.SearchNodes;
  payload: SearchNodePayload;
}

export interface InitSearchAction {
  type: typeof ActionTypes.InitSearch;
  payload: InitSearchPayload;
}
export interface UpdateStateAction {
  type: typeof ActionTypes.UpdateState;
  payload: UpdateStatePayload;
}

export interface UpdateExpandedAction {
  type: typeof ActionTypes.UpdateExpanded;
  payload: UpdateStatePayload;
}

export type CheckboxTreeActionTypes =
  | ToggleNodeAction
  | CollapseAllAction
  | ExpandAllAction
  | CheckAllAction
  | CheckNoneAction
  | ToggleExpandedAction
  | SearchNodesAction
  | InitSearchAction
  | UpdateStateAction
  | UpdateExpandedAction;

export interface CheckboxTreeCustomCallbacks {
  customToggleNode: () => void;
}

export interface NodesPayload {
  nodes: NodeLike[];
}

export interface ToggleNodePayload {
  id: string;
}

export interface SearchNodePayload {
  expanded: Record<string, boolean>;
}

export interface InitSearchPayload {
  term: string;
}
export interface UpdateStatePayload {
  selected: NodeLike[];
}

export interface CheckboxTreeInitialState {
  selected?: NodeLike[];
  areSelectedProvidedAsTrees?: boolean;
}
export interface CheckboxTreeProps {
  rawNodes: NodeLike[];
  initialState?: CheckboxTreeInitialState;
}

export enum CheckedStateEnum {
  Unchecked = 0,
  Checked = 1,
  Indeterminate = 2,
}

export interface CheckboxTreeState {
  allNodes: Node[];
  checked: Record<string, CheckedStateEnum>;
  expanded: Record<string, boolean>;
  nodesMap: Map<string, Node>;
  filter: string;
  searching: boolean;
  selectedNodesMap: Map<string, NodeLike>;
  areSelectedProvidedAsTrees?: boolean;
}

export interface NodeMinimal {
  id: string;
  label: string;
}

export interface NodeLike {
  value: string;
  label: string;
  children?: NodeLike[];
  [key: string]: any;
}

export interface Node {
  id: string;
  value: string;
  label: string;
  children?: Node[];
  parent?: Node;
  allChildrenSelected?: boolean;
  ancestorList?: NodeMinimal[];
  [key: string]: any;
}

export type CheckboxTreeReducer = Reducer<
  CheckboxTreeState,
  CheckboxTreeActionTypes
>;
