import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dropdownField: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    textAlign: 'center',
    fontSize: '15px',
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.grey[200],
    },
  },
  menuList: {
    columnCount: 3,
    columnRule: `2px solid ${theme.palette.grey[400]}`,
  },
  menuItem: {
    minHeight: '0px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export default useStyles;
