import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  breadcrumbLabel: {
    fontWeight: 500,
  },
  listContainer: {
    maxHeight: '210px',
    overflowY: 'auto',
  },
}));

export default useStyles;
