import React from 'react';

import Loader from 'components/Loader/Loader';

import { useLoaderStyles } from './Table.styles';

const TableLoader = () => {
  const classes = useLoaderStyles();
  return (
    <div className={classes.root} data-testid="tableLoader">
      <Loader />
    </div>
  );
};

export default TableLoader;
