import { IBreadcrumbLinkInfo } from 'components/BreadcrumbNav/BreadcrumbLink';
import {
  BreadcrumbNodeEnum,
  IBreadcrumbConfigItem,
} from 'components/BreadcrumbNav/BreadcrumbNav';
import {
  Ad,
  Advertiser,
  Agency,
  Campaign,
  EntityType,
  Order,
} from 'interfaces/generated.types';
import { getEntityPath, getPathPerEntityType } from './routing';

// Order in which the direct entities will be displayed in the breadcrumb nav
export enum DirectEntitiesOrder {
  'Agency',
  'Advertiser',
  'Order',
  'Campaign',
  'Ad',
}

type DirectEntity = Agency | Advertiser | Order | Campaign | Ad;

interface IDataFromDirectEntity {
  Agency: Agency | undefined;
  Advertiser: Advertiser | Advertiser[] | undefined;
  Order: Order | Order[] | undefined;
  Campaign: Campaign | Campaign[] | undefined;
  Ad: Ad | Ad[] | undefined;
}

// This function will correctly map the data based on the current entity (given it's a direct
// entity). So, for example, if we are on the Order details page, currentEntity will be an
// Order object and we need to know how to get data for the other entities from that object.
export const getDataFromDirectEntity = (
  currentEntity: DirectEntity
): IDataFromDirectEntity => {
  switch (currentEntity?.__typename) {
    case DirectEntitiesOrder[DirectEntitiesOrder.Agency]: {
      const agency = currentEntity as Agency;
      return {
        Agency: agency,
        Advertiser: agency.advertisers,
        Order: [],
        Campaign: [],
        Ad: [],
      };
    }
    case DirectEntitiesOrder[DirectEntitiesOrder.Advertiser]: {
      const advertiser = currentEntity as Advertiser;
      return {
        Agency: advertiser.agency,
        Advertiser: advertiser.agency?.advertisers,
        Order: advertiser.orders,
        Campaign: [],
        Ad: [],
      };
    }
    case DirectEntitiesOrder[DirectEntitiesOrder.Order]: {
      const order = currentEntity as Order;
      return {
        Agency: order.advertiser?.agency,
        Advertiser: order.advertiser,
        Order: order.advertiser?.orders,
        Campaign: order.campaigns,
        Ad: [],
      };
    }
    case DirectEntitiesOrder[DirectEntitiesOrder.Campaign]: {
      const campaign = currentEntity as Campaign;
      return {
        Agency: campaign.order?.advertiser?.agency,
        Advertiser: campaign.order?.advertiser,
        Order: campaign.order,
        Campaign: campaign.order?.campaigns,
        Ad: campaign.ads,
      };
    }
    case DirectEntitiesOrder[DirectEntitiesOrder.Ad]: {
      const ad = currentEntity as Ad;
      return {
        Agency: ad.campaign?.order?.advertiser?.agency,
        Advertiser: ad.campaign?.order?.advertiser,
        Order: ad.campaign?.order,
        Campaign: ad.campaign,
        Ad: ad.campaign?.ads,
      };
    }
    default:
      return {
        Agency: undefined,
        Advertiser: undefined,
        Order: undefined,
        Campaign: undefined,
        Ad: undefined,
      };
  }
};

export const formatBreadcrumbLinkInfo = (entity: DirectEntity | undefined) => {
  if (entity) {
    const entityBasePath = entity.__typename
      ? getPathPerEntityType(EntityType[entity.__typename])
      : '';

    const entityPath = getEntityPath(entity.id, entityBasePath);

    return {
      id: entity.id,
      name: entity.name || 'Undefined name', // Name is an optional field
      path: entityPath,
    } as IBreadcrumbLinkInfo;
  }
  return undefined;
};

export const formatBreadcrumbDropdownOptions = (
  list: DirectEntity[] | undefined
) => {
  if (list && list.length > 0) {
    return list.map((item: any) => formatBreadcrumbLinkInfo(item));
  }
  return [];
};

// Creates the config for the breadcrumb nav of the currentEntity details page - for
// direct entities
export const createDirectBreadcrumbConfig = (
  currentEntity: DirectEntity | undefined
) => {
  if (!currentEntity || !currentEntity.__typename) return [];

  const currentEntityIndex = DirectEntitiesOrder[currentEntity.__typename];

  const generatedConfig: IBreadcrumbConfigItem[] = [];

  const dataFromCurrentEntity = getDataFromDirectEntity(currentEntity);

  for (const item in DirectEntitiesOrder) {
    // Iterate only through index numbers of enum
    if (!Number.isNaN(Number(item))) {
      const itemIndex = Number(item);
      const itemName = DirectEntitiesOrder[item];
      const itemData =
        dataFromCurrentEntity[itemName as keyof IDataFromDirectEntity];

      // If the type of the item we are checking is before the current entity type, based on
      // the order the breadcrumb should show them (eg Agency comes before Campaign) or if
      // it's the first item in the order, then display as a link.
      if (
        currentEntityIndex > itemIndex ||
        (currentEntityIndex === 0 && currentEntityIndex === itemIndex)
      ) {
        const itemInfo = !Array.isArray(itemData)
          ? formatBreadcrumbLinkInfo(itemData)
          : undefined;

        const node = {
          type: BreadcrumbNodeEnum.Link,
          item: {
            label: itemName,
            info: itemInfo,
          },
        };
        generatedConfig.push(node);
      }
      // Otherwise display as a dropdown
      else {
        // Grandchildren should be disabled
        const disabled = itemIndex >= currentEntityIndex + 2;
        // If current entity has same type as item, then set selected value for the dropdown
        const selectedValue =
          itemIndex === currentEntityIndex ? currentEntity.id : undefined;
        const itemOptions = Array.isArray(itemData)
          ? formatBreadcrumbDropdownOptions(itemData)
          : [];

        const node = {
          type: BreadcrumbNodeEnum.Dropdown,
          item: {
            label: itemName,
            value: selectedValue,
            options: itemOptions,
            placeholder: `${itemName} Name`,
            disabled,
          },
        };
        generatedConfig.push(node);
      }
    }
  }

  return generatedConfig;
};

export default {
  createDirectBreadcrumbConfig,
  formatBreadcrumbLinkInfo,
  formatBreadcrumbDropdownOptions,
};
