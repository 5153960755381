import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import FormButtons from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import { adStatusValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { dealStatusValues } from 'features/programmatic/deal/components/DealTabsForm/DealFormValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFormProps } from 'interfaces';
import { Territory } from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleTabHistory } from 'utils/journeys';
import {
  ITargetingGroup,
  ITargetingTemplate,
  ITargetingTemplateLink,
} from 'utils/targetingDefinitions';

import { AppBar, Tabs } from '@material-ui/core';

import TargetingTemplateDetails from '../TargetingTemplateDetails/TargetingTemplateDetails';
import { targetingTemplateDetailsFields } from './TargetingTemplateFormValues';

export interface ITargetingTemplateValues {
  name: string;
  description: string;
  isMasterTemplate: boolean;
  targetingGroups: ITargetingGroup[];
  territory: Territory;
}

interface ITargetingTemplateProps {
  targetingTemplate?: ITargetingTemplate;
  history: History;
  isTemplateAssignedToActiveTerritory?: boolean;
}

const formatAdData = memoizeOne((assignedTo: ITargetingTemplateLink[]) =>
  assignedTo.filter((value) => !!value.adStatus)
);

const formatDealData = memoizeOne((assignedTo: ITargetingTemplateLink[]) =>
  assignedTo.filter((value) => !!value.dealStatus)
);

const getTargetingTemplateDetailsTabErrors = createNumberOfFormErrorsFn();

const TargetingTemplateTabsForm = (
  props: IFormProps<ITargetingTemplateValues> & ITargetingTemplateProps
) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    targetingTemplate,
    status,
    history,
    isTemplateAssignedToActiveTerritory,
  } = props;

  const [selectedTab, setTab] = useState(0);

  const location = usePreviousLocation();
  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const isTargetingTab = selectedTab === 0;
  const isListAdsTab = selectedTab === 1;
  const isListDealsTab = selectedTab === 2;

  const adColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<any>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/ad/${row.original.id}`,
              state: { parent: location.state, from: location.pathname },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'adStatus',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }: CellProps<any>) =>
          findLabelValue({
            collection: adStatusValues,
            lookupValue: value,
          }),
      },
    ],
    [location.pathname, location.state]
  );

  const dealColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<any>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/deal/${row.original.id}`,
              state: { parent: location.state, from: location.pathname },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'dealStatus',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }: CellProps<any>) =>
          findLabelValue({
            collection: dealStatusValues,
            lookupValue: value,
          }),
      },
    ],
    [location.pathname, location.state]
  );

  const classes = tabStyles();

  return (
    <>
      <Form className={classes.form} role="form">
        <AppBar position="static" classes={{ root: classes.bar }}>
          {targetingTemplate && targetingTemplate.isMasterTemplate ? (
            <Tabs
              value={selectedTab}
              onChange={(e, index) => {
                setTab(index);
                history && handleTabHistory(history, index);
              }}
              data-tc="targetingTemplateTabs"
            >
              <Tab
                label="Targeting"
                dataTc="targetingTab"
                numberOfTabErrors={getTargetingTemplateDetailsTabErrors(
                  status,
                  targetingTemplateDetailsFields
                )}
              />
              <Tab dataTc="listAdsTab" label="Ads" />
              <Tab dataTc="listDealsTab" label="Deals" />
            </Tabs>
          ) : (
            <Tabs value={selectedTab} data-tc="targetingTemplateTabs">
              <Tab label="Targeting" dataTc="targetingTab" />
            </Tabs>
          )}
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]: isListAdsTab || isListDealsTab,
            },
          ])}
        >
          {isTargetingTab && <TargetingTemplateDetails {...props} />}
          {isListAdsTab && targetingTemplate && (
            <Table
              history={history}
              title="All Ads"
              columns={adColumns}
              dataTc="listAdsTable"
              data={formatAdData(targetingTemplate.assignedTo)}
              isPageTable={false}
            />
          )}
          {isListDealsTab && targetingTemplate && (
            <Table
              history={history}
              title="All Deals"
              columns={dealColumns}
              dataTc="listDealsTable"
              data={formatDealData(targetingTemplate.assignedTo)}
              isPageTable={false}
            />
          )}
        </div>
        <FormButtons
          dataTc="submitTargetingTemplateButton"
          data-testid="submitTargetingTemplateButton"
          disabled={
            !isValid ||
            !dirty ||
            isSubmitting ||
            (!isTemplateAssignedToActiveTerritory && update)
          }
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/targeting-templates',
            state: location.state?.parent || {},
          }}
        >
          {update ? 'Save Changes' : 'Create Targeting Template'}
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !isSubmitting} />
    </>
  );
};

export default TargetingTemplateTabsForm;
