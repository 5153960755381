import { AuditChangeType } from 'interfaces/generated.types';

export const auditChangeValues = [
  {
    value: AuditChangeType.Create,
    label: 'Created',
  },
  {
    value: AuditChangeType.Update,
    label: 'Updated',
  },
  {
    value: AuditChangeType.Delete,
    label: 'Deleted',
  },
  {
    value: AuditChangeType.AutomaticTransition,
    label: 'Status Transition',
  },
];

export default {};
