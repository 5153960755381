import { PublisherInsightsFilterValue } from 'interfaces/generated.types';
import React from 'react';

import { Checkbox, Typography } from '@material-ui/core';

import useStyles from './CategoryValueItem.styles';

interface ICategoryValueItemProps {
  filterValue: PublisherInsightsFilterValue;
  isChecked: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

const CategoryValueItem = ({
  filterValue,
  isDisabled,
  isChecked,
  onClick,
}: ICategoryValueItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer} key={filterValue.id}>
      <Checkbox
        name={filterValue.displayName}
        checked={isChecked}
        disabled={isDisabled}
        onClick={() => onClick()}
        className={classes.checkbox}
        inputProps={{ 'aria-label': filterValue.displayName }}
      />
      <Typography>{filterValue.displayName}</Typography>
    </div>
  );
};

export default CategoryValueItem;
