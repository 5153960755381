import tabStyles from 'assets/styles/components/Tab.styles';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import Tab from 'components/Tab/Tab';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import AdTargetingTable from 'features/direct/ad/components/AdTargetingTable/AdTargetingTable';
import { History } from 'history';
import { Ad, Campaign, useCampaignAdsQuery } from 'interfaces/generated.types';
import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs/Tabs';

import CampaignModalDetails from '../CampaignModalDetails/CampaignModalDetails';
import useStyles from './CampaignModalTabs.styles';

interface ICampaignModalTabsProps {
  id: string;
  history: History;
}

const CampaignModalTabs = ({ id, history }: ICampaignModalTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const classes = useStyles();
  const tabClasses = tabStyles();

  const isCampaignDetails = selectedTab === 0;
  const isAdsTab = selectedTab === 1;

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { data, loading } = useCampaignAdsQuery({
    variables: { id, territories: [activeTerritory!] },
  });

  const formattedAds = data?.campaign?.ads.map(
    (ad) =>
      ({
        ...ad,
      } as Partial<Ad>)
  );

  if (!loading && !data?.campaign) return <Redirecting history={history} />;

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(_, index) => setSelectedTab(index)}
        className={classes.tabs}
        classes={{ indicator: tabClasses.indicator }}
      >
        <Tab label="Campaign Details" dataTc="campaignDetailsTab" />
        <Tab label="Ads" dataTc="adsTab" />
      </Tabs>
      {loading ? (
        <Loader />
      ) : (
        <>
          {isAdsTab && <AdTargetingTable ads={formattedAds || []} />}
          {isCampaignDetails && (
            <CampaignModalDetails campaign={data?.campaign as Campaign} />
          )}
        </>
      )}
    </>
  );
};

export default CampaignModalTabs;
