import { FormikProps } from 'formik';
import { EntityType, FrequencyCapTimeUnit } from 'interfaces/generated.types';

export interface ILocationState {
  entityList?: string;
  tab?: number;
  innerTab?: number;
  from?: string;
  parent?: any;
  table?: any;
  traceParent?: string;
  params?: any;
}

export interface ILocation {
  pathname: string;
  state: ILocationState;
}

export interface IOption<T> {
  label: string;
  value: T;
}

export interface OptionType extends IOption<string> {
  label: string;
  value: string;
  readOnly?: boolean;
}

export interface OptionGroupType {
  label: string;
  options: OptionType[];
}

export interface IEntity {
  id: string;
  altId?: number;
  name?: string;
}

export interface IFormValues {
  [key: string]: any;
}

export interface IFormProps<P> extends FormikProps<P> {
  update?: boolean;
  classes?: any;
}

export interface IFormWarningState {
  hasWarning: boolean;
  handleWarningContinue: () => void;
  handleWarningCancel?: () => void;
}

export interface IFieldValidate {
  entity: any;
  handleContinue: () => void;
  handleCancel?: () => void;
}

export interface IFieldUpdate {
  entity: {
    [key: string]: any;
  };
}

export interface ITreeItems {
  label: string;
  value: string;
  showCheckbox?: boolean;
  children?: ITreeItems[];
}

export interface ITreeItemsWithType extends ITreeItems {
  type: EntityType;
  children?: ITreeItemsWithType[];
}

export const FrequencyCapTimeUnits = [
  {
    value: FrequencyCapTimeUnit.TimeUnitUnspecified,
    label: undefined,
  },
  {
    value: FrequencyCapTimeUnit.Minutes,
    label: 'Minutes',
  },
  {
    value: FrequencyCapTimeUnit.Hours,
    label: 'Hours',
  },
  {
    value: FrequencyCapTimeUnit.Days,
    label: 'Days',
  },
  {
    value: FrequencyCapTimeUnit.Weeks,
    label: 'Weeks',
  },
  {
    value: FrequencyCapTimeUnit.Months,
    label: 'Months',
  },
];

export interface IChartSegmentData {
  id: string;
  category: string;
  value: number | undefined;
  color: string;
  icon?: string;
  percentage?: string;
  isActive?: boolean;
  [propName: string]: any;
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}
