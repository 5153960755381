import React from 'react';
import useStyles from './PrintedBlockList.styles';

interface IPrintedBlockListProps {
  label?: string;
  content: any;
  testId?: string;
}

const PrintedBlockList = ({
  label,
  content,
  testId,
}: IPrintedBlockListProps) => {
  const classes: any = useStyles();

  return (
    <>
      {label && <span>{label}</span>}
      <div className={classes.container} data-testid={testId}>
        {content}
      </div>
    </>
  );
};

export default PrintedBlockList;
