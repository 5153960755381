import memoizeOne from 'memoize-one';

import { AgencyStatus, Territory } from 'interfaces/generated.types';

export interface IAgencyValues {
  name: string;
  id: string;
  altId?: number;
  externalId: string;
  externalSystemId: string;
  journey: string;
  status?: AgencyStatus;
  territory?: Territory;
}

export const agencyStatusValues = [
  {
    value: AgencyStatus.Draft,
    label: 'Draft',
    isDisabled: (value: AgencyStatus) =>
      [AgencyStatus.Enabled, AgencyStatus.Disabled].includes(value),
  },
  {
    value: AgencyStatus.Enabled,
    label: 'Enabled',
  },
  {
    value: AgencyStatus.Disabled,
    label: 'Disabled',
  },
];

export const getAgencyStatusData = memoizeOne((values: IAgencyValues) => [
  {
    status: AgencyStatus.Draft,
    required: [...(!values.name ? ["Complete 'Name'"] : [])],
  },
  {
    status:
      values.status === AgencyStatus.Disabled
        ? AgencyStatus.Disabled
        : AgencyStatus.Enabled,
    required: [...(!values.name ? ['Waiting for Name'] : [])],
  },
]);

export const agencyDetailsFields = [
  'name',
  'externalId',
  'externalSystemId',
  'territory',
];
