export const formatListToString = ({
  list,
  separator = ', ',
  conjunctionSeparator = ' and ',
}: {
  list: any;
  separator?: string;
  conjunctionSeparator?: string;
}) => {
  const listStart = list.slice(0, -1).join(separator);
  const listEnd = list.slice(-1);
  const conjunction = list.length <= 1 ? '' : conjunctionSeparator;

  return [listStart, listEnd].join(conjunction);
};

export default { formatListToString };
