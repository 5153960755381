import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    textAlign: 'center',
    fontWeight: 400,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
