import CheckboxTree from 'features/targetingV2/components/core/CheckboxTree/CheckboxTree';
import { NodeLike } from 'features/targetingV2/types/common';
import { formatMenuTreeListSelection } from 'features/targetingV2/utils/formValuesFormatting';
import React from 'react';

import useStyles from './MenuTreeList.styles';

const MenuTreeList = (props: any) => {
  const classes = useStyles();

  const { options, selectProps, children } = props;

  const handleControlledNodeToggle = (newSelectedNodes: NodeLike[]) => {
    const formattedNodes = formatMenuTreeListSelection(
      newSelectedNodes,
      selectProps.value || []
    );

    selectProps.onChange(formattedNodes);
  };

  return options.length > 0 ? (
    <div className={classes.listContainer}>
      <CheckboxTree
        data={options as NodeLike[]}
        selected={(selectProps.value || []) as NodeLike[]}
        classes={{ label: classes.breadcrumbLabel }}
        isStateControlled
        filterText={selectProps.inputValue}
        onControlledNodeToggle={handleControlledNodeToggle}
        disableExpansionControls
        disableSelectionControls
        disableFilteringField
        displayAncestorPath={!!selectProps.inputValue}
        noNodesMessage={selectProps.noOptionsMessage()}
        searchResultsLimit={selectProps.maxSearchResults}
        testId={`${selectProps.testId}-list`}
      />
    </div>
  ) : (
    children
  );
};

export default MenuTreeList;
