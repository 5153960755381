import { IAdValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { adButtons } from 'features/direct/ad/components/AdTabsForm/AdTabsForm';
import {
  allCreativeStatuses,
  CreativeStatusEnum,
} from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import { ICreativeRedirect } from 'features/direct/creative/graphql/queries';
import { formatTargetingDefinitionV2 } from 'features/targetingV2/utils/formValuesFormatting';
import {
  Ad,
  Campaign,
  FrequencyCapTimeUnit,
  Territory,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import { createSelectOptions } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { numbroDecimalFormatting } from 'utils/numbers';
import { formatTargeting } from 'utils/targetingDefinitions';

export const transformCreativeRedirectData = (
  creativeRedirect: ICreativeRedirect
) => ({
  ...creativeRedirect,
  trackingUrls: creativeRedirect.trackingUrls.length
    ? creativeRedirect.trackingUrls
    : [{ url: '', consentVendorId: '' }],
});

export const transformCreativeData = (creatives: any[]) =>
  creatives.map((creative) => ({
    ...creative,
    status: allCreativeStatuses.includes(creative.status)
      ? creative.status
      : CreativeStatusEnum.HasCreative,
    trackingUrls: creative.trackingUrls.length
      ? creative.trackingUrls
      : [{ url: '', consentVendorId: '' }],
  }));

const formatInitialValues = (
  ad: Ad,
  activeTerritory: Territory | undefined
): IAdValues => {
  const targeting = formatTargeting(ad.targetingDefinition);

  return {
    duration: ad.duration ? ad.duration.toString() : '',
    name: ad.name as string,
    id: ad.id,
    altId: ad.altId,
    frequencyCapCount: ad.frequencyCapCount || '',
    frequencyCapTimeUnit: ad.frequencyCapTimeUnit as FrequencyCapTimeUnit,
    frequencyCapValue: ad.frequencyCapValue || '',
    campaignName: (ad.campaign as Campaign).name as string,
    campaignId: (ad.campaign as Campaign).id,
    campaignCpm:
      typeof (ad.campaign as Campaign).cpm === 'number'
        ? numbro((ad.campaign as Campaign).cpm).format(numbroDecimalFormatting)
        : '',
    campaignStartDate: ad.campaign?.startDate
      ? dateUtils.getDateInSpecificTimezone(
          ad.campaign?.startDate,
          (ad.campaign as Campaign).timeZone
        )
      : null,
    startDate: ad.startDate.value
      ? dateUtils.getDateInSpecificTimezone(
          ad.startDate.value,
          (ad.campaign as Campaign).timeZone
        )
      : null,
    startDateIsInherited: ad.startDate.isInherited,
    isStartDateInheritable: true,
    endDate: ad.endDate.value
      ? dateUtils.getDateInSpecificTimezone(
          ad.endDate.value,
          (ad.campaign as Campaign).timeZone
        )
      : null,
    endDateIsInherited: ad.endDate.isInherited,
    isEndDateInheritable: true,
    dailyCap: ad.dailyCap
      ? numbro(ad.dailyCap).format(numbroDecimalFormatting)
      : '',
    weighting: ad.weighting || '',
    creativeRedirect: ad.creativeRedirect
      ? transformCreativeRedirectData(ad.creativeRedirect as ICreativeRedirect)
      : {
          url: null,
          id: null,
          altId: null,
          consentVendorId: null,
          trackingUrls: [],
          territory: activeTerritory!,
        },
    audioCreatives: ad.creativeAudio
      ? transformCreativeData([ad.creativeAudio])
      : [],
    imageCreatives: transformCreativeData(ad.creativeImages),
    sequence: ad.sequence,
    targetingMasterTemplates: targeting.templates,
    targetingGroups: targeting.groups,
    targetingGeneralGroup: targeting.general,
    tags: createSelectOptions(ad.tags),
    tagsAreInherited: ad.tagsAreInherited,
    inheritedTags: createSelectOptions(ad.inheritedTags),
    journey: adButtons[0].url,
    status: ad.status,
    adExclusive: ad.adExclusive || false,
    territory: ad.territory!,
    targetingDefinitionV2: formatTargetingDefinitionV2(
      ad.targetingDefinitionV2
    ),
    targetingVersion: (ad.campaign as Campaign).targetingVersion,
    targetingRestrictions: ad.targetingRestrictions,
  };
};

export default formatInitialValues;
