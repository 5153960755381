import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import DataControl from 'components/DataControl/DataControl';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import DatacentersTable from 'features/programmatic/datacenter/components/DatacentersTable/DatacentersTable';
import DspsTable from 'features/programmatic/dsp/components/DspsTable/DspsTable';
import TechnicalProviderDetails from 'features/programmatic/technicalProvider/components/TechnicalProviderDetails/TechnicalProviderDetails';
import {
  getTechnicalProviderStatusData,
  ITechnicalProviderValues,
  technicalProviderDataControlFields,
  technicalProviderDetailsFields,
  technicalProviderStatusValues,
} from 'features/programmatic/technicalProvider/components/TechnicalProviderTabsForm/TechnicalProviderFormValues';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFieldUpdate, IFieldValidate, IFormProps } from 'interfaces';
import { DataCenter, Dsp, EntityType } from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

export const selectCorrectTab = (location?: any) => {
  if (location && location.state) {
    const { entityList, tab } = location.state;

    if (entityList) {
      if (entityList === 'listDatacenters') return 2;
      if (entityList === 'listDsps') return 3;
    }

    if (tab) return tab;
  }
  return 0;
};

export const technicalProviderButtons = [
  {
    text: 'Save and Review',
    url: '/programmatic-connector/ID',
    update: true,
    dataTc: 'technicalProviderReviewButton',
  },
  {
    text: 'Save and Create DSP',
    url: '/programmatic-connector/ID/dsp',
    update: false,
    dataTc: 'technicalProviderCreateDspButton',
  },
  {
    text: 'Save and Create Datacenter',
    url: '/programmatic-connector/ID/datacenter',
    update: false,
    dataTc: 'technicalProviderCreateDatacenterButton',
  },
  {
    text: 'Save and View All Programmatic Connectors',
    url: '/programmatic-connectors',
    update: false,
    dataTc: 'technicalProviderListButton',
  },
];

interface ITechnicalProviderProps {
  hasEditPermissions?: boolean;
  update?: boolean;
  datacenters?: DataCenter[];
  dsps?: Dsp[];
  history?: History;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  match?: {
    params: {
      technicalProviderId: string;
    };
  };
}

const getDetailsTabErrors = createNumberOfFormErrorsFn();

const TechnicalProviderTabsForm = (
  props: IFormProps<ITechnicalProviderValues> & ITechnicalProviderProps
) => {
  const {
    setFieldValue,
    values,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    update = false,
    hasEditPermissions = true,
    datacenters,
    dsps,
    history,
    match,
    status,
    onFieldUpdate,
    onFieldValidate,
  } = props;

  const classes = tabStyles();

  const location = usePreviousLocation();

  const [selectedTab, setTab] = useState(selectCorrectTab(location));
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const isTechnicalProviderTab = selectedTab === 0;
  const isDataControlTab = selectedTab === 1;
  const isListDatacentersTab = selectedTab === 2;
  const isListDspsTab = selectedTab === 3;
  const isTechnicalProviderActivityTab = selectedTab === 4;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && (
          <>
            <EntityProgressBar
              statusData={getTechnicalProviderStatusData(values)}
              currentStatus={values.status || ''}
              dataTc="technicalProviderStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={technicalProviderStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="technicalProviderHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="techProviderTabs"
          >
            <Tab
              dataTc="techProviderDetailsTab"
              numberOfTabErrors={getDetailsTabErrors(
                status,
                technicalProviderDetailsFields
              )}
              label="Details"
            />
            <Tab
              dataTc="dataControlTab"
              label="Data Control"
              numberOfTabErrors={getDetailsTabErrors(
                status,
                technicalProviderDataControlFields
              )}
            />
            <Tab
              disabled={!update}
              dataTc="listDatacentersTab"
              label="Datacenters"
            />
            <Tab disabled={!update} dataTc="listDspsTab" label="DSPs" />
            <Tab
              disabled={!update}
              dataTc="technicalProviderActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListDatacentersTab ||
                isListDspsTab ||
                isTechnicalProviderActivityTab,
            },
          ])}
        >
          {isTechnicalProviderTab && <TechnicalProviderDetails {...props} />}
          {isDataControlTab && <DataControl {...props} />}
          {isListDatacentersTab && history && match && datacenters && (
            <DatacentersTable
              history={history}
              match={match}
              datacenters={datacenters}
              isEditable={hasEditPermissions}
            />
          )}
          {isListDspsTab && history && match && dsps && (
            <DspsTable
              history={history}
              match={match}
              dsps={dsps}
              isEditable={hasEditPermissions}
            />
          )}
          {isTechnicalProviderActivityTab && (
            <ActivityTable
              entityType={EntityType.Technicalprovider}
              id={values.id}
              data-tc="technicalProviderActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitTechnicalProviderButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/programmatic-connectors',
            state: location.state?.parent || {},
          }}
          variant={FormButtonVariants.split}
          buttons={technicalProviderButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default TechnicalProviderTabsForm;
