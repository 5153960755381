import colors from 'assets/styles/colors';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  labelSection: {
    display: 'flex',
    margin: theme.spacing(0, 1),
  },
  labelText: ({ disabled }: { disabled: boolean }) => ({
    fontSize: '12px',
    color: disabled ? theme.palette.grey[400] : colors.primaryBlue,
  }),
  iconContainer: {
    display: 'flex',
    marginRight: theme.spacing(0.5),
    '&--positionedRight': { marginLeft: theme.spacing(0.5), marginRight: 0 },
  },
  defaultIcon: ({ disabled }: { disabled: boolean }) => ({
    width: '16px',
    height: '16px',
    '& > path': {
      stroke: disabled ? theme.palette.grey[400] : colors.primaryBlue,
    },
  }),
  sliderSection: {
    flex: 4,
    marginRight: '12px',
    margin: theme.spacing('auto', 1),
  },
  slider: ({ disabled }: { disabled: boolean }) => ({
    color: disabled ? theme.palette.grey[400] : colors.primaryBlue,
    '& .MuiSlider-mark': {
      width: '4px',
      height: '4px',
      top: '12px',
      borderRadius: '50%',
      boxShadow: '0 0 0 4px white',
      backgroundColor: disabled
        ? theme.palette.grey[300]
        : 'rgba(0, 90, 166, 0.4)',
    },
    '& .MuiSlider-markActive': {
      borderRadius: '50%',
      backgroundColor: disabled ? theme.palette.grey[400] : colors.primaryBlue,
      opacity: 1,
    },
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiSlider-thumb': {
      width: '12px',
      height: '12px',
      boxShadow: '0 0 0 4px white',
      marginLeft: '-4px',
      '&:hover, &:focus': {
        boxShadow: `0 0 0 6px ${colors.primaryBlueMediumLight}`,
      },
    },
    '& .MuiSlider-thumb.Mui-disabled': {
      marginTop: '-5px',
    },
  }),
}));

export default useStyles;
