import { ReactComponent as RemoveIcon } from 'assets/icons/TargetingV2/dax-removeIcon.svg';
import { ReactComponent as TargetIcon } from 'assets/icons/TargetingV2/dax-target.svg';
import classNames from 'classnames';
import IconButtonMenu from 'features/targetingV2/components/core/IconButtonMenu/IconButtonMenu';
import Loader from 'features/targetingV2/components/core/Loader';
import Accuracy from 'features/targetingV2/components/LocationSection/Accuracy/Accuracy';
import { ISliderValue } from 'features/targetingV2/types/common';
import { getSliderValue } from 'features/targetingV2/utils/accuracy';
import React from 'react';

import useStyles from './AccuracyRow.styles';

export interface IAccuracyRowProps {
  setFieldValue: (field: string, value: any) => void;
  allAccuracyValues: ISliderValue[];
  areAccuraciesLoading: boolean;
  defaultAccuracy: number;
  selectedAccuracy: number;
  fieldName: string;
  menuIconButtonLabel: string;
  externalClasses?: any;
  handleAccuracyDeletion: () => void;
}

const AccuracyRow = (props: IAccuracyRowProps) => {
  const {
    allAccuracyValues,
    areAccuraciesLoading,
    defaultAccuracy,
    selectedAccuracy,
    setFieldValue,
    fieldName,
    menuIconButtonLabel,
    externalClasses,
    handleAccuracyDeletion,
  } = props;
  const classes = useStyles();

  const onSliderChange = (newValue: number | string) =>
    setFieldValue(fieldName, newValue);

  const contextMenuOptions = [
    {
      MenuItemIcon: <RemoveIcon />,
      menuItemIconLabel: 'Remove',
      menuItemAction: handleAccuracyDeletion,
    },
  ];

  return (
    <div
      data-testid="accuracy-row"
      className={classNames([classes.accuracyContainer, externalClasses])}
    >
      {areAccuraciesLoading ? (
        <Loader />
      ) : (
        <>
          <Accuracy
            label="with accuracy"
            AccuracyIcon={<TargetIcon />}
            sliderValue={getSliderValue(selectedAccuracy, defaultAccuracy)}
            onSliderChange={onSliderChange}
            allAccuracyValues={allAccuracyValues}
          />
          <IconButtonMenu
            menuIconButtonLabel={menuIconButtonLabel}
            menuOptions={contextMenuOptions}
          />
        </>
      )}
    </div>
  );
};

export default AccuracyRow;
