import { endOfDay, isAfter, isBefore, startOfDay, subDays } from 'date-fns';
import dateUtils, { TimeZones } from 'utils/date';

export const getInitialDealHealthDateRange = (
  startDate: string,
  endDate: string,
  timeZone: TimeZones
) => {
  const currentDate = startOfDay(
    dateUtils.getDateInSpecificTimezone(new Date().toISOString(), timeZone)
  );
  let initialStartDate = startOfDay(
    dateUtils.getDateInSpecificTimezone(
      subDays(new Date(), 6).toISOString(),
      timeZone
    )
  );
  let initialEndDate = endOfDay(
    dateUtils.getDateInSpecificTimezone(new Date().toISOString(), timeZone)
  );

  const dealStartDate = startDate
    ? startOfDay(dateUtils.getDateInSpecificTimezone(startDate, timeZone))
    : null;
  const dealEndDate = endDate
    ? endOfDay(dateUtils.getDateInSpecificTimezone(endDate, timeZone))
    : null;

  if (dealStartDate && dealEndDate && isBefore(dealEndDate, initialStartDate)) {
    initialStartDate = startOfDay(subDays(dealEndDate, 6));
    initialEndDate = dealEndDate;
  } else if (
    dealStartDate &&
    isAfter(dealStartDate, initialStartDate) &&
    !isAfter(dealStartDate, currentDate)
  ) {
    initialStartDate = dealStartDate;
  }

  return { initialStartDate, initialEndDate };
};

export default { getInitialDealHealthDateRange };
