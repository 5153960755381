import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  postcodeWrapper: {
    margin: theme.spacing(0, 1.5),
  },
  uploadPostcodesContainer: {
    marginBottom: 0,
  },
  uploadPostcodesTextfield: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
  uploadPostcodesError: {
    margin: 0,
    fontSize: theme.spacing(1.625),
    color: theme.palette.error.main,
  },
}));

export default useStyles;
