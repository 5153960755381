export const UPDATE_TABLE_FILTERS = 'UPDATE_TABLE_FILTERS';
export const UPDATE_ACTIVE_TERRITORY = 'UPDATE_ACTIVE_USER_TERRITORY';
export const UPDATE_USER_TERRITORIES = 'UPDATE_USER_TERRITORIES';

const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_TABLE_FILTERS:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.parentPageName]: {
            ...state.pages[action.payload.parentPageName],
            filters: action.payload.filters,
            globalFilter: action.payload.globalFilter,
          },
        },
      };

    case UPDATE_USER_TERRITORIES:
      return {
        ...state,
        user: {
          ...state.user,
          territories: action.payload,
        },
      };

    case UPDATE_ACTIVE_TERRITORY:
      return {
        ...state,
        user: {
          ...state.user,
          activeTerritory: action.payload,
        },
      };
    default:
      return state;
  }
};

const SessionedReducer = (state: any, action: any) => {
  const newState = Reducer(state, action);

  window.sessionStorage.setItem('state', JSON.stringify(newState));

  return newState;
};

export default SessionedReducer;
