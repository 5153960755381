import memoizeOne from 'memoize-one';
import React from 'react';

import Loader from 'components/Loader/Loader';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';

import { IFormProps } from 'interfaces';
import { useAllDeviceTypesQuery } from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

export const deviceTypeValues = {
  deviceTypeParams: {
    deviceTypes: [],
  },
};

const formatData = memoizeOne((data: string[]) =>
  data.map((deviceType: string) => ({
    label: deviceType,
    value: deviceType,
  }))
);

const TargetingDeviceType = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const { loading, data } = useAllDeviceTypesQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <TargetingMultiSelect
      {...props}
      label="Device Type"
      targetingName="deviceTypeParams.deviceTypes"
      options={
        data && data.allDeviceTypes ? formatData(data.allDeviceTypes) : []
      }
    />
  );
};

export default TargetingDeviceType;
