import Loader from 'components/Loader/Loader';
import { useCustomApolloContext } from 'context/CustomApolloProvider/CustomApolloProvider';
import { Territory } from 'interfaces/generated.types';
import isEqual from 'lodash/isEqual';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { AuthState } from 'utils/authProvider';

import { useAuthContext } from '../AuthProvider/AuthProvider';
import { useUserContext } from '../UserProvider/UserProvider';
import reducer, {
  UPDATE_ACTIVE_TERRITORY,
  UPDATE_USER_TERRITORIES,
} from './reducer';
import initialSessionState, { SessionState } from './sessionState';

const SessionContext = createContext<{
  state: SessionState;
  dispatch: React.Dispatch<any>;
}>({ state: initialSessionState, dispatch: () => null });

export const getState = (sessionState: any) => {
  if (!sessionState) {
    return initialSessionState;
  }

  const initialStateKeys = Object.keys(initialSessionState);
  const sessionStateKeys = Object.keys(sessionState);

  const sameKeys = isEqual(initialStateKeys, sessionStateKeys);

  return sameKeys ? sessionState : initialSessionState;
};

const SessionContextProvider: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { state: authState } = useAuthContext();
  const { territories, primaryTerritory, fetchingUserDetails } =
    useUserContext();

  const [updatingSessionState, setUpdatingSessionState] =
    useState<boolean>(true);

  const sessionState = JSON.parse(
    window.sessionStorage.getItem('state') || 'null'
  );

  const {
    state: { assumedId, updatingUserWithAssumedDetails },
  } = useCustomApolloContext();

  const [state, dispatch] = useReducer(reducer, getState(sessionState));

  const updateSessionContext = (
    newTerritories: Territory[],
    newPrimaryTerritory: Territory | undefined
  ) => {
    dispatch({
      type: UPDATE_USER_TERRITORIES,
      payload: newTerritories,
    });
    dispatch({
      type: UPDATE_ACTIVE_TERRITORY,
      payload: newPrimaryTerritory,
    });
    setUpdatingSessionState(false);
  };

  const userDetailsAlreadyFetched =
    authState === AuthState.LoggedIn && !fetchingUserDetails;

  useEffect(() => {
    setUpdatingSessionState(true);

    if (!sessionState && userDetailsAlreadyFetched) {
      updateSessionContext(territories, primaryTerritory);
    } else {
      setUpdatingSessionState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, fetchingUserDetails]);

  useEffect(() => {
    if (
      userDetailsAlreadyFetched &&
      !updatingUserWithAssumedDetails &&
      !isEqual(territories, state.user.territories)
    ) {
      setUpdatingSessionState(true);

      dispatch({
        type: UPDATE_USER_TERRITORIES,
        payload: territories,
      });

      if (!territories.includes(state.user.activeTerritory)) {
        dispatch({
          type: UPDATE_ACTIVE_TERRITORY,
          payload: primaryTerritory,
        });
      }

      setUpdatingSessionState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assumedId]);

  if (updatingSessionState || fetchingUserDetails) return <Loader />;

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);

export default SessionContextProvider;
