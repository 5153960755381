import * as Yup from 'yup';

const ForgottenPasswordValidation = Yup.object().shape({
  loginID: Yup.string()
    .trim()
    .required('Please enter an email')
    .email('Must be valid email')
    .test(
      'email',
      'Please enter a valid email',
      (email?: string) => !!(email && !email.endsWith('@global.com'))
    ),
});

export default ForgottenPasswordValidation;
