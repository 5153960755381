import { History } from 'history';
import React from 'react';

import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';

import CreateSeatContainer from 'features/programmatic/seat/containers/CreateSeatContainer/CreateSeatContainer';

import { Dsp, useDspMinimalQuery } from 'interfaces/generated.types';

interface ICreateSeatPage {
  history: History;
  match: {
    params: {
      dspId: string;
    };
  };
}

const CreateSeatPage = ({ history, match }: ICreateSeatPage) => {
  const { loading, error, data } = useDspMinimalQuery({
    variables: { id: match.params.dspId },
  });
  if (!loading && (error || !(data && data.dsp)))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Seat</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreateSeatContainer
            history={history}
            match={match}
            dsp={data?.dsp as Dsp}
          />
        )}
      </Container>
    </main>
  );
};

export default CreateSeatPage;
