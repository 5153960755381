import Container from 'components/Container/Container';
import MultiselectModal from 'components/MultiselectModal/MultiselectModal';
import { UPDATE_ACTIVE_TERRITORY } from 'context/SessionProvider/reducer';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
    endOfDay, endOfYesterday, format, parse, startOfDay, startOfToday, subDays
} from 'date-fns';
import InsightsDateRangeModal from 'features/insights/components/InsightsDateRangeModal/InsightsDateRangeModal';
import InsightsLineChartsContainer from 'features/insights/containers/InsightsLineChartsContainer/InsightsLineChartsContainer';
import useUrlSearchParams from 'hooks/UrlParams/useUrlSearchParams';
import useUrlSearchParamUtils from 'hooks/UrlParams/useUrlSearchParamUtils';
import { IDateRange, OptionType } from 'interfaces';
import { Territory } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDefaultCurrencyBasedOnTerritory } from 'utils/defaultsByTerritory';
import { ISelectedFilter } from 'utils/insights';

import { Grid, Typography } from '@material-ui/core';

import CurrencyDropdown from '../components/CurrencyDropdown/CurrencyDropdown';
import ExportDimensionsMenu from '../components/ExportDimensionsMenu/ExportDimensionsMenu';
import InsightsFilterDialog from '../components/InsightsFilterDialog/InsightsFilterDialog';
import useStyles from './MonetizeInsightsPage.styles';

const MonetizeInsightsPage = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const urlSearchParams = useUrlSearchParams(search);
  const { setUrlParam, getUrlParam, deleteUrlParam } =
    useUrlSearchParamUtils(urlSearchParams);

  let initialDateRange: IDateRange;
  interface IQueryParams {
    territory?: string;
    currency?: string;
    filters?: string;
    startDate?: string;
    endDate?: string;
  }

  const urlParams: IQueryParams = Array.from(urlSearchParams.keys()).reduce(
    (next, param) => {
      const queryParam = getUrlParam(param);
      return queryParam ? { ...next, [param]: queryParam } : next;
    },
    {}
  );

  if ((urlParams.startDate as string) && (urlParams.endDate as string)) {
    const formattedStartDate = startOfDay(
      parse(urlParams.startDate as string, 'dd-MM-yyyy', new Date())
    );
    const formattedEndDate = endOfDay(
      parse(urlParams.endDate as string, 'dd-MM-yyyy', new Date())
    );

    initialDateRange = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  } else {
    initialDateRange = {
      startDate: subDays(startOfToday(), 7),
      endDate: endOfYesterday(),
    };
  }

  const [dateRange, setDateRange] = useState<IDateRange>(initialDateRange);

  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilter[]>(
    urlParams.filters ? JSON.parse(urlParams.filters) : []
  );

  const formattedSelectedFilters: OptionType[] = selectedFilters.map(
    (selectedFilter) => ({
      label: `${selectedFilter.filterCategory.displayName} (${selectedFilter.ids.length})`,
      value: `${selectedFilter.filterCategory.type}`,
    })
  );

  const {
    state: {
      user: { activeTerritory, territories },
    },
    dispatch,
  } = useSessionContext();

  const [selectedCurrency, setSelectedCurrency] = useState(
    getDefaultCurrencyBasedOnTerritory(activeTerritory!)
  );

  useEffect(() => {
    if (
      urlParams.territory &&
      urlParams.territory !== activeTerritory &&
      territories?.includes(urlParams.territory as Territory)
    ) {
      dispatch({
        type: UPDATE_ACTIVE_TERRITORY,
        payload: urlParams.territory,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUrlParam('territory', activeTerritory);
    setSelectedCurrency(getDefaultCurrencyBasedOnTerritory(activeTerritory!));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTerritory]);

  useEffect(() => {
    setUrlParam('startDate', format(dateRange.startDate, 'dd-MM-yyyy'));
    setUrlParam('endDate', format(dateRange.endDate, 'dd-MM-yyyy'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      const formattedFiltersToString = JSON.stringify(selectedFilters);
      setUrlParam('filters', formattedFiltersToString);
    } else {
      deleteUrlParam('filters');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  return (
    <main>
      <Container>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item classes={{ item: classes.container }}>
            <InsightsDateRangeModal
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Grid>
          <Grid item xs={8}>
            <MultiselectModal
              label="Filter"
              items={formattedSelectedFilters}
              useWhiteBackground
              CustomDialog={InsightsFilterDialog}
              customDialogProps={{
                previouslySelectedFilters: selectedFilters,
                setPreviouslySelectedFilters: setSelectedFilters,
              }}
              id="insightsFilter"
              removeItem={(item: string) => {
                const updatedSelectedFilters = selectedFilters.filter(
                  (filter) => filter.filterCategory.type !== item
                );
                setSelectedFilters(updatedSelectedFilters);
              }}
            />
          </Grid>
          <Grid item>
            <ExportDimensionsMenu
              selectedDateRange={dateRange}
              selectedFilters={selectedFilters}
              selectedCurrency={selectedCurrency}
            />
          </Grid>
        </Grid>
        <InsightsLineChartsContainer
          testId="insightsLineChartsContainer"
          selectedDateRange={dateRange}
          selectedFilters={selectedFilters}
          selectedCurrency={selectedCurrency}
        />
      </Container>
      <Grid container className={classes.currencyContainer}>
        <CurrencyDropdown
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
        />
        <Typography variant="body1" className={classes.footerText}>
          These are indicative figures not to be used for billing.
        </Typography>
      </Grid>
    </main>
  );
};

export default MonetizeInsightsPage;
