const globalStandardPrimary = '#2f84c6';
const globalStandardSecondary = '#CBE0F1';
const globalStandardTertiary = '#EBF3FA';

const daxStatusError = '#C25600';
const daxStatusWarning = '#C20000';
const daxStatusConfirmation = '#318c05';

const daxPrimaryBlue = '#244185';
const daxSecondaryBlue = '#C8CFE0';
const daxBlue50 = '#F2F8FD';
const daxBlue100 = '#C1DFF6';
const daxBlue700 = '#0055a4';
const daxBlue800 = '#20427E';
const daxBlue800Light = 'rgba(32, 66, 126, 0.2)';
const daxTertiaryBlue = '#E9ECF2';
const daxSecondaryBlueHover = '#AFB9D3';
const daxSecondaryBlueClick = '#92A0C2';
const daxPrimaryGreen = '#029093';
const daxSecondaryGreen = '#80C7C9';
const daxTertiaryGreen = '#BFE3E4';

const daxGreyscaleWhite = '#FFFFFF';
const daxGreyscaleOffWhite = '#f2f2f2';
const daxGreyscaleStandardGrey = '#9E9E9E';
const daxGreyscaleLightGrey = '#D1D1D1';
const daxGreyscaleDarkGrey = '#4E4E4E';
const daxGreyscaleBlack = '#000000';
const daxGreyscaleNeutral = '#7C7C7C';
const daxGreyscaleSilver = '#757575';
const daxGrey50 = '#F8F8F8';
const daxGrey100 = '#DADADA';
const daxGrey600 = '#686868';
const daxGrey900 = '#323232';

const daxOrange50 = '#FEF6EE';
const daxOrange100 = '#FFD2AC';
const daxOrange500 = '#D45B07';

const mainText = '#282828';
const errorText = '#A30000';
const errorHighlight = 'rgba(163,0,0,0.1)';

const primaryBlue = '#005AA6';
const primaryBlueLight = 'rgba(0, 90, 166, 0.05)';
const primaryBlueMediumLight = 'rgba(0, 90, 166, 0.1)';
const backgroundLightBlue = '#E5F0F9';

const boxShadowLightGrey = 'rgba(0, 0, 0, 0.15)';

const secondaryBase = '#6dd2f5';
const neutral = '#232323';

export default {
  daxStatusError,
  daxGreyscaleStandardGrey,
  daxGreyscaleOffWhite,
  daxGreyscaleDarkGrey,
  globalStandardPrimary,
  globalStandardTertiary,
  daxStatusWarning,
  daxSecondaryBlueHover,
  daxSecondaryBlueClick,
  daxStatusConfirmation,
  globalStandardSecondary,
  daxPrimaryBlue,
  daxSecondaryBlue,
  daxTertiaryBlue,
  daxPrimaryGreen,
  daxSecondaryGreen,
  daxTertiaryGreen,
  daxGreyscaleWhite,
  daxGreyscaleLightGrey,
  daxGreyscaleBlack,
  daxGreyscaleSilver,
  daxGreyscaleNeutral,
  daxGrey50,
  daxGrey100,
  daxGrey600,
  daxGrey900,
  boxShadowLightGrey,
  mainText,
  errorText,
  primaryBlue,
  primaryBlueLight,
  primaryBlueMediumLight,
  secondaryBase,
  daxBlue50,
  daxBlue100,
  daxBlue700,
  daxBlue800,
  daxBlue800Light,
  neutral,
  daxOrange50,
  daxOrange100,
  daxOrange500,
  errorHighlight,
  backgroundLightBlue,
};
