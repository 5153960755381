import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { TargetingPackTypeEnum } from 'features/targeting/components/TargetingPackForm/TargetingPackFormValues';
import TargetingPacksTable from 'features/targeting/components/TargetingPacksTable/TargetingPacksTable';
import { History } from 'history';
import {
  PostcodeGroup,
  useAllPostcodeGroupsQuery,
} from 'interfaces/generated.types';
import React from 'react';

interface ITargetingPostcodePacksTabProps {
  history: History;
}

const TargetingPostcodePacksTab = ({
  history,
}: ITargetingPostcodePacksTabProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useAllPostcodeGroupsQuery({
    variables: { territories: [activeTerritory!] },
    errorPolicy: 'all',
  });

  if (!loading && error) return <Redirecting history={history} />;

  return loading ? (
    <Loader />
  ) : (
    <TargetingPacksTable
      allTargetingPacks={(data?.allPostcodeGroups as PostcodeGroup[]) || []}
      dataTc="listPostcodePacksTable"
      type={TargetingPackTypeEnum.POSTCODE}
      history={history}
    />
  );
};

export default TargetingPostcodePacksTab;
