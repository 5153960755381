import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 0.5),
    alignItems: 'flex-start',
  },
  customAccuracyInput: {
    borderRadius: '0px',
  },
}));

export default useStyles;
