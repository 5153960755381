import { ICampaignValues } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import { FormikHelpers as FormikActions } from 'formik';
import { History } from 'history';
import { IFormValues } from 'interfaces';
import {
  Campaign,
  UpdateCampaignMutation,
  UpdateCampaignMutationFn,
} from 'interfaces/generated.types';
import { differenceInValues, handleFormErrors } from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { getTargetingDefinitionForUpdate } from 'utils/targetingDefinitions';

export const handleOnComplete = async ({
  response,
  history,
  selectedJourney,
}: {
  response: UpdateCampaignMutation;
  history: History;
  selectedJourney: string;
}) => {
  const { updateCampaign } = response;
  const { id } = updateCampaign as Campaign;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
  });
};

export const submitUpdateCampaign =
  (
    updateCampaign: UpdateCampaignMutationFn,
    initialValues: ICampaignValues,
    toggleErrorModal: () => void,
    setSelectedJourney: any
  ) =>
  (
    formValues: ICampaignValues,
    { setStatus, setSubmitting }: FormikActions<ICampaignValues>
  ) => {
    setSelectedJourney(formValues.journey);

    const filteredValues: any = {
      ...differenceInValues(
        {
          ...formValues,
          journey: null,
          unlimitedObjective: null,
          targetingGroups: null,
          targetingMasterTemplates: null,
          targetingGeneralGroup: null,
          ...getTargetingDefinitionForUpdate(formValues),
        },
        {
          ...initialValues,
          journey: null,
          unlimitedObjective: null,
          targetingGroups: null,
          targetingMasterTemplates: null,
          targetingGeneralGroup: null,
          ...getTargetingDefinitionForUpdate(initialValues),
        }
      ),
      id: formValues.id,
    } as IFormValues;

    delete filteredValues.targetingDefinitionV2;

    updateCampaign({
      variables: filteredValues,
    }).catch((error) =>
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      })
    );
  };
