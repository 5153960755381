import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import {
  TableBody as MUITableBody,
  TableBodyProps as MUITableBodyProps,
} from '@material-ui/core';

import useStyles from './TableBody.styles';

export type TableBodyProps = MUITableBodyProps;

const TableBody = React.forwardRef(
  (props: TableBodyProps, ref: React.Ref<any>) => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);

    return <MUITableBody ref={ref} classes={classes} {...otherProps} />;
  }
);

TableBody.displayName = 'TableBody';

export default TableBody;
