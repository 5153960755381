import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ErrorDialog from 'features/targetingV2/components/core/ErrorDialog/ErrorDialog';
import LimitLocationCheckbox from 'features/targetingV2/components/LocationSection/LimitLocationCheckbox/LimitLocationCheckbox';
import Location from 'features/targetingV2/components/LocationSection/Location/Location';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import useError from 'features/targetingV2/hooks/Error/useError';
import useLimitLocation from 'features/targetingV2/hooks/Location/useLimitLocation/useLimitLocation';
import { IFormProps, SectionType } from 'features/targetingV2/types/common';
import { ILocationParameterGroup } from 'features/targetingV2/types/targeting';
import { getAllAccuracyValues } from 'features/targetingV2/utils/accuracy';
import { defaultParameter } from 'features/targetingV2/utils/defaults';
import { FieldArray } from 'formik';
import {
  Territory,
  useAllLocationAccuraciesQuery,
  useAllLocationParametersQuery,
  useGetLocationParameterValueByTerritoryQuery,
} from 'interfaces/generated.types';
import React, { useEffect } from 'react';
import { getSelectOptions } from 'utils/dataTransformation';
import { TerritoryLabelShort } from 'utils/territory';

const LocationBuilder = (props: IFormProps<any>) => {
  const { values, setFieldValue } = props;

  const locationsFieldName =
    'targetingDefinitionV2.locationTargeting.locations';

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const territoryForLocationLimit: Territory =
    values.territory || activeTerritory;

  const {
    loading: loadingLocationParameters,
    data,
    error,
  } = useAllLocationParametersQuery({
    variables: {
      territory: territoryForLocationLimit,
    },
    fetchPolicy: 'cache-first',
  });

  const { loading: loadingAccuracyValues, data: dataAccuracyValues } =
    useAllLocationAccuraciesQuery({
      fetchPolicy: 'cache-first',
    });

  const allAccuracyValues = getAllAccuracyValues(
    [...(dataAccuracyValues?.allLocationAccuracies.allValues || [])].reverse()
  );

  const accuracyRadiusDefaultValue =
    dataAccuracyValues?.allLocationAccuracies.defaultValue;

  const { hasError, toggleErrorModal, errorMessages, setErrorMessages } =
    useError(['An error has occurred.']);

  const handleError = (err: any) => {
    const errorHeader =
      'Error while loading location targeting parameters. Please refresh and try again.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const locations = values.targetingDefinitionV2.locationTargeting
    ?.locations as ILocationParameterGroup[];

  const showAddButton = locations.every((loc) => !loc.parameters.length);

  const {
    loading: loadingLimitLocationParameterValue,
    data: limitLocationData,
  } = useGetLocationParameterValueByTerritoryQuery({
    variables: { territory: territoryForLocationLimit },
    fetchPolicy: 'cache-first',
  });

  const limitLocationCountryLabel =
    TerritoryLabelShort[territoryForLocationLimit];

  const { isLocationLimited, setIsLocationLimited } = useLimitLocation(
    locations,
    locationsFieldName,
    setFieldValue,
    limitLocationData
  );

  const allParametersInfo = data?.allLocationParameters || [];

  const getNewLocation = (parameterType: string) => ({
    ...locations[0],
    parameters: [
      {
        ...defaultParameter,
        type: parameterType,
        isFocused: true,
      },
    ],
  });

  const targetingSectionHeader = (
    <>
      <LimitLocationCheckbox
        countryLabel={limitLocationCountryLabel}
        fieldName={`${locationsFieldName}[0].limitLocationParameterValue`}
        limitLocationParameterValueFromTerritory={
          limitLocationData?.getLocationParameterValueByTerritory
        }
        loading={loadingLimitLocationParameterValue}
        isLocationLimited={isLocationLimited}
        setIsLocationLimited={setIsLocationLimited}
        setFieldValue={setFieldValue}
      />
      {showAddButton && (
        <AddParameterDropdownButton
          options={getSelectOptions(allParametersInfo, 'name', 'type')}
          onOptionClick={(parameterType: string) => {
            setFieldValue(locationsFieldName, [getNewLocation(parameterType)]);
          }}
          loading={loadingLocationParameters}
          testId="add-first-location-parameter-btn"
        />
      )}
    </>
  );

  return (
    <>
      <TargetingSection
        headerChildren={targetingSectionHeader}
        sectionType={SectionType.Location}
      >
        {!loadingLocationParameters && (
          <FieldArray name={locationsFieldName}>
            {() =>
              locations.map(
                (location: ILocationParameterGroup, index: number) => (
                  <Location
                    // eslint-disable-next-line react/no-array-index-key
                    key={`location-${index}`}
                    location={location}
                    locationIndex={index}
                    locationFieldName={`${locationsFieldName}[${index}]`}
                    accuracyRadiusDefaultValue={accuracyRadiusDefaultValue}
                    allAccuracyValues={allAccuracyValues}
                    areLocationAccuraciesLoading={loadingAccuracyValues}
                    allParametersInfo={data?.allLocationParameters || []}
                    areParameterTypeOptionsLoading={loadingLocationParameters}
                    setFieldValue={setFieldValue}
                    limitLocationCountryLabel={limitLocationCountryLabel}
                    limitLocationParameterValueFromTerritory={
                      limitLocationData?.getLocationParameterValueByTerritory
                    }
                    isLocationLimited={isLocationLimited}
                  />
                )
              )
            }
          </FieldArray>
        )}
      </TargetingSection>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        name="allLocationParameterError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default LocationBuilder;
