import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import Grid from '@material-ui/core/Grid';

import formStyles from 'assets/styles/components/Form.styles';

import { IFormProps } from 'interfaces';
import { AgencyStatus, Territory } from 'interfaces/generated.types';
import { TerritoryLabel } from 'utils/territory';
import { MenuItem } from '@material-ui/core';
import { advertiserAndAgencyExternalSystems } from 'utils/defaultExternalSystems';

interface IAgencyValues {
  name: string;
  id: string;
  altId?: number;
  externalId: string;
  externalSystemId: string;
  journey: string;
  status?: AgencyStatus;
  territory?: Territory;
}

export interface IAgencyProps {
  update: boolean;
}

const AgencyDetails = ({
  values,
  errors,
  touched,
  update = false,
  status = {},
}: IFormProps<IAgencyValues> & IAgencyProps) => {
  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Agency Name"
            placeholder="Agency Name"
            name="name"
            autoFocus={!update}
            data-tc="agencyName"
            fullWidth
            inputProps={{ 'data-testid': 'agencyName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Agency ID"
              placeholder="Agency ID"
              name="altId"
              data-tc="agencyId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={8}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="External System "
            placeholder="External System"
            name="externalSystemId"
            data-tc="agencyExternalSystemId"
            inputProps={{ 'data-testid': 'agencyExternalSystemId-input' }}
            fullWidth
            helperText={
              (touched.externalSystemId && errors.externalSystemId) ||
              status.externalSystemId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalSystemId && errors.externalSystemId) ||
                status.externalSystemId
              ),
            }}
          >
            <MenuItem value="" />
            {advertiserAndAgencyExternalSystems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="External ID"
            placeholder="External ID"
            name="externalId"
            data-tc="agencyExternalId"
            fullWidth
            helperText={
              (touched.externalId && errors.externalId) || status.externalId
            }
            FormHelperTextProps={{
              error: !!(
                (touched.externalId && errors.externalId) ||
                status.externalId
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default AgencyDetails;
