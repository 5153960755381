import debounce from 'debounce-promise';
import { GET_AUDIENCE_PARAMETER_VALUES } from 'features/targetingV2/graphql/audience/queries';
import { GET_INVENTORY_PARAMETER_VALUES } from 'features/targetingV2/graphql/inventory/queries';
import { GET_LOCATION_PARAMETER_VALUES } from 'features/targetingV2/graphql/location/queries';
import { GET_TECHNOLOGY_PARAMETER_VALUES } from 'features/targetingV2/graphql/technology/queries';
import { SectionType } from 'features/targetingV2/types/common';
import { DocumentNode } from 'graphql';
import { InventoryStatus, Territory } from 'interfaces/generated.types';

import { formatParameterValuesResponse } from './formValuesFormatting';

export interface IAsyncParameterValuesQueryDetails {
  queryDoc: DocumentNode;
  path: string;
  fetchPolicy: string;
  variables?: any;
}

export const getAsyncParameterValuesQueryDetails = (
  sectionType: SectionType,
  territory?: Territory
): IAsyncParameterValuesQueryDetails => {
  const defaultFetchPolicy = 'cache-first';
  switch (sectionType) {
    case SectionType.Audience:
      return {
        queryDoc: GET_AUDIENCE_PARAMETER_VALUES,
        fetchPolicy: defaultFetchPolicy,
        path: 'audienceParameterValues',
      };
    case SectionType.Location:
      return {
        queryDoc: GET_LOCATION_PARAMETER_VALUES,
        fetchPolicy: 'no-cache',
        path: 'locationParameterValues',
      };
    case SectionType.Technology:
      return {
        queryDoc: GET_TECHNOLOGY_PARAMETER_VALUES,
        fetchPolicy: defaultFetchPolicy,
        path: 'technologyParameterValues',
      };
    case SectionType.Inventory:
      return {
        queryDoc: GET_INVENTORY_PARAMETER_VALUES,
        fetchPolicy: 'no-cache',
        path: 'inventoryParameterValues',
        variables: {
          territory,
          status: InventoryStatus.Enabled,
        },
      };

    default:
      return {
        queryDoc: GET_TECHNOLOGY_PARAMETER_VALUES,
        fetchPolicy: defaultFetchPolicy,
        path: '',
      };
  }
};

export const loadOptions = async (
  searchValue: string,
  minimumCharactersRequired: number,
  parameterType: string,
  sectionType: SectionType,
  idUsedToLimitParameterValues: string,
  client: any,
  setFetchedOptions: any,
  territory?: Territory
) => {
  if (!parameterType) return [];

  if (searchValue.length >= minimumCharactersRequired) {
    const queryDetails = getAsyncParameterValuesQueryDetails(
      sectionType,
      territory
    );
    const { data } = await client.query({
      query: queryDetails.queryDoc,
      variables: {
        ...queryDetails.variables,
        type: parameterType,
        search: searchValue,
      },
    });

    if (data && data[queryDetails.path]) {
      const formattedParameterValues = formatParameterValuesResponse(
        data[queryDetails.path],
        sectionType,
        undefined,
        true
      );

      let parameterValue = null;
      if (idUsedToLimitParameterValues) {
        parameterValue = formattedParameterValues.find(
          (option) => option.value === idUsedToLimitParameterValues
        );
      }

      const newOptions = parameterValue?.children || formattedParameterValues;

      setFetchedOptions(newOptions);
      return newOptions;
    }

    return [];
  }

  return [];
};

export const debouncedLoadOptions = debounce(loadOptions, 250, {
  leading: true,
});
