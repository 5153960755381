import useStyles from 'components/DonutChart/DonutChart.styles';
import Loader from 'components/Loader/Loader';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IChartSegmentData } from 'interfaces';
import { Campaign } from 'interfaces/generated.types';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { Filters, Row } from 'react-table';

import { GET_ALL_CAMPAIGNS_FOR_GRAPH_DATA } from '../../graphql/queries';
import {
  onSegmentSelection,
  updateHistoryWithSelectedSegment,
} from './CampaignsHealthOverviewUtils';
import {
  ChartIdType,
  formatCampaignGraphData,
  getConfigForCampaignDeliveryCharts,
} from './CampaignsHealthOverviewValues';

const DonutChart = lazy(() => import('components/DonutChart/DonutChart'));

export interface ICampaignsHealthOverviewDonutsProps {
  data?: any[];
  tableFilters: Filters<Row<Record<string, unknown>>>;
  campaignsFilter?: any;
  history?: History;
  setAllFilters: (filters: any[]) => void;
}

const CampaignsHealthOverviewDonuts = ({
  data,
  tableFilters,
  campaignsFilter,
  history,
  setAllFilters,
}: ICampaignsHealthOverviewDonutsProps) => {
  const classes = useStyles();
  const location = usePreviousLocation();
  const { table } = location.state || 0;
  const segmentStoredInHistory = table?.customHeader?.selectedSegment;

  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(
    segmentStoredInHistory || ''
  );

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [graphData, setGraphData] = useState<any[]>(data || []);

  const queryVariables = {
    variables: {
      ...(campaignsFilter ? { filter: campaignsFilter } : {}),
      territories: [activeTerritory!],
    },
  };

  const [fetchGraphData, { loading: fetchingData, error: fetchingError }] =
    useLazyQuery<{
      allCampaigns: Campaign[];
    }>(GET_ALL_CAMPAIGNS_FOR_GRAPH_DATA, {
      ...queryVariables,
      onCompleted: (result) => {
        if (result?.allCampaigns) {
          setGraphData(formatCampaignGraphData(result?.allCampaigns));
        }
      },
    });

  const chartHomePageOrder: ChartIdType[] = [
    'recentlyEnded',
    'endingSoon',
    'live',
  ];

  const charts = getConfigForCampaignDeliveryCharts(
    graphData,
    selectedSegmentId,
    chartHomePageOrder
  );

  const handleSegmentSelection = (segment: IChartSegmentData | undefined) => {
    onSegmentSelection(
      segment,
      location,
      setSelectedSegmentId,
      setAllFilters,
      history
    );
  };

  useEffect(() => {
    if (tableFilters.length === 0) {
      setSelectedSegmentId('');
      updateHistoryWithSelectedSegment('', location, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters]);

  useEffect(() => {
    if (!data && fetchGraphData) {
      fetchGraphData();
    }
  }, [data, campaignsFilter, fetchGraphData]);

  return (
    <>
      {fetchingData ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div
          className={classes.graphsWrapper}
          data-testid="campaignsHealthOverview-donuts"
        >
          {fetchingError ? (
            <div>There was an error loading the graph data</div>
          ) : (
            <>
              <Suspense fallback={<Loader />}>
                {charts.map((chart) => (
                  <DonutChart
                    key={chart.id}
                    label={chart.label}
                    segments={chart.segments}
                    onClick={handleSegmentSelection}
                    isBlurred={
                      !!selectedSegmentId &&
                      !selectedSegmentId.includes(`${chart.id}-`)
                    }
                    testId={`${chart.id}CampaignsChart`}
                  />
                ))}
              </Suspense>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CampaignsHealthOverviewDonuts;
