import { Row } from 'react-table';
import memoizeOne from 'memoize-one';

export const includesSome = memoizeOne(
  (rows: Row[], ids: string[], filterValues: string[]) =>
    rows.filter((row) =>
      ids.some((id) => {
        const rowValues = String(row.values[id]).split(',');
        const isReset = filterValues.every((filterValue) => filterValue === '');
        return isReset
          ? true
          : rowValues &&
              rowValues.length &&
              filterValues
                .filter((filterValue) => filterValue !== '')
                .some((filterValue) =>
                  rowValues.some((rowValue) => rowValue === filterValue)
                );
      })
    )
);

export default { includesSome };
