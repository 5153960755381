import { isArray, isBoolean } from 'lodash';

export const isBetweenInclusive = (value: number, min: number, max: number) =>
  value >= min && value <= max;

export const isNumber = (value: any) =>
  !Number.isNaN(Number(value)) &&
  value !== null &&
  value !== undefined &&
  !isBoolean(value) &&
  !isArray(value) &&
  value.toString().trim() !== '';
