import React from 'react';

import { SearchResult } from 'interfaces/generated.types';
import { getEntityPath, getPathPerEntityType } from 'utils/routing';

import useStyles from './SearchField.styles';

export interface SearchOptionProps {
  option: SearchResult;
}

const SearchOption = ({ option }: SearchOptionProps) => {
  const classes = useStyles();

  const basePath = getPathPerEntityType(option.type);
  const optionUrl = getEntityPath(option.id, basePath);

  return (
    <a
      href={optionUrl}
      className={classes.optionWrapper}
      data-tc={`searchOption-${option.id}`}
    >
      <div className={classes.optionField}>
        <span className={classes.optionTextWrapper} data-tc="searchOptionName">
          {option.name}
        </span>
      </div>
      <div className={`${classes.optionField} ${classes.optionFieldSecondary}`}>
        <span className={classes.optionTextWrapper} data-tc="searchOptionType">
          {option.type}
        </span>
      </div>
      <div className={`${classes.optionField} ${classes.optionFieldSecondary}`}>
        <span className={classes.optionTextWrapper} data-tc="searchOptionAltId">
          {option.altId}
        </span>
      </div>
    </a>
  );
};

export default SearchOption;
