import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import formStyles from 'assets/styles/components/Form.styles';

import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';

import { IFormProps } from 'interfaces';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';

export interface ITagValues {
  name: string;
  description: string;
}

const TagForm = ({
  handleSubmit,
  isSubmitting,
  isValid,
  dirty,
  touched,
  errors,
  status = {},
}: IFormProps<ITagValues>) => {
  const classes = formStyles();

  const location = usePreviousLocation();

  return (
    <>
      <Form className={classes.form} data-tc="userForm">
        <fieldset className={classes.fieldset}>
          <legend className={classes.legend} data-tc="tagLegend">
            Create Tag
          </legend>
          <div className={classes.formWrapper}>
            <Field
              component={TextField}
              label="Name"
              name="name"
              autoFocus
              placeholder="Name"
              data-tc="tagNameField"
              required
              fullWidth
              margin="normal"
              inputProps={{ 'data-testid': 'tagName' }}
              helperText={(touched.name && errors.name) || status.name}
              FormHelperTextProps={{
                error: !!((touched.name && errors.name) || status.name),
              }}
            />
            <Field
              component={TextField}
              label="Description"
              name="description"
              placeholder="Description"
              data-tc="tagDescriptionField"
              fullWidth
              margin="normal"
              inputProps={{ 'data-testid': 'tagDescription' }}
              helperText={
                (touched.description && errors.description) ||
                status.description
              }
              FormHelperTextProps={{
                error: !!(
                  (touched.description && errors.description) ||
                  status.description
                ),
              }}
            />
          </div>
        </fieldset>
        <FormButtons
          dataTc="createTagButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={{
            pathname: '/reporting-tags',
            state: location.state?.parent || {},
          }}
          variant={FormButtonVariants.single}
        >
          Create Tag
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !isSubmitting} />
    </>
  );
};

export default TagForm;
