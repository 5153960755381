import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  table: {
    margin: theme.spacing(5, 0),
  },
  tabTable: {
    padding: theme.spacing(1.25, 0),
  },
  title: {
    fontSize: '7.5em',
    color: theme.palette.primary.main,
    margin: 0,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '1.875em',
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    margin: 0,
    textAlign: 'center',
    marginTop: theme.spacing(-2),
  },
  message: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
