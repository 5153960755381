import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';

import formStyles from 'assets/styles/components/Form.styles';

import MultiSelect from 'components/MultiSelect/MultiSelect';

import { ISalesChannelValues } from 'features/programmatic/salesChannel/components/SalesChannelTabsForm/SalesChannelValues';
import { TerritoryLabel } from 'utils/territory';

import { IFormProps, OptionType } from 'interfaces';
import { User } from 'interfaces/generated.types';

import { createUserOption } from 'utils/dataTransformation';

export interface ISalesChannelDetailsProps {
  update?: boolean;
  allUsers: User[];
}

const SalesChannelDetails = ({
  errors,
  setFieldTouched,
  setFieldValue,
  touched,
  update = false,
  values,
  allUsers,

  status = {},
}: IFormProps<ISalesChannelValues> & ISalesChannelDetailsProps) => {
  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <MultiSelect
              id="salesChannelAgency"
              data-tc="salesChannelAgencyMultiSelect"
              isDisabled={update}
              isMulti={false}
              selectAll={false}
              label="Agency"
              name="agency"
              externalLink
              noMargin
              onBlur={() => setFieldTouched('agency', true)}
              onChange={(fieldValue: any) =>
                setFieldValue('agency', fieldValue)
              }
              options={values.allAgencies}
              placeholder="Select an agency..."
              value={values.agency}
              attributes={{
                fieldAttribute: 'agencyField',
                menuAttribute: 'agencyMenuOptions',
                chipAttribute: 'agencyChip',
              }}
              errorProps={{
                helperText: (touched.agency && errors.agency) || status.agency,
                FormHelperTextProps: {
                  error: !!((touched.agency && errors.agency) || status.agency),
                },
              }}
            />
            <IconButton
              href={`/agency/${values && values.agency && values.agency.value}`}
              target="_blank"
              classes={{ root: classes.textFieldLinkMultiSelect }}
              disabled={!values.agency}
              data-tc="salesChannelAgencyLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for{' '}
                {values && values.agency && values.agency.label}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Sales Channel Name"
            placeholder="Sales Channel Name"
            data-tc="salesChannelName"
            name="name"
            autoFocus={!update}
            fullWidth
            inputProps={{ 'data-testid': 'salesChannelName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Sales Channel ID"
              placeholder="Sales Channel ID"
              data-tc="salesChannelId"
              name="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={8}>
          <MultiSelect
            data-tc="salesChannelOwnerMultiSelect"
            data-testId="salesChannelOwnerMultiSelect"
            id="salesChannelOwnerMultiSelect"
            label="Sales Channel Owner"
            isMulti={false}
            selectAll={false}
            maxSelectHeight={250}
            name="owner"
            noMargin
            onBlur={() => setFieldTouched('owner', true)}
            onChange={(fieldValue: OptionType) =>
              setFieldValue('owner', fieldValue)
            }
            options={allUsers.map(
              (user) => createUserOption(user) as OptionType
            )}
            placeholder="Select an Owner..."
            value={values.owner}
            attributes={{
              fieldAttribute: 'salesChannelOwnerField',
              menuAttribute: 'salesChannelOwnerMenuOptions',
              chipAttribute: 'salesChannelOwnerChip',
            }}
            errorProps={{
              helperText: (touched.owner && errors.owner) || status.owner,
              FormHelperTextProps: {
                error: !!((touched.owner && errors.owner) || status.owner),
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Territory"
              placeholder="Territory"
              name="territory"
              value={TerritoryLabel[values.territory!]}
              fullWidth
              disabled
              id="Territory"
              helperText={status.territory}
              FormHelperTextProps={{
                error: !!status.territory,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default SalesChannelDetails;
