import classNames from 'classnames';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import loginOptionsStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { TextField, Typography } from '@material-ui/core';

import useStyles from './EmailVerification.styles';

const EmailVerificationPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location: { state: { prevPage: string } } = useLocation();
  const containerClasses = loginOptionsStyles();

  const [error, setError] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');

  const isUserRegistering =
    location.state && location.state.prevPage === 'sign-up';

  const {
    state,
    registrationToken,
    verificationToken,
    unverifiedEmail,
    onVerifyEmail,
    onSendVerificationCode,
  } = useAuthContext();

  if (state === AuthState.LoggedIn) {
    return <Redirect to="/home" />;
  }

  const handleSubmit = () => {
    onVerifyEmail(
      verificationCode,
      registrationToken,
      verificationToken,
      setError
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  return (
    <PageContainer>
      <div className={containerClasses.container}>
        <div className={classes.infoWrapper}>
          <Typography variant="body1" className={classes.heading}>
            Verify Email Address
          </Typography>
          <Typography
            variant="body2"
            data-testid="verification-page--description"
          >
            Verification code has been sent to your email address, please check
            spam folder
          </Typography>
        </div>
        <div>
          <div className={classes.textFieldContainer}>
            <TextField
              label="Verification Code"
              fullWidth
              margin="normal"
              InputLabelProps={{
                'aria-label': 'verificationCode',
                shrink: true,
                className: classes.textFieldLabel,
              }}
              id="verificationCode"
              name="verificationCode"
              value={verificationCode}
              onChange={handleChange}
              onKeyUp={() => setError('')}
              className={classes.textField}
            />
          </div>
          {error && (
            <Typography
              variant="body2"
              align="center"
              className={classes.errorWrapper}
              data-testid="emailVerificationError"
            >
              {error}
            </Typography>
          )}
          <div
            className={classNames([
              classes.buttonsContainer,
              {
                [`${classes.buttonsContainer}--space-evenly`]: isUserRegistering,
              },
            ])}
          >
            {isUserRegistering ? null : (
              <StyledButton
                onClick={history.goBack}
                color={ButtonColorEnum.Default}
                variant={ButtonVariantEnum.Outlined}
                className={classes.button}
              >
                Back
              </StyledButton>
            )}
            <StyledButton
              onClick={handleSubmit}
              color={ButtonColorEnum.Default}
              variant={ButtonVariantEnum.Contained}
              className={classNames(
                classes.button,
                `${classes.button}--submit`
              )}
            >
              Submit
            </StyledButton>
          </div>
        </div>
        <div className={classes.resendLinkContainer}>
          <StyledButton
            onClick={() => onSendVerificationCode(unverifiedEmail)}
            variant={ButtonVariantEnum.Text}
            className={classNames([classes.italic, classes.resendLink])}
          >
            Resend verification code
          </StyledButton>
        </div>
      </div>
    </PageContainer>
  );
};

export default EmailVerificationPage;
