import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  filterItem: {
    width: '100%',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
}));

export default useStyles;
