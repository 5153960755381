import React from 'react';
import { ColumnInstance, HeaderGroup, Row } from 'react-table';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { useHeaderStyles } from 'components/Table/Table.styles';

interface ITableHeader {
  headerGroups: HeaderGroup<Row<Record<string, unknown>>>[];
  handleSort?: (column: ColumnInstance<Row<Record<string, unknown>>>) => void;
  tabTable?: boolean;
  entityType?: {};
  showFilters?: boolean;
}

const TableHeader = ({
  headerGroups,
  handleSort,
  entityType,
  tabTable = false,
  showFilters = true,
}: ITableHeader) => {
  const classes = useHeaderStyles();
  return (
    <TableHead className={classes.root}>
      {headerGroups.map(
        (
          headerGroup: HeaderGroup<Row<Record<string, unknown>>>,
          index: number
        ) => (
          /* eslint-disable react/no-array-index-key */
          <React.Fragment key={index}>
            <TableRow {...headerGroup.getHeaderGroupProps()} key={1}>
              {headerGroup.headers.map(
                (column: ColumnInstance<Row<Record<string, unknown>>>) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    key={column.id}
                    className={classNames([
                      classes.cell,
                      tabTable && classes.tabTableCell,
                    ])}
                    onClick={handleSort ? () => handleSort(column) : undefined}
                  >
                    {!column.canSort ? (
                      column.render('Header')
                    ) : (
                      <div {...column.getSortByToggleProps()}>
                        <TableSortLabel
                          disabled={!column.canSort}
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          hideSortIcon
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      </div>
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
            {showFilters ? (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={2}>
                {headerGroup.headers.map(
                  (column: ColumnInstance<Row<Record<string, unknown>>>) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      className={classes.filter}
                      key={column.id}
                    >
                      {column.canFilter &&
                        column.render('Filter', { entityType })}
                    </TableCell>
                  )
                )}
              </TableRow>
            ) : null}
          </React.Fragment>
        )
      )}
    </TableHead>
  );
};

export default TableHeader;
