/**
 * Return the file name needed for generating a preassign url for the creatives
 * to access them in the s3 bucket
 * @param url creative url strting
 */
export const getCreativeFileName = (url: string) => {
  try {
    const pathName = new URL(url).pathname;
    return decodeURIComponent(pathName).replace(/^\/+/, '');
  } catch {
    return '';
  }
};

export default {
  getCreativeFileName,
};
