import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchIcon from '@material-ui/icons/Launch';

import formStyles from 'assets/styles/components/Form.styles';

import {
  IDatacenterValues,
  Regions,
} from 'features/programmatic/datacenter/components/DatacenterTabsForm/DatacenterFormValues';

import { IFormProps } from 'interfaces';
import NumberInput, {
  ThousandSeparatorTypes,
} from 'components/NumberInput/NumberInput';

interface IDatacenterDetailsProps {
  update?: boolean;
}

const DatacenterDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  update = false,
  status = {},
}: IFormProps<IDatacenterValues> & IDatacenterDetailsProps) => {
  const classes = formStyles();

  return (
    <fieldset className={classes.fieldset}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className={classes.textFieldLinkContainer}>
            <Field
              component={TextField}
              label="Programmatic Connector Name"
              placeholder="Programmatic Connector Name"
              name="technicalProviderName"
              data-tc="technicalProviderName"
              fullWidth
              disabled
              InputProps={{
                classes: { root: classes.textfieldLinkWrapper },
              }}
            />
            <IconButton
              href={`/programmatic-connector/${values.technicalProviderId}`}
              target="_blank"
              classes={{ root: classes.textFieldLink }}
              data-tc="datacenterTechnicalProviderLink"
            >
              <LaunchIcon />
              <span className="sr-only">
                View details for {values.technicalProviderName}
              </span>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Field
            component={TextField}
            label="Name"
            placeholder="Name"
            data-tc="datacenterName"
            name="name"
            autoFocus={!update}
            fullWidth
            inputProps={{ 'data-testid': 'datacenterName' }}
            helperText={(touched.name && errors.name) || status.name}
            FormHelperTextProps={{
              error: !!((touched.name && errors.name) || status.name),
            }}
          />
        </Grid>
        {update && values.altId ? (
          <Grid item xs={4}>
            <Field
              component={TextField}
              label="Datacenter ID"
              data-tc="datacenterAltId"
              name="altId"
              fullWidth
              disabled
            />
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <Field
            component={TextField}
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              },
            }}
            label="Region"
            data-tc="datacenterRegion"
            name="region"
            helperText={(touched.region && errors.region) || status.region}
            FormHelperTextProps={{
              error: !!((touched.region && errors.region) || status.region),
            }}
            fullWidth
          >
            {Regions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            label="Bid Url"
            placeholder="Bid Url"
            data-tc="datacenterBidUrl"
            name="bidUrl"
            fullWidth
            helperText={(touched.bidUrl && errors.bidUrl) || status.bidUrl}
            FormHelperTextProps={{
              error: !!((touched.bidUrl && errors.bidUrl) || status.bidUrl),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            allowNegative={false}
            dataTc="datacenterMaxQps"
            decimalScale={0}
            error={errors.maxQps || status.maxQps}
            label="Max QPS"
            name="maxQps"
            onBlur={handleBlur}
            onChange={handleChange}
            thousandSeparator={ThousandSeparatorTypes.comma}
            touched={touched.maxQps || !!status.maxQps}
            value={values.maxQps}
            fullWidth
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

export default DatacenterDetails;
