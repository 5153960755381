import React from 'react';

import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import { ILocation } from 'interfaces';

interface ITableLink {
  location: string | ILocation;
  name: string;
  onClick?: () => void;
}

const TableLink = ({ name, location, onClick = () => {} }: ITableLink) => (
  <StyledLink
    compact
    color={LinkColorEnum.Plain}
    location={location}
    onClick={onClick}
  >
    {name}
  </StyledLink>
);

export default TableLink;
