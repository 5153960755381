import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { ApprovalStatus } from 'interfaces/generated.types';

import useStyles from './ProgrammaticCreativeBulkApprovalModal.styles';

export interface IProgrammaticCreativeBulkApprovalModalProps {
  onConfirm(): void;
  approvalStatus: ApprovalStatus;
  isOpen: boolean;
  closeModal(): void;
}

const ProgrammaticCreativeBulkApprovalModal = ({
  onConfirm,
  approvalStatus,
  isOpen,
  closeModal,
}: IProgrammaticCreativeBulkApprovalModalProps) => {
  const submitText =
    approvalStatus === ApprovalStatus.Approved ? 'approve' : 'block';

  const classes = useStyles();
  const onSubmit = async () => {
    await onConfirm();
    closeModal();
  };

  return (
    <Dialog
      data-tc="programmaticCreativeModal"
      open={isOpen}
      onClose={closeModal}
      maxWidth="xl"
    >
      <DialogTitle>
        Are you sure you want to bulk {submitText} the selected creatives?
      </DialogTitle>
      <DialogActions className={classes.buttonContainer}>
        <Grid container justify="space-between">
          <StyledButton
            onClick={closeModal}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="programmaticCreativeBulkModalClose"
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={onSubmit}
            color={ButtonColorEnum.Primary}
            variant={ButtonVariantEnum.Outlined}
            data-tc="programmaticCreativeBulkModalSubmit"
          >
            Yes
          </StyledButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProgrammaticCreativeBulkApprovalModal;
