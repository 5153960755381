import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { IFormProps, OptionType } from 'interfaces';
import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import React, { useRef } from 'react';
import { SelectableGroup } from 'react-selectable-fast';
import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SelectableTargetingDayTimeCell from '../TargetingDayTimeCell/TargetingDayTimeCell';
import {
  targetingDayValues,
  targetingHourValues,
  targetingTimeZoneEnum,
  targetingTimeZoneValues,
} from '../TargetingParameters/TargetingParametersValues';
import useStyles from './TargetingDayTime.styles';

/* eslint-disable no-param-reassign */
export const dayTimeValues = {
  dayAndTimeParams: {
    timeZone: targetingTimeZoneEnum.DEMAND,
    selectedPeriods: [],
  },
};

interface IDayTime {
  day: string;
  hours: { hour: number; readOnly: boolean };
}

export const hourSelect = (
  values: any,
  path: string,
  hour: number,
  setFieldValue: (field: string, value: any) => void
) => {
  const currentSelectedDays = targetingDayValues.filter((day: OptionType) =>
    some(values, { day: day.value, hours: { hour } })
  );

  if (currentSelectedDays.length === targetingDayValues.length) {
    const removeSelectedDays = values.filter(
      (selectedPeriod: IDayTime) =>
        selectedPeriod.hours.readOnly ||
        (selectedPeriod.hours.hour !== hour && !selectedPeriod.hours.readOnly)
    );
    setFieldValue(path, removeSelectedDays);
  } else {
    const newSelectedDays: IDayTime[] = [];
    targetingDayValues.forEach((day: OptionType) => {
      if (!some(values, { day: day.value, hours: { hour } }))
        newSelectedDays.push({
          day: day.value,
          hours: { hour, readOnly: false },
        });
    });
    setFieldValue(path, [...values, ...newSelectedDays]);
  }
};

export const daySelect = (
  values: any,
  path: string,
  day: string,
  setFieldValue: (field: string, value: any) => void
) => {
  const currentSelectedHours = targetingHourValues.filter((hour: number) =>
    some(values, { day, hours: { hour } })
  );
  if (currentSelectedHours.length === targetingHourValues.length) {
    const removeSelectedHours = values.filter(
      (selectedPeriod: IDayTime) =>
        selectedPeriod.hours.readOnly ||
        (selectedPeriod.day !== day && !selectedPeriod.hours.readOnly)
    );
    setFieldValue(path, removeSelectedHours);
  } else {
    const newSelectedHours: IDayTime[] = [];
    targetingHourValues.forEach((hour: number) => {
      if (!some(values, { day, hours: { hour } }))
        newSelectedHours.push({ day, hours: { hour, readOnly: false } });
    });
    setFieldValue(path, [...values, ...newSelectedHours]);
  }
};

const TargetingDayTime = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index,
    setFieldValue,
  } = props;

  const classes: any = useStyles();
  const selectableGroupRef = useRef<SelectableGroup>(null);

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  const handleSelectionFinish = (selectedItems: any) => {
    if (selectedItems && selectedItems.length) {
      const newPeriods = [
        ...targetingValues.audienceParams[index].dayAndTimeParams
          .selectedPeriods,
      ];

      selectedItems.forEach((selectedItem: any) => {
        if (!selectedItem.props.readOnly) {
          if (
            some(
              targetingValues.audienceParams[index].dayAndTimeParams
                .selectedPeriods,
              {
                day: selectedItem.props.day,
                hours: { hour: selectedItem.props.hour, readOnly: false },
              }
            )
          ) {
            const selectedIndex = findIndex(newPeriods, {
              day: selectedItem.props.day,
              hours: { hour: selectedItem.props.hour, readOnly: false },
            });
            newPeriods.splice(selectedIndex, 1);
          } else {
            newPeriods.push({
              day: selectedItem.props.day,
              hours: { hour: selectedItem.props.hour, readOnly: false },
            });
          }
        }
      });

      setFieldValue(
        `${targetingPath}.audienceParams.${index}.dayAndTimeParams.selectedPeriods`,
        newPeriods
      );

      selectableGroupRef &&
        selectableGroupRef.current &&
        selectableGroupRef.current.clearSelection();
    }
  };

  return (
    <div className={classes.value}>
      <Field
        component={TextField}
        select
        SelectProps={{
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        }}
        label="Time Zone"
        data-tc="targetingDayTimeTimeZone"
        name={`${targetingPath}.audienceParams.${index}.dayAndTimeParams.timeZone`}
        fullWidth
        classes={{ root: classes.timeZone }}
        disabled={targetingValues.audienceParams[index].readOnlyType}
      >
        {targetingTimeZoneValues.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Field>
      <SelectableGroup
        ref={selectableGroupRef}
        data-tc="targetingDayTimeSelectableGroup"
        enableDeselect
        tolerance={5}
        allowClickWithoutSelected
        selectboxClassName={`.${classes.verticalHeadings}`}
        onSelectionFinish={handleSelectionFinish}
        ignoreList={[
          `.${classes.verticalHeadings}`,
          `.${classes.horizontalHeadings}`,
        ]}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.verticalHeadings} />
              {targetingDayValues.map((day: OptionType) => (
                <TableCell
                  key={`day-${day.value}`}
                  className={classes.horizontalHeadings}
                >
                  <button
                    type="button"
                    data-tc={`targetingDay-${day.value}`}
                    onClick={() =>
                      daySelect(
                        targetingValues.audienceParams[index].dayAndTimeParams
                          .selectedPeriods,
                        `${targetingPath}.audienceParams.${index}.dayAndTimeParams.selectedPeriods`,
                        day.value,
                        setFieldValue
                      )
                    }
                  >
                    {day.value}
                  </button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {targetingHourValues.map((hour: number) => (
              <TableRow key={`hour-${hour}`}>
                <TableCell className={classes.verticalHeadings}>
                  <button
                    type="button"
                    data-tc={`targetingHour-${hour}`}
                    onClick={() =>
                      hourSelect(
                        targetingValues.audienceParams[index].dayAndTimeParams
                          .selectedPeriods,
                        `${targetingPath}.audienceParams.${index}.dayAndTimeParams.selectedPeriods`,
                        hour,
                        setFieldValue
                      )
                    }
                  >
                    {hour}
                  </button>
                </TableCell>
                {targetingDayValues.map((day: OptionType) => (
                  /* eslint-disable jsx-a11y/label-has-associated-control */
                  <TableCell
                    key={`dayTime-${day.value}-${hour}`}
                    className={classes.tableCell}
                  >
                    <SelectableTargetingDayTimeCell
                      day={`${day.value}`}
                      hour={hour}
                      selected={some(
                        targetingValues.audienceParams[index].dayAndTimeParams
                          .selectedPeriods,
                        { day: day.value, hours: { hour } }
                      )}
                      readOnly={some(
                        targetingValues.audienceParams[index].dayAndTimeParams
                          .selectedPeriods,
                        { day: day.value, hours: { hour, readOnly: true } }
                      )}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SelectableGroup>
    </div>
  );
};

export default TargetingDayTime;
