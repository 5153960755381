import classNames from 'classnames';
import MuiCircularProgress, {
  CircularProgressProps as MUICircularProgressProps,
} from 'features/targetingV2/components/core/CircularProgress';
import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import withoutKeys from 'features/targetingV2/utils/withoutKeys';
import React from 'react';

import MUIButton, {
  ButtonClassKey as MUIButtonClassKey,
  ButtonProps as MUIButtonProps,
} from '@material-ui/core/Button';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import useStyles from './Button.styles';

export type ButtonClassKey = 'content' | 'loading' | 'loader' | 'hasStartIcon';

export interface ButtonProps extends MUIButtonProps {
  classes?: Partial<ClassNameMap<MUIButtonClassKey | ButtonClassKey>>;
  loading?: boolean;
  CircularProgressProps?: MUICircularProgressProps & Record<string, unknown>;
}

const Button = React.forwardRef(
  (
    props: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const classes = useStyles();
    const {
      loading = false,
      disabled,
      classes: userClasses = {} as ButtonProps['classes'],
      children,
      className,
      CircularProgressProps = {},
      color,
      ...otherProps
    } = props;
    const { startIcon } = otherProps;
    const mergedClasses = mergeClasses(
      withoutKeys(classes, ['content', 'loading', 'loader', 'hasStartIcon']),
      userClasses
        ? withoutKeys(userClasses, [
            'content',
            'loading',
            'loader',
            'hasStartIcon',
          ])
        : {}
    );

    return (
      <MUIButton
        {...otherProps}
        classes={mergedClasses}
        className={classNames(
          classes.root,
          loading && classes.loading,
          startIcon && classes.hasStartIcon,
          className
        )}
        disabled={disabled || loading}
        color={color}
        ref={ref}
        disableElevation
      >
        <div className={classNames(classes?.content, userClasses?.content)}>
          {children}
        </div>
        {loading ? (
          <div className={classNames(classes?.loader, userClasses?.loader)}>
            <MuiCircularProgress
              color="primary"
              size="1rem"
              thickness={5}
              {...CircularProgressProps}
            />
          </div>
        ) : null}
      </MUIButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
