import pageStyles from 'assets/styles/components/Page.styles';
import DualFilteredTreeList from 'components/DualFilteredTreeList/DualFilteredTreeList';
import Loader from 'components/Loader/Loader';
import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';
import { ITreeItemsWithType } from 'interfaces';
import {
  EntityPermission,
  Territory,
  useInsightsTier2PermissionEntitiesQuery,
} from 'interfaces/generated.types';
import { isEqual } from 'lodash';
import React from 'react';
import {
  generateAllReadonlySelectedItems,
  getSelectedItemsLeafIds,
} from 'utils/treeDataTransformation';

import { Dialog, DialogActions } from '@material-ui/core';

import { getEntityPermissionsIds } from '../InsightsPermissionsSelector/InsightsPermissionsSelectorUtils';
import useStyles from './InsightsPermissionsDialog.styles';
import {
  formatTier2Entities,
  getSelectedPermissions,
} from './InsightsPermissionsDialogUtils';

export interface IInsightsPermissionsDialogProps {
  isModalOpen: boolean;
  territories: Territory[];
  entityPermissions: EntityPermission[];
  handleClose: () => void;
  setFieldValue: (field: string, value: any) => void;
}

const InsightsPermissionsDialog = ({
  handleClose,
  isModalOpen,
  setFieldValue,
  territories,
  entityPermissions,
}: IInsightsPermissionsDialogProps) => {
  const classes = { ...pageStyles(), ...useStyles() };
  const [selectedPermissions, setSelectedPermissions] =
    React.useState<EntityPermission[]>(entityPermissions);

  const [selectedPermissionIds, setSelectedPermissionsIds] = React.useState<
    string[]
  >(getEntityPermissionsIds(selectedPermissions));

  const { loading, data, error } = useInsightsTier2PermissionEntitiesQuery({
    variables: { territories },
    errorPolicy: 'all',
  });

  const formattedData: ITreeItemsWithType[] = formatTier2Entities(data);

  const handleTreeNodeSelection = (node: any) => {
    const newCheckedNodes = getSelectedPermissions(
      formattedData,
      selectedPermissions,
      node
    );

    setSelectedPermissionsIds(getEntityPermissionsIds(newCheckedNodes));
    setSelectedPermissions(newCheckedNodes);
  };

  const selectedLeafIds = getSelectedItemsLeafIds(
    selectedPermissionIds,
    formattedData
  );

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isModalOpen}
      onClose={handleClose}
      classes={{
        paper: classes.dialog,
      }}
    >
      {loading && <Loader />}
      {!loading && error && (
        <p
          className={classes.errorMessage}
          data-testid="insightsTier2PermissionEntitiesError"
        >
          Error, issues have occurred loading permission data
        </p>
      )}
      {!loading && !error && (
        <DualFilteredTreeList
          data-tc="insightsPermissionTreeContainer"
          allItems={formattedData}
          selectedItemsIds={selectedLeafIds}
          onTreeNodeSelection={handleTreeNodeSelection}
          providedReadOnlySelectedItems={generateAllReadonlySelectedItems(
            selectedPermissionIds,
            formattedData,
            true
          )}
          displaySelectOptions={false}
        />
      )}
      <DialogActions
        classes={{
          root: classes.ctaContainer,
        }}
      >
        <StyledButton
          onClick={handleClose}
          color={ButtonColorEnum.Primary}
          variant={ButtonVariantEnum.Outlined}
        >
          Cancel
        </StyledButton>
        <StyledButton
          variant={ButtonVariantEnum.Contained}
          color={ButtonColorEnum.Primary}
          disabled={isEqual(entityPermissions, selectedPermissions)}
          onClick={() => {
            setFieldValue('entityPermissions', selectedPermissions);
            handleClose();
          }}
        >
          Apply permissions
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default InsightsPermissionsDialog;
