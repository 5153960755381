import tabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import Blocklisting, {
  BlocklistingEntityEnum,
} from 'components/Blocklisting/Blocklisting';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import ChannelsTable from 'features/inventory/channel/components/ChannelsTable/ChannelsTable';
import PublisherAssociatedAdsTable from 'features/inventory/publisher/components/PublisherAssociatedAdsTable/PublisherAssociatedAdsTable';
import PublisherAssociatedDealsTable from 'features/inventory/publisher/components/PublisherAssociatedDealsTable/PublisherAssociatedDealsTable';
import PublisherContracts from 'features/inventory/publisher/components/PublisherContracts/PublisherContracts';
import PublisherDetails from 'features/inventory/publisher/components/PublisherDetails/PublisherDetails';
import PublisherMonitoring from 'features/inventory/publisher/components/PublisherMonitoring/PublisherMonitoring';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useUserPermissions from 'hooks/UserPermissions/useUserPermissions';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  Channel,
  EntityType,
  NetworkStatus,
  PermissionName,
  Publisher,
  PublisherStatus,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import {
  hasPublisherContractEditPermissions,
  hasPublisherContractReadPermissions,
} from 'utils/contracts';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';
import { userHasAtLeastOnePermission } from 'utils/permissions';
import { isActiveTerritoryAssignedToEntity } from 'utils/territory';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import {
  getPublisherStatusData,
  IPublisherValues,
  publisherDetailsFields,
  publisherStatusValues,
} from './PublisherFormValues';

interface IPublisherFormProps {
  update?: boolean;
  goBackTo: ILocation;
  channels?: Channel[];
  publisher?: Publisher;
  history: History;
  match?: {
    params: {
      publisherId: string;
    };
  };
  networkStatus?: NetworkStatus;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
}

export const publisherButtons = [
  {
    text: 'Save and Review',
    url: '/publisher/ID',
    update: true,
    dataTc: 'publisherReviewButton',
  },
  {
    text: 'Save and Create Channel',
    url: '/publisher/ID/channel',
    update: false,
    dataTc: 'publisherCreateChannelButton',
  },
  {
    text: 'Save and View All Publishers',
    url: '/network/PARENT_ID',
    update: false,
    dataTc: 'publisherListButton',
  },
];

export const getDefaultTab = (publisherStatus?: string, location?: any) => {
  if (location && location.state) {
    const { entityList, tab } = location.state;
    if (entityList === 'listChannels') return 3;
    if (tab) return tab;
  }
  return publisherStatus && publisherStatus === PublisherStatus.Enabled ? 0 : 1;
};

const getPublisherDetailsTabErrors = createNumberOfFormErrorsFn();

const publisherEditPermissions = [
  PermissionName.Primary,
  PermissionName.Supply,
];

const PublisherTabsForm = (
  props: IFormProps<IPublisherValues> & IPublisherFormProps
) => {
  const {
    values,
    handleSubmit,
    goBackTo,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    update = false,
    history,
    match,
    channels,
    publisher,
    status,
    networkStatus,
    onFieldUpdate,
    onFieldValidate,
  } = props;

  const classes = tabStyles();

  const location = usePreviousLocation();

  const [selectedTab, setTab] = useState(
    getDefaultTab(values.status, location)
  );
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);

  const isPublisherMonitoringTab = selectedTab === 0;
  const isPublisherTab = selectedTab === 1;
  const isBlocklistingTab = selectedTab === 2;
  const isListChannelsTab = selectedTab === 3;
  const isPublisherActivityTab = selectedTab === 4;
  const isLinkedAdsTab = selectedTab === 5;
  const isLinkedDealsTab = selectedTab === 6;
  const isPublisherContractsTab = selectedTab === 7;

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const userPermissions = useUserPermissions();

  const hasEditPermissions =
    userHasAtLeastOnePermission(userPermissions, publisherEditPermissions) &&
    publisher?.territories
      ? isActiveTerritoryAssignedToEntity(
          publisher?.territories!,
          activeTerritory!
        )
      : true;

  return (
    <>
      <Form className={classes.form} role="form">
        {update && networkStatus && (
          <>
            <EntityProgressBar
              statusData={getPublisherStatusData(values, networkStatus)}
              currentStatus={values.status || ''}
              dataTc="publisherStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={publisherStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="publisherHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="publisherTabs"
            variant="scrollable"
            scrollButtons="off"
          >
            <Tab
              disabled={
                !update &&
                values.status !== PublisherStatus.Enabled &&
                values.status !== PublisherStatus.Disabled
              }
              dataTc="publisherMonitoringTab"
              label="Performance Monitoring"
            />
            <Tab
              dataTc="publisherDetailsTab"
              label="Details"
              numberOfTabErrors={getPublisherDetailsTabErrors(
                status,
                publisherDetailsFields
              )}
            />
            <Tab dataTc="publisherBlocklistingTab" label="Block Listing" />
            <Tab disabled={!update} dataTc="listChannelsTab" label="Channels" />
            <Tab
              disabled={!update}
              dataTc="publisherActivityTab"
              label="Activity"
            />
            <Tab
              disabled={!update}
              dataTc="publisherLinkedAdsTab"
              label="Active Ads"
            />
            <Tab
              disabled={!update}
              dataTc="publisherLinkedDealsTab"
              label="Active Deals"
            />
            {(hasPublisherContractEditPermissions(userPermissions) ||
              hasPublisherContractReadPermissions(userPermissions)) && (
              <Tab
                disabled={!update}
                dataTc="publisherContracts"
                label="Contract Details"
              />
            )}
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            classes.formView,
            {
              [`${classes.formView}--table`]:
                isListChannelsTab ||
                isPublisherActivityTab ||
                isLinkedAdsTab ||
                isLinkedDealsTab,
            },
          ])}
        >
          {isPublisherMonitoringTab && <PublisherMonitoring id={values.id} />}
          {isPublisherTab && <PublisherDetails {...props} />}
          {isBlocklistingTab && (
            <Blocklisting
              {...props}
              entity={BlocklistingEntityEnum.Publisher}
            />
          )}
          {isListChannelsTab && history && match && channels && (
            <ChannelsTable
              history={history}
              match={match}
              channels={channels}
              isEditable={hasEditPermissions}
              title="Publisher Channels"
              isPublisher
            />
          )}
          {isPublisherActivityTab && (
            <ActivityTable
              entityType={EntityType.Publisher}
              id={values.id}
              data-tc="publisherActivityTable"
            />
          )}
          {isLinkedAdsTab && (
            <PublisherAssociatedAdsTable
              publisherId={publisher?.id || ''}
              history={history}
            />
          )}
          {isLinkedDealsTab && (
            <PublisherAssociatedDealsTable
              publisherId={publisher?.id || ''}
              history={history}
            />
          )}
          {isPublisherContractsTab &&
            (hasPublisherContractEditPermissions(userPermissions) ||
              hasPublisherContractReadPermissions(userPermissions)) && (
              <PublisherContracts
                publisher={publisher as Publisher}
                history={history}
                hasEditPermissions={hasPublisherContractEditPermissions(
                  userPermissions
                )}
              />
            )}
        </div>
        <FormButtons
          dataTc="submitPublisherButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={publisherButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default PublisherTabsForm;
