import colors from 'assets/styles/colors';
import classNames from 'classnames';
import Card, { CardColorEnum } from 'components/Card/Card';
import Loader from 'components/Loader/Loader';
import MenuButton from 'components/MenuButton/MenuButton';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import {
  ITargetingDefinitionV2,
  ITargetingV2Info,
} from 'features/targetingV2/types/targeting';
import { formatForecastDataForClipboard } from 'features/targetingV2/utils/forecastingCopy';
import utils from 'hooks/CsvParser/utils';
import {
  TargetingDefinition,
  TargetingVersion,
  TimeSeriesDataPoint,
} from 'interfaces/generated.types';
import { IToolTipInfo } from 'interfaces/XYDateChart';
import React, { lazy, Suspense } from 'react';
import { ChartAggregatorEnum } from 'utils/charts';
import { findLabelValue } from 'utils/dataTransformation';
import { formatForecastDataForCsvExport } from 'utils/forecasting';
import addCommaToImpressions from 'utils/formatImpressionsValue';
import { divideTwoNumbers } from 'utils/numbers';

import Grid from '@material-ui/core/Grid';

import { IForecastingValues } from '../ForecastingDetails/ForecastingDetails';
import useStyles from './ForecastingChart.styles';

interface IForecastingChartProps {
  data: {
    availableImpressionDataPoints: TimeSeriesDataPoint[];
    bookedImpressionDataPoints: TimeSeriesDataPoint[];
  };
  iabCategories: string[];
  mergedTargetingDefinition: TargetingDefinition | undefined;
  dateOfForecastGeneration: Date | undefined;
  values: IForecastingValues;
  totals: { bookedImpressions: number; availableImpressions: number };
  targetingV2Info: ITargetingV2Info;
  errors: any;
}

export enum DeliveryStatus {
  GREEN = 'GREEN',
  AMBER = 'AMBER',
  RED = 'RED',
}

interface FormattedTimeSeriesDataPoint {
  date: any;
  value: number | undefined;
  toolTipInfo?: IToolTipInfo[] | undefined;
}

const XYDateChart = lazy(() => import('components/XYDateChart/XYDateChart'));

const deliveryStatusValues = [
  {
    value: DeliveryStatus.GREEN,
    label: 'Will deliver',
    color: CardColorEnum.GREEN,
  },
  {
    value: DeliveryStatus.AMBER,
    label: 'May deliver',
    color: CardColorEnum.AMBER,
  },
  {
    value: DeliveryStatus.RED,
    label: 'Will not deliver',
    color: CardColorEnum.RED,
  },
];

export const formatSeriesData = (data: TimeSeriesDataPoint[]) =>
  data.map((point) => ({
    date: new Date(point.t),
    value: point.v,
  }));

export const formatImpressionChartData = (
  formattedData: FormattedTimeSeriesDataPoint[],
  seriesColor: string
) => [
  {
    label: 'Impressions',
    value: 'impressions',
    seriesColor,
    series: formattedData,
    aggregator: ChartAggregatorEnum.SUM,
  },
];

export const formatChartData = ({
  totalImpressionDataPoints,
  availableImpressionDataPoints,
  bookedImpressionDataPoints,
}: {
  totalImpressionDataPoints: TimeSeriesDataPoint[];
  availableImpressionDataPoints: TimeSeriesDataPoint[];
  bookedImpressionDataPoints: TimeSeriesDataPoint[];
}) => {
  const formattedTotalImpressionDataPoints = formatSeriesData(
    totalImpressionDataPoints
  );
  const formattedAvailableImpressionDataPoints = formatSeriesData(
    availableImpressionDataPoints
  );
  const formattedBookedImpressionDataPoints = formatSeriesData(
    bookedImpressionDataPoints
  );

  const impressionDataPoints = formattedTotalImpressionDataPoints.map(
    (dataPoint, index) => ({
      ...dataPoint,
      toolTipInfo: [
        {
          label: 'Total Impressions',
          value: formattedTotalImpressionDataPoints[index].value,
        },
        {
          label: 'Available Impressions',
          value: formattedAvailableImpressionDataPoints[index].value,
        },
        {
          label: 'Booked Impressions',
          value: formattedBookedImpressionDataPoints[index].value,
        },
      ],
    })
  );

  const formattedTotalImpressionChartData = formatImpressionChartData(
    impressionDataPoints,
    colors.daxPrimaryBlue
  );

  const formattedBookedImpressionChartData = formatImpressionChartData(
    formattedBookedImpressionDataPoints,
    colors.daxGreyscaleSilver
  );

  return formattedTotalImpressionChartData.concat(
    formattedBookedImpressionChartData
  );
};

export const calculateDeliveryStatus = (
  totalImpressions: number,
  objective: string
) => {
  const percentageCompletion = divideTwoNumbers(totalImpressions, objective);
  if (percentageCompletion >= 1.05) return DeliveryStatus.GREEN;
  if (percentageCompletion >= 0.95) return DeliveryStatus.AMBER;
  return DeliveryStatus.RED;
};

const ForecastingChart = ({
  data,
  iabCategories,
  mergedTargetingDefinition,
  dateOfForecastGeneration,
  values,
  totals,
  targetingV2Info,
  errors,
}: IForecastingChartProps) => {
  const classes = useStyles();

  const { bookedImpressions, availableImpressions } = totals;
  const { bookedImpressionDataPoints, availableImpressionDataPoints } = data;

  const totalImpressionDataPoints = bookedImpressionDataPoints.map(
    (dataPoint, index) => {
      const totalDataPointImpressionValue =
        (dataPoint.v as number) +
        (availableImpressionDataPoints[index].v as number);

      return {
        ...dataPoint,
        v: totalDataPointImpressionValue,
      };
    }
  );

  const deliveryStatus = calculateDeliveryStatus(
    availableImpressions,
    values.objective
  );

  const formattedBookedImpressions = addCommaToImpressions(bookedImpressions);
  const formattedAvailableImpressions =
    addCommaToImpressions(availableImpressions);
  const deliveryStatusLabel = findLabelValue({
    collection: deliveryStatusValues,
    lookupValue: deliveryStatus,
  });

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const formattedChartData = formatChartData({
    totalImpressionDataPoints,
    availableImpressionDataPoints,
    bookedImpressionDataPoints,
  });

  const handleExport = () => {
    const optionalMergedTargetingDefinition =
      values.targetingVersion === TargetingVersion.TargetingV1
        ? mergedTargetingDefinition
        : undefined;

    const dataToBeExported = formatForecastDataForCsvExport(
      values,
      iabCategories,
      optionalMergedTargetingDefinition,
      formattedBookedImpressions,
      formattedAvailableImpressions,
      deliveryStatusLabel,
      dateOfForecastGeneration,
      activeTerritory,
      targetingV2Info,
      errors
    );

    utils.unparseAndDownloadData(dataToBeExported, 'forecastData.csv');
  };

  const handleCopy = () => {
    const dataToBeCopied = formatForecastDataForClipboard(
      values.targetingDefinitionV2 as ITargetingDefinitionV2,
      targetingV2Info,
      activeTerritory!,
      errors,
      values.targetingRestrictions
    );

    navigator.clipboard.writeText(dataToBeCopied);
  };

  const optionalCopyToClipboardOption =
    values.targetingVersion === TargetingVersion.TargetingV2
      ? [
          {
            title: 'Copy to clipboard',
            onClick: handleCopy,
          },
        ]
      : [];

  const exportOptions = [
    { title: 'Export as CSV', onClick: handleExport },
    ...optionalCopyToClipboardOption,
  ];

  return (
    <>
      <Grid container alignItems="center">
        <Grid container item xs={10} justifyContent="flex-end">
          <Grid item xs={3}>
            <Card
              title="Forecast Campaign Delivery Status"
              data-testid="forecastDeliveryStatus"
              color={
                findLabelValue({
                  collection: deliveryStatusValues,
                  lookupValue: deliveryStatus,
                  labelKey: 'color',
                }) as CardColorEnum
              }
            >
              {deliveryStatusLabel}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card
              title="Booked Impressions"
              data-testid="forecastBookedImpressions"
            >
              <div
                className={classNames(
                  classes.bullet,
                  `${classes.bullet}--booked`
                )}
              />
              {formattedBookedImpressions}
            </Card>
          </Grid>

          <Grid item xs={3}>
            <Card
              title="Available Impressions"
              data-testid="forecastAvailableImpressions"
            >
              <div
                className={classNames(
                  classes.bullet,
                  `${classes.bullet}--available`
                )}
              />
              {formattedAvailableImpressions}
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <MenuButton
            title={`Export${
              values.targetingVersion === TargetingVersion.TargetingV2
                ? ' / Copy'
                : ''
            }`}
            options={exportOptions}
            testId="export-to-csv"
          />
        </Grid>
      </Grid>
      <Suspense fallback={<Loader />}>
        <XYDateChart
          data={formattedChartData}
          initialSeries="impressions"
          data-tc="chart"
          testId="forecastChart"
          displayYAxisCursorLabel={false}
        />
      </Suspense>
    </>
  );
};

export default ForecastingChart;
