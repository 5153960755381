import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { IPublisherValues } from 'features/inventory/publisher/components/PublisherTabsForm/PublisherFormValues';
import PublisherTabsForm, {
  publisherButtons,
} from 'features/inventory/publisher/components/PublisherTabsForm/PublisherTabsForm';
import PublisherFormValidation from 'features/inventory/publisher/validations/publishers';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate, IFormValues } from 'interfaces';
import {
  EntityType,
  Network,
  PricingType,
  Publisher,
  UpdatePublisherMutationFn,
  useUpdatePublisherMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useState } from 'react';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { numbroDecimalFormatting } from 'utils/numbers';
import { getTerritoryValues } from 'utils/territory';

interface IUpdatePublisherContainerProps {
  history: History;
  publisher: Publisher;
  match: {
    params: {
      publisherId: string;
    };
  };
}

export const handleOnComplete = (
  history: History,
  selectedJourney: string,
  id: string,
  networkId: string
) =>
  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: networkId,
    listEntity: selectedJourney === '/network/PARENT_ID' ? 'Publisher' : '',
  });

export const submitUpdatePublisher =
  (
    updatePublisher: UpdatePublisherMutationFn,
    initialValues: IPublisherValues,
    toggleErrorModal: () => void,
    setSelectedJourney: any
  ) =>
  (
    formValues: IPublisherValues,
    { setStatus, setSubmitting }: FormikActions<IPublisherValues>
  ) => {
    setSelectedJourney(formValues.journey);

    const filteredValues: any = {
      ...differenceInValues(
        { ...formValues, journey: null, generatedDomains: null },
        { ...initialValues, journey: null, generatedDomains: null }
      ),
      id: formValues.id,
    } as IFormValues;

    updatePublisher({
      variables: filteredValues,
    }).catch((error) => {
      handleFormErrors({
        error,
        toggleErrorModal,
        setStatus,
        setSubmitting,
      });
    });
  };

export const UpdatePublisherContainer = ({
  publisher,
  history,
  match,
}: IUpdatePublisherContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Publisher.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updatePublisher] = useUpdatePublisherMutation({
    onCompleted() {
      handleOnComplete(
        history,
        selectedJourney,
        publisher.id,
        (publisher.network as Network).id
      );
    },
  });
  const validatePublisher = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const initialValues = {
    currency: publisher.currency,
    name: publisher.name as string,
    id: publisher.id,
    altId: publisher.altId,
    networkTerritories: (publisher.network as Network).territories,
    networkName: (publisher.network as Network).name as string,
    networkId: (publisher.network as Network).id,
    pricingType: publisher.pricingType as PricingType,
    generatedDomains: '',
    pricingAmount:
      typeof publisher.pricingAmount === 'number'
        ? numbro(publisher.pricingAmount).format(numbroDecimalFormatting)
        : '',
    blacklistedIabCategoryCodes: publisher.blacklistedIabCategories.map(
      (categories) => categories.code
    ),
    blacklistedDomains: publisher.blacklistedDomains.length
      ? publisher.blacklistedDomains
      : [''],
    journey: publisherButtons[0].url,
    status: publisher.status,
    territories: getTerritoryValues(publisher.territories),
  };

  const location = usePreviousLocation();

  return (
    <>
      <Formik<IPublisherValues>
        initialValues={initialValues}
        validationSchema={PublisherFormValidation}
        onSubmit={submitUpdatePublisher(
          updatePublisher,
          initialValues,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <PublisherTabsForm
            {...formikProps}
            update
            goBackTo={{
              pathname: `/network/${(publisher.network as Network).id}`,
              state: location.state?.parent || {},
            }}
            channels={publisher?.channels || []}
            publisher={publisher}
            history={history}
            match={match}
            networkStatus={(publisher.network as Network).status}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validatePublisher,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Publisher,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updatePublisher,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updatePublisher"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updatePublisherWarningDialog"
      />
    </>
  );
};

export default UpdatePublisherContainer;
