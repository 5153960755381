import { IParameter } from 'features/targetingV2/types/targeting';
import { resetFileInputValue } from 'features/targetingV2/utils/common';
import { MutableRefObject, useEffect, useState } from 'react';

const useBulkUploadMessages = (
  selectedParameter: IParameter,
  externalUploadInputRef?: MutableRefObject<HTMLInputElement | null>
) => {
  const [warningMessage, setWarningMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isBulkUploaded, setIsBulkUploaded] = useState<boolean>(false);
  useEffect(() => {
    if (warningMessage || errorMessage) {
      setWarningMessage('');
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter.type]);

  useEffect(() => {
    if ((warningMessage || errorMessage) && !isBulkUploaded) {
      setWarningMessage('');
      setErrorMessage('');
      externalUploadInputRef && resetFileInputValue(externalUploadInputRef);
    }
    setIsBulkUploaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParameter.values]);

  return {
    warningMessage,
    errorMessage,
    isBulkUploaded,
    setWarningMessage,
    setErrorMessage,
    setIsBulkUploaded,
  };
};

export default useBulkUploadMessages;
