import classNames from 'classnames';
import Divider from 'features/targetingV2/components/core/Divider';
import ExpandableMenuItem from 'features/targetingV2/components/core/ExpandableMenuItem/ExpandableMenuItem';
import IconButton from 'features/targetingV2/components/core/IconButton';
import Loader from 'features/targetingV2/components/core/Loader';
import Menu from 'features/targetingV2/components/core/Menu/Menu';
import MenuItem from 'features/targetingV2/components/core/MenuItem';
import Typography from 'features/targetingV2/components/core/Typography';
import { IOptionType } from 'features/targetingV2/types/common';
import React, { useState } from 'react';

import { PopoverActions } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './AddParameterDropdownButton.styles';

export interface IGroupCreationDetails {
  label: string;
  options: IOptionType[];
  onOptionClick: (value: string) => void;
  caption?: {
    text?: string;
    link?: {
      label: string;
      url: string;
    };
  };
}

export interface IAddParameterDropdownButtonProps {
  options: IOptionType[];
  onOptionClick: (value: string) => void;
  groupCreationOptionDetails?: IGroupCreationDetails;
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
}
const AddParameterDropdownButton = (
  props: IAddParameterDropdownButtonProps
) => {
  const classes = useStyles();
  const menuActions = React.useRef<PopoverActions>(null);

  const {
    options,
    onOptionClick,
    groupCreationOptionDetails,
    disabled = false,
    loading = false,
    testId = 'add-parameter-dropdown-button',
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateMenuPosition = () => {
    if (menuActions.current) {
      menuActions.current.updatePosition();
    }
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'icon-button-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        disableRipple
        classes={{
          root: classNames(classes.menuIconButton, {
            [`${classes.menuIconButton}--open`]: open,
          }),
        }}
        disabled={disabled}
        data-testid={testId}
      >
        <Typography variant="body2">Add</Typography>
        {open ? (
          <ExpandLess data-testid="expandLessIcon" />
        ) : (
          <ExpandMore data-testid="expandMoreIcon" />
        )}
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-label': 'icon-button-menu',
        }}
        PaperProps={{
          className: classes.paper,
        }}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        action={menuActions}
      >
        {loading ? (
          <MenuItem disableRipple>
            <Loader
              size={25}
              classes={{
                container: classes.progressContainer,
                progress: classes.progress,
              }}
            />
          </MenuItem>
        ) : (
          options.map((option: IOptionType) => (
            <MenuItem
              key={option.value}
              className={classes.menuItem}
              disableRipple
              onClick={() => {
                onOptionClick(option.value);
                handleClose();
              }}
            >
              <div>
                <Typography variant="body2">{option.label}</Typography>
              </div>
            </MenuItem>
          ))
        )}
        {groupCreationOptionDetails && [
          <Divider className={classes.divider} key={`${testId}-divider`} />,
          <ExpandableMenuItem
            key={`${testId}-groupItem`}
            {...groupCreationOptionDetails}
            indentation={2}
            onOptionClick={(value: string) => {
              groupCreationOptionDetails.onOptionClick(value);
              handleClose();
            }}
            updateMenuPosition={updateMenuPosition}
          />,
        ]}
      </Menu>
    </>
  );
};

export default AddParameterDropdownButton;
