import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import BulkUploadModal from 'components/BulkUploadModal/BulkUploadModal';

import StyledButton, {
  ButtonColorEnum,
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { OptionType, IFormProps } from 'interfaces';

import { dedupeValues } from 'utils/dataTransformation';
import {
  IValidatePostcodesResponse,
  VALIDATE_POSTCODES,
} from 'graphql/postcodes/queries';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

export interface IPostcodeBulkUploadProps {
  name: string;
  label: string;
  placeholder: string;
  dataTc: string;
}

export const parseTextPostcodes = (postcodes = '') =>
  postcodes.split(/\s+/g).filter((postcode) => !!postcode);

export const parseCsvPostcodes = (values: { [key: string]: string }[]) =>
  values.flatMap((code) => Object.values(code));

export const aggregatePostcodes = (
  existingPostcodes: OptionType[],
  newPostcodes: string[]
) => {
  const formattedPostcodes = newPostcodes.map((code) => ({
    label: code,
    value: code,
    readOnly: false,
  }));

  return dedupeValues([...existingPostcodes, ...formattedPostcodes]);
};

const PostcodeBulkUpload = (
  props: IFormProps<any> & IPostcodeBulkUploadProps
) => {
  const { name, values, setFieldValue, dataTc } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadedPostcodes, setUploadedPostcodes] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const selectedPostcodes = get(values, name) || [];

  const validatePostcodes = useLazyQuery<IValidatePostcodesResponse>(
    VALIDATE_POSTCODES
  );

  const handleUpload = async (postcodes: any[]) => {
    setIsLoading(true);

    try {
      const { data } = await validatePostcodes({
        postcodes,
      });

      setUploadedPostcodes(data.validatePostcodes.valid);
      setModalOpen(false);
    } catch {
      setError(
        'Postcode validation service is currently unavailable. Please try again later'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (name && uploadedPostcodes.length) {
      setFieldValue(
        name,
        aggregatePostcodes(selectedPostcodes, uploadedPostcodes)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedPostcodes]);

  return (
    <>
      <StyledButton
        color={ButtonColorEnum.Primary}
        onClick={() => setModalOpen(!isModalOpen)}
        variant={ButtonVariantEnum.Outlined}
        data-tc={`${dataTc}UploadButton`}
        testId={`${dataTc}UploadButton`}
      >
        Bulk Upload
      </StyledButton>
      {isModalOpen && (
        <BulkUploadModal
          {...props}
          label="Postcodes"
          name="textPostcodes"
          placeholder="Separate postcodes with a space or a new line"
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          onUpload={handleUpload}
          parseTextValues={parseTextPostcodes}
          parseCsvValues={parseCsvPostcodes}
          dataTc={`${dataTc}-modal`}
          error={error}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default PostcodeBulkUpload;
