import type { ValueLabelProps } from 'features/targetingV2/components/core/Slider';
import Tooltip from 'features/targetingV2/components/core/Tooltip/Tooltip';
import Typography from 'features/targetingV2/components/core/Typography';
import { ISliderValue } from 'features/targetingV2/types/common';
import { getValueByIndex } from 'features/targetingV2/utils/slider';
import React from 'react';

import useStyles from './AccuracyValueLabel.styles';

export interface IAccuracyValueLabelProps extends ValueLabelProps {
  allValues: ISliderValue[];
}

const AccuracyValueLabel = ({
  allValues,
  ...valueLabelProps
}: IAccuracyValueLabelProps) => {
  const { value, children, open } = valueLabelProps;
  const accuracyValue = getValueByIndex(value, allValues);
  const classes = useStyles();

  return accuracyValue ? (
    <Tooltip
      data-testid={`accuracy-value-tooltip-${value}`}
      placement="bottom"
      open={open}
      title={<Typography variant="caption">{accuracyValue} Km</Typography>}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      {children}
    </Tooltip>
  ) : null;
};

export default AccuracyValueLabel;
