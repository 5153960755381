import 'regenerator-runtime/runtime';

import classNames from 'classnames';
import ErrorDialog, { ISetErrorModal } from 'components/ErrorDialog/ErrorDialog';
import Loader from 'components/Loader/Loader';
import Tab, { ITabProps } from 'components/Tab/Tab';
import useTabStyles from 'components/Tab/Tab.styles';
import useTableStyles from 'components/Table/Table.styles';
import TableFilter from 'components/Table/TableFilter';
import TableFooter from 'components/Table/TableFooter';
import TableLoader from 'components/Table/TableLoader';
import TableToolbar from 'components/Table/TableToolbar';
import WarningDialog, { ISetWarningModal } from 'components/WarningDialog/WarningDialog';
import { UPDATE_TABLE_FILTERS } from 'context/SessionProvider/reducer';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { ILocationState } from 'interfaces';
import { EntityStatus } from 'interfaces/generated.types';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Filters,
  Row,
  SortingRule,
  TableInstance,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  customGetToggleAllPageRowsSelectedProps,
  customGetToggleRowSelectedProps,
} from 'utils/tables';

import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs/Tabs';

import TableCheckbox from './TableCheckbox';
import TableHeader from './TableHeader';

export interface ICloneCellData {
  entity: {
    id: string;
    name: string;
  };
  cloneChildren: boolean;
  hasChildren: boolean;
  isEdit: boolean;
  setErrorModal: (values: ISetErrorModal) => void;
}

export interface IDeleteCellData {
  entity: {
    id: string;
    name: string;
  };
  setErrorModal: (values: ISetErrorModal) => void;
}

export interface IValidateCellData {
  entity: {
    id: string;
    targetStatus: EntityStatus;
  };
  setErrorModal: (values: ISetErrorModal) => void;
  setWarningModal: (values: ISetWarningModal) => void;
  setUpdating: (value: boolean) => void;
  handleContinue: () => void;
}

export interface ITableFetch {
  pageIndex: number;
  pageSize: number;
  filters?: Filters<Row<Record<string, unknown>>>;
  sortBy?: SortingRule<Row<Record<string, unknown>>>[];
  search?: string;
}

export interface ITableErrorState {
  isOpened: boolean;
  errors: string[];
  handleContinue?: () => void;
  content: { title: string; closeButton: string; continueButton: string };
}

interface ITablePaginationProps {
  manualPagination: boolean;
  pageCount?: number;
  initialPageSize?: number;
}

interface ITableSelectionProps {
  manualSelection: boolean;
  initialSelections?: Record<string, boolean>;
  columnLabel?: string;
}

interface ITableFilteringProps {
  manualFiltering: boolean;
  initialFilters?: Filters<Row<Record<string, unknown>>>;
}

interface ITableSortingProps {
  manualSorting: boolean;
  initialSortBy?: SortingRule<Row<Record<string, unknown>>>[];
}

interface ITableSearchProps {
  manualSearching: boolean;
  initialSearch?: string;
}

export interface ITableProps extends Record<string, any> {
  columns: any;
  data: any[];
  isEditable?: boolean;
  title?: string;
  errorMessage?: string | undefined;
  dataTc: string;
  showColumnToggle?: boolean;
  isPageTable?: boolean;
  hiddenColumns?: string[];
  history?: History;
  tabs?: ITabProps[];
  selectedTab?: number;
  onTabChange?(e: React.ChangeEvent<{}>, index: number): void;
  onHiddenColumnsChange?: (columns: string[]) => void;
  customToolbarCtas?: React.ReactNode;
  hideToolbar?: boolean;
  CustomHeader?: React.ElementType<any>;
  customHeaderProps?: any;
  pagination?: ITablePaginationProps;
  filtering?: ITableFilteringProps;
  selection?: ITableSelectionProps;
  sorting?: ITableSortingProps;
  searching?: ITableSearchProps;
  fetchData?: (arg: ITableFetch) => void;
  selectRow?: (arg: string[]) => void;
  loading?: boolean;
  fetchingData?: boolean;
  shouldRefetchData?: boolean;
  updateRefetchData?: (value: boolean) => void;
  renderRowSubComponent?: (arg: Row<any>) => void;
}

const getStyles = (props: any, style = {}) => [
  props,
  {
    style,
  },
];

const cellProps = (props: any, { cell }: any) =>
  getStyles(props, cell.column && cell.column.style);

export const updatePaginationInHistory = (
  history: History,
  locationState: ILocationState,
  index: number
) => {
  const { table } = locationState || 0;

  history &&
    history.push({
      pathname: window.location.pathname,
      state: {
        ...locationState,
        table: { ...table, pagination: index },
      },
    });
};

const Table = (props: ITableProps) => {
  const {
    columns,
    data,
    title,
    isEditable = true,
    showColumnToggle = true,
    errorMessage,
    dataTc,
    isPageTable = true,
    hiddenColumns = [],
    history,
    tabs,
    selectedTab,
    onTabChange,
    onHiddenColumnsChange,
    hideToolbar = false,
    customToolbarCtas,
    CustomHeader,
    customHeaderProps,
    pagination,
    filtering,
    sorting,
    searching,
    selection,
    fetchData,
    selectRow,
    loading = false,
    renderRowSubComponent,
    fetchingData = false,
    shouldRefetchData = false,
    updateRefetchData,
    ...rest
  } = props;

  const classes = useTableStyles();
  const tabClasses = useTabStyles();
  const location = usePreviousLocation();

  const [isUpdating, setUpdating] = useState(false);
  const [paginationPageIndex, setPaginationPageIndex] = useState(0);
  const [paginationPageSize, setPaginationPageSize] = useState(
    pagination?.initialPageSize || 15
  );
  const [{ isOpened, errors, handleContinue, content }, setErrorModal] =
    useState<ITableErrorState>({
      isOpened: false,
      errors: [],
      content: { title: 'Error', closeButton: 'Close', continueButton: '' },
    });

  const toggleErrorModal = () =>
    setErrorModal((prevState: ITableErrorState) => ({
      ...prevState,
      isOpened: !prevState.isOpened,
    }));

  const {
    hasWarning,
    handleWarningContinue,
    setWarningModal,
    toggleWarningModal,
  } = useWarning();

  const defaultColumn = useMemo(
    () => ({
      Filter: TableFilter,
    }),
    []
  );

  const { state: appState, dispatch } = useSessionContext();

  const storedFilters =
    dataTc && appState.pages[dataTc]?.filters
      ? appState.pages[dataTc]?.filters
      : [];

  const storedGlobalFilter =
    dataTc && appState.pages[dataTc]?.globalFilter
      ? appState.pages[dataTc]?.globalFilter
      : '';

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: {
      filters,
      globalFilter,
      pageIndex,
      pageSize,
      sortBy,
      selectedRowIds,
      hiddenColumns: currentHiddenColumns,
    },
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    allColumns: tableColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      isEditable,
      ...rest,
      setErrorModal,
      setWarningModal,
      setUpdating,
      initialState: {
        pageSize: paginationPageSize,
        hiddenColumns: ['id', ...hiddenColumns],
        ...(selection?.initialSelections && {
          selectedRowIds: selection.initialSelections,
        }),
        ...{ filters: filtering?.initialFilters || storedFilters },
        ...(sorting?.initialSortBy && { sortBy: sorting?.initialSortBy }),
        ...{ globalFilter: searching?.initialSearch || storedGlobalFilter },
      },
      useControlledState: (state) =>
        useMemo(
          () => ({
            ...state,
            pageIndex: paginationPageIndex,
            pageSize: paginationPageSize,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, paginationPageIndex, paginationPageSize]
        ),
      getRowId: (row: Row) => row.id,
      manualPagination: pagination?.manualPagination,
      manualFilters: filtering?.manualFiltering,
      manualSortBy: sorting?.manualSorting,
      manualGlobalFilter: searching?.manualSearching,
      pageCount: pagination?.pageCount,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      customGetToggleAllPageRowsSelectedProps(hooks);
      customGetToggleRowSelectedProps(hooks);
      hooks.allColumns.push((allColumns: any) => [
        ...(selection?.manualSelection
          ? [
              {
                id: 'selection',
                // eslint-disable-next-line react/display-name
                Header: ({
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  getToggleAllPageRowsSelectedProps,
                }: TableInstance) => (
                  <div>
                    {selection.columnLabel && (
                      <Typography variant="body1">
                        {selection.columnLabel}
                      </Typography>
                    )}
                    <TableCheckbox {...getToggleAllPageRowsSelectedProps()} />
                  </div>
                ),
                disableFilters: true,
                disableSortBy: true,
                Filter: () => null,
                // eslint-disable-next-line react/display-name
                Cell: ({ row }: { row: Row }) => (
                  <div>
                    <TableCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
            ]
          : []),
        ...allColumns,
      ]);
    }
  );

  const noop = () => {};
  const fetchDataDebounced = useAsyncDebounce(fetchData || noop, 250);

  const memoizedSelectedRowIds = useMemo(
    () => Object.keys(selectedRowIds),
    [selectedRowIds]
  );

  const handleTabChange = () => {
    setPaginationPageIndex(0);
    if (history) updatePaginationInHistory(history, location.state, 0);
  };

  useEffect(() => {
    // Dispatch action to update context if filters or globalFilter have changed
    if (
      !isEqual(storedFilters, filters) ||
      (globalFilter && !isEqual(storedGlobalFilter, globalFilter))
    ) {
      dispatch({
        type: UPDATE_TABLE_FILTERS,
        payload: { parentPageName: dataTc, filters, globalFilter },
      });

      setPaginationPageIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, globalFilter]);

  useEffect(() => {
    if (onHiddenColumnsChange)
      onHiddenColumnsChange(currentHiddenColumns || []);
  }, [currentHiddenColumns, onHiddenColumnsChange]);

  useEffect(() => {
    const { table } = location.state || 0;
    const { pagination: storedPagination, rowsPerPage: storedRowsPerPage } =
      table || 0;

    if (storedPagination) setPaginationPageIndex(storedPagination);
    if (storedRowsPerPage) setPaginationPageSize(storedRowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (fetchData) {
      fetchDataDebounced({
        pageIndex,
        pageSize,
        filters,
        sortBy,
        search: globalFilter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, filters, sortBy, globalFilter]);

  useEffect(() => {
    setAllFilters(storedFilters);
    setGlobalFilter(storedGlobalFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (fetchData && shouldRefetchData) {
      fetchDataDebounced({
        pageIndex,
        pageSize,
        filters,
        sortBy,
        search: globalFilter,
      });
      if (updateRefetchData) updateRefetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchData]);

  useEffect(() => {
    selectRow && selectRow(memoizedSelectedRowIds);
  }, [selectRow, memoizedSelectedRowIds]);

  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <>
          {CustomHeader && (
            <CustomHeader
              {...customHeaderProps}
              setAllFilters={setAllFilters}
              tableFilters={filters}
              history={history}
            />
          )}
          {!hideToolbar && (
            <TableToolbar
              title={title}
              columns={tableColumns}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              setAllFilters={setAllFilters}
              errorMessage={errorMessage}
              showColumnToggle={showColumnToggle}
              isPageTable={isPageTable}
              customToolbarCtas={customToolbarCtas}
            />
          )}
          <div
            className={classNames(classes.root, {
              [`${classes.root}--updating`]: isUpdating || fetchingData,
            })}
          >
            <MuiTableContainer>
              {tabs && (
                <AppBar position="static" classes={{ root: tabClasses.bar }}>
                  <Tabs
                    value={selectedTab}
                    data-tc={`${dataTc}-tabs`}
                    onChange={(e, index) => {
                      handleTabChange();
                      if (onTabChange) onTabChange(e, index);
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        label={tab.label}
                        dataTc={tab.dataTc}
                        key={tab.dataTc}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              )}
              <MuiTable
                {...getTableProps()}
                className={classes.table}
                aria-label="table"
                data-tc={dataTc}
                data-testid={dataTc}
              >
                <TableHeader
                  headerGroups={headerGroups}
                  showFilters={!!filtering}
                  tabTable={!!tabs}
                />
                <MuiTableBody
                  {...getTableBodyProps()}
                  className={classes.body}
                  aria-label="table-body"
                >
                  {(pagination ? page : rows).map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <MuiTableRow
                          {...rowProps}
                          data-tc={row.original.id}
                          data-testid={row.original.id}
                          className={classNames(classes.row, {
                            [`${classes.row}--subRow`]: !!(row.original as any)
                              .subRow,
                          })}
                        >
                          {row.cells.map((cell: Cell<Row>) => (
                            // eslint-disable-next-line react/jsx-key
                            <MuiTableCell
                              {...cell.getCellProps(cellProps)}
                              className={classes.cell}
                            >
                              {cell.render('Cell')}
                            </MuiTableCell>
                          ))}
                        </MuiTableRow>
                        {renderRowSubComponent && renderRowSubComponent(row)}
                      </React.Fragment>
                    );
                  })}
                </MuiTableBody>
                <TableFooter
                  totalItems={
                    pagination?.manualPagination && pagination?.pageCount
                      ? pagination.pageCount
                      : rows.length
                  }
                  gotoPage={gotoPage}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  history={history}
                  setPaginationIndex={setPaginationPageIndex}
                  setPaginationRowsPerPage={setPaginationPageSize}
                />
              </MuiTable>
            </MuiTableContainer>
            {isUpdating || fetchingData ? <TableLoader /> : null}
            {isOpened ? (
              <ErrorDialog
                content={{
                  title: content.title,
                  closeButton: content.closeButton,
                  continueButton: content.continueButton,
                }}
                handleContinue={handleContinue}
                isOpen={isOpened}
                handleClose={toggleErrorModal}
                dataTc={`${dataTc}Dialog`}
                errorMessages={errors}
              />
            ) : null}
            {hasWarning ? (
              <WarningDialog
                handleContinue={handleWarningContinue}
                handleClose={toggleWarningModal}
                isOpen={hasWarning}
                dataTc={`${dataTc}WarningDialog`}
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Table;
