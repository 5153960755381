import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import UpdateAdContainer from 'features/direct/ad/containers/UpdateAdContainer/UpdateAdContainer';
import { History } from 'history';
import { Ad, PermissionName, useAdQuery } from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface UpdateAdPageProps {
  history: History;
  match: {
    params: {
      adId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const UpdateAdPage = ({
  history,
  match,
  userPermissions,
}: UpdateAdPageProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const {
    loading: loadingAd,
    data: adData,
    error,
  } = useAdQuery({
    errorPolicy: 'all',
    variables: {
      id: match.params.adId,
      territories: [activeTerritory!],
    },
  });

  const hasErrorAndNoData = error && !(adData && adData.ad);
  const noErrorAndNoData = !error && !(adData && adData.ad);

  if (!loadingAd && (hasErrorAndNoData || noErrorAndNoData))
    return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Ad</h1>
        {loadingAd ? (
          <Loader />
        ) : (
          <UpdateAdContainer
            history={history}
            ad={adData?.ad as Ad}
            hasEditPermissions={hasEditPermissions}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateAdPage;
