import { IAdValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import { adButtons } from 'features/direct/ad/components/AdTabsForm/AdTabsForm';
import { defaultTargetingDefinitionV2 } from 'features/targetingV2/utils/defaults';
import {
  Campaign,
  FrequencyCapTimeUnit,
  Territory,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import { createSelectOptions } from 'utils/dataTransformation';
import dateUtils from 'utils/date';
import { numbroDecimalFormatting } from 'utils/numbers';
import {
  groupTargetingGroups,
  ITargetingDefinition,
} from 'utils/targetingDefinitions';

const formatInitialValues = (
  campaign: Campaign,
  activeTerritory: Territory | undefined
): IAdValues => {
  const {
    startDate: campaignStartDate,
    endDate: campaignEndDate,
    name,
    timeZone,
    id,
    targetingDefinition,
    territory: campaignTerritory,
    targetingVersion: campaignTargetingVersion,
  } = campaign;

  const targeting =
    targetingDefinition && targetingDefinition
      ? groupTargetingGroups(targetingDefinition as ITargetingDefinition)
      : { groups: [], templates: [], general: null };

  return {
    duration: '',
    name: '',
    id: '',
    campaignCpm:
      typeof campaign.cpm === 'number'
        ? numbro(campaign.cpm).format(numbroDecimalFormatting)
        : '',
    startDate: campaignStartDate
      ? dateUtils.getDateInSpecificTimezone(campaignStartDate, timeZone)
      : null,
    startDateIsInherited: !!campaignStartDate,
    isStartDateInheritable: true,
    endDate: campaignEndDate
      ? dateUtils.getDateInSpecificTimezone(campaignEndDate, timeZone)
      : null,
    endDateIsInherited: !!campaignEndDate,
    isEndDateInheritable: true,
    frequencyCapCount: '',
    frequencyCapTimeUnit: FrequencyCapTimeUnit.TimeUnitUnspecified,
    frequencyCapValue: '',
    campaignName: name as string,
    campaignId: id,
    campaignStartDate: campaignStartDate
      ? dateUtils.getDateInSpecificTimezone(campaignStartDate, timeZone)
      : null,
    audioCreatives: [],
    creativeRedirect: {
      url: null,
      id: null,
      altId: null,
      consentVendorId: null,
      trackingUrls: [],
      territory: activeTerritory!,
    },
    imageCreatives: [],
    weighting: '',
    dailyCap: '',
    sequence: [],
    targetingMasterTemplates: targeting.templates,
    targetingGroups: targeting.groups,
    targetingGeneralGroup: targeting.general,
    tags: [],
    inheritedTags: createSelectOptions(campaign.tags).map((tag) => ({
      ...tag,
      readOnly: true,
    })),
    tagsAreInherited: true,
    journey: adButtons[0].url,
    adExclusive: false,
    territory: campaignTerritory!,
    targetingDefinitionV2: defaultTargetingDefinitionV2,
    targetingVersion: campaignTargetingVersion,
    targetingRestrictions: [],
  };
};

export default formatInitialValues;
