import { FieldArray, getIn, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import NumberInput from 'components/NumberInput/NumberInput';
import StyledButton, {
  ButtonVariantEnum,
} from 'components/StyledButton/StyledButton';

import { IFormProps } from 'interfaces';

import useStyles from './CreativeRedirect.styles';

const CreativeRedirect = ({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  values,
}: IFormProps<any>) => {
  const classes = useStyles({});

  return (
    <div className={classes.container}>
      {typeof values.creativeRedirect.url === 'string' ? (
        <>
          <StyledButton
            type="button"
            data-tc="removeCreativeRedirect"
            variant={ButtonVariantEnum.Text}
            onClick={() =>
              setFieldValue('creativeRedirect', {
                url: null,
                consentVendorId: null,
                trackingUrls: [],
              })
            }
          >
            <CloseIcon fontSize="large" />
            <span className="sr-only">Remove creative redirect</span>
          </StyledButton>
          <div className={classes.adCreativeRedirect}>
            <Field
              component={TextField}
              fullWidth
              label="Redirect URL"
              placeholder="Redirect URL"
              data-tc="adCreativeRedirect"
              name="creativeRedirect.url"
              margin="normal"
              helperText={
                getIn(touched, 'creativeRedirect.url') &&
                getIn(errors, 'creativeRedirect.url')
              }
              FormHelperTextProps={{
                error: !!(
                  getIn(touched, 'creativeRedirect.url') &&
                  getIn(errors, 'creativeRedirect.url')
                ),
              }}
            />
            <NumberInput
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.creativeRedirect.consentVendorId}
              allowNegative={false}
              dataTc="adCreativeRedirectConsentVendorId"
              decimalScale={0}
              name="creativeRedirect.consentVendorId"
              touched={getIn(touched, 'creativeRedirect.consentVendorId')}
              error={getIn(errors, 'creativeRedirect.consentVendorId')}
              label="Consent Vendor Id"
              fullWidth
            />

            <FieldArray name="creativeRedirect.trackingUrls">
              {({ remove, insert }) => (
                <>
                  {!!values.creativeRedirect.trackingUrls &&
                    values.creativeRedirect.trackingUrls.map(
                      (_: any, index: number) => (
                        /* eslint-disable react/no-array-index-key */
                        <div key={index} className={classes.trackingUrlsItems}>
                          <div className={classes.trackingUrlItem}>
                            <Field
                              component={TextField}
                              label="Tracking URL"
                              placeholder="Tracking URL"
                              data-tc="creativeRedirectTrackingUrl"
                              name={`creativeRedirect.trackingUrls.${index}.url`}
                              fullWidth
                              helperText={
                                getIn(
                                  touched,
                                  `creativeRedirect.trackingUrls.${index}.url`
                                ) &&
                                getIn(
                                  errors,
                                  `creativeRedirect.trackingUrls.${index}.url`
                                )
                              }
                              FormHelperTextProps={{
                                error: !!(
                                  getIn(
                                    touched,
                                    `creativeRedirect.trackingUrls.${index}.url`
                                  ) &&
                                  getIn(
                                    errors,
                                    `creativeRedirect.trackingUrls.${index}.url`
                                  )
                                ),
                              }}
                            />
                            <NumberInput
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values.creativeRedirect.trackingUrls[index]
                                  .consentVendorId || ''
                              }
                              allowNegative={false}
                              dataTc="creativeRedirectTrackingUrlConsentVendorId"
                              decimalScale={0}
                              name={`creativeRedirect.trackingUrls.${index}.consentVendorId`}
                              touched={getIn(
                                touched,
                                `creativeRedirect.trackingUrls.${index}.consentVendorId`
                              )}
                              error={getIn(
                                errors,
                                `creativeRedirect.trackingUrls.${index}.consentVendorId`
                              )}
                              label="Consent Vendor Id"
                              fullWidth
                            />
                          </div>

                          {values.creativeRedirect.trackingUrls.length > 1 && (
                            <StyledButton
                              type="button"
                              data-tc="creativeRedirectTrackingUrlRemoveButton"
                              variant={ButtonVariantEnum.Text}
                              onClick={() => remove(index)}
                            >
                              <CloseIcon />
                              <span className="sr-only">
                                Remove the tracking url grouping from the
                                creative redirect
                              </span>
                            </StyledButton>
                          )}
                          <StyledButton
                            type="button"
                            data-tc="creativeRedirectTrackingUrlAddButton"
                            variant={ButtonVariantEnum.Text}
                            onClick={() =>
                              insert(index + 1, {
                                url: '',
                                consentVendorId: '',
                                trackingUrls: [],
                              })
                            }
                            disabled={
                              values.creativeRedirect.trackingUrls.length >= 5
                            }
                          >
                            <AddIcon />
                            <span className="sr-only">
                              Add a new tracking url to the creative redirect
                            </span>
                          </StyledButton>
                        </div>
                      )
                    )}
                </>
              )}
            </FieldArray>
          </div>
        </>
      ) : (
        <IconButton
          data-tc="addCreativeRedirect"
          className={classes.iconAdd}
          onClick={() =>
            setFieldValue('creativeRedirect', {
              url: '',
              consentVendorId: '',
              trackingUrls: [{ url: '', consentVendorId: '' }],
            })
          }
        >
          <span>Add a Redirect URL</span>
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};

export default CreativeRedirect;
