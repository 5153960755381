import { DspStatus, SeatStatus, Territory } from 'interfaces/generated.types';

export interface ISeatFormValues {
  id: string;
  altId?: number;
  externalSeatId: string;
  name: string;
  journey: string;
  status?: SeatStatus;
  dspName: string;
  dspId: string;
  dspTerritories: { value: Territory; label: string }[];
  territories: { value: Territory; label: string }[];
}

export const seatStatusValues = [
  {
    value: SeatStatus.Draft,
    label: 'Draft',
    isDisabled: (value: SeatStatus) =>
      [SeatStatus.Active, SeatStatus.Inactive].includes(value),
  },
  {
    value: SeatStatus.Active,
    label: 'Active',
    isDisabled: (value: SeatStatus) => [SeatStatus.Draft].includes(value),
  },
  {
    value: SeatStatus.Inactive,
    label: 'Inactive',
    isDisabled: (value: SeatStatus) => [SeatStatus.Draft].includes(value),
  },
];

export const getSeatStatusData = (
  values: ISeatFormValues,
  dspStatus: DspStatus
) => [
  {
    status: SeatStatus.Draft,
    required: [
      ...(!values.name ? ["Complete 'Name'"] : []),
      ...(!values.dspName ? ['Complete Parent DSP'] : []),
    ],
  },
  {
    status:
      values.status === SeatStatus.Inactive
        ? SeatStatus.Inactive
        : SeatStatus.Active,
    required: [
      ...(!values.name ? ['Waiting for Name'] : []),
      ...(dspStatus !== DspStatus.Active
        ? ['Waiting for Parent DSP to be in ACTIVE state']
        : []),
      ...(!values.externalSeatId ? ['Waiting for External Seat ID'] : []),
    ],
  },
];

export const seatDetailsFields = ['name', 'externalSeatId'];
