import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(5),
    width: '100%',
    height: '500px',
  },
}));

export default useStyles;
