import { makeStyles, Theme } from '@material-ui/core/styles';

const mediaLibraryStyles = makeStyles((theme: Theme) => ({
  form: {
    height: '90%',
    overflowY: 'scroll',
  },
  list: {
    height: '80%',
    overflowY: 'scroll',
    boxShadow: `0 0 5px 2.5px ${theme.palette.grey[400]}`,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
  },
}));

export default mediaLibraryStyles;
