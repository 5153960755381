import classNames from 'classnames';
import Button from 'features/targetingV2/components/core/Button';
import CircularProgress from 'features/targetingV2/components/core/CircularProgress';
import IconButton from 'features/targetingV2/components/core/IconButton';
import InputAdornment from 'features/targetingV2/components/core/InputAdornment';
import TextField from 'features/targetingV2/components/core/TextField';
import Typography from 'features/targetingV2/components/core/Typography';
import useCheckboxTree, {
  CheckboxTreeState,
  Node,
  NodeLike,
  SearchMatchingLogic,
} from 'features/targetingV2/hooks/UseCheckboxTree';
import React, { useEffect } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

import useStyles from './CheckboxTree.styles';
import CheckboxTreeNode from './CheckboxTreeNode';

interface ICheckboxTreeProps {
  data: NodeLike[];
  title?: string;
  onNodeToggle?: (nodes: Node[], state: CheckboxTreeState) => void;
  onControlledNodeToggle?: (newSelectedNodes: NodeLike[]) => void;
  selected?: NodeLike[];
  isStateControlled?: boolean;
  filterText?: string;
  classes?: {
    root?: string;
    list?: string;
    title?: string;
    nodeContainer?: string;
    label?: string;
    toggle?: string;
    checkbox?: string;
    search?: string;
    controls?: string;
    noNodesMessage?: string;
  };
  defaultExpandIcon?: React.ReactNode;
  defaultCollapseIcon?: React.ReactNode;
  disableSelection?: boolean;
  disableFilteringField?: boolean;
  disableSelectionControls?: boolean;
  disableExpansionControls?: boolean;
  selectAllNodes?: boolean;
  unselectAllNodes?: boolean;
  displayAncestorPath?: boolean;
  noNodesMessage?: string;
  searchResultsLimit?: number;
  handleNoSearchResults?: (hasNoSearchResults: boolean) => void;
  testId?: string;
}

const CheckboxTree = ({
  data,
  title,
  onNodeToggle,
  onControlledNodeToggle,
  selected,
  isStateControlled = false,
  filterText = '',
  classes: externalClasses = {},
  defaultExpandIcon = <ExpandMoreIcon style={{ pointerEvents: 'none' }} />,
  defaultCollapseIcon = <ExpandLessIcon style={{ pointerEvents: 'none' }} />,
  disableSelection = false,
  disableFilteringField = false,
  disableSelectionControls = false,
  disableExpansionControls = false,
  selectAllNodes = false,
  unselectAllNodes = false,
  displayAncestorPath = false,
  noNodesMessage,
  searchResultsLimit,
  handleNoSearchResults,
  testId,
}: ICheckboxTreeProps) => {
  const {
    nodes,
    state,
    getCheckboxProps,
    getExpandButtonProps,
    getSearchInputProps,
    getTreeItemProps,
    isExpanded,
    selectAll,
    selectNone,
    expandAll,
    collapseAll,
    onExternalSearch,
    clearFilter,
    refreshState,
  } = useCheckboxTree({
    data,
    initialState: {
      selected,
    },
    filterOptions: {
      matchingLogic: SearchMatchingLogic.Includes,
      removeParentLevels: true,
    },
    isStateControlled,
    onControlledNodeToggle,
    searchResultsLimit,
  });

  const classes = useStyles({});

  useEffect(() => {
    if (onNodeToggle && !isStateControlled) onNodeToggle(nodes, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedNodesMap]);

  useEffect(() => {
    if (selected && isStateControlled) refreshState(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selectAllNodes) {
      selectAll();
    }
    if (unselectAllNodes) {
      selectNone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllNodes, unselectAllNodes]);

  useEffect(() => {
    onExternalSearch(filterText, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  useEffect(() => {
    if (handleNoSearchResults) handleNoSearchResults(nodes.length === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, nodes]);

  return (
    <div
      className={classNames([classes.root, externalClasses.root])}
      data-testid={testId}
    >
      {!disableFilteringField ? (
        <TextField
          {...getSearchInputProps()}
          name="search"
          placeholder="Search..."
          value={state.filter}
          fullWidth
          className={classNames([classes.search, externalClasses.search])}
          InputProps={{
            inputProps: { 'aria-label': 'Search' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                <span className="sr-only">Search</span>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {state.searching ? <CircularProgress size={20} /> : null}
                <IconButton
                  aria-label="clear search"
                  onClick={clearFilter}
                  size="small"
                  disabled={state.searching}
                  disableRipple
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : null}

      {!disableSelectionControls ? (
        <div
          className={classNames([classes.controls, externalClasses.controls])}
        >
          <Button type="button" color="secondary" onClick={selectAll}>
            Select All
          </Button>
          <Button type="button" color="secondary" onClick={selectNone}>
            Select None
          </Button>
        </div>
      ) : null}

      {title && nodes.length > 0 && (
        <Typography
          variant="caption"
          className={classNames([classes.title, externalClasses.title])}
        >
          {title}
        </Typography>
      )}

      {nodes.length > 0 && (
        <ul
          className={classNames([
            classes.list,
            externalClasses.list,
            `${classes.list}--outer`,
            { [`${classes.list}--hasTitle`]: !!title },
          ])}
          role="tree"
          aria-label="checkboxTree-list"
        >
          {nodes.map((node) => (
            <CheckboxTreeNode
              key={node.id}
              node={node}
              disableSelection={disableSelection}
              classes={{ ...classes, ...externalClasses }}
              getCheckboxProps={getCheckboxProps}
              getExpandButtonProps={getExpandButtonProps}
              getTreeItemProps={getTreeItemProps}
              isExpanded={isExpanded}
              defaultCollapseIcon={defaultCollapseIcon}
              defaultExpandIcon={defaultExpandIcon}
              displayAncestorPath={displayAncestorPath}
            />
          ))}
        </ul>
      )}
      {nodes.length === 0 && noNodesMessage && (
        <div
          className={classNames([
            classes.noNodesMessage,
            externalClasses.noNodesMessage,
          ])}
        >
          {noNodesMessage}
        </div>
      )}

      {!disableExpansionControls ? (
        <div
          className={classNames([classes.controls, externalClasses.controls])}
        >
          <Button type="button" color="secondary" onClick={expandAll}>
            Expand All
          </Button>
          <Button type="button" color="secondary" onClick={collapseAll}>
            Collapse All
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default CheckboxTree;
