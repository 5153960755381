import pageStyles from 'assets/styles/components/Page.styles';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { dspStatusValues } from 'features/programmatic/dsp/components/DspTabsForm/DspFormValues';
import { GET_TECHNICAL_PROVIDER } from 'features/programmatic/technicalProvider/graphql/queries';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  Dsp,
  DspStatus,
  EntityType,
  useUpdateDspMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

interface IDspsTableProps {
  dsps: Dsp[];
  match: {
    params: {
      technicalProviderId: string;
    };
  };
  history: History;
  isEditable: boolean;
}

interface IDspsRow {
  id: string;
  altId: number;
  name: string;
  status: DspStatus;
}

const formatData = memoizeOne((data: Dsp[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const DspsTable = ({
  history,
  match,
  dsps,
  isEditable = true,
}: IDspsTableProps) => {
  const classes = pageStyles();
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();
  const [updateDsp] = useUpdateDspMutation({
    refetchQueries: [
      {
        query: GET_TECHNICAL_PROVIDER,
        variables: {
          id: match.params.technicalProviderId,
          territories: activeTerritory,
        },
      },
    ],
  });
  const validateDsp = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createDspCta = isEditable && (
    <StyledLink
      location={{
        pathname: `/programmatic-connector/${match.params.technicalProviderId}/dsp`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newDspButton"
    >
      Create New DSP
    </StyledLink>
  );

  const dspColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IDspsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/dsp/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        /* eslint-disable react/display-name */
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IDspsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: dspStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={dspStatusValues}
                name="dspStatusSelect"
                dataTc="dspStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="DSPS"
        columns={dspColumns}
        data={formatData(dsps)}
        isEditable={isEditable}
        dataTc={`${match.params.technicalProviderId || ''}listDspTable`}
        isPageTable={false}
        onCellUpdate={(row: IDspsRow, setErrorModal, setUpdating) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updateDsp,
            handleContinue: () => history.push(`/dsp/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Dsp',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateDsp,
            entity: { ...entity, type: EntityType.Dsp },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createDspCta}
      />
    </div>
  );
};

export default DspsTable;
