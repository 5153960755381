import classNames from 'classnames';
import SelectableDayTimeCell from 'features/targetingV2/components/core/DayTime/DayTimeCell/DayTimeCell';
import Table from 'features/targetingV2/components/core/Table';
import TableBody from 'features/targetingV2/components/core/TableBody';
import TableCell from 'features/targetingV2/components/core/TableCell';
import TableHead from 'features/targetingV2/components/core/TableHead';
import TableRow from 'features/targetingV2/components/core/TableRow';
import { IDayTimePoint } from 'features/targetingV2/types/targeting';
import {
  handleDaySelection,
  handleHourSelection,
  handleSelectionFinish,
  hoursInDay,
  weekdayOptions,
} from 'features/targetingV2/utils/dayTime';
import { OptionType } from 'interfaces';
import some from 'lodash/some';
import React, { useRef } from 'react';
import { SelectableGroup } from 'react-selectable-fast';

import useStyles from './DayTimeTable.styles';

export interface IDayTimeTableProps {
  dayTimePoints: IDayTimePoint[];
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  CustomFooter?: React.ElementType<any>;
  classes?: {
    container?: string;
    table?: string;
  };
}

const DayTimeTable = (props: IDayTimeTableProps) => {
  const {
    dayTimePoints,
    fieldName,
    setFieldValue,
    CustomFooter,
    classes: externalClasses = {},
  } = props;

  const classes = useStyles();
  const selectableGroupRef = useRef<SelectableGroup>(null);

  const rowHasCellSelected = (hour: number) =>
    weekdayOptions.some((day) =>
      some(dayTimePoints, {
        day: day.value,
        hour,
      })
    );

  return (
    <div className={classNames([classes.container, externalClasses.container])}>
      <SelectableGroup
        ref={selectableGroupRef}
        enableDeselect
        tolerance={5}
        allowClickWithoutSelected
        selectboxClassName={`.${classes.columnHeadings}`}
        onSelectionFinish={(items: any) =>
          handleSelectionFinish(
            dayTimePoints,
            items,
            fieldName,
            setFieldValue,
            selectableGroupRef
          )
        }
        ignoreList={[`.${classes.columnHeadings}`, `.${classes.rowHeadings}`]}
      >
        <Table
          className={classNames([classes.table, externalClasses.table])}
          data-testid="dayTimeTable"
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.columnHeadings,
                }}
              />
              {weekdayOptions.map((day: OptionType) => (
                <TableCell
                  key={`day-${day.value}`}
                  classes={{
                    root: classes.columnHeadings,
                  }}
                >
                  <button
                    type="button"
                    className={classes.columnHeadingButton}
                    onClick={() => {
                      handleDaySelection(
                        dayTimePoints,
                        fieldName,
                        day.value,
                        setFieldValue
                      );
                    }}
                  >
                    {day.label}
                  </button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {hoursInDay.map((hour: number) => (
              <TableRow key={`hour-${hour}`}>
                <TableCell
                  classes={{
                    root: classes.rowHeadings,
                  }}
                >
                  <button
                    type="button"
                    className={classNames([
                      classes.rowHeadingButton,
                      {
                        [`${classes.rowHeadingButton}--selected`]:
                          rowHasCellSelected(hour),
                      },
                    ])}
                    onClick={() => {
                      handleHourSelection(
                        dayTimePoints,
                        fieldName,
                        hour,
                        setFieldValue
                      );
                    }}
                  >
                    {hour.toString().padStart(2, '0')}
                  </button>
                </TableCell>
                {weekdayOptions.map((day: OptionType) => (
                  <TableCell
                    key={`dayTime-${day.value}-${hour}`}
                    classes={{
                      root: classes.tableCell,
                    }}
                  >
                    <SelectableDayTimeCell
                      day={`${day.value}`}
                      hour={hour}
                      selected={some(dayTimePoints, {
                        day: day.value,
                        hour,
                      })}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SelectableGroup>
      {CustomFooter && (
        <div className={classes.footer}>
          <CustomFooter />
        </div>
      )}
    </div>
  );
};

export default DayTimeTable;
