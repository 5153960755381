import { baseDialogStyles } from 'assets/styles/components/Dialog.styles';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...baseDialogStyles(theme),
  title: {
    ...baseDialogStyles(theme).title,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    justifyContent: 'center',
  },
  wrapper: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  selectWrapper: {
    marginBottom: theme.spacing(6.25),
  },
  content: {
    ...baseDialogStyles(theme).content,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    fontStyle: 'italic',
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
