import classNames from 'classnames';
import Button from 'features/targetingV2/components/core/Button';
import React from 'react';

import useStyles from './StyledButton.styles';

export enum ButtonColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
}

export enum ButtonVariantEnum {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}

const StyledButton = ({
  children,
  color = ButtonColorEnum.Default,
  type,
  className,
  disabled,
  onClick,
  isLoading,
  variant = ButtonVariantEnum.Contained,
  testId,
  externalWrapper,
}: {
  children: any;
  color?: ButtonColorEnum;
  href?: string;
  type?: any;
  className?: string;
  disabled?: boolean;
  onClick?: (value: any) => void;
  isLoading?: boolean;
  variant?: ButtonVariantEnum;
  testId?: string;
  externalWrapper?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.wrapper, externalWrapper)}>
      <Button
        className={className}
        classes={{
          root: classes.button,
          containedPrimary: classes.containedPrimary,
          outlinedPrimary: classes.outlinedPrimary,
          outlinedSecondary: classes.outlinedSecondary,
          text: classes.text,
        }}
        type={type}
        variant={variant}
        color={color}
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
        disableRipple
        loading={isLoading}
      >
        {children}
      </Button>
    </div>
  );
};

export default StyledButton;
