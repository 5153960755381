import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    label: {
      position: 'absolute',
      zIndex: 1,
      color: theme.palette.grey[200],
      fontSize: theme.spacing(1.75),
      display: 'inline',
      lineHeight: theme.spacing(2.5),
    },
    text: {
      fontSize: theme.spacing(1.875),
      display: 'inline',
      color: theme.palette.common.black,
    },
    legend: {
      display: 'flex',
      fontSize: theme.spacing(1.875),
      justifyContent: 'center',
    },
    bar: {
      height: theme.spacing(2),
      borderRadius: '5px',
      background: theme.palette.grey[300],
    },
    barColor: {
      backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    },
  })
);

export default useStyles;
