import { makeStyles, Theme } from '@material-ui/core/styles';

import { basePageStyles } from 'assets/styles/components/Page.styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...basePageStyles(theme),
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
