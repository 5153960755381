import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import FormButtons from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ForecastingChart from 'features/forecasting/components/ForecastingChart/ForecastingChart';
import ForecastingDetails, {
  IForecastingValues,
} from 'features/forecasting/components/ForecastingDetails/ForecastingDetails';
import IabCategoriesTree from 'features/iabCategories/containers/IabCategoriesTree/IabCategoriesTree';
import TargetingVersionSelection from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSelection';
import {
  IAllLocationAccuracies,
  IParameterInfo,
} from 'features/targetingV2/types/targeting';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { IFormProps } from 'interfaces';
import { Forecast, TargetingVersion } from 'interfaces/generated.types';
import React, { useEffect, useState } from 'react';
import { handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

interface IForecastingTabsProps {
  data: Forecast | undefined;
  dateOfForecastGeneration: Date | undefined;
  history: History;
  allParametersInfo: IParameterInfo[];
  allLocationAccuracies: IAllLocationAccuracies | undefined;
  loading: boolean;
}

const ForecastingTabsForm = (
  props: IFormProps<IForecastingValues> & IForecastingTabsProps
) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    values,
    data,
    history,
    dateOfForecastGeneration,
    errors,
    allParametersInfo,
    allLocationAccuracies,
    loading,
  } = props;
  const classes = TabStyles();

  const availableImpressionDataPoints = data?.availableImpressions?.dataPoints;
  const bookedImpressionDataPoints = data?.bookedImpressions?.dataPoints;

  const mergedTargetingDefinition = data?.mergedTargetingDefinition;

  const bookedImpressions = data?.bookedImpressionsTotal;
  const availableImpressions = data?.availableImpressionsTotal;

  const isDataPoints =
    availableImpressionDataPoints && bookedImpressionDataPoints;

  const [selectedTab, setTab] = useState(0);
  const [selectedIabCategoriesNames, setSelectedIabCategoriesNames] = useState<
    string[]
  >([]);

  const isForecastingDetailsTab = selectedTab === 0;
  const isIabCategoriesTab = selectedTab === 1;
  const isTargetingTab = selectedTab === 2;
  const isForecastTab = selectedTab === 3;

  useEffect(() => {
    if (isDataPoints) setTab(3);
  }, [availableImpressionDataPoints, bookedImpressionDataPoints, isDataPoints]);

  const location = usePreviousLocation();

  useEffect(() => {
    const { tab } = location.state || 0;
    if (tab) setTab(tab);
  }, [location]);

  const getSelectedIabCategoriesNames = (value: string[]) => {
    setSelectedIabCategoriesNames(value);
  };
  const targetingV2Info = {
    allParametersInfo,
    allLocationAccuracies: {
      allValues: [...(allLocationAccuracies?.allValues || [])].reverse(),
    },
  };

  return (
    <>
      <Form className={classes.form} role="form">
        <AppBar position="static" classes={{ root: classes.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="forecastingTabs"
          >
            <Tab dataTc="forecastingDetailsTab" label="Details" />
            <Tab dataTc="iabCategoriesTab" label="IAB Categories" />
            <Tab dataTc="targetingTab" label="Targeting" />
            <Tab
              disabled={!isDataPoints}
              dataTc="forecastTab"
              label="Forecast"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames(classes.formView, {
            [`${classes.formView}--noPadding`]:
              isTargetingTab &&
              values.targetingVersion === TargetingVersion.TargetingV2,
          })}
        >
          {isForecastingDetailsTab && <ForecastingDetails {...props} />}
          {isIabCategoriesTab && (
            <IabCategoriesTree
              dataTc="iabCategories"
              onChange={setFieldValue}
              attribute="iabCategoryCodes"
              iabCategoryCodes={values.iabCategoryCodes}
              setIabCategoriesNames={getSelectedIabCategoriesNames}
            />
          )}
          {isTargetingTab && (
            <TargetingVersionSelection {...props} filterByActiveTerritory />
          )}
          {isForecastTab && isDataPoints && (
            <ForecastingChart
              data={{
                availableImpressionDataPoints,
                bookedImpressionDataPoints,
              }}
              iabCategories={selectedIabCategoriesNames}
              mergedTargetingDefinition={mergedTargetingDefinition}
              totals={{ bookedImpressions, availableImpressions }}
              values={values}
              dateOfForecastGeneration={dateOfForecastGeneration}
              targetingV2Info={targetingV2Info}
              errors={errors}
            />
          )}
        </div>
        <FormButtons
          dataTc="generateForecast"
          disabled={!isValid || !dirty || isSubmitting || loading}
          onClick={handleSubmit}
          isLoading={isSubmitting || loading}
          goBackTo={{
            pathname: '/',
            state: location.state,
          }}
        >
          Generate Forecast
        </FormButtons>
      </Form>
      <LeavingPrompt when={dirty && !isSubmitting} />
    </>
  );
};

export default ForecastingTabsForm;
