import classNames from 'classnames';
import FormControl from 'features/targetingV2/components/core/FormControl';
import Loader from 'features/targetingV2/components/core/Loader';
import MenuItem from 'features/targetingV2/components/core/MenuItem';
import Select from 'features/targetingV2/components/core/Select';
import { IOptionType, SectionType } from 'features/targetingV2/types/common';
import { lowerCase } from 'lodash';
import React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';

import useStyles from './ParameterSelect.styles';

interface IParameterSelectProps {
  sectionType: SectionType;
  options: IOptionType[];
  areOptionsLoading: boolean;
  fieldName: string;
  fieldValue: string;
  setFieldValue: (field: string, value: any) => void;
  customOnChange?: (parameterType: string) => void;
  isVisible?: boolean;
  readOnly?: boolean;
}
const ParameterSelect = (props: IParameterSelectProps) => {
  const classes = useStyles();
  const {
    sectionType,
    options,
    areOptionsLoading,
    setFieldValue,
    fieldName,
    fieldValue,
    customOnChange,
    isVisible = true,
    readOnly = false,
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (customOnChange) customOnChange(event.target.value as string);

    setFieldValue(`${fieldName}.type`, event.target.value as string);
  };

  const customRenderValue = (selectedValue: any) => {
    if (!selectedValue) return 'Select Parameter';

    const selectedParameter = options.find(
      (param) => param.value === selectedValue
    );

    return selectedParameter?.label || 'Select Parameter';
  };

  const sectionName = lowerCase(sectionType);

  return (
    <FormControl
      component={'fieldset' as 'div'}
      className={classNames([
        classes.section,
        {
          [`${classes.section}--visibility`]: !isVisible,
        },
      ])}
      data-testid={`parameterSelectField-${fieldName}`}
    >
      <Select
        data-testid={`parameterSelect-${fieldName}`}
        name={fieldName}
        value={fieldValue}
        label={`${sectionName}Parameter`}
        onChange={handleChange}
        IconComponent={readOnly ? () => null : ExpandMore}
        readOnly={readOnly}
        inputProps={{
          classes: {
            root: classes.dropdown,
            select: classNames(classes.select, {
              [`${classes.select}--readOnly`]: readOnly,
            }),
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.selectMenu,
          },
        }}
        renderValue={customRenderValue}
        displayEmpty
      >
        {areOptionsLoading && (
          <MenuItem
            disableRipple
            className={classNames([classes.unSelectableOption, classes.loader])}
          >
            <Loader />
          </MenuItem>
        )}
        {!areOptionsLoading && !options.length ? (
          <MenuItem disableRipple className={classes.unSelectableOption}>
            No parameters
          </MenuItem>
        ) : (
          options.map((option: IOptionType) => (
            <MenuItem
              disableRipple
              key={option.value}
              value={option.value}
              className={classes.menuItem}
              disabled={!!option.readOnly}
            >
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default ParameterSelect;
