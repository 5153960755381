import { ChannelStatus } from 'interfaces/generated.types';
import { parseFormattedValue } from 'utils/numbers';
import commons from 'validations/commons';
import * as Yup from 'yup';

const ChannelFormValidation = (channelStatus?: ChannelStatus) =>
  Yup.object().shape(
    {
      name: commons.name('Channel Name'),
      territories: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          })
        )
        .required('Channel should be assigned at least one territory'),
      externalDealId: commons.max255Characters(),
      inventoryType: Yup.string().required('Choose inventory type'),
      floorCpm: commons.optionalPrice({
        messages: {
          minMessage: 'Floor CPM should be higher than or equal to 0',
          maxMessage: 'Floor CPM should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'channelFloorCpm',
      }),
      blacklistedDomains: commons.domains({
        fieldKey: 'blacklistedDomains',
        message: 'Only a valid domain is allowed',
      }),
      blockedKeyValues: Yup.array()
        .of(Yup.object().shape({ key: Yup.string(), value: Yup.string() }))
        .notRequired(),
      adRequestEnrichment: Yup.object().shape({
        customKvps: commons.customKvps,
      }),
      trafficAcceptancePercentage: commons.optionalPrice({
        messages: {
          minMessage: 'Percentage should be a positive number',
          maxMessage: 'Percentage should be 100 or lower',
        },
        minimum: 0,
        maximum: 1,
        fieldKey: 'trafficAcceptancePercentage',
      }),
      transcodePresets: Yup.array().test(
        'transcodePresets',
        'A transcode preset is required',
        (transcodePresets) => {
          if (channelStatus === ChannelStatus.Enabled) {
            return !!transcodePresets.length;
          }
          return true;
        }
      ),
      minAdDuration: commons
        .optionalPrice({
          messages: {
            minMessage:
              'Minimum ad duration should be greater than 0.001 seconds',
            maxMessage: 'Minimum ad duration should be less than 300 seconds',
          },
          minimum: 0.001,
          maximum: 300,
          fieldKey: 'minAdDuration',
        })
        .when(
          'maxAdDuration',
          (maxAdDuration: string, schema: Yup.StringSchema) =>
            schema.test(
              'minAdDuration',
              'Minimum ad duration should not be greater than maximum ad duration',
              (minAdDuration: string) => {
                if (minAdDuration && maxAdDuration)
                  return (
                    parseFormattedValue(minAdDuration) <=
                    parseFormattedValue(maxAdDuration)
                  );
                return true;
              }
            )
        )
        .when(
          'maxAdDuration',
          (maxAdDuration: string, schema: Yup.StringSchema) =>
            schema.test(
              'minAdDuration',
              'Minimum ad duration is required when maximum ad duration is less than 5',
              (minAdDuration: string) => {
                if (maxAdDuration) {
                  const min = parseFormattedValue(minAdDuration);
                  const max = parseFormattedValue(maxAdDuration);
                  if (max <= 5) {
                    return !!min && min > 0;
                  }
                }
                return true;
              }
            )
        ),

      maxAdDuration: commons
        .optionalPrice({
          messages: {
            minMessage: 'Maximum ad duration should be greater than 1 second',
            maxMessage: 'Maximum ad duration should be less than 300 seconds',
          },
          minimum: 1,
          maximum: 300,
          fieldKey: 'maxAdDuration',
        })
        .when(
          'minAdDuration',
          (minAdDuration: string, schema: Yup.StringSchema) =>
            schema.test(
              'maxAdDuration',
              'Maximum ad duration must not be less than minimum ad duration',
              (maxAdDuration: string) => {
                if (minAdDuration && maxAdDuration)
                  return (
                    parseFormattedValue(minAdDuration) <=
                    parseFormattedValue(maxAdDuration)
                  );
                return true;
              }
            )
        ),
    },
    [
      ['maxAdDuration', 'minAdDuration'],
      ['minAdDuration', 'maxAdDuration'],
    ]
  );

export default ChannelFormValidation;
