import { getAllGradesOrdered } from 'features/targetingV2/utils/grading';
import { TargetingGrade } from 'interfaces/generated.types';
import React from 'react';

import GradeColumn from './GradeColumn';
import useStyles from './GradingIcon.styles';

interface IGradingProps {
  enabledGrades: TargetingGrade[];
  id?: string;
  disabled?: boolean;
}

const GradingIcon = (props: IGradingProps) => {
  const { enabledGrades, id = 'gradingIcon', disabled = false } = props;
  const classes = useStyles({});

  return (
    <div className={classes.container} data-testid={id}>
      {getAllGradesOrdered().map((grade) => (
        <GradeColumn
          key={`${id}-${grade}`}
          id={`${id}-${grade}`}
          selected={enabledGrades.includes(grade)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default GradingIcon;
