import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    borderRadius: 0,
    fontSize: theme.spacing(2.375),
    background: 'transparent',
    textDecoration: 'none',
    border: 0,
    color: theme.palette.common.white,
    display: 'inline-flex',
    padding: theme.spacing(1, 0),
    margin: '0 1.625rem 0 0',
    minWidth: 'fit-content',
    '@media screen and (max-width: 1200px)': {
      fontSize: theme.spacing(2),
    },
    '&--positionRight': {
      marginLeft: theme.spacing(3),
      marginRight: 0,
    },
    '&--autoLeftMargin': {
      marginLeft: 'auto',
      marginRight: 0,
    },
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&--active': {
      borderBottom: '1px solid white',
    },
  },
  expandIcon: {
    paddingLeft: theme.spacing(0.25),
  },
  paper: {
    boxShadow: 'none',
    top: `${theme.spacing(5.625)} !important`,
  },
  list: {
    padding: 0,
    width: '100%',
    color: theme.palette.common.black,
  },
  item: {
    padding: 0,
    background: theme.palette.common.white,
    minHeight: theme.spacing(4.8),
    borderBottom: `4px solid ${theme.palette.common.white}`,
    '&:first-child': {
      borderTop: `4px solid ${theme.palette.common.white}`,
    },
  },
  linkSlim: {
    width: '100%',
    textDecoration: 'none',
    padding: theme.spacing(0, 3),
    color: theme.palette.common.black,
    lineHeight: theme.spacing(4.8),
    '&:hover, &:focus': {
      background: theme.palette.grey[200],
    },
  },
  logout: {
    composes: '$linkSlim',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.spacing(2),
    borderRadius: 'unset',
    fontWeight: 'normal',
    alignSelf: 'center',
    justifyContent: 'end',
  },
}));

export default useStyles;
