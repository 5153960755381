import memoizeOne from 'memoize-one';
import React from 'react';

import Loader from 'components/Loader/Loader';

import TargetingMultiSelect from 'features/targeting/components/TargetingMultiSelect/TargetingMultiSelect';

import { IFormProps } from 'interfaces';
import {
  OperatingSystemAttributes,
  useAllDeviceOsQuery,
} from 'interfaces/generated.types';

import { ITargetingComponentProps } from 'utils/targetingDefinitions';

export const deviceOSValues = {
  deviceOperatingSystemParams: {
    deviceOperatingSystems: [],
  },
};

const formatData = memoizeOne((data: OperatingSystemAttributes[]) =>
  data.map((deviceOS: OperatingSystemAttributes) => ({
    label: deviceOS.name,
    options: deviceOS.versions.map((version: string) => ({
      label: version,
      value: version,
      os: deviceOS.name,
    })),
  }))
);

const TargetingDeviceOS = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const { loading, data } = useAllDeviceOsQuery({
    fetchPolicy: 'cache-first',
  });

  if (loading) return <Loader />;

  return (
    <TargetingMultiSelect
      {...props}
      label="Device OS"
      targetingName="deviceOperatingSystemParams.deviceOperatingSystems"
      options={
        data && data.allDeviceOperatingSystems
          ? formatData(data.allDeviceOperatingSystems)
          : []
      }
    />
  );
};

export default TargetingDeviceOS;
