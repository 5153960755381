import pageStyles from 'assets/styles/components/Page.styles';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';
import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { GET_NETWORK } from 'features/inventory/network/graphql/queries';
import { publisherStatusValues } from 'features/inventory/publisher/components/PublisherTabsForm/PublisherFormValues';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  EntityType,
  Publisher,
  PublisherStatus,
  useUpdatePublisherMutation,
} from 'interfaces/generated.types';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';

interface IPublisherTableProps {
  isEditable: boolean;
  history: History;
  match: {
    params: {
      networkId: string;
    };
  };
  publishers: Publisher[];
}

interface IPublisherRow {
  id: string;
  altId: number;
  name: string;
  status: PublisherStatus;
}

const formatData = memoizeOne((data: Publisher[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    status: d.status,
  }))
);

const PublishersTable = ({
  isEditable = true,
  history,
  match,
  publishers,
}: IPublisherTableProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const [updatePublisher] = useUpdatePublisherMutation({
    refetchQueries: [
      {
        query: GET_NETWORK,
        variables: {
          id: match.params.networkId,
          territories: [activeTerritory!],
        },
      },
    ],
  });

  const validatePublisher = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const classes = pageStyles();

  const location = usePreviousLocation();

  const createPublisherCta = isEditable && (
    <StyledLink
      location={{
        pathname: `/network/${match.params.networkId}/publisher`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newPublisherButton"
    >
      Create New Publisher
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IPublisherRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/publisher/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IPublisherRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: publisherStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={publisherStatusValues}
                name="publisherStatusSelect"
                dataTc="publisherStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title="Network Publishers"
        columns={columns}
        data={formatData(publishers)}
        isEditable={isEditable}
        dataTc="listPublishersTable"
        isPageTable={false}
        onCellUpdate={(
          row: IPublisherRow,
          setErrorModal: any,
          setUpdating: any
        ) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
            },
            update: updatePublisher,
            handleContinue: () => history.push(`/publisher/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Publisher',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validatePublisher,
            entity: { ...entity, type: EntityType.Publisher },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createPublisherCta}
      />
    </div>
  );
};

export default PublishersTable;
