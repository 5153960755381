import mergeClasses from 'features/targetingV2/utils/mergeClasses';
import React from 'react';

import MUISwitch, {
  SwitchProps as MUISwitchProps,
} from '@material-ui/core/Switch';

import useStyles from './Switch.styles';

export type SwitchProps = MUISwitchProps;

const Switch = React.forwardRef(
  (props: SwitchProps, ref: React.Ref<any>): React.ReactElement => {
    const { classes: userClasses = {}, ...otherProps } = props;
    const classes = mergeClasses(useStyles(), userClasses);
    return <MUISwitch classes={classes} ref={ref} {...otherProps} />;
  }
);

Switch.displayName = 'Switch';

export default Switch;
