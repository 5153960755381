import { IParameterValueOption } from 'features/targetingV2/types/common';
import {
  IAudienceParameter,
  IAudienceParameterGroup,
} from 'features/targetingV2/types/targeting';
import {
  AudienceGroupAttributes,
  AudienceParameterAttributes,
  AudienceParameterType,
} from 'interfaces/generated.types';

import { isTargetingParameterInvalid } from './common';

export const getAudienceParameterValuesAttributes = (
  currentParameter: IAudienceParameter
) => {
  const validAudienceParameterValues = currentParameter.values.map(
    (parameterValue: IParameterValueOption) => ({
      id: parameterValue.value,
    }),
    []
  );
  return validAudienceParameterValues;
};

export const getAudienceParametersAttributes = (
  parameters: IAudienceParameter[]
) =>
  parameters.reduce(
    (
      parametersAttributesResult: AudienceParameterAttributes[],
      currentParameter: IAudienceParameter
    ) => {
      const currentParameterWithValidValues = {
        type: currentParameter.type as AudienceParameterType,
        clusivity: currentParameter.clusivity,
        values: getAudienceParameterValuesAttributes(currentParameter),
        enabledGrades: currentParameter.enabledGrades,
      };
      const parameterAttributes = isTargetingParameterInvalid(
        currentParameterWithValidValues
      )
        ? null
        : currentParameterWithValidValues;

      if (parameterAttributes)
        parametersAttributesResult.push(
          parameterAttributes as AudienceParameterAttributes
        );

      return parametersAttributesResult;
    },
    []
  );

export const getAudiencesAttributes = (audiences: IAudienceParameterGroup[]) =>
  audiences.reduce(
    (
      audiencesAttributesResult: AudienceGroupAttributes[],
      currentAudience: IAudienceParameterGroup
    ) => {
      const audienceAttributes: AudienceGroupAttributes = {
        parameters: getAudienceParametersAttributes(currentAudience.parameters),
      };

      if (audienceAttributes.parameters.length > 0)
        audiencesAttributesResult.push(audienceAttributes);

      return audiencesAttributesResult;
    },
    []
  );

export const getAudienceTargetingAttributes = (formValues: any) => {
  if (
    !formValues?.targetingDefinitionV2?.audienceTargeting?.audiences ||
    !formValues.targetingDefinitionV2.audienceTargeting.audiences.length
  )
    return null;

  const audiencesAttributes = getAudiencesAttributes(
    formValues.targetingDefinitionV2.audienceTargeting.audiences
  );

  return !audiencesAttributes.length
    ? null
    : {
        audiences: audiencesAttributes,
      };
};
