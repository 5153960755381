import { ReactComponent as AlertIcon } from 'assets/icons/TargetingV2/dax-alert.svg';
import AddParameterButton from 'features/targetingV2/components/AddParameterButton/AddParameterButton';
import Typography from 'features/targetingV2/components/core/Typography';
import CustomGroup from 'features/targetingV2/components/CustomSection/CustomGroup/CustomGroup';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import { IFormProps, SectionType } from 'features/targetingV2/types/common';
import { ICustomParameterGroup } from 'features/targetingV2/types/targeting';
import { defaultCustomParameterGroup } from 'features/targetingV2/utils/defaults';
import { FieldArray } from 'formik';
import React from 'react';

import useStyles from './CustomBuilder.styles';

const CustomBuilder = (props: IFormProps<any>) => {
  const { values, setFieldValue, errors, status } = props;

  const classes = useStyles();

  const customGroupsFieldName =
    'targetingDefinitionV2.customTargeting.customGroups';

  const { customGroups } = values.targetingDefinitionV2.customTargeting;

  const optionalAddFirstParameterButton = !customGroups.length && (
    <AddParameterButton
      onClick={() =>
        setFieldValue(customGroupsFieldName, [defaultCustomParameterGroup])
      }
      testId="add-first-custom-group-btn"
    />
  );

  const optionalStatusMessage = status?.customKvps && (
    <div className={classes.statusMessageWrapper}>
      <AlertIcon className={classes.statusIcon} />
      <Typography variant="body2">{status.customKvps}</Typography>
    </div>
  );

  return (
    <TargetingSection
      sectionType={SectionType.Custom}
      headerChildren={
        <>
          {optionalAddFirstParameterButton}
          {optionalStatusMessage}
        </>
      }
      hideDivider
    >
      <FieldArray name={customGroupsFieldName}>
        {({ remove: removeCustomGroup }) => (
          <>
            {customGroups.map(
              (customGroup: ICustomParameterGroup, index: number) => (
                <CustomGroup
                  // eslint-disable-next-line react/no-array-index-key
                  key={`customGroup-${index}`}
                  customGroup={customGroup}
                  customGroupFieldName={`${customGroupsFieldName}[${index}]`}
                  customGroupIndex={index}
                  setFieldValue={setFieldValue}
                  removeCustomGroup={() => removeCustomGroup(index)}
                  errors={errors}
                />
              )
            )}
          </>
        )}
      </FieldArray>
    </TargetingSection>
  );
};

export default CustomBuilder;
