/* eslint-disable no-return-assign */
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

interface ILeavingPromptProps {
  when: boolean;
  message?: string;
}

const LeavingPrompt = ({
  when,
  message = 'There are unsaved changes on this page. Are you sure you want to leave?',
}: ILeavingPromptProps) => {
  const history = useHistory();
  const lastPathName = useRef(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen(
      ({ pathname }) => (lastPathName.current = pathname)
    );

    // eslint-disable-next-line consistent-return
    const unblock = history.block(({ pathname }) => {
      if (lastPathName.current !== pathname && when) return message;
    });

    return () => {
      unlisten();
      unblock();
    };
  }, [history, when, message]);

  return null;
};

export default LeavingPrompt;
