import gql from 'graphql-tag';

export const GET_CHANNEL = gql`
  query Channel($id: ID!) {
    channel(id: $id) {
      id
      altId
      territories
      publisher {
        id
        name
        status
        territories
      }
      name
      vastVersion
      externalDealId
      type
      inventoryType
      locale
      timeZone
      floorCpm
      blacklistedDomains
      blockedKeyValues {
        key
        values
      }
      status
      defaultTranscodePreset {
        id
      }
      transcodePresets {
        id
        altId
        name
      }
      blacklistedIabCategories {
        name
        code
      }
      overrideConsentSignal
      trafficAcceptancePercentage
      includedInDefaultInventoryTargeting
      targetingRestrictions
      minAdDuration
      maxAdDuration
      adRequestEnrichment {
        parameters {
          type
          values {
            id
            name
          }
        }
        customKvps {
          key
          value
          override
        }
      }
    }
  }
`;

export const GET_CHANNEL_ASSOCIATED_ADS = gql`
  query ChannelAssociatedAds($id: ID!, $territories: [Territory!]!) {
    channel(id: $id) {
      id
      associatedAds(
        filter: { status_in: [LIVE, READY, PAUSED] }
        territories: $territories
      ) {
        id
        altId
        name
        startDate {
          isInherited
          value
        }
        endDate {
          isInherited
          value
        }
        status
        campaign {
          id
          name
          timeZone
          pacing
          owner {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_CHANNEL_ASSOCIATED_DEALS = gql`
  query ChannelAssociatedDeals($id: ID!, $territories: [Territory!]!) {
    channel(id: $id) {
      id
      associatedDeals(
        territories: $territories
        filter: { status_in: [ACTIVE, READY] }
      ) {
        id
        altId
        name
        startDate
        endDate
        timeZone
        status
        priority
        salesChannel {
          id
          name
          owner {
            id
            email
          }
        }
      }
    }
  }
`;

export const GET_CHANNEL_TIME_SERIES = gql`
  query ChannelTimeSeries($id: ID!, $timeZone: ZoneId!) {
    channel(id: $id) {
      id
      timeZone
      timeSeries(params: { timeUnit: HOUR, timeZone: $timeZone }) {
        impressions {
          dataPoints {
            t
            v
          }
        }
      }
    }
  }
`;

export const GET_ALL_CHANNELS_MINIMAL = gql`
  query AllChannelsMinimal($publisherId: ID, $territories: [Territory!]!) {
    allChannels(publisherId: $publisherId, territories: $territories) {
      id
      altId
      name
    }
  }
`;

export const GET_ALL_CHANNELS = gql`
  query AllChannels($publisherId: ID, $territories: [Territory!]!) {
    allChannels(publisherId: $publisherId, territories: $territories) {
      id
      altId
      name
      trafficAcceptancePercentage
      type
      publisher {
        network {
          name
          id
        }
        name
        id
      }
      status
    }
  }
`;

export const GET_ALL_SHOW_CONTRACTS = gql`
  query allShowContracts($channelId: ID, $filter: ShowContractFilter) {
    allShowContracts(channelId: $channelId, filter: $filter) {
      id
      altId
      startDate
      endDate
      show {
        show_id
        name
        hostingPlatform
      }
      directSpotCpm
      programmaticSpotCpm
      spotRevenueShare
      hostReadRevenueShare
      hostReadCpm
      sponsorshipRevenueShare
      territories
      currency
      notes
    }
  }
`;

export const GET_ALL_SHOWS = gql`
  query allShows {
    allShows {
      show_id
      name
      hostingPlatform
    }
  }
`;

export const GET_ALL_AD_REQUEST_ENRICHMENT_AUDIENCE_PARAMETERS = gql`
  query allAdRequestEnrichmentAudienceParameters {
    allAdRequestEnrichmentAudienceParameters {
      type
      name
      cardinality
    }
  }
`;

export const GET_ALL_AD_REQUEST_ENRICHMENT_AUDIENCE_PARAMETER_VALUES = gql`
  query AllAdRequestEnrichmentAudienceParameterValues(
    $type: AudienceParameterType!
  ) {
    allAdRequestEnrichmentAudienceParameterValues(type: $type) {
      id
      name
      active
      children {
        id
        name
        active
        children {
          id
          name
          active
          children {
            id
            name
            active
          }
        }
      }
    }
  }
`;
