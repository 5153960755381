import { ApolloError } from 'apollo-client';
import { IOption } from 'interfaces';
import {
  PublisherInsightsFilterCategory,
  PublisherInsightsMetric,
  PublisherInsightsMetricAggregationType,
  PublisherInsightsMetricType,
  PublisherInsightsTimeSeries,
  Territory,
} from 'interfaces/generated.types';
import { camelCase, find, get, startCase } from 'lodash';
import numbro from 'numbro';
import dateUtils, { TimeZones } from 'utils/date';

import { ChartAggregatorEnum } from './charts';
import {
  CurrenciesEnum,
  currencyValues,
  getDefaultCurrencyLabelBasedOnCurrency,
} from './currency';
import { getDefaultCurrencyBasedOnTerritory } from './defaultsByTerritory';
import { GraphqlErrorCodes } from './errors';
import {
  numbroFullDecimalFormatting,
  numbroNumberFormatting,
  numbroPercentageFormatting,
} from './numbers';

export interface ISelectedFilter {
  filterCategory: PublisherInsightsFilterCategory;
  ids: string[];
}

export const getInsightsMetricLabel = (metric: PublisherInsightsMetric) => {
  switch (metric) {
    case PublisherInsightsMetric.Ltr:
      return metric;
    case PublisherInsightsMetric.PublisherRevenue:
      return 'Revenue';
    case PublisherInsightsMetric.Ecpm:
      return 'eCPM';
    case PublisherInsightsMetric.AdsRequestedEcpm:
      return 'Ads Requested eCPM';
    default:
      return startCase(camelCase(metric));
  }
};

export const getInsightsMetricSelectOptions = () =>
  Object.values(PublisherInsightsMetric).map((metric) => ({
    label: getInsightsMetricLabel(metric),
    value: metric,
  }));

export const toolTipNumberFormat = (metric: PublisherInsightsMetric) => {
  switch (metric) {
    case PublisherInsightsMetric.PublisherRevenue:
      return '#.';
    case PublisherInsightsMetric.FillRate:
    case PublisherInsightsMetric.Ltr:
    case PublisherInsightsMetric.SellThroughRate:
      return '#.00%';
    case PublisherInsightsMetric.Ecpm:
    case PublisherInsightsMetric.AdsRequestedEcpm:
      return '#.##';
    default:
      return '';
  }
};

export const isPercentageChart = (metric: PublisherInsightsMetric) => {
  switch (metric) {
    case PublisherInsightsMetric.FillRate:
    case PublisherInsightsMetric.Ltr:
    case PublisherInsightsMetric.SellThroughRate:
      return true;
    default:
      return false;
  }
};

export const getInsightsMetricTotalValue = (
  metricType: PublisherInsightsMetricType,
  value: number,
  currency: string
) => {
  const currencyValue = currencyValues.find(
    (currencyVal) => currencyVal.value === currency
  )!.value;

  switch (metricType) {
    case PublisherInsightsMetricType.Percent:
      return numbro(value).format(numbroPercentageFormatting);
    case PublisherInsightsMetricType.Financial:
      return `${numbro(value).format(
        numbroFullDecimalFormatting
      )} ${getDefaultCurrencyLabelBasedOnCurrency(currencyValue)}`;
    default:
      return numbro(value).format(numbroNumberFormatting);
  }
};

export const getDataSet = (
  selectedMetric: PublisherInsightsMetric,
  data: PublisherInsightsTimeSeries[]
) => data.find((dataSet) => dataSet.metric === selectedMetric);

export interface IFormattedInsightsLineGraphData {
  value: PublisherInsightsMetric;
  series: {
    date: Date;
    value: number | undefined;
  }[];
  aggregator: ChartAggregatorEnum;
}

export const getChartAggregatorEnum = (
  metricAggregationType: PublisherInsightsMetricAggregationType
) => {
  switch (metricAggregationType) {
    case PublisherInsightsMetricAggregationType.WeightedAverage:
      return ChartAggregatorEnum.WEIGHTED_AVERAGE;
    default:
      return ChartAggregatorEnum.SUM;
  }
};

export const formatChartData = (
  data: PublisherInsightsTimeSeries,
  timeZone: TimeZones,
  weightingData?: PublisherInsightsTimeSeries
) => [
  {
    value: data.metric,
    series: data.timeseries.dataPoints.map((point) => ({
      date: dateUtils.getDateInSpecificTimezone(point.t, timeZone),
      value: point.v,
      ...(weightingData && {
        weighting: get(
          find(weightingData.timeseries.dataPoints, { t: point.t }),
          'v',
          null
        ),
      }),
    })),
    aggregator: getChartAggregatorEnum(data.metricAggregationType),
  },
];

export const currenciesBasedOnTerritories = (userTerritories: Territory[]) =>
  userTerritories.map((territory) =>
    getDefaultCurrencyBasedOnTerritory(territory)
  );

export const getAvailableCurrencyOptions = (
  currencies: IOption<CurrenciesEnum>[],
  currenciesBasedOnUserTerritories: CurrenciesEnum[]
) =>
  currencies.filter((currencyValue) =>
    currenciesBasedOnUserTerritories.includes(currencyValue.value)
  );
export const getLineChartErrorMessage = (
  metric: PublisherInsightsMetric,
  responseError: ApolloError | undefined
) => {
  const errorCode = get(
    responseError,
    'graphQLErrors[0].extensions.code',
    ''
  ) as GraphqlErrorCodes;

  if (errorCode === GraphqlErrorCodes.BAD_USER_INPUT) {
    const errorMessage = get(responseError, 'graphQLErrors[0].message', '');
    const invalidMetrics = get(
      responseError,
      'graphQLErrors[0].extensions.invalidMetrics',
      ''
    );

    if (invalidMetrics?.length)
      return `"${getInsightsMetricLabel(
        invalidMetrics[0] as PublisherInsightsMetric
      )}" is not filterable by the given filters`;

    return errorMessage;
  }
  return '';
};

export const datePresetOptions = [7, 14, 28];

export const calculateDataTrend = (
  previousTotalValue: number,
  totalValue: number
) => {
  let newDataTrend;
  switch (true) {
    case previousTotalValue === 0 && totalValue > 0:
      newDataTrend = 100;
      break;
    case totalValue === 0 && previousTotalValue === 0:
      newDataTrend = 0;
      break;
    default:
      newDataTrend = Math.round(
        ((totalValue - previousTotalValue) / previousTotalValue) * 100
      );
  }
  return newDataTrend;
};

export interface IInsightsLineGraphsSelectedMetrics {
  'insightsLineChart-0': PublisherInsightsMetric;
  'insightsLineChart-1': PublisherInsightsMetric;
  'insightsLineChart-2': PublisherInsightsMetric;
  'insightsLineChart-3': PublisherInsightsMetric;
}
export const defaultInitialMetrics: IInsightsLineGraphsSelectedMetrics = {
  'insightsLineChart-0': PublisherInsightsMetric.PublisherRevenue,
  'insightsLineChart-1': PublisherInsightsMetric.AdInventory,
  'insightsLineChart-2': PublisherInsightsMetric.Ecpm,
  'insightsLineChart-3': PublisherInsightsMetric.Impressions,
};
