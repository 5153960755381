import BulkUpload from 'features/targetingV2/components/BulkUpload/BulkUpload';
import StyledButton, {
  ButtonVariantEnum,
} from 'features/targetingV2/components/core/StyledButton';
import StyledChip from 'features/targetingV2/components/core/StyledChip/StyledChip';
import Typography from 'features/targetingV2/components/core/Typography';
import ParameterMessage, {
  ParameterMessageColorEnum,
} from 'features/targetingV2/components/ParameterMessage/ParameterMessage';
import useBulkUploadMessages from 'features/targetingV2/hooks/BulkUploadMessages/useBulkUploadMessages';
import { IParameter } from 'features/targetingV2/types/targeting';
import {
  getHeaderDetails,
  handleParameterValuesUpload,
} from 'features/targetingV2/utils/bulkUpload';
import { getNoOfMultiValuesToShow } from 'features/targetingV2/utils/common';
import React, { MutableRefObject, useEffect, useState } from 'react';

import useStyles from './BulkUploadSelector.styles';

export interface IBulkUploadSelectorProps {
  selectedParameter: IParameter;
  fieldName: string;
  setFieldValue: (field: string, value: any) => void;
  onClick?: () => void;
  placeholder?: string;
  externalUploadInputRef: MutableRefObject<HTMLInputElement | null>;
}

const BulkUploadSelector = ({
  selectedParameter,
  fieldName,
  setFieldValue,
  onClick,
  placeholder = 'Click here to upload a CSV',
  externalUploadInputRef,
}: IBulkUploadSelectorProps) => {
  const classes = useStyles();
  const valuesFieldName = `${fieldName}.values`;

  const headerDetails = getHeaderDetails(selectedParameter.type);
  const noOfValuesToShow = getNoOfMultiValuesToShow(selectedParameter.type);
  const showToggleValuesButton =
    noOfValuesToShow !== undefined &&
    selectedParameter.values.length > noOfValuesToShow;

  const [showHiddenValues, setShowHiddenValues] = useState(
    !showToggleValuesButton
  );

  const {
    warningMessage,
    errorMessage,
    setWarningMessage,
    setErrorMessage,
    setIsBulkUploaded,
  } = useBulkUploadMessages(selectedParameter, externalUploadInputRef);

  useEffect(() => {
    if (!showToggleValuesButton) {
      setShowHiddenValues(false);
    }
  }, [showToggleValuesButton]);

  const removeValue = (value: any) => {
    const newValues = selectedParameter.values.filter(
      (parameterValue) => parameterValue.value !== value
    );

    setFieldValue(valuesFieldName, newValues);
  };

  const handleToggleValuesButtonClick = () => {
    setShowHiddenValues((prevState: boolean) => !prevState);
  };

  return (
    <div
      className={classes.container}
      data-testid={`${selectedParameter.type}-bulk-upload-selector`}
    >
      <div
        role="button"
        className={classes.fieldWrapper}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
      >
        {selectedParameter.values.length === 0 ? (
          <Typography variant="body2" className={classes.placeholder}>
            {placeholder}
          </Typography>
        ) : (
          <>
            {selectedParameter.values.map((parameterValue, index) => {
              const showChip =
                (noOfValuesToShow && index < noOfValuesToShow) ||
                showHiddenValues;

              return showChip ? (
                <StyledChip
                  key={parameterValue.value}
                  label={parameterValue.label}
                  removeActions={{
                    onClick: () => {
                      removeValue(parameterValue.value);
                    },
                  }}
                />
              ) : null;
            })}
            {showToggleValuesButton && (
              <StyledButton
                variant={ButtonVariantEnum.Text}
                onClick={(event) => {
                  handleToggleValuesButtonClick();
                  if (event.stopPropagation) event.stopPropagation();
                }}
                className={classes.toggleValuesBtnText}
              >
                {`Show ${
                  showHiddenValues
                    ? 'Less'
                    : `All (${selectedParameter.values.length})`
                }`}
              </StyledButton>
            )}
          </>
        )}
        <BulkUpload
          fieldName={valuesFieldName}
          label="Bulk Upload"
          externalUploadInputRef={externalUploadInputRef}
          handleDataUpload={(data, error) =>
            handleParameterValuesUpload(
              data,
              error,
              selectedParameter,
              valuesFieldName,
              setErrorMessage,
              setWarningMessage,
              setFieldValue,
              setIsBulkUploaded
            )
          }
          headerOptions={headerDetails.headerOptions}
          header={headerDetails.header}
        />
      </div>
      <div className={classes.messagesWrapper}>
        {warningMessage && (
          <ParameterMessage
            message={warningMessage}
            color={ParameterMessageColorEnum.Warning}
          />
        )}
        {errorMessage && (
          <ParameterMessage
            message={errorMessage}
            color={ParameterMessageColorEnum.Error}
          />
        )}
      </div>
    </div>
  );
};

export default BulkUploadSelector;
