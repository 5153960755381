import { ErrorMessage, Field, FieldArray, getIn } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';

import targetingStyles from 'assets/styles/components/Targeting.styles';

import { IFormProps } from 'interfaces';

import {
  getTargetingPath,
  getTargetingValues,
  ITargetingComponentProps,
} from 'utils/targetingDefinitions';

export const customTypeValues = {
  keyValueParams: {
    keyValues: [
      {
        key: '',
        value: '',
        readOnly: false,
      },
    ],
  },
};

export interface ITargetingCustomTypeValues {
  key: string;
  value: string;
}

const TargetingCustomType = (
  props: IFormProps<any> & ITargetingComponentProps
) => {
  const {
    values,
    targetingGroupName,
    templateIndex,
    groupIndex,
    index: locationIndex,
    touched,
    errors,
  } = props;

  const classes: any = targetingStyles();

  const targetingValues = getTargetingValues(
    values,
    targetingGroupName,
    templateIndex,
    groupIndex
  );
  const targetingPath = getTargetingPath(
    targetingGroupName,
    templateIndex,
    groupIndex
  );

  return (
    <FieldArray
      name={`${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues`}
    >
      {({ push, remove }) => (
        <>
          {targetingValues.audienceParams[
            locationIndex
          ].keyValueParams.keyValues.map(
            (param: ITargetingCustomTypeValues, index: number) => (
              /* eslint-disable react/no-array-index-key */
              <div className={classes.wrapper} key={index}>
                <Field
                  component={TextField}
                  data-tc="targetingCustomTypeName"
                  label="Name"
                  placeholder="Name"
                  name={`${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.key`}
                  fullWidth
                  classes={{ root: classes.pairValues }}
                  disabled={
                    targetingValues.audienceParams[locationIndex].keyValueParams
                      .keyValues[index].readOnly
                  }
                />
                <Field
                  component={TextField}
                  data-tc="targetingCustomTypeValue"
                  label="Value"
                  placeholder="Value"
                  name={`${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.value`}
                  fullWidth
                  classes={{ root: classes.pairValues }}
                  disabled={
                    targetingValues.audienceParams[locationIndex].keyValueParams
                      .keyValues[index].readOnly
                  }
                />
                <div className={classes.buttons}>
                  <IconButton
                    data-tc="targetingCustomTypeRemoveButton"
                    disabled={
                      targetingValues.audienceParams[locationIndex]
                        .keyValueParams.keyValues.length === 1 ||
                      targetingValues.audienceParams[locationIndex]
                        .keyValueParams.keyValues[index].readOnly
                    }
                    className={classes.button}
                    onClick={() => remove(index)}
                  >
                    <ClearIcon />
                    <span className="sr-only">Remove custom type</span>
                  </IconButton>
                  <IconButton
                    data-tc="targetingCustomTypeAddButton"
                    disabled={
                      targetingValues.audienceParams[locationIndex].readOnlyType
                    }
                    onClick={() => {
                      push(customTypeValues.keyValueParams.keyValues[0]);
                    }}
                    className={classes.button}
                  >
                    <AddIcon />
                    <span className="sr-only">Add another custom type</span>
                  </IconButton>
                </div>
                {((getIn(
                  touched,
                  `${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.key`
                ) &&
                  getIn(
                    errors,
                    `${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.key`
                  )) ||
                  (getIn(
                    touched,
                    `${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.value`
                  ) &&
                    getIn(
                      errors,
                      `${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.value`
                    ))) && (
                  <div className={classes.errors}>
                    <FormHelperText error data-tc="targetingCustomTypeError">
                      <span className={classes.errorMessage}>
                        <ErrorMessage
                          name={`${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.key`}
                        />
                      </span>
                      <span className={classes.errorMessage}>
                        <ErrorMessage
                          name={`${targetingPath}.audienceParams.${locationIndex}.keyValueParams.keyValues.${index}.value`}
                        />
                      </span>
                    </FormHelperText>
                  </div>
                )}
              </div>
            )
          )}
        </>
      )}
    </FieldArray>
  );
};

export default TargetingCustomType;
