import AddParameterDropdownButton from 'features/targetingV2/components/AddParameterDropdownButton/AddParameterDropdownButton';
import ErrorDialog from 'features/targetingV2/components/core/ErrorDialog/ErrorDialog';
import Inventory from 'features/targetingV2/components/InventorySection/Inventory/Inventory';
import TargetingSection from 'features/targetingV2/components/TargetingSection/TargetingSection';
import useError from 'features/targetingV2/hooks/Error/useError';
import { IFormProps, SectionType } from 'features/targetingV2/types/common';
import { IParameterGroup } from 'features/targetingV2/types/targeting';
import { getNewGroupWithFirstParameter } from 'features/targetingV2/utils/common';
import { FieldArray } from 'formik';
import { useAllInventoryParametersQuery } from 'interfaces/generated.types';
import React, { useEffect } from 'react';
import { getSelectOptions } from 'utils/dataTransformation';

const InventoryBuilder = (props: IFormProps<any>) => {
  const { values, setFieldValue } = props;

  const inventoriesFieldName =
    'targetingDefinitionV2.inventoryTargeting.inventories';

  const { loading, data, error } = useAllInventoryParametersQuery({
    fetchPolicy: 'cache-first',
  });

  const { hasError, toggleErrorModal, errorMessages, setErrorMessages } =
    useError(['An error has occurred.']);

  const handleError = (err: any) => {
    const errorHeader =
      'Error while loading inventory targeting parameters. Please refresh and try again.';
    setErrorMessages([errorHeader, err.message]);
    toggleErrorModal();
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const inventories = values.targetingDefinitionV2?.inventoryTargeting
    ?.inventories as IParameterGroup[];

  const allParametersInfo = data?.allInventoryParameters || [];

  const optionalAddFirstInventoryButton = !inventories?.length && (
    <AddParameterDropdownButton
      options={getSelectOptions(allParametersInfo, 'name', 'type')}
      onOptionClick={(parameterType: string) => {
        setFieldValue(inventoriesFieldName, [
          getNewGroupWithFirstParameter(parameterType),
        ]);
      }}
      loading={loading}
      testId="add-first-parameter-btn"
    />
  );

  return (
    <>
      <TargetingSection
        sectionType={SectionType.Inventory}
        headerChildren={optionalAddFirstInventoryButton}
        restrictionsList={values.targetingRestrictions}
      >
        {!loading && (
          <FieldArray name={inventoriesFieldName}>
            {({ remove: removeInventory }) => (
              <>
                {inventories?.map(
                  (inventory: IParameterGroup, index: number) => (
                    <Inventory
                      // eslint-disable-next-line react/no-array-index-key
                      key={`inventory-${index}`}
                      inventory={inventory}
                      inventoryIndex={index}
                      allParametersInfo={allParametersInfo}
                      inventoryFieldName={`${inventoriesFieldName}[${index}]`}
                      areParameterTypeOptionsLoading={loading}
                      setFieldValue={setFieldValue}
                      removeInventory={removeInventory}
                    />
                  )
                )}
              </>
            )}
          </FieldArray>
        )}
      </TargetingSection>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        name="allInventoryParameterError"
        errorMessages={errorMessages}
      />
    </>
  );
};

export default InventoryBuilder;
