import gql from 'graphql-tag';

export const GET_ALL_TAGS = gql`
  query AllTags {
    allTags {
      id
      name
      description
    }
  }
`;

export default { GET_ALL_TAGS };
