import { History } from 'history';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';

import pageStyles from 'assets/styles/components/Page.styles';

import Table from 'components/Table/Table';
import TableLink from 'components/Table/TableLink';
import TableSelect from 'components/Table/TableSelect';
import TableSelectFilter, {
  includesSome,
} from 'components/Table/TableSelectFilter';
import TableValidateCell from 'components/Table/TableValidateCell';
import TableUpdateCell from 'components/Table/TableUpdateCell';
import NumberInput, { SuffixTypes } from 'components/NumberInput/NumberInput';
import StyledLink, { LinkColorEnum } from 'components/StyledLink/StyledLink';

import {
  channelStatusValues,
  channelTypes,
} from 'features/inventory/channel/components/ChannelTabsForm/ChannelFormValues';

import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';

import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';

import {
  Channel,
  ChannelStatus,
  ChannelType,
  EntityType,
  useUpdateChannelMutation,
  Territory,
} from 'interfaces/generated.types';

import { findLabelValue } from 'utils/dataTransformation';
import { handleCellUpdate, handleCellValidate } from 'utils/tables';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import { calculatePercentage, parseFormattedValue } from 'utils/numbers';

import { GET_PUBLISHER } from 'features/inventory/publisher/graphql/queries';
import { GET_ALL_CHANNELS } from '../../graphql/queries';

export interface IChannelsTableProps {
  history: History;
  match?: {
    params: {
      publisherId: string;
    };
  };
  channels: Channel[];
  isEditable: boolean;
  isPublisher?: boolean;
  title: string;
  activeTerritory?: Territory;
}

interface IChannelsRow {
  id: string;
  altId: number;
  name: string;
  type: string | null;
  status: ChannelStatus;
  traffic?: string;
  publisher?: string;
  publisherId?: string;
  network?: string;
  networkId?: string;
}

export const formatData = memoizeOne((data: Channel[]) =>
  data.map((d) => ({
    id: d.id,
    altId: d.altId,
    name: d.name,
    type:
      d.type === ChannelType.ChannelTypeUnspecified
        ? null
        : findLabelValue({
            collection: channelTypes,
            lookupValue: d.type,
          }),
    status: d.status,
    traffic:
      d.trafficAcceptancePercentage || d.trafficAcceptancePercentage === 0
        ? calculatePercentage(d.trafficAcceptancePercentage, 100)
        : null,
    publisher: d.publisher?.name,
    publisherId: d.publisher?.id,
    network: d.publisher?.network?.name,
    networkId: d.publisher?.network?.id,
  }))
);

const ChannelsTable = (props: IChannelsTableProps) => {
  const {
    history,
    channels,
    isEditable,
    isPublisher = false,
    title,
    match,
    activeTerritory,
  } = props;
  const classes = pageStyles();

  const [updateChannel] = useUpdateChannelMutation({
    refetchQueries: [
      {
        query: GET_ALL_CHANNELS,
        variables: { territories: [activeTerritory!] },
      },
    ],
  });

  const [updateChannelRefetchPublisher] = useUpdateChannelMutation({
    refetchQueries: [
      {
        query: GET_PUBLISHER,
        variables: {
          id: match?.params.publisherId,
          territories: [activeTerritory!],
        },
      },
    ],
  });

  const validateChannel = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const location = usePreviousLocation();

  const createChannelCta = isPublisher && isEditable && (
    <StyledLink
      location={{
        pathname: `/publisher/${match?.params.publisherId}/channel`,
        state: { parent: location.state },
      }}
      color={LinkColorEnum.Secondary}
      data-tc="newChannelButton"
    >
      Create New Channel
    </StyledLink>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: 'altId',
      },
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          wordBreak: 'break-word',
        },
        Cell: ({ cell: { value, row } }: CellProps<IChannelsRow>) =>
          TableLink({
            name: value,
            location: {
              pathname: `/channel/${row.original.id}`,
              state: { parent: location.state },
            },
          }),
      },
      ...(!isPublisher
        ? [
            {
              Header: 'Traffic Acceptance %',
              accessor: 'traffic',
              // eslint-disable-next-line react/display-name
              Cell: ({
                cell: {
                  column: { id },
                  row,
                  value,
                },
                onCellUpdate,
                setErrorModal,
                setUpdating,
              }: CellProps<IChannelsRow>) => (
                <TableUpdateCell
                  id={id}
                  row={row}
                  value={value}
                  render={() => value}
                  onCellUpdate={onCellUpdate}
                  setErrorModal={setErrorModal}
                  setUpdating={setUpdating}
                  isEditable={isEditable}
                  editComponent={(cellValue: any, onChange: any) => (
                    <NumberInput
                      value={cellValue}
                      allowNegative={false}
                      name="trafficAcceptance"
                      dataTc="trafficAcceptance"
                      decimalScale={2}
                      suffix={SuffixTypes.percentage}
                      onChange={(event) => onChange(event.target.value)}
                      isAllowed={({ floatValue }) =>
                        floatValue ? floatValue >= 0 && floatValue <= 100 : true
                      }
                    />
                  )}
                />
              ),
            },
          ]
        : []),
      {
        Header: 'Publisher Type',
        accessor: 'type',
      },
      ...(!isPublisher
        ? [
            {
              Header: 'Publisher',
              accessor: 'publisher',
              Cell: ({ cell: { value, row } }: CellProps<IChannelsRow>) =>
                TableLink({
                  name: value,
                  location: {
                    pathname: `/publisher/${row.original.publisherId}`,
                    state: { parent: location.state },
                  },
                }),
            },
            {
              Header: 'Network',
              accessor: 'network',
              Cell: ({ cell: { value, row } }: CellProps<IChannelsRow>) =>
                TableLink({
                  name: value,
                  location: {
                    pathname: `/network/${row.original.networkId}`,
                    state: { parent: location.state },
                  },
                }),
            },
          ]
        : []),
      {
        Header: 'Status',
        accessor: 'status',
        Filter: TableSelectFilter,
        filter: includesSome,
        // eslint-disable-next-line react/display-name
        Cell: ({
          cell: { row, value },
          onCellUpdate,
          onCellValidate,
          setErrorModal,
          setWarningModal,
          setUpdating,
        }: CellProps<IChannelsRow>) => (
          <TableValidateCell
            render={() =>
              findLabelValue({
                collection: channelStatusValues,
                lookupValue: value,
              })
            }
            editComponent={(cellValue: any, onChange: any) => (
              <TableSelect
                value={cellValue}
                onChange={onChange}
                options={channelStatusValues}
                name="channelStatusSelect"
                dataTc="channelStatusSelect"
              />
            )}
            onCellUpdate={onCellUpdate}
            onCellValidate={onCellValidate}
            setErrorModal={setErrorModal}
            setWarningModal={setWarningModal}
            setUpdating={setUpdating}
            row={row}
            value={value}
            isEditable={isEditable}
          />
        ),
      },
    ],
    [isEditable, isPublisher, location.state]
  );

  return (
    <div className={classes.table}>
      <Table
        history={history}
        title={title}
        columns={columns}
        data={formatData(channels)}
        isEditable={isEditable}
        dataTc={`${match?.params.publisherId || ''}listChannelsTable`}
        data-testid="listChannelsTable"
        isPageTable={false}
        onCellUpdate={(
          row: IChannelsRow,
          setErrorModal: any,
          setUpdating: any
        ) =>
          handleCellUpdate({
            variables: {
              id: row.id,
              status: row.status,
              trafficAcceptancePercentage:
                row.traffic !== undefined && row.traffic !== null
                  ? parseFormattedValue(row.traffic) * 100
                  : null,
            },
            update: isPublisher ? updateChannelRefetchPublisher : updateChannel,
            handleContinue: () => history.push(`/channel/${row.id}`),
            setErrorModal,
            setUpdating,
            errorModalContent: {
              title: 'Error',
              closeButton: 'Close',
              continueButton: 'Edit Channel',
            },
          })
        }
        onCellValidate={({
          entity,
          setErrorModal,
          setWarningModal,
          setUpdating,
          handleContinue,
        }) =>
          handleCellValidate({
            validate: validateChannel,
            entity: { ...entity, type: EntityType.Channel },
            setWarningModal,
            setErrorModal,
            setUpdating,
            handleContinue,
          })
        }
        customToolbarCtas={createChannelCta}
      />
    </div>
  );
};

export default ChannelsTable;
