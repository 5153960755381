import { ErrorResponse } from 'apollo-link-error';
import { History } from 'history';

export enum GraphqlErrorCodes {
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
}

const errorCodesMap: any = {
  [GraphqlErrorCodes.FORBIDDEN]: '403',
  [GraphqlErrorCodes.UNAUTHENTICATED]: '401',
  [GraphqlErrorCodes.INTERNAL_SERVER_ERROR]: '500',
};

enum NetworkErrorCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
}

const networkErrorCodesMap: any = {
  BAD_REQUEST: '400',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  INTERNAL_SERVER_ERROR: '500',
  BAD_GATEWAY: '500',
  SERVICE_UNAVAILABLE: '500',
  GATEWAY_TIMEOUT: '500',
};

export enum AlreadyExistMessages {
  EXTERNAL_DEAL_ID = 'External ID already exists',
  EXTERNAL_DEAL_ID_FOR_CHANNEL = 'External deal ID already exists',
}

export const handleGraphqlErrors = (error: ErrorResponse, history: History) => {
  const { graphQLErrors, networkError, operation } = error;

  const traceParent = operation
    .getContext()
    .response?.headers?.get('traceparent');

  if (networkError && 'statusCode' in networkError) {
    const errorCode =
      networkErrorCodesMap[NetworkErrorCodes[networkError.statusCode]];
    if (errorCode) {
      history.replace(`/error/${errorCode}`, { traceParent });
    }
  }

  if (graphQLErrors) {
    const errorCode: any = graphQLErrors.find(({ extensions }) => {
      if (extensions) {
        return !!errorCodesMap[extensions.code as GraphqlErrorCodes] as boolean;
      }
      return false;
    });
    if (errorCode) {
      history.replace(
        `/error/${
          errorCodesMap[errorCode.extensions.code as GraphqlErrorCodes]
        }`,
        { traceParent }
      );
    }
  }
};
