import { format, isValid } from 'date-fns';
import targetingV2 from 'features/targetingV2/validations/targetingV2';
import { AuctionType, Territory } from 'interfaces/generated.types';
import dateUtils, { TimeZones } from 'utils/date';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';
import commons from 'validations/commons';
import * as Yup from 'yup';

const DealFormValidation = (territory: Territory | undefined) =>
  Yup.object().shape(
    {
      name: commons.name('Deal Name'),
      adSeparation: commons.optionalPrice({
        messages: {
          minMessage: 'Minimum ad separation is 0 seconds',
          maxMessage: 'Maximum ad separation is 300 seconds',
        },
        minimum: 0,
        maximum: 300,
        fieldKey: 'adSeparation',
      }),
      owner: Yup.object()
        .shape({
          label: Yup.string().required('Owner label must be present'),
          value: Yup.string().required('Owner value must be present'),
        })
        .required('Owner is a required field')
        .nullable(),
      timeZone: Yup.string().when(['startDate', 'endDate'], {
        is: (startDate: Date, endDate: Date) => !!startDate || !!endDate,
        then: Yup.string().required(
          'Timezone is required when a start or end date is added'
        ),
      }),
      floorCpm: commons.optionalPrice({
        messages: {
          minMessage: 'Floor CPM should be higher than or equal to 0',
          maxMessage: 'Floor CPM should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'dealFloorCpm',
      }),
      startDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Deal Start Date should be a valid date')
        .when('endDate', {
          is: (endDate: Date) => !!endDate,
          then: Yup.date().required(
            'Start date is required when an end date is added'
          ),
        }),
      endDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Deal End Date should be a valid date')
        .when(
          ['startDate', 'timeZone'],
          (
            startDate: Date | null,
            timeZone: TimeZones,
            schema: Yup.DateSchema
          ) => {
            if (!isValid(startDate)) {
              return schema;
            }
            return schema.test(
              'endDate',
              `Deal End Date should be after ${format(
                startDate as Date,
                getDateFormatBasedOnTerritory(territory, true),
                {
                  useAdditionalWeekYearTokens: true,
                  useAdditionalDayOfYearTokens: true,
                }
              )}`,
              (endDate: Date) => {
                if (!isValid(endDate)) {
                  return true;
                }
                return dateUtils.isDateAfterTheOther({
                  date: endDate,
                  dateToCompare: startDate as Date,
                  timeZone,
                });
              }
            );
          }
        )
        .test(
          'endDate',
          'Deal End Date should not be in the past',
          function endDateInThePast(endDate: Date) {
            if (!endDate || !isValid(endDate)) {
              return true;
            }
            const { timeZone } = this.parent;
            return dateUtils.isDateInTheFuture(endDate, timeZone);
          }
        ),
      currency: commons
        .currency('currency')
        .when('dealFloorCpm', {
          is: (value: number) => !!value,
          then: Yup.string().required(
            'Currency is required if Floor CPM is added'
          ),
        })
        .when('offsetCpm', {
          is: (value: number) => !!value,
          then: Yup.string().required(
            'Currency is required when an offset is added'
          ),
        }),
      externalId: commons.max255Characters(),
      offsetCpm: commons.optionalPrice({
        messages: {
          minMessage:
            'Deal Off-set should be higher than or equal to -999,999.99',
          maxMessage:
            'Deal Off-set should be lower than or equal to 999,999.99',
        },
        minimum: -999999.99,
        maximum: 999999.99,
        fieldKey: 'offsetCpm',
      }),
      auctionType: commons.optionalFieldWithValidation({
        message: 'Action Type should be a valid type',
        fieldKey: 'auctionType',
        valuesToCompareAgainst: [
          AuctionType.AuctionTypeUnspecified,
          AuctionType.FirstPrice,
          AuctionType.FixedPrice,
          AuctionType.SecondPrice,
        ],
      }),
      priority: commons.optionalFieldWithValidation({
        message: 'Please select a valid priority',
        fieldKey: 'priority',
        valuesToCompareAgainst: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      }),
      overridePublisherFloor: Yup.bool().test(
        'overridePublisherFloor',
        'Override Publisher Floor must be set when Auction Type is Fixed Price',
        function canOverridePublisherFloorBeFalse(
          overridePublisherFloor: boolean
        ) {
          const { auctionType } = this.parent;
          return !(
            auctionType === AuctionType.FixedPrice && !overridePublisherFloor
          );
        }
      ),
      seats: Yup.array().when(['dsp', 'allSeats', 'allKnownSeats'], {
        is: (dsp, allSeats, allKnownSeats) =>
          dsp && !allSeats && !allKnownSeats,
        then: Yup.array().required('You need to select at least one seat'),
      }),
      targetingMasterTemplates: Yup.array().of(
        Yup.object().shape({
          groups: Yup.array().of(
            Yup.object().shape(
              {
                audienceParams: commons.audienceParams('Listener parameters'),
                channels: Yup.array()
                  .of(Yup.string())
                  .when(['audienceParams'], {
                    is: (audienceParams: any) =>
                      audienceParams && audienceParams.length > 0,
                    then: Yup.array().required(
                      "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
                    ),
                  })
                  .nullable(),
              },
              ['audienceParams', 'channels'] as any
            )
          ),
        })
      ),
      targetingGeneralGroup: Yup.object()
        .shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
          },
          ['audienceParams'] as any
        )
        .nullable(),
      targetingGroups: Yup.array().of(
        Yup.object().shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
            channels: Yup.array()
              .of(Yup.string())
              .when(['audienceParams'], {
                is: (audienceParams: any) =>
                  audienceParams && audienceParams.length > 0,
                then: Yup.array().required(
                  "It looks like your targeting group does not have channels assigned. Use the 'Add channels' button to assign a channel."
                ),
              })
              .nullable(),
          },
          ['audienceParams', 'channels'] as any
        )
      ),
      targetingDefinitionV2: Yup.object().shape({
        customTargeting: targetingV2.customTargeting,
      }),
    },
    [
      ['timeZone', 'startDate'],
      ['timeZone', 'endDate'],
      ['startDate', 'endDate'],
      ['currency', 'dealFloorCpm'],
      ['currency', 'offsetCpm'],
    ]
  );

export default DealFormValidation;
