import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    maxHeight: theme.spacing(6),
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textAlign: 'center',
  },
  button: {
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
  },
}));

export default useStyles;
