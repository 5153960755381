import Loader from 'components/Loader/Loader';
import { useAuthContext } from 'context/AuthProvider/AuthProvider';
import PasswordResetForm, {
  IPasswordResetFormValues,
} from 'features/login/components/PasswordResetForm/PasswordResetForm';
import loginOptionsStyles from 'features/login/pages/LoginOptions/LoginOptionsPage.styles';
import PageContainer from 'features/login/pages/PageContainer/PageContainer';
import PasswordResetFormValidation from 'features/login/validations/passwordReset';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AuthState } from 'utils/authProvider';

import { Typography } from '@material-ui/core';

const PasswordReset = () => {
  const classes = loginOptionsStyles();

  const { loaded, state, onResetPassword } = useAuthContext();

  const { search }: { search: string } = useLocation();
  const passwordResetToken = new URLSearchParams(search).get('pwrt');

  const [error, setError] = useState<string>('');

  const isLoading = !loaded || state === AuthState.Fetching;

  if (state === AuthState.PasswordResetSuccess) {
    return <Redirect to="/sign-in" />;
  }

  if (!passwordResetToken) {
    return <Redirect to="/error/404" />;
  }

  const handleFormSubmit = (
    values: IPasswordResetFormValues,
    actions: FormikHelpers<IPasswordResetFormValues>
  ) => {
    const params = {
      passwordResetToken,
      newPassword: values.password,
    };
    actions.setSubmitting(false);
    onResetPassword(params, setError);
  };

  return (
    <PageContainer>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            Reset Password
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <Formik<IPasswordResetFormValues>
              initialValues={{ password: '', passwordConfirm: '' }}
              onSubmit={(values, actions) => {
                handleFormSubmit(values, actions);
              }}
              validationSchema={PasswordResetFormValidation}
              component={(formikProps) => (
                <PasswordResetForm
                  error={error}
                  setError={setError}
                  {...formikProps}
                />
              )}
            />
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default PasswordReset;
