import gql from 'graphql-tag';
import { PermissionName } from 'interfaces/generated.types';

export type MappedPermissionName = keyof typeof permissionNameMap;

export const permissionNameMap = {
  [PermissionName.Direct]: 'Direct',
  [PermissionName.DirectRead]: 'Direct: Read-only',
  [PermissionName.Supply]: 'Supply',
  [PermissionName.SupplyRead]: 'Supply: Read-only',
  [PermissionName.Programmatic]: 'Programmatic',
  [PermissionName.ProgrammaticRead]: 'Programmatic: Read-only',
  [PermissionName.Primary]: 'Primary',
  [PermissionName.Experimental]: 'Experimental',
  [PermissionName.Forecasting]: 'Forecasting',
  [PermissionName.PublisherInsightsTier1]: 'Publisher Insights Tier 1',
  [PermissionName.PublisherInsightsTier2]: 'Publisher Insights Tier 2',
  [PermissionName.PublisherContract]: 'Publisher Contract',
  [PermissionName.PublisherContractRead]: 'Publisher Contract Read',
  [PermissionName.Booking]: 'Booking',
  [PermissionName.BookingRead]: 'Booking: Read-only',
};

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      permissions {
        id
        name
        featureToggles {
          id
          name
          toggled
        }
      }
      entityPermissions {
        entityId
        entityType
      }
      primaryTerritory
      territories
      userType
    }
  }
`;

export default {
  GET_CURRENT_USER,
};
